import React from "react";
import I18n from "../../../languageSelector";

const ReportImages = ({
  photo,
  imageCount = undefined,
  setImageIndex,
  setShowImageSrc,
  setShowImageDialog,
}) => {
  const random = Math.round(1 + Math.random() * (1000000 - 1));

  return (
    <>
      <div className="preview-thumbs">
        {photo && photo.length
          ? photo.map((item, index) => {
              return (
                <span
                  className="thumb-wrap"
                  key={index}
                  onClick={() => {
                    setImageIndex(index);
                    setShowImageSrc(photo);
                    setShowImageDialog(true);
                  }}
                >
                  <div className="thumb-img">
                    <img
                      src={item.thumbnail ? item.thumbnail : item.image}
                      alt="Thumbnail"
                      id={`image-${index}-${random}`}
                      onLoad={({ target: img }) => {
                        let style =
                          img.naturalWidth > img.naturalHeight
                            ? img.naturalHeight
                            : img.naturalWidth;
                        let documents;
                        documents = document.getElementById(
                          `image-${index}-${random}`
                        );
                        documents.setAttribute("height", style + "px");
                        documents.setAttribute("width", style + "px");
                      }}
                    />
                    {imageCount !== undefined ? (
                      <p className="text-center text-muted mb-0">
                        <I18n lng="global.Pic" /> {imageCount + index + 1}
                      </p>
                    ) : null}
                  </div>
                </span>
              );
            })
          : null}
      </div>
    </>
  );
};

export default ReportImages;
