import axios from "axios";
import { REQUEST, SUCCESS, FAILURE } from "../action-type.util";

export const ACTION_TYPES = {
  COMPANY_UPDATE: "companies/update",
  GET_COMPANY: "companies/Get",
  SAVE_CARD: "companies/SaveCard",
  GET_CARD: "companies/GetCard",
  VERIFY_CARD: "companies/VerifyCard",
};

const initialState = {
  company: {},
  loading: false,
  error: undefined,
};

export const CompanyState = initialState;

// Reducer
// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.COMPANY_UPDATE):
    case REQUEST(ACTION_TYPES.GET_COMPANY):
    case REQUEST(ACTION_TYPES.SAVE_CARD):
    case REQUEST(ACTION_TYPES.GET_CARD):
    case REQUEST(ACTION_TYPES.VERIFY_CARD):
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.COMPANY_UPDATE):
    case FAILURE(ACTION_TYPES.GET_COMPANY):
    case FAILURE(ACTION_TYPES.SAVE_CARD):
    case FAILURE(ACTION_TYPES.GET_CARD):
    case FAILURE(ACTION_TYPES.VERIFY_CARD):
      return {
        ...state,
        error: undefined,
        loading: false,
      };
    case SUCCESS(ACTION_TYPES.GET_COMPANY):
      return {
        ...state,
        company: action.payload.data,
        error: undefined,
        loading: false,
      };
    case SUCCESS(ACTION_TYPES.COMPANY_UPDATE):
      return {
        ...state,
        company: action.payload.data,
        error: undefined,
        loading: false,
      };
    case SUCCESS(ACTION_TYPES.SAVE_CARD):
      return {
        ...state,
        verificationUrl: action.payload.data,
        error: undefined,
        loading: false,
      };
    case SUCCESS(ACTION_TYPES.GET_CARD):
      return {
        ...state,
        cardData: action.payload.data,
        error: undefined,
        loading: false,
      };
    case SUCCESS(ACTION_TYPES.VERIFY_CARD):
      return {
        ...state,
        cardData: action.payload.data,
        error: undefined,
        loading: false,
      };
    default:
      return state;
  }
};

// Actions

export const companyUpdate = (company) => async (dispatch) => {
  let companyUpdate = {
    address: company.address,
    taxNumber: company.taxNumber,
    city: company.city,
    companyName: company.companyName,
    country: company?.country?.label ? company?.country?.label : "",
    email: company.email,
    emailMessage: company.emailMessage,
    subject: company.subject,
    phone: company.phone ? company.phone : "",
    postalCode: company.postalCode,
    smsMessage: company.smsMessage,
    state: company.state,
    timeZone: company.timeZone ? company.timeZone.label : "",
    image: company.image,
  };
  return await dispatch({
    type: ACTION_TYPES.COMPANY_UPDATE,
    payload: axios.patch(`/companies/${company.id}`, { ...companyUpdate }),
  });
};
export const companyUpdateReportColor = (id, color) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.COMPANY_UPDATE,
    payload: axios.patch(`/companies/${id}`, color),
  });
};
export const getCompanyById = (id) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.COMPANY_UPDATE,
    payload: axios.get(`/companies/${id}`),
  });
};
export const saveCard = (id, cardData) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.SAVE_CARD,
    payload: axios.patch(`/payments/saveCard/${id}`, { ...cardData }),
  });
};
export const getCard = (id) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.GET_CARD,
    payload: axios.get(`/payments/getCard/${id}`),
  });
};
export const verifyCard = (id) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.GET_CARD,
    payload: axios.get(`/payments/verifyCard/${id}`),
  });
};
