import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { i18n } from "../../languageSelector";
import { GenaricListDTO } from "../DTO/analyticsDTO";
import FilterForm, { InitialStatProps } from "../Filter-Form/FilterForm";
import CustomDummyChart from "../empty-chart/CustomDummyChart";
import SingleDummyChart from "../empty-chart/SingleDummyChart";
import jsPDF from "jspdf";
import autoTable, { UserOptions } from "jspdf-autotable";
import customFont from "../../../../assets/fonts/Amiri-regular-normal";

// Load custom font

import {
  getAnalytics,
  getAnalyticsForms,
  getAnalyticsSites,
  getAnalyticsUsers,
  getAllAnalytics,
  updateAnalticsUser,
} from "../reducer/analytics.reducer";
import Table from "../table/Table";
// import { useSidebar } from "../useSidebarProvider";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { getSession } from "../../../../redux/reducers/authentication/authentication";
import moment from "moment";
import { TEXT_DIRECTION, translateStatusForPDF } from "../../../../helper/helperFunctions";
import html2canvas from "html2canvas";

customFont(jsPDF.API);
export interface HeaderDataInterfae {
  title: string;
  value: number | string;
  perDayCalculation: string[];
  color: string;
  width?: number | string;
  height?: number;
  percentage: string;
}
interface jsPDFCustom extends jsPDF {
  autoTable: (options: UserOptions) => void;
}

const AnalyticsMain = ({
  getAnalytics,
  getAnalyticsForms,
  getAnalyticsSites,
  getAnalyticsUsers,
  updateAnalticsUser,
  analyticUsers,
  analyticSites,
  analyticForms,
  analytics,
  getAllAnalytics,
  getSession,
  user,
}: any) => {
  const [analyticsReports, setAnalyticsReports] = useState<any>([]);
  const [analyticsCharts, setAnalyticsCharts] = useState<HeaderDataInterfae[]>([]);
  const [analyticsXaxisData, setanalyticsXaxisData] = useState<string[]>([]);
  const [page, setpage] = useState(1);
  const [total, setTotal] = useState(100);
  const [size, setSize] = useState(10);
  const [totalResult, setTotalResult] = useState(0);
  const [selectedValue, setselectedValue] = useState("Last30Days");

  const statusOptions = [
    {
      label: i18n("Analytics.Complete"),
      value: "accept",
      isSelected: true,
      id: "1",
    },
    {
      label: i18n("Analytics.Draft"),
      value: "draft",
      isSelected: true,
      id: "2",
    },
    {
      label: i18n("Analytics.Incomplete"),
      value: "incomplete",
      isSelected: true,
      id: "3",
    },
    {
      label: i18n("Analytics.Rejected"),
      value: "reject",
      isSelected: true,
      id: "4",
    },
    {
      label: i18n("Analytics.Resubmitted"),
      value: "reSubmit",
      isSelected: true,
      id: "5",
    },
    {
      label: i18n("Analytics.Submitted"),
      value: "submit",
      isSelected: true,
      id: "6",
    },
  ];
  const [selectedItem, setSelectedItem] = useState<HeaderDataInterfae | any>();
  const [sitesList, setSitesList] = useState<GenaricListDTO[]>([]);
  const [formsList, setFormsList] = useState<GenaricListDTO[]>([]);
  const [usersList, setUsersList] = useState<GenaricListDTO[]>([]);
  const [statusList, setstatusList] = useState<GenaricListDTO[]>([]);
  const [chartsLoading, setChartsLoading] = useState<boolean>(true);
  const [isDeletedItemChecked, setIsDeletedItemChecked] = useState<boolean>(true);
  const [selectedDate, setSelectedDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [screenshotLoading, setscreenshotLoading] = useState(false);
  const [formValues, setFormValues] = useState<InitialStatProps>({
    day: i18n("Analytics.Last30Days"),
    form: i18n("global.All"),
    inspector: i18n("global.All"),
    status: i18n("global.All"),
    site: [i18n("global.All")],
  });

  const handleClick = (item: HeaderDataInterfae) => {
    setSelectedItem(item);
  };

  useEffect(() => {
    setChartsLoading(true);
    getAnalyticsForms(isDeletedItemChecked);
    getAnalyticsSites(isDeletedItemChecked);
    getAnalyticsUsers(isDeletedItemChecked);

    // eslint-disable-next-line
  }, [isDeletedItemChecked]);

  useEffect(() => {
    if (user) {
      if (user?.analyticsSetting?.selectedDate && user?.analyticsSetting?.startDate && user?.analyticsSetting?.endDate) {
        setSelectedDate({
          startDate: user?.analyticsSetting?.startDate,
          endDate: user?.analyticsSetting?.endDate,
        });
        setselectedValue(user?.analyticsSetting?.selectedDate);
      }
      const refactorStatusOption = statusOptions.map((item) => {
        return {
          ...item,
          isSelected:
            user && user.analyticsSetting && user?.analyticsSetting?.reportStatus?.length > 0
              ? user?.analyticsSetting?.reportStatus?.includes(item.value)
                ? true
                : false
              : true,
        };
      });
      setstatusList([...refactorStatusOption]);
    }
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (analyticForms?.length) {
      setFormsList([...analyticForms]);
    } else {
      setFormsList([]);
    }
    // eslint-disable-next-line
  }, [analyticForms]);

  useEffect(() => {
    if (analyticSites?.length) {
      setSitesList([...analyticSites]);
    } else {
      setSitesList([]);
    }
    // eslint-disable-next-line
  }, [analyticSites]);

  useEffect(() => {
    if (analyticUsers?.length) {
      setUsersList([...analyticUsers]);
    } else {
      setUsersList([]);
    }
    // eslint-disable-next-line
  }, [analyticUsers]);

  useEffect(() => {
    if (
      formsList?.length > 0 &&
      sitesList?.length > 0 &&
      usersList?.length > 0 &&
      statusList?.length > 0 &&
      selectedDate.startDate &&
      selectedDate.endDate &&
      page &&
      size
    ) {
      setChartsLoading(true);
      const selectedFormIds = formsList?.filter((item: GenaricListDTO) => item.isSelected)?.map((item: GenaricListDTO) => item.id);
      const selectedSiteIds = sitesList?.filter((item: GenaricListDTO) => item.isSelected)?.map((item: GenaricListDTO) => item.id);
      const selectedUserIds = usersList?.filter((item: GenaricListDTO) => item.isSelected)?.map((item: GenaricListDTO) => item.id);
      const selectedStatus = statusList?.filter((item) => item.isSelected)?.map((item) => item.value);

      getAnalytics({
        page,
        size,
        selectedFormIds,
        selectedUserIds,
        selectedSiteIds,
        selectedStatus,
        startDate: selectedDate.startDate,
        endDate: selectedDate.endDate,
        selectedDate: selectedValue,
      });
    }
    // eslint-disable-next-line
  }, [formsList, sitesList, usersList, statusList, selectedDate, page, size]);

  useEffect(() => {
    if (analytics && analytics.chartsData?.chartsToShow?.length > 0) {
      const refactorData = analytics.chartsData?.chartsToShow?.map((item: HeaderDataInterfae) => ({
        ...item,
        width: "100%",
        height: 110,
      }));
      setAnalyticsCharts(refactorData);
      if (refactorData?.length > 0) {
        setSelectedItem(refactorData[0]);
        setscreenshotLoading(true);
      }
      setanalyticsXaxisData(analytics?.chartsData?.xAxisData);
      setChartsLoading(false);
      setAnalyticsReports(analytics?.results);
      setTotal(analytics?.totalPages);
      setTotalResult(analytics?.totalResults);
    }
    // eslint-disable-next-line
  }, [analytics]);

  // selectAl lFor Forms
  const formHandleSelectAll = () => {
    let refactorFormList;
    if (formsList && formsList.length) {
      if (formsList?.filter((item) => !item.isSelected).length > 0) {
        refactorFormList = formsList.map((item) => {
          return { ...item, isSelected: true };
        });
      } else {
        refactorFormList = formsList.map((item) => {
          return { ...item, isSelected: false };
        });
      }
      setChartsLoading(true);
      const selectedFormIds = refactorFormList?.filter((item: GenaricListDTO) => item.isSelected)?.map((item: GenaricListDTO) => item.id);
      const selectedSiteIds = sitesList?.filter((item: GenaricListDTO) => item.isSelected)?.map((item: GenaricListDTO) => item.id);
      const selectedUserIds = usersList?.filter((item: GenaricListDTO) => item.isSelected)?.map((item: GenaricListDTO) => item.id);
      const selectedStatus = statusList?.filter((item) => item.isSelected)?.map((item) => item.value);
      getAnalytics({
        page,
        size,
        selectedFormIds,
        selectedSiteIds,
        selectedUserIds,
        selectedStatus,
        startDate: selectedDate.startDate,
        endDate: selectedDate.endDate,
        selectedDate: selectedValue,
      });
      setFormsList([...refactorFormList]);
    }
  };

  // selectAll For users
  const userHandleSelectAll = () => {
    if (usersList && usersList.length) {
      let refactorUserList;
      if (usersList?.filter((item) => !item.isSelected).length > 0) {
        refactorUserList = usersList.map((item) => {
          return { ...item, isSelected: true };
        });
      } else {
        refactorUserList = usersList.map((item) => {
          return { ...item, isSelected: false };
        });
      }
      setChartsLoading(true);
      const selectedUserIds = refactorUserList?.filter((item: GenaricListDTO) => item.isSelected)?.map((item: GenaricListDTO) => item.id);
      const selectedSiteIds = sitesList?.filter((item: GenaricListDTO) => item.isSelected)?.map((item: GenaricListDTO) => item.id);
      const selectedFormIds = formsList?.filter((item: GenaricListDTO) => item.isSelected)?.map((item: GenaricListDTO) => item.id);
      const selectedStatus = statusList?.filter((item) => item.isSelected)?.map((item) => item.value);
      getAnalytics({
        page,
        size,
        selectedFormIds,
        selectedSiteIds,
        selectedUserIds,
        selectedStatus,
        startDate: selectedDate.startDate,
        endDate: selectedDate.endDate,
        selectedDate: selectedValue,
      });
      setUsersList([...refactorUserList]);
    }
  };
  // selectAll For sites
  const sitesHandleSelectAll = () => {
    let refactorSiteList;
    if (sitesList && sitesList.length) {
      if (sitesList?.filter((item) => !item.isSelected).length > 0) {
        refactorSiteList = sitesList.map((item) => {
          return { ...item, isSelected: true };
        });
      } else {
        refactorSiteList = sitesList.map((item) => {
          return { ...item, isSelected: false };
        });
      }
      setChartsLoading(true);
      const selectedSiteIds = refactorSiteList?.filter((item: GenaricListDTO) => item.isSelected)?.map((item: GenaricListDTO) => item.id);
      const selectedUserIds = usersList?.filter((item: GenaricListDTO) => item.isSelected)?.map((item: GenaricListDTO) => item.id);
      const selectedFormIds = formsList?.filter((item: GenaricListDTO) => item.isSelected)?.map((item: GenaricListDTO) => item.id);
      const selectedStatus = statusList?.filter((item) => item.isSelected)?.map((item) => item.value);
      getAnalytics({
        page,
        size,
        selectedFormIds,
        selectedSiteIds,
        selectedUserIds,
        selectedStatus,
        startDate: selectedDate.startDate,
        endDate: selectedDate.endDate,
        selectedDate: selectedValue,
      });

      setSitesList([...refactorSiteList]);
    }
  };
  // select All For status
  const statusHandleSelectAll = () => {
    let refactorStatusList;
    if (statusList && statusList.length) {
      if (statusList?.filter((item) => !item.isSelected).length > 0) {
        refactorStatusList = statusList.map((item) => {
          return { ...item, isSelected: true };
        });
      } else {
        refactorStatusList = statusList.map((item) => {
          return { ...item, isSelected: false };
        });
      }
      setChartsLoading(true);
      const selectedSiteIds = sitesList?.map((item: GenaricListDTO) => item.id);
      const selectedUserIds = usersList?.filter((item: GenaricListDTO) => item.isSelected)?.map((item: GenaricListDTO) => item.id);
      const selectedFormIds = formsList?.filter((item: GenaricListDTO) => item.isSelected)?.map((item: GenaricListDTO) => item.id);
      const selectedStatus = refactorStatusList?.filter((item: GenaricListDTO) => item.isSelected)?.map((item) => item.value);
      getAnalytics({
        page,
        size,
        selectedFormIds,
        selectedSiteIds,
        selectedUserIds,
        selectedStatus,
        startDate: selectedDate.startDate,
        endDate: selectedDate.endDate,
        selectedDate: selectedValue,
      });
      setstatusList([...refactorStatusList]);
    }
  };
  // single form selected
  const formHandleSelectItem = (id: string) => {
    const refactorFormList = formsList.map((item) => {
      if (item.id === id) {
        return { ...item, isSelected: !item.isSelected };
      } else {
        return item;
      }
    });
    setChartsLoading(true);
    const selectedSiteIds = sitesList?.filter((item: GenaricListDTO) => item.isSelected)?.map((item: GenaricListDTO) => item.id);
    const selectedUserIds = usersList?.filter((item: GenaricListDTO) => item.isSelected)?.map((item: GenaricListDTO) => item.id);
    const selectedFormIds = refactorFormList?.filter((item: GenaricListDTO) => item.isSelected)?.map((item: GenaricListDTO) => item.id);
    const selectedStatus = statusList?.filter((item: GenaricListDTO) => item.isSelected).map((item) => item.value);
    getAnalytics({
      page,
      size,
      selectedFormIds,
      selectedSiteIds,
      selectedUserIds,
      selectedStatus,
      startDate: selectedDate.startDate,
      endDate: selectedDate.endDate,
      selectedDate: selectedValue,
    });
    setFormsList([...refactorFormList]);
  };
  // single sites selected
  const sitesHandleSelectItem = (id: string) => {
    const refactorSiteList = sitesList.map((item) => {
      if (item.id === id) {
        return { ...item, isSelected: !item.isSelected };
      } else {
        return item;
      }
    });
    setChartsLoading(true);
    const selectedSiteIds = refactorSiteList?.filter((item: GenaricListDTO) => item.isSelected)?.map((item: GenaricListDTO) => item.id);
    const selectedUserIds = usersList?.filter((item: GenaricListDTO) => item.isSelected)?.map((item: GenaricListDTO) => item.id);
    const selectedFormIds = formsList?.filter((item: GenaricListDTO) => item.isSelected)?.map((item: GenaricListDTO) => item.id);
    const selectedStatus = statusList?.filter((item: GenaricListDTO) => item.isSelected).map((item) => item.value);
    getAnalytics({
      page,
      size,
      selectedFormIds,
      selectedSiteIds,
      selectedUserIds,
      selectedStatus,
      startDate: selectedDate.startDate,
      endDate: selectedDate.endDate,
      selectedDate: selectedValue,
    });
    setSitesList([...refactorSiteList]);
  };
  // single user selected
  const userHandleSelectItem = (id: string) => {
    const refactorUserList = usersList.map((item) => {
      if (item.id === id) {
        return { ...item, isSelected: !item.isSelected };
      } else {
        return item;
      }
    });
    setChartsLoading(true);
    const selectedSiteIds = sitesList?.filter((item: GenaricListDTO) => item.isSelected)?.map((item: GenaricListDTO) => item.id);
    const selectedUserIds = refactorUserList?.filter((item: GenaricListDTO) => item.isSelected)?.map((item: GenaricListDTO) => item.id);
    const selectedFormIds = formsList?.filter((item: GenaricListDTO) => item.isSelected)?.map((item: GenaricListDTO) => item.id);
    const selectedStatus = statusList?.filter((item: GenaricListDTO) => item.isSelected).map((item) => item.value);
    getAnalytics({
      page,
      size,
      selectedFormIds,
      selectedSiteIds,
      selectedUserIds,
      selectedStatus,
      startDate: selectedDate.startDate,
      endDate: selectedDate.endDate,
      selectedDate: selectedValue,
    });
    setUsersList([...refactorUserList]);
  };
  // single status selected
  const statusHandleSelectItem = (id: string) => {
    const refactorStatusList = statusList.map((item) => {
      if (item.id === id) {
        return { ...item, isSelected: !item.isSelected };
      } else {
        return item;
      }
    });
    setChartsLoading(true);
    const selectedSiteIds = sitesList?.filter((item: GenaricListDTO) => item.isSelected)?.map((item: GenaricListDTO) => item.id);
    const selectedUserIds = usersList?.filter((item: GenaricListDTO) => item.isSelected)?.map((item: GenaricListDTO) => item.id);
    const selectedFormIds = formsList?.filter((item: GenaricListDTO) => item.isSelected)?.map((item: GenaricListDTO) => item.id);
    const selectedStatus = refactorStatusList?.filter((item: GenaricListDTO) => item.isSelected)?.map((item) => item.value);
    getAnalytics({
      page,
      size,
      selectedFormIds,
      selectedSiteIds,
      selectedUserIds,
      selectedStatus,
      startDate: selectedDate.startDate,
      endDate: selectedDate.endDate,
      selectedDate: selectedValue,
    });
    setstatusList([...refactorStatusList]);
  };

  // // form handler search

  const formHandleSearch = (() => {
    let timeoutId: NodeJS.Timeout | undefined;

    return (value: string) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        getAnalyticsForms(isDeletedItemChecked, value).then((res: any) => {
          setChartsLoading(true);
          const selectedFormIds = res?.value?.data?.map((item: GenaricListDTO) => item.id);
          const selectedSiteIds = sitesList?.filter((item: GenaricListDTO) => item.isSelected)?.map((item: GenaricListDTO) => item.id);
          const selectedUserIds = usersList?.filter((item: GenaricListDTO) => item.isSelected)?.map((item: GenaricListDTO) => item.id);
          const selectedStatus = statusList?.filter((item) => item.isSelected)?.map((item) => item.value);
          getAnalytics({
            page,
            size,
            selectedFormIds,
            selectedUserIds,
            selectedSiteIds,
            selectedStatus,
            startDate: selectedDate.startDate,
            endDate: selectedDate.endDate,
            selectedDate: selectedValue,
          });
        });
      }, 200);
    };
  })();

  const userHandlerSearch = (() => {
    let timeoutId: NodeJS.Timeout | undefined;

    return (value: string) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        getAnalyticsUsers(isDeletedItemChecked, value).then((res: any) => {
          setChartsLoading(true);
          const selectedUserIds = res?.value?.data?.map((item: GenaricListDTO) => item.id);
          const selectedSiteIds = sitesList?.filter((item: GenaricListDTO) => item.isSelected)?.map((item: GenaricListDTO) => item.id);
          const selectedFormIds = formsList?.filter((item: GenaricListDTO) => item.isSelected)?.map((item: GenaricListDTO) => item.id);
          const selectedStatus = statusList?.filter((item) => item.isSelected)?.map((item) => item.value);
          getAnalytics({
            page,
            size,
            selectedFormIds,
            selectedUserIds,
            selectedSiteIds,
            selectedStatus,
            startDate: selectedDate.startDate,
            endDate: selectedDate.endDate,
            selectedDate: selectedValue,
          });
        });
      }, 200);
    };
  })();
  const siteHandlerSearch = (() => {
    let timeoutId: NodeJS.Timeout | undefined;

    return (value: string) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        getAnalyticsSites(isDeletedItemChecked, value).then((res: any) => {
          setChartsLoading(true);
          const selectedSiteIds = res?.value?.data?.map((item: GenaricListDTO) => item.id);
          const selectedUserIds = usersList?.filter((item: GenaricListDTO) => item.isSelected)?.map((item: GenaricListDTO) => item.id);
          const selectedFormIds = formsList?.filter((item: GenaricListDTO) => item.isSelected)?.map((item: GenaricListDTO) => item.id);
          const selectedStatus = statusList?.filter((item) => item.isSelected)?.map((item) => item.value);
          getAnalytics({
            page,
            size,
            selectedFormIds,
            selectedUserIds,
            selectedSiteIds,
            selectedStatus,
            startDate: selectedDate.startDate,
            endDate: selectedDate.endDate,
            selectedDate: selectedValue,
          });
        });
      }, 200);
    };
  })();

  // report table handler

  const reportTableHandler = (dataToUpdate: number[]) => {
    updateAnalticsUser(dataToUpdate);
  };
  let img = useRef<any>(null);
  let content: HTMLElement = document.querySelector("#content")!;
  useEffect(() => {
    html2canvas(content).then((canvas) => {
      img.current = canvas.toDataURL();
      if (img !== undefined) {
        setscreenshotLoading(false);
      }
    });

    console.log("i ran for screenshot");
    // eslint-disable-next-line
  }, [analyticsReports, TEXT_DIRECTION() === "rtl", TEXT_DIRECTION() == "ltr", screenshotLoading]);
  console.log(screenshotLoading, "i ran for screenshot loading");
  const handleGeneratePDF = (tableHeaderState: any[]) => {
    const selectedSiteIds = sitesList?.filter((item: GenaricListDTO) => item.isSelected).map((item: GenaricListDTO) => item.id);
    const selectedUserIds = usersList?.filter((item: GenaricListDTO) => item.isSelected)?.map((item: GenaricListDTO) => item.id);
    const selectedFormIds = formsList?.filter((item: GenaricListDTO) => item.isSelected)?.map((item: GenaricListDTO) => item.id);
    const selectedStatus = statusList?.filter((item) => item.isSelected)?.map((item) => item.value);

    getAllAnalytics({
      selectedFormIds,
      selectedUserIds,
      selectedSiteIds,
      selectedStatus,
      startDate: selectedDate.startDate,
      endDate: selectedDate.endDate,
    }).then((res: any) => {
      let allReports = res?.value?.data;
      const doc = new jsPDF() as jsPDFCustom as any;
      const total: number = allReports?.length;
      const pages: number = Math.ceil(total / size);

      html2canvas(content).then((canvas) => {
        img.current = canvas.toDataURL();
      });

      for (let page = 0; page < pages; page++) {
        if (page > 0) {
          doc.addPage();
        }

        if (img && page === 0) {
          doc.addImage(img.current, "PNG", 2, 2, 205, 30);
        }
        const startIndex: number = page * size;
        const endIndex: number = Math.min(startIndex + size, total);
        const currentReports: any[] = allReports.slice(startIndex, endIndex);

        TEXT_DIRECTION() === "ltr"
          ? autoTable(doc, {
              startY: page === 0 ? 40 : 10,
              head: [tableHeaderState.map((item) => item.name)],
              body: currentReports.map((report) => [
                `${report?.site.name} - ${report?.assignee.fullName} - ${moment(report?.updatedAt).format("DD-MMM-YYYY")}`,
                moment(report?.updatedAt).format("DD-MMM-YYYY"),
                report?.formDetail?.title,
                report?.assignee.fullName,
                report.site?.name,
                report?.failItem > 0 ? report?.failItem : 0,
                report?.score !== 0 ? report.score : "-",
                report?.answered !== 0 ? report.answered : "-",
                translateStatusForPDF(report?.status),
              ]),

              theme: "plain",
              styles: {
                fontStyle: "normal",
                fontSize: 6,
                textColor: 51,
                overflow: "linebreak",
                font: "helvetica",
              },
              headStyles: {
                fontSize: 8,
                fontStyle: "bold",
                textColor: 116,
                halign: "left",
                font: "helvetica",
              },

              didParseCell: (data) => {
                if (data.column.index === 8) {
                  if (data.cell.raw === i18n("Analytics.Submitted")) {
                    data.cell.styles.textColor = [24, 91, 147];
                  }
                  if (data.cell.raw === i18n("Analytics.Draft")) {
                    data.cell.styles.textColor = [116, 116, 116];
                  }
                  if (data.cell.raw === i18n("Analytics.Complete")) {
                    data.cell.styles.textColor = [14, 103, 36];
                  }
                  if (data.cell.raw === i18n("Analytics.Incomplete")) {
                    data.cell.styles.textColor = [150, 109, 5];
                  }
                  if (data.cell.raw === i18n("Analytics.Rejected")) {
                    data.cell.styles.textColor = [175, 27, 27];
                  }
                  if (data.cell.raw === i18n("Analytics.Resubmitted")) {
                    data.cell.styles.textColor = [24, 91, 147];
                  }
                }
              },

              useCss: true,
              bodyStyles: {
                overflow: "linebreak",
                minCellHeight: 10,
                valign: "middle",
              },
              margin: { left: 5, right: 5 },

              footStyles: { fontSize: 5, textColor: 33 },
              columnStyles: {
                0: { overflow: "linebreak", cellWidth: "auto" },
                1: { overflow: "linebreak", cellWidth: "auto" },
                2: { overflow: "linebreak", cellWidth: "auto" },
                3: { overflow: "linebreak", cellWidth: "auto" },
                4: { overflow: "linebreak", cellWidth: "auto" },
                5: { overflow: "linebreak", cellWidth: "auto" },
                6: { overflow: "linebreak", cellWidth: "auto" },
                7: { overflow: "linebreak", cellWidth: "auto" },
                8: { overflow: "linebreak", cellWidth: "auto" },
              },
              willDrawCell: function (data) {
                // add borders around the head cells
                if (data.row.section === "head" || data.row.section === "body") {
                  doc.setDrawColor(234, 234, 234); // set the border color
                  doc.setLineWidth(0.5); // set the border with

                  // draw bottom border
                  doc.line(data.cell.x, data.cell.y + data.cell.height, data.cell.x + data.cell.width, data.cell.y + data.cell.height);
                }
              },
            })
          : autoTable(doc, {
              startY: page === 0 ? 40 : 10, // Set startY for the table content
              head: [tableHeaderState.map((item) => item.name)],
              body: currentReports.map((report) => [
                translateStatusForPDF(report?.status),
                report?.answered !== 0 ? report.answered : "-",
                report?.score !== 0 ? report.score : "-",
                report?.failItem > 0 ? report?.failItem : 0,
                report.site?.name,
                report?.assignee.fullName,
                report?.formDetail?.title,
                moment(report?.updatedAt).format("DD-MMM-YYYY"),
                `${report?.site.name} - ${report?.assignee.fullName} - ${moment(report?.updatedAt).format("DD-MMM-YYYY")}`,
              ]),

              theme: "plain",
              styles: {
                fontStyle: "normal",
                fontSize: 6,
                textColor: 51,
                overflow: "linebreak",
                font: "Amiri-Regular",
                halign: "right",
              },
              headStyles: {
                fontSize: 8,
                fontStyle: "bold",
                textColor: 116,
                halign: "right",
                font: "Amiri-Regular",
                overflow: "linebreak",
              },

              didParseCell: (data) => {
                if (data.column.index === 0) {
                  if (data.cell.raw === i18n("Analytics.Submitted")) {
                    data.cell.styles.textColor = [24, 91, 147];
                  }
                  if (data.cell.raw === i18n("Analytics.Draft")) {
                    data.cell.styles.textColor = [116, 116, 116];
                  }
                  if (data.cell.raw === i18n("Analytics.Complete")) {
                    data.cell.styles.textColor = [14, 103, 36];
                  }
                  if (data.cell.raw === i18n("Analytics.Incomplete")) {
                    data.cell.styles.textColor = [150, 109, 5];
                  }
                  if (data.cell.raw === "مرفوض") {
                    data.cell.styles.textColor = [175, 27, 27];
                  }
                  if (data.cell.raw === i18n("Analytics.Resubmitted")) {
                    data.cell.styles.textColor = [24, 91, 147];
                  }
                }
              },

              useCss: true,
              bodyStyles: {
                overflow: "linebreak",
                minCellHeight: 10,
                valign: "middle",
              },
              margin: { left: 5, right: 5 },

              footStyles: { fontSize: 5, textColor: 33 },
              columnStyles: {
                0: { overflow: "linebreak", cellWidth: "auto" },
                1: { overflow: "linebreak", cellWidth: "auto" },
                2: { overflow: "linebreak", cellWidth: "auto" },
                3: { overflow: "linebreak", cellWidth: "auto" },
                4: { overflow: "linebreak", cellWidth: "auto" },
                5: { overflow: "linebreak", cellWidth: "auto" },
                6: { overflow: "linebreak", cellWidth: "auto" },
                7: { overflow: "linebreak", cellWidth: "auto" },
                8: { overflow: "linebreak", cellWidth: "auto" },
              },
              willDrawCell: function (data) {
                // add borders around the head cells
                if (data.row.section === "head" || data.row.section === "body") {
                  doc.setDrawColor(234, 234, 234); // set the border color
                  doc.setLineWidth(0.5); // set the border with

                  // draw bottom border
                  doc.line(data.cell.x, data.cell.y + data.cell.height, data.cell.x + data.cell.width, data.cell.y + data.cell.height);
                }
              },
            });

        // Add pagination information

        doc.setFontSize(7);
        doc.setTextColor(33);
        doc.text(`${page + 1}/${pages}`, 5, doc.internal.pageSize.height - 10);

        doc.setFontSize(7);
        doc.setTextColor(33);
        doc.text(
          `(${moment(selectedDate.startDate).format("MMM DD,YYYY")} - ${moment(selectedDate.endDate).format("MMM DD,YYYY")})`,
          175,
          doc.internal.pageSize.height - 10
        );
      }
      // Save the PDF
      if (img !== undefined && !screenshotLoading) {
        doc.save(`analytics_report.pdf`);
      }
    });
  };

  return (
    <div>
      <div
        className="date-header"
        style={{
          backgroundColor: "#ffffff",
          alignItems: "end",
          textAlign: "right",
          padding: "2px 26px 0 26px",
          marginTop: "-7px",
          width: "100%",
        }}
      >
        <p
          style={{
            fontSize: "13px",
            lineHeight: "36px",
            color: "#212121",
            textAlign: "end",
            letterSpacing: ".14px",
          }}
          className="m-0 p-0"
        >{`${formValues.day} (${selectedDate.startDate} - ${selectedDate.endDate})`}</p>
      </div>
      <div className="main-page">
        <div className="row mr-0 mt-4 ml-2">
          <div className="col-2" style={{ maxWidth: "270px", padding: "0" }}>
            <FilterForm
              selectedDate={selectedDate}
              setselectedValue={setselectedValue}
              selectedValue={selectedValue}
              sitesList={sitesList}
              setSitesList={setSitesList}
              usersList={usersList}
              setUsersList={setUsersList}
              formsList={formsList}
              statusList={statusList}
              setstatusList={setstatusList}
              setFormsList={setFormsList}
              setSelectedDate={setSelectedDate}
              formValues={formValues}
              setFormValues={setFormValues}
              isDeletedItemChecked={isDeletedItemChecked}
              setIsDeletedItemChecked={setIsDeletedItemChecked}
              formHandleSelectAll={formHandleSelectAll}
              userHandleSelectAll={userHandleSelectAll}
              sitesHandleSelectAll={sitesHandleSelectAll}
              statusHandleSelectAll={statusHandleSelectAll}
              formHandleSelectItem={formHandleSelectItem}
              sitesHandleSelectItem={sitesHandleSelectItem}
              userHandleSelectItem={userHandleSelectItem}
              statusHandleSelectItem={statusHandleSelectItem}
              formHandleSearch={formHandleSearch}
              userHandleSearch={userHandlerSearch}
              siteHandleSearch={siteHandlerSearch}
            />
          </div>
          <div className="chart-table col-10">
            <div className="row" id="content">
              {!chartsLoading ? (
                analyticsCharts?.map((item, index) => {
                  return (
                    <div className="col-lg-3">
                      {!chartsLoading ? (
                        <CustomDummyChart
                          key={index}
                          headData={item}
                          analyticsXaxisData={analyticsXaxisData}
                          handleClick={() => {
                            handleClick(item);
                          }}
                          totalResult={totalResult}
                          className={item.title === selectedItem?.title ? true : false}
                        />
                      ) : (
                        <div className="bg-white circle p-2">
                          <div className="d-flex justify-content-between align-items-center">
                            <Skeleton width={100} />
                            <Skeleton width={100} />
                          </div>
                          <Skeleton count={4} />
                        </div>
                      )}
                    </div>
                  );
                })
              ) : (
                <>
                  {" "}
                  {[1, 2, 3, 4].map((item) => {
                    return (
                      <div className="col-lg-3">
                        <div className="bg-white circle p-2">
                          <div className="d-flex justify-content-between align-items-center">
                            <Skeleton width={100} />
                            <Skeleton width={100} />
                          </div>
                          <Skeleton count={4} />
                        </div>
                      </div>
                    );
                  })}
                </>
              )}
            </div>
            {!chartsLoading ? (
              <SingleDummyChart
                analyticsXaxisData={analyticsXaxisData}
                selectedItem={{ ...selectedItem, width: "100%", height: 210 }}
                className="w-100 shadow-lg mt-3 "
                totalResult={totalResult}
              />
            ) : (
              <div className="bg-white p-2 px-5 circle w-100 shadow-lg mt-3" style={{ height: 210, width: "100%" }}>
                <div className="d-flex justify-content-between mb-3   align-items-center">
                  <Skeleton width={100} height={30} />
                  <Skeleton width={100} height={30} />
                </div>
                <Skeleton count={6} />
              </div>
            )}

            <div className="mt-3">
              <Table
                analyticsReports={analyticsReports}
                user={user}
                reportTableHandler={reportTableHandler}
                setpage={setpage}
                page={page}
                size={size}
                setSize={setSize}
                total={total}
                totalResult={totalResult}
                handleGeneratePDF={handleGeneratePDF}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ authentication, analytics }: any) => ({
  user: authentication.user,
  analyticUsers: analytics.analyticUsers,
  analyticSites: analytics.analyticSites,
  analyticForms: analytics.analyticForms,
  analytics: analytics.analytics,
});

const mapDispatchToProps = {
  getAnalytics,
  getSession,
  getAnalyticsForms,
  getAnalyticsSites,
  getAnalyticsUsers,
  updateAnalticsUser,
  getAllAnalytics,
};

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsMain);
