import React, { useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const PieChart = ({
  failItems,
  totalQuestion,
  setPieChartFail,
  setPieChartSuccess,
}) => {
  const failPercentage = (failItems / totalQuestion) * 100;
  const successPercentage = 100 - failPercentage;
  useEffect(() => {
    setPieChartFail(failPercentage);
    setPieChartSuccess(successPercentage);
    // eslint-disable-next-line
  }, []);

  let data = {
    series: [successPercentage, failPercentage],
    options: {
      tooltip: {
        enabled: false,
      },
      grid: {
        borderColor: "#EF3252",
      },
      stroke: {
        colors: ["#4F4F4F"],
        width: "1",
      },
      plotOptions: {
        pie: {
          size: "45%",
          expandOnClick: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#FADA79", `${failItems > 0 ? "#fc3535" : null}`],
      chart: {
        width: 230,
        type: "pie",
        events: {
          animationEnd: function (ctx) {
            ctx.toggleDataPointSelection(1);
          },
        },
      },
      legend: {
        show: false,
      },
    },
  };
  return (
    <>
      <div id="chart">
        <ReactApexChart
          options={data.options}
          series={data.series}
          type="pie"
          width={230}
        />
      </div>
    </>
  );
};

export default PieChart;
