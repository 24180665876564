import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import I18n, { i18n } from "../../../languageSelector";
import { isValidPhoneNumber } from "react-phone-number-input";
import IconCopy from "../../../../../assets/images/icon-copy.svg";
import TextArea from "../../../../../components/form-input/text-Area";
import IconPreview from "../../../../../assets/images/icon-preview.svg";
import Radio from "../../../../../components/form-input/radio.component";
import Input from "../../../../../components/form-input/input.component";
import IconPdfDownload from "../../../../../assets/images/icon-pdf-download.svg";
import IconWordDownload from "../../../../../assets/images/icon-word-download.png";
import PhoneNumber from "../../../../../components/form-input/phone-number.component";
import {
  EMAIL_REGEX,
  MIN_LENGTH,
  MAX_LENGTH,
} from "../../../../../config/validations";
import {
  getReportById,
  sendPdfReport,
} from "../../../../../redux/reducers/admin-manager-reports/admin-manager-report.reducer";
import DynamicModal from "../../../../../components/dialogs";
import {
  getReportByIdForShareData,
  getReportBySettingsId,
  getReportSettings,
} from "../../../../../redux/reducers/admin-manager-reports/admin-manager-report.reducer";
import {
  TEXT_DIRECTION,
  formAllAnswerdDetails,
  numberFailHandler,
  reportThemeColor,
} from "../../../../../helper/helperFunctions";
import { generatePDFReport } from "../../../../../helper/jsreport-helper";
import moment from "moment";

const initState = {
  email: "",
  mobile: "",
  message: "",
  subject: "",
};

const MyReportsShare = ({
  // URL,
  user,
  reportId,
  getReportByIdForShareData,
  getReportBySettingsId,
  getReportSettings,
  sendPdfReport,
  getReportById,
  reportForEdit,
}) => {
  const { id } = useParams();
  const [radioButtonsValue, setRadioButtonsValue] = useState({
    shareViaEmail: false,
    shareViaSMS: false,
    shareAsWord: false,
    shareAsPDF: true,
  });
  const [openReportSentDialog, setOpenReportSentDialog] = useState(false);
  const [failItem, setFailItem] = useState(0);
  const [reportInspectionNumber, setReportInspectionNumber] = useState(0);
  const [reportTheme, setReportTheme] = useState("32, 99, 155");
  const [pieChartFail, setPieChartFail] = useState();
  const [pieChartSuccess, setPieChartSuccess] = useState();
  const [questionScore, setQuestionScore] = useState(0);
  const [totalPercentage, setTotalPercentage] = useState(0);
  const [totalQuestion, setTotalQuestion] = useState(0);
  const [answeredScore, setAnsweredScore] = useState(0);
  const [answeredPercent, setAnsweredPercent] = useState();
  const [settingsId, setSettingsId] = useState();

  // const location = useLocation();
  let language = localStorage.getItem("language");

  const copyToClipboard = () => {
    var textBox = document.getElementById("pdf-url");
    textBox.select();
    document.execCommand("copy");
    toast.success("Link Copied!");
  };

  const env = process.env.REACT_APP_URL;
  const trimmedEnv = env.trim().replace(/\/$/, "");
  const URL = `${trimmedEnv}/report-pdf-view/${settingsId}`;

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm({
    defaultValues: initState,
  });

  useEffect(() => {
    if (id) {
      getReportSettings(id, user.id).then((res) => {
        setSettingsId(res?.value?.data?.id);
      });
    }
    // eslint-disable-next-line
  }, [id]);
  useEffect(() => {
    if (!radioButtonsValue.shareViaEmail && !radioButtonsValue.shareViaSMS) {
      reset();
    }
    // eslint-disable-next-line
  }, [radioButtonsValue]);

  useEffect(() => {
    var str = "" + reportForEdit?.dispatchNumber;
    var pad = "0000";
    var ans = pad.substring(0, pad.length - str.length) + str;
    setReportInspectionNumber(ans);
  }, [reportForEdit?.dispatchNumber]);

  useEffect(() => {
    if (reportForEdit) {
      const themeData = reportThemeColor(
        reportForEdit?.company?.reportColor?.label
      );
      if (themeData) {
        setReportTheme(themeData);
      }
    }
    // eslint-disable-next-line
  }, [reportForEdit]);

  // const url = location.pathname;
  const mergedUrl = URL;

  useEffect(() => {
    let multiQuestionsWithIsFail = [];
    let singleQuestionsWithIsFail = [];
    let failItem = 0;
    let answerScore = 0;
    let questionScore = 0;
    let totalQuestions = 0;

    if (reportForEdit) {
      reportForEdit?.formDetail?.schema?.pages.map((item) =>
        // eslint-disable-next-line
        item.sections.map((secItem) => {
          let questions = secItem?.questions?.filter(
            (item) => item.type !== "instructions"
          );
          totalQuestions += questions.length;

          // eslint-disable-next-line
          secItem.questions
            .filter(
              (item) =>
                item.type === "COMPLEX_MULTIPLE_CHOICE" ||
                item.type === "checkbox" ||
                item.type === "slider" ||
                item.type === "number"
            ) // eslint-disable-next-line
            .map((question) => {
              if (question) {
                question?.settings?.score !== "-"
                  ? (questionScore =
                      questionScore + Number(question?.settings?.score))
                  : (questionScore = 0);
              }
              let answer = reportForEdit.formAnswerDetail.find(
                (item) => item?.questionUuid === question?.questionUuid
              );

              if (answer && question.type === "COMPLEX_MULTIPLE_CHOICE") {
                if (
                  question?.settings?.answers[answer?.selected[0]?.index]
                    ?.score === "-" &&
                  question?.settings?.selectionType !== "MULTIPLE"
                ) {
                  questionScore = questionScore - question.settings.score;
                }
                answer?.selected
                  .filter(
                    (item) =>
                      question.settings.answers[item.index].score !== "-"
                  )
                  // eslint-disable-next-line
                  .map((select) => {
                    answerScore =
                      answerScore +
                      Number(question.settings.answers[select.index].score);
                  });
              } else if (answer && question.type === "checkbox") {
                answer?.value === true
                  ? (answerScore =
                      answerScore + Number(question.settings.score))
                  : (answerScore = answerScore + 0);
              } else if (answer && question.type === "slider") {
                let sliderMaxValue = question.settings.max;

                answer?.sliderValue !== null || answer?.sliderValue !== ""
                  ? (answerScore =
                      answerScore +
                      (Number(answer?.sliderValue) / Number(sliderMaxValue)) *
                        Number(question.settings.score))
                  : (answerScore = answerScore + 0);
              } else if (answer && question.type === "number") {
                let isFail = numberFailHandler(question, answer?.value);
                if (isFail) {
                  failItem += 1;
                  setFailItem(
                    failItem +
                      multiQuestionsWithIsFail.length +
                      singleQuestionsWithIsFail?.length
                  );
                }
              }
              if (answer && question?.settings?.selectionType === "MULTIPLE") {
                const hasFailSelected = answer?.selected?.some(
                  (item) => question.settings.answers[item.index].isFail
                );

                if (hasFailSelected) {
                  multiQuestionsWithIsFail.push(question);
                  setFailItem(
                    failItem +
                      multiQuestionsWithIsFail.length +
                      singleQuestionsWithIsFail?.length
                  );
                }
              } else {
                const singleFailSelected = answer?.selected?.some(
                  (item) => question.settings.answers[item.index].isFail
                );

                if (singleFailSelected) {
                  singleQuestionsWithIsFail.push(question);
                  setFailItem(
                    failItem +
                      multiQuestionsWithIsFail.length +
                      singleQuestionsWithIsFail?.length
                  );
                }
              }
            });
        })
      );
      setQuestionScore(questionScore);
      setAnsweredScore(answerScore);
      setTotalQuestion(totalQuestions);
      setTotalPercentage(
        isNaN((answerScore / questionScore) * 100)
          ? 0
          : (answerScore / questionScore) * 100
      );
      let failPieChart = (failItem / totalQuestions) * 100;
      setPieChartFail(failPieChart);
      const answeredPercentage =
        (reportForEdit?.formAnswerDetail?.length * 100) / totalQuestions;
      const answeredPercentageResult = parseFloat(
        answeredPercentage.toFixed(15)
      );
      if (answeredPercentageResult) {
        setAnsweredPercent(answeredPercentageResult);
      }
      setPieChartSuccess(100 - failPieChart);
    }
  }, [reportForEdit]);
  const downloadReport = async (settingsId) => {
    const res = await getReportBySettingsId(settingsId);
    const formAnswerDetail = await formAllAnswerdDetails(
      res?.value?.data?.report
    );

    generatePDFReport(
      reportForEdit.formDetail.title,
      {
        ...res?.value?.data?.report,
        formAnswerDetail,
        hideAndShow: res?.value?.data?.userReportSetting,
        reportInspectionNumber,
        startAt: moment(reportForEdit?.startAt).format(
          "dddd DD MMM YYYY hh:mm a"
        ),
        primaryColor: reportTheme,
        pieChartFail: pieChartFail,
        pieChartSuccess: pieChartSuccess,
        failedQuestions: failItem,
        totalPercentage: totalPercentage,
        answeredPercent: answeredPercent,
        questionScore: questionScore,
        answeredScore: answeredScore,
        totalQuestions: totalQuestion,
        answeredQuestions: reportForEdit?.formAnswerDetail?.length,
        url: mergedUrl + "/view",
      },
      language === "ENGLISH" ? "EN" : "AR",
      "DOWNLOAD"
    );
  };

  const onSubmit = (data) => {
    if (
      !radioButtonsValue.shareAsPDF &&
      !radioButtonsValue.shareAsWord &&
      !radioButtonsValue.shareViaEmail &&
      !radioButtonsValue.shareViaSMS
    ) {
      toast.error(i18n("Notifications.SelectOneScenario"));
    } else if (
      !radioButtonsValue.shareViaEmail &&
      !radioButtonsValue.shareViaSMS
    ) {
      toast.error(i18n("Notifications.SelectOneWay"));
    } else if (
      !radioButtonsValue.shareAsPDF &&
      !radioButtonsValue.shareAsWord
    ) {
      toast.error(i18n("Notifications.SelectOneFormat"));
    } else if (
      radioButtonsValue.shareViaEmail &&
      radioButtonsValue.shareAsPDF &&
      !radioButtonsValue.shareAsWord &&
      !radioButtonsValue.shareViaSMS
    ) {
      sendPdfReport({
        email: data.email,
        link: URL,
        message: data.message,
        subject: data.subject,
        reportId: id,
        mobile: "",
        shareObject: {
          shareViaEmail: radioButtonsValue.shareViaEmail,
          shareAsPDF: radioButtonsValue.shareAsPDF,
        },
      }).then(() => {
        toast.success(i18n("Notifications.PdfFormatSentByEmail"));
        reset();
        getReportById(id);
        setOpenReportSentDialog(true);
      });
    } else if (
      radioButtonsValue.shareViaEmail &&
      !radioButtonsValue.shareAsPDF &&
      radioButtonsValue.shareAsWord &&
      !radioButtonsValue.shareViaSMS
    ) {
      sendPdfReport({
        email: data.email,
        reportId: id,
        link: URL,
        message: data.message,
        subject: data.subject,
        mobile: "",
        shareObject: {
          shareViaEmail: radioButtonsValue.shareViaEmail,
          shareAsWord: radioButtonsValue.shareAsWord,
        },
      }).then(() => {
        toast.success(i18n("Notifications.WordFormatSentByEmail"));
        reset();
        setRadioButtonsValue({ ...initState });
        getReportById(id);
      });
    } else if (
      !radioButtonsValue.shareViaEmail &&
      radioButtonsValue.shareAsPDF &&
      !radioButtonsValue.shareAsWord &&
      radioButtonsValue.shareViaSMS
    ) {
      sendPdfReport({
        mobile: data.mobile,
        link: URL,
        email: "",
        reportId: id,
        message: data.message,
        subject: data.subject,
        shareObject: {
          shareViaSMS: radioButtonsValue.shareViaSMS,
          shareAsPDF: radioButtonsValue.shareAsPDF,
        },
      }).then(() => {
        toast.success(i18n("Notifications.PdfFormatSentBySMS"));
        reset();
        setRadioButtonsValue({ ...initState });
        getReportById(id);
      });
    } else if (
      !radioButtonsValue.shareViaEmail &&
      !radioButtonsValue.shareAsPDF &&
      radioButtonsValue.shareAsWord &&
      radioButtonsValue.shareViaSMS
    ) {
      sendPdfReport({
        email: "",
        mobile: data.mobile,
        link: URL,
        reportId: id,
        message: data.message,
        subject: data.subject,
        shareObject: {
          shareViaSMS: radioButtonsValue.shareViaSMS,
          shareAsWord: radioButtonsValue.shareAsWord,
        },
      }).then((response) => {
        toast.success(i18n("Notifications.WordFormatSentBySMS"));
        reset();
        setRadioButtonsValue({ ...initState });
        getReportById(id);
      });
    }
  };

  useEffect(() => {
    if (user && user.company) {
      if (radioButtonsValue.shareViaEmail) {
        setValue("message", user.company.emailMessage);
        setValue("subject", user.company.subject);
      }
      if (radioButtonsValue.shareViaSMS) {
        setValue("message", user.company.smsMessage);
      }
    }
    // eslint-disable-next-line
  }, [user, radioButtonsValue]);

  return (
    <>
      <div className="search-bar">
        <div className="form-search">
          <div className="input-group pr-0">
            <input
              id="pdf-url"
              className="form-control"
              placeholder="www.tamam.com/l/1769"
              value={`${mergedUrl + "/admin"}`}
              readOnly={true}
            />
            <div className="input-group-append">
              <Link to="#" onClick={copyToClipboard} className="p-0">
                <img src={IconCopy} alt="IconFilter" />
              </Link>
            </div>
          </div>
        </div>

        <Link
          to="#"
          onClick={() => {
            if (settingsId) {
              window.open(`/report-pdf-view/${settingsId}/view`);
            } else {
              toast(i18n("global.PleaseWait"));
            }
          }}
        >
          <img src={IconPreview} alt="IconSortAlpha" />
        </Link>
        <Link
          to="#"
          onClick={() => {
            if (reportId) {
              getReportBySettingsId(settingsId, "view")
                .then((res) => {
                  downloadReport(settingsId);
                })
                .catch((err) => {
                  if (err) {
                    toast.error("An error Occured");
                  }
                });
            }
          }}
        >
          <img src={IconPdfDownload} alt="loading" />
        </Link>
        <Link
          to="#"
          onClick={() => {
            if (reportId) {
              window.open(`/report-pdf-view/${settingsId}/admin`, "_self");
              setRadioButtonsValue({
                ...radioButtonsValue,
                shareAsWord: true,
                shareAsPDF: false,
              });
            } else {
              toast(i18n("global.PleaseWait"));
            }
          }}
        >
          <img width={22} src={IconWordDownload} alt="IconFilter" />
        </Link>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-5 mb-2">
          <div className="px-3 row">
            <label
              className={`px-1 mt-1 ${
                TEXT_DIRECTION() === "rtl" ? "col-3" : "col-3"
              }`}
            >
              <I18n lng="Reports.ShareVia" />
            </label>
            <div
              className={`${TEXT_DIRECTION() === "rtl" ? "col-4" : "col-4"}`}
            >
              <div className="row">
                <div
                  className={`${
                    TEXT_DIRECTION() === "rtl" ? "" : "col-1 pl-1"
                  }`}
                >
                  <Radio
                    id="shareViaEmail"
                    checked={radioButtonsValue.shareViaEmail}
                    onClick={(e) => {
                      setRadioButtonsValue({
                        ...radioButtonsValue,
                        shareViaEmail: radioButtonsValue.shareViaEmail
                          ? false
                          : true,
                        shareViaSMS: false,
                      });
                    }}
                  />
                </div>
                <div
                  style={{ color: "#20639b" }}
                  className={`${
                    TEXT_DIRECTION() === "rtl" ? "col-7 w-100" : "col-7 pl-3"
                  }`}
                >
                  <label
                    className="mb-0"
                    style={{
                      color: "#20639b",
                      width: "80px",
                      marginTop: "3px",
                    }}
                  >
                    {i18n("global.Email")}
                  </label>
                </div>
              </div>
            </div>
            <div
              className={`${TEXT_DIRECTION() === "rtl" ? "col-5" : "col-4"}`}
            >
              <div className="row w-100">
                <div
                  className={`${
                    TEXT_DIRECTION() === "rtl" ? "" : "col-1 pl-1"
                  }`}
                >
                  <Radio
                    id="shareViaSms"
                    checked={radioButtonsValue.shareViaSMS}
                    onClick={(e) => {
                      setRadioButtonsValue({
                        ...radioButtonsValue,
                        shareViaSMS: radioButtonsValue.shareViaSMS
                          ? false
                          : true,
                        shareViaEmail: false,
                      });
                    }}
                  />
                </div>
                <div
                  style={{ color: "#20639b" }}
                  className={`${
                    TEXT_DIRECTION() === "rtl" ? "col-8" : "col-7 pl-3"
                  }`}
                >
                  <label
                    className="mb-0"
                    style={{
                      color: "#20639b",
                      width: "100px",
                      marginTop: "3px",
                    }}
                  >
                    {i18n("global.SMS")}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        {radioButtonsValue.shareViaEmail && (
          <Input
            name="email"
            type="text"
            label={i18n("global.Email")}
            register={register}
            placeholder={i18n("global.Email")}
            validations={{
              required: {
                value: true,
                message: i18n("validation.REQUIRED_FIELD"),
              },
              minLength: { value: 3, message: MIN_LENGTH(3) },
              maxLength: { value: 50, message: MAX_LENGTH(50) },
              pattern: {
                value: EMAIL_REGEX,
                message: i18n("validation.INVALID_EMAIL"),
              },
            }}
            error={errors["email"]}
          />
        )}
        {radioButtonsValue.shareViaSMS && (
          <PhoneNumber
            label={<I18n lng="global.Mobile" />}
            name={"mobile"}
            id={"mobileInput"}
            register={register}
            control={control}
            validations={{
              required: {
                value: true,
                message: i18n("validation.REQUIRED_FIELD"),
              },
              validate: isValidPhoneNumber,
            }}
            error={errors["mobile"]}
          />
        )}
        {radioButtonsValue.shareViaEmail && (
          <Input
            name="subject"
            type="text"
            label="Subject"
            register={register}
            placeholder={i18n("global.WriteTheSubject")}
            validations={{
              minLength: { value: 3, message: MIN_LENGTH(3) },
              maxLength: { value: 50, message: MAX_LENGTH(50) },
            }}
            error={errors["subject"]}
          />
        )}
        <TextArea
          label={i18n("global.Message")}
          name="message"
          placeholder={i18n("Reports.WriteTextEmail")}
          rows="6"
          register={register}
          validations={{
            minLength: { value: 3, message: MIN_LENGTH(3) },
          }}
          error={errors["message"]}
        />
        <div className="btn-bar mt-5">
          <button className="btn btn-primary" type="submit">
            <I18n lng="Reports.Send" />
          </button>
        </div>
      </form>
      <DynamicModal
        customClasses="modal-drawer modal-report-sh"
        show={openReportSentDialog}
        secondaryAction={() => setOpenReportSentDialog(false)}
      >
        <div className="text-center mt-5">
          <h5>
            <I18n lng="Reports.ReportSent" />
          </h5>
        </div>
        <div className="text-center mt-5 ">
          <span style={{ color: "#3CAEA3" }}>
            <I18n lng="Reports.TheReportWasSentSuccessfully" />
          </span>
        </div>
        <div className="text-center mt-5">
          <Button
            color="primary"
            onClick={() => {
              setOpenReportSentDialog(false);
            }}
          >
            <I18n lng="Reports.Back" />
          </Button>
        </div>
      </DynamicModal>
    </>
  );
};
const mapStateToProps = ({ adminManagerReports, authentication }) => ({
  user: authentication.user,
  currentRole: authentication.currentRole,
  allReports: adminManagerReports.allReports,
  reportForEdit: adminManagerReports.reportForEdit,
});

const mapDispatchToProps = {
  getReportByIdForShareData,
  getReportBySettingsId,
  getReportSettings,
  sendPdfReport,
  getReportById,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyReportsShare);
