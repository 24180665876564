import React, { useState, useEffect } from "react";
import { Collapse } from "reactstrap";
import { Link } from "react-router-dom";
import { useSwipeable } from "react-swipeable";
import I18n, { i18n } from "../../../languageSelector";
import FormBuilderSection from "./form-builder-section";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { TEXT_DIRECTION } from "../../../../../helper/helperFunctions";
import IconDraggable from "../../../../../assets/images/icon-draggable.svg";
import IconCollapseArrow from "../../../../../assets/images/icon-collapse-arrow.svg";

const FormBuilderPage = ({
  page,
  pageIndex,
  setShowResponseListDrawer,
  showResponseListDrawer,
  setShowQuestionOptionsDrawer,
  setActionItem,
  sections = [],
  isScored,
  deletePage,
  deleteSection,
  deleteQuestion,
  updateQuestion,
  updatePage,
  updateSection,
  selectedElement,
  setSelectedElement,
  closeAll,
  form,
  setMcqID,
  addQuestion,
  updateMultipleChoiceById,
  getAllMultipleChoiceQuestions,
  formId,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showItemActions, setShowItemActions] = useState(false);
  const [pageText, setPageText] = useState(`Page ${pageIndex + 1}`);
  const [isPageTextEditable, setIsPageTextEditable] = useState(false);
  const [isSlideToDeleteDisabled, setIsSlideToDeleteDisabled] = useState(false);

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      setShowItemActions(TEXT_DIRECTION() === "rtl" ? false : true);
      setSelectedElement(pageIndex, undefined, undefined, true);
    },
    onSwipedRight: () => {
      setShowItemActions(TEXT_DIRECTION() === "rtl" ? true : false);
      setSelectedElement(pageIndex, undefined, undefined, true);
    },
    onTap: () => {
      setSelectedElement(pageIndex, undefined, undefined, true);
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  useEffect(() => {
    setPageText("");
    if (page) {
      if (page.name !== "Page" && page.name !== "Page ") {
        setPageText(page.name);
      }
      setIsOpen(page.isOpen);
    }
    // eslint-disable-next-line
  }, [page]);

  const openPage = () => {
    updatePage(pageIndex, { ...page, isOpen: true }, true);
  };

  const closePage = () => {
    updatePage(pageIndex, { ...page, isOpen: false }, true);
  };

  useEffect(() => {
    if (
      !selectedElement ||
      selectedElement.pageNo !== pageIndex ||
      selectedElement.sectionNo !== undefined ||
      (selectedElement.questionNo !== undefined && showItemActions)
    ) {
      setShowItemActions(false);
    }
    // eslint-disable-next-line
  }, [selectedElement]);

  return (
    <Draggable draggableId={"page-" + pageIndex} index={pageIndex}>
      {(provided) => (
        <div
          {...provided.draggableProps}
          ref={provided.innerRef}
          className={
            selectedElement &&
            selectedElement.pageNo === pageIndex &&
            selectedElement.sectionNo === undefined &&
            selectedElement.questionNo === undefined
              ? "selected-form-builder-element"
              : ""
          }
        >
          <section className="form-block page">
            <div
              className="form-title"
              {...(isSlideToDeleteDisabled ? {} : handlers)}
            >
              <strong>
                <Link
                  to="#"
                  className={`icon icon-draggable ${
                    TEXT_DIRECTION() === "rtl" ? "mr-0" : ""
                  }`}
                  {...provided.dragHandleProps}
                >
                  <img
                    className="ml-2"
                    src={IconDraggable}
                    alt="IconDraggable"
                  />
                </Link>
                {isPageTextEditable ? (
                  <input
                    autoFocus
                    type="text"
                    className={`mr-1 ${
                      TEXT_DIRECTION() === "rtl" ? "ml-2" : ""
                    }`}
                    id={page?.pageUuid}
                    value={pageText}
                    placeholder={`${i18n("Form.Page")} ${pageIndex + 1}`}
                    onBlur={() => {
                      setIsSlideToDeleteDisabled(false);
                      setIsPageTextEditable(false);
                      if (!pageText) {
                        updatePage(pageIndex, {
                          ...page,
                          name: "Page",
                        });
                        setPageText("Page");
                      } else {
                        updatePage(pageIndex, { ...page, name: pageText });
                      }
                    }}
                    onChange={(e) => {
                      setPageText(e.target.value);
                    }}
                  />
                ) : (
                  <span
                    className={
                      pageText === "Page " + (pageIndex + 1)
                        ? "placeholder"
                        : "dummy"
                    }
                    onClick={() => {
                      if (pageText === "Page ") {
                        setPageText("");
                      }
                      setIsSlideToDeleteDisabled(true);
                      setIsPageTextEditable(true);
                    }}
                  >
                    {page?.name === "Page"
                      ? `${i18n("Form.Page")} ${pageIndex + 1}`
                      : page?.name}
                  </span>
                )}
              </strong>
              {showItemActions &&
              selectedElement &&
              selectedElement.pageNo === pageIndex &&
              selectedElement.sectionNo === undefined &&
              selectedElement.questionNo === undefined ? (
                <div
                  className={`action ${
                    TEXT_DIRECTION() === "rtl"
                      ? "action-delete-left"
                      : "action-delete"
                  }`}
                >
                  <button
                    className=" btn btn-danger"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowItemActions(false);
                      deletePage(pageIndex);
                    }}
                  >
                    <I18n lng="global.Delete" />
                  </button>
                  <button
                    className="ml-3 mr-3 btn btn-secondary"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowItemActions(false);
                    }}
                  >
                    <I18n lng="global.Cancel" />
                  </button>
                </div>
              ) : null}
              {page?.sections.length ? (
                <Link
                  to="#"
                  className="icon icon-collapse"
                  id={page?.name + pageIndex}
                  onClick={() => {
                    if (isOpen) {
                      closePage();
                    } else {
                      openPage();
                    }
                  }}
                >
                  <img src={IconCollapseArrow} alt="IconCollapseArrow" />
                </Link>
              ) : null}
            </div>
            {page?.sections.length ? (
              <Collapse toggler={page?.name + pageIndex} isOpen={isOpen}>
                <Droppable
                  droppableId={"page-section-" + pageIndex}
                  type="section"
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      isdraggingover={snapshot.isdraggingover}
                    >
                      {sections.map((item, index) => (
                        <FormBuilderSection
                          section={item}
                          pageIndex={pageIndex}
                          sectionIndex={index}
                          key={index}
                          showResponseListDrawer={showResponseListDrawer}
                          setShowResponseListDrawer={setShowResponseListDrawer}
                          setShowQuestionOptionsDrawer={
                            setShowQuestionOptionsDrawer
                          }
                          setActionItem={setActionItem}
                          questions={item.questions}
                          isScored={isScored}
                          deleteSection={deleteSection}
                          deleteQuestion={deleteQuestion}
                          updateSection={updateSection}
                          updateQuestion={updateQuestion}
                          selectedElement={selectedElement}
                          setSelectedElement={setSelectedElement}
                          closeAll={closeAll}
                          form={form}
                          setMcqID={setMcqID}
                          addQuestion={addQuestion}
                          updateMultipleChoiceById={updateMultipleChoiceById}
                          getAllMultipleChoiceQuestions={
                            getAllMultipleChoiceQuestions
                          }
                          formId={formId}
                        />
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </Collapse>
            ) : null}
          </section>
        </div>
      )}
    </Draggable>
  );
};

export default FormBuilderPage;
