import React, { useEffect, useState } from "react";
import SectionPreview from "./section-preview";
import I18n, { i18n } from "../../../languageSelector";
import { floatOrIntegerNumber } from "../../../../../helper/helperFunctions";

const PagePreview = ({
  page,
  currentPage,
  totalPages,
  isScored,
  filterSectionPage,
  addUpdateAnwser,
  getAnswer,
  view,
  allQuestions,
  totalFilledAnswers,
  totalQuestion,
  currentRole,
  removeFromAnswers,
  totalAnswerScore,
  totalQuestionScore,
  preview,
  questionScorePreview,
  answerScorePreview,
  previewFilledAnswers,
  showFormDetail,
}) => {
  const [isFloatAnswer, setIsFloatAnswer] = useState(false);
  const [isFloatQuestion, setIsFloatQuestion] = useState(false);
  const [isFloatAnswerPreview, setIsFloatAnswerPreview] = useState(false);
  const [isFloatQuestionPreview, setIsFloatQuestionPreview] = useState(false);

  useEffect(() => {
    // Checking for float values during inspection
    setIsFloatQuestion(floatOrIntegerNumber(totalQuestionScore));
    setIsFloatAnswer(floatOrIntegerNumber(totalAnswerScore));

    // Checking for float values during form preview
    setIsFloatQuestionPreview(floatOrIntegerNumber(questionScorePreview));
    setIsFloatAnswerPreview(floatOrIntegerNumber(answerScorePreview));
  }, [
    questionScorePreview,
    answerScorePreview,
    totalAnswerScore,
    totalQuestionScore,
  ]);

  return (
    <>
      <div className="form-title-info px-2  d-inline-block w-100">
        <div className="form-info d-flex">
          <small>
            <strong>
              <I18n lng="Form.Page" />: {currentPage} <I18n lng="Form.Of" />{" "}
              {totalPages}{" "}
            </strong>
          </small>
          {currentRole ? (
            <>
              <small>
                <strong>
                  {isScored && (
                    <span>
                      <I18n lng="Form.Score" />:{" "}
                      {totalAnswerScore.toFixed(isFloatAnswer ? 2 : 0)}/
                      {totalQuestionScore.toFixed(isFloatQuestion ? 2 : 0)} (
                      {!isNaN((totalAnswerScore / totalQuestionScore) * 100)
                        ? (
                            (totalAnswerScore / totalQuestionScore) *
                            100
                          ).toFixed(2)
                        : 0}
                      %)
                    </span>
                  )}
                </strong>
              </small>
              <small>
                <strong>
                  <I18n lng="Form.Answered" />: {totalFilledAnswers}/
                  {totalQuestion}
                </strong>
              </small>{" "}
            </>
          ) : currentRole !== "user" && preview ? (
            <>
              <small>
                <strong>
                  {isScored && (
                    <span>
                      <I18n lng="Form.Score" />:{" "}
                      {answerScorePreview.toFixed(isFloatAnswerPreview ? 2 : 0)}
                      /
                      {questionScorePreview.toFixed(
                        isFloatQuestionPreview ? 2 : 0
                      )}{" "}
                      (
                      {!isNaN((answerScorePreview / questionScorePreview) * 100)
                        ? (
                            (answerScorePreview / questionScorePreview) *
                            100
                          ).toFixed(2)
                        : 0}
                      %)
                    </span>
                  )}
                </strong>
              </small>
              <small>
                <strong>
                  <I18n lng="Form.Answered" />: {previewFilledAnswers}/
                  {totalQuestion}
                </strong>
              </small>
            </>
          ) : null}
        </div>
      </div>

      {/* Showing form page no and annswered and score in header */}
      <div className={`form-detail-header ${showFormDetail ? "show" : "hide"}`}>
        <div className="form-title-info px-2  d-inline-block w-100">
          <div className="form-info d-flex">
            <small>
              <strong>
                <I18n lng="Form.Page" /> {currentPage} <I18n lng="Form.Of" />{" "}
                {totalPages}{" "}
              </strong>
            </small>
            {currentRole ? (
              <>
                <small>
                  <strong>
                    {isScored && (
                      <span>
                        <I18n lng="Form.Score" />:{" "}
                        {totalAnswerScore.toFixed(isFloatAnswer ? 2 : 0)}/
                        {totalQuestionScore.toFixed(isFloatQuestion ? 2 : 0)} (
                        {!isNaN((totalAnswerScore / totalQuestionScore) * 100)
                          ? (
                              (totalAnswerScore / totalQuestionScore) *
                              100
                            ).toFixed(2)
                          : 0}
                        %)
                      </span>
                    )}
                  </strong>
                </small>
                <small>
                  <strong>
                    <I18n lng="Form.Answered" />: {totalFilledAnswers}/
                    {totalQuestion}
                  </strong>
                </small>{" "}
              </>
            ) : currentRole !== "user" && preview ? (
              <>
                <small>
                  <strong>
                    {isScored && (
                      <span>
                        <I18n lng="Form.Score" />:{" "}
                        {answerScorePreview.toFixed(
                          isFloatAnswerPreview ? 2 : 0
                        )}
                        /
                        {questionScorePreview.toFixed(
                          isFloatQuestionPreview ? 2 : 0
                        )}{" "}
                        (
                        {!isNaN(
                          (answerScorePreview / questionScorePreview) * 100
                        )
                          ? (
                              (answerScorePreview / questionScorePreview) *
                              100
                            ).toFixed(2)
                          : 0}
                        %)
                      </span>
                    )}
                  </strong>
                </small>
                <small>
                  <strong>
                    <I18n lng="Form.Answered" />: {previewFilledAnswers}/
                    {totalQuestion}
                  </strong>
                </small>
              </>
            ) : null}
          </div>
        </div>
      </div>
      <div
        className="form-header-title"
        style={{
          marginLeft: "-1.5rem",
          marginRight: "-1.5rem",
          width: "calc(100% + 3rem)",
          padding: ".25rem 2rem",
        }}
      >
        <b className="title">
          {page?.name === "Page"
            ? `${i18n("Form.Page")} ${filterSectionPage + 1}`
            : page?.name}
        </b>
      </div>
      <div className="form-page">
        {page?.sections?.length
          ? page?.sections.map((section, index) => {
              return (
                <SectionPreview
                  key={index}
                  view={view}
                  section={section}
                  getAnswer={getAnswer}
                  currentSection={index + 1}
                  addUpdateAnwser={addUpdateAnwser}
                  removeFromAnswers={removeFromAnswers}
                  allQuestions={allQuestions}
                />
              );
            })
          : null}
      </div>
    </>
  );
};
export default PagePreview;
