import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import FormNavbar from "../form-navbar";
import I18n, { i18n } from "../../languageSelector";
import { Link, useHistory } from "react-router-dom";
import FilterDialog from "../form-management/formFilterDialog";
import ListItem from "./global-list-list-components/list-item";
import IconSearch from "../../../../assets/images/icon-search.svg";
import IconFilter from "../../../../assets/images/icon-filter.svg";
import DialogModal from "../../../../components/dialogs/compact-dialog";
import IconSortAlpha from "../../../../assets/images/icon-sort-alpha.svg";
import { updateRoute } from "../../../../redux/reducers/authentication/authentication";
import { getGlobalLists } from "../../../../redux/reducers/global-list/global-list.reducer";
import {
  FaSortAlphaUp,
  FaSortAlphaDown,
  FaSortNumericUpAlt,
  FaSortNumericDownAlt,
} from "react-icons/fa";
import { TEXT_DIRECTION } from "../../../../helper/helperFunctions";

const globalListInitFilterState = {
  fromDate: "",
  toDate: "",
};

const GlobalListListing = ({
  getGlobalLists,
  globalLists,
  updateRoute,
  currentRole,
}) => {
  const history = useHistory();

  const [searchValue, setSearchValue] = useState("");
  const [showSortModal, setShowSortModal] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [globalListFilterState, setGlobalListFilterState] = useState(
    globalListInitFilterState
  );
  const [sortBy, setSortBy] = useState({
    attribute: "DATE_MODIFIED",
    order: "DESC",
  });

  useEffect(() => {
    if (currentRole !== "user") {
      getGlobalLists(globalListFilterState);
    }
    // eslint-disable-next-line
  }, [currentRole]);

  useEffect(() => {
    updateRoute({
      step1: "Forms",
      step2: "Global List",
      enableBack: false,
      formScrol: false,
    });
    // eslint-disable-next-line
  }, [globalLists]);

  const searchSortFilter = (globalList) => {
    let filteredGlobalList = [];
    if (globalList?.length) {
      if (searchValue) {
        filteredGlobalList = globalList.filter((item) =>
          item.title.toLowerCase().includes(searchValue.toLowerCase())
        );
      } else {
        filteredGlobalList = globalList;
      }
      if (filteredGlobalList) {
        if (sortBy) {
          filteredGlobalList = [
            ...filteredGlobalList.sort(function (a, b) {
              if (sortBy.attribute === "FORM_NAME") {
                return (
                  (a.title === b.title ? 0 : a.title < b.title ? -1 : 1) *
                  (sortBy.order === "ASC" ? 1 : -1)
                );
              } else if (sortBy.attribute === "DATE_CREATED") {
                return (
                  (a.createdAt.toString() === b.createdAt.toString()
                    ? 0
                    : a.createdAt.toString() < b.createdAt.toString()
                    ? -1
                    : 1) * (sortBy.order === "ASC" ? 1 : -1)
                );
              } else {
                return (
                  (a.updatedAt.toString() === b.updatedAt.toString()
                    ? 0
                    : a.updatedAt.toString() < b.updatedAt.toString()
                    ? -1
                    : 1) * (sortBy.order === "ASC" ? 1 : -1)
                );
              }
            }),
          ];
        }
      }
      return [...filteredGlobalList];
    } else {
      return [];
    }
  };

  const globalListFilterHandler = () => {
    getGlobalLists(globalListFilterState);
  };
  return (
    <>
      <div className="px-3">
        <FormNavbar moduleName={"global-list"} />
        <div className="search-bar">
          <form className="form-search">
            <div className="input-group">
              <div className="input-group-prepend">
                <img src={IconSearch} alt="Search Icon" />
              </div>
              <input
                className="form-control"
                placeholder={i18n("global.Search")}
                onChange={(e) => setSearchValue(e.target.value)}
              ></input>
            </div>
          </form>
          <Link
            to="#"
            onClick={(e) => {
              e.preventDefault();
              setShowSortModal(true);
            }}
          >
            <img src={IconSortAlpha} alt="IconSortAlpha" />
          </Link>
          <Link
            to="#"
            onClick={(e) => {
              e.preventDefault();
              setShowFilterModal(true);
            }}
          >
            <img src={IconFilter} alt="IconFilter" />
          </Link>
        </div>
        {globalLists?.length ? (
          <>
            <ul className="form-list forms">
              {searchSortFilter(globalLists)?.map((globalList, index) => (
                <ListItem globalList={globalList} key={index} />
              ))}
            </ul>
          </>
        ) : null}
      </div>
      <Link
        to="#"
        onClick={(e) => {
          e.preventDefault();
          history.push("/forms/global-list/new");
        }}
        className={`btn btn-rounded btn-add-new ${
          TEXT_DIRECTION() === "rtl" ? "left-direction" : "right-direction"
        }`}
      >
        <I18n lng="Form.NewList" />
      </Link>
      <DialogModal
        showDlg={showSortModal}
        classes="modal-sort"
        content={
          <ul className="list-unstyled">
            <li
              onClick={() => {
                if (sortBy.attribute === "FORM_NAME") {
                  if (sortBy.order === "ASC") {
                    setSortBy({ attribute: "FORM_NAME", order: "DESC" });
                  } else {
                    setSortBy({ attribute: "FORM_NAME", order: "ASC" });
                  }
                } else {
                  setSortBy({ attribute: "FORM_NAME", order: "ASC" });
                }
              }}
            >
              <span className="icon-wrap">
                {sortBy.attribute === "FORM_NAME" ? (
                  sortBy.order === "ASC" ? (
                    <FaSortAlphaDown />
                  ) : (
                    <FaSortAlphaUp />
                  )
                ) : null}
              </span>
              <strong>
                <I18n lng="Form.GlobalListName" />
              </strong>
            </li>
            <li
              onClick={() => {
                if (sortBy.attribute === "DATE_CREATED") {
                  if (sortBy.order === "ASC") {
                    setSortBy({ attribute: "DATE_CREATED", order: "DESC" });
                  } else {
                    setSortBy({ attribute: "DATE_CREATED", order: "ASC" });
                  }
                } else {
                  setSortBy({ attribute: "DATE_CREATED", order: "ASC" });
                }
              }}
            >
              <span className="icon-wrap">
                {sortBy.attribute === "DATE_CREATED" ? (
                  sortBy.order === "ASC" ? (
                    <FaSortNumericDownAlt />
                  ) : (
                    <FaSortNumericUpAlt />
                  )
                ) : null}
              </span>
              <strong>
                <I18n lng="global.DateCreated" />
              </strong>
            </li>
            <li
              onClick={() => {
                if (sortBy.attribute === "DATE_MODIFIED") {
                  if (sortBy.order === "ASC") {
                    setSortBy({ attribute: "DATE_MODIFIED", order: "DESC" });
                  } else {
                    setSortBy({ attribute: "DATE_MODIFIED", order: "ASC" });
                  }
                } else {
                  setSortBy({ attribute: "DATE_MODIFIED", order: "ASC" });
                }
              }}
            >
              <span className="icon-wrap">
                {sortBy.attribute === "DATE_MODIFIED" ? (
                  sortBy.order === "ASC" ? (
                    <FaSortNumericDownAlt />
                  ) : (
                    <FaSortNumericUpAlt />
                  )
                ) : null}
              </span>
              <strong>
                <I18n lng="global.DateModified" />
              </strong>
            </li>
          </ul>
        }
        primaryActionTitle={i18n("global.Apply")}
        secondaryAction={() => setShowSortModal(false)}
        primaryAction={() => setShowSortModal(false)}
      />
      <FilterDialog
        show={showFilterModal}
        clearActions={() => {
          setGlobalListFilterState(globalListInitFilterState);
          getGlobalLists(globalListInitFilterState);
        }}
        secondryActionTitle={i18n("global.Clear")}
        primaryAction={globalListFilterHandler}
        secondaryAction={() => setShowFilterModal(false)}
        filterState={globalListFilterState}
        setFilterState={setGlobalListFilterState}
        filterInitState={globalListInitFilterState}
        formfilterState={true}
        hideInspector={true}
      />
    </>
  );
};

const mapStateToProps = ({ globalList, authentication }) => ({
  globalLists: globalList.globalLists,
  currentRole: authentication.currentRole,
});

const mapDispatchToProps = { getGlobalLists, updateRoute };

export default connect(mapStateToProps, mapDispatchToProps)(GlobalListListing);
