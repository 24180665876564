import { useHistory } from "react-router-dom";
import I18n from "../pages/private/languageSelector";
import { TEXT_DIRECTION } from "../helper/helperFunctions";
import { connect } from "react-redux";

const NavTabs = ({ list, moduleName, subscriptionData }) => {
  const history = useHistory();

  const userInFreePlan = subscriptionData?.currentPlan?.plan === "Free";

  const isTrialExpired =
    subscriptionData?.userTrial?.isTrialExpired &&
    subscriptionData?.currentPlan?.status === "Expired";

  const isSubscriptionPaused =
    subscriptionData?.currentPlan.status === "Paused";

  const isTrialExpiredORIsSubscriptionPaused =
    isTrialExpired || isSubscriptionPaused;

  return (
    <>
      <div
        className={
          window?.location?.pathName?.includes("/reports") ||
          TEXT_DIRECTION() !== "rtl"
            ? ""
            : "nav-parent mb-4"
        }
      >
        <ul
          className="nav nav-tabs"
          style={
            TEXT_DIRECTION() === "rtl"
              ? {
                  paddingRight: "28px",
                  paddingLeft: "8px",
                }
              : {
                  paddingRight: "20px",
                  paddingLeft: "14px",
                }
          }
        >
          {list.map((item) => {
            if (userInFreePlan && item === "Company.Roles") {
              return null;
            } else if (
              isTrialExpiredORIsSubscriptionPaused &&
              ["Company.Info", "Company.Roles", "Company.Sites"].includes(item)
            ) {
              return (
                <li className="nav-item" key={item}>
                  <button
                    className={`nav-link disabled`}
                    disabled
                    style={{ cursor: "not-allowed", color: "#E0E0E0" }}
                  >
                    <div
                      className="mx-1 text-center"
                      style={{ fontSize: "16px" }}
                    >
                      <I18n lng={item} />
                    </div>
                  </button>
                </li>
              );
            } else {
              return (
                <li className="nav-item" key={item}>
                  <button
                    className={`nav-link ${
                      moduleName === item?.toLowerCase()?.split(".")[1]
                        ? "active"
                        : ""
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      history.push(`${item?.toLowerCase()?.split(".")[1]}`);
                    }}
                  >
                    <div
                      className="mx-1 text-center"
                      style={{ fontSize: "16px" }}
                    >
                      <I18n lng={item} />
                    </div>
                  </button>
                </li>
              );
            }
          })}
        </ul>
      </div>
    </>
  );
};

const mapStateToProps = ({ subscription }) => ({
  subscriptionData: subscription.subscriptionData,
});

export default connect(mapStateToProps)(NavTabs);
