import React from "react";
import I18n from "../languageSelector";
import { TEXT_DIRECTION } from "../../../helper/helperFunctions";
import IconArrowDown from "../../../assets/images/arrowDown.svg";
import { Collapse } from "reactstrap";
import PagePdfPreview from "./report-pdf-previews/pagePdfPeview";

const ReportFailSummary = ({
  answers,
  getAnswer,
  failItems,
  hideAndShow,
  setFailItems,
  reportForEdit,
  showDocumentMedia,
  reportFailSumCollapsed,
  setReportFailSumCollapsed,
}) => {
  return (
    <div>
      {reportForEdit && hideAndShow?.content?.failsSummary && failItems ? (
        <div
          className="heading-top"
          style={{ borderRadius: "12px", marginTop: "13px" }}
        >
          <div className="report-table fail-summary">
            <div className="report-header">
              <div
                className="table mb-1"
                style={{
                  border: "#FFFFFF",
                  boxShadow: "1px 5px 2px rgba(0,0,0,.10)",
                }}
              >
                <div className="failed-summary">
                  <div className="table-header">
                    <div className="table-row">
                      <div
                        className="table-head"
                        style={{
                          padding: "0.25rem 1.5rem",
                          borderRadius: "0",
                        }}
                      >
                        <div className="my-1 d-flex justify-content-between w-100 ">
                          <b
                            className={
                              TEXT_DIRECTION() === "rtl" ? "ml-3" : "mr-3"
                            }
                          >
                            <I18n lng="Reports.FailSummary" />
                          </b>
                          <div
                            onClick={() => {
                              reportFailSumCollapsed &&
                              reportFailSumCollapsed === true
                                ? setReportFailSumCollapsed(false)
                                : setReportFailSumCollapsed(true);
                            }}
                            className="text-right align-end "
                          >
                            <img
                              style={
                                reportFailSumCollapsed === true
                                  ? {
                                      margin: "0 15px 5px 15px",
                                      width: "25px",
                                      cursor: "pointer",
                                    }
                                  : {
                                      margin: "0 15px 5px 15px",
                                      width: "25px",
                                      cursor: "pointer",
                                      rotate: "180deg",
                                    }
                              }
                              src={IconArrowDown}
                              alt="arrow Down"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {failItems ? (
                  <div className="fail-summaryBody">
                    <Collapse isOpen={reportFailSumCollapsed}>
                      <div className="report-data card">
                        {reportForEdit &&
                        reportForEdit.formDetail.schema.pages.length &&
                        answers &&
                        answers.length
                          ? reportForEdit.formDetail.schema.pages.map(
                              (item, index) => {
                                return (
                                  <PagePdfPreview
                                    key={index}
                                    index={index}
                                    page={item}
                                    getAnswer={getAnswer}
                                    setFailItems={setFailItems}
                                    failItems={failItems}
                                    failStatus={true}
                                    viewPageSections={false}
                                    instructionHide={
                                      hideAndShow?.content?.instructions
                                    }
                                    hideAndShow={hideAndShow}
                                    answers={answers}
                                    showDocumentMedia={showDocumentMedia}
                                  />
                                );
                              }
                            )
                          : null}
                      </div>
                    </Collapse>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ReportFailSummary;
