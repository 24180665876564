import React, { useContext, createContext } from "react";
const UserUIContext = createContext();
export function useUserUIContext() {
  return useContext(UserUIContext);
}
export function UserUiProvider({ UserUIEvents, children }) {
  const value = {
    openUserViewDialog: UserUIEvents.openUserViewDialog,
    openUserEditDialog: UserUIEvents.openUserEditDialog,
    openUserDeleteDialog: UserUIEvents.openUserDeleteDialog,
    createPremiumUser: UserUIEvents.createPremiumUser,
  };
  return (
    <UserUIContext.Provider value={value}> {children}</UserUIContext.Provider>
  );
}
