import React, { useState, useEffect, useRef } from "react";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import { CustomInput } from "reactstrap";
import { AiOutlineClose } from "react-icons/ai";
import I18n, { i18n } from "../../../../../languageSelector";
import {
  uploadImage,
  getDropDownList,
} from "../../../../../../../redux/reducers/form/form.reducer";
import { getFileData } from "../../../../../../../helper/image-helper";
import IconSearch from "../../../../../../../assets/images/icon-search.svg";
import ImageEditorDialog from "../../../../../../../components/dialogs/image-editor-dialog";
import { patchGlobalList } from "../../../../../../../redux/reducers/global-list/global-list.reducer";
import { TEXT_DIRECTION } from "../../../../../../../helper/helperFunctions";

const GlobalListSettings = ({
  question,
  actionItem,
  uploadImage,
  dropDownList,
  updateQuestion,
  getDropDownList,
  patchGlobalList,
}) => {
  const fileInputRef = useRef(null);
  const [search, setSearch] = useState("");
  const [Image64Data, setImage64Data] = useState("");
  const [description, setDescription] = useState("");
  const [questionSetting, setQuestionSetting] = useState();
  const [openImageEditor, setOpenImageEditor] = useState(false);
  const [dropDownListArray, setDropDownListArray] = useState([]);
  const [image64DataForPreview, setImage64DataForPreview] = useState("");

  useEffect(() => {
    if (JSON.stringify(question) !== JSON.stringify(questionSetting)) {
      setQuestionSetting(question);
      setDescription(question.settings.description);
    }
    // eslint-disable-next-line
  }, [question]);

  useEffect(() => {
    getDropDownList();
    // eslint-disable-next-line
  }, []);

  const updateQuestionData = (updatedData) => {
    setQuestionSetting({
      ...updatedData,
    });
    updateQuestion(
      actionItem.pageNo,
      actionItem.sectionNo,
      actionItem.questionNo,
      {
        ...updatedData,
      }
    );
  };

  useEffect(() => {
    if (dropDownList?.length) {
      let sortedByDate = dropDownList.sort(
        (a, b) => Date.parse(a?.updatedAt) - Date.parse(b?.updatedAt)
      );
      if (sortedByDate?.length) {
        let sortedByRecentlyUsed = sortedByDate.sort((a, b) =>
          a?.recentlyUsed === b?.recentlyUsed ? 0 : a?.recentlyUsed ? 1 : -1
        );
        setDropDownListArray([...sortedByRecentlyUsed]);
      } else {
        setDropDownListArray([...sortedByDate]);
      }
    }
  }, [dropDownList]);

  const onImageEditorHandler = (data) => {
    setImage64DataForPreview(data);

    if (question?.settings?.photo) {
      uploadImage({
        base64: data,
        existingFileUrl: question?.settings?.photo,
        fileType: "jpg",
      }).then((response) => {
        updateQuestion(
          actionItem.pageNo,
          actionItem.sectionNo,
          actionItem.questionNo,
          {
            ...questionSetting,
            settings: {
              ...questionSetting.settings,
              photo: response.value.data,
            },
          }
        );
        toast.success(<I18n lng="global.PhotoUpdated" />);
      });
    } else {
      uploadImage({ base64: data, existingFileUrl: "", fileType: "jpg" }).then(
        (response) => {
          updateQuestion(
            actionItem.pageNo,
            actionItem.sectionNo,
            actionItem.questionNo,
            {
              ...questionSetting,
              settings: {
                ...questionSetting.settings,
                photo: response.value.data,
              },
            }
          );
          toast.success(<I18n lng="global.PhotoUploaded" />);
        }
      );
    }
  };
  const selectImagehandler = async (event) => {
    const fileData = await getFileData(event);
    if (fileData && fileData.fileUrl !== "") {
      setImage64Data(fileData.fileUrl);
      setOpenImageEditor(true);
    }
    fileInputRef.current.value = "";
  };
  const listSelectHandler = (checked, item) => {
    if (checked && dropDownListArray.length) {
      updateQuestionData({
        ...questionSetting,
        settings: {
          ...questionSetting.settings,
          listName: item.title,
          options: [...item.list],
        },
      });
    }
  };

  return (
    <>
      <input
        ref={fileInputRef}
        type="file"
        hidden
        multiple={false}
        onChange={selectImagehandler}
      />
      {questionSetting && (
        <>
          <div className="sr-options">
            <div className="search-bar">
              <form className="form-search">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <img src={IconSearch} alt="Search Icon" />
                  </div>
                  <input
                    className="form-control"
                    placeholder={i18n("global.Search")}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </form>
            </div>
            <div className="row">
              <div className="col-6 mt-3 mb-3">
                <span className="recently-used-list">
                  {<I18n lng="global.RecentlyUsed" />}
                </span>
              </div>
              <div className="col-6"></div>
            </div>
            {dropDownListArray.length
              ? dropDownListArray
                  .filter((item) =>
                    item.title?.toLowerCase()?.includes(search?.toLowerCase())
                  )
                  .reverse()
                  .slice(0, 5)
                  .map((item) => {
                    return (
                      <div>
                        <div className="row">
                          <div
                            className={`col-1 ${
                              TEXT_DIRECTION() === "rtl" ? "ml-3 pr-0" : ""
                            }`}
                          >
                            <CustomInput
                              type="radio"
                              name={"list"}
                              id={"radioList"}
                              inline
                              checked={
                                item.title === questionSetting.settings.listName
                                  ? true
                                  : false
                              }
                              onClick={(e) => {
                                patchGlobalList(item.id, {
                                  updatedAt: new Date(),
                                  recentlyUsed: true,
                                });
                                listSelectHandler(e.target.checked, item);
                              }}
                            />
                          </div>
                          <div className="col-8">{item.title}</div>
                        </div>
                      </div>
                    );
                  })
              : null}
            <div className={`${TEXT_DIRECTION() === "rtl" ? "" : "ml-3"}`}>
              <div className="row">
                <div
                  className={`${TEXT_DIRECTION() === "rtl" ? "" : "col-1 p-0"}`}
                >
                  <CustomInput
                    type="switch"
                    name={"switchComment"}
                    id={"switchComment"}
                    inline
                    checked={questionSetting.settings.canComment}
                    onChange={() => {
                      updateQuestionData({
                        ...questionSetting,
                        settings: {
                          ...questionSetting.settings,
                          canComment: !questionSetting.settings.canComment,
                        },
                      });
                    }}
                  />
                </div>
                <div className="col-4">
                  <label>{<I18n lng="global.Comment" />}</label>
                </div>
                <div
                  className={`${TEXT_DIRECTION() === "rtl" ? "" : "col-1 p-0"}`}
                >
                  {" "}
                  <CustomInput
                    type="switch"
                    name={"switchPhoto"}
                    id={"switchPhoto"}
                    inline
                    checked={questionSetting.settings.canAttachPhoto}
                    onChange={() => {
                      updateQuestionData({
                        ...questionSetting,
                        settings: {
                          ...questionSetting.settings,
                          canAttachPhoto:
                            !questionSetting.settings.canAttachPhoto,
                        },
                      });
                    }}
                  />
                </div>
                <div className="col-4">
                  <label>{<I18n lng="global.Photo" />}</label>
                </div>
              </div>
            </div>
          </div>

          <hr className={"mb-3"} />
          <div className={`${TEXT_DIRECTION() === "rtl" ? "mr-3" : "ml-3"}`}>
            <div className="row">
              <div className="col-1 p-0">
                <CustomInput
                  className="font-weight-bold"
                  type="switch"
                  name="mendatoryAnswer"
                  id="mendatoryAnswer"
                  checked={
                    questionSetting.settings.isEnabled === true &&
                    questionSetting.settings.isMandatory === true
                      ? questionSetting.settings.isMandatory
                      : questionSetting.settings.isEnabled === false &&
                        questionSetting.settings.isMandatory === true
                      ? !questionSetting.settings.isMandatory
                      : questionSetting.settings.isEnabled === true &&
                        questionSetting.settings.isMandatory === false
                      ? questionSetting.settings.isMandatory
                      : questionSetting.settings.isEnabled === false &&
                        questionSetting.settings.isMandatory === false
                      ? questionSetting.settings.isMandatory
                      : null
                  }
                  disabled={questionSetting.settings.isEnabled === false}
                  onChange={() => {
                    updateQuestionData({
                      ...questionSetting,
                      settings: {
                        ...questionSetting.settings,
                        isMandatory: !questionSetting.settings.isMandatory,
                      },
                    });
                  }}
                />
              </div>
              <div className="col-11">
                <label>{<I18n lng="Form.AnswerIsMandatory" />}</label>
              </div>
              <div className="col-1 p-0">
                <CustomInput
                  className="font-weight-bold"
                  type="switch"
                  name="enabledQuestion"
                  id="enabledQuestion"
                  checked={questionSetting.settings.isEnabled}
                  onChange={(e) => {
                    updateQuestionData({
                      ...questionSetting,
                      settings: {
                        ...questionSetting.settings,
                        isEnabled: !questionSetting.settings.isEnabled,
                        isMandatory:
                          questionSetting.settings.isEnabled === true &&
                          questionSetting.settings.isMandatory === true
                            ? !questionSetting.settings.isMandatory
                            : questionSetting.settings.isMandatory,
                      },
                    });
                  }}
                />
              </div>
              <div className="col-11">
                <label>{<I18n lng="Form.EnabledQuestion" />}</label>
              </div>
            </div>
          </div>
          <div className="sr-text-block btn-bar">
            <textarea
              placeholder={i18n("global.PleaseHelpNoteHere")}
              rows="3"
              value={description}
              onBlur={() => {
                questionSetting.settings.description = description;
                updateQuestionData({ ...questionSetting });
              }}
              onChange={(e) => setDescription(e.target.value)}
            />
            {question?.settings?.photo || image64DataForPreview !== "" ? (
              <div className="edit-photo">
                <AiOutlineClose
                  className="icon icon-img-remove"
                  onClick={(e) => {
                    e.preventDefault();
                    updateQuestionData({
                      ...questionSetting,
                      settings: {
                        ...questionSetting.settings,
                        photo: "",
                      },
                    });
                    setImage64DataForPreview("");
                  }}
                />
                <img
                  id={"image-preview"}
                  onLoad={({ target: img }) => {
                    let style =
                      img.naturalWidth > img.naturalHeight
                        ? img.naturalHeight
                        : img.naturalWidth;
                    let documents;
                    documents = document.getElementById("image-preview");
                    documents.setAttribute("height", style + "px");
                    documents.setAttribute("width", style + "px");
                  }}
                  src={
                    image64DataForPreview !== ""
                      ? image64DataForPreview
                      : question?.settings?.photo
                  }
                  alt="loaidng"
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenImageEditor(true);
                  }}
                />
              </div>
            ) : (
              <div className="add-photo mr-2">
                <span
                  onClick={(e) => {
                    e.preventDefault();
                    fileInputRef.current.click();
                  }}
                >
                  <I18n lng="global.Photo" />
                </span>
              </div>
            )}
          </div>
        </>
      )}
      <ImageEditorDialog
        show={openImageEditor}
        onHide={() => {
          setOpenImageEditor(false);
        }}
        onEdit={(data) => onImageEditorHandler(data)}
        toEdit={
          Image64Data
            ? Image64Data
            : question?.settings?.photo
            ? question?.settings?.photo
            : ""
        }
      />
    </>
  );
};
const mapStateToProps = ({ form }) => ({
  dropDownList: form.dropDownList,
});
const mapDispatchToProps = {
  uploadImage,
  patchGlobalList,
  getDropDownList,
};
export default connect(mapStateToProps, mapDispatchToProps)(GlobalListSettings);
