import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import NavTabs from "../../../components/navTabs";
import { Route, Switch, useParams } from "react-router-dom";
import CompanyInfoPage from "./company/info/company-info-page";
import CompanySitesPage from "./company/sites/company-sites-page";
import CompanyUserPage from "./company/users/company-users-page";
import CompanyRolesPage from "./company/roles/company-roles-page";
import { swapTwoValuesPositionInArray } from "../../../helper/helperFunctions";
import CompanySubscriptionsPage from "./company/subscriptions/company-subscription-page";
import SubscriptionExpiredRedirect from "../../../components/private-route/SubscriptionExpiredRedirect";

const ManagementPage = ({ roleAccess }) => {
  const history = useHistory();
  const { moduleName } = useParams();

  const [arrayOfTabs, setArrayOfTabs] = useState([]);

  useEffect(() => {
    if (roleAccess?.company?.access) {
      let arrayOfKeys = Object.keys(roleAccess?.company?.access);

      let keysRearranged = swapTwoValuesPositionInArray(arrayOfKeys, 0, 3);

      if (keysRearranged?.length) {
        let dummyTabs = [];
        // eslint-disable-next-line
        keysRearranged.map((item) => {
          if (
            roleAccess?.company?.access[item] === true ||
            (roleAccess?.company?.access[item] === false &&
              item === "manageCompanyInformation")
          ) {
            dummyTabs.push(
              item === "manageCompanyInformation"
                ? "Company.Info"
                : item === "manageSites"
                ? "Company.Sites"
                : item === "manageUsers"
                ? "Company.Users"
                : item === "manageRoles"
                ? "Company.Roles"
                : item === "manageSubscription"
                ? "Company.Subscriptions"
                : ""
            );
          }
        });
        setArrayOfTabs([...dummyTabs]);
        if (dummyTabs?.length) {
          let currentRoute;
          if (window.location.pathname === "/management/info") {
            currentRoute = "Company.Info";
          } else if (window.location.pathname === "/management/users") {
            currentRoute = "Company.Users";
          } else if (window.location.pathname === "/management/roles") {
            currentRoute = "Company.Roles";
          } else if (window.location.pathname === "/management/sites") {
            currentRoute = "Company.Sites";
          } else if (window.location.pathname === "/management/subscriptions") {
            currentRoute = "Company.Subscriptions";
          }
          let routeAccess = dummyTabs.find((item) => item === currentRoute);
          if (routeAccess !== undefined) {
            history.push(
              `/management/${routeAccess?.toLowerCase().split(".")[1]}`
            );
          } else {
            history.push(
              `/management/${dummyTabs[0]?.toLowerCase().split(".")[1]}`
            );
          }
        }
      }
    } // eslint-disable-next-line
  }, [roleAccess]);

  return (
    <>
      <div className="px-3">
        <NavTabs list={arrayOfTabs} moduleName={moduleName} />
        <Switch>
          <SubscriptionExpiredRedirect
            path="/management/roles"
            component={CompanyRolesPage}
          />
          <SubscriptionExpiredRedirect
            path="/management/info"
            component={CompanyInfoPage}
          />
          <SubscriptionExpiredRedirect
            path="/management/sites"
            component={CompanySitesPage}
          />
          <Route path="/management/users" component={CompanyUserPage} />
          <Route
            path="/management/subscriptions"
            component={CompanySubscriptionsPage}
          />
        </Switch>
      </div>
    </>
  );
};
const mapStateToProps = ({ authentication }) => ({
  roleAccess: authentication.userRoleAccess,
  user: authentication.user,
});

export default connect(mapStateToProps, null)(ManagementPage);
