import React from "react";
const Input = ({
  label,
  iconSize,
  id,
  name,
  validations,
  prefix,
  suffix,
  suffixAction = () => {
    console.log("siffix action");
  },
  register,
  required,
  error,
  ...otherProps
}) => {
  return (
    <>
      {label && (
        <label className="form-label" htmlFor={id}>
          {label} {validations?.required && <span className="required"></span>}
        </label>
      )}
      <div className="input-group">
        {prefix && (
          <div className={`input-group-prepend ${iconSize ? "icon-lg" : ""}`}>
            <img src={prefix} alt="fieldIcon" />
          </div>
        )}
        <input
          className="form-control"
          name={name}
          {...(register ? register(name, { ...validations }) : {})}
          {...otherProps}
        />
        {suffix && (
          <div className="input-group-append" onClick={suffixAction}>
            <img src={suffix} alt="fieldIcon" />
          </div>
        )}
      </div>
      {error && <p className="error">{error.message}</p>}
    </>
  );
};
export default Input;
