import keyboardLeftArrow from "../../../../assets/images/keyboard_arrow_left.svg";
import keyboardRightArrow from "../../../../assets/images/keyboard_arrow_right.svg";
import leftArrow from "../../../../assets/images/pagination-arrow-left.svg";
import rightArrow from "../../../../assets/images/pagination-arrow-right.svg";
import { TEXT_DIRECTION } from "../../../../helper/helperFunctions";
import { i18n } from "../../languageSelector";

interface PaginationIProps {
  page: number;
  total: number;
  setpage: any;
  setSize: any;
  size: number;
}
function CustomPagination({ page, total, setpage, setSize, size }: PaginationIProps) {
  const onPrevious = () => {
    setpage(page - 1);
  };
  const onNext = () => {
    setpage(page + 1);
  };
  return (
    <div className="page-wrap ">
      <div className="arrow-wrap">
        {TEXT_DIRECTION() === "ltr" ? (
          <>
            {" "}
            <div onClick={() => (page === 1 ? null : onPrevious())}>
              <img src={keyboardLeftArrow} alt="" />
            </div>
            <div onClick={() => (page >= total ? null : onNext())}>
              <img src={keyboardRightArrow} alt="" />
            </div>
          </>
        ) : (
          <>
            <div onClick={() => (page === 1 ? null : onPrevious())}>
              <img src={leftArrow} alt="" />
            </div>
            <div onClick={() => (page >= total ? null : onNext())}>
              <img src={rightArrow} alt="" />
            </div>
          </>
        )}
      </div>
      <div className="d-flex align-items-center justify-content-start">
        <div className="d-flex w-100 " style={{ fontSize: "12px" }}>
          <div className="d-flex justify-content-center align-items-center px-2">
            <div className="w-100" style={{ color: "#333333" }}>
              {" "}
              {i18n("Analytics.Page")}:{" "}
            </div>
            <div className="selected-page mx-3 py-1">{page}</div>{" "}
            <div style={{ width: "100% ", color: "#333333" }}>
              {i18n("Analytics.of")} {total}
            </div>
          </div>
        </div>
        <div className="d-flex w-100 ml-5" style={{ fontSize: "12px", whiteSpace: "nowrap" }}>
          <div className="d-flex justify-content-center align-items-center">
            <div className="mx-3" style={{ color: "#333333", lineHeight: "29.8px" }}>
              {i18n("Analytics.Rows per page")}:
            </div>
            <select onChange={(e) => setSize(parseInt(e.target.value))}>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomPagination;
