import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import ReportInformation from "./report-information";
import ReportCharts from "./report-charts";
import ReportFailSummary from "./report-failSummary";
import ReportTableOfContent from "./report-tableOfContent";
import ReportDisclaimer from "./report-desclaimer";
import { connect } from "react-redux";
import I18n from "../languageSelector";
import "../../../assets/css/pdf.min.css";
import { createUseStyles } from "react-jss";
import "react-circular-progressbar/dist/styles.css";
import { useLocation, useParams } from "react-router-dom";
import IconArrowDown from "../../../assets/images/arrowDown.svg";
import { Collapse } from "reactstrap";
import PoweredByTamamLogo from "../../../assets/images/poweredByTamamLogo.svg";
import PDFIcon from "../../../assets/images/pdfIcon.svg";
import PdfPrint from "../../../assets/images/pdf-Print.svg";
import {
  getReportByIdForShareData,
  getReportBySettingsId,
  getReportSettings,
} from "../../../redux/reducers/admin-manager-reports/admin-manager-report.reducer";
import { PDF_IMAGE_CLASS, reportThemeColor, numberFailHandler, TEXT_DIRECTION, formAllAnswerdDetails } from "../../../helper/helperFunctions";
import ReportInspectionQuestions from "./report-inspectQuestions";
import { generatePDFReport } from "../../../helper/jsreport-helper";

const useStyle = createUseStyles({
  themeColorStyle: (props) => ({
    "& .report-graph": {
      "& .scoreProgress": {
        "& .CircularProgressbar": {
          "& .CircularProgressbar-path": {
            stroke: `#6FCF97`,
          },
        },
      },
      "& .answerProgress": {
        "& .CircularProgressbar": {
          "& .CircularProgressbar-path": {
            stroke: `#2D9CDB`,
          },
        },
      },
    },

    "& .report-divider": {
      background: `rgb(${props.color})`,
    },
    "& .report-table": {
      "&.fail-summary": {
        "& .table": {
          border: `1px solid rgb(${props.color})`,
          "& .table-header": {
            background: "none",
            "& .table-head": {
              background: `rgba(${props.color}, 0.55)`,
            },
          },
        },
      },
      "&.questions": {
        "& .table": {
          "& .report-page-title": {
            background: `rgb(${props.color})`,
          },
          "& .table-header": {
            "& .table-head": {
              background: `rgba(${props.color}, 0.55)`,
            },
          },
        },
      },
    },
  }),
});

const ReportPdfView = ({ user, reportForEdit, getReportByIdForShareData, getReportBySettingsId, getReportSettings, currentRole }) => {
  const componentRef = useRef();
  const { id, email } = useParams();

  const [reportTitlePageCollapsed, setReportTitlePageCollapsed] = useState(true);
  const [reportTitleCollapsed, setReportTitleCollapsed] = useState(true);
  const [reportDesclaimCollapsed, setReportDesclaimCollapsed] = useState(true);
  const [reportKeyIndicatesCollapsed, setReportKeyIndicatesCollapsed] = useState(true);
  const [reportFailSumCollapsed, setReportFailSumCollapsed] = useState(true);
  const [reportTableContent, setReportTableContent] = useState(true);

  const [answers, setAnswers] = useState([]);
  const [pieChartFail, setPieChartFail] = useState();
  const [pieChartSuccess, setPieChartSuccess] = useState();
  const [timeZone, setTimeZone] = useState([]);
  // const [reportId, setReportId] = useState();
  const [reportSettings, setReportSettings] = useState();
  const [failItems, setFailItems] = useState(0);
  const [hideAndShow, setHideAndShow] = useState({});
  const [totalQuestion, setTotalQuestion] = useState(0);
  // const [showPdfHeader, setShowPdfHeader] = useState(true);
  const [imageCountData, setImageCountData] = useState([]);
  const [totalPercentage, setTotalPercentage] = useState(0);
  const [answeredScore, setAnsweredScore] = useState(0);
  const [questionScore, setQuestionScore] = useState(0);
  const [reportTheme, setReportTheme] = useState("32, 99, 155");
  const [imagesInReport, setImagesInReport] = useState(false);
  const [answeredPercent, setAnsweredPercent] = useState();
  const [showDocumentMedia, setShowDocumentMedia] = useState(false);
  const [reportInspectionNumber, setReportInspectionNumber] = useState(0);
  const [showShareHistory, setShowShareHistory] = useState(true);
  console.log(setShowDocumentMedia);
  const theme = useStyle({ color: reportTheme });
  const location = useLocation();
  let language = localStorage.getItem("language");

  const currentUrl = window.location.pathname;
  const parts = currentUrl.split("/");
  const selectedEmail = parts[parts.length - 1];

  var element = document.getElementsByClassName("app-wrapper")[0];
  var height = element && element.offsetHeight;
  var width = element && element.offsetWidth;
  if (height) {
    element.style.position = "relative";
    element.style.bottom = 62 + "px";
  }
  if (width) {
    element.style.width = "150%";
    element.style.maxWidth = 100 + "%";
    element.style.padding = 0;
  }

  var bodyTag = document.getElementsByTagName("body")[0];
  bodyTag.style.backgroundColor = "#f5f5f5";

  const navbars = document.querySelectorAll(".navbar");
  for (let i = 0; i < navbars.length; i++) {
    navbars[i].style.display = "none";
  }

  useEffect(() => {
    if (selectedEmail && selectedEmail.includes("@") && reportForEdit?.report?.shareHistory !== undefined) {
      if (reportForEdit?.report?.shareHistory?.length === 0) {
        setShowShareHistory(false);
      } else {
        const emailFound = reportForEdit?.report?.shareHistory?.some((history) => history.email === selectedEmail);
        setShowShareHistory(emailFound);
      }
    }
  }, [reportForEdit?.report, selectedEmail]);

  useEffect(() => {
    setReportInspectionNumber(0);
    let timeZones = user?.company?.timeZone.split(" ");
    setTimeZone(timeZones);
  }, [user]);

  useEffect(() => {
    if (reportForEdit?.report) {
      const themeData = reportThemeColor(reportForEdit?.report?.company?.reportColor?.label);
      if (themeData) {
        setReportTheme(themeData);
      }
    }
    // eslint-disable-next-line
  }, [reportForEdit?.report]);

  useEffect(() => {
    getReportBySettingsId(id).then((res) => {
      setHideAndShow(res.value.data.userReportSetting);
      setReportSettings(res.value.data.userReportSetting);
    });
    // });
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setFailItems(0);
    if (id && email) {
      // getReportByIdForShareData(id, email).catch((err) => {
      //   if (err) {
      //     setDeletedReport(
      //       <h3 style={{ color: "red" }} className="mt-5">
      //         <I18n lng="global.SorryReportIsDeleted" />
      //       </h3>
      //     );
      //   }
      // });
    }
    setAnswers([]);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (reportForEdit && reportForEdit?.report?.formAnswerDetail && reportForEdit?.report?.formAnswerDetail.length) {
      setAnswers([...reportForEdit?.report.formAnswerDetail]);
    }
    // eslint-disable-next-line
  }, [reportForEdit?.report]);

  const getAnswer = (uuid) => {
    if (answers && answers.length) {
      const answer = answers.find((item) => item?.questionUuid === uuid);
      return answer ? answer : undefined;
    }
  };

  useEffect(() => {
    let multiQuestionsWithIsFail = [];
    let singleQuestionsWithIsFail = [];
    let failItem = 0;
    let answerScore = 0;
    let questionScore = 0;
    let totalQuestions = 0;
    console.log(failItem);
    if (reportForEdit?.report) {
      reportForEdit?.report?.formDetail?.schema?.pages.map((item) =>
        // eslint-disable-next-line
        item.sections.map((secItem) => {
          let questions = secItem?.questions?.filter((item) => item.type !== "instructions");
          totalQuestions += questions.length;

          // eslint-disable-next-line
          secItem.questions
            .filter((item) => item.type === "COMPLEX_MULTIPLE_CHOICE" || item.type === "checkbox" || item.type === "slider" || item.type === "number") // eslint-disable-next-line
            .map((question) => {
              if (question) {
                question?.settings?.score !== "-" ? (questionScore = questionScore + Number(question?.settings?.score)) : (questionScore = 0);
              }
              let answer = reportForEdit?.report.formAnswerDetail?.find((item) => item?.questionUuid === question?.questionUuid);

              if (answer && question.type === "COMPLEX_MULTIPLE_CHOICE") {
                if (question?.settings?.answers[answer?.selected[0]?.index]?.score === "-" && question?.settings?.selectionType !== "MULTIPLE") {
                  questionScore = questionScore - question.settings.score;
                }
                answer?.selected
                  .filter((item) => question.settings.answers[item.index].score !== "-")
                  // eslint-disable-next-line
                  .map((select) => {
                    answerScore = answerScore + Number(question.settings.answers[select.index].score);
                  });
              } else if (answer && question.type === "checkbox") {
                answer?.value === true ? (answerScore = answerScore + Number(question.settings.score)) : (answerScore = answerScore + 0);
              } else if (answer && question.type === "slider") {
                let sliderMaxValue = question.settings.max;

                answer?.sliderValue !== null || answer?.sliderValue !== ""
                  ? (answerScore = answerScore + (Number(answer?.sliderValue) / Number(sliderMaxValue)) * Number(question.settings.score))
                  : (answerScore = answerScore + 0);
              } else if (answer && question.type === "number") {
                let isFail = numberFailHandler(question, answer?.value);
                if (isFail) {
                  failItem += 1;
                  setFailItems(failItem + multiQuestionsWithIsFail.length + singleQuestionsWithIsFail?.length);
                }
              }
              if (answer && question?.settings?.selectionType === "MULTIPLE") {
                const hasFailSelected = answer?.selected?.some((item) => question.settings.answers[item.index].isFail);

                if (hasFailSelected) {
                  multiQuestionsWithIsFail.push(question);
                  setFailItems(failItem + multiQuestionsWithIsFail.length + singleQuestionsWithIsFail?.length);
                }
              } else {
                const singleFailSelected = answer?.selected?.some((item) => question.settings.answers[item.index].isFail);

                if (singleFailSelected) {
                  singleQuestionsWithIsFail.push(question);
                  setFailItems(failItem + multiQuestionsWithIsFail.length + singleQuestionsWithIsFail?.length);
                }
              }
            });
        })
      );
    }

    setTotalPercentage(isNaN((answerScore / questionScore) * 100) ? 0 : (answerScore / questionScore) * 100);
    setTotalQuestion(totalQuestions);
    setQuestionScore(questionScore);
    setAnsweredScore(answerScore);

    // eslint-disable-next-line
  }, [reportForEdit]);
  useEffect(() => {
    var str = "" + reportForEdit?.report?.dispatchNumber;
    var pad = "0000";
    var ans = pad.substring(0, pad.length - str.length) + str;
    setReportInspectionNumber(ans);
  }, [reportForEdit?.report?.dispatchNumber]);

  const handleScroll = (id) => {
    document.getElementById(id).scrollIntoView({
      block: "center",
      inline: "nearest",
      behavior: "smooth",
    });
  };
  useEffect(() => {
    // eslint-disable-next-line
    let data = answers.length
      ? answers.forEach((item) => {
          return item?.image?.length ? setImagesInReport(true) : item?.inspectorImages?.length ? setImagesInReport(true) : setImagesInReport(false);
        })
      : null;
  }, [answers]);
  useEffect(() => {
    let data = [];
    let imageCount = 0;
    if (reportForEdit?.report?.formDetail?.schema?.pages?.length) {
      reportForEdit?.report.formDetail.schema.pages.forEach((item) => {
        return item.sections && item.sections.length
          ? item.sections.forEach((section) => {
              return section.questions && section.questions.length
                ? section.questions.forEach((question) => {
                    if (getAnswer(question.questionUuid)?.image?.length) {
                      data.push({
                        questionId: question.questionUuid,
                        imageCountStart: imageCount,
                      });
                    } else if (getAnswer(question.questionUuid)?.inspectorImages?.length) {
                      data.push({
                        questionId: question.questionUuid,
                        imageCountStart: imageCount,
                      });
                    }

                    return getAnswer(question.questionUuid)?.image?.length || getAnswer(question.questionUuid)?.inspectorImages?.length
                      ? (imageCount = getAnswer(question.questionUuid)?.image?.length
                          ? imageCount + getAnswer(question.questionUuid)?.image?.length
                          : getAnswer(question.questionUuid)?.inspectorImages?.length
                          ? imageCount + getAnswer(question.questionUuid)?.inspectorImages?.length
                          : null)
                      : null;
                  })
                : null;
            })
          : null;
      });
    }
    setImageCountData([...data]);
    // eslint-disable-next-line
  }, [reportForEdit, answers]);

  const url = location.pathname;
  const env = process.env.REACT_APP_URL;

  const trimmedEnv = env.trim().replace(/\/$/, "");
  const mergedUrl = trimmedEnv + "" + url;
  const printReport = async () => {
    const formAnswerDetail = await formAllAnswerdDetails(reportForEdit?.report);
    generatePDFReport(
      "Test Report",
      {
        ...reportForEdit?.report,
        formAnswerDetail,
        hideAndShow,
        reportInspectionNumber,
        startAt: moment(reportForEdit?.report.startAt).format("dddd DD MMM YYYY hh:mm a"),
        primaryColor: reportTheme,
        pieChartFail: pieChartFail,
        pieChartSuccess: pieChartSuccess,
        totalPercentage: totalPercentage,
        answeredPercent: answeredPercent,
        failedQuestions: failItems,
        questionScore: questionScore,
        answeredScore: answeredScore,
        totalQuestions: totalQuestion,
        answeredQuestions: reportForEdit?.report?.formAnswerDetail?.length,
        url: mergedUrl,
      },
      language === "ENGLISH" ? "EN" : "AR",
      "WEB_VIEW"
    );
  };

  const downloadReport = async () => {
    const formAnswerDetail = await formAllAnswerdDetails(reportForEdit?.report);
    generatePDFReport(
      reportForEdit?.report.formDetail.title,
      {
        ...reportForEdit?.report,
        formAnswerDetail,
        hideAndShow,
        reportInspectionNumber,
        startAt: moment(reportForEdit?.report.startAt).format("dddd DD MMM YYYY hh:mm a"),
        primaryColor: reportTheme,
        pieChartFail: pieChartFail,
        pieChartSuccess: pieChartSuccess,
        failedQuestions: failItems,
        totalPercentage: totalPercentage,
        answeredPercent: answeredPercent,
        questionScore: questionScore,
        answeredScore: answeredScore,
        totalQuestions: totalQuestion,
        answeredQuestions: reportForEdit?.report?.formAnswerDetail?.length,
        url: mergedUrl,
      },
      language === "ENGLISH" ? "EN" : "AR",
      "DOWNLOAD"
    );
  };

  return showShareHistory ? (
    <>
      {reportForEdit?.report?.id && theme.themeColorStyle && reportTheme ? (
        <div id="report-pdf" ref={componentRef} className={theme.themeColorStyle}>
          <div id="test ">
            {/* {showPdfHeader ? ( */}
            <div
              className="web-report-header "
              style={{
                padding: "10px",
                position: "fixed",
                width: "100%",
                zIndex: "3",
                top: "1px",
              }}
            >
              <a href="/" onclick="location.reload();">
                <img width={"170px"} src={PoweredByTamamLogo} alt="loading" />
              </a>

              <div className={`${TEXT_DIRECTION() === "rtl" ? " mr-auto" : "ml-auto"} `}>
                <img width={"20px"} className="cursor-pointer" onClick={printReport} src={PdfPrint} alt="PDF icon" />

                <img
                  onClick={downloadReport}
                  className={`cursor-pointer ${TEXT_DIRECTION() === "rtl" ? " mr-3" : "ml-3"} `}
                  width={"20px"}
                  src={PDFIcon}
                  alt="loading"
                />
              </div>
            </div>
            {/* ) : null} */}
            <div className="report-pdf-view app-wrapper container" style={{ position: "relative", top: "50px" }}>
              {hideAndShow?.content?.companyInformation && (
                <div
                  className="d-flex justify-content-between align-items-end p-3 mt-3"
                  style={{
                    display: "flex",
                    backgroundColor: "white",
                    width: "calc(100% + 2rem)",
                    marginRight: "-1.25rem",
                    marginLeft: "-1rem",
                  }}
                >
                  {reportForEdit?.report?.company?.image ? (
                    <img src={reportForEdit?.report?.company?.image} alt="logo" style={{ maxWidth: "120px", width: "100%" }} />
                  ) : (
                    <span></span>
                  )}
                  <h2 className="float-right" style={{ paddingLeft: "15px" }}>
                    {reportForEdit?.report?.company?.companyName}
                  </h2>
                </div>
              )}
              <div className="report-table questions">
                <div className="title-header table mb-1">
                  <div className="d-flex">
                    <div className="report-page-title mb-0 mt-0" style={{ padding: ".75rem 1.35rem" }}>
                      <b className="m-0 p-0">
                        <I18n lng="Reports.TitlePage" />
                      </b>
                      <div
                        onClick={() => {
                          reportTitlePageCollapsed && reportTitlePageCollapsed === true
                            ? setReportTitlePageCollapsed(false)
                            : setReportTitlePageCollapsed(true);
                        }}
                        className="text-right align-end "
                      >
                        <img
                          style={
                            reportTitlePageCollapsed === true
                              ? {
                                  margin: "0 15px 5px 15px",
                                  width: "25px",
                                  cursor: "pointer",
                                }
                              : {
                                  margin: "0 15px 5px 15px",
                                  width: "25px",
                                  cursor: "pointer",
                                  rotate: "180deg",
                                }
                          }
                          src={IconArrowDown}
                          alt="arrow Down"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <Collapse isOpen={reportTitlePageCollapsed}>
                  <div className="heading-top" style={{ borderRadius: "12px", marginTop: "13px" }}>
                    <ReportInformation
                      reportSettings={reportSettings}
                      timeZone={timeZone}
                      hideAndShow={hideAndShow}
                      currentRole={currentRole}
                      reportForEdit={reportForEdit?.report}
                      reportTitleCollapsed={reportTitleCollapsed}
                      setReportTitleCollapsed={setReportTitleCollapsed}
                      reportInspectionNumber={reportInspectionNumber}
                    />
                  </div>

                  <ReportCharts
                    setAnsweredPercent={setAnsweredPercent}
                    setPieChartFail={setPieChartFail}
                    setPieChartSuccess={setPieChartSuccess}
                    hideAndShow={hideAndShow}
                    reportForEdit={reportForEdit?.report}
                    answeredScore={answeredScore}
                    totalQuestion={totalQuestion}
                    failItems={failItems}
                    totalPercentage={totalPercentage}
                    questionScore={questionScore}
                    reportKeyIndicatesCollapsed={reportKeyIndicatesCollapsed}
                    setReportKeyIndicatesCollapsed={setReportKeyIndicatesCollapsed}
                  />

                  <ReportDisclaimer
                    reportForEdit={reportForEdit?.report}
                    hideAndShow={hideAndShow}
                    reportDesclaimCollapsed={reportDesclaimCollapsed}
                    setReportDesclaimCollapsed={setReportDesclaimCollapsed}
                  />

                  <ReportTableOfContent
                    hideAndShow={hideAndShow}
                    handleScroll={handleScroll}
                    reportForEdit={reportForEdit?.report}
                    reportTableContent={reportTableContent}
                    setReportTableContent={setReportTableContent}
                  />

                  <ReportFailSummary
                    answers={answers}
                    failItems={failItems}
                    getAnswer={getAnswer}
                    hideAndShow={hideAndShow}
                    setFailItems={setFailItems}
                    reportForEdit={reportForEdit?.report}
                    showDocumentMedia={showDocumentMedia}
                    reportFailSumCollapsed={reportFailSumCollapsed}
                    setReportFailSumCollapsed={setReportFailSumCollapsed}
                  />
                </Collapse>
              </div>

              <ReportInspectionQuestions
                answers={answers}
                failItems={failItems}
                getAnswer={getAnswer}
                // showQrCode={showQrCode}
                hideAndShow={hideAndShow}
                setFailItems={setFailItems}
                reportForEdit={reportForEdit?.report}
                imageCountData={imageCountData}
                showDocumentMedia={showDocumentMedia}
              />

              {/* ///////////////////////////// Footer ///////////////////////////// */}
              {showDocumentMedia && hideAndShow?.content?.footer ? (
                <footer id="pageFooter">
                  <div className="report-divider line-height-15 mt-5"></div>
                  <div className="row align-items-center">
                    <div id="page-number" className="col-3"></div>
                    <div className="col-6 d-flex justify-content-center">
                      <p className="text-muted">
                        {hideAndShow?.content?.title && reportForEdit?.report?.site?.name + "-"}
                        {hideAndShow?.content?.inspectorName ? reportForEdit?.report?.assignee?.fullName : ""}{" "}
                        {hideAndShow?.content?.footerDate ? (
                          timeZone ? (
                            <strong>{moment(reportForEdit?.report?.startAt).utcOffset(timeZone[1]).format("llll")}</strong>
                          ) : (
                            <strong>{moment(reportForEdit?.report?.startAt).format("llll")}</strong>
                          )
                        ) : null}
                      </p>
                    </div>
                    <div className="col-3 d-flex justify-content-end align-items-center">
                      <span className="logo-wrap">
                        <img width={"200px"} src={PoweredByTamamLogo} alt="logo" />
                      </span>
                    </div>
                  </div>
                </footer>
              ) : null}
            </div>

            {/* //////////////////////////// Document Media //////////////////////////// */}
            {hideAndShow?.content?.photos && imagesInReport && showDocumentMedia ? (
              <div className="report-pdf-view document-media">
                <h4 className="text-center mb-3">
                  <I18n lng="Reports.DocumentMedia" />
                </h4>

                {hideAndShow?.content?.standardQuality ? (
                  <div className="standard-quality-wrapper ">
                    {answers.length
                      ? answers.map((item) => {
                          let data =
                            imageCountData?.length &&
                            imageCountData.filter((subItem) => {
                              return subItem.questionId === item?.questionUuid;
                            });
                          return item?.image?.length
                            ? item?.image?.map((item, index) => {
                                let className = PDF_IMAGE_CLASS(data[0]?.imageCountStart + index + 1);
                                return (
                                  <div key={index + item.image} className={`standard-quality ${className}`}>
                                    <div className="image">
                                      <img src={item.image} alt="loading" />
                                    </div>
                                    {data.length ? (
                                      <p className="image-number text-muted">
                                        <I18n lng="global.Pic" /> {data[0].imageCountStart + index + 1}
                                      </p>
                                    ) : null}
                                  </div>
                                );
                              })
                            : item?.inspectorImages?.length
                            ? item?.inspectorImages?.map((inspectorImage, index) => {
                                let data =
                                  imageCountData?.length &&
                                  imageCountData.filter((subItem) => {
                                    return subItem.questionId === item?.questionUuid;
                                  });
                                let className = PDF_IMAGE_CLASS(data[0]?.imageCountStart + index + 1);
                                return (
                                  <div key={index + inspectorImage.image} className={`standard-quality ${className}`}>
                                    <div className="image">
                                      <img src={inspectorImage.image} alt="loading" />
                                    </div>
                                    {data.length ? (
                                      <p className="image-number text-muted">
                                        <I18n lng="global.Pic" /> {data[0].imageCountStart + index + 1}
                                      </p>
                                    ) : null}
                                  </div>
                                );
                              })
                            : null;
                        })
                      : null}
                  </div>
                ) : ////////// High Qualtiy Images //////////
                hideAndShow?.content?.highQuality ? (
                  <div>
                    {answers.length
                      ? answers.map((item) => {
                          let data =
                            imageCountData?.length &&
                            imageCountData.filter((subItem) => {
                              return subItem.questionId === item?.questionUuid;
                            });
                          return item.image.length
                            ? item.image.map((item, index) => {
                                return (
                                  <div key={index + item.image} className="high-quality">
                                    <img src={item.image} alt="loading" />
                                    {data.length ? (
                                      <p className="text-muted">
                                        <I18n lng="global.Pic" /> {data[0].imageCountStart + index + 1}
                                      </p>
                                    ) : null}
                                  </div>
                                );
                              })
                            : item?.inspectorImages?.length
                            ? item?.inspectorImages?.map((inspectorImage, index) => {
                                let data =
                                  imageCountData?.length &&
                                  imageCountData.filter((subItem) => {
                                    return subItem.questionId === item?.questionUuid;
                                  });
                                return (
                                  <div key={index + inspectorImage.image} className="high-quality">
                                    <img src={inspectorImage.image} alt="loading" />
                                    {data.length ? (
                                      <p className="text-muted">
                                        <I18n lng="global.Pic" /> {data[0].imageCountStart + index + 1}
                                      </p>
                                    ) : null}
                                  </div>
                                );
                              })
                            : null;
                        })
                      : null}
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
      ) : (
        ""
      )}{" "}
    </>
  ) : (
    <h3 style={{ color: "red" }} className="mt-5">
      <I18n lng="global.SorryReportIsDeleted" />
    </h3>
  );
};

const mapStateToProps = ({ adminManagerReports, authentication, company }) => ({
  company: company.company,
  user: authentication.user,
  currentRole: authentication.currentRole,
  reportForEdit: adminManagerReports.reportForEdit,
});

const mapDispatchToProps = {
  getReportByIdForShareData,
  getReportSettings,
  getReportBySettingsId,
};
export default connect(mapStateToProps, mapDispatchToProps)(ReportPdfView);
