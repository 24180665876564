import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { BsPlay } from "react-icons/bs";
import I18n from "../../../../private/languageSelector";
import IconEye from "../../../../../assets/images/icon-eye.svg";
import IconMenu from "../../../../../assets/images/icon-menu.svg";
import IconPencil from "../../../../../assets/images/icon-pencil.svg";
import IconArchive from "../../../../../assets/images/icon-archive.svg";
import IconDispatch from "../../../../../assets/images/icon-dispatch.svg";
import IconBookmark from "../../../../../assets/images/icon-bookmark.png";
import IconUnBookmark from "../../../../../assets/images/icon-bookmark.svg";
import IconDuplicate from "../../../../../assets/images/icon-duplicate.svg";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import FormDispatchDialog from "../form-dispatch/form-dispatch-list";
import { canAccessTheModule } from "../../../../../helper/helperFunctions";
import {
  getAssignedFormsForUser,
  selfPostFormmanagement,
  createBookmark,
  getBookmark,
  deleteBookmark,
} from "../../../../../redux/reducers/form/form.reducer";

const ListItemMenu = ({
  openEditForm,
  openFormView,
  openFormStart,
  createDuplicateForm,
  bookmarkUnbookmarkForm,
  setIsBookmarked,
  isBookmarked,
  selfPostFormmanagement,
  createBookmark,
  getBookmark,
  deleteBookmark,
  openArchvieFormDialog,
  form,
  id,
  user,
  currentRole,
  dispatchFormId,
  rolesAccess,
  loading,
  setLoading,
}) => {
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [bookmarkId, setbookmarkId] = useState();
  const [dispatchDialog, setDispatchDialog] = useState(false);

  const selfFormManagement = (dataObj) => {
    selfPostFormmanagement(dataObj).then((res) => {
      let selfDispatchId = res?.value?.data[0]?.id ? res?.value?.data[0]?.id : res?.value?.data[0]?._id;
      if (selfDispatchId !== undefined) {
        openFormStart(id, selfDispatchId);
      }
    });
  };

  useEffect(() => {
    if (user && id && form) {
      getBookmark(user.id, "FORM").then((res) => {
        const bookmarkedItem = res?.value?.data.find((item) => item?.moduleId === id && user.id === item?.userId);

        if (bookmarkedItem) {
          setIsBookmarked(true);
          setbookmarkId(bookmarkedItem.id);
        } else {
          setIsBookmarked(false);
          setbookmarkId(null);
        }

        setLoading(false);
      });
    }
    // eslint-disable-next-line
  }, [loading]);

  return (
    <>
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle>
          <img src={IconMenu} alt="Menu" />
        </DropdownToggle>
        <DropdownMenu right>
          {(currentRole === "user" || currentRole === "admin" || (currentRole !== "user" && user?.role === "manager/inspector")) &&
            form.status !== "submit" && (
              <DropdownItem
                className="action action-start"
                onClick={() => {
                  if (currentRole === "admin" || (user?.role === "manager/inspector" && currentRole === "manager")) {
                    let dataObj = {
                      managerId: user?.id,
                      formId: id,
                    };
                    selfFormManagement(dataObj);
                  } else if (currentRole === "user") {
                    openFormStart(id, dispatchFormId);
                  }
                }}
              >
                <span className="icon-wrap">
                  <BsPlay size={"1.2rem"} color={"#20639B"} />
                </span>
                <I18n lng="global.Start" />
              </DropdownItem>
            )}
          <DropdownItem className="action action-view" onClick={() => openFormView(id, dispatchFormId)}>
            <span className="icon-wrap">
              <img src={IconEye} alt="IconEye" />
            </span>
            <I18n lng="global.View" />
          </DropdownItem>
          {currentRole !== "user" ? (
            <DropdownItem
              className="action action-bookmark"
              onClick={(e) => {
                e.preventDefault();
                // bookmarkUnbookmarkForm(form);
              }}
            >
              {isBookmarked ? (
                <>
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      deleteBookmark(bookmarkId).then(() => {
                        setLoading(true);
                      });
                    }}
                  >
                    <span className="icon-wrap">
                      <img src={IconUnBookmark} alt="IconBookmark" />
                    </span>
                    <I18n lng="global.UnBookmark" />
                  </div>
                </>
              ) : (
                <>
                  <div
                    onClick={(e) => {
                      let data = {
                        module: "FORM",
                        moduleId: id,
                      };
                      e.preventDefault();
                      createBookmark(data).then(() => {
                        setLoading(true);
                      });
                    }}
                  >
                    <span className="icon-wrap">
                      <img src={IconBookmark} alt="IconBookmark" />
                    </span>
                    <I18n lng="global.Bookmark" />
                  </div>
                </>
              )}
            </DropdownItem>
          ) : (
            <DropdownItem
              className="action action-bookmark"
              onClick={(e) => {
                e.preventDefault();
                // bookmarkUnbookmarkForm(form);
              }}
            >
              {isBookmarked ? (
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    deleteBookmark(bookmarkId).then(() => {
                      setLoading(true);
                    });
                    // bookmarkUnbookmarkForm(form);
                  }}
                >
                  <span className="icon-wrap">
                    <img src={IconUnBookmark} alt="IconBookmark" />
                  </span>
                  <I18n lng="global.UnBookmark" />
                </div>
              ) : (
                <>
                  <div
                    onClick={(e) => {
                      let data = {
                        module: "FORM",
                        moduleId: id,
                      };
                      e.preventDefault();
                      createBookmark(data).then(() => {
                        setLoading(true);
                      });
                    }}
                  >
                    <span className="icon-wrap">
                      <img src={IconBookmark} alt="IconBookmark" />
                    </span>
                    <I18n lng="global.Bookmark" />
                  </div>
                </>
              )}
            </DropdownItem>
          )}

          {currentRole !== "user" ? (
            <>
              {canAccessTheModule(
                rolesAccess?.form?.access?.edit,
                <DropdownItem
                  className="action action-edit"
                  onClick={(e) => {
                    e.preventDefault();
                    localStorage.setItem("formState", "forEdit");
                    openEditForm(id);
                  }}
                >
                  <span className="icon-wrap">
                    <img src={IconPencil} alt="IconPencil" />
                  </span>
                  <I18n lng="global.Edit" />
                </DropdownItem>
              )}
              {canAccessTheModule(
                rolesAccess?.form?.access?.create,
                <DropdownItem
                  className="action action-duplicate"
                  onClick={(e) => {
                    e.preventDefault();
                    createDuplicateForm(form);
                  }}
                >
                  <span className="icon-wrap">
                    <img src={IconDuplicate} alt="IconDuplicate" />
                  </span>
                  <I18n lng="global.Duplicate" />
                </DropdownItem>
              )}

              <DropdownItem
                className="action action-dispatch"
                onClick={(e) => {
                  e.preventDefault();
                  setDispatchDialog(true);
                }}
              >
                <span className="icon-wrap">
                  <img src={IconDispatch} alt="IconDispatch" />
                </span>
                <I18n lng="global.Dispatch" />
              </DropdownItem>

              {canAccessTheModule(
                rolesAccess?.form?.access?.archive,
                <DropdownItem
                  className="action action-archive"
                  onClick={(e) => {
                    e.preventDefault();
                    openArchvieFormDialog(form.id, form);
                  }}
                >
                  <span className="icon-wrap">
                    <img src={IconArchive} alt="IconArchive" />
                  </span>
                  <I18n lng="global.Archive" />
                </DropdownItem>
              )}
            </>
          ) : null}
        </DropdownMenu>
      </Dropdown>
      <FormDispatchDialog formId={id} show={dispatchDialog} onHide={() => setDispatchDialog(false)} />
    </>
  );
};

const mapStateToProps = ({ authentication }) => ({
  user: authentication.user,
  currentRole: authentication.currentRole,
  rolesAccess: authentication.userRoleAccess,
});

const mapDispatchToProps = {
  getAssignedFormsForUser,
  selfPostFormmanagement,
  createBookmark,
  getBookmark,
  deleteBookmark,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListItemMenu);
