import React from "react";
import TextArea from "../../../../../components/form-input/text-Area";
import Radio from "../../../../../components/form-input/radio.component";
import CheckBox from "../../../../../components/form-input/checkbox.component";
import ToggleSwitch from "../../../../../components/form-input/input.switch.component";
import { i18n } from "../../../languageSelector";

const ReportsEditFilterContent = ({ reportSetting, setReportSettings }) => {
  return (
    <>
      <ul className="list-unstyled px-4 m-0 font-weight-bold">
        <li>
          <ToggleSwitch
            label={i18n("Form.Date&Time")}
            id="date"
            selected={reportSetting?.content?.dateTime}
            checked={reportSetting?.content?.dateTime}
            onChange={(e) => {
              setReportSettings({
                ...reportSetting,
                content: {
                  ...reportSetting.content,
                  dateTime: !e.target.selected,
                },
              });
            }}
          />
        </li>
        <li>
          <ToggleSwitch
            label={i18n("global.DocumentNo")}
            id="docNumber"
            selected={reportSetting?.content?.docNumber}
            checked={reportSetting?.content?.docNumber}
            onChange={(e) => {
              setReportSettings({
                ...reportSetting,
                content: {
                  ...reportSetting.content,
                  docNumber: !e.target.selected,
                },
              });
            }}
          />
        </li>
        <li>
          <ToggleSwitch
            label={i18n("global.Reports") + " " + i18n("global.Statistics")}
            id="reoprtStatistics"
            selected={reportSetting?.content?.reoprtStatistics}
            checked={reportSetting?.content?.reoprtStatistics}
            onChange={(e) => {
              if (!e.target.selected) {
                setReportSettings({
                  ...reportSetting,
                  content: {
                    ...reportSetting.content,
                    reoprtStatistics: !e.target.selected,
                    scores: true,
                    failsCount: true,
                    unAnsweredQuestions: true,
                  },
                });
              } else {
                setReportSettings({
                  ...reportSetting,
                  content: {
                    ...reportSetting.content,
                    reoprtStatistics: !e.target.selected,
                    scores: false,
                    failsCount: false,
                    unAnsweredQuestions: false,
                  },
                });
              }
            }}
          />
        </li>
        <li>
          {reportSetting?.content?.reoprtStatistics ? (
            <ul>
              <li>
                <CheckBox
                  label={i18n("Form.Score")}
                  name="Scores"
                  checked={reportSetting?.content?.scores}
                  onChange={(e) =>
                    setReportSettings({
                      ...reportSetting,
                      content: {
                        ...reportSetting.content,
                        scores: e.target.checked,
                      },
                    })
                  }
                />
              </li>
              <li>
                <CheckBox
                  label={i18n("Reports.FailedItems")}
                  name="failsCount"
                  checked={reportSetting?.content?.failsCount}
                  onChange={(e) =>
                    setReportSettings({
                      ...reportSetting,
                      content: {
                        ...reportSetting.content,
                        failsCount: e.target.checked,
                      },
                    })
                  }
                />
              </li>
              <li>
                <CheckBox
                  label={i18n("Form.UnAnsweredQuestions")}
                  name="unAnsweredQue"
                  checked={reportSetting?.content?.unAnsweredQuestions}
                  onChange={(e) =>
                    setReportSettings({
                      ...reportSetting,
                      content: {
                        ...reportSetting.content,
                        unAnsweredQuestions: e.target.checked,
                      },
                    })
                  }
                />
              </li>
            </ul>
          ) : (
            ""
          )}
        </li>
        <li>
          <ToggleSwitch
            label={
              i18n("Reports.FailedItems") + " " + i18n("Subscriptions.Summary")
            }
            id="failsSummary"
            selected={reportSetting?.content?.failsSummary}
            checked={reportSetting?.content?.failsSummary}
            onChange={(e) => {
              setReportSettings({
                ...reportSetting,
                content: {
                  ...reportSetting.content,
                  failsSummary: !e.target.selected,
                },
              });
            }}
          />
        </li>
        <li>
          <ToggleSwitch
            label={i18n("Form.Instruction")}
            id="instructions"
            selected={reportSetting?.content?.instructions}
            checked={reportSetting?.content?.instructions}
            onChange={(e) => {
              setReportSettings({
                ...reportSetting,
                content: {
                  ...reportSetting.content,
                  instructions: !e.target.selected,
                },
              });
            }}
          />
        </li>
        <li>
          <ToggleSwitch
            label={i18n("global.InspectionQuestions")}
            id="inspectionQue"
            checked={reportSetting.content.inspectionQuestions}
            onChange={(e) =>
              setReportSettings({
                ...reportSetting,
                content: {
                  ...reportSetting.content,
                  inspectionQuestions: e.target.checked,
                },
              })
            }
          />
          {reportSetting.content.inspectionQuestions && (
            <ul>
              <li>
                <Radio
                  label={i18n("Form.Answered")}
                  id="answered"
                  name="inspectionQue"
                  checked={reportSetting.content.answered}
                  onClick={(e) =>
                    setReportSettings({
                      ...reportSetting,
                      content: {
                        ...reportSetting.content,
                        answered: e.target.checked,
                        unAnswered: false,
                        all: false,
                      },
                    })
                  }
                />
              </li>
              <li>
                <Radio
                  label={i18n("Form.UnAnsweredQuestions")}
                  id="unAnswered"
                  name="inspectionQue"
                  checked={reportSetting.content.unAnswered}
                  onClick={(e) =>
                    setReportSettings({
                      ...reportSetting,
                      content: {
                        ...reportSetting.content,

                        answered: false,
                        unAnswered: e.target.checked,
                        all: false,
                      },
                    })
                  }
                />
              </li>
              <li>
                <Radio
                  label={i18n("global.All")}
                  id="all"
                  name="inspectionQue"
                  checked={reportSetting.content.all}
                  onClick={(e) =>
                    setReportSettings({
                      ...reportSetting,
                      content: {
                        ...reportSetting.content,
                        answered: false,
                        unAnswered: false,
                        all: e.target.checked,
                      },
                    })
                  }
                />
              </li>
            </ul>
          )}
        </li>
        <li>
          <ToggleSwitch
            label={i18n("global.Photo")}
            id="photos"
            selected={reportSetting?.content?.photos}
            checked={reportSetting?.content?.photos}
            onChange={(e) => {
              setReportSettings({
                ...reportSetting,
                content: {
                  ...reportSetting.content,
                  photos: !e.target.selected,
                },
              });
            }}
          />

          {reportSetting.content.photos && (
            <ul>
              <li>
                <Radio
                  label={i18n("global.StandardQuality")}
                  id="photoStandardQuality"
                  name="photos"
                  checked={reportSetting.content.standardQuality}
                  onClick={(e) =>
                    setReportSettings({
                      ...reportSetting,
                      content: {
                        ...reportSetting.content,
                        standardQuality: e.target.checked,
                        highQuality: false,
                      },
                    })
                  }
                />
              </li>
              <li>
                <Radio
                  label={i18n("global.HighQuality")}
                  id="photoHighQuality"
                  name="photos"
                  checked={reportSetting.content.highQuality}
                  onClick={(e) =>
                    setReportSettings({
                      ...reportSetting,
                      content: {
                        ...reportSetting.content,
                        standardQuality: false,
                        highQuality: e.target.checked,
                      },
                    })
                  }
                />
              </li>
            </ul>
          )}
        </li>
        <li>
          <ToggleSwitch
            label={i18n("global.QrCodes")}
            id="pdfLinksQR"
            selected={reportSetting?.content?.PdfLinksQrCode}
            checked={reportSetting?.content?.PdfLinksQrCode}
            onChange={(e) => {
              setReportSettings({
                ...reportSetting,
                content: {
                  ...reportSetting.content,
                  PdfLinksQrCode: !e.target.selected,
                },
              });
            }}
          />
        </li>
        <li>
          <ToggleSwitch
            label={i18n("Reports.TableOfContents")}
            id="tableOfContents"
            selected={reportSetting?.content?.tableOfContents}
            checked={reportSetting?.content?.tableOfContents}
            onChange={(e) => {
              setReportSettings({
                ...reportSetting,
                content: {
                  ...reportSetting.content,
                  tableOfContents: !e.target.selected,
                },
              });
            }}
          />
        </li>
        <li>
          <ToggleSwitch
            label={
              i18n("CompanySetup.Company") +
              " " +
              i18n("UserProfile.Informations")
            }
            id="companyInfo"
            selected={reportSetting?.content?.companyInformation}
            checked={reportSetting?.content?.companyInformation}
            onChange={(e) => {
              setReportSettings({
                ...reportSetting,
                content: {
                  ...reportSetting.content,
                  companyInformation: !e.target.selected,
                },
              });
            }}
          />
        </li>
        <li>
          <ToggleSwitch
            label={i18n("Reports.Disclaimer")}
            id="disclaimer"
            checked={reportSetting?.content?.showDisclaimer}
            selected={reportSetting?.content?.showDisclaimer}
            onChange={(e) => {
              setReportSettings({
                ...reportSetting,
                content: {
                  ...reportSetting.content,
                  showDisclaimer: !e.target.selected,
                },
              });
            }}
          />
        </li>
        {reportSetting.content?.showDisclaimer && (
          <TextArea
            rows="5"
            value={reportSetting?.content?.disclaimer}
            onChange={(e) => {
              setReportSettings({
                ...reportSetting,
                content: {
                  ...reportSetting.content,
                  disclaimer: e.target.value,
                },
              });
            }}
          />
        )}
        <li>
          <ToggleSwitch
            label={i18n("global.Footer")}
            id="footerId"
            checked={reportSetting?.content?.footer}
            selected={reportSetting?.content?.footer}
            onChange={(e) =>
              setReportSettings({
                ...reportSetting,
                content: {
                  ...reportSetting.content,
                  footer: e.target.checked,
                },
              })
            }
          />
          {reportSetting.content.footer && (
            <ul>
              <li>
                <CheckBox
                  label={i18n("Form.Page")}
                  name="pageIdFooter"
                  checked={reportSetting?.content?.page}
                  onChange={(e) =>
                    setReportSettings({
                      ...reportSetting,
                      content: {
                        ...reportSetting.content,
                        page: e.target.checked,
                      },
                    })
                  }
                />
              </li>
              <li>
                <CheckBox
                  label={i18n("global.SiteName")}
                  name="pageIdTitle"
                  checked={reportSetting?.content?.title}
                  onChange={(e) =>
                    setReportSettings({
                      ...reportSetting,
                      content: {
                        ...reportSetting.content,
                        title: e.target.checked,
                      },
                    })
                  }
                />
              </li>
              <li>
                <CheckBox
                  label={i18n("Form.Date")}
                  name="pageIdDate"
                  checked={reportSetting?.content?.footerDate}
                  onChange={(e) =>
                    setReportSettings({
                      ...reportSetting,
                      content: {
                        ...reportSetting.content,
                        footerDate: e.target.checked,
                      },
                    })
                  }
                />
              </li>
              <li>
                <CheckBox
                  label={i18n("global.Inspector") + " " + i18n("global.Name")}
                  name="pageIdInspectorName"
                  checked={reportSetting?.content?.inspectorName}
                  onChange={(e) =>
                    setReportSettings({
                      ...reportSetting,
                      content: {
                        ...reportSetting.content,
                        inspectorName: e.target.checked,
                      },
                    })
                  }
                />
              </li>
            </ul>
          )}
        </li>
      </ul>
    </>
  );
};

export default ReportsEditFilterContent;
