/** @format */
import moment from "moment";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import PlanDialog from "../../../../../components/dialogs/PlanDialog";
import { getSubscriptionByCompanyId, updateSubscriptionData } from "../../../../../redux/reducers/subscription/subscription.reducer";
import I18n, { i18n } from "../../../languageSelector";
import CardAddDialog from "./card-details/add-card-dialog";
import SubscriptionsCardDialog from "./card-details/subscriptions-card-details";
import SubscriptionsDetailDialog from "./subscriptions-details";
import { TEXT_DIRECTION } from "../../../../../helper/helperFunctions";
import SubscriptionBillingDetails from "./subscription-billing-details";
import PricingPlanDialog from "../../../../../components/dialogs/PricingPlanDialog";
import ChargeBillingDetails from "./charge-billing-details";
import { getAllUsers } from "../../../../../redux/reducers/user/user.reducer";
import { getSession } from "../../../../../redux/reducers/authentication/authentication";
import { companyPaymentCardTranslation, subscriptionsTranslation } from "../../../../../config/constants";
import { getInvoices } from "../../../../../redux/reducers/invoices/invoices.reducer";
import { getCard, getCompanyById, verifyCard } from "../../../../../redux/reducers/company/company.reducer";

const SubscriptionsView = ({
  user,
  getSubscriptionByCompanyId,
  subscriptionData,
  updateSubscriptionData,
  allUsers,
  getAllUsers,
  getInvoices,
  getCard,
  verifyCard,
  getSession,
  getCompanyById,
}) => {
  const [showUpdatePlanModal, setShowUpdatePlanModal] = useState(false);
  const [showAddPaymentModal, setShowAddPaymentModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [showDetailsDialog, setShowDetailsDialog] = useState(false);
  const [showUpdateSuccessModal, setShowUpdateSuccessModal] = useState(false);
  const [trialDate, setTrialDate] = useState(null);
  const [showCardDialog, setShowCardDialog] = useState(false);
  const [edit, setEdit] = useState(false);
  const [cardIndex, setCardIndex] = useState(null);
  const [cardToEdit, setCardToEdit] = useState({});
  const [subscriptionBillingDailog, setSubsriptionBillingDailog] = useState(false);
  const [chargeBillingDetailsDailog, setChargeBillingDetailsDailog] = useState(false);
  const [cancelSubscriptionModal, setCancelSubscriptionModal] = useState(false);
  const [showAlertCancelTrial, setShowAlertCancelTrial] = useState(false);
  const [unsubscribeModal, setUnsubscribeModal] = useState(false);
  const [unsubscribeAndDisbandedTeamModal, setUnsubscribeAndDisbandedTeamModal] = useState(false);
  const [TrialStarted, setTrialStarted] = useState(false);
  const [isUpdatePlanClick, setIsUpdatePlanClick] = useState(false);
  const [showPricingPlanModal, setShowPricingPlanModal] = useState(false);
  const [showRevertBackFreePlanModal, setShowRevertBackFreePlanModal] = useState(false);
  const [isTrialExpired, setIsTrialExpired] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState("");
  const [revertBackFreePlanModalMoreThanTenUser, setRevertBackFreePlanModalMoreThanTenUser] = useState(false);
  const [showUnsubscribeModal, setShowUnsubscribeModal] = useState(false);
  const [showUnsubscribeConfirmModal, setShowUnsubscribeCofirmModal] = useState(false);
  const [usedSeatsCount, setUsedSeatsCount] = useState(
    subscriptionData?.currentPlan?.plan === "Monthly Subscription" ? 79 * subscriptionData?.seats?.total : 790 * subscriptionData?.seats?.total
  );

  const [showAfterResumeSubscriptionModal, setShowAfterResumeSubscriptionModal] = useState(false);

  const [taxSeatsCount, setTaxSeatsCount] = useState(usedSeatsCount * 0.15);
  const [totalAmountCount, setTotalAmountCount] = useState(usedSeatsCount + taxSeatsCount);
  const [showResumeSubscriptionModal, setShowResumeSubscriptionModal] = useState(false);
  const [showPausedSubscriptionsModal, setShowPausedSubscriptionsModal] = useState(false);
  const [showExpiredSubscriptionsModal, setShowExpiredSubscriptionsModal] = useState(false);
  const [showSubscriptionExpiredModal, setShowSubscriptionExpiredModal] = useState(false);
  const [updateSeatsModal, setUpdateSeatsModal] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [allInvoices, setAllInvoices] = useState([]);
  const [revertToFreePlanAfterExpired, setRevertToFreePlanAfterExpired] = useState(false);
  const [companyPaymentCard, setCompanyPaymentCard] = useState();
  const [getCardLoading, setGetCardLoading] = useState(false);

  const monthlyInvoices = invoices.filter((item) => item.subscriptionData.currentPlan.plan === "Monthly Subscription");
  const lastMonthlyInvoice = monthlyInvoices.pop();

  const lastInvoice = invoices[0];
  const lastInvoicePlan = lastInvoice?.subscriptionData?.currentPlan?.price === 79 ? "Monthly Subscription" : "Yearly Subscription";

  let totalCostBefore =
    lastInvoice?.subscriptionData?.currentPlan?.plan === "Additional seats"
      ? lastInvoice?.subscriptionData?.lastAdditionalseats?.seats * lastInvoice?.subscriptionData?.lastAdditionalseats?.lastPerSeatBill
      : lastInvoice?.subscriptionData?.currentPlan?.seats * lastInvoice?.subscriptionData?.currentPlan?.price;

  const withTax = totalCostBefore * 0.15;
  const totalCost = withTax + totalCostBefore;

  const Language = user?.language;

  useEffect(() => {
    const fetchSubscriptionData = async () => {
      getSubscriptionByCompanyId(user?.company?.id);
    };
    fetchSubscriptionData();
  }, [getSubscriptionByCompanyId, user?.company?.id]);

  useEffect(() => {
    if (user?.company) {
      getSubscriptionByCompanyId(user?.company?.id);
      getAllUsers();
    }
  }, [
    user?.company,
    getSubscriptionByCompanyId,
    showUpdatePlanModal,
    getAllUsers,
    showDetailsDialog,
    showAfterResumeSubscriptionModal,
    isUpdatePlanClick,
    chargeBillingDetailsDailog,
    subscriptionData?.currentPlan?.plan,
  ]);

  useEffect(() => {
    const fetchInvoices = async () => {
      const response = await getInvoices();
      setInvoices(response?.value?.data);
    };
    fetchInvoices();
  }, [getInvoices]);

  useEffect(() => {
    setTotalAmountCount(usedSeatsCount + taxSeatsCount);
    // eslint-disable-next-line
  }, [showDetailsDialog]);

  useEffect(() => {
    const hasModalBeenShown = localStorage.getItem("hasModalBeenShown");

    const hasSubscriptionPausedModalShown = localStorage.getItem("hasSubscriptionPausedModalShown");

    const hasSubscriptionExpiredModalShown = localStorage.getItem("hasSubscriptionExpiredModalShown");

    if (
      !hasModalBeenShown &&
      subscriptionData?.userTrial?.isTrialExpired &&
      subscriptionData?.currentPlan?.status === "Expired" &&
      !subscriptionData?.subscriptionInfo?.isSubscribed
    ) {
      setIsTrialExpired(true);
      localStorage.setItem("hasModalBeenShown", "true");
    }

    if (!hasSubscriptionPausedModalShown && subscriptionData?.currentPlan?.status === "Paused" && subscriptionData?.subscriptionInfo?.isSubscribed) {
      setShowPausedSubscriptionsModal(true);
      localStorage.setItem("hasSubscriptionPausedModalShown", "true");
    }

    if (
      !hasSubscriptionExpiredModalShown &&
      subscriptionData?.currentPlan?.status === "Expired" &&
      subscriptionData?.subscriptionInfo?.isSubscribed &&
      subscriptionData?.subscriptionInfo?.isSubscribedExpired
    ) {
      setShowExpiredSubscriptionsModal(true);
      localStorage.setItem("hasSubscriptionExpiredModalShown", "true");
    }

    setSelectedPlan(
      subscriptionData?.currentPlan?.plan === "Monthly Subscription" || subscriptionData?.currentPlan?.plan === "Yearly Subscription"
        ? subscriptionData?.currentPlan?.plan
        : ""
    );
  }, [subscriptionData]);

  useEffect(() => {
    if (companyPaymentCard) {
      setCardToEdit(companyPaymentCard);
      setCardIndex(1);
    }
  }, [companyPaymentCard]);

  const trialNextBillFormatedDate = moment(subscriptionData?.trialEndedAt).format("DD MMM YYYY");
  const subscriptionNextBillFormatedDate = moment(subscriptionData?.subscriptionEndedAt).format("DD MMM YYYY");

  const startTrialData = {
    currentPlan: {
      plan: "Trial",
      status: "Running",
      seats: subscriptionData?.seats?.used,
      price: 0,
    },
    seats: {
      used: subscriptionData?.seats?.used,
      available: 0,
      total: subscriptionData?.seats?.used,
    },
    userTrial: {
      isTrialStarted: true,
      isTrialCancelled: false,
      isTrialExpired: false,
    },
  };

  useEffect(() => {
    if (showPricingPlanModal) {
      if (subscriptionData?.currentPlan?.plan === "Monthly Subscription" || subscriptionData?.currentPlan?.plan === "Yearly Subscription") {
        setSelectedPlan(
          subscriptionData?.currentPlan?.plan === "Monthly Subscription" || subscriptionData?.currentPlan?.plan === "Yearly Subscription"
            ? subscriptionData?.currentPlan?.plan
            : ""
        );
      }
    }
  }, [
    user?.company,
    getSubscriptionByCompanyId,
    getAllUsers,
    isUpdatePlanClick,
    subscriptionData?.currentPlan?.plan,
    showPricingPlanModal,
  ]);

  const formattedTrialEndDate = moment(trialDate).format("DD MMM YYYY");

  const StartFreeTrialHandler = async () => {
    const responseData = await updateSubscriptionData(subscriptionData?.id, startTrialData, "Update");
    setTrialDate(responseData?.value?.data?.trialEndedAt);
    setShowUpdatePlanModal(false);
    if (isUpdatePlanClick) {
      setShowUpdateSuccessModal(true);
    } else {
      setTrialStarted(true);
    }
  };

  const closeAddPaymentModal = () => {
    setShowAddPaymentModal(false);
  };

  const openUpdatePlanModal = (data) => {
    setModalData(data);
    setShowUpdatePlanModal(true);
  };

  const closeUpdatePlanModal = () => {
    setShowUpdatePlanModal(false);
  };

  const freeTrialData = {
    title: <I18n lng="global.SubscriptionRequired" />,
    text_one: <I18n lng="Subscriptions.YourCurrentPlanIsFreeYouCanAddMoreSeatsIfYouAreSubscribedOnly" />,
    text_two: <I18n lng="Subscriptions.StartYour30dayFreeTrialWithNoCreditCardRequired" />,
    closeButtonText: <I18n lng="global.Close" />,
    actionButtonText: <I18n lng="global.StartFreeTrial" />,
  };

  const updatePlanData = {
    title: <I18n lng="Subscriptions.UpdatePlanTrialStartedModalTitle" />,
    text_one: (
      <>
        <I18n lng="Subscriptions.YourTrialHasStartedSuccessfully" />
        {formattedTrialEndDate}
      </>
    ),
    closeButtonText: <I18n lng="global.Close" />,
    actionButtonText: i18n("global.UpdatePlan"),
  };

  const TrySubscriptionForFreeData = {
    title: <I18n lng="Subscriptions.TrySubscriptionForFree" />,
    text_two: <I18n lng="Subscriptions.StartYour30dayFreeTrialWithNoCreditCardRequired" />,
    closeButtonText: <I18n lng="global.Close" />,
    actionButtonText: <I18n lng="global.StartFreeTrial" />,
  };

  const cancelSubscriptionModalData = {
    title: i18n("Subscriptions.CancelTrial"),
    text_one: <I18n lng="Subscriptions.AreYouSureYouWantToCancelTheTrialAndGoRevertToFreePlan" />,
    closeButtonText: <I18n lng="global.Close" />,
    actionButtonText: i18n("Subscriptions.CancelTrialForSubscriptions"),
  };

  const revertBackFreePlanModalData = {
    title: i18n("Subscriptions.CancelTrial"),
    text_one: <I18n lng="Subscriptions.AreYouSureYouWantToCancelTrialRevertBackToFreePlan" />,
    text_two: <I18n lng="Subscriptions.IfYouChooseToReturnToTheFreePlanYourTeamWillBeDisbanded" />,
    closeButtonText: <I18n lng="global.Close" />,
    actionButtonText: i18n("Subscriptions.RevertToFreePlan"),
  };

  const userTenSubscriptionModalData = {
    title: i18n("Subscriptions.CancelTrialForSubscriptions"),
    text_one: <I18n lng="Subscriptions.AreYouSureYouWantToCancelTheTrialAndGoRevertToFreePlan" />,
    text_two: <I18n lng="Subscriptions.IfYouChooseToReturnToTheFreePlanYourTeamWillBeDisbanded" />,
    closeButtonText: <I18n lng="global.Close" />,
    actionButtonText: i18n("Subscriptions.CancelTrialForSubscriptions"),
  };

  const unsubscribeModalData = {
    title: <I18n lng="Subscriptions.Unsubscribed" />,
    // here i have to send the text conditionally that text
    text_one: <I18n lng="Subscriptions.YouHaveUnsubscribedNoteThatAllUsersAreAdminsNow" />,
    closeButtonText: <I18n lng="global.Close" />,
  };

  const unsubscribeAndDisbandedModalData = {
    title: <I18n lng="Subscriptions.Unsubscribed" />,
    text_one: <I18n lng="Subscriptions.YouHaveUnsubscribedNoteThatYourTeamHaBbeenDisbandedFormsReportsOnlyAccessibleToYou" />,
    closeButtonText: <I18n lng="global.Close" />,
  };

  const trialStartedData = {
    title: <I18n lng="Subscriptions.UpdatePlanTrialStartedModalTitle" />,
    text_one: (
      <>
        <span>
          <I18n lng="Subscriptions.YourTrialHasStartedSuccessfully" />
        </span>
        <span className="ml-1">{formattedTrialEndDate}</span>
      </>
    ),
    closeButtonText: <I18n lng="global.Close" />,
  };

  const pricingPlanModalData = {
    title: <I18n lng="Subscriptions.SelectYourPricingPlan" />,
    text_one: <I18n lng="Subscriptions.PriceIsExclusiveOfTaxes" />,
    text_two: <I18n lng="Subscriptions.YouWillBeChargedAutomaticallyAtThe" />,
    text_three: <I18n lng="Subscriptions.startOfEachBillingPeriodUntilYouCancel" />,
    actionButtonText: <I18n lng="Subscriptions.Continue" />,
    closeButtonText: <I18n lng="global.Cancel" />,
  };

  const trialExpiredData = {
    title: <I18n lng="Subscriptions.TrialExpired" />,
    text_one: <I18n lng="Subscriptions.YourTrialPeriodHasExpiredYouCanSubscribeToEnjoyFullBenefitsORCancelTrial" />,
    closeButtonText: <I18n lng="Subscriptions.Subscribe" />,
  };

  const unSubsubscribeModalData = {
    title: <I18n lng="Subscriptions.Unsubscribe" />,
    text_one: <I18n lng="Subscriptions.AreYouSureYouWantToUnsubscribeAndRevertBackToFreePlan" />,
    text_two: <I18n lng="Subscriptions.IfYouChooseToReturnToTheFreePlanYourTeamWillBeDisbanded" />,
    actionButtonText: i18n("Subscriptions.Unsubscribe"),
    closeButtonText: <I18n lng="global.Close" />,
  };

  const unSubsubscribeConfirmModalData = {
    title: <I18n lng="Subscriptions.ConfirmUnsubscribe" />,
    text_one: <I18n lng="Subscriptions.WriteWordUUnsubscribeToCancelYourSubscription" />,
    actionButtonText: i18n("Subscriptions.Submit"),
    closeButtonText: <I18n lng="global.Close" />,
  };

  const resumeSubscriptionModalData = {
    title: <I18n lng="Subscriptions.ResumesSubscription" />,
    text_one: i18n("Subscriptions.ClickResumeSubscriptionToConfirmYourDesireToContinueAccessingAllFeaturesBenefits"),
    actionButtonText: i18n("Subscriptions.ResumesSubscription"),
    closeButtonText: <I18n lng="global.Close" />,
  };

  const afterResumeSubscriptionModalData = {
    title: <I18n lng="Subscriptions.ResumesSubscription" />,
    text_one: <I18n lng="Subscriptions.YourSubscriptionHasBeenSuccessfullyResumedYourRegularBillingCycleWillBeginOn" />,
    text_two: moment(subscriptionData?.subscriptionEndedAt).format("MMMM D, YYYY"),
    closeButtonText: <I18n lng="global.Close" />,
  };

  const showPausedSubscriptionsModalData = {
    title: <I18n lng="Subscriptions.UpdateCreditCard" />,
    text_one: (
      <I18n lng="Subscriptions.TamamHasBeenUnableToProcessYourCreditCardToMaintainUninterruptedServiceKindlyUpdateYourCreditCardInformation" />
    ),
    secondaryButtonLabel: <I18n lng="global.Update" />,
  };

  const showSubscriptionExpiredModalData = {
    title: <I18n lng="Subscriptions.SubscriptionExpired" />,
    text_one: i18n("Subscriptions.YourSubscriptionHasExpiredYouCanSubscribeToEnjoyFullBenefitsUnsubscribe"),
    actionButtonText: i18n("Subscriptions.Subscribe"),
    closeButtonText: <I18n lng="global.Close" />,
  };

  const showSubscriptionExpiredModalAfterRevertToFreePlanData = {
    title: <I18n lng="global.SubscriptionRequired" />,
    text_one: <I18n lng="Subscriptions.YourCurrentPlanIsFreeYouCanAddMoreSeatsIfYouAreSubscribedOnly" />,
    actionButtonText: i18n("Subscriptions.Subscribe"),
    closeButtonText: <I18n lng="global.Close" />,
  };

  const updateSeatsModalData = {
    title: <I18n lng="Subscriptions.PlanUpdated" />,
    text_one: <I18n lng="Subscriptions.YourPlanHasBeenUpdatedSuccessfully" />,
    secondaryButtonLabel: <I18n lng="global.Close" />,
  };

  const revertToFreePlanAfterExpiredData = {
    title: <I18n lng="Subscriptions.RevertToFreePlan" />,
    text_one: <I18n lng="Subscriptions.AreYouSureYouWantToRevertBackToFreePlan" />,
    text_two: <I18n lng="Subscriptions.IfYouChooseToReturnToTheFreePlanYourTeamWillBeDisbanded" />,
    actionButtonText: i18n("Subscriptions.RevertToFreePlan"),
    closeButtonText: <I18n lng="global.Close" />,
  };

  const cancelTrialData = {
    currentPlan: {
      plan: "Free",
      status: "Free",
      seats: 10,
      price: 0,
    },
    seats: {
      used: subscriptionData?.seats?.used > 10 ? 1 : subscriptionData?.seats?.used,
      available: subscriptionData?.seats?.used > 10 ? 9 : 10 - subscriptionData?.seats?.used,
      total: 10,
    },
    userTrial: {
      isTrialStarted: false,
      isTrialCancelled: true,
      isTrialExpired: true,
    },
  };
  const cancelTrialDataIfExpired = {
    currentPlan: {
      plan: "Free",
      status: "Free",
      seats: 10,
      price: 0,
    },
    seats: {
      used: subscriptionData?.seats?.used > 10 ? 1 : subscriptionData?.seats?.used,
      available: subscriptionData?.seats?.used > 10 ? 9 : 10 - subscriptionData?.seats?.used,
      total: 10,
    },
    userTrial: {
      isTrialStarted: false,
      isTrialCancelled: true,
      isTrialExpired: true,
    },
  };

  const unsubscribedFromSubscription = {
    currentPlan: {
      plan: "Free",
      status: "Free",
      seats: 10,
      price: 0,
    },
    seats: {
      used: subscriptionData?.seats?.used > 10 ? 1 : subscriptionData?.seats?.used,
      available: subscriptionData?.seats?.used > 10 ? 9 : 10 - subscriptionData?.seats?.used,
      total: 10,
    },
    userTrial: {
      isTrialStarted: false,
      isTrialCancelled: true,
      isTrialExpired: true,
    },
    subscriptionInfo: {
      isSubscribed: false,
      isUnsubscribed: true,
      isSubscribeResumed: false,
      isSubscribedExpired: false,
    },
  };

  const unsubscribeAndSubscriptionEndedHaveTime = {
    currentPlan: {
      plan: subscriptionData?.currentPlan?.plan,
      status: "Unsubscribed, subscription is running",
      seats: subscriptionData?.currentPlan?.seats,
      price: subscriptionData?.currentPlan?.price,
    },
    seats: {
      used: subscriptionData?.seats?.used,
      available: subscriptionData?.seats?.available,
      total: subscriptionData?.seats?.total,
    },
    subscriptionInfo: {
      isSubscribed: true,
      isUnsubscribed: true,
      isSubscribedExpired: false,
      isSubscribeResumed: false,
    },
  };

  const unsubscribeAndSubscriptionArePaused = {
    currentPlan: {
      plan: "Free",
      status: "Free",
      seats: 10,
      price: 0,
    },
    seats: {
      used: subscriptionData?.seats?.used,
      available: 10 - subscriptionData?.seats?.used,
      total: 10,
    },
    userTrial: {
      isTrialStarted: false,
      isTrialCancelled: true,
      isTrialExpired: true,
    },
    subscriptionInfo: {
      isSubscribed: false,
      isUnsubscribed: true,
      isSubscribedExpired: true,
      isSubscribeResumed: false,
    },
  };

  const revertToFreeData = {
    currentPlan: {
      plan: "Free",
      status: "Free",
      seats: 10,
      price: 0,
    },
    seats: {
      used: subscriptionData?.seats?.used > 10 ? 1 : subscriptionData?.seats?.used,
      available: subscriptionData?.seats?.used > 10 ? 9 : 10 - subscriptionData?.seats?.used,
      total: 10,
    },
    userTrial: {
      isTrialStarted: false,
      isTrialCancelled: true,
      isTrialExpired: true,
    },
    subscriptionInfo: {
      isSubscribed: false,
      isSubscribedExpired: true,
      isUnsubscribed: false,
      isSubscribeResumed: false,
    },
  };

  useEffect(() => {
    setUsedSeatsCount(
      subscriptionData?.currentPlan?.plan === "Monthly Subscription" ? 79 * subscriptionData?.seats?.total : 790 * subscriptionData?.seats?.total
    );
    setTaxSeatsCount(usedSeatsCount * 0.15);
    setTotalAmountCount(usedSeatsCount + taxSeatsCount);
    // eslint-disable-next-line
  }, [subscriptionData, showPricingPlanModal]);

  const cancelSubscription = async () => {
    setRevertToFreePlanAfterExpired(false);
    if (subscriptionData?.currentPlan?.status === "Paused" && subscriptionData?.subscriptionInfo?.isSubscribed) {
      await updateSubscriptionData(subscriptionData?.id, unsubscribeAndSubscriptionArePaused, "Update");
      await getSubscriptionByCompanyId(user?.company?.id);
      return;
    }

    if (subscriptionData?.seats?.used > 10) {
      setShowAlertCancelTrial(false);
      setUnsubscribeAndDisbandedTeamModal(true);
      setShowUnsubscribeModal(false);
    } else {
      setCancelSubscriptionModal(false);
      setShowRevertBackFreePlanModal(false);
      setRevertBackFreePlanModalMoreThanTenUser(false);
      setUnsubscribeModal(true);
      setShowUnsubscribeModal(false);
    }
    if (subscriptionData?.userTrial?.isTrialExpired && subscriptionData?.subscriptionInfo?.isSubscribed === false) {
      await updateSubscriptionData(subscriptionData?.id, cancelTrialDataIfExpired, "Update");
    } else {
      await updateSubscriptionData(subscriptionData?.id, cancelTrialData);
    }

    if (subscriptionData?.subscriptionEndedAt && subscriptionData?.subscriptionStartedAt) {
      await updateSubscriptionData(subscriptionData?.id, unsubscribeAndSubscriptionEndedHaveTime, "Update");
    } else if (subscriptionData?.subscriptionInfo?.isSubscribed === true) {
      await updateSubscriptionData(subscriptionData?.id, unsubscribedFromSubscription, "Update");
    }

    await getSubscriptionByCompanyId(user?.company?.id);
  };

  const resumeSubscriptionData = {
    currentPlan: {
      plan: subscriptionData?.currentPlan?.plan,
      status: "Running",
      seats: subscriptionData?.currentPlan?.seats,
      price: subscriptionData?.currentPlan?.price,
    },
    seats: {
      used: subscriptionData?.seats?.used,
      available: subscriptionData?.seats?.available,
      total: subscriptionData?.seats?.total,
    },
    userTrial: {
      isTrialStarted: false,
      isTrialCancelled: true,
      isTrialExpired: true,
    },
    subscriptionInfo: {
      isSubscribed: true,
      isUnsubscribed: false,
      isSubscribedExpired: false,
      isSubscribeResumed: true,
    },
  };

  const hanldeResumeSubscription = async () => {
    await updateSubscriptionData(subscriptionData?.id, resumeSubscriptionData, "Update");
    setShowResumeSubscriptionModal(false);
    setShowAfterResumeSubscriptionModal(true);
    await getSubscriptionByCompanyId(user?.company?.id);
  };

  const revertToFreePlanHandler = async () => {
    if (subscriptionData?.seats?.used > 10) {
      setShowAlertCancelTrial(false);
      setUnsubscribeAndDisbandedTeamModal(true);
      setRevertBackFreePlanModalMoreThanTenUser(false);
    } else {
      setCancelSubscriptionModal(false);
      setShowRevertBackFreePlanModal(false);
      setUnsubscribeModal(true);
    }
    await updateSubscriptionData(subscriptionData?.id, revertToFreeData, "Update");
    await getSubscriptionByCompanyId(user?.company?.id);
  };

  const handleCancelSubscriptions = () => {
    if (subscriptionData?.seats?.used > 10) {
      setShowAlertCancelTrial(true);
    } else {
      setCancelSubscriptionModal(true);
    }
  };

  const cancelTrialCondition = subscriptionData?.currentPlan?.plan === "Free" && subscriptionData?.userTrial?.isTrialCancelled === false;

  const formattedSubscriptionEndedDate = moment(subscriptionData?.subscriptionEndedAt).format("DD MMM YYYY");

  useEffect(() => {
    const fetchData = async () => {
      const response = await getInvoices();
      const sortedInvoices = response?.value?.data;
      setAllInvoices(sortedInvoices);
    };
    if (subscriptionBillingDailog) {
      fetchData();
    }
  }, [getInvoices, subscriptionBillingDailog]);

  useEffect(() => {
    const fetchCard = async () => {
      setGetCardLoading(true);
      if (user?.company?.id) {
        const response = await getCompanyById(user?.company?.id);
        if (response?.value?.data?.paymentCards?.moyasar?.tempToken) {
          await verifyCard(user?.company?.id);
        }
        if (response?.value?.data?.paymentCards?.moyasar?.token) {
          const cardResponse = await getCard(user?.company?.id);
          setCompanyPaymentCard(cardResponse?.value?.data);
        }
      }
      setGetCardLoading(false);
    };

    fetchCard();
  }, [getCompanyById, verifyCard, getCard, user?.company?.id, user?.company?.paymentCards]);

  return (
    <div>
      <section className="payment-plans py-2" style={{ margin: "0 auto" }}>
        <div className="current-plan-section">
          <p className="current-plan-heading">
            <span className="mx-4">
              <I18n lng="global.CurrentPlan" />
            </span>
          </p>
          <div className="plan-detail">
            <div className="d-flex">
              <p className="mx-4" style={{ color: "#333333" }}>
                <I18n lng="global.Plan" />:
              </p>
              <p style={{ color: "#333333" }} className={`mx-4 ${TEXT_DIRECTION() === "rtl" ? "mr-auto" : "ml-auto"}`}>
                {subscriptionsTranslation(subscriptionData?.currentPlan?.plan)}
              </p>
            </div>
            {subscriptionData?.userTrial.isTrialStarted || subscriptionData?.subscriptionInfo?.isSubscribed ? (
              <div className="d-flex">
                <p className="mx-4">
                  <I18n lng="global.Status" />:
                </p>
                <p className={`mx-4 ${TEXT_DIRECTION() === "rtl" ? "mr-auto" : "ml-auto"}`}>
                  {subscriptionsTranslation(subscriptionData?.currentPlan?.status)}
                </p>
              </div>
            ) : (
              ""
            )}
            <div className="d-flex">
              <p className="mx-4" style={{ color: "#333333" }}>
                <I18n lng="Subscriptions.Seats" />:
              </p>
              <p style={{ color: "#333333" }} className={`mx-4 ${TEXT_DIRECTION() === "rtl" ? "mr-auto" : "ml-auto"}`}>
                {subscriptionData?.currentPlan?.seats}
              </p>
            </div>
            {subscriptionData?.userTrial.isTrialStarted || subscriptionData?.subscriptionInfo?.isSubscribed ? (
              <div className="d-flex">
                <p className="mx-4">
                  <I18n lng="global.Price" />:
                </p>
                <p className={`mx-4 ${TEXT_DIRECTION() === "rtl" ? "mr-auto" : "ml-auto"}`}>
                  {" "}
                  <span className="">
                    {subscriptionData?.currentPlan?.price === 0 ? (
                      <I18n lng="global.Free" />
                    ) : (
                      <>
                        {Language === "ENGLISH" ? (
                          <span>
                            <span className="mx-1">
                              <I18n lng="Subscriptions.SAR" />
                            </span>
                            <span className="mx-1">{subscriptionData?.currentPlan?.price}.00</span>
                            <span>
                              (<I18n lng="Subscriptions.PerSeat" />)
                            </span>
                          </span>
                        ) : (
                          <span>
                            <span className="mx-1">{subscriptionData?.currentPlan?.price}.00</span>
                            <span className="mx-1">
                              <I18n lng="Subscriptions.SAR" />
                            </span>
                            <span>
                              (<I18n lng="Subscriptions.PerSeat" />)
                            </span>
                          </span>
                        )}
                      </>
                    )}
                  </span>
                </p>
              </div>
            ) : (
              ""
            )}
          </div>
          <hr style={{ marginBottom: "-4px" }} />
          <div
            className="subscription-open-modal-btn text-center"
            style={{
              cursor: "pointer",
              height: "40px",
            }}
            onClick={() => {
              if (subscriptionData?.userTrial?.isTrialStarted) {
                setShowDetailsDialog(true);
              } else if (
                subscriptionData?.currentPlan?.plan === "Free" &&
                subscriptionData?.currentPlan?.status === "Free" &&
                subscriptionData?.userTrial?.isTrialCancelled
              ) {
                return setShowSubscriptionExpiredModal(true);
              } else if (subscriptionData?.subscriptionInfo?.isSubscribed) {
                setShowDetailsDialog(true);
              } else if (cancelTrialCondition) {
                openUpdatePlanModal(freeTrialData);
                setIsUpdatePlanClick(true);
              } else if (subscriptionData?.userTrial?.isTrialCancelled === true) {
                setShowPricingPlanModal(true);
              } else {
                return null;
              }
            }}
          >
            <span>
              <I18n lng="global.UpdatePlan" />
            </span>
          </div>
          {(subscriptionData?.userTrial?.isTrialStarted ||
            subscriptionData?.subscriptionInfo?.isSubscribed ||
            subscriptionData?.subscriptionInfo?.isUnsubscribed === true ||
            (subscriptionData?.currentPlan?.plan === "Free" &&
              subscriptionData?.subscriptioninfo?.isUnSubscribed === true &&
              subscriptionData?.currentPlan?.status === "Free" &&
              subscriptionData?.userTrial?.isTrialCancelled &&
              subscriptionData?.userTrial?.isTrialExpired)) && (
            <div>
              <p className="px-4 my-3 d-flex justify-content-between align-items-center" style={{ color: "#828282" }}>
                <span>
                  <I18n lng="Subscriptions.Billing" />
                </span>
              </p>
              <hr />
              <p className="px-4 my-3 d-flex flex-column">
                {subscriptionData?.currentPlan?.status === "Expired" && subscriptionData?.subscriptionInfo?.isSubscribed ? (
                  <span style={{ width: "137px", height: "16px", fontWeight: "500", fontSize: "14px", lineHeight: "15.92px", color: "#333333" }}>
                    {lastInvoicePlan}
                  </span>
                ) : subscriptionData?.currentPlan?.plan === "Free" &&
                  subscriptionData?.currentPlan?.status === "Free" &&
                  subscriptionData?.userTrial?.isTrialCancelled &&
                  subscriptionData?.userTrial?.isTrialExpired &&
                  subscriptionData?.subscriptionInfo?.isUnsubscribed ? (
                  <span>{lastInvoicePlan}</span>
                ) : subscriptionData?.currentPlan?.plan === "Free" &&
                  subscriptionData?.currentPlan?.status === "Free" &&
                  subscriptionData?.subscriptionInfo?.isSubscribedExpired &&
                  subscriptionData?.userTrial?.isTrialCancelled &&
                  subscriptionData?.userTrial?.isTrialExpired ? (
                  <span style={{ fontWeight: "500", fontSize: "14px", color: "#333333", width: "137px", height: "16px" }}>
                    {moment(lastMonthlyInvoice?.createdAt).format("D MMM YYYY")}
                  </span>
                ) : (
                  <span>
                    <I18n lng="Subscriptions.NextBill" />
                  </span>
                )}
                <div className="d-flex justify-content-between align-items-center my-1">
                  <p style={{ color: "#828282", height: "16px", fontSize: "12px", fontWeight: "400" }}>
                    {subscriptionData?.subscriptionInfo?.isSubscribed === false ? (
                      trialNextBillFormatedDate
                    ) : subscriptionData?.currentPlan?.status === "Expired" && subscriptionData?.subscriptionInfo?.isSubscribed ? (
                      <>{moment(lastMonthlyInvoice?.createdAt).format("D MMM YYYY")}</>
                    ) : (
                      subscriptionNextBillFormatedDate
                    )}
                  </p>
                  {subscriptionData?.currentPlan?.plan === "Free" &&
                  subscriptionData?.currentPlan?.status === "Free" &&
                  subscriptionData?.userTrial?.isTrialCancelled &&
                  subscriptionData?.userTrial?.isTrialExpired &&
                  subscriptionData?.subscriptionInfo?.isUnsubscribed ? (
                    <p style={{ color: "#828282", fontSize: "smaller" }}>
                      <I18n lng="Subscriptions.SAR" /> {totalCost?.toFixed(2)}
                    </p>
                  ) : (
                    subscriptionData?.subscriptionInfo?.isSubscribed &&
                    subscriptionData?.currentPlan?.status !== "Unsubscribed, subscription is running" &&
                    subscriptionData?.subscriptionInfo?.status !== "Trial" &&
                    ((subscriptionData?.currentPlan?.plan === "Monthly Subscription" && user?.language === "English") ||
                    subscriptionData?.currentPlan?.plan === "Yearly Subscription" ? (
                      <p style={{ color: "#828282", fontSize: "smaller" }}>
                        <I18n lng="Subscriptions.SAR" />{" "}
                        {totalAmountCount
                          ?.toFixed(2)
                          ?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </p>
                    ) : (
                      <p style={{ color: "#828282", fontSize: "smaller" }}>
                        {totalAmountCount
                          ?.toFixed(2)
                          ?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                        <I18n lng="Subscriptions.SAR" />
                      </p>
                    ))
                  )}
                </div>
              </p>
              <hr style={{ marginBottom: "0rem" }} />
              <div
                style={{ cursor: "pointer", height: "40px" }}
                className="subscription-open-modal-btn text-center"
                onClick={async () => {
                  await getInvoices();
                  setSubsriptionBillingDailog(true);
                }}
              >
                <span style={{ height: "21px" }}>
                  <I18n lng="Subscriptions.ViewMore" />
                </span>
              </div>
            </div>
          )}
        </div>
        <div className="payment-method-section py-3">
          <p className="payment-method-heading">
            <span className="mx-4" style={{ height: "16px" }}>
              <I18n lng="Subscriptions.PaymentMethod" />
            </span>
          </p>
          <div>
            {getCardLoading ? (
              <p className="d-flex justify-content-center align-items-center">
                <I18n lng="global.loading" />
              </p>
            ) : (
              <>
                {companyPaymentCard ? (
                  <ul
                    className={`subscription-cost font-size-14 mb-2  ${TEXT_DIRECTION() === "rtl" ? "pr-0" : "pl-0"}`}
                    style={{ listStyle: "none" }}
                  >
                    <li className="d-flex w-100 mx-4">
                      <label className="text-muted">{companyPaymentCardTranslation(companyPaymentCard?.brand)}:</label>
                      <div className="d-flex justify-content-between" style={{ width: "85%" }}>
                        <p>**********{companyPaymentCard?.last_four}</p>
                        <p className="mx-2">
                          <span className="mx-1">{moment(companyPaymentCard?.month, "MM").format("MMM")}</span>
                          <span>{moment(companyPaymentCard?.year).format("YYYY")}</span>
                        </p>
                      </div>
                    </li>
                  </ul>
                ) : (
                  <div className="text-center d-flex justify-content-center align-items-center">
                    <label
                      style={{
                        width: "260px",
                        height: "16px",
                        fontWeight: "200",
                        fontSize: "14px",
                        lineHeight: "15.92px",
                        color: "#BDBDBD",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <I18n lng="Subscriptions.ThereIsNoCreditCards" />
                    </label>
                  </div>
                )}
              </>
            )}
          </div>

          <div
            className="text-center subscription-open-modal-btn-add-payment-method"
            onClick={() => {
              setShowCardDialog(true);
            }}
            style={{
              cursor: "pointer",
              borderTop: "1px solid rgba(0, 0, 0, .1)",
              height: "40px",
              paddingTop: "16px",
              paddingBottom: "34px",
            }}
          >
            <span
              style={{ fontSize: "14px", height: "30px", color: "#20639B", width: "152px" }}
              onClick={() => {
                companyPaymentCard ? setEdit(true) : setEdit(false);
              }}
            >
              {getCardLoading ? (
                <>
                  <I18n lng="global.loading" />
                </>
              ) : companyPaymentCard ? (
                <I18n lng="Subscriptions.UpdatePaymentMethod" />
              ) : (
                <I18n lng="Subscriptions.AddPaymentMethod" />
              )}
            </span>
          </div>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center">
          {subscriptionData?.currentPlan?.plan === "Free" &&
            subscriptionData?.currentPlan?.status === "Free" &&
            !subscriptionData?.subscriptionInfo?.isUnsubscribed && (
              <>
                <div className="subscription-heading mb-4 d-flex flex-column align-items-center justify-content-center">
                  <span style={{ color: "#333333" }}>
                    <I18n lng="Subscriptions.TryTheFullFeaturesIncludingAnalytics" />
                  </span>
                  <span style={{ color: "#333333" }}>
                    <I18n lng="Company.PermissionsAndMoreForCompanySubcriptionView" />
                  </span>
                </div>

                <>
                  <div
                    style={{
                      width: "276px",
                      height: "48px",
                      backgroundColor: "#9B51E0",
                      borderRadius: "25px",
                    }}
                  >
                    <p
                      onClick={() => {
                        subscriptionData?.userTrial?.isTrialCancelled === false
                          ? openUpdatePlanModal(TrySubscriptionForFreeData)
                          : setShowPricingPlanModal(true);
                      }}
                      style={{
                        cursor: "pointer",
                        color: "#ffffff",
                        textAlign: "center",
                        paddingTop: "11px",
                        paddingleft: "32px",
                        fontWeight: "600",
                        fontSize: "16px",
                      }}
                    >
                      {subscriptionData?.userTrial?.isTrialCancelled === false ? (
                        <I18n lng="Subscriptions.TrySubscription30DaysForFree" />
                      ) : (
                        <I18n lng="Subscriptions.Subscribe" />
                      )}
                    </p>
                  </div>
                </>
              </>
            )}
          {subscriptionData?.currentPlan?.plan === "Trial" &&
            subscriptionData?.userTrial?.isTrialStarted === true &&
            subscriptionData?.subscriptionInfo?.isSubscribed === false &&
            subscriptionData?.subscriptionInfo?.isUnsubscribed === false && (
              <>
                <div className="subscription-heading mb-4 d-flex flex-column align-items-center justify-content-center">
                  <span style={{ color: "#333333" }}>
                    <I18n lng="Subscriptions.TryTheFullFeaturesIncludingAnalytics" />
                  </span>
                  <span style={{ color: "#333333" }}>
                    <I18n lng="Company.PermissionsAndMoreForCompanySubcriptionView" />
                  </span>
                </div>

                <>
                  <div
                    style={{
                      width: "276px",
                      height: "48px",
                      backgroundColor: "#2d9cdb",
                      borderRadius: "25px",
                    }}
                  >
                    <p
                      onClick={() => {
                        setShowPricingPlanModal(true);
                      }}
                      style={{
                        cursor: "pointer",
                        color: "#ffffff",
                        textAlign: "center",
                        paddingTop: "11px",
                        paddingleft: "32px",
                        fontWeight: "600",
                        fontSize: "16px",
                      }}
                    >
                      <I18n lng="Subscriptions.Subscribe" />
                    </p>
                  </div>
                </>
              </>
            )}
          {subscriptionData?.currentPlan?.plan === "Free" &&
            subscriptionData?.userTrial?.isTrialStarted === false &&
            subscriptionData?.subscriptionInfo?.isSubscribed === false &&
            subscriptionData?.subscriptionInfo?.isUnsubscribed === true && (
              <>
                <div className="subscription-heading mb-4 d-flex flex-column align-items-center justify-content-center">
                  <span style={{ color: "#333333" }}>
                    <I18n lng="Subscriptions.TryTheFullFeaturesIncludingAnalytics" />
                  </span>
                  <span style={{ color: "#333333" }}>
                    <I18n lng="Company.PermissionsAndMoreForCompanySubcriptionView" />
                  </span>
                </div>

                <>
                  <div
                    style={{
                      width: "276px",
                      height: "48px",
                      backgroundColor: "#9B51E0",
                      borderRadius: "25px",
                    }}
                  >
                    <p
                      onClick={() => {
                        setShowPricingPlanModal(true);
                      }}
                      style={{
                        cursor: "pointer",
                        color: "#ffffff",
                        textAlign: "center",
                        paddingTop: "11px",
                        paddingleft: "32px",
                        fontWeight: "600",
                        fontSize: "16px",
                      }}
                    >
                      <I18n lng="Subscriptions.Subscribe" />
                    </p>
                  </div>
                </>
              </>
            )}
          {subscriptionData?.subscriptionInfo?.isSubscribed === false && (
            <div className="subscription-benefits mb-4 my-4">
              <p className="benefits-heading" style={{ color: "#333333" }}>
                <I18n lng="Subscriptions.AllTheBenefitsOfTheFreePlanPlus" />:
              </p>
              <ul className="d-flex flex-column" style={{ color: "blue" }}>
                <li className="mb-2" style={{ color: "#333333" }}>
                  <I18n lng="Subscriptions.ManageUserPermissions" />
                </li>
                <li className="mb-2" style={{ color: "#333333" }}>
                  <I18n lng="Subscriptions.GainInsightsWithAnalytics" />
                </li>
                <li className="mb-2" style={{ color: "#333333" }}>
                  <I18n lng="Subscriptions.UnlimitedTeamMembers" />
                </li>
                <li className="mb-2" style={{ color: "#333333" }}>
                  <I18n lng="Subscriptions.ShareCustomizedReports" />
                </li>
                <li className="mb-2" style={{ color: "#333333" }}>
                  <I18n lng="Subscriptions.AddYourBrandLogoToReports" />
                </li>
                <li className="mb-2" style={{ color: "#333333" }}>
                  <I18n lng="Subscriptions.NoCreditCardRequiredForCompanySubscriptionView" />
                </li>
                <li className="mb-2" style={{ color: "#333333" }}>
                  <I18n lng="Subscriptions.CancelAtAnyTime" />
                </li>
              </ul>
            </div>
          )}
          {subscriptionData?.currentPlan.status === "Unsubscribed, subscription is running" && subscriptionData?.subscriptionInfo?.isUnsubscribed && (
            <div className="d-flex flex-column align-items-center">
              <p style={{ fontSize: "16px", fontWeight: "600" }} className="mt-2">
                <I18n lng="Subscriptions.ResumeYourSubscriptionNow" />
              </p>
              <p
                onClick={() => {
                  getSubscriptionByCompanyId(user?.company?.id).then((res) => {
                    if (res?.value?.data?.subscriptionInfo?.isSubscribedExpired === true) {
                      setIsTrialExpired(true);
                      localStorage.setItem("hasModalBeenShown", "true");
                    } else {
                      setShowResumeSubscriptionModal(true);
                    }
                  });
                }}
                className="d-flex justify-content-center align-items-center mt-3"
                style={{
                  width: "276px",
                  height: "48px",
                  backgroundColor: "#2D9CDB",
                  borderRadius: "25px",
                  cursor: "pointer",
                }}
              >
                <span
                  style={{
                    fontWeight: "600",
                    fontSize: "16px",
                    color: "#FFFFFF",
                  }}
                >
                  <I18n lng="Subscriptions.ResumeSubscription" />
                </span>
              </p>
              <p
                className="d-flex flex-column justify-content-center align-items-center text-center mt-3"
                style={{
                  width: "334px",
                  height: "42px",
                  fontWeight: "400",
                  fontSize: "14px",
                }}
              >
                <span>
                  <I18n lng="Subscriptions.YouHaveUnsubscribedButYourSubscriptionIsValidTill" />
                </span>
                <span>{formattedSubscriptionEndedDate}</span>
              </p>
            </div>
          )}
          <div>
            {subscriptionData?.userTrial.isTrialStarted &&
              !subscriptionData?.subscriptionInfo?.isSubscribed &&
              !subscriptionData?.userTrial?.isTrialExpired &&
              !subscriptionData?.userTrial?.isTrialCancelled && (
                <div onClick={handleCancelSubscriptions} className="cancel-subscription-button d-flex align-items-center justify-content-center">
                  <span
                    style={{
                      color: "#828282",
                      fontSize: "16px",
                      fontWeight: "500",
                      width: user?.language === "ARABIC" ? "140px" : "86px",
                      height: "16px",
                      lineHeight: "15.92px",
                    }}
                  >
                    <I18n lng="Subscriptions.CancelTrial" />
                  </span>
                </div>
              )}
            {subscriptionData?.userTrial?.isTrialExpired &&
              subscriptionData?.currentPlan?.status === "Expired" &&
              !subscriptionData?.subscriptionInfo?.isSubscribed && (
                <div
                  onClick={() => {
                    if (allUsers.length > 10) {
                      setRevertBackFreePlanModalMoreThanTenUser(true);
                    } else {
                      setShowRevertBackFreePlanModal(true);
                    }
                  }}
                  className="cancel-subscription-button d-flex align-items-center justify-content-center"
                >
                  <span>
                    <I18n lng="Subscriptions.RevertBackToFreePlan" />
                  </span>
                </div>
              )}
            {subscriptionData?.subscriptionInfo?.isSubscribed &&
              !subscriptionData?.subscriptionInfo?.isUnsubscribed &&
              !subscriptionData?.subscriptionInfo?.isSubscribedExpired && (
                <div
                  onClick={() => {
                    setShowUnsubscribeModal(true);
                  }}
                  className="mt-5 cancel-subscription-button d-flex align-items-center justify-content-center"
                >
                  <span style={{ color: "#828282" }}>
                    <I18n lng="Subscriptions.Unsubscribe" />
                  </span>
                </div>
              )}
            {subscriptionData?.currentPlan?.status === "Expired" &&
              subscriptionData?.subscriptionInfo?.isSubscribed &&
              subscriptionData?.subscriptionInfo?.isSubscribedExpired &&
              subscriptionData?.subscriptionInfo?.isUnsubscribed && (
                <div
                  className="text-center"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <p
                    className="d-flex flex-column text-center"
                    style={{
                      width: "256px",
                      height: "42px",
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "21px",
                      color: "#333333",
                    }}
                  >
                    <span>
                      <I18n lng="Subscriptions.ActivateTheFullFeaturesWithAccessTo" />
                    </span>
                    <span>
                      <I18n lng="Subscriptions.AnalyticsPermissionsAndMore" />
                    </span>
                  </p>
                  <div
                    style={{
                      width: "276px",
                      height: "48px",
                      backgroundColor: "#2d9cdb",
                      borderRadius: "25px",
                    }}
                  >
                    <p
                      onClick={() => {
                        setShowPricingPlanModal(true);
                      }}
                      style={{
                        cursor: "pointer",
                        color: "#ffffff",
                        textAlign: "center",
                        paddingTop: "11px",
                        paddingleft: "32px",
                        fontWeight: "600",
                        fontSize: "16px",
                      }}
                    >
                      <I18n lng="Subscriptions.Subscribe" />
                    </p>
                  </div>
                  <p
                    className="d-flex flex-column text-center pt-3"
                    style={{
                      height: "21px",
                      fontWeight: "600",
                      fontSize: "16px",
                      lineHeight: "21px",
                      color: "#333333",
                    }}
                  >
                    <I18n lng="Subscriptions.AllTheBenefitsOfTheFreePlanPlus" />
                  </p>
                  <ul
                    className="text-center"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "6px",
                      textDecoration: "none",
                      listStyleType: "none",
                      padding: 0,
                      alignItems: "flex-start",
                    }}
                  >
                    <li>
                      <I18n lng="Subscriptions.ManageUserPermissions" />
                    </li>
                    <li>
                      <I18n lng="Subscriptions.GainInsightsWithAnalytics" />
                    </li>
                    <li>
                      <I18n lng="Subscriptions.UnlimitedTeamMembers" />
                    </li>
                    <li>
                      <I18n lng="Subscriptions.ShareCustomizedReports" />
                    </li>
                    <li>
                      <I18n lng="Subscriptions.AddYourBrandLogoToReports" />
                    </li>
                  </ul>
                  <div
                    onClick={() => {
                      if (allUsers.length > 10 && subscriptionData?.currentPlan?.status !== "Expired") {
                        setRevertBackFreePlanModalMoreThanTenUser(true);
                      } else if (
                        subscriptionData?.currentPlan?.status === "Expired" &&
                        subscriptionData?.subscriptionInfo?.isSubscribed &&
                        subscriptionData?.subscriptionInfo?.isUnsubscribed &&
                        subscriptionData?.subscriptionInfo?.isSubscribedExpired
                      ) {
                        return setRevertToFreePlanAfterExpired(true);
                      } else {
                        setShowRevertBackFreePlanModal(true);
                      }
                    }}
                    className="cancel-subscription-button d-flex align-items-center justify-content-center"
                  >
                    <span>
                      <I18n lng="Subscriptions.RevertBackToFreePlan" />
                    </span>
                  </div>
                </div>
              )}
          </div>
        </div>
      </section>

      {showUpdatePlanModal && (
        <PlanDialog
          StartFreeTrialHandler={StartFreeTrialHandler}
          subscriptionId={subscriptionData?.id}
          isTrialStarted={subscriptionData?.currentPlan?.isTrialStarted}
          showModal={showUpdatePlanModal}
          onClose={closeUpdatePlanModal}
          modalTitle={modalData?.title}
          text_one={modalData?.text_one}
          text_two={modalData?.text_two}
          primaryButtonLabel={modalData?.actionButtonText}
          secondaryButtonLabel={modalData?.closeButtonText}
          secondaryButtonColor="#828282"
          primaryButtonColor="#20639B"
          titleWidth={"221px"}
          titleHeight={"20px"}
          titleFontWeight={"600"}
          titleFontSize={"20px"}
          titleColor={"#333333"}
          titleLineHeight={"20px"}
          secondaryButtonWidth={"43px"}
          secondaryButtonHeight={"16px"}
          secondaryButtonFontWeight={"600"}
          secondaryButtonFontSize={"16px"}
          secondaryButtonLineHeight={"15.92px"}
          primaryButtonWidth={"114px"}
          primaryButtonHeight={"16px"}
          primaryButtonFontWeight={"600"}
          primaryButtonFontSize={"16px"}
          primaryButtonLineHeight={"15.92px"}
        />
      )}

      {showAddPaymentModal && (
        <CardAddDialog
          edit={edit}
          cardIndex={cardIndex}
          cardToEdit={cardToEdit}
          showDlg={showAddPaymentModal}
          userCardsList={companyPaymentCard}
          secondaryAction={closeAddPaymentModal}
        />
      )}

      <SubscriptionsDetailDialog subscriptionData={subscriptionData} show={showDetailsDialog} onHide={() => setShowDetailsDialog(false)} />

      <SubscriptionBillingDetails
        invoices={allInvoices}
        subscriptionData={subscriptionData}
        show={subscriptionBillingDailog}
        onHide={() => setSubsriptionBillingDailog(false)}
      />
      {chargeBillingDetailsDailog && (
        <ChargeBillingDetails
          setShowCardDialog={setShowCardDialog}
          subscriptionData={subscriptionData}
          show={chargeBillingDetailsDailog}
          onHide={() => setChargeBillingDetailsDailog(false)}
          selectedPlan={selectedPlan}
          setSelectedPlan={setSelectedPlan}
          setUpdateSeatsModal={setUpdateSeatsModal}
        />
      )}

      {showUpdateSuccessModal && (
        <PlanDialog
          setShowDetailsDialog={setShowDetailsDialog}
          showModal={showUpdateSuccessModal}
          onClose={() => setShowUpdateSuccessModal(false)}
          modalTitle={updatePlanData.title}
          text_one={updatePlanData.text_one}
          text_two={updatePlanData.text_two}
          primaryButtonLabel={updatePlanData.actionButtonText}
          secondaryButtonLabel={updatePlanData.closeButtonText}
          secondaryButtonColor="#20639B"
          primaryButtonColor="#20639B"
          titleWidth={"124px"}
          titleHeight={"60px"}
          titleFontWeight={"600"}
          titleFontSize={"20px"}
          titleLineHeight={"20px"}
          titleColor={"#333333"}
          secondaryButtonWidth={"43px"}
          secondaryButtonHeight={"16px"}
          secondaryButtonFontWeight={"600"}
          secondaryButtonFontSize={"16px"}
          secondaryButtonLineHeight={"15.92px"}
          primaryButtonWidth={"98px"}
          primaryButtonHeight={"16px"}
          primaryButtonFontWeight={"600"}
          primaryButtonFontSize={"16px"}
          primaryButtonLineHeight={"15.92px"}
        />
      )}

      {cancelSubscriptionModal && (
        <PlanDialog
          cancelSubscription={cancelSubscription}
          showModal={cancelSubscriptionModal}
          onClose={() => setCancelSubscriptionModal(false)}
          modalTitle={cancelSubscriptionModalData.title}
          text_one={cancelSubscriptionModalData.text_one}
          primaryButtonLabel={cancelSubscriptionModalData.actionButtonText}
          secondaryButtonLabel={cancelSubscriptionModalData.closeButtonText}
          primaryButtonColor="#828282"
          secondaryButtonColor="#20639B"
          titleWidth={"114px"}
          titleHeight={"20px"}
          titleFontWeight={"600"}
          titleFontSize={"20px"}
          titleColor={"#333333"}
          secondaryButtonWidth={"43px"}
          secondaryButtonHeight={"16px"}
          secondaryButtonFontWeight={"600"}
          secondaryButtonFontSize={"16px"}
          secondaryButtonLineHeight={"15.92px"}
          primaryButtonWidth={user?.language === "ARABIC" ? "140px" : "93px"}
          primaryButtonHeight={"16px"}
          primaryButtonFontWeight={"600"}
          primaryButtonFontSize={"16px"}
          primaryButtonLineHeight={"15.92px"}
        />
      )}

      {showRevertBackFreePlanModal && (
        <PlanDialog
          cancelSubscription={revertToFreePlanHandler}
          showModal={showRevertBackFreePlanModal}
          onClose={() => setShowRevertBackFreePlanModal(false)}
          modalTitle={revertBackFreePlanModalData.title}
          text_one={revertBackFreePlanModalData.text_one}
          primaryButtonLabel={revertBackFreePlanModalData.actionButtonText}
          secondaryButtonLabel={revertBackFreePlanModalData.closeButtonText}
          primaryButtonColor="#828282"
          secondaryButtonColor="#20639B"
          titleWidth={"116px"}
          titleHeight={"20px"}
          titleFontWeight={"600"}
          titleFontSize={"20px"}
          titleLineHeight={"20px"}
          titleColor={"#333333"}
          secondaryButtonWidth={"43px"}
          secondaryButtonHeight={"16px"}
          secondaryButtonFontWeight={"600"}
          secondaryButtonFontSize={"16px"}
          secondaryButtonLineHeight={"15.92px"}
          primaryButtonWidth={user?.language === "ARABIC" ? "170px" : "152px"}
          primaryButtonHeight={"16px"}
          primaryButtonFontWeight={"600"}
          primaryButtonFontSize={"16px"}
          primaryButtonLineHeight={"15.92px"}
        />
      )}

      {revertBackFreePlanModalMoreThanTenUser && (
        <PlanDialog
          cancelSubscription={revertToFreePlanHandler}
          showModal={revertBackFreePlanModalMoreThanTenUser}
          onClose={() => setRevertBackFreePlanModalMoreThanTenUser(false)}
          modalTitle={revertBackFreePlanModalData.title}
          text_one={revertBackFreePlanModalData.text_one}
          text_two={revertBackFreePlanModalData.text_two}
          primaryButtonLabel={revertBackFreePlanModalData.actionButtonText}
          secondaryButtonLabel={revertBackFreePlanModalData.closeButtonText}
          primaryButtonColor="#828282"
          secondaryButtonColor="#20639B"
          titleWidth={"116px"}
          titleHeight={"20px"}
          titleFontWeight={"600"}
          titleFontSize={"20px"}
          titleLineHeight={"20px"}
          titleColor={"#333333"}
          secondaryButtonWidth={"43px"}
          secondaryButtonHeight={"16px"}
          secondaryButtonFontWeight={"600"}
          secondaryButtonFontSize={"16px"}
          secondaryButtonLineHeight={"15.92px"}
          primaryButtonWidth={"152px"}
          primaryButtonHeight={"16px"}
          primaryButtonFontWeight={"600"}
          primaryButtonFontSize={"16px"}
          primaryButtonLineHeight={"15.92px"}
        />
      )}

      {unsubscribeModal && (
        <PlanDialog
          showModal={unsubscribeModal}
          onClose={() => setUnsubscribeModal(false)}
          text_one={unsubscribeModalData.text_one}
          modalTitle={unsubscribeModalData.title}
          secondaryButtonLabel={unsubscribeModalData.closeButtonText}
          secondaryButtonColor="#20639B"
          titleWidth={"143px"}
          titleHeight={"42px"}
          titleFontWeight={"600"}
          titleFontSize={"20px"}
          titleColor={"#333333"}
          secondaryButtonWidth={"43px"}
          secondaryButtonHeight={"16px"}
          secondaryButtonFontWeight={"600"}
          secondaryButtonFontSize={"16px"}
          secondaryButtonLineHeight={"15.92px"}
        />
      )}

      {showAlertCancelTrial && (
        <PlanDialog
          cancelSubscription={cancelSubscription}
          setShowDetailsDialog={setShowDetailsDialog}
          showModal={showAlertCancelTrial}
          onClose={() => setShowAlertCancelTrial(false)}
          modalTitle={userTenSubscriptionModalData.title}
          text_one={userTenSubscriptionModalData.text_one}
          text_two={userTenSubscriptionModalData.text_two}
          primaryButtonLabel={userTenSubscriptionModalData.actionButtonText}
          secondaryButtonLabel={userTenSubscriptionModalData.closeButtonText}
          secondaryButtonColor="#20639B"
          primaryButtonColor="#828282"
          titleWidth={"114px"}
          titleHeight={"20px"}
          titleFontWeight={"600"}
          titleFontSize={"20px"}
          titleColor={"#333333"}
          secondaryButtonWidth={"43px"}
          secondaryButtonHeight={"16px"}
          secondaryButtonFontWeight={"600"}
          secondaryButtonFontSize={"16px"}
          secondaryButtonLineHeight={"15.92px"}
          primaryButtonWidth={"93px"}
          primaryButtonHeight={"16px"}
          primaryButtonFontWeight={"600"}
          primaryButtonFontSize={"16px"}
          primaryButtonLineHeight={"15.92px"}
        />
      )}

      {unsubscribeAndDisbandedTeamModal && (
        <PlanDialog
          showModal={unsubscribeAndDisbandedTeamModal}
          onClose={() => setUnsubscribeAndDisbandedTeamModal(false)}
          text_one={unsubscribeAndDisbandedModalData.text_one}
          modalTitle={unsubscribeAndDisbandedModalData.title}
          secondaryButtonLabel={unsubscribeAndDisbandedModalData.closeButtonText}
          secondaryButtonColor="#20639B"
          titleWidth={"143px"}
          titleHeight={"42px"}
          titleFontWeight={"600"}
          titleFontSize={"20px"}
          titleLineHeight={"42.24px"}
          titleColor={"#333333"}
          secondaryButtonWidth={"43px"}
          secondaryButtonHeight={"16px"}
          secondaryButtonFontWeight={"600"}
          secondaryButtonFontSize={"16px"}
          secondaryButtonLineHeight={"15.92px"}
        />
      )}

      {TrialStarted && (
        <PlanDialog
          showModal={true}
          onClose={() => setTrialStarted(false)}
          text_one={trialStartedData.text_one}
          modalTitle={trialStartedData.title}
          secondaryButtonLabel={trialStartedData.closeButtonText}
          secondaryButtonColor="#20639B"
          titleWidth={"124px"}
          titleHeight={"60px"}
          titleFontWeight={"600"}
          titleColor={"#333333"}
          titleFontSize={"20px"}
          titleLineHeight={"20px"}
          secondaryButtonWidth={"43px"}
          secondaryButtonHeight={"16px"}
          secondaryButtonFontWeight={"600"}
          secondaryButtonFontSize={"16px"}
          secondaryButtonLineHeight={"15.92px"}
        />
      )}

      {isTrialExpired && (
        <PlanDialog
          showModal={true}
          onClose={() => {
            setShowPricingPlanModal(true);
            setIsTrialExpired(false);
          }}
          modalTitle={trialExpiredData.title}
          text_one={trialExpiredData.text_one}
          secondaryButtonLabel={trialExpiredData.closeButtonText}
          secondaryButtonColor="#20639B"
          titleWidth={"125px"}
          titleHeight={"20px"}
          titleFontWeight={"600"}
          titleFontSize={"20px"}
          titleLineHeight={"20px"}
          titleColor={"#333333"}
        />
      )}

      {showPricingPlanModal && (
        <PricingPlanDialog
          subscriptionData={subscriptionData}
          showModal={showPricingPlanModal}
          onClose={() => {
            setShowPricingPlanModal(false);
          }}
          text_one={pricingPlanModalData.text_one}
          text_two={pricingPlanModalData.text_two}
          text_three={pricingPlanModalData.text_three}
          modalTitle={pricingPlanModalData.title}
          primaryButtonLabel={pricingPlanModalData.actionButtonText}
          secondaryButtonLabel={pricingPlanModalData.closeButtonText}
          secondaryButtonColor="#828282"
          setChargeBillingDetailsDailog={setChargeBillingDetailsDailog}
          setSelectedPlan={setSelectedPlan}
          selectedPlan={selectedPlan}
          isDoingSubcription={true}
        />
      )}

      {showUnsubscribeModal && (
        <PlanDialog
          cancelSubscription={() => {
            setShowUnsubscribeCofirmModal(true);
            setShowUnsubscribeModal(false);
          }}
          showModal={showUnsubscribeModal}
          onClose={() => setShowUnsubscribeModal(false)}
          modalTitle={unSubsubscribeModalData.title}
          text_one={unSubsubscribeModalData.text_one}
          text_two={allUsers.length > 10 ? unSubsubscribeModalData.text_two : ""}
          secondaryButtonLabel={unSubsubscribeModalData.closeButtonText}
          secondaryButtonColor="#20639B"
          primaryButtonLabel={unSubsubscribeModalData.actionButtonText}
          primaryButtonColor="#828282"
          titleWidth={"124px"}
          titleHeight={"20px"}
          titleFontWeight={"600"}
          titleFontSize={"20px"}
          titleLineHeight={"20px"}
          titleColor={"#333333"}
          secondaryButtonWidth={"43px"}
          secondaryButtonHeight={"16px"}
          secondaryButtonFontWeight={"600"}
          secondaryButtonFontSize={"16px"}
          secondaryButtonLineHeight={"15.92px"}
          primaryButtonWidth={"100px"}
          primaryButtonHeight={"16px"}
          primaryButtonFontWeight={"600"}
          primaryButtonFontSize={"16px"}
          primaryButtonLineHeight={"15.92px"}
        />
      )}

      {showUnsubscribeConfirmModal && (
        <PlanDialog
          cancelSubscription={cancelSubscription}
          showModal={showUnsubscribeConfirmModal}
          onClose={() => setShowUnsubscribeCofirmModal(false)}
          modalTitle={unSubsubscribeConfirmModalData.title}
          text_one={unSubsubscribeConfirmModalData.text_one}
          secondaryButtonLabel={unSubsubscribeConfirmModalData.closeButtonText}
          secondaryButtonColor="#20639B"
          primaryButtonLabel={unSubsubscribeConfirmModalData.actionButtonText}
          isInput={true}
          titleWidth={"210px"}
          titleHeight={"20px"}
          titleFontWeight={"600"}
          titleFontSize={"20px"}
          titleLineHeight={"20px"}
          titleColor={"#333333"}
          secondaryButtonWidth={"43px"}
          secondaryButtonHeight={"16px"}
          secondaryButtonFontWeight={"600"}
          secondaryButtonFontSize={"16px"}
          secondaryButtonLineHeight={"15.92px"}
          primaryButtonWidth={"57px"}
          primaryButtonHeight={"16px"}
          primaryButtonFontWeight={"600"}
          primaryButtonFontSize={"16px"}
          primaryButtonLineHeight={"15.92px"}
        />
      )}

      {showResumeSubscriptionModal && (
        <PlanDialog
          cancelSubscription={hanldeResumeSubscription}
          showModal={showResumeSubscriptionModal}
          onClose={() => setShowResumeSubscriptionModal(false)}
          modalTitle={resumeSubscriptionModalData.title}
          text_one={resumeSubscriptionModalData.text_one}
          secondaryButtonLabel={resumeSubscriptionModalData.closeButtonText}
          secondaryButtonColor="#828282"
          primaryButtonColor="#20639B"
          primaryButtonLabel={resumeSubscriptionModalData.actionButtonText}
          titleWidth={"220px"}
          titleHeight={"20px"}
          titleFontWeight={"600"}
          titleFontSize={"20px"}
          titleLineHeight={"20px"}
          titleColor={"#333333"}
          secondaryButtonWidth={"43px"}
          secondaryButtonHeight={"16px"}
          secondaryButtonFontWeight={"600"}
          secondaryButtonFontSize={"16px"}
          secondaryButtonLineHeight={"15.92px"}
          primaryButtonWidth={"168px"}
          primaryButtonHeight={"16px"}
          primaryButtonFontWeight={"600"}
          primaryButtonFontSize={"16px"}
          primaryButtonLineHeight={"15.92px"}
        />
      )}

      {showAfterResumeSubscriptionModal && (
        <PlanDialog
          showModal={showAfterResumeSubscriptionModal}
          onClose={() => setShowAfterResumeSubscriptionModal(false)}
          modalTitle={afterResumeSubscriptionModalData.title}
          text_one={afterResumeSubscriptionModalData.text_one}
          text_two={afterResumeSubscriptionModalData.text_two}
          secondaryButtonLabel={afterResumeSubscriptionModalData.closeButtonText}
          secondaryButtonColor="#20639B"
          titleWidth={"223px"}
          titleHeight={"40px"}
          titleFontWeight={"600"}
          titleFontSize={"20px"}
          titleLineHeight={"20px"}
          titleColor={"#333333"}
          secondaryButtonWidth={"43px"}
          secondaryButtonHeight={"16px"}
          secondaryButtonFontWeight={"600"}
          secondaryButtonFontSize={"16px"}
          secondaryButtonLineHeight={"15.92px"}
        />
      )}

      <SubscriptionsCardDialog
        edit={edit}
        cardIndex={cardIndex}
        cardToEdit={cardToEdit}
        userCardsList={companyPaymentCard}
        show={showCardDialog}
        onHide={() => setShowCardDialog(false)}
      />

      {showPausedSubscriptionsModal && (
        <PlanDialog
          showModal={showPausedSubscriptionsModal}
          onClose={() => {
            setShowPausedSubscriptionsModal(false);
            setShowCardDialog(true);
          }}
          modalTitle={showPausedSubscriptionsModalData.title}
          text_one={showPausedSubscriptionsModalData.text_one}
          secondaryButtonLabel={showPausedSubscriptionsModalData.secondaryButtonLabel}
          secondaryButtonColor="#20639B"
          titleWidth={"191px"}
          titleHeight={"20px"}
          titleFontWeight={"600"}
          titleFontSize={"20px"}
          titleLineHeight={"20px"}
          titleColor={"#333333"}
          secondaryButtonWidth={"59px"}
          secondaryButtonHeight={"16px"}
          secondaryButtonFontWeight={"600"}
          secondaryButtonFontSize={"16px"}
          secondaryButtonLineHeight={"15.92px"}
        />
      )}

      {showExpiredSubscriptionsModal && (
        <PlanDialog
          showModal={showExpiredSubscriptionsModal}
          onClose={() => setShowExpiredSubscriptionsModal(false)}
          modalTitle={showSubscriptionExpiredModalData.title}
          text_one={showSubscriptionExpiredModalData.text_one}
          secondaryButtonLabel={showSubscriptionExpiredModalData.closeButtonText}
          secondaryButtonColor="#828282"
          primaryButtonColor="#20639B"
          primaryButtonLabel={showSubscriptionExpiredModalData.actionButtonText}
          setShowPricingPlanModal={setShowPricingPlanModal}
          titleWidth={"211px"}
          titleHeight={"20px"}
          titleFontWeight={"600"}
          titleFontSize={"20px"}
          titleLineHeight={"20px"}
          titleColor={"#333333"}
          secondaryButtonWidth={"43px"}
          secondaryButtonHeight={"16px"}
          secondaryButtonFontWeight={"600"}
          secondaryButtonFontSize={"16px"}
          secondaryButtonLineHeight={"15.92px"}
          primaryButtonWidth={"78px"}
          primaryButtonHeight={"16px"}
          primaryButtonFontWeight={"600"}
          primaryButtonFontSize={"16px"}
          primaryButtonLineHeight={"15.92px"}
        />
      )}

      {showSubscriptionExpiredModal && (
        <PlanDialog
          showModal={showSubscriptionExpiredModal}
          onClose={() => setShowSubscriptionExpiredModal(false)}
          modalTitle={showSubscriptionExpiredModalAfterRevertToFreePlanData.title}
          text_one={showSubscriptionExpiredModalAfterRevertToFreePlanData.text_one}
          secondaryButtonLabel={showSubscriptionExpiredModalAfterRevertToFreePlanData.closeButtonText}
          secondaryButtonColor="#828282"
          primaryButtonColor="#20639B"
          primaryButtonLabel={showSubscriptionExpiredModalAfterRevertToFreePlanData.actionButtonText}
          setShowDetailsDialog={setShowDetailsDialog}
          titleWidth={"211px"}
          titleHeight={"20px"}
          titleFontWeight={"600"}
          titleFontSize={"20px"}
          titleLineHeight={"20px"}
          titleColor={"#333333"}
          secondaryButtonWidth={"43px"}
          secondaryButtonHeight={"16px"}
          secondaryButtonFontWeight={"600"}
          secondaryButtonFontSize={"16px"}
          secondaryButtonLineHeight={"15.92px"}
          primaryButtonWidth={"78px"}
          primaryButtonHeight={"16px"}
          primaryButtonFontWeight={"600"}
          primaryButtonFontSize={"16px"}
          primaryButtonLineHeight={"15.92px"}
        />
      )}

      {showSubscriptionExpiredModal && (
        <PlanDialog
          showModal={showSubscriptionExpiredModal}
          onClose={() => setShowSubscriptionExpiredModal(false)}
          modalTitle={showSubscriptionExpiredModalAfterRevertToFreePlanData.title}
          text_one={showSubscriptionExpiredModalAfterRevertToFreePlanData.text_one}
          secondaryButtonLabel={showSubscriptionExpiredModalAfterRevertToFreePlanData.closeButtonText}
          secondaryButtonColor="#828282"
          primaryButtonColor="#20639B"
          primaryButtonLabel={showSubscriptionExpiredModalAfterRevertToFreePlanData.actionButtonText}
          setShowDetailsDialog={setShowDetailsDialog}
          titleWidth={"211px"}
          titleHeight={"20px"}
          titleFontWeight={"600"}
          titleFontSize={"20px"}
          titleLineHeight={"20px"}
          titleColor={"#333333"}
          secondaryButtonWidth={"43px"}
          secondaryButtonHeight={"16px"}
          secondaryButtonFontWeight={"600"}
          secondaryButtonFontSize={"16px"}
          secondaryButtonLineHeight={"15.92px"}
          primaryButtonWidth={"78px"}
          primaryButtonHeight={"16px"}
          primaryButtonFontWeight={"600"}
          primaryButtonFontSize={"16px"}
          primaryButtonLineHeight={"15.92px"}
        />
      )}

      {revertToFreePlanAfterExpired && (
        <PlanDialog
          showModal={revertToFreePlanAfterExpired}
          onClose={() => setRevertToFreePlanAfterExpired(false)}
          modalTitle={revertToFreePlanAfterExpiredData.title}
          text_one={revertToFreePlanAfterExpiredData.text_one}
          text_two={allUsers?.length > 10 ? revertToFreePlanAfterExpiredData.text_two : null}
          secondaryButtonLabel={revertToFreePlanAfterExpiredData.closeButtonText}
          secondaryButtonColor="#20639B"
          primaryButtonColor="#828282"
          primaryButtonLabel={revertToFreePlanAfterExpiredData.actionButtonText}
          cancelSubscription={cancelSubscription}
          titleWidth={"190px"}
          titleHeight={"20px"}
          titleFontWeight={"600"}
          titleFontSize={"20px"}
          titleLineHeight={"20px"}
          titleColor={"#333333"}
          secondaryButtonWidth={"43px"}
          secondaryButtonHeight={"16px"}
          secondaryButtonFontWeight={"600"}
          secondaryButtonFontSize={"16px"}
          secondaryButtonLineHeight={"15.92px"}
          primaryButtonWidth={"152px"}
          primaryButtonHeight={"16px"}
          primaryButtonFontWeight={"600"}
          primaryButtonFontSize={"16px"}
          primaryButtonLineHeight={"15.92px"}
        />
      )}

      {updateSeatsModal && (
        <PlanDialog
          showModal={updateSeatsModal}
          onClose={() => {
            setUpdateSeatsModal(false);
            setChargeBillingDetailsDailog(false);
          }}
          modalTitle={updateSeatsModalData.title}
          text_one={updateSeatsModalData.text_one}
          primaryButtonLabel={updateSeatsModalData.primaryButtonLabel}
          secondaryButtonLabel={updateSeatsModalData.secondaryButtonLabel}
          handleModalAction={() => setUpdateSeatsModal(true)}
          secondaryButtonColor="#20639B"
          titleWidth={"135px"}
          titleHeight={"20px"}
          titleFontWeight={"600"}
          titleFontSize={"20px"}
          titleLineHeight={"20px"}
          titleColor={"#333333"}
          secondaryButtonWidth={"43px"}
          secondaryButtonHeight={"16px"}
          secondaryButtonFontWeight={"600"}
          secondaryButtonFontSize={"16px"}
          secondaryButtonLineHeight={"15.92px"}
        />
      )}
    </div>
  );
};

const mapStateToProps = ({ authentication, subscription, user }) => ({
  plans: subscription.plans,
  user: authentication.user,
  subscriptionData: subscription.subscriptionData,
  allUsers: user.allUsers,
});

const mapDispatchToProps = {
  getSubscriptionByCompanyId,
  updateSubscriptionData,
  getAllUsers,
  getSession,
  getInvoices,
  getCard,
  verifyCard,
  getCompanyById,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionsView);
