import React, { useState, useEffect } from "react";
import validator from "validator";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import "react-phone-number-input/style.css";
import CreditCardType from "credit-card-type";
import countryList from "react-select-country-list";
import I18n, { i18n } from "../../../languageSelector";
import { useForm, useFormState } from "react-hook-form";
import DynamicModal from "../../../../../components/dialogs";
import IconInfo from "../../../../../assets/images/icon-info.svg";
import Input from "../../../../../components/form-input/input.component";
import { createSubscriptionInvoice, createSubscriptionPayment } from "../../../../../redux/reducers/subscription/subscription.reducer";
import { updateUserById } from "../../../../../redux/reducers/user/user.reducer";
import { DATE_REGEX, INVALID_DATE, MIN_NUM_LENGTH, MAX_NUM_LENGTH } from "../../../../../config/validations";
import { jumpInputField, INVOICE_NUMBER_GENERATOR, TEXT_DIRECTION } from "../../../../../helper/helperFunctions";
import { INPUT_VALUES_TO_EXCLUDE } from "../../../../../config/constants";
import ReactSelect from "../../../../../components/form-input/React-Select";
import CheckBox from "../../../../../components/form-input/checkbox.component";
import { getInvoices } from "../../../../../redux/reducers/invoices/invoices.reducer";

const subscriptionInitState = {
  firstName: "",
  lastName: "",
  addressLineOne: "",
  addressLineTwo: "",
  city: "",
  country: "",
  state: "",
  postalCode: "",
  name: "",
  expiry: "",
  CVC: "",
  cardNumber: "",
  totalUsers: 0,
  default: false,
};

const CompanySubscriptionsAdd = ({
  user,
  plan,
  invoice,
  showDlg,
  invoices,
  userEmpty,
  getInvoices,
  updateUserById,
  secondaryAction,
  createSubscriptionInvoice,
  createSubscriptionPayment,
}) => {
  const [vat, setVat] = useState(0);
  const [coupon, setCoupon] = useState("");
  const [subTotal, setSubTotal] = useState(0);
  const [usersCount, setUsersCount] = useState(1);
  const [expiryDate, setExpiryDate] = useState("");
  const [cardError, setCardError] = useState(false);
  const [monthsCount, setMonthsCount] = useState(1);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [cardNumber, setCardNumber] = useState("", "", "", "");
  const [formSetps, setFormSteps] = useState("new subscription");
  const [buttonDisabledCard, setButtonDisabledCard] = useState(true);
  const [buttonDisabledCardNumber, setButtonDisabledCardNumber] = useState(true);

  const {
    reset,
    control,
    register,
    setValue,
    handleSubmit,
    getFieldState,
    formState: { errors },
  } = useForm({
    defaultValues: subscriptionInitState,
  });

  const { dirtyFields } = useFormState({
    control,
  });

  // fetching all invoices
  useEffect(() => {
    getInvoices();
    // eslint-disable-next-line
  }, []);

  const options = countryList().getData();
  let userCardsList = [...user.userCards];

  const incrementUserCount = () => {
    setUsersCount(usersCount + 1);
  };

  const decrementUserCount = () => {
    if (usersCount > 0) {
      setUsersCount(usersCount - 1);
    }
  };

  useEffect(() => {
    if (user?.userCards?.length) {
      let defaultCard = user.userCards.filter((item) => {
        return item.default;
      });
      if (defaultCard?.length) {
        setValue("default", false);
      } else {
        setValue("default", true);
      }
    } else {
      setValue("default", true);
    }
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (plan?.type === "Monthly Subscription") {
      setMonthsCount(1);
    } else {
      setMonthsCount(12);
    }
    if (plan?.totalUsers) {
      setUsersCount(plan.totalUsers);
    }
  }, [plan]);

  useEffect(() => {
    setVat((subTotal * 15) / 100);
    setSubTotal(usersCount * monthsCount * plan?.price);

    // eslint-disable-next-line
  }, [usersCount, subTotal, plan, monthsCount]);

  const handleFirstForm = () => {
    if (usersCount === 0) {
      toast.error("Please select atleast one user");
    } else {
      setFormSteps("form filling");
    }
  };

  const handleExpiryDate = (e) => {
    let textTemp = e.target.value;
    if (expiryDate && expiryDate.length === 3 && textTemp.length === 2) {
      textTemp = textTemp[0];
    } else if (textTemp.length === 2) {
      textTemp += "/";
    }
    setExpiryDate(textTemp);
    setValue("expiry", textTemp, {
      shouldDirty: true,
    });
  };

  const handleSecondForm = (data) => {
    if (Object.keys(errors).length === 0) {
      createSubscriptionInvoice({
        currency: "SAR",
        amount: Math.ceil(subTotal + vat) + "00",
        description: "Invoice & Payment",
        metadata: {
          firstName: data.firstName,
          lastName: data.lastName,
          city: data.city,
          country: data.country.label,
          addressOne: data.addressLineOne,
          addressTwo: data.addressLineTwo,
          state: data.state,
          postalCode: data.postalCode,
          customerId: user.id,
          subscriptionPlan: { ...plan, totalUsers: usersCount },
          invoiceNumber: INVOICE_NUMBER_GENERATOR(invoices.length + 1),
        },
      })
        .then(() => {
          setFormSteps("payments details");
        })
        .catch((err) => console.log(err?.response?.data));
    }
  };

  const onSubmit = (data) => {
    if (cardNumber && cardNumber?.join("").length === 16 && !cardError) {
      createSubscriptionPayment({
        invoice_id: invoice.invoiceId,
        description: invoice.description,
        currency: invoice.currency,
        amount: invoice.amount,
        callback_url: `${window.location.origin}/subscription-payment`,
        source: {
          type: "creditcard",
          name: data.name,
          number: cardNumber.join(""),
          cvc: Number(data.CVC),
          month: Number(data.expiry?.split("/")[0]),
          year: Number(data.expiry?.split("/")[1]),
        },
      })
        .then((paymentRes) => {
          let dummyList = [...userCardsList];
          let existingCard = userCardsList.some((item) => item.number === Number(cardNumber.join("")));

          if (!existingCard) {
            if (data.default && dummyList.length) {
              dummyList = dummyList.map((item) => {
                return { ...item, default: false };
              });
            }
            dummyList = [
              ...dummyList,
              {
                name: data.name,
                default: data.default,
                number: Number(cardNumber.join("")),
                month: data.expiry.split("/")[0],
                year: data.expiry.split("/")[1],
              },
            ];
            updateUserById({
              ...user,
              userCards: [...dummyList],
            })
              .then(() => {
                window.open(paymentRes?.value?.data?.source?.transaction_url, "_self");
              })
              .catch((err) => console.log(err?.response?.data));
          } else {
            window.open(paymentRes?.value?.data?.source?.transaction_url, "_self");
          }
        })
        .catch((err) => console.log(err?.response?.data));
    } else {
      toast.error(i18n("Subscriptions.EnterValidCardNumber"));
    }
  };

  useEffect(() => {
    if (userEmpty) {
      setUsersCount(1);
      reset({ ...subscriptionInitState });
      setFormSteps("new subscription");
    }
    // eslint-disable-next-line
  }, [userEmpty]);

  useEffect(() => {
    if (getFieldState("firstName").isDirty && getFieldState("country").isDirty) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }

    if (getFieldState("CVC").isDirty && getFieldState("expiry").isDirty && getFieldState("name").isDirty) {
      setButtonDisabledCard(false);
    } else {
      setButtonDisabledCard(true);
    }
    if (cardNumber && cardNumber.join("").length === 16) {
      setButtonDisabledCardNumber(false);
    } else {
      setButtonDisabledCardNumber(true);
    }
    // eslint-disable-next-line
  }, [getFieldState(), cardNumber, expiryDate, dirtyFields]);

  useEffect(() => {
    if (cardNumber.length && cardNumber.join("").length === 16) {
      if (validator.isCreditCard(cardNumber.join(""))) {
        setCardError(false);
      } else {
        setCardError(true);
      }
    } else {
      setCardError(false);
    }
  }, [cardNumber]);

  return (
    <>
      <DynamicModal
        customClasses="modal-drawer company-subscription-drawer"
        show={showDlg}
        secondaryAction={() => {
          setFormSteps(formSetps === "form filling" ? "new subscription" : formSetps === "payments details" ? "form filling" : "new subscription");
        }}
      >
        {formSetps === "new subscription" ? (
          <form id="my-form" className="comp-subscriptions-form mt-5" onSubmit={handleSubmit(onSubmit)}>
            <div className="d-flex align-items-center mb-4">
              <div className="input-group input-number" style={{ width: "25%" }}>
                <input type="number" name="userCount" className="form-control score p-0" value={usersCount} disabled readOnly />
                <div className="btn-group">
                  <button
                    className="btn p-2"
                    onClick={(e) => {
                      e.preventDefault();
                      incrementUserCount();
                    }}
                  />
                  <button
                    className="btn p-2"
                    onClick={(e) => {
                      e.preventDefault();
                      decrementUserCount();
                    }}
                  />
                </div>
              </div>
              <label className="form-label fs-lg m-0 ml-1">
                <I18n lng="Company.Users" />
              </label>
            </div>
            <ul className="subscription-cost">
              <li>
                <label className="text-muted">
                  <I18n lng="Subscriptions.SubTotal" />
                </label>
                <strong className={`${TEXT_DIRECTION() === "rtl" ? "mr-auto" : "ml-auto"}`}>{subTotal?.toFixed(2)} $</strong>
              </li>
              <li>
                <label className="text-muted">
                  Vat. <span>(15%)</span>
                </label>
                <strong className={`${TEXT_DIRECTION() === "rtl" ? "mr-auto" : "ml-auto"}`}>{vat?.toFixed(2)} $</strong>
              </li>
              <li>
                <label>
                  <b>
                    <I18n lng="Subscriptions.Total" />
                  </b>
                </label>
                <strong className={`${TEXT_DIRECTION() === "rtl" ? "mr-auto" : "ml-auto"}`}>
                  <b>{(subTotal + vat + 0).toFixed(2)} $</b>
                </strong>
              </li>
            </ul>
            <div className="input-coupon-code mb-5">
              <label className="form-label">
                <I18n lng="Subscriptions.YouHaveDiscountCoupon" />
              </label>
              <div className="input-group pr-0">
                <input
                  className="form-control"
                  type="text"
                  name="coupon"
                  value={coupon}
                  placeholder={i18n("Subscriptions.EnterCouponCode")}
                  onChange={(e) => setCoupon(e.target.value)}
                />
                <button type="text" className="btn btn-primary" onClick={(e) => e.preventDefault()}>
                  {<I18n lng="global.Apply" />}
                </button>
              </div>
            </div>
          </form>
        ) : formSetps === "payments details" ? (
          <>
            <div className="form-view-wrapper dispatch-card">
              <form className="dispatch-wrapper text-center">
                <div className="form-header">
                  <div className="form-title">
                    <strong>
                      <I18n lng="Subscriptions.AddYourCardDetails" />
                    </strong>
                  </div>
                </div>
              </form>
            </div>
            {user?.userCards?.length ? (
              <div className="card dispatch-card mb-2">
                <label className="form-label p-0 mb-3 mt-2">
                  <I18n lng="Subscriptions.SelectThePaymentCard" />
                </label>
                {user?.userCards?.length
                  ? user?.userCards?.map((item, index) => {
                      return (
                        <div key={index}>
                          <label className="text-muted">
                            {CreditCardType(String(item.number).slice(0, 4)).length === 1
                              ? CreditCardType(String(item.number).slice(0, 4))[0].niceType
                              : item?.name}{" "}
                            :
                          </label>
                          <span> ************{String(item?.number).slice(12)}</span>
                          <span className="pl-3"> {item?.month + "/" + item?.year}</span>
                          <strong className="float-right">
                            <input
                              type="radio"
                              name="card"
                              onClick={() => {
                                setValue("name", item.name, {
                                  shouldDirty: true,
                                });
                                setValue("CVC", item.cvc, {
                                  shouldDirty: true,
                                });
                                setValue("expiry", item.month + "/" + item.year, {
                                  shouldDirty: true,
                                });
                                setExpiryDate(item.month + "/" + item.year);
                                setCardNumber([
                                  String(item.number).slice(0, 4),
                                  String(item.number).slice(4, 8),
                                  String(item.number).slice(8, 12),
                                  String(item.number).slice(12, 16),
                                ]);
                              }}
                            />
                          </strong>
                        </div>
                      );
                    })
                  : null}
                <div>
                  <label className="text-muted">
                    <I18n lng="Subscriptions.UseAnotherCard" />
                  </label>
                  <strong className="float-right">
                    <input
                      type="radio"
                      name="card"
                      onClick={() => {
                        setValue("name", "", { shouldDirty: "false" });
                        setValue("expiry", "", { shouldDirty: "false" });
                        setValue("CVC", "", { shouldDirty: "false" });
                        setExpiryDate("");
                        setCardNumber(["", "", "", ""]);
                      }}
                    />
                  </strong>
                </div>
              </div>
            ) : null}
            <form className="payment-card-form" onSubmit={handleSubmit(onSubmit)} id="my-form">
              <label className="credit-card-label">
                <I18n lng="Subscriptions.Name" />
              </label>
              <Input
                // label="Name"
                name="name"
                type="text"
                placeholder={i18n("Subscriptions.EnterTheName")}
                register={register}
                validations={{
                  required: {
                    value: true,
                    message: i18n("validation.REQUIRED_FIELD"),
                  },
                }}
                error={errors["name"]}
              />

              <label className="credit-card-label">
                <I18n lng="Subscriptions.CardNumber" />
              </label>
              <div className="row" style={TEXT_DIRECTION() === "rtl" ? { direction: "ltr" } : {}}>
                <div className="col-3 remove-arrows">
                  <input
                    pattern="\d*"
                    type="string"
                    name="field-1"
                    maxLength="4"
                    value={cardNumber[0]}
                    onKeyDown={(e) => INPUT_VALUES_TO_EXCLUDE.includes(e.key) && e.preventDefault()}
                    onChange={(e) => {
                      jumpInputField(e, 0, cardNumber, setCardNumber);
                    }}
                  />
                </div>
                <div className="col-3 remove-arrows">
                  <input
                    pattern="\d*"
                    type="string"
                    maxLength="4"
                    name="field-2"
                    value={cardNumber[1]}
                    onKeyDown={(e) => INPUT_VALUES_TO_EXCLUDE.includes(e.key) && e.preventDefault()}
                    onChange={(e) => {
                      jumpInputField(e, 1, cardNumber, setCardNumber);
                    }}
                  />
                </div>
                <div className="col-3 remove-arrows">
                  <input
                    pattern="\d*"
                    type="string"
                    maxLength="4"
                    name="field-3"
                    value={cardNumber[2]}
                    onKeyDown={(e) => INPUT_VALUES_TO_EXCLUDE.includes(e.key) && e.preventDefault()}
                    onChange={(e) => {
                      jumpInputField(e, 2, cardNumber, setCardNumber);
                    }}
                  />
                </div>
                <div className={`col-3 remove-arrows ${!cardError ? "mb-3" : ""}`}>
                  <input
                    pattern="\d*"
                    type="string"
                    maxLength="4"
                    name="field-4"
                    value={cardNumber[3]}
                    onKeyDown={(e) => INPUT_VALUES_TO_EXCLUDE.includes(e.key) && e.preventDefault()}
                    onChange={(e) => {
                      jumpInputField(e, 3, cardNumber, setCardNumber);
                    }}
                  />
                </div>
              </div>
              {cardError && (
                <label className="credit-card-error label">
                  <I18n lng="Subscriptions.EnterValidCard" />
                </label>
              )}
              <div className="row input-cardnumber">
                <div className="col-6">
                  <label className="credit-card-label">
                    <I18n lng="Subscriptions.Expiry" />
                  </label>
                  <Input
                    // label="Expiry"
                    name="expiry"
                    type="string"
                    maxLength="5"
                    placeholder="MM/YY"
                    onKeyDown={(e) => INPUT_VALUES_TO_EXCLUDE.includes(e.key) && e.preventDefault()}
                    value={expiryDate}
                    onChange={handleExpiryDate}
                    register={register}
                    validations={{
                      required: {
                        value: true,
                        message: i18n("validation.REQUIRED_FIELD"),
                      },
                      pattern: {
                        value: DATE_REGEX,
                        message: INVALID_DATE,
                      },
                    }}
                    error={errors["expiry"]}
                    className="form-control text-center"
                  />
                </div>
                <div className="col-6">
                  <Input
                    label="CVV"
                    name="CVC"
                    type="string"
                    placeholder="CVV"
                    maxLength="3"
                    onKeyDown={(e) => INPUT_VALUES_TO_EXCLUDE.includes(e.key) && e.preventDefault()}
                    suffix={IconInfo}
                    register={register}
                    validations={{
                      required: {
                        value: true,
                        message: i18n("validation.REQUIRED_FIELD"),
                      },
                      minLength: { value: 3, message: MIN_NUM_LENGTH(3) },
                      maxLength: { value: 3, message: MAX_NUM_LENGTH(3) },
                    }}
                    error={errors["CVC"]}
                    className="form-control text-center"
                  />
                </div>
              </div>
              <div className="row">
                <div className={`${TEXT_DIRECTION() === "rtl" ? "pr-2" : "col- pl-3"}`}>
                  <CheckBox name="default" register={register} label />
                </div>
                <div style={{ color: "#20639b" }} className={`${TEXT_DIRECTION() === "rtl" ? "col-6 p-0 pr-2" : "col-6 p-0"}`}>
                  <label className="mb-0" style={{ color: "#20639b" }}>
                    <I18n lng="Subscriptions.SetDefaultPaymentCard" />
                  </label>
                </div>
              </div>
            </form>
          </>
        ) : formSetps === "form filling" ? (
          <>
            <div className="form-view-wrapper dispatch-card">
              <form className="dispatch-wrapper text-center">
                <div className="form-header">
                  <div className="form-title">
                    <strong>
                      <I18n lng="Subscriptions.AddYourBillingAddress" />
                    </strong>
                  </div>
                </div>
              </form>
            </div>
            <form className="payment-card-form" onSubmit={handleSubmit(onSubmit)} id="my-form">
              <Input
                label={<I18n lng="Subscriptions.FirstName" />}
                name="firstName"
                type="text"
                placeholder={i18n("Subscriptions.EnterFirstName")}
                register={register}
                {...register("firstName")}
                validations={{
                  required: {
                    value: true,
                    message: i18n("validation.REQUIRED_FIELD"),
                  },
                }}
                error={errors["firstName"]}
              />
              <Input
                label={<I18n lng="Subscriptions.LastName" />}
                name="lastName"
                type="text"
                placeholder={i18n("Subscriptions.EnterLastName")}
                register={register}
                validations={{
                  required: {
                    value: true,
                    message: i18n("validation.REQUIRED_FIELD"),
                  },
                }}
                error={errors["lastName"]}
              />
              <Input
                label={<I18n lng="Subscriptions.AddressLine1" />}
                name="addressLineOne"
                type="text"
                placeholder={i18n("Subscriptions.EnterYourAddress")}
                register={register}
                validations={{
                  required: {
                    value: true,
                    message: i18n("validation.REQUIRED_FIELD"),
                  },
                }}
                error={errors["addressLineOne"]}
              />
              <Input
                label={<I18n lng="Subscriptions.AddressLine2" />}
                name="addressLineTwo"
                type="text"
                placeholder={i18n("Subscriptions.EnterYourAddress")}
                register={register}
                validations={{}}
              />
              <div className="row input-cardnumber">
                <div className="col-6">
                  <Input
                    label={<I18n lng="Subscriptions.City" />}
                    name="city"
                    type="text"
                    placeholder={i18n("Subscriptions.EnterYourCity")}
                    register={register}
                    validations={{
                      required: {
                        value: true,
                        message: i18n("validation.REQUIRED_FIELD"),
                      },
                    }}
                    error={errors["city"]}
                  />
                </div>
                <div className="col-6">
                  <Input
                    label={<I18n lng="Subscriptions.State" />}
                    name="state"
                    type="text"
                    placeholder={i18n("Subscriptions.EnterYourState")}
                    register={register}
                    validations={{
                      required: {
                        value: true,
                        message: i18n("validation.REQUIRED_FIELD"),
                      },
                    }}
                    error={errors["state"]}
                  />
                </div>
              </div>
              <div className="row input-cardnumber">
                <div className="col-6">
                  <Input
                    label={<I18n lng="Subscriptions.PostalCode" />}
                    name="postalCode"
                    type="text"
                    placeholder={i18n("Subscriptions.EnterYourPostalCode")}
                    register={register}
                    validations={{
                      required: {
                        value: true,
                        message: i18n("validation.REQUIRED_FIELD"),
                      },
                    }}
                    error={errors["postalCode"]}
                  />
                </div>
                <div className="col-6">
                  <ReactSelect
                    label={<I18n lng="global.Country" />}
                    placeholder={<I18n lng="global.Select" />}
                    name="country"
                    register={register}
                    validations={{
                      required: {
                        value: true,
                        message: i18n("validation.REQUIRED_FIELD"),
                      },
                    }}
                    control={control}
                    options={options?.length ? options : [i18n("Subscriptions.PleaseEnterCountry")]}
                  />
                </div>
              </div>
            </form>
          </>
        ) : null}

        <div className="btn-bar">
          {formSetps !== "new subscription" && (
            <button
              className="btn btn-white"
              onClick={() => {
                setFormSteps(
                  formSetps === "form filling" ? "new subscription" : formSetps === "payments details" ? "form filling" : "new subscription"
                );
                secondaryAction();
              }}
            >
              <I18n lng="global.Cancel" />
            </button>
          )}
          {formSetps === "new subscription" && (
            <>
              <button className="btn btn-white" onClick={secondaryAction}>
                <I18n lng="global.Cancel" />
              </button>
              <button className="btn btn-primary" type="submit" onClick={handleSubmit(handleFirstForm)}>
                <I18n lng="global.Save&Continue" />
              </button>
            </>
          )}
          {formSetps === "form filling" && (
            <button
              className={`btn ${buttonDisabled ? "btn-light" : "btn-primary"}`}
              type="submit"
              onClick={!buttonDisabled ? handleSubmit(handleSecondForm) : () => {}}
            >
              <I18n lng="global.Continue" />
            </button>
          )}
          {formSetps === "payments details" && (
            <button
              className={`btn ${
                (buttonDisabledCard && buttonDisabledCardNumber) ||
                (buttonDisabledCard && !buttonDisabledCardNumber) ||
                (!buttonDisabledCard && buttonDisabledCardNumber)
                  ? "btn-light"
                  : "btn-primary"
              }`}
              type="submit"
              form="my-form"
            >
              <I18n lng="global.Save&Continue" />
            </button>
          )}
        </div>
      </DynamicModal>
    </>
  );
};
const mapStateToProps = ({ authentication, subscription, invoices }) => ({
  user: authentication.user,
  invoices: invoices.invoices,
  invoice: subscription.invoice,
});

const mapDispatchToProps = {
  getInvoices,
  updateUserById,
  createSubscriptionInvoice,
  createSubscriptionPayment,
};
export default connect(mapStateToProps, mapDispatchToProps)(CompanySubscriptionsAdd);
