import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import SignatureCanvas from "react-signature-canvas";
import I18n, { i18n } from "../../../languageSelector";
import { uploadImage } from "../../../../../redux/reducers/form/form.reducer";
import { updateFormById } from "../../../../../redux/reducers/form/form.reducer";

const TitleSignaturePad = ({
  view,
  preparedBy,
  updateFormById,
  sigValue,
  fromId,
  currentRole,
}) => {
  let sigRef = React.useRef(null);

  const [value, setValue] = useState([]);

  useEffect(() => {
    if (fromId && currentRole && sigRef?.toData().length !== 0) {
      updateFormById(fromId, [...sigRef?.toData()]);
    }
    // eslint-disable-next-line
  }, [value]);

  useEffect(() => {
    if (sigValue && sigValue.length) {
      setTimeout(() => {
        sigRef?.fromData(sigValue);
      }, 500);
    }
    // eslint-disable-next-line
  }, [sigValue]);

  return (
    <div className="multiple-choice">
      <div className="d-flex justify-content-between question-title h-auto"></div>
      <div className="attachment-preview"></div>
      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <div className="form-group">
              <input
                className="form-control"
                placeholder={i18n("global.EnterName")}
                defaultValue={preparedBy}
                readOnly={true}
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className="col-12">
          <strong>
            <I18n lng="Form.SignHere" />{" "}
          </strong>
          <div className="signature-preview">
            <SignatureCanvas
              ref={(ref) => {
                sigRef = ref;
              }}
              onEnd={(value) => setValue(value)}
            />
          </div>
        </div>
        <div className="col-12">
          {!view && (
            <button
              className={`btn btn-primary align-items-center pt-2 pb-2 mt-2 w-100`}
              onClick={() => {
                sigRef.clear();
              }}
            >
              <I18n lng="global.ClearSignature" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ authentication }) => ({
  currentRole: authentication.currentRole,
});

const mapDispatchToProps = {
  uploadImage,
  updateFormById,
};

export default connect(mapStateToProps, mapDispatchToProps)(TitleSignaturePad);
