import React, { useState, useEffect } from "react";
import { TEXT_DIRECTION } from "../../../../../helper/helperFunctions";
import I18n from "../../../languageSelector";
import ReportPdfImages from "../report-pdf-images/reportPdfImages.js";

const GlobalListPdfPreview = ({
  getAnswer = (uuid) => {
    console.log(uuid);
    return undefined;
  },
  question,
  failStatus,
  hideAndShow,
  setImageIndex,
  setShowImageSrc,
  setShowImageDialog,
  setShowSection,
  setTotalAnswered,
  showDocumentMedia,
  imageCountData,
}) => {
  const [note, setNote] = useState("");
  const [image, setImage] = useState("");
  const [photo, setPhoto] = useState("");
  const [imageCount, setImageCount] = useState(0);
  const [answerValue, setAnswerValue] = useState("");

  useEffect(() => {
    if (question && question.questionUuid) {
      let answer = getAnswer(question.questionUuid);
      if (answer) {
        setAnswerValue(answer.value);
        setNote(answer.note);
        setImage(answer.image);
        setPhoto(answer.image);

        if (answer?.value || answer?.note || answer?.image) {
          setShowSection(true);
          setTotalAnswered((prev) => prev + 1);
        }
      }
    }
    // eslint-disable-next-line
  }, [question]);

  useEffect(() => {
    if (question && question.questionUuid && imageCountData?.length) {
      let data = imageCountData.filter((item) => {
        return item.questionId === question.questionUuid;
      });
      if (data.length) {
        setImageCount(data[0].imageCountStart);
      }
    }
  }, [question, imageCountData]);

  return (
    <>
      {!failStatus ? (
        hideAndShow?.content?.all ? (
          <div className="table-body">
            <div
              className="row table-row ml-0 mr-0 question status-na"
              style={{
                boxShadow: "0px 5px 5px rgba(0,0,0,.10)",
                backgroundColor: "#ffffff",
              }}
            >
              <div
                className={`col-lg-8 col-md-6 mb-3 table-column d-flex ${
                  TEXT_DIRECTION() === "rtl" ? "pr-0" : "pl-0"
                }`}
              >
                <span
                  className={`text-muted ${
                    TEXT_DIRECTION() === "rtl" ? "ml-2" : "mr-2"
                  }`}
                >
                  {question?.questionNumber}.
                </span>
                {"   "}
                {question?.settings?.questionText === "Add a new question" ? (
                  <I18n lng="Form.AddANewQuestion" />
                ) : (
                  question?.settings?.questionText
                )}
              </div>
              <div
                className="col-lg-4 col-md-6 table-column question-type text-primary"
                style={{ padding: "0 23px" }}
              >
                {answerValue ? answerValue : ""}
                <div className="table-column">
                  {note || photo.length ? (
                    <div
                      className="table-column attachments-wrapper pb-3"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <>
                        <fieldset>
                          <legend
                            className={`${
                              TEXT_DIRECTION() === "rtl"
                                ? "legend-arabic"
                                : "legend"
                            }`}
                          >
                            <I18n lng="global.InspectorNotes" />
                          </legend>
                          {note ? (
                            <div className="notes">
                              <p>{note}</p>
                            </div>
                          ) : null}
                          {photo.length ? (
                            <div className="thumbnail-wrapper">
                              <ReportPdfImages
                                photo={photo}
                                imageCount={imageCount}
                                setImageIndex={setImageIndex}
                                setShowImageSrc={setShowImageSrc}
                                showDocumentMedia={showDocumentMedia}
                                setShowImageDialog={setShowImageDialog}
                              />
                            </div>
                          ) : null}
                        </fieldset>
                      </>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ) : hideAndShow?.content?.answered && (answerValue || note || image) ? (
          <div className="table-body">
            <div
              className="row ml-0 mr-0 table-row question status-na"
              style={{
                boxShadow: "0px 5px 5px rgba(0,0,0,.10)",
                backgroundColor: "#ffffff",
              }}
            >
              <div
                className={`col-lg-8 col-md-6 mb-3 table-column d-flex ${
                  TEXT_DIRECTION() === "rtl" ? "pr-0" : "pl-0"
                }`}
              >
                <span
                  className={`text-muted ${
                    TEXT_DIRECTION() === "rtl" ? "ml-2" : "mr-2"
                  }`}
                >
                  {question?.questionNumber}.
                </span>
                {"   "}
                {question?.settings?.questionText === "Add a new question" ? (
                  <I18n lng="Form.AddANewQuestion" />
                ) : (
                  question?.settings?.questionText
                )}
              </div>
              <div className=" col-lg-8 col-md-6 table-column question-type text-primary">
                {answerValue ? answerValue : ""}
                <div className="table-column">
                  {note || photo.length ? (
                    <div
                      className="table-column attachments-wrapper pb-3"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <>
                        <fieldset>
                          <legend
                            className={`${
                              TEXT_DIRECTION() === "rtl"
                                ? "legend-arabic"
                                : "legend"
                            }`}
                          >
                            <I18n lng="global.InspectorNotes" />
                          </legend>
                          {note ? (
                            <div className="notes">
                              <p>{note}</p>
                            </div>
                          ) : null}
                          {photo.length ? (
                            <div className="thumbnail-wrapper">
                              <ReportPdfImages
                                photo={photo}
                                imageCount={imageCount}
                                setImageIndex={setImageIndex}
                                setShowImageSrc={setShowImageSrc}
                                showDocumentMedia={showDocumentMedia}
                                setShowImageDialog={setShowImageDialog}
                              />
                            </div>
                          ) : null}
                        </fieldset>
                      </>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ) : hideAndShow?.content?.unAnswered &&
          !answerValue &&
          !note &&
          !image ? (
          <div className="table-body">
            <div
              className="row ml-0 mr-0 table-row question status-na"
              style={{
                boxShadow: "0px 5px 5px rgba(0,0,0,.10)",
                backgroundColor: "#ffffff",
              }}
            >
              <div
                className={`col-lg-8 col-md-6 mb-3 table-column d-flex ${
                  TEXT_DIRECTION() === "rtl" ? "pr-0" : "pl-0"
                }`}
              >
                <span
                  className={`text-muted ${
                    TEXT_DIRECTION() === "rtl" ? "ml-2" : "mr-2"
                  }`}
                >
                  {question?.questionNumber}.
                </span>
                {"   "}
                {question?.settings?.questionText === "Add a new question" ? (
                  <I18n lng="Form.AddANewQuestion" />
                ) : (
                  question?.settings?.questionText
                )}
              </div>
              <div className="table-column col-lg-4 col-md-6 question-type text-primary">
                {answerValue ? answerValue : ""}
                <div className="table-column">
                  {note || photo.length ? (
                    <div
                      className="table-column attachments-wrapper pb-3"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <>
                        <fieldset>
                          <legend
                            className={`${
                              TEXT_DIRECTION() === "rtl"
                                ? "legend-arabic"
                                : "legend"
                            }`}
                          >
                            <I18n lng="global.InspectorNotes" />
                          </legend>
                          {note ? (
                            <div className="notes">
                              <p>{note}</p>
                            </div>
                          ) : null}
                          {photo.length ? (
                            <div className="thumbnail-wrapper">
                              <ReportPdfImages
                                photo={photo}
                                imageCount={imageCount}
                                setImageIndex={setImageIndex}
                                setShowImageSrc={setShowImageSrc}
                                showDocumentMedia={showDocumentMedia}
                                setShowImageDialog={setShowImageDialog}
                              />
                            </div>
                          ) : null}
                        </fieldset>
                      </>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ) : null
      ) : null}
    </>
  );
};
export default GlobalListPdfPreview;
