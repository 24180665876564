import React from "react";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import SitesAdd from "./company-sites-add";
import I18n, { i18n } from "../../../languageSelector";
import SitesView from "./company-sites-view";
import { Route, Switch } from "react-router-dom";
import { SiteUIProvider } from "./site-ui-context";
import DialogModal from "../../../../../components/dialogs/compact-dialog";
import {
  getAllSites,
  deleteSiteById,
} from "../../../../../redux/reducers/sites/sites.reducer";

const SitesPage = ({ history, deleteSiteById, getAllSites }) => {
  const sitesUiEvents = {
    openSiteViewDialog: (id) => {
      history.push(`/management/sites/${id}/view`);
    },
    openSiteEditDialog: (id) => {
      history.push(`/management/sites/${id}/edit`);
    },
    openSiteDeleteDialog: (id) => {
      history.push(`/management/sites/${id}/delete`);
    },
  };

  const sitesDeleteHandler = (id) => {
    if (id) {
      deleteSiteById(id)
        .then(() => {
          toast.success(<I18n lng="validation.SiteDeleted" />);
          getAllSites();
          history.push("/management/sites");
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    }
  };
  return (
    <>
      <SiteUIProvider sitesUiEvents={sitesUiEvents}>
        <Switch>
          <Route path="/management/sites/add">
            {({ history, match }) => (
              <SitesAdd
                showDlg={match != null}
                secondaryAction={() => {
                  history.push("/management/sites");
                }}
              />
            )}
          </Route>
          <Route path="/management/sites/:id/view">
            {({ history, match }) => (
              <SitesAdd
                showDlg={match != null}
                secondaryAction={() => {
                  history.push("/management/sites");
                }}
                view={true}
              />
            )}
          </Route>
          <Route path="/management/sites/:id/edit">
            {({ history, match }) => (
              <SitesAdd
                showDlg={match != null}
                secondaryAction={() => {
                  history.push("/management/sites");
                }}
              />
            )}
          </Route>
          <Route path="/management/sites/:id/delete">
            {({ history, match }) => (
              <>
                <DialogModal
                  showDlg={match != null}
                  title={i18n("global.Note")}
                  classes="modal-alert"
                  iconCloseDlg
                  content={
                    <p>
                      <I18n lng="Company.DeleteSite" />
                    </p>
                  }
                  primaryActionTitle={i18n("validation.SiteDelete")}
                  secondaryAction={() => history.push("/management/sites")}
                  primaryAction={sitesDeleteHandler}
                  primaryActionClasses={"btn-danger"}
                />
              </>
            )}
          </Route>
        </Switch>
        <SitesView />
      </SiteUIProvider>
    </>
  );
};
const mapDispatchToProps = { deleteSiteById, getAllSites };

export default connect(null, mapDispatchToProps)(SitesPage);
