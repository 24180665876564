import { combineReducers } from "redux";
import { loadingBarReducer as loadingBar } from "react-redux-loading-bar";
import authentication, { AuthenticationState } from "./reducers/authentication/authentication";
import company, { CompanyState } from "../redux/reducers/company/company.reducer";
import form, { FormState } from "./reducers/form/form.reducer";
import globalList, { GlobalListState } from "./reducers/global-list/global-list.reducer";
import sites, { SiteState } from "./reducers/sites/sites.reducer";
import user, { UserState } from "./reducers/user/user.reducer";
import subscription, { SubscriptionState } from "./reducers/subscription/subscription.reducer";
import adminManagerReports, { ReportState } from "./reducers/admin-manager-reports/admin-manager-report.reducer";
import notifications, { NotificationState } from "./reducers/notifications/notifications.reducer";
import multipleChoiceQuestions, { MultipleChoiceState } from "./reducers/multiple-choice-questions/multiple-choice.reducer";
import roles, { RolesState } from "./reducers/roles/roles.reducer";
import invoices, { InvoicesState } from "./reducers/invoices/invoices.reducer";
import analytics, { analyticState } from "../pages/private/analytics/reducer/analytics.reducer";

export interface IRootState {
  readonly loadingBar: any;
  readonly authentication: typeof AuthenticationState;
  readonly form: typeof FormState;
  readonly globalList: typeof GlobalListState;
  readonly company: typeof CompanyState;
  readonly sites: typeof SiteState;
  readonly user: typeof UserState;
  readonly subscription: typeof SubscriptionState;
  readonly adminManagerReports: typeof ReportState;
  readonly notifications: typeof NotificationState;
  readonly multipleChoiceQuestions: typeof MultipleChoiceState;
  readonly roles: typeof RolesState;
  readonly invoices: typeof InvoicesState;
  readonly analytics: typeof analyticState;
}

const rootReducer = combineReducers<IRootState>({
  loadingBar,
  authentication,
  form,
  globalList,
  company,
  sites,
  user,
  subscription,
  adminManagerReports,
  notifications,
  multipleChoiceQuestions,
  roles,
  invoices,
  analytics,
});
export default rootReducer;
