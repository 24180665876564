/**
 * eslint-disable react-hooks/exhaustive-deps
 *
 * @format
 */
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import "react-phone-number-input/style.css";
import { connect } from "react-redux";
import IconInfo from "../../../../../../assets/images/icon-info.svg";
import DynamicModal from "../../../../../../components/dialogs";
import PlanDialog from "../../../../../../components/dialogs/PlanDialog";
import AlertSigupDialog from "../../../../../../components/dialogs/alertSignupDialog";
import Input from "../../../../../../components/form-input/input.component";
import { INPUT_VALUES_TO_EXCLUDE, companyPaymentCardTranslation } from "../../../../../../config/constants";
import { MAX_NUM_LENGTH, MIN_NUM_LENGTH } from "../../../../../../config/validations";
import { jumpInputField } from "../../../../../../helper/helperFunctions";
import { getSession } from "../../../../../../redux/reducers/authentication/authentication";
import { updateUserById } from "../../../../../../redux/reducers/user/user.reducer";
import I18n, { i18n } from "../../../../languageSelector";
import CardAddDialog from "./add-card-dialog";
import { getSubscriptionByCompanyId, updateSubscriptionData } from "../../../../../../redux/reducers/subscription/subscription.reducer";
import { getCard, getCompanyById, saveCard, verifyCard } from "../../../../../../redux/reducers/company/company.reducer";
import { useHistory, useLocation } from "react-router-dom";
import ChargeBillingDetails from "../charge-billing-details";
const INITIAL_VALUES = {
  cvc: "",
  name: "",
  expiry: "",
  default: false,
};

const SubscriptionsCardDialog = ({
  edit,
  cardIndex,
  user,
  cardToEdit,
  userCardsList,
  show,
  onHide,
  getSession,
  updateUserById,
  getSubscriptionByCompanyId,
  updateSubscriptionData,
  subscriptionData,
  saveCard,
  getCard,
  verifyCard,
  getCompanyById,
  setPlanUpdateUI,
  planUpdatedUI,
}) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const message = queryParams.get("message");
  const history = useHistory();

  const [cardIdToDelete] = useState();
  const [showCardAddDlg, setShowCardAddDlg] = useState(false);
  const [showCardDeleteDlg, setShowCardDeleteDlg] = useState(false);
  const [showAddPaymentModal, setShowAddPaymentModal] = useState(false);
  const [showUpdatePaymentModal, setShowUpdatePaymentModal] = useState(false);
  const [declinedCardModal, setDeclinedCardModal] = useState(false);
  const [cardAdded, setCardAdded] = useState(false);
  const [expiryDate, setExpiryDate] = useState("");
  const [expiryDateError, setExpiryDateError] = useState(false);
  const [cardError] = useState(false);
  const [cardNumber, setCardNumber] = useState("", "", "", "");
  const [, setFormFilled] = useState(false);
  const [companyPaymentCard, setCompanyPaymentCard] = useState();
  const [chargeBillingDetailsDailog, setChargeBillingDetailsDailog] = useState(false);
  const [unExpectedCardIssues, setUnExpectedCardIssues] = useState(false);

  useEffect(() => {
    reset({ ...INITIAL_VALUES });
    setCardNumber(["", "", "", ""]);
    // eslint-disable-next-line
  }, [edit, showCardAddDlg, cardToEdit]);
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = useForm({
    defaultValues: INITIAL_VALUES,
  });

  const isFormFilled = () => {
    const { name, cvc, expiry } = watch();
    const cardNumberFilled = Array.isArray(cardNumber) && cardNumber.join("").length === 16 && !cardError;
    const expiryDateFilled = expiryDate !== "" && !expiryDateError;
    const cvcFilled = cvc?.length === 3;
    return !!name && !!cvc && !!expiry && cardNumberFilled && expiryDateFilled && cvcFilled;
  };

  useEffect(() => {
    setFormFilled(isFormFilled());
    // eslint-disable-next-line
  }, [cardNumber, expiryDate, errors, cardError]);

  const buttonStyle = {
    backgroundColor: isFormFilled() ? "#20639B" : "#E0E0E0",
    pointerEvents: isFormFilled() ? "" : "none",
  };

  const parsedBillingDetails = subscriptionData?.lastSubscriptionData;

  const parsedSeats = subscriptionData?.lastSubscriptionData?.seats;

  let amountToCharge =
    subscriptionData?.currentPlan?.plan === "Monthly Subscription" ? 79 * subscriptionData?.seats?.total : 790 * subscriptionData?.seats?.total;
  let addtax = amountToCharge * 0.15;
  let finalAmount = amountToCharge + addtax;

  const updateSubscriptionDuringPausedAfterSuccessfullyAddedCard = {
    currentPlan: {
      plan: parsedBillingDetails?.plan ? parsedBillingDetails?.plan : subscriptionData?.currentPlan?.plan,
      status: "Running",
      seats: parsedSeats ? parsedSeats : subscriptionData?.currentPlan?.seats,
      price: parsedBillingDetails?.plan === "Yearly Subscription" ? 790 : 79,
    },
    seats: {
      used: subscriptionData?.seats?.used,
      available: parsedSeats ? parsedSeats - subscriptionData?.seats?.used : subscriptionData?.seats?.available,
      total: parsedSeats ? parsedSeats : subscriptionData?.seats?.total,
    },
    subscriptionInfo: {
      isSubscribed: true,
      isSubscribedExpired: false,
      isUnsubscribed: false,
      isSubscribeResumed: false,
    },
    nextUpdatedPlan: parsedBillingDetails?.plan,
    isPlanChanged: subscriptionData?.isPlanChanged,
    lastAdditionalseats: {
      seats: parsedSeats > 0 ? parsedSeats : subscriptionData?.seats?.total,
      seatsBill: parsedBillingDetails?.todaysTotal ? parsedBillingDetails?.todaysTotal : finalAmount,
      lastPerSeatBill: parsedBillingDetails?.proratedCostPerSeat,
    },
    lastSubscriptionData: {
      isPreviousPaused: true,
    },
  };

  const updateSubscriptionDuringPausedAfterUnSuccessfullyAddedCard = {
    currentPlan: {
      plan: parsedBillingDetails?.plan,
      status: "Paused",
      seats: parsedSeats,
      price: parsedBillingDetails?.plan === "Yearly Subscription" ? 790 : 79,
    },
    seats: {
      used: subscriptionData?.seats?.used,
      available: parsedSeats - subscriptionData?.seats?.used,
      total: parsedSeats,
    },
    subscriptionInfo: {
      isSubscribed: true,
      isSubscribedExpired: false,
      isUnsubscribed: false,
      isSubscribeResumed: false,
    },
    nextUpdatedPlan: parsedBillingDetails?.plan,
    isPlanChanged: subscriptionData?.isPlanChanged,
    lastAdditionalseats: {
      seats: parsedSeats > 0 ? parsedSeats : subscriptionData?.currentPlan?.seats - parsedSeats,
      seatsBill: parsedBillingDetails?.todaysTotal,
      lastPerSeatBill: parsedBillingDetails?.proratedCostPerSeat,
    },
  };

  const updateSubscriptionDuringPausedWhileSuccessfullyUpdatedCard = {
    currentPlan: {
      plan: subscriptionData?.currentPlan?.plan,
      status: "Running",
      seats: subscriptionData?.currentPlan?.seats,
      price: subscriptionData?.currentPlan?.price,
    },
    seats: {
      used: subscriptionData?.seats?.used,
      available: subscriptionData?.seats?.available,
      total: subscriptionData?.seats?.total,
    },
    subscriptionInfo: {
      isSubscribed: true,
      isSubscribedExpired: false,
      isUnsubscribed: false,
      isSubscribeResumed: false,
    },
    nextUpdatedPlan: subscriptionData?.nextUpdatedPlan,
    isPlanChanged: subscriptionData?.isPlanChanged,
    lastAdditionalseats: {
      seats: subscriptionData?.lastAdditionalseats?.seats,
      seatsBill: subscriptionData?.lastAdditionalseats?.seatsBill,
      lastPerSeatBill: subscriptionData?.lastAdditionalseats?.lastPerSeatBill,
    },
    lastSubscriptionData: {
      isPreviousPaused: true,
    },
  };

  const updateSubscriptionDuringPausedWhileUnSuccessfullyUpdatedCard = {
    currentPlan: {
      plan: subscriptionData?.currentPlan?.plan,
      status: "Paused",
      seats: subscriptionData?.currentPlan?.seats,
      price: subscriptionData?.currentPlan?.price,
    },
    seats: {
      used: subscriptionData?.seats?.used,
      available: subscriptionData?.seats?.available,
      total: subscriptionData?.seats?.total,
    },
    subscriptionInfo: {
      isSubscribed: true,
      isSubscribedExpired: false,
      isUnsubscribed: false,
      isSubscribeResumed: false,
    },
    nextUpdatedPlan: subscriptionData?.nextUpdatedPlan,
    isPlanChanged: subscriptionData?.isPlanChanged,
    lastAdditionalseats: {
      seats: subscriptionData?.lastAdditionalseats?.seats,
      seatsBill: subscriptionData?.lastAdditionalseats?.seatsBill,
      lastPerSeatBill: subscriptionData?.lastAdditionalseats?.lastPerSeatBill,
    },
  };

  const onSubmit = async (data) => {
    const cardData = {
      name: data.name,
      cvc: data.cvc,
      number: cardNumber.join(""),
      month: data.expiry.split("/")[0],
      year: data.expiry.split("/")[1],
      callback_url: process.env.REACT_APP_CALL_BACK_URL,
    };

    const response = await saveCard(user?.company?.id, cardData).catch((error) => {
      if (error?.response?.data?.error?.number) {
        toast.error(error?.response?.data?.error?.number[0]);
      } else if (error?.response?.data?.error?.name) {
        toast.error(error?.response?.data?.error?.name[0]);
      }
    });
    if (response?.value?.data?.verificationUrl) {
      window.location.href = response?.value?.data?.verificationUrl;
    }
    let dummyList = [...userCardsList];
    if (data.default && dummyList.length) {
      dummyList = dummyList.map((item) => {
        return { ...item, default: false };
      });
    }
    if (cardIndex !== null) {
      dummyList[cardIndex] = {
        name: data.name,
        default: data.default,
        number: Number(cardNumber.join("")),
        month: data.expiry.split("/")[0],
        year: data.expiry.split("/")[1],
      };
    } else {
      dummyList = [
        ...dummyList,
        {
          name: data.name,
          default: data.default,
          number: Number(cardNumber.join("")),
          month: data.expiry.split("/")[0],
          year: data.expiry.split("/")[1],
        },
      ];
    }
    if (cardNumber.join("").length === 16 && !cardError) {
      updateUserById({
        ...user,
      })
        .then((res) => {
          setShowCardAddDlg(false);
        })
        .catch((err) => console.log(err?.response?.data));
    } else {
      toast.error(i18n("Subscriptions.EnterValidCardNumber"));
    }
  };

  useEffect(() => {
    if (message === "APPROVED") {
      if (companyPaymentCard) {
        getSession();
        setShowUpdatePaymentModal(true);
        setCardAdded(false);
      } else {
        setShowAddPaymentModal(true);
        setCardAdded(true);
      }
    }

    if (message === "3-D Secure transaction attempt failed (DECLINED_INVALID_PIN)") {
      setDeclinedCardModal(true);
    }

    if (message === "UNSPECIFIED_FAILURE" || message === "INSUFFICIENT_FUNDS" || message === "DECLINED") {
      setUnExpectedCardIssues(true);
    }
    // eslint-disable-next-line
  }, [message, companyPaymentCard]);

  useEffect(() => {
    const fetchCard = async () => {
      if (user?.company?.id) {
        try {
          const response = await getCompanyById(user?.company?.id);

          if (response?.value?.data?.paymentCards?.moyasar?.tempToken) {
            await verifyCard(user?.company?.id);
          }

          if (response?.value?.data?.paymentCards?.moyasar?.token) {
            const response = await getCard(user?.company?.id);
            setCompanyPaymentCard(response?.value?.data);
          }
        } catch (error) {
          console.error("Error fetching card:", error);
        }
      }
    };

    fetchCard();
  }, [getCard, getCompanyById, user?.company?.id, verifyCard]);

  const cardDeleteHandler = () => {
    if (cardIdToDelete) {
      updateUserById({
        ...user,
      }).then(() => {
        toast.success(i18n("Subscriptions.CardDeleted"));
        setShowCardDeleteDlg(false);
      });
    }
  };

  const addPaymentModalData = {
    title: <I18n lng="Subscriptions.PaymentMethodAdded" />,
    text_one: <I18n lng="Subscriptions.YourPaymentMethodWasAddedSuccessfully" />,
    closeButtonText: <I18n lng="global.Close" />,
    actionButtonText: <I18n lng="global.UpdatePlan" />,
  };

  const updateModalData = {
    title: <I18n lng="Subscriptions.PaymentMethodUpdated" />,
    text_one: <I18n lng="Subscriptions.YourPaymentMethodWasUpdatedSuccessfully" />,
    closeButtonText: <I18n lng="global.Close" />,
  };

  const handleCloseAddPaymentSidebar = async () => {
    console.log("..........11111");
    if (message === "APPROVED") {
      history.push("/management/subscriptions");
      getSession();
      setShowAddPaymentModal(false);
    } else {
      setShowAddPaymentModal(false);
    }
    if (message === "APPROVED") {
      getSession();
      console.log("..........22222");
      history.push("/management/subscriptions");
      setShowUpdatePaymentModal(false);
    } else {
      setShowUpdatePaymentModal(false);
    }
    console.log("..........33333");
    await getCard(user?.company?.id);
    if (
      parsedBillingDetails &&
      subscriptionData?.currentPlan?.status !== "Paused" &&
      subscriptionData?.currentPlan?.plan !== "Expired" &&
      !subscriptionData?.subscriptionInfo?.isSubscribed
    ) {
      setChargeBillingDetailsDailog(true);
    }
    if (message === "APPROVED") {
      getSession();
      if (parsedBillingDetails) {
        if (subscriptionData?.currentPlan?.status === "Paused") {
          await updateSubscriptionData(subscriptionData?.id, updateSubscriptionDuringPausedAfterSuccessfullyAddedCard, "Charge");
          await getSubscriptionByCompanyId(user?.company?.id);
          if (companyPaymentCard) {
            await getSession();
          }
          if (setPlanUpdateUI) {
            setPlanUpdateUI(true);
          }
          if (planUpdatedUI) {
            setChargeBillingDetailsDailog(true);
          }
        }
      }
    }
    if (message === "APPROVED") {
      getSession();
      if (!parsedBillingDetails) {
        if (subscriptionData?.currentPlan?.status === "Paused") {
          await updateSubscriptionData(subscriptionData?.id, updateSubscriptionDuringPausedWhileSuccessfullyUpdatedCard, "Charge");
          await getSubscriptionByCompanyId(user?.company?.id);
          if (companyPaymentCard) {
            await getSession();
          }
        }
      }
    }
    onHide();
  };

  const unExpectedCardIssuesData = {
    title:
      message === "UNSPECIFIED_FAILURE"
        ? companyPaymentCardTranslation("Unexpected Failure")
        : message === "INSUFFICIENT_FUNDS"
        ? companyPaymentCardTranslation("Insufficient Funds")
        : message === "DECLINED"
        ? companyPaymentCardTranslation("Card Declined")
        : null,

    text_one:
      message === "UNSPECIFIED_FAILURE"
        ? companyPaymentCardTranslation("There was an unexpected issue with your card. Please try again or use a different card.")
        : message === "INSUFFICIENT_FUNDS"
        ? companyPaymentCardTranslation("There are insufficient funds on your card. Please try a different card or add funds to your account.")
        : message === "DECLINED"
        ? companyPaymentCardTranslation("Your card was declined. Please use a different card or contact your bank for more information.")
        : null,

    closeButtonText: <I18n lng="global.Close" />,
  };

  const handleCloseDeclineCard = async () => {
    setDeclinedCardModal(false);

    history.push("/management/subscriptions");
    getSession();
    if (subscriptionData?.currentPlan?.status === "Paused" && message === "3-D Secure transaction attempt failed (DECLINED_INVALID_PIN)") {
      await updateSubscriptionData(subscriptionData?.id, updateSubscriptionDuringPausedAfterUnSuccessfullyAddedCard, "Update");
      await getSubscriptionByCompanyId(user?.company?.id);
      await getSession();
      if (setPlanUpdateUI) {
        setPlanUpdateUI(true);
      }
      if (planUpdatedUI) {
        setChargeBillingDetailsDailog(true);
      }
    }
    if (
      subscriptionData?.currentPlan?.status === "Paused" &&
      companyPaymentCard &&
      message === "3-D Secure transaction attempt failed (DECLINED_INVALID_PIN)"
    ) {
      await updateSubscriptionData(subscriptionData?.id, updateSubscriptionDuringPausedWhileUnSuccessfullyUpdatedCard, "Update");
    }
    if (message === "UNSPECIFIED_FAILURE" || message === "INSUFFICIENT_FUNDS" || message === "DECLINED") {
      setUnExpectedCardIssues(false);
    }
  };

  const validationCardExpiry = /^(0[1-9]|1[0-2])\/\d{2}$/;
  useEffect(() => {
    const validationCardExpiry = /^(0[1-9]|1[0-2])\/\d{2}$/;
    const inputValue = expiryDate;

    const isRequiredFulfilled = inputValue !== "";

    const isPatternFulfilled = validationCardExpiry && validationCardExpiry.test(inputValue); // Pattern Validation

    const isValid = isRequiredFulfilled && isPatternFulfilled ? true : false;
    if (isValid === false) {
      setExpiryDateError(true);
    } else {
      setExpiryDateError(false);
    }
  }, [expiryDate]);

  const pausedSubscriptionData = {
    currentPlan: {
      plan: subscriptionData?.currentPlan?.plan,
      status: "Paused",
      seats: subscriptionData?.currentPlan?.seats,
      price: subscriptionData?.currentPlan?.price,
    },
    seats: {
      used: subscriptionData?.seats?.used,
      available: subscriptionData?.seats?.available,
      total: subscriptionData?.seats?.total,
    },
  };

  const handleSubscriptionPaused = async () => {
    await updateSubscriptionData(subscriptionData?.id, pausedSubscriptionData, "Update");
    alert("Paused Subscription");
    await getSubscriptionByCompanyId(user?.company?.id);
  };

  useEffect(() => {
    if (show) {
      setExpiryDate("");
    }
  }, [show]);

  useEffect(() => {
    const fetchSubscriptionData = async () => {
      await getSubscriptionByCompanyId(user?.company?.id);
    };
    fetchSubscriptionData();
  }, [getSubscriptionByCompanyId, user?.company?.id]);

  useEffect(() => {
    const isExpiryDateValid = (expiry) => {
      const currentDate = new Date();
      const [month, year] = expiry.split("/");
      const fullYear = new Date().getFullYear().toString().slice(0, 2) + year;
      const expiryDate = new Date(`${fullYear}-${month}-01`);
      expiryDate.setMonth(expiryDate.getMonth() + 1);
      expiryDate.setDate(expiryDate.getDate() - 1);
      return expiryDate >= currentDate;
    };
    setExpiryDateError(!isExpiryDateValid(expiryDate));
  }, [expiryDate]);

  return (
    <>
      <DynamicModal
        title={companyPaymentCard ? <I18n lng="Subscriptions.UpdatePaymentMethod" /> : <I18n lng="Subscriptions.AddPaymentMethod" />}
        show={show}
        secondaryAction={() => {
          onHide();
          reset();
        }}
        modalContentClass="bg-white"
        customClasses="modal-drawer custom-roles-drawer"
      >
        <p className="d-flex justify-content-between  add-payment-method-heading-current-plan">
          <span className="mx-1">
            <I18n lng="Subscriptions.CardDetails" />
          </span>
          {subscriptionData?.subscriptionInfo?.isSubscribed && subscriptionData?.currentPlan.status === "Running" && (
            <span
              style={{
                marginRight: "10px",
                color: "black",
                cursor: "pointer",
              }}
              onClick={handleSubscriptionPaused}
            >
              Paused
            </span>
          )}
        </p>
        <form
          id="my-form"
          className="payment-card-form my-3 d-flex flex-column justify-content-between card-details-add"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div>
            <Input
              name="name"
              type="text"
              label={i18n("Subscriptions.Name")}
              placeholder={i18n("Subscriptions.EnterTheName")}
              register={register}
              validations={{
                required: {
                  value: true,
                  message: i18n("validation.REQUIRED_FIELD"),
                },
              }}
              error={errors["name"]}
            />
            <label className="credit-card-label">
              <I18n lng="Subscriptions.CardNumber" />
            </label>
            <div className="row" style={{ direction: "ltr" }}>
              <div className="col-3 remove-arrows">
                <input
                  type="string"
                  maxLength="4"
                  name="field-1"
                  value={cardNumber[0]}
                  onKeyDown={(e) => {
                    INPUT_VALUES_TO_EXCLUDE.includes(e.key) && e.preventDefault();
                  }}
                  onChange={(e) => {
                    jumpInputField(e, 0, cardNumber, setCardNumber);
                  }}
                />
              </div>
              <div className="col-3 remove-arrows">
                <input
                  type="string"
                  maxLength="4"
                  name="field-2"
                  value={cardNumber[1]}
                  onKeyDown={(e) => INPUT_VALUES_TO_EXCLUDE.includes(e.key) && e.preventDefault()}
                  onChange={(e) => {
                    jumpInputField(e, 1, cardNumber, setCardNumber);
                  }}
                />
              </div>
              <div className="col-3 remove-arrows">
                <input
                  type="string"
                  maxLength="4"
                  name="field-3"
                  value={cardNumber[2]}
                  onKeyDown={(e) => INPUT_VALUES_TO_EXCLUDE.includes(e.key) && e.preventDefault()}
                  onChange={(e) => {
                    jumpInputField(e, 2, cardNumber, setCardNumber);
                  }}
                />
              </div>
              <div className={`col-3 remove-arrows ${!cardError ? "mb-3" : ""}`}>
                <input
                  type="string"
                  maxLength="4"
                  name="field-4"
                  value={cardNumber[3]}
                  onKeyDown={(e) => INPUT_VALUES_TO_EXCLUDE.includes(e.key) && e.preventDefault()}
                  onChange={(e) => {
                    jumpInputField(e, 3, cardNumber, setCardNumber);
                  }}
                />
              </div>
            </div>
            {cardError && (
              <label className="credit-card-error label">
                <I18n lng="Subscriptions.EnterValidCard" />
              </label>
            )}
            <div className="row input-cardnumber">
              <div className="col-6">
                <Input
                  name="expiry"
                  label={i18n("Subscriptions.Expiry")}
                  type="string"
                  maxLength="5"
                  placeholder="mm     /     yy"
                  register={register}
                  validations={{
                    required: {
                      value: true,
                      message: i18n("validation.REQUIRED_FIELD"),
                    },
                    pattern: {
                      value: validationCardExpiry,
                      message: i18n("Subscriptions.InvalidDateFormat"),
                    },
                  }}
                  onKeyDown={(e) => {
                    INPUT_VALUES_TO_EXCLUDE.includes(e.key) && e.preventDefault();
                  }}
                  value={expiryDate}
                  onChange={(e) => {
                    let textTemp = e.target.value;
                    if (textTemp.length === 2) {
                      textTemp += "/";
                    }
                    setExpiryDate(textTemp);
                  }}
                  className="form-control text-center"
                />
                {expiryDateError === true && expiryDate !== "" ? (
                  <label style={{ color: "red" }}>{i18n("Subscriptions.InvalidDateFormat")}</label>
                ) : (
                  ""
                )}
              </div>
              <div className="col-6">
                <Input
                  name="cvc"
                  label="CVV"
                  type="string"
                  maxLength="3"
                  placeholder="CVV"
                  suffix={IconInfo}
                  register={register}
                  validations={{
                    required: {
                      value: true,
                      message: i18n("validation.REQUIRED_FIELD"),
                    },
                    minLength: { value: 3, message: MIN_NUM_LENGTH(3) },
                    maxLength: { value: 3, message: MAX_NUM_LENGTH(3) },
                  }}
                  onKeyDown={(e) => {
                    INPUT_VALUES_TO_EXCLUDE.includes(e.key) && e.preventDefault();
                  }}
                  error={errors["cvc"]}
                  className="form-control text-center"
                />
              </div>
            </div>
          </div>
          <div className="payment-card-form-button d-flex justify-content-center align-items-center">
            <button type="submit" style={buttonStyle}>
              <span>{companyPaymentCard ? <I18n lng="global.Update" /> : <I18n lng="global.Save" />}</span>
            </button>
          </div>
        </form>
      </DynamicModal>
      <CardAddDialog
        edit={edit}
        cardIndex={cardIndex}
        cardToEdit={cardToEdit}
        showDlg={showCardAddDlg}
        userCardsList={userCardsList}
        secondaryAction={() => setShowCardAddDlg(false)}
      />

      {showAddPaymentModal && cardAdded && (
        <PlanDialog
          showModal={showAddPaymentModal}
          onClose={handleCloseAddPaymentSidebar}
          modalTitle={addPaymentModalData?.title}
          text_one={addPaymentModalData?.text_one}
          secondaryButtonLabel={addPaymentModalData?.closeButtonText}
          secondaryButtonColor="#828282"
          titleWidth={"241px"}
          titleHeight={"20px"}
          titleFontWeight={"600"}
          titleFontSize={"20px"}
          titleLineHeight={"20px"}
          titleColor={"#333333"}
          secondaryButtonWidth={"43px"}
          secondaryButtonHeight={"16px"}
          secondaryButtonFontWeight={"600"}
          secondaryButtonFontSize={"16px"}
          secondaryButtonLineHeight={"15.92px"}
        />
      )}

      {showUpdatePaymentModal && !cardAdded && (
        <PlanDialog
          showModal={showUpdatePaymentModal}
          onClose={handleCloseAddPaymentSidebar}
          modalTitle={updateModalData?.title}
          text_one={updateModalData?.text_one}
          secondaryButtonLabel={updateModalData?.closeButtonText}
          secondaryButtonColor="#828282"
          titleWidth={"263px"}
          titleHeight={"20px"}
          titleFontWeight={"600"}
          titleFontSize={"20px"}
          titleLineHeight={"20px"}
          titleColor={"#333333"}
          secondaryButtonWidth={"43px"}
          secondaryButtonHeight={"16px"}
          secondaryButtonFontWeight={"600"}
          secondaryButtonFontSize={"16px"}
          secondaryButtonLineHeight={"15.92px"}
        />
      )}

      {declinedCardModal && !cardAdded && (
        <PlanDialog
          showModal={declinedCardModal}
          onClose={handleCloseDeclineCard}
          modalTitle={"Invalid Pin Code"}
          text_one={"We regret to inform you that your payment was unsuccessful."}
          secondaryButtonLabel={updateModalData?.closeButtonText}
          secondaryButtonColor="#828282"
          titleWidth={"263px"}
          titleHeight={"20px"}
          titleFontWeight={"600"}
          titleFontSize={"20px"}
          titleLineHeight={"20px"}
          titleColor={"#333333"}
          secondaryButtonWidth={"43px"}
          secondaryButtonHeight={"16px"}
          secondaryButtonFontWeight={"600"}
          secondaryButtonFontSize={"16px"}
          secondaryButtonLineHeight={"15.92px"}
        />
      )}

      {unExpectedCardIssues && (
        <PlanDialog
          showModal={unExpectedCardIssues}
          onClose={handleCloseDeclineCard}
          modalTitle={unExpectedCardIssuesData.title}
          text_one={unExpectedCardIssuesData.text_one}
          secondaryButtonLabel={unExpectedCardIssuesData.closeButtonText}
          secondaryButtonColor="#828282"
          titleWidth={"263px"}
          titleHeight={"20px"}
          titleFontWeight={"600"}
          titleFontSize={"20px"}
          titleLineHeight={"20px"}
          titleColor={"#333333"}
          secondaryButtonWidth={"43px"}
          secondaryButtonHeight={"16px"}
          secondaryButtonFontWeight={"600"}
          secondaryButtonFontSize={"16px"}
          secondaryButtonLineHeight={"15.92px"}
        />
      )}

      <AlertSigupDialog
        showDlg={showCardDeleteDlg}
        classes="modal-alert-dialog mt-5"
        iconCloseDlg={true}
        size="md"
        content={
          <div className="text-center">
            <p className="mt2">
              <I18n lng="Subscriptions.RemoveCard" />
            </p>
          </div>
        }
        primaryActionTitle={<I18n lng="global.Delete" />}
        secondryActionTitle={<I18n lng="global.Cancel" />}
        secondryActionClasses={"btn-white"}
        primaryActionClasses={"btn-danger"}
        secondaryAction={() => setShowCardDeleteDlg(false)}
        primaryAction={() => cardDeleteHandler()}
      />

      <ChargeBillingDetails
        subscriptionData={subscriptionData}
        show={chargeBillingDetailsDailog}
        onHide={() => setChargeBillingDetailsDailog(false)}
      />
    </>
  );
};

const mapStateToProps = ({ authentication, subscription }) => ({
  user: authentication.user,
  subscriptionData: subscription.subscriptionData,
});

const mapDispatchToProps = {
  getSession,
  updateUserById,
  getSubscriptionByCompanyId,
  updateSubscriptionData,
  saveCard,
  getCard,
  verifyCard,
  getCompanyById,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionsCardDialog);
