import { v4 as uuid } from "uuid";
import { i18n } from "../pages/private/languageSelector";
const config = {
  VERSION: "1",
};
export const AUTH_TOKEN_KEY = "tamam-authenticationToken";

export const DEFAULT_FORM_IMAGE = "https://tamam-bucket.s3.me-south-1.amazonaws.com/ee6023bcb103ac283cbe79ec86d16323.png";

export const COLOR_TAGS = ["tag-green", "tag-yellow", "tag-red", "tag-gray", "tag-blue", "tag-pink", "tag-blue-light", "tag-slate"];

export const SEATS_SELECT = [
  { label: "Seated User", value: true },
  { label: "Unseated User", value: false },
];

export const MULTIPLE_CHOICE_RESPONSES = [
  {
    options: [
      {
        name: "Yes",
        class: "tag-green",
        score: 1,
        canComment: false,
        canAttachPhoto: false,
        isFail: false,
      },
      {
        name: "No",
        class: "tag-red",
        score: 0,
        canComment: false,
        canAttachPhoto: false,
        isFail: true,
      },
      {
        name: "N/A",
        class: "tag-slate",
        score: "-",
        canComment: false,
        canAttachPhoto: false,
        isFail: false,
      },
    ],
  },
  {
    options: [
      {
        name: "Good",
        class: "tag-green",
        score: 1,
        canComment: false,
        canAttachPhoto: false,
        isFail: false,
      },
      {
        name: "Fair",
        class: "tag-yellow",
        score: "-",
        canComment: false,
        canAttachPhoto: false,
        isFail: false,
      },
      {
        name: "Poor",
        class: "tag-red",
        score: 0,
        canComment: false,
        canAttachPhoto: false,
        isFail: true,
      },
      {
        name: "N/A",
        class: "tag-slate",
        score: "-",
        canComment: false,
        canAttachPhoto: false,
        isFail: false,
      },
    ],
  },
  {
    options: [
      {
        name: "Safe",
        class: "tag-green",
        score: 1,
        canComment: false,
        canAttachPhoto: false,
        isFail: false,
      },
      {
        name: "Risk",
        class: "tag-red",
        score: 0,
        canComment: false,
        canAttachPhoto: false,
        isFail: true,
      },
      {
        name: "N/A",
        class: "tag-slate",
        score: "-",
        canComment: false,
        canAttachPhoto: false,
        isFail: false,
      },
    ],
  },
  {
    options: [
      {
        name: "Pass",
        class: "tag-green",
        score: 1,
        canComment: false,
        canAttachPhoto: false,
        isFail: false,
      },
      {
        name: "Fail",
        class: "tag-red",
        score: 0,
        canComment: false,
        canAttachPhoto: false,
        isFail: true,
      },
      {
        name: "N/A",
        class: "tag-slate",
        score: "-",
        canComment: false,
        canAttachPhoto: false,
        isFail: false,
      },
    ],
  },
  {
    options: [
      {
        name: "Compliant",
        class: "tag-green",
        score: 1,
        canComment: false,
        canAttachPhoto: false,
        isFail: false,
      },
      {
        name: "Non-Compliant",
        class: "tag-red",
        score: 0,
        canComment: false,
        canAttachPhoto: false,
        isFail: true,
      },
      {
        name: "N/A",
        class: "tag-slate",
        score: "-",
        canComment: false,
        canAttachPhoto: false,
        isFail: false,
      },
    ],
  },
  {
    options: [
      {
        name: "N/A",
        class: "tag-slate",
        score: "-",
        canComment: false,
        canAttachPhoto: false,
        isFail: false,
      },
    ],
  },
  {
    options: [
      {
        name: "Enter Response",
        class: "tag-slate",
        score: "-",
        canComment: false,
        canAttachPhoto: false,
        isFail: false,
      },
    ],
  },
];

export const RESPONSES_TYPES = {
  MULTIPLE_CHOICE_RESPONSE_V1: {
    name: "Multiple choices",
    type: "COMPLEX_MULTIPLE_CHOICE",
    questionUuid: uuid(),
    settings: {
      isOpen: true,
      questionText: "Add a new question",
      questionPlaceHolder: "Add a new question",
      isEnabled: true,
      isMandatory: false,
      score: 1,
      description: "",
      photo: "",
      selectionType: "SINGLE",
      answers: [...MULTIPLE_CHOICE_RESPONSES[0].options],
    },
  },
  MULTIPLE_CHOICE_RESPONSE_V2: {
    name: "Multiple choices",
    type: "COMPLEX_MULTIPLE_CHOICE",
    questionUuid: uuid(),
    settings: {
      isOpen: true,
      questionText: "Add a new question",
      questionPlaceHolder: "Add a new question",
      isEnabled: true,
      isMandatory: false,
      score: 1,
      description: "",
      photo: "",
      selectionType: "SINGLE",
      answers: [...MULTIPLE_CHOICE_RESPONSES[1].options],
    },
  },
  MULTIPLE_CHOICE_RESPONSE_V3: {
    name: "Multiple choices",
    type: "COMPLEX_MULTIPLE_CHOICE",
    questionUuid: uuid(),
    settings: {
      isOpen: true,
      questionText: "Add a new question",
      questionPlaceHolder: "Add a new question",
      isEnabled: true,
      isMandatory: false,
      score: 1,
      description: "",
      photo: "",
      selectionType: "SINGLE",
      answers: [...MULTIPLE_CHOICE_RESPONSES[2].options],
    },
  },
  MULTIPLE_CHOICE_RESPONSE_V4: {
    name: "Multiple choices",
    type: "COMPLEX_MULTIPLE_CHOICE",
    questionUuid: uuid(),
    settings: {
      isOpen: true,
      questionText: "Add a new question",
      questionPlaceHolder: "Add a new question",
      isEnabled: true,
      isMandatory: false,
      score: 1,
      description: "",
      photo: "",
      selectionType: "SINGLE",
      answers: [...MULTIPLE_CHOICE_RESPONSES[3].options],
    },
  },
  MULTIPLE_CHOICE_RESPONSE_V5: {
    name: "Multiple choices",
    type: "COMPLEX_MULTIPLE_CHOICE",
    questionUuid: uuid(),
    settings: {
      isOpen: true,
      questionText: "Add a new question",
      questionPlaceHolder: "Add a new question",
      isEnabled: true,
      isMandatory: false,
      score: 1,
      description: "",
      photo: "",
      selectionType: "SINGLE",
      answers: [...MULTIPLE_CHOICE_RESPONSES[4].options],
    },
  },
  MULTIPLE_CHOICE_RESPONSE_V6: {
    name: "Multiple choices",
    type: "COMPLEX_MULTIPLE_CHOICE",
    questionUuid: uuid(),
    settings: {
      isOpen: true,
      questionText: "Add a new question",
      questionPlaceHolder: "Add a new question",
      isEnabled: true,
      isMandatory: false,
      score: 1,
      description: "",
      photo: "",
      selectionType: "SINGLE",
      answers: [...MULTIPLE_CHOICE_RESPONSES[5].options],
    },
  },
  MULTIPLE_CHOICE_RESPONSE_V7: {
    name: "Multiple choices",
    type: "COMPLEX_MULTIPLE_CHOICE",
    questionUuid: uuid(),
    settings: {
      isOpen: true,
      questionText: "Add a new question",
      questionPlaceHolder: "Add a new question",
      isEnabled: true,
      isMandatory: false,
      score: 1,
      description: "",
      photo: "",
      selectionType: "SINGLE",
      answers: [...MULTIPLE_CHOICE_RESPONSES[6].options],
    },
  },
  TEXT: {
    name: "Text Answer",
    type: "text",
    questionUuid: uuid(),
    settings: {
      isOpen: true,
      questionText: "Add a new question",
      questionPlaceHolder: "Add a new question",
      canComment: false,
      canAttachPhoto: false,
      // isFail: false,
      isEnabled: true,
      isMandatory: false,
      score: 0,
      description: "",
      photo: "",
      textType: "SINGLE_LINE",
      answers: "",
    },
  },
  NUMBER: {
    name: "Number",
    type: "number",
    questionUuid: uuid(),
    settings: {
      isOpen: true,
      questionText: "Add a new question",
      questionPlaceHolder: "Add a new question",
      canComment: false,
      canAttachPhoto: false,
      isFail: false,
      isEnabled: true,
      isMandatory: false,
      score: 0,
      description: "",
      photo: "",
      min: "5",
      max: "10",
      comparisionOptions: ["Between", "More than", "More than or equals", "Equals", "Less than or equals", "Less than"],
      comparision: "More than",
      measurement: "CENTIGRAGE",
      numberType: "NUMBER",
      answers: "",
    },
  },
  CHECKBOX: {
    name: "Checkbox",
    type: "checkbox",
    questionUuid: uuid(),
    settings: {
      isOpen: true,
      questionText: "Add a new question",
      questionPlaceHolder: "Add a new question",
      canComment: false,
      canAttachPhoto: false,
      // isFail: false,
      isEnabled: true,
      isMandatory: false,
      score: 1,
      description: "",
      photo: "",
      answers: "",
    },
  },
  DATE_AND_TIME: {
    name: "Date & Time",
    type: "date time",
    questionUuid: uuid(),
    settings: {
      isOpen: true,
      questionText: "Add a new question",
      questionPlaceHolder: "Add a new question",
      canComment: false,
      canAttachPhoto: false,
      // isFail: false,
      isEnabled: true,
      isMandatory: false,
      score: 0,
      description: "",
      photo: "",
      isDate: true,
      isTime: true,
      answers: "",
    },
  },
  IMAGE: {
    name: "Image",
    type: "image",
    questionUuid: uuid(),
    settings: {
      isOpen: true,
      questionText: "Add a new question",
      questionPlaceHolder: "Add a new question",
      canComment: false,
      canAttachPhoto: false,
      // isFail: false,
      isEnabled: true,
      isMandatory: false,
      score: 0,
      description: "",
      photo: "",
      isOnlyCamera: false,
      fileTypes: ["png", "jpg", "jpeg"],
      answers: "",
    },
  },
  PDF: {
    name: "PDF",
    type: "pdf",
    questionUuid: uuid(),
    settings: {
      isOpen: true,
      questionText: "Add a new question",
      questionPlaceHolder: "Add a new question",
      canComment: false,
      canAttachPhoto: false,
      // isFail: false,
      isEnabled: true,
      isMandatory: false,
      score: 0,
      description: "",
      photo: "",
      fileTypes: ["pdf"],
      answers: "",
      selectedFile: "",
    },
  },
  SLIDER: {
    name: "Slider",
    type: "slider",
    questionUuid: uuid(),
    settings: {
      isOpen: true,
      questionText: "Add a new question",
      questionPlaceHolder: "Add a new question",
      canComment: false,
      canAttachPhoto: false,
      // isFail: false,
      isEnabled: true,
      isMandatory: false,
      score: 1,
      description: "",
      photo: "",
      min: 0,
      max: 10,
      increment: 1,
      answers: "",
    },
  },
  INSTRUCTIONS: {
    name: "Instructions",
    type: "instructions",
    questionUuid: uuid(),
    settings: {
      isOpen: true,
      questionText: "Enter Instructions",
      questionPlaceHolder: "Enter Instructions",
      isEnabled: true,
      score: 0,
      description: "",
      photo: "",
      selectedFile: "",
      selectedFileName: "",
      answers: "",
    },
  },
  LOCATION: {
    name: "Location",
    type: "location",
    questionUuid: uuid(),
    settings: {
      isOpen: true,
      questionText: "Add a new question",
      questionPlaceHolder: "Add a new question",
      canComment: false,
      canAttachPhoto: false,
      // isFail: false,
      isEnabled: true,
      isMandatory: false,
      score: 0,
      description: "",
      photo: "",
      answers: "",
    },
  },
  SIGNATURE: {
    name: "Signature",
    type: "signature",
    questionUuid: uuid(),
    settings: {
      isOpen: true,
      questionText: "Add a new question",
      questionPlaceHolder: "Add a new question",
      isEnabled: true,
      isMandatory: false,
      score: 0,
      description: "",
      photo: "",
      answers: "",
    },
  },
  DROP_DOWN_LIST: {
    name: "Dropdown",
    type: "DROPDOWN",
    questionUuid: uuid(),
    settings: {
      isOpen: true,
      questionText: "Add a new question",
      questionPlaceHolder: "Add a new question",
      canComment: false,
      canAttachPhoto: false,
      // isFail: false,
      isEnabled: true,
      isMandatory: false,
      score: 0,
      description: "",
      photo: "",
      selectionType: "SINGLE",
      listName: "",
      options: [],
      answers: "",
    },
  },
};

export default config;

export const SERVER_API_URL = process.env.REACT_APP_SERVER_URL;
export const SERVER_ENV = process.env.REACT_APP_MODE;

export const APP_DATE_FORMAT = "DD/MM/YY HH:mm";
export const APP_TIMESTAMP_FORMAT = "DD/MM/YY HH:mm:ss";
export const APP_LOCAL_DATE_FORMAT = "DD/MM/YYYY";
export const APP_LOCAL_DATETIME_FORMAT = "YYYY-MM-DDTHH:mm";
export const APP_LOCAL_DATETIME_FORMAT_Z = "YYYY-MM-DDTHH:mm Z";
export const APP_WHOLE_NUMBER_FORMAT = "0,0";
export const APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT = "0,0.[00]";
export const COMPANY_INDUSTORY_DROPDOWN = [
  { value: "Agriculture", label: "Agriculture" },
  { value: "Cleaning", label: "Cleaning" },
  { value: "Community services", label: "Community services" },
  { value: "Construction", label: "Construction" },
  { value: "Education", label: "Education" },
  { value: "Emergency services", label: "Emergency services" },
  { value: "Financial services", label: "Financial services" },
  { value: "Food and Beverages", label: "Food and Beverages" },
  { value: "General", label: "General" },
  { value: "Health services", label: "Health services" },
  { value: "Hospitality", label: "Hospitality" },
  { value: "ICT", label: "ICT" },
  { value: "Manufacturing", label: "Manufacturing" },
  { value: "Mining", label: "Mining" },
  { value: "Professional services", label: "Professional services" },
  { value: "Publishing", label: "Publishing" },
  {
    value: "Transport and logistics",
    label: "Transport and logistics",
  },
];

export const COMPANY_NUMBER_OF_EMPLOYEES = [
  { value: "1--5", label: "1--5" },
  { value: "6--19", label: "6--19" },
  { value: "20--49", label: "20--49" },
  { value: "50--99", label: "50--99" },
  { value: "100--199", label: "100--199" },
  { value: "200--499", label: "200--499" },
  { value: "500--2999", label: "500--2999" },
  { value: "3000 and More", label: "3000 and More" },
];

export const REPORT_STATUS_FILTER = [
  { value: "reSubmit", lable: "ReSubmit" },
  { value: "submit", lable: "Submit" },
  { value: "accept", lable: "Accept" },
  { value: "reject", lable: "Reject" },
  { value: "incomplete", lable: "In-Complete" },
];

export const REPORT_COLORS = [
  { value: false, label: "theme-gray-one" },
  { value: false, label: "theme-gray-two" },
  { value: false, label: "theme-gray-three" },
  { value: false, label: "theme-primary-one" },
  { value: false, label: "theme-primary-two" },
  { value: false, label: "theme-primary-three" },
  { value: false, label: "theme-green-one" },
  { value: false, label: "theme-green-two" },
  { value: false, label: "theme-green-three" },
  { value: false, label: "theme-red" },
  { value: false, label: "theme-orange" },
  { value: false, label: "theme-yellow" },
  { value: false, label: "theme-purple-one" },
  { value: false, label: "theme-purple-two" },
  { value: false, label: "theme-pink" },
];
export const settingInitState = {
  title: {
    siteName: true,
    inspectorName: true,
    dateTime: true,
  },
  content: {
    scores: true,
    failsCount: true,
    docNumber: true,
    dateTime: true,
    created: true,
    lastUpdate: true,
    conducted: true,
    failsSummary: true,
    instructions: true,
    unAnsweredQuestions: true,
    inspectionQuestions: true,
    answered: false,
    unAnswered: false,
    all: true,
    photos: false,
    standardQuality: true,
    highQuality: false,
    photosLinksQrCode: true,
    PdfLinksQrCode: true,
    tableOfContents: true,
    companyInformation: true,
    disclaimer: "",
    footer: {
      page: true,
      title: true,
      date: true,
      inspectorName: true,
    },
  },
};

export const INPUT_VALUES_TO_EXCLUDE = [
  "+",
  "-",
  ",",
  "`",
  "/",
  "|",
  "!",
  "@",
  "#",
  "$",
  "%",
  "^",
  "&",
  "*",
  "_",
  "(",
  ")",
  "?",
  "[",
  "]",
  "{",
  "}",
  ">",
  "<",
  "'",
  ";",
  ":",
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

export const DUMMY_ANALYTICS_TABLE_DATA = [
  {
    id: 1,
    report: "Jeddah - Yasser Nemer - 21 Aug 2023",
    date: "21 Aug 2023",
    form: "Safety first",
    inspector: "Yasser Nemer",
    site: "Jeddah",
    fail: 1,
    score: 75.98,
    completion: 99.01,
    status: "Complete",
  },
  {
    id: 2,
    report: "Qatif - Abdulla ali - 20 Aug 2023",
    date: "20 Aug 2023",
    form: "Dispatch Notification Test",
    inspector: "Abdulla Ali",
    site: "Qatif",
    fail: 7,
    score: 96.23,
    completion: 87.36,
    status: "Draft",
  },
  {
    id: 3,
    report: "Default Site - Hani Alnasser - 19 Aug 2023",
    date: "19 Aug 2023",
    form: "Cleaning",
    inspector: "Hani Alnasser",
    site: "Default Site",
    fail: 8,
    score: 0,
    completion: 77.0,
    status: "Incomplete",
  },
  {
    id: 4,
    report: "Jeddah  - Yasser Nemer - 18 Aug 2023",
    date: "18 Aug 2023",
    form: "Multi-Select Fail Test",
    inspector: "Yasser Nemer",
    site: "Jeddah",
    fail: 9,
    score: 0,
    completion: 57.65,
    status: "Rejected",
  },
  {
    id: 5,
    report: "Default Site - Nasser Ahmed - 16 Aug 2023",
    date: "17 Aug 2023",
    form: "Safety first",
    inspector: "Nasser Ahmed",
    site: "Default Site",
    fail: 4,
    score: 66.85,
    completion: 25.36,
    status: "Resubmitted",
  },
  {
    id: 6,
    report: "Default Site - Abdulla Ali - 15 Aug 2023",
    date: "15 Aug 2023",
    form: "Cleaning",
    inspector: "Abdulla Ali",
    site: "Default Site",
    fail: 7,
    score: 69.65,
    completion: 70.0,
    status: "Submitted",
  },
  {
    id: 7,
    report: "Qatif - Yasser Nemer - 14 Aug 2023",
    date: "14 Aug 2023",
    form: "Dispatch Notification Test",
    inspector: "Yasser Nemer",
    site: "Qatif",
    fail: 13,
    score: 0,
    completion: 78.23,
    status: "Complete",
  },
  {
    id: 8,
    report: "Default Site - Hani Alnasser - 13 Aug 2023",
    date: "13 Aug 2023",
    form: "Quality and health",
    inspector: "Hani Alnasser",
    site: "Default Site",
    fail: 2,
    score: 78.38,
    completion: 68.33,
    status: "Complete",
  },
  {
    id: 9,
    report: "Jeddah - Nasser Ahmed - 12Aug 2023",
    date: "12 Aug 2023",
    form: "Safety first",
    inspector: "Nasser Ahmed",
    site: "Jeddah",
    fail: 0,
    score: 45.65,
    completion: 68.33,
    status: "Complete",
  },
];

export const subscriptionsTranslation = (text: any) => {
  if (text === "Free") {
    return i18n("global.Free");
  } else if (text === "Trial") {
    return i18n("Subscriptions.Trial");
  } else if (text === "Monthly Subscription") {
    return i18n("Subscriptions.MonthlySubscription");
  } else if (text === "Yearly Subscription") {
    return i18n("Subscriptions.YearlySubscription");
  } else if (text === "Running") {
    return i18n("Subscriptions.Running");
  } else if (text === "Expired") {
    return i18n("Subscriptions.Expired");
  } else if (text === "Trial is running") {
    return i18n("Subscriptions.TrialIsRunning");
  } else if (text === "Unsubscribed, subscription is running") {
    return i18n("Subscriptions.UnsubscribedSubscriptionRunning");
  } else if (text === "Paused") {
    return i18n("Subscriptions.Paused");
  } else if (text === "Additional seats") {
    return i18n("Subscriptions.AdditionalSeats");
  }
};

export const invoiceTranslation = (text: any) => {
  if (text === "Monthly Subscription") {
    return i18n("Subscriptions.MonthlyInvoice");
  } else if (text === "Yearly Subscription") {
    return i18n("Subscriptions.YearlyInvoice");
  } else if (text === "Additional seats") {
    return i18n("Subscriptions.AdditionalSeatsInvoice");
  }
};

export const companyPaymentCardTranslation = (text: any) => {
  if (text === "visa") {
    return i18n("Subscriptions.Visa");
  } else if (text === "master") {
    return i18n("Subscriptions.master");
  } else if (text === "American Express") {
    return i18n("Subscriptions.AmericanExpress");
  } else if (text === "Discover") {
    return i18n("Subscriptions.Discover");
  } else if (text === "DinersClub") {
    return i18n("Subscriptions.DinersClub");
  } else if (text === "JCB") {
    return i18n("Subscriptions.JCB");
  } else if (text === "UnionPay") {
    return i18n("Subscriptions.UnionPay");
  } else if (text === "Unexpected Failure") {
    return i18n("Subscriptions.UnexpectedFailure");
  } else if (text === "Insufficient Funds") {
    return i18n("Subscriptions.InsufficientFunds");
  } else if (text === "Card Declined") {
    return i18n("Subscriptions.CardDeclined");
  } else if (text === "There was an unexpected issue with your card. Please try again or use a different card.") {
    return i18n("Subscriptions.ThereWasAnUnexpectedIssueWithYourCardPleaseTryAgainORUseDifferentCard");
  } else if (text === "There are insufficient funds on your card. Please try a different card or add funds to your account.") {
    return i18n("Subscriptions.ThereAreInsufficientFundsOnYourCardPleaseTryDifferentCardORAddFundsToYourAccount");
  } else if (text === "Your card was declined. Please use a different card or contact your bank for more information.") {
    return i18n("Subscriptions.YourCardWasDeclinedPleaseUseDifferentCardORContactYourBankForMoreInformation");
  }
};
