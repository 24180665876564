import React from "react";
import MyReportsView from "./my-reports-view";
import { Route, Switch } from "react-router-dom";
import MyReportsDetail from "./my-report-detail";
import ReportHistory from "./my-reports-history";
import FormView from "../../form/form-management/form-view/form-view";
import MyReportsExportView from "./my-reports-export/my-reports-export-view";
import MyReportsEditFilterView from "./my-reports-edit-filter/my-reports-edit-filter-view";

const MyReportsPage = ({
  reportUUid,
  filterState,
  setFilterState,
  filterInitState,
  filterApplyHandler,
  showFilterModal,
  setShowFilterModal,
}) => {
  return (
    <>
      <Switch>
        <Route path="/reports/my-reports/view/:id">
          {({ history, match }) => (
            <MyReportsDetail
              showDlg={match != null}
              secondaryAction={() => {
                history.push("/reports/my-reports");
              }}
              otherReportView={true}
              filterState={filterState}
            />
          )}
        </Route>
        <Route path="/reports/my-reports/editReport/:id">
          {({ history, match }) => (
            <MyReportsDetail
              showDlg={match != null}
              secondaryAction={() => {
                history.push("/reports/my-reports");
              }}
              filterState={filterState}
            />
          )}
        </Route>
        <Route path="/reports/my-reports/edit/:id">
          {({ history, match }) => (
            <FormView
              show={match != null}
              onHide={() => {
                history.push("/reports/my-reports");
              }}
              reportEdit={true}
              filterState={filterState}
            />
          )}
        </Route>
        <Route path={`/reports/my-reports/export/:id`}>
          {({ history, match }) => (
            <MyReportsExportView
              showDlg={match != null}
              secondaryAction={() => {
                history.push("/reports/my-reports");
              }}
              reportUUid={reportUUid}
              filterState={filterState}
            />
          )}
        </Route>
        <Route path="/reports/my-reports/settings/edit/:id">
          {({ history, match }) => (
            <MyReportsEditFilterView
              showDlg={match != null}
              secondaryAction={() => {
                history.push("/reports/my-reports");
              }}
              filterState={filterState}
            />
          )}
        </Route>
        <Route path="/reports/my-reports/view-history/:id">
          {({ history, match }) => (
            <ReportHistory
              showDlg={match != null}
              secondaryAction={() => {
                history.push("/reports/my-reports");
              }}
              filterState={filterState}
            />
          )}
        </Route>
      </Switch>
      <MyReportsView
        filterState={filterState}
        setFilterState={setFilterState}
        filterInitState={filterInitState}
        filterApplyHandler={filterApplyHandler}
        showFilterModal={showFilterModal}
        setShowFilterModal={setShowFilterModal}
      />
    </>
  );
};

export default MyReportsPage;
