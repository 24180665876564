import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Attachment from "../snipets/attachment";
import I18n, { i18n } from "../../../../../languageSelector";
import { BsInfoCircleFill } from "react-icons/bs";
import ReactTooltip from "react-tooltip";

const GlobalListPreview = ({
  question,
  currentRole,
  actionItem,
  addUpdateAnwser = (answer) => {
    console.log(answer);
  },
  getAnswer = (uuid) => {
    console.log(uuid);
    return undefined;
  },
  view,
}) => {
  const [value, setValue] = useState(undefined);
  const [photo, setPhoto] = useState([]);
  const [notes, setNotes] = useState("");
  const [questionPreview, setQuestionPreview] = useState(question);
  const [isQuestionLoading, setIsQuestionLoading] = useState(true);
  const [questionImageURL, setQuestionImageURL] = useState([]);
  const [answerSchema, setAnserSchema] = useState({});
  useEffect(() => {
    setIsQuestionLoading(true);
  }, []);

  useEffect(() => {
    if (question) {
      setQuestionPreview(question);
      setValue("");
    }
  }, [question]);

  // eslint-disable-next-line
  useEffect(async () => {
    if (question) {
      setQuestionPreview(question);

      if (question?.questionUuid) {
        const answer = getAnswer(question?.questionUuid);
        if (answer?.questionUuid) {
          setAnserSchema({ ...answer });
          setValue(answer.value);
          setNotes(answer.note);

          setQuestionImageURL([...answer.image]);
          if (answer.image && answer.image.length) {
            setPhoto([...answer.image]);
          }
          setIsQuestionLoading(false);
        } else {
          setIsQuestionLoading(false);
          setValue();
        }
      }
    }
    // eslint-disable-next-line
  }, [question]);

  const imageDeleteHandler = (index) => {
    let removeImageArray =
      answerSchema && answerSchema?.image?.length
        ? [...answerSchema.image]
        : [...questionImageURL];
    removeImageArray.splice(index, 1);

    if (answerSchema.image?.length || questionImageURL?.length) {
      if (!isQuestionLoading) {
        let answerToSave = {
          questionUuid: question.questionUuid,
          value: value,
          note: notes,
          image: [...removeImageArray],
        };
        addUpdateAnwser(answerToSave);
      }
      photo.splice(index, 1);
      setPhoto([...photo]);
    } else {
      setPhoto([]);
    }
  };

  useEffect(() => {
    let answerToSave = {
      questionUuid: question.questionUuid,
      value: value,
      note: notes,
      image: questionImageURL?.length ? [...questionImageURL] : [],
    };
    if (!isQuestionLoading) {
      setAnserSchema({ ...answerToSave });
      addUpdateAnwser(answerToSave);
    }
    // eslint-disable-next-line
  }, [value]);

  useEffect(() => {
    let answerToSave = {
      questionUuid: question.questionUuid,
      value: value,
      note: notes,
      image: questionImageURL?.length ? [...questionImageURL] : [],
    };
    if (!isQuestionLoading) {
      setAnserSchema({ ...answerToSave });
      addUpdateAnwser(answerToSave);
    }
    // eslint-disable-next-line
  }, [notes]);
  const imageAddHandler = () => {
    if (photo && photo.length) {
      let answerToSave = {
        questionUuid: question.questionUuid,
        value: value,
        note: notes,
        image: [...photo],
      };
      setQuestionImageURL([...photo]);

      if (!isQuestionLoading) {
        addUpdateAnwser(answerToSave);
      }
    }
  };
  return (
    <>
      <div className="d-flex justify-content-end w-100">
        <div></div>
        <div>
          {question.settings.description ? (
            <>
              <p
                data-for={`${question.settings.questionText}`}
                data-tip={`${question.settings.description}`}
                data-iscapture="true"
              >
                <BsInfoCircleFill color={"C4C4C4"} />
              </p>
              <ReactTooltip
                id={`${question.settings.questionText}`}
                place="top"
                type="dark"
                effect="float"
                multiline={true}
              />
            </>
          ) : (
            ""
          )}
        </div>
      </div>
      <div id={question?.questionUuid} className="multiple-choice">
        <div className="attachment-preview">
          {question?.settings?.photo ? (
            <img src={question.settings.photo} alt="Attachment Preview" />
          ) : (
            ""
          )}
        </div>
        <div className=" justify-content-between question-title h-auto">
          <strong>
            {questionPreview?.settings.questionText === "Type Instructions" ? (
              <I18n lng="Form.TypeInstructions" />
            ) : questionPreview?.settings.questionText ===
              "Add a new question" ? (
              <>
                {question?.questionNumber
                  ? question?.questionNumber
                  : actionItem?.questionNo === undefined
                  ? actionItem
                  : actionItem?.questionNo
                  ? actionItem?.questionNo + 1
                  : 1}
                .{"   "}
                <I18n lng="Form.AddANewQuestion" />{" "}
              </>
            ) : (
              `${
                question?.questionNumber
                  ? question?.questionNumber
                  : actionItem?.questionNo === undefined
                  ? actionItem
                  : actionItem?.questionNo
                  ? actionItem?.questionNo + 1
                  : 1
              }. ${questionPreview?.settings.questionText}`
            )}
            {question.settings.isMandatory && (
              <span className="required"></span>
            )}
          </strong>
        </div>
      </div>
      <div className="row justify-content-center">
        <select
          placeholder={i18n("global.Select")}
          className="globalList-preview form-control"
          onChange={(e) => setValue(e.target.value)}
          // disabled={view}
          value={value}
        >
          <option hidden>select...</option>
          {question?.settings.options.map((item, index) => {
            return (
              <>
                <option key={index} value={item}>
                  {item}
                </option>
              </>
            );
          })}
        </select>
      </div>
      {
        <Attachment
          notes={notes}
          photo={photo}
          setNotes={setNotes}
          setPhoto={setPhoto}
          imageDeleteHandler={imageDeleteHandler}
          canComment={
            value !== undefined ? question?.settings?.canComment : false
          }
          canAttachPhoto={
            value !== undefined ? question?.settings?.canAttachPhoto : false
          }
          imageAddHandler={imageAddHandler}
        />
      }
    </>
  );
};

const mapStateToProps = ({ authentication }) => ({
  currentRole: authentication.currentRole,
});

export default connect(mapStateToProps, null)(GlobalListPreview);
