import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import { useHistory } from "react-router-dom";
import I18n from "../../../languageSelector";
import TickIcon from "../../../../../assets/images/tick-icon.jpg";
import CrossIcon from "../../../../../assets/images/cross-icon.png";
import { getSession } from "../../../../../redux/reducers/authentication/authentication";
import { getSubscriptionPayment } from "../../../../../redux/reducers/subscription/subscription.reducer";

function SubscriptionVerification({
  showDlg,
  getSession,
  secondaryAction,
  getSubscriptionPayment,
}) {
  const history = useHistory();
  const locationForPayment = window.location.href;

  const subscriptionId = locationForPayment
    ?.split("?")[1]
    ?.split("=")[1]
    ?.split("&")[0];

  const subscriptionStatus = locationForPayment?.split("&")[1]?.split("=")[1];

  const subscriptionMessage = locationForPayment
    ?.split("&")[3]
    ?.split("=")[1]
    .split("%")[0];

  useEffect(() => {
    if (subscriptionId) {
      getSubscriptionPayment(subscriptionId);
    }
    // eslint-disable-next-line
  }, []);

  const continueHandler = () => {
    getSession().then(() => {
      history.push("/");
      secondaryAction();
    });
  };

  return (
    <Modal isOpen={showDlg} toggle={() => {}}>
      <ModalBody>
        <div>
          <div className="container-fluid text-center mt-5">
            {subscriptionStatus === "paid" ? (
              <div>
                <img src={TickIcon} width="200" alt="tick-icon" />
                <h4 className="mt-2">
                  <I18n lng="Subscriptions.SubscriptionActivated" />
                </h4>
                <p>
                  <I18n lng="Subscriptions.SubscriptionPlanActivated" />
                </p>
              </div>
            ) : (
              <div>
                <img src={CrossIcon} width="150" alt="cross-icon" />
                <h4 className="mt-4">
                  <I18n lng="Subscriptions.SubscriptionFailed" />
                </h4>
                <p>
                  {subscriptionMessage &&
                    subscriptionMessage.replaceAll("+", " ")}
                  .
                </p>
              </div>
            )}
            <button
              type="button"
              className="btn btn-primary mt-4 mb-4"
              onClick={() => continueHandler()}
            >
              <I18n lng="Subscriptions.Continue" />
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

const mapStateToProps = ({ authentication }) => ({
  user: authentication.user,
});

const mapDispatchToProps = {
  getSubscriptionPayment,
  getSession,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionVerification);
