import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import I18n, { i18n } from "../../../languageSelector";
import ListItem from "./sites-list-components/list-item";
import { FaSortAlphaDown, FaSortAlphaUp } from "react-icons/fa";
import IconSearch from "../../../../../assets/images/icon-search.svg";
import IconFilter from "../../../../../assets/images/icon-filter.svg";
import DialogModal from "../../../../../components/dialogs/compact-dialog";
import IconSortAlpha from "../../../../../assets/images/icon-sort-alpha.svg";
import { getAllSites } from "../../../../../redux/reducers/sites/sites.reducer";
import { TEXT_DIRECTION } from "../../../../../helper/helperFunctions";

const SitesView = ({ getAllSites, sites }) => {
  const history = useHistory();

  const [searchValue, setSearchValue] = useState("");
  const [sortBy, setSortBy] = useState({
    attribute: "SITE_NAME",
    order: "ASC",
  });

  const [showSortModal, setShowSortModal] = useState(false);

  useEffect(() => {
    getAllSites();
    // eslint-disable-next-line
  }, []);

  const searchSortFilter = (sitesList) => {
    let toReturn = sitesList?.length ? [...sitesList] : [];
    if (searchValue) {
      toReturn = [
        ...toReturn.filter((item) =>
          item.name.toLowerCase().includes(searchValue.toLowerCase())
        ),
      ];
    }

    toReturn = [
      ...toReturn.sort(function (a, b) {
        if (sortBy.attribute === "SITE_NAME") {
          return (
            (a.name === b.name ? 0 : a.name < b.name ? -1 : 1) *
            (sortBy.order === "ASC" ? 1 : -1)
          );
        } else if (sortBy.attribute === "DATE_CREATED") {
          return (
            (a.createdAt.toString() === b.createdAt.toString()
              ? 0
              : a.createdAt.toString() < b.createdAt.toString()
              ? -1
              : 1) * (sortBy.order === "ASC" ? 1 : -1)
          );
        } else {
          return (
            (a.updatedAt.toString() === b.updatedAt.toString()
              ? 0
              : a.updatedAt.toString() < b.updatedAt.toString()
              ? -1
              : 1) * (sortBy.order === "ASC" ? 1 : -1)
          );
        }
      }),
    ];
    return toReturn;
  };

  return (
    <>
      <div className="px-3">
        <div className="search-bar">
          <form className="form-search">
            <div className="input-group">
              <div className="input-group-prepend">
                <img src={IconSearch} alt="Search Icon" />
              </div>
              <input
                className="form-control"
                placeholder={i18n("global.Search")}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
          </form>
          <Link to="#" onClick={() => setShowSortModal(true)}>
            <img src={IconSortAlpha} alt="IconSortAlpha" />
          </Link>
          <Link to="#">
            <img src={IconFilter} alt="IconFilter" />
          </Link>
        </div>
        <ul className="form-list su-list">
          {searchSortFilter(sites)?.map((site, index) => (
            <ListItem site={site} key={index} />
          ))}
        </ul>
        <Link
          to="#"
          onClick={(e) => {
            e.preventDefault();
            history.push(`/management/sites/add`);
          }}
          className={`btn btn-rounded btn-add-new ${
            TEXT_DIRECTION() === "rtl" ? "left-direction" : "right-direction"
          }`}
        >
          <I18n lng="Company.AddSite" />
        </Link>
      </div>

      <DialogModal
        showDlg={showSortModal}
        title={<I18n lng="global.Sort" />}
        content={
          <div>
            <div
              onClick={() => {
                if (sortBy.attribute === "SITE_NAME") {
                  if (sortBy.order === "ASC") {
                    setSortBy({ attribute: "SITE_NAME", order: "DESC" });
                  } else {
                    setSortBy({ attribute: "SITE_NAME", order: "ASC" });
                  }
                } else {
                  setSortBy({ attribute: "SITE_NAME", order: "ASC" });
                }
              }}
            >
              {sortBy.attribute === "SITE_NAME" ? (
                sortBy.order === "ASC" ? (
                  <FaSortAlphaDown />
                ) : (
                  <FaSortAlphaUp />
                )
              ) : null}
              <span className={"btn"}>
                {" "}
                <I18n lng="Company.SiteName" />{" "}
              </span>
              <hr />
            </div>
          </div>
        }
        primaryActionTitle={<I18n lng="global.Close" />}
        secondaryAction={() => setShowSortModal(false)}
        primaryAction={() => setShowSortModal(false)}
      />
    </>
  );
};

const mapStateToProps = ({ sites }) => ({
  sites: sites.sites,
});
const mapDispatchToProps = {
  getAllSites,
};
export default connect(mapStateToProps, mapDispatchToProps)(SitesView);
