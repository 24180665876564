import React, { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { AiOutlineClose } from "react-icons/ai";
import { Link, useHistory } from "react-router-dom";
import I18n, { i18n } from "../../../languageSelector";
import IconCheck from "../../../../../assets/images/check-mark.svg";
// import IconEmail from "../../../../../assets/images/icon-envelope.svg";
// import IconCompany from "../../../../../assets/images/icon-company.svg";
import {
  companyUpdate,
  getCompanyById,
  companyUpdateReportColor,
} from "../../../../../redux/reducers/company/company.reducer";
import { REPORT_COLORS } from "../../../../../config/constants";
import { getFileData } from "../../../../../helper/image-helper";
import IconPencil from "../../../../../assets/images/icon-pencil.svg";
import {
  TEXT_DIRECTION,
  canAccessTheModule,
} from "../../../../../helper/helperFunctions";
import AlertSigupDialog from "../../../../../components/dialogs/alertSignupDialog";
// import PhoneNumber from "../../../../../components/form-input/phone-number.component";

const CompanyInfoView = ({
  user,
  company,
  rolesAccess,
  companyUpdate,
  getCompanyById,
  companyUpdateReportColor,
}) => {
  const [image, setImage] = useState(
    "https://tamam-bucket.s3.me-south-1.amazonaws.com/ee6023bcb103ac283cbe79ec86d16323.png"
  );

  const history = useHistory();
  const hiddenFileInput = React.useRef(null);

  const { setValue } = useForm({
    defaultValues: { ...company, phone: company?.phone },
  });

  const [reportColors, setReportColors] = useState(REPORT_COLORS);
  const [showDeleteImageDlg, setShowDeleteImageDlg] = useState(false);

  useEffect(() => {
    if (user && user?.company?.id) {
      getCompanyById(user?.company?.id);
    }
    // eslint-disable-next-line
  }, [user?.company?.id]);

  const handleClick = () => {
    if (rolesAccess?.company?.access?.manageCompanyInformation) {
      hiddenFileInput.current.click();
    }
  };

  const imageHandleChange = async (event) => {
    const fileData = await getFileData(event);
    let existingFileUrl = image ? image : company.image;
    setImage(fileData.fileUrl);
    await axios
      .post("/uploads", {
        base64: fileData.fileUrl,
        existingFileUrl,
        fileType: fileData.fileType,
      })
      .then((response) => {
        setImage(response.data);
        companyUpdate({
          ...company,
          image: response.data,
          country: { value: company.country, label: company.country },
        });
      });
  };

  useEffect(() => {
    setImage(company.image);
    setValue("phone", company?.phone);
    if (company.reportColor) {
      reportColors.forEach((subItem, subIndex) => {
        if (subItem.label === company?.reportColor?.label) {
          subItem.value = true;
        } else {
          subItem.value = false;
        }
      });
    }
    setReportColors([...reportColors]);

    // eslint-disable-next-line
  }, [company]);

  const imageDeletHandler = () => {
    companyUpdate({ ...company, image: "" });
    setShowDeleteImageDlg(false);
  };

  const reportColorHandler = (index) => {
    if (reportColors && reportColors.length && index !== null) {
      reportColors[index] = {
        ...reportColors[index],
        value: !reportColors[index].value,
      };
      reportColors.forEach((subItem, subIndex) => {
        if (subIndex !== index) {
          subItem.value = false;
        } else {
          companyUpdateReportColor(company.id, { reportColor: subItem });
        }
      });
    }
    setReportColors([...reportColors]);
  };

  return (
    <div className="comp-set">
      <div className="d-flex justify-content-between">
        <div className="comp-logo ">
          <label>
            <I18n lng="Company.CompanyLogo" />
          </label>
          <div
            className="logo-wrap"
            onClick={(e) => {
              e.preventDefault();
              handleClick();
            }}
          >
            {company?.image ? (
              <>
                <img src={company.image} alt="Company Logo" />
              </>
            ) : (
              ""
            )}
          </div>

          {company.image &&
          rolesAccess?.company?.access?.manageCompanyInformation ? (
            <div
              className="action-remove"
              style={TEXT_DIRECTION() === "rtl" ? { right: "-0.5rem" } : {}}
            >
              <AiOutlineClose
                className="icon icon-img-remove"
                onClick={() => {
                  if (rolesAccess?.company?.access?.manageCompanyInformation) {
                    setShowDeleteImageDlg(true);
                  }
                }}
              />
            </div>
          ) : (
            ""
          )}
          <div
            className="action-edit"
            style={TEXT_DIRECTION() === "rtl" ? { right: "-0.5rem" } : {}}
          >
            <img src={IconPencil} alt="IconPencil" title="Edit" />
            {canAccessTheModule(
              rolesAccess?.company?.access?.manageCompanyInformation,
              <input
                type="file"
                ref={hiddenFileInput}
                onChange={imageHandleChange}
                accept="image/png, image/gif, image/jpeg, application/pdf"
              />
            )}
          </div>
        </div>
        <div className="theme-colors">
          <label>
            <I18n lng="Company.ColorTheme" />
          </label>
          <ul>
            {reportColors && reportColors.length
              ? reportColors.map((color, index) => {
                  return (
                    <li
                      key={index}
                      onClick={() => {
                        if (
                          rolesAccess?.company?.access?.manageCompanyInformation
                        ) {
                          reportColorHandler(index);
                        }
                      }}
                    >
                      {color.value ? (
                        <div className="comp-theme-color">
                          <img src={IconCheck} alt="loading" />
                        </div>
                      ) : null}
                    </li>
                  );
                })
              : null}
          </ul>
        </div>
      </div>

      <hr />
      <div className="comp-info">
        <div className="  mb-3 ">
          <ul>
            <li className="row">
              <label className="col-6">
                <I18n lng="Company.CompanyName" />
              </label>
              <div
                className={`col-6 
               ${TEXT_DIRECTION() === "rtl" ? "text-left" : "text-right"}`}
              >
                {/* <i className="icon">
                  <img src={IconCompany} alt="Company" />
                </i> */}
                <span>{company?.companyName}</span>
              </div>
            </li>
            <li className="row">
              <label className="col-5">
                <I18n lng="Company.CompanyEmail" />
              </label>
              <div
                className={`col-7  
               ${TEXT_DIRECTION() === "rtl" ? "text-left" : "text-right"}`}
              >
                {/* <i className="icon">
                  <img src={IconEmail} alt="Email" />
                </i> */}
                <span>{company?.email}</span>
              </div>
            </li>
            <li className="row">
              <label className="col-5">
                <I18n lng="Company.CompanyPhone" />
              </label>
              <div
                style={TEXT_DIRECTION() === "rtl" ? { direction: "ltr" } : {}}
                className={`col-7 
               ${TEXT_DIRECTION() === "rtl" ? "text-left" : "text-right"}`}
              >
                <span>{company?.phone}</span>
              </div>
            </li>
            <li className="row">
              <label className="col-6">
                <I18n lng="Company.TaxNumber" />
              </label>
              <span
                className={`col-6 
               ${TEXT_DIRECTION() === "rtl" ? "text-left" : "text-right"}`}
              >
                {company?.taxNumber}
              </span>
            </li>
          </ul>
        </div>
        <hr />
        <div className="mt-3 ">
          <ul>
            <li className="row">
              <label className="col-6">
                <I18n lng="global.Address" />
              </label>
              <span
                className={`col-6 
               ${TEXT_DIRECTION() === "rtl" ? "text-left" : "text-right"}`}
              >
                {company?.address}
              </span>
            </li>
            <li className="row">
              <label className="col-6">
                <I18n lng="global.City" />
              </label>
              <span
                className={`col-6 
               ${TEXT_DIRECTION() === "rtl" ? "text-left" : "text-right"}`}
              >
                {company?.city}
              </span>
            </li>
            <li className="row">
              <label className="col-6">
                <I18n lng="global.PostalCode" />
              </label>
              <span
                className={`col-6 
               ${TEXT_DIRECTION() === "rtl" ? "text-left" : "text-right"}`}
              >
                {company?.postalCode}
              </span>
            </li>
            <li className="row">
              <label className="col-6">
                <I18n lng="global.State" />
              </label>
              <span
                className={`col-6 
               ${TEXT_DIRECTION() === "rtl" ? "text-left" : "text-right"}`}
              >
                {company?.state}
              </span>
            </li>
            <li className="row">
              <label className="col-6">
                <I18n lng="global.Country" />
              </label>
              <span
                className={`col-6 
               ${TEXT_DIRECTION() === "rtl" ? "text-left" : "text-right"}`}
              >
                {company?.country}
              </span>
            </li>
            <li className="row">
              <label className="col-6">
                <I18n lng="global.TimeZone" />
              </label>
              <span
                className={`col-6 
               ${TEXT_DIRECTION() === "rtl" ? "text-left" : "text-right"}`}
              >
                {company?.timeZone}
              </span>
            </li>
          </ul>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-lg-12">
          <div className="form-group form-subject mb-3">
            <label>
              <I18n lng="Company.Subject" />
            </label>
            <input
              rows="1"
              disabled={true}
              value={company?.subject}
              placeholder={i18n("Company.Subject")}
              className="form-control subject-textArea"
            />
          </div>
          <div className="form-group">
            <label>
              <I18n lng="Company.DefaultReportSharingEmailMessage" />
            </label>
            <textarea
              rows="6"
              disabled={true}
              value={company?.emailMessage}
              className="form-control messege-text"
            />
          </div>
        </div>
        <div className="col-lg-12">
          <hr />
          <div className="form-group">
            <label>
              <I18n lng="Company.DefaultReportSharingSMSMessage" />
            </label>
            <textarea
              rows="6"
              disabled={true}
              value={company?.smsMessage}
              className="form-control messege-text"
            />
          </div>
        </div>
      </div>
      {canAccessTheModule(
        rolesAccess?.company?.access?.manageCompanyInformation,
        <div className="btn-bar text-center my-4">
          <Link
            to="#"
            className="btn btn-primary"
            onClick={(e) => {
              e.preventDefault();
              history.push(`/management/info/edit`);
            }}
          >
            <I18n lng="global.Edit" />
          </Link>
        </div>
      )}
      <AlertSigupDialog
        showDlg={showDeleteImageDlg}
        classes="modal-alert"
        content={
          <p>
            <br />
            <br />
            <I18n lng="Company.DeleteCompanyLogo" />
          </p>
        }
        primaryActionTitle={i18n("global.Delete")}
        secondryActionTitle={i18n("global.Cancel")}
        primaryActionClasses={"btn-danger"}
        secondryActionClasses={"btn-white"}
        primaryAction={() => imageDeletHandler()}
        secondaryAction={() => setShowDeleteImageDlg(false)}
      />
    </div>
  );
};

const mapStateToProps = ({ authentication, company }) => ({
  company: company.company,
  user: authentication.user,
  currentRole: authentication.currentRole,
  rolesAccess: authentication.userRoleAccess,
});

const mapDispatchToProps = {
  getCompanyById,
  companyUpdate,
  companyUpdateReportColor,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyInfoView);
