import React, { useState, useEffect } from "react";
import { Collapse } from "reactstrap";
import { Link } from "react-router-dom";
import I18n, { i18n } from "../../../languageSelector";
import { useSwipeable } from "react-swipeable";
import FormBuilderQuestion from "./form-builder-question";
import { Draggable, Droppable } from "react-beautiful-dnd";
import IconDraggable from "../../../../../assets/images/icon-draggable.svg";
import IconCollapseArrow from "../../../../../assets/images/icon-collapse-arrow.svg";
import { TEXT_DIRECTION } from "../../../../../helper/helperFunctions";

const FormBuilderSection = ({
  pageIndex,
  sectionIndex,
  section,
  setShowResponseListDrawer,
  showResponseListDrawer,
  setShowQuestionOptionsDrawer,
  setActionItem,
  questions = [],
  isScored,
  updateSection,
  updateQuestion,
  deleteSection,
  deleteQuestion,
  selectedElement,
  setSelectedElement,
  closeAll,
  form,
  setMcqID,
  addQuestion,
  updateMultipleChoiceById,
  getAllMultipleChoiceQuestions,
  formId,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showItemActions, setShowItemActions] = useState(false);
  const [isSectionTextEditable, setIsSectionTextEditable] = useState(false);
  const [sectionText, setSectionText] = useState(`Section ${sectionIndex + 1}`);
  const [isSlideToDeleteDisabled, setIsSlideToDeleteDisabled] = useState(false);

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      setShowItemActions(TEXT_DIRECTION() === "rtl" ? false : true);
      setSelectedElement(pageIndex, sectionIndex, undefined, true);
    },
    onSwipedRight: () => {
      setShowItemActions(TEXT_DIRECTION() === "rtl" ? true : false);
      setSelectedElement(pageIndex, sectionIndex, undefined, true);
    },
    onTap: () => setSelectedElement(pageIndex, sectionIndex, undefined, true),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  useEffect(() => {
    setSectionText("");
    if (section) {
      if (section.name !== "Section" && section.name !== "Section ") {
        setSectionText(section.name);
      }
      setIsOpen(section.isOpen);
    }
    // eslint-disable-next-line
  }, [section]);

  const openSection = () => {
    updateSection(pageIndex, sectionIndex, { ...section, isOpen: true }, true);
  };

  const closeSection = () => {
    updateSection(pageIndex, sectionIndex, { ...section, isOpen: false }, true);
  };

  useEffect(() => {
    if (
      !selectedElement ||
      selectedElement.pageNo !== pageIndex ||
      selectedElement.sectionNo !== sectionIndex ||
      (selectedElement.questionNo !== undefined && showItemActions)
    ) {
      setShowItemActions(false);
    }
    // eslint-disable-next-line
  }, [selectedElement]);

  return (
    <Draggable
      draggableId={"section-" + pageIndex + sectionIndex}
      index={pageIndex + sectionIndex}
    >
      {(provided, snapshot) => (
        <div
          {...provided.draggableProps}
          ref={provided.innerRef}
          isdragging={snapshot.isdragging}
          className={
            selectedElement &&
            selectedElement.pageNo === pageIndex &&
            selectedElement.sectionNo === sectionIndex &&
            selectedElement.questionNo === undefined
              ? "selected-form-builder-element"
              : ""
          }
        >
          <section className="form-block section">
            <div
              className={`form-title ${
                TEXT_DIRECTION() === "rtl" ? "pl-4" : ""
              }`}
              style={{ background: "#cedde9" }}
              {...(isSlideToDeleteDisabled ? {} : handlers)}
            >
              <strong>
                <Link
                  to="#"
                  {...provided.dragHandleProps}
                  className="icon icon-draggable"
                >
                  <img
                    className="ml-2"
                    src={IconDraggable}
                    alt="IconDraggable"
                  />
                </Link>

                {isSectionTextEditable ? (
                  <input
                    className="mr-1"
                    id={section?.sectionUuid}
                    autoFocus
                    type="text"
                    value={sectionText}
                    placeholder={`${i18n("Form.Section")} ${sectionIndex + 1}`}
                    onBlur={() => {
                      setIsSlideToDeleteDisabled(false);
                      setIsSectionTextEditable(false);
                      if (!sectionText) {
                        updateSection(pageIndex, sectionIndex, {
                          ...section,
                          name: "Section",
                        });
                      } else {
                        updateSection(pageIndex, sectionIndex, {
                          ...section,
                          name: sectionText,
                        });
                      }
                    }}
                    onChange={(e) => setSectionText(e.target.value)}
                  />
                ) : (
                  <span
                    className={
                      sectionText === "Section " + (sectionIndex + 1)
                        ? "placeholder"
                        : "dummy"
                    }
                    onClick={() => {
                      if (sectionText === "Section ") {
                        setSectionText("");
                      }
                      setIsSlideToDeleteDisabled(true);
                      setIsSectionTextEditable(true);
                    }}
                  >
                    {section?.name === "Section"
                      ? `${i18n("Form.Section")} ${sectionIndex + 1}`
                      : section?.name}
                  </span>
                )}
              </strong>
              {showItemActions &&
              selectedElement &&
              selectedElement.pageNo === pageIndex &&
              selectedElement.sectionNo === sectionIndex &&
              selectedElement.questionNo === undefined ? (
                <div
                  className={`action ${
                    TEXT_DIRECTION() === "rtl"
                      ? "action-delete-left"
                      : "action-delete"
                  }`}
                >
                  <button
                    className=" btn btn-danger"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowItemActions(false);
                      deleteSection(pageIndex, sectionIndex);
                    }}
                  >
                    <I18n lng="global.Delete" />
                  </button>
                  <button
                    className="ml-3 mr-3 btn btn-secondary"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowItemActions(false);
                    }}
                  >
                    <I18n lng="global.Cancel" />
                  </button>
                </div>
              ) : null}
              {section?.questions?.length ? (
                <Link
                  onClick={() => {
                    if (isOpen) {
                      closeSection();
                    } else {
                      openSection();
                    }
                  }}
                  to="#"
                  className={`icon icon-collapse ${
                    TEXT_DIRECTION() === "rtl" ? "pr-4" : ""
                  }`}
                  id={section.name + sectionIndex + pageIndex}
                >
                  <img src={IconCollapseArrow} alt="IconCollapseArrow" />
                </Link>
              ) : null}
            </div>
            {section?.questions?.length ? (
              <Collapse
                toggler={section.name + sectionIndex + pageIndex}
                isOpen={isOpen}
              >
                <ul className="list-unstyled">
                  <Droppable
                    droppableId={
                      "page-section-question-" + pageIndex + "-" + sectionIndex
                    }
                    type="question"
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        isdraggingover={snapshot.isdraggingover}
                      >
                        {questions.map((item, index) => (
                          <FormBuilderQuestion
                            key={index}
                            question={item}
                            pageIndex={pageIndex}
                            sectionIndex={sectionIndex}
                            questionIndex={index}
                            index={index}
                            showResponseListDrawer={showResponseListDrawer}
                            setShowResponseListDrawer={
                              setShowResponseListDrawer
                            }
                            setShowQuestionOptionsDrawer={
                              setShowQuestionOptionsDrawer
                            }
                            setActionItem={setActionItem}
                            isScored={isScored}
                            updateQuestion={updateQuestion}
                            deleteQuestion={deleteQuestion}
                            selectedElement={selectedElement}
                            setSelectedElement={setSelectedElement}
                            closeAll={closeAll}
                            form={form}
                            setMcqID={setMcqID}
                            addQuestion={addQuestion}
                            updateMultipleChoiceById={updateMultipleChoiceById}
                            getAllMultipleChoiceQuestions={
                              getAllMultipleChoiceQuestions
                            }
                            formId={formId}
                          />
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </ul>
              </Collapse>
            ) : null}
          </section>
        </div>
      )}
    </Draggable>
  );
};

export default FormBuilderSection;
