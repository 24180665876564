export const ReportsStatusCssClasses = ["danger", "success", "info", ""];
export const ReportsStatusTitles = ["Suspended", "Active", "Pending", ""];
export const ReportsTypeCssClasses = ["success", "primary", ""];
export const ReportsTypeTitles = ["Business", "Individual", ""];
export const defaultSorted = [{ dataField: "forms", order: "desc" }];
export const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "25", value: 25 },
  { text: "50", value: 50 },
];
export const initialFilter = {
  filter: { customerName: "", forms: "", email: "", mobile: "" },
  sortOrder: "desc", // asc||desc
  sortField: "forms",
  pageNumber: 1,
  pageSize: 25,
};
