import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./archive-ui-helper";

const ArchiveUIContext = createContext();

export function useArchiveUIContext() {
  return useContext(ArchiveUIContext);
}

export const ArchiveUIConsumer = ArchiveUIContext.Consumer;

export function ArchiveUIProvider({ archiveUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initInvoice = {
    id: undefined,
    englishName: "",
    arabicName: "",
    price: 0.0,
    catagory: "",
    serviceIsActive: "",
    serviceIsTaxable: "",
    englishDetails: "",
    arabicDetails: "",
    detailedInvoice: "",
    paymentDate: "",
    barcode: "",
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initInvoice,
    openDeleteFormDlg: archiveUIEvents.openDeleteFormDlg,
    openRestoreFormDlg: archiveUIEvents.openRestoreFormDlg,
  };

  return (
    <ArchiveUIContext.Provider value={value}>
      {children}
    </ArchiveUIContext.Provider>
  );
}
