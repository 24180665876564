import React from "react";
const TextArea = ({
  label,
  id,
  name,
  validations,
  prefix,
  suffix,
  suffixAction = () => {
    console.log("siffix action");
  },
  register,
  required,
  error,
  ...otherProps
}) => {
  return (
    <>
      {label && (
        <label className="form-label" htmlFor={id}>
          {label} {validations?.required && <span className="required"></span>}
        </label>
      )}
      <div className="input-group">
        {prefix && (
          <div className="input-group-prepend">
            <img src={prefix} alt="fieldIcon" />
          </div>
        )}
        <textarea
          className="form-control textarea"
          name={name}
          {...(register ? register(name, { ...validations }) : {})}
          {...otherProps}
        ></textarea>
        {suffix && (
          <div className="input-group-append" onClick={suffixAction}>
            <img src={suffix} alt="fieldIcon" />
          </div>
        )}
      </div>
      {error && <p className="error">{error.message}</p>}
    </>
  );
};
export default TextArea;
