import React, { useMemo } from "react";
import ListItemMenu from "./list-item-menu";
import I18n, { i18n } from "../../../../languageSelector";
import { FaRegUserCircle } from "react-icons/fa";
import { useUserUIContext } from "../users-ui-context";
import IconUserStar from "../../../../../../assets/images/icon-premium.svg";
import {
  isRTL,
  TEXT_DIRECTION,
} from "../../../../../../helper/helperFunctions";

const SitesList = ({ user }) => {
  const userUIContext = useUserUIContext();
  const userUIProps = useMemo(() => {
    return {
      openUserViewDialog: userUIContext.openUserViewDialog,
    };
  }, [userUIContext]);

  return (
    <li>
      <div className="list-item-avatar">
        <span className="avatar-wrap">
          {user.profileImage ? (
            <img src={user.profileImage} alt="Avatar" />
          ) : (
            <FaRegUserCircle size={"3.3rem"} />
          )}
        </span>

        {user?.isUserSeated ? (
          <div className="avatar-badge">
            <img
              className="icon icon-premium"
              src={IconUserStar}
              alt="IconUserStar"
            />
          </div>
        ) : (
          ""
        )}
      </div>
      <div
        className="list-item-detail cursor-pointer"
        onClick={(e) => {
          e.preventDefault();
          userUIProps.openUserViewDialog(user?.id);
        }}
      >
        <h6
          className={`truncate user-truncate ${
            TEXT_DIRECTION() !== "rtl" &&
            isRTL(user?.firstName + " " + user?.lastName)
              ? "direction-rtl"
              : TEXT_DIRECTION() === "rtl" &&
                !isRTL(user?.firstName + " " + user?.lastName)
              ? "direction-ltr"
              : ""
          }`}
        >
          {user?.firstName + " " + user?.lastName === "Untitled" ? (
            <I18n lng="global.Untitled" />
          ) : (
            user?.firstName + " " + user?.lastName
          )}
        </h6>

        <p className="truncate text-muted">
          {user?.roleSetting?.name === "owner"
            ? i18n("Roles.owner")
            : user?.roleSetting?.name === "manager"
            ? i18n("Roles.manager")
            : user?.roleSetting?.name === "inspector"
            ? i18n("Roles.inspector")
            : user.roleSetting.name === "guest"
            ? i18n("Roles.guest")
            : user?.roleSetting?.name === "admin"
            ? i18n("Roles.admin")
            : user?.roleSetting?.name}
        </p>
        <small>{user?.email}</small>
      </div>
      <span className="list-item-action">
        {!user?.active ? (
          <small className="status status-danger">
            <I18n lng="global.Disabled" />
          </small>
        ) : user?.active && user?.isEmailVerified ? (
          <small className="status status-success">
            <I18n lng="global.Enable" />
          </small>
        ) : !user?.isEmailVerified ? (
          <small className="status status-pending">
            <I18n lng="global.Pending" />
          </small>
        ) : null}
        <ListItemMenu id={user?.id} userRole={user?.role} selectedUser={user} />
      </span>
    </li>
  );
};

export default SitesList;
