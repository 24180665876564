import React, { useState } from "react";
import { connect } from "react-redux";
import RolesEditDialog from "./RolesEditDialog";
import I18n, { i18n } from "../../../languageSelector";
import DynamicModal from "../../../../../components/dialogs";
import IconPencil from "../../../../../assets/images/role-edit.svg";
import IconDelete from "../../../../../assets/images/role-delete.svg";
import IconSearch from "../../../../../assets/images/icon-search.svg";
import AlertSigupDialog from "../../../../../components/dialogs/alertSignupDialog";
import IconDeleteDisable from "../../../../../assets/images/role-delete-disable.svg";
import IconPencilDisabled from "../../../../../assets/images/role-edit-disabled.svg";
import { deleteRoleById, getAllRoles } from "../../../../../redux/reducers/roles/roles.reducer";

const ManageRolesDialog = ({ show, loggedInUser, onHide, rolesList, getAllRoles, deleteRoleById }) => {
  const [edit, setEdit] = useState(false);
  const [roleForEdit, setRoleForEdit] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [roleDeleteId, setRoleDeleteId] = useState("");
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showAddRoleDialog, setShowAddRoleDialog] = useState(false);
  const [showCantDeleteDialog, setShowCantDeleteDialog] = useState(false);
  const [size, setSize] = useState(10);
  const deleteRoleHandler = () => {
    if (roleDeleteId) {
      deleteRoleById(roleDeleteId)
        .then(() => {
          setShowDeleteDialog(false);
          getAllRoles(10);
        })
        .catch((error) => {
          error.clientMessage = "Can't delete Role";
          setShowCantDeleteDialog(true);
          setShowDeleteDialog(false);
        });
    }
  };
  const getMoreRoles = () => {
    getAllRoles(size + 10);
    setSize(size + 10);
  };
  return (
    <>
      <DynamicModal
        show={show}
        title={i18n("Roles.RolesManagement")}
        secondaryAction={onHide}
        modalContentClass="bg-white"
        customClasses="modal-drawer"
      >
        <div className="roles-wrapper">
          <h5 className="mt-3 mb-2 text-center">
            <I18n lng="Company.Roles" />
          </h5>

          <form className="form-search">
            <div className="input-group mb-1 mt-1">
              <div className="input-group-prepend">
                <img src={IconSearch} alt="Search Icon" />
              </div>
              <input className="form-control" placeholder={i18n("global.Search")} onChange={(e) => setSearchValue(e.target.value)} />
            </div>
          </form>
          {rolesList?.length ? (
            <div>
              {rolesList
                ?.filter((item) => item.name.toLowerCase().includes(searchValue.toLowerCase()))
                ?.map((item, index) => {
                  return (
                    <div key={index}>
                      <div className="role-info-wrapper">
                        <div className="role-info">
                          <b className="role-title h6">
                            {item?.name === "owner"
                              ? i18n("global.Owner")
                              : item?.name === "manager"
                              ? i18n("global.Manager")
                              : item?.name === "inspector"
                              ? i18n("global.Inspector")
                              : item?.name === "admin"
                              ? i18n("Roles.admin")
                              : item?.name === "guest"
                              ? i18n("Roles.guest")
                              : item?.name}
                          </b>
                          <p className="mb-0 text-muted">
                            {item?.totalUsers ? item?.totalUsers : 0} {item?.totalUsers > 1 ? i18n("Company.Users") : i18n("Company.User")}
                          </p>
                          <p className="mb-0 text-muted">
                            {item?.inspectorRole === true && item?.managerRole === true
                              ? i18n("Company.BackOfficeApplication")
                              : item?.managerRole === true && item?.inspectorRole === false
                              ? i18n("Company.BackOffice")
                              : item?.managerRole === false && item?.inspectorRole === true
                              ? i18n("Company.Application")
                              : ""}
                          </p>
                        </div>
                        <div className="ml-auto">
                          {loggedInUser?.roleSetting?.originalName !== "owner" && item?.originalName === "owner" ? (
                            <img title="Edit" alt="loading" className="mr-3" src={IconPencilDisabled} />
                          ) : (
                            <img
                              title="Edit"
                              alt="loading"
                              src={IconPencil}
                              className="mr-3 cursor-pointer"
                              onClick={() => {
                                setRoleForEdit({ ...item });
                                setEdit(true);
                                setShowAddRoleDialog(true);
                              }}
                            />
                          )}
                          {item?.originalName === "owner" ||
                          item?.originalName === "manager" ||
                          item?.originalName === "admin" ||
                          item?.originalName === "guest" ||
                          item?.originalName === "inspector" ? (
                            <img alt="loading" title="Delete" src={IconDeleteDisable} className="ml-1 mr-1" />
                          ) : (
                            <img
                              alt="loading"
                              title="Delete"
                              src={IconDelete}
                              className="cursor-pointer ml-1 mr-1"
                              onClick={() => {
                                setRoleDeleteId(item?.id);
                                setShowDeleteDialog(true);
                              }}
                            />
                          )}
                        </div>
                      </div>
                      <hr />
                    </div>
                  );
                })}
            </div>
          ) : null}
        </div>
        <button className="btn btn-primary btn-icon p-2 mt-3 ml-3 mr-3" onClick={getMoreRoles}>
          <I18n lng="Form.LoadMore" />
        </button>
        <div className="text-center btn-bar">
          <button className="btn btn-white btn-icon p-2 mt-3" onClick={() => onHide()}>
            <I18n lng="global.Back" />
          </button>
          <button
            className="btn btn-primary btn-icon p-2 mt-3 ml-3 mr-3"
            onClick={() => {
              setEdit(false);
              setRoleForEdit({});
              setShowAddRoleDialog(true);
            }}
          >
            <I18n lng="Company.AddNewRole" />
          </button>
        </div>
      </DynamicModal>
      <RolesEditDialog edit={edit} show={showAddRoleDialog} roleForEdit={roleForEdit} onHide={() => setShowAddRoleDialog(false)} />
      <AlertSigupDialog
        showDlg={showDeleteDialog}
        primaryAction={() => deleteRoleHandler()}
        secondaryAction={() => setShowDeleteDialog(false)}
        classes="modal-alert"
        primaryActionTitle={i18n("global.Delete")}
        primaryActionClasses={"btn-danger"}
        secondryActionTitle={i18n("global.Cancel")}
        secondryActionClasses={"btn-white"}
        content={
          <p>
            <br />
            <br />
            <span>
              <I18n lng="Company.DeleteRole" />
            </span>
          </p>
        }
      />
      <AlertSigupDialog
        showDlg={showCantDeleteDialog}
        secondaryAction={() => setShowCantDeleteDialog(false)}
        classes="modal-alert"
        secondryActionTitle={i18n("global.Cancel")}
        secondryActionClasses={"btn-white"}
        content={
          <p>
            <br />
            <br />
            <h5 style={{ color: "#20639b" }}>
              <I18n lng="Company.CantDeleteRole" />
            </h5>
            <span>
              <I18n lng="Company.UsersLinkedToRole" />
            </span>
          </p>
        }
      />
    </>
  );
};
const mapStateToProps = ({ roles, authentication }) => ({
  rolesList: roles.roles,
  loggedInUser: authentication.user,
});
const mapDispatchToProps = { deleteRoleById, getAllRoles };
export default connect(mapStateToProps, mapDispatchToProps)(ManageRolesDialog);
