// import React, { useState } from "react";
// import { Route, Switch } from "react-router-dom";
// import SubscriptionsAdd from "./company-subscription-add";
// import SubscriptionsView from "./company-subscription-view";

// const SubscriptionsPage = () => {
//   const [selectPlan, setSelectPlan] = useState({});

//   return (
//     <>
//       <Switch>
//         <Route path="/management/subscriptions/:moduleName/add">
//           {({ history, match }) => (
//             <SubscriptionsAdd
//               showDlg={match != null}
//               secondaryAction={() => {
//                 history.push("/management/subscriptions");
//               }}
//               plan={selectPlan}
//             />
//           )}
//         </Route>
//       </Switch>
//       <SubscriptionsView setSelectPlan={setSelectPlan} />
//     </>
//   );
// };

// export default SubscriptionsPage;

import React, { useState } from "react";
import { Route, Switch } from "react-router-dom";
import SubscriptionsAdd from "./company-subscription-add";
import SubscriptionsView from "./company-subscription-view";
import InvoiceDetails from "./invoices/invoice-details";

const SubscriptionsPage = () => {
  const [selectPlan, setSelectPlan] = useState({});

  return (
    <>
      <Switch>
        <Route path="/management/subscriptions/:moduleName/add">
          {({ history, match }) => (
            <SubscriptionsAdd
              showDlg={match != null}
              secondaryAction={() => {
                history.push("/management/subscriptions");
              }}
              plan={selectPlan}
            />
          )}
        </Route>
        <Route path="/management/subscriptions/invoice/:invoceId">
          <InvoiceDetails />
        </Route>
      </Switch>
      <SubscriptionsView setSelectPlan={setSelectPlan} />
    </>
  );
};

export default SubscriptionsPage;
