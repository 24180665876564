import React from "react";
import { Route, Switch } from "react-router-dom";
import CompanyInfoEdit from "./company-info-edit";
import CompanyInfoView from "./company-info-view";

const CompanyPage = () => {
  return (
    <>
      <Switch>
        <Route path="/management/info/edit">
          {({ history, match }) => (
            <CompanyInfoEdit
              showDlg={match != null}
              secondaryAction={() => {
                history.push("/management/info");
              }}
            />
          )}
        </Route>
      </Switch>
      <CompanyInfoView />
    </>
  );
};

export default CompanyPage;
