import { translateChartsValue, translateMonthsFromCharts, translatePercentageFromCharts } from "../../../../helper/helperFunctions";
import { i18n } from "../../languageSelector";
import ApexChart from "../area-chart/areaChart";
import { HeaderDataInterfae } from "../main-page/analytics-main";

function SingleDummyChart({
  selectedItem,
  className,
  analyticsXaxisData,
  totalResult,
}: {
  selectedItem: HeaderDataInterfae;
  className?: string;
  analyticsXaxisData: string[];
  totalResult: number;
}) {
  let refactoredMonths = analyticsXaxisData.map((item) => {
    return translateMonthsFromCharts(item);
  });
  console.log(refactoredMonths, "months translated");
  return (
    <div
      className={`w-100 rounded mt-2`}
      style={{
        backgroundColor: "#ffffff",
        padding: "0 .2rem",
        paddingTop: "12px",
      }}
    >
      <div style={{ padding: "0 3rem", height: "28px" }} className="d-flex  align-items-center justify-content-between ">
        <p className="title m-0" style={{ fontSize: "25px", fontWeight: 400, lineHeight: "15.71px", color: "#333333" }}>
          {i18n(`Analytics.${selectedItem.title}`)}:
        </p>
        <p className="m-0">
          <span
            style={{
              color:
                selectedItem.percentage?.includes("-") && selectedItem.title === "Fail"
                  ? "#3CAEA3"
                  : selectedItem.percentage?.includes("-") && selectedItem.title !== "Fail"
                  ? "#EB5757"
                  : selectedItem.value === 0 || selectedItem.value === "0%"
                  ? "#333333"
                  : selectedItem.title === "Fail"
                  ? "red"
                  : "#3CAEA3",
            }}
          >
            {totalResult !== 0
              ? selectedItem.title === "Score" && selectedItem.percentage === "0%" && selectedItem.value === "0%"
                ? ""
                : selectedItem.percentage?.includes("-")
                ? translatePercentageFromCharts(selectedItem.percentage)
                : selectedItem.value === 0 || selectedItem.value === "0%"
                ? translatePercentageFromCharts(selectedItem.percentage)
                : `+${translatePercentageFromCharts(selectedItem.percentage)}`
              : ""}
          </span>{" "}
          <span
            style={{
              fontSize: "25px",
              fontWeight: 500,
              lineHeight: "25.66px",
              color: "#333333",
            }}
          >
            {totalResult !== 0
              ? selectedItem.title === "Score" && selectedItem.percentage === "0%" && selectedItem.value === "0%"
                ? ""
                : translateChartsValue(selectedItem.value)
              : ""}
          </span>
        </p>
      </div>
      <div>
        <ApexChart totalResult={totalResult} analyticsXaxisData={refactoredMonths} headData={selectedItem} dateToShow={true} />
      </div>
      {/* <table className="w-100">
        <tbody className="w-100">
          <div
            className="border-left-line"
            style={{ left: TEXT_DIRECTION() === "ltr" ? "19px" : "", right: TEXT_DIRECTION() === "rtl" ? "5px" : "" }}
          ></div>
          {yAxisData.map((item, index) => {
            return (
              <tr className="w-100">
                <th key={index} scope="row" style={{ fontSize: "10px", display: "flex", alignItems: "center" }} className="head">
                  {item}
                  <div
                    style={{ marginLeft: TEXT_DIRECTION() === "ltr" ? "11px" : "", marginRight: TEXT_DIRECTION() === "rtl" ? "11px" : "" }}
                    className={`x-axis-line ${index === 6 ? "border-top" : ""}`}
                  ></div>
                </th>
              </tr>
            );
          })}

          <div className="w-100 d-flex mx-3">
            {xAxisData.map((item, index) => {
              return (
                <li style={{ fontSize: "10px", width: "100%" }} key={index} className="d-flex mx-2">
                  {item}
                </li>
              );
            })}
          </div>
        </tbody>
      </table> */}
    </div>
  );
}

export default SingleDummyChart;
