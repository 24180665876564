import React, { useState } from "react";
import MultipleChoiceSettings from "./multiple-choice-settings";
import MultipleChoicePreview from "./multiple-choice-preview";
import I18n from "../../../../../languageSelector";
import { TEXT_DIRECTION } from "../../../../../../../helper/helperFunctions";

const MultipleChoiceBuilder = ({
  updatedDuplicateForm,
  setUpdatedDuplicateForm,
  updateQuestion,
  actionItem,
  question,
  newQuestion = false,
  mcqId,
  form,
  setForm,
}) => {
  const [selected, setSelected] = useState("SETTINGS");
  const [previewChecked, setPreviewChecked] = useState(false);

  return (
    <>
      <ul
        className={`nav nav-tabs ${TEXT_DIRECTION() === "rtl" ? "pr-0" : ""}`}
      >
        <li className="nav-item">
          <button
            onClick={() => setSelected("SETTINGS")}
            className={`nav-link ${selected === "SETTINGS" ? "active" : ""}`}
          >
            <I18n lng="global.Settings" />
          </button>
        </li>
        <li className="nav-item">
          <button
            onClick={() => {
              setSelected("PREVIEW");
              setPreviewChecked(true);
            }}
            className={`nav-link ${selected === "PREVIEW" ? "active" : ""}`}
          >
            <I18n lng="Form.Preview" />
          </button>
        </li>
      </ul>
      {selected === "SETTINGS" ? (
        <MultipleChoiceSettings
          updatedDuplicateForm={updatedDuplicateForm}
          setUpdatedDuplicateForm={setUpdatedDuplicateForm}
          updateQuestion={updateQuestion}
          actionItem={actionItem}
          question={
            actionItem && newQuestion && !previewChecked
              ? question
              : form.schema.pages[actionItem.pageNo].sections[
                  actionItem.sectionNo
                ].questions[actionItem.questionNo]
          }
          // question={question}
          mcqId={mcqId}
          form={form}
          setForm={setForm}
        />
      ) : (
        <MultipleChoicePreview
          actionItem={actionItem}
          question={
            form.schema.pages[actionItem.pageNo].sections[actionItem.sectionNo]
              .questions[actionItem.questionNo]
          }
          // question={question}
        />
      )}
    </>
  );
};

export default MultipleChoiceBuilder;
