import React, { useState, useEffect } from "react";
import moment from "moment";
import { v4 as uuid } from "uuid";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { IoIosCopy } from "react-icons/io";
import { cloneDeep, isEqual } from "lodash";
import { Link, useParams } from "react-router-dom";
import FormPreview from "./form-preview/form-preview";
import { getFileData } from "../../../../helper/image-helper";
import IconUndo from "../../../../assets/images/icon-undo.svg";
import IconRedo from "../../../../assets/images/icon-redo.svg";
import I18n, { i18n } from "../../../private/languageSelector";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { DEFAULT_FORM_IMAGE } from "../../../../config/constants";
import IconPencil from "../../../../assets/images/icon-pencil.svg";
import IconAdd from "../../../../assets/images/icon-form-add.svg";
import IconPreview from "../../../../assets/images/icon-preview.svg";
import IconDocBoard from "../../../../assets/images/icon-doc-board.svg";
import FormBuilderPage from "./form-builder-components/form-builder-page";
import AppHeader from "../../../../components/Layout/header";
import { getForms, uploadImage } from "../../../../redux/reducers/form/form.reducer";
import Checkbox from "../../../../components/form-input/checkbox.component";
import { updateRoute } from "../../../../redux/reducers/authentication/authentication";
import { getGlobalLists } from "../../../../redux/reducers/global-list/global-list.reducer";
import ResponsePicker from "./form-builder-components/form-builder-response-picker/form-builder-response-picker";
import ResponseSettings from "./form-builder-components/form-builder-response-settings/form-builder-response.settings";
import { updateMultipleChoiceById } from "../../../../redux/reducers/multiple-choice-questions/multiple-choice.reducer";
import { getAllMultipleChoiceQuestions } from "../../../../redux/reducers/multiple-choice-questions/multiple-choice.reducer";
import { getForm, saveFormIdInStore, patchForm, storeOldForm } from "../../../../redux/reducers/form/form.reducer";
import {
  ADD_PAGE,
  ADD_SECTION,
  COPY_HANDLER,
  PASTE_HANDLER,
  addQuestionByPasting,
  ACTIVE_ELEMENT_HANDLER,
  RETURN_LAST_USED_QUESTION,
  RETURN_LAST_USED_QUESTION_V1,
} from "../../../../helper/formHelperFunctions";
import { TEXT_DIRECTION } from "../../../../helper/helperFunctions";
import toast from "react-hot-toast";
import axios from "axios";

const FormBuilder = ({
  getForm,
  getForms,
  patchForm,
  updateRoute,
  uploadImage,
  getGlobalLists,
  updateMultipleChoiceById,
  allMultipleChoiceQuestions,
  saveFormIdInStore,
  getAllMultipleChoiceQuestions,
  storeOldForm,
}) => {
  const { id } = useParams();
  const { register } = useForm();
  const hiddenFileInput = React.useRef(null);
  const responseListDrawerRef = React.useRef(null);
  const questionOptionsDrawerRef = React.useRef(null);

  const [undoFormData, setUndoFormData] = useState([]);
  const [redoFormData, setRedoFormData] = useState([]);
  const [formHeadings, setFormHeadings] = useState({
    title: "",
    description: "",
  });

  const [undoLoading, setUndoLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(true);
  const [internalFormState, setInternalFormState] = useState();
  const [form, setForm] = useState({
    title: "Untitled Form",
    description: "",
    documentNumber: "00001",
    createdBy: "Nasser Ali",
    createdAt: "30/01/2021",
    image: "",
    schema: {
      isScored: false,
      pages: [],
    },
    selectedElement: {
      pageNo: undefined,
      sectionNo: undefined,
      questionNo: undefined,
    },
  });

  const [mcqID, setMcqID] = useState("");
  const [formImage, setFormImage] = useState();
  const [isActive, setActive] = useState(false);
  const [defaultMcq, setDefaultMcq] = useState({});
  const [actionItem, setActionItem] = useState(undefined);
  const [showFormPreview, setShowFormPreview] = useState(false);
  const [isTitleTextEditable, setIsTitleTextEditable] = useState(false);
  const [showResponseListDrawer, setShowResponseListDrawer] = useState(false);
  const [updatedDuplicateForm, setUpdatedDuplicateForm] = useState();

  const [newQuestionType, setNewQuestionType] = useState("COMPLEX_MULTIPLE_CHOICE");
  const [showQuestionOptionsDrawer, setShowQuestionOptionsDrawer] = useState(false);
  const [duplicateNewForm, setDuplicateNewForm] = useState();
  const defaultMcqRef = React.useRef(null);
  const selectedQuestionTypeRef = React.useRef(null);
  const selectedElementToCopyRef = React.useRef(null);
  useEffect(() => {
    if (id) {
      axios.get(`/forms/${id}`).then((response) => {
        storeOldForm(response?.data?.formDetail);
      });
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    updateRoute({
      step1: "Forms",
      step2: "Form Management",
      enableBack: true,
      formScrol: true,
    });
    getGlobalLists();
    setUndoFormData([]);
    setRedoFormData([]);
    setUndoLoading(false);
    setFormImage(undefined);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setForm({
      title: "Untitled Form",
      description: "",
      documentNumber: "00001",
      createdBy: "Nasser Ali",
      createdAt: "30/01/2021",
      schema: {
        isScored: false,
        pages: [],
      },
      selectedElement: {
        pageNo: undefined,
        sectionNo: undefined,
        questionNo: undefined,
      },
    });
    if (id) {
      getForm(id).then((res) => {
        setFormImage(res?.value?.data?.formDetail?.image);
        setFormHeadings({
          title: res?.value?.data?.formDetail?.title,
          description: res?.value?.data?.formDetail?.description,
        });
        setInternalFormState(res.value.data.formDetail);
        setForm({
          ...form,
          createdBy: res?.value?.data?.user?.fullName,
          createdAt: res?.value?.data?.createdAt,
          documentNumber: res?.value?.data?.documentNumber,
          ...res?.value?.data.formDetail,
        });
        setFormLoading(false);
      });
      getAllMultipleChoiceQuestions(id);
    }
    // eslint-disable-next-line
  }, [id]);
  console.log(localStorage.getItem("isEditOpen"), ";;;;;;;;;;;;;;;;;;;");
  useEffect(() => {
    if (allMultipleChoiceQuestions && allMultipleChoiceQuestions.length) {
      let mcqV1 = allMultipleChoiceQuestions.filter((item) => {
        return item.name === "MULTIPLE_CHOICE_RESPONSE_V1";
      });
      setDefaultMcq({ ...mcqV1[0] });
      defaultMcqRef.current = { ...mcqV1[0] };
    }
  }, [allMultipleChoiceQuestions, id]);

  const formRef = React.useRef(null);

  useEffect(() => {
    if (!undoLoading) {
      setRedoFormData([]);
    }

    if (!formLoading) {
      saveForm();
    }
    formRef.current = form;
    // eslint-disable-next-line
  }, [form, formLoading]);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = async (event) => {
    const fileData = await getFileData(event);
    let existingFileUrl = form.image ? form.image : DEFAULT_FORM_IMAGE;
    uploadImage({
      base64: fileData.fileUrl,
      existingFileUrl,
      fileType: fileData.fileType,
    }).then((response) => {
      setForm({
        ...form,
        image: response.value.data,
      });
      setFormImage(response.value.data);
      setRedoFormData([]);
    });
  };

  const addToUndoArray = (updatedForm) => {
    let testDataUpdated = cloneDeep(updatedForm);

    if (testDataUpdated?.schema?.pages?.length) {
      updatedForm?.schema?.pages?.map((item, pageIndex) => {
        return item?.sections?.map((secItem, secIndex) => {
          return secItem?.questions?.map((queItem, queIndex) => {
            return (testDataUpdated.schema.pages[pageIndex].sections[secIndex].questions[queIndex] =
              queItem?.settings?.questionText === ""
                ? {
                    ...queItem,
                    settings: {
                      ...queItem.settings,
                      questionText: "Add a new question",
                    },
                  }
                : { ...queItem });
          });
        });
      });
    }
    if (!undoLoading) {
      if (undoFormData.length) {
        // checking if data does not match the last object in undoFormData array
        let alreadyExists = isEqual(testDataUpdated, undoFormData[undoFormData?.length - 1]);

        // push if does not match
        if (!alreadyExists) {
          if (undoFormData.length < 40) {
            // if length is less than 40 keep adding data

            setUndoFormData([...cloneDeep(undoFormData), cloneDeep(updatedForm)]);
          } else {
            // if length is 40 than

            // remove oldest object
            undoFormData.shift();

            // add new object in undo array
            setUndoFormData([...cloneDeep(undoFormData), cloneDeep(updatedForm)]);
          }
        }
      } else {
        setUndoFormData([cloneDeep(updatedForm)]);
      }
    }
  };

  const saveForm = () => {
    let questionNumber = 0;
    let duplicateForm = { ...cloneDeep(form) };

    // adding numbers in questions sequentially
    if (form?.schema?.pages?.length) {
      form?.schema?.pages?.map((item, pageIndex) => {
        return item?.sections?.map((secItem, secIndex) => {
          return secItem?.questions?.map((queItem, queIndex) => {
            queItem?.type !== "instructions" && questionNumber++;
            return (duplicateForm.schema.pages[pageIndex].sections[secIndex].questions[queIndex] =
              queItem?.type !== "instructions" ? { ...queItem, questionNumber } : { ...queItem });
          });
        });
      });
    }

    setUndoLoading(false);
    addToUndoArray({
      ...cloneDeep(duplicateForm),
      selectedElement: duplicateForm?.selectedElement,
    });
    if (duplicateForm) {
      saveFormIdInStore(id, internalFormState, duplicateForm);
      patchForm(id, {
        ...duplicateForm,
        formState: localStorage.getItem("formState"),
      });
      setDuplicateNewForm(duplicateForm);
    }
  };
  const setSelectedElement = (pageNo, sectionNo, questionNo, dontUpdateUndo) => {
    if (dontUpdateUndo) {
      setUndoLoading(true);
    }
    setForm({
      ...form,
      selectedElement: {
        pageNo: pageNo,
        sectionNo: sectionNo,
        questionNo: questionNo,
      },
    });
  };

  const addQuestion = (pageNo, sectionNo, isPaste, questionText) => {
    if (
      !form.selectedElement ||
      (form.selectedElement.pageNo === undefined && form.selectedElement.sectionNo === undefined && form.selectedElement.questionNo === undefined)
    ) {
      if (form.schema.pages.length === 0) {
        form.schema.pages.push({
          name: "Page",
          isOpen: true,
          pagePlaceHolder: `Page ${form.schema.pages.length + 1}`,
          pageUuid: uuid(),
          sections: [
            {
              name: "Section",
              isOpen: true,
              sectionsPlaceHolder: "Section 1",
              sectionUuid: uuid(),
              questions: [],
            },
          ],
        });
      }
      //add question section
      if (!pageNo && !sectionNo) {
        pageNo = form.schema.pages.length - 1;
        if (form.schema.pages[pageNo].sections.length) {
          sectionNo = form.schema.pages[pageNo].sections.length - 1;
        } else {
          sectionNo = 0;
        }
        if (form.schema.pages[pageNo].sections[sectionNo] === undefined) {
          form.schema.pages[pageNo].sections.push({
            name: "Section",
            isOpen: true,
            sectionsPlaceHolder: `Section ${form.schema.pages[pageNo].sections.length + 1}`,
            sectionUuid: uuid(),
            questions: [],
          });
        }
        if (newQuestionType) {
          setNewQuestionType(newQuestionType);
          selectedQuestionTypeRef.current = newQuestionType;
        } else {
          setNewQuestionType("COMPLEX_MULTIPLE_CHOICE");
        }

        // Adding last question
        let lastSelectedQuestion = RETURN_LAST_USED_QUESTION(form, pageNo, sectionNo, newQuestionType, defaultMcq);

        if (isPaste !== undefined && questionText !== undefined && lastSelectedQuestion) {
          form.schema.pages[pageNo].sections[sectionNo].questions.push({
            ...lastSelectedQuestion,
            settings: {
              ...lastSelectedQuestion.settings,
              questionText: questionText,
            },
          });
        } else if (isPaste === undefined && questionText === undefined && lastSelectedQuestion) {
          form.schema.pages[pageNo].sections[sectionNo].questions.push({
            ...lastSelectedQuestion,
          });
        }
      }
      let questionNo =
        form?.selectedElement?.questionNo !== undefined
          ? form?.selectedElement?.questionNo + 1
          : form.schema.pages[pageNo].sections[sectionNo].questions.length - 1;

      closeAll(pageNo, sectionNo, questionNo);
    } else {
      if (form.selectedElement.sectionNo !== undefined) {
        if (newQuestionType) {
          setNewQuestionType(newQuestionType);
          selectedQuestionTypeRef.current = newQuestionType;
        } else {
          setNewQuestionType("COMPLEX_MULTIPLE_CHOICE");
        }

        let selectedQuestion = RETURN_LAST_USED_QUESTION(form, pageNo, sectionNo, newQuestionType, defaultMcq);
        if (isPaste !== undefined && questionText !== undefined && selectedQuestion) {
          form?.schema?.pages[form.selectedElement.pageNo]?.sections[form.selectedElement.sectionNo]?.questions?.splice(
            form?.selectedElement?.questionNo + 1,
            0,
            {
              ...selectedQuestion,
              settings: {
                ...selectedQuestion.settings,
                questionText: questionText,
              },
            }
          );
        } else if (isPaste === undefined && questionText === undefined && selectedQuestion) {
          form?.schema?.pages[form.selectedElement.pageNo]?.sections[form.selectedElement.sectionNo]?.questions?.splice(
            form?.selectedElement?.questionNo + 1,
            0,
            {
              ...selectedQuestion,
            }
          );
        }

        let questionNo =
          form?.selectedElement?.questionNo !== undefined
            ? form?.selectedElement?.questionNo + 1
            : form?.schema?.pages[form?.selectedElement?.pageNo]?.sections[form?.selectedElement?.sectionNo]?.questions?.length - 1;

        // ENDED
        closeAll(form.selectedElement.pageNo, form.selectedElement.sectionNo, questionNo);
      } else {
        // new page with no sections and questions
        if (
          form.schema.pages[form.selectedElement.pageNo]?.sections[form.schema.pages[form.selectedElement.pageNo]?.sections.length - 1] === undefined
        ) {
          form.schema.pages[form.selectedElement.pageNo].sections.push({
            name: "Section",
            isOpen: true,
            sectionsPlaceHolder: `Sections ${form.schema.pages[form.selectedElement.pageNo].sections.length + 1}`,
            sectionUuid: uuid(),
            questions: [],
          });
        }
        if (form.schema.pages[pageNo].sections.length) {
          sectionNo = form.schema.pages[pageNo].sections.length - 1;
        } else {
          sectionNo = 0;
        }
        if (newQuestionType) {
          setNewQuestionType(newQuestionType);
          selectedQuestionTypeRef.current = newQuestionType;
        } else {
          setNewQuestionType("COMPLEX_MULTIPLE_CHOICE");
        }

        let seletedPageQuestion = RETURN_LAST_USED_QUESTION_V1(form, sectionNo, newQuestionType, defaultMcq);

        if (isPaste !== undefined && questionText !== undefined && seletedPageQuestion) {
          form.schema.pages[form.selectedElement.pageNo].sections[form.schema.pages[form.selectedElement.pageNo].sections.length - 1].questions.push({
            ...seletedPageQuestion,
            settings: {
              ...seletedPageQuestion.settings,
              questionText: questionText,
            },
          });
        } else if (isPaste === undefined && questionText === undefined && seletedPageQuestion) {
          form.schema.pages[form.selectedElement.pageNo].sections[form.schema.pages[form.selectedElement.pageNo].sections.length - 1].questions.push({
            ...seletedPageQuestion,
          });
        }
        let questionNo =
          form?.selectedElement?.questionNo !== undefined
            ? form?.selectedElement?.questionNo + 1
            : form.schema.pages[form.selectedElement.pageNo].sections[form.schema.pages[form.selectedElement.pageNo].sections.length - 1].questions
                .length - 1;

        closeAll(form.selectedElement.pageNo, form.schema.pages[form.selectedElement.pageNo].sections.length - 1, questionNo);
      }
    }
  };

  const deleteQuestion = (pageNo, sectionNo, questionNo) => {
    form.schema.pages[pageNo].sections[sectionNo].questions = [
      ...form.schema.pages[pageNo].sections[sectionNo].questions.filter((item, index) => index !== questionNo),
    ];
    setForm({
      ...form,
      selectedElement: {
        pageNo: undefined,
        sectionNo: undefined,
        questionNo: undefined,
      },
    });
  };

  const deleteSection = (pageNo, sectionNo) => {
    form.schema.pages[pageNo].sections = [...form.schema.pages[pageNo].sections.filter((item, index) => index !== sectionNo)];
    setForm({
      ...form,
      selectedElement: {
        pageNo: undefined,
        sectionNo: undefined,
        questionNo: undefined,
      },
    });
  };

  const deletePage = (pageNo) => {
    form.schema.pages = [...form.schema.pages.filter((item, index) => index !== pageNo)];
    setForm({
      ...form,
      selectedElement: {
        pageNo: undefined,
        sectionNo: undefined,
        questionNo: undefined,
      },
    });
  };

  const onDragEnd = (result) => {
    if (result?.draggableId && result?.destination?.droppableId && result?.source?.droppableId) {
      if (result.draggableId.includes("question")) {
        const destination = result.destination;
        const source = result.source;
        const destinationIds = destination.droppableId.replace("page-section-question-", "").split("-");
        const destinationIndex = destination.index;
        const sourceIds = source.droppableId.replace("page-section-question-", "").split("-");
        const sourceIndex = source.index;

        const destinationPage = +destinationIds[0];
        const destinationSection = +destinationIds[1];
        const sourcePage = +sourceIds[0];
        const sourceSection = +sourceIds[1];
        const finalDestinationIndex = destinationIndex - destinationPage - destinationSection;

        const finalSourceIndex = sourceIndex - sourcePage - sourceSection;

        if (destinationIds[0] !== sourceIds[0] || destinationIds[1] !== sourceIds[1]) {
          if (destinationIds[0] === sourceIds[0] && destinationIds[1] !== sourceIds[1]) {
            let tempQuestion = form.schema.pages[sourcePage].sections[sourceSection].questions[finalSourceIndex];
            form.schema.pages[sourcePage].sections[sourceSection].questions.splice(finalSourceIndex, 1);
            form.schema.pages[destinationPage].sections[destinationSection].questions.splice(finalDestinationIndex, 0, {
              ...tempQuestion,
            });
            setForm({
              ...form,
              selectedElement: {
                ...form.selectedElement,
                questionNo: finalDestinationIndex,
              },
            });
          } else {
            let tempQuestion = form.schema.pages[sourcePage].sections[sourceSection].questions[finalSourceIndex];
            form.schema.pages[sourcePage].sections[sourceSection].questions.splice(finalSourceIndex, 1);
            form.schema.pages[destinationPage].sections[destinationSection].questions.splice(finalDestinationIndex, 0, {
              ...tempQuestion,
            });
            setForm({
              ...form,
              selectedElement: {
                ...form.selectedElement,
                questionNo: finalDestinationIndex,
              },
            });
          }
        } else {
          let tempQuestion = form.schema.pages[sourcePage].sections[sourceSection].questions[finalSourceIndex];
          form.schema.pages[sourcePage].sections[sourceSection].questions.splice(finalSourceIndex, 1);
          form.schema.pages[destinationPage].sections[destinationSection].questions.splice(finalDestinationIndex, 0, {
            ...tempQuestion,
          });
          setForm({
            ...form,
            selectedElement: {
              ...form.selectedElement,
              questionNo: finalDestinationIndex,
            },
          });
        }
      } else if (result.draggableId.includes("section")) {
        const destination = result.destination;
        const source = result.source;
        const destinationPage = +destination.droppableId.replace("page-section-", "");
        const sourcePage = +source.droppableId.replace("page-section-", "");
        const destinationSection = destination.index - destinationPage;
        const sourceSection = source.index - sourcePage;

        let tempSection = form.schema.pages[sourcePage].sections[sourceSection];
        form.schema.pages[sourcePage].sections.splice(sourceSection, 1);
        form.schema.pages[destinationPage].sections.splice(destinationSection, 0, { ...tempSection });
        setForm({ ...form });
      } else if (result.draggableId.includes("page")) {
        let tempPage = form.schema.pages[result.source.index];
        form.schema.pages.splice(result.source.index, 1);
        form.schema.pages.splice(result.destination.index, 0, { ...tempPage });
        setForm({ ...form });
      }
    }
  };

  const updatePage = (pageNo, page, dontUpdateUndo) => {
    if (dontUpdateUndo) {
      setUndoLoading(true);
    }
    form.schema.pages[pageNo] = {
      ...page,
    };
    setForm({ ...form });
  };

  const updateSection = (pageNo, sectionNo, section, dontUpdateUndo) => {
    if (dontUpdateUndo) {
      setUndoLoading(true);
    }
    form.schema.pages[pageNo].sections[sectionNo] = {
      ...section,
    };
    setForm({ ...form });
  };

  const updateQuestion = (pageNo, sectionNo, questionNo, question, dontUpdateUndo) => {
    if (dontUpdateUndo) {
      setUndoLoading(true);
    }
    setNewQuestionType(question?.type?.toUpperCase());
    selectedQuestionTypeRef.current = question?.type?.toUpperCase();
    form.schema.pages[pageNo].sections[sectionNo].questions[questionNo] = {
      ...question,
    };
    setForm({ ...form });
  };

  const closeAll = (targetPageNo, targetSectionNo, targetQuestionNo, dontUpdateUndo) => {
    form.schema.pages.forEach((page, pageIndex) => {
      form.schema.pages[pageIndex].sections.forEach((section, sectionIndex) => {
        form.schema.pages[pageIndex].sections[sectionIndex].questions.forEach((question, questionIndex) => {
          form.schema.pages[pageIndex].sections[sectionIndex].questions[questionIndex] = {
            ...question,
            settings: { ...question?.settings, isOpen: false },
          };
        });
      });
    });
    form.schema.pages[targetPageNo] = {
      ...form.schema.pages[targetPageNo],
      isOpen: true,
    };

    form.schema.pages[targetPageNo].sections[targetSectionNo] = {
      ...form.schema.pages[targetPageNo].sections[targetSectionNo],
      isOpen: true,
    };
    form.schema.pages[targetPageNo].sections[targetSectionNo].questions[targetQuestionNo] = {
      ...form.schema.pages[targetPageNo].sections[targetSectionNo].questions[targetQuestionNo],
      settings: {
        ...form.schema.pages[targetPageNo].sections[targetSectionNo].questions[targetQuestionNo]?.settings,
        isOpen: true,
      },
    };
    if (dontUpdateUndo) {
      setUndoLoading(true);
    }
    setForm({
      ...form,
      selectedElement: {
        ...form.selectedElement,
        questionNo: form.selectedElement.questionNo !== undefined ? form.selectedElement.questionNo + 1 : undefined,
      },
    });
  };

  useEffect(() => {
    // assigning value to ref bcz in eventListner the value remains initial state value
    responseListDrawerRef.current = showResponseListDrawer;
    questionOptionsDrawerRef.current = showQuestionOptionsDrawer;
  }, [showResponseListDrawer, showQuestionOptionsDrawer]);

  useEffect(() => {
    document.addEventListener("copy", () => {
      if (window.location.pathname.slice(0, -24) === "/forms/form-management/") {
        let text = window.getSelection().toString();
        // if no selected text is copied then copy undefined
        if (text === "") {
          navigator.clipboard.writeText("undefined");
        }
        COPY_HANDLER(formRef.current, selectedElementToCopyRef);
      }
    });

    document.addEventListener("paste", () => {
      navigator.clipboard
        .readText()
        .then((text) => {
          if (text?.length) {
            let isElementFocused = ACTIVE_ELEMENT_HANDLER(formRef);
            if (text === "undefined" && !isElementFocused) {
              // paste question, section and page
              PASTE_HANDLER(formRef.current, selectedElementToCopyRef, setForm);
            } else if (!isElementFocused && !responseListDrawerRef.current && !questionOptionsDrawerRef.current) {
              // paste copied text as questions
              let data = Array(text)[0].split("\n");
              let questions = [];
              data.forEach((item) => {
                return item.replace("\r", "").match(/^ *$/) === null && questions.push(item.replace("\r", ""));
              });

              questions.map((item, index) => {
                return item !== ""
                  ? addQuestionByPasting(
                      formRef.current,
                      formRef.current.selectedElement.pageNo,
                      formRef.current.selectedElement.sectionNo,
                      item,
                      newQuestionType,
                      setNewQuestionType,
                      defaultMcqRef.current,
                      setForm,
                      index + 1,
                      questions.length
                    )
                  : null;
              });
            }
          }
        })
        .catch((err) => toast.error(err.message));
    });
    // eslint-disable-next-line
  }, []);

  // const questionPasteHandler = () => {
  //   navigator.clipboard.readText().then((text) => {
  //     if (text?.length) {
  //       let data = Array(text)[0].split("\n");

  //       let questions = [];
  //       data.forEach((item) => {
  //         return (
  //           item.replace("\r", "").match(/^ *$/) === null &&
  //           questions.push(item.replace("\r", ""))
  //         );
  //       });

  //       questions.map((item, index) => {
  //         return item !== ""
  //           ? addQuestionByPasting(
  //               formRef.current,
  //               formRef.current.selectedElement.pageNo,
  //               formRef.current.selectedElement.sectionNo,
  //               item,
  //               newQuestionType,
  //               setNewQuestionType,
  //               defaultMcqRef.current,
  //               setForm,
  //               index + 1,
  //               questions.length
  //             )
  //           : null;
  //       });
  //     }
  //   });
  // };

  const undoFormHandler = () => {
    setUndoLoading(true);

    let duplicatUndoArray = [];
    duplicatUndoArray = [...cloneDeep(undoFormData)];

    if (duplicatUndoArray.length >= 1) {
      setRedoFormData([...cloneDeep([...redoFormData, duplicatUndoArray[duplicatUndoArray.length - 1]])]);
      duplicatUndoArray.splice(duplicatUndoArray.length - 1, 1);

      setForm({
        ...cloneDeep(duplicatUndoArray[duplicatUndoArray.length - 1]),
      });

      // handling form headings
      setFormHeadings({
        title: duplicatUndoArray[duplicatUndoArray.length - 1].title,
        description: duplicatUndoArray[duplicatUndoArray.length - 1].description,
      });

      // handling form image
      if (duplicatUndoArray[duplicatUndoArray.length - 1].image !== "") {
        setFormImage(duplicatUndoArray[duplicatUndoArray.length - 1].image);
      } else {
        setFormImage("");
      }
      setUndoFormData([...cloneDeep(duplicatUndoArray)]);
    }
  };

  const redoFormHandler = () => {
    setUndoLoading(true);

    let duplicateRedoArray = [];
    if (redoFormData.length >= 1) {
      duplicateRedoArray = [...cloneDeep(redoFormData)];
      setForm({
        ...cloneDeep(duplicateRedoArray[duplicateRedoArray.length - 1]),
      });

      // handling form headings
      setFormHeadings({
        title: duplicateRedoArray[duplicateRedoArray.length - 1].title,
        description: duplicateRedoArray[duplicateRedoArray.length - 1].description,
      });

      // handling form image
      if (duplicateRedoArray[duplicateRedoArray.length - 1].image !== "") {
        setFormImage(duplicateRedoArray[duplicateRedoArray.length - 1].image);
      } else {
        setFormImage("");
      }

      setUndoFormData([...cloneDeep(undoFormData), duplicateRedoArray[duplicateRedoArray.length - 1]]);

      duplicateRedoArray.splice(duplicateRedoArray.length - 1, 1);

      setRedoFormData([...cloneDeep(duplicateRedoArray)]);
    }
  };
  return (
    <>
      <div className="form-builder-wrapper w-100">
        <div className="row">
          <div className="col-lg-4 ">
            <div className="form-sider">
              <div className="form-actions d-flex px-3">
                <Link to="#" onClick={() => setShowFormPreview(true)}>
                  <img src={IconPreview} alt="IconPreview" />
                  <I18n lng="Form.Preview" />
                </Link>
                <div className={`form-undo-redo ${TEXT_DIRECTION() === "rtl" ? "mr-auto" : "ml-auto"}`}>
                  <Link
                    to="#"
                    className="mr-2"
                    onClick={(e) => {
                      e.preventDefault();
                      if (undoFormData.length > 1) {
                        undoFormHandler();
                      }
                    }}
                  >
                    <img style={{ filter: undoFormData.length > 1 ? "none" : "" }} src={IconUndo} alt="IconUndo" />
                    <span
                      className={`${TEXT_DIRECTION() === "rtl" ? "mr-1" : ""}`}
                      style={{
                        color: undoFormData.length > 1 ? "#20639b" : "",
                      }}
                    >
                      <I18n lng="Form.Undo" />
                    </span>
                  </Link>
                  <Link
                    to="#"
                    className={`mr-2 ${TEXT_DIRECTION() === "rtl" ? "ml-2" : ""}`}
                    onClick={(e) => {
                      e.preventDefault();
                      redoFormHandler();
                    }}
                  >
                    <img style={{ filter: redoFormData?.length ? "none" : "" }} src={IconRedo} alt="IconRedo" />
                    <span className={`${TEXT_DIRECTION() === "rtl" ? "mr-1" : ""}`} style={{ color: redoFormData?.length ? "#20639b" : "" }}>
                      <I18n lng="Form.Redo" />
                    </span>
                  </Link>

                  <Link
                    to="#"
                    className={`ml-1 ${TEXT_DIRECTION() === "rtl" ? "mr-1" : ""}`}
                    onClick={() => PASTE_HANDLER(formRef.current, selectedElementToCopyRef, setForm)}
                  >
                    <IoIosCopy /> <I18n lng="global.Paste" />
                  </Link>
                </div>
              </div>
              <div className="form-detail d-flex justify-content-between">
                <div
                  className="form-avatar"
                  style={formImage ? { backgroundImage: `url('${formImage}') !important` } : {}}
                  onClick={(e) => {
                    e.preventDefault();
                    handleClick();
                  }}
                >
                  {formImage ? <img src={formImage} alt="IconPencil" className={"form-img"} /> : null}
                  <div className="action-edit">
                    <img src={IconPencil} alt="IconPencil" />
                  </div>
                </div>
                <input
                  type="file"
                  style={{ display: "none" }}
                  ref={hiddenFileInput}
                  onChange={handleChange}
                  accept="image/png, image/gif, image/jpeg, application/pdf"
                />
                <div>
                  <p>
                    <I18n lng="Form.Document" />:
                    {form?.documentNumber?.toLocaleString("en-US", {
                      minimumIntegerDigits: 5,
                      useGrouping: false,
                    })}
                  </p>
                  <p>
                    <I18n lng="Form.Created" /> :{moment(form.createdAt).format("MM/DD/YYYY")}
                  </p>
                  <p>
                    <I18n lng="Form.By" /> : {form.createdBy}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="form-builder">
              <section className="form-block main">
                <div className="form-title">
                  <strong>
                    <img src={IconDocBoard} alt="IconDocBoard" />
                    {isTitleTextEditable ? (
                      <div
                        style={{
                          width: "calc(100% - 3rem)",
                        }}
                      >
                        <input
                          autoFocus
                          type="text"
                          id="form-name"
                          className="w-100"
                          style={{ all: "initial" }}
                          value={formHeadings.title}
                          placeholder={i18n("global.FormName")}
                          onChange={(e) => {
                            setFormHeadings({
                              ...formHeadings,
                              title: e.target.value,
                            });
                          }}
                          onBlur={() => {
                            setIsTitleTextEditable(false);
                            if (!formHeadings.title) {
                              setFormHeadings({
                                ...formHeadings,
                                title: `Untitled Form ${form.documentNumber}`,
                              });
                              setForm({
                                ...form,
                                title: `Untitled Form ${form.documentNumber}`,
                              });
                            } else {
                              setForm({
                                ...form,
                                title: formHeadings.title,
                              });
                            }
                          }}
                        />
                      </div>
                    ) : (
                      <span
                        onClick={() => {
                          setIsTitleTextEditable(true);
                          if (formHeadings.title?.includes("Untitled Form")) {
                            setFormHeadings({ ...formHeadings, title: "" });
                          }
                        }}
                      >
                        {formHeadings?.title.includes("Untitled Form") ? (
                          <>{formHeadings?.title.replace("Untitled Form", i18n("global.UntitledForm"))} </>
                        ) : (
                          formHeadings?.title
                        )}
                      </span>
                    )}
                  </strong>
                  <Checkbox
                    label={<I18n lng="Form.Score" />}
                    name="score"
                    register={register}
                    validations={{}}
                    checked={form.schema.isScored}
                    onChange={() => {
                      setForm({
                        ...form,
                        schema: {
                          ...form.schema,
                          isScored: !form.schema.isScored,
                        },
                      });
                    }}
                  />
                </div>
                <div className="input-group">
                  <textarea
                    rows="2"
                    id="form-description"
                    value={formHeadings.description}
                    placeholder={i18n("Form.AddDescription")}
                    onChange={(e) => {
                      setFormHeadings({
                        ...formHeadings,
                        description: e.target.value,
                      });
                    }}
                    onBlur={() => {
                      setForm({
                        ...form,
                        description: formHeadings.description,
                      });
                    }}
                  />
                </div>
              </section>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId={"page-dropable"} direction="vertical" type="page">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {form.schema.pages.map((item, index) => (
                        <FormBuilderPage
                          key={"page-" + index}
                          page={item}
                          pageIndex={index}
                          showResponseListDrawer={showResponseListDrawer}
                          setShowResponseListDrawer={setShowResponseListDrawer}
                          setShowQuestionOptionsDrawer={setShowQuestionOptionsDrawer}
                          sections={item?.sections}
                          setActionItem={setActionItem}
                          updateQuestion={updateQuestion}
                          deletePage={deletePage}
                          deleteSection={deleteSection}
                          deleteQuestion={deleteQuestion}
                          isScored={form.schema.isScored}
                          updatePage={updatePage}
                          updateSection={updateSection}
                          selectedElement={form.selectedElement}
                          setSelectedElement={setSelectedElement}
                          closeAll={closeAll}
                          setMcqID={setMcqID}
                          form={form}
                          addQuestion={addQuestion}
                          updateMultipleChoiceById={updateMultipleChoiceById}
                          getAllMultipleChoiceQuestions={getAllMultipleChoiceQuestions}
                          formId={id}
                        />
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </div>
        </div>
        <div className="btn-form-handler">
          {isActive ? (
            <div className={`btn-status-wrapper ${TEXT_DIRECTION() === "rtl" ? "direction-left" : ""}`}>
              <div
                className={`btn-status ${TEXT_DIRECTION() === "rtl" ? "btn-page-left" : "btn-page"}`}
                onClick={() => {
                  ADD_PAGE(form, setForm, defaultMcq, newQuestionType);
                }}
              >
                <span>
                  <I18n lng="Form.Page" />
                </span>
              </div>
              <div
                className={`btn-status  ${TEXT_DIRECTION() === "rtl" ? "btn-section-left" : "btn-section"}`}
                onClick={() => {
                  ADD_SECTION(form, setForm, defaultMcq, newQuestionType);
                }}
              >
                <span>
                  <I18n lng="Form.Section" />
                </span>
              </div>
              <div
                className={`btn-status  ${TEXT_DIRECTION() === "rtl" ? "btn-question-left" : "btn-question"}`}
                onClick={() => {
                  addQuestion(form?.selectedElement?.pageNo, form?.selectedElement?.sectionNo);
                }}
              >
                <span>
                  <I18n lng="Form.Question" />
                </span>
              </div>
            </div>
          ) : null}
          <button
            className={`btn btn-rounded btn-add-new-form ${TEXT_DIRECTION() === "rtl" ? "left-direction" : "right-direction"} ${
              !isActive ? "rotate-image-45" : ""
            }`}
            onClick={() => setActive(!isActive)}
          >
            <img src={IconAdd} alt="IconClose" />
          </button>
        </div>
      </div>
      <AppHeader id={id} newForm={duplicateNewForm} />
      <ResponsePicker
        showDlg={showResponseListDrawer}
        secondaryAction={() => setShowResponseListDrawer(false)}
        actionItem={actionItem}
        updateQuestion={updateQuestion}
        addQuestion={addQuestion}
        question={actionItem ? form?.schema?.pages[actionItem.pageNo]?.sections[actionItem.sectionNo]?.questions[actionItem.questionNo] : null}
        form={form}
        setForm={setForm}
      />
      <ResponseSettings
        updatedDuplicateForm={updatedDuplicateForm}
        setUpdatedDuplicateForm={setUpdatedDuplicateForm}
        showDlg={showQuestionOptionsDrawer}
        secondaryAction={() => setShowQuestionOptionsDrawer(false)}
        updateQuestion={updateQuestion}
        actionItem={actionItem}
        question={
          actionItem && actionItem !== undefined && actionItem !== null
            ? form?.schema?.pages[actionItem?.pageNo]?.sections[actionItem?.sectionNo]?.questions[actionItem?.questionNo]
            : null
        }
        mcqId={mcqID}
        form={form}
        setForm={setForm}
      />
      {form?.schema && (
        <FormPreview
          showDlg={showFormPreview}
          secondaryAction={() => setShowFormPreview(false)}
          title={form.title}
          description={form.description}
          schema={form?.schema}
          conductedOn={""}
          preparedBy={""}
          documentNo={""}
          hideSignature={true}
          formSchemaQuestions={{
            ...form,
            formDetail: { schema: form.schema },
            schema: undefined,
          }}
          view={true}
          preview={true}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ form, globalList, multipleChoiceQuestions }) => ({
  formToEdit: form.formToEdit,
  globalLists: globalList.globalLists,
  allMultipleChoiceQuestions: multipleChoiceQuestions.allMultipleChoiceQuestions,
});

const mapDispatchToProps = {
  getForm,
  getForms,
  patchForm,
  updateRoute,
  uploadImage,
  getGlobalLists,
  updateMultipleChoiceById,
  saveFormIdInStore,
  getAllMultipleChoiceQuestions,
  storeOldForm,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormBuilder);
