import React, { useState } from "react";
import MultipleChoicePdfPreview from "./multipleChoice-pdf-preview/multipleChoicePdfPreview";
import CheckboxPdfPreview from "./checkbox-pdf-Preview/checkboxPdfPreview";
import TextPdfPreview from "./text-pdf-preview/textPdfPReview";
import NumberPdfPreview from "./number-pdf-preview/numberPdfPreview";
import DatePdfPreview from "./date-Pdf-Preview/dataPdfPreview";
import ImagePdfPreview from "./image-pdf-preview/imagePdfPreview";
import PdfPreview from "./pdf-pdf-Preview/pdfPreview";
import InstructionPdfPreview from "./instruction-pdf-preview/instructionPdfPreview";
import LocationPdfPreview from "./location-pdf-preview/locationPdfPreview";
import SignaturePdfPreview from "./signature-pdf-preview/signaturePdfPreview";
import SliderPdfPreview from "./slider-pdf-preview/sliderPdfPreview";
import GlobalListPdfPreview from "./globalList-pdf-preview/globalListPdfPreview";
import ImageViewDialog from "../../../../components/dialogs/image-view-dialog";

export const getPreview = (
  type,
  question,
  getAnswer,
  setFailItems,
  failItems,
  failStatus,
  instructionHide,
  failure,
  index,
  hideAndShow,
  setImageIndex,
  setShowImageSrc,
  setShowImageDialog,
  setShowSection,
  setTotalAnswered,
  showDocumentMedia,
  imageCountData
) => {
  return type === "COMPLEX_MULTIPLE_CHOICE" ? (
    <MultipleChoicePdfPreview
      question={question}
      getAnswer={getAnswer}
      setFailItems={setFailItems}
      failItems={failItems}
      failStatus={failStatus}
      failure={failure}
      index={index}
      hideAndShow={hideAndShow}
      setImageIndex={setImageIndex}
      setShowImageSrc={setShowImageSrc}
      setShowImageDialog={setShowImageDialog}
      setShowSection={setShowSection}
      setTotalAnswered={setTotalAnswered}
      showDocumentMedia={showDocumentMedia}
      imageCountData={imageCountData}
    />
  ) : type === "text" ? (
    <TextPdfPreview
      question={question}
      getAnswer={getAnswer}
      failItems={failItems}
      failStatus={failStatus}
      failure={failure}
      index={index}
      hideAndShow={hideAndShow}
      setImageIndex={setImageIndex}
      setShowImageSrc={setShowImageSrc}
      setShowImageDialog={setShowImageDialog}
      setShowSection={setShowSection}
      setTotalAnswered={setTotalAnswered}
      showDocumentMedia={showDocumentMedia}
      imageCountData={imageCountData}
    />
  ) : type === "number" ? (
    <NumberPdfPreview
      question={question}
      getAnswer={getAnswer}
      setFailItems={setFailItems}
      failItems={failItems}
      failStatus={failStatus}
      failure={failure}
      index={index}
      hideAndShow={hideAndShow}
      setImageIndex={setImageIndex}
      setShowImageSrc={setShowImageSrc}
      setShowImageDialog={setShowImageDialog}
      setShowSection={setShowSection}
      setTotalAnswered={setTotalAnswered}
      showDocumentMedia={showDocumentMedia}
      imageCountData={imageCountData}
    />
  ) : type === "checkbox" ? (
    <CheckboxPdfPreview
      question={question}
      getAnswer={getAnswer}
      failItems={failItems}
      failStatus={failStatus}
      index={index}
      hideAndShow={hideAndShow}
      setImageIndex={setImageIndex}
      setShowImageSrc={setShowImageSrc}
      setShowImageDialog={setShowImageDialog}
      setShowSection={setShowSection}
      setTotalAnswered={setTotalAnswered}
      showDocumentMedia={showDocumentMedia}
      imageCountData={imageCountData}
    />
  ) : type === "date time" ? (
    <DatePdfPreview
      question={question}
      getAnswer={getAnswer}
      failItems={failItems}
      failStatus={failStatus}
      index={index}
      hideAndShow={hideAndShow}
      setImageIndex={setImageIndex}
      setShowImageSrc={setShowImageSrc}
      setShowImageDialog={setShowImageDialog}
      setShowSection={setShowSection}
      setTotalAnswered={setTotalAnswered}
      showDocumentMedia={showDocumentMedia}
      imageCountData={imageCountData}
    />
  ) : type === "image" ? (
    <ImagePdfPreview
      question={question}
      getAnswer={getAnswer}
      failItems={failItems}
      failStatus={failStatus}
      index={index}
      hideAndShow={hideAndShow}
      setImageIndex={setImageIndex}
      setShowImageSrc={setShowImageSrc}
      setShowImageDialog={setShowImageDialog}
      setShowSection={setShowSection}
      setTotalAnswered={setTotalAnswered}
      showDocumentMedia={showDocumentMedia}
      imageCountData={imageCountData}
    />
  ) : type === "pdf" ? (
    <PdfPreview
      question={question}
      getAnswer={getAnswer}
      failItems={failItems}
      failStatus={failStatus}
      index={index}
      hideAndShow={hideAndShow}
      setImageIndex={setImageIndex}
      setShowImageSrc={setShowImageSrc}
      setShowImageDialog={setShowImageDialog}
      setShowSection={setShowSection}
      setTotalAnswered={setTotalAnswered}
      showDocumentMedia={showDocumentMedia}
      imageCountData={imageCountData}
    />
  ) : type === "instructions" ? (
    <InstructionPdfPreview
      question={question}
      getAnswer={getAnswer}
      failItems={failItems}
      failStatus={failStatus}
      instructionHide={instructionHide}
      index={index}
      hideAndShow={hideAndShow}
      setImageIndex={setImageIndex}
      setShowImageSrc={setShowImageSrc}
      setShowImageDialog={setShowImageDialog}
      setShowSection={setShowSection}
      setTotalAnswered={setTotalAnswered}
      showDocumentMedia={showDocumentMedia}
    />
  ) : type === "location" ? (
    <LocationPdfPreview
      question={question}
      getAnswer={getAnswer}
      failItems={failItems}
      failStatus={failStatus}
      index={index}
      hideAndShow={hideAndShow}
      setImageIndex={setImageIndex}
      setShowImageSrc={setShowImageSrc}
      setShowImageDialog={setShowImageDialog}
      setShowSection={setShowSection}
      setTotalAnswered={setTotalAnswered}
      showDocumentMedia={showDocumentMedia}
      imageCountData={imageCountData}
    />
  ) : type === "signature" ? (
    <SignaturePdfPreview
      question={question}
      getAnswer={getAnswer}
      failItems={failItems}
      failStatus={failStatus}
      index={index}
      hideAndShow={hideAndShow}
      setImageIndex={setImageIndex}
      setShowImageSrc={setShowImageSrc}
      setShowImageDialog={setShowImageDialog}
      setShowSection={setShowSection}
      setTotalAnswered={setTotalAnswered}
      showDocumentMedia={showDocumentMedia}
      imageCountData={imageCountData}
    />
  ) : type === "slider" ? (
    <SliderPdfPreview
      question={question}
      getAnswer={getAnswer}
      failItems={failItems}
      failStatus={failStatus}
      index={index}
      hideAndShow={hideAndShow}
      setImageIndex={setImageIndex}
      setShowImageSrc={setShowImageSrc}
      setShowImageDialog={setShowImageDialog}
      setShowSection={setShowSection}
      setTotalAnswered={setTotalAnswered}
      showDocumentMedia={showDocumentMedia}
      imageCountData={imageCountData}
    />
  ) : type === "DROPDOWN" ? (
    <GlobalListPdfPreview
      question={question}
      getAnswer={getAnswer}
      failItems={failItems}
      failStatus={failStatus}
      index={index}
      hideAndShow={hideAndShow}
      setImageIndex={setImageIndex}
      setShowImageSrc={setShowImageSrc}
      setShowImageDialog={setShowImageDialog}
      setShowSection={setShowSection}
      setTotalAnswered={setTotalAnswered}
      showDocumentMedia={showDocumentMedia}
      imageCountData={imageCountData}
    />
  ) : (
    ""
  );
};
const QuestionPdfPreview = ({
  question,
  getAnswer,
  setFailItems,
  failItems,
  failStatus,
  instructionHide,
  failure,
  index,
  hideAndShow,
  setShowSection,
  setTotalAnswered,
  showDocumentMedia,
  imageCountData,
}) => {
  const [imageIndex, setImageIndex] = useState(0);
  const [showImageSrc, setShowImageSrc] = useState(false);
  const [showImageDialog, setShowImageDialog] = useState(false);

  return (
    <>
      {getPreview(
        question?.type,
        question,
        getAnswer,
        setFailItems,
        failItems,
        failStatus,
        instructionHide,
        failure,
        index,
        hideAndShow,
        setImageIndex,
        setShowImageSrc,
        setShowImageDialog,
        setShowSection,
        setTotalAnswered,
        showDocumentMedia,
        imageCountData
      )}
      <ImageViewDialog
        imageSrc={showImageSrc}
        imageIndex={imageIndex}
        showDlg={showImageDialog && hideAndShow?.content?.photos}
        secondaryAction={() => setShowImageDialog(false)}
      />
    </>
  );
};
export default QuestionPdfPreview;
