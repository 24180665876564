import React, { useState, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import { PDFViewer } from "react-view-pdf";
import { FaDownload } from "react-icons/fa";
import { AiFillPrinter } from "react-icons/ai";
import { BsInfoCircleFill } from "react-icons/bs";
import I18n from "../../../../../languageSelector";
import { getBase64FromFile } from "../../../../../../../helper/image-helper";
// import useDownloader from "react-use-downloader";

const InstructionsPreview = ({ question, actionItem }) => {
  const [showPDf, setShowPDf] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [imageValue] = useState("");
  const [setShowImageSrc] = useState(false);
  const [questionPreview, setQuestionPreview] = useState(question);
  const [setShowImageDialog] = useState(false);

  // // const { download } = useDownloader();
  // const fileUrl = question.settings.selectedFile;
  // const filename = question.settings.selectedFileName;

  // console.log(filename, fileUrl);

  useEffect(() => {
    if (question) {
      setQuestionPreview(question);
    }
  }, [question]);

  const printPdf = async (url) => {
    const newFile = await fetch(url)
      .then((res) => res.blob())
      .then((blob) => {
        return new File([blob], "Pdf", { type: "application/pdf" });
      });

    const dataUrl = await getBase64FromFile(newFile);
    var winparams =
      "dependent=yes,locationbar=no,scrollbars=yes,menubar=yes," +
      "resizable,screenX=50,screenY=50,width=850,height=1050";

    var htmlPop =
      "<embed width=100% height=100%" +
      ' type="application/pdf"' +
      ' src="' +
      dataUrl.fileUrl +
      '"></embed>';

    var printWindow = window.open("", "PDF", winparams);
    printWindow.document.write(htmlPop);
    printWindow.print();
  };

  useEffect(() => {
    if (question.settings.selectedFile?.image) {
      setShowImage(question.settings?.selectedFile?.image ? true : false);
    } else {
      setShowPDf(question.settings.selectedFile ? true : false);
    }
    // eslint-disable-next-line
  }, [question.settings.selectedFile]);

  // const imageBase64 = async (url) => {
  //   const newFile = await fetch(url)
  //     .then((res) => res.blob())
  //     .then((blob) => {
  //       return new File([blob], `Image`, {
  //         type: `image/${
  //           question.settings.selectedFile?.split("/")[3]?.split(".")[1]
  //         }`,
  //       });
  //     });

  //   const dataUrl = await getBase64FromFile(newFile);
  //   if (dataUrl && dataUrl.fileUrl !== null) {
  //     setImageValue(
  //       dataUrl.fileUrl.split("/")[1].split(";")[0] !== "undefined"
  //         ? dataUrl.fileUrl
  //         : ""
  //     );
  //   }
  // };
  return (
    <div id={question?.questionUuid} className="multiple-choice">
      <div className="instruction-preview">
        <p className="static-preview ml-2">
          <I18n lng="Form.Instructions" />
        </p>
        <div className="static-priview-text">
          {questionPreview?.settings.questionText === "Type Instructions" ||
          questionPreview?.settings.questionText === "Enter Instructions" ? (
            <I18n lng="Form.TypeInstructions" />
          ) : questionPreview?.settings.questionText ===
            "Add a new question" ? (
            <>
              {question?.questionNumber}.{"   "}
              <I18n lng="Form.AddANewQuestion" />{" "}
            </>
          ) : (
            ` ${questionPreview?.settings.questionText}`
          )}
        </div>

        {question?.settings?.photo ? (
          <img src={question.settings.photo.image} alt="Attachment Preview" />
        ) : (
          ""
        )}
      </div>
      <div className="d-flex justify-content-between question-title  h-auto">
        <div className="d-flex">
          {question.settings.description ? (
            <>
              <p
                data-for={`${question.settings.questionText}`}
                data-tip={`${question.settings.description}`}
                data-iscapture="true"
              >
                <BsInfoCircleFill color={"C4C4C4"} />
              </p>
              <ReactTooltip
                id={`${question.settings.questionText}`}
                place="top"
                type="dark"
                effect="float"
                multiline={true}
              />
            </>
          ) : (
            ""
          )}
        </div>
      </div>
      {imageValue && imageValue !== "" ? (
        <div className="row justify-content-center">
          <div className="attachment-preview">
            <div className="img-wrap">
              <img
                onClick={(e) => {
                  setShowImageSrc(questionPreview?.settings?.selectedFile);
                  setShowImageDialog(true);
                }}
                src={imageValue}
                alt="Attachment Preview"
              />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {showPDf && question.settings.selectedFile !== null ? (
        <div className="preview-wrapper preview-pdf">
          <div className="actions">
            <a
              className="action action-download"
              title="Download"
              // onClick={() => download(fileUrl, filename)}
              href={question.settings.selectedFile}
            >
              <FaDownload />
            </a>
            <div
              className="action action-print"
              title="Print"
              id="divcontents"
              onClick={() => printPdf(question.settings.selectedFile)}
            >
              <AiFillPrinter size={"1.2rem"} />
            </div>
          </div>

          <PDFViewer
            url={
              question.settings.selectedFile
                ? question.settings.selectedFile
                : ""
            }
          />
        </div>
      ) : null}
      {/* <ImageViewDialog
        imageSrc={showImageSrc}
        showDlg={showImageDialog}
        secondaryAction={() => setShowImageDialog(false)}
      /> */}
      {showImage ? (
        <div className="row justify-content-center">
          <img
            style={{ width: "200px", height: "200px" }}
            src={question?.settings?.selectedFile?.image}
            alt=""
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default InstructionsPreview;
