import React, { useEffect, useState } from "react";
import { Collapse } from "reactstrap";
import QuestionPdfPreview from "./questionPdfPreview";
import IconArrowDown from "../../../../assets/images/arrowDown.svg";
import { i18n } from "../../languageSelector";

const SectionPdfPreview = ({
  section,
  index,
  getAnswer,
  setFailItems,
  failItems,
  failStatus,
  viewPageSections,
  instructionHide,
  hideAndShow,
  setShowPage,
  showDocumentMedia,
  imageCountData,
}) => {
  const [showSection, setShowSection] = useState(false);
  const [totalAnswered, setTotalAnswered] = useState(0);
  const [reportSectionCollapsed, setReportSectionCollapsed] = useState(true);

  useEffect(() => {
    if (hideAndShow?.content?.all) {
      setShowPage(true);
    } else if (hideAndShow?.content?.answered && showSection) {
      setShowPage(true);
    } else if (
      hideAndShow?.content?.unAnswered &&
      section?.questions?.length !== totalAnswered
    ) {
      setShowPage(true);
    } else {
      setShowPage(false);
    }
    // eslint-disable-next-line
  }, [showSection]);

  return (
    <>
      {failStatus && !viewPageSections ? null : hideAndShow?.content?.all ? (
        <div className="table-header" id={section?.sectionUuid}>
          <div className="table-row ">
            <div className="section-header">
              <div
                className="table-head d-flex justify-content-between "
                style={{
                  marginTop: "15px",
                  marginBottom: "0px",
                  padding: ".75rem 1.15rem",
                }}
              >
                <label>
                  <b>
                    {section.name === "Section"
                      ? `${i18n("Form.Section")} ${index + 1}`
                      : section.name}
                  </b>
                </label>
                <div
                  onClick={() => {
                    reportSectionCollapsed && reportSectionCollapsed === true
                      ? setReportSectionCollapsed(false)
                      : setReportSectionCollapsed(true);
                  }}
                  className="text-right align-end "
                >
                  <img
                    style={
                      reportSectionCollapsed === true
                        ? {
                            margin: "0 15px 5px 15px",
                            width: "25px",
                            cursor: "pointer",
                          }
                        : {
                            margin: "0 15px 5px 15px",
                            width: "25px",
                            cursor: "pointer",
                            rotate: "180deg",
                          }
                    }
                    src={IconArrowDown}
                    alt="arrow Down"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : hideAndShow?.content?.unAnswered &&
        section?.questions?.length !== totalAnswered ? (
        <div className="table-header" id={section?.sectionUuid}>
          <div className="table-row ">
            <div className="section-header">
              <div
                className="table-head d-flex justify-content-between "
                style={{
                  marginTop: "15px",
                  marginBottom: "0px",
                  padding: ".75rem 1.15rem",
                }}
              >
                <label>
                  <b>
                    {section.name === "Section"
                      ? `${i18n("Form.Section")} ${index + 1}`
                      : section.name}
                  </b>
                </label>
                <div
                  onClick={() => {
                    reportSectionCollapsed && reportSectionCollapsed === true
                      ? setReportSectionCollapsed(false)
                      : setReportSectionCollapsed(true);
                  }}
                  className="text-right align-end "
                >
                  <img
                    style={
                      reportSectionCollapsed === true
                        ? {
                            margin: "0 15px 5px 15px",
                            width: "25px",
                            cursor: "pointer",
                          }
                        : {
                            margin: "0 15px 5px 15px",
                            width: "25px",
                            cursor: "pointer",
                            rotate: "180deg",
                          }
                    }
                    src={IconArrowDown}
                    alt="arrow Down"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : hideAndShow?.content?.answered && showSection ? (
        <div className="table-header" id={section?.sectionUuid}>
          <div className="table-row ">
            <div className="section-header">
              <div
                className="table-head d-flex justify-content-between "
                style={{
                  marginTop: "15px",
                  marginBottom: "0px",
                  padding: ".75rem 1.15rem",
                }}
              >
                <label>
                  <b>
                    {section.name === "Section"
                      ? `${i18n("Form.Section")} ${index + 1}`
                      : section.name}
                  </b>
                </label>
                <div
                  onClick={() => {
                    reportSectionCollapsed && reportSectionCollapsed === true
                      ? setReportSectionCollapsed(false)
                      : setReportSectionCollapsed(true);
                  }}
                  className="text-right align-end "
                >
                  <img
                    style={
                      reportSectionCollapsed === true
                        ? {
                            margin: "0 15px 5px 15px",
                            width: "25px",
                            cursor: "pointer",
                          }
                        : {
                            margin: "0 15px 5px 15px",
                            width: "25px",
                            cursor: "pointer",
                            rotate: "180deg",
                          }
                    }
                    src={IconArrowDown}
                    alt="arrow Down"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {section.questions && section.questions.length
        ? section.questions.map((question, index) => {
            return (
              <Collapse isOpen={reportSectionCollapsed}>
                <QuestionPdfPreview
                  key={index}
                  index={index}
                  question={question}
                  getAnswer={getAnswer}
                  setFailItems={setFailItems}
                  failItems={failItems}
                  failStatus={failStatus}
                  instructionHide={instructionHide}
                  hideAndShow={hideAndShow}
                  setShowSection={setShowSection}
                  setTotalAnswered={setTotalAnswered}
                  showDocumentMedia={showDocumentMedia}
                  imageCountData={imageCountData}
                />
              </Collapse>
            );
          })
        : null}
    </>
  );
};
export default SectionPdfPreview;
