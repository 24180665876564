import React, { useState, useEffect } from "react";
import I18n from "../../../languageSelector";
import { FileIcon, defaultStyles } from "react-file-icon";
import PdfViewDialog from "../../../../../components/dialogs/pdf-view-dialog";
import ReportPdfImages from "../report-pdf-images/reportPdfImages.js";
import { TEXT_DIRECTION } from "../../../../../helper/helperFunctions";

const PdfPreview = ({
  getAnswer = (uuid) => {
    console.log(uuid);
    return undefined;
  },
  question,
  failStatus,
  hideAndShow,
  setImageIndex,
  setShowImageSrc,
  setShowImageDialog,
  setShowSection,
  setTotalAnswered,
  showDocumentMedia,
  imageCountData,
}) => {
  const [answerValue, setAnswerValue] = useState("");
  const [note, setNote] = useState("");
  const [photo, setPhoto] = useState("");
  const [pdfFileUrl, setPdfFileUrl] = useState("");
  const [showPdfViewDialog, setShowPdfViewDialog] = useState(false);

  useEffect(() => {
    if (question && question.questionUuid) {
      let answer = getAnswer(question.questionUuid);
      if (answer) {
        setAnswerValue(answer.pdfPreview);
        setPdfFileUrl(answer.pdfFileUrl);
        setNote(answer.note);
        setPhoto(answer.image);

        if (answer?.pdfPreview || answer?.note || answer?.image) {
          setShowSection(true);
          setTotalAnswered((prev) => prev + 1);
        }
      }
    }
    // eslint-disable-next-line
  }, [question]);

  const [imageCount, setImageCount] = useState(0);

  useEffect(() => {
    if (question && question.questionUuid && imageCountData?.length) {
      let data = imageCountData.filter((item) => {
        return item.questionId === question.questionUuid;
      });
      if (data.length) {
        setImageCount(data[0].imageCountStart);
      }
    }
  }, [question, imageCountData]);
  return (
    <>
      {!failStatus ? (
        hideAndShow?.content?.all ? (
          <div className="table-body">
            <div
              className="row table-row ml-0 mr-0 question status-na"
              style={{
                boxShadow: "0px 5px 5px rgba(0,0,0,.10)",
                backgroundColor: "#ffffff",
              }}
            >
              <div
                className={`col-lg-8 col-md-6 mb-3 table-column d-flex ${
                  TEXT_DIRECTION() === "rtl" ? "pr-0" : "pl-0"
                }`}
              >
                <span
                  className={`text-muted ${
                    TEXT_DIRECTION() === "rtl" ? "ml-2" : "mr-2"
                  }`}
                >
                  {question?.questionNumber}.
                </span>
                {"   "}
                {question?.settings?.questionText === "Add a new question" ? (
                  <I18n lng="Form.AddANewQuestion" />
                ) : (
                  question?.settings?.questionText
                )}
              </div>
              {answerValue ? (
                <div
                  className="table-column col-lg-4 col-md-6 question-type text-primary pdf-type"
                  style={{ padding: "0 23px" }}
                >
                  <div className="attachment-wrap">
                    {!showDocumentMedia ? (
                      <React.Fragment>
                        {TEXT_DIRECTION() === "rtl" ? (
                          <>
                            <span className=" align-items-center d-flex text-center">
                              <p
                                id="pdf-button"
                                className="cursor-pointer mx-2 align-items-center d-flex text-center"
                                onClick={() => setShowPdfViewDialog(true)}
                              >
                                {answerValue ? answerValue : ""}
                              </p>
                            </span>
                            <span className="icon-wrap">
                              <FileIcon
                                extension="pdf"
                                {...defaultStyles.pdf}
                              />
                            </span>
                          </>
                        ) : (
                          <>
                            <span className="icon-wrap">
                              <FileIcon
                                extension="pdf"
                                {...defaultStyles.pdf}
                              />
                            </span>
                            <span className="t align-items-center d-flex text-center">
                              <p
                                id="pdf-button"
                                className="cursor-pointer mx-2 align-items-center d-flex text-center"
                                onClick={() => setShowPdfViewDialog(true)}
                              >
                                {answerValue ? answerValue : ""}
                              </p>
                            </span>
                          </>
                        )}
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <span className="icon-wrap">
                          <a href={pdfFileUrl}>
                            <FileIcon extension="pdf" {...defaultStyles.pdf} />
                          </a>
                        </span>
                        <a href={pdfFileUrl} style={{ textDecoration: "none" }}>
                          <span className=" align-items-center d-flex text-center">
                            <p
                              id="pdf-button"
                              className="cursor-pointer"
                              onClick={() => setShowPdfViewDialog(true)}
                            >
                              {answerValue ? answerValue : ""}
                            </p>
                          </span>
                        </a>
                      </React.Fragment>
                    )}
                  </div>
                  {note || photo.length ? (
                    <div
                      className="table-column attachments-wrapper pb-3"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <>
                        <fieldset>
                          <legend
                            className={`${
                              TEXT_DIRECTION() === "rtl"
                                ? "legend-arabic"
                                : "legend"
                            }`}
                          >
                            <I18n lng="global.InspectorNotes" />
                          </legend>
                          {note ? (
                            <div className="notes">
                              <p>{note}</p>
                            </div>
                          ) : null}
                          {photo.length ? (
                            <div className="thumbnail-wrapper">
                              <ReportPdfImages
                                photo={photo}
                                imageCount={imageCount}
                                setImageIndex={setImageIndex}
                                setShowImageSrc={setShowImageSrc}
                                showDocumentMedia={showDocumentMedia}
                                setShowImageDialog={setShowImageDialog}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </fieldset>
                      </>
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
        ) : hideAndShow?.content?.answered &&
          (answerValue || note || photo?.length) ? (
          <div className="table-body">
            <div
              className="row ml-0 mr-0 table-row question status-na"
              style={{
                boxShadow: "0px 5px 5px rgba(0,0,0,.10)",
                backgroundColor: "#ffffff",
              }}
            >
              <div className="col-lg-8 col-md-6 table-column">
                <span
                  className={`text-muted ${
                    TEXT_DIRECTION() === "rtl" ? "ml-2" : "mr-2"
                  }`}
                >
                  {question?.questionNumber}.
                </span>
                {"   "}
                {question?.settings?.questionText === "Add a new question" ? (
                  <I18n lng="Form.AddANewQuestion" />
                ) : (
                  question?.settings?.questionText
                )}
              </div>
              <div
                className="col-lg-4 col-md-6 table-column question-type text-primary pdf-type"
                style={{ padding: "0 23px" }}
              >
                <div className="attachment-wrap">
                  {!showDocumentMedia ? (
                    <React.Fragment>
                      <span className="icon-wrap">
                        <FileIcon extension="pdf" {...defaultStyles.pdf} />
                      </span>
                      <span>
                        <p
                          id="pdf-button"
                          className="cursor-pointer"
                          onClick={() => setShowPdfViewDialog(true)}
                        >
                          {answerValue ? answerValue : ""}
                        </p>
                      </span>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <span className="icon-wrap">
                        <a href={pdfFileUrl}>
                          <FileIcon extension="pdf" {...defaultStyles.pdf} />
                        </a>
                      </span>
                      <a href={pdfFileUrl} style={{ textDecoration: "none" }}>
                        <span>
                          <p
                            id="pdf-button"
                            className="cursor-pointer"
                            onClick={() => setShowPdfViewDialog(true)}
                          >
                            {answerValue ? answerValue : ""}
                          </p>
                        </span>
                      </a>
                    </React.Fragment>
                  )}
                </div>
                {note || photo.length ? (
                  <div
                    className="table-column attachments-wrapper pb-3"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <>
                      <fieldset>
                        <legend
                          className={`${
                            TEXT_DIRECTION() === "rtl"
                              ? "legend-arabic"
                              : "legend"
                          }`}
                        >
                          <I18n lng="global.InspectorNotes" />
                        </legend>
                        {note ? (
                          <div className="notes">
                            <p>{note}</p>
                          </div>
                        ) : null}
                        {photo.length ? (
                          <div className="thumbnail-wrapper">
                            <ReportPdfImages
                              photo={photo}
                              imageCount={imageCount}
                              setImageIndex={setImageIndex}
                              setShowImageSrc={setShowImageSrc}
                              showDocumentMedia={showDocumentMedia}
                              setShowImageDialog={setShowImageDialog}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </fieldset>
                    </>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        ) : hideAndShow?.content?.unAnswered &&
          !answerValue?.length &&
          !note &&
          !photo?.length ? (
          <div className="table-body">
            <div
              className="row table-row ml-0 mr-0   question status-na"
              style={{
                boxShadow: "0px 5px 5px rgba(0,0,0,.10)",
                backgroundColor: "#ffffff",
              }}
            >
              <div
                className={`col-lg-8 col-md-6 mb-3 table-column d-flex ${
                  TEXT_DIRECTION() === "rtl" ? "pr-0" : "pl-0"
                }`}
              >
                <span
                  className={`text-muted ${
                    TEXT_DIRECTION() === "rtl" ? "ml-2" : "mr-2"
                  }`}
                >
                  {question?.questionNumber}.
                </span>
                {"   "}

                {question?.settings?.questionText === "Add a new question" ? (
                  <I18n lng="Form.AddANewQuestion" />
                ) : (
                  question?.settings?.questionText
                )}
              </div>
              <div
                className="col-lg-4 col-md-6 table-column question-type text-primary pdf-type"
                style={{ padding: "0 23px" }}
              >
                <div className="attachment-wrap">
                  {!showDocumentMedia ? (
                    <React.Fragment>
                      <span className="icon-wrap">
                        <FileIcon extension="pdf" {...defaultStyles.pdf} />
                      </span>
                      <span>
                        <p
                          id="pdf-button"
                          className="cursor-pointer"
                          onClick={() => setShowPdfViewDialog(true)}
                        >
                          {answerValue ? answerValue : ""}
                        </p>
                      </span>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <span className="icon-wrap">
                        <a href={pdfFileUrl}>
                          <FileIcon extension="pdf" {...defaultStyles.pdf} />
                        </a>
                      </span>
                      <a href={pdfFileUrl} style={{ textDecoration: "none" }}>
                        <span>
                          <p
                            id="pdf-button"
                            className="cursor-pointer"
                            onClick={() => setShowPdfViewDialog(true)}
                          >
                            {answerValue ? answerValue : ""}
                          </p>
                        </span>
                      </a>
                    </React.Fragment>
                  )}
                </div>
                {note || photo.length ? (
                  <div
                    className="table-column attachments-wrapper pb-3 mt-3"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <>
                      <fieldset>
                        <legend
                          className={`${
                            TEXT_DIRECTION() === "rtl"
                              ? "legend-arabic"
                              : "legend"
                          }`}
                        >
                          <I18n lng="global.InspectorNotes" />
                        </legend>
                        {note ? (
                          <div className="notes">
                            {/* <label>
                      <I18n lng="global.Notes" />:
                    </label> */}
                            <p>{note}</p>
                          </div>
                        ) : null}
                        {photo.length ? (
                          <div className="thumbnail-wrapper">
                            <ReportPdfImages
                              photo={photo}
                              imageCount={imageCount}
                              setImageIndex={setImageIndex}
                              setShowImageSrc={setShowImageSrc}
                              showDocumentMedia={showDocumentMedia}
                              setShowImageDialog={setShowImageDialog}
                            />
                          </div>
                        ) : null}
                      </fieldset>
                    </>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        ) : null
      ) : null}
      <PdfViewDialog
        answerValue={answerValue}
        pdfUrl={pdfFileUrl}
        showDlg={showPdfViewDialog}
        secondaryAction={() => setShowPdfViewDialog(false)}
      />
    </>
  );
};
export default PdfPreview;
