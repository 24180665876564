import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import Attachment from "../snipets/attachment";
import { BsInfoCircleFill } from "react-icons/bs";
import I18n, { i18n } from "../../../../../languageSelector";
import CheckBox from "../../../../../../../components/form-input/checkbox.component";
import { TEXT_DIRECTION } from "../../../../../../../helper/helperFunctions";

const CheckboxPreview = ({
  question,
  addUpdateAnwser = (answer) => {
    console.log(answer);
  },
  getAnswer = (uuid) => {
    console.log(uuid);
    return undefined;
  },
  view,
  currentRole,
  actionItem,
}) => {
  const [notes, setNotes] = useState("");
  const [photo, setPhoto] = useState([]);
  const [value, setValue] = useState(false);
  const [questionImageURL, setQuestionImageURL] = useState([]);
  const [questionPreview, setQuestionPreview] = useState(question);
  const [isQuestionLoading, setIsQuestionLoading] = useState(true);
  const [answerSchema, setAnserSchema] = useState({});
  const [answerScore, setAnswerScore] = useState(0);

  useEffect(() => {
    setIsQuestionLoading(true);
  }, []);

  // eslint-disable-next-line
  useEffect(async () => {
    if (question) {
      setQuestionPreview(question);

      if (question?.questionUuid) {
        const answer = getAnswer(question?.questionUuid);
        if (answer?.questionUuid) {
          setAnserSchema({ ...answer });
          setValue(answer.value);
          setNotes(answer.note);

          setQuestionImageURL([...answer.image]);
          if (answer.image && answer.image.length) {
            setPhoto([...answer.image]);
          }
          setIsQuestionLoading(false);
        } else {
          setIsQuestionLoading(false);
          setValue("");
        }
      }
    }
    // eslint-disable-next-line
  }, [question]);

  const imageDeleteHandler = (index) => {
    let removeImageArray =
      answerSchema && answerSchema?.image?.length
        ? [...answerSchema.image]
        : [...questionImageURL];
    removeImageArray.splice(index, 1);

    if (answerSchema.image?.length || questionImageURL?.length) {
      if (!isQuestionLoading) {
        let answerToSave = {
          questionUuid: question.questionUuid,
          value: value,
          note: notes,
          image: [...removeImageArray],
        };
        addUpdateAnwser(answerToSave);
      }
      photo.splice(index, 1);
      setPhoto([...photo]);
    } else {
      setPhoto([]);
    }
  };

  useEffect(() => {
    let answerToSave = {
      questionUuid: question.questionUuid,
      value: value === false ? "" : value,
      note: notes,
      image: questionImageURL?.length ? [...questionImageURL] : [],
    };
    if (!isQuestionLoading) {
      setAnserSchema({ ...answerToSave });
      addUpdateAnwser(answerToSave);
    }
    // eslint-disable-next-line
  }, [notes]);

  useEffect(() => {
    if (!isQuestionLoading && value !== "") {
      let answerToSave = {
        questionUuid: question.questionUuid,
        value: value === false ? "" : value,
        note: notes,
        image: questionImageURL?.length ? [...questionImageURL] : [],
      };
      setAnserSchema({ ...answerToSave });
      addUpdateAnwser(answerToSave);
      if (value && actionItem?.isScored) {
        setAnswerScore(question?.settings?.score);
      } else {
        setAnswerScore(0);
      }
    }
    // eslint-disable-next-line
  }, [value]);

  const imageAddHandler = () => {
    if (photo && photo.length) {
      let answerToSave = {
        questionUuid: question.questionUuid,
        value: value === false ? "" : value,
        note: notes,
        image: [...photo],
      };
      setQuestionImageURL([...photo]);

      if (!isQuestionLoading) {
        addUpdateAnwser(answerToSave);
      }
    }
  };

  return (
    <div id={question?.questionUuid} className="multiple-choice">
      <div className="d-flex justify-content-between question-title">
        <strong></strong>
        <div className="d-flex">
          {actionItem.isScored && question.settings.score !== "" ? (
            <span className="mr-2 score">
              <I18n lng="Form.Score" /> :
              {`${answerScore.toFixed(2)}/${+question.settings.score} (${
                ((answerScore / +question.settings.score) * 100)?.toFixed(2) ===
                "0"
                  ? "NAN"
                  : ((answerScore / +question.settings.score) * 100)?.toFixed(2)
              }%)`}
            </span>
          ) : null}
          {question.settings.description ? (
            <>
              <p
                data-for={`${question.settings.questionText}`}
                data-tip={`${question.settings.description}`}
                data-iscapture="true"
              >
                <BsInfoCircleFill color={"C4C4C4"} />
              </p>
              <ReactTooltip
                id={`${question.settings.questionText}`}
                place="top"
                type="dark"
                effect="float"
                multiline={true}
              />
            </>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="attachment-preview">
        {question?.settings?.photo ? (
          <img src={question.settings.photo} alt="Attachment Preview" />
        ) : (
          ""
        )}
      </div>
      <div className="row">
        <div className="col-12 d-flex justify-content-between">
          <div className="custom-control custom-checkbox d-flex pl-0">
            {question?.questionNumber
              ? question?.questionNumber
              : actionItem?.questionNo === undefined
              ? actionItem
              : actionItem?.questionNo
              ? actionItem?.questionNo + 1
              : 1}
            . {"   "}
            <div className="pl-1">
              {questionPreview?.settings.questionText ===
              "Add a new question" ? (
                <I18n lng="Form.AddANewQuestion" />
              ) : (
                questionPreview?.settings.questionText
              )}
              {question.settings.isMandatory && <span className="required" />}
            </div>
          </div>
        </div>
        <div className="checkbox-view">
          <div>
            <div
              className={`row ${TEXT_DIRECTION() === "rtl" ? "mr-3" : "ml-2"}`}
            >
              <div className="col-2 checkbox-view m-0 p-0">
                <CheckBox
                  name={question.name + question.questionUuid}
                  checked={value}
                  onChange={(e) => setValue(e.target.checked)}
                  // disabled={view}
                />
              </div>
              <div
                className="col-10"
                style={
                  TEXT_DIRECTION() === "rtl"
                    ? { marginRight: "0" }
                    : { marginLeft: "0", paddingLeft: "5px" }
                }
              >
                <label>
                  {" "}
                  {value === true
                    ? i18n("Form.Checked")
                    : i18n("Form.NotChecked")}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      {
        <Attachment
          notes={notes}
          photo={photo}
          setNotes={setNotes}
          setPhoto={setPhoto}
          imageDeleteHandler={imageDeleteHandler}
          imageAddHandler={imageAddHandler}
          canComment={value === true ? question?.settings?.canComment : false}
          canAttachPhoto={
            value === true ? question?.settings?.canAttachPhoto : false
          }
        />
      }
    </div>
  );
};

const mapStateToProps = ({ authentication }) => ({
  currentRole: authentication.currentRole,
});

export default connect(mapStateToProps, null)(CheckboxPreview);
