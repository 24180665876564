import ReactApexChart from "react-apexcharts";
import { OptionsData } from "./areaChartsInterface";
import { HeaderDataInterfae } from "../main-page/analytics-main";
import { i18n } from "../../languageSelector";
import { TEXT_DIRECTION } from "../../../../helper/helperFunctions";

interface Iprops {
  headData: HeaderDataInterfae | any;
  dateToShow: boolean;
  analyticsXaxisData: string[];
  totalResult: number;
}
const ApexChart = ({ totalResult, headData, dateToShow, analyticsXaxisData }: Iprops) => {
  const refactorPerdayCalculation = totalResult === 0 ? [] : headData.perDayCalculation.map((item: any) => (item === null ? 0 : item));
  // console.log(headData.value.includes("-"), "perc ttt");
  const series = [
    {
      name: headData.title,
      data: headData.title === "Score" && headData.percentage === "0%" && headData.value === "0%" ? [] : refactorPerdayCalculation,
    },
  ];

  const data: OptionsData = {
    chart: {
      type: "area",
      height: "0",
      width: "0",
      toolbar: {
        show: false,
      },

      zoom: {
        enabled: false,
      },
    },
    tooltip: {
      y: {
        formatter: undefined,
        title: {
          formatter: (seriesName) => i18n(`Analytics.${seriesName}`),
        },
      },
    },
    responsive: [
      {
        breakpoint: 900,
        options: {},
      },
    ],

    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },
    //
    // xaxis: {
    //   labels: { show: dateToShow ? true : false }, // Hide x-axis
    //   min: 1,
    //   // max: analyticsXaxisData?.length > 30 ? analyticsXaxisData.length + 1 : analyticsXaxisData.length,
    //   max: analyticsXaxisData.length,
    //   // type: "datetime",
    // },
    // labels: dates,

    labels: analyticsXaxisData,

    xaxis: {
      position: "bottom",
      labels: { show: dateToShow ? true : false, rotate: TEXT_DIRECTION() === "rtl" ? 45 : -45 },
      min: 1,
      max: analyticsXaxisData.length,
    },

    yaxis: { labels: { show: dateToShow ? true : false } },
    // labels: dates,
    legend: {
      horizontalAlign: "left",
    },

    colors: [headData.color],
    exporting: {
      enabled: false,
    },
  };

  return (
    <div>
      <ReactApexChart width={headData.width} height={headData.height} options={data} series={series} type="area" />
    </div>
  );
};

export default ApexChart;
