import React, { useState, useEffect } from "react";
import I18n from "../../../languageSelector";
import { compose, withProps } from "recompose";
import { withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import ReportPdfImages from "../report-pdf-images/reportPdfImages.js";
import { TEXT_DIRECTION } from "../../../../../helper/helperFunctions";

const MapComponent = compose(
  withProps({
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `150px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withGoogleMap
)((props) => (
  <GoogleMap
    defaultZoom={15}
    center={{
      lat: props.lat,
      lng: props.lng,
    }}
  >
    {props.markers && props.markers.length > 0
      ? props.markers.map((marker, index) => (
          <Marker key={index} position={marker.position} draggable={false} />
        ))
      : null}
  </GoogleMap>
));

const LocationPdfPreview = ({
  getAnswer = (uuid) => {
    console.log(uuid);
    return undefined;
  },
  question,
  failStatus,
  hideAndShow,
  setImageIndex,
  setShowImageSrc,
  setShowImageDialog,
  setShowSection,
  setTotalAnswered,
  showDocumentMedia,
  imageCountData,
}) => {
  const [markers, setMarkers] = useState([
    {
      name: "Current position",
      position: {
        lat: 31.5203696,
        lng: 74.35874729999999,
      },
    },
  ]);
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [note, setNote] = useState("");
  const [photo, setPhoto] = useState("");
  const [address, setAddress] = useState("");

  useEffect(() => {
    if (question && question.questionUuid) {
      let answer = getAnswer(question.questionUuid);
      if (answer) {
        setPhoto(answer.image);
        setNote(answer.note);
        setLat(answer.lat);
        setLng(answer.lng);
        setAddress(answer.address);
        setMarkers([
          {
            position: {
              lat: parseFloat(answer.lat),
              lng: parseFloat(answer.lng),
            },
          },
        ]);
        if (answer?.city || answer?.lat || answer?.note || answer?.image) {
          setShowSection(true);
          setTotalAnswered((prev) => prev + 1);
        }
      }
    }
    // eslint-disable-next-line
  }, [question]);

  const [imageCount, setImageCount] = useState(0);

  useEffect(() => {
    if (question && question.questionUuid && imageCountData?.length) {
      let data = imageCountData.filter((item) => {
        return item.questionId === question.questionUuid;
      });
      if (data.length) {
        setImageCount(data[0].imageCountStart);
      }
    }
  }, [question, imageCountData]);

  return (
    <>
      {!failStatus ? (
        hideAndShow?.content?.all ? (
          <div className="table-body">
            <div
              className="row table-row ml-0 mr-0   question status-na"
              style={{
                boxShadow: "0px 5px 5px rgba(0,0,0,.10)",
                backgroundColor: "#ffffff",
              }}
            >
              <div
                className={`col-lg-8 col-md-6 mb-3 table-column d-flex ${
                  TEXT_DIRECTION() === "rtl" ? "pr-0" : "pl-0"
                }`}
              >
                <span
                  className={`text-muted ${
                    TEXT_DIRECTION() === "rtl" ? "ml-2" : "mr-2"
                  }`}
                >
                  {question?.questionNumber}.
                </span>
                {"   "}
                {question?.settings?.questionText === "Add a new question" ? (
                  <I18n lng="Form.AddANewQuestion" />
                ) : (
                  question?.settings?.questionText
                )}
              </div>
              <div
                className="col-lg-4 col-md-6 table-column question-type text-primary map-location"
                style={{ padding: "0 23px" }}
              >
                <div className="row">
                  <div className="col-6 pr-1 pt-3">
                    {" "}
                    <div>
                      <label style={{ color: "#4F4F4F", fontWeight: 600 }}>
                        {address && <I18n lng="Form.Location" />}
                      </label>
                      <div
                        className=" mb-0"
                        style={{ color: "#4F4F4F", fontWeight: 500 }}
                      >
                        {address && lng && lat ? (
                          `${address}  (${lng ? Number(lng).toFixed(4) : ""}, ${
                            lat ? Number(lat).toFixed(4) : ""
                          })`
                        ) : (
                          <p>{address}</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 pl-0">
                    {" "}
                    <div className="loc-wrap mb-2">
                      {lat && lng && markers ? (
                        <div
                          className="loc-map-pdf"
                          style={{
                            border: "1.67px solid #828282",
                            borderRadius: "3px",
                          }}
                        >
                          <MapComponent
                            lat={parseFloat(lat)}
                            lng={parseFloat(lng)}
                            markers={markers}
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                {note || photo.length ? (
                  <div
                    className="table-column attachments-wrapper mt-3 pb-4"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <>
                      <fieldset>
                        <legend
                          className={`${
                            TEXT_DIRECTION() === "rtl"
                              ? "legend-arabic"
                              : "legend"
                          }`}
                        >
                          <I18n lng="global.InspectorNotes" />
                        </legend>
                        {note ? (
                          <div className="notes">
                            {/* <label>
                              <I18n lng="global.Notes" />:
                            </label> */}
                            <p>{note}</p>
                          </div>
                        ) : null}
                        {photo.length ? (
                          <div className="thumbnail-wrapper">
                            <ReportPdfImages
                              photo={photo}
                              imageCount={imageCount}
                              setImageIndex={setImageIndex}
                              setShowImageSrc={setShowImageSrc}
                              showDocumentMedia={showDocumentMedia}
                              setShowImageDialog={setShowImageDialog}
                            />
                          </div>
                        ) : null}
                      </fieldset>
                    </>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        ) : hideAndShow?.content?.answered &&
          ((address && lng && lat && markers) || note || photo?.length) ? (
          <div className="table-body">
            <div
              className="row table-row ml-0 mr-0   question status-na"
              style={{
                boxShadow: "0px 5px 5px rgba(0,0,0,.10)",
                backgroundColor: "#ffffff",
              }}
            >
              <div
                className={`col-lg-8 col-md-6 mb-3 table-column d-flex ${
                  TEXT_DIRECTION() === "rtl" ? "pr-0" : "pl-0"
                }`}
              >
                <span
                  className={`text-muted ${
                    TEXT_DIRECTION() === "rtl" ? "ml-2" : "mr-2"
                  }`}
                >
                  {question?.questionNumber}.
                </span>
                {"   "}
                {question?.settings?.questionText === "Add a new question" ? (
                  <I18n lng="Form.AddANewQuestion" />
                ) : (
                  question?.settings?.questionText
                )}
              </div>
              <div
                className="col-lg-4 col-md-6 table-column question-type text-primary map-location"
                style={{ padding: "0 23px" }}
              >
                <div>
                  <label className="font-weight-bold">
                    {address && <I18n lng="Form.Location" />}
                  </label>
                  <div className="font-weight-normal mb-0">
                    {address && lng && lat ? (
                      `${address}( ${lng ? Number(lng).toFixed(4) : ""}, ${
                        lat ? Number(lat).toFixed(4) : ""
                      })`
                    ) : (
                      <p>{address}</p>
                    )}
                  </div>
                </div>
                <div className="loc-wrap mb-2">
                  <div
                    className="loc-map-pdf"
                    style={{
                      border: "1.67px solid #828282",
                      borderRadius: "6px",
                    }}
                  >
                    <MapComponent
                      lat={parseFloat(lat)}
                      lng={parseFloat(lng)}
                      markers={markers}
                    />
                  </div>
                </div>
                {note || photo.length ? (
                  <div
                    className="table-column  attachments-wrapper mt-3"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <>
                      <fieldset>
                        <legend
                          className={`${
                            TEXT_DIRECTION() === "rtl"
                              ? "legend-arabic"
                              : "legend"
                          }`}
                        >
                          <I18n lng="global.InspectorNotes" />
                        </legend>
                        {note ? (
                          <div className="notes">
                            {/* <label>
                            <I18n lng="global.Notes" />:
                          </label> */}
                            <p>{note}</p>
                          </div>
                        ) : null}
                        {photo.length ? (
                          <div className="thumbnail-wrapper">
                            <ReportPdfImages
                              photo={photo}
                              imageCount={imageCount}
                              setImageIndex={setImageIndex}
                              setShowImageSrc={setShowImageSrc}
                              showDocumentMedia={showDocumentMedia}
                              setShowImageDialog={setShowImageDialog}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </fieldset>
                    </>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        ) : hideAndShow?.content?.answered &&
          !address &&
          !lng &&
          !lat &&
          !markers &&
          !note &&
          !photo?.length ? (
          <div className="table-body">
            <div
              className="row table-row ml-0 mr-0   question status-na"
              style={{
                boxShadow: "0px 5px 5px rgba(0,0,0,.10)",
                backgroundColor: "#ffffff",
              }}
            >
              <div
                className={`col-lg-8 col-md-6 mb-3 table-column d-flex ${
                  TEXT_DIRECTION() === "rtl" ? "pr-0" : "pl-0"
                }`}
              >
                <span
                  className={`text-muted ${
                    TEXT_DIRECTION() === "rtl" ? "ml-2" : "mr-2"
                  }`}
                >
                  {question?.questionNumber}.
                </span>
                {"   "}
                {question?.settings?.questionText === "Add a new question" ? (
                  <I18n lng="Form.AddANewQuestion" />
                ) : (
                  question?.settings?.questionText
                )}
              </div>
              <div
                className="col-lg-4 col-md-6 table-column question-type text-primary map-location"
                style={{ padding: "0 23px" }}
              >
                <div>
                  <label className="font-weight-bold">
                    {address && <I18n lng="Form.Location" />}
                  </label>
                  <div className="font-weight-normal mb-0">
                    {address && lng && lat ? (
                      `${address}( ${lng ? Number(lng).toFixed(4) : ""}, ${
                        lat ? Number(lat).toFixed(4) : ""
                      })`
                    ) : (
                      <p>{address}</p>
                    )}
                  </div>
                </div>
                <div className="loc-wrap mb-2">
                  {lat && lng && markers ? (
                    <div
                      className="loc-map-pdf"
                      style={{
                        border: "1.67px solid #828282",
                        borderRadius: "6px",
                      }}
                    >
                      <MapComponent
                        lat={parseFloat(lat)}
                        lng={parseFloat(lng)}
                        markers={markers}
                      />
                    </div>
                  ) : null}
                </div>
                {note || photo.length ? (
                  <div
                    className="table-column  attachments-wrapper mt-3"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <>
                      <fieldset>
                        <legend
                          className={`${
                            TEXT_DIRECTION() === "rtl"
                              ? "legend-arabic"
                              : "legend"
                          }`}
                        >
                          <I18n lng="global.InspectorNotes" />
                        </legend>
                        {note ? (
                          <div className="notes">
                            {/* <label>
                          <I18n lng="global.Notes" />:
                        </label> */}
                            <p>{note}</p>
                          </div>
                        ) : null}
                        {photo.length ? (
                          <div className="thumbnail-wrapper">
                            <ReportPdfImages
                              photo={photo}
                              imageCount={imageCount}
                              setImageIndex={setImageIndex}
                              setShowImageSrc={setShowImageSrc}
                              showDocumentMedia={showDocumentMedia}
                              setShowImageDialog={setShowImageDialog}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </fieldset>
                    </>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        ) : null
      ) : null}
    </>
  );
};
export default LocationPdfPreview;
