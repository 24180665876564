import { createStore, applyMiddleware, compose } from "redux";
import promiseMiddleware from "redux-promise-middleware";
import thunkMiddleware from "redux-thunk";
import reducer, { IRootState } from "./root.reducer";
import DevTools from "../config/devtools";
import errorMiddleware from "../config/error-middleware";
import notificationMiddleware from "../config/notification-middleware";
import loggerMiddleware from "../config/logger-middleware";
import { loadingBarMiddleware } from "react-redux-loading-bar";
import { SERVER_ENV } from "../config/constants";

const defaultMiddlewares = [
  thunkMiddleware,
  errorMiddleware,
  notificationMiddleware,
  promiseMiddleware,
  loadingBarMiddleware(),
  loggerMiddleware,
];
const composedMiddlewares = (middlewares: any) =>
  SERVER_ENV === "development"
    ? compose(
        applyMiddleware(...defaultMiddlewares, ...middlewares),
        DevTools.instrument()
      )
    : compose(applyMiddleware(...defaultMiddlewares, ...middlewares));

const initialize = (initialState: IRootState, middlewares = []) =>
  createStore(reducer, initialState, composedMiddlewares(middlewares));

export default initialize;
