import React, { useState, useEffect, useRef } from "react";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { CustomInput } from "reactstrap";
import { useParams } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import I18n, { i18n } from "../../../../../languageSelector";
import { getFileData } from "../../../../../../../helper/image-helper";
import IconDraggable from "../../../../../../../assets/images/icon-draggable.svg";
import {
  COLOR_TAGS,
  INPUT_VALUES_TO_EXCLUDE,
  MULTIPLE_CHOICE_RESPONSES,
} from "../../../../../../../config/constants";
import IconDeleteRed from "../../../../../../../assets/images/icon-delete-red.svg";
import {
  TEXT_DIRECTION,
  floatOrIntegerNumber,
} from "../../../../../../../helper/helperFunctions";
import {
  uploadImage,
  patchForm,
  getForm,
} from "../../../../../../../redux/reducers/form/form.reducer";
import ImageEditorDialog from "../../../../../../../components/dialogs/image-editor-dialog";
import {
  updateMultipleChoiceById,
  createMultipleChoiceQuestion,
  getAllMultipleChoiceQuestions,
} from "../../../../../../../redux/reducers/multiple-choice-questions/multiple-choice.reducer";
import { GET_MCQ_NAME } from "../../../../../../../helper/formHelperFunctions";

const MultipleChoiceSettings = ({
  updatedDuplicateForm,
  setUpdatedDuplicateForm,
  form,
  setForm,
  mcqId,
  question,
  actionItem,
  uploadImage,
  updateQuestion,
  patchForm,
  getForm,
  updateMultipleChoiceById,
  getAllMultipleChoiceQuestions,
}) => {
  const { id } = useParams();
  const fileInputRef = useRef(null);

  const [Image64Data, setImage64Data] = useState("");
  const [description, setDescription] = useState("");
  const [questionSetting, setQuestionSetting] = useState();
  const [questionAnswers, setQuestionAnswers] = useState([]);
  const [openImageEditor, setOpenImageEditor] = useState(false);
  const [image64DataForPreview, setImage64DataForPreview] = useState("");
  const [scoreValue, setScoreValue] = useState();
  const [updatedScore, setUpdatedScore] = useState([]);
  const [scoreInputSelect, setScoreInputSelect] = useState(false);
  const [scoreBtnActive, setScoreBtnActive] = useState();
  const [colorPicker, setColorPicker] = useState({
    show: false,
    for: undefined,
  });

  useEffect(() => {
    setTimeout(() => {
      if (document.getElementById("input-0")) {
        document.getElementById("input-0").focus();
      }
    }, 500);
  }, []);

  useEffect(() => {
    if (JSON.stringify(question) !== JSON.stringify(questionSetting)) {
      setQuestionSetting({
        ...question,
        settings: {
          ...question.settings,
          questionText:
            question.settings.questionText !== ""
              ? question.settings.questionText
              : "Add a new question",
        },
      });
      setDescription(question.settings.description);
      setQuestionAnswers([...question.settings.answers]);
    }
    // eslint-disable-next-line
  }, [question]);

  const updateQuestionData = (updatedData, index) => {
    setQuestionSetting({
      ...updatedData,
    });
    updateQuestion(
      actionItem.pageNo,
      actionItem.sectionNo,
      actionItem.questionNo,
      {
        ...updatedData,
        settings: { ...updatedData.settings },
      }
    );
    updateMultipleChoice(updatedData, index);
  };

  const updateAnswers = (index, key, value) => {
    let scorePercentageByIndex = (value / question.settings.score) * 100;
    let answers = [...questionAnswers];
    let answer;
    if (key === "score") {
      if (value === "-") {
        answer = {
          ...answers[index],
          [key]: "-",
        };
      } else if (value !== "-" && floatOrIntegerNumber(Number(value))) {
        answer = {
          ...answers[index],
          [key]: Number(value).toFixed(2),
        };
      } else if (value !== "-" && !floatOrIntegerNumber(Number(value))) {
        answer = {
          ...answers[index],
          [key]: Number(value).toFixed(0),
        };
      }
    } else {
      answer = {
        ...answers[index],
        [key]: value,
      };
    }

    if (key === "class" && value === "tag-red") {
      answer = { ...answer, isFail: true };
    }
    answers[index] = { ...answer };

    let answerScore = answers
      .filter((item) => item.score !== "-" && item.score !== "/")
      .reduce(function (a, b) {
        return a + +b.score;
      }, 0);
    if (
      actionItem.isScored &&
      answerScore > question.settings.score &&
      question?.settings?.selectionType !== "SINGLE"
    ) {
      toast.error(
        `Total score can not be not greater than ${question.settings.score}`
      );
      setQuestionAnswers([...questionSetting.settings.answers]);
    } else {
      if (question?.settings?.selectionType !== "SINGLE") {
        updateQuestionData({
          ...questionSetting,
          settings: {
            ...questionSetting.settings,
            answers: [...answers],
          },
        });
        setQuestionAnswers([...answers]);
      } else {
        if (key === "score" && value > question?.settings?.score) {
          setQuestionAnswers([...questionSetting.settings.answers]);
        } else {
          updateQuestionData({
            ...questionSetting,
            settings: {
              ...questionSetting.settings,
              answers: [...answers],
            },
          });
          setQuestionAnswers([...answers]);
        }
      }

      if (scoreInputSelect) {
        const getAllQuestions = (formObj) => {
          return formObj.schema.pages.flatMap((page) =>
            page.sections.flatMap((section) => section.questions)
          );
        };

        const formToUse = updatedDuplicateForm || form;
        const questions = getAllQuestions(formToUse);

        if (mcqId && index >= 0 && scorePercentageByIndex >= 0) {
          const allQuestions = questions.filter(
            (q) => q.id === mcqId && q.type === "COMPLEX_MULTIPLE_CHOICE"
          );

          const updatedQuestions = allQuestions.map((question) => {
            const { settings } = question;
            const { answers, score } = settings;
            const updatedScore = (
              (scorePercentageByIndex / 100) *
              score
            )?.toFixed(2);
            const updatedAnswers = answers.map((answer, subIndex) =>
              subIndex === index
                ? {
                    ...answer,
                    score: Number(updatedScore),
                  }
                : answer
            );
            return {
              ...question,
              settings: {
                ...settings,
                answers: updatedAnswers,
              },
            };
          });

          const updatedQuestionsArray = questions.map((question) =>
            allQuestions.includes(question)
              ? updatedQuestions[allQuestions.indexOf(question)]
              : question
          );

          updatedQuestions.forEach((q) => {
            updateCustomMultipleChoice(q.id, q);
          });

          updateFormQuestions(
            updatedQuestionsArray,
            formToUse,
            questionSetting.settings.score
          );
        }

        function updateFormQuestions(updatedQuestions, form, value) {
          const updateSectionQuestions = (section, updatedQuestions) => {
            const sectionQuestions = section?.questions?.map((question) =>
              updatedQuestions.find(
                (q) => q.questionUuid === question.questionUuid
              )
            );
            return { ...section, questions: sectionQuestions };
          };

          const updatePageSections = (page, updatedQuestions) => {
            const sections = page?.sections?.map((section) =>
              updateSectionQuestions(section, updatedQuestions)
            );
            return { ...page, sections };
          };

          const updatedForm = {
            ...form,
            schema: {
              ...form.schema,
              pages: form.schema?.pages?.map((page) =>
                updatePageSections(page, updatedQuestions)
              ),
            },
          };
          if (
            value !== "-" &&
            value !== "/" &&
            +scoreValue <= +question?.settings.score &&
            scoreBtnActive === false
          ) {
            patchForm(id, updatedForm).then(() => {
              setUpdatedDuplicateForm(updatedForm);
            });

            setForm({
              ...updatedForm,
              selectedElement: {
                pageNo: form.pageNo,
                sectionNo: form.sectionNo,
                questionNo: form.questionNo,
              },
            });
            setScoreBtnActive(true);
          } else if (scoreBtnActive === true) {
            patchForm(id, updatedForm).then(() => {
              setUpdatedDuplicateForm(updatedForm);
            });

            setForm({
              ...updatedForm,
              selectedElement: {
                pageNo: form.pageNo,
                sectionNo: form.sectionNo,
                questionNo: form.questionNo,
              },
            });
          } else {
            toast.error(
              `Total score cannot be greater than ${question.settings.score}`
            );
            setScoreBtnActive(true);
          }
        }
      } else {
        if (mcqId) {
          updateCustomMultipleChoice(mcqId, {
            ...questionSetting,
            settings: {
              ...questionSetting.settings,
              answers: [...answers],
            },
          });
        }
      }
    }

    setColorPicker({
      show: false,
      for: undefined,
    });
  };

  const deleteAnswers = (index) => {
    questionSetting.settings.answers.splice(index, 1);

    setQuestionAnswers([...questionSetting.settings.answers]);

    updateQuestionData({ ...questionSetting }, index);
    if (mcqId) {
      updateCustomMultipleChoice(mcqId, {
        ...questionSetting,
      });
    }
  };

  const addAnswer = () => {
    questionSetting.settings.answers.push({
      ...MULTIPLE_CHOICE_RESPONSES[0].options[2],
      name: "Enter Response",
      score: "-",
    });

    setQuestionAnswers([...questionSetting.settings.answers]);

    updateQuestionData({ ...questionSetting });
    if (mcqId) {
      updateCustomMultipleChoice(mcqId, {
        ...questionSetting,
      });
    }
  };

  const updateDescription = () => {
    questionSetting.settings.description = description;
    updateQuestionData({ ...questionSetting });
    if (mcqId) {
      updateCustomMultipleChoice(mcqId, {
        ...questionSetting,
      });
    }
  };

  const scoreHandler = (index, value) => {
    if (scoreInputSelect) {
      let pattern = /^[+-]?([0-9|-]+\.?[0-9|-]*|\.[0-9|-]+)$/;

      if (question.settings.selectionType !== "SINGLE") {
        if (
          (pattern.test(value) && value !== "") ||
          (pattern.test(value) && value === "-")
        ) {
          if (+value < 0) {
            toast.error("Only positive numbers and - are allowed.");
            updateAnswers(index, "score", 0);
          } else {
            updateAnswers(index, "score", value);
          }
        } else {
          updateAnswers(index, "score", 0);
          toast.error(`Only positive numbers and - are allowed.`);
        }
      } else {
        if (
          value !== "-" &&
          value !== "/" &&
          +value <= +question?.settings.score
        ) {
          updateAnswers(index, "score", value);
        } else if (value === "-" || value === "/") {
          updateAnswers(index, "score", "-");
        }
      }
    }
  };

  const getScoreValue = (className) => {
    if (className === "tag-green") {
      return 1;
    }
    if (className === "tag-yellow") {
      return 1;
    }
    if (className === "tag-red") {
      return 0;
    }
    if (className === "tag-slate") {
      return "-";
    }
  };

  const onImageEditorHandler = (data) => {
    setImage64DataForPreview(data);
    if (question?.settings?.photo) {
      uploadImage({
        base64: data,
        existingFileUrl: question?.settings?.photo,
        fileType: "jpg",
      }).then((response) => {
        updateQuestionData({
          ...questionSetting,
          settings: {
            ...questionSetting.settings,
            photo: response.value.data,
          },
        });
        if (mcqId) {
          updateCustomMultipleChoice(mcqId, {
            ...questionSetting,
            settings: {
              ...questionSetting.settings,
              photo: response.value.data,
            },
          });
        }
        toast.success(<I18n lng="global.PhotoUpdated" />);
      });
    } else {
      uploadImage({ base64: data, existingFileUrl: "", fileType: "jpg" }).then(
        (response) => {
          updateQuestionData({
            ...questionSetting,
            settings: {
              ...questionSetting.settings,
              photo: response.value.data,
            },
          });
          if (mcqId) {
            updateCustomMultipleChoice(mcqId, {
              ...questionSetting,
              settings: {
                ...questionSetting.settings,
                photo: response.value.data,
              },
            });
          }
          toast.success(<I18n lng="global.PhotoUploaded" />);
        }
      );
    }
  };

  const selectImagehandler = async (event) => {
    const fileData = await getFileData(event);
    if (fileData && fileData.fileUrl !== "") {
      setImage64Data(fileData.fileUrl);
      setOpenImageEditor(true);
    }
    fileInputRef.current.value = "";
  };

  const resetScoreHandler = (type) => {
    let updatedAnswers = [];
    if (type === "SINGLE") {
      if (updatedScore.length) {
        updatedAnswers = [...updatedScore];
      } else {
        updatedAnswers = questionSetting?.settings?.answers.map((item) => {
          return item?.class === "tag-green" || item?.class === "tag-yellow"
            ? { ...item, score: questionSetting?.settings?.score }
            : item.class === "tag-red"
            ? { ...item, score: 0 }
            : { ...item, score: "-" };
        });
      }
    } else {
      setUpdatedScore([...questionSetting?.settings?.answers]);
      updatedAnswers = questionSetting?.settings?.answers.map((item) => {
        return { ...item, score: "-" };
      });
    }
    setQuestionAnswers([...updatedAnswers]);
    return updatedAnswers;
  };

  const updateCustomMultipleChoice = (mcqId, questionData) => {
    updateMultipleChoiceById(mcqId, questionData).then(() => {
      getAllMultipleChoiceQuestions(id);
    });
  };

  const updateMultipleChoice = (updatedData, deletedIndex) => {
    if (form?.schema?.pages?.length && questionSetting?.id) {
      form?.schema?.pages?.forEach((page, pageIndex) => {
        return page?.sections?.length
          ? page?.sections?.forEach((section, sectionIndex) => {
              return section?.questions?.length
                ? section?.questions.forEach((question, questionIndex) => {
                    let answers = [...question.settings.answers];

                    if (answers.length < updatedData.settings.answers.length) {
                      // addiing newly added answer to all questions in form
                      answers = [
                        ...answers,
                        updatedData.settings.answers[
                          updatedData.settings.answers.length - 1
                        ],
                      ];
                    } else if (
                      answers.length > updatedData.settings.answers.length &&
                      deletedIndex
                    ) {
                      // removing one answer at deletedIndex from all questions in form
                      answers.splice(deletedIndex, 1);
                    }
                    if (question?.id === questionSetting?.id) {
                      answers = answers.map((item, answerIndex) => ({
                        ...item,
                        name: updatedData?.settings?.answers[answerIndex]?.name,
                        class:
                          updatedData?.settings?.answers[answerIndex]?.class,
                        canComment:
                          question.settings.answers[answerIndex]?.canComment,
                        canAttachPhoto:
                          question.settings.answers[answerIndex]
                            ?.canAttachPhoto,
                      }));

                      return updateQuestion(
                        pageIndex,
                        sectionIndex,
                        questionIndex,
                        {
                          ...question,
                          settings: {
                            ...question.settings,
                            answers: [...answers],
                            selectionType: updatedData?.settings?.selectionType,
                          },
                        }
                      );
                    }
                  })
                : null;
            })
          : null;
      });
    }
  };

  return (
    <>
      <input
        ref={fileInputRef}
        type="file"
        hidden
        multiple={false}
        onChange={selectImagehandler}
      />
      {questionSetting ? (
        <>
          <div className={`${TEXT_DIRECTION() === "rtl" ? "mr-3" : "ml-3"}`}>
            <div className="row mb-3">
              <div className="col-1 p-0">
                <CustomInput
                  className="font-weight-bold"
                  label
                  type="radio"
                  id="singleAns"
                  name="answerOption"
                  checked={
                    questionSetting.settings.selectionType === "SINGLE"
                      ? true
                      : false
                  }
                  onChange={() => {
                    let updatedAnswerScores = resetScoreHandler("SINGLE");
                    if (updatedAnswerScores) {
                      updateQuestionData({
                        ...questionSetting,
                        settings: {
                          ...questionSetting.settings,
                          selectionType: "SINGLE",
                          answers: [...updatedAnswerScores],
                        },
                      });
                      if (mcqId) {
                        updateCustomMultipleChoice(mcqId, {
                          ...questionSetting,
                          settings: {
                            ...questionSetting.settings,
                            selectionType: "SINGLE",
                          },
                        });
                      }
                    }
                  }}
                />
              </div>
              <div className="col-4">
                <label
                  className={`font-weight-bold ${
                    TEXT_DIRECTION() === "rtl" ? "mr-3" : ""
                  }`}
                >
                  {<I18n lng="Form.Single" />}
                </label>
              </div>
              <div className="col-1 p-0">
                <CustomInput
                  className="font-weight-bold"
                  label
                  type="radio"
                  id="multipleAns"
                  name="answerOption"
                  checked={
                    questionSetting.settings.selectionType === "MULTIPLE"
                      ? true
                      : false
                  }
                  onChange={() => {
                    let updatedAnswerScores = resetScoreHandler("MULTIPLE");
                    if (updatedAnswerScores) {
                      updateQuestionData({
                        ...questionSetting,
                        settings: {
                          ...questionSetting.settings,
                          selectionType: "MULTIPLE",
                          answers: [...updatedAnswerScores],
                        },
                      });
                    }
                    if (mcqId) {
                      updateCustomMultipleChoice(mcqId, {
                        ...questionSetting,
                        settings: {
                          ...questionSetting.settings,
                          selectionType: "MULTIPLE",
                        },
                      });
                    }
                  }}
                />
              </div>
              <div className="col-5">
                <label
                  className={`font-weight-bold ${
                    TEXT_DIRECTION() === "rtl" ? "mr-3" : ""
                  }`}
                >
                  {<I18n lng="Form.Multiple" />}
                </label>
              </div>
            </div>
          </div>

          <div className="sr-block">
            {questionAnswers.map((answer, index) => (
              <React.Fragment key={index}>
                <div className="block-title" key={index}>
                  <strong className="d-flex">
                    <img
                      className={`${
                        TEXT_DIRECTION() === "rtl" ? "ml-2 mr-0" : ""
                      }`}
                      src={IconDraggable}
                      alt="IconDraggable"
                    />
                    <div className="input-score">
                      <div className="d-flex align-items-center">
                        <input
                          id={`input-${index}`}
                          className={`form-control`}
                          value={GET_MCQ_NAME(answer.name)}
                          onClick={() => {
                            setScoreInputSelect(false);
                            if (answer.name === "Enter Response") {
                              updateAnswers(index, "name", "");
                            }
                          }}
                          onBlur={(e) => {
                            if (e.target.value.match(/^ *$/) !== null) {
                              updateAnswers(index, "name", "Enter Response");
                            } else if (
                              e.target.value !== questionAnswers[index].name
                            ) {
                              updateAnswers(index, "name", e.target.value);
                            } else {
                              updateAnswers(index, "name", answer.name);
                            }
                          }}
                          onChange={(e) => {
                            let newAnswer = {
                              ...questionAnswers[index],
                              name: e.target.value,
                            };
                            questionAnswers[index] = newAnswer;
                            setQuestionAnswers([...questionAnswers]);
                          }}
                          onKeyDown={(e) => {
                            if (
                              e.key === "Enter" &&
                              index !==
                                questionSetting.settings.answers.length - 1
                            ) {
                              updateAnswers(
                                index,
                                "name",
                                e.target.value
                                  ? e.target.value
                                  : "Enter Response"
                              );
                              document
                                .getElementById(`input-${index + 1}`)
                                .focus();
                            }
                          }}
                        />
                      </div>
                    </div>
                  </strong>
                  {actionItem.isScored && (
                    <div className="scored-input d-flex align-items-center">
                      <label
                        className={`mb-0 ${
                          TEXT_DIRECTION() === "rtl" ? "ml-1 mr-2" : "mr-2"
                        }`}
                      >
                        <I18n lng="Form.Score" />
                      </label>
                      <div
                        className="input-group remove-arrows input-number"
                        onClick={() => {
                          setScoreInputSelect(true);
                        }}
                      >
                        <input
                          onClick={(e) => {
                            setScoreBtnActive(false);
                            setScoreValue(e.target.value);
                          }}
                          type="text"
                          className="form-control score p-0"
                          value={
                            answer.score >= 0
                              ? answer.score
                              : answer.score === "-"
                              ? "-"
                              : getScoreValue(answer.class)
                          }
                          onChange={(e) => {
                            const newAnswer = {
                              ...questionAnswers[index],
                              score: e.target.value,
                            };
                            setScoreValue(e.target.value);
                            setQuestionAnswers((prevAnswers) => {
                              const updatedAnswers = [...prevAnswers];
                              updatedAnswers[index] = newAnswer;
                              return updatedAnswers;
                            });
                          }}
                          onKeyDown={(e) =>
                            INPUT_VALUES_TO_EXCLUDE.includes(e.key) &&
                            e.preventDefault()
                          }
                          onBlur={(e) => {
                            if (e.target.value.trim() !== "") {
                              setScoreInputSelect(true);
                              updateAnswers(index, "score", e.target.value);
                            }
                          }}
                        />

                        <div className="btn-group">
                          <button
                            className="btn p-2"
                            onClick={() => {
                              setScoreBtnActive(true);
                              const currentScore =
                                question.settings.answers[index].score;
                              let newScore;

                              if (currentScore === "-") {
                                newScore = 0;
                              } else {
                                newScore =
                                  Number(currentScore) <
                                  Number(question.settings.score)
                                    ? Number(currentScore) + 1
                                    : Number(currentScore);
                              }
                              scoreHandler(index, newScore);
                            }}
                          />
                          <button
                            className="btn p-2"
                            onClick={() => {
                              setScoreBtnActive(true);
                              scoreHandler(
                                index,
                                question.settings.answers[index].score === "-"
                                  ? "-"
                                  : Number(
                                      question.settings.answers[index].score
                                    ) >= 1
                                  ? Number(
                                      question.settings.answers[index].score
                                    ) - 1
                                  : "-"
                              );
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className={`${
                          questionSetting.settings.score > 999 ? "" : "d-flex"
                        }`}
                      >
                        <div
                          className={`mb-0   ${
                            TEXT_DIRECTION() === "rtl" ? "mr-2 " : "ml-2"
                          } ${
                            questionSetting.settings.score > 999 ? "" : "d-flex"
                          }`}
                        >
                          <div>/</div>
                          <div>{questionSetting.settings.score}</div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div
                    className="color-picker tags-wrapper"
                    onClick={() => {
                      setColorPicker({ show: true, for: index });
                      setScoreInputSelect(false);
                    }}
                  >
                    <span className={`tag ${answer.class}`}></span>
                  </div>
                </div>
                {colorPicker.show && colorPicker.for === index ? (
                  <div className="sr-options">
                    {COLOR_TAGS.map((item, ind) => {
                      return (
                        <div
                          key={ind}
                          className="color-picker tags-wrapper"
                          onClick={() => {
                            updateAnswers(index, "class", item);
                          }}
                        >
                          <span className={`tag ${item}`}></span>
                        </div>
                      );
                    })}
                  </div>
                ) : null}
                <div className={`${TEXT_DIRECTION() === "rtl" ? "" : "ml-3"}`}>
                  <div className="row mt-2 ml-0 ">
                    <div
                      className={`${
                        TEXT_DIRECTION() === "rtl" ? "mr-2" : "col-1 pl-0"
                      }`}
                      onClick={(e) => {
                        setScoreInputSelect(false);
                      }}
                    >
                      <CustomInput
                        label
                        type="switch"
                        name={"switchComment" + index}
                        id={"switchComment" + index}
                        inline
                        checked={answer.canComment}
                        onChange={() => {
                          updateAnswers(
                            index,
                            "canComment",
                            !answer.canComment
                          );
                        }}
                      />
                    </div>
                    <div
                      className={`${
                        TEXT_DIRECTION() === "rtl" ? "col-2 " : "col-3 pl-2"
                      }`}
                    >
                      <label>{<I18n lng="global.Comment" />}</label>
                    </div>
                    <div
                      className={`${
                        TEXT_DIRECTION() === "rtl" ? "" : "col-1 pl-1"
                      }`}
                      onClick={(e) => {
                        setScoreInputSelect(false);
                      }}
                    >
                      <CustomInput
                        label
                        type="switch"
                        name={"switchPhoto" + index}
                        id={"switchPhoto" + index}
                        inline
                        checked={answer.canAttachPhoto}
                        onChange={() => {
                          updateAnswers(
                            index,
                            "canAttachPhoto",
                            !answer.canAttachPhoto
                          );
                        }}
                      />
                    </div>
                    <div
                      className={`${
                        TEXT_DIRECTION() === "rtl" ? "pr-2" : "col-2 pl-2"
                      }`}
                    >
                      <label>{<I18n lng="global.Photo" />}</label>
                    </div>
                    <div
                      className={`${
                        TEXT_DIRECTION() === "rtl" ? "" : "col-1 pl-2"
                      }`}
                      onClick={(e) => {
                        setScoreInputSelect(false);
                      }}
                    >
                      <CustomInput
                        label
                        type="switch"
                        name={"switchFail" + index}
                        id={"switchFail" + index}
                        inline
                        checked={answer.isFail}
                        onChange={() => {
                          updateAnswers(index, "isFail", !answer.isFail);
                        }}
                      />
                    </div>
                    <div
                      className={`${
                        TEXT_DIRECTION() === "rtl" ? "pr-2" : "col-2 pl-3"
                      }`}
                    >
                      <label>{<I18n lng="global.Fail" />}</label>
                    </div>

                    {index ? (
                      <Link
                        to="#"
                        onClick={() => {
                          deleteAnswers(index);
                          setScoreInputSelect(false);
                        }}
                        className={`${
                          TEXT_DIRECTION() === "rtl" ? "" : " float-right"
                        }`}
                        style={{
                          marginRight:
                            TEXT_DIRECTION() === "rtl" ? "25px" : "none",
                        }}
                      >
                        <img src={IconDeleteRed} alt="IconDeleteRed" />
                      </Link>
                    ) : null}
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
          {question.settings.answers.length < 5 ? (
            <Link
              to="#"
              onClick={() => {
                addAnswer();
                setScoreInputSelect(false);
              }}
              className="add-answer"
            >
              {<I18n lng="Form.AddAnswer" />}
            </Link>
          ) : (
            ""
          )}

          <hr className={"mb-3"} />
          <div className={`${TEXT_DIRECTION() === "rtl" ? "mr-2" : "ml-3"}`}>
            <div className="row">
              <div className="col-1 p-0">
                <CustomInput
                  className="font-weight-bold"
                  label
                  type="switch"
                  name="enabledQuestion"
                  id="enabledQuestion"
                  checked={questionSetting.settings.isEnabled}
                  onChange={(e) => {
                    updateQuestionData({
                      ...questionSetting,
                      settings: {
                        ...questionSetting.settings,
                        isEnabled: !questionSetting.settings.isEnabled,
                        isMandatory:
                          questionSetting.settings.isEnabled === true &&
                          questionSetting.settings.isMandatory === true
                            ? !questionSetting.settings.isMandatory
                            : questionSetting.settings.isMandatory,
                      },
                    });
                  }}
                />
              </div>
              <div className="col-11">
                <label
                  className={`${TEXT_DIRECTION() === "rtl" ? "mr-2" : ""}`}
                >
                  {<I18n lng="Form.EnabledQuestion" />}
                </label>
              </div>
              <div className="col-1 p-0">
                <CustomInput
                  className="font-weight-bold"
                  label
                  type="switch"
                  name="mendatoryAnswer"
                  id="mendatoryAnswer"
                  checked={
                    questionSetting.settings.isEnabled === true &&
                    questionSetting.settings.isMandatory === true
                      ? questionSetting.settings.isMandatory
                      : questionSetting.settings.isEnabled === false &&
                        questionSetting.settings.isMandatory === true
                      ? !questionSetting.settings.isMandatory
                      : questionSetting.settings.isEnabled === true &&
                        questionSetting.settings.isMandatory === false
                      ? questionSetting.settings.isMandatory
                      : questionSetting.settings.isEnabled === false &&
                        questionSetting.settings.isMandatory === false
                      ? questionSetting.settings.isMandatory
                      : null
                  }
                  disabled={questionSetting.settings.isEnabled === false}
                  onChange={() => {
                    updateQuestionData({
                      ...questionSetting,
                      settings: {
                        ...questionSetting.settings,
                        isMandatory: !questionSetting.settings.isMandatory,
                      },
                    });
                  }}
                />
              </div>
              <div className="col-11">
                <label
                  className={`${TEXT_DIRECTION() === "rtl" ? "mr-2" : ""}`}
                >
                  {<I18n lng="Form.AnswerIsMandatory" />}
                </label>
              </div>
            </div>
          </div>
          <div className="sr-text-block btn-bar">
            <textarea
              className={`${TEXT_DIRECTION() === "rtl" ? "ml-2 mr-0" : ""}`}
              placeholder={i18n("global.PleaseHelpNoteHere")}
              rows="3"
              value={description}
              onBlur={updateDescription}
              onChange={(e) => setDescription(e.target.value)}
            />
            {question?.settings?.photo || image64DataForPreview !== "" ? (
              <div className="edit-photo">
                <AiOutlineClose
                  className="icon icon-img-remove"
                  onClick={(e) => {
                    e.preventDefault();
                    updateQuestionData({
                      ...questionSetting,
                      settings: {
                        ...questionSetting.settings,
                        photo: "",
                      },
                    });
                    setImage64DataForPreview("");
                  }}
                />
                <img
                  id={"image-preview"}
                  onLoad={({ target: img }) => {
                    let style =
                      img.naturalWidth > img.naturalHeight
                        ? img.naturalHeight
                        : img.naturalWidth;
                    let documents;
                    documents = document.getElementById("image-preview");
                    documents.setAttribute("height", style + "px");
                    documents.setAttribute("width", style + "px");
                  }}
                  src={
                    image64DataForPreview !== ""
                      ? image64DataForPreview
                      : question?.settings?.photo
                  }
                  alt="loaidng"
                  onClick={(e) => {
                    e.preventDefault();
                    fileInputRef.current.click();
                  }}
                />
              </div>
            ) : (
              <div className="add-photo">
                <span
                  onClick={(e) => {
                    e.preventDefault();
                    fileInputRef.current.click();
                  }}
                >
                  <I18n lng="global.Photo" />
                </span>
              </div>
            )}
          </div>
        </>
      ) : null}
      <ImageEditorDialog
        show={openImageEditor}
        onHide={() => {
          setOpenImageEditor(false);
        }}
        onEdit={(data, type) => onImageEditorHandler(data, type)}
        toEdit={
          Image64Data
            ? Image64Data
            : question?.settings?.photo
            ? question?.settings?.photo
            : ""
        }
      />
    </>
  );
};
const mapDispatchToProps = {
  uploadImage,
  updateMultipleChoiceById,
  createMultipleChoiceQuestion,
  getAllMultipleChoiceQuestions,
  patchForm,
  getForm,
};
export default connect(null, mapDispatchToProps)(MultipleChoiceSettings);
