import React from "react";
import ListItemMenu from "./list-item-menu";
import { MdPermMedia } from "react-icons/md";
import { getFormLastModifiedDate } from "../../../../../helper/date-time-helper";
import { isRTL, TEXT_DIRECTION } from "../../../../../helper/helperFunctions";
import { i18n } from "../../../../private/languageSelector";

const ListItem = ({ form, roleAccess }) => {
  return (
    <li>
      <div className="list-item-avatar">
        <span className="avatar-wrap">
          {form.formDetail.image ? (
            <img src={form.formDetail.image} alt="Avatar"></img>
          ) : (
            <div className={"svgItem"}>
              <MdPermMedia />
            </div>
          )}
        </span>
      </div>
      <div className="list-item-detail">
        <h6
          className={`truncate ${
            TEXT_DIRECTION() !== "rtl" && isRTL(form?.formDetail?.title)
              ? "direction-rtl"
              : TEXT_DIRECTION() === "rtl" && !isRTL(form?.formDetail?.title)
              ? "direction-ltr"
              : ""
          }`}
        >
          {form?.formDetail?.title ? (
            form?.formDetail?.title.includes("Untitled Form") ? (
              <>
                {form?.formDetail?.title.replace(
                  "Untitled Form",
                  i18n("global.UntitledForm")
                )}{" "}
              </>
            ) : (
              form?.formDetail?.title
            )
          ) : null}
        </h6>
        <p
          className={`truncate text-muted ${
            TEXT_DIRECTION() !== "rtl" && isRTL(form?.formDetail?.description)
              ? "direction-rtl"
              : TEXT_DIRECTION() === "rtl" &&
                !isRTL(form?.formDetail?.description)
              ? "direction-ltr"
              : ""
          }`}
        >
          {form?.formDetail?.description === "Untitled"
            ? ""
            : form?.formDetail?.description}
        </p>
        <small>{getFormLastModifiedDate(form.updatedAt)}</small>
      </div>
      <span className="list-item-action">
        <ListItemMenu form={form} roleAccess={roleAccess} />
      </span>
    </li>
  );
};

export default ListItem;
