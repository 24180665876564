import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import "react-phone-number-input/style.css";
import { connect } from "react-redux";
import validator from "validator";
import IconInfo from "../../../../../../assets/images/icon-info.svg";
import DynamicModal from "../../../../../../components/dialogs";
import Input from "../../../../../../components/form-input/input.component";
import { INPUT_VALUES_TO_EXCLUDE } from "../../../../../../config/constants";
import { DATE_REGEX, INVALID_DATE, MAX_NUM_LENGTH, MIN_NUM_LENGTH } from "../../../../../../config/validations";
import { jumpInputField } from "../../../../../../helper/helperFunctions";
import { updateUserById } from "../../../../../../redux/reducers/user/user.reducer";
import I18n, { i18n } from "../../../../languageSelector";

const INITIAL_VALUES = {
  cvc: "",
  name: "",
  expiry: "",
  default: false,
};
const CardAddDialog = ({ user, edit, cardIndex, showDlg, cardToEdit, userCardsList, updateUserById, secondaryAction }) => {
  const [expiryDate, setExpiryDate] = useState("");
  const [inputsFilled, setInputsFilled] = useState(false);
  const [cardError, setCardError] = useState(false);
  const [cardNumber, setCardNumber] = useState("", "", "", "");

  useEffect(() => {
    const anyInputFilled = Object.values(INITIAL_VALUES).some((value) => value !== "");
    setInputsFilled(anyInputFilled);
  }, [cardNumber, expiryDate]);

  useEffect(() => {
    if (edit && cardToEdit?.name) {
      reset({
        cvc: "",
        name: cardToEdit.name,
        default: cardToEdit.default,
        expiry: cardToEdit.month + "/" + cardToEdit.year,
      });
      setCardNumber([
        String(cardToEdit.number).slice(0, 4),
        String(cardToEdit.number).slice(4, 8),
        String(cardToEdit.number).slice(8, 12),
        String(cardToEdit.number).slice(12, 16),
      ]);
    } else {
      reset({ ...INITIAL_VALUES });
      setCardNumber(["", "", "", ""]);
    }
    // eslint-disable-next-line
  }, [edit, showDlg, cardToEdit]);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: INITIAL_VALUES,
  });

  const onSubmit = (data) => {
    let dummyList = [...userCardsList];
    if (data.default && dummyList.length) {
      dummyList = dummyList.map((item) => {
        return { ...item, default: false };
      });
    }
    if (cardIndex !== null) {
      dummyList[cardIndex] = {
        name: data.name,
        default: data.default,
        number: Number(cardNumber.join("")),
        month: data.expiry.split("/")[0],
        year: data.expiry.split("/")[1],
      };
    } else {
      dummyList = [
        ...dummyList,
        {
          name: data.name,
          default: data.default,
          number: Number(cardNumber.join("")),
          month: data.expiry.split("/")[0],
          year: data.expiry.split("/")[1],
        },
      ];
    }
    if (cardNumber.join("").length === 16 && !cardError) {
      updateUserById({
        ...user
      })
        .then((res) => {
          secondaryAction();
          toast.success(i18n("Subscriptions.CreditCardAdded"));
        })
        .catch((err) => console.log(err?.response?.data));
    } else {
      toast.error(i18n("Subscriptions.EnterValidCardNumber"));
    }
  };

  useEffect(() => {
    if (cardNumber.length && cardNumber.join("").length === 16) {
      if (validator.isCreditCard(cardNumber.join(""))) {
        setCardError(false);
      } else {
        setCardError(true);
      }
    } else {
      setCardError(false);
    }
  }, [cardNumber]);

  return (
    <>
      <DynamicModal
        title={"Add Payment Method"}
        show={showDlg}
        secondaryAction={secondaryAction}
        customClasses="modal-drawer company-subscription-drawer"
      >
        <div className="p-3 add-payment-method-card-detials">
          <span>Card details</span>
        </div>
        <form id="my-form" className="payment-card-form my-3 d-flex flex-column justify-content-between" onSubmit={handleSubmit(onSubmit)}>
          <div>
            <Input
              name="name"
              type="text"
              label={i18n("Subscriptions.Name")}
              placeholder={i18n("Subscriptions.EnterTheName")}
              register={register}
              validations={{
                required: {
                  value: true,
                  message: i18n("validation.REQUIRED_FIELD"),
                },
              }}
              error={errors["name"]}
            />

            <label className="credit-card-label">
              <I18n lng="Subscriptions.CardNumber" />
            </label>
            <div className="row">
              <div className="col-3 remove-arrows">
                <input
                  type="string"
                  maxLength="4"
                  name="field-1"
                  value={cardNumber[0]}
                  onKeyDown={(e) => {
                    INPUT_VALUES_TO_EXCLUDE.includes(e.key) && e.preventDefault();
                  }}
                  onChange={(e) => {
                    jumpInputField(e, 0, cardNumber, setCardNumber);
                  }}
                />
              </div>
              <div className="col-3 remove-arrows">
                <input
                  type="string"
                  maxLength="4"
                  name="field-2"
                  value={cardNumber[1]}
                  onKeyDown={(e) => INPUT_VALUES_TO_EXCLUDE.includes(e.key) && e.preventDefault()}
                  onChange={(e) => {
                    jumpInputField(e, 1, cardNumber, setCardNumber);
                  }}
                />
              </div>
              <div className="col-3 remove-arrows">
                <input
                  type="string"
                  maxLength="4"
                  name="field-3"
                  value={cardNumber[2]}
                  onKeyDown={(e) => INPUT_VALUES_TO_EXCLUDE.includes(e.key) && e.preventDefault()}
                  onChange={(e) => {
                    jumpInputField(e, 2, cardNumber, setCardNumber);
                  }}
                />
              </div>
              <div className={`col-3 remove-arrows ${!cardError ? "mb-3" : ""}`}>
                <input
                  type="string"
                  maxLength="4"
                  name="field-4"
                  value={cardNumber[3]}
                  onKeyDown={(e) => INPUT_VALUES_TO_EXCLUDE.includes(e.key) && e.preventDefault()}
                  onChange={(e) => {
                    jumpInputField(e, 3, cardNumber, setCardNumber);
                  }}
                />
              </div>
            </div>
            {cardError && (
              <label className="credit-card-error label">
                <I18n lng="Subscriptions.EnterValidCard" />
              </label>
            )}
            <div className="row input-cardnumber">
              <div className="col-6">
                <Input
                  name="expiry"
                  label={i18n("Subscriptions.Expiry")}
                  type="string"
                  maxLength="5"
                  placeholder="mm     /     yy"
                  register={register}
                  validations={{
                    required: {
                      value: true,
                      message: i18n("validation.REQUIRED_FIELD"),
                    },
                    pattern: {
                      value: DATE_REGEX,
                      message: INVALID_DATE,
                    },
                  }}
                  onKeyDown={(e) => {
                    INPUT_VALUES_TO_EXCLUDE.includes(e.key) && e.preventDefault();
                  }}
                  value={expiryDate}
                  onChange={(e) => {
                    let textTemp = e.target.value;
                    if (textTemp.length === 2) {
                      textTemp += "/";
                    }
                    setExpiryDate(textTemp);
                  }}
                  error={errors["expiry"]}
                  className="form-control text-center"
                />
              </div>
              <div className="col-6">
                <Input
                  name="cvc"
                  label="CVV"
                  type="string"
                  maxLength="3"
                  placeholder="CVV"
                  suffix={IconInfo}
                  register={register}
                  validations={{
                    required: {
                      value: true,
                      message: i18n("validation.REQUIRED_FIELD"),
                    },
                    minLength: { value: 3, message: MIN_NUM_LENGTH(3) },
                    maxLength: { value: 3, message: MAX_NUM_LENGTH(3) },
                  }}
                  onKeyDown={(e) => {
                    INPUT_VALUES_TO_EXCLUDE.includes(e.key) && e.preventDefault();
                  }}
                  error={errors["cvc"]}
                  className="form-control text-center"
                />
              </div>
            </div>
          </div>
          <div className="payment-card-form-button d-flex justify-content-center align-items-center">
            <button type="submit" style={{ backgroundColor: inputsFilled ? "#20639B" : "#E0E0E0" }}>
              <span>
                <I18n lng="global.Save" />
              </span>
            </button>
          </div>
        </form>
      </DynamicModal>
    </>
  );
};

const mapStateToProps = ({ authentication }) => ({
  user: authentication.user,
});

const mapDispatchToProps = { updateUserById };

export default connect(mapStateToProps, mapDispatchToProps)(CardAddDialog);
