import React, { createContext, useContext } from "react";

const SitesUIContext = createContext();

export function useSiteUIContext() {
  return useContext(SitesUIContext);
}

export const SitesUIConsumer = SitesUIContext.Consumer;

export function SiteUIProvider({ sitesUiEvents, children }) {
  const value = {
    openSiteViewDialog: sitesUiEvents.openSiteViewDialog,
    openSiteEditDialog: sitesUiEvents.openSiteEditDialog,
    openSiteDeleteDialog: sitesUiEvents.openSiteDeleteDialog,
  };

  return (
    <SitesUIContext.Provider value={value}>{children}</SitesUIContext.Provider>
  );
}
