import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Storage } from "react-jhipster";
import { connect } from "react-redux";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import PrivateRoute from "./components/private-route/private-route";
import { canAccessTheRoutes, logoutUserWithNoAccess } from "./helper/helperFunctions";
import Login from "./pages/auth/login";
import PasswordResetChange from "./pages/auth/passwordResetChange";
import PasswordResetMessageEmail from "./pages/auth/passwordResetMessageEmail";
import PasswordResetMessageSuccess from "./pages/auth/passwordResetMessageSuccess";
import PasswordResetRequest from "./pages/auth/passwordResetRequest";
import RegisterDevice from "./pages/auth/register-device";
import SetupAccount from "./pages/auth/setup-account";
import Signup from "./pages/auth/signup";
import PageNotFound from "./pages/error/PageNotFound";
import CompanySetup from "./pages/private/companySetup";
import FormPage from "./pages/private/form/form-page";
import InvoicePdfView from "./pages/private/management/company/subscriptions/invoices/invoice-pdf-view";
import SmsVerification from "./pages/private/management/company/subscriptions/sms/sms-verification";
import SubscriptionVerification from "./pages/private/management/company/subscriptions/subscription-verification-modal";
import ManagementPage from "./pages/private/management/management-page";
import ReportsPage from "./pages/private/reports/reports-page";
import ReportPdfView from "./pages/private/reports/reports-pdf-view";
import VerifyAccount from "./pages/private/verify-account";
import TermsAndConditions from "./pages/public/TermsAndConditions";
import { logout } from "./redux/reducers/authentication/authentication";
import { getAssignedFormsForUser, getForms } from "./redux/reducers/form/form.reducer";
import AnalyticsMain from "./pages/private/analytics/main-page/analytics-main";
import SubscriptionExpiredRedirect from "./components/private-route/SubscriptionExpiredRedirect";
import PlanDialog from "./components/dialogs/PlanDialog";

const AppRoutes = ({
  user,
  logout,
  getForms,
  currentRole,
  rolesAccess,
  isAuthenticated,
  getAssignedFormsForUser,
  // ,
}) => {
  const history = useHistory();
  const [laterActivate, setLaterActivate] = useState(false);
  const activateLater = Storage.local.get("activate");

  useEffect(() => {
    if (currentRole) {
      if (currentRole === "user") {
        getAssignedFormsForUser({
          limit: 10,
          page: 1,
          sortBy: "formUpdatedAt:desc",
        });
      } else {
        if (window.location.pathname === "/forms/archive") {
          getForms({
            sortBy: "updatedAt:desc",
            isArchived: true,
          });
        } else {
          getForms({
            limit: 10,
            page: 1,
            sortBy: "updatedAt:desc",
            isArchived: false,
          });
        }
      }
    }
    // eslint-disable-next-line
  }, [currentRole]);

  useEffect(() => {
    if (user?.subscription?.name === "basic" && user?.subscription?.isRemainingTrails <= 0 && user?.subscription?.isRemainingTrails !== null) {
      toast.error(`Your remaining trails is ${user?.subscription?.isRemainingTrails}`);
    }
  }, [user?.subscription, user?.role]);

  useEffect(() => {
    // checking if user have routes access
    if (user?.roleSetting && !user?.roleSetting?.managerRole && !user?.roleSetting?.inspectorRole) {
      logoutUserWithNoAccess(logout, history);
    } else if (user?.roleSetting?.managerRole && rolesAccess) {
      canAccessTheRoutes(rolesAccess, logout, history);
    }
    // eslint-disable-next-line
  }, [user, rolesAccess]);

  if (user) {
    if (!user.isEmailVerified && !activateLater && laterActivate === false) {
      return <VerifyAccount setLaterActivate={setLaterActivate} />;
    }
    if (!user.company) {
      return <CompanySetup />;
    }
  }

  return (
    <Switch>
      <Route exact path="/report-pdf-view/:id/:email" component={ReportPdfView} />
      <Route exact path="/management/subscriptions/add-payment-method-modal" component={PlanDialog} />
      <Route exact path="/report-pdf-view/:id/view" component={() => <ReportPdfView view={true} />} />
      <Route exact path="/password-reset-message-success" component={PasswordResetMessageSuccess} />
      <Route exact path="/password-reset-message-email" component={PasswordResetMessageEmail} />
      <Route exact path="/reset_password/:token" component={PasswordResetChange} />
      <Route exact path="/password-reset-request" component={PasswordResetRequest} />
      <Route exact path="/terms-and-conditions" component={TermsAndConditions} />
      <Route exact path="/invoice/:id" component={InvoicePdfView} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/setup-account/:token" component={SetupAccount} />
      <Route exact path="/register-device/:token" component={RegisterDevice} />
      <Route exact path="/signup" component={Signup} />
      <Route
        exact
        path="/not-registered"
        component={() => {
          return <span></span>;
        }}
      />
      <Route exact path="/404" component={PageNotFound} />

      <Redirect exact from="/" to="/forms/form-management" />
      {(user && currentRole === "admin") || (user && currentRole === "manager") ? (
        <>
          <Route path="/subscription-payment">
            {({ history, match }) => (
              <SubscriptionVerification
                showDlg={match != null}
                secondaryAction={() => {
                  history.push("/");
                }}
              />
            )}
          </Route>
          <Route path="/subscriptions">
            {({ history, match }) => (
              <SmsVerification
                showDlg={match != null}
                secondaryAction={() => {
                  history.push("/");
                }}
              />
            )}
          </Route>

          <PrivateRoute
            path="/forms/:moduleName(form-management|global-list|archive)"
            component={() => <FormPage />}
            isAuthenticated={isAuthenticated}
          />

          <PrivateRoute path="/reports/:moduleName(my-reports|other-reports|archive)" component={ReportsPage} isAuthenticated={isAuthenticated} />

          <PrivateRoute
            path="/management/:moduleName(info|sites|users|roles|subscriptions)"
            component={ManagementPage}
            isAuthenticated={isAuthenticated}
          />
          <Route exact path="/company-setup" component={CompanySetup} />
          <SubscriptionExpiredRedirect exact path="/analytics" component={() => <AnalyticsMain />} />
        </>
      ) : (
        <>
          <PrivateRoute
            path="/management/:moduleName(info|sites|users|roles|subscriptions)"
            component={ManagementPage}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute exact path="/analytics" component={() => <AnalyticsMain />} isAuthenticated={isAuthenticated} />
          <PrivateRoute
            path="/forms/:moduleName(form-management|global-list|archive)"
            component={() => <FormPage />}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute path="/reports/:moduleName(my-reports|other-reports|archive)" component={ReportsPage} isAuthenticated={isAuthenticated} />
          <Route exact path="/company-setup" component={CompanySetup} />
        </>
      )}
    </Switch>
  );
};

const mapStateToProps = ({ authentication }) => ({
  user: authentication.user,
  currentRole: authentication.currentRole,
  rolesAccess: authentication.userRoleAccess,
  isAuthenticated: authentication.isAuthenticated,
});

const mapDispatchToProps = { logout, getForms, getAssignedFormsForUser };

export default connect(mapStateToProps, mapDispatchToProps)(AppRoutes);
