import React, { useState, useEffect } from "react";
import DynamicModal from "../../../../../../components/dialogs/index";
import MultipleChoiceBuilder from "./multiple-choice/mutliple-choice-builder";
import TextBuilder from "./text/text-builder";
import NumberBuilder from "./number/number-builder";
import CheckboxBuilder from "./checkbox/checkbox-builder";
import DateTimeBuilder from "./date-time/date-time-builder";
import ImageBuilder from "./image/image-builder";
import PDFBuilder from "./pdf/pdf-builder";
import SliderBuilder from "./slider/slider-builder";
import InstructionsBuilder from "./instructions/instructions-builder";
import LocationBuilder from "./location/location-builder";
import SignatureBuilder from "./signature/signature-builder";
import GlobalListBuilder from "./globalList/globalList-builder";
import { TEXT_DIRECTION } from "../../../../../../helper/helperFunctions";

const ResponseSettings = ({
  updatedDuplicateForm,
  setUpdatedDuplicateForm,
  showDlg,
  secondaryAction,
  updateQuestion,
  actionItem,
  question,
  newQuestion,
  mcqId,
  form,
  setForm,
}) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(showDlg);
  }, [showDlg]);

  const getBuilder = (updateQuestion, actionItem, question) => {
    return (
      <>
        {question?.type === "COMPLEX_MULTIPLE_CHOICE" ? (
          <MultipleChoiceBuilder
            updatedDuplicateForm={updatedDuplicateForm}
            setUpdatedDuplicateForm={setUpdatedDuplicateForm}
            updateQuestion={updateQuestion}
            actionItem={actionItem}
            question={question}
            mcqId={mcqId}
            form={form}
            setForm={setForm}
            newQuestion={newQuestion}
          />
        ) : question?.type === "text" ? (
          <TextBuilder
            updateQuestion={updateQuestion}
            actionItem={actionItem}
            question={question}
          />
        ) : question?.type === "number" ? (
          <NumberBuilder
            updateQuestion={updateQuestion}
            actionItem={actionItem}
            question={question}
          />
        ) : question?.type === "checkbox" ? (
          <CheckboxBuilder
            updateQuestion={updateQuestion}
            actionItem={actionItem}
            question={question}
          />
        ) : question?.type === "date time" ? (
          <DateTimeBuilder
            updateQuestion={updateQuestion}
            actionItem={actionItem}
            question={question}
          />
        ) : question?.type === "image" ? (
          <ImageBuilder
            updateQuestion={updateQuestion}
            actionItem={actionItem}
            question={question}
          />
        ) : question?.type === "pdf" ? (
          <PDFBuilder
            updateQuestion={updateQuestion}
            actionItem={actionItem}
            question={question}
          />
        ) : question?.type === "slider" ? (
          <SliderBuilder
            updateQuestion={updateQuestion}
            actionItem={actionItem}
            question={question}
          />
        ) : question?.type === "instructions" ? (
          <InstructionsBuilder
            updateQuestion={updateQuestion}
            actionItem={actionItem}
            question={question}
          />
        ) : question?.type === "location" ? (
          <LocationBuilder
            updateQuestion={updateQuestion}
            actionItem={actionItem}
            question={question}
          />
        ) : question?.type === "signature" ? (
          <SignatureBuilder
            updateQuestion={updateQuestion}
            actionItem={actionItem}
            question={question}
          />
        ) : question?.name === "Dropdown" ? (
          <GlobalListBuilder
            updateQuestion={updateQuestion}
            actionItem={actionItem}
            question={question}
          />
        ) : (
          ""
        )}
      </>
    );
  };

  return (
    <DynamicModal
      customClasses={`modal-drawer modal-sp ${
        TEXT_DIRECTION() === "rtl" ? "left-direction" : ""
      }`}
      show={show}
      primaryAction={() => console.log("primary called")}
      secondaryAction={secondaryAction}
    >
      {getBuilder(updateQuestion, actionItem, question)}
    </DynamicModal>
  );
};

export default ResponseSettings;
