import React, { useState, useEffect } from "react";
import IconCloseDialog from "../../assets/images/icon-close-dialog.svg";
import { Modal, ModalBody } from "reactstrap";
import { Link, useParams } from "react-router-dom";
import I18n from "../../pages/private/languageSelector";

const DialogModal = ({
  showDlg,
  iconCloseDlg,
  secondaryAction,
  primaryAction,
  primaryActionTitle,
  secondryActionTitle,
  primaryActionClasses,
  clearActions,
  content,
  title,
  classes,
  size,
}) => {
  const { id } = useParams();
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(showDlg);
  }, [showDlg]);

  return (
    <Modal
      isOpen={show}
      toggle={() => secondaryAction()}
      size={size ? size : "sm"}
      className={classes}
    >
      {iconCloseDlg && (
        <Link
          to="#"
          className="modal-close px-3 py-2"
          onClick={() => secondaryAction()}
        >
          <img src={IconCloseDialog} alt="IconCloseDialog" />
        </Link>
      )}
      <ModalBody>
        <div className="modal-title text-center">
          <strong>{title}</strong>
        </div>
        {content}

        <div className="btn-bar d-flex justify-content-center mt-3">
          {secondryActionTitle && (
            <button
              className={`btn mx-1 btn-secondary`}
              onClick={() => clearActions()}
            >
              {secondryActionTitle === "Clear" ? (
                <I18n lng={"global.Clear"}></I18n>
              ) : (
                secondryActionTitle
              )}
            </button>
          )}
          <button
            className={`btn ${
              primaryActionClasses ? primaryActionClasses : "btn-primary"
            }`}
            onClick={() => primaryAction(id)}
          >
            {primaryActionTitle}
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default DialogModal;
