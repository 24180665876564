import React from "react";
import "../../assets/css/auth.min.css";
import { Link } from "react-router-dom";
import I18n from "../private/languageSelector";

const TermsAndConditions = () => {
  return (
    <div className="auth-wrapper">
      <div className="auth-title">
        <h1 className="font-weight-light font-primary">
          <I18n lng="global.TermsAndConditions" />
        </h1>
      </div>
      <div className="btn-wrap text-center">
        <Link
          to="/signup"
          className="btn btn-primary"
          onClick={() => {
            window.close();
          }}
        >
          <I18n lng="global.Close" />
        </Link>
      </div>
    </div>
  );
};
export default TermsAndConditions;
