import React, { useEffect, useState } from "react";
import _ from "lodash";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import I18n, { i18n } from "../../../languageSelector";
import Multiselect from "multiselect-react-dropdown";
import DynamicModal from "../../../../../components/dialogs";
import IconDocBoard from "../../../../../assets/images/icon-doc-board.svg";
import {
  getForm,
  editDispatchRule,
  deleteAssignUser,
  deleteDispatchRule,
  assignFormDispatch,
  getAssignUsersByFormId,
} from "../../../../../redux/reducers/form/form.reducer";
import { TEXT_DIRECTION, canAccessTheModule } from "../../../../../helper/helperFunctions";
import CheckBox from "../../../../../components/form-input/checkbox.component";
import AlertSigupDialog from "../../../../../components/dialogs/alertSignupDialog";

const FormDispatchRuleDialog = ({
  allDispatchRules,
  edit,
  show,
  onHide,
  formId,
  getForm,
  allUsers,
  ruleForEdit,
  currentRole,
  dispatchedUsers,
  formForDispatch,
  editDispatchRule,
  assignFormDispatch,
  deleteDispatchRule,
  getAssignUsersByFormId,
  roleAccess,
}) => {
  const [managerUsers, setManagerUsers] = useState([]);
  const [inspectorUsers, setInspectorUsers] = useState([]);
  const [selectedManagers, setSelectedManagers] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedInspectors, setSelectedInspectors] = useState([]);
  const [inspectorUsersToShow, setInspectorUsersToShow] = useState([]);
  const [showMultielect, setShowMultiSelect] = useState(true);

  const selectedIdsInOtherDispatchRules = new Set();
  allDispatchRules.forEach((rule) => {
    if (rule !== ruleForEdit) {
      rule.assignees.forEach((assignee) => {
        selectedIdsInOtherDispatchRules.add(assignee.id);
      });
    }
  });

  const usersIncludingCurrentRule = inspectorUsersToShow.filter(
    (inspector) => !selectedIdsInOtherDispatchRules.has(inspector.id) || ruleForEdit?.assignees.some((assignee) => assignee.id === inspector.id)
  );

  useEffect(() => {
    if (formId && currentRole !== "user" && show) {
      getForm(formId);
    }
    setSelectedInspectors([]);
    // eslint-disable-next-line
  }, [show, formId]);

  useEffect(() => {
    let managers = [];
    let inspectors = [];

    if (allUsers?.length) {
      inspectors = allUsers
        .filter((user) => user?.isEmailVerified || user?.role === "admin")
        .filter((item) => {
          return item?.roleSetting?.inspectorRole;
        });
      managers = allUsers
        .filter((user) => user?.isEmailVerified || user?.role === "admin")
        .filter((item) => {
          return item?.roleSetting?.managerRole || item.role === "admin";
        });
    }
    if (managers?.length) {
      setSelectedManagers([
        {
          label: managers[0]?.fullName,
          value: managers[0]?.id,
          approver: true,
        },
      ]);
    }
    setInspectorUsers([...inspectors]);
    setManagerUsers([...managers]);
  }, [allUsers, show]);

  useEffect(() => {
    if (edit) {
      setInspectorUsersToShow([...inspectorUsers]);
    } else if (!edit && dispatchedUsers?.length && inspectorUsers?.length) {
      let result = _(inspectorUsers).differenceBy(dispatchedUsers, "id", "fullName").map(_.partial(_.pick, _, "id", "fullName")).value();

      setInspectorUsersToShow(result);
    } else {
      setInspectorUsersToShow([...inspectorUsers]);
    }
  }, [dispatchedUsers, inspectorUsers, show, edit]);

  useEffect(() => {
    if (edit && ruleForEdit) {
      let inspectorsForEdit = ruleForEdit?.assignees?.map((item) => {
        return { label: item?.fullName, value: item?.id };
      });

      // Filtering selected and approved managers
      let managersToShow = [];
      ruleForEdit?.approvedManagers?.map((item) => {
        return managerUsers?.filter((subItem) => {
          return item?.id === subItem?.id
            ? managersToShow.push({
                ...subItem,
                label: subItem?.fullName,
                value: subItem?.id,
                approver: true,
              })
            : null;
        });
      });

      // Filtering selected and non-approved managers
      ruleForEdit?.managers?.map((item) => {
        return managerUsers?.filter((subItem) => {
          return item?.id === subItem?.id
            ? managersToShow.some((item) => subItem.id === item.id)
              ? null
              : managersToShow.push({
                  ...subItem,
                  label: subItem?.fullName,
                  value: subItem?.id,
                  approver: false,
                })
            : null;
        });
      });
      setSelectedInspectors([...inspectorsForEdit]);
      setSelectedManagers(managersToShow);
    }
  }, [edit, managerUsers, ruleForEdit]);

  useEffect(() => {
    // Keeping atleast one manager in approver list
    let defaultApprovedManager;
    if (selectedManagers && selectedManagers.length) {
      defaultApprovedManager = selectedManagers.filter((item) => item.approver);
    }
    if (defaultApprovedManager && defaultApprovedManager.length === 0) {
      selectedManagers[0] = { ...selectedManagers[0], approver: true };
      setSelectedManagers([...selectedManagers]);
    }
    // eslint-disable-next-line
  }, [selectedManagers]);

  const dispatchRuleCreateHandler = () => {
    if (formForDispatch && selectedManagers?.length && selectedInspectors?.length) {
      let dispatchRules = {
        managers: selectedManagers.map((item) => {
          return item.value;
        }),
        approvedManagers: selectedManagers
          .filter((item) => item.approver)
          .map((subItem) => {
            return subItem.value;
          }),
        assignees: selectedInspectors.map((item) => {
          return item.value;
        }),
      };

      let formManagements = {
        formDetail: { ...formForDispatch.formDetail },
        formId: formForDispatch.id,
        assignee: selectedInspectors.map((item) => {
          return item.value;
        }),
        dispatchRules,
      };
      assignFormDispatch(formManagements)
        .then(() => {
          getForm(formId);
          getAssignUsersByFormId(formId);
          setSelectedInspectors([]);
          setSelectedManagers([]);
          onHide();
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message);
        });
    }
  };

  const dispatchRuleEditHandler = () => {
    if (edit && formForDispatch && selectedManagers?.length && selectedInspectors?.length) {
      let dispatchRule = {
        managers: selectedManagers.map((item) => {
          return item.value;
        }),
        approvedManagers: selectedManagers
          .filter((item) => item.approver)
          .map((subItem) => {
            return subItem.value;
          }),
        assignees: selectedInspectors.map((item) => {
          return item.value;
        }),
        formId: formForDispatch?.id,
        formDetail: formForDispatch?.formDetail,
        companyId: formForDispatch?.company?.id,
        dispatchNumber: formForDispatch?.company?.inspectionNumber,
      };

      editDispatchRule(ruleForEdit?.id, { ...dispatchRule })
        .then(() => {
          getForm(formId);
          getAssignUsersByFormId(formId);
          setSelectedInspectors([]);
          setSelectedManagers([]);
          onHide();
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message);
        });
    }
  };

  const dispatchRuleDeleteHandler = () => {
    deleteDispatchRule(ruleForEdit?.id)
      .then(() => {
        getForm(formId);
        setOpenDeleteDialog(false);
        getAssignUsersByFormId(formId);
        setSelectedInspectors([]);
        setSelectedManagers([]);
        onHide();
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  const handleSelectInspectors = (selectedList) => {
    setSelectedInspectors([...selectedList]);
  };
  const handleSelectManagers = (selectedList, selectedItem) => {
    let selectedUser = selectedManagers.filter((item) => item.id === selectedItem.value);
    if (selectedUser.length) {
      if (!selectedUser[0].approver) {
        setSelectedManagers([...selectedList]);
      } else {
        setShowMultiSelect(false);
        setSelectedManagers([...selectedManagers]);
        toast.error(i18n("Form.NoAccessToRemoveApproverUser"));
        setTimeout(() => {
          setShowMultiSelect(true);
        }, 0);
      }
    } else {
      setSelectedManagers([...selectedList]);
    }
  };
  return (
    <>
      <DynamicModal show={show} secondaryAction={onHide} customClasses="modal-drawer" modalContentClass="bg-white">
        <div className="notification">
          <div className="form-view-wrapper dispatch-card">
            <form className="dispatch-wrapper text-center">
              <div className="form-header">
                <div className="form-title">
                  <strong>
                    <img src={IconDocBoard} alt="Doc Board" /> {formForDispatch?.formDetail?.title}
                  </strong>
                </div>
              </div>
            </form>
          </div>
          {/* Select inspectors to dispatch */}
          <div className="card dispatch-card pb-3 pt-2 mb-2">
            <h6 className="mb-0">
              <I18n lng="Form.Inspectors" />
            </h6>
            <small className="text-muted mb-2">
              <I18n lng="Form.InspectorsWithAccessCanPerformInspections" />
            </small>
            <Multiselect
              displayValue="label"
              onRemove={handleSelectInspectors}
              onSelect={handleSelectInspectors}
              options={usersIncludingCurrentRule.map((item) => {
                return { label: item.fullName, value: item.id };
              })}
              showCheckbox
              placeholder={i18n("Form.SelectInspectors")}
              selectedValues={selectedInspectors}
            />
            {selectedInspectors && selectedInspectors.length ? (
              <div className="mt-2">
                {selectedInspectors.map((item, index) => {
                  return (
                    <div className="mt-1 ml-1" key={index}>
                      {item.label}
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
          {/* Select managers to dispatch */}
          <div className="card dispatch-card pb-3 pt-2 mb-2">
            <h6 className="mb-0">
              <I18n lng="Form.Commissioners" />
            </h6>
            <small className="text-muted mb-2">
              <I18n lng="Form.AssociatedManagersReportsAutomatically" />
            </small>
            {showMultielect && (
              <Multiselect
                showCheckbox
                displayValue="label"
                placeholder={i18n("Form.SelectManagers")}
                onRemove={handleSelectManagers}
                onSelect={handleSelectManagers}
                selectedValues={selectedManagers}
                options={managerUsers.map((item) => {
                  return {
                    value: item.id,
                    label: item.fullName,
                    approver: false,
                  };
                })}
                disable={edit && !roleAccess?.form?.access?.formDispatch}
              />
            )}
            <div className="mt-2">
              {selectedManagers && selectedManagers.length
                ? selectedManagers.map((item, index) => {
                    return (
                      <div className="mt-1 ml-1" key={index}>
                        {item.label}
                      </div>
                    );
                  })
                : []}
            </div>
          </div>
          {/* Select approver from selected managers */}
          <div className="card dispatch-card pb-3 pt-2 mb-2">
            <h6 className="mb-0">
              <I18n lng="Form.Approvers" />
            </h6>
            <small className="text-muted mb-2">
              <I18n lng="Form.ManagersWhoApprove/RejectReports" />
            </small>

            <div className="mt-2">
              {selectedManagers && selectedManagers.length
                ? selectedManagers?.map((item, index) => {
                    return (
                      <div className={`${TEXT_DIRECTION() === "rtl" ? "mr-3" : ""}`}>
                        <div className="row">
                          <div className="col-1" style={TEXT_DIRECTION() === "rtl" ? { marginRight: "4px" } : { marginLeft: "4px" }}>
                            <CheckBox
                              key={index}
                              name={index}
                              checked={item.approver}
                              disabled={edit && !roleAccess?.form?.access?.approvalRights}
                              className={
                                edit && !roleAccess?.form?.access?.approvalRights
                                  ? "custom-control custom-control-disabled custom-checkbox"
                                  : "custom-control custom-checkbox"
                              }
                              onClick={(e) => {
                                let newOption;
                                newOption = {
                                  ...item,
                                  approver: e.target.checked,
                                };
                                selectedManagers[index] = newOption;

                                setSelectedManagers([...selectedManagers]);
                              }}
                            />
                          </div>
                          <div className="col-10">
                            <label> {item.label}</label>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
          {edit
            ? canAccessTheModule(
                roleAccess?.form?.access?.deleteDispatch,
                <div className="card dispatch-card pb-1 pt-1">
                  <button className="btn" style={{ color: "red" }} onClick={() => setOpenDeleteDialog(true)}>
                    <I18n lng="global.Delete" />
                  </button>
                </div>
              )
            : null}
        </div>

        <div className="text-center btn-bar">
          <button className="btn btn-white p-2 mt-3" onClick={() => onHide()}>
            <I18n lng="global.Cancel" />
          </button>
          <button
            className="btn btn-primary btn-icon p-2 mt-3 ml-3 mr-3"
            disabled={!selectedInspectors?.length || !selectedManagers?.length}
            onClick={() => {
              if (edit) {
                dispatchRuleEditHandler();
              } else {
                dispatchRuleCreateHandler();
              }
            }}
          >
            <I18n lng="global.Submit" />
          </button>
        </div>
      </DynamicModal>
      <AlertSigupDialog
        showDlg={openDeleteDialog}
        classes="modal-alert"
        content={
          <p>
            <br />
            <br />
            <I18n lng="Form.DeleteDispatchRule" />
          </p>
        }
        primaryActionTitle={<I18n lng="global.Delete" />}
        secondryActionTitle={<I18n lng="global.Cancel" />}
        primaryActionClasses={"btn-danger"}
        secondryActionClasses={"btn-white"}
        primaryAction={() => dispatchRuleDeleteHandler()}
        secondaryAction={() => setOpenDeleteDialog(false)}
      />
    </>
  );
};
const mapStateToProps = ({ authentication, user, form }) => ({
  allUsers: user.allUsers,
  user: authentication.user,
  assignUsers: form.assignUsers,
  formForDispatch: form.formToEdit,
  currentRole: authentication.currentRole,
  roleAccess: authentication.userRoleAccess,
});
const mapDispatchToProps = {
  getForm,
  editDispatchRule,
  deleteAssignUser,
  assignFormDispatch,
  deleteDispatchRule,
  getAssignUsersByFormId,
};
export default connect(mapStateToProps, mapDispatchToProps)(FormDispatchRuleDialog);
