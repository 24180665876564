import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./form-ui-helper";

const FormsUIContext = createContext();

export function useFormsUIContext() {
    return useContext(FormsUIContext);
}

export const FormsUIConsumer = FormsUIContext.Consumer;

export function FormsUIProvider({ formsUIEvents, children }) {
    const [queryParams, setQueryParamsBase] = useState(initialFilter);
    const [ids, setIds] = useState([]);
    const setQueryParams = useCallback((nextQueryParams) => {
        setQueryParamsBase((prevQueryParams) => {
            if (isFunction(nextQueryParams)) {
                nextQueryParams = nextQueryParams(prevQueryParams);
            }

            if (isEqual(prevQueryParams, nextQueryParams)) {
                return prevQueryParams;
            }

            return nextQueryParams;
        });
    }, []);

    const initInvoice = {
        id: undefined,
        englishName: "",
        arabicName: "",
        price: 0.0,
        catagory: "",
        serviceIsActive: "",
        serviceIsTaxable: "",
        englishDetails: "",
        arabicDetails: "",
        detailedInvoice: "",
        paymentDate: "",
        barcode: "",
    };

    const value = {
        queryParams,
        setQueryParamsBase,
        ids,
        setIds,
        setQueryParams,
        initInvoice,
        openEditForm: formsUIEvents.openEditForm,
        openFormView: formsUIEvents.openFormView,
        openFormStart: formsUIEvents.openFormStart,
        openArchvieFormDialog: formsUIEvents.openArchvieFormDialog,
        openFormDispatch: formsUIEvents.openFormDispatch,
    };

    return ( <
        FormsUIContext.Provider value = { value } > { " " } { children } { " " } <
        /FormsUIContext.Provider>
    );
}