import React, { useState } from "react";
import MultipleChoiceReportPreview from "../reports-previews/multipleChoice-reportPreview/multipleChoiceReportsPreview";
import NumberReportPreview from "../reports-previews/number-reportPreview/numberReportPreview";
import TextAnswerReportPreview from "../reports-previews/textAnswer-reportPreview/textAnswerReportPreview";
import CheckboxReportPreview from "../reports-previews/checkbox-reportPreview/checkbooxReportPreview";
import DateReportPreview from "../reports-previews/date-reportPreview/dateReportPreview";
import InstructionReportPreview from "../reports-previews/instruction-reportPreview/instructionReportPreview";
import LocationReportPreview from "../reports-previews/location-reportPreview/locationReportPreview";
import SignatureReportPreview from "../reports-previews/signature-reportsPreview/signatureReportPreview";
import ImageReportPeview from "../reports-previews/image-reportPreview/imageReportPreview";
import PdfReportPreview from "../reports-previews/pdf-reportPReview/pdfReportPreview";
import SliderReportPreview from "./slider-reportPreview/sliderReportPreview";
import GlobalListReportPreview from "./globalList-reportPreview/globalListReportPreview";
import ImageViewDialog from "../../../../components/dialogs/image-view-dialog";

export const getPreview = (
  setShowQuestionPreview,
  type,
  question,
  addUpdateAnwser,
  getAnswer,
  getOldAnswer,
  getUpdatedAnswer,
  reportHistory,
  index,
  showAndHide,
  setImageIndex,
  setShowImageSrc,
  setShowImageDialog,
  setShowSection,
  setTotalAnswered,
  imageCountData,
  setFailItems,
  failItems,
  failStatus
) => {
  return type === "COMPLEX_MULTIPLE_CHOICE" ? (
    <MultipleChoiceReportPreview
      setShowQuestionPreview={setShowQuestionPreview}
      question={question}
      addUpdateAnwser={addUpdateAnwser}
      getAnswer={getAnswer}
      getOldAnswer={getOldAnswer}
      getUpdatedAnswer={getUpdatedAnswer}
      reportHistory={reportHistory}
      index={index}
      showAndHide={showAndHide}
      setImageIndex={setImageIndex}
      setShowImageSrc={setShowImageSrc}
      setShowImageDialog={setShowImageDialog}
      setShowSection={setShowSection}
      setTotalAnswered={setTotalAnswered}
      imageCountData={imageCountData}
      setFailItems={setFailItems}
      failItems={failItems}
      failStatus={failStatus}
    />
  ) : type === "text" ? (
    <TextAnswerReportPreview
      setShowQuestionPreview={setShowQuestionPreview}
      question={question}
      addUpdateAnwser={addUpdateAnwser}
      getAnswer={getAnswer}
      getOldAnswer={getOldAnswer}
      getUpdatedAnswer={getUpdatedAnswer}
      reportHistory={reportHistory}
      index={index}
      showAndHide={showAndHide}
      setImageIndex={setImageIndex}
      setShowImageSrc={setShowImageSrc}
      setShowImageDialog={setShowImageDialog}
      setShowSection={setShowSection}
      setTotalAnswered={setTotalAnswered}
      imageCountData={imageCountData}
      setFailItems={setFailItems}
      failItems={failItems}
      failStatus={failStatus}
    />
  ) : type === "number" ? (
    <NumberReportPreview
      setShowQuestionPreview={setShowQuestionPreview}
      question={question}
      addUpdateAnwser={addUpdateAnwser}
      getAnswer={getAnswer}
      getOldAnswer={getOldAnswer}
      getUpdatedAnswer={getUpdatedAnswer}
      reportHistory={reportHistory}
      index={index}
      showAndHide={showAndHide}
      setImageIndex={setImageIndex}
      setShowImageSrc={setShowImageSrc}
      setShowImageDialog={setShowImageDialog}
      setShowSection={setShowSection}
      setTotalAnswered={setTotalAnswered}
      imageCountData={imageCountData}
      setFailItems={setFailItems}
      failItems={failItems}
      failStatus={failStatus}
    />
  ) : type === "checkbox" ? (
    <CheckboxReportPreview
      setShowQuestionPreview={setShowQuestionPreview}
      question={question}
      addUpdateAnwser={addUpdateAnwser}
      getAnswer={getAnswer}
      getOldAnswer={getOldAnswer}
      getUpdatedAnswer={getUpdatedAnswer}
      reportHistory={reportHistory}
      index={index}
      showAndHide={showAndHide}
      setImageIndex={setImageIndex}
      setShowImageSrc={setShowImageSrc}
      setShowImageDialog={setShowImageDialog}
      setShowSection={setShowSection}
      setTotalAnswered={setTotalAnswered}
      imageCountData={imageCountData}
      setFailItems={setFailItems}
      failItems={failItems}
      failStatus={failStatus}
    />
  ) : type === "date time" ? (
    <DateReportPreview
      setShowQuestionPreview={setShowQuestionPreview}
      question={question}
      addUpdateAnwser={addUpdateAnwser}
      getAnswer={getAnswer}
      getOldAnswer={getOldAnswer}
      getUpdatedAnswer={getUpdatedAnswer}
      reportHistory={reportHistory}
      index={index}
      showAndHide={showAndHide}
      setImageIndex={setImageIndex}
      setShowImageSrc={setShowImageSrc}
      setShowImageDialog={setShowImageDialog}
      setShowSection={setShowSection}
      setTotalAnswered={setTotalAnswered}
      imageCountData={imageCountData}
      setFailItems={setFailItems}
      failItems={failItems}
      failStatus={failStatus}
    />
  ) : type === "image" ? (
    <ImageReportPeview
      setShowQuestionPreview={setShowQuestionPreview}
      question={question}
      addUpdateAnwser={addUpdateAnwser}
      getAnswer={getAnswer}
      getOldAnswer={getOldAnswer}
      getUpdatedAnswer={getUpdatedAnswer}
      reportHistory={reportHistory}
      index={index}
      showAndHide={showAndHide}
      setImageIndex={setImageIndex}
      setShowImageSrc={setShowImageSrc}
      setShowImageDialog={setShowImageDialog}
      setShowSection={setShowSection}
      setTotalAnswered={setTotalAnswered}
      imageCountData={imageCountData}
      setFailItems={setFailItems}
      failItems={failItems}
      failStatus={failStatus}
    />
  ) : type === "pdf" ? (
    <PdfReportPreview
      setShowQuestionPreview={setShowQuestionPreview}
      question={question}
      addUpdateAnwser={addUpdateAnwser}
      getAnswer={getAnswer}
      getOldAnswer={getOldAnswer}
      getUpdatedAnswer={getUpdatedAnswer}
      reportHistory={reportHistory}
      index={index}
      showAndHide={showAndHide}
      setImageIndex={setImageIndex}
      setShowImageSrc={setShowImageSrc}
      setShowImageDialog={setShowImageDialog}
      setShowSection={setShowSection}
      setTotalAnswered={setTotalAnswered}
      imageCountData={imageCountData}
      setFailItems={setFailItems}
      failItems={failItems}
      failStatus={failStatus}
    />
  ) : showAndHide?.content?.instructions && type === "instructions" ? (
    <InstructionReportPreview
      question={question}
      addUpdateAnwser={addUpdateAnwser}
      getAnswer={getAnswer}
      getOldAnswer={getOldAnswer}
      getUpdatedAnswer={getUpdatedAnswer}
      reportHistory={reportHistory}
      index={index}
      showAndHide={showAndHide}
      setImageIndex={setImageIndex}
      setShowImageSrc={setShowImageSrc}
      setShowImageDialog={setShowImageDialog}
      setShowSection={setShowSection}
      setTotalAnswered={setTotalAnswered}
      imageCountData={imageCountData}
      setFailItems={setFailItems}
      failItems={failItems}
      failStatus={failStatus}
    />
  ) : type === "location" ? (
    <LocationReportPreview
      setShowQuestionPreview={setShowQuestionPreview}
      question={question}
      addUpdateAnwser={addUpdateAnwser}
      getAnswer={getAnswer}
      getOldAnswer={getOldAnswer}
      getUpdatedAnswer={getUpdatedAnswer}
      reportHistory={reportHistory}
      index={index}
      showAndHide={showAndHide}
      setImageIndex={setImageIndex}
      setShowImageSrc={setShowImageSrc}
      setShowImageDialog={setShowImageDialog}
      setShowSection={setShowSection}
      setTotalAnswered={setTotalAnswered}
      imageCountData={imageCountData}
      setFailItems={setFailItems}
      failItems={failItems}
      failStatus={failStatus}
    />
  ) : type === "signature" ? (
    <SignatureReportPreview
      setShowQuestionPreview={setShowQuestionPreview}
      question={question}
      addUpdateAnwser={addUpdateAnwser}
      getAnswer={getAnswer}
      getOldAnswer={getOldAnswer}
      getUpdatedAnswer={getUpdatedAnswer}
      reportHistory={reportHistory}
      index={index}
      showAndHide={showAndHide}
      setImageIndex={setImageIndex}
      setShowImageSrc={setShowImageSrc}
      setShowImageDialog={setShowImageDialog}
      setShowSection={setShowSection}
      setTotalAnswered={setTotalAnswered}
      imageCountData={imageCountData}
      setFailItems={setFailItems}
      failItems={failItems}
      failStatus={failStatus}
    />
  ) : type === "slider" ? (
    <SliderReportPreview
      setShowQuestionPreview={setShowQuestionPreview}
      question={question}
      addUpdateAnwser={addUpdateAnwser}
      getAnswer={getAnswer}
      getOldAnswer={getOldAnswer}
      getUpdatedAnswer={getUpdatedAnswer}
      reportHistory={reportHistory}
      index={index}
      showAndHide={showAndHide}
      setImageIndex={setImageIndex}
      setShowImageSrc={setShowImageSrc}
      setShowImageDialog={setShowImageDialog}
      setShowSection={setShowSection}
      setTotalAnswered={setTotalAnswered}
      imageCountData={imageCountData}
      setFailItems={setFailItems}
      failItems={failItems}
      failStatus={failStatus}
    />
  ) : type === "DROPDOWN" ? (
    <GlobalListReportPreview
      setShowQuestionPreview={setShowQuestionPreview}
      question={question}
      addUpdateAnwser={addUpdateAnwser}
      getAnswer={getAnswer}
      getOldAnswer={getOldAnswer}
      getUpdatedAnswer={getUpdatedAnswer}
      reportHistory={reportHistory}
      index={index}
      showAndHide={showAndHide}
      setImageIndex={setImageIndex}
      setShowImageSrc={setShowImageSrc}
      setShowImageDialog={setShowImageDialog}
      setShowSection={setShowSection}
      setTotalAnswered={setTotalAnswered}
      imageCountData={imageCountData}
      setFailItems={setFailItems}
      failItems={failItems}
      failStatus={failStatus}
    />
  ) : (
    ""
  );
};
const QuestionPreview = ({
  question,
  addUpdateAnwser,
  getAnswer,
  getOldAnswer,
  getUpdatedAnswer,
  reportLogs,
  reportHistory,
  index,
  showAndHide,
  setShowSection,
  setTotalAnswered,
  imageCountData,
  setFailItems,
  failItems,
  failStatus,
}) => {
  const [imageIndex, setImageIndex] = useState(0);
  const [showImageSrc, setShowImageSrc] = useState(false);
  const [showImageDialog, setShowImageDialog] = useState(false);
  const [showQuestionPreview, setShowQuestionPreview] = useState(true);
  return (
    <>
      {reportHistory ? (
        showQuestionPreview ? (
          <ul>
            {getPreview(
              setShowQuestionPreview,
              question?.type,
              question,
              addUpdateAnwser,
              getAnswer,
              getOldAnswer,
              getUpdatedAnswer,
              reportHistory,
              index,
              showAndHide,
              setImageIndex,
              setShowImageSrc,
              setShowImageDialog,
              setShowSection,
              setTotalAnswered,
              imageCountData,
              setFailItems,
              failItems,
              failStatus
            )}
          </ul>
        ) : null
      ) : (
        <ul>
          {getPreview(
            setShowQuestionPreview,
            question?.type,
            question,
            addUpdateAnwser,
            getAnswer,
            getOldAnswer,
            getUpdatedAnswer,
            reportHistory,
            index,
            showAndHide,
            setImageIndex,
            setShowImageSrc,
            setShowImageDialog,
            setShowSection,
            setTotalAnswered,
            imageCountData,
            setFailItems,
            failItems,
            failStatus
          )}
        </ul>
      )}

      <ImageViewDialog
        imageSrc={showImageSrc}
        imageIndex={imageIndex}
        showDlg={showImageDialog}
        secondaryAction={() => setShowImageDialog(false)}
      />
    </>
  );
};
export default QuestionPreview;
