import React, { useState } from "react";
import I18n from "../../../../../languageSelector";
import GlobalListPreview from "./globalList-preview";
import GlobalListSettings from "./globalList-settings";

const GlobalListBuilder = ({ updateQuestion, actionItem, question }) => {
  const [selected, setSelected] = useState("SETTINGS");

  return (
    <>
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <button
            onClick={() => setSelected("SETTINGS")}
            className={`nav-link ${selected === "SETTINGS" ? "active" : ""}`}
          >
            <I18n lng="global.Settings" />
          </button>
        </li>
        <li className="nav-item">
          <button
            onClick={() => setSelected("PREVIEW")}
            className={`nav-link ${selected === "PREVIEW" ? "active" : ""}`}
          >
            <I18n lng="Form.Preview" />
          </button>
        </li>
      </ul>
      {selected === "SETTINGS" ? (
        <GlobalListSettings
          updateQuestion={updateQuestion}
          actionItem={actionItem}
          question={question}
        />
      ) : (
        <GlobalListPreview actionItem={actionItem} question={question} />
      )}
    </>
  );
};

export default GlobalListBuilder;
