// eslint-disable-next-line
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import english from "./../private/english.json";
import arabic from "./../private/arabic.json";
let language = localStorage.getItem("language");

const I18n = ({ lng, user }) => {
  let getlng = lng.split(".");

  // const [language, setLanguage] = useState("ENGLISH");
  // const userLanguage = user?.language;
  // const localStorageLanguage = localStorage.getItem("language");

  // useEffect(() => {
  //   if (userLanguage) {
  //     setLanguage(userLanguage);
  //   } else if (localStorageLanguage) {
  //     setLanguage(localStorageLanguage);
  //   }
  // }, [userLanguage, localStorageLanguage]);

  useEffect(() => {
    language = user?.language
      ? user?.language
      : localStorage.getItem("language")
      ? localStorage.getItem("language")
      : "ENGLISH";
  }, [lng, user, user?.language]);

  return language === "ENGLISH" || language === null || language === undefined
    ? `${
        english[getlng.length ? getlng[0] : ""][getlng.length ? getlng[1] : ""]
      }`
    : `${
        arabic[getlng.length ? getlng[0] : ""][getlng.length ? getlng[1] : ""]
      }`;
};

const mapStateToProps = ({ authentication }) => ({
  user: authentication.user,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(I18n);

export const i18n = (lng) => {
  let getlng = lng.split(".");
  return language === "ENGLISH" || language === null || language === undefined
    ? `${
        english[getlng.length ? getlng[0] : ""][getlng.length ? getlng[1] : ""]
      }`
    : `${
        arabic[getlng.length ? getlng[0] : ""][getlng.length ? getlng[1] : ""]
      }`;
};
