import React, { useState, useEffect } from "react";
import {
  numberFailHandler,
  NUMBER_CONDITION,
  TEMPERATURE_CONDITION,
  TEXT_DIRECTION,
} from "../../../../../helper/helperFunctions";
import I18n from "../../../languageSelector";
import ReportPdfImages from "../report-pdf-images/reportPdfImages.js";

const NumberPdfPreview = ({
  getAnswer = (uuid) => {
    console.log(uuid);
    return undefined;
  },
  question,
  setFailItems,
  failStatus,
  hideAndShow,
  setImageIndex,
  setShowImageSrc,
  setShowImageDialog,
  setShowSection,
  setTotalAnswered,
  showDocumentMedia,
  imageCountData,
}) => {
  const [note, setNote] = useState("");
  const [photo, setPhoto] = useState("");
  const [answerValue, setAnswerValue] = useState(undefined);
  const [imageCount, setImageCount] = useState(0);

  useEffect(() => {
    if (question && question.questionUuid) {
      let answer = getAnswer(question.questionUuid);
      if (answer) {
        setAnswerValue(answer.value);
        setNote(answer.note);
        setPhoto(answer.image);

        if (answer?.value || answer?.note || answer?.image) {
          setShowSection(true);
          setTotalAnswered((prev) => prev + 1);
        }
      }
      if (question.settings.isFail && failStatus) {
        // setFailItems((preState) => preState + 1);
      }
    }
    // eslint-disable-next-line
  }, [question]);

  useEffect(() => {
    if (question && question.questionUuid && imageCountData?.length) {
      let data = imageCountData.filter((item) => {
        return item.questionId === question.questionUuid;
      });
      if (data.length) {
        setImageCount(data[0].imageCountStart);
      }
    }
  }, [question, imageCountData]);

  return (
    <>
      {failStatus && numberFailHandler(question, answerValue) ? (
        <div className="table-body">
          <div
            style={{ borderLeft: "0", borderRight: "0", borderTop: "0" }}
            className={`row table-row ml-0 mr-0   question ${
              question?.settings?.isFail ||
              numberFailHandler(question, answerValue)
                ? ""
                : "status-na"
            }`}
          >
            <div
              className={`col-lg-8 col-md-6 mb-3 table-column d-flex ${
                TEXT_DIRECTION() === "rtl" ? "pr-0" : "pl-0"
              }`}
            >
              <span
                className={` ${TEXT_DIRECTION() === "rtl" ? "ml-2" : "mr-2"}`}
              >
                {question?.questionNumber}.
              </span>
              {"   "}
              {"   "}
              {question?.settings?.questionText === "Add a new question" ? (
                <I18n lng="Form.AddANewQuestion" />
              ) : (
                question?.settings?.questionText
              )}
            </div>
            <div
              className="col-lg-4 col-md-6 table-column question-type text-primary"
              style={{ padding: "0 23px" }}
            >
              {question?.settings.numberType === "TEMPERATURE" ? (
                <p className="answer w-100">
                  <span
                    className={`${
                      numberFailHandler(question, answerValue)
                        ? "text-danger"
                        : "text-primary"
                    }`}
                  >
                    <div className="d-flex">
                      <p
                        style={
                          TEXT_DIRECTION() === "rtl"
                            ? { direction: "ltr" }
                            : null
                        }
                      >
                        <b>
                          {answerValue}{" "}
                          {question?.settings?.measurement === "FAHRENHEIT"
                            ? "˚F  "
                            : "˚C "}{" "}
                        </b>
                      </p>
                      <p
                        className={` ${
                          TEXT_DIRECTION() === "rtl" ? "mr-auto" : "ml-auto"
                        }`}
                        style={{
                          fontSize: "14px",
                          color: "#828282",
                          marginTop: "4px",
                        }}
                      >
                        {TEMPERATURE_CONDITION(question)}
                      </p>
                    </div>
                  </span>
                </p>
              ) : (
                <p className="answer w-100">
                  <span
                    className={`${
                      numberFailHandler(question, answerValue)
                        ? "text-danger"
                        : "text-primary"
                    }`}
                  >
                    <div className="d-flex">
                      <p
                        style={
                          TEXT_DIRECTION() === "rtl"
                            ? { direction: "ltr" }
                            : null
                        }
                      >
                        <b>{answerValue}</b>
                      </p>
                      <p
                        className={` ${
                          TEXT_DIRECTION() === "rtl" ? "mr-auto" : "ml-auto"
                        }`}
                        style={{
                          fontSize: "14px",
                          color: "#828282",
                          marginTop: "4px",
                        }}
                      >
                        {NUMBER_CONDITION(question)}
                      </p>
                    </div>
                  </span>
                </p>
              )}
              {note || photo.length ? (
                <div
                  className="table-column attachments-wrapper pb-3"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <>
                    <fieldset>
                      <legend
                        className={`${
                          TEXT_DIRECTION() === "rtl"
                            ? "legend-arabic"
                            : "legend"
                        }`}
                      >
                        <I18n lng="global.InspectorNotes" />
                      </legend>
                      {note ? (
                        <div className="notes">
                          <p>{note}</p>
                        </div>
                      ) : null}
                      {photo.length ? (
                        <div className="thumbnail-wrapper">
                          <ReportPdfImages
                            photo={photo}
                            imageCount={imageCount}
                            setImageIndex={setImageIndex}
                            setShowImageSrc={setShowImageSrc}
                            showDocumentMedia={showDocumentMedia}
                            setShowImageDialog={setShowImageDialog}
                          />
                        </div>
                      ) : null}
                    </fieldset>
                  </>
                </div>
              ) : null}
            </div>
          </div>
          {/* {failStatus ? <hr className="m-0" /> : ""} */}
        </div>
      ) : !failStatus && !question?.settings?.isFail ? (
        hideAndShow?.content?.all ? (
          <div className="table-body">
            <div
              className={`row table-row ml-0 mr-0   question ${
                question?.settings?.isFail ||
                numberFailHandler(question, answerValue)
                  ? "status-danger"
                  : "status-na"
              }`}
              style={{
                boxShadow: "0px 5px 5px rgba(0,0,0,.10)",
                backgroundColor: "#FFFFFF",
              }}
            >
              <div
                className={`col-lg-8 col-md-6 mb-3 table-column d-flex ${
                  TEXT_DIRECTION() === "rtl" ? "pr-0" : "pl-0"
                }`}
              >
                <span
                  className={` ${TEXT_DIRECTION() === "rtl" ? "ml-2" : "mr-2"}`}
                >
                  {question?.questionNumber}.
                </span>
                {"   "}
                {question?.settings?.questionText === "Add a new question" ? (
                  <I18n lng="Form.AddANewQuestion" />
                ) : (
                  question?.settings?.questionText
                )}
              </div>
              <div
                className="col-lg-4 col-md-6 table-column question-type text-primary"
                style={{ padding: "0 23px" }}
              >
                {question?.settings.numberType === "TEMPERATURE" ? (
                  <p className="answer w-100">
                    <span
                      className={`${
                        numberFailHandler(question, answerValue)
                          ? "text-danger"
                          : "text-primary"
                      }`}
                    >
                      <div className="d-flex">
                        <p
                          style={
                            TEXT_DIRECTION() === "rtl"
                              ? { direction: "ltr" }
                              : null
                          }
                        >
                          <b>
                            {answerValue}{" "}
                            {question?.settings?.measurement === "FAHRENHEIT"
                              ? "˚F  "
                              : "˚C "}{" "}
                          </b>
                        </p>
                        <p
                          className={` ${
                            TEXT_DIRECTION() === "rtl" ? "mr-auto" : "ml-auto"
                          }`}
                          style={{
                            fontSize: "14px",
                            color: "#828282",
                            marginTop: "4px",
                          }}
                        >
                          {TEMPERATURE_CONDITION(question)}
                        </p>
                      </div>
                    </span>
                  </p>
                ) : (
                  <p className="answer w-100">
                    <span
                      className={`${
                        numberFailHandler(question, answerValue)
                          ? "text-danger"
                          : "text-primary"
                      }`}
                    >
                      <div className="d-flex">
                        <p
                          style={
                            TEXT_DIRECTION() === "rtl"
                              ? { direction: "ltr" }
                              : null
                          }
                        >
                          <b>{answerValue}</b>
                        </p>
                        <p
                          className={` ${
                            TEXT_DIRECTION() === "rtl" ? "mr-auto" : "ml-auto"
                          }`}
                          style={{
                            fontSize: "14px",
                            color: "#828282",
                            marginTop: "4px",
                          }}
                        >
                          {NUMBER_CONDITION(question)}
                        </p>
                      </div>
                    </span>
                  </p>
                )}
                {note || photo.length ? (
                  <div
                    className="table-column attachments-wrapper pb-3"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <>
                      <fieldset>
                        <legend
                          className={`${
                            TEXT_DIRECTION() === "rtl"
                              ? "legend-arabic"
                              : "legend"
                          }`}
                        >
                          <I18n lng="global.InspectorNotes" />
                        </legend>
                        {note ? (
                          <div className="notes">
                            <p>{note}</p>
                          </div>
                        ) : null}
                        {photo.length ? (
                          <div className="thumbnail-wrapper">
                            <ReportPdfImages
                              photo={photo}
                              imageCount={imageCount}
                              setImageIndex={setImageIndex}
                              setShowImageSrc={setShowImageSrc}
                              showDocumentMedia={showDocumentMedia}
                              setShowImageDialog={setShowImageDialog}
                            />
                          </div>
                        ) : null}
                      </fieldset>
                    </>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        ) : hideAndShow?.content?.answered &&
          (answerValue || note || photo?.length) ? (
          <div className="table-body">
            <div
              className={`row table-row ml-0 mr-0   question ${
                question?.settings?.isFail ||
                numberFailHandler(question, answerValue)
                  ? "status-danger"
                  : "status-na"
              }`}
              style={{
                boxShadow: "0px 5px 5px rgba(0,0,0,.10)",
                backgroundColor: "#FFFFFF",
              }}
            >
              <div
                className={`col-lg-8 col-md-6 mb-3 table-column d-flex ${
                  TEXT_DIRECTION() === "rtl" ? "pr-0" : "pl-0"
                }`}
              >
                <span
                  className={` ${TEXT_DIRECTION() === "rtl" ? "ml-2" : "mr-2"}`}
                >
                  {question?.questionNumber}.
                </span>
                {"   "}
                {question?.settings?.questionText === "Add a new question" ? (
                  <I18n lng="Form.AddANewQuestion" />
                ) : (
                  question?.settings?.questionText
                )}
              </div>
              <div
                className=" col-lg-4 col-md-6 table-column question-type text-primary"
                style={{ padding: "0 23px" }}
              >
                {question?.settings.numberType === "TEMPERATURE" ? (
                  <p className="answer w-100">
                    <span
                      className={`${
                        numberFailHandler(question, answerValue)
                          ? "text-danger"
                          : "text-primary"
                      }`}
                    >
                      <div className="d-flex">
                        <p
                          style={
                            TEXT_DIRECTION() === "rtl"
                              ? { direction: "ltr" }
                              : null
                          }
                        >
                          <b>
                            {answerValue}{" "}
                            {question?.settings?.measurement === "FAHRENHEIT"
                              ? "˚F  "
                              : "˚C "}{" "}
                          </b>
                        </p>
                        <p
                          className={` ${
                            TEXT_DIRECTION() === "rtl" ? "mr-auto" : "ml-auto"
                          }`}
                          style={{
                            fontSize: "14px",
                            color: "#828282",
                            marginTop: "4px",
                          }}
                        >
                          {TEMPERATURE_CONDITION(question)}
                        </p>
                      </div>
                    </span>
                  </p>
                ) : (
                  <p className="answer w-100">
                    <span
                      className={`${
                        numberFailHandler(question, answerValue)
                          ? "text-danger"
                          : "text-primary"
                      }`}
                    >
                      <div className="d-flex">
                        <p
                          style={
                            TEXT_DIRECTION() === "rtl"
                              ? { direction: "ltr" }
                              : null
                          }
                        >
                          <b>{answerValue}</b>
                        </p>
                        <p
                          className={` ${
                            TEXT_DIRECTION() === "rtl" ? "mr-auto" : "ml-auto"
                          }`}
                          style={{
                            fontSize: "14px",
                            color: "#828282",
                            marginTop: "4px",
                          }}
                        >
                          {NUMBER_CONDITION(question)}
                        </p>
                      </div>
                    </span>
                  </p>
                )}

                {note | photo.length ? (
                  <div
                    className=" attachments-wrapper pb-3 table-column"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <>
                      <fieldset>
                        <legend
                          className={`${
                            TEXT_DIRECTION() === "rtl"
                              ? "legend-arabic"
                              : "legend"
                          }`}
                        >
                          <I18n lng="global.InspectorNotes" />
                        </legend>
                        {note ? (
                          <div className="notes">
                            <p>{note}</p>
                          </div>
                        ) : null}
                        {photo.length ? (
                          <div className="thumbnail-wrapper">
                            <ReportPdfImages
                              photo={photo}
                              imageCount={imageCount}
                              setImageIndex={setImageIndex}
                              setShowImageSrc={setShowImageSrc}
                              showDocumentMedia={showDocumentMedia}
                              setShowImageDialog={setShowImageDialog}
                            />
                          </div>
                        ) : null}
                      </fieldset>
                    </>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        ) : hideAndShow?.content?.unAnswered &&
          !answerValue &&
          !note &&
          !photo?.length ? (
          <div className="table-body">
            <div
              className={`row table-row ml-0 mr-0   question ${
                question?.settings?.isFail ||
                numberFailHandler(question, answerValue)
                  ? "status-danger"
                  : "status-na"
              }`}
              style={{
                boxShadow: "0px 5px 5px rgba(0,0,0,.10)",
                backgroundColor: "#FFFFFF",
              }}
            >
              <div
                className={`col-lg-8 col-md-6 mb-3 table-column d-flex ${
                  TEXT_DIRECTION() === "rtl" ? "pr-0" : "pl-0"
                }`}
              >
                <span
                  className={` ${TEXT_DIRECTION() === "rtl" ? "ml-2" : "mr-2"}`}
                >
                  {question?.questionNumber}.
                </span>
                {"   "}
                {question?.settings?.questionText === "Add a new question" ? (
                  <I18n lng="Form.AddANewQuestion" />
                ) : (
                  question?.settings?.questionText
                )}
              </div>
              <div
                className="col-lg-4 col-md-6 table-column question-type text-primary"
                style={{ padding: "0 23px" }}
              >
                {question?.settings.numberType === "TEMPERATURE" ? (
                  <p className="answer w-100">
                    <span
                      className={`${
                        numberFailHandler(question, answerValue)
                          ? "text-danger"
                          : "text-primary"
                      }`}
                    >
                      <div className="d-flex">
                        <p
                          style={
                            TEXT_DIRECTION() === "rtl"
                              ? { direction: "ltr" }
                              : null
                          }
                        >
                          {answerValue}{" "}
                          {question?.settings?.measurement === "FAHRENHEIT"
                            ? "˚F  "
                            : "˚C "}{" "}
                        </p>
                        <p
                          className={` ${
                            TEXT_DIRECTION() === "rtl" ? "mr-auto" : "ml-auto"
                          }`}
                          style={{
                            fontSize: "14px",
                            color: "#828282",
                            marginTop: "4px",
                          }}
                        >
                          {TEMPERATURE_CONDITION(question)}
                        </p>
                      </div>
                    </span>
                  </p>
                ) : (
                  <p className="answer w-100">
                    <span
                      className={`${
                        numberFailHandler(question, answerValue)
                          ? "text-danger"
                          : "text-primary"
                      }`}
                    >
                      <div className="d-flex">
                        <p
                          style={
                            TEXT_DIRECTION() === "rtl"
                              ? { direction: "ltr" }
                              : null
                          }
                        >
                          <b>{answerValue}</b>
                        </p>
                        <p
                          className={` ${
                            TEXT_DIRECTION() === "rtl" ? "mr-auto" : "ml-auto"
                          }`}
                          style={{
                            fontSize: "14px",
                            color: "#828282",
                            marginTop: "4px",
                          }}
                        >
                          {NUMBER_CONDITION(question)}
                        </p>
                      </div>
                    </span>
                  </p>
                )}
              </div>
              {note || photo.length ? (
                <div
                  className="table-column  attachments-wrapper pb-3"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <>
                    <fieldset>
                      <legend
                        className={`${
                          TEXT_DIRECTION() === "rtl"
                            ? "legend-arabic"
                            : "legend"
                        }`}
                      >
                        <I18n lng="global.InspectorNotes" />
                      </legend>
                      {note ? (
                        <div className="notes">
                          <p>{note}</p>
                        </div>
                      ) : null}
                      {photo.length ? (
                        <div className="notes thumbnail-wrapper">
                          <ReportPdfImages
                            photo={photo}
                            imageCount={imageCount}
                            setImageIndex={setImageIndex}
                            setShowImageSrc={setShowImageSrc}
                            showDocumentMedia={showDocumentMedia}
                            setShowImageDialog={setShowImageDialog}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </fieldset>
                  </>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : null
      ) : null}
    </>
  );
};
export default NumberPdfPreview;
