import toast from "react-hot-toast";
import I18n, { i18n } from "../pages/private/languageSelector";

export const formatDateToArabic = (dateString, language, options) => {
  const date = new Date(dateString);

  if (language === "ENGLISH") {
    return date.toLocaleDateString("en", options);
  } else {
    return date.toLocaleDateString("ar", options);
  }
};

export const formatTimeToArabic = (timeString, language, options) => {
  const [hours, minutes] = timeString.split(":");
  const date = new Date();
  date.setHours(Number(hours));
  date.setMinutes(Number(minutes));

  if (language === "ENGLISH") {
    return date.toLocaleTimeString("en", options);
  } else {
    return date.toLocaleTimeString("ar", options);
  }
};
export const formatDateToTime = (timeString, language, options) => {
  const date = new Date(timeString);

  if (language === "ENGLISH") {
    return date.toLocaleTimeString("en", options);
  } else {
    return date.toLocaleTimeString("ar", options);
  }
};

export const ADD_ZEROES = (num) => {
  let value = Number(num);
  let res = num.split(".");
  if (res[1]?.length && res[1]?.length > 2) {
    value = value.toFixed(2);
  }
  return value;
};

export const ReportStatusHandler = (status) => {
  if (status === "draft") {
    return <I18n lng="Reports.Draft" />;
  } else if (status === "incomplete") {
    return <I18n lng="Reports.Incomplete" />;
  } else if (status === "reSubmit") {
    return <I18n lng="Reports.ResubmitStatus" />;
  } else if (status === "submit") {
    return <I18n lng="Reports.Submitted" />;
  } else if (status === "accept") {
    return <I18n lng="Reports.Compelete" />;
  } else if (status === "reject") {
    return <I18n lng="Reports.RejectStatus" />;
  }
};

export const ReportStatusColorHandler = (status) => {
  if (status === "draft") {
    return "#828282";
  } else if (status === "incomplete") {
    return "#F2994A";
  } else if (status === "reSubmit") {
    return "#20639B";
  } else if (status === "submit") {
    return "#20639B";
  } else if (status === "accept") {
    return "#3CAEA3";
  } else if (status === "reject") {
    return "#EB5757";
  }
};

export const replacingObjectInArray = (orignalArray, arrayYouWantToAddedInOrignalArray) => {
  let result;
  result = arrayYouWantToAddedInOrignalArray.reduce(function (arr1, arr2) {
    let isMatchFound = false;
    arr1.forEach(function (list) {
      if (arr2.id === list.id) {
        list = Object.assign(list, arr2);
        isMatchFound = true;
      }
    });
    if (!isMatchFound) {
      arr1.push(arr2);
    }
    return arr1;
  }, orignalArray);
  if (result && result.length) {
    return result;
  }
};

export const reportThemeColor = (reportTheme) => {
  if (reportTheme === "theme-gray-one") {
    return "51, 51, 51";
  }
  if (reportTheme === "theme-gray-two") {
    return "79, 79, 79";
  }
  if (reportTheme === "theme-gray-three") {
    return "108, 130, 148";
  }
  if (reportTheme === "theme-primary-one") {
    return "32, 99, 155";
  }
  if (reportTheme === "theme-primary-two") {
    return "45, 156, 219";
  }
  if (reportTheme === "theme-primary-three") {
    return "86, 204, 242";
  }
  if (reportTheme === "theme-green-one") {
    return "33, 150, 83";
  }
  if (reportTheme === "theme-green-two") {
    return "39, 174, 96";
  }
  if (reportTheme === "theme-green-three") {
    return "111, 207, 151";
  }
  if (reportTheme === "theme-purple-one") {
    return "155, 81, 224";
  }
  if (reportTheme === "theme-purple-two") {
    return "187, 107, 217";
  }
  if (reportTheme === "theme-pink") {
    return "234, 172, 202";
  }
  if (reportTheme === "theme-red") {
    return "235, 87, 87";
  }
  if (reportTheme === "theme-orange") {
    return "242, 153, 74";
  }
  if (reportTheme === "theme-yellow") {
    return "242, 201, 76";
  }
};

export const mandatoryQuestionHandler = (schema, getAnswer, setMandatoryIds, setMandatoryQuestions) => {
  let mandatoryQuestions = 0;
  let mandatoryQuestionsIds = [];

  if (schema && schema.pages?.length) {
    schema?.pages?.map((page, pageIndex) =>
      page?.sections?.map((section, sectionIndex) => {
        return section?.questions?.map((question, questionIndex) => {
          const answer = getAnswer(question.questionUuid);
          // checking if question is answered or not
          return getAnswer(question.questionUuid) === undefined ||
            (question?.type !== "COMPLEX_MULTIPLE_CHOICE" &&
              (answer?.value === "" ||
                answer?.value === false ||
                (question?.type === "slider" && answer?.sliderValue === "") ||
                (question?.type === "slider" && answer?.sliderValue === undefined) ||
                (question?.type === "signature" && answer?.signature.length === 0) ||
                (question?.type === "DROPDOWN" && answer?.value === undefined) ||
                (question?.type === "image" && answer?.inspectorImages?.length < 1) ||
                answer?.address === "" ||
                answer?.inspectorImages?.length === 0 ||
                answer?.pdfPreview === "")) ||
            (question?.type === "COMPLEX_MULTIPLE_CHOICE" && answer?.selected?.length === 0)
            ? question?.settings?.isMandatory
              ? // if question is not answered and is mandatory add to the list
                ((mandatoryQuestions += 1),
                mandatoryQuestionsIds.push({
                  id: question.questionUuid,
                  pageIndex,
                  sectionIndex,
                  questionIndex,
                  question,
                }))
              : null
            : // checking if queston is answered and have a mandatory photo or comment
            question?.type !== "COMPLEX_MULTIPLE_CHOICE" &&
              ((question?.settings?.canComment && getAnswer(question.questionUuid)?.note === "") ||
                (question?.settings?.canAttachPhoto && getAnswer(question.questionUuid).image.length === 0) ||
                (question?.settings?.canComment &&
                  question?.settings?.canAttachPhoto &&
                  getAnswer(question.questionUuid)?.note === "" &&
                  getAnswer(question.questionUuid).image.length === 0))
            ? ((mandatoryQuestions += 1),
              mandatoryQuestionsIds.push({
                id: question.questionUuid,
                pageIndex,
                sectionIndex,
                questionIndex,
                question,
              }))
            : question?.type === "COMPLEX_MULTIPLE_CHOICE" &&
              ((getAnswer(question.questionUuid)?.selected.some((item) => question.settings.answers[item.index]?.canComment) &&
                getAnswer(question.questionUuid).note === "") ||
                (getAnswer(question.questionUuid)?.selected.some((item) => question.settings.answers[item.index]?.canAttachPhoto) &&
                  getAnswer(question.questionUuid).image.length === 0))
            ? ((mandatoryQuestions += 1),
              mandatoryQuestionsIds.push({
                id: question.questionUuid,
                pageIndex,
                sectionIndex,
                questionIndex,
                question,
              }))
            : null;
        });
      })
    );
  }
  setMandatoryQuestions(mandatoryQuestions);
  setMandatoryIds(mandatoryQuestionsIds);
};

export const mandatoryAnswerHandler = (getAnswer, mandatoryIds, mandatoryQuestions, setMandatoryAnswered) => {
  let answered = 0;

  mandatoryIds.forEach((item) => {
    return getAnswer(item.id) !== undefined
      ? item?.question?.type !== "COMPLEX_MULTIPLE_CHOICE" &&
        item?.question?.settings?.canComment &&
        getAnswer(item.id).note !== "" &&
        item?.question?.settings?.canAttachPhoto &&
        getAnswer(item.id).image?.length
        ? (answered += 1)
        : item?.question?.type === "COMPLEX_MULTIPLE_CHOICE" &&
          item?.question?.settings?.answers[getAnswer(item?.question.questionUuid)?.selected[0]?.index]?.canComment &&
          getAnswer(item.id).note !== "" &&
          item?.question?.settings?.answers[getAnswer(item?.question.questionUuid)?.selected[0]?.index]?.canAttachPhoto &&
          getAnswer(item.id).image?.length
        ? (answered += 1)
        : null
      : null;
  });
  setMandatoryAnswered(answered);
  if (mandatoryQuestions === answered) return true;
  if (mandatoryQuestions !== answered) return false;
};

export const navigatingToQuestion = (mandatoryIds, getAnswer) => {
  let unAnswered = [];

  mandatoryIds.forEach((item) => {
    return getAnswer(item.id) === undefined
      ? unAnswered.push(document.getElementById(item.id))
      : item?.question?.type !== "COMPLEX_MULTIPLE_CHOICE" &&
        ((item?.question?.settings?.canComment && getAnswer(item.id).note === "") ||
          (item?.question?.settings?.canAttachPhoto && getAnswer(item.id).image?.length === 0))
      ? unAnswered.push(document.getElementById(item.id))
      : item?.question?.type === "COMPLEX_MULTIPLE_CHOICE" &&
        ((item?.question?.settings?.answers[getAnswer(item?.question.questionUuid)?.selected[0]?.index]?.canComment &&
          getAnswer(item.id).note === "") ||
          (item?.question?.settings?.answers[getAnswer(item?.question.questionUuid)?.selected[0]?.index]?.canAttachPhoto &&
            getAnswer(item.id).image?.length === 0))
      ? unAnswered.push(document.getElementById(item.id))
      : null;
  });
  return unAnswered[0];
};

export const numberFailHandler = (question, answerValue) => {
  if (answerValue && answerValue !== undefined) {
    if (question?.settings?.comparision === "More than") {
      if (answerValue > +question?.settings?.min) {
        return false;
      } else {
        return true;
      }
    } else if (question?.settings?.comparision === "More than or equals") {
      if (answerValue >= +question?.settings?.min) {
        return false;
      } else {
        return true;
      }
    } else if (question?.settings?.comparision === "Equals") {
      if (+answerValue === +question?.settings?.min) {
        return false;
      } else {
        return true;
      }
    } else if (question?.settings?.comparision === "Less than or equals") {
      if (answerValue <= +question?.settings?.max) {
        return false;
      } else {
        return true;
      }
    } else if (question?.settings?.comparision === "Less than") {
      if (answerValue < +question?.settings?.max) {
        return false;
      } else {
        return true;
      }
    } else if (question?.settings?.comparision === "Between") {
      if (answerValue >= +question?.settings?.min && answerValue <= +question?.settings?.max) {
        return false;
      } else {
        return true;
      }
    }
  } else {
    return false;
  }
};

export const canAccessTheModule = (roleSetting, module) => {
  if (roleSetting) {
    return <>{module}</>;
  } else {
    return false;
  }
};

export const canAccessTheTabs = (roleSetting, array) => {
  if (roleSetting) {
    return array;
  } else {
    return false;
  }
};

export const swapTwoValuesPositionInArray = (arr, position1, position2) => {
  let duplicateArray = [...arr];
  let temp = duplicateArray[position1];
  duplicateArray[position1] = duplicateArray[position2];
  duplicateArray[position2] = temp;
  return duplicateArray;
};

export const logoutUserWithNoAccess = (logout, history) => {
  logout();
  history.push("/login");
  toast.error(<I18n lng="global.YouDontHaveAnyAccess" />);
};

export const canAccessTheRoutes = (rolesAccess, logout, history) => {
  if (!rolesAccess?.company?.selected && window.location.pathname.includes("/management")) {
    if (rolesAccess?.form?.selected) {
      history.push("/forms/form-management");
    } else if (rolesAccess?.report?.selected) {
      history.push("/reports/my-reports");
    } else {
      history.push("/not-registered");
    }
  } else if (!rolesAccess?.report?.selected && window.location.pathname.includes("/reports")) {
    if (rolesAccess?.form?.selected) {
      history.push("/forms/form-management");
    } else if (rolesAccess?.company?.selected) {
      history.push("/management/info");
    } else {
      history.push("/not-registered");
    }
  } else if (!rolesAccess?.form?.selected && window.location.pathname.includes("/forms")) {
    if (rolesAccess?.report?.selected) {
      history.push("/reports/my-reports");
    } else if (rolesAccess?.company?.selected) {
      history.push("/management/info");
    } else {
      history.push("/not-registered");
    }
  }
};

export const floatOrIntegerNumber = (x) => {
  if (Number.isInteger(x)) {
    return false;
  } else {
    return true;
  }
};

export const jumpInputField = (e, index, cardNumber, setCardNumber) => {
  // changing the values
  let numbers = [...cardNumber];
  numbers[index] = e.target.value;
  setCardNumber([...numbers]);

  const { maxLength, value, name } = e.target;
  // eslint-disable-next-line
  const [fieldName, fieldIndex] = name.split("-");

  let fieldIntIndex = parseInt(fieldIndex, 10);

  // Check if no. of char in field === maxLength
  if (value.length >= maxLength) {
    // It should not be last input field
    if (fieldIntIndex < 4) {
      // Get the next input field using it's name
      const nextfield = document.querySelector(`input[name=field-${fieldIntIndex + 1}]`);

      // If found, focus the next field
      if (nextfield !== null) {
        nextfield.focus();
      }
    }
    // Check if no. of char in field === 0
  } else if (value.length === 0) {
    if (fieldIntIndex > 1) {
      // Get the previous input field using it's name
      const nextfield = document.querySelector(`input[name=field-${fieldIntIndex - 1}]`);

      // If found, focus the previous field
      if (nextfield !== null) {
        nextfield.focus();
      }
    }
  }
};

export const TEMPERATURE_CONDITION = (question) => {
  if (question?.settings?.comparision === "Less than") {
    return "(Range < " + question?.settings?.max + `${question?.settings?.measurement === "FAHRENHEIT" ? "˚F  " : "˚C "})`;
  } else if (question?.settings?.comparision === "Less than or equals") {
    return "(Range <= " + question?.settings?.max + `${question?.settings?.measurement === "FAHRENHEIT" ? "˚F  " : "˚C "})`;
  } else if (question?.settings?.comparision === "Equals") {
    return "(Range = " + question?.settings?.min + `${question?.settings?.measurement === "FAHRENHEIT" ? "˚F  " : "˚C "})`;
  } else if (question?.settings?.comparision === "More than or equals") {
    return "(Range >= " + question?.settings?.min + `${question?.settings?.measurement === "FAHRENHEIT" ? "˚F  " : "˚C "})`;
  } else if (question?.settings?.comparision === "More than") {
    return "(Range > " + question?.settings?.min + `${question?.settings?.measurement === "FAHRENHEIT" ? "˚F  " : "˚C "})`;
  } else if (question?.settings?.comparision === "Between") {
    return (
      "(Range > " +
      question?.settings?.min +
      " ~ " +
      question?.settings?.max +
      " < " +
      `${question?.settings?.measurement === "FAHRENHEIT" ? "˚F   " : "˚C "})`
    );
  }
};
export const NUMBER_CONDITION = (question) => {
  if (question?.settings?.comparision === "Less than") {
    return "(Range < " + question?.settings?.max + ")";
  } else if (question?.settings?.comparision === "Less than or equals") {
    return "(Range <= " + question?.settings?.max + ")";
  } else if (question?.settings?.comparision === "Equals") {
    return "(Range = " + question?.settings?.min + ")";
  } else if (question?.settings?.comparision === "More than or equals") {
    return "(Range >= " + question?.settings?.min + ")";
  } else if (question?.settings?.comparision === "More than") {
    return "(Range > " + question?.settings?.min + ")";
  } else if (question?.settings?.comparision === "Between") {
    return `(Range > ${question?.settings?.min} ~ ${question?.settings?.max} < )`;
  }
};

export const CAPITALIZE_FIRST_LETTER = (text) => {
  if (text !== "" && text !== undefined && text !== null) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  } else {
    return "";
  }
};

export const INVOICE_NUMBER_GENERATOR = (totalInvoices) => {
  let str = "" + totalInvoices;
  let pad = "000000";
  let number = pad.substring(0, pad.length - str.length) + str;
  return "A1-" + number;
};

export const INVOICE_AMOUNT_CALCULATOR = (invoiceDetailsToShow, tax) => {
  if (invoiceDetailsToShow?.cost && invoiceDetailsToShow?.totalUsers) {
    if (tax === undefined) {
      let subTotal = +invoiceDetailsToShow?.cost * +invoiceDetailsToShow?.totalUsers;
      return Number(subTotal).toFixed(2);
    } else {
      let tax = (+invoiceDetailsToShow?.cost * +invoiceDetailsToShow?.totalUsers * 15) / 100;
      let total = +invoiceDetailsToShow?.cost * +invoiceDetailsToShow?.totalUsers + tax;
      return Number(total).toFixed(2);
    }
  } else if (invoiceDetailsToShow && invoiceDetailsToShow?.totalSms) {
    if (tax === undefined) {
      let subTotal = invoiceDetailsToShow.totalSms * 0.04;
      return Number(subTotal).toFixed(2);
    } else {
      let tax = (+invoiceDetailsToShow?.totalSms * 0.04 * 15) / 100;
      let total = invoiceDetailsToShow.totalSms * 0.04 + tax;
      return Number(total).toFixed(2);
    }
  }
  return "";
};

export const PDF_IMAGE_CLASS = (number) => {
  let className = "";
  if (number && number !== undefined) {
    if (number !== 1 && number !== 2 && (number % 4 === 1 || number % 4 === 2)) {
      className = "pageBreak";
    }
  }
  return className;
};

export const TEXT_DIRECTION = () => {
  let direction = document.getElementsByTagName("html")[0].getAttribute("dir");
  return direction;
};

export const GET_USER_LOCATION = async (setLocation) => {
  if (window.navigator.geolocation) {
    window.navigator.geolocation.getCurrentPosition(
      (res) => {
        fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${res.coords.latitude},${res.coords.longitude}&key=AIzaSyBoV-vH2LdoFdgz5JR5fR-1HQ45wAeACPg`
        )
          .then((res) => res.json())
          .then((res) => {
            let returnData = {
              address: "",
              city: "",
              country: "",
            };
            res.results.map((item, index) => {
              if (item.types && item.types.length) {
                if (item.types.includes("country")) {
                  returnData.country = item.address_components[0].long_name;
                } else if (item.types.includes("administrative_area_level_2")) {
                  returnData.city = item.address_components[0].long_name;
                }
              }
              if (index === 0) {
                returnData.address = item?.formatted_address;
                returnData.latitude = item?.geometry?.location?.lat;
                returnData.longitude = item?.geometry?.location?.lng;
              }
              return { ...returnData };
            });
            setLocation({ ...returnData });
          });
      },
      (err) => {
        if (err && err.message === "User denied Geolocation") {
          toast(i18n("validation.PleaseAllowLocationAccess"));
        }
      }
    );
  }
};

export const GET_LOCATION_BY_GEOCODE = async (latitude, longitude) => {
  let location = await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyBoV-vH2LdoFdgz5JR5fR-1HQ45wAeACPg`
  )
    .then((res) => res.json())
    .then((res) => {
      let returnData = {
        address: "",
        city: "",
        country: "",
      };
      res.results.map((item, index) => {
        if (item.types && item.types.length) {
          if (item.types.includes("country")) {
            returnData.country = item.address_components[0].long_name;
          } else if (item.types.includes("administrative_area_level_2")) {
            returnData.city = item.address_components[0].long_name;
          }
        }
        if (index === 0) {
          returnData.address = item?.formatted_address;
        }
        return { ...returnData };
      });
      return { ...returnData };
    });
  return location;
};

export const GET_WINDOW_DIMENSIONS = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export const isRTL = (s) => {
  // eslint-disable-next-line
  var ltrChars = "A-Za-z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u02B8\u0300-\u0590\u0800-\u1FFF" + "\u2C00-\uFB1C\uFDFE-\uFE6F\uFEFD-\uFFFF",
    rtlChars = "\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC",
    rtlDirCheck = new RegExp("^[^" + ltrChars + "]*[" + rtlChars + "]");

  return rtlDirCheck.test(s);
};

export const formAllAnswerdDetails = async (data) => {
  let formUnAnswerdDetail = [];
  let allImages = [];
  let newFormAnswerDetail = [];
  if (data.formDetail.schema.pages.length) {
    // eslint-disable-next-line
    data.formDetail.schema.pages.map((page) => {
      // eslint-disable-next-line
      page?.sections.map((section) => {
        // eslint-disable-next-line
        return section?.questions.map((question) => {
          const answer = data?.formAnswerDetail?.find((item) => item?.questionUuid === question.questionUuid);
          if (answer === undefined) {
            let submitedData;
            if (question.type === "COMPLEX_MULTIPLE_CHOICE") {
              submitedData = {
                questionUuid: question.questionUuid,
                selected: [],
                note: "",
                image: [],
              };
            }
            if (question.type === "number" || question.type === "text" || question.type === "date time" || question.type === "DROPDOWN") {
              submitedData = {
                questionUuid: question.questionUuid,
                value: "",
                note: "",
                image: [],
              };
            }
            if (question.type === "checkbox") {
              submitedData = {
                questionUuid: question.questionUuid,
                value: false,
                note: "",
                image: [],
              };
            }
            if (question.type === "pdf") {
              submitedData = {
                questionUuid: question.questionUuid,
                pdfPreview: "",
                pdfFileUrl: "",
                note: "",
                image: [],
              };
            }
            if (question.type === "slider") {
              submitedData = {
                questionUuid: question.questionUuid,
                sliderValue: "",
                note: "",
                image: [],
              };
            }
            if (question.type === "image") {
              submitedData = {
                questionUuid: question.questionUuid,
                inspectorImages: [],
                note: "",
                image: [],
              };
            }
            if (question.type === "location") {
              submitedData = {
                questionUuid: question.questionUuid,
                locationImage: "",
                note: "",
                image: [],
              };
            }
            if (question.type === "signature") {
              submitedData = {
                questionUuid: question.questionUuid,
                signatureImage: "",
                signatureName: "",
                note: "",
                image: [],
                signature: [],
              };
            }
            if (question.type === "instructions") {
              submitedData = {
                value: "",
              };
            }
            if (submitedData !== undefined) {
              formUnAnswerdDetail.push(submitedData);
            }
          }
        });
      });
    });
    let formAnswerDetail;
    if (formUnAnswerdDetail.length && data?.formAnswerDetail?.length) {
      formAnswerDetail = data.formAnswerDetail.concat(formUnAnswerdDetail);
    } else if (data?.formAnswerDetail?.length && formUnAnswerdDetail.length === 0) {
      formAnswerDetail = data.formAnswerDetail;
    } else if (formUnAnswerdDetail.length && (data?.formAnswerDetail?.length === 0 || data.formAnswerDetail === undefined)) {
      formAnswerDetail = formUnAnswerdDetail;
    }
    // eslint-disable-next-line
    formAnswerDetail?.map((answer) => {
      if (answer.image?.length || answer.inspectorImages?.length) {
        const imageQuestion = answer.inspectorImages?.map((item, index) => {
          allImages.push(index + 1);
          return { ...item, imageNumber: allImages?.length };
        });
        const image = answer.image.map((item, index) => {
          allImages.push(index + 1);
          return { ...item, imageNumber: allImages?.length };
        });
        let updatedAnswer = {
          ...answer,
          image: image,
          inspectorImages: imageQuestion,
        };
        newFormAnswerDetail.push(updatedAnswer);
      } else {
        newFormAnswerDetail.push(answer);
      }
    });
    return newFormAnswerDetail;
  }
};

export const IncludeStringInDropdownOrNot = (name) => {
  if (name === i18n("Analytics.Completion (%)")) {
    return i18n("Analytics.Completion");
  } else if (name === i18n("Analytics.Score (%)")) {
    return i18n("Analytics.Score");
  } else if (name === i18n("Analytics.Form")) {
    return i18n("Analytics.FormName");
  } else {
    return name;
  }
};

export const translateMonthsFromCharts = (item) => {
  if (!item.includes(":00")) {
    let month = item.slice(item.length - 3, item.length);
    let date = item.slice(0, item.indexOf(month));
    if (month === "Jan") {
      return i18n("Analytics.Jan") + date;
    } else if (month === "Feb") {
      return i18n("Analytics.Feb") + date;
    } else if (month === "Mar") {
      return i18n("Analytics.Mar") + date;
    } else if (month === "Apr") {
      return i18n("Analytics.Apr") + date;
    } else if (month === "May") {
      return i18n("Analytics.May") + date;
    } else if (month === "Jun") {
      return i18n("Analytics.Jun") + date;
    } else if (month === "Jul") {
      return i18n("Analytics.Jul") + date;
    } else if (month === "Aug") {
      return i18n("Analytics.Aug") + date;
    } else if (month === "Sep") {
      return i18n("Analytics.Sep") + date;
    } else if (month === "Oct") {
      return i18n("Analytics.Oct") + date;
    } else if (month === "Nov") {
      return i18n("Analytics.Nov") + date;
    } else if (month === "Dec") {
      return i18n("Analytics.Dec") + date;
    } else return month + date;
  } else {
    return item;
  }
};

export const translatePercentageFromCharts = (percentage) => {
  if (TEXT_DIRECTION() === "rtl") {
    return percentage.replace("%", "").replace("(", "(%");
  } else return percentage;
};

export const translateChartsValue = (value) => {
  let percentage = value.toString().split("").pop();
  let valueToAppend = value.toString().slice(0, value.toString().indexOf(percentage));
  if (TEXT_DIRECTION() === "rtl") {
    return percentage + valueToAppend;
  } else return value;
};

export const translateStatusForPDF = (status) => {
  if (status === "submit") {
    return TEXT_DIRECTION() === "ltr" ? i18n("Analytics.Submitted") : "مسلّم";
  } else if (status === "draft") {
    return TEXT_DIRECTION() === "ltr" ? i18n("Analytics.Draft") : "مسودة";
  } else if (status === "accept") {
    return TEXT_DIRECTION() === "ltr" ? i18n("Analytics.Complete") : "مكتمل";
  } else if (status === "incomplete") {
    return TEXT_DIRECTION() === "ltr" ? i18n("Analytics.Incomplete") : "غير مكتمل";
  } else if (status === "reSubmit") {
    return TEXT_DIRECTION() === "ltr" ? i18n("Analytics.Resubmitted") : "معاد تسليمه";
  } else if (status === "reject") {
    return TEXT_DIRECTION() === "ltr" ? i18n("Analytics.Rejected") : "مرفوض";
  } else return status;
};
