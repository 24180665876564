import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { Collapse } from "reactstrap";
import { Link } from "react-router-dom";
import { useSwipeable } from "react-swipeable";
import { Draggable } from "react-beautiful-dnd";
import I18n, { i18n } from "../../../languageSelector";
import IconPdf from "../../../../../assets/images/icon-pdf.svg";
import IconMenu from "../../../../../assets/images/icon-menu.svg";
import {
  ADD_ZEROES,
  TEXT_DIRECTION,
} from "../../../../../helper/helperFunctions";
import IconImage from "../../../../../assets/images/icon-image.svg";
import IconNumber from "../../../../../assets/images/icon-number.svg";
import IconSlider from "../../../../../assets/images/icon-slider.svg";
import IconDateTime from "../../../../../assets/images/icon-datetime.svg";
import IconCheckbox from "../../../../../assets/images/icon-checkbox.svg";
import IconLocation from "../../../../../assets/images/icon-location.svg";
import IconDropdown from "../../../../../assets/images/dropdown-icon.svg";
import IconDraggable from "../../../../../assets/images/icon-draggable.svg";
import IconSignature from "../../../../../assets/images/icon-signature.svg";
import IconTextAnswer from "../../../../../assets/images/icon-text-answer.svg";
import IconInstruction from "../../../../../assets/images/icon-instruction.svg";
import IconDropdownArrow from "../../../../../assets/images/icon-collapse-arrow.svg";
import IconCircleArrowDown from "../../../../../assets/images/icon-circle-arrow-down.svg";
import { INPUT_VALUES_TO_EXCLUDE } from "../../../../../config/constants";
import { GET_MCQ_NAME } from "../../../../../helper/formHelperFunctions";

const getBuilderQuestionPreview = (question) => {
  return question.type === "COMPLEX_MULTIPLE_CHOICE" ? (
    <div
      className={`tags-wrapper  ${
        TEXT_DIRECTION() === "rtl" ? "rotate-image" : ""
      }`}
    >
      {question.settings.answers.map((item, index) => (
        <span
          key={index}
          className={`tag ${item.class}  ${
            TEXT_DIRECTION() === "rtl" ? "rotate-image" : ""
          }`}
        >
          {GET_MCQ_NAME(item.name)}
        </span>
      ))}
    </div>
  ) : question.type === "text" ? (
    <div>
      <span className="icon-wrap mr-3">
        <img className="ml-2" src={IconTextAnswer} alt="IconTextAnswer" />
      </span>
      <I18n lng="Form.TextAnswer" />
    </div>
  ) : question.type === "number" ? (
    <div>
      <span className="icon-wrap mr-3">
        <img className="ml-2" src={IconNumber} alt="IconTextAnswer" />
      </span>
      {question.settings.numberType === "NUMBER" ? (
        <I18n lng="Form.Number" />
      ) : (
        <I18n lng="Form.Temperature" />
      )}
    </div>
  ) : question.type === "checkbox" ? (
    <div>
      <span className="icon-wrap mr-3">
        <img className="ml-2" src={IconCheckbox} alt="IconCheckBox" />
      </span>
      <I18n lng="Form.CheckBox" />
    </div>
  ) : question.type === "date time" ? (
    <div>
      <span className="icon-wrap mr-3">
        <img className="ml-2" src={IconDateTime} alt="IconDateTime" />
      </span>
      {question.settings.isDate && question.settings.isTime ? (
        <I18n lng="Form.Date&Time" />
      ) : question.settings.isDate ? (
        <I18n lng="Form.Date" />
      ) : question.settings.isTime ? (
        <I18n lng="Form.Time" />
      ) : (
        ""
      )}
    </div>
  ) : question.type === "image" ? (
    <div>
      <span className="icon-wrap mr-3">
        <img className="ml-2" src={IconImage} alt="IconImage" />
      </span>
      <I18n lng="Form.Image" />
    </div>
  ) : question.type === "pdf" ? (
    <div>
      <span className="icon-wrap mr-3">
        <img className="ml-2" src={IconPdf} alt="IconImage" />
      </span>
      <I18n lng="Form.PDF" />
    </div>
  ) : question.type === "slider" ? (
    <div>
      <span className="icon-wrap mr-3">
        <img className="ml-2" src={IconSlider} alt="IconSlider" />
      </span>
      <I18n lng="Form.Slider" />
    </div>
  ) : question.type === "instructions" ? (
    <div>
      <span className="icon-wrap mr-3">
        <img className="ml-2" src={IconInstruction} alt="IconInstruction" />
      </span>
      <I18n lng="Form.Instructions" />
    </div>
  ) : question.type === "location" ? (
    <div>
      <span className="icon-wrap mr-3">
        <img className="ml-2" src={IconLocation} alt="IconLocation" />
      </span>

      <I18n lng="Form.Location" />
    </div>
  ) : question.type === "signature" ? (
    <div>
      <span className="icon-wrap mr-3">
        <img className="ml-2" src={IconSignature} alt="IconSignature" />
      </span>

      <I18n lng="Form.Signature" />
    </div>
  ) : question.name === "Dropdown" ? (
    <div>
      <span className="icon-wrap mr-3">
        <img className="ml-2" src={IconDropdown} alt="IconPreview" />
      </span>

      {question.settings.listName}
    </div>
  ) : (
    ""
  );
};

const FormBuilderQuestion = ({
  form,
  formId,
  closeAll,
  question,
  isScored,
  pageIndex,
  sectionIndex,
  questionIndex,
  setActionItem,
  updateQuestion,
  deleteQuestion,
  selectedElement,
  setSelectedElement,
  setShowResponseListDrawer,
  showResponseListDrawer,
  setShowQuestionOptionsDrawer,
  setMcqID,
  addQuestion,
  updateMultipleChoiceById,
  getAllMultipleChoiceQuestions,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [questionText, setQuestionText] = useState("");
  const [questionScore, setQuestionScore] = useState(1);
  const [showItemActions, setShowItemActions] = useState(false);
  const [isQuestionEditable, setIsQuestionEditable] = useState(false);
  const [isSlideToDeleteDisabled, setIsSlideToDeleteDisabled] = useState(false);

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      setShowItemActions(TEXT_DIRECTION() === "rtl" ? false : true);
      setSelectedElement(pageIndex, sectionIndex, questionIndex, true);
    },
    onSwipedRight: () => {
      setShowItemActions(TEXT_DIRECTION() === "rtl" ? true : false);
      setSelectedElement(pageIndex, sectionIndex, questionIndex, true);
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const updateScore = (value) => {
    let questionScore = ADD_ZEROES(value);

    let answers = [...question.settings.answers];
    let updatedAnswers = answers.map((item) => {
      let prevPercentage = null;
      if (item.score !== 0 && item.score !== "-") {
        prevPercentage = (item.score / question?.settings?.score) * 100;
      }
      let newValue = null;
      if (prevPercentage) {
        newValue = (prevPercentage / 100) * value;
      }
      let questionScoreNew = newValue !== null ? newValue.toFixed(2) : newValue;
      return (item.class === "tag-green" ||
        (item.class === "tag-yellow" &&
          question?.settings?.selectionType !== "MULTIPLE")) &&
        questionScoreNew !== null
        ? { ...item, score: Number(questionScoreNew) }
        : (item.class === "tag-green" ||
            (item.class === "tag-yellow" &&
              question?.settings?.selectionType !== "MULTIPLE")) &&
          questionScoreNew !== null
        ? { ...item, score: Number(questionScore) }
        : item.class === "tag-red" && questionScoreNew
        ? { ...item, score: Number(questionScoreNew) }
        : item.class === "tag-red" && questionScoreNew === null
        ? { ...item, score: 0 }
        : questionScoreNew !== null
        ? { ...item, score: Number(questionScoreNew) }
        : { ...item, score: "-" };
    });

    updateQuestion(pageIndex, sectionIndex, questionIndex, {
      ...question,
      settings: {
        ...question.settings,
        score: Number(questionScore),
        answers: [...updatedAnswers],
      },
    });
    if (question.id) {
      updateMultipleChoiceById(question.id, {
        ...question,
        settings: {
          ...question.settings,
          score: Number(questionScore),
        },
      }).then(() => {
        if (formId) {
          getAllMultipleChoiceQuestions(formId);
        }
      });
    }
  };

  const closeQuestion = () => {
    updateQuestion(
      pageIndex,
      sectionIndex,
      questionIndex,
      {
        ...question,
        settings: {
          ...question.settings,
          isOpen: false,
        },
      },
      true
    );
  };

  const updateQuestionText = (text, isTrue) => {
    let updateUndo =
      text === "" ||
      text === "Add a new question" ||
      text === "Enter Instructions" ||
      isTrue
        ? true
        : false;
    updateQuestion(
      pageIndex,
      sectionIndex,
      questionIndex,
      {
        ...question,
        settings: {
          ...question.settings,
          questionText: text,
          answers: [...question.settings.answers],
        },
      },
      updateUndo
    );
  };

  useEffect(() => {
    if (question?.settings) {
      setIsOpen(question.settings.isOpen);
      setQuestionScore(question?.settings?.score);
      setQuestionText(question.settings.questionText);
    }
  }, [question]);

  useEffect(() => {
    if (
      !selectedElement ||
      selectedElement.pageNo !== pageIndex ||
      selectedElement.sectionNo !== sectionIndex ||
      (selectedElement.questionNo !== questionIndex && showItemActions)
    ) {
      setShowItemActions(false);
    }
    // eslint-disable-next-line
  }, [selectedElement]);
  useEffect(() => {
    if (showResponseListDrawer) {
      setIsQuestionEditable(false);
    } else {
      setIsQuestionEditable(true);
    }
  }, [question?.questionUuid, showResponseListDrawer]);

  useEffect(() => {
    if (
      selectedElement &&
      selectedElement?.pageNo === pageIndex &&
      selectedElement?.sectionNo === sectionIndex &&
      selectedElement?.questionNo === questionIndex
    ) {
      setIsSlideToDeleteDisabled(true);
      if (
        question?.settings?.questionText === "Add a new question" ||
        question?.settings?.questionText === "Enter Instructions" ||
        question?.settings?.questionText?.match(/^ *$/) !== null ||
        question?.settings?.questionText === ""
      ) {
        setQuestionText("");
        // updateQuestionText("");
        setTimeout(() => {
          if (document.getElementById(question.questionUuid) !== null) {
            document.getElementById(question.questionUuid).focus();
          }
        }, 0);
      }
    } else if (
      (question &&
        question?.type === "instructions" &&
        questionText === "Enter Instructions") ||
      (question &&
        question?.type === "instructions" &&
        questionText !== "Enter Instructions")
    ) {
      // setQuestionText("Enter Instructions");
      // updateQuestionText("Enter Instructions");
    } else if (
      question?.settings?.questionText === "" ||
      question?.settings?.questionText?.match(/^ *$/) !== null
    ) {
      setQuestionText("Add a new question");
      // updateQuestionText("Add a new question");
    }
    // eslint-disable-next-line
  }, [question?.questionUuid]);

  useEffect(() => {
    if (
      question?.questionUuid !==
      form?.schema?.pages[selectedElement?.pageNo]?.sections[
        selectedElement?.sectionNo
      ]?.questions[selectedElement?.questionNo]?.questionUuid
    ) {
      setIsQuestionEditable(false);
      setIsSlideToDeleteDisabled(false);
      if (
        question?.settings?.questionText === "Add a new question" ||
        question?.settings?.questionText === "Enter Instructions" ||
        question?.settings?.questionText?.match(/^ *$/) !== null ||
        question?.settings?.questionText === ""
      ) {
        setQuestionText("Add a new question");
      }
    }
    // eslint-disable-next-line
  }, [form]);

  const handleTextClick = (text) => {
    if (text !== undefined) {
      if (
        text === "" ||
        text.match(/^ *$/) !== null ||
        text === "Add a new question" ||
        (text === "Enter Instructions" && question.type === "instructions")
      ) {
        setQuestionText("");
        updateQuestionText("");
      } else {
        updateQuestionText(text);
      }
    }
    closeAll(pageIndex, sectionIndex, questionIndex, true);
    setSelectedElement(pageIndex, sectionIndex, questionIndex, true);
    setIsQuestionEditable(true);
    setIsSlideToDeleteDisabled(true);
  };
  return (
    <Draggable
      className="position-relative"
      draggableId={"question-" + pageIndex + sectionIndex + questionIndex}
      index={pageIndex + sectionIndex + questionIndex}
    >
      {(provided, snapshot) => (
        <div
          {...provided.draggableProps}
          ref={provided.innerRef}
          isdragging={snapshot.isdragging}
          className={
            selectedElement &&
            selectedElement.pageNo === pageIndex &&
            selectedElement.sectionNo === sectionIndex &&
            selectedElement.questionNo === questionIndex
              ? "selected-form-builder-element"
              : ""
          }
        >
          <li
            className={`question form-block ${
              !question?.settings?.isEnabled ? "disabled" : ""
            }`}
          >
            <div
              className="form-question"
              {...(isSlideToDeleteDisabled ? {} : handlers)}
            >
              <strong>
                <Link
                  to="#"
                  className={`icon icon-draggable ${
                    TEXT_DIRECTION() === "rtl" ? "mr-0" : ""
                  }`}
                  {...provided.dragHandleProps}
                >
                  <img
                    className="ml-2"
                    src={IconDraggable}
                    alt="IconDraggable"
                  />
                </Link>
                <span className="text-editable">
                  {question?.settings?.isMandatory ? (
                    <span className="required"></span>
                  ) : null}
                  {isQuestionEditable ? (
                    <input
                      id={question?.questionUuid}
                      className={`${
                        question.type === "instructions"
                          ? "form-instruction"
                          : ""
                      } ${TEXT_DIRECTION() === "rtl" ? "ml-2" : "mr-1"}`}
                      autoFocus
                      type="text"
                      placeholder={
                        question.type === "instructions"
                          ? i18n("Form.EnterInstructions")
                          : i18n("Form.AddANewQuestion")
                      }
                      style={{ background: "lightgray" }}
                      value={questionText}
                      onClick={(e) => handleTextClick(e.target.value)}
                      onBlur={() => {
                        setIsQuestionEditable(false);
                        setIsSlideToDeleteDisabled(false);

                        if (
                          !questionText ||
                          questionText.match(/^ *$/) !== null
                        ) {
                          if (
                            (question.type === "instructions" &&
                              questionText === "Enter Instructions") ||
                            (question.type === "instructions" &&
                              questionText !== "Enter Instructions")
                          ) {
                            setQuestionText("Enter Instructions");
                            updateQuestionText("Enter Instructions");
                          } else {
                            setQuestionText("Add a new question");
                            updateQuestionText("Add a new question");
                          }
                        } else {
                          if (
                            question?.settings?.questionText !== questionText
                          ) {
                            updateQuestionText(questionText);
                          }
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          setIsQuestionEditable(false);
                          setIsSlideToDeleteDisabled(false);
                          if (!questionText) {
                            if (
                              (question.type === "instructions" &&
                                questionText === "Enter Instructions") ||
                              (question.type === "instructions" &&
                                questionText !== "Enter Instructions")
                            ) {
                              // setQuestionText("Enter Instructions");
                              // updateQuestionText("Enter Instructions");
                            } else {
                              setQuestionText("Add a new question");
                              updateQuestionText("Add a new question");
                            }
                          } else {
                            updateQuestionText(questionText);
                          }
                          addQuestion(
                            selectedElement.pageNo,
                            selectedElement.sectionNo
                          );
                        }
                      }}
                      onChange={(e) => setQuestionText(e.target.value)}
                    />
                  ) : (
                    <span
                      className={
                        questionText === "Enter Instructions" &&
                        question.type === "instructions"
                          ? `placeholder form-instruction ${
                              TEXT_DIRECTION() === "rtl" ? "mr-1" : ""
                            }`
                          : questionText !== "Enter Instructions" &&
                            question.type === "instructions"
                          ? `placeholder form-instruction-answer ${
                              TEXT_DIRECTION() === "rtl" ? "mr-1" : ""
                            }`
                          : questionText === "Add a new question"
                          ? `placeholder ${
                              TEXT_DIRECTION() === "rtl" ? "mr-1" : ""
                            }`
                          : `${TEXT_DIRECTION() === "rtl" ? "mr-1" : ""}`
                      }
                      onClick={() => handleTextClick(questionText)}
                    >
                      {questionText === "Add a new question"
                        ? i18n("Form.AddANewQuestion")
                        : questionText === "Enter Instructions"
                        ? i18n("Form.EnterInstructions")
                        : questionText
                        ? questionText
                        : i18n("Form.AddANewQuestion")}
                    </span>
                  )}
                </span>
              </strong>
              {showItemActions &&
              selectedElement &&
              selectedElement.pageNo === pageIndex &&
              selectedElement.sectionNo === sectionIndex &&
              selectedElement.questionNo === questionIndex ? (
                <div
                  className={`action ${
                    TEXT_DIRECTION() === "rtl"
                      ? "action-delete-left"
                      : "action-delete"
                  }`}
                >
                  <button
                    className=" btn btn-danger delete"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowItemActions(false);
                      deleteQuestion(pageIndex, sectionIndex, questionIndex);
                    }}
                  >
                    <I18n lng="global.Delete" />
                  </button>
                  <button
                    className="btn btn-secondary cancel"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowItemActions(false);
                    }}
                  >
                    <I18n lng="global.Cancel" />
                  </button>
                </div>
              ) : null}
              <div>
                <Link
                  to="#"
                  className="icon icon-collapse"
                  id={"answer" + questionIndex + sectionIndex + pageIndex}
                  onClick={() => {
                    if (isOpen) {
                      closeQuestion();
                    } else {
                      closeAll(pageIndex, sectionIndex, questionIndex, true);
                      setSelectedElement(
                        pageIndex,
                        sectionIndex,
                        questionIndex,
                        true
                      );
                    }
                  }}
                >
                  <img src={IconDropdownArrow} alt="IconCollapseArrow" />
                </Link>
              </div>
            </div>
            <Collapse
              toggler={"answer" + questionIndex + sectionIndex + pageIndex}
              isOpen={isOpen}
            >
              <div className="answer-detail">
                {getBuilderQuestionPreview(question)}
                <div className="question-actions d-flex">
                  <Link
                    to="#"
                    className="question-change"
                    onClick={() => {
                      closeAll(pageIndex, sectionIndex, questionIndex, true);
                      setSelectedElement(
                        pageIndex,
                        sectionIndex,
                        questionIndex,
                        true
                      );
                      setActionItem({
                        pageNo: pageIndex,
                        sectionNo: sectionIndex,
                        questionNo: questionIndex,
                        isScored: isScored,
                      });
                      setShowResponseListDrawer(true);
                    }}
                  >
                    <img src={IconCircleArrowDown} alt="IconCircleArrowDown" />
                  </Link>
                  <Link
                    to="#"
                    className={`question-menu ${
                      TEXT_DIRECTION() === "rtl" ? "ml-0" : ""
                    }`}
                    onClick={() => {
                      closeAll(pageIndex, sectionIndex, questionIndex, true);
                      setSelectedElement(
                        pageIndex,
                        sectionIndex,
                        questionIndex,
                        true
                      );
                      setActionItem({
                        pageNo: pageIndex,
                        sectionNo: sectionIndex,
                        questionNo: questionIndex,
                        isScored: isScored,
                      });
                      setMcqID(question && question.id ? question.id : "");
                      setShowQuestionOptionsDrawer(true);
                    }}
                  >
                    <img src={IconMenu} alt="IconMenu" />
                  </Link>
                </div>
              </div>
              {isScored &&
              (question.type === "COMPLEX_MULTIPLE_CHOICE" ||
                question.type === "checkbox" ||
                question.type === "slider") ? (
                <div className="answer-detail answer-score">
                  <I18n lng="Form.OverallScoreAssignedToQuestion" />
                  <input
                    type="number"
                    min="0"
                    className="form-control"
                    value={questionScore}
                    onKeyDown={(e) =>
                      INPUT_VALUES_TO_EXCLUDE.includes(e.key) &&
                      e.preventDefault()
                    }
                    onChange={(e) => {
                      setQuestionScore(e.target.value);
                    }}
                    onBlur={(e) => {
                      if (e.target.value.match(/^ *$/) === null) {
                        updateScore(e.target.value);
                      } else {
                        updateScore("1");
                        toast.error(
                          i18n("validation.OnlyPositiveNumbersAreAllowed")
                        );
                      }
                    }}
                  />
                </div>
              ) : null}
            </Collapse>
          </li>
        </div>
      )}
    </Draggable>
  );
};

export default FormBuilderQuestion;
