import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import "../../assets/css/auth.min.css";
import { useForm } from "react-hook-form";
import I18n, { i18n } from "../private/languageSelector";
import IconCompany from "../../assets/images/icon-company.svg";
import Input from "../../components/form-input/input.component";
import IconIndustry from "../../assets/images/icon-industry.svg";
import Select from "../../components/form-input/select.component";
import ImgCompany from "../../assets/images/img-setup-company.svg";
import IconMultiUser from "../../assets/images/icon-multi-user.svg";
import IconArrowRight from "../../assets/images/icon-btn-arrow.svg";
import { logout, getSession, updateProfile } from "../../redux/reducers/authentication/authentication";
import { MIN_LENGTH, MAX_LENGTH } from "../../config/validations";

const companySetupInitialValues = {
  companyName: "",
  industry: "",
  numberOfEmployees: "",
};

const CompanySetup = ({ user, getSession, updateProfile, logout }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: companySetupInitialValues });
  const [ip, setIp] = useState("");
  const onSubmit = (data) => {
    updateProfile({
      id: user?.id,
      company: { ...data, ip: ip },
    })
      .then(() => {
        toast.success(<I18n lng="validation.COMPANY_CREATED" />);
        getSession();
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(<I18n lng="validation.AN_ERROR_OCCURED" />);
        }
      });
  };
  useEffect(() => {
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => {
        setIp(data?.ip);
      });
  }, []);

  return (
    <div className="auth-wrapper">
      <div className="auth-title">
        <h1>
          <I18n lng="CompanySetup.SetupYourCompany" />
        </h1>
        <img className="mt-4" src={ImgCompany} alt={"ImgCompany"}></img>
      </div>
      <form autoComplete="false" onSubmit={handleSubmit(onSubmit)}>
        <Input
          label={<I18n lng="CompanySetup.Company" />}
          prefix={IconCompany}
          id="companyInput"
          name="companyName"
          type="text"
          placeholder={i18n("Company.CompanyName")}
          register={register}
          validations={{
            required: {
              value: true,
              message: i18n("validation.REQUIRED_FIELD"),
            },
            minLength: { value: 3, message: MIN_LENGTH(3) },
            maxLength: { value: 50, message: MAX_LENGTH(50) },
          }}
          error={errors["companyName"]}
        />
        <Select
          placeholder={<I18n lng="global.Select" />}
          label={<I18n lng="CompanySetup.Industry" />}
          prefix={IconIndustry}
          id="companyInput"
          name="industry"
          value="Enter Industry"
          register={register}
          validations={{
            required: {
              value: true,
              message: i18n("validation.REQUIRED_FIELD"),
            },
          }}
          error={errors["industry"]}
          options={[
            "Agriculture",
            "Cleaning",
            "Community services",
            "Construction",
            "Education",
            "Emergency services",
            "Financial services",
            "Food and Beverages",
            "General",
            "Health services",
            "Hospitality",
            "ICT",
            "Manufacturing",
            "Mining",
            "Professional services",
            "Publishing",
            "Transport and logistics",
          ]}
        />
        <Select
          label={<I18n lng="CompanySetup.NumberOfEmployees" />}
          prefix={IconMultiUser}
          id="companyInput"
          placeholder={<I18n lng="global.Select" />}
          name="numberOfEmployees"
          value="Enter Number of Employees"
          register={register}
          validations={{
            required: {
              value: true,
              message: i18n("validation.REQUIRED_FIELD"),
            },
          }}
          error={errors["numberOfEmployees"]}
          options={["1--5", "6--19", "20--49", "50--99", "100--199", "200--499", "500--2999", "3000 and More"]}
        />
        <div className="btn-wrap text-center">
          <button type="submit" className="btn btn-primary btn-next">
            <div className="d-flex">
              <p className="mb-0">
                <I18n lng="global.Next" />
              </p>
              <img style={{ width: "12px" }} className="arrow-right" src={IconArrowRight} alt={"ImgCompany"} />
            </div>
          </button>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = ({ authentication }) => ({
  isAuthenticated: authentication.isAuthenticated,
  user: authentication.user,
});

const mapDispatchToProps = { getSession, updateProfile, logout };
export default connect(mapStateToProps, mapDispatchToProps)(CompanySetup);
