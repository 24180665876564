import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import I18n, { i18n } from "../../../languageSelector";
import DeleteIcon from "../../../../../assets/images/delete.svg";
import RestoreIcon from "../../../../../assets/images/restore.svg";
import { canAccessTheModule } from "../../../../../helper/helperFunctions";
import ConfirmationDialog from "../../../../../components/dialogs/confirmation-dialog";

const ListItemMenu = ({
  id,
  rolesAccess,
  unArchiveReports,
  deleteAdminManagerReports,
}) => {
  const [openConfirmationDialog, setOpenConfiirmationDialog] = useState(false);

  return (
    <>
      <div>
        {canAccessTheModule(
          rolesAccess?.report?.access?.restoreDeleteReport,
          <Link
            to="#"
            onClick={(e) => {
              e.preventDefault();
              unArchiveReports(id, false);
            }}
          >
            <img src={RestoreIcon} alt="RestoreIcon" className="mr-3 ml-3" />
          </Link>
        )}

        {canAccessTheModule(
          rolesAccess?.report?.access?.deleteArchive,
          <Link
            to="#"
            onClick={(e) => {
              e.preventDefault();
              setOpenConfiirmationDialog(true);
            }}
          >
            <img src={DeleteIcon} alt="DeleteIcon" className="icon-red" />
          </Link>
        )}
      </div>
      <ConfirmationDialog
        showDlg={openConfirmationDialog}
        title={i18n("global.Note")}
        classes="modal-alert"
        iconCloseDlg
        content={
          <p>
            <I18n lng="Reports.DeleteReport" />
          </p>
        }
        primaryActionTitle={i18n("global.Yes")}
        secondryActionTitle={i18n("global.No")}
        secondaryAction={() => setOpenConfiirmationDialog(false)}
        primaryAction={() => {
          deleteAdminManagerReports(id);
          setOpenConfiirmationDialog(false);
        }}
        primaryActionClasses={"btn-success"}
        secondryActionClasses={"btn-danger"}
      />
    </>
  );
};

const mapStateToProps = ({ authentication }) => ({
  rolesAccess: authentication.userRoleAccess,
});

export default connect(mapStateToProps, null)(ListItemMenu);
