import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import IonRangeSlider from "react-ion-slider";
import Attachment from "../snipets/attachment";
import { BsInfoCircleFill } from "react-icons/bs";
import I18n from "../../../../../languageSelector";

const SliderPreview = ({
  question,
  view,
  addUpdateAnwser = (answer) => {
    console.log(answer);
  },
  getAnswer = (uuid) => {
    console.log(uuid);
    return undefined;
  },
  currentRole,
  actionItem,
}) => {
  const [notes, setNotes] = useState("");
  const [photo, setPhoto] = useState([]);
  const [sliderValue, setSilderValue] = useState();
  const [answerScore, setAnswerScore] = useState(0);
  const [questionImageURL, setQuestionImageURL] = useState([]);
  const [isQuestionLoading, setIsQuestionLoading] = useState(true);
  const [questionPreview, setQuestionPreview] = useState(question);

  useEffect(() => {
    setIsQuestionLoading(true);
  }, []);

  // eslint-disable-next-line
  useEffect(async () => {
    if (question?.questionUuid) {
      const answer = getAnswer(question?.questionUuid);

      if (answer?.questionUuid) {
        setSilderValue(answer.sliderValue);
        setNotes(answer.note);
        setQuestionImageURL([...answer.image]);

        if (answer.image && answer.image.length) {
          setPhoto([...answer.image]);
        }
        setIsQuestionLoading(false);
      } else {
        setIsQuestionLoading(false);
      }
    }
    // eslint-disable-next-line
  }, [question]);

  useEffect(() => {
    if (question) {
      setQuestionPreview(question);
    }
  }, [question]);

  const imageDeleteHandler = (index) => {
    let removeImageArray = [...questionImageURL];
    removeImageArray.splice(index, 1);

    if (questionImageURL?.length) {
      if (!isQuestionLoading) {
        let answerToSave = {
          questionUuid: question.questionUuid,
          sliderValue: sliderValue,
          note: notes,
          image: [...removeImageArray],
        };
        addUpdateAnwser(answerToSave);
      }
      photo.splice(index, 1);
      setPhoto([...photo]);
    } else {
      setPhoto([]);
    }
  };

  useEffect(() => {
    let answerToSave = {
      questionUuid: question.questionUuid,
      sliderValue: sliderValue,
      note: notes,
      image: questionImageURL?.length ? [...questionImageURL] : [],
    };
    if (!isQuestionLoading) {
      addUpdateAnwser(answerToSave);
      if (actionItem?.isScored) {
        setAnswerScore(
          (sliderValue / question?.settings?.max) * question?.settings?.score
        );
      } else {
        setAnswerScore(0);
      }
    }
    // eslint-disable-next-line
  }, [sliderValue, notes]);

  const imageAddHandler = () => {
    if (photo && photo.length) {
      let answerToSave = {
        questionUuid: question.questionUuid,
        sliderValue: sliderValue,
        note: notes,
        image: [...photo],
      };

      setQuestionImageURL([...photo]);

      if (!isQuestionLoading) {
        addUpdateAnwser(answerToSave);
      }
    }
  };

  return (
    <div id={question?.questionUuid} className="multiple-choice">
      <div className="d-flex justify-content-between question-title h-auto">
        <strong>
          {questionPreview?.settings.questionText === "Type Instructions" ? (
            <I18n lng="Form.TypeInstructions" />
          ) : questionPreview?.settings.questionText ===
            "Add a new question" ? (
            <>
              {question?.questionNumber
                ? question?.questionNumber
                : actionItem?.questionNo === undefined
                ? actionItem
                : actionItem?.questionNo
                ? actionItem?.questionNo + 1
                : 1}
              .{"   "}
              <I18n lng="Form.AddANewQuestion" />{" "}
            </>
          ) : (
            `${
              question?.questionNumber
                ? question?.questionNumber
                : actionItem?.questionNo === undefined
                ? actionItem
                : actionItem?.questionNo
                ? actionItem?.questionNo + 1
                : 1
            }. ${questionPreview?.settings.questionText}`
          )}
          {question.settings.isMandatory && <span className="required"></span>}
        </strong>
        <div className="d-flex">
          {actionItem.isScored && question.settings.score !== "" ? (
            <span className="mr-2 score">
              <I18n lng="Form.Score" /> :
              {`${answerScore.toFixed(2)}/${+question.settings.score} (${
                ((answerScore / +question.settings.score) * 100)?.toFixed(2) ===
                "0"
                  ? "NAN"
                  : ((answerScore / +question.settings.score) * 100)?.toFixed(2)
              }%)`}
            </span>
          ) : null}
          {question.settings.description ? (
            <>
              <p
                data-for={`${question.settings.questionText}`}
                data-tip={`${question.settings.description}`}
                data-iscapture="true"
              >
                <BsInfoCircleFill color={"C4C4C4"} />
              </p>
              <ReactTooltip
                id={`${question.settings.questionText}`}
                place="top"
                type="dark"
                effect="float"
                multiline={true}
              />
            </>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="attachment-preview">
        {question?.settings?.photo ? (
          <img src={question.settings.photo} alt="Attachment Preview" />
        ) : (
          ""
        )}
      </div>
      <div className="input-range col-12 mx-auto">
        <IonRangeSlider
          type="single"
          skin="round"
          min={question.settings.min}
          max={question.settings.max}
          from={sliderValue ? sliderValue : question.settings.min}
          to={question.settings.max}
          step={question.settings.increment}
          to_shadow={true}
          grid={true}
          grid_num={5}
          keyboard={true}
          onFinish={(value) => {
            let toSet = value;
            setSilderValue(toSet.from);
          }}
          // block={view}
        />
      </div>
      {
        <Attachment
          canComment={
            sliderValue >= question.settings.min
              ? question?.settings?.canComment
              : false
          }
          canAttachPhoto={
            sliderValue >= question.settings.min
              ? question?.settings?.canAttachPhoto
              : false
          }
          notes={notes}
          setNotes={setNotes}
          photo={photo}
          setPhoto={setPhoto}
          imageDeleteHandler={imageDeleteHandler}
          imageAddHandler={imageAddHandler}
        />
      }
    </div>
  );
};
const mapStateToProps = ({ authentication }) => ({
  currentRole: authentication.currentRole,
});

export default connect(mapStateToProps, null)(SliderPreview);
