import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import Attachment from "../snipets/attachment";
import { BsInfoCircleFill } from "react-icons/bs";
import I18n, { i18n } from "../../../../../languageSelector";

const TextPreview = ({
  view,
  question,
  actionItem,
  currentRole,
  addUpdateAnwser = (answer) => {
    console.log(answer);
  },
  getAnswer = (uuid) => {
    console.log(uuid);
    return undefined;
  },
}) => {
  const [value, setValue] = useState("");
  const [notes, setNotes] = useState("");
  const [photo, setPhoto] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [answerText, setAnswerText] = useState("");
  const [answerSchema, setAnserSchema] = useState({});
  const [questionImageURL, setQuestionImageURL] = useState([]);
  const [questionPreview, setQuestionPreview] = useState(question);
  const [isQuestionLoading, setIsQuestionLoading] = useState(true);
  const [openEditImageEditor, setOpenEditImageEditor] = useState(false);

  useEffect(() => {
    setIsQuestionLoading(true);
  }, []);

  // eslint-disable-next-line
  useEffect(async () => {
    if (question) {
      setQuestionPreview(question);

      if (question?.questionUuid) {
        const answer = getAnswer(question?.questionUuid);
        if (answer?.questionUuid) {
          setAnserSchema({ ...answer });
          setValue(answer.value);
          setNotes(answer.note);
          setQuestionImageURL([...answer.image]);

          if (answer.image && answer.image.length) {
            setPhoto([...answer.image]);
          }
          setIsQuestionLoading(false);
        } else {
          setIsQuestionLoading(false);
          setValue("");
        }
      }
    }
    // eslint-disable-next-line
  }, [question]);

  const imageDeleteHandler = (index) => {
    let removeImageArray =
      answerSchema && answerSchema?.image?.length
        ? [...answerSchema.image]
        : [...questionImageURL];
    removeImageArray.splice(index, 1);

    if (answerSchema.image?.length || questionImageURL?.length) {
      if (!isQuestionLoading) {
        let answerToSave = {
          questionUuid: question.questionUuid,
          value: value,
          note: notes,
          image: [...removeImageArray],
        };
        addUpdateAnwser(answerToSave);
      }
      photo.splice(index, 1);
      setPhoto([...photo]);
    } else {
      setPhoto([]);
    }
  };

  useEffect(() => {
    let answerToSave = {
      questionUuid: question.questionUuid,
      value: value,
      note: notes,
      image: questionImageURL?.length ? [...questionImageURL] : [],
    };
    if (!isQuestionLoading) {
      addUpdateAnwser(answerToSave);
    }
    // eslint-disable-next-line
  }, [value, notes]);

  const imageAddHandler = () => {
    if (photo && photo.length) {
      let answerToSave = {
        questionUuid: question.questionUuid,
        value: value,
        note: notes,
        image: [...photo],
      };
      setQuestionImageURL([...photo]);

      if (!isQuestionLoading) {
        addUpdateAnwser(answerToSave);
      }
    }
  };

  useEffect(() => {
    setImageUrl(photo[0]);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (value !== "") {
      setAnswerText(value);
    }
  }, [value]);

  return (
    <div id={question?.questionUuid} className="multiple-choice">
      <div className="d-flex justify-content-between question-title h-auto">
        <strong>
          {questionPreview?.settings.questionText === "Type Instructions" ? (
            <I18n lng="Form.TypeInstructions" />
          ) : questionPreview?.settings.questionText ===
            "Add a new question" ? (
            <>
              {question?.questionNumber
                ? question?.questionNumber
                : actionItem?.questionNo === undefined
                ? actionItem
                : actionItem?.questionNo
                ? actionItem?.questionNo + 1
                : 1}
              . {"   "}
              <I18n lng="Form.AddANewQuestion" />{" "}
            </>
          ) : (
            `${
              question?.questionNumber
                ? question?.questionNumber
                : actionItem?.questionNo === undefined
                ? actionItem
                : actionItem?.questionNo
                ? actionItem?.questionNo + 1
                : 1
            }. ${questionPreview?.settings.questionText}`
          )}
          {question.settings.isMandatory && <span className="required"></span>}
        </strong>
        <div className="d-flex">
          {question.settings.description ? (
            <>
              <p
                data-for={`${question.settings.questionText}`}
                data-tip={`${question.settings.description}`}
                data-iscapture="true"
              >
                <BsInfoCircleFill color={"C4C4C4"} />
              </p>
              <ReactTooltip
                id={`${question.settings.questionText}`}
                place="top"
                type="dark"
                effect="float"
                multiline={true}
              />
            </>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="attachment-preview">
        {question?.settings?.photo ? (
          <img src={question.settings.photo} alt="Attachment Preview" />
        ) : (
          ""
        )}
      </div>
      <div className="input-group">
        <textarea
          className="form-control textarea"
          rows="4"
          placeholder={i18n("global.AddText")}
          value={answerText}
          maxLength={question.settings.textType === "SINGLE_LINE" ? 180 : 10000}
          onChange={(e) => setAnswerText(e.target.value)}
          onBlur={() => setValue(answerText)}
          // disabled={view}
        />
      </div>
      <span className="input-textcount">
        {question.settings.textType === "SINGLE_LINE"
          ? `${answerText ? answerText.split("").length : 0} / 180`
          : null}
      </span>

      {
        <Attachment
          canComment={
            answerText === "" ? false : question?.settings?.canComment
          }
          canAttachPhoto={
            answerText === "" ? false : question?.settings?.canAttachPhoto
          }
          notes={notes}
          setNotes={setNotes}
          photo={photo}
          setPhoto={setPhoto}
          imageUrl={imageUrl}
          openEditImageEditor={openEditImageEditor}
          setOpenEditImageEditor={setOpenEditImageEditor}
          imageDeleteHandler={imageDeleteHandler}
          imageAddHandler={imageAddHandler}
        />
      }
    </div>
  );
};
const mapStateToProps = ({ authentication }) => ({
  currentRole: authentication.currentRole,
});

export default connect(mapStateToProps, null)(TextPreview);
