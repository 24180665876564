import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import Attachment from "../snipets/attachment";
import { BsInfoCircleFill } from "react-icons/bs";
import I18n from "../../../../../languageSelector";
import { floatOrIntegerNumber } from "../../../../../../../helper/helperFunctions";
import { GET_MCQ_NAME } from "../../../../../../../helper/formHelperFunctions";

const MultipleChoicePreview = ({
  view,
  question,
  actionItem,
  currentRole,
  getAnswer = (uuid) => console.log(uuid),
  removeFromAnswers = (uuid) => console.log(uuid),
  addUpdateAnwser = (answer) => console.log(answer),
}) => {
  const [questionPreview, setQuestionPreview] = useState(question);
  const [selected, setSelected] = useState([]);
  const [answerIndex, setAnswerIndex] = useState();
  const [answerScore, setAnswerScore] = useState(0);
  const [isQuestionLoading, setIsQuestionLoading] = useState(true);
  const [notes, setNotes] = useState("");
  const [photo, setPhoto] = useState([]);
  const [questionImageURL, setQuestionImageURL] = useState([]);
  const [answerSchema, setAnserSchema] = useState({});
  const [isFloatAnswerScore, setIsFloatAnswerScore] = useState(false);
  const [isFloatQuestionScore, setIsFloatQuestionScore] = useState(false);
  const [canComment, setCanComment] = useState(false);
  const [canAttachPhoto, setCanAttachPhoto] = useState(false);

  useEffect(() => {
    setIsQuestionLoading(true);
  }, []);

  useEffect(() => {
    if (question) {
      setQuestionPreview(question);

      if (question?.questionUuid) {
        const answer = getAnswer(question?.questionUuid);

        if (answer?.questionUuid) {
          setAnserSchema({ ...answer });
          setNotes(answer.note);
          setSelected([...answer.selected]);
          setQuestionImageURL([...answer.image]);
          if (answer.image && answer.image.length) {
            setPhoto([...answer.image]);
          }

          setIsQuestionLoading(false);
        } else {
          setIsQuestionLoading(false);
          setSelected([]);
        }
      }
    }

    // eslint-disable-next-line
  }, [question]);

  useEffect(() => {
    if (question?.settings?.score) {
      setIsFloatQuestionScore(
        floatOrIntegerNumber(Number(question?.settings?.score))
      );
    }
    if (question?.settings?.answers && answerIndex >= 0) {
      setIsFloatAnswerScore(
        floatOrIntegerNumber(
          Number(question?.settings?.answers[answerIndex].score)
        )
      );
    }
  }, [question, answerIndex]);

  useEffect(() => {
    let answerToSave = {
      questionUuid: question.questionUuid,
      selected: [...selected],
      note: notes,
      image:
        questionImageURL && questionImageURL.length
          ? [...questionImageURL]
          : [],
    };
    if (!isQuestionLoading && selected?.length) {
      addUpdateAnwser(answerToSave);
    }
    // eslint-disable-next-line
  }, [selected]);

  useEffect(() => {
    let answerToSave = {
      questionUuid: question.questionUuid,
      selected: [...selected],
      note: notes,
      image:
        questionImageURL && questionImageURL.length
          ? [...questionImageURL]
          : [],
    };
    if (!isQuestionLoading) {
      addUpdateAnwser(answerToSave);
    }
    // eslint-disable-next-line
  }, [notes]);

  const selectAnswer = (selectionType, index) => {
    if (selectionType === "MULTIPLE") {
      setSelected([...selected, { index, note: "", photo: "" }]);
    } else {
      setSelected([{ index, note: "", photo: "" }]);
    }
  };

  const unSelectAnswer = (index) => {
    setAnswerIndex();
    removeFromAnswers(question.questionUuid);
    setSelected([...selected.filter((item) => item.index !== index)]);
  };

  const isSelected = (index) => {
    const el = selected.find((item) => item.index === index);
    return el ? true : false;
  };

  useEffect(() => {
    let scoreCalculation;
    if (selected) {
      scoreCalculation = selected.map((item) => {
        return question.settings.answers[item.index].score !== "-"
          ? +question.settings.answers[item.index].score
          : 0;
      });
    }
    let ansScore = scoreCalculation.length
      ? scoreCalculation.reduce((a, b) => {
          return a + b;
        })
      : 0;
    setAnswerScore(ansScore);
    // eslint-disable-next-line
  }, [selected]);

  const imageDeleteHandler = (index) => {
    let removeImageArray =
      answerSchema && answerSchema?.image?.length
        ? [...answerSchema.image]
        : [...questionImageURL];
    removeImageArray.splice(index, 1);

    if (answerSchema.image?.length || questionImageURL?.length) {
      if (!isQuestionLoading) {
        let answerToSave = {
          questionUuid: question.questionUuid,
          selected: selected,
          note: notes,
          image: [...removeImageArray],
        };
        addUpdateAnwser(answerToSave);
      }
      photo.splice(index, 1);
      setPhoto([...photo]);
    } else {
      if (photo && photo.length) {
        photo.splice(index, 1);
        setPhoto([...photo]);
      }
    }
  };

  const imageAddHandler = () => {
    if (photo && photo.length) {
      let answerToSave = {
        questionUuid: question.questionUuid,
        selected: [...selected],
        note: notes,
        image: [...photo],
      };
      setQuestionImageURL([...photo]);

      if (!isQuestionLoading) {
        addUpdateAnwser(answerToSave);
      }
    }
  };

  useEffect(() => {
    const newCanComment = selected.some(
      (item) => question.settings.answers[item.index]?.canComment
    );

    const newCanAttachPhoto = selected.some(
      (item) => question.settings.answers[item.index]?.canAttachPhoto
    );

    setCanComment(newCanComment);
    setCanAttachPhoto(newCanAttachPhoto);
    // eslint-disable-next-line
  }, [selected]);

  return (
    <div id={question?.questionUuid} className="multiple-choice">
      <div className="d-flex justify-content-end w-100">
        {actionItem?.isScored &&
          (question.settings.selectionType === "SINGLE" ? (
            question.settings.score !== "" ? (
              // eslint-disable-next-line
              question.settings.answers[answerIndex]?.score != "/" &&
              // eslint-disable-next-line
              question.settings.answers[answerIndex]?.score != "-" ? (
                <span className="mr-2 score">
                  <I18n lng="Form.Score" /> :{" "}
                  {answerIndex !== null && answerIndex !== undefined ? (
                    `${Number(
                      question?.settings?.answers[answerIndex]?.score
                    ).toFixed(isFloatAnswerScore ? 2 : 0)}/${Number(
                      question?.settings?.score
                    ).toFixed(isFloatQuestionScore ? 2 : 0)} (${
                      question.settings.answers[answerIndex].score === "-" ||
                      question.settings.answers[answerIndex].score === "/"
                        ? ((0 / +question.settings.score) * 100)?.toFixed(2)
                        : (
                            (question.settings.answers[answerIndex].score /
                              +question.settings.score) *
                            100
                          )?.toFixed(2) === "0"
                        ? "NAN"
                        : (
                            (question.settings.answers[answerIndex].score /
                              +question.settings.score) *
                            100
                          )?.toFixed(2)
                    }%)`
                  ) : (
                    <span className="mr-2 score">
                      {`0/${+question.settings.score} (0%)`}
                    </span>
                  )}
                </span>
              ) : null
            ) : null
          ) : question.settings.score !== "" ? (
            <span className="mr-2 score">
              <I18n lng="Form.Score" /> :
              {`${answerScore}/${+question.settings.score} (${
                ((answerScore / +question.settings.score) * 100)?.toFixed(2) ===
                "0"
                  ? "NAN"
                  : ((answerScore / +question.settings.score) * 100)?.toFixed(2)
              }%)`}
            </span>
          ) : null)}

        {question.settings.description ? (
          <>
            <p
              data-for={`${question.settings.questionText}`}
              data-tip={`${question.settings.description}`}
              data-iscapture="true"
            >
              <BsInfoCircleFill color={"C4C4C4"} />
            </p>
            <ReactTooltip
              id={`${question.settings.questionText}`}
              place="top"
              type="dark"
              effect="float"
              multiline={true}
            />
          </>
        ) : (
          ""
        )}
      </div>
      <div className="question-title h-auto d-block w-100">
        <strong>
          {questionPreview?.settings.questionText === "Type Instructions" ? (
            <I18n lng="Form.TypeInstructions" />
          ) : questionPreview?.settings.questionText ===
            "Add a new question" ? (
            <>
              {question?.questionNumber
                ? question?.questionNumber
                : actionItem?.questionNo === undefined
                ? actionItem
                : actionItem?.questionNo
                ? actionItem?.questionNo + 1
                : 1}
              .{"   "}
              <I18n lng="Form.AddANewQuestion" />{" "}
            </>
          ) : (
            ` ${
              question?.questionNumber
                ? question?.questionNumber
                : actionItem?.questionNo === undefined
                ? actionItem
                : actionItem?.questionNo
                ? actionItem?.questionNo + 1
                : 1
            }.   ${questionPreview?.settings.questionText}`
          )}
          {question.settings.isMandatory && <span className="required"></span>}
        </strong>{" "}
      </div>
      <div className="attachment-preview">
        {question?.settings?.photo ? (
          <img src={question.settings.photo} alt="Attachment Preview" />
        ) : (
          ""
        )}
      </div>

      {questionPreview?.settings?.answers.map((answer, index) => (
        <React.Fragment key={index}>
          <button
            className={`btn btn-multiple-choice align-items-center pt-2 pb-2 mt-2 w-100 ${
              isSelected(index) ? answer.class : ""
            }`}
            onClick={() => {
              if (isSelected(index)) {
                unSelectAnswer(index);
              } else {
                selectAnswer(question.settings.selectionType, index);
                setAnswerIndex(index);
              }
            }}
            // disabled={view}
          >
            {GET_MCQ_NAME(answer.name)}
          </button>
        </React.Fragment>
      ))}

      <Attachment
        canComment={canComment}
        canAttachPhoto={canAttachPhoto}
        notes={notes}
        setNotes={setNotes}
        photo={photo}
        setPhoto={setPhoto}
        imageAddHandler={imageAddHandler}
        imageDeleteHandler={imageDeleteHandler}
      />
    </div>
  );
};

const mapStateToProps = ({ authentication }) => ({
  currentRole: authentication.currentRole,
});

export default connect(mapStateToProps, null)(MultipleChoicePreview);
