import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import "react-phone-number-input/style.css";
import { Link, useHistory } from "react-router-dom";
import DynamicModal from "../../../../../components/dialogs";
import IconUser from "../../../../../assets/images/icon-user.svg";
import IconLock from "../../../../../assets/images/icon-lock.svg";
import IconCell from "../.././../../../assets/images/icon-cell.svg";
import IconClock from "../../../../../assets/images/icon-clock.svg";
import IconGlobe from "../../../../../assets/images/icon-globe.svg";
import IconEmail from "../.././../../../assets/images/icon-envelope.svg";
import IconDelete from "../../../../../assets/images/icon-delete-red.svg";
import IconEditAvatar from "../../../../../assets/images/icon-edit-avatar.svg";
import { updateUserById } from "../../../../../redux/reducers/user/user.reducer";
import { uploadImage } from "../../../../../redux/reducers/form/form.reducer";
import {
  logout,
  getSession,
  changeUsersPassword,
} from "../../../../../redux/reducers/authentication/authentication";
import Select from "react-select";
import { FaRegUserCircle } from "react-icons/fa";
import IconEye from "../../../../../assets/images/icon-eye.svg";
import { getFileData } from "../../../../../helper/image-helper";
import I18n, { i18n } from "../../../../private/languageSelector";
import { REQUIRED_FIELD } from "../../../../../config/validations";
import Input from "../../../../../components/form-input/input.component";
import { TEXT_DIRECTION } from "../../../../../helper/helperFunctions";

const CompanyUsersProfile = ({
  showDlg,
  secondaryAction,
  updateUserById,
  changeUsersPassword,
  logout,
  getSession,
  uploadImage,
  user,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm();

  const history = useHistory();
  const hiddenFileInput = React.useRef(null);

  const [showChangePasswordFields, setShowChangePasswordFields] =
    useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [image, setImage] = useState(
    "https://tamam-bucket.s3.me-south-1.amazonaws.com/ee6023bcb103ac283cbe79ec86d16323.png"
  );

  const imageHandleChange = async (event) => {
    const fileData = await getFileData(event);
    let existingFileUrl = image ? image : user?.profileImage;
    setImage(fileData.fileUrl);

    uploadImage({
      base64: fileData.fileUrl,
      existingFileUrl,
      fileType: fileData.fileType,
    }).then((response) => {
      setImage(response.value.data);

      updateUserById({ ...user, profileImage: response.value.data }).then(
        (res) => {
          getSession();
          setImage(res.value.data.profileImage);
        }
      );
    });
  };

  const deviceDeleteHandler = (index, deviceId) => {
    if (deviceId && deviceId === localStorage.getItem("deviceId")) {
      history.push("/logout");
    }
    let userDevices = [];
    userDevices = [...user?.userDevices];
    userDevices.splice(index, 1);

    updateUserById({ ...user, userDevices })
      .then(() => getSession())
      .catch((error) => toast.error(error?.response?.data?.message));
  };

  const onSubmitPasswordHandler = (data) => {
    if (data.newPassword !== data.currentPassword) {
      if (data.newPassword === data.confirmPassword) {
        changeUsersPassword({
          oldPassword: data.currentPassword,
          newPassword: data.newPassword,
        })
          .then(() => {
            toast.success(<I18n lng="validation.PasswordChanged" />);
            setShowChangePasswordFields(false);
            secondaryAction();
            reset();
            logout();
            history.push("/login");
          })
          .catch((error) => {
            error?.response?.data?.message === "Incorrect email or password"
              ? toast.error("Current Password Incorrect")
              : toast.error(error?.response?.data?.message);
          });
      } else {
        toast.error(<I18n lng="validation.PasswordDoesntMatch" />);
      }
    } else {
      toast.error(<I18n lng="validation.PasswordShouldBeDifferent" />);
    }
  };

  useEffect(() => {
    setImage(user?.profileImage);
    // eslint-disable-next-line
  }, [user]);

  const languageChangeHandler = (language) => {
    if (language) {
      updateUserById({ ...user, language: language })
        .then((res) => {
          localStorage.setItem("language", res.value.data.language);
          getSession();
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message);
        });
    }
  };

  useEffect(() => {
    if (!showDlg) {
      setShowChangePasswordFields(false);
    }
  }, [showDlg]);

  return (
    <>
      <DynamicModal
        customClasses={`modal-drawer user-profile-drawer ${
          TEXT_DIRECTION() === "rtl" ? "left-direction icon-left" : ""
        }`}
        show={showDlg}
        secondaryAction={secondaryAction}
      >
        <label className="form-label">
          <I18n lng="UserProfile.Informations" />
        </label>
        <ul className="user-detail mt-0">
          <div className="user-avatar mb-3">
            <div className="img-wrap">
              {user?.profileImage ? (
                <div className="avatar-wrap">
                  <img src={user?.profileImage} alt="loading" />
                </div>
              ) : (
                <FaRegUserCircle size={"4rem"} />
              )}

              <button type="button" className="btn btn-edit">
                <img src={IconEditAvatar} alt="loading" />
                <input
                  type="file"
                  ref={hiddenFileInput}
                  onChange={imageHandleChange}
                />
              </button>
            </div>
          </div>
          <li>
            <span className="icon-wrap">
              <img src={IconUser} alt="loading" />
            </span>
            <label>
              <span>
                <I18n lng="global.Name" />:
              </span>
            </label>
            <span className="user-info">{user?.fullName}</span>
          </li>
          <li>
            <span className="icon-wrap">
              <img src={IconEmail} alt="loading" />
            </span>
            <label>
              <span>
                <I18n lng="global.Email" />:
              </span>{" "}
            </label>
            <span className="user-info">{user?.email}</span>
          </li>
          <li>
            <span className="icon-wrap">
              <img src={IconCell} alt="loading" />
            </span>
            <label>
              <span>
                <I18n lng="global.Mobile" />:
              </span>{" "}
            </label>
            <span className="user-info">{user?.mobile}</span>
          </li>
          <li>
            <span className="icon-wrap">
              <img src={IconClock} alt="loading" />
            </span>
            <label>
              <span>
                <I18n lng="global.TimeZone" />:
              </span>{" "}
            </label>
            <span className="user-info">{user?.timeZone}</span>
          </li>
        </ul>
        <ul className="user-detail list-unstyled">
          <li>
            <span className="icon-wrap">
              <img src={IconLock} alt="loading" />
            </span>
            <label>
              <span>
                <I18n lng="global.Password" />:
              </span>
            </label>
            <Link
              to="#"
              onClick={() => {
                setShowChangePasswordFields(
                  showChangePasswordFields ? false : true
                );
              }}
            >
              <I18n lng="global.ChangePassword" />
            </Link>
          </li>
          {showChangePasswordFields && (
            <>
              <form onSubmit={handleSubmit(onSubmitPasswordHandler)}>
                <div
                  className={`input-password ${
                    !showCurrentPassword ? "hide-password" : ""
                  }`}
                >
                  <Input
                    label={<I18n lng="global.CurrentPassword" />}
                    prefix={IconLock}
                    suffix={IconEye}
                    suffixAction={() => {
                      setShowCurrentPassword(!showCurrentPassword);
                    }}
                    id="passwordInput"
                    name="currentPassword"
                    type={showCurrentPassword ? "text" : "password"}
                    placeholder={i18n("global.CurrentPassword")}
                    register={register}
                    validations={{
                      required: { value: true, message: REQUIRED_FIELD },
                    }}
                    error={errors["password"]}
                  />
                </div>
                <div
                  className={`input-password ${
                    !showNewPassword ? "hide-password" : ""
                  }`}
                >
                  <Input
                    label={<I18n lng="global.NewPassword" />}
                    prefix={IconLock}
                    suffix={IconEye}
                    suffixAction={() => {
                      setShowNewPassword(!showNewPassword);
                    }}
                    id="passwordInput"
                    name="newPassword"
                    type={showNewPassword ? "text" : "password"}
                    placeholder={i18n("global.NewPassword")}
                    register={register}
                    validations={{
                      required: { value: true, message: REQUIRED_FIELD },
                    }}
                    error={errors["password"]}
                  />
                </div>
                <div
                  className={`input-password ${
                    !showConfirmPassword ? "hide-password" : ""
                  }`}
                >
                  <Input
                    label={<I18n lng="global.ConfirmPassword" />}
                    prefix={IconLock}
                    suffix={IconEye}
                    suffixAction={() => {
                      setShowConfirmPassword(!showConfirmPassword);
                    }}
                    id="passwordInput"
                    name="confirmPassword"
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder={i18n("global.ConfirmPassword")}
                    register={register}
                    validations={{
                      required: { value: true, message: REQUIRED_FIELD },
                    }}
                    error={errors["password"]}
                  />
                </div>
                <div className="btn-bar">
                  <button className="btn btn-primary" type="submit">
                    <I18n lng="global.Apply" />
                  </button>
                  <button
                    className="btn btn-white"
                    onClick={() => {
                      setShowChangePasswordFields(false);
                    }}
                  >
                    <I18n lng="global.Cancel" />
                  </button>
                </div>
              </form>
            </>
          )}
          <li>
            <span className="icon-wrap">
              <img src={IconGlobe} alt="loading" />
            </span>
            <label className="d-flex align-items-center w-100 overflow-visible">
              <span>
                <I18n lng="UserProfile.Language" />:{" "}
              </span>
              <div className="input-group input-select  mb-0">
                <Select
                  placeholder={<I18n lng="global.Select" />}
                  className="react-select"
                  label={<I18n lng="UserProfile.Language" />}
                  name="language"
                  value={{
                    value: user?.language,
                    label:
                      user?.language === "ARABIC"
                        ? `${i18n("global.Arabic")}`
                        : user?.language,
                  }}
                  register={register}
                  control={control}
                  onChange={(e) => languageChangeHandler(e.value)}
                  validations={{}}
                  options={[
                    {
                      value: "ARABIC",
                      label: `${i18n("global.Arabic")}`,
                    },
                    { value: "ENGLISH", label: "ENGLISH" },
                  ]}
                />
              </div>
            </label>
          </li>
        </ul>

        <div className="border-block block-devices">
          {user?.role !== "admin" ? (
            <>
              <label className="text-muted pl-2 m-0">
                <I18n lng="UserProfile.DeviceAllowance" /> :
                {user?.userDevices?.length}/3
              </label>
              <ul className="inner">
                {user?.userDevices?.map((device, index) => {
                  return (
                    <li key={index}>
                      <span>{device.deviceName}</span>
                      <div className="block-actions">
                        {user?.userDevices.length > 1 ? (
                          <Link to="#" className="action action-delete">
                            <img
                              src={IconDelete}
                              alt="Delete"
                              title="Delete"
                              onClick={(e) => {
                                e.preventDefault();
                                deviceDeleteHandler(index, device._id);
                              }}
                            />
                          </Link>
                        ) : (
                          ""
                        )}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </>
          ) : null}
        </div>
      </DynamicModal>
    </>
  );
};
const mapStateToProps = ({ authentication }) => ({
  user: authentication.user,
});

const mapDispatchToProps = {
  logout,
  getSession,
  uploadImage,
  updateUserById,
  changeUsersPassword,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyUsersProfile);
