import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./reports-ui-helper";

const ReportsUIContext = createContext();

export function useReportsUIContext() {
  return useContext(ReportsUIContext);
}

export const ReportsUIConsumer = ReportsUIContext.Consumer;

export function ReportsUIProvider({ reportsUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    openReportViewDialog: reportsUIEvents.openReportViewDialog,
    openReportEditDialog: reportsUIEvents.openReportEditDialog,
    openReportExportViewDialog: reportsUIEvents.openReportExportViewDialog,
    archiveReports: reportsUIEvents.archiveReports,
    viewHistoryReport: reportsUIEvents.viewHistoryReport,
    viewOtherHistoryReport: reportsUIEvents.viewOtherHistoryReport,
    unArchiveReports: reportsUIEvents.unArchiveReports,
    deleteAdminManagerReports: reportsUIEvents.deleteAdminManagerReports,
    openEditReportDialog: reportsUIEvents.openEditReportDialog,
    openOtherReportsView: reportsUIEvents.openOtherReportsView,
    openOtherReportsManagerView: reportsUIEvents.openOtherReportsManagerView,
    reportBookmarkUnBookMarked: reportsUIEvents.reportBookmarkUnBookMarked,
    openOtherReportShareDialog: reportsUIEvents.openOtherReportShareDialog,
  };

  return (
    <ReportsUIContext.Provider value={value}>
      {" "}
      {children}{" "}
    </ReportsUIContext.Provider>
  );
}
