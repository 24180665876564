import React from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import "../../assets/css/auth.min.css";
import { useForm } from "react-hook-form";
import { Redirect, useHistory } from "react-router-dom";
import I18n, { i18n } from "../private/languageSelector";
import IconEmail from "../../assets/images/icon-envelope.svg";
import Input from "../../components/form-input/input.component";
import ImgLock from "../../assets/images/img-password-reset.svg";
import Checkbox from "../../components/form-input/checkbox.component";
import { MIN_LENGTH, MAX_LENGTH, EMAIL_REGEX } from "../../config/validations";
import { TEXT_DIRECTION } from "../../helper/helperFunctions";

const forgotPasswordInitialValues = {
  email: "",
  robotCheck: false,
};
const PasswordResetRequest = ({ isAuthenticated, location }) => {
  const history = useHistory();

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({ defaultValues: forgotPasswordInitialValues });

  const robotCheck = watch("robotCheck");

  const { from } = location.state || {
    from: { pathname: "/", search: location.search },
  };

  if (isAuthenticated) {
    return <Redirect to={from} />;
  }

  const onSubmit = (data) => {
    axios
      .post(`/auth/forgot-password`, { email: data.email })
      .then(() => {
        toast.success(<I18n lng="validation.EMAIL_SENT" />);
        history.push("/password-reset-message-email");
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(<I18n lng="validation.AN_ERROR_OCCURED" />);
        }
      });
    reset(forgotPasswordInitialValues);
  };
  console.log(robotCheck);
  return (
    <div className="auth-wrapper">
      <div className="auth-title">
        <h1 className="font-weight-light font-primary text-center">
          <I18n lng="ForgotPassword.PasswordReset" />
        </h1>
        <img className="mt-4 mb-5" src={ImgLock} alt={"ImgCompany"}></img>
        <p className="text-center">
          <I18n lng="ForgotPassword.PleaseInterYourEmaiToReceiveAPasswordResetMessage" />
        </p>
      </div>
      <form autoComplete="false" onSubmit={handleSubmit(onSubmit)}>
        <Input
          label={<I18n lng="global.Email" />}
          prefix={IconEmail}
          id="emailInput"
          name="email"
          type="email"
          placeholder={i18n("global.Email")}
          register={register}
          validations={{
            required: {
              value: true,
              message: i18n("validation.REQUIRED_FIELD"),
            },
            minLength: { value: 3, message: MIN_LENGTH(3) },
            maxLength: { value: 50, message: MAX_LENGTH(50) },
            pattern: {
              value: EMAIL_REGEX,
              message: i18n("validation.INVALID_EMAIL"),
            },
          }}
          error={errors["email"]}
        />
        <div className="row">
          <div className={`${TEXT_DIRECTION() === "rtl" ? "mr-3" : "col-1 "}`}>
            <Checkbox
              id="robotCheck"
              name="robotCheck"
              register={register}
              validations={{
                required: {
                  value: true,
                  message: i18n("validation.REQUIRED_FIELD"),
                },
              }}
              error={errors["robotCheck"]}
            />
          </div>
          <div
            style={{ color: "#20639b" }}
            className={`${
              TEXT_DIRECTION() === "rtl" ? "col-9 pr-1" : "col-9 pl-3"
            }`}
          >
            <label className="mb-0 mx-1">
              {i18n("ForgotPassword.ImNoRobot")}
            </label>
          </div>
        </div>

        <div className="btn-bar text-center">
          <button
            type="button"
            onClick={() => {
              history.push("/login");
            }}
            className="btn btn-white mr-2"
            style={{ padding: ".5rem 2rem" }}
          >
            <I18n lng="global.Back" />
          </button>
          <button
            disabled={!robotCheck}
            type="submit"
            className="btn btn-primary mx-2"
          >
            <I18n lng="global.Send" />
          </button>
        </div>
      </form>
    </div>
  );
};
const mapStateToProps = ({ authentication }) => ({
  isAuthenticated: authentication.isAuthenticated,
});

export default connect(mapStateToProps, null)(PasswordResetRequest);
