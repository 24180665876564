import React, { useEffect, useState } from "react";
import moment from "moment";
import { Button } from "reactstrap";
import { connect } from "react-redux";
import I18n, { i18n } from "../languageSelector";
import DynamicModal from "../../../components/dialogs";
import { getNotificationTime } from "../../../helper/date-time-helper";
import { getAllNotifications } from "../../../redux/reducers/notifications/notifications.reducer";
import { TEXT_DIRECTION } from "../../../helper/helperFunctions";

const NotificationsDialog = ({
  showDlg,
  secondaryAction,
  allNotifications,
  markedAsReadHandle,
  getAllNotifications,
  markedReadSingleHandler,
}) => {
  const [unReadNotification, setUnReadNotification] = useState([]);

  useEffect(() => {
    if (showDlg) {
      getAllNotifications();
      setUnReadNotification([]);
    }
    // eslint-disable-next-line
  }, [showDlg]);

  useEffect(() => {
    let unreadArray = [];
    if (allNotifications && allNotifications.length) {
      unreadArray = allNotifications
        .filter((item) => !item.isRead)
        .map((item) => item);
    }
    setUnReadNotification([...unreadArray]);
  }, [allNotifications]);
  return (
    <>
      <DynamicModal
        show={showDlg}
        title={<I18n lng="global.NotificationsCenter" />}
        secondaryAction={secondaryAction}
        customClasses={`modal-drawer user-profile-drawer ${
          TEXT_DIRECTION() === "rtl" ? "left-direction" : ""
        }`}
        count={
          unReadNotification && unReadNotification.length
            ? unReadNotification.length
            : ""
        }
      >
        <div>
          <ul
            style={TEXT_DIRECTION() === "rtl" ? { paddingRight: "0px" } : {}}
            className="notification list-unstyled mt-3"
          >
            {allNotifications && allNotifications.length
              ? allNotifications.map((item, index) => {
                  return (
                    <li
                      key={index}
                      onClick={() => markedReadSingleHandler(item.id)}
                    >
                      <p className="notification-title d-flex ">
                        {item.isRead ? (
                          <span
                            style={
                              TEXT_DIRECTION() === "rtl"
                                ? { fontWeight: "500", marginRight: "13px" }
                                : { fontWeight: "500", marginLeft: "13px" }
                            }
                          >
                            {item.event === "assigned"
                              ? i18n("Notifications.FormAssignment")
                              : item.event === "approved"
                              ? i18n("Notifications.ReportApproved")
                              : item.event === "rejected"
                              ? i18n("Notifications.ReportRejected")
                              : item.event === "incomplete"
                              ? i18n("Notifications.ReportIncomplete")
                              : item.event === "roleUpdate"
                              ? i18n("Notifications.RoleUpdated")
                              : item.event === "formUpdate"
                              ? i18n("Notifications.FormUpdated")
                              : item.event === "reSubmit"
                              ? i18n("Notifications.ReportReSubmitted")
                              : i18n("Notifications.ReportSubmitted")}
                          </span>
                        ) : (
                          <div className="d-flex align-items-center">
                            <div
                              style={
                                TEXT_DIRECTION() === "rtl"
                                  ? { marginLeft: "8px", marginRight: "0" }
                                  : { marginRight: "8px" }
                              }
                              className="dot"
                            />
                            <strong>
                              {item.event === "assigned"
                                ? i18n("Notifications.FormAssignment")
                                : item.event === "approved"
                                ? i18n("Notifications.ReportApproved")
                                : item.event === "rejected"
                                ? i18n("Notifications.ReportRejected")
                                : item.event === "incomplete"
                                ? i18n("Notifications.ReportIncomplete")
                                : item.event === "roleUpdate"
                                ? i18n("Notifications.RoleUpdated")
                                : item.event === "formUpdate"
                                ? i18n("Notifications.FormUpdated")
                                : item.event === "reSubmit"
                                ? i18n("Notifications.ReportReSubmitted")
                                : i18n("Notifications.ReportSubmitted")}
                            </strong>
                          </div>
                        )}
                        <span className="text-muted">
                          {moment(item.createdAt).locale("it").fromNow() !==
                          "7 days ago"
                            ? getNotificationTime(item.createdAt)
                            : moment(item.createdAt).format("DD/MM/YYYY")}
                        </span>
                      </p>
                      <span
                        style={
                          TEXT_DIRECTION() === "rtl"
                            ? { paddingRight: "12px" }
                            : { paddingLeft: "12px" }
                        }
                        className="notification-text"
                      >
                        {item.event === "assigned" ? (
                          <span>
                            {" "}
                            <I18n lng="Notifications.NewFormTitle" />{" "}
                            <b>
                              "
                              {item?.form?.formDetail?.title.includes(
                                "Untitled Form"
                              ) ? (
                                <>
                                  <I18n lng="global.UntitledForm" />{" "}
                                  {item?.form?.formDetail?.documentNumber}{" "}
                                </>
                              ) : (
                                item?.form?.formDetail?.title
                              )}
                              "
                            </b>{" "}
                            <I18n lng="Notifications.HasBeenAssigned" />{" "}
                            <b>"{item?.sender?.fullName}"</b>{" "}
                            <I18n lng="Notifications.On" />{" "}
                            {moment(item.createdAt).format(
                              "DD/MM/YYYY hh:mm A"
                            )}{" "}
                          </span>
                        ) : item.event === "approved" ? (
                          <span>
                            <I18n lng="Notifications.YourReportTitle" />{" "}
                            <b>
                              "
                              {item?.report?.formDetail?.title ? (
                                item?.report?.formDetail?.title.includes(
                                  "Untitled Form"
                                ) ? (
                                  <>
                                    {item?.report?.formDetail?.title.replace(
                                      "Untitled Form",
                                      i18n("global.UntitledForm")
                                    )}{" "}
                                  </>
                                ) : (
                                  item?.report?.formDetail?.title
                                )
                              ) : null}{" "}
                              -{" "}
                              {item?.report?.site?.name === "Default Site"
                                ? `${i18n("Company.DefaultSite")}`
                                : item?.report?.site?.name}{" "}
                              - {moment(item.createdAt).format("DD MM YYYY")} -{" "}
                              {item?.report?.assignee?.fullName}"
                            </b>{" "}
                            <I18n lng="Notifications.HasBeenApprovedBy" />{" "}
                            <b>"{item?.sender?.fullName}"</b>{" "}
                            <I18n lng="Notifications.On" />{" "}
                            {moment(item.createdAt).format(
                              "DD/MM/YYYY hh:mm A"
                            )}{" "}
                          </span>
                        ) : item.event === "rejected" ? (
                          <span>
                            <I18n lng="Notifications.YourReportTitle" />{" "}
                            <b>
                              "
                              {item?.report?.formDetail?.title ? (
                                item?.report?.formDetail?.title.includes(
                                  "Untitled Form"
                                ) ? (
                                  <>
                                    {item?.report?.formDetail?.title.replace(
                                      "Untitled Form",
                                      i18n("global.UntitledForm")
                                    )}{" "}
                                  </>
                                ) : (
                                  item?.report?.formDetail?.title
                                )
                              ) : null}{" "}
                              -{" "}
                              {item?.report?.site?.name === "Default Site"
                                ? `${i18n("Company.DefaultSite")}`
                                : item?.report?.site?.name}{" "}
                              - {moment(item.createdAt).format("DD MM YYYY")} -{" "}
                              {item?.report?.assignee?.fullName}"
                            </b>{" "}
                            <I18n lng="Notifications.HasBeen" />{" "}
                            <I18n lng="Notifications.HasBeenRejectedBy" />{" "}
                            <I18n lng="Notifications.By" />
                            <b> "{item?.sender?.fullName}"</b>{" "}
                            <I18n lng="Notifications.On" />{" "}
                            {moment(item.createdAt).format(
                              "DD/MM/YYYY hh:mm A"
                            )}{" "}
                          </span>
                        ) : item.event === "incomplete" ? (
                          <span>
                            <I18n lng="Notifications.YourReportTitled" />{" "}
                            <b>
                              "
                              {item?.report?.formDetail?.title ? (
                                item?.report?.formDetail?.title.includes(
                                  "Untitled Form"
                                ) ? (
                                  <>
                                    {item?.report?.formDetail?.title.replace(
                                      "Untitled Form",
                                      i18n("global.UntitledForm")
                                    )}{" "}
                                  </>
                                ) : (
                                  item?.report?.formDetail?.title
                                )
                              ) : null}{" "}
                              -{" "}
                              {item?.report?.site?.name === "Default Site"
                                ? `${i18n("Company.DefaultSite")}`
                                : item?.report?.site?.name}{" "}
                              - {moment(item.createdAt).format("DD MM YYYY")} -{" "}
                              {item?.report?.assignee?.fullName}"
                            </b>{" "}
                            <I18n lng="Notifications.hasBeenChangedToIncompleteBy" />{" "}
                            <b className="incomplete">
                              <I18n lng="Notifications.Incomplete" />{" "}
                            </b>{" "}
                            <I18n lng="Notifications.By" /> {"  "}
                            <b>{item?.sender?.fullName}</b>{" "}
                            <I18n lng="Notifications.On" />{" "}
                            <b>
                              {moment(item.createdAt).format(
                                "DD/MM/YYYY hh:mm A"
                              )}{" "}
                            </b>
                            .
                          </span>
                        ) : item.event === "roleUpdate" ? (
                          <span>
                            <I18n lng="Notifications.YourRoleHasBeen" />{" "}
                            <b>
                              "<I18n lng="Notifications.Updated" />"
                            </b>{" "}
                            <I18n lng="Notifications.By" />{" "}
                            <b>"{item?.sender?.fullName}"</b>
                          </span>
                        ) : item.event === "formUpdate" ? (
                          <span>
                            <I18n lng="Notifications.YourFormTitle" />{" "}
                            <b>"{item?.form?.formDetail?.title}"</b>{" "}
                            <I18n lng="Notifications.HasBeen" />{" "}
                            <b>
                              "<I18n lng="Notifications.Updated" />"
                            </b>{" "}
                            <I18n lng="Notifications.By" />{" "}
                            <b>"{item?.sender?.fullName}"</b>{" "}
                            <I18n lng="Notifications.On" />{" "}
                            {moment(item?.createdAt).format(
                              "DD/MM/YYYY hh:mm A"
                            )}{" "}
                          </span>
                        ) : item.event === "reSubmit" ? (
                          <span>
                            <I18n lng="Notifications.YourReportTitled" />{" "}
                            <b>
                              "
                              {item?.report?.formDetail?.title ? (
                                item?.report?.formDetail?.title.includes(
                                  "Untitled Form"
                                ) ? (
                                  <>
                                    {item?.report?.formDetail?.title.replace(
                                      "Untitled Form",
                                      i18n("global.UntitledForm")
                                    )}{" "}
                                  </>
                                ) : (
                                  item?.report?.formDetail?.title
                                )
                              ) : null}{" "}
                              -{" "}
                              {item?.report?.site?.name === "Default Site"
                                ? `${i18n("Company.DefaultSite")}`
                                : item?.report?.site?.name}{" "}
                              - {moment(item.createdAt).format("DD MM YYYY")} -{" "}
                              {item?.report?.assignee?.fullName}"
                            </b>{" "}
                            <I18n lng="Notifications.HasBeen" />{" "}
                            <b className="incomplete">
                              <I18n lng="Notifications.ReSubmitted" />
                            </b>{" "}
                            <I18n lng="Notifications.By" />{" "}
                            <b>"{item?.sender?.fullName}"</b>{" "}
                            <I18n lng="Notifications.On" />{" "}
                            {moment(item?.createdAt).format(
                              "DD/MM/YYYY hh:mm A"
                            )}{" "}
                          </span>
                        ) : (
                          <span>
                            <I18n lng="Notifications.ReportTitle" />{" "}
                            <b>
                              <b>
                                "
                                {item?.report?.formDetail?.title ? (
                                  item?.report?.formDetail?.title.includes(
                                    "Untitled Form"
                                  ) ? (
                                    <>
                                      {item?.report?.formDetail?.title.replace(
                                        "Untitled Form",
                                        i18n("global.UntitledForm")
                                      )}{" "}
                                    </>
                                  ) : (
                                    item?.report?.formDetail?.title
                                  )
                                ) : null}{" "}
                                -{" "}
                                {item?.report?.site?.name === "Default Site"
                                  ? `${i18n("Company.DefaultSite")}`
                                  : item?.report?.site?.name}{" "}
                                - {moment(item.createdAt).format("DD MM YYYY")}{" "}
                                - {item?.report?.assignee?.fullName}"
                              </b>{" "}
                            </b>{" "}
                            <I18n lng="Notifications.HasBeen" />{" "}
                            <I18n lng="Notifications.HasBeenSubmittedBy" />{" "}
                            <I18n lng="Notifications.By" />{" "}
                            <b>"{item?.sender?.fullName}"</b>{" "}
                            <I18n lng="Notifications.On" />{" "}
                            {moment(item.createdAt).format(
                              "DD/MM/YYYY hh:mm A"
                            )}{" "}
                          </span>
                        )}
                      </span>
                    </li>
                  );
                })
              : null}
          </ul>
          <div className="text-center btn-bar">
            <Button
              color="primary btn-bar"
              onClick={() => markedAsReadHandle()}
            >
              <I18n lng="Notifications.MarkAllAsRead" />
            </Button>
          </div>
        </div>
      </DynamicModal>
    </>
  );
};
const mapDispatchToProps = {
  getAllNotifications,
};
export default connect(null, mapDispatchToProps)(NotificationsDialog);
