/** @format */

import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import "../../assets/css/auth.min.css";
import { useForm } from "react-hook-form";
import { Link, Redirect } from "react-router-dom";
import IconEye from "../../assets/images/icon-eye.svg";
import { EMAIL_REGEX } from "../../config/validations";
import I18n, { i18n } from "../private/languageSelector";
import IconLock from "../../assets/images/icon-lock.svg";
import IconEmail from "../../assets/images/icon-envelope.svg";
import Input from "../../components/form-input/input.component";
import Checkbox from "../../components/form-input/checkbox.component";
import { login } from "../../redux/reducers/authentication/authentication";
import { TEXT_DIRECTION } from "../../helper/helperFunctions";
import platform from "platform";
import axios from "axios";

const Login = ({ login, isAuthenticated, location, loading }) => {
  const osInfo = platform.os;
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    localStorage.setItem("hasModalBeenShown", "");
    localStorage.setItem("hasSubscriptionPausedModalShown", "");
    localStorage.setItem("hasSubscriptionExpiredModalShown", "");
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { from } = location.state || {
    from: { pathname: "/", search: location.search },
  };

  if (isAuthenticated) {
    return <Redirect to={from} />;
  } else if (loading) {
    return (
      <>
        <I18n lng="global.loading" />
        ...
      </>
    );
  }

  const onSubmit = async (data) => {
    const response = await axios.get("https://api.ipify.org?format=json");
    const countryInfo = await axios.get(`https://ipapi.co/${response?.data?.ip}/json/`);

    await login({ ...data, ipAddress: response?.data?.ip, country: countryInfo?.data?.country_code, platform: osInfo.family })
      .then((response) => {
        const { language } = response?.value?.data?.user;
        localStorage.setItem("trialModalShown", "false");
        if (language && language !== null && language !== undefined) {
          localStorage.setItem("language", language);
        } else {
          localStorage.setItem("language", "ENGLISH");
        }
      })
      .catch((error) => {
        if (error.message === `The user can't login. Please contact Admin.`) {
          toast.error(error.message);
        }
        if (error.message === `You can't login, Please contact administrator`) {
          toast.error(error.message);
        }
        if (error?.response?.data?.message && error?.response?.data?.code !== 500) {
          toast.error(error?.response?.data?.message);
        } else if (error?.response?.data?.message && error?.response?.data?.code === 500) {
          toast.error(i18n("login.DeviceNotRegistered"));
        } else {
          toast.error(i18n("validation.AN_ERROR_OCCURED"));
        }
      });
  };

  return (
    <div className="auth-wrapper">
      <div className="auth-title">
        <h1 className="text-center">
          <I18n lng="login.LoginToTamam" />
        </h1>
        <p className="text-center">
          <I18n lng="login.EnterYourUserNameAndPassword" />
        </p>
      </div>
      <form autoComplete="false" onSubmit={handleSubmit(onSubmit)}>
        <Input
          label={<I18n lng="global.Email" />}
          prefix={IconEmail}
          id="emailInput"
          name="email"
          type="email"
          placeholder={i18n("global.Email")}
          register={register}
          validations={{
            required: {
              value: true,
              message: i18n("validation.REQUIRED_FIELD"),
            },
            pattern: {
              value: EMAIL_REGEX,
              message: i18n("validation.INVALID_EMAIL"),
            },
          }}
          error={errors["email"]}
        />
        <div className={`input-password ${!showPassword ? "hide-password" : ""}`}>
          <Input
            label={<I18n lng="global.Password" />}
            prefix={IconLock}
            suffix={IconEye}
            suffixAction={() => {
              setShowPassword(!showPassword);
            }}
            id="passwordInput"
            name="password"
            type={showPassword ? "text" : "password"}
            placeholder={i18n("global.Password")}
            register={register}
            validations={{
              required: {
                value: true,
                message: i18n("validation.REQUIRED_FIELD"),
              },
            }}
            error={errors["password"]}
          />
        </div>
        <div className={`row ${TEXT_DIRECTION() === "rtl" ? "mr-3" : "ml-1"}`}>
          <div className="col-1 checkbox-view m-0 p-0">
            <Checkbox name="rememberMe" register={register} validations={{}} error={errors["rememberMe"]} />
          </div>
          <div
            className="col-10"
            style={TEXT_DIRECTION() === "rtl" ? { marginRight: "0", paddingRight: "2px" } : { marginLeft: "0", paddingLeft: "2px" }}
          >
            <label>
              {" "}
              <I18n lng="login.RememberMe" />
            </label>
          </div>
        </div>
        <div className="btn-wrap text-center">
          <button type="submit" className="btn btn-primary">
            <I18n lng="login.SignIn" />
          </button>
        </div>
        <p className="auth-redirect justify-content-start">
          <span className="mr-1 ml-1 float-left">
            <I18n lng="login.DontHaveAnAccountYet" />
          </span>
          <strong>
            <Link to="/signup">
              <I18n lng="login.RegisterNow" />
            </Link>
          </strong>
        </p>
        <Link
          to="/password-reset-request"
          className={`d-block 
           ${TEXT_DIRECTION() === "rtl" ? "text-right mr-1" : "ml-1 text-left"}`}
        >
          <I18n lng="login.ForgotPassword" />
        </Link>
      </form>
    </div>
  );
};

const mapStateToProps = ({ authentication }) => ({
  isAuthenticated: authentication.isAuthenticated,
  loading: authentication.loading,
});

const mapDispatchToProps = { login };
export default connect(mapStateToProps, mapDispatchToProps)(Login);
