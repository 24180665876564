import React from "react";

import PhoneInput from "react-phone-number-input";
import { INVALID_PHONE } from "../../config/validations";
import { Controller } from "react-hook-form";

const PhoneNumber = ({ label, placeholder, control, register, validations, disabled, name, id, error }) => {
  return (
    <>
      <div>
        <label className="form-label">
          {label}
          {validations.required && <span className="required"></span>}
        </label>
        <div className="input-group">
          <Controller
            control={control}
            id={id}
            name={name}
            {...register(name, { ...validations })}
            render={({ field: { onChange, onBlur, value, name, ref, id } }) => (
              <PhoneInput
                className="form-control"
                international
                countryCallingCodeEditable={false}
                defaultCountry="SA"
                onBlur={onBlur}
                onChange={(phone) => {
                  onChange(phone ? phone : "");
                }}
                disabled={disabled}
                value={value}
                inputRef={ref}
                name={name}
                placeholder={placeholder}
                id={id}
              />
            )}
          />
        </div>
      </div>
      {error && <p className="error">{error.type === "validate" ? INVALID_PHONE : error.message}</p>}
    </>
  );
};

export default PhoneNumber;
