import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import I18n, { i18n } from "../../../../private/languageSelector";
import IconDelete from "../../../../../assets/images/icon-delete.svg";
import ConfirmationDialog from "../../../../../components/dialogs/confirmation-dialog";
import {
  getReportById,
  deleteReportHistory,
} from "../../../../../redux/reducers/admin-manager-reports/admin-manager-report.reducer";

const MyReportsHistory = ({
  getReportById,
  reportHistory,
  deleteReportHistory,
}) => {
  const { id } = useParams();

  const [selectedEmail, setSelectedEmail] = useState({});
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  useEffect(() => {
    setSelectedEmail({});
  }, []);

  const historyDeleteHandler = (historyId) => {
    if (historyId) {
      setOpenConfirmationDialog(true);
    }
    let result = getEmail(historyId);
    if (result) {
      setSelectedEmail({ ...result });
    }
  };

  const getEmail = (id) => {
    let result =
      reportHistory && reportHistory.length
        ? reportHistory.find((item) => item._id === id)
        : null;
    if (result) {
      return result;
    }
  };

  return (
    <>
      <ul className="list-unstyled px-4 m-0 font-weight-bold">
        {reportHistory && reportHistory.length ? (
          reportHistory.map((item, index) => {
            return (
              <li key={index} className="d-flex justify-content-between">
                {item?.email ? item.email : item.mobile}
                <Link
                  to="#"
                  className="icon-red"
                  onClick={(e) => {
                    e.preventDefault();
                    historyDeleteHandler(item._id);
                  }}
                >
                  <img src={IconDelete} alt="Delete" />
                </Link>
              </li>
            );
          })
        ) : (
          <li className="d-flex justify-content-between">
            <I18n lng="Reports.NoHistoryFound" />
          </li>
        )}
      </ul>
      <ConfirmationDialog
        showDlg={openConfirmationDialog}
        title={i18n("global.Note")}
        classes="modal-alert"
        iconCloseDlg
        content={
          <p>
            <I18n lng="Reports.IfYouProceed" />{" "}
            <b style={{ color: "#EB5757" }}>{selectedEmail.email}</b>{" "}
            <I18n lng="Reports.WillBeDisabled" />
          </p>
        }
        primaryActionTitle={i18n("global.Yes")}
        secondryActionTitle={i18n("global.No")}
        secondaryAction={() => setOpenConfirmationDialog(false)}
        primaryAction={() => {
          deleteReportHistory(id, selectedEmail._id).then((res) => {
            getReportById(id);
          });
          setOpenConfirmationDialog(false);
        }}
        primaryActionClasses={"btn-success"}
        secondryActionClasses={"btn-danger"}
      />
    </>
  );
};
const mapStateToProps = ({ adminManagerReports }) => ({
  reportForEdit: adminManagerReports.reportForEdit,
});

const mapDispatchToProps = {
  getReportById,
  deleteReportHistory,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyReportsHistory);
