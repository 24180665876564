import React from "react";
import I18n, { i18n } from "../languageSelector";
import { TEXT_DIRECTION } from "../../../helper/helperFunctions";
import IconArrowDown from "../../../assets/images/arrowDown.svg";
import { Collapse } from "reactstrap";
import moment from "moment";

const ReportInformation = ({
  reportSettings,
  timeZone,
  hideAndShow,
  currentRole,
  reportForEdit,
  reportTitleCollapsed,
  setReportTitleCollapsed,
  reportInspectionNumber,
}) => {
  return (
    <div className="report-table fail-summary">
      <div className="report-header">
        <div
          className="table mb-1"
          style={{
            border: "#FFFFFF",
            boxShadow: "1px 5px 2px rgba(0,0,0,.10)",
          }}
          timeZone
        >
          <div className="failed-summary">
            <div className="table-header">
              <div className="table-row">
                <div
                  className="table-head"
                  style={{
                    padding: "0.25rem 1.5rem",
                    borderRadius: "0",
                  }}
                >
                  <div className="my-1 d-flex justify-content-between w-100 ">
                    <b className={TEXT_DIRECTION() === "rtl" ? "ml-3" : "mr-3"}>
                      <I18n lng="Reports.Information" />
                    </b>
                    <div
                      onClick={() => {
                        reportTitleCollapsed && reportTitleCollapsed === true
                          ? setReportTitleCollapsed(false)
                          : setReportTitleCollapsed(true);
                      }}
                      className="text-right align-end "
                    >
                      <img
                        style={
                          reportTitleCollapsed === true
                            ? {
                                margin: "0 15px 5px 15px",
                                width: "25px",
                                cursor: "pointer",
                              }
                            : {
                                margin: "0 15px 5px 15px",
                                width: "25px",
                                cursor: "pointer",
                                rotate: "180deg",
                              }
                        }
                        src={IconArrowDown}
                        alt="arrow Down"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="fail-summaryBody">
            <Collapse isOpen={reportTitleCollapsed}>
              <div
                className="report-data card"
                style={{
                  padding: "1.25rem 1.5rem",
                }}
              >
                <h5>
                  {reportForEdit?.formDetail?.title.includes(
                    "Untitled Form"
                  ) ? (
                    <>
                      <>
                        {reportForEdit?.formDetail?.title.replace(
                          "Untitled Form",
                          i18n("global.UntitledForm")
                        )}{" "}
                      </>
                    </>
                  ) : reportForEdit?.formDetail?.title ? (
                    reportForEdit?.formDetail?.title?.toUpperCase()
                  ) : reportForEdit?.form?.formDetail.title.includes(
                      `Untitled Form`
                    ) ? (
                    <>
                      <I18n lng="global.UntitledForm" />{" "}
                      {reportForEdit?.formDetail?.documentNumber}
                    </>
                  ) : reportForEdit?.form?.formDetail.title ? (
                    reportForEdit?.form?.formDetail.title?.toUpperCase()
                  ) : (
                    ""
                  )}
                </h5>
                <h5>
                  {reportSettings?.title?.siteName
                    ? reportForEdit?.site?.name === "Default Site"
                      ? `${i18n("Company.DefaultSite") + " - "}`
                      : reportForEdit?.site?.name
                      ? reportForEdit?.site?.name + " - "
                      : ""
                    : ""}
                  {reportSettings?.title?.inspectorName
                    ? reportForEdit?.assignee?.fullName + " "
                    : ""}{" "}
                  {"  "}
                  {reportSettings?.title?.dateTime ? (
                    <span className="direction-ltr date">
                      {moment(reportForEdit?.startAt).format("DD MMM YYYY")}
                    </span>
                  ) : (
                    ""
                  )}
                </h5>
                <ul
                  className={`list-unstyled main-info ${
                    TEXT_DIRECTION() === "rtl" ? "pr-0" : "pl-0"
                  }`}
                >
                  {hideAndShow?.content?.dateTime ? (
                    <li>
                      <label
                        style={{
                          color: "#4F4F4F",
                          fontWeight: "500",
                        }}
                      >
                        <I18n lng="Reports.ConductedOn" />
                      </label>
                      {reportForEdit?.status !== "draft" ? (
                        timeZone ? (
                          <strong className="direction-ltr date">
                            {moment(reportForEdit?.startAt)
                              .utcOffset(timeZone[1])
                              .format("DD MMM YYYY hh:mm a")}
                          </strong>
                        ) : (
                          <strong className="direction-ltr">
                            {moment(reportForEdit?.startAt).format(
                              "DD MMM YYYY hh:mm a"
                            )}
                          </strong>
                        )
                      ) : (
                        <strong>N/A</strong>
                      )}
                    </li>
                  ) : null}
                  <li>
                    <label
                      style={{
                        color: "#4F4F4F",
                        fontWeight: "500",
                      }}
                    >
                      <I18n lng="Reports.Preparedby" />
                    </label>
                    <strong>
                      {reportForEdit?.assignee?.fullName
                        ? reportForEdit?.assignee?.fullName
                        : ""}
                    </strong>
                  </li>

                  {reportSettings?.content?.docNumber ? (
                    <li>
                      <label
                        style={{
                          color: "#4F4F4F",
                          fontWeight: "500",
                        }}
                      >
                        <I18n lng="Reports.DocumentNo" />
                      </label>
                      {TEXT_DIRECTION() === "rtl" ? (
                        <strong>
                          {`${reportInspectionNumber}  - ${
                            reportForEdit?.form
                              ? reportForEdit?.form?.formDetail?.documentNumber
                              : reportForEdit?.formDetail?.documentNumber
                          } `}
                        </strong>
                      ) : (
                        <strong>
                          {`${
                            reportForEdit?.form
                              ? reportForEdit?.form?.formDetail?.documentNumber
                              : reportForEdit?.formDetail?.documentNumber
                          } - ${reportInspectionNumber}`}
                        </strong>
                      )}
                    </li>
                  ) : (
                    ""
                  )}

                  <li>
                    <label
                      style={{
                        color: "#4F4F4F",
                        fontWeight: "500",
                      }}
                    >
                      <I18n lng="Reports.Site" />
                    </label>
                    <strong>
                      {" "}
                      {reportForEdit?.site?.name === "Default Site"
                        ? `${i18n("Company.DefaultSite")}`
                        : reportForEdit?.site?.name}
                    </strong>
                  </li>
                  <li>
                    <label
                      style={{
                        color: "#4F4F4F",
                        fontWeight: "600",
                      }}
                      className="font-color"
                    >
                      <I18n lng="Reports.Status" />
                    </label>

                    <b
                      className={`pdf-report-status ${
                        reportForEdit?.status === "reject"
                          ? "status-reject"
                          : reportForEdit?.status === "accept"
                          ? "status-accept"
                          : reportForEdit?.status === "submit"
                          ? "status-submit"
                          : reportForEdit?.status === "incomplete"
                          ? "status-warning"
                          : reportForEdit?.status === "draft"
                          ? "status-draft"
                          : reportForEdit?.status === "reSubmit"
                          ? "status-submit"
                          : "status-accept"
                      }`}
                      style={{
                        fontWeight: "600",
                        fontSize: "16px",
                      }}
                    >
                      {reportForEdit?.status === "reject" ? (
                        <I18n lng="Reports.Rejected" />
                      ) : reportForEdit?.status === "accept" ? (
                        <I18n lng="Reports.Compelete" />
                      ) : reportForEdit?.status === "submit" ? (
                        <I18n lng="Reports.Submitted" />
                      ) : reportForEdit?.status === "incomplete" ? (
                        <I18n lng="Reports.Incomplete" />
                      ) : reportForEdit?.status === "draft" ? (
                        <I18n lng="Reports.Draft" />
                      ) : reportForEdit?.status === "reSubmit" ? (
                        <I18n lng="Reports.ResubmitStatus" />
                      ) : (
                        "status-accept"
                      )}
                    </b>
                  </li>
                </ul>
              </div>
            </Collapse>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportInformation;
