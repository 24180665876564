import React, { useEffect, useState } from "react";
import { PDFViewer } from "react-view-pdf";
import I18n from "../../../languageSelector";

const InstructionReportPreview = ({
  question,
  setImageIndex,
  setShowImageSrc,
  setShowImageDialog,
  setShowSection,
  setTotalAnswered,
}) => {
  const [pdfViewer, setPdfViewer] = useState("");

  useEffect(() => {
    setShowSection(true);
    setTotalAnswered((prev) => prev + 1);
    if (!question.settings.selectedFile.image) {
      let spliting = question.settings.selectedFile.split("/");
      setPdfViewer(
        question.settings.selectedFile
          .split("/")
          [spliting.length - 1].split(".")[1]
      );
    }
    // eslint-disable-next-line
  }, [question]);

  return (
    <li className="instructions card ">
      <div className="list-item-detail ">
        <div className="notes">
          <label>
            {" "}
            <I18n lng="Form.Instructions" />
          </label>
          <p>
            {question?.settings?.questionText === "Add a new question" ? (
              <I18n lng="Form.AddANewQuestion" />
            ) : (
              question?.settings?.questionText
            )}
          </p>
          {question?.settings?.photo?.image !== undefined ? (
            <div
              className="instruction-img mt-2"
              onClick={() => {
                setImageIndex(0);
                setShowImageSrc([question?.settings?.photo?.image]);
                setShowImageDialog(true);
              }}
            >
              <img
                src={question?.settings?.photo?.image}
                alt="Instruction Icon"
              />
            </div>
          ) : (
            ""
          )}
        </div>
        {pdfViewer === "pdf" ? (
          <PDFViewer
            url={
              question.settings.selectedFile
                ? question.settings.selectedFile
                : ""
            }
          />
        ) : question?.settings?.selectedFile?.image ? (
          <div className="row justify-content-center mb-3">
            <img
              style={{ width: "200px", height: "200px" }}
              src={question?.settings?.selectedFile?.image}
              alt=""
            />
          </div>
        ) : null}
      </div>
    </li>
  );
};
export default InstructionReportPreview;
