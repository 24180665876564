import React from "react";
import { QRCode } from "react-qr-svg";
import I18n from "../languageSelector";
import PagePdfPreview from "./report-pdf-previews/pagePdfPeview";
import DetailPdfPagePreview from "./report-pdf-previews/detail-pdf-page-Preview/detailPdfPagePreview";
import { useLocation } from "react-router-dom";

const ReportInspectionQuestions = ({
  answers,
  failItems,
  getAnswer,
  showQrCode,
  hideAndShow,
  setFailItems,
  reportForEdit,
  imageCountData,
  showDocumentMedia,
}) => {
  const location = useLocation();
  return (
    <div>
      <div className="report-table questions">
        <div className="table">
          {reportForEdit &&
          hideAndShow?.content?.inspectionQuestions &&
          reportForEdit.formDetail.schema.pages.length
            ? reportForEdit.formDetail.schema.pages.map((item, index) => {
                return (
                  <PagePdfPreview
                    key={index}
                    page={item}
                    getAnswer={getAnswer}
                    setFailItems={setFailItems}
                    failItems={failItems}
                    failStatus={false}
                    viewPageSections={true}
                    instructionHide={hideAndShow?.content?.instructions}
                    hideAndShow={hideAndShow}
                    answers={answers}
                    index={index}
                    showDocumentMedia={showDocumentMedia}
                    imageCountData={imageCountData}
                  />
                );
              })
            : null}
          {hideAndShow?.content?.PdfLinksQrCode ? (
            <div
              className="qr-code"
              style={{ display: showQrCode ? "inherit" : "none" }}
            >
              <div className="mt-4 qr-code-image">
                <QRCode
                  fgColor="#000000"
                  level="Q"
                  value={`${
                    process.env.REACT_APP_URL
                  }${location.pathname.replace("/r", "r")}`}
                />
              </div>
              <label>
                <I18n lng="Reports.ScanThisCode" />
              </label>
            </div>
          ) : null}
          <DetailPdfPagePreview
            signature={reportForEdit?.form?.signature}
            creatorName={reportForEdit?.assignee?.fullName}
            disclaimer={
              hideAndShow?.content?.disclaimer
                ? hideAndShow?.content?.disclaimer
                : ""
            }
            showDisclaimer={hideAndShow?.content?.showDisclaimer}
            location={reportForEdit?.formDetail?.inspectorLocation}
            showDocumentMedia={showDocumentMedia}
          />
        </div>
      </div>
    </div>
  );
};

export default ReportInspectionQuestions;
