import React from "react";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import "../../assets/css/auth.min.css";
import { useForm } from "react-hook-form";
import I18n, { i18n } from "../private/languageSelector";
import { useParams, useHistory } from "react-router-dom";
import IconEmail from "../../assets/images/icon-envelope.svg";
import Input from "../../components/form-input/input.component";
import { setDeviceName } from "../../redux/reducers/authentication/authentication";

const RegisterDevice = ({ setDeviceName }) => {
  const { token } = useParams();
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    let getDeviceId = localStorage.getItem("deviceId");
    let deviceId =
      (
        Date.now().toString(36) + Math.random().toString(36).substr(2, 5)
      ).toUpperCase() + Math.random().toString(36).substr(2, 9);

    if (data && deviceId && getDeviceId && token) {
      setDeviceName(data.deviceName, getDeviceId, token)
        .then((res) => {
          toast.success(<I18n lng="validation.DeviceAddedSuccessfully" />);
          history.push("/login");
          localStorage.setItem("deviceId", getDeviceId);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    } else {
      setDeviceName(data.deviceName, deviceId, token)
        .then((res) => {
          toast.success(<I18n lng="validation.DeviceAddedSuccessfully" />);
          history.push("/login");
          localStorage.setItem("deviceId", deviceId);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    }
  };

  return (
    <>
      <div className="auth-wrapper">
        <div className="auth-title">
          <h1>
            <I18n lng="DeviceRegister.SetDeviceNameForTAMAM" />
          </h1>
          <p>
            <I18n lng="DeviceRegister.EnterYourDeviceName" />
          </p>
        </div>
        <form autoComplete="false" onSubmit={handleSubmit(onSubmit)}>
          <Input
            label={<I18n lng="DeviceRegister.DeviceName" />}
            prefix={IconEmail}
            id="deviceName"
            name="deviceName"
            type="text"
            placeholder={i18n("DeviceRegister.EnterDeviceName")}
            register={register}
            validations={{
              required: {
                value: true,
                message: i18n("validation.REQUIRED_FIELD"),
              },
            }}
            error={errors["deviceName"]}
          />

          <div className="btn-wrap text-center">
            <button type="submit" className="btn btn-primary">
              <I18n lng="global.Submit" />
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

const mapStateToProps = ({ authentication }) => ({
  isAuthenticated: authentication.isAuthenticated,
});

const mapDispatchToProps = { setDeviceName };
export default connect(mapStateToProps, mapDispatchToProps)(RegisterDevice);
