import React, { useState } from "react";
import IconEye from "../../../../../assets/images/icon-eye.svg";
import IconMenu from "../../../../../assets/images/icon-menu.svg";
import IconExport from "../../../../../assets/images/icon-export.svg";
import IconArchive from "../../../../../assets/images/icon-archive.svg";
import IconViewHistory from "../../../../../assets/images/icon-view-history.svg";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { connect } from "react-redux";
import {
  getReportById,
  updateReportStatus,
  getAdminManagerReports,
  getAllReportsWithOutStateChange,
} from "../../../../../redux/reducers/admin-manager-reports/admin-manager-report.reducer";
import IconBookmark from "../../../../../assets/images/icon-bookmark.png";
import IconUnBookmark from "../../../../../assets/images/icon-bookmark.svg";
import AlertModal from "../../reports-previews/alertModal/alerModal";
import {
  deleteBookmark,
  createBookmark,
} from "../../../../../redux/reducers/form/form.reducer";
import I18n, { i18n } from "../../../../private/languageSelector";
import ConfirmationDialog from "../../../../../components/dialogs/confirmation-dialog";
import { canAccessTheModule } from "../../../../../helper/helperFunctions";

const ListItemMenuOthersReports = ({
  id,
  user,
  status,
  reportItem,
  reportUUid,
  bookmarked,
  deleteBookmark,
  createBookmark,
  setLoading,
  isBookmarked,
  rolesAccess,
  currentRole,
  bookmarkId,
  reportForEdit,
  archiveReports,
  viewHistoryReport,
  viewOtherHistoryReport,
  updateReportStatus,
  openReportEditDialog,
  openOtherReportsView,
  openOtherReportsManagerView,
  reportBookmarkUnBookMarked,
  openOtherReportShareDialog,
  getAllReportsWithOutStateChange,
}) => {
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [openAlertFormReason, setOpenAlertFormReason] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const isAssignee = reportItem?.dispatchRules.assignees?.includes(user?.id);

  const isApprover = reportItem?.dispatchRules.approvedManagers?.includes(
    user?.id
  );
  const isViewVisible = isAssignee || isApprover;
  const isReviewVisible =
    (isAssignee && isApprover) || (!isAssignee && isApprover);

  return (
    <>
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle>
          <img src={IconMenu} alt="Menu" />
        </DropdownToggle>
        <DropdownMenu right>
          {currentRole === "admin" ? (
            <>
              <DropdownItem
                className="action action-view"
                onClick={() => openOtherReportsView(id)}
                style={{
                  display: isViewVisible
                    ? "block"
                    : currentRole === "user" || currentRole === "manager"
                    ? "block"
                    : "none",
                }}
              >
                <span className="icon-wrap">
                  <img src={IconEye} alt="IconEye" />
                </span>
                {isReviewVisible && currentRole !== "user"
                  ? i18n("Reports.Review")
                  : i18n("global.View")}
              </DropdownItem>
              {/* <DropdownItem
                className="action action-view"
                
              >
                <span className="icon-wrap">
                  <img src={IconEye} alt="IconEye" />
                </span>
                <I18n lng="Reports.Review" />
              </DropdownItem> */}
              <DropdownItem className="action action-view">
                {isBookmarked ? (
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      deleteBookmark(bookmarkId).then(() => {
                        setLoading(true);
                      });
                    }}
                  >
                    <span className="icon-wrap">
                      <img src={IconUnBookmark} alt="IconBookmark" />
                    </span>
                    <I18n lng="global.UnBookmark" />
                  </div>
                ) : (
                  <>
                    <div
                      onClick={(e) => {
                        let data = {
                          module: "REPORT",
                          moduleId: id,
                        };
                        e.preventDefault();
                        createBookmark(data).then(() => {
                          setLoading(true);
                        });
                      }}
                    >
                      <span className="icon-wrap">
                        <img src={IconBookmark} alt="IconBookmark" />
                      </span>
                      <I18n lng="global.Bookmark" />
                    </div>
                  </>
                )}
              </DropdownItem>

              {status === "reSubmit"
                ? canAccessTheModule(
                    rolesAccess?.report?.access?.viewHistoryOtherReport,
                    <DropdownItem
                      className="action action-view-history"
                      onClick={(e) => {
                        e.preventDefault();
                        viewOtherHistoryReport(id);
                      }}
                    >
                      <span className="icon-wrap">
                        <img src={IconViewHistory} alt="IconViewHistory" />
                      </span>
                      <I18n lng="global.ViewHistory" />
                    </DropdownItem>
                  )
                : null}
              <DropdownItem
                className="action action-export"
                onClick={(e) => {
                  e.preventDefault();
                  openOtherReportShareDialog(id, reportUUid);
                }}
              >
                <span className="icon-wrap">
                  <img src={IconExport} alt="IconExport" />
                </span>
                <I18n lng="global.Export" />
              </DropdownItem>
              <DropdownItem
                className="action action-archive"
                onClick={(e) => {
                  e.preventDefault();
                  setOpenConfirmationDialog(true);
                }}
              >
                <span className="icon-wrap">
                  <img src={IconArchive} alt="IconArchive" />
                </span>
                <I18n lng="global.Archive" />
              </DropdownItem>
            </>
          ) : (
            <>
              <DropdownItem
                className="action action-view"
                onClick={(e) => {
                  e.preventDefault();
                  openOtherReportsManagerView(id);
                }}
              >
                <span className="icon-wrap">
                  <img src={IconEye} alt="IconEye" />
                </span>
                <I18n lng="global.View" />
              </DropdownItem>
              {canAccessTheModule(
                rolesAccess?.report?.access?.exportOTherReport,
                <DropdownItem
                  className="action action-export"
                  onClick={(e) => {
                    e.preventDefault();
                    openOtherReportShareDialog(id, reportUUid);
                  }}
                >
                  <span className="icon-wrap">
                    <img src={IconExport} alt="IconExport" />
                  </span>
                  <I18n lng="global.Export" />
                </DropdownItem>
              )}
              {status === "reSubmit"
                ? canAccessTheModule(
                    rolesAccess?.report?.access?.viewHistoryOtherReport,
                    <DropdownItem
                      className="action action-view-history"
                      onClick={(e) => {
                        e.preventDefault();
                        viewOtherHistoryReport(id);
                      }}
                    >
                      <span className="icon-wrap">
                        <img src={IconViewHistory} alt="IconViewHistory" />
                      </span>
                      <I18n lng="global.ViewHistory" />
                    </DropdownItem>
                  )
                : null}
            </>
          )}
        </DropdownMenu>
      </Dropdown>
      <AlertModal
        show={openAlertFormReason}
        secondaryAction={() => {
          setOpenAlertFormReason(false);
        }}
        role={currentRole}
        primaryAction={() => {
          openReportEditDialog(id);
          setOpenAlertFormReason(false);
          // if (currentRole === "user" && reportForEdit.status !== "reject") {
          updateReportStatus(id, { status: "incomplete" }).then((res) =>
            getAllReportsWithOutStateChange(false)
          );
          // }
        }}
        reportForEdit={reportForEdit}
        setReasonForRejection={setOpenAlertFormReason}
        reason={reportForEdit?.reason ? reportForEdit?.reason : ""}
      />
      <ConfirmationDialog
        showDlg={openConfirmationDialog}
        title={i18n("global.Note")}
        classes="modal-alert"
        iconCloseDlg
        content={
          <p>
            <I18n lng="Reports.ArchiveThisReport" />
          </p>
        }
        primaryActionTitle={"Yes"}
        secondryActionTitle={"No"}
        secondaryAction={() => setOpenConfirmationDialog(false)}
        primaryAction={() => {
          archiveReports(id, "other");
          setOpenConfirmationDialog(false);
        }}
        primaryActionClasses={"btn-success"}
        secondryActionClasses={"btn-danger"}
      />
    </>
  );
};

const mapStateToProps = ({ authentication, adminManagerReports }) => ({
  currentRole: authentication.currentRole,
  user: authentication.user,
  reportForEdit: adminManagerReports.reportForEdit,
  rolesAccess: authentication.userRoleAccess,
});
const mapDispatchToProps = {
  getReportById,
  updateReportStatus,
  deleteBookmark,
  createBookmark,
  getAllReportsWithOutStateChange,
  getAdminManagerReports,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListItemMenuOthersReports);
