import axios from "axios";
import { REQUEST, SUCCESS, FAILURE } from "../action-type.util";
export const ACTION_TYPES = {
  GET_NOTIFICATIONS: "/GET_NOTIFICATIONS",
  NOTIFICTAION_MARKED_READ_SINGLE: "/NOTIFICTAION_MARKED_READ_SINGLE",
  NOTIFICATION_MARKED_AS_READ: "/NOTIFICATION_MARKED_AS_READ",
};

const initialState = {
  loading: false,
  allNotifications: [],
  notificationForEdit: undefined,
  error: undefined,
};

export const NotificationState = initialState;

// Reducer
// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.GET_NOTIFICATIONS):
    case REQUEST(ACTION_TYPES.NOTIFICTAION_MARKED_READ_SINGLE):
    case REQUEST(ACTION_TYPES.NOTIFICATION_MARKED_AS_READ):
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.GET_NOTIFICATIONS):
    case FAILURE(ACTION_TYPES.NOTIFICTAION_MARKED_READ_SINGLE):
    case FAILURE(ACTION_TYPES.NOTIFICATION_MARKED_AS_READ):
      return {
        ...state,
        error: undefined,
        loading: false,
      };
    case SUCCESS(ACTION_TYPES.GET_NOTIFICATIONS):
      return {
        ...state,
        allNotifications: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.NOTIFICTAION_MARKED_READ_SINGLE):
    case SUCCESS(ACTION_TYPES.NOTIFICATION_MARKED_AS_READ):
      return {
        ...state,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

// Actions

export const getAllNotifications = () => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.GET_NOTIFICATIONS,
    payload: axios.get(`/notifications`),
  });
};
export const notificationReadSingle = (id, data) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.NOTIFICTAION_MARKED_READ_SINGLE,
    payload: axios.patch(`/notifications/${id}`, data),
  });
};
export const notificationMarkedAsRead = () => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.NOTIFICATION_MARKED_AS_READ,
    payload: axios.get(`/notifications/markedAsRead`),
  });
};
