import React, { useEffect, useState } from "react";
import I18n from "../../../languageSelector";
import { FileIcon, defaultStyles } from "react-file-icon";
import PdfViewDialog from "../../../../../components/dialogs/pdf-view-dialog";
import { TEXT_DIRECTION } from "../../../../../helper/helperFunctions";

const InstructionPdfPreview = ({
  question,
  failStatus,
  instructionHide,
  setImageIndex,
  setShowImageSrc,
  setShowImageDialog,
  setShowSection,
  setTotalAnswered,
  showDocumentMedia,
}) => {
  let re = /(?:\.([^.]+))?$/;
  let fileExtension = re.exec(question.settings.selectedFileName)[1];

  const [pdfFileUrl, setPdfFileUrl] = useState("");
  const [showPdfViewDialog, setShowPdfViewDialog] = useState(false);

  useEffect(() => {
    setShowSection(true);
    setTotalAnswered((prev) => prev + 1);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (fileExtension === "pdf" && question?.settings?.selectedFile) {
      setPdfFileUrl(question?.settings?.selectedFile);
    }
    // eslint-disable-next-line
  }, [question, fileExtension]);

  return (
    <>
      {!failStatus && instructionHide ? (
        <div className="table-body no-count">
          <div
            className="row table-row ml-0 mr-0   question status-na"
            style={{
              boxShadow: "0px 5px 5px rgba(0,0,0,.10)",
              backgroundColor: "#ffffff",
            }}
          >
            <div
              className={` mb-3 table-column ${
                TEXT_DIRECTION() === "rtl" ? "pr-0" : "pl-0"
              }`}
            >
              <div
                className={
                  TEXT_DIRECTION() === "rtl" ? "notes mr-3" : "notes ml-3"
                }
              >
                <label style={{ color: "#4F4F4F", fontWeight: "700" }}>
                  <i>
                    <I18n lng="Form.Instructions" />:
                  </i>
                </label>
                <p style={{ color: "#4F4F4F", fontWeight: "600" }}>
                  {question?.settings?.questionText}
                </p>
              </div>
              {question?.settings?.selectedFile && fileExtension === "pdf" ? (
                <div
                  style={
                    TEXT_DIRECTION() === "rtl"
                      ? { marginRight: "32px" }
                      : { marginLeft: "32px" }
                  }
                  className={`instruction-img cursor-pointer mt-2
                  `}
                  onClick={() => setShowPdfViewDialog(true)}
                >
                  <div className="attachment-wrap">
                    <React.Fragment>
                      <span className="icon-wrap">
                        <FileIcon extension="pdf" {...defaultStyles.pdf} />
                      </span>
                      <span>{question?.settings?.selectedFileName}</span>
                    </React.Fragment>
                  </div>
                </div>
              ) : question?.settings?.selectedFile ? (
                <div
                  style={
                    TEXT_DIRECTION() === "rtl"
                      ? { marginRight: "32px" }
                      : { marginLeft: "32px" }
                  }
                  className={`instruction-img  mt-2 
                  `}
                  onClick={() => {
                    setImageIndex(0);
                    setShowImageSrc(question?.settings?.selectedFile.image);
                    setShowImageDialog(true);
                  }}
                >
                  {!showDocumentMedia ? (
                    <img
                      src={question?.settings?.selectedFile?.image}
                      width="200px"
                      alt="Thumbnail"
                    />
                  ) : (
                    <a href={question?.settings?.selectedFile}>
                      <img
                        src={question?.settings?.selectedFile}
                        width="200px"
                        alt="Thumbnail"
                      />
                    </a>
                  )}
                </div>
              ) : null}
            </div>
            <div
              onClick={(e) => {
                setImageIndex(0);
                setShowImageSrc(question?.settings?.photo?.image);
                setShowImageDialog(true);
              }}
              style={
                TEXT_DIRECTION() === "rtl"
                  ? { marginRight: "32px" }
                  : { marginLeft: "32px" }
              }
            >
              {question?.settings?.photo?.image && (
                <div className={`thumbnail-wrapper `}>
                  <span className="thumbnail">
                    <span>
                      {!showDocumentMedia ? (
                        <img
                          style={{ height: "200px", width: "200px" }}
                          src={question?.settings?.photo?.image}
                          alt="Thumbnail"
                        />
                      ) : (
                        <a href={question?.settings?.photo?.image}>
                          <img
                            style={{ height: "200px", width: "200px" }}
                            src={question?.settings?.photo?.image}
                            alt="Thumbnail"
                          />
                        </a>
                      )}
                    </span>
                  </span>
                </div>
              )}
            </div>
          </div>
          <PdfViewDialog
            answerValue={question?.settings?.selectedFileName}
            pdfUrl={pdfFileUrl}
            showDlg={showPdfViewDialog}
            secondaryAction={() => setShowPdfViewDialog(false)}
          />
        </div>
      ) : null}
    </>
  );
};
export default InstructionPdfPreview;
