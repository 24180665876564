import React from "react";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import timeZones from "timezones-list";
import { useForm } from "react-hook-form";
import "react-phone-number-input/style.css";
import countryList from "react-select-country-list";
import I18n, { i18n } from "../../../languageSelector";
import DynamicModal from "../../../../../components/dialogs";
import { isValidPhoneNumber } from "react-phone-number-input/max";
import { REQUIRED_FIELD } from "../../../../../config/validations";
import TextArea from "../../../../../components/form-input/text-Area";
import IconEmail from "../../../../../assets/images/icon-envelope.svg";
import IconCompany from "../../../../../assets/images/icon-company.svg";
import Input from "../../../../../components/form-input/input.component";
import ReactSelect from "../../../../../components/form-input/React-Select";
import PhoneNumber from "../../../../../components/form-input/phone-number.component";
import { companyUpdate } from "../../../../../redux/reducers/company/company.reducer";
import { getSession } from "../../../../../redux/reducers/authentication/authentication";

const editCompanyInitialValues = {
  companyName: "",
  email: "",
  phone: "",
  address: "",
  city: "",
  state: "",
  postalCode: "",
  country: "",
  timeZone: "",
  emailMessage: "",
  smsMessage: "",
  subject: "",
  taxNumber: "",
};

const CompanyInfoEdit = ({
  showDlg,
  secondaryAction,
  companyUpdate,
  getSession,
  user,
  company,
}) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues:
      {
        ...company,
        country: { value: company.country, label: company.country },
        timeZone: {
          value: company.timeZone,
          label: company.timeZone,
        },
      } || editCompanyInitialValues,
  });

  const options = countryList().getData();

  const onSubmit = (data) => {
    if (user && user.company.id) {
      companyUpdate({
        ...data,
        id: user.company.id,
      })
        .then(() => {
          secondaryAction();
          getSession();
          toast.success(<I18n lng="validation.CompanyUpdated" />);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    }
  };
  return (
    <>
      <DynamicModal
        customClasses="modal-drawer"
        show={showDlg}
        secondaryAction={secondaryAction}
      >
        <form
          className="comp-form sites-wrapper"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Input
            label={<I18n lng="Company.CompanyName" />}
            prefix={IconCompany}
            id="companyName"
            name="companyName"
            type="text"
            placeholder={i18n("Company.CompanyName")}
            register={register}
            validations={{
              required: { value: true, message: REQUIRED_FIELD },
            }}
            error={errors["companyName"]}
          />
          <Input
            label={<I18n lng="global.EnterEmail" />}
            prefix={IconEmail}
            id="emailInput"
            name="email"
            type="email"
            placeholder={i18n("global.Email")}
            register={register}
            validations={{
              required: { value: true, message: REQUIRED_FIELD },
            }}
            error={errors["email"]}
          />
          <PhoneNumber
            label={<I18n lng="global.Phone" />}
            name={"phone"}
            id={"mobileInput"}
            register={register}
            control={control}
            validations={{
              required: {
                value: true,
                message: i18n("validation.REQUIRED_FIELD"),
              },
              validate: isValidPhoneNumber,
            }}
            error={errors["phone"]}
          />
          <Input
            label={<I18n lng="Company.TaxNumber" />}
            id="companyTax"
            name="taxNumber"
            type="text"
            placeholder={i18n("Company.TaxNumber")}
            register={register}
            validations={{}}
          />
          <Input
            label={<I18n lng="global.Address" />}
            id="companyAddress"
            name="address"
            type="text"
            placeholder={i18n("global.Address")}
            register={register}
            validations={{}}
          />
          <div className="row">
            <div className="col-lg-6">
              <Input
                label={<I18n lng="global.City" />}
                id="city"
                name="city"
                type="text"
                placeholder={i18n("global.City")}
                register={register}
                validations={{}}
              />
            </div>
            <div className="col-lg-6">
              <Input
                label={<I18n lng="global.State" />}
                id="stateProvince"
                name="state"
                type="text"
                placeholder={i18n("global.State")}
                register={register}
                validations={{}}
              />
            </div>
            <div className="col-lg-6">
              <Input
                label={<I18n lng="global.PostalCode" />}
                id="postalCode"
                name="postalCode"
                type="text"
                placeholder={i18n("global.PostalCode")}
                register={register}
                validations={{}}
              />
            </div>
            <div className="col-lg-6 ">
              <ReactSelect
                label={<I18n lng="global.Country" />}
                placeholder={<I18n lng="global.Select" />}
                name="country"
                register={register}
                validations={{}}
                control={control}
                options={options?.length ? options : ["Please enter country"]}
              />
            </div>
            <div className="col-lg-12">
              <ReactSelect
                label={<I18n lng="global.TimeZone" />}
                placeholder={<I18n lng="global.Select" />}
                name="timeZone"
                register={register}
                control={control}
                validations={{
                  required: { value: true, message: REQUIRED_FIELD },
                }}
                options={
                  timeZones.length
                    ? timeZones.map((item) => item)
                    : ["Riyadh (GMT+03)"]
                }
              />
            </div>
            <div className="col-12">
              <hr></hr>
              <div className="form-group">
                <TextArea
                  label={<I18n lng="Company.Subject" />}
                  className="form-control"
                  rows="6"
                  name="subject"
                  placeholder={i18n("Company.EnterSubject")}
                  register={register}
                  validations={{}}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <TextArea
                  label={
                    <I18n lng="Company.DefaultReportSharingEmailMessage" />
                  }
                  className="form-control textarea"
                  rows="6"
                  name="emailMessage"
                  register={register}
                  validations={{}}
                />
              </div>
            </div>
            <div className="col-12">
              <hr />
              <div className="form-group">
                <TextArea
                  label={<I18n lng="Company.DefaultReportSharingSMSMessage" />}
                  className="form-control textarea"
                  rows="6"
                  name="smsMessage"
                  register={register}
                  validations={{}}
                />
              </div>
            </div>
          </div>
          <div className="btn-bar">
            <button className="btn btn-primary" type="submit">
              <I18n lng="global.Save" />
            </button>
            <button
              className="btn btn-white"
              onClick={() => {
                secondaryAction();
              }}
            >
              <I18n lng="global.Cancel" />
            </button>
          </div>
        </form>
      </DynamicModal>
    </>
  );
};

const mapStateToProps = ({ authentication, company }) => ({
  user: authentication.user,
  company: company.company,
});

const mapDispatchToProps = {
  companyUpdate,
  getSession,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyInfoEdit);
