import React from "react";
import Select from "react-select";
import { Controller } from "react-hook-form";

const ReactSelect = ({
  label,
  name,
  prefix,
  suffix,
  register,
  validations,
  isDisabled,
  isReadOnly,
  isMulti,
  error,
  options,
  control,
  placeholder,
}) => {
  return (
    <>
      <label className="form-label" htmlFor={name}>
        {label} {validations?.required && <span className="required"></span>}
      </label>
      <div className="input-group input-select">
        {prefix && (
          <div className="input-group-prepend">
            <img src={prefix} alt="fieldIcon" />
          </div>
        )}
        <Controller
          control={control}
          id={name}
          name={name}
          {...(register ? register(name, { ...validations }) : {})}
          render={({ field: { onChange, onBlur, value, name, ref, id } }) => (
            <Select
              className="react-select"
              options={options}
              name={name}
              onChange={onChange}
              value={value}
              onBlur={onBlur}
              inputRef={ref}
              isDisabled={isDisabled}
              isReadOnly={isReadOnly}
              id={id}
              isMulti={isMulti ? true : false}
              placeholder={placeholder} // Use the provided placeholder
            />
          )}
        />
        {suffix && (
          <div className="input-group-append">
            <img src={suffix} alt="fieldIcon" />
          </div>
        )}
      </div>
      {error && <p className="error">{error.message}</p>}
    </>
  );
};
export default ReactSelect;
