import React, { useState, useEffect } from "react";
import { BiImage } from "react-icons/bi";
import { useForm } from "react-hook-form";
import "react-phone-number-input/style.css";
import countryList from "react-select-country-list";
import DynamicModal from "../../../../../components/dialogs";
import Input from "../../../../../components/form-input/input.component";
import IconEmail from "../.././../../../assets/images/icon-envelope.svg";
import ReactSelect from "../../../../../components/form-input/React-Select";
import IconEditAvatar from "../../../../../assets/images/icon-edit-avatar.svg";
import IconLocation from "../../../../../assets/images/icon-location-gray.svg";
import PhoneNumber from "../../../../../components/form-input/phone-number.component";
import ToggleSwitch from "../../../../../components/form-input/input.switch.component";
import {
  createSite,
  getAllSites,
  getSiteById,
  updateSiteById,
} from "../../../../../redux/reducers/sites/sites.reducer";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import timeZones from "timezones-list";
import { useParams } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import I18n, { i18n } from "../../../languageSelector";
import { EMAIL_REGEX } from "../../../../../config/validations";
import { getFileData } from "../../../../../helper/image-helper";
import { REQUIRED_FIELD } from "../../../../../config/validations";
import { uploadImage } from "../../../../../redux/reducers/form/form.reducer";

const initSitesState = {
  name: "",
  email: "",
  mobile: "",
  address: "",
  city: "",
  country: "",
  state: "",
  postalCode: "",
  timeZone: "",
  image: "",
  active: true,
};
const CompanySitesAdd = ({
  showDlg,
  secondaryAction,
  createSite,
  getSiteById,
  siteByID,
  view,
  updateSiteById,
  uploadImage,
  company,
  getAllSites,
}) => {
  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: { initSitesState },
  });

  const { id } = useParams();
  const options = countryList().getData();
  const hiddenFileInput = React.useRef(null);

  const [image, setImage] = useState();

  const onSubmit = (data) => {
    if (data && !data.id) {
      createSite({ ...data, image: image })
        .then((res) => {
          toast.success(<I18n lng="validation.SiteCreated" />);
          getAllSites();
          secondaryAction();
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    } else {
      updateSiteById({
        ...data,
        name: data.name === "الموقع الرئيسي" ? "Default Site" : data.name,
        image: image,
      })
        .then((res) => {
          toast.success(<I18n lng="validation.SiteUpdated" />);
          getAllSites();
          secondaryAction();
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    }
  };

  useEffect(() => {
    if (id) {
      getSiteById(id);
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (siteByID && showDlg && id) {
      reset({
        ...siteByID,
        name:
          siteByID.name === "Default Site"
            ? `${i18n("Company.DefaultSite")}`
            : siteByID.name,
        country: { value: siteByID.country, label: siteByID.country },
        timeZone: { value: siteByID.timeZone, label: siteByID.timeZone },
      });
      setImage(siteByID.image);
    } else {
      reset({
        ...initSitesState,
        timeZone: { value: company.timeZone, label: company.timeZone },
      });
      setImage("");
    }
    // eslint-disable-next-line
  }, [siteByID, showDlg, id]);

  const imageHandleChange = async (event) => {
    const fileData = await getFileData(event);
    let existingFileUrl = siteByID.image
      ? siteByID.image
      : "https://tamam-bucket.s3.me-south-1.amazonaws.com/ee6023bcb103ac283cbe79ec86d16323.png";
    uploadImage({
      base64: fileData.fileUrl,
      existingFileUrl,
      fileType: fileData.fileType,
    }).then((response) => {
      setImage(response.value.data);
      if (id) {
        updateSiteById({ ...siteByID, image: response.value.data })
          .then((res) => {
            toast.success(<I18n lng="validation.SiteUpdated" />);
            getAllSites();
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message);
          });
      }
    });
  };

  const imageDeleteHandler = () => {
    if (siteByID && showDlg && id) {
      updateSiteById({ ...siteByID, image: "", timeZone: siteByID.timeZone })
        .then(() => {})
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    } else {
      setImage("");
    }
  };

  return (
    <>
      <DynamicModal
        customClasses="modal-drawer"
        show={showDlg}
        secondaryAction={secondaryAction}
      >
        <form
          className="comp-sites-form sites-wrapper"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="d-block w-100 mb-5">
            <div className="sites-avatar mb-3">
              <div className="img-wrap">
                {image ? (
                  <div className="avatar-wrap">
                    <img src={image} alt="loading"></img>
                  </div>
                ) : (
                  <div className="avatar-wrap">
                    <BiImage size={"4rem"} />
                  </div>
                )}

                {image && !view ? (
                  <div className="action-remove">
                    <AiOutlineClose
                      className="icon icon-img-remove"
                      onClick={imageDeleteHandler}
                    />
                  </div>
                ) : null}
                {!view && (
                  <button type="button" className="action-edit">
                    <img src={IconEditAvatar} alt="loading"></img>
                    <input
                      type="file"
                      ref={hiddenFileInput}
                      onChange={imageHandleChange}
                    />
                  </button>
                )}
              </div>
            </div>
          </div>
          <ToggleSwitch
            name="active"
            id="activeLocation"
            checked={getValues("active")}
            onClick={(e) =>
              setValue("active", e.target.checked, {
                shouldValidate: true,
              })
            }
            label={<I18n lng="Company.ActiveThisLocation" />}
            {...(view ? { readOnly: true, disabled: true } : {})}
          />
          <Input
            label={<I18n lng="Company.SiteName" />}
            prefix={IconLocation}
            iconSize
            id="siteName"
            name="name"
            type="text"
            placeholder={i18n("Company.EnterSiteName")}
            register={register}
            validations={{
              required: {
                value: true,
                message: i18n("validation.REQUIRED_FIELD"),
              },
            }}
            error={errors["name"]}
            {...(view ? { readOnly: true, disabled: true } : {})}
          />
          <Input
            label={<I18n lng="Company.SiteEmail" />}
            prefix={IconEmail}
            id="emailInput"
            name="email"
            type="email"
            placeholder={i18n("Company.EnterSiteEmail")}
            register={register}
            validations={{
              pattern: {
                value: EMAIL_REGEX,
                message: i18n("validation.INVALID_EMAIL"),
              },
            }}
            error={errors["email"]}
            {...(view ? { readOnly: true, disabled: true } : {})}
          />
          <PhoneNumber
            label={<I18n lng="Company.SitePhone" />}
            name="mobile"
            id={"mobileInput"}
            register={register}
            placeholder={i18n("global.EnterPhoneNo")}
            control={control}
            validations={{}}
            error={errors["mobile"]}
            {...(view ? { readOnly: true, disabled: true } : {})}
          />
          <Input
            label={<I18n lng="global.Address" />}
            id="siteAddress"
            name="address"
            type="text"
            placeholder={i18n("global.Address")}
            register={register}
            validations={{}}
            error={errors["address"]}
            {...(view ? { readOnly: true, disabled: true } : {})}
          />
          <div className="row">
            <div className="col-lg-6">
              <Input
                label={<I18n lng="global.City" />}
                id="city"
                name="city"
                type="text"
                placeholder={i18n("global.City")}
                register={register}
                validations={{}}
                {...(view ? { readOnly: true, disabled: true } : {})}
              />
            </div>
            <div className="col-lg-6">
              <Input
                label={<I18n lng="global.State" />}
                id="stateProvince"
                name="state"
                type="text"
                placeholder={i18n("global.State")}
                register={register}
                validations={{}}
                {...(view ? { readOnly: true, disabled: true } : {})}
              />
            </div>
            <div className="col-lg-6">
              <Input
                label={<I18n lng="global.PostalCode" />}
                id="postalCode"
                name="postalCode"
                type="text"
                placeholder={i18n("global.PostalCode")}
                register={register}
                validations={{}}
                {...(view ? { readOnly: true, disabled: true } : {})}
              />
            </div>
            <div className="col-lg-6">
              <ReactSelect
                label={<I18n lng="global.Country" />}
                placeholder={<I18n lng="global.Select" />}
                name="country"
                register={register}
                control={control}
                validations={{}}
                options={options?.length ? options : ["Please enter country"]}
                onChange={(option) => {
                  setValue("country", option.label);
                }}
                isReadOnly={view}
                isDisabled={view}
              />
            </div>
            <div className="col-lg-12">
              <ReactSelect
                label={<I18n lng="global.TimeZone" />}
                placeholder={<I18n lng="global.Select" />}
                name="timeZone"
                register={register}
                control={control}
                validations={{
                  required: { value: true, message: REQUIRED_FIELD },
                }}
                options={
                  timeZones.length
                    ? timeZones.map((item) => item)
                    : ["Riyadh (GMT+03)"]
                }
                error={errors["timeZone"]}
                isReadOnly={view}
                isDisabled={view}
              />
            </div>
          </div>
          <div className="btn-bar">
            {!view ? (
              <button className="btn btn-primary" type="submit">
                {<I18n lng="global.Save" />}
              </button>
            ) : (
              ""
            )}

            <button
              className="btn btn-white"
              onClick={() => {
                secondaryAction();
              }}
            >
              {view ? (
                <I18n lng="global.Close" />
              ) : (
                <I18n lng="global.Cancel" />
              )}
            </button>
          </div>
        </form>
      </DynamicModal>
    </>
  );
};

const mapStateToProps = ({ authentication, company, sites }) => ({
  user: authentication.user,
  company: company.company,
  siteByID: sites.siteByID,
});

const mapDispatchToProps = {
  createSite,
  getSiteById,
  updateSiteById,
  getAllSites,
  uploadImage,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanySitesAdd);
