/**
 * eslint-disable jsx-a11y/img-redundant-alt
 *
 * @format
 */

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";
import timeZones from "timezones-list";
import appIcon from "../../../../../assets/images/appSVG.svg";
import IconDelete from "../../../../../assets/images/icon-delete-red.svg";
import IconUser from "../../../../../assets/images/icon-user.svg";
import emailIcon from "../../../../../assets/images/mailSVG.svg";
import smsIcon from "../../../../../assets/images/smsSVG.svg";
import DynamicModal from "../../../../../components/dialogs";
import ReactSelect from "../../../../../components/form-input/React-Select";
import CheckBox from "../../../../../components/form-input/checkbox.component";
import Input from "../../../../../components/form-input/input.component";
import ToggleSwitch from "../../../../../components/form-input/input.switch.component";
import PhoneNumber from "../../../../../components/form-input/phone-number.component";
import { REQUIRED_FIELD } from "../../../../../config/validations";
import { getAllRoles } from "../../../../../redux/reducers/roles/roles.reducer";
import {
  assignUserResponsibility,
  createUser,
  getAllUsers,
  getUserById,
  getUserDataById,
  updateUserById,
  userDispatchRule,
} from "../../../../../redux/reducers/user/user.reducer";
import I18n, { i18n } from "../../../languageSelector";
import IconEmail from "../.././../../../assets/images/icon-envelope.svg";
import alertIcon from "../../../../../assets/images/alertIconUpdatePlan.svg";
import PlanDialog from "../../../../../components/dialogs/PlanDialog";
import SeatedTypeChangeDialog from "../../../../../components/dialogs/SeatedTypeChangeDialog";
import { TEXT_DIRECTION } from "../../../../../helper/helperFunctions";
import { getSession } from "../../../../../redux/reducers/authentication/authentication";
import { getSubscriptionByCompanyId, updateSubscriptionData } from "../../../../../redux/reducers/subscription/subscription.reducer";
import SubscriptionsDetailDialog from "../subscriptions/subscriptions-details";
import ChargeBillingDetails from "../subscriptions/charge-billing-details";

const initUserState = {
  active: true,
  firstName: "",
  lastName: "",
  email: "",
  mobile: "",
  password: "",
  timeZone: "",
  passwordLink: false,
  isUserSeated: false,
  roleName: "",
  seatedType: false,
  inviteLink: false,
  managerPortal: false,
  inspectorPortal: false,
  assignForm: { app: true, email: false, sms: false },
  reportSubmission: { app: true, email: false, sms: false },
  approvalReport: { app: true, email: false, sms: false },
  rejectReport: { app: true, email: false, sms: false },
};

const CompanyUsersAdd = ({
  showDlg,
  secondaryAction,
  createUser,
  getAllUsers,
  getUserById,
  userForEdit,
  updateUserById,
  hideInviteLinks,
  getSession,
  view,
  editUser,
  user,
  getAllRoles,
  rolesList,
  assignUserResponsibility,
  getUserDataById,
  getSubscriptionByCompanyId,
  subscriptionData,
  allUsers,
  updateSubscriptionData,
  userDispatchRule,
}) => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues: { ...initUserState },
  });

  const { id } = useParams();

  const UserFreePlan = subscriptionData?.currentPlan?.plan === "Free" && subscriptionData?.userTrial?.isTrialCancelled === false;

  const cancelTrialCondition = subscriptionData?.currentPlan?.plan === "Free" && subscriptionData?.userTrial?.isTrialCancelled === true;

  const [userEdit, setUserEdit] = useState({});
  const [isChanges, setIsChanges] = useState(false);
  // const [newUserId, setNewUserId] = useState("");
  const [usersToShow, setUsersToShow] = useState([]);
  const [userDataToUpdate, setUserDataToUpdate] = useState({});
  const [userRoleChanged, setUserRoleChanged] = useState(false);
  const [accessRightsValues, setAccessRightsValues] = useState([]);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [seatedType, setSeatedType] = useState();
  const [usedTotalAlertModal, setUsedTotalAlertModal] = useState(false);
  const [roleName, setRoleName] = useState("");
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
  const [showCreateNewUserModal, setShowCreateNewUserModal] = useState(false);
  const [isSeatedTypeClicked, setIsSeatedTypeClicked] = useState(null);
  const [isSeatedTypeChanged, setIsSeatedTypeChanged] = useState(false);
  const [showStartFreeTrialModal, setShowTrialFreeTrialModal] = useState(false);
  const [showSeatAddWarning, setShowShowAddWarning] = useState(false);
  const [showDetailsDialog, setShowDetailsDialog] = useState(false);
  const [isUserAssignSomething, setIsUserAssignSomething] = useState(false);
  const [currentSeatType, setCurrentSeatType] = useState();
  const [canceTrialAndChangeSeatedTypeModal, setCanceTrialAndChangeSeatedTypeModal] = useState(false);
  const [isRoleSelected, setIsRoleSelected] = useState();
  const [canceTrialAndChangeRoleModal, setCanceTrialAndChangeRoleModal] = useState(false);

  const [showSubscribeModalAfterTenUser, setShowSubsribeModalAfterTenUser] = useState(false);

  const [subscriptionDataGet, setSubscriptionDataGet] = useState(null);
  useState(false);

  const [availableSeats, setAvailableSeats] = useState(0);
  const [userPlan, setUserPlan] = useState("");
  const [showPausedSubscriptionsModal, setShowPausedSubscriptionsModal] = useState(false);

  const [chargeBillingDetailsDailog, setChargeBillingDetailsDailog] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(subscriptionData?.currentPlan?.plan);

  const [userDataToPassChargeBillingDetailsIfAddingNewUser, setUserDataToPassChargeBillingDetailsIfAddingNewUser] = useState();

  const [userDataToPassChargeBillingDetailsIfEditUser, setUserDataToPassChargeBillingDetailsIfEditUser] = useState();

  const [showSubscriptionExpiredModal, setShowSubscriptionExpiredModal] = useState(false);

  const filterEmails = allUsers?.filter((item) => item?.email)?.map((item) => item?.email);

  const filterPhons = allUsers?.filter((item) => item?.mobile)?.map((item) => item?.mobile);

  useEffect(() => {
    const fetchSubscriptionData = () => {
      getSubscriptionByCompanyId(user?.company?.id)
        .then((subscriptionResponse) => {
          setSubscriptionDataGet(subscriptionResponse?.value?.data);
          setAvailableSeats(subscriptionResponse?.value?.data?.seats?.available);
          setUserPlan(subscriptionResponse?.value?.data?.currentPlan?.plan);
        })
        .catch((error) => {
          console.error("Error fetching subscription data:", error);
        });
    };

    fetchSubscriptionData();
  }, [user?.company?.id, getSubscriptionByCompanyId]);

  useEffect(() => {
    if (userPlan === "Free" && availableSeats === 0) {
      setShowSubsribeModalAfterTenUser(true);
    }
  }, [userPlan, availableSeats]);

  const dataToPassEverySubscription = (data, text) => {
    if (text === "createUserSeatUpdate") {
      return {
        seats: {
          used: data?.seats?.used + 1,
          available: data?.seats?.available - 1,
          total: data?.seats?.total,
        },
      };
    } else if (text === "extraSeatAddData") {
      return {
        currentPlan: {
          plan: data?.currentPlan?.plan,
          status: data?.currentPlan?.status,
          price: data?.currentPlan?.price,
          seats: data?.currentPlan?.seats + 1,
        },
        seats: {
          used: data?.seats?.used + 1,
          available: 0,
          total: data?.seats?.total + 1,
        },
      };
    } else if (text === "startTrialData") {
      return {
        currentPlan: {
          plan: "Trial",
          status: "Running",
          seats: subscriptionData?.seats?.used,
          price: 0,
        },
        seats: {
          used: subscriptionData?.seats?.used,
          available: 0,
          total: subscriptionData?.seats?.used,
        },
        userTrial: {
          isTrialStarted: true,
          isTrialCancelled: false,
          isTrialExpired: false,
        },
      };
    } else if (text === "userNonSeatWhenUpdate") {
      return {
        seats: {
          used: subscriptionData?.seats?.used - 1,
          available: subscriptionData?.seats?.available + 1,
          total: subscriptionData?.seats?.total,
        },
      };
    }
  };

  const handleCloseIsSeatedTypeChanged = () => {
    setIsSeatedTypeChanged(false);
    setSeatedType(true);
    const userRoleData = userForEdit?.roleSetting;
    setAccessRightsValues({
      value: userRoleData?.id,
      label:
        userRoleData?.name === "owner"
          ? i18n("Roles.owner")
          : userRoleData?.name === "manager"
          ? i18n("Roles.manager")
          : userRoleData?.name === "inspector"
          ? i18n("Roles.inspector")
          : userRoleData?.name === "admin"
          ? i18n("Roles.admin")
          : userRoleData?.name,
      role: userRoleData,
    });
    if (showConfirmationDialog) {
      setShowConfirmationDialog(false);
    }
  };

  useEffect(() => {
    if (showCreateNewUserModal) {
      setShowShowAddWarning(true);
    }
  }, [showCreateNewUserModal]);

  const closeAlertModalHandler = () => {
    setUsedTotalAlertModal(false);
  };

  useEffect(() => {
    if (userForEdit) {
      setSeatedType(userForEdit?.isUserSeated);
    }
  }, [userForEdit]);

  useEffect(() => {
    if (cancelTrialCondition) {
      setSeatedType(true);
      let adminData = rolesList?.find((item) => item.originalName === "admin");
      setAccessRightsValues({
        value: adminData?.id,
        label:
          adminData?.name === "owner"
            ? i18n("Roles.owner")
            : adminData?.name === "manager"
            ? i18n("Roles.manager")
            : adminData?.name === "inspector"
            ? i18n("Roles.inspector")
            : adminData?.name === "admin"
            ? i18n("Roles.admin")
            : adminData?.name === "guest"
            ? i18n("Roles.guest")
            : adminData?.name,
        role: adminData,
      });
    }
  }, [cancelTrialCondition, rolesList]);

  useEffect(() => {
    if (UserFreePlan) {
      setSeatedType(true);
      let adminData = rolesList?.find((item) => item.originalName === "admin");
      setAccessRightsValues({
        value: adminData?.id,
        label:
          adminData?.name === "owner"
            ? i18n("Roles.owner")
            : adminData?.name === "manager"
            ? i18n("Roles.manager")
            : adminData?.name === "inspector"
            ? i18n("Roles.inspector")
            : adminData?.name === "admin"
            ? i18n("Roles.admin")
            : adminData?.name,
        role: adminData,
      });
    }
  }, [UserFreePlan, rolesList]);

  useEffect(() => {
    if (editUser) {
      setSeatedType(true);
    } else {
      setSeatedType(undefined);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!id && subscriptionData?.seats?.available > 0) {
      setSeatedType(true);
      let adminData = rolesList?.find((item) => item.originalName === "admin");
      setAccessRightsValues({
        value: adminData?.id,
        label:
          adminData?.name === "owner"
            ? i18n("Roles.owner")
            : adminData?.name === "manager"
            ? i18n("Roles.manager")
            : adminData?.name === "inspector"
            ? i18n("Roles.inspector")
            : adminData?.name === "admin"
            ? i18n("Roles.admin")
            : adminData?.name,
        role: adminData,
      });
    }
    if (!id && subscriptionData?.seats?.available === 0) {
      setSeatedType(false);
      let guestData = rolesList?.find((item) => item.originalName === "guest");
      setAccessRightsValues({
        value: guestData?.id,
        label:
          guestData?.name === "owner"
            ? i18n("Roles.owner")
            : guestData?.name === "manager"
            ? i18n("Roles.manager")
            : guestData?.name === "inspector"
            ? i18n("Roles.inspector")
            : guestData?.name === "guest"
            ? i18n("Roles.guest")
            : guestData?.name,
        role: guestData,
      });
    }
  }, [subscriptionData?.seats?.available, rolesList, id]);

  const handleSeatedTypeChange = async (option) => {
    setCurrentSeatType(option.value);
    if (id) {
      userDispatchRule(userForEdit.id).then((res) => {
        setIsUserAssignSomething(res.value.data);
      });
    }
    if (UserFreePlan && !option.value) {
      setUsedTotalAlertModal(true);
    } else if (cancelTrialCondition && !option.value) {
      setCanceTrialAndChangeSeatedTypeModal(true);
    } else {
      setSeatedType(option.value);
      setShowShowAddWarning(option.value);
      setRoleName(option.value ? accessRightsValues?.role?.originalName : "");
      if (option.value) {
        let adminData = rolesList?.find((item) => item.originalName === "admin");
        setAccessRightsValues({
          value: adminData?.id,
          label:
            adminData?.name === "owner"
              ? i18n("Roles.owner")
              : adminData?.name === "manager"
              ? i18n("Roles.manager")
              : adminData?.name === "inspector"
              ? i18n("Roles.inspector")
              : adminData?.name === "admin"
              ? i18n("Roles.admin")
              : adminData?.name,
          role: adminData,
        });
      } else {
        let guestData = rolesList?.find((item) => item.originalName === "guest");
        setAccessRightsValues({
          value: guestData?.id,
          label:
            guestData?.name === "owner"
              ? i18n("Roles.owner")
              : guestData?.name === "manager"
              ? i18n("Roles.manager")
              : guestData?.name === "inspector"
              ? i18n("Roles.inspector")
              : guestData?.name === "guest"
              ? i18n("Roles.guest")
              : guestData?.name,
          role: guestData,
        });
      }
      handleChange();
    }
  };

  const handleRoleChange = (option) => {
    setIsRoleSelected(option);
    if (UserFreePlan) {
      let adminData = rolesList?.find((item) => item.originalName === "admin");
      setAccessRightsValues({
        value: adminData?.id,
        label: i18n("Roles.admin"),
        role: adminData,
      });
      if (option.label !== "Admin") {
        setUsedTotalAlertModal(true);
      }
    } else if (cancelTrialCondition) {
      let adminData = rolesList?.find((item) => item.originalName === "admin");
      setAccessRightsValues({
        value: adminData?.id,
        label: i18n("Roles.admin"),
        role: adminData,
      });
      if (option.label !== "Admin") {
        setCanceTrialAndChangeRoleModal(true);
      }
    } else {
      setAccessRightsValues(option);
    }
  };

  useEffect(() => {
    if (id) {
      getUserById(id);
    }
    getAllRoles(10);
    // eslint-disable-next-line
  }, [id]);

  const updateUserHandler = async (data) => {
    const subscriptionResponse = await getSubscriptionByCompanyId(user?.company?.id);
    const latestSubscriptionData = subscriptionResponse?.value?.data;
    if (userForEdit?.isUserSeated === false && seatedType === true) {
      if (subscriptionData?.seats?.used === subscriptionData?.seats?.total) {
        updateSubscriptionData(subscriptionData?.id, dataToPassEverySubscription(latestSubscriptionData, "extraSeatAddData", "Update")).then(() => {
          getSubscriptionByCompanyId(user?.company?.id);
        });
      } else {
        updateSubscriptionData(subscriptionData?.id, dataToPassEverySubscription(latestSubscriptionData, "createUserSeatUpdate", "Update")).then(
          () => {
            getSubscriptionByCompanyId(user?.company?.id);
          }
        );
      }
    } else if (userForEdit?.isUserSeated === true && seatedType === false) {
      updateSubscriptionData(subscriptionData?.id, dataToPassEverySubscription(latestSubscriptionData, "userNonSeatWhenUpdate", "Update")).then(
        () => {
          getSubscriptionByCompanyId(user?.company?.id);
        }
      );
    }
    updateUserById({
      ...data,
      roleSetting: accessRightsValues,
      managerPortal: false,
      inspectorPortal: false,
      userInfoChange: userForEdit.active !== data.active,
      isUserSeated: seatedType,
      roleName: accessRightsValues?.role?.originalName,
    }).then(() => {
      if (subscriptionData?.seats?.available === 0 && !UserFreePlan && showSeatAddWarning && seatedType === false) {
        updateSubscriptionData(subscriptionData?.id, dataToPassEverySubscription(latestSubscriptionData, "extraSeatAddData", "Update")).then(
          (res) => {
            if (res) {
              getSubscriptionByCompanyId(user?.company?.id);
            }
          }
        );
      } else {
        getSubscriptionByCompanyId(user?.company?.id);
      }
      toast.success(<I18n lng="validation.Updated" />);
      setSeatedType();
      getAllUsers();
      secondaryAction();

      if (userForEdit.role === "admin") {
        getSession();
      }
    });
  };

  const onSubmit = async (data) => {
    setUserDataToPassChargeBillingDetailsIfEditUser(data);
    const userData = {
      ...data,
      seatedType: seatedType,
      isUserSeated: seatedType,
      roleName: roleName,
    };
    setUserDataToPassChargeBillingDetailsIfAddingNewUser(userData);
    const subscriptionResponse = await getSubscriptionByCompanyId(user?.company?.id);
    const latestSubscriptionData = subscriptionResponse?.value?.data;
    // This condition is for that if the available seats is zero & Subscription is Unsubscribe but still subscription ended have time & adding new seated user OR edit seated user
    if (
      !id &&
      seatedType &&
      latestSubscriptionData.currentPlan.status === "Unsubscribed, subscription is running" &&
      latestSubscriptionData.subscriptionInfo.isSubscribed &&
      latestSubscriptionData?.seats?.available === 0
    ) {
      if (filterEmails.includes(data?.email)) {
        return toast.error("Email Already Exists");
      } else if (filterPhons.includes(data?.mobile)) {
        return toast.error("Phone Already Exists");
      } else {
        return setChargeBillingDetailsDailog(true);
      }
    }
    if (
      id &&
      !userForEdit?.isUserSeated &&
      currentSeatType &&
      latestSubscriptionData.currentPlan.status === "Unsubscribed, subscription is running" &&
      latestSubscriptionData.subscriptionInfo.isSubscribed &&
      latestSubscriptionData?.seats?.available === 0
    ) {
      return setChargeBillingDetailsDailog(true);
    }
    // This condition is for that if the available seats is zero & Subscription is Paused & adding new user
    if (
      seatedType &&
      latestSubscriptionData.currentPlan.status === "Paused" &&
      latestSubscriptionData.subscriptionInfo.isSubscribed &&
      latestSubscriptionData?.seats?.available === 0
    ) {
      return setShowPausedSubscriptionsModal(true);
    }
    // This condition is for that if the available seats is zero & Subscription is Running & adding new seated user OR edit seated user
    if (
      !id &&
      seatedType &&
      latestSubscriptionData.currentPlan.status === "Running" &&
      latestSubscriptionData.subscriptionInfo.isSubscribed &&
      latestSubscriptionData?.seats?.available === 0
    ) {
      if (filterEmails.includes(data?.email)) {
        return toast.error("Email Already Exists");
      } else if (filterPhons.includes(data?.mobile)) {
        return toast.error("Phone Already Exists");
      } else {
        return setChargeBillingDetailsDailog(true);
      }
    }
    if (
      id &&
      !userForEdit?.isUserSeated &&
      currentSeatType &&
      latestSubscriptionData.currentPlan.status === "Running" &&
      latestSubscriptionData.subscriptionInfo.isSubscribed &&
      latestSubscriptionData?.seats?.available === 0
    ) {
      return setChargeBillingDetailsDailog(true);
    }
    if (subscriptionDataGet.currentPlan.plan === "Free" && subscriptionDataGet?.seats?.available <= 0 && subscriptionDataGet?.seats?.total >= 10) {
      return setShowSubsribeModalAfterTenUser(true);
    }
    if (userPlan === "Free" && availableSeats === 0) {
      return setShowSubsribeModalAfterTenUser(true);
    }
    if (latestSubscriptionData?.currentPlan?.plan === "Free" && latestSubscriptionData?.seats?.used === 10) {
      setShowTrialFreeTrialModal(true);
    }
    if (!id && subscriptionData?.seats?.available === 0 && !UserFreePlan) {
      createUser({
        ...userData,
        isInvited: true,
        roleSetting: accessRightsValues,
        managerPortal: false,
        inspectorPortal: false,
        isUserSeated: seatedType,
        roleName: accessRightsValues?.role?.originalName,
      }).then(() => {
        if (subscriptionData?.seats?.available === 0 && !UserFreePlan && showSeatAddWarning && seatedType === true) {
          updateSubscriptionData(subscriptionData?.id, dataToPassEverySubscription(latestSubscriptionData, "extraSeatAddData", "Update")).then(
            (res) => {
              if (res) {
                getSubscriptionByCompanyId(user?.company?.id);
              }
            }
          );
        } else {
          if (subscriptionData?.seats?.available === 0 && UserFreePlan && showSeatAddWarning) {
            updateSubscriptionData(subscriptionData?.id, dataToPassEverySubscription(latestSubscriptionData, "startTrialData", "Update")).then(
              (res) => {
                if (res) {
                  getSubscriptionByCompanyId(user?.company?.id);
                }
              }
            );
          }
        }
        toast.success(<I18n lng="validation.Created" />);
        getAllUsers();
        secondaryAction();
      });
    } else if (UserFreePlan && allUsers?.length === 10) {
      setUsedTotalAlertModal(true);
    } else {
      if (data && data.id) {
        if (userRoleChanged) {
          getUserDataById(id).then((res) => {
            if (res.value.data) {
              updateUserHandler(data);
            } else {
              if (userForEdit?.role !== "notRegistered") {
                let users = allUsers.filter((item) => {
                  if (!item.isUserSeated) return false;
                  if (!item.isEmailVerified) return false;
                  if (userForEdit.role === "manager") {
                    return ["manager", "manager/inspector", "admin"].includes(item.role) && item.id !== userForEdit.id;
                  } else if (userForEdit.role === "user") {
                    return ["user", "manager/inspector", "admin"].includes(item.role) && item.id !== userForEdit.id;
                  } else if (userForEdit.role === "manager/inspector") {
                    return ["manager/inspector", "admin"].includes(item.role) && item.id !== userForEdit.id;
                  }
                  return false;
                });
                if (users.length) {
                  setUsersToShow([...users]);
                  setUserDataToUpdate({
                    ...data,
                    roleSetting: accessRightsValues,
                    managerPortal: false,
                    inspectorPortal: false,
                    userInfoChange: userForEdit.active !== data.active,
                    isUserSeated: seatedType,
                    roleName: roleName,
                  });
                  if (id && userForEdit.isUserSeated !== seatedType) {
                    setIsSeatedTypeChanged(true);
                  } else {
                    setShowConfirmationDialog(true);
                  }
                } else {
                  toast.error("No user to assign this user's data");
                }
              } else {
                updateUserHandler(data);
              }
            }
          });
        } else {
          updateUserHandler(data);
        }
      } else {
        if (latestSubscriptionData?.seats?.used !== latestSubscriptionData?.seats?.total) {
          createUser({
            ...userData,
            isInvited: true,
            roleSetting: accessRightsValues,
            managerPortal: false,
            inspectorPortal: false,
            isUserSeated: seatedType,
            roleName: accessRightsValues?.role?.originalName,
          })
            .then(() => {
              if (seatedType === true) {
                updateSubscriptionData(
                  subscriptionData?.id,
                  dataToPassEverySubscription(latestSubscriptionData, "createUserSeatUpdate", "Update")
                ).then(() => {
                  getSubscriptionByCompanyId(user?.company?.id);
                });
              }
              toast.success(<I18n lng="validation.Created" />);
              getAllUsers();
              secondaryAction();
            })
            .catch((error) => {
              toast.error(error.response.data.message);
            });
        }
      }
    }
  };

  useEffect(() => {
    if (showDlg && userForEdit && id) {
      setValue("isUserSeated", userForEdit.isUserSeated);
      setUserEdit({
        ...userForEdit,
        timeZone: userForEdit.timeZone ? { value: userForEdit.timeZone, label: userForEdit.timeZone } : "",
      });
      if (userForEdit.roleSetting && userForEdit.roleSetting.id) {
        setAccessRightsValues({
          value: userForEdit.roleSetting.id,
          label:
            userForEdit.roleSetting.name === "owner"
              ? i18n("Roles.owner")
              : userForEdit.roleSetting.name === "manager"
              ? i18n("Roles.manager")
              : userForEdit.roleSetting.name === "inspector"
              ? i18n("Roles.inspector")
              : userForEdit.roleSetting.name === "admin"
              ? i18n("Roles.admin")
              : userForEdit.roleSetting.name === "guest"
              ? i18n("Roles.guest")
              : userForEdit.roleSetting.name,
          role: userForEdit?.roleSetting,
        });
      } else {
        setAccessRightsValues([]);
      }
    } else {
      setUserEdit({
        ...initUserState,
        timeZone: user?.company?.timeZone ? { value: user?.company?.timeZone, label: user?.company?.timeZone } : "",
      });
    }
    // eslint-disable-next-line
  }, [showDlg, id, userForEdit, editUser, view]);

  useEffect(() => {
    if (userEdit) {
      reset({ ...userEdit });
    }
    setIsChanges(false);
    // eslint-disable-next-line
  }, [userEdit]);

  useEffect(() => {
    if (userForEdit?.id && id && accessRightsValues?.value) {
      if (userForEdit?.roleSetting?.id !== accessRightsValues?.value) {
        setUserRoleChanged(true);
      } else {
        setUserRoleChanged(false);
      }
    }
  }, [accessRightsValues, userForEdit, showDlg, id]);

  const deviceDeleteHandler = (index) => {
    let userDevices = [];
    userDevices = [...userForEdit?.userDevices];
    userDevices.splice(index, 1);
    updateUserById({ ...userForEdit, userDevices });
  };

  const StartFreeTrialHandler = async () => {
    const subscriptionResponse = await getSubscriptionByCompanyId(user?.company?.id);
    const latestSubscriptionData = subscriptionResponse?.value?.data;

    const responseData = await updateSubscriptionData(
      subscriptionData?.id,
      dataToPassEverySubscription(latestSubscriptionData, "startTrialData", "Update")
    );
    await getSubscriptionByCompanyId(user?.company?.id);
    setUsedTotalAlertModal(false);
    if (responseData?.value?.data?.userTrial?.isTrialStarted) {
      setShowCreateNewUserModal(true);
      if (currentSeatType === false) {
        setSeatedType(false);
        let guestData = rolesList?.find((item) => item.originalName === "guest");
        setAccessRightsValues({
          value: guestData?.id,
          label:
            guestData?.name === "owner"
              ? i18n("Roles.owner")
              : guestData?.name === "manager"
              ? i18n("Roles.manager")
              : guestData?.name === "inspector"
              ? i18n("Roles.inspector")
              : guestData?.name === "guest"
              ? i18n("Roles.guest")
              : guestData?.name,
          role: guestData,
        });
      }
      if (isRoleSelected && isRoleSelected?.label !== "Admin") {
        setAccessRightsValues(isRoleSelected);
      }
    }
  };

  const freeTrialData = {
    title: <I18n lng="global.SubscriptionRequired" />,
    text_one:
      isSeatedTypeClicked === true ? (
        <I18n lng="Subscriptions.YouCanChangeTheUserTypeIfYouAreSubscribedOnly" />
      ) : isSeatedTypeClicked === false ? (
        <I18n lng="Subscriptions.YouCanChangeUsersRoleIfYouAreSubscribedOnly" />
      ) : (
        <I18n lng="Subscriptions.YourCurrentPlanIsFreeYouCanAddMoreSeatsIfYouAreSubscribedOnly" />
      ),

    text_two: <I18n lng="Subscriptions.StartYour30dayFreeTrialWithNoCreditCardRequired" />,
    closeButtonText: <I18n lng="global.Close" />,
    actionButtonText: <I18n lng="global.StartFreeTrial" />,
  };
  const freeTrialData2 = {
    title: <I18n lng="global.SubscriptionRequired" />,
    text_one: <I18n lng="Subscriptions.YourCurrentPlanIsFreeYouAreCannotAddingElevenUser" />,
    text_two: <I18n lng="Subscriptions.StartYour30dayFreeTrialWithNoCreditCardRequired" />,
    closeButtonText: <I18n lng="global.Close" />,
    actionButtonText: <I18n lng="global.StartFreeTrial" />,
  };

  const createNewUserTrialStartedData = {
    title: <I18n lng="Subscriptions.UpdatePlanTrialStartedModalTitle" />,
    text_one: <I18n lng="Subscriptions.YourTrialHasStartedSuccessfullyYouCanAddMoreUser" />,
    actionButtonText: i18n("Subscriptions.CreateNewUser"),
    closeButtonText: <I18n lng="global.Close" />,
  };

  const unSavedDialogData = {
    title: <I18n lng="global.UnsavedChanges" />,
    text_one: <I18n lng="global.AreYouSureYouWantToContinueWithoutSavingChanges" />,
    primaryButtonLabel: i18n("Subscriptions.Continue"),
    secondaryButtonLabel: <I18n lng="global.Close" />,
  };

  const showSubscribeModalAfterTenUserData = {
    title: <I18n lng="global.SubscriptionRequired" />,
    text_one: <I18n lng="Subscriptions.YourCurrentPlanIsFreeYouAreCannotAddingElevenUser" />,
    primaryButtonLabel: i18n("Subscriptions.Subscribe"),
    secondaryButtonLabel: <I18n lng="global.Close" />,
  };

  const handleCloseSidebar = () => {
    if (isChanges || getValues("timeZone").value !== userEdit.timeZone.value) {
      setShowUnsavedChangesModal(true);
    } else {
      secondaryAction();
    }
  };

  const handleChange = () => {
    setIsChanges(true);
  };

  const handleModalAction = (value) => {
    if (value) {
      secondaryAction();
    }
    setShowUnsavedChangesModal(false);
  };

  const userChangesSeatedTypeData = {
    title: <I18n lng="Subscriptions.Important" />,
    text_one: <I18n lng="Subscriptions.SelectAnotherUserWhoWillTakeCareUserBusiness" />,
    primaryButtonLabel: <I18n lng="Subscriptions.Assign" />,
    secondaryButtonLabel: <I18n lng="global.Cancel" />,
  };

  const showPausedSubscriptionsModalData = {
    title: <I18n lng="Subscriptions.SubscriptionPaused" />,
    text_one: <I18n lng="Subscriptions.YourSubscriptionPausedDueToPaymentCardIssueUpdateYourCardBeforeYouCanProceedWithThisAction" />,
    secondaryButtonLabel: <I18n lng="global.Close" />,
  };

  const showSubscriptionExpiredModalData = {
    title: <I18n lng="Subscriptions.SubscriptionExpired" />,
    text_one: <I18n lng="Subscriptions.YourSubscriptionExpiredSubscribeBeforeYouCanProceedWithThisAction" />,
    secondaryButtonLabel: <I18n lng="global.Close" />,
  };

  console.log(usersToShow);

  return (
    <>
      <DynamicModal
        title={editUser ? <I18n lng="global.EditUser" /> : view ? <I18n lng="global.ViewUser" /> : <I18n lng="global.CreateUser" />}
        show={showDlg}
        secondaryAction={handleCloseSidebar}
        customClasses="modal-drawer company-user-crud"
      >
        <form onSubmit={handleSubmit(onSubmit)} className="comp-users-form sites-wrapper">
          {userEdit?.role !== "admin" && userEdit?.id && userEdit?.isEmailVerified ? (
            <ToggleSwitch
              name="active"
              id="active"
              label={<I18n lng="Company.ActiveThisUser" />}
              checked={userEdit && userEdit.active ? userEdit.active : false}
              {...(view ? { readOnly: true, disabled: true } : {})}
              onClick={() => {
                setUserEdit({ ...userEdit, active: !userEdit.active });
              }}
            />
          ) : null}

          <Input
            onChange={(e) => handleChange("firstName", e.target.value)}
            label={<I18n lng="global.FirstName" />}
            prefix={IconUser}
            id="firstNameInput"
            name="firstName"
            type="text"
            placeholder={i18n("global.FirstName")}
            register={register}
            validations={{
              required: {
                value: true,
                message: i18n("validation.REQUIRED_FIELD"),
              },
            }}
            error={errors["firstName"]}
            {...(view ? { readOnly: true, disabled: true } : {})}
          />
          <Input
            onChange={(e) => handleChange("lastName", e.target.value)}
            label={<I18n lng="global.LastName" />}
            prefix={IconUser}
            id="lastNameInput"
            name="lastName"
            type="text"
            placeholder={i18n("global.LastName")}
            register={register}
            validations={{
              required: {
                value: true,
                message: i18n("validation.REQUIRED_FIELD"),
              },
            }}
            error={errors["lastName"]}
            {...(view ? { readOnly: true, disabled: true } : {})}
          />
          <Input
            onChange={(e) => handleChange("email", e.target.value)}
            label={<I18n lng="global.EnterEmail" />}
            prefix={IconEmail}
            id="emailInput"
            name="email"
            type="email"
            placeholder={i18n("global.Email")}
            register={register}
            validations={{
              required: {
                value: true,
                message: i18n("validation.REQUIRED_FIELD"),
              },
            }}
            error={errors["email"]}
            {...(view ? { readOnly: true, disabled: true } : {})}
          />
          <PhoneNumber
            handleChange={handleChange}
            label={<I18n lng="global.Mobile" />}
            name={"mobile"}
            id={"mobileInput"}
            control={control}
            register={register}
            validations={{
              required: {
                value: true,
                message: i18n("validation.REQUIRED_FIELD"),
              },
              validate: isValidPhoneNumber,
            }}
            error={errors["mobile"]}
            {...(view ? { readOnly: true, disabled: true } : {})}
          />
          <div className="row mb-3">
            {userEdit.role !== "admin" && (
              <>
                <label
                  className="form-label"
                  style={
                    TEXT_DIRECTION() === "rtl"
                      ? {
                          marginRight: "14px",
                        }
                      : {
                          marginLeft: "14px",
                        }
                  }
                >
                  <I18n lng="Subscriptions.UserType" />
                </label>
                <div className="col-lg-12 seatUser-input" onClick={() => setIsSeatedTypeClicked(true)}>
                  <Select
                    value={{
                      value: seatedType,
                      label: (
                        <>
                          {seatedType === undefined ? (
                            <span style={{ color: "#828282" }}>
                              <I18n lng="global.Select" />
                            </span>
                          ) : seatedType ? (
                            <span>{i18n("UserProfile.SeatedUser")}</span>
                          ) : (
                            <span>{i18n("UserProfile.NonSeatedUser")}</span>
                          )}
                          {seatedType !== undefined ? (
                            <span style={{ color: "#828282", marginLeft: "4px" }}>
                              <span className="mx-1">({subscriptionData?.seats?.available}</span>
                              <span>{i18n("UserProfile.SeatsAvailable")})</span>
                            </span>
                          ) : (
                            ""
                          )}
                        </>
                      ),
                    }}
                    isReadOnly={view}
                    isDisabled={view}
                    placeholder={
                      <>
                        <I18n lng="UserProfile.SeatedUser" /> ({subscriptionData?.seats?.available}
                        <I18n lng="UserProfile.SeatsAvailable" />)
                      </>
                    }
                    name="seatedType"
                    {...register("seatedType")}
                    onChange={(option) => {
                      if (
                        option?.value === true &&
                        subscriptionData.currentPlan.status === "Paused" &&
                        subscriptionData.subscriptionInfo.isSubscribed &&
                        subscriptionData?.seats?.available === 0
                      ) {
                        return setShowPausedSubscriptionsModal(true);
                      } else if (
                        option?.value === true &&
                        subscriptionData?.currentPlan?.status === "Expired" &&
                        subscriptionData?.subscriptionInfo?.isSubscribed &&
                        subscriptionData?.subscriptionInfo?.isUnsubscribed &&
                        subscriptionData?.subscriptionInfo?.isSubscribedExpired &&
                        subscriptionData?.seats?.available === 0
                      ) {
                        return setShowSubscriptionExpiredModal(true);
                      } else {
                        handleSeatedTypeChange(option);
                      }
                    }}
                    options={[
                      { value: true, label: i18n("UserProfile.SeatedUser") },
                      {
                        value: false,
                        label: i18n("UserProfile.NonSeatedUser"),
                      },
                    ]}
                  />
                </div>
              </>
            )}
          </div>
          {subscriptionData?.seats?.available === 0 &&
            !UserFreePlan &&
            seatedType !== false &&
            showSeatAddWarning &&
            ((!editUser && seatedType) || (editUser && !userForEdit.isUserSeated)) && (
              <div className="alert-when-adding-on-zero-seats-parent mb-2">
                <div className="alert-when-adding-on-zero-seats-child">
                  <div className="d-flex">
                    <div className="pr-1">
                      <img src={alertIcon} alt="alert Icon" />
                    </div>
                    <div className="ml-1 pr-1">
                      <p>
                        <I18n lng="Company.YouAreAdding" />
                        <span style={{ fontWeight: "600", marginLeft: "4px" }}>
                          1 <I18n lng="Subscriptions.AdditionalSeat" />
                        </span>
                        (<I18n lng="Subscriptions.SAR" /> 79.00{" "}
                        {subscriptionData?.currentPlan?.plan === "Monthly Subscription" ? (
                          <I18n lng="global.PerMonth" />
                        ) : subscriptionData?.currentPlan?.plan === "Yearly Subscription" ? (
                          <I18n lng="global.PerYear" />
                        ) : (
                          <I18n lng="global.PerMonth" />
                        )}
                        )
                      </p>
                      {subscriptionData?.currentPlan?.status === "Running" && subscriptionData?.subscriptionInfo?.isSubscribed ? (
                        <p style={{ fontSize: "12px", fontWeight: "500" }}>
                          <I18n lng="Subscriptions.SubscriptionFeesForAdditionalSeatsWillBeProratedForTheRemainingDaysOfThisCycleAndRegularBillingWillCommenceFromTheNextCyclePleaseNoteThatThePriceDoesNotIncludeAnyTax" />
                        </p>
                      ) : (subscriptionData?.currentPlan?.status === "Unsubscribed, subscription is running" &&
                          subscriptionData?.subscriptionInfo?.isSubscribed) ||
                        subscriptionData?.currentPlan?.status === "Trial is running" ? (
                        <p style={{ fontSize: "12px", fontWeight: "500" }}>
                          <I18n lng="Subscriptions.SubscriptionFeesForTheAdditionalSeatsWillBeProratedForTheRemainingDaysThisCyclePleaseNoteThatThePriceDoesNotIncludeAnyTax" />
                        </p>
                      ) : (
                        <p style={{ fontSize: "12px", fontWeight: "500" }}>
                          <I18n lng="Subscriptions.NoSubscriptionFeesForAdditionalSeatsWillBeChargedDuringYourTrialPeriodRegularBillingYourSubscription" />
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}

          <div className="row mb-3">
            {userEdit.role !== "admin" ? (
              <>
                <label
                  className="form-label"
                  style={
                    TEXT_DIRECTION() === "rtl"
                      ? {
                          marginRight: "14px",
                        }
                      : {
                          marginLeft: "14px",
                        }
                  }
                >
                  <I18n lng="Company.Role" />
                </label>
                <div className="col-lg-12" onClick={() => setIsSeatedTypeClicked(false)}>
                  <Select
                    placeholder={<I18n lng="global.Select" />}
                    isReadOnly={view}
                    isDisabled={view || (id && userForEdit.id === user.id)}
                    label={<I18n lng="Company.Role" />}
                    options={
                      rolesList?.length
                        ? rolesList
                            ?.filter((role) => role.name !== "owner")
                            ?.filter((role) => {
                              if (UserFreePlan && role.name === "guest") {
                                return false;
                              } else if (
                                (cancelTrialCondition && allUsers?.length > 10 && role.name === "guest") ||
                                (cancelTrialCondition && role.name === "guest")
                              ) {
                                return false;
                              } else {
                                return true;
                              }
                            })
                            ?.map((item) => {
                              if (item?.name === "guest" && seatedType === false) {
                                return {
                                  value: item?.id,
                                  label: i18n("Roles.guest"),
                                  role: item,
                                };
                              } else if (item?.name !== "guest" && seatedType) {
                                return {
                                  value: item?.id,
                                  label:
                                    item?.name === "owner"
                                      ? i18n("Roles.owner")
                                      : item?.name === "manager"
                                      ? i18n("Roles.manager")
                                      : item?.name === "inspector"
                                      ? i18n("Roles.inspector")
                                      : item?.name === "admin"
                                      ? i18n("Roles.admin")
                                      : item?.name,
                                  role: item,
                                };
                              }
                              return null;
                            })
                            .filter((option) => option !== null)
                        : []
                    }
                    value={accessRightsValues}
                    onChange={handleRoleChange}
                  />
                </div>
              </>
            ) : null}
          </div>
          <div className="row">
            <div className="col-lg-12">
              <ReactSelect
                label={<I18n lng="global.TimeZone" />}
                placeholder={<I18n lng="global.Select" />}
                name="timeZone"
                register={register}
                control={control}
                validations={{
                  required: { value: true, message: REQUIRED_FIELD },
                }}
                options={timeZones.length ? timeZones.map((item) => item) : ["Riyadh (GMT+03)"]}
                isReadOnly={view}
                isDisabled={view}
              />
            </div>
          </div>
          {userEdit.role !== "admin" ? (
            !view && !hideInviteLinks ? (
              <>
                <div className="my-3">
                  <div className="mb-3 d-block">
                    <div className="row mx-0">
                      <div className={` ${TEXT_DIRECTION() === "rtl" ? "" : ""}`}>
                        <CheckBox
                          onChange={(e) => handleChange("passwordLink", e.target.value)}
                          name="passwordLink"
                          register={register}
                          validations={{}}
                          {...(view ? { readOnly: true, disabled: true } : {})}
                        />
                      </div>
                      <div style={{ color: "#20639b" }} className={`${TEXT_DIRECTION() === "rtl" ? "col-10 pr-1" : "col-11 pl-1"}`}>
                        <label className="mb-0 p-0 mx-1">
                          <I18n lng="Company.SendPasswordResetInstructionsLink" />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )
          ) : null}
          {userEdit.role !== "admin" ? (
            view || editUser ? (
              <div className="border-block block-devices">
                <label className="form-label">
                  <I18n lng="Company.DeviceAllowance" /> :{userEdit?.userDevices?.length}/3
                </label>
                <ul className="inner">
                  {userEdit?.userDevices?.map((device, index) => {
                    return (
                      <li key={index}>
                        <span>{device.deviceName}</span>
                        <div className="block-actions">
                          {userEdit?.userDevices.length > 0 ? (
                            <Link to="#" className="action action-delete">
                              <img
                                src={IconDelete}
                                alt="Delete"
                                title="Delete"
                                onClick={(e) => {
                                  e.preventDefault();
                                  deviceDeleteHandler(index);
                                }}
                              />
                            </Link>
                          ) : (
                            ""
                          )}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            ) : (
              ""
            )
          ) : null}
          {userEdit.role !== "admin" ? (
            !view && !hideInviteLinks ? (
              <div className="row mb-3 mx-0">
                <div className={` ${TEXT_DIRECTION() === "rtl" ? "" : ""}`}>
                  <CheckBox
                    onChange={(e) => handleChange("inviteLink", e.target.value)}
                    name="inviteLink"
                    register={register}
                    validations={{}}
                    {...(view ? { readOnly: true, disabled: true } : {})}
                  />
                </div>
                <div style={{ color: "#20639b" }} className={`${TEXT_DIRECTION() === "rtl" ? "col-10 pr-1" : "col-10 pl-0 ml-0 pr-0"}`}>
                  <label>
                    <I18n lng="Company.SendDeviceRegistrationLink" />
                  </label>
                </div>
              </div>
            ) : (
              ""
            )
          ) : null}
          <div className="border-block block-notifications">
            <table className="table">
              <thead>
                <tr>
                  <th className="d-flex">
                    <I18n lng="Company.Notifications" />
                  </th>
                  <th>
                    <img src={appIcon} alt="" className="w-50  h-50" />
                  </th>
                  <th>
                    <img src={emailIcon} alt="" className="w-50  h-50" />
                  </th>
                  <th>
                    <img src={smsIcon} alt="" className="w-50  h-50" />
                  </th>
                </tr>
              </thead>
              <tbody className="inner">
                <tr>
                  <td>
                    <I18n lng="Company.AssignForm" />
                  </td>
                  <td>
                    <CheckBox
                      label=""
                      onChange={(e) => handleChange("assignForm.app", e.target.value)}
                      name="assignForm.app"
                      register={register}
                      validations={{}}
                      {...(view ? { readOnly: true, disabled: true } : {})}
                    />
                  </td>
                  <td>
                    <CheckBox
                      label=""
                      onChange={(e) => handleChange("assignForm.email", e.target.value)}
                      name="assignForm.email"
                      register={register}
                      validations={{}}
                      {...(view ? { readOnly: true, disabled: true } : {})}
                    />
                  </td>
                  <td>
                    <CheckBox
                      label=""
                      onChange={(e) => handleChange("assignForm.sms", e.target.value)}
                      name="assignForm.sms"
                      register={register}
                      validations={{}}
                      {...(view ? { readOnly: true, disabled: true } : {})}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <I18n lng="Company.ReportSubmission" />
                  </td>
                  <td>
                    <CheckBox
                      label=""
                      onChange={(e) => handleChange("reportSubmission.app", e.target.value)}
                      name="reportSubmission.app"
                      register={register}
                      validations={{}}
                      {...(view ? { readOnly: true, disabled: true } : {})}
                    />
                  </td>
                  <td>
                    <CheckBox
                      label=""
                      onChange={(e) => handleChange("reportSubmission.email", e.target.value)}
                      name="reportSubmission.email"
                      register={register}
                      validations={{}}
                      {...(view ? { readOnly: true, disabled: true } : {})}
                    />
                  </td>
                  <td>
                    <CheckBox
                      label=""
                      onChange={(e) => handleChange("reportSubmission.sms", e.target.value)}
                      name="reportSubmission.sms"
                      register={register}
                      validations={{}}
                      {...(view ? { readOnly: true, disabled: true } : {})}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <I18n lng="Company.ApprovalOfTheReport" />
                  </td>
                  <td>
                    <CheckBox
                      label=""
                      onChange={(e) => handleChange("approvalReport.app", e.target.value)}
                      name="approvalReport.app"
                      register={register}
                      validations={{}}
                      {...(view ? { readOnly: true, disabled: true } : {})}
                    />
                  </td>
                  <td>
                    <CheckBox
                      label=""
                      onChange={(e) => handleChange("approvalReport.email", e.target.value)}
                      name="approvalReport.email"
                      register={register}
                      validations={{}}
                      {...(view ? { readOnly: true, disabled: true } : {})}
                    />
                  </td>
                  <td>
                    <CheckBox
                      label=""
                      onChange={(e) => handleChange("approvalReport.sms", e.target.value)}
                      name="approvalReport.sms"
                      register={register}
                      validations={{}}
                      {...(view ? { readOnly: true, disabled: true } : {})}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <I18n lng="Company.RejectReport" />
                  </td>
                  <td>
                    <CheckBox
                      label=""
                      onChange={(e) => handleChange("rejectReport.app", e.target.value)}
                      name="rejectReport.app"
                      register={register}
                      validations={{}}
                      {...(view ? { readOnly: true, disabled: true } : {})}
                    />
                  </td>
                  <td>
                    <CheckBox
                      label=""
                      onChange={(e) => handleChange("rejectReport.email", e.target.value)}
                      name="rejectReport.email"
                      register={register}
                      validations={{}}
                      {...(view ? { readOnly: true, disabled: true } : {})}
                    />
                  </td>
                  <td>
                    <CheckBox
                      label=""
                      onChange={(e) => handleChange("rejectReport.sms", e.target.value)}
                      name="rejectReport.sms"
                      register={register}
                      validations={{}}
                      {...(view ? { readOnly: true, disabled: true } : {})}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="btn-bar">
            {!view && (
              <button className="btn btn-primary" type="submit" onClick={() => setIsSeatedTypeClicked(null)}>
                <I18n lng="global.Save" />
              </button>
            )}

            <button
              type="button"
              className="btn btn-white"
              onClick={() => {
                if (isChanges) {
                  setShowUnsavedChangesModal(true);
                } else {
                  secondaryAction();
                }
              }}
            >
              {view ? <I18n lng="global.Close" /> : <I18n lng="global.Cancel" />}
            </button>
          </div>
        </form>
      </DynamicModal>
      {usedTotalAlertModal && (
        <PlanDialog
          StartFreeTrialHandler={StartFreeTrialHandler}
          showModal={usedTotalAlertModal}
          onClose={closeAlertModalHandler}
          modalTitle={freeTrialData.title}
          text_one={freeTrialData.text_one}
          text_two={freeTrialData.text_two}
          primaryButtonLabel={freeTrialData.actionButtonText}
          secondaryButtonLabel={freeTrialData.closeButtonText}
          secondaryButtonColor="#828282"
          primaryButtonColor="#20639B"
          titleWidth={"221px"}
          titleHeight={"20px"}
          titleFontWeight={"600"}
          titleFontSize={"20px"}
          titleLineHeight={"20px"}
          titleColor={"#333333"}
          secondaryButtonWidth={"43px"}
          secondaryButtonHeight={"16px"}
          secondaryButtonFontWeight={"600"}
          secondaryButtonFontSize={"16px"}
          secondaryButtonLineHeight={"15.92px"}
        />
      )}

      {showSubscribeModalAfterTenUser && (
        <PlanDialog
          setShowDetailsDialog={setShowDetailsDialog}
          showModal={showSubscribeModalAfterTenUser}
          onClose={() => setShowSubsribeModalAfterTenUser(false)}
          modalTitle={showSubscribeModalAfterTenUserData.title}
          text_one={showSubscribeModalAfterTenUserData.text_one}
          primaryButtonLabel={showSubscribeModalAfterTenUserData.primaryButtonLabel}
          secondaryButtonLabel={showSubscribeModalAfterTenUserData.secondaryButtonLabel}
          secondaryButtonColor="#828282"
          primaryButtonColor="#20639B"
          titleWidth={"221px"}
          titleHeight={"20px"}
          titleFontWeight={"600"}
          titleFontSize={"20px"}
          titleLineHeight={"20px"}
          titleColor={"#333333"}
          secondaryButtonWidth={"43px"}
          secondaryButtonHeight={"16px"}
          secondaryButtonFontWeight={"600"}
          secondaryButtonFontSize={"16px"}
          secondaryButtonLineHeight={"15.92px"}
          primaryButtonWidth={"78px"}
          primaryButtonHeight={"16px"}
          primaryButtonFontWeight={"600"}
          primaryButtonFontSize={"16px"}
          primaryButtonLineHeight={"15.92px"}
        />
      )}
      {showStartFreeTrialModal && (
        <PlanDialog
          StartFreeTrialHandler={StartFreeTrialHandler}
          showModal={showStartFreeTrialModal}
          onClose={() => {
            setShowTrialFreeTrialModal(false);
            showStartFreeTrialModal(false);
          }}
          modalTitle={freeTrialData2.title}
          text_one={freeTrialData2.text_one}
          text_two={freeTrialData2.text_two}
          primaryButtonLabel={freeTrialData2.actionButtonText}
          secondaryButtonLabel={freeTrialData2.closeButtonText}
          secondaryButtonColor="#828282"
          primaryButtonColor="#20639B"
          titleWidth={"221px"}
          titleHeight={"20px"}
          titleFontWeight={"600"}
          titleFontSize={"20px"}
          titleLineHeight={"20px"}
          titleColor={"#333333"}
          secondaryButtonWidth={"43px"}
          secondaryButtonHeight={"16px"}
          secondaryButtonFontWeight={"600"}
          secondaryButtonFontSize={"16px"}
          secondaryButtonLineHeight={"15.92px"}
        />
      )}

      {showCreateNewUserModal && (
        <PlanDialog
          showModal={showCreateNewUserModal}
          onClose={() => setShowCreateNewUserModal(false)}
          modalTitle={createNewUserTrialStartedData.title}
          text_one={createNewUserTrialStartedData.text_one}
          text_two={createNewUserTrialStartedData.text_two}
          secondaryButtonLabel={createNewUserTrialStartedData.closeButtonText}
          secondaryButtonColor="#20639B"
          titleWidth={"124px"}
          titleHeight={"60px"}
          titleFontWeight={"600"}
          titleFontSize={"20px"}
          titleLineHeight={"20px"}
          titleColor={"#333333"}
          secondaryButtonWidth={"43px"}
          secondaryButtonHeight={"16px"}
          secondaryButtonFontWeight={"600"}
          secondaryButtonFontSize={"16px"}
          secondaryButtonLineHeight={"15.92px"}
          primaryButtonWidth={"134px"}
          primaryButtonHeight={"16px"}
          primaryButtonFontWeight={"600"}
          primaryButtonFontSize={"16px"}
          primaryButtonLineHeight={"15.92px"}
        />
      )}

      {canceTrialAndChangeSeatedTypeModal && (
        <PlanDialog
          showModal={canceTrialAndChangeSeatedTypeModal}
          onClose={() => setCanceTrialAndChangeSeatedTypeModal(false)}
          modalTitle={<I18n lng="global.SubscriptionRequired" />}
          text_one={<I18n lng="Subscriptions.YouCanChangeTheUserTypeIfYouAreSubscribedOnly" />}
          secondaryButtonLabel={<I18n lng="global.Close" />}
          titleWidth={"221px"}
          titleHeight={"20px"}
          titleFontWeight={"600"}
          titleFontSize={"20px"}
          titleLineHeight={"20px"}
          secondaryButtonColor="#828282"
          secondaryButtonWidth={"43px"}
          secondaryButtonHeight={"16px"}
          secondaryButtonFontWeight={"600"}
          secondaryButtonFontSize={"16px"}
          secondaryButtonLineHeight={"15.92px"}
        />
      )}

      {canceTrialAndChangeRoleModal && (
        <PlanDialog
          showModal={canceTrialAndChangeRoleModal}
          onClose={() => setCanceTrialAndChangeRoleModal(false)}
          modalTitle={<I18n lng="global.SubscriptionRequired" />}
          text_one={<I18n lng="Subscriptions.YouCanChangeUsersRoleIfYouAreSubscribedOnly" />}
          secondaryButtonLabel={<I18n lng="global.Close" />}
          secondaryButtonColor="#828282"
          titleWidth={"221px"}
          titleHeight={"20px"}
          titleFontWeight={"600"}
          titleFontSize={"20px"}
          titleLineHeight={"20px"}
          secondaryButtonWidth={"43px"}
          secondaryButtonHeight={"16px"}
          secondaryButtonFontWeight={"600"}
          secondaryButtonFontSize={"16px"}
          secondaryButtonLineHeight={"15.92px"}
        />
      )}

      {showUnsavedChangesModal && (
        <PlanDialog
          showModal={showUnsavedChangesModal}
          onClose={() => setShowUnsavedChangesModal(false)}
          modalTitle={unSavedDialogData.title}
          text_one={unSavedDialogData.text_one}
          primaryButtonLabel={unSavedDialogData.primaryButtonLabel}
          secondaryButtonLabel={unSavedDialogData.secondaryButtonLabel}
          handleModalAction={() => handleModalAction(true)}
          primaryButtonColor="#20639B"
          secondaryButtonColor="#828282"
          titleWidth={"175px"}
          titleHeight={"20px"}
          titleFontWeight={"700"}
          titleFontSize={"20px"}
          titleLineHeight={"20px"}
          titleColor={"#333333"}
          secondaryButtonWidth={"53px"}
          secondaryButtonHeight={"16px"}
          secondaryButtonFontWeight={"700"}
          secondaryButtonFontSize={"16px"}
          secondaryButtonLineHeight={"15.92px"}
          primaryButtonWidth={"73px"}
          primaryButtonHeight={"16px"}
          primaryButtonFontWeight={"700"}
          primaryButtonFontSize={"16px"}
          primaryButtonLineHeight={"15.92px"}
        />
      )}

      {showConfirmationDialog || (isSeatedTypeChanged && isUserAssignSomething) ? (
        <SeatedTypeChangeDialog
          showModal={isSeatedTypeChanged || showConfirmationDialog}
          onClose={handleCloseIsSeatedTypeChanged}
          secondaryAction={secondaryAction}
          modalTitle={userChangesSeatedTypeData.title}
          text_one={userChangesSeatedTypeData.text_one}
          primaryButtonLabel={userChangesSeatedTypeData.primaryButtonLabel}
          secondaryButtonLabel={userChangesSeatedTypeData.secondaryButtonLabel}
          primaryButtonColor="#20639B"
          secondaryButtonColor="#828282"
          allUsers={allUsers}
          userForEdit={userForEdit}
          id={id}
          assignUserResponsibility={assignUserResponsibility}
          dataToPassEverySubscription={dataToPassEverySubscription}
          subscriptionData={subscriptionData}
          updateSubscriptionData={updateSubscriptionData}
          getSubscriptionByCompanyId={getSubscriptionByCompanyId}
          userDataToUpdate={userDataToUpdate}
          updateUserById={updateUserById}
          getAllUsers={getAllUsers}
          getSession={getSession}
          user={user}
        />
      ) : (
        ""
      )}

      <SubscriptionsDetailDialog subscriptionData={subscriptionData} show={showDetailsDialog} onHide={() => setShowDetailsDialog(false)} />

      {showPausedSubscriptionsModal && (
        <PlanDialog
          showModal={showPausedSubscriptionsModal}
          onClose={() => setShowPausedSubscriptionsModal(false)}
          modalTitle={showPausedSubscriptionsModalData.title}
          text_one={showPausedSubscriptionsModalData.text_one}
          secondaryButtonLabel={showPausedSubscriptionsModalData.secondaryButtonLabel}
          secondaryButtonColor="#20639B"
          titleWidth={"203PX"}
          titleHeight={"20px"}
          titleFontWeight={"700"}
          titleFontSize={"20px"}
          titleColor={"#333333"}
          secondaryButtonWidth={"43px"}
          secondaryButtonHeight={"16px"}
          secondaryButtonFontWeight={"600"}
          secondaryButtonFontSize={"16px"}
          secondaryButtonLineHeight={"15.92px"}
        />
      )}

      {showSubscriptionExpiredModal && (
        <PlanDialog
          showModal={showSubscriptionExpiredModal}
          onClose={() => setShowSubscriptionExpiredModal(false)}
          modalTitle={showSubscriptionExpiredModalData.title}
          text_one={showSubscriptionExpiredModalData.text_one}
          secondaryButtonLabel={showSubscriptionExpiredModalData.secondaryButtonLabel}
          secondaryButtonColor="#20639B"
          titleWidth={"206px"}
          titleHeight={"20px"}
          titleFontWeight={"700"}
          titleFontSize={"20px"}
          titleLineHeight={"20px"}
          titleColor={"#333333"}
          secondaryButtonWidth={"43px"}
          secondaryButtonHeight={"16px"}
          secondaryButtonFontWeight={"600"}
          secondaryButtonFontSize={"16px"}
          secondaryButtonLineHeight={"15.92px"}
        />
      )}

      <ChargeBillingDetails
        subscriptionData={subscriptionData}
        show={chargeBillingDetailsDailog}
        onHide={() => setChargeBillingDetailsDailog(false)}
        selectedPlan={selectedPlan}
        setSelectedPlan={setSelectedPlan}
        billingSeats={1}
        billingTotalSeats={1}
        isAdditionalSeatsAdd={true}
        openBillingDetailsIfSeatsIsZero={true}
        userData={userDataToPassChargeBillingDetailsIfAddingNewUser}
        accessRightsValues={accessRightsValues}
        seatedType={seatedType}
        secondaryAction={secondaryAction}
        data={userDataToPassChargeBillingDetailsIfEditUser}
        userForEdit={userForEdit}
        fromUsers={true}
      />
    </>
  );
};
const mapStateToProps = ({ authentication, company, sites, user, roles, subscription }) => ({
  rolesList: roles.roles,
  company: company.company,
  siteByID: sites.siteByID,
  user: authentication.user,
  userForEdit: user.userForEdit,
  subscriptionData: subscription.subscriptionData,
  allUsers: user.allUsers,
});

const mapDispatchToProps = {
  createUser,
  getSession,
  getAllUsers,
  getUserById,
  getAllRoles,
  updateUserById,
  getUserDataById,
  assignUserResponsibility,
  getSubscriptionByCompanyId,
  updateSubscriptionData,
  userDispatchRule,
};
export default connect(mapStateToProps, mapDispatchToProps)(CompanyUsersAdd);
