/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { GET_MCQ_NAME } from "../../../../../helper/formHelperFunctions";
import I18n from "../../../languageSelector";
import ReportImages from "../report-photo-component/report-images";
import IconArrowDown from "../../../../../assets/images/arrow-down.svg";

const MultipleChoiceReportPreview = ({
  getAnswer = (answer) => console.log(answer),
  getUpdatedAnswer = (answer) => console.log(answer),
  getOldAnswer = (answer) => console.log(answer),
  setShowQuestionPreview,
  question,
  reportHistory,
  showAndHide,
  setImageIndex,
  setShowImageSrc,
  setShowImageDialog,
  setShowSection,
  setTotalAnswered,
  imageCountData,
  failStatus,
}) => {
  const [answerValue, setAnswerValue] = useState([]);
  const [oldAnswerValue, setOldAnswerValue] = useState([]);
  const [updatedAnswerValue, setUpdatedAnswerValue] = useState([]);
  const [updatedValue, setUpdatedValue] = useState();
  const [oldValue, setOldValue] = useState();
  const [statusState, setStateStatus] = useState("badge-success");
  const [isFailItem, setIsFailItem] = useState("");
  const [multipleClass, setMultipleClass] = useState("");
  const [oldNote, setOldNote] = useState("");
  const [updatedNote, setUpdatedNote] = useState("");
  const [oldPhoto, setOldPhoto] = useState("");
  const [updatedPhoto, setUpdatedPhoto] = useState("");
  const [note, setNote] = useState("");
  const [photo, setPhoto] = useState("");
  const [imageCount, setImageCount] = useState(0);
  console.log(statusState, multipleClass);

  useEffect(() => {
    if (question && question.questionUuid) {
      let answer = getAnswer(question.questionUuid);
      if (answer) {
        setAnswerValue([...answer.selected]);
        setPhoto([...answer?.image]);

        setNote(answer.note);
        if (answer?.selected || answer?.image || answer?.note) {
          setShowSection(true);
          setTotalAnswered((prev) => prev + 1);
        }
      }
      let oldAnswer = getOldAnswer(question.questionUuid);
      if (oldAnswer) {
        setOldAnswerValue([...oldAnswer.selected]);
        setOldPhoto([...oldAnswer?.image]);
        setOldNote(oldAnswer?.note);
      }
      let updatedAnswer = getUpdatedAnswer(question.questionUuid);
      if (updatedAnswer) {
        setUpdatedAnswerValue([...updatedAnswer.selected]);
        setUpdatedPhoto([...updatedAnswer?.image]);
        setUpdatedNote(updatedAnswer?.note);
      }
      let isTextChanged = false;
      let isImagesChanged = false;
      let isNotesChanged = false;
      if (oldAnswer?.selected[0]?.index === updatedAnswer?.selected[0]?.index) {
        isTextChanged = false;
      } else {
        isTextChanged = true;
      }
      if (oldAnswer?.note === updatedAnswer?.note) {
        isNotesChanged = false;
      } else {
        isNotesChanged = true;
      }
      if (oldAnswer?.image.length === updatedAnswer?.image.length) {
        let show = false;
        oldAnswer?.image.forEach((oldImageItem, index) => {
          if (oldImageItem.image !== updatedAnswer.image[index].image) {
            show = true;
          }
        });
        if (show) {
          isImagesChanged = true;
        }
      } else {
        isImagesChanged = true;
      }
      if (!isTextChanged && !isImagesChanged && !isNotesChanged) {
        setShowQuestionPreview(false);
      }
    }
    // eslint-disable-next-line
  }, [question]);

  useEffect(() => {
    if (answerValue && answerValue.length) {
      // eslint-disable-next-line
      answerValue.map((item, index) => {
        if (question?.settings?.answers[item.index].isFail || failStatus) {
          setStateStatus(
            question?.settings?.answers[item.index].name !== "Yes"
              ? "badge-danger"
              : "badge-success"
          );
          setMultipleClass(question?.settings?.answers[item.index].class);

          setIsFailItem("status-failed");
        } else {
          setMultipleClass(question?.settings?.answers[item.index].class);
          setStateStatus(
            question?.settings?.answers[item.index].name === "Fair"
              ? "badge-warning"
              : "badge-success"
          );
        }
      });
    }
    // eslint-disable-next-line
  }, [answerValue]);

  useEffect(() => {
    if (question && question.questionUuid && imageCountData?.length) {
      let data = imageCountData.filter((item) => {
        return item.questionId === question.questionUuid;
      });
      if (data.length) {
        setImageCount(data[0].imageCountStart);
      }
    }
  }, [question, imageCountData]);

  useEffect(() => {
    oldAnswerValue.at(-1);
    setUpdatedValue(oldAnswerValue);
    updatedAnswerValue.at(-1);
    setOldValue(updatedAnswerValue);
    // eslint-disable-next-line
  }, [oldAnswerValue, updatedAnswerValue]);

  return (
    <>
      {failStatus ? (
        <li className={`card ${isFailItem}`}>
          {failStatus ? (
            answerValue?.length &&
            answerValue.some(
              (item) => question?.settings?.answers[item.index].isFail
            ) ? (
              <React.Fragment>
                <div className="list-item-detail">
                  <p className="mt-0 mb-0">
                    <I18n lng="Form.Question" /> # {question?.questionNumber}
                  </p>
                  <p className="question mt-1">
                    {question?.settings?.questionText ===
                    "Add a new question" ? (
                      <I18n lng="Form.AddANewQuestion" />
                    ) : (
                      question?.settings?.questionText
                    )}
                  </p>
                  {answerValue && answerValue.length
                    ? answerValue
                        .filter(
                          (subItem) =>
                            question?.settings?.answers[subItem.index].isFail
                        )
                        .map((item, index) => (
                          <React.Fragment key={index}>
                            <span
                              className={`badge  ${
                                question?.settings?.answers[item.index].class
                              }`}
                            >
                              {GET_MCQ_NAME(
                                question?.settings?.answers[item.index].name
                              )}
                            </span>
                          </React.Fragment>
                        ))
                    : null}
                  {note && (
                    <div className="notes">
                      <label>
                        <I18n lng="global.Notes" />:
                      </label>
                      <p>{note}</p>
                    </div>
                  )}
                  <div className="thumbnail-wrapper">
                    <ReportImages
                      photo={photo}
                      imageCount={imageCount}
                      setImageIndex={setImageIndex}
                      setShowImageSrc={setShowImageSrc}
                      setShowImageDialog={setShowImageDialog}
                    />
                  </div>
                </div>
              </React.Fragment>
            ) : null
          ) : null}
        </li>
      ) : (
        ""
      )}
      {!failStatus ? (
        <li className={`card ${isFailItem}`}>
          {reportHistory ? (
            question?.settings?.answers[oldValue?.[0]?.index]?.name ||
            question?.settings?.answers[updatedValue?.[0]?.index]?.name ||
            oldPhoto?.length ||
            updatedPhoto?.length ||
            oldNote ||
            updatedNote ? (
              <div className="list-item-detail">
                <p className="mb-1">
                  <I18n lng="Form.Question" /> # {question?.questionNumber}
                </p>
                <p className="mt-0 question">
                  {question?.settings?.questionText === "Add a new question" ? (
                    <I18n lng="Form.AddANewQuestion" />
                  ) : (
                    question?.settings?.questionText
                  )}
                </p>
                <p className="pAnswer">
                  <I18n lng="global.PreviousAnswer" />
                </p>
                {oldAnswerValue && oldAnswerValue.length
                  ? oldAnswerValue.map((item, index) => (
                      <React.Fragment key={index}>
                        <span
                          className={`w-100 badge  ${
                            question?.settings?.answers[item.index].class
                          }`}
                        >
                          <div className="mt-1 mb-1">
                            {GET_MCQ_NAME(
                              question?.settings?.answers[item.index].name
                            )}
                          </div>
                        </span>
                      </React.Fragment>
                    ))
                  : null}
                {oldNote && (
                  <div className="notes">
                    <label>
                      <I18n lng="global.Notes" />:
                    </label>
                    <p>{oldNote}</p>
                  </div>
                )}
                {oldPhoto.length ? (
                  <div className="thumbnail-wrapper">
                    <ReportImages
                      photo={oldPhoto}
                      setImageIndex={setImageIndex}
                      setShowImageSrc={setShowImageSrc}
                      setShowImageDialog={setShowImageDialog}
                    />
                  </div>
                ) : (
                  ""
                )}
                <div className="text-center mt-2">
                  <img src={IconArrowDown} alt="loading" />
                </div>
                <p className="uAnswer mt-3">
                  <I18n lng="global.UpdatedAnswer" />
                </p>
                {updatedAnswerValue && updatedAnswerValue.length
                  ? updatedAnswerValue.map((item, index) => (
                      <React.Fragment key={index}>
                        <span
                          className={`w-100 badge  ${
                            question?.settings?.answers[item.index].class
                          }`}
                        >
                          <div className="mt-1 mb-1">
                            {GET_MCQ_NAME(
                              question?.settings?.answers[item.index].name
                            )}
                          </div>
                        </span>
                      </React.Fragment>
                    ))
                  : null}
                {updatedNote && (
                  <div className="notes">
                    <label>
                      <I18n lng="global.Notes" />:
                    </label>
                    <p>{updatedNote}</p>
                  </div>
                )}
                {updatedPhoto.length ? (
                  <div className="thumbnail-wrapper">
                    <ReportImages
                      photo={updatedPhoto}
                      setImageIndex={setImageIndex}
                      setShowImageSrc={setShowImageSrc}
                      setShowImageDialog={setShowImageDialog}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : null
          ) : showAndHide?.content?.all ? (
            <div className="list-item-detail">
              <p className="mt-0 mb-0">
                <I18n lng="Form.Question" /> # {question?.questionNumber}
              </p>
              <p className="question mt-1">
                {question?.settings?.questionText === "Add a new question" ? (
                  <I18n lng="Form.AddANewQuestion" />
                ) : (
                  question?.settings?.questionText
                )}
              </p>
              {answerValue && answerValue.length
                ? answerValue.map((item, index) => (
                    <React.Fragment key={index}>
                      <span
                        className={`badge ${
                          question?.settings?.answers[item.index].class
                        }`}
                      >
                        {GET_MCQ_NAME(
                          question?.settings?.answers[item.index].name
                        )}
                      </span>
                    </React.Fragment>
                  ))
                : null}
              {note && (
                <div className="notes">
                  <label>
                    <I18n lng="global.Notes" />:
                  </label>
                  <p>{note}</p>
                </div>
              )}
              <div className="thumbnail-wrapper">
                <ReportImages
                  photo={photo}
                  imageCount={imageCount}
                  setImageIndex={setImageIndex}
                  setShowImageSrc={setShowImageSrc}
                  setShowImageDialog={setShowImageDialog}
                />
              </div>
            </div>
          ) : showAndHide?.content?.answered &&
            (answerValue?.length || note || photo?.length) ? (
            <div className="list-item-detail">
              <p className="question">
                {question?.settings?.questionText === "Add a new question" ? (
                  <I18n lng="Form.AddANewQuestion" />
                ) : (
                  question?.settings?.questionText
                )}
              </p>
              {answerValue && answerValue.length
                ? answerValue.map((item, index) => (
                    <React.Fragment>
                      <span
                        className={`badge  ${
                          question?.settings?.answers[item.index].class
                        }`}
                      >
                        {GET_MCQ_NAME(
                          question?.settings?.answers[item.index].name
                        )}
                      </span>
                    </React.Fragment>
                  ))
                : null}
              {note && (
                <div className="notes">
                  <label>
                    <I18n lng="global.Notes" />:
                  </label>
                  <p>{note}</p>
                </div>
              )}
              <div className="thumbnail-wrapper">
                <ReportImages
                  photo={photo}
                  imageCount={imageCount}
                  setImageIndex={setImageIndex}
                  setShowImageSrc={setShowImageSrc}
                  setShowImageDialog={setShowImageDialog}
                />
              </div>
            </div>
          ) : showAndHide?.content?.unAnswered &&
            !answerValue?.length &&
            !note &&
            !photo?.length ? (
            <div className="list-item-detail">
              <p className="question">
                {question?.settings?.questionText === "Add a new question" ? (
                  <I18n lng="Form.AddANewQuestion" />
                ) : (
                  question?.settings?.questionText
                )}
              </p>
              {answerValue && answerValue.length
                ? answerValue.map((item, index) => (
                    <React.Fragment key={index}>
                      <span
                        className={`badge  ${
                          question?.settings?.answers[item.index].class
                        }`}
                      >
                        {GET_MCQ_NAME(
                          question?.settings?.answers[item.index].name
                        )}
                      </span>
                    </React.Fragment>
                  ))
                : null}
              {note && (
                <div className="notes">
                  <label>
                    <I18n lng="global.Notes" />:
                  </label>
                  <p>{note}</p>
                </div>
              )}
              <div className="thumbnail-wrapper">
                <ReportImages
                  photo={photo}
                  imageCount={imageCount}
                  setImageIndex={setImageIndex}
                  setShowImageSrc={setShowImageSrc}
                  setShowImageDialog={setShowImageDialog}
                />
              </div>
            </div>
          ) : null}
        </li>
      ) : null}
    </>
  );
};
export default MultipleChoiceReportPreview;
