import axios from "axios";
import { REQUEST, SUCCESS, FAILURE } from "../action-type.util";

export const ACTION_TYPES = {
  CREATE_ROLE: "role/Create",
  GET_ALL_ROLES: "get/roles",
  GET_ROLE_BY_ID: "get/role/By/id",
  UPDATE_ROLE_BY_ID: "update/role/By/id",
};

const initialState = {
  role: {},
  roles: [],
  roleByID: {},
  loading: false,
  error: undefined,
};

export const RolesState = initialState;

// Reducer
// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.CREATE_ROLE):
    case REQUEST(ACTION_TYPES.GET_ALL_ROLES):
      return {
        ...state,
        error: undefined,
        roles: undefined,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.GET_ROLE_BY_ID):
    case REQUEST(ACTION_TYPES.UPDATE_ROLE_BY_ID):
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.CREATE_ROLE):
    case FAILURE(ACTION_TYPES.GET_ALL_ROLES):
      return {
        ...state,
        error: undefined,
        roles: undefined,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.GET_ROLE_BY_ID):
    case FAILURE(ACTION_TYPES.UPDATE_ROLE_BY_ID):
      return {
        ...state,
        error: undefined,
        loading: false,
      };
    case SUCCESS(ACTION_TYPES.GET_ALL_ROLES):
      return {
        ...state,
        roles: action.payload.data.results,
        error: undefined,
        loading: false,
      };
    case SUCCESS(ACTION_TYPES.GET_ROLE_BY_ID):
      return {
        ...state,
        roleByID: action.payload.data,
        error: undefined,
        loading: false,
      };
    case SUCCESS(ACTION_TYPES.UPDATE_ROLE_BY_ID):
      return {
        ...state,
        error: undefined,
        loading: false,
      };
    case SUCCESS(ACTION_TYPES.CREATE_ROLE):
      return {
        ...state,
        role: action.payload.data,
        error: undefined,
        loading: false,
      };
    default:
      return state;
  }
};

// Actions

export const createRole = (data) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.CREATE_ROLE,
    payload: axios.post("/roles", { ...data }),
  });
};
export const getAllRoles = (limit) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.GET_ALL_ROLES,
    payload: axios.get(`/roles?${limit ? `&limit=${limit}` : 10}`),
  });
};
export const getRoleById = (id) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.GET_ROLE_BY_ID,
    payload: axios.get(`/roles/${id}`),
  });
};
export const updateRoleById = (id, data) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.GET_ROLE_BY_ID,
    payload: axios.patch(`/roles/${id}`, { ...data }),
  });
};

export const deleteRoleById = (id) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.GET_ROLE_BY_ID,
    payload: axios.delete(`/roles/${id}`),
  });
};
