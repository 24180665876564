import { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { FaCaretDown } from "react-icons/fa";
import Select, { components } from "react-select";
import { Link, useParams } from "react-router-dom";
import DynamicModal from "../../../../components/dialogs";
import { useReportsUIContext } from "../reports-ui-context";
import I18n, { i18n } from "../../../private/languageSelector";
import { settingInitState } from "../../../../config/constants";
import PagePreview from "../reports-previews/pageReportPreview";
import IconImport from "../../../../assets/images/icon-export.svg";
import IconSettings from "../../../../assets/images/setting-Icon.png";
import { numberFailHandler } from "../../../../helper/helperFunctions";
import IconChervronDoubleDown from "../../../../assets/images/icon-chevron-double-down.svg";
import TitleReportPreview from "../reports-previews/title-reportPreview/titlePageReportPreview";
import {
  getReportById,
  getReportSettings,
} from "../../../../redux/reducers/admin-manager-reports/admin-manager-report.reducer";

const MyReportsDetail = ({
  user,
  showDlg,
  secondaryAction,
  getReportById,
  reportForEdit,
  reportSettings,
  getReportSettings,
  currentRole,
  viewReport,
  otherReportView,
  filterState,
  loggedInUser,
  accessRoles,
}) => {
  const { id } = useParams();
  let isEdit = true;

  let currentRoute =
    window.location.pathname.length === 49
      ? "/reports/my-reports"
      : "/reports/other-reports";

  const reportsUIContext = useReportsUIContext();
  const reportsUIProps = useMemo(() => {
    return {
      openReportExportViewDialog: reportsUIContext.openReportExportViewDialog,
      openOtherReportShareDialog: reportsUIContext.openOtherReportShareDialog,
    };
  }, [reportsUIContext]);

  const [report, setReport] = useState();
  const [answers, setAnswers] = useState([]);
  const [failCount, setFailCount] = useState(0);
  const [answerScore, setAnswerScore] = useState(0);
  const [isApproved, setIsApproved] = useState(false);
  const [questionScore, setQuestionScore] = useState(0);
  const [imageCountData, setImageCountData] = useState([]);
  const [showFullReport, setShowFullReport] = useState(false);
  const [approvedManagers, setApprovedManagers] = useState([]);
  const [pageName, setPageName] = useState({
    value: "id-0",
    label: <I18n lng="Reports.TitlePage" />,
  });

  useEffect(() => {
    if (id) {
      getReportSettings(id, user?.id);
      getReportById(id);
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    setQuestionScore(0);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let approved = [];
    if (reportForEdit) {
      setReport({ ...reportForEdit });
      reportForEdit?.dispatchRules?.some((item) => {
        return item?.approvedManagers?.filter((subItem) => {
          return approved.some((item) => subItem.id === item.id)
            ? null
            : approved.push({
                ...subItem,
              });
        });
      });
    }
    setApprovedManagers([...approved]);

    // eslint-disable-next-line
  }, [reportForEdit]);

  useEffect(() => {
    if (approvedManagers?.length) {
      let isUserApproved = approvedManagers?.filter((user, index) => {
        return user?.id === loggedInUser?.id;
      });
      if (loggedInUser?.role === "admin" || isUserApproved?.length) {
        setIsApproved(true);
      } else {
        setIsApproved(false);
      }
    }
  }, [approvedManagers, loggedInUser]);

  useEffect(() => {
    if (report && report?.formAnswerDetail?.length) {
      setAnswers([...report.formAnswerDetail]);
    }
    // eslint-disable-next-line
  }, [report]);

  const addUpdateAnwser = (answer) => {
    if (answers?.length) {
      const answerIndex = answers.findIndex(
        (item) => item?.questionUuid === answer.questionUuid
      );
      if (answerIndex >= 0) {
        answers[answerIndex] = { ...answer };
        setAnswers([...answers]);
      } else {
        answers.push(answer);
        setAnswers([...answers]);
      }
    } else {
      answers.push(answer);
      setAnswers([...answers]);
    }
  };

  const getAnswer = (uuid) => {
    if (answers.length) {
      const answer = answers.find((item) => item?.questionUuid === uuid);
      return answer ? answer : undefined;
    }
  };

  useEffect(() => {
    let sum = 0;
    let failItem = 0;
    let questionScore = 0;
    let answerScore = 0;
    let failCount = 0;
    if (reportForEdit) {
      reportForEdit?.formDetail?.schema?.pages?.map((item) =>
        // eslint-disable-next-line
        item?.sections?.map((secItem) => {
          sum = sum + secItem.questions.length;
          // eslint-disable-next-line
          secItem?.questions?.map((queItem) => {
            // eslint-disable-next-line
            queItem.settings.answers && queItem.settings.answers.length
              ? // eslint-disable-next-line
                queItem?.settings?.answers?.map((answer) => {
                  return answer.isFail ? (failItem += 1) : null;
                })
              : null;
          });
        })
      );
    }
    if (reportForEdit) {
      let multiQuestionsWithIsFail = [];
      let singleQuestionsWithIsFail = [];
      // eslint-disable-next-line
      reportForEdit?.formDetail?.schema?.pages?.map((page) => {
        // eslint-disable-next-line
        page?.sections.map((section) => {
          // eslint-disable-next-line
          section?.questions
            .filter(
              (item) =>
                item.type === "COMPLEX_MULTIPLE_CHOICE" ||
                item.type === "checkbox" ||
                item.type === "slider" ||
                item.type === "number"
            )
            // eslint-disable-next-line
            .map((question) => {
              if (question) {
                if (question?.settings?.score !== "-") {
                  questionScore =
                    questionScore + Number(question?.settings?.score);
                }
                let answer = reportForEdit.formAnswerDetail.find(
                  (item) => item?.questionUuid === question?.questionUuid
                );
                if (answer && question.type === "COMPLEX_MULTIPLE_CHOICE") {
                  if (
                    question?.settings?.answers[answer?.selected[0]?.index]
                      ?.score === "-" &&
                    question?.settings?.selectionType !== "MULTIPLE"
                  ) {
                    questionScore = questionScore - question.settings.score;
                  }
                  answer?.selected
                    .filter(
                      (item) =>
                        question.settings.answers[item.index].score !== "-"
                    )
                    // eslint-disable-next-line
                    .map((select) => {
                      answerScore =
                        answerScore +
                        Number(question.settings.answers[select.index].score);
                    });
                } else if (answer && question.type === "checkbox") {
                  answer?.value === true
                    ? (answerScore =
                        answerScore + Number(question.settings.score))
                    : (answerScore = answerScore + 0);
                } else if (answer && question.type === "slider") {
                  let sliderMaxValue = question.settings.max;

                  answer?.sliderValue !== null || answer?.sliderValue !== ""
                    ? (answerScore =
                        answerScore +
                        (Number(answer?.sliderValue) / Number(sliderMaxValue)) *
                          Number(question.settings.score))
                    : (answerScore = answerScore + 0);
                } else if (answer && question.type === "number") {
                  let isFail = numberFailHandler(question, answer?.value);
                  if (isFail) {
                    failCount += 1;
                    setFailCount(
                      failCount +
                        multiQuestionsWithIsFail.length +
                        singleQuestionsWithIsFail?.length
                    );
                  }
                }
                if (
                  answer &&
                  question?.settings?.selectionType === "MULTIPLE"
                ) {
                  const hasFailSelected = answer?.selected?.some(
                    (item) => question.settings.answers[item.index].isFail
                  );

                  if (hasFailSelected) {
                    multiQuestionsWithIsFail.push(question);
                    setFailCount(
                      failCount +
                        multiQuestionsWithIsFail.length +
                        singleQuestionsWithIsFail?.length
                    );
                  }
                } else {
                  const singleFailSelected = answer?.selected?.some(
                    (item) => question.settings.answers[item.index].isFail
                  );

                  if (singleFailSelected) {
                    singleQuestionsWithIsFail.push(question);
                    setFailCount(
                      failCount +
                        multiQuestionsWithIsFail.length +
                        singleQuestionsWithIsFail?.length
                    );
                  }
                }
              }
            });
        });
      });
    }
    setQuestionScore(questionScore);
    setAnswerScore(answerScore);

    // eslint-disable-next-line
  }, [reportForEdit]);

  useEffect(() => {
    let data = [];
    let imageCount = 0;
    if (reportForEdit?.formDetail?.schema?.pages?.length) {
      reportForEdit.formDetail.schema.pages.forEach((item) => {
        return item.sections && item.sections.length
          ? item.sections.forEach((section) => {
              return section.questions && section.questions.length
                ? section.questions.forEach((question) => {
                    if (getAnswer(question.questionUuid)?.image?.length) {
                      data.push({
                        questionId: question.questionUuid,
                        imageCountStart: imageCount,
                      });
                    } else if (
                      getAnswer(question.questionUuid)?.inspectorImages?.length
                    ) {
                      data.push({
                        questionId: question.questionUuid,
                        imageCountStart: imageCount,
                      });
                    }

                    return getAnswer(question.questionUuid)?.image?.length ||
                      getAnswer(question.questionUuid)?.inspectorImages?.length
                      ? (imageCount = getAnswer(question.questionUuid)?.image
                          ?.length
                          ? imageCount +
                            getAnswer(question.questionUuid)?.image?.length
                          : getAnswer(question.questionUuid)?.inspectorImages
                              ?.length
                          ? imageCount +
                            getAnswer(question.questionUuid)?.inspectorImages
                              ?.length
                          : null)
                      : null;
                  })
                : null;
            })
          : null;
      });
    }
    setImageCountData([...data]);
    // eslint-disable-next-line
  }, [reportForEdit, answers]);

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <FaCaretDown />
      </components.DropdownIndicator>
    );
  };

  return (
    <>
      <DynamicModal
        customClasses="modal-drawer modal-report-preview"
        show={showDlg}
        secondaryAction={secondaryAction}
      >
        <div className="report-preview">
          <section className="report-title">
            <div className="title-action">
              {currentRole !== "user" &&
              !viewReport &&
              otherReportView &&
              reportForEdit?.status !== "incomplete" ? (
                <Link
                  to="#"
                  className="icon icon-import"
                  onClick={() => {
                    if (currentRoute === "/reports/my-reports") {
                      reportsUIProps.openReportExportViewDialog(id);
                    } else {
                      reportsUIProps.openOtherReportShareDialog(id);
                    }
                  }}
                >
                  <img src={IconImport} alt="Import" />
                </Link>
              ) : null}
              <Select
                placeholder={<I18n lng="global.Select" />}
                className="form-preview-select"
                components={{ DropdownIndicator }}
                onChange={(e) => {
                  if (e.value !== "id-0") {
                    setShowFullReport(true);
                  }
                  setTimeout(() => {
                    let elmnt = document.getElementById(e.value);
                    elmnt?.scrollIntoView();
                    setPageName(e);
                  }, 0);
                }}
                value={
                  pageName === "Title Page" ? (
                    <I18n lng="Reports.TitlePage" />
                  ) : (
                    pageName
                  )
                }
                defaultValue={{
                  value: "id-0",
                  label: <I18n lng="Reports.TitlePage" />,
                }}
                options={
                  report?.formDetail?.schema?.pages?.length
                    ? [
                        {
                          value: "id-0",
                          label: <I18n lng="Reports.TitlePage" />,
                        },
                        ...report?.formDetail?.schema.pages.map(
                          (item, index) => {
                            return {
                              value: `id-${index + 1}`,
                              label:
                                item.name === "Page"
                                  ? `${i18n("Form.Page")} ${index + 1}`
                                  : item.name,
                            };
                          }
                        ),
                      ]
                    : []
                }
              />
              {(currentRole === "user" &&
                accessRoles?.inspector?.access?.customizeReport &&
                !viewReport &&
                isEdit &&
                otherReportView) ||
              (currentRole !== "user" &&
                !viewReport &&
                isEdit &&
                otherReportView) ? (
                <Link
                  to={`${currentRoute}/settings/edit/${reportForEdit?.id}`}
                  className="icon icon-settings"
                >
                  <img
                    src={IconSettings}
                    alt="Filter"
                    className="setting-img"
                  />
                </Link>
              ) : null}
            </div>
          </section>
          <section>
            {report ? (
              <>
                <TitleReportPreview
                  createdAt={report?.createdAt}
                  updatedAt={report?.updatedAt}
                  startAt={report?.startAt}
                  status={report?.status}
                  viewReport={viewReport}
                  isEdit={isEdit}
                  approvedManagers={approvedManagers}
                  assignee={report?.assignee}
                  inspectorName={report?.formDetail?.inspector}
                  documentNumber={
                    report?.form ? report?.form?.documentNumber : 0
                  }
                  title={
                    report?.formDetail?.title ? report?.formDetail?.title : ""
                  }
                  otherReportView={otherReportView}
                  failCount={failCount}
                  site={report?.site}
                  reportShareSetting={{
                    ...reportSettings?.userReportSetting,
                    content: { ...settingInitState.content },
                  }}
                  filterState={filterState}
                  totalPercentage={
                    isNaN((answerScore / questionScore) * 100)
                      ? 0
                      : (answerScore / questionScore) * 100
                  }
                  reportForEdit={reportForEdit}
                  inspectionNumber={report?.dispatchNumber}
                  isApproved={isApproved}
                  imageCountData={imageCountData}
                />
                {report?.formDetail?.schema?.pages &&
                showFullReport &&
                settingInitState.content?.inspectionQuestions &&
                report?.formDetail?.schema?.pages.length
                  ? report?.formDetail?.schema?.pages.map((item, index) => {
                      return (
                        <PagePreview
                          key={index}
                          page={item}
                          index={index}
                          addUpdateAnwser={addUpdateAnwser}
                          getAnswer={getAnswer}
                          showAndHide={{
                            ...reportForEdit?.reportShareSetting,
                            content: { ...settingInitState.content },
                          }}
                          imageCountData={imageCountData}
                        />
                      );
                    })
                  : null}
              </>
            ) : null}
          </section>
          {showFullReport ? (
            <Link
              to="#"
              className="view-full-report report-bar"
              onClick={() => setShowFullReport(false)}
            >
              <strong>
                <I18n lng="Reports.ViewLessReport" />
              </strong>
              <img
                className="view-less"
                src={IconChervronDoubleDown}
                alt="View Less Report"
              />
            </Link>
          ) : null}
          {!showFullReport && settingInitState?.content?.inspectionQuestions ? (
            <Link
              to="#"
              className="view-full-report report-bar"
              onClick={() => setShowFullReport(true)}
            >
              <strong>
                <I18n lng="Reports.ViewFullReport" />
              </strong>
              <img
                className="view-more"
                src={IconChervronDoubleDown}
                alt="View Full Report"
              />
            </Link>
          ) : null}
        </div>
      </DynamicModal>
    </>
  );
};
const mapStateToProps = ({ adminManagerReports, authentication }) => ({
  loggedInUser: authentication.user,
  currentRole: authentication.currentRole,
  accessRoles: authentication.userRoleAccess,
  allReports: adminManagerReports.allReports,
  user: authentication.user,
  reportForEdit: adminManagerReports.reportForEdit,
  reportSettings: adminManagerReports.reportSettings,
});

const mapDispatchToProps = {
  getReportById,
  getReportSettings,
};
export default connect(mapStateToProps, mapDispatchToProps)(MyReportsDetail);
