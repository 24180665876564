import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ManageRoles from "./ManageRoles";
import I18n, { i18n } from "../../../languageSelector";
import IconSearch from "../../../../../assets/images/icon-search.svg";
import { getAllRoles } from "../../../../../redux/reducers/roles/roles.reducer";
import { isRTL, TEXT_DIRECTION } from "../../../../../helper/helperFunctions";

const CompanyRolesPage = ({ getAllRoles, rolesList }) => {
  const [searchValue, setSearchValue] = useState("");
  const [showManageRoles, setShowManageRoles] = useState(false);
  const [size, setSize] = useState(10);
  useEffect(() => {
    getAllRoles(10);
    // eslint-disable-next-line
  }, []);

  const getMoreRoles = () => {
    getAllRoles(size + 10);
    setSize(size + 10);
  };

  return (
    <>
      <div className="px-3">
        <div className="search-bar">
          <form className="form-search">
            <div className="input-group">
              <div className="input-group-prepend">
                <img src={IconSearch} alt="Search Icon" />
              </div>
              <input className="form-control" placeholder={i18n("global.Search")} onChange={(e) => setSearchValue(e.target.value)} />
            </div>
          </form>
        </div>

        <ul className="form-list su-list">
          {rolesList
            ?.filter((item) => item.name.toLowerCase().includes(searchValue.toLowerCase()))
            ?.map((role, index) => {
              return (
                <li key={index} className="justify-content-between">
                  <div className="list-item-detail">
                    <h6
                      className={`truncate ${
                        TEXT_DIRECTION() !== "rtl" && isRTL(role?.name)
                          ? "direction-rtl"
                          : TEXT_DIRECTION() === "rtl" && !isRTL(role?.name)
                          ? "direction-ltr"
                          : ""
                      }`}
                    >
                      {role?.name === "Untitled" ? (
                        <I18n lng="global.Untitled" />
                      ) : role?.name === "owner" ? (
                        i18n("Roles.owner")
                      ) : role?.name === "manager" ? (
                        i18n("Roles.manager")
                      ) : role?.name === "inspector" ? (
                        i18n("Roles.inspector")
                      ) : role?.name === "admin" ? (
                        i18n("Roles.admin")
                      ) : role?.name === "guest" ? (
                        i18n("Roles.guest")
                      ) : (
                        role?.name
                      )}
                    </h6>
                    <small className="truncate text-muted">
                      {role?.totalUsers ? role?.totalUsers : 0} {role?.totalUsers > 1 ? <I18n lng="Company.Users" /> : <I18n lng="Company.User" />}
                    </small>
                  </div>
                  <span className="list-item-action">
                    <p className="mb-0 ">
                      {role?.inspectorRole === true && role?.managerRole === true ? (
                        <span>
                          <I18n lng="Company.BackOffice" /> <br />
                          <I18n lng="Company.Application" />
                        </span>
                      ) : role?.managerRole === true && role?.inspectorRole === false ? (
                        i18n("Company.BackOffice")
                      ) : role?.managerRole === false && role?.inspectorRole === true ? (
                        i18n("Company.Application")
                      ) : (
                        ""
                      )}
                    </p>
                  </span>
                </li>
              );
            })}
        </ul>
        <div className="text-center">
          <button className="btn btn-primary btn-icon mt-3 btn-bar mr-2" onClick={getMoreRoles}>
            <I18n lng="Form.LoadMore" />
          </button>
          <button className="btn btn-primary btn-icon mt-3 btn-bar" onClick={() => setShowManageRoles(true)}>
            <I18n lng="Company.Manage" />
          </button>
        </div>
      </div>
      <ManageRoles show={showManageRoles} onHide={() => setShowManageRoles(false)} />
    </>
  );
};
const mapStateToProps = ({ roles }) => ({ rolesList: roles.roles });

const mapDispatchToProps = { getAllRoles };

export default connect(mapStateToProps, mapDispatchToProps)(CompanyRolesPage);
