import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { connect } from "react-redux";
import ListItemMenu from "./list-item-menu";
import { TiDocumentText } from "react-icons/ti";
import I18n, { i18n } from "../../../languageSelector";
import { useReportsUIContext } from "../../reports-ui-context";
import AlertDialog from "../../../../../components/dialogs/alertDialog";
import IconBookmark from "../../../../../assets/images/icon-bookmark.png";
import { getFormLastModifiedDate } from "../../../../../helper/date-time-helper";
import {
  getOtherReports,
  updateReportIsRead,
  getAllReportsWithOutStateChange,
} from "../../../../../redux/reducers/admin-manager-reports/admin-manager-report.reducer";
import ListItemMenuOthersReports from "../my-reports-list-components/list-item-menu-other";
import {
  getAllNotifications,
  notificationReadSingle,
} from "../../../../../redux/reducers/notifications/notifications.reducer";
import {
  GET_WINDOW_DIMENSIONS,
  isRTL,
  TEXT_DIRECTION,
} from "../../../../../helper/helperFunctions";
import { getBookmark } from "../../../../../redux/reducers/form/form.reducer";

const ReportsList = ({
  id,
  user,
  site,
  form,
  status,
  assigner,
  updatedAt,
  formDetail,
  loading,
  setLoading,
  bookmarked,
  reportItem,
  reportUUid,
  currentRole,
  dispatchDate,
  getBookmark,
  isSelfAssign,
  inspectorName,
  getOtherReports,
  formManagementId,
  otherReportsView,
  allNotifications,
  allReportSettings,
  getAllNotifications,

  notificationReadSingle,
  getAllReportsWithOutStateChange,
}) => {
  const [windowWidth, setWIndowWidth] = useState(1920);
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [bookmarkId, setbookmarkId] = useState();
  const [showAlertDialog, setShowAlertDialog] = useState(false);
  const [updatedReportNotification, setUpdatedReportNotification] =
    useState(undefined);

  const reportsUIContext = useReportsUIContext();
  const reportsUIProps = useMemo(() => {
    return {
      openReportViewDialog: reportsUIContext.openReportViewDialog,
      openReportEditDialog: reportsUIContext.openReportEditDialog,
      openReportExportViewDialog: reportsUIContext.openReportExportViewDialog,
      archiveReports: reportsUIContext.archiveReports,
      viewHistoryReport: reportsUIContext.viewHistoryReport,
      viewOtherHistoryReport: reportsUIContext.viewOtherHistoryReport,
      openEditReportDialog: reportsUIContext.openEditReportDialog,
      openOtherReportsView: reportsUIContext.openOtherReportsView,
      openOtherReportsManagerView: reportsUIContext.openOtherReportsManagerView,
      openOtherReportShareDialog: reportsUIContext.openOtherReportShareDialog,
      reportBookmarkUnBookMarked: reportsUIContext.reportBookmarkUnBookMarked,
    };
  }, [reportsUIContext]);

  const handleReportView = () => {
    if (window.location.pathname.includes("/my-reports")) {
      reportsUIProps.openReportViewDialog(formManagementId);
    } else {
      reportsUIProps.openOtherReportsManagerView(formManagementId);
    }
  };

  useEffect(() => {
    let formId = form?.id ? form?.id : form?._id;
    if (allNotifications && allNotifications.length && formId) {
      let updatedReport = allNotifications.filter((item) => {
        return (
          (item?.formManagement &&
            item?.formManagement?.form?.id === formId &&
            id === item?.report?.id) ||
          item?.report?.id === formManagementId
        );
      });
      let sortedByDate = updatedReport.sort(
        (a, b) => Date.parse(a?.createdAt) - Date.parse(b?.createdAt)
      );
      if (sortedByDate && sortedByDate.length) {
        setUpdatedReportNotification({
          ...sortedByDate[sortedByDate.length - 1],
        });
      }
    } else {
      setUpdatedReportNotification(undefined);
    }
  }, [id, form, formManagementId, allNotifications]);

  useEffect(() => {
    const handleResize = () => {
      setWIndowWidth(GET_WINDOW_DIMENSIONS().width);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (user && id && form) {
      getBookmark(user.id, "REPORT").then((res) => {
        const bookmarkedItem = res?.value?.data.find(
          (item) => item?.moduleId === id && user.id === item?.userId
        );

        if (bookmarkedItem) {
          setIsBookmarked(true);
          setbookmarkId(bookmarkedItem.id);
        } else {
          setIsBookmarked(false);
          setbookmarkId(null);
        }
        if (loading === true) {
          setLoading(false);
        }
      });
    }
    // eslint-disable-next-line
  }, [loading]);

  return (
    <>
      <li>
        <div
          className={`list-item-avatar ${
            updatedReportNotification !== undefined &&
            !updatedReportNotification.isRead
              ? status === "reject" && currentRole === "user"
                ? "status-danger"
                : status === "accept" && currentRole === "user"
                ? "status-success"
                : status === "submit" && currentRole !== "user"
                ? "status-submit"
                : status === "draft" && currentRole === "user"
                ? "status-draft"
                : status === "incomplete" && currentRole === "user"
                ? "status-incomplete"
                : status === "reSubmit" && currentRole !== "user"
                ? "status-submit"
                : ""
              : ""
          }`}
          onClick={() => {
            if (updatedReportNotification !== undefined) {
              if (
                currentRole !== "user" &&
                status === "submit" &&
                !updatedReportNotification.isRead
              ) {
                setShowAlertDialog(true);
              } else if (
                currentRole === "user" &&
                status === "accept" &&
                !updatedReportNotification.isRead
              ) {
                setShowAlertDialog(true);
              } else if (
                currentRole === "user" &&
                status === "reject" &&
                !updatedReportNotification.isRead
              ) {
                setShowAlertDialog(true);
              } else if (
                currentRole === "user" &&
                status === "incomplete" &&
                !updatedReportNotification.isRead
              ) {
                setShowAlertDialog(true);
              } else if (
                currentRole !== "user" &&
                status === "incomplete" &&
                !updatedReportNotification.isRead
              ) {
                setShowAlertDialog(true);
              } else if (
                currentRole !== "user" &&
                status === "draft" &&
                !updatedReportNotification.isRead
              ) {
                setShowAlertDialog(true);
              } else if (
                currentRole !== "user" &&
                status === "reSubmit" &&
                !updatedReportNotification.isRead
              ) {
                setShowAlertDialog(true);
              }
            }
          }}
        >
          <span className="avatar-wrap">
            {form?.formDetail?.image ? (
              <img src={form?.formDetail?.image} alt="Avatar"></img>
            ) : (
              <TiDocumentText />
            )}
          </span>
          <div className="avatar-badge">
            {isBookmarked ? (
              <img
                className="icon icon-bookmark active"
                src={IconBookmark}
                alt="Bookmark"
              />
            ) : null}
          </div>
        </div>
        <div
          className="list-item-detail cursor-pointer"
          onClick={() => handleReportView()}
        >
          <h6
            className={`truncate ${
              TEXT_DIRECTION() !== "rtl" &&
              isRTL(
                formDetail?.title ? formDetail?.title : form?.formDetail.title
              )
                ? "direction-rtl"
                : TEXT_DIRECTION() === "rtl" && !isRTL(formDetail?.title)
                ? "direction-ltr"
                : ""
            }`}
          >
            {form?.formDetail?.title ? (
              form?.formDetail?.title.includes("Untitled Form") ? (
                <>
                  {form?.formDetail?.title.replace(
                    "Untitled Form",
                    i18n("global.UntitledForm")
                  )}{" "}
                </>
              ) : (
                form?.formDetail?.title
              )
            ) : null}
          </h6>

          <p className={`truncate text-muted`}>
            <span>
              {reportItem?.site?.name === "Default Site"
                ? `${i18n("Company.DefaultSite")}`
                : reportItem?.site?.name}
            </span>{" "}
            - <span>{reportItem?.assignee?.fullName}</span> -{" "}
            <span>{moment(reportItem?.updatedAt).format("DD-MM-YYYY")}</span>
          </p>
          <small>{getFormLastModifiedDate(updatedAt)}</small>
        </div>
        <span
          className={`list-item-action ${
            TEXT_DIRECTION() === "rtl" ? "ml-0" : ""
          } 
        ${
          TEXT_DIRECTION() !== "rtl" && windowWidth < 770
            ? "report-action-icons ltr"
            : ""
        }
        ${
          TEXT_DIRECTION() === "rtl" && windowWidth < 770
            ? "report-action-icons rtl"
            : ""
        }
        `}
        >
          <small
            className={`status ${
              status === "reject"
                ? "status-danger"
                : status === "accept"
                ? "status-success"
                : status === "submit"
                ? "status-submit"
                : status === "incomplete"
                ? "status-incomplete"
                : status === "draft"
                ? "status-draft"
                : status === "reSubmit"
                ? "status-re-submit"
                : "status-submit"
            }`}
          >
            {status === "accept" ? (
              <I18n lng="Reports.Compelete" />
            ) : status === "draft" ? (
              <I18n lng="Reports.Draft" />
            ) : status === "incomplete" ? (
              <I18n lng="Reports.Incomplete" />
            ) : status === "submit" ? (
              <I18n lng="Notifications.Submitted" />
            ) : status === "reSubmit" ? (
              <I18n lng="Reports.ResubmitStatus" />
            ) : (
              <I18n lng="Reports.RejectStatus" />
            )}
          </small>
          {otherReportsView ? (
            <ListItemMenuOthersReports
              id={formManagementId}
              bookmarkId={bookmarkId}
              status={status}
              openReportViewDialog={reportsUIProps.openReportViewDialog}
              openReportEditDialog={reportsUIProps.openReportEditDialog}
              openReportExportViewDialog={
                reportsUIProps.openReportExportViewDialog
              }
              viewHistoryReport={reportsUIProps.viewHistoryReport}
              viewOtherHistoryReport={reportsUIProps.viewOtherHistoryReport}
              archiveReports={reportsUIProps.archiveReports}
              openEditReportDialog={reportsUIProps.openEditReportDialog}
              openOtherReportsView={reportsUIProps.openOtherReportsView}
              openOtherReportsManagerView={
                reportsUIProps.openOtherReportsManagerView
              }
              openOtherReportShareDialog={
                reportsUIProps.openOtherReportShareDialog
              }
              reportBookmarkUnBookMarked={
                reportsUIProps.reportBookmarkUnBookMarked
              }
              otherReportsView={otherReportsView}
              reportUUid={reportUUid}
              loading={loading}
              setLoading={setLoading}
              bookmarked={bookmarked}
              isBookmarked={isBookmarked}
              setisBookmarked={setIsBookmarked}
              reportItem={reportItem}
            />
          ) : (
            <ListItemMenu
              isSelfAssign={isSelfAssign}
              id={formManagementId}
              form={form}
              status={status}
              isBookmarked={isBookmarked}
              setisBookmarked={setIsBookmarked}
              bookmarkId={bookmarkId}
              loading={loading}
              setLoading={setLoading}
              reportItem={reportItem}
              openReportViewDialog={reportsUIProps.openReportViewDialog}
              openReportEditDialog={reportsUIProps.openReportEditDialog}
              openReportExportViewDialog={
                reportsUIProps.openReportExportViewDialog
              }
              viewHistoryReport={reportsUIProps.viewHistoryReport}
              viewOtherHistoryReport={reportsUIProps.viewOtherHistoryReport}
              archiveReports={reportsUIProps.archiveReports}
              openEditReportDialog={reportsUIProps.openEditReportDialog}
              openOtherReportsView={reportsUIProps.openOtherReportsView}
              openOtherReportShareDialog={
                reportsUIProps.openOtherReportShareDialog
              }
              reportBookmarkUnBookMarked={
                reportsUIProps.reportBookmarkUnBookMarked
              }
              otherReportsView={otherReportsView}
              reportUUid={reportUUid}
              bookmarked={bookmarked}
            />
          )}
        </span>
      </li>

      <AlertDialog
        showDlg={showAlertDialog}
        classes="modal-alert-dialog"
        iconCloseDlg={true}
        size="md"
        content={
          <div className="text-center">
            <p>
              {status === "submit" ? (
                <>
                  <h3 className="mt-2">
                    <I18n lng="Notifications.ReportSubmitted" />!
                  </h3>
                  <strong>
                    <I18n lng="Notifications.YourReportTitled" />{" "}
                    <b>
                      "
                      {updatedReportNotification?.report?.formDetail?.title?.includes(
                        "Untitled Form"
                      ) ? (
                        <>
                          <I18n lng="global.UntitledForm" />{" "}
                          {
                            updatedReportNotification?.report?.formDetail
                              ?.documentNumber
                          }{" "}
                        </>
                      ) : (
                        updatedReportNotification?.report?.formDetail?.title
                      )}
                      -{" "}
                      {updatedReportNotification?.report?.site?.name ===
                      "Default Site"
                        ? `${i18n("Company.DefaultSite")}`
                        : updatedReportNotification?.report?.site?.name}{" "}
                      -{" "}
                      {moment(updatedReportNotification?.createdAt).format(
                        "DD MM YYYY"
                      )}{" "}
                      - {updatedReportNotification?.report?.assignee?.fullName}"
                    </b>{" "}
                    <I18n lng="Notifications.HasBeen" />{" "}
                    <b className="submit">
                      {" "}
                      <I18n lng="Notifications.HasBeenSubmittedBy" />{" "}
                    </b>{" "}
                    <I18n lng="Notifications.By" />{" "}
                    <b>
                      {inspectorName
                        ? inspectorName
                        : updatedReportNotification?.report?.assignee?.fullName}
                    </b>{" "}
                    <I18n lng="Notifications.On" />{" "}
                    <b>{moment(updatedAt).format("DD/MM/YYYY hh:mm A")}</b> .
                  </strong>
                </>
              ) : status === "reject" ? (
                <>
                  <h3 className="mt-2">
                    <I18n lng="Notifications.ReportRejected" />!
                  </h3>
                  <strong>
                    <I18n lng="Notifications.YourReportTitle" />{" "}
                    <b>
                      "
                      {updatedReportNotification?.report?.formDetail?.title?.includes(
                        "Untitled Form"
                      ) ? (
                        <>
                          <I18n lng="global.UntitledForm" />{" "}
                          {
                            updatedReportNotification?.report?.formDetail
                              ?.documentNumber
                          }{" "}
                        </>
                      ) : (
                        updatedReportNotification?.report?.formDetail?.title
                      )}{" "}
                      -{" "}
                      {updatedReportNotification?.report?.site?.name ===
                      "Default Site"
                        ? `${i18n("Company.DefaultSite")}`
                        : updatedReportNotification?.report?.site?.name}{" "}
                      -{" "}
                      {moment(updatedReportNotification?.createdAt).format(
                        "DD MM YYYY"
                      )}{" "}
                      - {updatedReportNotification?.report?.assignee?.fullName}"
                    </b>{" "}
                    <I18n lng="Notifications.HasBeen" />
                    <b className="danger ml-1">
                      <I18n lng="Notifications.HasBeenRejectedBy" />{" "}
                    </b>{" "}
                    <I18n lng="Notifications.By" /> <b>{assigner?.fullName}</b>{" "}
                    <I18n lng="Notifications.On" />{" "}
                    <b>{moment(updatedAt).format("DD/MM/YYYY hh:mm A")}</b> .
                  </strong>
                </>
              ) : status === "accept" ? (
                <>
                  <h3 className="mt-2">
                    <I18n lng="Notifications.ReportApproved" />!
                  </h3>
                  <strong>
                    <I18n lng="Notifications.YourReportTitled" />{" "}
                    <b>
                      "
                      {updatedReportNotification?.report?.formDetail?.title?.includes(
                        "Untitled Form"
                      ) ? (
                        <>
                          <I18n lng="global.UntitledForm" />{" "}
                          {
                            updatedReportNotification?.report?.formDetail
                              ?.documentNumber
                          }{" "}
                        </>
                      ) : (
                        updatedReportNotification?.report?.formDetail?.title
                      )}{" "}
                      -{" "}
                      {updatedReportNotification?.report?.site?.name ===
                      "Default Site"
                        ? `${i18n("Company.DefaultSite")}`
                        : updatedReportNotification?.report?.site?.name}{" "}
                      -{" "}
                      {moment(updatedReportNotification?.createdAt).format(
                        "DD MM YYYY"
                      )}{" "}
                      - {updatedReportNotification?.report?.assignee?.fullName}"
                    </b>{" "}
                    <I18n lng="Notifications.HasBeen" />{" "}
                    <b className="success">
                      <I18n lng="Notifications.Approved" />
                    </b>{" "}
                    <I18n lng="Notifications.By" /> {"  "}
                    <b>
                      {assigner?.fullName}
                    </b> <I18n lng="Notifications.On" />{" "}
                    <b>{moment(updatedAt).format("DD/MM/YYYY hh:mm A")}</b> .
                  </strong>
                </>
              ) : status === "incomplete" ? (
                <>
                  <h3 className="mt-2">
                    <I18n lng="Notifications.ReportIncomplete" />!
                  </h3>
                  <strong>
                    <I18n lng="Notifications.YourReportTitled" />{" "}
                    <b>
                      "
                      {updatedReportNotification?.report?.formDetail?.title?.includes(
                        "Untitled Form"
                      ) ? (
                        <>
                          <I18n lng="global.UntitledForm" />{" "}
                          {
                            updatedReportNotification?.report?.formDetail
                              ?.documentNumber
                          }{" "}
                        </>
                      ) : (
                        updatedReportNotification?.report?.formDetail?.title
                      )}{" "}
                      -{" "}
                      {updatedReportNotification?.report?.site?.name ===
                      "Default Site"
                        ? `${i18n("Company.DefaultSite")}`
                        : updatedReportNotification?.report?.site?.name}{" "}
                      -{" "}
                      {moment(updatedReportNotification?.createdAt).format(
                        "DD MM YYYY"
                      )}{" "}
                      - {updatedReportNotification?.report?.assignee?.fullName}"
                    </b>{" "}
                    <I18n lng="Notifications.hasBeenChangedToIncompleteBy" />{" "}
                    <b className="incomplete">
                      <I18n lng="Notifications.Incomplete" />{" "}
                    </b>{" "}
                    <I18n lng="Notifications.By" /> {"  "}
                    <b>
                      {assigner?.fullName}
                    </b> <I18n lng="Notifications.On" />{" "}
                    <b>{moment(updatedAt).format("DD/MM/YYYY hh:mm A")}</b> .
                  </strong>
                </>
              ) : status === "reSubmit" ? (
                <>
                  <h3 className="mt-2">
                    <I18n lng="Notifications.ReportReSubmitted" />!
                  </h3>
                  <strong>
                    <I18n lng="Notifications.YourReportTitled" />{" "}
                    <b>
                      "
                      {updatedReportNotification?.report?.formDetail?.title?.includes(
                        "Untitled Form"
                      ) ? (
                        <>
                          <I18n lng="global.UntitledForm" />{" "}
                          {
                            updatedReportNotification?.report?.formDetail
                              ?.documentNumber
                          }{" "}
                        </>
                      ) : (
                        updatedReportNotification?.report?.formDetail?.title
                      )}{" "}
                      -{" "}
                      {updatedReportNotification?.report?.site?.name ===
                      "Default Site"
                        ? `${i18n("Company.DefaultSite")}`
                        : updatedReportNotification?.report?.site?.name}{" "}
                      -{" "}
                      {moment(updatedReportNotification?.createdAt).format(
                        "DD MM YYYY"
                      )}{" "}
                      - {updatedReportNotification?.report?.assignee?.fullName}"
                    </b>{" "}
                    <I18n lng="Notifications.HasBeen" />{" "}
                    <b className="submit">
                      <I18n lng="Notifications.ReSubmitted" />
                    </b>{" "}
                    <I18n lng="Notifications.By" /> {"  "}
                    <b>
                      {inspectorName
                        ? inspectorName
                        : updatedReportNotification?.report?.assignee?.fullName}
                    </b>{" "}
                    <I18n lng="Notifications.On" />{" "}
                    <b>{moment(updatedAt).format("DD/MM/YYYY hh:mm A")}</b> .
                  </strong>
                </>
              ) : (
                <strong>
                  <I18n lng="Notifications.InProgress" />
                </strong>
              )}
            </p>
          </div>
        }
        primaryActionTitle={i18n("global.Close")}
        secondaryAction={() => {
          setShowAlertDialog(false);
          if (!updatedReportNotification.isRead) {
            notificationReadSingle(updatedReportNotification.id, {
              isRead: true,
            }).then((res) => {
              getAllNotifications().then(() => {
                if (otherReportsView) {
                  getOtherReports();
                } else {
                  getAllReportsWithOutStateChange(false);
                }
              });
            });
          }
        }}
        primaryAction={() => {
          setShowAlertDialog(false);
          if (!updatedReportNotification.isRead) {
            notificationReadSingle(updatedReportNotification.id, {
              isRead: true,
            }).then(() => {
              getAllNotifications().then((res) => {
                if (otherReportsView) {
                  getOtherReports();
                } else {
                  getAllReportsWithOutStateChange(false);
                }
              });
            });
          }
        }}
      />
    </>
  );
};

const mapStateToProps = ({
  notifications,
  authentication,
  adminManagerReports,
}) => ({
  user: authentication.user,
  currentRole: authentication.currentRole,
  isAuthenticated: authentication.isAuthenticated,
  allReportSettings: adminManagerReports.allReportSettings,
  allNotifications: notifications.allNotifications,
});

const mapDispatchToProps = {
  getOtherReports,
  updateReportIsRead,
  getAllNotifications,
  notificationReadSingle,
  getBookmark,
  getAllReportsWithOutStateChange,
};
export default connect(mapStateToProps, mapDispatchToProps)(ReportsList);
