import React, { useEffect, useState } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import FormPreview from "../form-preview/form-preview";
import {
  getForm,
  formManagementById,
  clearForManagementAnswers,
} from "../../../../../redux/reducers/form/form.reducer";
import I18n from "../../../languageSelector";
import ConfirmationDialog from "../../../../../components/dialogs/confirmation-dialog";

const FormView = ({
  user,
  view,
  onHide,
  getForm,
  formToEdit,
  reportEdit,
  currentRole,
  filterState,
  reportForEdit,
  formManagementById,
  formManagementToEdit,
  clearForManagementAnswers,
}) => {
  const [alertDialog, setAlertDialog] = useState(false);
  const { id, dispatchFormId } = useParams();

  useEffect(() => {
    if (id && !reportEdit) {
      getForm(id);
    }
    if (dispatchFormId) {
      formManagementById(dispatchFormId);
    }
    // eslint-disable-next-line
  }, [id, dispatchFormId]);

  const formTitleInitState = {
    preparedBy: formToEdit?.user?.fullName,
    documentNo: formToEdit?.documentNumber,
  };

  return (
    <>
      {reportEdit ? (
        reportForEdit?.form?.formDetail?.schema && (
          <FormPreview
            showDlg={true}
            secondaryAction={() => onHide()}
            title={reportForEdit?.form?.formDetail.title}
            description={reportForEdit?.form?.formDetail?.description}
            schema={reportForEdit?.formDetail.schema}
            formTitleInitState={formTitleInitState}
            view={view}
            reportFormAnswers={reportForEdit?.formAnswerDetail}
            reportEdit={reportEdit}
            reportForEdit={reportForEdit}
            preparedBy={reportForEdit?.assignee?.fullName}
            documentNo={
              reportForEdit?.form?.documentNumber
                ? reportForEdit?.form?.documentNumber
                : reportForEdit?.formDetail?.documentNumber
            }
            formSchemaQuestions={reportForEdit}
            inspectionNumber={reportForEdit?.dispatchNumber}
            signatureName={reportForEdit?.assigner?.fullName}
            filterState={filterState}
            site={
              reportForEdit?.site
                ? {
                    value: reportForEdit?.site.id,
                    label: reportForEdit?.site?.name,
                  }
                : { value: "", label: "" }
            }
            conductedOn={moment(reportForEdit?.startAt).format("DD MMM YYYY")}
          />
        )
      ) : formToEdit?.formDetail?.schema && currentRole !== "user" ? (
        <FormPreview
          showDlg={true}
          secondaryAction={(key) => {
            if (currentRole && view) {
              onHide();
            } else if (key) {
              onHide();
            } else if (formManagementToEdit?.status === "assign") {
              onHide();
            } else {
              setAlertDialog(true);
            }
          }}
          title={formToEdit?.formDetail.title}
          status={formManagementToEdit?.status}
          description={formToEdit?.formDetail.description}
          schema={formToEdit?.formDetail.schema}
          formTitleInitState={formTitleInitState}
          view={view}
          preparedBy={user?.fullName}
          signatureName={user?.fullName}
          conductedOn={moment().format("DD MMM YYYY")}
          documentNo={
            formToEdit?.documentNumber
              ? formToEdit?.documentNumber
              : formToEdit?.documentNumber
          }
          inspectionNumber={formManagementToEdit?.dispatchNumber}
          formSchemaQuestions={formManagementToEdit}
          sigValue={
            formToEdit?.signature && formToEdit?.signature.length
              ? formToEdit?.signature
              : []
          }
          site={
            formManagementToEdit?.site
              ? {
                  value: formManagementToEdit?.site.id,
                  label: formManagementToEdit?.site?.name,
                }
              : { value: "", label: "" }
          }
        />
      ) : formManagementToEdit &&
        formManagementToEdit?.form?.formDetail?.schema &&
        currentRole === "user" ? (
        <FormPreview
          showDlg={true}
          secondaryAction={(key) => {
            if (currentRole !== "user") {
              onHide();
            } else if (currentRole === "user" && view) {
              onHide();
            } else if (key) {
              onHide();
            } else if (
              formManagementToEdit?.status === "assign" &&
              currentRole === "user"
            ) {
              onHide();
            } else {
              setAlertDialog(true);
            }
          }}
          title={formManagementToEdit?.formDetail.title}
          status={formManagementToEdit?.status}
          description={formManagementToEdit?.formDetail.description}
          schema={formManagementToEdit?.formDetail.schema}
          formTitleInitState={formTitleInitState}
          view={view}
          preparedBy={user?.fullName}
          signatureName={user?.fullName}
          conductedOn={moment().format("DD MMM YYYY")}
          documentNo={
            formManagementToEdit?.form?.documentNumber
              ? formManagementToEdit?.form?.documentNumber
              : formManagementToEdit?.formDetail?.documentNumber
          }
          inspectionNumber={formManagementToEdit?.dispatchNumber}
          formSchemaQuestions={formManagementToEdit}
          sigValue={
            formManagementToEdit?.signature &&
            formManagementToEdit?.signature.length
              ? formManagementToEdit?.signature
              : []
          }
          site={
            formManagementToEdit?.site
              ? {
                  value: formManagementToEdit?.site.id,
                  label: formManagementToEdit?.site?.name,
                }
              : { value: "", label: "" }
          }
        />
      ) : null}

      <ConfirmationDialog
        showDlg={alertDialog}
        title={<I18n lng="global.Note" />}
        classes="modal-alert"
        iconCloseDlg
        content={
          <p>
            <I18n lng="Form.AreYouSureYouWantToExit" />
          </p>
        }
        primaryActionTitle={<I18n lng="global.Yes" />}
        secondryActionTitle={<I18n lng="global.No" />}
        secondaryAction={() => setAlertDialog(false)}
        primaryAction={() => {
          clearForManagementAnswers(dispatchFormId, {
            status: "assign",
            formId: id,
          }).then(() => {
            formManagementById(dispatchFormId);
            onHide();
            setAlertDialog(false);
          });
        }}
        primaryActionClasses={"btn-success"}
        secondryActionClasses={"btn-danger"}
      />
    </>
  );
};

const mapStateToProps = ({ form, authentication, adminManagerReports }) => ({
  user: authentication.user,
  formToEdit: form.formToEdit,
  currentRole: authentication.currentRole,
  formManagementToEdit: form.formManagementToEdit,
  reportForEdit: adminManagerReports.reportForEdit,
});

const mapDispatchToProps = {
  getForm,
  formManagementById,
  clearForManagementAnswers,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormView);
