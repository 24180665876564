import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import "../../assets/css/auth.min.css";
import { useForm } from "react-hook-form";
import IconEye from "../../assets/images/icon-eye.svg";
import I18n, { i18n } from "../private/languageSelector";
// import IconLock from "../../assets/images/icon-lock.svg";
import { useParams, useHistory } from "react-router-dom";
// import IconEmail from "../../assets/images/icon-envelope.svg";
import Input from "../../components/form-input/input.component";
import PhoneNumber from "../../components/form-input/phone-number.component";
import {
  setupAccount,
  tokenCheckApi,
} from "../../redux/reducers/authentication/authentication";

const SetupAccount = ({ setupAccount, tokenCheckApi }) => {
  const history = useHistory();
  const { token } = useParams();

  const [showPassword, setShowPassword] = useState(false);
  const [setupAccoutError, setSetupAccoutError] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  useEffect(() => {
    if (token) {
      tokenCheckApi({ token, type: "" })
        .then(() => {
          setSetupAccoutError(false);
        })
        .catch((err) => {
          setSetupAccoutError(true);
        });
    } // eslint-disable-next-line
  }, [token]);

  const onSubmit = (data) => {
    let exsistingDevice = localStorage.getItem("deviceId");
    let deviceId =
      (
        Date.now().toString(36) + Math.random().toString(36).substr(2, 5)
      ).toUpperCase() + Math.random().toString(36).substr(2, 9);
    if (data && deviceId && token && exsistingDevice) {
      setupAccount(
        { ...data, deviceId: exsistingDevice, isInvited: false },
        token
      )
        .then(() => {
          localStorage.setItem("deviceId", exsistingDevice);
          toast.success(<I18n lng="validation.DeviceAddedSuccessfully" />);
          history.push("/login");
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    } else {
      setupAccount({ ...data, deviceId: deviceId, isInvited: false }, token)
        .then(() => {
          localStorage.setItem("deviceId", deviceId);
          toast.success(<I18n lng="validation.DeviceAddedSuccessfully" />);
          history.push("/login");
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    }
  };

  return (
    <>
      {setupAccoutError ? (
        <div style={{ color: "red" }} className="text-center mt-5">
          <strong>
            <I18n lng="global.DeviceAlreadyRegistered" />
          </strong>
        </div>
      ) : (
        <div className="auth-wrapper">
          <div className="auth-title">
            <h1>
              <I18n lng="SetupAccount.SetupYourAccountForTAMAM" />
            </h1>
          </div>
          <form autoComplete="false" onSubmit={handleSubmit(onSubmit)}>
            <Input
              label={<I18n lng="SetupAccount.DeviceName" />}
              // prefix={IconEmail}
              id="deviceName"
              name="deviceName"
              type="text"
              placeholder={i18n("DeviceRegister.EnterDeviceName")}
              register={register}
              validations={{
                required: {
                  value: true,
                  message: i18n("validation.REQUIRED_FIELD"),
                },
              }}
              error={errors["deviceName"]}
            />
            <div
              className={`input-password ${
                !showPassword ? "hide-password" : ""
              }`}
            >
              <Input
                label={<I18n lng="global.Password" />}
                // prefix={IconLock}
                suffix={IconEye}
                suffixAction={() => {
                  setShowPassword(!showPassword);
                }}
                id="passwordInput"
                name="password"
                type={showPassword ? "text" : "password"}
                placeholder={i18n("global.Password")}
                register={register}
                validations={{
                  required: {
                    value: true,
                    message: i18n("validation.REQUIRED_FIELD"),
                  },
                }}
                error={errors["password"]}
              />
            </div>
            <PhoneNumber
              label={<I18n lng="global.Mobile" />}
              name={"mobile"}
              id={"mobileInput"}
              control={control}
              register={register}
              validations={{
                required: {
                  value: true,
                  message: i18n("validation.REQUIRED_FIELD"),
                },
              }}
              error={errors["mobile"]}
            />
            <div className="btn-wrap text-center">
              <button type="submit" className="btn btn-primary">
                {<I18n lng="global.Submit" />}
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

const mapStateToProps = ({ authentication }) => ({
  isAuthenticated: authentication.isAuthenticated,
});

const mapDispatchToProps = { setupAccount, tokenCheckApi };
export default connect(mapStateToProps, mapDispatchToProps)(SetupAccount);
