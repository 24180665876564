import React from "react";
import { connect } from "react-redux";
import ArchivePage from "./archive/archive-page";
import { Route, Switch } from "react-router-dom";
import GlobalListPage from "./global-list/global-list-page";
import FormManagementPage from "./form-management/form-management-page";
import { updateRoute } from "../../../redux/reducers/authentication/authentication";
import SubscriptionExpiredRedirect from "../../../components/private-route/SubscriptionExpiredRedirect";

const FormPage = ({ currentRole  }) => {
  return (
    <Switch>
      <Route path="/forms/form-management">
        {({ history, match }) => (
          <SubscriptionExpiredRedirect
            component={FormManagementPage}
            history={history}
          />
        )}
      </Route>

      {currentRole !== "user" ? (
        <>
          <Route path="/forms/archive" component={ArchivePage} />
          <Route path="/forms/global-list" component={GlobalListPage} />
        </>
      ) : null}
    </Switch>
  );
};

const mapStateToProps = ({ authentication,subscription  }) => ({
  currentRole: authentication.currentRole,
});

const mapDispatchToProps = { updateRoute };

export default connect(mapStateToProps, mapDispatchToProps)(FormPage);
