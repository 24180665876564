import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import IconCloseDialog from "../../assets/images/icon-close-dialog.svg";

const AlertDialog = ({
  showDlg,
  iconCloseDlg,
  secondaryAction,
  content,
  title,
  classes,
  size,
}) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(showDlg);
  }, [showDlg]);

  return (
    <Modal
      isOpen={show}
      toggle={secondaryAction}
      size={size}
      className={classes}
    >
      {iconCloseDlg && (
        <Link
          to="#"
          onClick={secondaryAction}
          className="modal-close px-3 py-2"
        >
          <img src={IconCloseDialog} alt="IconCloseDialog"></img>
        </Link>
      )}
      <ModalBody>
        <div className="modal-title text-center">
          <strong>{title}</strong>
        </div>
        {content}
      </ModalBody>
    </Modal>
  );
};

export default AlertDialog;
