import React, { useLayoutEffect, useState } from "react";
import "tui-image-editor/dist/tui-image-editor.css";
import { whiteTheme } from "../../config/white-theme";
import ImageEditor from "@toast-ui/react-image-editor";
import I18n from "../../pages/private/languageSelector";
import { Modal, ModalBody, ModalFooter } from "reactstrap";

const ImageEditorDialog = ({
  show,
  onHide,
  toEdit,
  onEdit,
  imageDeleteHandler,
  imageIndex,
  openEditImageEditor,
  imagesToShow = undefined,
  setImagesToShow,
  loadingForImage,
}) => {
  let editorRef = React.createRef();

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    });
  }, []);

  const handleClickButton = (event) => {
    let els = document.getElementsByTagName("canvas");

    for (let i = 0; i++; i < els.length) {
      if (i !== 0) {
        els[i].width = 0;
        els[i].height = 0;
        const ctx = els[i]?.getContext("2d");
        ctx && ctx.clearRect(0, 0, 0, 0);
        els.remove();
      }
    }

    // let elss = document.getElementsByTagName("canvas");
    let editorInstance = editorRef.current.getInstance();
    // onEdit(editorInstance.toDataURL());
    onEdit(editorInstance.toDataURL({ format: "jpeg", quality: 0.9 }));
    event.currentTarget.disabled = true;
    let elss = document.getElementsByTagName("canvas");
    for (let i = 0; i++; i < elss.length) {
      if (i !== 0) {
        elss[i].width = 0;
        elss[i].height = 0;
        const ctx = elss[i]?.getContext("2d");
        ctx && ctx.clearRect(0, 0, 0, 0);
        elss[i].remove();
      }
    }

    // let elss = document.getElementsByTagName("canvas");

    onHide();
  };
  return (
    <Modal isOpen={show} toggle={() => onHide()} className="image-editor">
      <ModalBody>
        {loadingForImage ? (
          <div className="mt-4">
            <div className="text-center">
              <div class="spinner-border text-info" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <p>
                <b>Please wait i am fetching you new selected image</b>
              </p>
            </div>
          </div>
        ) : null}
        <ImageEditor
          ref={editorRef}
          id="image=custom-editor"
          includeUI={{
            loadImage: {
              path: toEdit ? toEdit : null,
              name: "ImageToEdit",
            },
            theme: whiteTheme,
            // uiSize: {
            //   width: "1000px",
            //   height: "700px",
            // },
            // menuBarPosition: "bottom",
          }}
          cssMaxHeight={screenHeight ? screenHeight - 200 : 500}
          cssMaxWidth={screenWidth ? screenWidth - 20 : 700}
          selectionStyle={{
            cornerSize: 20,
            rotatingPointOffset: 70,
          }}
          usageStatistics={true}
        />
        <ModalFooter>
          <button
            className="btn btn-white"
            onClick={() => {
              onHide();
            }}
          >
            <I18n lng="global.Close" />
          </button>
          <button className="btn btn-primary" onClick={handleClickButton}>
            <I18n lng="global.Save" />
          </button>
          {openEditImageEditor ? (
            <button
              className="btn btn-danger"
              onClick={() => {
                if (imagesToShow !== undefined) {
                  imagesToShow.splice(imageIndex, 1);
                  setImagesToShow([...imagesToShow]);
                }

                imageDeleteHandler(imageIndex);
                onHide();
              }}
            >
              <I18n lng="global.Remove" />
            </button>
          ) : null}
        </ModalFooter>
      </ModalBody>
    </Modal>
  );
};

export default ImageEditorDialog;
