import React, { useEffect } from "react";
import Select from "react-select";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import I18n, { i18n } from "../../../languageSelector";
import DialogModal from "../../../../../components/dialogs/compact-dialog";
import { getAllRoles } from "../../../../../redux/reducers/roles/roles.reducer";

const statusList = [
  { name: "Enable", value: "enable" },
  { name: "Disable", value: "disable" },
  { name: "Pending", value: "pending" },
];

const FilterDialog = ({
  show,
  primaryAction,
  secondaryAction,
  filterState,
  setFilterState,
  clearActions,
  secondryActionTitle = "Clear",
  roles,
  getAllRoles,
}) => {
  useEffect(() => {
    getAllRoles(10);
    // eslint-disable-next-line
  }, []);

  return (
    <DialogModal
      size="md"
      showDlg={show}
      classes={"modal-filter"}
      content={
        <>
          <h3 className="text-center">
            <I18n lng="global.Filters" />
          </h3>
          <ul className="list-unstyled">
            <li>
              <label className="select-label">
                <I18n lng="Company.Role" />
              </label>
              <Select
                placeholder={<I18n lng="global.Select" />}
                className="filter-select"
                value={filterState.role}
                onChange={(option) => {
                  setFilterState({
                    ...filterState,
                    role: option,
                  });
                }}
                options={
                  roles && roles.length
                    ? roles.map((role) => {
                        return {
                          value: role.id,
                          label:
                            role?.name === "owner"
                              ? i18n("Roles.owner")
                              : role?.name === "manager"
                              ? i18n("Roles.manager")
                              : role?.name === "inspector"
                              ? i18n("Roles.inspector")
                              : role?.name,
                        };
                      })
                    : []
                }
              />
            </li>
            <li>
              <label className="select-label">
                <I18n lng="global.Status" />
              </label>
              <Select
                placeholder={<I18n lng="global.Select" />}
                className="filter-select"
                value={filterState.status}
                onChange={(option) => {
                  if (option.value === "enable") {
                    setFilterState({
                      ...filterState,
                      isEmailVerified: true,
                      active: true,
                    });
                  } else if (option.value === "disable") {
                    setFilterState({
                      ...filterState,
                      active: false,
                      isEmailVerified: "",
                    });
                  } else if (option.value === "pending") {
                    setFilterState({
                      ...filterState,
                      active: true,
                      isEmailVerified: false,
                    });
                  }
                }}
                options={
                  statusList && statusList.length
                    ? statusList.map((role) => {
                        return {
                          value: role.value,
                          label:
                            role.name === "Enable"
                              ? i18n("global.Enable")
                              : role.name === "Disable"
                              ? i18n("global.Disable")
                              : role.name === "Pending"
                              ? i18n("global.Pending")
                              : role?.name,
                        };
                      })
                    : []
                }
              />
            </li>
          </ul>
        </>
      }
      primaryActionTitle={<I18n lng="global.Apply" />}
      secondryActionTitle={secondryActionTitle}
      iconCloseDlg={true}
      clearActions={() => {
        clearActions();
      }}
      secondaryAction={() => {
        secondaryAction();
      }}
      primaryAction={() => primaryAction()}
    />
  );
};

const mapStateToProps = ({ roles }) => ({
  roles: roles.roles,
});

const mapDispatchToProps = { getAllRoles };

export default connect(mapStateToProps, mapDispatchToProps)(FilterDialog);
