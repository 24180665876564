import React, { useState, useEffect } from "react";
import { TEXT_DIRECTION } from "../../../../../helper/helperFunctions";
import I18n from "../../../languageSelector";
import ReportPdfImages from "../report-pdf-images/reportPdfImages.js";

const ImagePdfPreview = ({
  getAnswer = (uuid) => {
    console.log(uuid);
    return undefined;
  },
  question,
  failStatus,
  hideAndShow,
  setImageIndex,
  setShowImageSrc,
  setShowImageDialog,
  setShowSection,
  setTotalAnswered,
  showDocumentMedia,
  imageCountData,
}) => {
  const [answerValue, setAnswerValue] = useState([]);
  const [note, setNote] = useState("");
  const [image, setImage] = useState("");

  useEffect(() => {
    if (question && question.questionUuid) {
      let answer = getAnswer(question.questionUuid);
      if (answer) {
        setAnswerValue([...answer.inspectorImages]);
        setNote(answer.note);
        setImage(answer.image);

        if (answer?.inspectorImages || answer?.note || answer?.image) {
          setShowSection(true);
          setTotalAnswered((prev) => prev + 1);
        }
      }
    }
    // eslint-disable-next-line
  }, [question]);

  const [imageCount, setImageCount] = useState(0);

  useEffect(() => {
    if (question && question.questionUuid && imageCountData?.length) {
      let data = imageCountData.filter((item) => {
        return item.questionId === question.questionUuid;
      });
      if (data.length) {
        setImageCount(data[0].imageCountStart);
      }
    }
  }, [question, imageCountData]);
  return (
    <>
      {!failStatus ? (
        hideAndShow?.content?.all ? (
          <div className="table-body">
            <div
              className="row table-row ml-0 mr-0   question status-na"
              style={{
                boxShadow: "0px 5px 5px rgba(0,0,0,.10)",
                backgroundColor: "#ffffff",
              }}
            >
              <div
                className={`col-lg-8 col-md-6 mb-3 table-column d-flex ${
                  TEXT_DIRECTION() === "rtl" ? "pr-0" : "pl-0"
                }`}
              >
                <span
                  className={`text-muted ${
                    TEXT_DIRECTION() === "rtl" ? "ml-2" : "mr-2"
                  }`}
                >
                  {question?.questionNumber}.
                </span>
                {"   "}
                {question?.settings?.questionText === "Add a new question" ? (
                  <I18n lng="Form.AddANewQuestion" />
                ) : (
                  question?.settings?.questionText
                )}
              </div>
              <div className="col-lg-4 col-md-6 table-column question-type">
                <div className="thumbnail-wrapper">
                  <ReportPdfImages
                    photo={answerValue}
                    imageCount={imageCount}
                    setImageIndex={setImageIndex}
                    setShowImageSrc={setShowImageSrc}
                    showDocumentMedia={showDocumentMedia}
                    setShowImageDialog={setShowImageDialog}
                  />
                </div>
                {note || image.length ? (
                  <div
                    className="table-column attachments-wrapper mt-3 pb-3"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <>
                      <fieldset>
                        <legend
                          className={`${
                            TEXT_DIRECTION() === "rtl"
                              ? "legend-arabic"
                              : "legend"
                          }`}
                        >
                          <I18n lng="global.InspectorNotes" />
                        </legend>
                        {note ? (
                          <div className="notes">
                            <p>{note}</p>
                          </div>
                        ) : null}
                        <div className="thumbnail-wrapper">
                          {image ? (
                            <span className="thumbnail">
                              <span
                                className="img-wrap"
                                onClick={() => {
                                  setImageIndex(0);
                                  setShowImageSrc([image]);
                                  setShowImageDialog(true);
                                }}
                              >
                                {!showDocumentMedia ? (
                                  <img
                                    src={image}
                                    alt="Thumbnail"
                                    id={`image-id`}
                                    onLoad={({ target: img }) => {
                                      let style =
                                        img.naturalWidth > img.naturalHeight
                                          ? img.naturalHeight
                                          : img.naturalWidth;
                                      let documents;
                                      documents =
                                        document.getElementById(`image-id`);
                                      documents.setAttribute(
                                        "height",
                                        style + "px"
                                      );
                                      documents.setAttribute(
                                        "width",
                                        style + "px"
                                      );
                                    }}
                                  />
                                ) : (
                                  <a href={image}>
                                    <img
                                      src={image}
                                      alt="Thumbnail"
                                      id={`image-id`}
                                      onLoad={({ target: img }) => {
                                        let style =
                                          img.naturalWidth > img.naturalHeight
                                            ? img.naturalHeight
                                            : img.naturalWidth;
                                        let documents;
                                        documents =
                                          document.getElementById(`image-id`);
                                        documents.setAttribute(
                                          "height",
                                          style + "px"
                                        );
                                        documents.setAttribute(
                                          "width",
                                          style + "px"
                                        );
                                      }}
                                    />
                                  </a>
                                )}
                              </span>
                            </span>
                          ) : null}
                        </div>
                      </fieldset>
                    </>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        ) : hideAndShow?.content?.answered &&
          (answerValue?.length || note || image) ? (
          <div className="table-body">
            <div
              className="row table-row ml-0 mr-0   question status-na"
              style={{
                boxShadow: "0px 5px 5px rgba(0,0,0,.10)",
                backgroundColor: "#ffffff",
              }}
            >
              <div
                className={`col-lg-8 col-md-6 mb-3 table-column d-flex ${
                  TEXT_DIRECTION() === "rtl" ? "pr-0" : "pl-0"
                }`}
              >
                <span
                  className={`text-muted ${
                    TEXT_DIRECTION() === "rtl" ? "ml-2" : "mr-2"
                  }`}
                >
                  {question?.questionNumber}.
                </span>
                {"   "}
                {question?.settings?.questionText === "Add a new question" ? (
                  <I18n lng="Form.AddANewQuestion" />
                ) : (
                  question?.settings?.questionText
                )}
              </div>
              <div className="col-lg-4 col-md-6 table-column question-type">
                <div className="thumbnail-wrapper">
                  <ReportPdfImages
                    photo={answerValue}
                    imageCount={imageCount}
                    setImageIndex={setImageIndex}
                    setShowImageSrc={setShowImageSrc}
                    showDocumentMedia={showDocumentMedia}
                    setShowImageDialog={setShowImageDialog}
                  />
                </div>
                {note || image.length ? (
                  <div
                    className="table-column attachments-wrapper mt-3 pb-3"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <>
                      <fieldset>
                        <legend
                          className={`${
                            TEXT_DIRECTION() === "rtl"
                              ? "legend-arabic"
                              : "legend"
                          }`}
                        >
                          <I18n lng="global.InspectorNotes" />
                        </legend>
                        {note ? (
                          <div className="notes">
                            <p>{note}</p>
                          </div>
                        ) : null}
                        <div className="thumbnail-wrapper">
                          {image ? (
                            <span className="thumbnail">
                              <span
                                className="img-wrap"
                                onClick={() => {
                                  setImageIndex(0);
                                  setShowImageSrc([image]);
                                  setShowImageDialog(true);
                                }}
                              >
                                {!showDocumentMedia ? (
                                  <img
                                    src={image}
                                    alt="Thumbnail"
                                    id={`image-id`}
                                    onLoad={({ target: img }) => {
                                      let style =
                                        img.naturalWidth > img.naturalHeight
                                          ? img.naturalHeight
                                          : img.naturalWidth;
                                      let documents;
                                      documents =
                                        document.getElementById(`image-id`);
                                      documents.setAttribute(
                                        "height",
                                        style + "px"
                                      );
                                      documents.setAttribute(
                                        "width",
                                        style + "px"
                                      );
                                    }}
                                  />
                                ) : (
                                  <a href={image}>
                                    <img
                                      src={image}
                                      alt="Thumbnail"
                                      id={`image-id`}
                                      onLoad={({ target: img }) => {
                                        let style =
                                          img.naturalWidth > img.naturalHeight
                                            ? img.naturalHeight
                                            : img.naturalWidth;
                                        let documents;
                                        documents =
                                          document.getElementById(`image-id`);
                                        documents.setAttribute(
                                          "height",
                                          style + "px"
                                        );
                                        documents.setAttribute(
                                          "width",
                                          style + "px"
                                        );
                                      }}
                                    />
                                  </a>
                                )}
                              </span>
                            </span>
                          ) : null}
                        </div>
                      </fieldset>
                    </>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        ) : hideAndShow?.content?.unAnswered &&
          !answerValue?.length &&
          !note &&
          !image ? (
          <div className="table-body">
            <div
              className="row table-row ml-0 mr-0   question status-na"
              style={{
                boxShadow: "0px 5px 5px rgba(0,0,0,.10)",
                backgroundColor: "#ffffff",
              }}
            >
              <div
                className={`col-lg-8 col-md-6 mb-3 table-column d-flex ${
                  TEXT_DIRECTION() === "rtl" ? "pr-0" : "pl-0"
                }`}
              >
                <span
                  className={`text-muted ${
                    TEXT_DIRECTION() === "rtl" ? "ml-2" : "mr-2"
                  }`}
                >
                  {question?.questionNumber}.
                </span>
                {"   "}
                {question?.settings?.questionText === "Add a new question" ? (
                  <I18n lng="Form.AddANewQuestion" />
                ) : (
                  question?.settings?.questionText
                )}
              </div>
              <div className="table-column col-lg-4 col-md-6 question-type">
                <div className="thumbnail-wrapper">
                  <ReportPdfImages
                    photo={answerValue}
                    imageCount={imageCount}
                    setImageIndex={setImageIndex}
                    setShowImageSrc={setShowImageSrc}
                    showDocumentMedia={showDocumentMedia}
                    setShowImageDialog={setShowImageDialog}
                  />
                </div>
                {note || image.length ? (
                  <div
                    className="table-column attachments-wrapper mt-3 pb-3"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <>
                      <fieldset>
                        <legend
                          className={`${
                            TEXT_DIRECTION() === "rtl"
                              ? "legend-arabic"
                              : "legend"
                          }`}
                        >
                          <I18n lng="global.InspectorNotes" />
                        </legend>
                        {note ? (
                          <div className="notes">
                            <p>{note}</p>
                          </div>
                        ) : null}
                        <div className="thumbnail-wrapper">
                          {image ? (
                            <span className="thumbnail">
                              <span
                                className="img-wrap"
                                onClick={() => {
                                  setImageIndex(0);
                                  setShowImageSrc([image]);
                                  setShowImageDialog(true);
                                }}
                              >
                                {!showDocumentMedia ? (
                                  <img
                                    src={image}
                                    alt="Thumbnail"
                                    id={`image-id`}
                                    onLoad={({ target: img }) => {
                                      let style =
                                        img.naturalWidth > img.naturalHeight
                                          ? img.naturalHeight
                                          : img.naturalWidth;
                                      let documents;
                                      documents =
                                        document.getElementById(`image-id`);
                                      documents.setAttribute(
                                        "height",
                                        style + "px"
                                      );
                                      documents.setAttribute(
                                        "width",
                                        style + "px"
                                      );
                                    }}
                                  />
                                ) : (
                                  <a href={image}>
                                    <img
                                      src={image}
                                      alt="Thumbnail"
                                      id={`image-id`}
                                      onLoad={({ target: img }) => {
                                        let style =
                                          img.naturalWidth > img.naturalHeight
                                            ? img.naturalHeight
                                            : img.naturalWidth;
                                        let documents;
                                        documents =
                                          document.getElementById(`image-id`);
                                        documents.setAttribute(
                                          "height",
                                          style + "px"
                                        );
                                        documents.setAttribute(
                                          "width",
                                          style + "px"
                                        );
                                      }}
                                    />
                                  </a>
                                )}
                              </span>
                            </span>
                          ) : null}
                        </div>
                      </fieldset>
                    </>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        ) : null
      ) : null}
    </>
  );
};
export default ImagePdfPreview;
