import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import GlobalListListing from "./global-list-list";
import I18n from "../../../private/languageSelector";
import GlobaListAddEdit from "./global-list-add-edit";
import { GlobalListUIProvider } from "./global-list-ui-context";
import DialogModal from "../../../../components/dialogs/compact-dialog";
import {
  getGlobalList,
  getGlobalLists,
  postGlobalList,
  deleteGlobalList,
} from "../../../../redux/reducers/global-list/global-list.reducer";

const GlobalListPage = ({
  history,
  postGlobalList,
  getGlobalLists,
  deleteGlobalList,
  getGlobalList,
}) => {
  const globalListUIEvents = {
    openGlobalListNew: () => {
      history.push(`/forms/global-list/new`);
    },
    openGlobalListEdit: (id) => {
      history.push(`/forms/global-list/${id}/edit`);
    },
    openGlobalListDelete: (id) => {
      history.push(`/forms/global-list/${id}/delete`);
    },
    openGlobalListDuplicate: (id) => {
      history.push(`/forms/global-list/${id}/duplicate`);
    },
  };

  const deleteList = (id) => {
    deleteGlobalList(id).then(() => {
      getGlobalLists();
      history.push("/forms/global-list/");
    });
  };

  const duplicateList = (id) => {
    getGlobalList(id).then((response) => {
      postGlobalList({
        title:
          response.value.data.title +
          " - " +
          moment(new Date()).format("MM/DD/YYYY-hh:mm:ss"),
        list: response.value.data.list,
      }).then(() => {
        getGlobalLists();
        history.push("/forms/global-list/");
      });
    });
  };

  return (
    <GlobalListUIProvider globalListUIEvents={globalListUIEvents}>
      <Switch>
        <Route path="/forms/global-list/new">
          {({ history, match }) => <GlobaListAddEdit />}
        </Route>
        <Route path="/forms/global-list/:id/edit">
          {({ history, match }) => <GlobaListAddEdit />}
        </Route>
        <Route path="/forms/global-list">
          <Switch>
            <Route path="/forms/global-list/:id/delete">
              {({ history, match }) => (
                <>
                  <DialogModal
                    showDlg={match != null}
                    title={<I18n lng="global.Note" />}
                    classes="modal-alert"
                    iconCloseDlg
                    content={
                      <p>
                        <I18n lng="global.DeleteList" />
                      </p>
                    }
                    primaryActionTitle={<I18n lng="global.Delete" />}
                    secondaryAction={() => history.push("/forms/global-list/")}
                    primaryAction={deleteList}
                    primaryActionClasses={"btn-danger"}
                  />
                </>
              )}
            </Route>
            <Route path="/forms/global-list/:id/duplicate">
              {({ history, match }) => (
                <>
                  <DialogModal
                    showDlg={match != null}
                    title={<I18n lng="global.Note" />}
                    classes="modal-alert"
                    iconCloseDlg
                    content={
                      <p>
                        <I18n lng="global.AreYouSuteToDuplicateList" />
                      </p>
                    }
                    primaryActionTitle={<I18n lng="global.Duplicate" />}
                    secondaryAction={() => history.push("/forms/global-list/")}
                    primaryAction={duplicateList}
                  />
                </>
              )}
            </Route>
          </Switch>
          <GlobalListListing />
        </Route>
      </Switch>
    </GlobalListUIProvider>
  );
};
const mapDispatchToProps = {
  postGlobalList,
  getGlobalLists,
  deleteGlobalList,
  getGlobalList,
};
export default connect(null, mapDispatchToProps)(GlobalListPage);
