import React, { useState, useEffect } from "react";
import I18n from "../../../languageSelector";
import ReportImages from "../report-photo-component/report-images";
import IconArrowDown from "../../../../../assets/images/arrow-down.svg";

const SliderReportPreview = ({
  addUpdateAnwser = (answer) => {
    console.log(answer);
  },
  getAnswer = (uuid) => {
    console.log(uuid);
  },
  getUpdatedAnswer = (answer) => console.log(answer),
  getOldAnswer = (answer) => console.log(answer),
  setShowQuestionPreview,
  question,
  reportHistory,
  index,
  showAndHide,
  setImageIndex,
  setShowImageSrc,
  setShowImageDialog,
  setShowSection,
  setTotalAnswered,
  imageCountData,
}) => {
  const [note, setNote] = useState("");
  const [photo, setPhoto] = useState("");
  const [answerValue, setAnswerValue] = useState("");
  const [oldAnswerValue, setOldAnswerValue] = useState("");
  const [updatedAnswerValue, setUpdatedAnswerValue] = useState("");
  const [oldNote, setOldNote] = useState("");
  const [updatedNote, setUpdatedNote] = useState("");
  const [oldPhoto, setOldPhoto] = useState("");
  const [updatedPhoto, setUpdatedPhoto] = useState("");

  useEffect(() => {
    if (question && question.questionUuid) {
      let answer = getAnswer(question.questionUuid);
      if (answer) {
        setAnswerValue(answer.sliderValue);
        setPhoto(answer.image);
        setNote(answer.note);
        if (answer?.sliderValue || answer?.image || answer?.note) {
          setShowSection(true);
          setTotalAnswered((prev) => prev + 1);
        }
      }
      let oldAnswer = getOldAnswer(question.questionUuid);
      if (oldAnswer) {
        setOldAnswerValue(oldAnswer.sliderValue);
        setOldPhoto(oldAnswer.image);
        setOldNote(oldAnswer.note);
      }
      let updatedAnswer = getUpdatedAnswer(question.questionUuid);
      if (updatedAnswer) {
        setUpdatedAnswerValue(updatedAnswer.sliderValue);
        setUpdatedPhoto(updatedAnswer.image);
        setUpdatedNote(updatedAnswer.note);
      }
      let isTextChanged = false;
      let isImagesChanged = false;
      let isNotesChanged = false;

      if (oldAnswer?.sliderValue === updatedAnswer?.sliderValue) {
        isTextChanged = false;
      } else {
        isTextChanged = true;
      }
      if (oldAnswer?.note === updatedAnswer?.note) {
        isNotesChanged = false;
      } else {
        isNotesChanged = true;
      }
      if (oldAnswer?.image.length === updatedAnswer?.image.length) {
        let show = false;
        oldAnswer?.image.forEach((oldImageItem, index) => {
          if (oldImageItem.image !== updatedAnswer.image[index].image) {
            show = true;
          }
        });
        if (show) {
          isImagesChanged = true;
        }
      } else {
        isImagesChanged = true;
      }
      if (!isTextChanged && !isImagesChanged && !isNotesChanged) {
        setShowQuestionPreview(false);
      }
    }
    // eslint-disable-next-line
  }, [question]);

  const [imageCount, setImageCount] = useState(0);
  useEffect(() => {
    if (question && question.questionUuid && imageCountData?.length) {
      let data = imageCountData.filter((item) => {
        return item.questionId === question.questionUuid;
      });
      if (data.length) {
        setImageCount(data[0].imageCountStart);
      }
    }
  }, [question, imageCountData]);

  return (
    <li className="card ">
      {/* <span className="index-wrap">3</span> */}
      {reportHistory ? (
        updatedAnswerValue || oldAnswerValue ? (
          <div className="list-item-detail">
            <p className="mb-1">
              <I18n lng="Form.Question" /> # {question?.questionNumber}
            </p>
            <p className="mt-0 question">
              {question?.settings?.questionText === "Add a new question" ? (
                <I18n lng="Form.AddANewQuestion" />
              ) : (
                question?.settings?.questionText
              )}
            </p>
            <p className="pAnswer">
              <I18n lng="global.PreviousAnswer" />
            </p>
            <div className="previous-Answer">
              <p className="answer">{`${oldAnswerValue} (Range ${question?.settings.min} - ${question.settings.max})`}</p>
              {oldNote && (
                <div className="notes">
                  <label>
                    <I18n lng="global.Notes" />:
                  </label>
                  <p>{oldNote}</p>
                </div>
              )}
              {oldPhoto.length ? (
                <div className="thumbnail-wrapper">
                  <ReportImages
                    photo={oldPhoto}
                    setImageIndex={setImageIndex}
                    setShowImageSrc={setShowImageSrc}
                    setShowImageDialog={setShowImageDialog}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="text-center mt-2">
              <img src={IconArrowDown} alt="loading" />
            </div>
            <p className="uAnswer mt-3">
              <I18n lng="global.UpdatedAnswer" />
            </p>
            <div className="updated-Answer mb-4">
              <p className="answer">{`${updatedAnswerValue} (Range ${question?.settings.min} - ${question.settings.max})`}</p>
              {updatedNote && (
                <div className="notes">
                  <label>
                    <I18n lng="global.Notes" />:
                  </label>
                  <p>{updatedNote}</p>
                </div>
              )}

              {updatedPhoto.length ? (
                <div className="thumbnail-wrapper">
                  <ReportImages
                    photo={updatedPhoto}
                    setImageIndex={setImageIndex}
                    setShowImageSrc={setShowImageSrc}
                    setShowImageDialog={setShowImageDialog}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : null
      ) : showAndHide?.content?.all ? (
        <div className="list-item-detail">
          <p className="mt-0 mb-1">
            <I18n lng="Form.Question" /> # {question?.questionNumber}
          </p>
          <p className="question">
            {question?.settings?.questionText === "Add a new question" ? (
              <I18n lng="Form.AddANewQuestion" />
            ) : (
              question?.settings?.questionText
            )}
          </p>
          <p className="answer">{`${answerValue} (Range ${question?.settings.min} - ${question.settings.max})`}</p>
          {note && (
            <div className="notes">
              <label>
                <I18n lng="global.Notes" />:
              </label>
              <p>{note}</p>
            </div>
          )}
          <div className="thumbnail-wrapper">
            <ReportImages
              photo={photo}
              imageCount={imageCount}
              setImageIndex={setImageIndex}
              setShowImageSrc={setShowImageSrc}
              setShowImageDialog={setShowImageDialog}
            />
          </div>
        </div>
      ) : showAndHide?.content?.answered &&
        (answerValue || note || photo?.length) ? (
        <div className="list-item-detail">
          <p className="question">
            {question?.settings?.questionText === "Add a new question" ? (
              <I18n lng="Form.AddANewQuestion" />
            ) : (
              question?.settings?.questionText
            )}
          </p>
          <p className="answer">{`${answerValue} (Range ${question?.settings.min} - ${question.settings.max})`}</p>
          {note && (
            <div className="notes">
              <label>
                <I18n lng="global.Notes" />:
              </label>
              <p>{note}</p>
            </div>
          )}
          <div className="thumbnail-wrapper">
            <ReportImages
              photo={photo}
              imageCount={imageCount}
              setImageIndex={setImageIndex}
              setShowImageSrc={setShowImageSrc}
              setShowImageDialog={setShowImageDialog}
            />
          </div>
        </div>
      ) : showAndHide?.content?.unAnswered &&
        !answerValue &&
        !note &&
        !photo?.length ? (
        <div className="list-item-detail">
          <p className="question">
            {question?.settings?.questionText === "Add a new question" ? (
              <I18n lng="Form.AddANewQuestion" />
            ) : (
              question?.settings?.questionText
            )}
          </p>
          <p className="answer">{`${answerValue} (Range ${question?.settings.min} - ${question.settings.max})`}</p>
          {note && (
            <div className="notes">
              <label>
                <I18n lng="global.Notes" />:
              </label>
              <p>{note}</p>
            </div>
          )}
          <div className="thumbnail-wrapper">
            <ReportImages
              photo={photo}
              imageCount={imageCount}
              setImageIndex={setImageIndex}
              setShowImageSrc={setShowImageSrc}
              setShowImageDialog={setShowImageDialog}
            />
          </div>
        </div>
      ) : null}
    </li>
  );
};
export default SliderReportPreview;
