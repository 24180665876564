import React, { useMemo, useEffect, useState } from "react";
import moment from "moment";
import { v4 as uuid } from "uuid";
import { connect } from "react-redux";
import FormNavbar from "../form-navbar";
import { Link } from "react-router-dom";
import FilterDialog from "./formFilterDialog";
import { useFormsUIContext } from "./form-ui-context";
import ListItem from "./form-list-components/list-item";
import IconSearch from "../../../../assets/images/icon-search.svg";
import IconFilter from "../../../../assets/images/icon-filter.svg";
import IconSortAlpha from "../../../../assets/images/icon-sort-alpha.svg";
import {
  getForm,
  getForms,
  postForm,
  patchForm,
  loadMoreForms,
  loadMoreUserAssignForms,
  getAssignedFormsForUser,
  getBookmark,
  updateFormManagementBookmarkedUnBookmarked,
} from "../../../../redux/reducers/form/form.reducer";
import I18n, { i18n } from "../../../private/languageSelector";
import DialogModal from "../../../../components/dialogs/compact-dialog";
import { getCompanyById } from "../../../../redux/reducers/company/company.reducer";
import { updateRoute } from "../../../../redux/reducers/authentication/authentication";
import { getAllMultipleChoiceQuestions } from "../../../../redux/reducers/multiple-choice-questions/multiple-choice.reducer";
import { FaSortAlphaUp, FaSortAlphaDown, FaSortNumericUpAlt, FaSortNumericDownAlt } from "react-icons/fa";
import { TEXT_DIRECTION, canAccessTheModule } from "../../../../helper/helperFunctions";

const createForm = {
  description: "",
  createdBy: "",
  title: "Untitled Form",
  schema: {
    isScored: false,
    pages: [
      {
        isOpen: true,
        name: "Page",
        pagePlaceHolder: "Page 1",
        pageUuid: uuid(),
        sections: [
          {
            isOpen: true,
            name: "Section",
            sectionPlaceHolder: "Section 1",
            sectionUuid: uuid(),
            questions: [
              {
                name: "Multiple choices",
                type: "COMPLEX_MULTIPLE_CHOICE",
                questionUuid: uuid(),
                questionNumber: 1,
                settings: {
                  answers: [
                    {
                      canAttachPhoto: false,
                      canComment: false,
                      class: "tag-green",
                      isFail: false,
                      name: "Yes",
                      score: 1,
                    },
                    {
                      canAttachPhoto: false,
                      canComment: false,
                      class: "tag-red",
                      isFail: true,
                      name: "No",
                      score: 0,
                    },
                    {
                      canAttachPhoto: false,
                      canComment: false,
                      class: "tag-slate",
                      isFail: false,
                      name: "N/A",
                      score: "-",
                    },
                  ],
                  description: "",
                  isEnabled: true,
                  isMandatory: false,
                  isOpen: true,
                  photo: "",
                  questionText: "Add a new question",
                  questionPlaceHolder: "Add a new question",
                  score: 1,
                  selectionType: "SINGLE",
                },
              },
            ],
          },
        ],
      },
    ],
  },
};

const formFilterInitState = {
  fromDate: "",
  toDate: "",
  inspector: "",
  limit: 10,
  page: 1,
  isArchived: false,
};

const FormListing = ({
  user,
  forms,
  getForm,
  postForm,
  getForms,
  patchForm,
  totalCount,
  currentRole,
  rolesAccess,
  updateRoute,
  getBookmark,
  loadMoreForms,
  getCompanyById,
  loadMoreUserAssignForms,
  getAssignedFormsForUser,
  getAllMultipleChoiceQuestions,
  updateFormManagementBookmarkedUnBookmarked,
}) => {
  const formsUIContext = useFormsUIContext();
  const formsUIProps = useMemo(() => {
    return {
      openEditForm: formsUIContext.openEditForm,
      openArchvieFormDialog: formsUIContext.openArchvieFormDialog,
      openFormView: formsUIContext.openFormView,
      openFormStart: formsUIContext.openFormStart,
      openFormDispatch: formsUIContext.openFormDispatch,
    };
  }, [formsUIContext]);

  const [documentNumber, setDocumentNumber] = useState(0);
  const [loading, setLoading] = useState(false);
  const [bookmarkForm, setBookmarkForm] = useState();
  const [showSortModal, setShowSortModal] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [formFilterState, setFormFilterState] = useState(formFilterInitState);
  const [sortBy, setSortBy] = useState({
    attribute: "updatedAt",
    order: "desc",
  });

  useEffect(() => {
    updateRoute({
      step1: "Forms",
      step2: "Form Management ",
      enableBack: false,
      formScrol: false,
    });
    if (currentRole !== "user") {
      getForms({
        limit: 10,
        page: 1,
        sortBy: "updatedAt:desc",
        isArchived: false,
      });
    }
    if (user && user.role !== "user" && user.company?.id) {
      getCompanyById(user.company?.id).then((res) => setDocumentNumber(res.value.data.documentNumber));
    }
    // eslint-disable-next-line
  }, [user]);

  const createNewForm = () => {
    postForm({
      ...createForm,
      createdBy: user ? user.fullName : "TAMAM",
      title: `${createForm.title} ${documentNumber}`,
    }).then((response) => {
      const { formDetail } = response.value.data;
      getForm(response.value.data.id).then((formRes) => {
        getAllMultipleChoiceQuestions(formRes.value.data.id).then((res) => {
          if (res && res.value.data.length) {
            let mcqV1 = res.value.data.filter((item) => {
              return item.name === "MULTIPLE_CHOICE_RESPONSE_V1";
            });
            if (mcqV1.length) {
              const payload = {
                ...formDetail,
                schema: {
                  ...formDetail.schema,
                  pages: [
                    {
                      ...formDetail?.schema?.pages[0],
                      sections: [
                        {
                          ...formDetail?.schema?.pages[0].sections[0],
                          questions: [
                            {
                              ...mcqV1[0],
                              form: mcqV1[0].form.id ? mcqV1[0].form.id : mcqV1[0].form,
                              questionNumber: 1,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              };
              if (payload) {
                patchForm(formRes.value.data.id, { ...payload }, undefined, undefined).then((res) => {
                  getForm(res.value.data.id).then(() => {
                    localStorage.setItem("formState", "formCreation");
                    formsUIProps.openEditForm(res.value.data.id);
                  });
                });
              }
            }
          }
        });
      });
    });
  };

  const createDuplicateForm = (form) => {
    let { formDetail } = form;
    delete formDetail.createdAt;
    delete formDetail.createdBy;
    delete formDetail.documentNumber;
    postForm({
      ...formDetail,
      title: `${formDetail.title} - ${moment(new Date()).format("MM/DD/YYYY-hh:mm:ss")}`,
    });
  };

  const archiveForm = (form) => {
    patchForm(form.id, undefined, true, undefined);
  };

  // const bookmarkUnbookmarkForm = (form) => {
  //   patchForm(
  //     currentRole === "user" ? form._id : form.id,
  //     undefined,
  //     undefined,
  //     !form.isBookmarked
  //   );
  // };

  const userBookmarkUnbookmarkForm = (form) => {
    updateFormManagementBookmarkedUnBookmarked(form.dispatchedFormId, {
      isBookmarked: !form.dispatchBookmarked,
    });
  };

  const searchSortFilter = (formsList) => {
    const activeForms = formsList?.filter((item) => item?.isArchived === false);
    if (formsList?.length) {
      let bookmarkedForms = activeForms?.filter((item) => (currentRole !== "user" ? item?.isBookmarked === true : item?.dispatchBookmarked === true));
      let unBookmarkedForms = activeForms?.filter((item) =>
        currentRole !== "user" ? item?.isBookmarked === false : item?.dispatchBookmarked === false
      );

      return [...bookmarkedForms, ...unBookmarkedForms];
    } else {
      return [];
    }
  };
  const formFilterHandler = () => {
    if (currentRole !== "user") {
      getForms(formFilterState).then(() => {
        setShowFilterModal(false);
      });
    } else {
      getAssignedFormsForUser({
        fromDate: formFilterState.fromDate,
        toDate: formFilterState.toDate,
        limit: 10,
        page: 1,
        sortBy: "formUpdatedAt:desc",
      }).then(() => {
        setShowFilterModal(false);
      });
    }
  };

  const getSortedFormsHandler = (sort, order) => {
    if (currentRole !== "user") {
      getForms({
        limit: 10,
        page: 1,
        sortBy: `${sort}:${order}`,
        isArchived: false,
      });
    } else {
      getAssignedFormsForUser({
        limit: 10,
        page: 1,
        sortBy: `${sort}:${order}`,
      });
    }
  };

  const searchFormsHandler = (text) => {
    if (currentRole !== "user") {
      getForms({
        limit: 10,
        page: 1,
        formName: text,
        isArchived: false,
      });
    } else {
      getAssignedFormsForUser({
        limit: 10,
        page: 1,
        formName: text,
      });
    }
  };

  useEffect(() => {
    if (user) {
      getBookmark(user.id, "FORM").then((res) => {
        setBookmarkForm(res?.value?.data);
      });
    }
    // eslint-disable-next-line
  }, [loading]);
  const moduleId = bookmarkForm?.map((bookmark) => bookmark?.moduleId);
  const userId = bookmarkForm?.map((bookmark) => bookmark?.userId);
  return (
    <>
      <div className="px-3">
        <FormNavbar moduleName={"form-management"} />
        <div className="search-bar">
          <form className="form-search">
            <div className="input-group">
              <div className="input-group-prepend">
                <img src={IconSearch} alt="Search Icon" />
              </div>
              <input className="form-control" placeholder={i18n("global.Search")} onChange={(e) => searchFormsHandler(e.target.value)} />
            </div>
          </form>
          <Link to="#" onClick={() => setShowSortModal(true)}>
            <img src={IconSortAlpha} alt="IconSortAlpha" />
          </Link>
          <Link to="#" onClick={() => setShowFilterModal(true)}>
            <img src={IconFilter} alt="IconFilter" />
          </Link>
        </div>
        {forms?.length ? (
          currentRole === "user" ? (
            <>
              <ul className="form-list forms">
                {searchSortFilter(forms)?.map((form, index) => {
                  // Check if the form's id is in the moduleIds array
                  if (moduleId?.includes(form.id) && userId?.includes(user.id)) {
                    return (
                      <ListItem
                        form={form}
                        key={index}
                        loading={loading}
                        setLoading={setLoading}
                        openEditForm={formsUIProps.openEditForm}
                        openFormView={formsUIProps.openFormView}
                        openFormStart={formsUIProps.openFormStart}
                        openFormDispatch={formsUIProps.openFormDispatch}
                        createDuplicateForm={createDuplicateForm}
                        archiveForm={archiveForm}
                        bookmarkUnbookmarkForm={userBookmarkUnbookmarkForm}
                        openArchvieFormDialog={formsUIProps.openArchvieFormDialog}
                      />
                    );
                  }
                  // Return null for forms that don't match
                  return null;
                })}
                {/* Render the remaining forms */}
                {searchSortFilter(forms)?.map((form, index) => {
                  // Check if the form's id is NOT in the moduleIds array
                  if (!moduleId?.includes(form.id)) {
                    return (
                      <ListItem
                        form={form}
                        key={index}
                        loading={loading}
                        setLoading={setLoading}
                        openEditForm={formsUIProps.openEditForm}
                        openFormView={formsUIProps.openFormView}
                        openFormStart={formsUIProps.openFormStart}
                        openFormDispatch={formsUIProps.openFormDispatch}
                        createDuplicateForm={createDuplicateForm}
                        archiveForm={archiveForm}
                        bookmarkUnbookmarkForm={userBookmarkUnbookmarkForm}
                        openArchvieFormDialog={formsUIProps.openArchvieFormDialog}
                      />
                    );
                  }
                  // Return null for forms that match (already rendered above)
                  return null;
                })}
              </ul>
            </>
          ) : (
            <>
              <ul className="form-list forms">
                {searchSortFilter(forms)?.map((form, index) => {
                  // Check if the form's id is in the moduleIds array
                  if (moduleId?.includes(form.id) && userId?.includes(user.id)) {
                    return (
                      <ListItem
                        form={form}
                        key={index}
                        loading={loading}
                        setLoading={setLoading}
                        openEditForm={formsUIProps.openEditForm}
                        openFormView={formsUIProps.openFormView}
                        openFormStart={formsUIProps.openFormStart}
                        openFormDispatch={formsUIProps.openFormDispatch}
                        createDuplicateForm={createDuplicateForm}
                        archiveForm={archiveForm}
                        bookmarkUnbookmarkForm={userBookmarkUnbookmarkForm}
                        openArchvieFormDialog={formsUIProps.openArchvieFormDialog}
                      />
                    );
                  }
                  // Return null for forms that don't match
                  return null;
                })}
                {/* Render the remaining forms */}
                {searchSortFilter(forms)?.map((form, index) => {
                  // Check if the form's id is NOT in the moduleIds array
                  if (!moduleId?.includes(form.id)) {
                    return (
                      <ListItem
                        form={form}
                        key={index}
                        loading={loading}
                        setLoading={setLoading}
                        openEditForm={formsUIProps.openEditForm}
                        openFormView={formsUIProps.openFormView}
                        openFormStart={formsUIProps.openFormStart}
                        openFormDispatch={formsUIProps.openFormDispatch}
                        createDuplicateForm={createDuplicateForm}
                        archiveForm={archiveForm}
                        bookmarkUnbookmarkForm={userBookmarkUnbookmarkForm}
                        openArchvieFormDialog={formsUIProps.openArchvieFormDialog}
                      />
                    );
                  }
                  // Return null for forms that match (already rendered above)
                  return null;
                })}
              </ul>
            </>
          )
        ) : null}

        {canAccessTheModule(
          rolesAccess?.form?.access?.create,
          <span
            onClick={(e) => {
              e.preventDefault();
              createNewForm();
            }}
            className={`btn btn-rounded btn-add-new ${TEXT_DIRECTION() === "rtl" ? "left-direction" : "right-direction"}`}
          >
            {i18n("global.NewForm")}
          </span>
        )}
        {forms && forms.length < totalCount && (
          <div className="d-flex justify-content-center">
            <button
              className="btn btn-primary"
              onClick={() => {
                if (currentRole === "user") {
                  loadMoreUserAssignForms({
                    limit: formFilterState.limit,
                    page: formFilterState.page + 1,
                    sortBy: `${sortBy.attribute}:${sortBy.order}`,
                  });
                } else {
                  loadMoreForms({
                    limit: formFilterState.limit,
                    page: formFilterState.page + 1,
                    sortBy: `${sortBy.attribute}:${sortBy.order}`,
                    isArchived: false,
                  });
                }
              }}
            >
              <I18n lng="Form.LoadMore" />
            </button>
          </div>
        )}
      </div>

      <DialogModal
        showDlg={showSortModal}
        classes="modal-sort"
        content={
          <ul className="list-unstyled">
            <li
              onClick={() => {
                if (sortBy.attribute === "formDetail.title") {
                  if (sortBy.order === "asc") {
                    getSortedFormsHandler("formDetail.title", "desc");
                    setSortBy({ attribute: "formDetail.title", order: "desc" });
                  } else {
                    getSortedFormsHandler("formDetail.title", "asc");
                    setSortBy({ attribute: "formDetail.title", order: "asc" });
                  }
                } else {
                  getSortedFormsHandler("formDetail.title", "asc");
                  setSortBy({ attribute: "formDetail.title", order: "asc" });
                }
              }}
            >
              <span className="icon-wrap">
                {sortBy.attribute === "formDetail.title" ? sortBy.order === "asc" ? <FaSortAlphaDown /> : <FaSortAlphaUp /> : null}
              </span>
              <strong>
                <I18n lng="global.FormName" />
              </strong>
            </li>
            <li
              onClick={() => {
                if (sortBy.attribute === "createdAt") {
                  if (sortBy.order === "asc") {
                    getSortedFormsHandler("createdAt", "desc");
                    setSortBy({ attribute: "createdAt", order: "desc" });
                  } else {
                    getSortedFormsHandler("createdAt", "asc");
                    setSortBy({ attribute: "createdAt", order: "asc" });
                  }
                } else {
                  getSortedFormsHandler("createdAt", "asc");
                  setSortBy({ attribute: "createdAt", order: "asc" });
                }
              }}
            >
              <span className="icon-wrap">
                {sortBy.attribute === "createdAt" ? sortBy.order === "asc" ? <FaSortNumericDownAlt /> : <FaSortNumericUpAlt /> : null}
              </span>
              <strong>
                <I18n lng="global.DateCreated" />
              </strong>
            </li>
            <li
              onClick={() => {
                if (currentRole !== "user") {
                  if (sortBy.order === "desc") {
                    getSortedFormsHandler("updatedAt", "asc");
                    setSortBy({ attribute: "updatedAt", order: "asc" });
                  } else {
                    getSortedFormsHandler("updatedAt", "desc");
                    setSortBy({ attribute: "updatedAt", order: "desc" });
                  }
                } else {
                  if (sortBy.order === "desc") {
                    getSortedFormsHandler("formUpdatedAt", "asc");
                    setSortBy({ attribute: "formUpdatedAt", order: "asc" });
                  } else {
                    getSortedFormsHandler("formUpdatedAt", "desc");
                    setSortBy({ attribute: "formUpdatedAt", order: "desc" });
                  }
                }
              }}
            >
              <span className="icon-wrap">
                {sortBy.attribute === "updatedAt" || sortBy.attribute === "formUpdatedAt" ? (
                  sortBy.order === "asc" ? (
                    <FaSortNumericDownAlt />
                  ) : (
                    <FaSortNumericUpAlt />
                  )
                ) : null}
              </span>
              <strong>
                <I18n lng="global.DateModified" />
              </strong>
            </li>
          </ul>
        }
        primaryActionTitle={<I18n lng="global.Apply" />}
        secondaryAction={() => setShowSortModal(false)}
        primaryAction={() => setShowSortModal(false)}
      />
      <FilterDialog
        show={showFilterModal}
        clearActions={() => {
          if (currentRole !== "user") {
            getForms({
              limit: 10,
              page: 1,
              sortBy: "updatedAt:desc",
              isArchived: false,
            });
            setFormFilterState({ ...formFilterInitState });
          } else {
            getAssignedFormsForUser({
              limit: 10,
              page: 1,
              sortBy: "formUpdatedAt:desc",
            });
            setFormFilterState({ ...formFilterInitState });
          }
        }}
        secondryActionTitle={<I18n lng="global.Clear" />}
        primaryAction={formFilterHandler}
        secondaryAction={() => setShowFilterModal(false)}
        filterState={formFilterState}
        setFilterState={setFormFilterState}
        filterInitState={formFilterInitState}
        formfilterState={true}
      />
    </>
  );
};

const mapStateToProps = ({ form, authentication, multipleChoiceQuestions }) => ({
  forms: form.forms,
  totalCount: form.totalCount,
  rolesAccess: authentication.userRoleAccess,
  user: authentication.user,
  currentRole: authentication.currentRole,
  allMultipleChoiceQuestions: multipleChoiceQuestions.allMultipleChoiceQuestions,
});

const mapDispatchToProps = {
  getForm,
  postForm,
  getForms,
  patchForm,
  updateRoute,
  loadMoreForms,
  getCompanyById,
  loadMoreUserAssignForms,
  getBookmark,
  getAssignedFormsForUser,
  getAllMultipleChoiceQuestions,
  updateFormManagementBookmarkedUnBookmarked,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormListing);
