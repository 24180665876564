// import { Route, Switch } from "react-router-dom";
import ArchiveReportsView from "./archieve-reports-view";
const ArchiveReportsPage = () => {
  return (
    <>
      {/* <Switch>
        <Route path="/reports">
          {({ history, match }) => (
            <MyReportDetail
              showDlg={match != null}
              secondaryAction={() => {
                history.push("/reports ");
              }}
            />
          )}
        </Route>
      </Switch> */}
      <ArchiveReportsView />
    </>
  );
};
export default ArchiveReportsPage;
