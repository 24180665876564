import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import I18n, { i18n } from "../../pages/private/languageSelector";
import { useHistory } from "react-router-dom";
import { useState } from "react";

const PlanDialog = ({
  cancelSubscription,
  setShowDetailsDialog,
  StartFreeTrialHandler,
  showModal,
  onClose,
  modalTitle,
  text_one,
  text_two,
  primaryButtonLabel,
  primaryButtonColor,
  secondaryButtonLabel,
  secondaryButtonColor,
  handleModalAction,
  setShowCardDialog,
  isInput,
  isPaymentCardAdded,
  setShowPricingPlanModal,

  // Title Related
  titleFontWeight,
  titleFontSize,
  titleWidth,
  titleHeight,
  titleColor,
  titleLineHeight,

  // secondary Button
  secondaryButtonWidth,
  secondaryButtonHeight,
  secondaryButtonFontWeight,
  secondaryButtonFontSize,
  secondaryButtonLineHeight,

  // primary Button
  primaryButtonWidth,
  primaryButtonHeight,
  primaryButtonFontWeight,
  primaryButtonFontSize,
  primaryButtonLineHeight,
}) => {
  const history = useHistory();

  const [inputValue, setInputValue] = useState("");
  const [inputError, setInputError] = useState(false);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    setInputError(false);
  };

  const isSubmitDisabled = primaryButtonLabel === i18n("Subscriptions.Unsubscribe") && inputValue.toLowerCase() !== "unsubscribe";

  const hanldeUpdateSidebarShow = () => {
    if (setShowDetailsDialog) {
      setShowDetailsDialog(true);
    }
    if (setShowPricingPlanModal) {
      setShowPricingPlanModal(true);
    }
    onClose();
  };

  const hanldeConfimrUnSubscription = (text) => {
    if (text === "Unsubscribe") {
      cancelSubscription();
      onClose();
    } else {
      setInputError(true);
    }
  };

  const unSubscriptionColor = inputValue === "Unsubscribe" ? "#20639B" : "#828282";

  return (
    <div>
      <Modal
        isOpen={showModal}
        toggle={() => {
          onClose();
        }}
        className="plan-dialog modal-main"
        size="sm"
        centered
      >
        <ModalHeader
          className="border-0 container-fluid d-flex justify-content-center align-items-center mt-1"
          style={{
            padding: "10px",
            fontWeight: "600",
          }}
        >
          <span
            style={{
              fontWeight: titleFontWeight,
              fontSize: titleFontSize,
              width: titleWidth,
              height: titleHeight,
              color: titleColor,
              lineHeight: titleLineHeight,
            }}
          >
            {modalTitle}
          </span>
        </ModalHeader>
        <ModalBody className="d-flex justify-content-center align-items-center flex-column p-1">
          <p className="text-center my-1" style={{ maxWidth: "240px", fontWeight: "500", fontSize: "16px", color: "#333333" }}>
            {text_one}
          </p>
          {text_two && (
            <p className="text-center my-1" style={{ maxWidth: "220px", fontWeight: "500", fontSize: "16px", color: "#333333" }}>
              {text_two}
            </p>
          )}
          {isInput && (
            <div className="p-2" style={{ width: "90%" }}>
              <input
                type="text"
                className="form-control"
                id="text"
                aria-describedby="text"
                style={{
                  outline: "none",
                  borderColor: "#ced4da",
                  boxShadow: "none",
                }}
                onChange={handleInputChange}
                disabled={isSubmitDisabled}
              />
            </div>
          )}
          {inputError && (
            <span className="d-flex justify-content-start align-items-start mx-3" style={{ color: "red", width: "84%" }}>
              <I18n lng="Subscriptions.PleaseTypeUnsubscribe" />
            </span>
          )}
        </ModalBody>
        <ModalFooter
          className={`cursor-pointer border-0 d-flex ${
            !primaryButtonLabel ? "justify-content-center" : "justify-content-between"
          }  align-items-center p-1 mx-3 mb-2`}
        >
          <p
            style={{
              color: secondaryButtonColor,
              width: secondaryButtonWidth,
              height: secondaryButtonHeight,
              fontSize: secondaryButtonFontSize,
              fontWeight: secondaryButtonFontWeight,
              lineHeight: secondaryButtonLineHeight,
            }}
            onClick={onClose}
          >
            {secondaryButtonLabel}
          </p>
          <p
            onClick={() => {
              if (primaryButtonLabel === i18n("global.UpdatePlan")) {
                hanldeUpdateSidebarShow();
              } else if (primaryButtonLabel === i18n("Subscriptions.Unsubscribe")) {
                cancelSubscription();
              } else if (primaryButtonLabel === i18n("Subscriptions.Submit")) {
                hanldeConfimrUnSubscription(inputValue);
              } else if (primaryButtonLabel === i18n("Subscriptions.Continue")) {
                handleModalAction();
              } else if (primaryButtonLabel === i18n("Subscriptions.CreateNewUser")) {
                history.push(`/management/users/add`);
                onClose();
              } else if (primaryButtonLabel === i18n("Subscriptions.CancelTrialForSubscriptions")) {
                cancelSubscription();
              } else if (primaryButtonLabel === i18n("Subscriptions.RevertToFreePlan")) {
                cancelSubscription();
              } else if (primaryButtonLabel === i18n("Subscriptions.Subscribe")) {
                hanldeUpdateSidebarShow();
                onClose();
              } else if (primaryButtonLabel === i18n("Subscriptions.AddPaymentMethod")) {
                setShowCardDialog(true);
                onClose();
              } else if (primaryButtonLabel === i18n("Subscriptions.ResumesSubscription")) {
                cancelSubscription();
              } else if (isPaymentCardAdded) {
                setShowCardDialog(false);
                onClose();
              } else {
                StartFreeTrialHandler();
              }
            }}
            style={{
              color: primaryButtonColor ? primaryButtonColor : unSubscriptionColor,
              width: primaryButtonWidth,
              height: primaryButtonHeight,
              fontWeight: primaryButtonFontWeight,
              fontSize: primaryButtonFontSize,
              lineHeight: primaryButtonLineHeight,
            }}
            className="cursor-pointer mb-2"
          >
            {primaryButtonLabel}
          </p>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default PlanDialog;
