import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import "../../assets/css/auth.min.css";
import { Storage } from "react-jhipster";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import Activate from "../../assets/images/activate.svg";
import IconLock from "../../assets/images/icon-lock.svg";
import I18n, { i18n } from "../private/languageSelector";
import { REQUIRED_FIELD } from "../../config/validations";
import Input from "../../components/form-input/input.component";
import {
  logout,
  getSession,
} from "../../redux/reducers/authentication/authentication";

const VerifyAccount = ({ getSession, logout, setLaterActivate, user }) => {
  const history = useHistory();

  const [errorMessage, setErrorMessage] = useState({ message: "" });

  const { register, handleSubmit } = useForm();

  const resendCode = () => {
    axios
      .post(`auth/send-verification-email`)
      .then(() => {
        toast.success(i18n("validation.EMAIL_SENT"));
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(i18n("validation.AN_ERROR_OCCURED"));
        }
      });
  };

  const onSubmit = (data) => {
    axios
      .post(`auth/verify-email?token=${data.token}`)
      .then(() => {
        toast.success(<I18n lng="validation.EMAIL_VERIFIED" />);
        getSession();
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          setErrorMessage({ message: <I18n lng="validation.INVALID_CODE" /> });
        } else {
          setErrorMessage({
            message: <I18n lng="validation.AN_ERROR_OCCURED" />,
          });
        }
      });
  };

  const activateAccountLater = () => {
    Storage.local.set("activate", "later");
    setLaterActivate(true);
  };

  return (
    <div className="auth-wrapper">
      <div className="auth-title">
        <h1 className="text-center">{i18n("VerifyAccount.ActivateAccount")}</h1>
        <img src={Activate} alt="Activate" />
        <p className="text-center">
          {i18n("VerifyAccount.WehaveSentAVerificationCodeToYourEmail")}
        </p>
      </div>
      <form autoComplete="false" onSubmit={handleSubmit(onSubmit)}>
        <Input
          label={i18n("VerifyAccount.VerificationCode")}
          prefix={IconLock}
          id="tokenInput"
          name="token"
          type="text"
          placeholder={i18n("VerifyAccount.VerificationCode")}
          register={register}
          onClick={() => setErrorMessage({ message: "" })}
          validations={{
            required: { value: true, message: REQUIRED_FIELD },
          }}
          error={errorMessage}
        />

        <div className="btn-wrap text-center">
          <button type="submit" className="btn btn-primary">
            {i18n("VerifyAccount.Verify")}
          </button>
        </div>
        <p className="auth-redirect justify-content-start">
          <span className="mr-1 ml-1 float-left">
            {i18n("VerifyAccount.DidntReceivedTheCodeYet")}
          </span>
          <strong>
            <Link
              to="/#"
              onClick={(e) => {
                e.preventDefault();
                resendCode();
              }}
            >
              {i18n("VerifyAccount.Resend")}
            </Link>
          </strong>
        </p>
        <p
          className="auth-redirect justify-content-start"
          style={{ fontSize: "15px" }}
        >
          <strong>
            <Link
              to="#"
              onClick={(e) => {
                e.preventDefault();
                activateAccountLater();
                history.push("/");
              }}
            >
              {i18n("VerifyAccount.NoThanksIWillActivateMyAccountLater")}
            </Link>
          </strong>
        </p>
      </form>
    </div>
  );
};
const mapStateToProps = ({ authentication }) => ({
  isAuthenticated: authentication.isAuthenticated,
  user: authentication.user,
});

const mapDispatchToProps = { getSession, logout };
export default connect(mapStateToProps, mapDispatchToProps)(VerifyAccount);
