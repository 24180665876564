import React, { useState, useEffect } from "react";
import { v4 as uuid } from "uuid";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import I18n, { i18n } from "../../../../languageSelector";
import IconPdf from "../../../../../../assets/images/icon-pdf.svg";
import { RESPONSES_TYPES } from "../../../../../../config/constants";
import DynamicModal from "../../../../../../components/dialogs/index";
import IconImage from "../../../../../../assets/images/icon-image.svg";
import IconSlider from "../../../../../../assets/images/icon-slider.svg";
import IconSearch from "../../../../../../assets/images/icon-search.svg";
import IconPencil from "../../../../../../assets/images/icon-pencil.svg";
import IconNumber from "../../../../../../assets/images/icon-number.svg";
import IconPreview from "../../../../../../assets/images/icon-preview.svg";
import GlobalListAddModal from "../../../global-list/global-list-add-modal";
import IconCheckbox from "../../../../../../assets/images/icon-checkbox.svg";
import IconDateTime from "../../../../../../assets/images/icon-datetime.svg";
import IconLocation from "../../../../../../assets/images/icon-location.svg";
import IconSignature from "../../../../../../assets/images/icon-signature.svg";
import IconTextAnswer from "../../../../../../assets/images/icon-text-answer.svg";
import IconInstruction from "../../../../../../assets/images/icon-instruction.svg";
import { getDropDownList } from "../../../../../../redux/reducers/form/form.reducer";
import { patchGlobalList } from "../../../../../../redux/reducers/global-list/global-list.reducer";
import ResponseSettings from "../form-builder-response-settings/form-builder-response.settings";
import {
  getAllMultipleChoiceQuestions,
  createMultipleChoiceQuestion,
  updateMultipleChoiceById,
} from "../../../../../../redux/reducers/multiple-choice-questions/multiple-choice.reducer";
import {
  replacingObjectInArray,
  TEXT_DIRECTION,
} from "../../../../../../helper/helperFunctions";
import { GET_MCQ_NAME } from "../../../../../../helper/formHelperFunctions";

const ResponsePicker = ({
  form,
  showDlg,
  question,
  actionItem,
  currentRole,
  dropDownList,
  updateQuestion,
  getDropDownList,
  patchGlobalList,
  secondaryAction,
  multipleChoicesQuestions,
  updateMultipleChoiceById,
  createMultipleChoiceQuestion,
  getAllMultipleChoiceQuestions,
}) => {
  const [show, setShow] = useState(false);
  const [mcqId, setMcqId] = useState(undefined);
  const [globalList, setGlobalList] = useState([]);
  const [newQuestion, setNewQuestion] = useState(false);
  const [addNewQuestion, setAddNewQuestion] = useState({});
  const [multipleChoiceSearch, setMultipleChoiceSearch] = useState("");
  const [allMultipleChoices, setAllMultipleChoices] = useState([]);
  const [globalListArray, setGlobalListArray] = useState([]);
  const [openGlobalListModal, setOpenGlobalListModal] = useState(false);
  const [showQuestionOptionsDrawer, setShowQuestionOptionsDrawer] =
    useState(false);

  const { id } = useParams();

  useEffect(() => {
    setShow(showDlg);
    setMultipleChoiceSearch("");
  }, [showDlg]);

  useEffect(() => {
    if (currentRole !== "user") {
      setGlobalList([]);
      getDropDownList();
    }
    getAllMultipleChoiceQuestions(id);
    setAllMultipleChoices([]);
    // eslint-disable-next-line
  }, []);
  const questionAddHandler = () => {
    createMultipleChoiceQuestion({
      ...RESPONSES_TYPES.MULTIPLE_CHOICE_RESPONSE_V7,
      settings: {
        ...RESPONSES_TYPES.MULTIPLE_CHOICE_RESPONSE_V7.settings,
        questionText: question?.settings?.questionText,
        questionPlaceHolder: question?.settings?.questionPlaceHolder,
        score: question?.settings?.score,
      },
      name: "CUSTOM_MULTIPLE_CHOICE_RESPONSE",
      form: id,
      questionUuid: uuid(),
    }).then((res) => {
      if (res.value.data.id) {
        setMcqId(res.value.data.id);
        setAddNewQuestion({
          ...res.value.data,
          questionUuid: uuid(),
        });
        setNewQuestion(true);
        setShowQuestionOptionsDrawer(true);
      }
      getAllMultipleChoiceQuestions(id);
    });
  };

  useEffect(() => {
    if (dropDownList.length) {
      dropDownList.forEach((item) => {
        globalList.push({
          ...RESPONSES_TYPES.DROP_DOWN_LIST,
          questionUuid: uuid(),
          id: item.id,
          settings: {
            ...RESPONSES_TYPES.DROP_DOWN_LIST.settings,
            options: [...item.list],
            listName: item.title,
            dropDownOpen: false,
          },
        });
      });
      setGlobalList([...globalList]);
    }
    // eslint-disable-next-line
  }, [dropDownList]);

  useEffect(() => {
    if (globalList.length) {
      globalList.forEach((item) => {
        item.settings = {
          ...item.settings,
          dropDownOpen: false,
        };
      });
    }

    setGlobalList([...globalList]);
    // eslint-disable-next-line
  }, [show]);

  const dropDownOpenHandler = (id) => {
    if (globalListArray.length && id !== null) {
      let indexof = globalListArray.findIndex((list) => {
        return list.id === id;
      });

      globalListArray[indexof].settings.dropDownOpen =
        !globalListArray[indexof].settings.dropDownOpen;

      globalListArray.forEach((item, index) => {
        if (index !== indexof) {
          item.settings.dropDownOpen = false;
        }
      });
    }
    setGlobalListArray([...globalListArray]);
  };

  const hideDropDownList = (target) => {
    if (target.className !== "dropdownListOpen") {
      globalListArray.forEach((item) => {
        item.settings = {
          ...item.settings,
          dropDownOpen: false,
        };
      });
    }
    setGlobalListArray([...globalListArray]);
  };

  useEffect(() => {
    if (multipleChoicesQuestions && multipleChoicesQuestions.length) {
      let sortedByDate = multipleChoicesQuestions.sort(
        (a, b) => Date.parse(a?.updatedAt) - Date.parse(b?.updatedAt)
      );
      setAllMultipleChoices([...sortedByDate]);
    } else {
      setAllMultipleChoices([]);
    }
  }, [multipleChoicesQuestions]);

  useEffect(() => {
    if (dropDownList?.length && globalList?.length) {
      setGlobalListArray(replacingObjectInArray(dropDownList, globalList));
    }
  }, [dropDownList, globalList]);

  const updateMultipleChoiceHandler = (item) => {
    updateMultipleChoiceById(item.id, {
      ...item,
      updatedAt: new Date(),
    }).then(() => {
      getAllMultipleChoiceQuestions(id);
    });
  };

  const getLastQuestionScore = (questionType) => {
    let questions = [];
    let lastQuestionScore = 1;
    if (actionItem.isScored && form?.schema?.pages?.length) {
      form?.schema?.pages?.map((item) => {
        return item?.sections?.forEach((secItem) => {
          return secItem?.questions?.filter((queItem) => {
            return queItem.type === questionType && questions.push(queItem);
          });
        });
      });
    }
    if (questions.length) {
      lastQuestionScore = questions[questions.length - 1].settings.score;
    }
    return lastQuestionScore;
  };

  return (
    <>
      <DynamicModal
        customClasses={`modal-drawer response-picker ${
          TEXT_DIRECTION() === "rtl" ? "left-direction" : ""
        }`}
        show={show}
        primaryAction={() => console.log("primary called")}
        secondaryAction={secondaryAction}
      >
        <div
          className="responses-list"
          onClick={(e) => hideDropDownList(e.target)}
        >
          <div className="responses-wrapper">
            <form className="form-search">
              <div className="input-group mb-1 mt-1">
                <div className="input-group-prepend">
                  <img src={IconSearch} alt="Search Icon" />
                </div>
                <input
                  className="form-control"
                  placeholder={i18n("global.Search")}
                  onChange={(e) => setMultipleChoiceSearch(e.target.value)}
                />
              </div>
            </form>
            <label className="label-title">
              <label className="label-title ml-0">
                <I18n lng="Form.MultipleChoiceResponses" />
              </label>
              <Link to="#" onClick={questionAddHandler}>
                <span>+</span> <I18n lng="Form.AddNew" />
              </Link>
            </label>
            <ul
              className={`list-unstyled ${
                TEXT_DIRECTION() === "rtl" ? "p-0" : ""
              }`}
            >
              {allMultipleChoices && allMultipleChoices.length
                ? allMultipleChoices
                    .filter((item) =>
                      item.settings.answers.find((subItem) =>
                        subItem.name
                          .toLowerCase()
                          .includes(multipleChoiceSearch?.toLowerCase())
                      )
                    )
                    .reverse()
                    .map((item, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          updateMultipleChoiceHandler(item);
                          updateQuestion(
                            actionItem.pageNo,
                            actionItem.sectionNo,
                            actionItem.questionNo,
                            {
                              ...item,
                              questionUuid: uuid(),
                              settings: {
                                ...item.settings,
                                answers: [...item.settings.answers],
                                questionText: question.settings.questionText,
                              },
                            }
                          );
                          secondaryAction();
                        }}
                      >
                        <div
                          className={`tags-wrapper cursor-pointer ${
                            TEXT_DIRECTION() === "rtl" ? "rotate-image" : ""
                          }`}
                        >
                          {item.settings.answers.map((subItem, index) => (
                            <span
                              key={index}
                              className={`tag ${subItem.class} ${
                                TEXT_DIRECTION() === "rtl" ? "rotate-image" : ""
                              }`}
                            >
                              {GET_MCQ_NAME(subItem.name)}
                            </span>
                          ))}
                        </div>

                        <Link
                          to="#"
                          className="icon icon-pencil"
                          onClick={(e) => {
                            e.preventDefault();
                            setNewQuestion(false);
                            setMcqId(item.id);
                            setShowQuestionOptionsDrawer(true);
                          }}
                        >
                          <img src={IconPencil} alt="IconPencil" />
                        </Link>
                      </li>
                    ))
                : null}
            </ul>

            <label className="label-title ">
              <I18n lng="Form.GlobalLists" />
              <Link
                to="#"
                onClick={(e) => {
                  e.preventDefault(e);
                  setOpenGlobalListModal(true);
                }}
              >
                <span>+</span> <I18n lng="Form.NewList" />
              </Link>
            </label>
            {globalListArray?.length
              ? globalListArray
                  .filter((item) =>
                    item?.title
                      .toLowerCase()
                      .includes(multipleChoiceSearch?.toLowerCase())
                  )
                  .sort(
                    (a, b) =>
                      Date.parse(a?.createdAt) - Date.parse(b?.createdAt)
                  )
                  .map((item, index) => (
                    <ul
                      key={index}
                      className={`dropdown global-list list-unstyled w-100 m-0 p-0 ${
                        item.settings.dropDownOpen ? "show" : ""
                      }`}
                    >
                      {
                        <li className="dropdown-toggler d-flex justify-content-between">
                          <label
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              patchGlobalList(item.id, {
                                updatedAt: new Date(),
                                recentlyUsed: true,
                              });
                              updateQuestion(
                                actionItem.pageNo,
                                actionItem.sectionNo,
                                actionItem.questionNo,
                                {
                                  ...item,
                                  questionUuid: uuid(),
                                  settings: {
                                    ...item.settings,
                                    questionText:
                                      question.settings.questionText,
                                  },
                                }
                              );
                              secondaryAction();
                            }}
                          >
                            {item.settings.listName}
                          </label>
                          <Link
                            to="#"
                            className="icon icon-collapse"
                            onClick={() => {
                              dropDownOpenHandler(item.id);
                            }}
                          >
                            <img
                              className="dropdownListOpen"
                              src={IconPreview}
                              alt="IconCollapseArrow"
                            />
                          </Link>
                        </li>
                      }
                      <div
                        className={`dropdown-menu py-0 ${
                          item.settings.dropDownOpen ? "show" : ""
                        }`}
                      >
                        {item.settings.options?.map((item, index) => {
                          return (
                            <li
                              className="dorpdown-item"
                              key={index}
                              value={item}
                            >
                              {item}
                            </li>
                          );
                        })}
                      </div>
                    </ul>
                  ))
              : null}
          </div>
        </div>

        <hr className="m-0"></hr>
        <div className="global-lists-wrapper ">
          <ul>
            <li>
              <Link
                to="#"
                onClick={() => {
                  secondaryAction();
                  updateQuestion(
                    actionItem.pageNo,
                    actionItem.sectionNo,
                    actionItem.questionNo,
                    {
                      ...RESPONSES_TYPES.TEXT,
                      questionUuid: uuid(),
                      settings: {
                        ...RESPONSES_TYPES.TEXT.settings,
                        questionText: question.settings.questionText,
                      },
                    }
                  );
                }}
              >
                <span className="icon-wrap">
                  <img src={IconTextAnswer} alt="IconTextAnswer" />
                </span>
                <I18n lng="Form.TextAnswer" />
              </Link>
            </li>
            <li>
              <Link
                to="#"
                onClick={() => {
                  secondaryAction();
                  updateQuestion(
                    actionItem.pageNo,
                    actionItem.sectionNo,
                    actionItem.questionNo,
                    {
                      ...RESPONSES_TYPES.NUMBER,
                      questionUuid: uuid(),
                      settings: {
                        ...RESPONSES_TYPES.NUMBER.settings,
                        questionText: question?.settings?.questionText,
                      },
                    }
                  );
                }}
              >
                <span className="icon-wrap">
                  <img src={IconNumber} alt="IconNumber" />
                </span>
                <I18n lng="Form.Number" />
              </Link>
            </li>
            <li>
              <Link
                to="#"
                onClick={() => {
                  let lastQuestionScore = getLastQuestionScore("checkbox");

                  secondaryAction();
                  updateQuestion(
                    actionItem.pageNo,
                    actionItem.sectionNo,
                    actionItem.questionNo,
                    {
                      ...RESPONSES_TYPES.CHECKBOX,
                      questionUuid: uuid(),
                      name: `Checkbox${actionItem.questionNo + 1}`,
                      settings: {
                        ...RESPONSES_TYPES.CHECKBOX.settings,
                        score: lastQuestionScore,
                        questionText: question.settings.questionText,
                      },
                    }
                  );
                }}
              >
                <span className="icon-wrap">
                  <img src={IconCheckbox} alt="IconCheckBox" />
                </span>
                <I18n lng="Form.CheckBox" />
              </Link>
            </li>
            <li>
              <Link
                to="#"
                onClick={() => {
                  secondaryAction();
                  updateQuestion(
                    actionItem.pageNo,
                    actionItem.sectionNo,
                    actionItem.questionNo,
                    {
                      ...RESPONSES_TYPES.DATE_AND_TIME,
                      questionUuid: uuid(),
                      settings: {
                        ...RESPONSES_TYPES.DATE_AND_TIME.settings,
                        questionText: question.settings.questionText,
                      },
                    }
                  );
                }}
              >
                <span className="icon-wrap">
                  <img src={IconDateTime} alt="IconDateTime" />
                </span>
                <I18n lng="Form.Date&Time" />
              </Link>
            </li>
            <li>
              <Link
                to="#"
                onClick={() => {
                  secondaryAction();
                  updateQuestion(
                    actionItem.pageNo,
                    actionItem.sectionNo,
                    actionItem.questionNo,
                    {
                      ...RESPONSES_TYPES.IMAGE,
                      questionUuid: uuid(),
                      settings: {
                        ...RESPONSES_TYPES.IMAGE.settings,
                        questionText: question.settings.questionText,
                      },
                    }
                  );
                }}
              >
                <span className="icon-wrap">
                  <img src={IconImage} alt="IconImage" />
                </span>
                <I18n lng="Form.Image" />
              </Link>
            </li>
            <li>
              <Link
                to="#"
                onClick={() => {
                  secondaryAction();
                  updateQuestion(
                    actionItem.pageNo,
                    actionItem.sectionNo,
                    actionItem.questionNo,
                    {
                      ...RESPONSES_TYPES.PDF,
                      questionUuid: uuid(),
                      settings: {
                        ...RESPONSES_TYPES.PDF.settings,
                        questionText: question.settings.questionText,
                      },
                    }
                  );
                }}
              >
                <span className="icon-wrap">
                  <img src={IconPdf} alt="IconPdf" />
                </span>
                <I18n lng="Form.PDF" />
              </Link>
            </li>
            <li>
              <Link
                to="#"
                onClick={() => {
                  let lastQuestionScore = getLastQuestionScore("slider");

                  secondaryAction();
                  updateQuestion(
                    actionItem.pageNo,
                    actionItem.sectionNo,
                    actionItem.questionNo,
                    {
                      ...RESPONSES_TYPES.SLIDER,
                      questionUuid: uuid(),
                      settings: {
                        ...RESPONSES_TYPES.SLIDER.settings,
                        score: lastQuestionScore,
                        questionText: question.settings.questionText,
                      },
                    }
                  );
                }}
              >
                <span className="icon-wrap">
                  <img src={IconSlider} alt="IconSlider" />
                </span>
                <I18n lng="Form.Slider" />
              </Link>
            </li>
            <li>
              <Link
                to="#"
                onClick={() => {
                  secondaryAction();
                  updateQuestion(
                    actionItem.pageNo,
                    actionItem.sectionNo,
                    actionItem.questionNo,
                    {
                      ...RESPONSES_TYPES.INSTRUCTIONS,
                      questionUuid: uuid(),
                      settings: {
                        ...RESPONSES_TYPES.INSTRUCTIONS.settings,
                        questionText: question.settings.questionText,
                      },
                    }
                  );
                }}
              >
                <span className="icon-wrap">
                  <img src={IconInstruction} alt="IconInstruction" />
                </span>
                <I18n lng="Form.Instruction" />
              </Link>
            </li>
            <li>
              <Link
                to="#"
                onClick={() => {
                  secondaryAction();
                  updateQuestion(
                    actionItem.pageNo,
                    actionItem.sectionNo,
                    actionItem.questionNo,
                    {
                      ...RESPONSES_TYPES.LOCATION,
                      questionUuid: uuid(),
                      settings: {
                        ...RESPONSES_TYPES.LOCATION.settings,
                        questionText: question.settings.questionText,
                      },
                    }
                  );
                }}
              >
                <span className="icon-wrap">
                  <img src={IconLocation} alt="IconLocation" />
                </span>
                <I18n lng="Form.Location" />
              </Link>
            </li>
            <li>
              <Link
                to="#"
                onClick={() => {
                  secondaryAction();
                  updateQuestion(
                    actionItem.pageNo,
                    actionItem.sectionNo,
                    actionItem.questionNo,
                    {
                      ...RESPONSES_TYPES.SIGNATURE,
                      questionUuid: uuid(),
                      settings: {
                        ...RESPONSES_TYPES.SIGNATURE.settings,
                        questionText: question.settings.questionText,
                      },
                    }
                  );
                }}
              >
                <span className="icon-wrap">
                  <img src={IconSignature} alt="IconSignature" />
                </span>
                <I18n lng="Form.Signature" />
              </Link>
            </li>
          </ul>
        </div>
      </DynamicModal>
      <ResponseSettings
        showDlg={showQuestionOptionsDrawer}
        secondaryAction={() => setShowQuestionOptionsDrawer(false)}
        updateQuestion={updateQuestion}
        actionItem={actionItem}
        question={newQuestion ? addNewQuestion : question}
        newQuestion={newQuestion ? true : false}
        mcqId={mcqId}
        form={form}
      />
      <GlobalListAddModal
        question={question}
        actionItem={actionItem}
        updateQuestion={updateQuestion}
        show={openGlobalListModal}
        secondaryAction={() => setOpenGlobalListModal(false)}
      />
    </>
  );
};

const mapStateToProps = ({
  form,
  authentication,
  multipleChoiceQuestions,
}) => ({
  dropDownList: form.dropDownList,
  currentRole: authentication.currentRole,
  multipleChoicesQuestions: multipleChoiceQuestions.allMultipleChoiceQuestions,
});

const mapDispatchToProps = {
  getDropDownList,
  patchGlobalList,
  updateMultipleChoiceById,
  createMultipleChoiceQuestion,
  getAllMultipleChoiceQuestions,
};
export default connect(mapStateToProps, mapDispatchToProps)(ResponsePicker);
