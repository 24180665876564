import axios from "axios";
import { REQUEST, SUCCESS, FAILURE } from "../action-type.util";

export const ACTION_TYPES = {
  POST_FORM: "form/POST_FORM",
  PATCH_FORM: "form/PATCH_FORM",
  DELETE_FORM: "form/DELETE_FORM",
  GET_FORM: "form/GET_FORM",
  GET_FORMS: "form/GET_FORMS",
  LOAD_MORE_FORMS: "form/LOAD_MORE_FORMS",
  GET_ASSIGNED_FORMS: "form/GET_ASSIGNED_FORMS",
  LOAD_MORE_ASSIGN_FORMS: "form/LOAD_MORE_ASSIGN_FORMS",
  RESET: "user/RESET",
  UPLOAD_IMAGE: "UPLOAD_IMAGE",
  UPLOAD_IMAGE_THUMBNAIL: "UPLOAD_IMAGE_THUMBNAIL",
  DROPDOWN_LIST: "DROPDOWN/List",
  ASSIGN_FORM_INSPECTOR: "ASSIGN/FORM/INSPECTOR",
  GET_ASSIGN_FORM_BY_ID: "GET/ASSIGN/FORM/BY/ID",
  DELETE_ASSIGN_USERS: "DELETE/ASSIGN/USERS",
  ANSWER_FILLING: "ANSWER/FILLING",
  GET_FORM_ANSWERS: "GET/FORM/ANSWERS",
  UPDATE_FORM_BY_ID: "UPDATE/FORM/BY/ID",
  UPDATE_FORM_MANAGEMENT_BY_ID: "UPDATE/FORM/MANAGEMENT/BY/ID",
  CLEAR_FORM_ANSWERS: "CLEAR/FORM/MANAGEMENT/ANSWERS",
  BOOKMARKED_UNBOOKMARKED: "PATCH/BOOKMARKED_UNBOOKMARKED",
  GET_FORMMANAGEMENT_BY_ID: "GET/FORMANAGEMENT/BY",
  PATCH_FORMMANAGEMENT_ISREAD: "PATCH/FORMANAGEMENT/ISREAD",
  DELETE_DISPATCHRULE: "DELETE/DISPATCHRULE",
  SAVE_FORM_ID: "SAVE_FORM_ID",
  STORE_OLD_FORM: "STORE_OLD_FORM",
  SELF_FORMMANAGEMENT: "SELF_FORMMANAGEMENT",
  SELF_BOOKMARKED: "SELF_BOOKMARKED",
  GET_SELF_BOOKMARKED: "GET_SELF_BOOKMARKED",
  PATCH_SELF_BOOKMARKED: "PATCH_SELF_BOOKMARKED",
};

const initialState = {
  loading: false,
  forms: [],
  totalCount: 0,
  dropDownList: [],
  formToEdit: undefined,
  error: undefined,
  assignUsers: [],
  formAnswers: [],
  formManagementToEdit: {},
  formIdForNotification: undefined,
  storedOldForm: undefined,
};

export const FormState = initialState;

// Reducer
// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.POST_FORM):
    case REQUEST(ACTION_TYPES.GET_FORM):
    case REQUEST(ACTION_TYPES.LOAD_MORE_FORMS):
    case REQUEST(ACTION_TYPES.LOAD_MORE_ASSIGN_FORMS):
      return {
        ...state,
        error: undefined,
        loading: true,
        formAnswers: undefined,
      };
    case REQUEST(ACTION_TYPES.GET_FORMS):
    case REQUEST(ACTION_TYPES.SELF_FORMMANAGEMENT):
    case REQUEST(ACTION_TYPES.SELF_BOOKMARKED):
    case REQUEST(ACTION_TYPES.GET_SELF_BOOKMARKED):
    case REQUEST(ACTION_TYPES.PATCH_SELF_BOOKMARKED):
    case REQUEST(ACTION_TYPES.GET_ASSIGNED_FORMS):
    case REQUEST(ACTION_TYPES.DROPDOWN_LIST):
    case REQUEST(ACTION_TYPES.ASSIGN_FORM_INSPECTOR):
    case REQUEST(ACTION_TYPES.GET_ASSIGN_FORM_BY_ID):
    case REQUEST(ACTION_TYPES.UPDATE_FORM_BY_ID):
    case REQUEST(ACTION_TYPES.DELETE_ASSIGN_USERS):
    case REQUEST(ACTION_TYPES.UPDATE_FORM_MANAGEMENT_BY_ID):
    case REQUEST(ACTION_TYPES.UPLOAD_IMAGE):
    case REQUEST(ACTION_TYPES.UPLOAD_IMAGE_THUMBNAIL):
    case REQUEST(ACTION_TYPES.CLEAR_FORM_ANSWERS):
    case REQUEST(ACTION_TYPES.GET_FORM_ANSWERS):
    case REQUEST(ACTION_TYPES.DELETE_FORM):
    case REQUEST(ACTION_TYPES.PATCH_FORM):
    case REQUEST(ACTION_TYPES.BOOKMARKED_UNBOOKMARKED):
    case REQUEST(ACTION_TYPES.SAVE_FORM_ID):
    case REQUEST(ACTION_TYPES.PATCH_FORMMANAGEMENT_ISREAD):
      return {
        ...state,
        error: undefined,
        loading: true,
        formAnswers: undefined,
      };
    case REQUEST(ACTION_TYPES.GET_FORMMANAGEMENT_BY_ID):
      return {
        ...state,
        error: undefined,
        loading: true,
        formAnswers: undefined,
      };
    case REQUEST(ACTION_TYPES.ANSWER_FILLING):
      return {
        ...state,
        error: undefined,
        loading: true,
        formAnswers: undefined,
      };
    case FAILURE(ACTION_TYPES.POST_FORM):
    case FAILURE(ACTION_TYPES.SELF_FORMMANAGEMENT):
    case FAILURE(ACTION_TYPES.SELF_BOOKMARKED):
    case FAILURE(ACTION_TYPES.GET_SELF_BOOKMARKED):
    case FAILURE(ACTION_TYPES.PATCH_SELF_BOOKMARKED):
    case FAILURE(ACTION_TYPES.PATCH_FORM):
    case FAILURE(ACTION_TYPES.GET_FORM):
    case FAILURE(ACTION_TYPES.UPLOAD_IMAGE):
    case FAILURE(ACTION_TYPES.UPLOAD_IMAGE_THUMBNAIL):
    case FAILURE(ACTION_TYPES.DROPDOWN_LIST):
    case FAILURE(ACTION_TYPES.GET_FORMS):
    case FAILURE(ACTION_TYPES.LOAD_MORE_FORMS):
    case FAILURE(ACTION_TYPES.LOAD_MORE_ASSIGN_FORMS):
    case FAILURE(ACTION_TYPES.ASSIGN_FORM_INSPECTOR):
    case FAILURE(ACTION_TYPES.UPDATE_FORM_MANAGEMENT_BY_ID):
    case FAILURE(ACTION_TYPES.UPDATE_FORM_BY_ID):
    case FAILURE(ACTION_TYPES.GET_FORM_ANSWERS):
    case FAILURE(ACTION_TYPES.GET_ASSIGN_FORM_BY_ID):
    case FAILURE(ACTION_TYPES.GET_ASSIGNED_FORMS):
    case FAILURE(ACTION_TYPES.DELETE_ASSIGN_USERS):
    case FAILURE(ACTION_TYPES.DELETE_FORM):
    case FAILURE(ACTION_TYPES.BOOKMARKED_UNBOOKMARKED):
    case FAILURE(ACTION_TYPES.SAVE_FORM_ID):
    case FAILURE(ACTION_TYPES.PATCH_FORMMANAGEMENT_ISREAD):
      return {
        ...state,
        error: undefined,
        loading: false,
        formAnswers: undefined,
      };
    case FAILURE(ACTION_TYPES.GET_FORMMANAGEMENT_BY_ID):
      return {
        ...state,
        error: undefined,
        loading: false,
        formAnswers: undefined,
        formManagementToEdit: undefined,
      };
    case FAILURE(ACTION_TYPES.ANSWER_FILLING):
      return {
        ...state,
        error: undefined,
        loading: false,
        formAnswers: undefined,
      };
    case SUCCESS(ACTION_TYPES.POST_FORM):
      return {
        ...state,
        loading: false,
        formAnswers: undefined,
        formToEdit: undefined,
        forms: [action.payload.data, ...state.forms],
        totalCount: state.totalCount + 1,
      };
    case SUCCESS(ACTION_TYPES.UPLOAD_IMAGE):
    case SUCCESS(ACTION_TYPES.UPLOAD_IMAGE_THUMBNAIL):
    case SUCCESS(ACTION_TYPES.DELETE_ASSIGN_USERS):
    case SUCCESS(ACTION_TYPES.UPDATE_FORM_BY_ID):
    case SUCCESS(ACTION_TYPES.ANSWER_FILLING):
      return {
        ...state,
        loading: false,
        formAnswers: undefined,
      };
    case SUCCESS(ACTION_TYPES.DELETE_FORM):
      let allFormsData = state.forms.filter((item) => item.id !== action.payload.data.id);
      return {
        ...state,
        loading: false,
        formAnswers: undefined,
        forms: [...allFormsData],
        totalCount: state.totalCount - 1,
      };
    case SUCCESS(ACTION_TYPES.PATCH_FORM):
      let allForms = [...state.forms];
      let oldFormIndex = allForms.findIndex((item) => item.id === action.payload.data.id);
      allForms.splice(oldFormIndex, 1, action.payload.data);
      allForms.sort((a, b) => {
        return new Date(b.updatedAt) - new Date(a.updatedAt);
      });
      return {
        ...state,
        loading: false,
        formAnswers: undefined,
        forms: [...allForms],
      };
    case SUCCESS(ACTION_TYPES.BOOKMARKED_UNBOOKMARKED):
    case SUCCESS(ACTION_TYPES.PATCH_FORMMANAGEMENT_ISREAD):
    case SUCCESS(ACTION_TYPES.UPDATE_FORM_MANAGEMENT_BY_ID):
      let allAssignedForms = [...state.forms];
      let assignedFormIndex = allAssignedForms.findIndex((item) => item.dispatchedFormId === action.payload.data.id);
      allAssignedForms.splice(assignedFormIndex, 1, {
        ...action.payload.data.form,
        dispatchedFormId: action.payload.data.id,
        status: action.payload.data.status,
        dispatchBookmarked: action.payload.data.isBookmarked,
        isRead: action.payload.data.isRead,
        dispatchNumber: action.payload.data.dispatchNumber,
        dispatchDate: action.payload.data.createdAt,
        assigner: action.payload.data.assigner,
      });
      return {
        ...state,
        loading: false,
        forms: [...allAssignedForms],
      };
    case SUCCESS(ACTION_TYPES.GET_FORM):
      return {
        ...state,
        loading: false,
        formToEdit: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.GET_FORMMANAGEMENT_BY_ID):
      return {
        ...state,
        loading: false,
        formManagementToEdit: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DROPDOWN_LIST):
      return {
        ...state,
        dropDownList: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CLEAR_FORM_ANSWERS):
    case SUCCESS(ACTION_TYPES.ASSIGN_FORM_INSPECTOR):
      return { ...state };
    case SUCCESS(ACTION_TYPES.GET_ASSIGN_FORM_BY_ID):
      return {
        ...state,
        assignUsers: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.GET_FORMS):
      return {
        ...state,
        forms: action.payload.data.forms,
        totalCount: action.payload.data.totalCount,
      };
    case SUCCESS(ACTION_TYPES.LOAD_MORE_FORMS):
      return {
        ...state,
        totalCount: action.payload.data.totalCount,
        forms: [...state.forms, ...action.payload.data.forms],
      };
    case SUCCESS(ACTION_TYPES.GET_FORM_ANSWERS):
      return {
        ...state,
        formAnswers: action.payload.data,
      };
    // case ACTION_TYPES.SAVE_FORM_ID:
    //   return {
    //     ...state,
    //     formIdForNotification: action.payload,
    //   };
    case ACTION_TYPES.STORE_OLD_FORM:
      return {
        ...state,
        storedOldForm: action.payload,
      };
    case SUCCESS(ACTION_TYPES.GET_ASSIGNED_FORMS):
      return {
        ...state,
        totalCount: action.payload.data.totalCount,
        forms: action.payload.data.forms.length
          ? action.payload.data.forms.map((item) => {
              return {
                ...item.form,
                dispatchedFormId: item.id,
                status: item.status,
                dispatchBookmarked: item.isBookmarked,
                isRead: item.isRead,
                dispatchNumber: item.dispatchNumber,
                dispatchDate: item.createdAt,
                assigner: item.assigner,
                formUpdatedAt: item?.formUpdatedAt,
              };
            })
          : [],
      };
    case SUCCESS(ACTION_TYPES.LOAD_MORE_ASSIGN_FORMS):
      let formsData = action.payload.data.forms.length
        ? action.payload.data.forms.map((item) => {
            return {
              ...item.form,
              dispatchedFormId: item.id,
              status: item.status,
              dispatchBookmarked: item.isBookmarked,
              isRead: item.isRead,
              dispatchNumber: item.dispatchNumber,
              dispatchDate: item.createdAt,
              assigner: item.assigner,
            };
          })
        : [];
      return {
        ...state,
        forms: [...state.forms, ...formsData],
        totalCount: action.payload.data.totalCount,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

// Actions

export const selfPostFormmanagement = (data) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.SELF_FORMMANAGEMENT,
    payload: axios.post(`/formManagements/self-assignForm`, data),
  });
};

export const createBookmark = (data) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.SELF_BOOKMARKED,
    payload: axios.post(`/bookmarks`, data),
  });
};

export const getBookmark = (userId, module) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.SELF_BOOKMARKED,
    payload: axios.get(`/bookmarks?userId=${userId}&module=${module}`),
  });
};

export const deleteBookmark = (bookmarkId) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.PATCH_SELF_BOOKMARKED,
    payload: axios.delete(`/bookmarks/unbookmark/${bookmarkId}`),
  });
};

export const postForm = (request) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.POST_FORM,
    payload: axios.post(`/forms/`, { formDetail: { ...request } }),
  });
};

export const getForm = (id) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.GET_FORM,
    payload: axios.get(`/forms/${id}`),
  });
};

export const patchForm = (id, request, isArchived, isBookmarked) => async (dispatch) => {
  let payLoad = {};
  if (request !== undefined) {
    payLoad.formDetail = { ...request };
  }

  if (isArchived !== undefined) {
    payLoad.isArchived = isArchived;
  }
  if (isBookmarked !== undefined) {
    payLoad.isBookmarked = isBookmarked;
  }
  return await dispatch({
    type: ACTION_TYPES.PATCH_FORM,
    payload: axios.patch(`/forms/${id}`, { ...payLoad }),
  });
};

export const patchFormForNotification = (id, request) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.PATCH_FORM,
    payload: axios.patch(`/forms/${id}/notification `, request),
  });
};

export const deleteForm = (id) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.DELETE_FORM,
    payload: axios.delete(`/forms/${id}`),
  });
};

export const getForms = (filterState) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.GET_FORMS,
    payload: axios.get(
      `/forms${
        filterState
          ? `?${filterState.fromDate ? `&fromDate=${filterState.fromDate}` : ""}${filterState.toDate ? `&toDate=${filterState.toDate}` : ""}${
              filterState.inspector ? `&inspector=${filterState.inspector.value}` : ""
            }${filterState.limit ? `&limit=${filterState.limit}` : ""}${
              filterState.isArchived === false ? `&isArchived=${filterState.isArchived}` : ""
            }${filterState.isArchived === true ? `&isArchived=${filterState.isArchived}` : ""}${filterState.page ? `&page=${filterState.page}` : ""}${
              filterState.sortBy ? `&sortBy=${filterState.sortBy}` : ""
            }${filterState.orderBy ? `&orderBy=${filterState.orderBy}` : ""}${filterState.formName ? `&formName=${filterState.formName}` : ""}`
          : ""
      }`
    ),
  });
};

export const loadMoreForms = (filterState) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.LOAD_MORE_FORMS,
    payload: axios.get(
      `/forms${
        filterState
          ? `?${filterState.fromDate ? `&fromDate=${filterState.fromDate}` : ""}${filterState.toDate ? `&toDate=${filterState.toDate}` : ""}${
              filterState.inspector ? `&inspector=${filterState.inspector.value}` : ""
            }${filterState.isArchived === false ? `&isArchived=${filterState.isArchived}` : ""}${
              filterState.isArchived === true ? `&isArchived=${filterState.isArchived}` : ""
            }${filterState.limit ? `&limit=${filterState.limit}` : ""}${filterState.page ? `&page=${filterState.page}` : ""}${
              filterState.sortBy ? `&sortBy=${filterState.sortBy}` : ""
            }`
          : ""
      }`
    ),
  });
};

export const uploadImage = (image) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.UPLOAD_IMAGE,
    payload: axios.post(`/uploads`, image),
  });
};
export const uploadImageWithThumbnail = (image) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.UPLOAD_IMAGE,
    payload: axios.post(`/uploads/thumbnail`, image),
  });
};
export const getDropDownList = () => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.DROPDOWN_LIST,
    payload: axios.get(`/globalLists`),
  });
};
// after form dispatch calls
export const assignFormDispatch = (assignForm) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.ASSIGN_FORM_INSPECTOR,
    payload: axios.post(`/formManagements`, assignForm),
  });
};
export const getAssignUsersByFormId = (id) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.GET_ASSIGN_FORM_BY_ID,
    payload: axios.get(`/formManagements/form/${id}`),
  });
};
export const deleteAssignUser = (formId, userId) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.DELETE_ASSIGN_USERS,
    payload: axios.delete(`formManagements/form/${formId}?assigneeId=${userId}`),
  });
};

export const getAssignedFormsForUser = (filterState) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.GET_ASSIGNED_FORMS,
    payload: axios.get(
      `/formManagements${
        filterState
          ? `?${filterState.fromDate ? `&fromDate=${filterState.fromDate}` : ""}${filterState.toDate ? `&toDate=${filterState.toDate}` : ""}${
              filterState.limit ? `&limit=${filterState.limit}` : ""
            }${filterState.page ? `&page=${filterState.page}` : ""}${filterState.sortBy ? `&sortBy=${filterState.sortBy}` : ""}${
              filterState.formName ? `&formName=${filterState.formName}` : ""
            }`
          : ""
      }`
    ),
  });
};

export const loadMoreUserAssignForms = (filterState) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.LOAD_MORE_ASSIGN_FORMS,
    payload: axios.get(
      `/formManagements${
        filterState
          ? `?${filterState.fromDate ? `&fromDate=${filterState.fromDate}` : ""}${filterState.sortBy ? `&sortBy=${filterState.sortBy}` : ""}${
              filterState.page ? `&page=${filterState.page}` : ""
            }${filterState.toDate ? `&toDate=${filterState.toDate}` : ""}${filterState.limit ? `&limit=${filterState.limit}` : ""}`
          : ""
      }`
    ),
  });
};

export const addUpdateFormAnswer = (formId, dispatchFormId, assignee, status, answer) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.ANSWER_FILLING,
    payload: axios.patch(`/formManagements/${dispatchFormId}`, {
      formId: formId,
      assignee: assignee,
      status: status,
      formAnswerDetail: [...answer],
    }),
  });
};

export const getFormManagementAnswers = (dispatchFormId) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.GET_FORM_ANSWERS,
    payload: axios.get(`/formManagements/${dispatchFormId}`),
  });
};

export const archiveFormManagement = (dispatchFormId, isArchived) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.GET_FORM_ANSWERS,
    payload: axios.patch(`/reports/${dispatchFormId}`, {
      isArchived: isArchived,
    }),
  });
};

export const updateFormById = (id, signature) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.UPDATE_FORM_BY_ID,
    payload: axios.patch(`/forms/${id}`, { signature }),
  });
};

export const updateFormManagementById = (id, site) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.UPDATE_FORM_MANAGEMENT_BY_ID,
    payload: axios.patch(`/formManagements/${id}`, {
      site,
      formAnswerDetail: [],
    }),
  });
};

export const updateFormManagementStatus = (dispatchFormId, status, location) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.ANSWER_FILLING,
    payload: axios.patch(`/formManagements/${dispatchFormId}`, {
      status: status.status,
      userLocation: location,
    }),
  });
};

export const updateFormManagementBookmarkedUnBookmarked = (dispatchFormId, isBookmarked) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.BOOKMARKED_UNBOOKMARKED,
    payload: axios.patch(`/formManagements/${dispatchFormId}`, isBookmarked),
  });
};

export const clearForManagementAnswers = (dispatchFormId, data) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.CLEAR_FORM_ANSWERS,
    payload: axios.patch(`/formManagements/${dispatchFormId}`, data),
  });
};

export const formManagementById = (dispatchFormId) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.GET_FORMMANAGEMENT_BY_ID,
    payload: axios.get(`/formManagements/${dispatchFormId}`),
  });
};

export const formManagmentIsRead = (id, isRead) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.PATCH_FORMMANAGEMENT_ISREAD,
    payload: axios.patch(`/formManagements/${id}`, { isRead }),
  });
};

export const editDispatchRule = (id, dispatchRule) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.PATCH_DISPATCHRULE,
    payload: axios.patch(`/dispatch-rules/${id}`, dispatchRule),
  });
};

export const deleteDispatchRule = (id) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.DELETE_DISPATCHRULE,
    payload: axios.delete(`/dispatch-rules/${id}`),
  });
};
export const saveFormIdInStore = (id, oldForm, newForm) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SAVE_FORM_ID,
    payload: { id: id, oldForm: oldForm, newForm: newForm },
  });
};
export const storeOldForm = (oldForm) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.STORE_OLD_FORM,
    payload: oldForm,
  });
};
