import React, { useState, useEffect } from "react";
import I18n from "../../../languageSelector";
import "../../../../../assets/css/pdf.min.css";
import ReportPdfImages from "../report-pdf-images/reportPdfImages.js";
import { GET_MCQ_NAME } from "../../../../../helper/formHelperFunctions";
import { TEXT_DIRECTION } from "../../../../../helper/helperFunctions";

const MultipleChoicePdfPreview = ({
  getAnswer = (uuid) => {
    console.log(uuid);
    return undefined;
  },
  question,
  failStatus,
  failItems,
  hideAndShow,
  setImageIndex,
  setShowImageSrc,
  setShowImageDialog,
  setShowSection,
  setTotalAnswered,
  showDocumentMedia,
  imageCountData,
}) => {
  const [note, setNote] = useState("");
  const [photo, setPhoto] = useState("");
  const [imageCount, setImageCount] = useState(0);
  const [answerValue, setAnswerValue] = useState([]);
  const [statusState, setStatusState] = useState("status-success");
  console.log(statusState);

  useEffect(() => {
    if (question && question.questionUuid) {
      let answer = getAnswer(question.questionUuid);
      if (answer) {
        setAnswerValue([...answer.selected]);
        setNote(answer.note);
        setPhoto([...answer.image]);
        if (
          answer?.selected ||
          answer?.note !== "" ||
          answer?.image?.length > 0
        ) {
          setShowSection(true);
          setTotalAnswered((prev) => prev + 1);
        }
      }
    }
    // eslint-disable-next-line
  }, [question]);

  useEffect(() => {
    if (answerValue && answerValue.length) {
      // eslint-disable-next-line
      answerValue.map((item, index) => {
        if (question?.settings?.answers[item.index].isFail && failStatus) {
          // setFailItems((preState) => preState + 1);
          setStatusState("status-danger");
        } else {
          setStatusState("status-success");
        }
      });
    }
    // eslint-disable-next-line
  }, [answerValue]);

  useEffect(() => {
    if (question && question.questionUuid && imageCountData?.length) {
      let data = imageCountData.filter((item) => {
        return item.questionId === question.questionUuid;
      });
      if (data.length) {
        setImageCount(data[0].imageCountStart);
      }
    }
  }, [question, imageCountData]);
  let lastFailedIndex = -1;
  answerValue.forEach((subItem) => {
    if (question?.settings?.answers[subItem.index].isFail) {
      lastFailedIndex = subItem.index;
    }
  });

  return (
    <>
      {failStatus ? (
        <div className="table-body">
          {answerValue &&
          answerValue.length &&
          answerValue.some(
            (subItem) => question?.settings?.answers[subItem.index].isFail
          ) ? (
            <div
              className="row table-row question ml-0 mr-0"
              style={{ borderLeft: "0", borderRight: "0", borderTop: "0" }}
            >
              <div
                className={`table-column col-lg-8 col-md-6 mb-3 d-flex ${
                  TEXT_DIRECTION() === "rtl" ? "pr-0" : "pl-0"
                }`}
              >
                <span
                  className={`text-muted ${
                    TEXT_DIRECTION() === "rtl" ? "ml-2" : "mr-2"
                  }`}
                >
                  {question?.questionNumber}.
                </span>
                {"   "}
                {question?.settings?.questionText === "Add a new question" ? (
                  <I18n lng="Form.AddANewQuestion" />
                ) : (
                  question?.settings?.questionText
                )}
              </div>
              {answerValue
                .filter(
                  (subItem) => question?.settings?.answers[subItem.index].isFail
                )
                .map((item, index) => (
                  <React.Fragment key={index}>
                    {index === 0 ? (
                      <div></div>
                    ) : (
                      <div className="col-lg-8 col-md-6"></div>
                    )}
                    <div className="col-lg-4 col-md-6 table-column question-type">
                      <span
                        className={`tag ${
                          question?.settings?.answers[item.index].class
                        }`}
                      >
                        {GET_MCQ_NAME(
                          question?.settings?.answers[item.index].name
                        )}
                      </span>

                      {note || photo.length ? (
                        <div
                          className="table-column attachments-wrapper pb-3"
                          style={{
                            display:
                              item.index === lastFailedIndex ? "flex" : "none",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <fieldset>
                            <legend
                              className={`${
                                TEXT_DIRECTION() === "rtl"
                                  ? "legend-arabic"
                                  : "legend"
                              }`}
                            >
                              <I18n lng="global.InspectorNotes" />
                            </legend>
                            {note ? (
                              <div className="notes">
                                <p>{note}</p>
                              </div>
                            ) : null}
                            {photo.length ? (
                              <div className="notes thumbnail-wrapper">
                                <ReportPdfImages
                                  photo={photo}
                                  imageCount={imageCount}
                                  setImageIndex={setImageIndex}
                                  setShowImageSrc={setShowImageSrc}
                                  showDocumentMedia={showDocumentMedia}
                                  setShowImageDialog={setShowImageDialog}
                                />
                              </div>
                            ) : null}
                          </fieldset>
                        </div>
                      ) : null}
                    </div>
                  </React.Fragment>
                ))}
            </div>
          ) : null}

          {/* {failStatus === true ? <hr className="m-0" /> : ""} */}
        </div>
      ) : (
        <div className="table-body">
          {hideAndShow?.content?.all ? (
            <div
              className={`row table-row question ml-0 mr-0  ${
                answerValue.some(
                  (subItem) => question?.settings?.answers[subItem.index].isFail
                )
                  ? "status-danger"
                  : !question?.settings?.answers[answerValue[0]?.index]?.isFail
                  ? question?.settings?.answers[answerValue[0]?.index]?.class
                  : ""
              }`}
              style={{
                boxShadow: "0px 5px 5px rgba(0,0,0,.10)",
                backgroundColor: "#FFFFFF",
              }}
            >
              <div
                className={`table-column col-lg-8 col-md-6 mb-3 d-flex  ${
                  TEXT_DIRECTION() === "rtl" ? "pr-0" : "pl-0"
                }`}
              >
                <span
                  className={`text-muted ${
                    TEXT_DIRECTION() === "rtl" ? "ml-2" : "mr-2"
                  }`}
                >
                  {question?.questionNumber}.
                </span>
                {question?.settings?.questionText === "Add a new question" ? (
                  <I18n lng="Form.AddANewQuestion" />
                ) : (
                  question?.settings?.questionText
                )}
              </div>
              {answerValue && answerValue.length
                ? answerValue.map((item, index) => (
                    <React.Fragment key={index}>
                      {index === 0 ? (
                        <div></div>
                      ) : (
                        <div className="col-lg-8 col-md-6"></div>
                      )}

                      <div className="col-lg-4 col-md-6 table-column question-type">
                        <span
                          className={`tag ${
                            question?.settings?.answers[item.index].class
                          }`}
                        >
                          {GET_MCQ_NAME(
                            question?.settings?.answers[item.index].name
                          )}
                        </span>
                        {index === answerValue?.length - 1 ? (
                          <>
                            {note || photo.length ? (
                              <div
                                className="table-column attachments-wrapper pb-3"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <>
                                  <fieldset>
                                    <legend
                                      className={`${
                                        TEXT_DIRECTION() === "rtl"
                                          ? "legend-arabic"
                                          : "legend"
                                      }`}
                                    >
                                      <I18n lng="global.InspectorNotes" />
                                    </legend>
                                    {note ? (
                                      <div className="notes">
                                        <p>{note}</p>
                                      </div>
                                    ) : null}
                                    {photo.length ? (
                                      <div className="notes thumbnail-wrapper">
                                        <ReportPdfImages
                                          photo={photo}
                                          imageCount={imageCount}
                                          setImageIndex={setImageIndex}
                                          setShowImageSrc={setShowImageSrc}
                                          showDocumentMedia={showDocumentMedia}
                                          setShowImageDialog={
                                            setShowImageDialog
                                          }
                                        />
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </fieldset>
                                </>
                              </div>
                            ) : null}
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </React.Fragment>
                  ))
                : null}
            </div>
          ) : hideAndShow?.content?.answered &&
            (answerValue?.length || note || photo?.length) ? (
            <div
              className={`row table-row question ml-0 mr-0  ${
                answerValue.some(
                  (subItem) => question?.settings?.answers[subItem.index].isFail
                )
                  ? "status-danger"
                  : !question?.settings?.answers[answerValue[0]?.index]?.isFail
                  ? question?.settings?.answers[answerValue[0]?.index]?.class
                  : ""
              }`}
              style={{
                boxShadow: "0px 5px 5px rgba(0,0,0,.10)",
                backgroundColor: "#FFFFFF",
              }}
            >
              <div
                className={`table-column col-lg-8 col-md-6 mb-3 d-flex  ${
                  TEXT_DIRECTION() === "rtl" ? "pr-0" : "pl-0"
                }`}
              >
                <span
                  className={`text-muted ${
                    TEXT_DIRECTION() === "rtl" ? "ml-2" : "mr-2"
                  }`}
                >
                  {question?.questionNumber}.
                </span>
                {question?.settings?.questionText === "Add a new question" ? (
                  <I18n lng="Form.AddANewQuestion" />
                ) : (
                  question?.settings?.questionText
                )}
              </div>
              {answerValue && answerValue.length
                ? answerValue.map((item, index) => (
                    <React.Fragment key={index}>
                      {index === 0 ? (
                        <div></div>
                      ) : (
                        <div className="col-lg-8 col-md-6"></div>
                      )}

                      <div className="col-lg-4 col-md-6 table-column question-type">
                        <span
                          className={`tag ${
                            question?.settings?.answers[item.index].class
                          }`}
                        >
                          {GET_MCQ_NAME(
                            question?.settings?.answers[item.index].name
                          )}
                        </span>
                        {index === answerValue?.length - 1 ? (
                          <>
                            {note || photo.length ? (
                              <div
                                className="table-column attachments-wrapper pb-3"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <>
                                  <fieldset>
                                    <legend
                                      className={`${
                                        TEXT_DIRECTION() === "rtl"
                                          ? "legend-arabic"
                                          : "legend"
                                      }`}
                                    >
                                      <I18n lng="global.InspectorNotes" />
                                    </legend>
                                    {note ? (
                                      <div className="notes">
                                        <p>{note}</p>
                                      </div>
                                    ) : null}
                                    {photo.length ? (
                                      <div className="notes thumbnail-wrapper">
                                        <ReportPdfImages
                                          photo={photo}
                                          imageCount={imageCount}
                                          setImageIndex={setImageIndex}
                                          setShowImageSrc={setShowImageSrc}
                                          showDocumentMedia={showDocumentMedia}
                                          setShowImageDialog={
                                            setShowImageDialog
                                          }
                                        />
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </fieldset>
                                </>
                              </div>
                            ) : null}
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </React.Fragment>
                  ))
                : null}
            </div>
          ) : hideAndShow?.content?.unAnswered &&
            !answerValue?.length &&
            !note &&
            !photo?.length ? (
            <div
              className={`row table-row question ml-0 mr-0  ${
                answerValue.some(
                  (subItem) => question?.settings?.answers[subItem.index].isFail
                )
                  ? "status-danger"
                  : !question?.settings?.answers[answerValue[0]?.index]?.isFail
                  ? question?.settings?.answers[answerValue[0]?.index]?.class
                  : ""
              }`}
              style={{
                boxShadow: "0px 5px 5px rgba(0,0,0,.10)",
                backgroundColor: "#FFFFFF",
              }}
            >
              <div
                className={`table-column col-lg-8 col-md-6 mb-3 d-flex  ${
                  TEXT_DIRECTION() === "rtl" ? "pr-0" : "pl-0"
                }`}
              >
                <span
                  className={`text-muted ${
                    TEXT_DIRECTION() === "rtl" ? "ml-2" : "mr-2"
                  }`}
                >
                  {question?.questionNumber}.
                </span>
                {question?.settings?.questionText === "Add a new question" ? (
                  <I18n lng="Form.AddANewQuestion" />
                ) : (
                  question?.settings?.questionText
                )}
              </div>
              {answerValue && answerValue.length
                ? answerValue.map((item, index) => (
                    <React.Fragment key={index}>
                      {index === 0 ? (
                        <div></div>
                      ) : (
                        <div className="col-lg-8 col-md-6"></div>
                      )}

                      <div className="col-lg-4 col-md-6 table-column question-type">
                        <span
                          className={`tag ${
                            question?.settings?.answers[item.index].class
                          }`}
                        >
                          {GET_MCQ_NAME(
                            question?.settings?.answers[item.index].name
                          )}
                        </span>
                        {index === answerValue?.length - 1 ? (
                          <>
                            {note || photo.length ? (
                              <div
                                className="table-column attachments-wrapper pb-3"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <>
                                  <fieldset>
                                    <legend
                                      className={`${
                                        TEXT_DIRECTION() === "rtl"
                                          ? "legend-arabic"
                                          : "legend"
                                      }`}
                                    >
                                      <I18n lng="global.InspectorNotes" />
                                    </legend>
                                    {note ? (
                                      <div className="notes">
                                        <p>{note}</p>
                                      </div>
                                    ) : null}
                                    {photo.length ? (
                                      <div className="notes thumbnail-wrapper">
                                        <ReportPdfImages
                                          photo={photo}
                                          imageCount={imageCount}
                                          setImageIndex={setImageIndex}
                                          setShowImageSrc={setShowImageSrc}
                                          showDocumentMedia={showDocumentMedia}
                                          setShowImageDialog={
                                            setShowImageDialog
                                          }
                                        />
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </fieldset>
                                </>
                              </div>
                            ) : null}
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </React.Fragment>
                  ))
                : null}
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};
export default MultipleChoicePdfPreview;
