/** @format */

import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Select from "react-select";
import I18n, { i18n } from "../../pages/private/languageSelector";
import { useState } from "react";
import toast from "react-hot-toast";

const SeatedTypeChangeDialog = ({
  showModal,
  onClose,
  secondaryAction,
  modalTitle,
  text_one,
  text_two,
  primaryButtonLabel,
  primaryButtonColor,
  secondaryButtonLabel,
  secondaryButtonColor,
  allUsers,
  userForEdit,
  id,
  assignUserResponsibility,
  updateSubscriptionData,
  subscriptionData,
  getSubscriptionByCompanyId,
  userDataToUpdate,
  updateUserById,
  getAllUsers,
  getSession,
  user,
  dataToPassEverySubscription,
}) => {
  const [newUserSeated, setNewUserSeated] = useState(null);
  const hanldeRoleChange = (option) => {
    setNewUserSeated(option);
  };

  const filteredUsers = (roleCriteria) => {
    return allUsers.filter((user) => {
      if (!user.isUserSeated) return false;
      if (!user.isEmailVerified) return false;
      if (roleCriteria === "manager") {
        return ["manager", "manager/inspector", "admin"].includes(user.role);
      } else if (roleCriteria === "user") {
        return ["user", "manager/inspector", "admin"].includes(user.role);
      } else if (roleCriteria === "manager/inspector") {
        return ["manager/inspector", "admin"].includes(user.role);
      }
      return false;
    });
  };

  const hanldeChangeSeatedTypeChange = async () => {
    const subscriptionResponse = await getSubscriptionByCompanyId(user?.company?.id);
    const latestSubscriptionData = subscriptionResponse?.value?.data;

    if (!newUserSeated) {
      return toast.error("Please select a user");
    } else {
      if (id && newUserSeated) {
        assignUserResponsibility(id, newUserSeated?.value, "update").then(() => {
          updateUserById(userDataToUpdate)
            .then(() => {
              toast.success(<I18n lng="validation.Updated" />);
              getAllUsers();
              if (userForEdit.role === "admin") {
                getSession();
              }
            })
            .then(() => {
              updateSubscriptionData(subscriptionData?.id, dataToPassEverySubscription(latestSubscriptionData, "userNonSeatWhenUpdate"), "Update");
            })
            .then(() => {
              getSubscriptionByCompanyId(user?.company?.id);
            })
            .then(() => onClose())
            .then(() => secondaryAction())
            .catch((error) => {
              toast.error(error.response.data.message);
            });
        });
      } else {
        toast.error(i18n("Company.PleaseSelectUser"));
      }
    }
  };

  return (
    <div>
      <Modal isOpen={showModal} toggle={onClose} className="plan-dialog modal-main" size="sm" centered>
        <ModalHeader
          className="border-0 container-fluid d-flex justify-content-center align-items-center"
          style={{ padding: "10px", fontWeight: "600" }}
        >
          <span style={{ fontWeight: "700", fontSize: "20px", lineHeight: "20px", color: "#333333", width: "110px", height: "20px" }}>
            {modalTitle}
          </span>
        </ModalHeader>
        <ModalBody className="d-flex justify-content-center align-items-center flex-column p-1">
          <p className="text-center my-1" style={{ maxWidth: "240px", color: "#333333", fontSize: "16px", fontWeight: "400" }}>
            {text_one}
          </p>
          {text_two && (
            <p className="text-center my-1" style={{ maxWidth: "220px", color: "#333333", fontSize: "16px", fontWeight: "400" }}>
              {text_two}
            </p>
          )}
          <div className="col-lg-12">
            <Select
              value={newUserSeated}
              options={filteredUsers(userForEdit?.role)
                .map((item) => ({
                  value: item?.id,
                  label: `${item?.firstName} ${item?.lastName}`,
                  role: item,
                  isCurrentUser: userForEdit.id === item.id,
                }))
                .filter((option) => !option.isCurrentUser)}
              placeholder={<I18n lng="global.Select" />}
              label={<I18n lng="Company.Role" />}
              onChange={hanldeRoleChange}
            />
          </div>
        </ModalBody>
        <ModalFooter className="d-flex border-0 justify-content-between mx-4">
          <p
            style={{ color: secondaryButtonColor, fontWeight: "700", fontSize: "16px", lineHeight: "15.92px", width: "53px", height: "16px" }}
            onClick={onClose}
            className="cursor-pointer"
          >
            {secondaryButtonLabel}
          </p>
          <p
            onClick={hanldeChangeSeatedTypeChange}
            style={{
              color: primaryButtonColor,
              fontWeight: "700",
              fontSize: "16px",
              lineHeight: "15.92px",
              width: "53px",
              height: "16px",
            }}
            className="cursor-pointer"
          >
            {primaryButtonLabel}
          </p>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default SeatedTypeChangeDialog;
