import React, { useEffect } from "react";
import I18n from "../languageSelector";
import { TEXT_DIRECTION } from "../../../helper/helperFunctions";
import IconArrowDown from "../../../assets/images/arrowDown.svg";
import { Collapse } from "reactstrap";
import { CircularProgressbar } from "react-circular-progressbar";
import PieChart from "../../../components/Charts/pieCharts";

const ReportCharts = ({
  failItems,
  hideAndShow,
  reportForEdit,
  totalQuestion,
  answeredScore,
  setPieChartSuccess,
  questionScore,
  setAnsweredPercent,
  totalPercentage,
  setPieChartFail,
  reportKeyIndicatesCollapsed,
  setReportKeyIndicatesCollapsed,
}) => {
  const scorePercentage = (failItems * 100) / totalQuestion;
  const scorePercentageResult = parseFloat(scorePercentage.toFixed(15));

  const answeredPercentage =
    (reportForEdit?.formAnswerDetail?.length * 100) / totalQuestion;
  const answeredPercentageResult = parseFloat(answeredPercentage.toFixed(15));
  useEffect(() => {
    if (answeredPercentageResult) {
      setAnsweredPercent(answeredPercentageResult);
    }
    // eslint-disable-next-line
  }, [answeredPercentageResult]);
  return (
    <div>
      {(hideAndShow?.content?.scores &&
        reportForEdit?.formDetail?.schema?.isScored) ||
      hideAndShow?.content?.failsCount ||
      hideAndShow?.content?.unAnsweredQuestions ? (
        <div
          className="heading-top"
          style={{ borderRadius: "12px", marginTop: "15px" }}
        >
          <div className="report-table fail-summary">
            <div className="report-header">
              <div
                className="table "
                style={{ border: "#FFFFFF", marginBottom: "0px" }}
              >
                <div className="statistic-header">
                  <div className="key-indicator">
                    <div className="table-header">
                      <div className="table-row">
                        <div
                          className="table-head"
                          style={{
                            padding: "0.25rem 1.5rem",
                            borderRadius: "0",
                          }}
                        >
                          <div className="mt-2 d-flex justify-content-between w-100 ">
                            <h5
                              className={
                                TEXT_DIRECTION() === "rtl" ? "ml-3" : "mr-3"
                              }
                            >
                              <b>
                                <I18n lng="Reports.KeyIndicators" />
                              </b>
                            </h5>
                            <div
                              onClick={() => {
                                reportKeyIndicatesCollapsed &&
                                reportKeyIndicatesCollapsed === true
                                  ? setReportKeyIndicatesCollapsed(false)
                                  : setReportKeyIndicatesCollapsed(true);
                              }}
                              className="text-right align-end "
                            >
                              <img
                                style={
                                  reportKeyIndicatesCollapsed === true
                                    ? {
                                        margin: "0 15px 5px 15px",
                                        width: "25px",
                                        cursor: "pointer",
                                      }
                                    : {
                                        margin: "0 15px 5px 15px",
                                        width: "25px",
                                        cursor: "pointer",
                                        rotate: "180deg",
                                      }
                                }
                                src={IconArrowDown}
                                alt="arrow Down"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Collapse isOpen={reportKeyIndicatesCollapsed}>
                    <div className="px-3">
                      <div className="report-graph">
                        <div className="row">
                          {hideAndShow?.content?.scores &&
                            reportForEdit?.formDetail?.schema?.isScored &&
                            reportForEdit?.formDetail?.schema?.isScored && (
                              <>
                                <div
                                  className={`${
                                    hideAndShow?.content?.scores &&
                                    reportForEdit?.formDetail?.schema
                                      ?.isScored &&
                                    hideAndShow?.content?.failsCount &&
                                    hideAndShow?.content?.unAnsweredQuestions
                                      ? `graphic-card mb-1 col-md-4 ${
                                          TEXT_DIRECTION() === "rtl"
                                            ? "pl-1 pr-0"
                                            : "pr-1 pl-0"
                                        }`
                                      : hideAndShow?.content?.scores &&
                                        reportForEdit?.formDetail?.schema
                                          ?.isScored &&
                                        hideAndShow?.content?.failsCount
                                      ? `graphic-card mb-1 col-md-6 ${
                                          TEXT_DIRECTION() === "rtl"
                                            ? "pl-1 pr-0"
                                            : "pr-1 pl-0"
                                        }`
                                      : hideAndShow?.content?.scores &&
                                        reportForEdit?.formDetail?.schema
                                          ?.isScored &&
                                        hideAndShow?.content
                                          ?.unAnsweredQuestions
                                      ? `graphic-card mb-1 col-md-6 ${
                                          TEXT_DIRECTION() === "rtl"
                                            ? "pl-1 pr-0"
                                            : "pr-1 pl-0"
                                        }`
                                      : hideAndShow?.content?.scores &&
                                        reportForEdit?.formDetail?.schema
                                          ?.isScored &&
                                        !hideAndShow?.content?.failsCount &&
                                        !hideAndShow?.content
                                          ?.unAnsweredQuestions
                                      ? `graphic-card mb-1 col-md-12 ${
                                          TEXT_DIRECTION() === "rtl"
                                            ? "px-0"
                                            : "px-0"
                                        }`
                                      : ""
                                  }`}
                                >
                                  <div
                                    className="card"
                                    style={{
                                      borderRadius: "0rem 0rem 1rem 1rem",
                                      boxShadow: "0px 5px 5px rgba(0,0,0,.10)",
                                    }}
                                  >
                                    <div className="mt-2 mb-1 mx-1 ">
                                      <div className="d-flex justify-content-around">
                                        <p>
                                          <strong
                                            style={{
                                              color: "#333333",
                                              fontWeight: "600",
                                            }}
                                            className="font-color mr-2 "
                                          >
                                            <I18n lng="Form.Score" />
                                          </strong>
                                        </p>
                                        <h4>
                                          %
                                          {Number.isInteger(totalPercentage)
                                            ? totalPercentage
                                            : totalPercentage?.toFixed(2)}
                                        </h4>
                                        <p
                                          style={{
                                            color: "#828282",
                                            fontWeight: "500",
                                            fontSize: "14px",
                                          }}
                                        >
                                          {Number.isInteger(answeredScore)
                                            ? answeredScore
                                            : answeredScore?.toFixed(2)}{" "}
                                          /{" "}
                                          {Number.isInteger(questionScore)
                                            ? questionScore
                                            : questionScore?.toFixed(2)}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="progress scoreProgress d-flex justify-content-center">
                                      <CircularProgressbar
                                        styles={{
                                          root: { width: 145 },
                                        }}
                                        value={totalPercentage?.toFixed(2)}
                                        width={200}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          {hideAndShow?.content?.failsCount ? (
                            <div
                              className={`${
                                hideAndShow?.content?.scores &&
                                reportForEdit?.formDetail?.schema?.isScored &&
                                hideAndShow?.content?.failsCount &&
                                hideAndShow?.content?.unAnsweredQuestions
                                  ? `graphic-card mb-1 col-md-4 ${
                                      TEXT_DIRECTION() === "rtl"
                                        ? "pl-1 pr-0"
                                        : "pr-1 pl-0"
                                    }`
                                  : hideAndShow?.content?.scores &&
                                    reportForEdit?.formDetail?.schema
                                      ?.isScored &&
                                    hideAndShow?.content?.failsCount
                                  ? `graphic-card mb-1 col-md-6 ${
                                      TEXT_DIRECTION() === "rtl"
                                        ? "pl-1 pr-0"
                                        : "pr-0 pl-1"
                                    }`
                                  : hideAndShow?.content?.failsCount &&
                                    hideAndShow?.content?.unAnsweredQuestions
                                  ? `graphic-card mb-1   col-md-6 ${
                                      TEXT_DIRECTION() === "rtl"
                                        ? "pl-1 pr-0"
                                        : "pr-1 pl-0"
                                    }`
                                  : !hideAndShow?.content?.scores &&
                                    hideAndShow?.content?.failsCount &&
                                    !hideAndShow?.content?.unAnsweredQuestions
                                  ? `graphic-card mb-1  col-md-12 ${
                                      TEXT_DIRECTION() === "rtl"
                                        ? "px-0"
                                        : "px-0"
                                    }`
                                  : ""
                              }`}
                            >
                              <div
                                className="card"
                                style={{
                                  borderRadius: "0rem 0rem 1rem 1rem",
                                  boxShadow: "0px 5px 5px rgba(0,0,0,.10)",
                                }}
                              >
                                <div className="mt-2">
                                  <div className="d-flex justify-content-around">
                                    <p>
                                      <strong
                                        style={{
                                          color: "#333333",
                                          fontWeight: "600",
                                        }}
                                        className="font-color"
                                      >
                                        <I18n lng="Reports.Fails" />
                                      </strong>
                                    </p>
                                    <h4>
                                      %
                                      {Number.isInteger(scorePercentageResult)
                                        ? scorePercentageResult?.toFixed(0)
                                        : scorePercentageResult?.toFixed(2)}
                                    </h4>
                                    <p
                                      style={{
                                        color: "#828282",
                                        fontWeight: "500",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {failItems}/{totalQuestion}
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-center mb-4">
                                  <PieChart
                                    setPieChartFail={setPieChartFail}
                                    setPieChartSuccess={setPieChartSuccess}
                                    totalQuestion={totalQuestion}
                                    failItems={failItems}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          {hideAndShow?.content?.unAnsweredQuestions ? (
                            <div
                              className={`${
                                hideAndShow?.content?.scores &&
                                reportForEdit?.formDetail?.schema?.isScored &&
                                hideAndShow?.content?.failsCount &&
                                hideAndShow?.content?.unAnsweredQuestions
                                  ? `graphic-card mb-1 col-md-4 ${
                                      TEXT_DIRECTION() === "rtl"
                                        ? "pl-0 pr-0"
                                        : "pr-0 pl-0"
                                    }`
                                  : hideAndShow?.content?.scores &&
                                    reportForEdit?.formDetail?.schema
                                      ?.isScored &&
                                    hideAndShow?.content?.unAnsweredQuestions
                                  ? `graphic-card mb-1 col-md-6 ${
                                      TEXT_DIRECTION() === "rtl"
                                        ? "pl-0 pr-1"
                                        : "pr-0 pl-1"
                                    }`
                                  : hideAndShow?.content?.failsCount &&
                                    hideAndShow?.content?.unAnsweredQuestions
                                  ? `graphic-card mb-1 col-md-6 ${
                                      TEXT_DIRECTION() === "rtl"
                                        ? "pl-0 pr-1"
                                        : "pr-0 pl-1"
                                    }`
                                  : !hideAndShow?.content?.scores &&
                                    !hideAndShow?.content?.failsCount &&
                                    hideAndShow?.content?.unAnsweredQuestions
                                  ? "graphic-card mb-1  col-md-12 px-0"
                                  : ""
                              }`}
                            >
                              <div
                                className="card"
                                style={{
                                  borderRadius: "0rem 0rem 1rem 1rem",
                                  boxShadow: "0px 5px 5px rgba(0,0,0,.10)",
                                }}
                              >
                                <div className="mt-2 mb-1 mx-1">
                                  <div className="d-flex justify-content-around">
                                    <p>
                                      <strong
                                        style={{
                                          color: "#333333",
                                          fontWeight: "600",
                                        }}
                                        className="font-color mr-2 "
                                      >
                                        <I18n lng="Reports.Answered" />
                                      </strong>
                                    </p>
                                    <h4>
                                      %
                                      {Number.isInteger(
                                        answeredPercentageResult
                                      )
                                        ? answeredPercentageResult?.toFixed(0)
                                        : answeredPercentageResult?.toFixed(2)}
                                    </h4>
                                    <p
                                      style={{
                                        color: "#828282",
                                        fontWeight: "500",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {reportForEdit?.formAnswerDetail?.length}/
                                      {totalQuestion}
                                    </p>
                                  </div>
                                </div>

                                <div className="progress answerProgress d-flex justify-content-center">
                                  <CircularProgressbar
                                    styles={{
                                      root: { width: 145 },
                                    }}
                                    value={
                                      (reportForEdit?.formAnswerDetail?.length *
                                        100) /
                                      totalQuestion?.toFixed(2)
                                    }
                                    width={200}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </Collapse>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ReportCharts;
