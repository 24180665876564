import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import I18n from "../../pages/private/languageSelector";
import { TEXT_DIRECTION } from "../../helper/helperFunctions";
import { useEffect } from "react";

const PricingPlanDialog = ({
  showModal,
  onClose,
  modalTitle,
  text_one,
  text_two,
  text_three,
  primaryButtonLabel,
  secondaryButtonLabel,
  secondaryButtonColor,
  setChargeBillingDetailsDailog,
  setSelectedPlan,
  selectedPlan,
  subscriptionData,
}) => {
  const handleSelectPlan = (e) => {
    const newSelectedPlan = e.target.value;
    setSelectedPlan(newSelectedPlan);
    if (subscriptionData?.currentPlan?.status === "Expired") {
      if (newSelectedPlan) {
        localStorage.setItem("selectedPlan", newSelectedPlan);
      }
    }
  };

  useEffect(() => {
    if (subscriptionData?.currentPlan?.status === "Expired") {
      if (selectedPlan) {
        localStorage.setItem("selectedPlan", selectedPlan);
      }
    }
  }, [selectedPlan, subscriptionData]);

  const hanldeOpenChargeBillingDetails = () => {
    setChargeBillingDetailsDailog(true);
    onClose();
  };

  const language = localStorage.getItem("language");

  return (
    <div>
      <Modal isOpen={showModal} toggle={onClose} className="plan-dialog modal-main" size="sm" centered>
        <ModalHeader
          className="border-0 container-fluid d-flex justify-content-start align-items-center"
          style={{ padding: "10px", fontSize: "18px", fontWeight: "400", marginLeft: "17px", marginRight: "7px" }}
        >
          <span style={{ fontWeight: "400", fontSize: "18px", lineHeight: "28.57px", color: "#333333", width: "204px", height: "29px" }}>
            {modalTitle}
          </span>
        </ModalHeader>
        <ModalBody className="d-flex justify-content-center align-items-center flex-column p-1">
          <form className="p-2">
            <div className="flex flex-col justify-content-center" style={{ marginLeft: "14px" }}>
              <div className="form-check mr-1">
                <input
                  checked={selectedPlan === "Monthly Subscription"}
                  className="form-check-input custom-radio"
                  type="radio"
                  value={"Monthly Subscription"}
                  onChange={handleSelectPlan}
                  style={{ width: "17px", height: "17px", cursor: "pointer" }}
                />
                <label
                  style={
                    (TEXT_DIRECTION() === "rtl"
                      ? {
                          paddingRight: "28px",
                          paddingLeft: "8px",
                        }
                      : {
                          paddingRight: "",
                          paddingLeft: "",
                        },
                    {
                      width: language === "ARABIC" ? "210px" : "173px",
                      height: "40px",
                      fontWeight: "400",
                      fontSize: "16px",
                      marginRight: language === "ARABIC" ? "24px" : "0px",
                    })
                  }
                  className="form-check-label ml-2"
                  htmlFor="Monthly Subscription"
                >
                  79 <I18n lng="Subscriptions.SAR/monthPerSeat" />
                </label>
              </div>

              <div className="form-check mr-1">
                <input
                  className="form-check-input"
                  type="radio"
                  value={"Yearly Subscription"}
                  checked={selectedPlan === "Yearly Subscription"}
                  onChange={handleSelectPlan}
                  style={{ width: "17px", height: "17px", cursor: "pointer", border: "1px solid red" }}
                />
                <label
                  style={
                    (TEXT_DIRECTION() === "rtl"
                      ? {
                          paddingRight: "25px",
                          paddingLeft: "8px",
                        }
                      : {
                          paddingRight: "",
                          paddingLeft: "",
                        },
                    {
                      width: language === "ARABIC" ? "210px" : "173px",
                      height: "40px",
                      fontWeight: "400",
                      color: "#333333",
                      marginRight: language === "ARABIC" ? "24px" : "0px",
                    })
                  }
                  className="form-check-label ml-2"
                  htmlFor="Yearly Subscription"
                >
                  790 <I18n lng="Subscriptions.SAR/yearPerSeat" />
                </label>
              </div>
            </div>
          </form>
          <p className="d-flex flex-column ml-2" style={{ fontWeight: "400", fontSize: "12px", width: "253px", height: "45px", color: "#828282" }}>
            <span>{text_one}</span>
            <span>{text_two}</span>
            <span>{text_three}</span>
          </p>
        </ModalBody>

        <ModalFooter
          className={`cursor-pointer border-0 px-3 d-flex ${
            !primaryButtonLabel ? "justify-content-center" : "justify-content-between"
          }  align-items-center p-1 mx-3 mb-3`}
        >
          <p style={{ color: secondaryButtonColor, fontWeight: "600", fontSize: "16px", width: "53px", height: "16px" }} onClick={onClose}>
            {secondaryButtonLabel}
          </p>
          <p
            onClick={hanldeOpenChargeBillingDetails}
            style={{
              color: selectedPlan ? "#20639B" : "#BDBDBD",
              fontWeight: "600",
              fontSize: "16px",
              width: "73px",
              height: "16px",
            }}
            className="cursor-pointer"
          >
            {primaryButtonLabel}
          </p>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default PricingPlanDialog;
