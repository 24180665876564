import React from "react";
const Radio = ({
  label,
  name,
  id,
  register,
  validations,
  error,
  children,
  ...otherProps
}) => {
  return (
    <>
      <div className="custom-control custom-radio">
        <input
          type="radio"
          className="custom-control-input"
          id={id}
          name={name}
          {...(register ? register(name, { ...validations }) : {})}
          {...otherProps}
        />
        <label className="custom-control-label" htmlFor={id}>
          {label}
          {validations?.required && <span className="required"></span>}
        </label>
      </div>
      {children}
      {error && (
        <p className="error" style={{ marginTop: "0px", marginLeft: "15px" }}>
          {error.message}
        </p>
      )}
    </>
  );
};

export default Radio;
