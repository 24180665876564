import React, { useMemo } from "react";
import moment from "moment";
import ListItemMenu from "./list-item-menu";
import { TiDocumentText } from "react-icons/ti";
import I18n, { i18n } from "../../../languageSelector";
import { useReportsUIContext } from "../../reports-ui-context";
import { getFormLastModifiedDate } from "../../../../../helper/date-time-helper";
import { isRTL, TEXT_DIRECTION } from "../../../../../helper/helperFunctions";

const ReportsList = ({ id, report, status, formName, archiveReportPhoto }) => {
  const reportsUIContext = useReportsUIContext();
  const reportsUIProps = useMemo(() => {
    return {
      unArchiveReports: reportsUIContext.unArchiveReports,
      deleteAdminManagerReports: reportsUIContext.deleteAdminManagerReports,
    };
  }, [reportsUIContext]);
  return (
    <>
      <li>
        <div
          className={`list-item-avatar ${
            status === "rejected" ? "status-danger" : ""
          }`}
        >
          <span className="avatar-wrap">
            {archiveReportPhoto ? (
              <img src={archiveReportPhoto} alt="Avatar" />
            ) : (
              <TiDocumentText />
            )}
          </span>
        </div>
        <div className="list-item-detail">
          <h6
            className={`truncate ${
              TEXT_DIRECTION() !== "rtl" && isRTL(formName)
                ? "direction-rtl"
                : TEXT_DIRECTION() === "rtl" && !isRTL(formName)
                ? "direction-ltr"
                : ""
            }`}
          >
            {report?.formDetail?.title.includes("Untitled Form") ? (
              <>
                <I18n lng="global.UntitledForm" />{" "}
                {report?.formDetail?.documentNumber}
              </>
            ) : report?.formDetail?.title ? (
              report?.formDetail?.title
            ) : report?.form?.formDetail.title.includes(`Untitled Form`) ? (
              <>
                <I18n lng="global.UntitledForm" />{" "}
                {report?.formDetail?.documentNumber}
              </>
            ) : report?.form?.formDetail.title ? (
              report?.form?.formDetail.title
            ) : (
              ""
            )}
          </h6>
          <small>
            {getFormLastModifiedDate(report.archivedDate, "archived")} -
            {i18n("global.Modified")}{" "}
            {moment(report.updatedAt).format("DD/MM/YYYY")}
          </small>
        </div>
        <span className="list-item-action">
          <ListItemMenu
            id={id}
            unArchiveReports={reportsUIProps.unArchiveReports}
            deleteAdminManagerReports={reportsUIProps.deleteAdminManagerReports}
          />
        </span>
      </li>
    </>
  );
};

export default ReportsList;
