import { translateChartsValue, translatePercentageFromCharts } from "../../../../helper/helperFunctions";
import { i18n } from "../../../../pages/private/languageSelector";
import ApexChart from "../area-chart/areaChart";
import { HeaderDataInterfae } from "../main-page/analytics-main";

function CustomDummyChart({
  headData,
  handleClick,
  className,
  analyticsXaxisData,
  totalResult,
}: {
  headData: HeaderDataInterfae;
  handleClick: () => void;
  className: any;
  analyticsXaxisData: string[];
  totalResult: number;
}) {
  console.log(headData);
  return (
    <div
      className={`card mb-2`}
      style={{
        boxShadow: className ? "0px 4px 4px 0px #00000040" : "",
        cursor: "pointer",
        height: "141px",
      }}
    >
      <div onClick={handleClick}>
        <div className={`w-100 p-1 px-3 rounded`}>
          <div style={{ height: "30px" }} className="d-flex  align-items-center justify-content-between ">
            {/* <p className="title m-0"> {headData && headData.title && <I18n lng={headData.title} />}</p> */}
            <p className="title m-0" style={{ color: "#333333", lineHeight: "15.77px" }}>
              {" "}
              {headData && headData.title && i18n(`Analytics.${headData.title}`)}
            </p>
            <p className="m-0">
              <span
                style={{
                  color:
                    headData.percentage?.includes("-") && headData.title === "Fail"
                      ? "#3CAEA3"
                      : headData.percentage?.includes("-") && headData.title !== "Fail"
                      ? "#EB5757"
                      : headData.value === 0 || headData.value === "0%"
                      ? "#333333"
                      : headData.title === "Fail"
                      ? "red"
                      : "#3CAEA3",
                  fontSize: "14px",
                  fontWeight: 500,
                  letterSpacing: "1.19px",
                  lineHeight: "25.77px",
                }}
              >
                {totalResult !== 0
                  ? headData.title === "Score" && headData.percentage === "0%" && headData.value === "0%"
                    ? ""
                    : headData.percentage?.includes("-")
                    ? translatePercentageFromCharts(headData.percentage)
                    : headData.value === 0 || headData.value === "0%"
                    ? translatePercentageFromCharts(headData.percentage)
                    : `+${translatePercentageFromCharts(headData.percentage)}`
                  : ""}
              </span>{" "}
              <span
                style={{
                  fontSize: "20px",
                  fontWeight: "500",
                  color: "#333333",
                  lineHeight: "25.77px",
                  letterSpacing: "1.19px",
                }}
              >
                {/* {TEXT_DIRECTION() === "rtl" ? headData?.value.toString().split("").splice(0, 1, "%") : headData.value} */}
                {totalResult !== 0
                  ? headData.title === "Score" && headData.percentage === "0%" && headData.value === "0%"
                    ? ""
                    : translateChartsValue(headData.value)
                  : ""}
              </span>
            </p>
          </div>
          <div>
            <div
              style={{
                maxHeight: "80px",
                position: "relative",
                top: "-14px",
                left: "-7px",
              }}
            >
              <ApexChart totalResult={totalResult} headData={headData} analyticsXaxisData={analyticsXaxisData} dateToShow={false} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomDummyChart;
