import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { VscDiffAdded } from "react-icons/vsc";
import I18n, { i18n } from "../../../../../languageSelector";
import attachmentImage from "../../../../../../../assets/images/attachmentImage-icon.svg";
import ImageEditorDialog from "../../../../../../../components/dialogs/image-editor-dialog";
import attachmentMessage from "../../../../../../../assets/images/attachmentMessage-icon.svg";
import { uploadImageWithThumbnail } from "../../../../../../../redux/reducers/form/form.reducer";
import attachmentImageEdit from "../../../../../../../assets/images/attachmentImageedit-icon.svg";
import attachmentMessageEdit from "../../../../../../../assets/images/attachmentMessageEdit-icon.svg";
import { TEXT_DIRECTION } from "../../../../../../../helper/helperFunctions";

const Attachment = ({
  notes,
  photo,
  setNotes,
  setPhoto,
  hideImage,
  canComment,
  currentRole,
  canAttachPhoto,
  imageAddHandler,
  imageDeleteHandler,
  uploadImageWithThumbnail,
}) => {
  const notesRef = useRef();
  const fileInputRef = useRef();
  const random = Math.round(1 + Math.random() * (10000000 - 1));

  const [imagesToShow, setImagesToShow] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [imageIndex, setImageIndex] = useState(0);
  const [showNote, setShowNote] = useState(false);
  const [notesValue, setNotesValue] = useState("");
  // eslint-disable-next-line
  const [image64Data, setImage64Data] = useState("");
  const [imageEditing, setImageEditing] = useState(false);
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [openEditImageEditor, setOpenEditImageEditor] = useState(false);
  const [openImageEditorDialog, setOpenImageEditorDialog] = useState(false);
  const [waitForImageUpload, setWaitForImageUpload] = useState(false);
  const [loadingForImage, setLoadingForImage] = useState(false);

  const reader = new FileReader();
  // let existingFileUrl = undefined;
  // let imageForCanvas = new Image();
  const selectImagehandler = async (event) => {
    if (event?.target?.files[0]?.size / 1024 / 1024 <= 5) {
      setLoadingForImage(true);
      reader.readAsDataURL(event?.target?.files[0]);
      reader.onload = function (e) {
        setIsImageUploading(true);
        var dataURL = reader.result;
        if (dataURL) {
          // let allocatingImageToCanvas = document.getElementsByTagName("canvas");
          // setTimeout(() => {
          //   setTimeout(() => {
          //     for (let i = 0; i < allocatingImageToCanvas?.length; i++) {
          //       imageForCanvas.src = dataURL;
          //       allocatingImageToCanvas[i]
          //         ?.getContext("2d")
          //         ?.drawImage(imageForCanvas, 0, 0);
          //     }
          // setOpenImageEditorDialog(true);
          setLoadingForImage(true);
          //   }, 2000);
          //   setImage64Data(dataURL);
          //   console.log("SET URL");
          // }, 2000);
          // console.log("going to empty URL");
          // setLoadingForImage(true);
          // setImage64Data("");
          // // uploadImageWithThumbnail({
          // //   base64: dataURL,
          // //   existingFileUrl,
          // //   fileType: "png",
          // // }).then((res) => {
          // //   if (res?.value?.data?.image) {
          // //   }
          // // });
          let existingFileUrl = undefined;
          uploadImageWithThumbnail({
            base64: dataURL,
            existingFileUrl,
            fileType: "png",
          })
            .then((response) => {
              setIsImageUploading(false);
              setImageEditing(false);
              if (
                imageUrl &&
                imageEditing &&
                openEditImageEditor &&
                imageIndex !== undefined
              ) {
                photo[imageIndex] = response.value.data;
                setPhoto([...photo]);
              } else {
                photo.push(response.value.data);
                setPhoto([...photo]);
              }
              imageAddHandler();
              setLoadingForImage(false);
            })
            .catch(() => {
              setIsImageUploading(false);
              setImageEditing(false);
              toast.error("Image uploading failed");
              setImagesToShow([...imagesToShow]);
            });
        }
      };
    } else {
      toast.error("File size must be 5mb or less");
    }
  };

  useEffect(() => {
    if (notes !== "") {
      setNotesValue(notes);
    }
  }, [notes]);

  useEffect(() => {
    if (photo && photo.length) {
      setImagesToShow([...photo]);
    } else {
      setImagesToShow([]);
    }
  }, [photo]);
  const imagetoDataURL64 = (url, callback) => {
    var image = new Image();
    image.crossOrigin = "Anonymous";
    image.onload = function () {
      var canvas = document.createElement("canvas");
      var context = canvas.getContext("2d");
      canvas.height = this.naturalHeight;
      canvas.width = this.naturalWidth;
      context.drawImage(this, 0, 0);
      var dataURL = canvas.toDataURL("image/jpeg");
      callback(dataURL);
    };
    image.src = url;
  };

  useEffect(() => {
    if (imageUrl || image64Data) {
      setWaitForImageUpload(false);
      setLoadingForImage(true);
      setTimeout(() => {
        setWaitForImageUpload(true);
        setLoadingForImage(false);
        setOpenEditImageEditor(true);
        setLoadingForImage(false);
      }, 1000);
    }
  }, [imageUrl, image64Data]);
  return (
    <div className={`attachments-wrapper`}>
      {loadingForImage ? (
        <div className="text-center">
          <div class="spinner-border text-info" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        ""
      )}

      {notes !== "" || imagesToShow?.length || showNote ? (
        <fieldset>
          <legend
            className={`${
              TEXT_DIRECTION() === "rtl" ? "legend-arabic" : "legend"
            }`}
          >
            <I18n lng="global.InspectorNotes" />
          </legend>
          <div className="photo-wrapper">
            {imagesToShow?.length ? (
              <>
                {imagesToShow.map((item, index) => {
                  // console.log(item, "item");
                  return (
                    <img
                      id={`image-${random + index}`}
                      key={index}
                      src={item.thumbnail}
                      alt="thumbnail-loading"
                      onClick={(e) => {
                        setLoadingForImage(true);
                        if (e.cancelable) {
                          e.preventDefault();
                        }
                        imagetoDataURL64(item.thumbnail, (res) => {
                          setOpenEditImageEditor(true);
                          setLoadingForImage(true);
                          setImageIndex(index);
                          setImageEditing(true);
                          setTimeout(() => {
                            setImageUrl(res);
                            setLoadingForImage(false);
                          }, 2000);
                        });
                        // getBase64FromUrl(item.image).then((res) => {
                        //   console.log(res, "res");
                        // });
                      }}
                      onLoad={({ target: img }) => {
                        let style =
                          img.naturalWidth > img.naturalHeight
                            ? img.naturalHeight
                            : img.naturalWidth;
                        let documents;
                        documents = document.getElementById(
                          `image-${random + index}`
                        );
                        documents.setAttribute("height", style + "px");
                        documents.setAttribute("width", style + "px");
                      }}
                    />
                  );
                })}

                {imagesToShow?.length < 3 && !loadingForImage ? (
                  <VscDiffAdded
                    size={"5rem"}
                    className={`ml-4 mt-1 ${
                      !isImageUploading ? "cursor-pointer" : ""
                    }`}
                    color={isImageUploading ? "grey" : ""}
                    onClick={() => {
                      if (!isImageUploading) {
                        fileInputRef.current.click();
                      }
                    }}
                  />
                ) : null}
              </>
            ) : null}
          </div>
          {showNote || notesValue !== "" || notes !== "" ? (
            <textarea
              ref={notesRef}
              className="attachment-notes w-100 p-3"
              placeholder={i18n("global.WriteYourCommentHere")}
              value={notesValue}
              onChange={(e) => setNotesValue(e.target.value)}
              onBlur={() => {
                if (notesValue === "") {
                  setShowNote(false);
                }
                setNotes(notesValue);
              }}
            />
          ) : null}
        </fieldset>
      ) : null}
      <div style={{ direction: "rtl" }}>
        <input
          hidden
          type="file"
          multiple={false}
          ref={fileInputRef}
          onChange={selectImagehandler}
          accept="image/png, image/gif, image/jpeg"
        />
        <>
          <div className="col-12 d-flex mb-4">
            {notes !== "" || showNote ? (
              <img
                className="pt-3"
                src={attachmentMessageEdit}
                alt="AddNoteIcon2"
              />
            ) : (
              <Link
                to="#"
                className={`btn-link cursor-pointer ${
                  canComment ? "icon-red" : ""
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  setShowNote(true);
                  setTimeout(() => {
                    if (notesRef.current) {
                      notesRef.current.focus();
                    }
                  }, 100);
                }}
              >
                <img src={attachmentMessage} alt="AddNoteIcon1" />
              </Link>
            )}
            {!hideImage && (
              <div
                style={
                  canAttachPhoto && photo?.length < 1
                    ? {
                        filter:
                          "invert(37%) sepia(99%) saturate(7095%) hue-rotate(356deg) brightness(92%) contrast(129%)",
                      }
                    : {}
                }
                className={`btn-link`}
              >
                <div className="sr-text-block mt-0">
                  <div className="snipets-attachment cursor-pointer">
                    {photo?.length ? (
                      <img
                        style={{ filter: "none" }}
                        src={attachmentImageEdit}
                        alt="AddCamera"
                      />
                    ) : (
                      <img
                        src={attachmentImage}
                        alt="AddCameraEdit"
                        onClick={() => {
                          fileInputRef.current.click();
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
        {waitForImageUpload ? (
          <ImageEditorDialog
            show={
              openEditImageEditor ? openEditImageEditor : openImageEditorDialog
            }
            onHide={() =>
              openEditImageEditor
                ? setOpenEditImageEditor(false)
                : setOpenImageEditorDialog(false)
            }
            toEdit={
              openEditImageEditor && imageUrl
                ? imageUrl
                : image64Data
                ? image64Data
                : ""
            }
            onEdit={async (data) => {
              // setLoadingForImage(true);
              if (data && data.length > 20) {
                setIsImageUploading(true);
                let existingFileUrl = undefined;

                // showing images locally before uploading
                if (
                  imageUrl &&
                  imageEditing &&
                  openEditImageEditor &&
                  imageIndex !== undefined
                ) {
                  // if already uploaded image is selected so replacing it
                  imagesToShow[imageIndex] = { image: data };
                  setImagesToShow([...imagesToShow]);
                } else {
                  // new image is uploaded so adding it to array
                  setImagesToShow([...imagesToShow, { image: data }]);
                }
                if (data) {
                  // uploading image to aws bucket
                  await uploadImageWithThumbnail({
                    base64: data,
                    existingFileUrl,
                    fileType: "png",
                  })
                    .then((response) => {
                      setIsImageUploading(false);
                      setImageEditing(false);
                      setLoadingForImage(false);

                      if (
                        imageUrl &&
                        imageEditing &&
                        openEditImageEditor &&
                        imageIndex !== undefined
                      ) {
                        photo[imageIndex] = response.value.data;
                        setPhoto([...photo]);
                      } else {
                        photo.push(response.value.data);
                        setPhoto([...photo]);
                      }
                      imageAddHandler();
                    })
                    .catch(() => {
                      setIsImageUploading(false);
                      setImageEditing(false);
                      toast.error("Image uploading failed");
                      setImagesToShow([...imagesToShow]);
                    });
                } else {
                  setIsImageUploading(false);
                  setImageEditing(false);
                }
              } else {
                toast.error("Failed");
              }
            }}
            imageIndex={imageIndex}
            imagesToShow={imagesToShow}
            setImagesToShow={setImagesToShow}
            imageDeleteHandler={imageDeleteHandler}
            openEditImageEditor={openEditImageEditor}
            setLoadingForImage={setLoadingForImage}
            loadingForImage={loadingForImage}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ authentication }) => ({
  currentRole: authentication.currentRole,
});

const mapDispatchToProps = { uploadImageWithThumbnail };

export default connect(mapStateToProps, mapDispatchToProps)(Attachment);
