import React, { useState } from "react";
import TextArea from "../../../../../components/form-input/text-Area";
import Radio from "../../../../../components/form-input/radio.component";
import CheckBox from "../../../../../components/form-input/checkbox.component";
import ToggleSwitch from "../../../../../components/form-input/input.switch.component";
import I18n from "../../../languageSelector";
import { TEXT_DIRECTION } from "../../../../../helper/helperFunctions";

const ReportsEditFilterContent = ({ reportSetting, setReportSettings }) => {
  const [showStatistics, setShowStatistics] = useState(true);
  return (
    <>
      <ul className="list-unstyled px-4 m-0 font-weight-bold">
        <li className="row mb-0">
          <div className={`${TEXT_DIRECTION() === "rtl" ? "" : "col-1 pl-1"}`}>
            <ToggleSwitch
              id="date"
              selected={reportSetting?.content?.dateTime}
              checked={reportSetting?.content?.dateTime}
              onChange={(e) => {
                setReportSettings({
                  ...reportSetting,
                  content: {
                    ...reportSetting.content,
                    dateTime: !e.target.selected,
                  },
                });
              }}
            />
          </div>
          <div
            style={{ color: "#20639b" }}
            className={`${TEXT_DIRECTION() === "rtl" ? "col-6" : "col-6 pl-3"}`}
          >
            <label className="mb-0" style={{ color: "#20639b" }}>
              {<I18n lng="Form.Date&Time" />}
            </label>
          </div>
        </li>
        <li className="row mb-0">
          <div className={`${TEXT_DIRECTION() === "rtl" ? "" : "col-1 pl-1"}`}>
            <ToggleSwitch
              id="docNumber"
              selected={reportSetting?.content?.docNumber}
              checked={reportSetting?.content?.docNumber}
              onChange={(e) => {
                setReportSettings({
                  ...reportSetting,
                  content: {
                    ...reportSetting.content,
                    docNumber: !e.target.selected,
                  },
                });
              }}
            />
          </div>
          <div
            style={{ color: "#20639b" }}
            className={`${TEXT_DIRECTION() === "rtl" ? "col-6" : "col-6 pl-3"}`}
          >
            <label className="mb-0" style={{ color: "#20639b" }}>
              {<I18n lng="global.DocumentNo" />}
            </label>
          </div>
        </li>
        <li className="row mb-0">
          <div className={`${TEXT_DIRECTION() === "rtl" ? "" : "col-1 pl-1"}`}>
            <ToggleSwitch
              id="reportStatistics"
              selected={reportSetting?.content?.reportStatistics}
              checked={
                reportSetting?.content?.scores ||
                reportSetting?.content?.failsCount ||
                reportSetting?.content?.unAnsweredQuestions
              }
              onChange={(e) => {
                setShowStatistics(!showStatistics);

                setReportSettings({
                  ...reportSetting,
                  content: {
                    ...reportSetting.content,
                    reportStatistics: !showStatistics,
                    scores: !showStatistics,
                    failsCount: !showStatistics,
                    unAnsweredQuestions: !showStatistics,
                  },
                });
              }}
            />
          </div>
          <div
            style={{ color: "#20639b" }}
            className={`${TEXT_DIRECTION() === "rtl" ? "col-6" : "col-6 pl-3"}`}
          >
            <label className="mb-0" style={{ color: "#20639b" }}>
              {<I18n lng="global.Reports" />} {<I18n lng="global.Statistics" />}
            </label>
          </div>
        </li>
        <li>
          {showStatistics && (
            <ul
              className="w-100"
              style={
                TEXT_DIRECTION() === "rtl"
                  ? { paddingRight: "18px", paddingLeft: "0" }
                  : { paddingLeft: "12px" }
              }
            >
              <li className="row mb-0">
                <div
                  className={`${
                    TEXT_DIRECTION() === "rtl" ? "" : "col-1 pl-1"
                  }`}
                >
                  <CheckBox
                    name="Scores"
                    checked={reportSetting?.content?.scores}
                    onChange={(e) =>
                      setReportSettings({
                        ...reportSetting,
                        content: {
                          ...reportSetting.content,
                          scores: e.target.checked,
                        },
                      })
                    }
                  />
                </div>
                <div
                  style={{ color: "#20639b" }}
                  className={`${
                    TEXT_DIRECTION() === "rtl" ? "col-6" : "col-6 pl-3"
                  }`}
                >
                  <label className="mb-0" style={{ color: "#20639b" }}>
                    {<I18n lng="Form.Score" />}
                  </label>
                </div>
              </li>
              <li className="row mb-0">
                <div
                  className={`${
                    TEXT_DIRECTION() === "rtl" ? "" : "col-1 pl-1"
                  }`}
                >
                  <CheckBox
                    name="failsCount"
                    checked={reportSetting?.content?.failsCount}
                    onChange={(e) =>
                      setReportSettings({
                        ...reportSetting,
                        content: {
                          ...reportSetting.content,
                          failsCount: e.target.checked,
                        },
                      })
                    }
                  />
                </div>
                <div
                  style={{ color: "#20639b" }}
                  className={`${
                    TEXT_DIRECTION() === "rtl" ? "col-6" : "col-6 pl-3"
                  }`}
                >
                  <label className="mb-0" style={{ color: "#20639b" }}>
                    {<I18n lng="Reports.FailedItems" />}
                  </label>
                </div>
              </li>
              <li className="row mb-0">
                <div
                  className={`${
                    TEXT_DIRECTION() === "rtl" ? "" : "col-1 pl-1"
                  }`}
                >
                  <CheckBox
                    name="unAnsweredQue"
                    checked={reportSetting?.content?.unAnsweredQuestions}
                    onChange={(e) =>
                      setReportSettings({
                        ...reportSetting,
                        content: {
                          ...reportSetting.content,
                          unAnsweredQuestions: e.target.checked,
                        },
                      })
                    }
                  />
                </div>
                <div
                  style={{ color: "#20639b" }}
                  className={`${
                    TEXT_DIRECTION() === "rtl" ? "col-6" : "col-6 pl-3"
                  }`}
                >
                  <label className="mb-0" style={{ color: "#20639b" }}>
                    {<I18n lng="Form.TotalAnswered" />}
                  </label>
                </div>
              </li>
            </ul>
          )}
        </li>
        <li className="row mb-0">
          <div className={`${TEXT_DIRECTION() === "rtl" ? "" : "col-1 pl-1"}`}>
            <ToggleSwitch
              id="failsSummary"
              selected={reportSetting?.content?.failsSummary}
              checked={reportSetting?.content?.failsSummary}
              onChange={(e) => {
                setReportSettings({
                  ...reportSetting,
                  content: {
                    ...reportSetting.content,
                    failsSummary: !e.target.selected,
                  },
                });
              }}
            />
          </div>
          <div
            style={{ color: "#20639b" }}
            className={`${TEXT_DIRECTION() === "rtl" ? "col-9" : "col-9 pl-3"}`}
          >
            <label className="mb-0" style={{ color: "#20639b" }}>
              {<I18n lng="Reports.FailedItems" />}{" "}
              {<I18n lng="Subscriptions.Summary" />}
            </label>
          </div>
        </li>
        <li className="row mb-0">
          <div className={`${TEXT_DIRECTION() === "rtl" ? "" : "col-1 pl-1"}`}>
            <ToggleSwitch
              id="instructions"
              selected={reportSetting?.content?.instructions}
              checked={reportSetting?.content?.instructions}
              onChange={(e) => {
                setReportSettings({
                  ...reportSetting,
                  content: {
                    ...reportSetting.content,
                    instructions: !e.target.selected,
                  },
                });
              }}
            />
          </div>
          <div
            style={{ color: "#20639b" }}
            className={`${TEXT_DIRECTION() === "rtl" ? "col-9" : "col-9 pl-3"}`}
          >
            <label className="mb-0" style={{ color: "#20639b" }}>
              {<I18n lng="Form.Instruction" />}
            </label>
          </div>
        </li>
        <li className="row mb-0">
          <div className={`${TEXT_DIRECTION() === "rtl" ? "" : "col-1 pl-1"}`}>
            <ToggleSwitch
              id="inspectionQue"
              checked={reportSetting.content.inspectionQuestions}
              onChange={(e) =>
                setReportSettings({
                  ...reportSetting,
                  content: {
                    ...reportSetting.content,
                    inspectionQuestions: e.target.checked,
                  },
                })
              }
            />
          </div>
          <div
            style={{ color: "#20639b" }}
            className={`${TEXT_DIRECTION() === "rtl" ? "col-9" : "col-9 pl-3"}`}
          >
            <label className="mb-0" style={{ color: "#20639b" }}>
              {<I18n lng="global.InspectionQuestions" />}
            </label>
          </div>
          {reportSetting.content.inspectionQuestions && (
            <ul
              className="w-100"
              style={
                TEXT_DIRECTION() === "rtl"
                  ? { paddingRight: "25px", paddingLeft: "0" }
                  : { paddingLeft: "27px" }
              }
            >
              <li className="row mb-0">
                <div
                  className={`${
                    TEXT_DIRECTION() === "rtl" ? "" : "col-1 pl-1"
                  }`}
                >
                  <Radio
                    id="answered"
                    name="inspectionQue"
                    checked={reportSetting.content.answered}
                    onClick={(e) =>
                      setReportSettings({
                        ...reportSetting,
                        content: {
                          ...reportSetting.content,
                          answered: e.target.checked,
                          unAnswered: false,
                          all: false,
                        },
                      })
                    }
                  />
                </div>
                <div
                  style={{ color: "#20639b" }}
                  className={`${
                    TEXT_DIRECTION() === "rtl" ? "col-9" : "col-9 pl-3"
                  }`}
                >
                  <label className="mb-0" style={{ color: "#20639b" }}>
                    {<I18n lng="Form.Answered" />}
                  </label>
                </div>
              </li>
              <li className="row mb-0">
                <div
                  className={`${
                    TEXT_DIRECTION() === "rtl" ? "" : "col-1 pl-1"
                  }`}
                >
                  <Radio
                    id="unAnswered"
                    name="inspectionQue"
                    checked={reportSetting.content.unAnswered}
                    onClick={(e) =>
                      setReportSettings({
                        ...reportSetting,
                        content: {
                          ...reportSetting.content,

                          answered: false,
                          unAnswered: e.target.checked,
                          all: false,
                        },
                      })
                    }
                  />
                </div>
                <div
                  style={{ color: "#20639b" }}
                  className={`${
                    TEXT_DIRECTION() === "rtl" ? "col-10" : "col-10 pl-3"
                  }`}
                >
                  <label className="mb-0" style={{ color: "#20639b" }}>
                    {<I18n lng="Form.UnAnsweredQuestions" />}
                  </label>
                </div>
              </li>
              <li className="row mb-0">
                <div
                  className={`${
                    TEXT_DIRECTION() === "rtl" ? "" : "col-1 pl-1"
                  }`}
                >
                  <Radio
                    id="all"
                    name="inspectionQue"
                    checked={reportSetting.content.all}
                    onClick={(e) =>
                      setReportSettings({
                        ...reportSetting,
                        content: {
                          ...reportSetting.content,
                          answered: false,
                          unAnswered: false,
                          all: e.target.checked,
                        },
                      })
                    }
                  />
                </div>
                <div
                  style={{ color: "#20639b" }}
                  className={`${
                    TEXT_DIRECTION() === "rtl" ? "col-9" : "col-9 pl-3"
                  }`}
                >
                  <label className="mb-0" style={{ color: "#20639b" }}>
                    {<I18n lng="global.All" />}
                  </label>
                </div>
              </li>
            </ul>
          )}
        </li>
        <li className="row mb-0">
          <div className={`${TEXT_DIRECTION() === "rtl" ? "" : "col-1 pl-1"}`}>
            <ToggleSwitch
              id="photos"
              selected={reportSetting?.content?.photos}
              checked={reportSetting?.content?.photos}
              onChange={(e) => {
                setReportSettings({
                  ...reportSetting,
                  content: {
                    ...reportSetting.content,
                    photos: !e.target.selected,
                  },
                });
              }}
            />
          </div>
          <div
            style={{ color: "#20639b" }}
            className={`${TEXT_DIRECTION() === "rtl" ? "col-9" : "col-9 pl-3"}`}
          >
            <label className="mb-0" style={{ color: "#20639b" }}>
              {<I18n lng="global.Photo" />}
            </label>
          </div>

          {reportSetting.content.photos && (
            <ul
              className="w-100"
              style={
                TEXT_DIRECTION() === "rtl"
                  ? { paddingRight: "25px", paddingLeft: "0" }
                  : { paddingLeft: "27px" }
              }
            >
              <li className="row mb-0">
                <div
                  className={`${
                    TEXT_DIRECTION() === "rtl" ? "" : "col-1 pl-1"
                  }`}
                >
                  <Radio
                    id="photoStandardQuality"
                    name="photos"
                    checked={reportSetting.content.standardQuality}
                    onClick={(e) =>
                      setReportSettings({
                        ...reportSetting,
                        content: {
                          ...reportSetting.content,
                          standardQuality: e.target.checked,
                          highQuality: false,
                        },
                      })
                    }
                  />
                </div>
                <div
                  style={{ color: "#20639b" }}
                  className={`${
                    TEXT_DIRECTION() === "rtl" ? "col-9" : "col-9 pl-3"
                  }`}
                >
                  <label className="mb-0" style={{ color: "#20639b" }}>
                    {<I18n lng="global.StandardQuality" />}
                  </label>
                </div>
              </li>
              <li className="row mb-0">
                <div
                  className={`${
                    TEXT_DIRECTION() === "rtl" ? "" : "col-1 pl-1"
                  }`}
                >
                  <Radio
                    id="photoHighQuality"
                    name="photos"
                    checked={reportSetting.content.highQuality}
                    onClick={(e) =>
                      setReportSettings({
                        ...reportSetting,
                        content: {
                          ...reportSetting.content,
                          standardQuality: false,
                          highQuality: e.target.checked,
                        },
                      })
                    }
                  />
                </div>
                <div
                  style={{ color: "#20639b" }}
                  className={`${
                    TEXT_DIRECTION() === "rtl" ? "col-9" : "col-9 pl-3"
                  }`}
                >
                  <label className="mb-0" style={{ color: "#20639b" }}>
                    {<I18n lng="global.HighQuality" />}
                  </label>
                </div>
              </li>
            </ul>
          )}
        </li>
        <li className="row mb-0">
          <div className={`${TEXT_DIRECTION() === "rtl" ? "" : "col-1 pl-1"}`}>
            <ToggleSwitch
              id="pdfLinksQR"
              selected={reportSetting?.content?.PdfLinksQrCode}
              checked={reportSetting?.content?.PdfLinksQrCode}
              onChange={(e) => {
                setReportSettings({
                  ...reportSetting,
                  content: {
                    ...reportSetting.content,
                    PdfLinksQrCode: !e.target.selected,
                  },
                });
              }}
            />
          </div>
          <div
            style={{ color: "#20639b" }}
            className={`${TEXT_DIRECTION() === "rtl" ? "col-9" : "col-9 pl-3"}`}
          >
            <label className="mb-0" style={{ color: "#20639b" }}>
              {<I18n lng="global.QrCodes" />}
            </label>
          </div>
        </li>
        <li className="row mb-0">
          <div className={`${TEXT_DIRECTION() === "rtl" ? "" : "col-1 pl-1"}`}>
            <ToggleSwitch
              id="tableOfContents"
              selected={reportSetting?.content?.tableOfContents}
              checked={reportSetting?.content?.tableOfContents}
              onChange={(e) => {
                setReportSettings({
                  ...reportSetting,
                  content: {
                    ...reportSetting.content,
                    tableOfContents: !e.target.selected,
                  },
                });
              }}
            />
          </div>
          <div
            style={{ color: "#20639b" }}
            className={`${TEXT_DIRECTION() === "rtl" ? "col-9" : "col-9 pl-3"}`}
          >
            <label className="mb-0" style={{ color: "#20639b" }}>
              {<I18n lng="Reports.TableOfContents" />}
            </label>
          </div>
        </li>
        <li className="row mb-0">
          <div className={`${TEXT_DIRECTION() === "rtl" ? "" : "col-1 pl-1"}`}>
            <ToggleSwitch
              id="companyInfo"
              selected={reportSetting?.content?.companyInformation}
              checked={reportSetting?.content?.companyInformation}
              onChange={(e) => {
                setReportSettings({
                  ...reportSetting,
                  content: {
                    ...reportSetting.content,
                    companyInformation: !e.target.selected,
                  },
                });
              }}
            />
          </div>
          <div
            style={{ color: "#20639b" }}
            className={`${TEXT_DIRECTION() === "rtl" ? "col-9" : "col-9 pl-3"}`}
          >
            <label className="mb-0" style={{ color: "#20639b" }}>
              {<I18n lng="CompanySetup.Company" />}{" "}
              {<I18n lng="UserProfile.Informations" />}
            </label>
          </div>
        </li>
        <li className="row mb-0">
          <div className={`${TEXT_DIRECTION() === "rtl" ? "" : "col-1 pl-1"}`}>
            <ToggleSwitch
              id="disclaimer"
              checked={reportSetting?.content?.showDisclaimer}
              selected={reportSetting?.content?.showDisclaimer}
              onChange={(e) => {
                setReportSettings({
                  ...reportSetting,
                  content: {
                    ...reportSetting.content,
                    showDisclaimer: !e.target.selected,
                  },
                });
              }}
            />
          </div>
          <div
            style={{ color: "#20639b" }}
            className={`${TEXT_DIRECTION() === "rtl" ? "col-9" : "col-9 pl-3"}`}
          >
            <label className="mb-0" style={{ color: "#20639b" }}>
              {<I18n lng="Reports.Disclaimer" />}
            </label>
          </div>
        </li>
        {reportSetting.content?.showDisclaimer && (
          <TextArea
            rows="5"
            value={reportSetting?.content?.disclaimer}
            onChange={(e) => {
              setReportSettings({
                ...reportSetting,
                content: {
                  ...reportSetting.content,
                  disclaimer: e.target.value,
                },
              });
            }}
          />
        )}
        <li className="row mb-0">
          <div className={`${TEXT_DIRECTION() === "rtl" ? "" : "col-1 pl-1"}`}>
            <ToggleSwitch
              id="footerId"
              checked={reportSetting?.content?.footer}
              selected={reportSetting?.content?.footer}
              onChange={(e) =>
                setReportSettings({
                  ...reportSetting,
                  content: {
                    ...reportSetting.content,
                    footer: e.target.checked,
                  },
                })
              }
            />
          </div>
          <div
            style={{ color: "#20639b" }}
            className={`${TEXT_DIRECTION() === "rtl" ? "col-9" : "col-9 pl-3"}`}
          >
            <label className="mb-0" style={{ color: "#20639b" }}>
              {<I18n lng="global.Footer" />}
            </label>
          </div>
          {reportSetting.content.footer && (
            <ul
              className="w-100"
              style={
                TEXT_DIRECTION() === "rtl"
                  ? { paddingRight: "32px", paddingLeft: "0" }
                  : { paddingLeft: "30px" }
              }
            >
              <li className="row mb-0">
                <div
                  className={`${
                    TEXT_DIRECTION() === "rtl" ? "" : "col-1 pl-1"
                  }`}
                >
                  <CheckBox
                    name="pageIdFooter"
                    checked={reportSetting?.content?.page}
                    onChange={(e) =>
                      setReportSettings({
                        ...reportSetting,
                        content: {
                          ...reportSetting.content,
                          page: e.target.checked,
                        },
                      })
                    }
                  />
                </div>
                <div
                  style={{ color: "#20639b" }}
                  className={`${
                    TEXT_DIRECTION() === "rtl" ? "col-9" : "col-9 pl-3"
                  }`}
                >
                  <label className="mb-0" style={{ color: "#20639b" }}>
                    {<I18n lng="Form.Page" />}
                  </label>
                </div>
              </li>
              <li className="row mb-0">
                <div
                  className={`${
                    TEXT_DIRECTION() === "rtl" ? "" : "col-1 pl-1"
                  }`}
                >
                  <CheckBox
                    name="pageIdTitle"
                    checked={reportSetting?.content?.title}
                    onChange={(e) =>
                      setReportSettings({
                        ...reportSetting,
                        content: {
                          ...reportSetting.content,
                          title: e.target.checked,
                        },
                      })
                    }
                  />
                </div>
                <div
                  style={{ color: "#20639b" }}
                  className={`${
                    TEXT_DIRECTION() === "rtl" ? "col-9" : "col-9 pl-3"
                  }`}
                >
                  <label className="mb-0" style={{ color: "#20639b" }}>
                    {<I18n lng="global.SiteName" />}
                  </label>
                </div>
              </li>
              <li className="row mb-0">
                <div
                  className={`${
                    TEXT_DIRECTION() === "rtl" ? "" : "col-1 pl-1"
                  }`}
                >
                  <CheckBox
                    name="pageIdDate"
                    checked={reportSetting?.content?.footerDate}
                    onChange={(e) =>
                      setReportSettings({
                        ...reportSetting,
                        content: {
                          ...reportSetting.content,
                          footerDate: e.target.checked,
                        },
                      })
                    }
                  />
                </div>
                <div
                  style={{ color: "#20639b" }}
                  className={`${
                    TEXT_DIRECTION() === "rtl" ? "col-9" : "col-9 pl-3"
                  }`}
                >
                  <label className="mb-0" style={{ color: "#20639b" }}>
                    {<I18n lng="Form.Date" />}
                  </label>
                </div>
              </li>
              <div className="report-bar">
                <li className="row mb-0">
                  <div
                    className={`${
                      TEXT_DIRECTION() === "rtl" ? "" : "col-1 pl-1"
                    }`}
                  >
                    <CheckBox
                      name="pageIdInspectorName"
                      checked={reportSetting?.content?.inspectorName}
                      onChange={(e) =>
                        setReportSettings({
                          ...reportSetting,
                          content: {
                            ...reportSetting.content,
                            inspectorName: e.target.checked,
                          },
                        })
                      }
                    />
                  </div>
                  <div
                    style={{ color: "#20639b" }}
                    className={`${
                      TEXT_DIRECTION() === "rtl" ? "col-10" : "col-10 pl-3"
                    }`}
                  >
                    <label className="mb-0" style={{ color: "#20639b" }}>
                      {<I18n lng="global.Inspector" />}{" "}
                      {<I18n lng="global.Name" />}
                    </label>
                  </div>
                </li>
              </div>
            </ul>
          )}
        </li>
      </ul>
    </>
  );
};

export default ReportsEditFilterContent;
