import React, { useEffect, useState } from "react";
import moment from "moment";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import "../../assets/css/auth.min.css";
import { useForm } from "react-hook-form";
import "react-phone-number-input/style.css";
import IconEye from "../../assets/images/icon-eye.svg";
import I18n, { i18n } from "../private/languageSelector";
import IconUser from "../../assets/images/icon-user.svg";
import IconLock from "../../assets/images/icon-lock.svg";
import { isValidPhoneNumber } from "react-phone-number-input";
import { Link, useHistory, Redirect } from "react-router-dom";
import IconEmail from "../../assets/images/icon-envelope.svg";
import Input from "../../components/form-input/input.component";
import Checkbox from "../../components/form-input/checkbox.component";
import PhoneNumber from "../../components/form-input/phone-number.component";
import { MIN_LENGTH, MAX_LENGTH, EMAIL_REGEX, PASSWORD_REGEX } from "../../config/validations";
import { registerUser, tokenCheckApi } from "../../redux/reducers/authentication/authentication";
import AlertSigupDialog from "../../components/dialogs/alertSignupDialog";
import { TEXT_DIRECTION } from "../../helper/helperFunctions";

const registerInitialValues = {
  firstName: "",
  lastName: "",
  email: "",
  mobile: "",
  password: "",
  termsNConditions: false,
};

const Signup = ({ registerUser, isAuthenticated, location, tokenCheckApi }) => {
  const history = useHistory();

  const {
    reset,
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm({ defaultValues: registerInitialValues });

  const termsNConditions = watch("termsNConditions");
  const { from } = location.state || {
    from: { pathname: "/", search: location.search },
  };

  const [userToken, setUserToken] = useState("");
  const [registerData, setRegisterData] = useState({});
  const [errorForUser, setErrorForUser] = useState("");
  const [alertDialog, setAlertDialog] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [ip, setIp] = useState("");
  useEffect(() => {
    setErrorForUser("");
    setAlertDialog(false);
    setRegisterData({});
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => {
        setIp(data?.ip);
      });
  }, []);

  if (isAuthenticated) {
    return <Redirect to={from} />;
  }
  const onSubmit = (data) => {
    setRegisterData({ ...data });

    registerUser({
      ...data,
      isUserSeated: true,
      ip: ip,
      confirmation: false,
      assignForm: { app: true, email: false, sms: false },
      reportSubmission: { app: true, email: false, sms: false },
      approvalReport: { app: true, email: false, sms: false },
      rejectReport: { app: true, email: false, sms: false },
    })
      .then(() => {
        reset(registerInitialValues);
        history.push("/login");
      })
      .catch((error) => {
        if (error?.response?.data?.message !== "Email already taken") {
          if (JSON.parse(error?.response.data.message)?.companyName) {
            setAlertDialog(true);
            setErrorForUser(JSON.parse(error.response.data.message));
            let jsonData = JSON.parse(error.response.data.message);
            setUserToken(jsonData.userToken.token);
          } else if (!JSON.parse(error?.response.data.message)?.companyName) {
            toast.error(error?.response.data.message);
          }
        } else {
          if (error.response.data.message === "Email already taken") {
            toast.error(error?.response.data.message);
          } else {
            toast.error(<I18n lng="validation.AN_ERROR_OCCURED" />);
          }
        }
      });
  };

  const confirmationRegisteration = () => {
    if (registerData) {
      registerUser({
        ...registerData,
        ip: ip,
        confirmation: true,
      })
        .then(() => {
          tokenCheckApi({
            token: userToken,
            type: "activateLink",
          }).then(() => {
            reset(registerInitialValues);
            history.push("/login");
          });
        })
        .catch((error) => {
          if (error?.response) {
            toast.error(error?.response?.data?.message);
          } else {
            toast.error(<I18n lng="validation.AN_ERROR_OCCURED" />);
          }
        });
    }
  };
  return (
    <div className="auth-wrapper">
      <div className="auth-title">
        <h1 className="text-center">
          <I18n lng="SignUp.SignUpToTAMAM" />
        </h1>
        <p className="text-center">
          <I18n lng="SignUp.EnterYourDetailsToCreateYourAccount" />
        </p>
      </div>
      <form autoComplete="false" onSubmit={handleSubmit(onSubmit)}>
        <Input
          label={<I18n lng="global.FirstName" />}
          prefix={IconUser}
          id="firstNameInput"
          name="firstName"
          type="text"
          placeholder={i18n("global.FirstName")}
          register={register}
          validations={{
            required: {
              value: true,
              message: i18n("validation.REQUIRED_FIELD"),
            },
            minLength: { value: 3, message: MIN_LENGTH(3) },
            maxLength: { value: 50, message: MAX_LENGTH(50) },
          }}
          error={errors["firstName"]}
        />
        <Input
          label={<I18n lng="global.LastName" />}
          prefix={IconUser}
          id="lastNameInput"
          name="lastName"
          type="text"
          placeholder={i18n("global.LastName")}
          register={register}
          validations={{
            required: {
              value: true,
              message: i18n("validation.REQUIRED_FIELD"),
            },
            minLength: { value: 3, message: MIN_LENGTH(3) },
            maxLength: { value: 50, message: MAX_LENGTH(50) },
          }}
          error={errors["lastName"]}
        />
        <Input
          label={<I18n lng="global.Email" />}
          prefix={IconEmail}
          id="emailInput"
          name="email"
          type="email"
          placeholder={i18n("global.Email")}
          register={register}
          validations={{
            required: {
              value: true,
              message: i18n("validation.REQUIRED_FIELD"),
            },
            minLength: { value: 3, message: MIN_LENGTH(3) },
            maxLength: { value: 50, message: MAX_LENGTH(50) },
            pattern: {
              value: EMAIL_REGEX,
              message: i18n("validation.INVALID_EMAIL"),
            },
          }}
          error={errors["email"]}
        />
        <PhoneNumber
          label={<I18n lng="global.Mobile" />}
          name={"mobile"}
          id={"mobileInput"}
          register={register}
          control={control}
          validations={{
            required: {
              value: true,
              message: i18n("validation.REQUIRED_FIELD"),
            },
            validate: isValidPhoneNumber,
          }}
          error={errors["mobile"]}
        />
        <div className={`input-password ${!showPassword ? "hide-password" : ""}`}>
          <Input
            label={<I18n lng="global.Password" />}
            prefix={IconLock}
            suffix={IconEye}
            suffixAction={() => {
              setShowPassword(!showPassword);
            }}
            id="passwordInput"
            name="password"
            type={showPassword ? "text" : "password"}
            placeholder={i18n("global.Password")}
            register={register}
            validations={{
              required: {
                value: true,
                message: i18n("validation.REQUIRED_FIELD"),
              },
              minLength: { value: 3, message: MIN_LENGTH(3) },
              maxLength: { value: 20, message: MAX_LENGTH(20) },
              pattern: {
                value: PASSWORD_REGEX,
                message: i18n("validation.INVALID_PASSWORD"),
              },
            }}
            error={errors["password"]}
          />
        </div>
        <small className="form-info">
          <I18n lng="SignUp.PasswordMustBeAtLeast" />
        </small>
        <div className="row">
          <div className={`${TEXT_DIRECTION() === "rtl" ? "mr-3" : "col-1 "}`}>
            <Checkbox
              name="termsNConditions"
              register={register}
              validations={{
                required: {
                  value: true,
                  message: i18n("validation.REQUIRED_FIELD"),
                },
              }}
              error={errors["termsNConditions"]}
            />
          </div>
          <div style={{ color: "#20639b" }} className={`${TEXT_DIRECTION() === "rtl" ? "col-9 pr-1" : "col-9 pl-3"}`}>
            <label className="mb-0 mx-1">
              {<I18n lng="SignUp.IAgreeThe" />}
              <Link style={{ color: "#20639b" }} to="/terms-and-conditions">
                <I18n lng="SignUp.Terms&Conditions" />
              </Link>
            </label>
          </div>
        </div>

        <div className="btn-wrap text-center">
          <button type="submit" className="btn btn-primary" disabled={!termsNConditions}>
            <I18n lng="SignUp.SignUp" />
          </button>
        </div>
        <p className="auth-redirect justify-content-center">
          <span className="mr-1 ml-1 float-left">
            <I18n lng="login.AlreadyHaveAnAccount" />
          </span>
          <strong>
            <Link to="/login">
              <I18n lng="login.Login" />
            </Link>
          </strong>
        </p>
      </form>
      <AlertSigupDialog
        showDlg={alertDialog}
        title={<I18n lng="global.Note" />}
        classes="modal-alert"
        iconCloseDlg
        content={
          <p>
            {i18n("Company.Email")} {"   "}
            <b style={{ color: "black" }}>{errorForUser?.email}</b>
            {"   "} {i18n("global.HasGotAsInvitationToJoin")}
            {"   "}
            <b style={{ color: "black" }}>{errorForUser?.companyName}</b> <br />
            <br />
            {i18n("global.VisitYourEmail")} {moment(errorForUser?.createdAt).format("LL")}, {i18n("global.AndContinueYourSignup")}{" "}
            {errorForUser?.companyName} <br />
            <br />
            {i18n("global.AlternativelyYouCanContinue")}
          </p>
        }
        primaryActionTitle={<I18n lng="global.Continue" />}
        secondryActionTitle={<I18n lng="global.Close" />}
        secondaryAction={() => {
          setAlertDialog(false);
          setErrorForUser("");
        }}
        primaryAction={confirmationRegisteration}
        secondryActionClasses={"btn-white"}
      />
    </div>
  );
};
const mapStateToProps = ({ authentication }) => ({
  isAuthenticated: authentication.isAuthenticated,
});

const mapDispatchToProps = { registerUser, tokenCheckApi };
export default connect(mapStateToProps, mapDispatchToProps)(Signup);
