import React, { useState, useMemo } from "react";
import IconMenu from "../../../../../../assets/images/icon-menu.svg";
import IconEye from "../../../../../../assets/images/icon-eye.svg";
import IconDelete from "../../../../../../assets/images/icon-delete.svg";
import IconPencil from "../../../../../../assets/images/icon-pencil.svg";
import { useUserUIContext } from "../users-ui-context";
import I18n from "../../../../languageSelector";
// import IconUserStar from "../../../../../../assets/images/icon-premium.svg";
// import IconUserVector from "../../../../../../assets/images/userVector-icon.svg";

import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { connect } from "react-redux";
// import toast from "react-hot-toast";

const ListItemMenu = ({ id, userRole, user, selectedUser, currentRole }) => {
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const userUIContext = useUserUIContext();
  const userUIProps = useMemo(() => {
    return {
      openUserViewDialog: userUIContext.openUserViewDialog,
      openUserEditDialog: userUIContext.openUserEditDialog,
      openUserDeleteDialog: userUIContext.openUserDeleteDialog,
      createPremiumUser: userUIContext.createPremiumUser,
    };
  }, [userUIContext]);

  return (
    <>
      {currentRole !== "admin" && selectedUser.role === "admin" ? null : (
        <>
          <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle>
              <img src={IconMenu} alt="Menu" />
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem
                className="action action-view"
                onClick={(e) => {
                  e.preventDefault();
                  userUIProps.openUserViewDialog(id);
                }}
              >
                <span className="icon-wrap">
                  <img src={IconEye} alt="IconEye" />
                </span>
                <I18n lng="global.View" />
              </DropdownItem>
              <DropdownItem
                className="action action-edit"
                onClick={(e) => {
                  e.preventDefault();
                  userUIProps.openUserEditDialog(id);
                }}
              >
                <span className="icon-wrap">
                  <img src={IconPencil} alt="IconPencil" />
                </span>
                <I18n lng="global.Edit" />
              </DropdownItem>{" "}
              {/* {!selectedUser?.isPremium && selectedUser.role !== "admin" ? (
                <DropdownItem
                  className="action action-view"
                  onClick={(e) => {
                    if (user?.subscription?.availablePremiumUsers > 0) {
                      e.preventDefault();
                      userUIProps.createPremiumUser(selectedUser);
                    } else {
                      toast.error("No seats available");
                    }
                  }}
                >
                  <span className="icon-wrap">
                    <img src={IconUserStar} alt="IconEye" />
                  </span> 
                  <I18n lng="global.PremiumUser" />
                </DropdownItem>
              ) : selectedUser.role !== "admin" ? (
                <DropdownItem
                  className="action action-view"
                  onClick={(e) => {
                    e.preventDefault();
                    userUIProps.createPremiumUser(selectedUser);
                  }}
                >
                  <span className="icon-wrap">
                    <img src={IconUserVector} alt="IconEye" />
                  </span>
                  <I18n lng="global.VirtualUser" />
                </DropdownItem>
              ) : null} */}
              {userRole !== "admin" && user?.email !== selectedUser?.email ? (
                <DropdownItem
                  className="action action-delete"
                  onClick={(e) => {
                    e.preventDefault();
                    userUIProps.openUserDeleteDialog(id);
                  }}
                >
                  <span className="icon-wrap">
                    <img src={IconDelete} alt="IconArchive" />
                  </span>
                  <I18n lng="global.Delete" />
                </DropdownItem>
              ) : null}
            </DropdownMenu>
          </Dropdown>
        </>
      )}
    </>
  );
};
const mapStateToProps = ({ authentication }) => ({
  user: authentication.user,
  currentRole: authentication.currentRole,
});

export default connect(mapStateToProps, null)(ListItemMenu);
