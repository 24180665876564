import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import IconCloseDialog from "../../assets/images/icon-close-dialog.svg";

const ConfirmationDialog = ({
  showDlg,
  iconCloseDlg,
  secondaryAction,
  primaryAction,
  primaryActionTitle,
  primaryActionClasses,
  secondryActionTitle,
  secondryActionClasses,
  content,
  title,
  classes,
}) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(showDlg);
  }, [showDlg]);

  return (
    <Modal
      isOpen={show}
      toggle={() => secondaryAction()}
      size={"sm"}
      className={classes}
    >
      {iconCloseDlg && (
        <Link
          to="#"
          className="modal-close px-3 py-2"
          onClick={() => secondaryAction()}
        >
          <img src={IconCloseDialog} alt="IconCloseDialog" />
        </Link>
      )}
      <ModalBody>
        <div className="modal-title text-center">
          <strong>{title}</strong>
        </div>
        {content}

        <div className="btn-bar d-flex justify-content-center">
          <button
            className={`btn ${
              primaryActionClasses ? primaryActionClasses : "btn-primary"
            }`}
            onClick={() => primaryAction()}
          >
            {primaryActionTitle}
          </button>
          <button
            className={`ml-2 mr-2 btn ${
              secondryActionClasses ? secondryActionClasses : "btn-primary"
            }`}
            onClick={() => secondaryAction()}
          >
            {secondryActionTitle}
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ConfirmationDialog;
