import { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { Storage } from "react-jhipster";
import { connect } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import io from "socket.io-client";
import "./assets/scss/themes/css/stylesManager.min.css";
import { AUTH_TOKEN_KEY } from "./config/constants";
import AppLayout from "./container/app-layout";
import { notificationAlert } from "./helper/notifications";
import { getAdminManagerReports } from "./redux/reducers/admin-manager-reports/admin-manager-report.reducer";
import { getSession } from "./redux/reducers/authentication/authentication";
import { getAllNotifications } from "./redux/reducers/notifications/notifications.reducer";
import { getAssignedFormsForUser, getForms } from "./redux/reducers/form/form.reducer";

const App = ({ user, getSession, currentRole, currentRoute, getForms, getAllNotifications, getAdminManagerReports, getAssignedFormsForUser }) => {
  const [theme, setTheme] = useState("");
  const [socket, setSocket] = useState();
  const [shouldReload, setShouldReload] = useState(false);

  let token = Storage.local.get(AUTH_TOKEN_KEY) || Storage.session.get(AUTH_TOKEN_KEY);

  useEffect(() => {
    getSession();
    getAllNotifications();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const htmlElement = document.getElementsByTagName("html")[0];
    const userLanguage = user?.language || localStorage.getItem("language");

    const isArabic = userLanguage === "ARABIC";
    const direction = isArabic ? "rtl" : "ltr";

    htmlElement.setAttribute("dir", direction);
  }, [token, user?.language]);

  useEffect(() => {
    if (currentRoute.formScrol) {
      window.showScroll = false;
    } else {
      window.showScroll = true;
    }
  }, [currentRoute.formScrol]);

  // sockets

  useEffect(() => {
    if (user && !socket && token) {
      const chat = io(process.env.REACT_APP_SERVER_URL_SOCKET, {
        query: {
          token: token,
        },
      });
      setSocket(chat);
      if (user.language) {
        localStorage.setItem("language", user.language);
      }
    }
    // eslint-disable-next-line
  }, [token, user]);

  useEffect(() => {
    if (socket && user?.id) {
      socket.emit("notification", user?.id);
      socket.on("notification-recieve", (payload) => {
        notificationAlert({ ...payload }, currentRole, user);
        getAllNotifications();
        getAdminManagerReports(false);
        if (currentRole === "user") {
          getAssignedFormsForUser({
            limit: 10,
            page: 1,
            sortBy: "formUpdatedAt:desc",
          });
        } else {
          if (window.location.pathname === "/forms/archive") {
            getForms({
              sortBy: "updatedAt:desc",
              isArchived: true,
            });
          } else {
            getForms({
              limit: 10,
              page: 1,
              sortBy: "updatedAt:desc",
              isArchived: false,
            });
          }
        }
        if (payload.event === "roleUpdate") {
          getSession();
        }
        if (payload.event === "languageChanged") {
          localStorage.setItem("language", payload.senderId);
          getSession();
        }
      });
    }
    // eslint-disable-next-line
  }, [socket, currentRole]);

  useEffect(() => {
    if (currentRole === "manager" || currentRole === "admin") {
      setShouldReload(true);
    }
    if (currentRole === "user") {
      setTheme("inspector");
      import(`./assets/scss/themes/css/${"stylesInspector"}.min.css`);
    } else {
      setTheme("manager");
      import(`./assets/scss/themes/css/${"stylesManager"}.min.css`);
    }

    if ((currentRole === "manager" || currentRole === "admin") && shouldReload) {
      setShouldReload(false);
      setTimeout(() => {
        // window.location.reload();
      }, 10);
    } else {
      if (currentRole === "user") {
        setTimeout(() => {
          getSession();
        }, 500);
      }
    }
    // eslint-disable-next-line
  }, [currentRole]);

  return (
    <BrowserRouter>
      <Toaster position="top-center" reverseOrder={false} />
      <div data-theme={theme} style={{ fontFamily: "Noto Sans Arabic" }}>
        <AppLayout />
      </div>
    </BrowserRouter>
  );
};

const mapStateToProps = ({ authentication }) => ({
  user: authentication.user,
  currentRoute: authentication.currentRoute,
  currentRole: authentication.currentRole,
});

const mapDispatchToProps = {
  getSession,
  getForms,
  getAllNotifications,
  getAssignedFormsForUser,
  getAdminManagerReports,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
