import React, { useState, useEffect } from "react";
import I18n from "../../../languageSelector";
import { compose, withProps } from "recompose";
import ReportImages from "../report-photo-component/report-images";
import { withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import IconArrowDown from "../../../../../assets/images/arrow-down.svg";

const MapComponent = compose(
  withProps({
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `150px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withGoogleMap
)((props) => (
  <GoogleMap
    defaultZoom={7}
    center={{
      lat: props.lat,
      lng: props.lng,
    }}
  >
    {props.markers && props.markers.length > 0
      ? props.markers.map((marker, index) => (
          <Marker key={index} position={marker.position} draggable={false} />
        ))
      : null}
  </GoogleMap>
));

const LocationReportPreview = ({
  addUpdateAnwser = (answer) => console.log(answer),
  getAnswer = (answer) => console.log(answer),
  getUpdatedAnswer = (answer) => console.log(answer),
  getOldAnswer = (answer) => console.log(answer),
  setShowQuestionPreview,
  question,
  reportHistory,
  index,
  showAndHide,
  setImageIndex,
  setShowImageSrc,
  setShowImageDialog,
  setShowSection,
  setTotalAnswered,
  imageCountData,
}) => {
  const [note, setNote] = useState("");
  const [photo, setPhoto] = useState("");
  const [address, setAddress] = useState("");
  // eslint-disable-next-line
  const [city, setCity] = useState("");
  // eslint-disable-next-line
  const [country, setCountry] = useState("");
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  // eslint-disable-next-line
  const [mapAddress, setMapAddress] = useState("");
  const [markers, setMarkers] = useState([
    {
      name: "Current position",
      position: {
        lat: 0,
        lng: 0,
      },
    },
  ]);
  // oldAnswer
  const [oldNote, setOldNote] = useState("");
  const [oldPhoto, setOldPhoto] = useState("");
  const [oldAddress, setOldAddress] = useState("");
  // eslint-disable-next-line
  const [oldCity, setOldCity] = useState("");
  // eslint-disable-next-line
  const [oldCountry, setOldCountry] = useState("");
  const [oldLat, setOldLat] = useState("");
  const [oldLng, setOldLng] = useState("");
  // eslint-disable-next-line
  const [oldMapAddress, setOldMapAddress] = useState("");
  const [oldMarkers, setOldMarkers] = useState([
    {
      name: "Current position",
      position: {
        lat: 0,
        lng: 0,
      },
    },
  ]);
  // updated answer
  const [updatedNote, setUpdatedNote] = useState("");
  const [updatedPhoto, setUpdatedPhoto] = useState("");
  const [updatedAddress, setUpdatedAddress] = useState("");
  // eslint-disable-next-line
  const [updatedCity, setUpdatedCity] = useState("");
  // eslint-disable-next-line
  const [updatedCountry, setUpdatedCountry] = useState("");
  const [updatedLat, setUpdatedLat] = useState("");
  const [updatedLng, setUpdatedLng] = useState("");

  // eslint-disable-next-line
  const [updatedMapAddress, setUpdatedMapAddress] = useState("");
  const [updatedMarkers, setUpdatedMarkers] = useState([
    {
      name: "Current position",
      position: {
        lat: 0,
        lng: 0,
      },
    },
  ]);

  useEffect(() => {
    if (question && question.questionUuid) {
      let answer = getAnswer(question.questionUuid);
      if (answer) {
        setPhoto(answer.image);
        setNote(answer.note);
        setLat(answer.lat);
        setLng(answer.lng);
        setAddress(answer.address);
        setMapAddress(answer.mapAddress);
        setCity(answer.city);
        setCountry(answer.country);
        setMarkers([
          {
            position: {
              lat: parseFloat(answer.lat),
              lng: parseFloat(answer.lng),
            },
          },
        ]);
        if (answer?.address || answer?.city || answer?.image || answer?.note) {
          setShowSection(true);
          setTotalAnswered((prev) => prev + 1);
        }
      }
      let oldAnswer = getOldAnswer(question.questionUuid);
      if (oldAnswer) {
        setOldPhoto(oldAnswer.image);
        setOldNote(oldAnswer.note);
        setOldLat(oldAnswer.lat);
        setOldLng(oldAnswer.lng);
        setOldAddress(oldAnswer.address);
        setOldMapAddress(oldAnswer.mapAddress);
        setOldCity(oldAnswer.city);
        setOldCountry(oldAnswer.country);
        setOldMarkers([
          {
            position: {
              lat: parseFloat(oldAnswer.lat),
              lng: parseFloat(oldAnswer.lng),
            },
          },
        ]);
      }
      let updatedAnswer = getUpdatedAnswer(question.questionUuid);
      if (updatedAnswer) {
        setUpdatedPhoto(updatedAnswer.image);
        setUpdatedNote(updatedAnswer.note);
        setUpdatedLat(updatedAnswer.lat);
        setUpdatedLng(updatedAnswer.lng);
        setUpdatedAddress(updatedAnswer.address);
        setUpdatedMapAddress(updatedAnswer.mapAddress);
        setUpdatedCity(updatedAnswer.city);
        setUpdatedCountry(updatedAnswer.country);
        setUpdatedMarkers([
          {
            position: {
              lat: parseFloat(updatedAnswer.lat),
              lng: parseFloat(updatedAnswer.lng),
            },
          },
        ]);
      }
      let isTextChanged = false;
      let isImagesChanged = false;
      let isNotesChanged = false;
      if (oldAnswer?.address === updatedAnswer?.address) {
        isTextChanged = false;
      } else {
        isTextChanged = true;
      }
      if (oldAnswer?.note === updatedAnswer?.note) {
        isNotesChanged = false;
      } else {
        isNotesChanged = true;
      }
      if (oldAnswer?.image.length === updatedAnswer?.image.length) {
        let show = false;
        oldAnswer?.image.forEach((oldImageItem, index) => {
          if (oldImageItem.image !== updatedAnswer.image[index].image) {
            show = true;
          }
        });
        if (show) {
          isImagesChanged = true;
        }
      } else {
        isImagesChanged = true;
      }
      if (!isTextChanged && !isImagesChanged && !isNotesChanged) {
        setShowQuestionPreview(false);
      }
    }
    // eslint-disable-next-line
  }, [question]);

  const [imageCount, setImageCount] = useState(0);
  useEffect(() => {
    if (question && question.questionUuid && imageCountData?.length) {
      let data = imageCountData.filter((item) => {
        return item.questionId === question.questionUuid;
      });
      if (data.length) {
        setImageCount(data[0].imageCountStart);
      }
    }
  }, [question, imageCountData]);

  return (
    <li className="location card ">
      {reportHistory ? (
        oldAddress ||
        updatedAddress ||
        oldPhoto?.length ||
        oldNote ||
        updatedPhoto?.length ||
        updatedNote ? (
          <div className="list-item-detail">
            <p className="mt-0 mb-0">
              <I18n lng="Form.Question" /> # {question?.questionNumber}
            </p>
            <p className="question">
              {question?.settings?.questionText === "Add a new question" ? (
                <I18n lng="Form.AddANewQuestion" />
              ) : (
                question?.settings?.questionText
              )}
            </p>

            <p className="pAnswer">
              {" "}
              <I18n lng="global.PreviousAnswer" />
            </p>
            <div className="previous-Answer">
              <p className="answer"></p>
              <div className="loc-wrap mb-2">
                <div className="loc-text">
                  {oldAddress && (
                    <>
                      {" "}
                      <label>
                        <I18n lng="Form.Location" />
                      </label>
                      {oldLat && oldLng ? (
                        <p>
                          {oldAddress} (
                          {oldLng ? Number(oldLng).toFixed(4) : ""},{" "}
                          {oldLat ? Number(oldLat).toFixed(4) : ""})
                        </p>
                      ) : (
                        <p>{oldAddress}</p>
                      )}{" "}
                    </>
                  )}
                </div>
                {oldLat && oldLng && oldMarkers ? (
                  <div className="loc-map">
                    <MapComponent
                      lat={parseFloat(oldLat)}
                      lng={parseFloat(oldLng)}
                      markers={oldMarkers}
                    />
                  </div>
                ) : null}
              </div>
              {oldNote && (
                <div className="notes">
                  <label>
                    <I18n lng="global.Notes" />:
                  </label>
                  <p>{oldNote}</p>
                </div>
              )}
              <div className="thumbnail-wrapper">
                <ReportImages
                  photo={oldPhoto}
                  setImageIndex={setImageIndex}
                  setShowImageSrc={setShowImageSrc}
                  setShowImageDialog={setShowImageDialog}
                />
              </div>
            </div>
            <div className="text-center mt-2">
              <img src={IconArrowDown} alt="loading" />
            </div>
            <p className="uAnswer mt-3">
              <I18n lng="global.UpdatedAnswer" />
            </p>
            <div className="updated-Answer mb-4">
              <div className="loc-wrap mb-2">
                <div className="loc-text">
                  {updatedAddress && (
                    <>
                      <label>
                        <I18n lng="Form.Location" />
                      </label>
                      {updatedLat && updatedLng ? (
                        <p>
                          {updatedAddress} (
                          {updatedLng ? Number(updatedLng).toFixed(4) : ""},{" "}
                          {updatedLat ? Number(updatedLat).toFixed(4) : ""})
                        </p>
                      ) : (
                        <p>{updatedAddress}</p>
                      )}
                    </>
                  )}
                </div>
                {updatedLat && updatedLng && updatedMarkers ? (
                  <div className="loc-map">
                    <MapComponent
                      lat={parseFloat(updatedLat)}
                      lng={parseFloat(updatedLng)}
                      markers={updatedMarkers}
                    />
                  </div>
                ) : null}
              </div>
              {updatedNote && (
                <div className="notes">
                  <label>
                    <I18n lng="global.Notes" />:
                  </label>
                  <p>{updatedNote}</p>
                </div>
              )}
              <div className="thumbnail-wrapper">
                <ReportImages
                  photo={updatedPhoto}
                  setImageIndex={setImageIndex}
                  setShowImageSrc={setShowImageSrc}
                  setShowImageDialog={setShowImageDialog}
                />
              </div>
            </div>
          </div>
        ) : null
      ) : showAndHide?.content?.all ? (
        <div className="list-item-detail ">
          <p className="mt-0 mb-1">
            <I18n lng="Form.Question" /> # {question?.questionNumber}
          </p>
          <p className="question">
            {question?.settings?.questionText === "Add a new question" ? (
              <I18n lng="Form.AddANewQuestion" />
            ) : (
              question?.settings?.questionText
            )}
          </p>
          <div className="loc-wrap mb-2">
            <div className="loc-text">
              {address && (
                <>
                  {" "}
                  <label>
                    <I18n lng="Form.Location" />
                  </label>
                  {lat && lng ? (
                    <p>
                      {address} ({lng ? Number(lng).toFixed(4) : ""},{" "}
                      {lat ? Number(lat).toFixed(4) : ""})
                    </p>
                  ) : (
                    <p>{address}</p>
                  )}{" "}
                </>
              )}
            </div>
            {lat && lng && markers ? (
              <div className="loc-map">
                <MapComponent
                  lat={parseFloat(lat)}
                  lng={parseFloat(lng)}
                  markers={markers}
                />
              </div>
            ) : null}
          </div>
          {note && (
            <div className="notes">
              <label>
                <I18n lng="global.Notes" />:
              </label>
              <p>{note}</p>
            </div>
          )}
          <div className="thumbnail-wrapper">
            <ReportImages
              photo={photo}
              imageCount={imageCount}
              setImageIndex={setImageIndex}
              setShowImageSrc={setShowImageSrc}
              setShowImageDialog={setShowImageDialog}
            />
          </div>
        </div>
      ) : showAndHide?.content?.answered &&
        ((address && lng && lat && markers) || note || photo?.length) ? (
        <div className="list-item-detail ">
          <p className="question">
            {question?.settings?.questionText === "Add a new question" ? (
              <I18n lng="Form.AddANewQuestion" />
            ) : (
              question?.settings?.questionText
            )}
          </p>
          <div className="loc-wrap mb-2">
            <div className="loc-text">
              {address && (
                <>
                  {" "}
                  <label>
                    <I18n lng="Form.Location" />
                  </label>
                  {lat && lng ? (
                    <p>
                      {address} ({lng ? Number(lng).toFixed(4) : ""},{" "}
                      {lat ? Number(lat).toFixed(4) : ""})
                    </p>
                  ) : (
                    <p>{address}</p>
                  )}{" "}
                </>
              )}
            </div>
            <div className="loc-map">
              <MapComponent
                lat={parseFloat(lat)}
                lng={parseFloat(lng)}
                markers={markers}
              />
            </div>
          </div>
          {note && (
            <div className="notes">
              <label>
                <I18n lng="global.Notes" />:
              </label>
              <p>{note}</p>
            </div>
          )}
          <div className="thumbnail-wrapper">
            <ReportImages
              photo={photo}
              imageCount={imageCount}
              setImageIndex={setImageIndex}
              setShowImageSrc={setShowImageSrc}
              setShowImageDialog={setShowImageDialog}
            />
          </div>
        </div>
      ) : showAndHide?.content?.unAnswered &&
        !address &&
        !lng &&
        !lat &&
        !markers &&
        !note &&
        !photo?.length ? (
        <div className="list-item-detail ">
          <p className="question">
            {question?.settings?.questionText === "Add a new question" ? (
              <I18n lng="Form.AddANewQuestion" />
            ) : (
              question?.settings?.questionText
            )}
          </p>
          <div className="loc-wrap mb-2">
            <div className="loc-text">
              {address && (
                <>
                  {" "}
                  <label>
                    <I18n lng="Form.Location" />
                  </label>
                  {lat && lng ? (
                    <p>
                      {address} ({lng ? Number(lng).toFixed(4) : ""},{" "}
                      {lat ? Number(lat).toFixed(4) : ""})
                    </p>
                  ) : (
                    <p>{address}</p>
                  )}{" "}
                </>
              )}
            </div>
            {lat && lng && markers ? (
              <div className="loc-map">
                <MapComponent
                  lat={parseFloat(lat)}
                  lng={parseFloat(lng)}
                  markers={markers}
                />
              </div>
            ) : null}
          </div>
          {note && (
            <div className="notes">
              <label>
                <I18n lng="global.Notes" />:
              </label>
              <p>{note}</p>
            </div>
          )}
          <div className="thumbnail-wrapper">
            <ReportImages
              photo={photo}
              imageCount={imageCount}
              setImageIndex={setImageIndex}
              setShowImageSrc={setShowImageSrc}
              setShowImageDialog={setShowImageDialog}
            />
          </div>
        </div>
      ) : null}
    </li>
  );
};
export default LocationReportPreview;
