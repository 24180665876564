import React from "react";

const CustomReactSelect = ({
  label,
  name,
  prefix,
  suffix,
  register,
  validations,
  error,
  options,
  disabled,
  onChange,
}) => {
  return (
    <>
      <label className="form-label" htmlFor={name}>
        {label} {validations?.required && <span className="required"></span>}
      </label>
      <div className="input-group input-select">
        {prefix && (
          <div className="input-group-prepend">
            <img src={prefix} alt="fieldIcon" />
          </div>
        )}
        <select
          className="form-control"
          id={name}
          name={name}
          {...(register ? register(name, { ...validations }) : {})}
          disabled={disabled}
          onChange={onChange}
        >
          <option value="">Please select an option</option>
          {options?.map((item, index) => (
            <option key={index}>{item}</option>
          ))}
        </select>
        {suffix && (
          <div className="input-group-append">
            <img src={suffix} alt="fieldIcon" />
          </div>
        )}
      </div>
      {error && <p className="error">{error.message}</p>}
    </>
  );
};
export default CustomReactSelect;
