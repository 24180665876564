import React, { useState, useEffect } from "react";
import I18n from "../../../languageSelector";
import SignatureCanvas from "react-signature-canvas";
import ReportImages from "../report-photo-component/report-images";
import IconArrowDown from "../../../../../assets/images/arrow-down.svg";

const SignatureReportPreview = ({
  getAnswer = (answer) => console.log(answer),
  getUpdatedAnswer = (answer) => console.log(answer),
  getOldAnswer = (answer) => console.log(answer),
  setShowQuestionPreview,
  question,
  reportHistory,
  showAndHide,
  setImageIndex,
  setShowImageSrc,
  setShowImageDialog,
  setShowSection,
  setTotalAnswered,
  imageCountData,
  failStatus,
}) => {
  let sigRef = React.useRef(null);
  let oldSigRef = React.useRef(null);
  let updatedSigRef = React.useRef(null);
  const [note, setNote] = useState("");
  const [photo, setPhoto] = useState("");
  const [signatureName, setSignatureName] = useState("");

  const [oldAnswerValue, setOldAnswerValue] = useState("");
  const [updatedAnswerValue, setUpdatedAnswerValue] = useState("");
  const [oldNote, setOldNote] = useState("");
  const [updatedNote, setUpdatedNote] = useState("");
  const [oldPhoto, setOldPhoto] = useState("");
  const [updatedPhoto, setUpdatedPhoto] = useState("");
  const [oldSigValues, setOldSigValues] = useState();
  const [updateSigValues, setUpdatedSigValues] = useState();
  const [imageCount, setImageCount] = useState(0);

  useEffect(() => {
    setOldSigValues(undefined);
    setUpdatedSigValues(undefined);
  }, []);

  useEffect(() => {
    if (question && question.questionUuid) {
      let answer = getAnswer(question.questionUuid);
      if (answer) {
        setSignatureName(answer.signatureName);
        setPhoto(answer.image);
        setNote(answer.note);
        if (answer?.signatureName || answer?.image || answer?.note) {
          setShowSection(true);
          setTotalAnswered((prev) => prev + 1);
        }

        if (answer?.signature?.length && !failStatus) {
          sigRef?.fromData(answer?.signature);
          sigRef?.off();
        }
      }

      let oldAnswer = getOldAnswer(question.questionUuid);
      if (oldAnswer) {
        setOldAnswerValue(oldAnswer.signatureName);
        setOldPhoto(oldAnswer.image);
        setOldNote(oldAnswer.note);
        setOldSigValues(oldAnswer?.signature);
      }

      let updatedAnswer = getUpdatedAnswer(question.questionUuid);
      if (updatedAnswer) {
        setUpdatedAnswerValue(updatedAnswer.signatureName);
        setUpdatedPhoto(updatedAnswer.image);
        setUpdatedNote(updatedAnswer.note);
        setUpdatedSigValues(updatedAnswer?.signature);
      }
      let isTextChanged = false;
      let isImagesChanged = false;
      let isNotesChanged = false;

      if (oldAnswer?.signatureName === updatedAnswer?.signatureName) {
        isTextChanged = false;
      } else {
        isTextChanged = true;
      }
      if (oldAnswer?.note === updatedAnswer?.note) {
        isNotesChanged = false;
      } else {
        isNotesChanged = true;
      }
      if (oldAnswer?.image.length === updatedAnswer?.image.length) {
        let show = false;
        oldAnswer?.image.forEach((oldImageItem, index) => {
          if (oldImageItem.image !== updatedAnswer.image[index].image) {
            show = true;
          }
        });
        if (show) {
          isImagesChanged = true;
        }
      } else {
        isImagesChanged = true;
      }
      if (!isTextChanged && !isImagesChanged && !isNotesChanged) {
        setShowQuestionPreview(false);
      }
    }
    // eslint-disable-next-line
  }, [question]);

  useEffect(() => {
    if (updateSigValues) {
      setTimeout(() => {
        updatedSigRef?.fromData(updateSigValues);
        updatedSigRef?.off();
      }, 2000);
    }
    if (oldSigValues) {
      setTimeout(() => {
        oldSigRef?.fromData(oldSigValues);
        oldSigRef?.off();
      }, 1500);
    }
  }, [oldSigValues, updateSigValues]);

  useEffect(() => {
    if (question && question.questionUuid && imageCountData?.length) {
      let data = imageCountData.filter((item) => {
        return item.questionId === question.questionUuid;
      });
      if (data.length) {
        setImageCount(data[0].imageCountStart);
      }
    }
  }, [question, imageCountData]);

  return (
    <li className="card ">
      {reportHistory ? (
        updatedAnswerValue || oldAnswerValue ? (
          <div className="list-item-detail">
            <p className="mt-0 mb-0">
              <I18n lng="Form.Question" /> # {question?.questionNumber}
            </p>
            <p className="question">
              {question?.settings?.questionText === "Add a new question" ? (
                <I18n lng="Form.AddANewQuestion" />
              ) : (
                question?.settings?.questionText
              )}
            </p>
            <p className="pAnswer">
              <I18n lng="global.PreviousAnswer" />
            </p>
            <div className="previous-Answer">
              <div className="signature">
                <SignatureCanvas
                  ref={(ref) => {
                    oldSigRef = ref;
                  }}
                  canvasProps={{
                    width: 500,
                    height: 350,
                    className: "sigCanvas",
                  }}
                />
              </div>{" "}
              {oldAnswerValue && <p className="text-muted">{oldAnswerValue}</p>}
              {oldNote && (
                <div className="notes">
                  <label>
                    <I18n lng="global.Notes" />:
                  </label>
                  <p>{oldNote}</p>
                </div>
              )}
              {oldPhoto ? (
                <div className="thumbnail-wrapper">
                  <ReportImages
                    photo={oldPhoto}
                    setImageIndex={setImageIndex}
                    setShowImageSrc={setShowImageSrc}
                    setShowImageDialog={setShowImageDialog}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="text-center mt-2">
              <img src={IconArrowDown} alt="loading" />
            </div>
            <p className="uAnswer mt-3">
              <I18n lng="global.UpdatedAnswer" />
            </p>
            <div className="updated-Answer mb-4">
              <div className="signature">
                <SignatureCanvas
                  ref={(ref) => {
                    updatedSigRef = ref;
                  }}
                  canvasProps={{
                    width: 500,
                    height: 350,
                    className: "sigCanvas",
                  }}
                />
              </div>{" "}
              {updatedAnswerValue && (
                <p className="text-muted">{updatedAnswerValue}</p>
              )}
              {updatedNote && (
                <div className="notes">
                  <label>
                    <I18n lng="global.Notes" />:
                  </label>
                  <p>{updatedNote}</p>
                </div>
              )}
              {updatedPhoto ? (
                <div className="thumbnail-wrapper">
                  <ReportImages
                    photo={updatedPhoto}
                    setImageIndex={setImageIndex}
                    setShowImageSrc={setShowImageSrc}
                    setShowImageDialog={setShowImageDialog}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : null
      ) : showAndHide?.content?.all ? (
        <div className="list-item-detail ">
          <p className="mt-0 mb-1">
            <I18n lng="Form.Question" /> # {question?.questionNumber}
          </p>
          <p className="question">
            {question?.settings?.questionText === "Add a new question" ? (
              <I18n lng="Form.AddANewQuestion" />
            ) : (
              question?.settings?.questionText
            )}
          </p>
          <div className="signature">
            {signatureName && <p className="text-muted">{signatureName}</p>}
            <SignatureCanvas
              ref={(ref) => {
                sigRef = ref;
              }}
              canvasProps={{
                width: 500,
                height: 350,
                className: "sigCanvas",
              }}
            />
          </div>{" "}
          {note && (
            <div className="notes" style={{ maxWidth: "260px" }}>
              <label>
                <I18n lng="global.Notes" />:
              </label>
              <p>{note}</p>
            </div>
          )}
          <div className="thumbnail-wrapper">
            <ReportImages
              photo={photo}
              imageCount={imageCount}
              setImageIndex={setImageIndex}
              setShowImageSrc={setShowImageSrc}
              setShowImageDialog={setShowImageDialog}
            />
          </div>
        </div>
      ) : showAndHide?.content?.answered &&
        (signatureName || note || photo?.length) ? (
        <div className="list-item-detail ">
          <p className="question">
            {question?.settings?.questionText === "Add a new question" ? (
              <I18n lng="Form.AddANewQuestion" />
            ) : (
              question?.settings?.questionText
            )}
          </p>
          <div className="signature">
            <SignatureCanvas
              ref={(ref) => {
                sigRef = ref;
              }}
              canvasProps={{
                width: 500,
                height: 350,
                className: "sigCanvas",
              }}
            />
          </div>{" "}
          {signatureName && <p className="text-muted">{signatureName}</p>}
          {note && (
            <div className="notes" style={{ maxWidth: "260px" }}>
              <label>
                <I18n lng="global.Notes" />:
              </label>
              <p>{note}</p>
            </div>
          )}
          <div className="thumbnail-wrapper">
            <ReportImages
              photo={photo}
              imageCount={imageCount}
              setImageIndex={setImageIndex}
              setShowImageSrc={setShowImageSrc}
              setShowImageDialog={setShowImageDialog}
            />
          </div>
        </div>
      ) : showAndHide?.content?.unAnswered &&
        !signatureName &&
        !note &&
        !photo?.length ? (
        <div className="list-item-detail ">
          <p className="question">
            {question?.settings?.questionText === "Add a new question" ? (
              <I18n lng="Form.AddANewQuestion" />
            ) : (
              question?.settings?.questionText
            )}
          </p>
          <div className="signature">
            <SignatureCanvas
              ref={(ref) => {
                sigRef = ref;
              }}
              canvasProps={{
                width: 500,
                height: 350,
                className: "sigCanvas",
              }}
            />
          </div>{" "}
          {signatureName && <p className="text-muted">{signatureName}</p>}
          {note && (
            <div className="notes" style={{ maxWidth: "260px" }}>
              <label>
                <I18n lng="global.Notes" />:
              </label>
              <p>{note}</p>
            </div>
          )}
          <div className="thumbnail-wrapper">
            <ReportImages
              photo={photo}
              imageCount={imageCount}
              setImageIndex={setImageIndex}
              setShowImageSrc={setShowImageSrc}
              setShowImageDialog={setShowImageDialog}
            />
          </div>
        </div>
      ) : null}
    </li>
  );
};
export default SignatureReportPreview;
