import React from "react";
import { connect } from "react-redux";
import ArchiveListing from "./archive-list";
import { Route, Switch } from "react-router-dom";
import I18n from "../../../private/languageSelector";
import { ArchiveUIProvider } from "./archive-ui-context";
import DialogModal from "../../../../components/dialogs/compact-dialog";
import {
  patchForm,
  deleteForm,
} from "../../../../redux/reducers/form/form.reducer";

const ArchivePage = ({ history, patchForm, deleteForm }) => {
  const archiveUIEvents = {
    openRestoreFormDlg: (id, form) => {
      patchForm(id, form?.formDetail, false, undefined).then(() => {
        history.push("/forms/archive/");
      });
    },
    openDeleteFormDlg: (id) => {
      history.push(`/forms/archive/${id}/delete`);
    },
  };
  const unArchiveForm = (id) => {
    patchForm(id, undefined, false, undefined).then(() => {
      history.push("/forms/archive/");
    });
  };

  const deleteFormById = (id) => {
    deleteForm(id).then(() => {
      history.push("/forms/archive/");
    });
  };
  return (
    <ArchiveUIProvider archiveUIEvents={archiveUIEvents}>
      <Switch>
        <Route path="/forms/archive/">
          <Switch>
            <Route path="/forms/archive/:id/unarchive">
              {({ history, match }) => (
                <>
                  <DialogModal
                    showDlg={match != null}
                    title={<I18n lng="global.Note" />}
                    classes="modal-alert"
                    iconCloseDlg
                    content={<I18n lng="Form.WhenYouUnArchiveAForm" />}
                    primaryActionTitle={<I18n lng="global.Un-Archive" />}
                    secondaryAction={() => history.push("/forms/archive/")}
                    primaryAction={unArchiveForm}
                  />
                </>
              )}
            </Route>
            <Route path="/forms/archive/:id/delete">
              {({ history, match }) => (
                <>
                  <DialogModal
                    showDlg={match != null}
                    title={<I18n lng="global.Note" />}
                    classes="modal-alert"
                    iconCloseDlg
                    content={<I18n lng="Form.WhenYouDeleteAForm" />}
                    primaryActionTitle={<I18n lng="global.Delete" />}
                    secondaryAction={() => history.push("/forms/archive/")}
                    primaryAction={deleteFormById}
                    primaryActionClasses={"btn-danger"}
                  />
                </>
              )}
            </Route>
          </Switch>
          <ArchiveListing />
        </Route>
      </Switch>
    </ArchiveUIProvider>
  );
};

const mapDispatchToProps = { patchForm, deleteForm };
export default connect(null, mapDispatchToProps)(ArchivePage);
