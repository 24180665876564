import React from "react";
import { Link } from "react-router-dom";
import { TEXT_DIRECTION } from "../../helper/helperFunctions";

const CheckBox = ({
  label,
  name,
  register,
  validations,
  error,
  children,
  disabled,
  link,
  linkTitle,
  className = "custom-control custom-checkbox",
  ...otherProps
}) => {
  return (
    <>
      <div
        className={className}
        style={
          window.location.pathname.includes("/management/users/") &&
          name !== "passwordLink" &&
          name !== "inviteLink" &&
          TEXT_DIRECTION() === "rtl"
            ? { paddingLeft: "12px", paddingRight: "0px", textAlign: "center" }
            : {}
        }
      >
        <input
          type="checkbox"
          className={`custom-control-input`}
          id={name}
          name={name}
          {...(register ? register(name, { ...validations }) : {})}
          {...otherProps}
          disabled={disabled}
        />
        <label
          style={{ color: "#20639B" }}
          className="custom-control-label"
          htmlFor={name}
        >
          {label}
          {linkTitle && (
            <Link to={"#"} onClick={() => window.open(link)}>
              {linkTitle}
            </Link>
          )}
          {validations?.required && <span className="required"></span>}
        </label>
      </div>
      {children}
      {error && (
        <p className="error" style={{ marginTop: "0px", marginLeft: "15px" }}>
          {error.message}
        </p>
      )}
    </>
  );
};

export default CheckBox;
