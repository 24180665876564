import React, { useState } from "react";
import { connect } from "react-redux";
import FormListing from "./form-list";
import FormBuilder from "./form-builder";
import FormView from "./form-view/form-view";
import { Route, Switch } from "react-router-dom";
import { FormsUIProvider } from "./form-ui-context";
import I18n from "../../../private/languageSelector";
import DialogModal from "../../../../components/dialogs/compact-dialog";
import { patchForm } from "../../../../redux/reducers/form/form.reducer";
import FormDispatch from "../form-management/form-dispatch/form-dispatch";
import { updateRoute } from "../../../../redux/reducers/authentication/authentication";
import { useHistory } from "react-router-dom";

const FormManagementPage = ({ patchForm }) => {
  const [formData, setFormData] = useState({});
  const history = useHistory();
  const formsUIEvents = {
    openEditForm: (id) => {
      history.push(`/forms/form-management/${id}`);
    },

    openArchvieFormDialog: (id, form) => {
      setFormData({ ...form?.formDetail });

      if (form?.dispatchRules?.length > 0) {
        history.push(`/forms/form-management/${id}/archive`);
      } else {
        archiveForm(id, form?.formDetail);
      }
    },

    openFormView: (id, dispatchFormId) => {
      history.push(`/forms/form-management/${id}/view/${dispatchFormId}`);
    },

    openFormStart: (id, dispatchFormId) => {
      history.push(`/forms/form-management/${id}/start/${dispatchFormId}`);
    },

    openFormDispatch: (id) => {
      history.push(`/forms/form-management/dispatch/${id}`);
    },
  };
  const archiveForm = (id, formDetail) => {
    if (formDetail !== undefined) {
      patchForm(id, { ...formDetail }, true, undefined).then(() => {
        history.push("/forms/form-management/");
      });
    } else {
      patchForm(id, { ...formData }, true, undefined).then(() => {
        history.push("/forms/form-management/");
      });
    }
  };

  return (
    <FormsUIProvider formsUIEvents={formsUIEvents}>
      <Switch>
        <Route exact path="/forms/form-management/:id">
          {({ history, match }) => (
            <FormBuilder
              show={match != null}
              onHide={() => {
                history.push("/forms/form-management");
              }}
            />
          )}
        </Route>

        <Route path="/forms/form-management">
          <Switch>
            <Route path="/forms/form-management/:id/view/:dispatchFormId">
              {({ history, match }) => (
                <FormView
                  show={match != null}
                  onHide={() => {
                    history.push("/forms/form-management/");
                  }}
                  view={true}
                />
              )}
            </Route>
            <Route path="/forms/form-management/:id/start/:dispatchFormId">
              {({ history, match }) => (
                <FormView
                  show={match != null}
                  onHide={() => {
                    history.push("/forms/form-management/");
                  }}
                  view={false}
                />
              )}
            </Route>
            <Route exact path="/forms/form-management/dispatch/:id">
              {({ history, match }) => (
                <FormDispatch
                  show={match != null}
                  onHide={() => {
                    history.push("/forms/form-management/");
                  }}
                />
              )}
            </Route>
            <Route path="/forms/form-management/:id/archive">
              {({ history, match }) => (
                <>
                  <DialogModal
                    showDlg={match != null}
                    title={<I18n lng="global.Note" />}
                    classes="modal-alert"
                    iconCloseDlg
                    content={<I18n lng="Form.Whenyouarchiveaforminspectors" />}
                    primaryActionTitle={<I18n lng="global.Archive" />}
                    secondaryAction={() =>
                      history.push("/forms/form-management/")
                    }
                    primaryAction={archiveForm}
                    primaryActionClasses={"btn-primary"}
                  />
                </>
              )}
            </Route>
          </Switch>
          <FormListing />
        </Route>
      </Switch>
    </FormsUIProvider>
  );
};
const mapStateToProps = ({ form }) => ({
  formIdForNotification: form.formIdForNotification,
});
const mapDispatchToProps = { patchForm, updateRoute };

export default connect(mapStateToProps, mapDispatchToProps)(FormManagementPage);
