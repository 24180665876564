/** @format */

import moment from "moment";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import DynamicModal from "../../../../../components/dialogs";
import { subscriptionsTranslation } from "../../../../../config/constants";
import { getAllInvoices, getInvoice } from "../../../../../redux/reducers/subscription/subscription.reducer";
import I18n from "../../../languageSelector";
import InvoiceDetails from "./invoices/invoice-details";

const SubscriptionBillingDetails = ({ show, onHide, subscriptionData, getInvoice, getAllInvoices, invoiceData, invoices }) => {
  const language = localStorage.getItem("language");
  const history = useHistory();

  const [showInvoicesDetails, setShowInvoicesDetails] = useState(false);
  const [invoiceId, setInvoiceId] = useState();

  const showInvoices =
    (subscriptionData?.subscriptionInfo?.isSubscribed && subscriptionData?.userTrial?.isTrialExpired) ||
    subscriptionData?.subscriptionInfo?.isUnsubscribed === true ||
    (subscriptionData?.currentPlan?.plan === "Free" &&
      subscriptionData?.currentPlan?.status === "Free" &&
      subscriptionData?.subscriptionInfo?.isSubscribed &&
      subscriptionData?.subscriptionInfo?.isSubscribedExpired &&
      subscriptionData?.userTrial?.isTrialCancelled &&
      subscriptionData?.userTrial?.isTrialExpired);

  const formattedMonthlyInvoiceData = (date) => {
    const createdAtDate = moment(date);
    const formattedDate = createdAtDate.format("DD MMMM YYYY");
    return formattedDate;
  };

  useEffect(() => {
    if (invoiceId) {
      getInvoice(invoiceId);
    }
  }, [invoiceId, getInvoice, show]);

  return (
    <>
      <DynamicModal
        title={<I18n lng="Subscriptions.BillingHistory" />}
        show={show}
        secondaryAction={onHide}
        modalContentClass="bg-white"
        customClasses={`modal-drawer custom-roles-drawer`}
      >
        <div className="d-flex flex-column justify-content-center h-100" style={{ width: "106%", marginLeft: "-10px", marginTop: "6px" }}>
          <div className="d-flex justify-content-between align-items-start w-100" style={{ borderBottom: "1px solid #F2F2F2", marginRight: "-10px" }}>
            {language === "ARABIC" ? (
              <span style={{ color: "#333333", fontWeight: "600", fontSize: "14px" }} className="p-2 mb-1">
                <I18n lng="Subscriptions.Amount" />
              </span>
            ) : (
              <span style={{ color: "#333333", fontWeight: "600", fontSize: "14px" }} className="p-2 pl-1 mb-1">
                <I18n lng="Subscriptions.Description" />
              </span>
            )}
            {language === "ARABIC" ? (
              <span style={{ color: "#333333", fontWeight: "600", fontSize: "14px" }} className="p-2 pl-1 mb-1">
                <I18n lng="Subscriptions.Description" />
              </span>
            ) : (
              <span style={{ color: "#333333", fontWeight: "600", fontSize: "14px" }} className="p-2 mb-1">
                <I18n lng="Subscriptions.Amount" />
              </span>
            )}
          </div>
          <div className="h-100">
            {showInvoices ? (
              <>
                {invoices?.map((item) => {
                  let totalCostBefore =
                    item?.subscriptionData?.currentPlan?.plan === "Additional seats"
                      ? item?.subscriptionData?.lastAdditionalseats?.seats * item?.subscriptionData?.lastAdditionalseats?.lastPerSeatBill
                      : item?.subscriptionData?.currentPlan?.seats * item?.subscriptionData?.currentPlan?.price;

                  const withTax = totalCostBefore * 0.15;
                  const totalCost = withTax + totalCostBefore;

                  return (
                    <div
                      onClick={() => {
                        setInvoiceId(item?.id);
                        setShowInvoicesDetails(true);
                        if (invoiceId) {
                          history.push(`/management/subscriptions/invoice/${invoiceId}`);
                        }
                      }}
                      key={item.id}
                      className="mt-3 d-flex justify-content-between w-100"
                      style={{
                        borderBottom: "1px solid #F2F2F2",
                        cursor: "pointer",
                      }}
                    >
                      <div className="mx-2">
                        <p className="d-flex flex-column">
                          <span style={{ fontWeight: "500", fontSize: "14px", color: "#333333" }}>
                            {subscriptionsTranslation(item?.subscriptionData?.currentPlan?.plan)}
                          </span>
                          <span
                            style={{
                              fontWeight: "400",
                              fontSize: "12px",
                              color: "#828282",
                            }}
                          >
                            {formattedMonthlyInvoiceData(item?.createdAt)}
                          </span>
                        </p>
                      </div>
                      <div className="mx-2">
                        <p className="d-flex flex-column ml-2">
                          <span style={{ fontWeight: "500", fontSize: "14px", height: "16px", color: "#333333", marginRight: "10px" }}>
                            A1-{item?.invoiceDummyId}
                          </span>
                          <span
                            style={{
                              fontWeight: "500",
                              fontSize: "12px",
                              color: "#828282",
                              display: "flex",
                              gap: "8px",
                            }}
                          >
                            {language === "ARABIC" ? (
                              <>
                                <span style={{ height: "16px", color: "#828282", display: "flex", gap: "6px" }}>
                                  <span>{totalCost.toFixed(2)}</span>
                                  <span>
                                    <I18n lng="Subscriptions.SAR" />
                                  </span>
                                </span>
                              </>
                            ) : (
                              <>
                                <span style={{ height: "16px", color: "#828282", display: "flex", gap: "6px" }}>
                                  <span>
                                    <I18n lng="Subscriptions.SAR" />
                                  </span>
                                  <span>{totalCost.toFixed(2)}</span>
                                </span>
                              </>
                            )}
                          </span>
                        </p>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <div className="d-flex flex-column align-items-center justify-content-center h-100">
                <span style={{ color: "#BDBDBD" }}>
                  <I18n lng="Subscriptions.NoInvoicesToShow" />
                </span>
              </div>
            )}
          </div>
        </div>
      </DynamicModal>
      {showInvoicesDetails && invoiceData && (
        <InvoiceDetails
          invoiceData={invoiceData}
          show={showInvoicesDetails}
          onHide={setShowInvoicesDetails}
          setShowInvoicesDetails={setShowInvoicesDetails}
          lastSubscriptionData={invoiceData?.subscriptionData?.lastSubscriptionData}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ authentication, subscription }) => ({
  user: authentication.user,
  invoiceData: subscription.invoiceData,
});

const mapDispatchToProps = {
  getAllInvoices,
  getInvoice,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionBillingDetails);
