import React, { useState } from "react";
import SectionPreview from "./sectionReportPreview";
import moment from "moment";
import { i18n } from "../../languageSelector";

const PagePreview = ({
  page,
  reportLogs,
  addUpdateAnwser,
  getAnswer,
  getOldAnswer,
  getUpdatedAnswer,
  reportHistory,
  index,
  showAndHide,
  imageCountData,
  setFailItems,
  failItems,
  failStatus,
}) => {
  const [showPage, setShowPage] = useState(false);
  return (
    <>
      <div>
        {reportHistory && (
          <div className="card mb-0">
            <div className="text-muted">
              <span>{reportLogs?.assignee?.fullName} </span>
              <span>
                <b style={{ color: "#f2994a" }}>edited </b>
                <span> a response</span>
              </span>{" "}
              <span>
                {moment(reportLogs?.updatedAt).format("HH:mm, DD MMM YYYY")}
              </span>
            </div>
          </div>
        )}
      </div>
      <div className="report-detail-block" id={`id-${index + 1}`}>
        {showAndHide?.content?.all ? (
          <h6 className="section-title primary" id={`-id-${index + 1}`}>
            {page.name === "Page"
              ? `${i18n("Form.Page")} ${index + 1}`
              : page.name}
          </h6>
        ) : showAndHide?.content?.unAnswered && showPage ? (
          <h6 className="section-title primary" id={`-id-${index + 1}`}>
            {page.name === "Page"
              ? `${i18n("Form.Page")} ${index + 1}`
              : page.name}
          </h6>
        ) : showAndHide?.content?.answered && showPage ? (
          <h6 className="section-title primary" id={`-id-${index + 1}`}>
            {page.name === "Page"
              ? `${i18n("Form.Page")} ${index + 1}`
              : page.name}
          </h6>
        ) : null}
        {page && page.sections.length
          ? page.sections.map((item, secIndex) => (
              <SectionPreview
                section={item}
                addUpdateAnwser={addUpdateAnwser}
                getAnswer={getAnswer}
                getOldAnswer={getOldAnswer}
                getUpdatedAnswer={getUpdatedAnswer}
                reportLogs={reportLogs}
                reportHistory={reportHistory}
                index={secIndex}
                showAndHide={showAndHide}
                setShowPage={setShowPage}
                imageCountData={imageCountData}
                setFailItems={setFailItems}
                failItems={failItems}
                failStatus={failStatus}
              />
            ))
          : null}
      </div>
    </>
  );
};
export default PagePreview;
