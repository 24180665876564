import React, { useRef, useState } from "react";
import searchIcon from "../../../src/assets/images/charm_search.svg";
import dropdownIcon from "../../../src/assets/images/dropdownMenuIcon.svg";
import { TEXT_DIRECTION } from "../../helper/helperFunctions";
import { i18n } from "../../pages/private/languageSelector";

const CustomMultiSelect = ({ options, onSelect, handleSelectAll, handleSelectedItem, handleSearch, formField }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [formSearchItem, setFormSearchItem] = useState("");
  const [userSearchItem, setUserSearchItem] = useState("");
  const [siteSearchItem, setSiteSearchItem] = useState("");

  const selectRef = useRef(null);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOutsideClick = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  React.useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  return (
    <div className="custom-multi-select" ref={selectRef}>
      <div
        className="select-field"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          // padding: "5px",
          // paddingLeft: "15px !important",
          // paddingRight: "15px !important",
          padding: "10px 14px 10px 14px",
          color: "#333333",
        }}
        onClick={toggleDropdown}
      >
        {options?.filter((item) => item.isSelected)?.length === 0 ? (
          <span>Select</span>
        ) : options?.filter((item) => !item.isSelected)?.length === 0 ? (
          <span style={{ fontWeight: 500, fontSize: "14px" }}>{i18n("Analytics.All")}</span>
        ) : options?.filter((item) => item.isSelected)?.length > 0 && options?.filter((item) => !item.isSelected)?.length > 0 ? (
          options
            .filter((option) => option.isSelected)
            .map((item) => {
              return <span className="selectedSubItems">{item.label}</span>;
            })
        ) : (
          ""
        )}
        <div className={`dropdown-icon ${isOpen ? "open" : ""}`}>
          <img src={dropdownIcon} alt="dropdown" />
        </div>
      </div>
      {isOpen && (
        <div className="multi-select-dropdown">
          <>
            {formField !== "STATUS" ? (
              <div style={{ position: "relative", marginLeft: "10px", marginRight: "10px" }}>
                <img
                  alt=""
                  src={searchIcon}
                  style={{
                    position: "absolute",
                    top: "14px",
                    left: TEXT_DIRECTION() === "ltr" ? "10px" : "",
                    right: TEXT_DIRECTION() === "rtl" ? "10px" : "",
                  }}
                />
                <input
                  type="text"
                  placeholder={i18n("global.Search")}
                  value={formField === "FORM" ? formSearchItem : formField === "USER" ? userSearchItem : siteSearchItem}
                  onChange={(e) => {
                    if (formField === "FORM") {
                      setFormSearchItem(e.target.value);
                      handleSearch(e.target.value);
                    } else if (formField === "USER") {
                      setUserSearchItem(e.target.value);
                      handleSearch(e.target.value);
                    } else {
                      setSiteSearchItem(e.target.value);
                      handleSearch(e.target.value);
                    }
                  }}
                  className="search-input"
                  // Add class for styling
                />
              </div>
            ) : null}

            {options?.length > 0 ? (
              <>
                {" "}
                <div className="d-flex select-all" style={{ alignItems: "center" }}>
                  <input
                    style={{ height: "16px", width: "16px", border: "none", background: "#F2F2F2" }}
                    type="checkbox"
                    checked={options?.filter((item) => !item.isSelected)?.length > 0 ? false : true}
                    onChange={handleSelectAll}
                  />
                  <span style={{ margin: "0px 15px", fontWeight: 500, fontSize: "14px" }}>{i18n("Analytics.All")}</span>
                </div>{" "}
                <hr style={{ marginTop: "0.15rem", marginBottom: "0.35rem" }} />
                <div className="options-container">
                  {" "}
                  {/* Container for options */}
                  {options.map((option) => (
                    <label key={option.value} className="option-label">
                      {" "}
                      {/* Added class for styling */}
                      <div onChange={() => handleSelectedItem(option.id)} className="d-flex" style={{ alignItems: "center", cursor: "pointer" }}>
                        <input
                          style={{ height: "18px", width: "18px", border: "none", background: "#F2F2F2" }}
                          type="checkbox"
                          checked={option.isSelected}
                        />
                        <span
                          className="label-checkbox"
                          style={{ color: option.isDeleted === true ? "#EB5757" : "#333333" && option?.isArchived === true ? "#F2994A" : "#333333" }}
                        >
                          {option.label.includes("Default Site") ? i18n(`Analytics.${option.label}`) : option.label}
                        </span>
                      </div>
                    </label>
                  ))}
                </div>
              </>
            ) : (
              <div style={{ textAlign: "center", margin: "15px 0 " }}>No Options</div>
            )}
          </>
        </div>
      )}
    </div>
  );
};

export default CustomMultiSelect;
