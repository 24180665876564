import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import FormNavbar from "../form-navbar";
import ListItem from "./archive-list-components/list-item";
import FilterDialog from "../form-management/formFilterDialog";
import I18n, { i18n } from "../../../private/languageSelector";
import IconSearch from "../../../../assets/images/icon-search.svg";
import IconFilter from "../../../../assets/images/icon-filter.svg";
import DialogModal from "../../../../components/dialogs/compact-dialog";
import IconSortAlpha from "../../../../assets/images/icon-sort-alpha.svg";
import {
  getAssignedFormsForUser,
  getForms,
  patchForm,
} from "../../../../redux/reducers/form/form.reducer";
import { updateRoute } from "../../../../redux/reducers/authentication/authentication";
import {
  FaSortAlphaUp,
  FaSortAlphaDown,
  FaSortNumericUpAlt,
  FaSortNumericDownAlt,
} from "react-icons/fa";

const formFilterInitState = {
  fromDate: "",
  toDate: "",
  page: 1,
  isArchived: true,
};

const ArchiveListing = ({
  forms,
  getForms,
  roleAccess,
  currentRole,
  updateRoute,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [showSortModal, setShowSortModal] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [formFilterState, setFormFilterState] = useState(formFilterInitState);

  const [sortBy, setSortBy] = useState({
    attribute: "DATE_MODIFIED",
    order: "DESC",
  });

  useEffect(() => {
    updateRoute({
      step1: "Forms",
      step2: "Archive",
      enableBack: false,
      formScrol: false,
    });
    getForms({
      sortBy: "updatedAt:desc",
      isArchived: true,
    });
    // eslint-disable-next-line
  }, []);

  const searchSortFilter = (formsList) => {
    const activeForms = formsList?.filter((item) => item?.isArchived === true);
    if (formsList?.length) {
      let bookmarkedForms = activeForms?.filter((item) =>
        currentRole !== "user"
          ? item?.isBookmarked === true
          : item?.dispatchBookmarked === true
      );
      let unBookmarkedForms = activeForms?.filter((item) =>
        currentRole !== "user"
          ? item?.isBookmarked === false
          : item?.dispatchBookmarked === false
      );

      if (bookmarkedForms?.length) {
        if (searchValue) {
          bookmarkedForms = [
            ...bookmarkedForms.filter((item) =>
              item.formDetail.title
                .toLowerCase()
                .includes(searchValue.toLowerCase())
            ),
          ];
        }

        if (sortBy) {
          bookmarkedForms = [
            ...bookmarkedForms.sort(function (a, b) {
              if (sortBy.attribute === "FORM_NAME") {
                return (
                  (a.formDetail.title === b.formDetail.title
                    ? 0
                    : a.formDetail.title < b.formDetail.title
                    ? -1
                    : 1) * (sortBy.order === "ASC" ? 1 : -1)
                );
              } else if (sortBy.attribute === "DATE_CREATED") {
                return (
                  (a.createdAt.toString() === b.createdAt.toString()
                    ? 0
                    : a.createdAt.toString() < b.createdAt.toString()
                    ? -1
                    : 1) * (sortBy.order === "ASC" ? 1 : -1)
                );
              } else {
                return (
                  (a.updatedAt.toString() === b.updatedAt.toString()
                    ? 0
                    : a.updatedAt.toString() < b.updatedAt.toString()
                    ? -1
                    : 1) * (sortBy.order === "ASC" ? 1 : -1)
                );
              }
            }),
          ];
        }
      }

      if (unBookmarkedForms?.length) {
        if (searchValue) {
          unBookmarkedForms = [
            ...unBookmarkedForms.filter((item) =>
              item.formDetail.title
                .toLowerCase()
                .includes(searchValue.toLowerCase())
            ),
          ];
        }
        if (sortBy) {
          unBookmarkedForms = [
            ...unBookmarkedForms.sort(function (a, b) {
              if (sortBy.attribute === "FORM_NAME") {
                return (
                  (a.formDetail.title === b.formDetail.title
                    ? 0
                    : a.formDetail.title < b.formDetail.title
                    ? -1
                    : 1) * (sortBy.order === "ASC" ? 1 : -1)
                );
              } else if (sortBy.attribute === "DATE_CREATED") {
                return (
                  (a.createdAt.toString() === b.createdAt.toString()
                    ? 0
                    : a.createdAt.toString() < b.createdAt.toString()
                    ? -1
                    : 1) * (sortBy.order === "ASC" ? 1 : -1)
                );
              } else {
                return (
                  (a.updatedAt.toString() === b.updatedAt.toString()
                    ? 0
                    : a.updatedAt.toString() < b.updatedAt.toString()
                    ? -1
                    : 1) * (sortBy.order === "ASC" ? 1 : -1)
                );
              }
            }),
          ];
        }
      }

      return [...bookmarkedForms, ...unBookmarkedForms];
    } else {
      return [];
    }
  };

  const formFilterHandler = () => {
    getForms(formFilterState);
  };

  return (
    <>
      <div className="px-3">
        <FormNavbar moduleName={"archive"} />
        <div className="search-bar">
          <form className="form-search">
            <div className="input-group">
              <div className="input-group-prepend">
                <img src={IconSearch} alt="Search Icon" />
              </div>
              <input
                className="form-control"
                placeholder={i18n("global.Search")}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
              />
            </div>
          </form>
          <Link
            to="#"
            onClick={(e) => {
              e.preventDefault();
              setShowSortModal(true);
            }}
          >
            <img src={IconSortAlpha} alt="IconSortAlpha" />
          </Link>
          <Link
            to="#"
            onClick={(e) => {
              e.preventDefault();
              setShowFilterModal(true);
            }}
          >
            <img src={IconFilter} alt="IconFilter" />
          </Link>
        </div>
        {forms && forms.length ? (
          <>
            <ul className="form-list forms">
              {searchSortFilter(forms).map((form, index) =>
                form?.isDeleted !== true ? (
                  <ListItem form={form} key={index} roleAccess={roleAccess} />
                ) : null
              )}
            </ul>
          </>
        ) : null}
      </div>
      <DialogModal
        classes="modal-sort"
        showDlg={showSortModal}
        content={
          <ul className="list-unstyled">
            <li
              onClick={() => {
                if (sortBy.attribute === "FORM_NAME") {
                  if (sortBy.order === "ASC") {
                    setSortBy({ attribute: "FORM_NAME", order: "DESC" });
                  } else {
                    setSortBy({ attribute: "FORM_NAME", order: "ASC" });
                  }
                } else {
                  setSortBy({ attribute: "FORM_NAME", order: "ASC" });
                }
              }}
            >
              <span className="icon-wrap">
                {sortBy.attribute === "FORM_NAME" ? (
                  sortBy.order === "ASC" ? (
                    <FaSortAlphaDown />
                  ) : (
                    <FaSortAlphaUp />
                  )
                ) : null}
              </span>
              <strong>
                <I18n lng="global.FormName" />
              </strong>
            </li>
            <li
              onClick={() => {
                if (sortBy.attribute === "DATE_CREATED") {
                  if (sortBy.order === "ASC") {
                    setSortBy({ attribute: "DATE_CREATED", order: "DESC" });
                  } else {
                    setSortBy({ attribute: "DATE_CREATED", order: "ASC" });
                  }
                } else {
                  setSortBy({ attribute: "DATE_CREATED", order: "ASC" });
                }
              }}
            >
              <span className="icon-wrap">
                {sortBy.attribute === "DATE_CREATED" ? (
                  sortBy.order === "ASC" ? (
                    <FaSortNumericDownAlt />
                  ) : (
                    <FaSortNumericUpAlt />
                  )
                ) : null}
              </span>
              <strong>
                <I18n lng="global.DateCreated" />
              </strong>
            </li>
            <li
              onClick={() => {
                if (sortBy.attribute === "DATE_MODIFIED") {
                  if (sortBy.order === "ASC") {
                    setSortBy({ attribute: "DATE_MODIFIED", order: "DESC" });
                  } else {
                    setSortBy({ attribute: "DATE_MODIFIED", order: "ASC" });
                  }
                } else {
                  setSortBy({ attribute: "DATE_MODIFIED", order: "ASC" });
                }
              }}
            >
              <span className="icon-wrap">
                {sortBy.attribute === "DATE_MODIFIED" ? (
                  sortBy.order === "ASC" ? (
                    <FaSortNumericDownAlt />
                  ) : (
                    <FaSortNumericUpAlt />
                  )
                ) : null}
              </span>
              <strong>
                <I18n lng="global.DateModified" />
              </strong>
            </li>
          </ul>
        }
        primaryActionTitle={<I18n lng="global.Apply" />}
        secondaryAction={() => setShowSortModal(false)}
        primaryAction={() => setShowSortModal(false)}
      />
      <FilterDialog
        show={showFilterModal}
        hideInspector={true}
        formfilterState={true}
        filterState={formFilterState}
        primaryAction={formFilterHandler}
        setFilterState={setFormFilterState}
        filterInitState={formFilterInitState}
        secondryActionTitle={<I18n lng="global.Clear" />}
        secondaryAction={() => setShowFilterModal(false)}
        clearActions={() => {
          if (currentRole !== "user") {
            getForms({
              sortBy: "updatedAt:desc",
              isArchived: true,
            });
            setFormFilterState({ ...formFilterInitState });
          } else {
            getAssignedFormsForUser({
              sortBy: "formUpdatedAt:desc",
              isArchived: true,
            });
            setFormFilterState({ ...formFilterInitState });
          }
        }}
      />
    </>
  );
};

const mapStateToProps = ({ form, authentication }) => ({
  forms: form.forms,
  currentRole: authentication.currentRole,
  roleAccess: authentication.userRoleAccess,
});

const mapDispatchToProps = { getForms, patchForm, updateRoute };

export default connect(mapStateToProps, mapDispatchToProps)(ArchiveListing);
