import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import arrangeImage from "../../../../assets/images/analytic-arrange.svg";
import image from "../../../../assets/images/emptyAnalytic.svg";

import { IncludeStringInDropdownOrNot, ReportStatusColorHandler, ReportStatusHandler, TEXT_DIRECTION } from "../../../../helper/helperFunctions";
import { i18n } from "../../languageSelector";
import CustomPagination from "./CustomPagination";
function Table({ analyticsReports, setpage, page, setSize, size, total, reportTableHandler, user, handleGeneratePDF }: any) {
  const [tableHeaderState, setTableHeaderState] = useState<any[]>([]);
  let tableHeaderList = [
    i18n("Analytics.Report"),
    i18n("Analytics.Date"),
    i18n("Analytics.Form"),
    i18n("Analytics.Inspector"),
    i18n("Analytics.Site"),
    i18n("Analytics.Fail"),
    i18n("Analytics.Score (%)"),
    i18n("Analytics.Completion (%)"),
    i18n("Analytics.Status"),
  ];

  let tableHeaderObject = tableHeaderList.map((name, index) => {
    return {
      name: name,
      id: index + 1,
      isSelected:
        user && user?.analyticsSetting && user?.analyticsSetting?.reportTableToShow?.length > 0
          ? user?.analyticsSetting?.reportTableToShow?.includes(index + 1)
            ? true
            : false
          : true,
    };
  });

  useEffect(() => {
    setTableHeaderState(tableHeaderObject);
    // eslint-disable-next-line
  }, []);

  const [isHeaderSelected, setisHeaderSelected] = useState(false);

  const handleSelectAllClick = (e: any) => {
    const refactorTableHeaderObject = tableHeaderState.map((item) => {
      return { ...item, isSelected: e.target.checked };
    });
    setTableHeaderState(refactorTableHeaderObject);

    const selectedTableList = refactorTableHeaderObject.filter((item) => item.isSelected).map((item) => item.id);
    reportTableHandler(selectedTableList?.length > 0 ? selectedTableList : [0]);
  };
  const handleSelectClick = (id: number) => {
    const refactorSingleSelectedList = tableHeaderState.map((item, index) => {
      if (item.id === id) {
        return { ...item, isSelected: !item.isSelected };
      } else if (index === 0) {
        return { ...item, isSelected: true };
      } else {
        return item;
      }
    });
    setisHeaderSelected(true);
    setTableHeaderState(refactorSingleSelectedList);

    const selectedTableList = refactorSingleSelectedList.filter((item) => item.isSelected).map((item) => item.id);
    reportTableHandler(selectedTableList?.length > 0 ? selectedTableList : [0]);
  };
  // const { collapsed } = useSidebar();

  const openColumListRef = useRef<any>(null);

  const handleOutsideClick = (event: any) => {
    if (openColumListRef.current && !openColumListRef.current.contains(event.target)) {
      // setIsOpen(false);
      setisHeaderSelected(false);
    }
  };
  React.useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div
      style={{
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        backgroundColor: "#ffffff",
      }}
    >
      <div className="d-flex justify-content-between align-items-center mx-3 wrap-export " style={{ height: "50px" }}>
        {user?.roleSetting?.managerPortal[3]?.subItem[0]?.selected ? (
          <div
            className="export"
            style={{
              color: " #20639B",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "29.8px",
              letterSpacing: "0.12px",
              cursor: "pointer",
            }}
            onClick={() => {
              if (analyticsReports?.length > 0 && tableHeaderState.length > 0) {
                let selectedHeader = tableHeaderState.filter((item) => item.isSelected);
                handleGeneratePDF(selectedHeader);
              }
            }}
          >
            {i18n("Analytics.Export")}{" "}
          </div>
        ) : (
          ""
        )}

        <div style={{ position: "relative" }} className="" ref={openColumListRef}>
          <img src={arrangeImage} alt="icon" style={{ cursor: "pointer" }} onClick={() => setisHeaderSelected(!isHeaderSelected)} />
          {isHeaderSelected ? (
            <div
              style={{
                position: "absolute",
                top: "2rem",
                right: TEXT_DIRECTION() === "ltr" ? "0px" : "-210px",
                left: TEXT_DIRECTION() === "rtl" ? "210px" : "-210px",
                width: "239px",
                //  height: "400px",
                padding: "1rem",
                borderRadius: "5px",
                background: "white",
                fontSize: "14px",
              }}
              onClick={() => {
                setisHeaderSelected(true);
              }}
              className="shadow"
            >
              <div
                className="d-flex  align-items-center justify-content-start w-100 "
                style={{
                  borderBottom: "1px solid #E0E0E0",
                  marginBottom: "0.5rem",
                }}
              >
                <input
                  type="checkbox"
                  checked={tableHeaderState?.filter((item) => !item.isSelected)?.length > 0 ? false : true}
                  onClick={(e: any) => {
                    handleSelectAllClick(e);
                  }}
                  style={{
                    height: "18px",
                    width: "18px",
                    border: "none",
                    background: "#F2F2F2",
                  }}
                />

                <div className={`${TEXT_DIRECTION() === "ltr" ? "ml-4" : "mr-4"} my-2`} style={{ fontSize: "14px" }}>
                  {i18n("Analytics.All")}
                </div>
              </div>
              {tableHeaderState.map((item, index) => {
                return (
                  <div key={index} className="d-flex  align-items-center justify-content-start ">
                    {item.name.includes(i18n("Analytics.Report")) ? (
                      ""
                    ) : (
                      <>
                        <div>
                          <input
                            type="checkbox"
                            checked={item?.isSelected}
                            onClick={(e: any) => {
                              handleSelectClick(item?.id);
                            }}
                            style={{
                              height: "18px",
                              width: "18px",
                              border: "none",
                              background: "#F2F2F2",
                            }}
                          />
                        </div>

                        <div className={`${TEXT_DIRECTION() === "ltr" ? "ml-4" : "mr-4"} my-2`} style={{ fontSize: "14px" }}>
                          {IncludeStringInDropdownOrNot(item.name)}
                        </div>
                      </>
                    )}
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
      </div>
      <div className="table-responsive w-100">
        <table className="table table-main ">
          {tableHeaderState?.filter((item) => item.isSelected)?.length > 0 ? (
            <thead>
              <tr>
                {tableHeaderState.map((item, index) => {
                  let finded = item.isSelected;

                  return (
                    <th
                      key={index}
                      scope="col"
                      data-align="start"
                      className="header text-start"
                      style={{
                        color: "#828282",
                        columnFill: "balance",
                        border: "none",
                        fontSize: "14px",
                        fontWeight: 700,
                        borderTop: "1px solid #EAEAEA",
                      }}
                    >
                      {finded ? item.name : item.name.includes(i18n("Analytics.Report") ? item.name : "")}
                    </th>
                  );
                })}
              </tr>
            </thead>
          ) : null}

          <tbody
            className="w-100 h-100"
            style={{
              color: "#333333",
              fontWeight: "400",
              fontSize: "14px",
              flexWrap: "wrap",
            }}
          >
            {tableHeaderState?.filter((item) => item.isSelected)?.length > 0 && analyticsReports?.length > 0 ? (
              analyticsReports.map((item: any, index: number) => {
                return (
                  <tr
                    key={index}
                    style={{
                      height: index === analyticsReports.length - 1 ? "" : "50px",
                      color: "#333333",
                      fontWeight: "400",
                      fontSize: "14px",
                      border: "1px solid #EAEAEA",
                    }}
                  >
                    <th
                      scope="row"
                      style={{
                        color: "#333333",
                        fontWeight: "400",
                        fontSize: "14px",
                        alignItems: "start",
                        display: "flex",
                        justifyContent: "start",
                        border: "none",
                      }}
                    >
                      {`${i18n(`Analytics.${item?.site.name}`) === "undefined" ? item.site.name : i18n(`Analytics.${item?.site.name}`)} - ${
                        item?.assignee.fullName
                      } - ${moment(item?.updatedAt).format("DD-MMM-YYYY")}`}
                    </th>
                    <td
                      style={{
                        color: "#333333",
                        fontWeight: "400",
                        fontSize: "14px",
                      }}
                    >
                      {tableHeaderState?.filter((item) => item.id === 2 && item.isSelected)?.length > 0
                        ? moment(item?.updatedAt).format("DD-MMM-YYYY")
                        : ""}
                    </td>
                    <td
                      style={{
                        color: "#333333",
                        fontWeight: "400",
                        fontSize: "14px",
                      }}
                    >
                      {tableHeaderState?.filter((item) => item.id === 3 && item.isSelected)?.length > 0 ? item?.formDetail?.title : ""}
                    </td>
                    <td
                      style={{
                        color: "#333333",
                        fontWeight: "400",
                        fontSize: "14px",
                      }}
                    >
                      {tableHeaderState?.filter((item) => item.id === 4 && item.isSelected)?.length > 0 ? item?.assignee.fullName : ""}
                    </td>
                    <td
                      style={{
                        color: "#333333",
                        fontWeight: "400",
                        fontSize: "14px",
                      }}
                    >
                      {tableHeaderState?.filter((item) => item.id === 5 && item.isSelected)?.length > 0
                        ? i18n(`Analytics.${item.site?.name}`) === "undefined"
                          ? item.site?.name
                          : i18n(`Analytics.${item.site?.name}`)
                        : ""}
                    </td>
                    <td
                      style={{
                        color: "#333333",
                        fontWeight: "400",
                        fontSize: "14px",
                      }}
                    >
                      {tableHeaderState?.filter((item) => item.id === 6 && item.isSelected)?.length > 0
                        ? item?.failItem > 0
                          ? item?.failItem
                          : 0
                        : ""}
                    </td>
                    <td
                      style={{
                        color: "#333333",
                        fontWeight: "400",
                        fontSize: "14px",
                      }}
                    >
                      {tableHeaderState?.filter((item) => item.id === 7 && item.isSelected)?.length > 0 ? (item?.score !== 0 ? item.score : "-") : ""}
                    </td>
                    <td style={{ fontSize: "14px" }}>
                      {tableHeaderState?.filter((item) => item.id === 8 && item.isSelected)?.length > 0
                        ? item?.answered !== 0
                          ? item.answered
                          : "-"
                        : ""}
                    </td>
                    <td
                      style={{
                        color: ReportStatusColorHandler(item?.status),
                        fontWeight: "400",
                        fontSize: "14px",
                      }}
                    >
                      {tableHeaderState?.filter((item) => item.id === 9 && item.isSelected)?.length > 0 ? ReportStatusHandler(item?.status) : ""}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr className="w-100 h-100">
                <td colSpan={tableHeaderObject.length}>
                  <div className="d-flex table-body-empty mt-5 justify-content-center align-items-center flex-column w-100 h-100">
                    <img src={image} alt="" />
                    <p
                      className="pt-3"
                      style={{
                        color: "#333333",
                        fontSize: "14px",
                        lineHeight: "15.77px",
                      }}
                    >
                      {i18n("Analytics.There are no reports in the selected time period")}
                    </p>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <CustomPagination page={page} total={total} setpage={setpage} setSize={setSize} size={size} />
    </div>
  );
}

export default Table;
