import React from "react";
import "../../assets/css/auth.min.css";
import { i18n } from "../private/languageSelector";
import { Link, useHistory } from "react-router-dom";
import ImgLock from "../../assets/images/img-password-reset.svg";

const PasswordResetMessageSuccess = () => {
  const history = useHistory();

  return (
    <div className="auth-wrapper text-center align-center">
      <div className="auth-title">
        <h1 className="font-weight-light font-primary text-center">
          {i18n("ForgotPassword.PasswordReset")}
        </h1>
        <img className="mt-4 mb-5" src={ImgLock} alt={"ImgCompany"}></img>
        <p className="text-success">
          <h1 className="font-weight-light font-primary text-center">
            {i18n("ForgotPassword.PasswordWasSetSuccessfully")}
          </h1>
        </p>
      </div>
      <div className="btn-wrap text-center">
        <Link
          to="#"
          className="btn btn-primary"
          onClick={() => {
            history.push("/login");
          }}
        >
          {i18n("login.Login")}
        </Link>
      </div>
    </div>
  );
};
export default PasswordResetMessageSuccess;
