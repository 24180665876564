import React, { useEffect, useState } from "react";
import moment from "moment";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import I18n, { i18n } from "../../../languageSelector";
import AlertModal from "../alertModal/alerModal";
// import PagePdfPreview from "../../report-pdf-previews/pagePdfPeview";
import DropUpIcon from "../../../../../assets/images/dropUp-icon.png";
import IconReportStatus from "../../../../../assets/images/check.svg";
import IconDocBoard from "../../../../../assets/images/icon-doc-board.svg";
import DropDownIcon from "../../../../../assets/images/drop-down-arrow.svg";
import {
  getReportById,
  getOtherReports,
  updateReportStatus,
  getAdminManagerReports,
  getReportSettings,
} from "../../../../../redux/reducers/admin-manager-reports/admin-manager-report.reducer";
import PagePreview from "../pageReportPreview";

const TitleReportPreview = ({
  createdAt,
  updatedAt,
  startAt,
  status,
  updateReportStatus,
  getAdminManagerReports,
  user,
  approvedManagers,
  currentRole,
  assignee,
  documentNumber,
  title,
  site,
  reportShareSetting,
  filterState,
  totalPercentage,
  reportForEdit,
  failCount,
  inspectionNumber,
  otherReportView,
  getOtherReports,
  getReportSettings,
  isApproved,
  imageCountData,
  getReportById,
  inspectorName,
}) => {
  const { id } = useParams();
  let path = window.location.pathname.split("/");
  let timeZone = user?.company?.timeZone.split(" ");

  const [answers, setAnswers] = useState([]);
  const [failItems, setFailItems] = useState(0);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [reportSettings, setReportSettings] = useState();
  const [reasonForRejection, setReasonForRejection] = useState("");
  const [showFailedSummary, setShowFailedSummary] = useState(false);
  const [reportInspectionNumber, setReportInspectionNumber] = useState(0);
  const [showReportStatusOptions, setShowReportStatusOptions] = useState(false);
  const onChangeSelectorHandler = (key) => {
    if (key === "reject" && id) {
      setShowAlertModal(true);
    } else if (id && key) {
      updateReportStatus(id, { status: key, isRead: false }).then(() => {
        getReportById(id);
        if (path[2] === "my-reports") {
          getAdminManagerReports(false, filterState);
        } else {
          getOtherReports();
        }
      });
    }
  };

  const rejectReportResonHandler = () => {
    if (id && reasonForRejection !== "") {
      updateReportStatus(id, {
        reason: reasonForRejection,
        isRead: undefined,
        status: "reject",
      }).then(() => {
        if (path[2] === "my-reports") {
          getAdminManagerReports(false, filterState);
          setShowAlertModal(false);
        } else {
          getOtherReports();
          setShowAlertModal(false);
        }
      });
    } else {
      toast.error("Please enter reason");
    }
  };

  useEffect(() => {
    if (
      reportForEdit &&
      reportForEdit.formAnswerDetail &&
      reportForEdit.formAnswerDetail.length
    ) {
      setAnswers([...reportForEdit.formAnswerDetail]);
    }
    // eslint-disable-next-line
  }, [reportForEdit]);

  const getAnswer = (uuid) => {
    if (answers && answers.length) {
      const answer = answers.find((item) => item?.questionUuid === uuid);
      return answer ? answer : undefined;
    }
  };

  useEffect(() => {
    if (!showFailedSummary) {
      setFailItems(0);
    }
  }, [showFailedSummary]);

  useEffect(() => {
    setReportInspectionNumber(0);
  }, []);

  useEffect(() => {
    var str = "" + inspectionNumber;
    var pad = "0000";
    var ans = pad.substring(0, pad.length - str.length) + str;
    setReportInspectionNumber(ans);
  }, [inspectionNumber]);
  useEffect(() => {
    if (id && user) {
      getReportSettings(id, user.id).then((res) => {
        setReportSettings(res?.value?.data?.userReportSetting);
      });
    }
    // eslint-disable-next-line
  }, [id]);
  let approverIds = approvedManagers.map((approver) => approver.id);
  return (
    <div className="report-preview" id="id-0">
      {user?.role !== "user" &&
      otherReportView &&
      approverIds.includes(user.id) ? (
        <div className="btn-report-status-wrap">
          {showReportStatusOptions ? (
            <div className="btn-status-wrapper">
              <div
                className="btn-status btn-reject"
                onClick={() => onChangeSelectorHandler("reject")}
              >
                <I18n lng="Reports.Reject" />
              </div>
              <div
                className="btn-status btn-accept"
                onClick={() => onChangeSelectorHandler("accept")}
              >
                <I18n lng="Reports.Accept" />
              </div>
              <div
                className="btn-status btn-incomplete"
                onClick={() => onChangeSelectorHandler("incomplete")}
              >
                <I18n lng="Reports.Incomplete" />
              </div>
            </div>
          ) : null}
          {isApproved ? (
            <button
              className={`btn-report-status ${
                status === "reject"
                  ? "reject"
                  : status === "accept"
                  ? "accept"
                  : status === "incomplete"
                  ? "incomplete"
                  : ""
              } `}
              onClick={() =>
                setShowReportStatusOptions(!showReportStatusOptions)
              }
            >
              <img src={IconReportStatus} alt="IconReportStatus" />
            </button>
          ) : null}
        </div>
      ) : null}
      <section className="report-title">
        <div className="title-detail">
          <h6>
            <img className="ml-2" src={IconDocBoard} alt="Doc Board" />{" "}
            {title ? (
              title.includes("Untitled Form") ? (
                <>
                  {title.replace("Untitled Form", i18n("global.UntitledForm"))}{" "}
                </>
              ) : (
                title
              )
            ) : null}
          </h6>
        </div>
        <div className="card card-stat mb-3">
          <p>
            <span>
              {" "}
              {reportSettings?.title?.siteName
                ? reportForEdit?.site?.name === "Default Site"
                  ? `${i18n("Company.DefaultSite") + " - "}`
                  : reportForEdit?.site?.name
                  ? reportForEdit?.site?.name + " - "
                  : ""
                : ""}
            </span>
            <span>
              {" "}
              {reportForEdit?.assignee?.fullName
                ? reportForEdit?.assignee?.fullName + " "
                : ""}
            </span>
            -{" "}
            {reportSettings?.title?.dateTime ? (
              <span className="direction-ltr date">
                {moment(reportForEdit?.startAt).format("DD MMM YYYY")}
              </span>
            ) : (
              ""
            )}
          </p>
          <div className="report-stat">
            <div className="stat-block">
              {reportShareSetting?.content?.scores &&
                reportForEdit?.formDetail?.schema?.isScored && (
                  <>
                    <h6 className="text-center">
                      {totalPercentage.toFixed(2)}%
                    </h6>
                    <small className="text-center">
                      <I18n lng="Reports.InspectionScore" />
                    </small>
                  </>
                )}
            </div>
            <div className="stat-block">
              {reportShareSetting?.content?.failsCount && (
                <>
                  <h6 className="text-danger text-center">{failCount}</h6>
                  <small>
                    <I18n lng="Reports.FailedItems" />
                  </small>
                </>
              )}
            </div>
            <div className="stat-block ">
              <h6
                className={`text-center ${
                  status === "reject"
                    ? "status-reject"
                    : status === "accept"
                    ? "status-accept"
                    : status === "submit"
                    ? "status-submit"
                    : status === "incomplete"
                    ? "status-warning"
                    : status === "draft"
                    ? "status-draft"
                    : ""
                }`}
              >
                {status === "accept" ? (
                  <I18n lng="Reports.Compelete" />
                ) : status === "reject" ? (
                  <I18n lng="Reports.RejectStatus" />
                ) : status === "accept" ? (
                  <I18n lng="Reports.Compelete" />
                ) : status === "submit" ? (
                  <I18n lng="Reports.Submitted" />
                ) : status === "incomplete" ? (
                  <I18n lng="Reports.Incomplete" />
                ) : status === "draft" ? (
                  <I18n lng="Reports.Draft" />
                ) : status === "reSubmit" ? (
                  <I18n lng="Reports.ResubmitStatus" />
                ) : (
                  "status-accept"
                )}
              </h6>
              <small>
                <I18n lng="global.Status" />
              </small>
            </div>
          </div>
        </div>
        {user?.role !== "user" &&
          (reportShareSetting?.content?.dateTime ? (
            <div className="card card-stat p-0">
              <div className="report-stat m-0 border-0">
                <div className="stat-block">
                  <h6 className="text-center direction-ltr">
                    {moment(createdAt).format("DD MMM YYYY")}
                  </h6>
                  <div className="text-center direction-ltr">
                    {timeZone?.length ? (
                      <small>
                        {moment(createdAt).utcOffset(timeZone[1]).format("LT")}
                      </small>
                    ) : (
                      <small>{moment(createdAt).format("LT")}</small>
                    )}
                  </div>
                  <small>
                    <I18n lng="global.CreatedOn" />
                  </small>
                </div>
                <div className="stat-block">
                  <h6 className="text-center direction-ltr">
                    {moment(updatedAt).format("DD MMM YYYY")}
                  </h6>
                  <div className="text-center direction-ltr">
                    {timeZone?.length ? (
                      <small>
                        {moment(updatedAt).utcOffset(timeZone[1]).format("LT")}
                      </small>
                    ) : (
                      <small>{moment(updatedAt).format("LT")}</small>
                    )}
                  </div>
                  <small>
                    <I18n lng="global.LastUpdated" />
                  </small>
                </div>

                {reportShareSetting?.content?.dateTime && (
                  <div className="stat-block">
                    {reportForEdit?.status !== "draft" ? (
                      <>
                        <h6 className="text-center direction-ltr">
                          {moment(startAt).format("DD MMM YYYY")}
                        </h6>
                        <div className="text-center direction-ltr">
                          {timeZone?.length ? (
                            <small>
                              {moment(startAt)
                                .utcOffset(timeZone[1])
                                .format("LT")}
                            </small>
                          ) : (
                            <small>{moment(startAt).format("LT")}</small>
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <h6 className="text-center">
                          <I18n lng="global.N/a" />
                        </h6>
                        <div className="text-center">
                          <small>
                            <I18n lng="global.N/a" />
                          </small>
                        </div>
                      </>
                    )}
                    <small>
                      <I18n lng="Reports.ConductedOn" />
                    </small>
                  </div>
                )}
              </div>
            </div>
          ) : (
            ""
          ))}
      </section>
      <section className="title-page">
        <h6 className="section-title primary">
          <I18n lng="global.TitlePage" />
        </h6>

        <div className="card">
          <small>
            <I18n lng="global.DocumentNo" />
          </small>
          <h6>{`${documentNumber}-${reportInspectionNumber}`}</h6>
        </div>

        <div className="card">
          <small>
            <I18n lng="Reports.ConductedOn" />
          </small>
          <h6 className="direction-ltr">
            {moment(createdAt).format("DD MMM YYYY")}
          </h6>
        </div>

        <div className="card">
          <small>
            <I18n lng="Reports.Preparedby" />
          </small>
          <h6>
            {" "}
            {reportForEdit?.assignee?.fullName
              ? reportForEdit?.assignee?.fullName + " "
              : ""}
          </h6>
        </div>

        <div className="card">
          <small>
            <I18n lng="global.Site" />
          </small>
          <h6>
            {site?.name === "Default Site"
              ? `${i18n("Company.DefaultSite")}`
              : site?.name}
          </h6>
        </div>

        <div
          className="card failed-Summary"
          onClick={() => setShowFailedSummary((preSate) => !preSate)}
        >
          <div className="d-flex justify-content-between">
            <small>
              <I18n lng="Reports.FailSummary" />
            </small>
            <small>
              {showFailedSummary ? (
                <img src={DropUpIcon} alt="drop-down-icon" />
              ) : (
                <img src={DropDownIcon} alt="drop-down-icon" />
              )}
            </small>
          </div>
        </div>
      </section>
      {showFailedSummary && (
        <section>
          <ul className="card failed-answers">
            {reportForEdit &&
            reportForEdit.formDetail.schema.pages.length &&
            answers &&
            answers.length
              ? reportForEdit.formDetail.schema.pages.map((item, index) => {
                  return (
                    <PagePreview
                      key={index}
                      page={item}
                      getAnswer={getAnswer}
                      setFailItems={setFailItems}
                      failItems={failItems}
                      failStatus={true}
                      viewPageSections={false}
                      instructionHide={
                        reportForEdit?.reportShareSetting?.content?.instructions
                      }
                      hideAndShow={reportForEdit?.reportShareSetting}
                      answers={answers}
                      index={index}
                      imageCountData={imageCountData}
                    />
                  );
                })
              : null}
          </ul>
        </section>
      )}

      <AlertModal
        show={showAlertModal}
        secondaryAction={() => {
          setShowAlertModal(false);
        }}
        role={currentRole}
        primaryAction={rejectReportResonHandler}
        setReasonForRejection={setReasonForRejection}
        reasonForRejection={reasonForRejection}
      />
    </div>
  );
};
const mapStateToProps = ({ authentication }) => ({
  user: authentication.user,
  currentRole: authentication.currentRole,
});
const mapDispatchToprops = {
  getReportById,
  getOtherReports,
  updateReportStatus,
  getAdminManagerReports,
  getReportSettings,
};
export default connect(mapStateToProps, mapDispatchToprops)(TitleReportPreview);
