import React, { useEffect } from "react";
import I18n from "../../../languageSelector";
import { compose, withProps } from "recompose";
import SignatureCanvas from "react-signature-canvas";
import { withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import { TEXT_DIRECTION } from "../../../../../helper/helperFunctions";

const MapComponent = compose(
  withProps({
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `150px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withGoogleMap
)((props) => (
  <GoogleMap
    defaultZoom={7}
    center={{
      lat: props.lat,
      lng: props.lng,
    }}
  >
    {props.markers && props.markers.length > 0
      ? props.markers.map((marker, index) => (
          <Marker key={index} position={marker.position} draggable={false} />
        ))
      : null}
  </GoogleMap>
));

const DetailPdfPagePreview = ({
  location,
  signature,
  creatorName,
  showDocumentMedia,
}) => {
  let sigRef = React.useRef(null);

  const markers = [
    {
      name: "Current position",
      position: {
        lat: parseFloat(location?.latitude ? location?.latitude : 31.5203696),
        lng: parseFloat(
          location?.longitude ? location?.longitude : 74.35874729999999
        ),
      },
    },
  ];

  useEffect(() => {
    if (signature && signature.length) {
      sigRef?.fromData(signature);
      sigRef?.off();
    } else {
      sigRef.off();
    }
  }, [signature]);

  return (
    <>
      <div className="table-body no-count">
        <div
          className="row table-row question status-na ml-0 mr-0 p-0"
          style={{
            boxShadow: "0px 5px 5px rgba(0,0,0,.10)",
            backgroundColor: "#ffffff",
          }}
        >
          <div className="col-lg-8 col-md-6 mb-3 table-column d-flex question-type px-3 my-3">
            <div className={TEXT_DIRECTION() === "rtl" ? "mr-3" : "ml-3"}>
              <label>
                <I18n lng="Reports.Preparedby" />:
              </label>
              <strong style={{ margin: "2px 8px" }}>{creatorName}</strong>
            </div>
            <div className="sig-wrap">
              <div className="signature">
                <SignatureCanvas
                  ref={(ref) => {
                    sigRef = ref;
                  }}
                  canvasProps={{
                    width: 500,
                    height: 350,
                    className: "sigCanvas",
                  }}
                />
              </div>
            </div>
          </div>
          {location && location.longitude ? (
            <div className=" col-lg-4 col-md-6 table-column p-0">
              <div className="m-3">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-6 pr-0 pt-0">
                    {" "}
                    <label
                      style={{
                        fontSize: "17px",
                        color: "#4f4f4f",
                        fontWeight: "600",
                      }}
                    >
                      <I18n lng="Form.Location" />
                    </label>
                    <strong
                      style={{
                        fontSize: "15px",
                        color: "#4f4f4f",
                        fontWeight: "500",
                      }}
                      className="d-block w-100 mb-2"
                    >
                      {location?.address ? location?.address : " "} ({" "}
                      {location?.latitude
                        ? (location?.latitude).toFixed(4)
                        : ""}
                      ,{" "}
                      {location?.longitude
                        ? (location?.longitude).toFixed(4)
                        : ""}
                      )
                    </strong>
                  </div>
                  <div className="col-lg-12 col-md-12 col-6">
                    {" "}
                    <div
                      className="loc-wrap d-flex mb-2"
                      style={{
                        border: "2.06286px solid #4F4F4F",
                        borderRadius: "7px",
                      }}
                    >
                      <div className="loc-map">
                        <MapComponent
                          height="100%"
                          lat={parseFloat(
                            location?.latitude ? location?.latitude : 31.5203696
                          )}
                          lng={parseFloat(
                            location?.longitude
                              ? location?.longitude
                              : 74.35874729999999
                          )}
                          markers={markers}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : !showDocumentMedia ? (
            <div className="d-flex p-2 h6">
              Location access was blocked by user
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};
export default DetailPdfPagePreview;
