/** @format */
import { connect } from "react-redux";
import DynamicModal from "../../../../../components/dialogs";
import I18n, { i18n } from "../../../languageSelector";
import moment from "moment";
import { TEXT_DIRECTION } from "../../../../../helper/helperFunctions";
import React, { useEffect, useRef, useState } from "react";
import SubscriptionsCardDialog from "./card-details/subscriptions-card-details";
import PlanDialog from "../../../../../components/dialogs/PlanDialog";
import processPaymentImage from "../../../../../assets/images/ProcessPaymentLoaderSVG.svg";
import { getAllInvoices, getSubscriptionByCompanyId, updateSubscriptionData } from "../../../../../redux/reducers/subscription/subscription.reducer";
import jsPDF from "jspdf";
import { getSession } from "../../../../../redux/reducers/authentication/authentication";
import html2canvas from "html2canvas";
import SubscriptionInvoice from "./subscription-invoice";
import alertIcon from "../../../../../assets/images/alertIconUpdatePlan.svg";
import { createUser, getAllUsers, updateUserById } from "../../../../../redux/reducers/user/user.reducer";
import { useParams } from "react-router-dom";
import { getInvoices } from "../../../../../redux/reducers/invoices/invoices.reducer";
import toast from "react-hot-toast";
import { getCard, getCompanyById } from "../../../../../redux/reducers/company/company.reducer";

const ChargeBillingDetails = ({
  show,
  onHide,
  user,
  selectedPlan,
  updateSubscriptionData,
  subscriptionData,
  getSubscriptionByCompanyId,
  loading,
  getSession,
  setSelectedPlan,
  isAdditionalSeatsAdd,
  billingSeats,
  billingTotalSeats,
  openBillingDetailsIfSeatsIsZero,
  createUser,
  userData,
  accessRightsValues,
  seatedType,
  secondaryAction,
  getAllUsers,
  updateUserById,
  data,
  userForEdit,
  setUpdateSeatsModal,
  getInvoices,
  fromUsers,
  getCard,
  getCompanyById,
  getAllInvoices,
}) => {
  const { id } = useParams();
  const [showCardDialog, setShowCardDialog] = useState(false);
  const [cardIndex, setCardIndex] = useState(null);
  const [cardToEdit, setCardToEdit] = useState({});
  const [edit, setEdit] = useState(false);
  const [addPaymentModal, setAddPaymentModal] = useState(false);
  const [showProcessPaymentLoader, setShowProcessPaymentLoader] = useState(false);
  const [planUpdatedUI, setPlanUpdateUI] = useState(false);
  const Language = localStorage.getItem("language");
  const [showInvoice, setShowInvoice] = useState(false);
  const [downloadInvoicePdf, setDownloadInvoicePdf] = useState(false);
  const rxPrintRef = useRef(null);
  const [showMonthlyDecrementSeatsModal, setShowMonthlyDecrementSeatsModal] = useState(false);
  const [isPaymentCardAdded, setIsPaymentCardAdded] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [isPlanChanged, setIsPlanChanged] = useState(false);
  const [companyPaymentCard, setCompanyPaymentCard] = useState();
  const [getCardLoading, setGetCardLoading] = useState(false);

  console.log(selectedPlan, "selectedPlan");

  let subscriptionEndDate = new Date(subscriptionData?.subscriptionEndedAt);

  let currentDate = new Date();

  let timeDifference = subscriptionEndDate.getTime() - currentDate.getTime();

  let hoursRemaining = timeDifference / (1000 * 3600);

  let costPerSeatPerMonth = (selectedPlan ? selectedPlan : subscriptionData?.currentPlan?.plan) === "Monthly Subscription" ? 79 : 790;

  let monthlyYearly = (selectedPlan ? selectedPlan : subscriptionData?.currentPlan?.plan) === "Monthly Subscription" ? 30 : 365;

  let proratedCostPerSeat = (costPerSeatPerMonth / (monthlyYearly * 24)) * hoursRemaining;

  let totalCostBefore = billingSeats * proratedCostPerSeat;

  let proratedSeatTax = totalCostBefore * 0.15;

  let totalCost = proratedSeatTax + totalCostBefore;

  const getInvoice = () => {
    if (invoices?.length > 0) {
      const lastInvoice = invoices[0];
      return lastInvoice;
    } else {
      return null;
    }
  };

  const invoiceData = getInvoice();

  useEffect(() => {
    const fetchCard = async () => {
      setGetCardLoading(true);
      if (user?.company?.id) {
        try {
          const response = await getCompanyById(user?.company?.id);
          if (response?.value?.data?.paymentCards?.moyasar?.token) {
            const cardResponse = await getCard(user?.company?.id);
            setCompanyPaymentCard(cardResponse?.value?.data);
          }
          if (!response?.value?.data?.paymentCards && show) {
            setAddPaymentModal(true);
          }
        } catch (error) {
          console.error("Error fetching card:", error);
        } finally {
          setGetCardLoading(false);
        }
      }
    };

    fetchCard();
  }, [user?.company?.id, getCard, getCompanyById, show]);

  useEffect(() => {
    getSubscriptionByCompanyId(user?.company?.companyId);
  }, [show, getSubscriptionByCompanyId, user?.company?.companyId]);

  useEffect(() => {
    if (companyPaymentCard) {
      setCardToEdit(companyPaymentCard);
      setCardIndex(1);
    }
  }, [companyPaymentCard]);

  const parsedBillingDetails = subscriptionData?.lastSubscriptionData;

  let usedSeatCalculation;

  if (subscriptionData?.currentPlan?.status === "Expired" && billingSeats === undefined && billingTotalSeats === undefined) {
    if (localStorage.getItem("selectedPlan")) {
      usedSeatCalculation =
        localStorage.getItem("selectedPlan") === "Monthly Subscription" ? 79 * subscriptionData?.seats?.total : 790 * subscriptionData?.seats?.total;
    }
  }

  if (subscriptionData?.currentPlan?.status === "Expired" && billingSeats > 0) {
    usedSeatCalculation =
      selectedPlan === "Monthly Subscription" || parsedBillingDetails?.plan === "Monthly Subscription" ? 79 * billingSeats : 790 * billingSeats;
  }

  if (subscriptionData?.currentPlan?.status === "Expired" && billingSeats < 0) {
    usedSeatCalculation =
      selectedPlan === "Monthly Subscription" || parsedBillingDetails?.plan === "Monthly Subscription"
        ? 79 * billingTotalSeats
        : 790 * billingTotalSeats;
  }

  if (subscriptionData?.currentPlan?.status === "Expired" && !billingSeats && selectedPlan !== subscriptionData?.currentPlan?.plan) {
    usedSeatCalculation =
      selectedPlan === "Monthly Subscription" || parsedBillingDetails?.plan === "Monthly Subscription"
        ? 79 * subscriptionData?.seats?.total
        : 790 * subscriptionData?.seats?.total;
  }

  if (subscriptionData?.currentPlan?.status === "Expired" && (billingSeats < 0) & (selectedPlan !== subscriptionData?.currentPlan?.plan)) {
    usedSeatCalculation =
      selectedPlan === "Monthly Subscription" || parsedBillingDetails?.plan === "Monthly Subscription"
        ? 79 * billingTotalSeats
        : 790 * billingTotalSeats;
  }

  // If user subscribe between Trial
  if (subscriptionData?.currentPlan?.plan === "Trial") {
    usedSeatCalculation =
      (selectedPlan ? selectedPlan : parsedBillingDetails?.plan) === "Monthly Subscription"
        ? 79 * subscriptionData?.seats?.total
        : 790 * subscriptionData?.seats?.total;
  }

  // If user subscribe after trial cancel doesnt matter from where
  if (subscriptionData?.userTrial?.isTrialCancelled && subscriptionData?.currentPlan?.plan === "Free") {
    usedSeatCalculation =
      (selectedPlan ? selectedPlan : parsedBillingDetails?.plan) === "Monthly Subscription" ? 79 * billingTotalSeats : 790 * billingTotalSeats;
  }
  if (subscriptionData?.userTrial?.isTrialCancelled && subscriptionData?.currentPlan?.plan === "Free" && isAdditionalSeatsAdd === undefined) {
    usedSeatCalculation =
      (selectedPlan ? selectedPlan : parsedBillingDetails?.plan) === "Monthly Subscription"
        ? 79 * subscriptionData?.seats?.used
        : 790 * subscriptionData?.seats?.used;
  }

  // When user changes the plan
  if (
    subscriptionData?.currentPlan?.plan !== (selectedPlan ? selectedPlan : parsedBillingDetails?.plan) &&
    subscriptionData?.subscriptionInfo?.isSubscribed === true
  ) {
    // console.log("2");
    usedSeatCalculation =
      (selectedPlan ? selectedPlan : parsedBillingDetails?.plan) === "Monthly Subscription" ? 79 * billingTotalSeats : 790 * billingTotalSeats;
  }

  const isValidSubscription =
    (subscriptionData?.currentPlan?.status === "Running" || subscriptionData?.currentPlan?.status === "Unsubscribed, subscription is running") &&
    (subscriptionData?.currentPlan?.plan === "Monthly Subscription" || subscriptionData?.currentPlan?.plan === "Yearly Subscription") &&
    subscriptionData?.currentPlan?.plan === (selectedPlan ? selectedPlan : parsedBillingDetails?.plan);

  if (isValidSubscription && billingSeats > 0) {
    // console.log("3");
    usedSeatCalculation =
      (selectedPlan ? selectedPlan : parsedBillingDetails?.plan) === "Monthly Subscription" ? 79 * billingSeats : 790 * billingSeats;
  } else if (isValidSubscription && billingSeats <= 0) {
    usedSeatCalculation =
      (selectedPlan ? selectedPlan : parsedBillingDetails?.plan) === "Monthly Subscription" ? 79 * billingTotalSeats : 790 * billingTotalSeats;
  }

  if (subscriptionData?.currentPlan?.status === "Paused") {
    if (billingSeats && billingTotalSeats) {
      usedSeatCalculation =
        (selectedPlan ? selectedPlan : parsedBillingDetails?.plan) === "Monthly Subscription" ? 79 * billingTotalSeats : 790 * billingTotalSeats;
    } else {
      usedSeatCalculation =
        (selectedPlan ? selectedPlan : parsedBillingDetails?.plan) === "Monthly Subscription"
          ? 79 * subscriptionData?.seats?.total
          : 790 * subscriptionData?.seats?.total;
    }
  }

  let taxOnUsedSeatCalculation = usedSeatCalculation * 0.15;
  let totalAmount = usedSeatCalculation + taxOnUsedSeatCalculation;

  const addPaymentModalData = {
    title: i18n("Subscriptions.AddPaymentMethod"),
    text_one: <I18n lng="Subscriptions.YouMustAddPaymentMethodToCompleteTheProcess" />,
    actionButtonText: i18n("Subscriptions.AddPaymentMethod"),
    closeButtonText: <I18n lng="global.Close" />,
  };

  useEffect(() => {
    if (
      subscriptionData?.subscriptionInfo?.isSubscribed === true &&
      (selectedPlan ? selectedPlan : parsedBillingDetails?.plan) &&
      subscriptionData?.currentPlan?.plan
    ) {
      if ((selectedPlan ? selectedPlan : parsedBillingDetails?.plan) !== subscriptionData?.currentPlan?.plan) {
        setIsPlanChanged(true);
      } else {
        setIsPlanChanged(false);
      }
    }
    // eslint-disable-next-line
  }, [show]);

  const billingDetails = {
    plan: selectedPlan,
    seats: billingSeats,
    seatsPrice: usedSeatCalculation,
    taxes: Number(taxOnUsedSeatCalculation?.toFixed(2)),
    total: Number(totalAmount.toFixed(2)),
    todaysTotal: Number(totalCost?.toFixed(2)),
    proratedCostPerSeat: proratedCostPerSeat.toFixed(2),
  };

  let tax = selectedPlan === "Monthly Subscription" ? 79 * subscriptionData?.seats?.total * 0.15 : 790 * subscriptionData?.seats?.total * 0.15;

  const subscriptionDataToSubscribe = {
    currentPlan: {
      plan: (selectedPlan ? selectedPlan : parsedBillingDetails?.plan) === "Monthly Subscription" ? "Monthly Subscription" : "Yearly Subscription",
      status:
        subscriptionData?.userTrial?.isTrialCancelled === false && subscriptionData?.userTrial?.isTrialExpired === false
          ? "Trial is running"
          : "Running",
      seats: subscriptionData?.seats?.total,
      price: (selectedPlan ? selectedPlan : parsedBillingDetails?.plan) === "Monthly Subscription" ? 79 : 790,
    },
    seats: {
      used: subscriptionData?.seats?.used,
      available: subscriptionData?.seats?.total - subscriptionData?.seats?.used,
      total: subscriptionData?.seats?.total,
    },
    subscriptionInfo: {
      isSubscribed: true,
      isSubscribedExpired: false,
      isUnsubscribed: false,
      isSubscribeResumed: false,
    },
    nextUpdatedPlan:
      (selectedPlan ? selectedPlan : parsedBillingDetails?.plan) === "Monthly Subscription" ? "Monthly Subscription" : "Yearly Subscription",
    isPlanChanged: isPlanChanged,
    lastAdditionalseats: {
      seats: billingSeats < 0 ? billingTotalSeats : billingSeats,
      seatsBill: totalCost,
      lastPerSeatBill: proratedCostPerSeat,
    },
    // lastSubscriptionData: {},
  };

  const subscriptionDataThatSubscribeAfterBackToFreePlanAndAgainSubscribe = {
    currentPlan: {
      plan: (selectedPlan ? selectedPlan : parsedBillingDetails?.plan) === "Monthly Subscription" ? "Monthly Subscription" : "Yearly Subscription",
      status:
        subscriptionData?.userTrial?.isTrialCancelled === false && subscriptionData?.userTrial?.isTrialExpired === false
          ? "Trial is running"
          : "Running",
      seats: parsedBillingDetails?.seats ? parsedBillingDetails?.seats : subscriptionData?.seats?.used,
      price: (selectedPlan ? selectedPlan : parsedBillingDetails?.plan) === "Monthly Subscription" ? 79 : 790,
    },
    seats: {
      used: subscriptionData?.seats?.used,
      available: parsedBillingDetails?.seats ? parsedBillingDetails?.seats - subscriptionData?.seats?.used : 0,
      total: parsedBillingDetails?.seats ? parsedBillingDetails?.seats : subscriptionData?.seats?.used,
    },
    subscriptionInfo: {
      isSubscribed: true,
      isSubscribedExpired: false,
      isUnsubscribed: false,
      isSubscribeResumed: false,
    },
    nextUpdatedPlan:
      (selectedPlan ? selectedPlan : parsedBillingDetails?.plan) === "Monthly Subscription" ? "Monthly Subscription" : "Yearly Subscription",
    isPlanChanged: isPlanChanged,
    lastAdditionalseats: {
      seats: billingSeats < 0 ? billingTotalSeats : billingSeats,
      seatsBill: totalCost,
      lastPerSeatBill: proratedCostPerSeat,
    },
    // lastSubscriptionData: {},
  };

  const subscriptionDataToSubscribeAfterCancelTrial = {
    currentPlan: {
      plan: (selectedPlan ? selectedPlan : parsedBillingDetails?.plan) === "Monthly Subscription" ? "Monthly Subscription" : "Yearly Subscription",
      status:
        subscriptionData?.userTrial?.isTrialCancelled === false && subscriptionData?.userTrial?.isTrialExpired === false
          ? "Trial is running"
          : "Running",
      seats: parsedBillingDetails?.seats ? parsedBillingDetails?.seats : subscriptionData?.seats?.used,
      price: (selectedPlan ? selectedPlan : parsedBillingDetails?.plan) === "Monthly Subscription" ? 79 : 790,
    },
    seats: {
      used: subscriptionData?.seats?.used,
      available: parsedBillingDetails?.seats ? parsedBillingDetails?.seats - subscriptionData?.seats?.used : 0,
      total: parsedBillingDetails?.seats ? parsedBillingDetails?.seats : subscriptionData?.seats?.used,
    },
    subscriptionInfo: {
      isSubscribed: true,
      isSubscribedExpired: false,
      isUnsubscribed: false,
      isSubscribeResumed: false,
    },
    nextUpdatedPlan:
      (selectedPlan ? selectedPlan : parsedBillingDetails?.plan) === "Monthly Subscription" ? "Monthly Subscription" : "Yearly Subscription",
    isPlanChanged: isPlanChanged,
    lastAdditionalseats: {
      seats: billingSeats < 0 ? billingTotalSeats : billingSeats,
      seatsBill: totalCost,
      lastPerSeatBill: proratedCostPerSeat,
    },
    // lastSubscriptionData: {},
  };

  const subscriptionDataThatOnlyChangeFromMonthlyToYearly = {
    currentPlan: {
      plan: selectedPlan === "Monthly Subscription" ? "Monthly Subscription" : "Yearly Subscription",
      status: subscriptionData?.currentPlan?.status,
      seats: subscriptionData?.currentPlan?.seats,
      price: selectedPlan === "Monthly Subscription" ? 79 : 790,
    },
    seats: {
      used: subscriptionData?.seats?.used,
      available: subscriptionData?.seats?.available,
      total: subscriptionData?.seats?.total,
    },
    subscriptionInfo: {
      isSubscribed: subscriptionData?.subscriptionInfo.isSubscribed,
      isSubscribedExpired: subscriptionData?.subscriptionInfo?.isSubscribedExpired,
      isUnsubscribed: subscriptionData?.subscriptionInfo?.isUnsubscribed,
      isSubscribeResumed: false,
    },
    nextUpdatedPlan: selectedPlan === "Monthly Subscription" ? "Monthly Subscription" : "Yearly Subscription",
    isPlanChanged: isPlanChanged,
    lastAdditionalseats: {
      seats: subscriptionData?.seats?.total,
      seatsBill: selectedPlan === "Monthly Subscription" ? 79 * subscriptionData?.seats?.total + tax : subscriptionData?.seats?.total * 790 + tax,
      lastPerSeatBill: proratedCostPerSeat,
    },
    // lastSubscriptionData: {},
  };

  const updatingSubscriptionData = {
    currentPlan: {
      plan: selectedPlan === "Monthly Subscription" ? "Monthly Subscription" : "Yearly Subscription",
      status:
        subscriptionData?.userTrial?.isTrialCancelled === false && subscriptionData?.userTrial?.isTrialExpired === false
          ? "Trial is running"
          : "Running",
      seats: billingTotalSeats,
      price: selectedPlan === "Monthly Subscription" ? 79 : 790,
    },
    seats: {
      used: subscriptionData?.seats?.used,
      available: billingTotalSeats - subscriptionData?.seats?.used,
      total: billingTotalSeats,
    },
    subscriptionInfo: {
      isSubscribed: true,
      isSubscribedExpired: false,
      isUnsubscribed: false,
      isSubscribeResumed: false,
    },
    nextUpdatedPlan: selectedPlan === "Monthly Subscription" ? "Monthly Subscription" : "Yearly Subscription",
    isPlanChanged: isPlanChanged,
    lastAdditionalseats: {
      seats: billingSeats < 0 ? billingTotalSeats : billingSeats,
      seatsBill: totalCost,
      lastPerSeatBill: proratedCostPerSeat,
    },
    // lastSubscriptionData: {},
  };

  const updatingSubscriptionDataWhileUnsubscribe = {
    currentPlan: {
      plan: selectedPlan ? selectedPlan : subscriptionData?.currentPlan?.plan,
      status: subscriptionData?.currentPlan?.status,
      seats: billingTotalSeats,
      price: selectedPlan === "Monthly Subscription" ? 79 : 790,
    },
    seats: {
      used: subscriptionData?.seats?.used,
      available: subscriptionData?.seats?.available + billingSeats,
      total: billingTotalSeats,
    },
    subscriptionInfo: {
      isSubscribed: true,
      isSubscribedExpired: false,
      isUnsubscribed: true,
      isSubscribeResumed: false,
    },
    nextUpdatedPlan: selectedPlan === "Monthly Subscription" ? "Monthly Subscription" : "Yearly Subscription",
    isPlanChanged: isPlanChanged,
    lastAdditionalseats: {
      seats: billingSeats < 0 ? billingTotalSeats : billingSeats,
      seatsBill: totalCost,
      lastPerSeatBill: proratedCostPerSeat,
    },
    // lastSubscriptionData: {},
  };

  const chargeUserIfSeatsAreNotAvailableWhileSubscriptionRunning = {
    currentPlan: {
      plan: selectedPlan === "Monthly Subscription" ? "Monthly Subscription" : "Yearly Subscription",
      status:
        subscriptionData?.userTrial?.isTrialCancelled === false && subscriptionData?.userTrial?.isTrialExpired === false
          ? "Trial is running"
          : "Running",
      seats: subscriptionData?.currentPlan?.seats + 1,
      price: selectedPlan === "Monthly Subscription" ? 79 : 790,
    },
    seats: {
      used: subscriptionData?.seats?.used + 1,
      available: 0,
      total: subscriptionData?.seats?.total + 1,
    },
    subscriptionInfo: {
      isSubscribed: true,
      isSubscribedExpired: false,
      isUnsubscribed: false,
      isSubscribeResumed: false,
    },
    nextUpdatedPlan: selectedPlan === "Monthly Subscription" ? "Monthly Subscription" : "Yearly Subscription",
    isPlanChanged: isPlanChanged,
    lastAdditionalseats: {
      seats: billingSeats < 0 ? billingTotalSeats : billingSeats,
      seatsBill: totalCost,
      lastPerSeatBill: proratedCostPerSeat,
    },
    // lastSubscriptionData: {},
  };

  const chargeUserIfSeatsAreNotAvailableWhileUnSubscription = {
    currentPlan: {
      plan: subscriptionData?.currentPlan?.plan,
      status: subscriptionData?.currentPlan?.status,
      seats: subscriptionData?.currentPlan?.seats + 1,
      price: selectedPlan === "Monthly Subscription" ? 79 : 790,
    },
    seats: {
      used: subscriptionData?.seats?.used + 1,
      available: 0,
      total: subscriptionData?.seats?.total + 1,
    },
    subscriptionInfo: {
      isSubscribed: true,
      isSubscribedExpired: false,
      isUnsubscribed: true,
      isSubscribeResumed: false,
    },
    nextUpdatedPlan: selectedPlan === "Monthly Subscription" ? "Monthly Subscription" : "Yearly Subscription",
    isPlanChanged: isPlanChanged,
    lastAdditionalseats: {
      seats: billingSeats < 0 ? billingTotalSeats : billingSeats,
      seatsBill: totalCost,
      lastPerSeatBill: proratedCostPerSeat,
    },
    // lastSubscriptionData: {},
  };

  const updateSubscriptionAndSubscriptionIsExpired = {
    currentPlan: {
      plan: selectedPlan === "Monthly Subscription" ? "Monthly Subscription" : "Yearly Subscription",
      status: "Expired",
      seats: billingTotalSeats,
      price: selectedPlan === "Monthly Subscription" ? 79 : 790,
    },
    seats: {
      used: subscriptionData?.seats?.used,
      available: subscriptionData?.seats?.available + billingSeats,
      total: billingTotalSeats,
    },
    subscriptionInfo: {
      isSubscribed: true,
      isSubscribedExpired: true,
      isUnsubscribed: true,
      isSubscribeResumed: false,
    },
    nextUpdatedPlan: selectedPlan === "Monthly Subscription" ? "Monthly Subscription" : "Yearly Subscription",
    isPlanChanged: isPlanChanged,
    lastAdditionalseats: {
      seats: billingSeats < 0 ? billingTotalSeats : billingSeats,
      seatsBill: totalCost,
      lastPerSeatBill: proratedCostPerSeat,
    },
    // lastSubscriptionData: {},
  };

  const updateSubscriptionThatSubscriptionIsAlreadyExpired = {
    currentPlan: {
      plan: selectedPlan === "Monthly Subscription" ? "Monthly Subscription" : "Yearly Subscription",
      status: "Running",
      seats: subscriptionData?.currentPlan?.seats,
      price: selectedPlan === "Monthly Subscription" ? 79 : 790,
    },
    seats: {
      used: subscriptionData?.seats?.used,
      available: subscriptionData?.seats?.available,
      total: subscriptionData?.seats?.total,
    },
    subscriptionInfo: {
      isSubscribed: true,
      isSubscribedExpired: false,
      isUnsubscribed: false,
      isSubscribeResumed: false,
    },
    nextUpdatedPlan: selectedPlan === "Monthly Subscription" ? "Monthly Subscription" : "Yearly Subscription",
    isPlanChanged: isPlanChanged,
    lastAdditionalseats: {
      seats: billingSeats < 0 ? billingTotalSeats : billingSeats,
      seatsBill: totalCost,
      lastPerSeatBill: proratedCostPerSeat,
    },
    // lastSubscriptionData: {},
  };

  const updateSubscriptionDataForToAddLastSubscriptionData = {
    lastSubscriptionData: {
      plan: localStorage.getItem("selectedPlan") ? localStorage.getItem("selectedPlan") : selectedPlan,
      seats: (() => {
        if (subscriptionData?.currentPlan?.status === "Unsubscribed, subscription is running" && billingSeats < 0) {
          return billingTotalSeats;
        }
        if (subscriptionData?.currentPlan?.status === "Running" && billingSeats < 0) {
          return billingTotalSeats;
        }
        if (selectedPlan !== subscriptionData?.currentPlan?.plan) {
          return billingTotalSeats;
        }
        if (subscriptionData?.currentPlan?.status === "Expired" && billingSeats === undefined && billingTotalSeats === undefined) {
          return subscriptionData?.seats?.total;
        }
        if (subscriptionData?.currentPlan?.status === "Expired" && billingSeats > 0) {
          return billingSeats;
        }
        if (subscriptionData?.currentPlan?.status === "Expired" && billingSeats < 0) {
          return billingTotalSeats;
        }
        if (subscriptionData?.currentPlan?.status === "Expired" && !billingSeats && selectedPlan !== subscriptionData?.currentPlan?.plan) {
          return subscriptionData?.seats?.total;
        }
        if (subscriptionData?.currentPlan.status === "Paused") {
          return billingTotalSeats ? billingTotalSeats : subscriptionData?.seats?.used;
        }
        if (subscriptionData?.currentPlan?.plan === "Free" && billingSeats === undefined) {
          return subscriptionData?.seats?.used;
        }
        if (billingSeats > 0) {
          return billingSeats;
        }
        if (billingSeats < 0 && subscriptionData?.currentPlan.status !== "Paused") {
          return billingSeats;
        }
      })(),
      seatsPrice: usedSeatCalculation,
      taxes: Number(taxOnUsedSeatCalculation?.toFixed(2)),
      total: Number(totalAmount.toFixed(2)),
      todaysTotal: Number(totalCost?.toFixed(2)),
      proratedCostPerSeat: proratedCostPerSeat,
    },
  };

  if (show && !planUpdatedUI && billingDetails.plan) {
    const updateSubscription = async () => {
      if (
        subscriptionData?.currentPlan?.status === "Free" ||
        subscriptionData?.currentPlan?.plan === "Trial" ||
        subscriptionData?.currentPlan?.plan === "Trial is running" ||
        subscriptionData?.currentPlan?.status === "Paused" ||
        subscriptionData?.currentPlan?.status === "Running" ||
        subscriptionData?.currentPlan?.status === "Expired" ||
        subscriptionData?.currentPlan?.status === "Unsubscribed, subscription is running"
      ) {
        await updateSubscriptionData(subscriptionData?.id, updateSubscriptionDataForToAddLastSubscriptionData, "Update");
      }
    };
    updateSubscription();
  }

  const handlePayment = async (value) => {
    if (!companyPaymentCard && !getCardLoading) {
      setAddPaymentModal(true);
    } else if (loading) {
      setShowProcessPaymentLoader(true);
    } else if (
      subscriptionData?.currentPlan?.status === "Unsubscribed, subscription is running" &&
      subscriptionData?.subscriptionInfo?.isSubscribed &&
      subscriptionData?.subscriptionInfo?.isUnsubscribed &&
      openBillingDetailsIfSeatsIsZero === undefined &&
      billingTotalSeats < subscriptionData?.currentPlan?.seats
    ) {
      console.log("Run 1");
      await updateSubscriptionData(subscriptionData?.id, updatingSubscriptionDataWhileUnsubscribe, value);
      const response = await getInvoices();
      setInvoices(response?.value?.data);
      setShowProcessPaymentLoader(false);
      setShowMonthlyDecrementSeatsModal(true);
      getSubscriptionByCompanyId(user?.company?.id);
    } else if (
      subscriptionData?.currentPlan?.status === "Unsubscribed, subscription is running" &&
      subscriptionData?.subscriptionInfo?.isSubscribed &&
      subscriptionData?.subscriptionInfo?.isUnsubscribed &&
      openBillingDetailsIfSeatsIsZero === undefined
    ) {
      console.log("Run 2");
      await updateSubscriptionData(subscriptionData?.id, updatingSubscriptionDataWhileUnsubscribe, value);
      const response = await getInvoices();
      setInvoices(response?.value?.data);
      setShowProcessPaymentLoader(false);
      setPlanUpdateUI(true);
      getSubscriptionByCompanyId(user?.company?.id);
    } else if (
      billingSeats > 0 &&
      subscriptionData?.currentPlan?.status !== "Paused" &&
      openBillingDetailsIfSeatsIsZero !== true &&
      !subscriptionData?.subscriptionInfo?.isSubscribedExpired
    ) {
      console.log("Run 3");
      await updateSubscriptionData(subscriptionData?.id, updatingSubscriptionData, value);
      const response = await getInvoices();
      setInvoices(response?.value?.data);
      setShowProcessPaymentLoader(false);
      setPlanUpdateUI(true);
      getSubscriptionByCompanyId(user?.company?.id);
    } else if (
      billingSeats < 0 &&
      subscriptionData?.currentPlan?.status !== "Paused" &&
      openBillingDetailsIfSeatsIsZero !== true &&
      !subscriptionData?.subscriptionInfo?.isSubscribedExpired
    ) {
      console.log("Run 4");
      await updateSubscriptionData(subscriptionData?.id, updatingSubscriptionData, value);
      const response = await getInvoices();
      setInvoices(response?.value?.data);
      setShowProcessPaymentLoader(false);
      setPlanUpdateUI(false);
      getSubscriptionByCompanyId(user?.company?.id);
      setShowMonthlyDecrementSeatsModal(true);
    } else if (
      subscriptionData?.currentPlan?.plan === "Free" &&
      subscriptionData?.currentPlan?.status === "Free" &&
      subscriptionData?.subscriptionInfo?.isSubscribedExpired
    ) {
      console.log("Run 5");
      setShowProcessPaymentLoader(false);
      await updateSubscriptionData(subscriptionData?.id, subscriptionDataThatSubscribeAfterBackToFreePlanAndAgainSubscribe, value);
      const response = await getInvoices();
      setInvoices(response?.value?.data);
      setPlanUpdateUI(true);
    } else if (
      billingSeats === 0 &&
      subscriptionData?.currentPlan?.status !== "Paused" &&
      subscriptionData?.currentPlan?.status !== "Expired" &&
      subscriptionData?.currentPlan?.status !== "Free"
    ) {
      console.log("Run 6");
      setShowProcessPaymentLoader(false);
      await updateSubscriptionData(subscriptionData?.id, subscriptionDataThatOnlyChangeFromMonthlyToYearly, value);
      const response = await getInvoices();
      setInvoices(response?.value?.data);
      setPlanUpdateUI(true);
    } else if (subscriptionData?.currentPlan?.status === "Paused" && subscriptionData?.subscriptionInfo?.isSubscribed) {
      setShowCardDialog(true);
      if (companyPaymentCard) {
        setEdit(true);
        setIsPaymentCardAdded(true);
      }
      const isPaymentCardUpdate = localStorage.getItem("isPaymentCardUpdate");
      if (isPaymentCardAdded && isPaymentCardUpdate) {
        console.log("Run 7");
        setShowCardDialog(false);
        await updateSubscriptionData(subscriptionData?.id, updatingSubscriptionData, value);
        localStorage.removeItem("isPaymentCardUpdate");
        const response = await getInvoices();
        setInvoices(response?.value?.data);
        setPlanUpdateUI(true);
      }
    } else if (
      !id &&
      subscriptionData?.seats?.available === 0 &&
      subscriptionData?.currentPlan?.status !== "Paused" &&
      subscriptionData?.currentPlan?.status === "Running" &&
      subscriptionData?.subscriptionInfo?.isSubscribed &&
      openBillingDetailsIfSeatsIsZero &&
      userData?.isUserSeated
    ) {
      createUser({
        ...userData,
        isInvited: true,
        roleSetting: accessRightsValues,
        managerPortal: false,
        inspectorPortal: false,
        isUserSeated: seatedType,
        roleName: accessRightsValues?.role?.originalName,
      });
      console.log("Run 8");
      await updateSubscriptionData(subscriptionData?.id, chargeUserIfSeatsAreNotAvailableWhileSubscriptionRunning, value);
      const response = await getInvoices();
      setInvoices(response?.value?.data);
      setPlanUpdateUI(true);
      getAllUsers();
    } else if (
      id &&
      subscriptionData?.seats?.available === 0 &&
      subscriptionData?.currentPlan?.status !== "Paused" &&
      subscriptionData?.currentPlan?.status === "Running" &&
      subscriptionData?.subscriptionInfo?.isSubscribed &&
      openBillingDetailsIfSeatsIsZero &&
      userData?.isUserSeated
    ) {
      updateUserById({
        ...data,
        roleSetting: accessRightsValues,
        managerPortal: false,
        inspectorPortal: false,
        userInfoChange: userForEdit.active !== data.active,
        isUserSeated: seatedType,
        roleName: accessRightsValues?.role?.originalName,
      });
      console.log("Run 9");
      await updateSubscriptionData(subscriptionData?.id, chargeUserIfSeatsAreNotAvailableWhileSubscriptionRunning, value);
      const response = await getInvoices();
      setInvoices(response?.value?.data);
      setPlanUpdateUI(true);
      getAllUsers();
    } else if (
      !id &&
      subscriptionData?.seats?.available === 0 &&
      subscriptionData?.currentPlan?.status !== "Paused" &&
      subscriptionData?.currentPlan?.status === "Unsubscribed, subscription is running" &&
      subscriptionData?.subscriptionInfo?.isSubscribed &&
      openBillingDetailsIfSeatsIsZero &&
      userData?.isUserSeated
    ) {
      createUser({
        ...userData,
        isInvited: true,
        roleSetting: accessRightsValues,
        managerPortal: false,
        inspectorPortal: false,
        isUserSeated: seatedType,
        roleName: accessRightsValues?.role?.originalName,
      });
      console.log("Run 10");
      await updateSubscriptionData(subscriptionData?.id, chargeUserIfSeatsAreNotAvailableWhileUnSubscription, value);
      const response = await getInvoices();
      setInvoices(response?.value?.data);
      setPlanUpdateUI(true);
      getAllUsers();
    } else if (
      id &&
      subscriptionData?.seats?.available === 0 &&
      subscriptionData?.currentPlan?.status !== "Paused" &&
      subscriptionData?.currentPlan?.status === "Unsubscribed, subscription is running" &&
      subscriptionData?.subscriptionInfo?.isSubscribed &&
      openBillingDetailsIfSeatsIsZero &&
      userData?.isUserSeated
    ) {
      updateUserById({
        ...data,
        roleSetting: accessRightsValues,
        managerPortal: false,
        inspectorPortal: false,
        userInfoChange: userForEdit.active !== data.active,
        isUserSeated: seatedType,
        roleName: accessRightsValues?.role?.originalName,
      });
      console.log("Run 11");
      await updateSubscriptionData(subscriptionData?.id, chargeUserIfSeatsAreNotAvailableWhileUnSubscription, value);
      const response = await getInvoices();
      setInvoices(response?.value?.data);
      setPlanUpdateUI(true);
      getAllUsers();
    } else if (
      subscriptionData?.currentPlan?.status === "Expired" &&
      subscriptionData?.subscriptionInfo?.isSubscribed &&
      subscriptionData?.subscriptionInfo?.isUnsubscribed &&
      subscriptionData?.subscriptionInfo?.isSubscribedExpired &&
      subscriptionData?.currentPlan?.status !== "Paused"
    ) {
      // if update the plan i change my plan while subscription is expired
      if (
        subscriptionData?.currentPlan?.status === "Expired" &&
        subscriptionData?.subscriptionInfo?.isSubscribed &&
        subscriptionData?.subscriptionInfo?.isUnsubscribed &&
        subscriptionData?.subscriptionInfo?.isSubscribedExpired &&
        subscriptionData?.currentPlan?.status !== "Paused" &&
        billingSeats === 0 &&
        isAdditionalSeatsAdd === false
      ) {
        console.log("Run 12");
        await updateSubscriptionData(subscriptionData?.id, subscriptionDataThatOnlyChangeFromMonthlyToYearly, value);
        const response = await getInvoices();
        setInvoices(response?.value?.data);
        setUpdateSeatsModal(true);
        getSubscriptionByCompanyId(user?.company?.id);
        return;
      }
      // if update the plan i mean increment or decrement the seats while subscription is expired
      if (
        (subscriptionData?.currentPlan?.status === "Expired" &&
          subscriptionData?.subscriptionInfo?.isSubscribed &&
          subscriptionData?.subscriptionInfo?.isUnsubscribed &&
          subscriptionData?.subscriptionInfo?.isSubscribedExpired &&
          subscriptionData?.currentPlan?.status !== "Paused" &&
          isAdditionalSeatsAdd) ||
        billingSeats > 0 ||
        billingSeats < 0
      ) {
        console.log("Run 13");
        await updateSubscriptionData(subscriptionData?.id, updateSubscriptionAndSubscriptionIsExpired, value);
        await getSubscriptionByCompanyId(user?.company?.id);
        const response = await getInvoices();
        setInvoices(response?.value?.data);
        await setUpdateSeatsModal(true);
      }
      // if again doing subscribe while subscription is has been expired
      else {
        console.log("Run 14");
        await updateSubscriptionData(subscriptionData?.id, updateSubscriptionThatSubscriptionIsAlreadyExpired, value);
        const response = await getInvoices();
        setInvoices(response?.value?.data);
        setPlanUpdateUI(true);
        getSubscriptionByCompanyId(user?.company?.id);
      }
    } else if (
      subscriptionData?.userTrial?.isTrialCancelled &&
      subscriptionData?.currentPlan?.plan === "Free" &&
      subscriptionData?.currentPlan?.status === "Free" &&
      !subscriptionData?.subscriptionInfo?.isSubscribed
    ) {
      console.log("Run 15");
      await updateSubscriptionData(subscriptionData?.id, subscriptionDataToSubscribeAfterCancelTrial, value);
      const response = await getAllInvoices();
      setInvoices(response?.value?.data);
      setPlanUpdateUI(true);
    } else {
      console.log("Run 16");
      setShowProcessPaymentLoader(false);
      await updateSubscriptionData(subscriptionData?.id, subscriptionDataToSubscribe, value);
      const response = await getInvoices();
      setInvoices(response?.value?.data);
      setPlanUpdateUI(true);
    }
    await getSubscriptionByCompanyId(user?.company?.id);
    localStorage.removeItem("selectedPlan");
  };

  const handleShowPDF = async () => {
    setShowInvoice(true);
    // setPrintRxLoading(true);
    setTimeout(() => {
      const input = document.getElementById("divToPrint");
      if (input) {
        const pdfWidth = input.clientWidth;
        const pdfHeight = input.clientHeight;

        const dpi = 300;

        html2canvas(input, {
          scale: dpi / 90,
          width: pdfWidth,
          height: pdfHeight,
          // useCORS:true,
          logging: true,
        }).then((canvas) => {
          setShowInvoice(false);
          // setPrintRxLoading(false);
          const imgData = canvas.toDataURL("image/png", 1.0);

          const pdf = new jsPDF({
            unit: "px",
            format: [pdfWidth, pdfHeight],
          });

          pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);

          const blobURL = pdf.output("bloburl");

          window.open(blobURL, "_blank");
        });
      }
    }, 1000);
  };

  const waitForElement = (selector, timeout) => {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        const element = document.querySelector(selector);
        if (element) {
          clearInterval(interval);
          resolve(element);
        }
      }, 100);
      setTimeout(() => {
        clearInterval(interval);
        resolve(null);
      }, timeout);
    });
  };

  const handleDownloadPDF = async () => {
    setDownloadInvoicePdf(true);

    try {
      await new Promise((resolve) => {
        if (document.readyState === "complete") {
          resolve();
        } else {
          window.addEventListener("load", resolve);
        }
      });

      const input = await waitForElement("#divToPrint", 4000);

      const pdfWidth = input.clientWidth;
      const pdfHeight = input.clientHeight;

      const canvas = await html2canvas(input, {
        scale: 2,
        width: pdfWidth,
        height: pdfHeight,
      });

      const imgWidth = 595;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        unit: "px",
        format: [pdfWidth, pdfHeight],
      });
      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      pdf.save(`${invoiceData?.invoiceDummyId}.pdf`);

      toast.success("PDF Downloaded!");
    } catch (error) {
      toast.error(`Error: ${error.message}`);
    } finally {
      setDownloadInvoicePdf(false);
    }
  };

  const monthlyDecrementSeatsModalData = {
    title: <I18n lng="Subscriptions.PlanUpdatedSuccessfully" />,
    text_one: (
      <I18n lng="Subscriptions.YourPlanHasBeenUpdatedSuccessfullyTheRevisedInvoiceReflectingTheAdjustedTotalWillBeGeneratedUpcomingBillingCycle" />
    ),
    closeButtonText: <I18n lng="global.Close" />,
  };

  const handleClickViewInvoice = async () => {
    const response = await getAllInvoices();
    setInvoices(response?.value?.data);
    handleShowPDF();
  };

  return (
    <>
      <DynamicModal
        title={showProcessPaymentLoader ? <I18n lng="Subscriptions.ProcessPayment" /> : <I18n lng="global.UpdatePlan" />}
        show={show}
        secondaryAction={() => {
          onHide();
          getSubscriptionByCompanyId(user?.company?.id);
          if (setSelectedPlan) {
            setSelectedPlan("");
          }
          if (
            (id &&
              subscriptionData?.seats?.available === 0 &&
              subscriptionData?.currentPlan?.status !== "Paused" &&
              subscriptionData?.currentPlan?.status === "Running" &&
              subscriptionData?.subscriptionInfo?.isSubscribed &&
              openBillingDetailsIfSeatsIsZero &&
              userData?.isUserSeated) ||
            (!id &&
              subscriptionData?.seats?.available === 0 &&
              subscriptionData?.currentPlan?.status !== "Paused" &&
              subscriptionData?.currentPlan?.status === "Running" &&
              subscriptionData?.subscriptionInfo?.isSubscribed &&
              openBillingDetailsIfSeatsIsZero &&
              userData?.isUserSeated)
          ) {
            getAllUsers();
            secondaryAction();
          }
          if (
            (!id &&
              subscriptionData?.seats?.available === 0 &&
              subscriptionData?.currentPlan?.status !== "Paused" &&
              subscriptionData?.currentPlan?.status === "Unsubscribed, subscription is running" &&
              subscriptionData?.subscriptionInfo?.isSubscribed &&
              openBillingDetailsIfSeatsIsZero &&
              userData?.isUserSeated) ||
            (id &&
              subscriptionData?.seats?.available === 0 &&
              subscriptionData?.currentPlan?.status !== "Paused" &&
              subscriptionData?.currentPlan?.status === "Unsubscribed, subscription is running" &&
              subscriptionData?.subscriptionInfo?.isSubscribed &&
              openBillingDetailsIfSeatsIsZero &&
              userData?.isUserSeated)
          ) {
            getAllUsers();
            secondaryAction();
          }
          if (isAdditionalSeatsAdd !== true) {
            getSession();
          }
        }}
        modalContentClass="bg-white"
        customClasses={`modal-drawer custom-roles-drawer`}
      >
        {showProcessPaymentLoader ? (
          <div className="d-flex justify-content-center align-items-center" style={{ height: "100%" }}>
            <img src={processPaymentImage} alt="processPaymentImage" />
          </div>
        ) : planUpdatedUI ? (
          <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: "100%" }}>
            <div>
              <p style={{ fontWeight: "600" }}>
                {subscriptionData?.subscriptionInfo?.isSubscribed ? (
                  <span>
                    <I18n lng="Subscriptions.PlanUpdatedSuccessfully" />
                  </span>
                ) : (
                  <span>
                    <I18n lng="Subscriptions.ThankYouForYourSubscription" />
                  </span>
                )}
              </p>
            </div>
            {subscriptionData?.userTrial?.isTrialExpired === false ||
            (subscriptionData?.userTrial?.isTrialCancelled === false && !billingTotalSeats < subscriptionData.currentPlan.plan) ? (
              <p className="text-center mt-5">
                <I18n lng="Subscriptions.YourBillingCycleWilStartAfter" /> {moment(subscriptionData?.trialEndedAt).format("DD MMM YYYY")}
              </p>
            ) : (
              <div
                style={{
                  width: "307px",
                }}
                className="d-flex flex-column justify-content-center align-items-center"
              >
                <p className="text-center text-justify">
                  <I18n lng="Subscriptions.YourPaymentHasBeenProcessedSuccessfullyClickBelowTheLinks" />
                </p>
                <p>
                  <span
                    onClick={handleClickViewInvoice}
                    style={{
                      color: "#2D9CDB",
                      cursor: "pointer",
                    }}
                  >
                    <I18n lng="Subscriptions.ViewYourBills" /> now
                  </span>
                  <span className="mr-1 ml-1">
                    <I18n lng="Subscriptions.or" />
                  </span>
                  <span
                    onClick={handleDownloadPDF}
                    style={{
                      color: "#2D9CDB",
                      cursor: "pointer",
                    }}
                  >
                    <I18n lng="Subscriptions.DownloadIt" />
                  </span>
                </p>
              </div>
            )}
          </div>
        ) : (
          <div className="d-flex flex-column h-100">
            <div className="w-100">
              <div className="my-2" style={{ color: "#828282", fontWeight: "400" }}>
                <I18n lng="Subscriptions.PricingPlanDetails" />
              </div>
              <hr
                style={{
                  width: "110%",
                  marginRight: "-10px",
                  marginLeft: "-10px",
                }}
              />
            </div>
            <div className="my-2">
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-column">
                  <span
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    {localStorage.getItem("selectedPlan") ? (
                      localStorage.getItem("selectedPlan")
                    ) : selectedPlan === "Monthly Subscription" ? (
                      <I18n lng="Subscriptions.MonthlySubscription" />
                    ) : selectedPlan === "Yearly Subscription" ? (
                      <I18n lng="Subscriptions.YearlySubscription" />
                    ) : (
                      parsedBillingDetails?.plan || null
                    )}
                  </span>
                  <span
                    style={{
                      color: "#828282",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    {Language === "ARABIC" ? (
                      <>
                        <span>
                          {(() => {
                            if (subscriptionData?.currentPlan?.status === "Unsubscribed, subscription is running" && billingSeats < 0) {
                              return billingTotalSeats;
                            }
                            if (
                              subscriptionData?.currentPlan?.plan === "Yearly Subscription" &&
                              billingSeats > 0 &&
                              selectedPlan === subscriptionData?.currentPlan?.plan
                            ) {
                              return billingSeats;
                            }
                            if (selectedPlan !== subscriptionData?.currentPlan?.plan) {
                              return billingTotalSeats;
                            }
                            if (
                              parsedBillingDetails?.plan === "Yearly Subscription" &&
                              subscriptionData?.currentPlan?.status !== "Expired" &&
                              billingSeats < 0
                            ) {
                              return billingTotalSeats;
                            }
                            if (
                              parsedBillingDetails?.plan === "Yearly Subscription" &&
                              subscriptionData?.currentPlan?.status === "Expired" &&
                              billingSeats > 0
                            ) {
                              return billingSeats;
                            }
                            if (parsedBillingDetails?.seats) {
                              return parsedBillingDetails?.seats;
                            }

                            if (subscriptionData?.currentPlan?.plan === "Trial") {
                              return subscriptionData?.seats?.total;
                            }

                            if (
                              billingTotalSeats &&
                              subscriptionData?.userTrial?.isTrialCancelled &&
                              subscriptionData?.currentPlan?.plan === "Free"
                            ) {
                              return billingTotalSeats;
                            }
                            if (
                              subscriptionData?.userTrial?.isTrialCancelled &&
                              subscriptionData?.currentPlan?.plan === "Free" &&
                              isAdditionalSeatsAdd === undefined
                            ) {
                              return subscriptionData?.seats?.used;
                            }
                            if (subscriptionData?.currentPlan?.plan !== selectedPlan && subscriptionData?.subscriptionInfo?.isSubscribed === true) {
                              return billingTotalSeats;
                            }

                            const isValidSubscription =
                              (subscriptionData?.currentPlan?.status === "Running" ||
                                subscriptionData?.currentPlan?.status === "Unsubscribed, subscription is running") &&
                              (subscriptionData?.currentPlan?.plan === "Monthly Subscription" ||
                                subscriptionData?.currentPlan?.plan === "Yearly Subscription") &&
                              subscriptionData?.currentPlan?.plan === (selectedPlan ? selectedPlan : parsedBillingDetails?.plan);

                            if (isValidSubscription && billingSeats > 0) {
                              return billingSeats;
                            } else if (isValidSubscription && billingSeats <= 0) {
                              return billingTotalSeats;
                            }
                            if (subscriptionData?.currentPlan?.status === "Expired" || subscriptionData?.currentPlan?.status === "Paused") {
                              if (billingSeats && billingTotalSeats) {
                                return billingTotalSeats;
                              } else {
                                return subscriptionData?.seats?.total;
                              }
                            }
                          })()}
                        </span>
                        <span className="mx-1">
                          <I18n lng="Subscriptions.Seats" />
                        </span>
                        <span className="mx-1">×</span>
                        <span> {(selectedPlan ? selectedPlan : parsedBillingDetails?.plan) === "Monthly Subscription" ? 79 : 790}</span>
                        <span className="mx-1">
                          <I18n lng="Subscriptions.SAR" />
                        </span>
                        <span>×</span>
                        <span className="mx-1">
                          {(selectedPlan ? selectedPlan : parsedBillingDetails?.plan) === "Monthly Subscription" ? (
                            <I18n lng="Subscriptions.Month" />
                          ) : (
                            <I18n lng="Subscriptions.Year" />
                          )}
                        </span>
                        <span className="mx-1">
                          <I18n lng="Subscriptions.one" />
                        </span>
                      </>
                    ) : (
                      <>
                        <span>
                          {() => {}}
                          {(() => {
                            if (subscriptionData?.currentPlan?.plan === "Free" && billingSeats === undefined) {
                              return subscriptionData?.seats?.used;
                            }
                            if (subscriptionData?.currentPlan?.plan === "Free" && billingSeats !== undefined) {
                              return billingSeats;
                            }

                            if (subscriptionData?.currentPlan?.status === "Unsubscribed, subscription is running" && billingSeats < 0) {
                              return billingTotalSeats;
                            }
                            if (
                              subscriptionData?.currentPlan?.plan === "Yearly Subscription" &&
                              billingSeats > 0 &&
                              selectedPlan === subscriptionData?.currentPlan?.plan
                            ) {
                              return billingSeats;
                            }
                            if (selectedPlan !== subscriptionData?.currentPlan?.plan) {
                              return billingTotalSeats;
                            }
                            if (
                              parsedBillingDetails?.plan === "Yearly Subscription" &&
                              subscriptionData?.currentPlan?.status !== "Expired" &&
                              billingSeats < 0
                            ) {
                              return billingTotalSeats;
                            }
                            if (
                              parsedBillingDetails?.plan === "Yearly Subscription" &&
                              subscriptionData?.currentPlan?.status === "Expired" &&
                              billingSeats > 0
                            ) {
                              return billingSeats;
                            }
                            if (parsedBillingDetails?.seats) {
                              return parsedBillingDetails?.seats;
                            }

                            if (subscriptionData?.currentPlan?.plan === "Trial") {
                              return subscriptionData?.seats?.total;
                            }

                            if (
                              billingTotalSeats &&
                              subscriptionData?.userTrial?.isTrialCancelled &&
                              subscriptionData?.currentPlan?.plan === "Free"
                            ) {
                              return billingTotalSeats;
                            }
                            if (
                              subscriptionData?.userTrial?.isTrialCancelled &&
                              subscriptionData?.currentPlan?.plan === "Free" &&
                              isAdditionalSeatsAdd === undefined
                            ) {
                              return subscriptionData?.seats?.used;
                            }
                            if (subscriptionData?.currentPlan?.plan !== selectedPlan && subscriptionData?.subscriptionInfo?.isSubscribed === true) {
                              return billingTotalSeats;
                            }

                            const isValidSubscription =
                              (subscriptionData?.currentPlan?.status === "Running" ||
                                subscriptionData?.currentPlan?.status === "Unsubscribed, subscription is running") &&
                              (subscriptionData?.currentPlan?.plan === "Monthly Subscription" ||
                                subscriptionData?.currentPlan?.plan === "Yearly Subscription") &&
                              subscriptionData?.currentPlan?.plan === (selectedPlan ? selectedPlan : parsedBillingDetails?.plan);

                            if (isValidSubscription && billingSeats > 0) {
                              return billingSeats;
                            } else if (isValidSubscription && billingSeats <= 0) {
                              return billingTotalSeats;
                            }
                            if (subscriptionData?.currentPlan?.status === "Expired" || subscriptionData?.currentPlan?.status === "Paused") {
                              if (billingSeats && billingTotalSeats) {
                                return billingTotalSeats;
                              } else {
                                return subscriptionData?.seats?.total;
                              }
                            }
                          })()}
                        </span>
                        <span className="mx-1">
                          <I18n lng="Subscriptions.Seats" />
                        </span>
                        <span>×</span>
                        <span className="mx-1">
                          <I18n lng="Subscriptions.SAR" />
                        </span>
                        <span>{(selectedPlan ? selectedPlan : parsedBillingDetails?.plan) === "Monthly Subscription" ? 79 : 790}</span>
                        <span className="mx-1">×</span>
                        <span className="mx-1">
                          <I18n lng="Subscriptions.one" />
                        </span>
                        <span className="mx-1">
                          {(selectedPlan ? selectedPlan : parsedBillingDetails?.plan) === "Monthly Subscription" ? (
                            <I18n lng="Subscriptions.Month" />
                          ) : (
                            <I18n lng="Subscriptions.Year" />
                          )}
                        </span>
                      </>
                    )}
                  </span>
                </div>
                <div>
                  <span className="mr-4" style={{ fontWeight: "500", fontSize: "14px" }}>
                    {parsedBillingDetails?.seatsPrice ? parsedBillingDetails?.seatsPrice : usedSeatCalculation?.toFixed(2)}
                  </span>
                  <span style={{ fontWeight: "500", fontSize: "14px" }} className="mr-2">
                    <I18n lng="Subscriptions.SAR" />
                  </span>
                </div>
              </div>
              <div className="d-flex justify-content-between my-4">
                <div className="d-flex flex-column">
                  <span
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    <I18n lng="Subscriptions.Texes" />
                  </span>
                  <span
                    style={{
                      color: "#828282",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    <I18n lng="Subscriptions.Vat" /> (15%)
                  </span>
                </div>
                <div>
                  <span className="mr-4" style={{ fontWeight: "500", fontSize: "14px" }}>
                    {parsedBillingDetails?.taxes ? parsedBillingDetails?.taxes : taxOnUsedSeatCalculation?.toFixed(2)}
                  </span>
                  <span style={{ fontWeight: "500", fontSize: "14px" }} className="mr-2">
                    <I18n lng="Subscriptions.SAR" />
                  </span>
                </div>
              </div>
              {!subscriptionData?.subscriptionInfo?.isSubscribed &&
                (subscriptionData?.currentPlan?.status !== "Running" ||
                  subscriptionData?.currentPlan?.status !== "Unsubscribed, subscription is running") && (
                  <hr
                    style={{
                      width: "110%",
                      marginRight: "-10px",
                      marginLeft: "-10px",
                      marginTop: "-10px",
                    }}
                  />
                )}
            </div>
            <div
              className="d-flex justify-content-between"
              style={{
                boxShadow:
                  (subscriptionData?.currentPlan?.status !== "Running" ||
                    subscriptionData?.currentPlan?.status !== "Unsubscribed, subscription is running") &&
                  "rgba(50, 50, 93, 0.25) 0px 4px 4px -2px, rgba(0, 0, 0, 0.3) 0px 34px 0px -62px",
                width: "107%",
                marginLeft: "-14px",
                marginRight: "-10px",
                marginTop: "-7px",
                borderBottom:
                  (subscriptionData?.currentPlan?.status === "Running" ||
                    subscriptionData?.currentPlan?.status === "Unsubscribed, subscription is running") &&
                  "1px solid #F2F2F2",
              }}
            >
              <p className="ml-3 mr-3">
                <I18n lng="Subscriptions.TotalForUpatePlanPricing" />
              </p>
              <p className="mr-2 ml-3">
                <span className="mr-3">
                  {parsedBillingDetails?.total ? parsedBillingDetails?.total : totalAmount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </span>
                <span className="mr-2">
                  <I18n lng="Subscriptions.SAR" />
                </span>
              </p>
            </div>
            {(fromUsers === true && subscriptionData?.subscriptionStartedAt) ||
            (billingTotalSeats > subscriptionData?.currentPlan?.seats &&
              subscriptionData?.currentPlan?.status === "Running" &&
              subscriptionData?.subscriptionInfo?.isSubscribed === true &&
              subscriptionData?.currentPlan?.plan === selectedPlan) ||
            (subscriptionData?.currentPlan?.status === "Unsubscribed, subscription is running" &&
              billingTotalSeats >= subscriptionData?.currentPlan?.seats) ||
            subscriptionData?.currentPlan?.plan === "Paused" ? (
              <div
                className="d-flex justify-content-between mt-4"
                style={{
                  boxShadow: "rgba(50, 50, 93, 0.25) 0px 4px 4px -2px, rgba(0, 0, 0, 0.3) 0px 34px 0px -62px",
                  width: "107%",
                  marginLeft: "-14px",
                  marginRight: "-10px",
                  marginTop: "-7px",
                }}
              >
                <p className="ml-3 mr-3" style={{ fontWeight: "600", fontSize: "14px" }}>
                  <I18n lng="Subscriptions.TodaysTotal" />
                </p>
                <p className="mr-2 ml-3">
                  <span className="mr-3" style={{ fontWeight: "600", fontSize: "14px" }}>
                    {parsedBillingDetails?.todaysTotal ? parsedBillingDetails?.todaysTotal : totalCost?.toFixed(2)}
                  </span>
                  <span className="mr-2" style={{ fontWeight: "600", fontSize: "14px" }}>
                    <I18n lng="Subscriptions.SAR" />
                  </span>
                </p>
              </div>
            ) : null}
            <div className="payment-method-section py-3">
              <p className="payment-method-heading">
                <span className="mx-1">
                  <I18n lng="Subscriptions.PaymentMethod" />
                </span>
              </p>
              <div>
                {getCardLoading ? (
                  <p className="d-flex justify-content-center align-items-center">
                    <I18n lng="global.loading" />
                  </p>
                ) : (
                  <>
                    {companyPaymentCard ? (
                      <ul
                        className={`subscription-cost font-size-14 mb-2  ${TEXT_DIRECTION() === "rtl" ? "pr-0" : "pl-0"}`}
                        style={{ listStyle: "none" }}
                      >
                        <li className="d-flex w-100 mx-1">
                          <label className="text-muted">{companyPaymentCard?.brand}</label>
                          <div className="d-flex justify-content-between w-100">
                            <span>************{companyPaymentCard?.last_four}</span>
                            <p style={{ marginRight: "34px" }}>
                              <span className="mx-1">{moment(companyPaymentCard?.month, "MM").format("MMM")}</span>
                              <span>{moment(companyPaymentCard?.year).format("YYYY")}</span>
                            </p>
                          </div>
                        </li>
                      </ul>
                    ) : (
                      <label className="no-credit-card-text-muted text-center d-flex justify-content-center align-items-center">
                        {loading ? "Loading..." : <I18n lng="Subscriptions.ThereIsNoCreditCards" />}
                      </label>
                    )}
                  </>
                )}
              </div>

              <div
                style={{ width: "106%", marginLeft: "-10px" }}
                className="text-center subscription-open-modal-btn-add-payment-method"
                onClick={() => {
                  setShowCardDialog(true);
                }}
              >
                <span
                  onClick={() => {
                    companyPaymentCard ? setEdit(true) : setEdit(false);
                  }}
                >
                  {getCardLoading ? (
                    <>
                      <p>
                        <I18n lng="global.loading" />
                      </p>
                    </>
                  ) : companyPaymentCard ? (
                    <I18n lng="Subscriptions.UpdatePaymentMethod" />
                  ) : (
                    <I18n lng="Subscriptions.AddPaymentMethod" />
                  )}
                </span>
              </div>
              {(subscriptionData?.currentPlan?.status === "Running" ||
                subscriptionData?.currentPlan?.status === "Unsubscribed, subscription is running") &&
                subscriptionData?.subscriptionInfo?.isSubscribed &&
                billingTotalSeats < subscriptionData?.currentPlan?.seats &&
                !userForEdit?.isUserSeated &&
                !id &&
                !seatedType && (
                  <div
                    className="d-flex justify-content-center algin-items-center"
                    style={{
                      width: "375px",
                      height: "73px",
                      backgroundColor: "#FCFAEC",
                      marginLeft: "-14px",
                      marginTop: "10px",
                      marginRight: "-10px",
                    }}
                  >
                    <p
                      className="d-flex justify-content-center algin-items-center"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        className="mr-1 ml-1"
                        src={alertIcon}
                        alt="ImageAlert"
                        style={{
                          width: "18px",
                          height: "18px",
                          marginTop: "16px",
                          alignSelf: "start",
                        }}
                      />
                      <span
                        style={{
                          width: "320px",
                          height: "43px",
                          fontWeight: "500",
                          fontSize: "14px",
                          color: "#4F4F4F",
                          marginTop: "14px",
                        }}
                      >
                        <I18n lng="Subscriptions.TheRevisedInvoiceFeflectingTheAdjustedTotalWillBeGeneratedUpcomingBillingCycle" />
                      </span>
                    </p>
                  </div>
                )}

              {subscriptionData?.userTrial?.isTrialExpired === false && (
                <div
                  className="text-center mt-5"
                  style={{
                    color: "#828282",
                    fontWeight: "500",
                    fontSize: "14px",
                  }}
                >
                  <I18n lng="Subscriptions.YouCanCancelYourFreeTrialBefore" /> {moment(subscriptionData?.trialEndedAt).format("DD MMM YYYY")}{" "}
                  <I18n lng="Subscriptions.AndWeWillRemind" />
                </div>
              )}
            </div>
            <div className="h-100 d-flex justify-content-center align-items-end">
              <p
                style={{
                  width: "276px",
                  height: "48px",
                  backgroundColor: "#20639B",
                  color: "white",
                  borderRadius: "25px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <span style={{ fontSize: "16px", fontWeight: "700", width: "270px" }}>
                  {(() => {
                    if (subscriptionData?.currentPlan?.status === "Expired" && isAdditionalSeatsAdd !== undefined) {
                      return (
                        <React.Fragment>
                          <div className="text-center" onClick={() => handlePayment("Update")}>
                            <I18n lng="global.Update" />
                          </div>
                        </React.Fragment>
                      );
                    }

                    if (
                      (subscriptionData?.currentPlan?.plan === "Trial" && subscriptionData?.currentPlan?.status === "Running") ||
                      subscriptionData?.currentPlan?.status === "Trial is running"
                    ) {
                      return (
                        <React.Fragment>
                          <div className="text-center" onClick={() => handlePayment("Update")}>
                            <I18n lng="global.Update" />
                          </div>
                        </React.Fragment>
                      );
                    }
                    if (fromUsers === true) {
                      return (
                        <React.Fragment>
                          <div className="text-center" onClick={() => handlePayment("Charge")}>
                            <I18n lng="Subscriptions.Charge" />
                          </div>
                        </React.Fragment>
                      );
                    }
                    if (billingTotalSeats > subscriptionData?.currentPlan?.seats) {
                      return (
                        <React.Fragment>
                          <div className="text-center" onClick={() => handlePayment("Charge")}>
                            <I18n lng="Subscriptions.Charge" />
                          </div>
                        </React.Fragment>
                      );
                    }
                    if (
                      subscriptionData?.currentPlan?.plan === "Unsubscribed, subscription is running" &&
                      billingTotalSeats >= subscriptionData?.currentPlan?.seats
                    ) {
                      return (
                        <React.Fragment>
                          <div className="text-center" onClick={() => handlePayment("Charge")}>
                            <I18n lng="Subscriptions.Charge" />
                          </div>
                        </React.Fragment>
                      );
                    }
                    if (subscriptionData?.currentPlan?.status === "Paused") {
                      return (
                        <React.Fragment>
                          <div className="text-center" onClick={() => handlePayment("Charge")}>
                            <I18n lng="Subscriptions.Charge" />
                          </div>
                        </React.Fragment>
                      );
                    }
                    if (selectedPlan !== subscriptionData?.currentPlan?.plan) {
                      return (
                        <React.Fragment>
                          <div className="text-center" onClick={() => handlePayment("Charge")}>
                            <I18n lng="Subscriptions.Charge" />
                          </div>
                        </React.Fragment>
                      );
                    }
                    if (subscriptionData?.currentPlan?.plan === "Free" && subscriptionData?.userTrial?.isTrialCancelled === true) {
                      return (
                        <React.Fragment>
                          <div className="text-center" onClick={() => handlePayment("Charge")}>
                            <I18n lng="Subscriptions.Charge" />
                          </div>
                        </React.Fragment>
                      );
                    }
                    if (billingTotalSeats < subscriptionData?.currentPlan?.seats && subscriptionData?.currentPlan?.plan !== "Paused") {
                      return (
                        <React.Fragment>
                          <div className="text-center" onClick={() => handlePayment("Update")}>
                            <I18n lng="global.Update" />
                          </div>
                        </React.Fragment>
                      );
                    }
                    if (subscriptionData?.currentPlan?.status === "Expired" && isAdditionalSeatsAdd === undefined) {
                      return (
                        <React.Fragment>
                          <div className="text-center" onClick={() => handlePayment("Charge")}>
                            <I18n lng="Subscriptions.Charge" />
                          </div>
                        </React.Fragment>
                      );
                    }
                  })()}
                </span>
              </p>
            </div>
          </div>
        )}

        <SubscriptionsCardDialog
          edit={edit}
          cardIndex={cardIndex}
          cardToEdit={cardToEdit}
          userCardsList={companyPaymentCard}
          show={showCardDialog}
          onHide={() => setShowCardDialog(false)}
          setPlanUpdateUI={setPlanUpdateUI}
          planUpdatedUI={planUpdatedUI}
        />
      </DynamicModal>

      {addPaymentModal && (
        <PlanDialog
          showModal={addPaymentModal}
          onClose={() => setAddPaymentModal(false)}
          modalTitle={addPaymentModalData.title}
          text_one={addPaymentModalData.text_one}
          secondaryButtonLabel={addPaymentModalData.closeButtonText}
          primaryButtonLabel={addPaymentModalData.actionButtonText}
          secondaryButtonColor="#828282"
          primaryButtonColor="#20639B"
          setShowCardDialog={setShowCardDialog}
          titleWidth={"222px"}
          titleHeight={"20px"}
          titleFontWeight={"600"}
          titleFontSize={"20px"}
          titleLineHeight={"20px"}
          titleColor={"#333333"}
          secondaryButtonWidth={"43px"}
          secondaryButtonHeight={"16px"}
          secondaryButtonFontWeight={"600"}
          secondaryButtonFontSize={"16px"}
          secondaryButtonLineHeight={"15.92px"}
          primaryButtonWidth={"152px"}
          primaryButtonHeight={"30px"}
          primaryButtonFontWeight={"600"}
          primaryButtonFontSize={"14px"}
          primaryButtonLineHeight={"29.57px"}
        />
      )}

      {showMonthlyDecrementSeatsModal && (
        <PlanDialog
          showModal={showMonthlyDecrementSeatsModal}
          onClose={() => {
            setShowMonthlyDecrementSeatsModal(false);
            getSession();
            onHide();
          }}
          modalTitle={monthlyDecrementSeatsModalData.title}
          text_one={monthlyDecrementSeatsModalData.text_one}
          secondaryButtonLabel={monthlyDecrementSeatsModalData.closeButtonText}
          secondaryButtonColor="#20639B"
          isPaymentCardAdded={isPaymentCardAdded}
          setShowCardDialog={setShowCardDialog}
          titleWidth={"232px"}
          titleHeight={"25px"}
          titleFontWeight={"600"}
          titleFontSize={"18px"}
          titleLineHeight={"24.52px"}
          titleColor={"#333333"}
          secondaryButtonWidth={"43px"}
          secondaryButtonHeight={"16px"}
          secondaryButtonFontWeight={"600"}
          secondaryButtonFontSize={"16px"}
          secondaryButtonLineHeight={"15.92px"}
        />
      )}

      {showInvoice || downloadInvoicePdf ? (
        <SubscriptionInvoice
          invoiceData={invoiceData}
          rxPrintRef={rxPrintRef}
          user={user}
          Amount={parsedBillingDetails?.total}
          totalBeforeTax={parsedBillingDetails?.seatsPrice}
          withTax={parsedBillingDetails?.taxes}
          // unitPrice={proratedCostPerSeat}
          unitPrice={
            invoiceData?.subscriptionData?.currentPlan?.plan === "Additional seats"
              ? invoiceData?.subscriptionData?.lastAdditionalseats?.lastPerSeatBill
              : invoiceData?.subscriptionData?.currentPlan?.price
          }
          language={user?.language}
        />
      ) : null}
    </>
  );
};

const mapStateToProps = ({ authentication, subscription }) => ({
  user: authentication.user,
  subscriptionData: subscription.subscriptionData,
  loading: subscription.loading,
});

const mapDispatchToProps = {
  createUser,
  updateSubscriptionData,
  getSubscriptionByCompanyId,
  getSession,
  getAllUsers,
  updateUserById,
  getAllInvoices,
  getInvoices,
  getCompanyById,
  getCard,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChargeBillingDetails);
