import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import Attachment from "../snipets/attachment";
import { compose, withProps } from "recompose";
import { BsInfoCircleFill } from "react-icons/bs";
import { MdEditLocationAlt } from "react-icons/md";
import { GoLocation } from "react-icons/go";
import PlacesAutocomplete, {
  getLatLng,
  geocodeByAddress,
} from "react-places-autocomplete";
import I18n, { i18n } from "../../../../../languageSelector";
import { withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import {
  GET_LOCATION_BY_GEOCODE,
  TEXT_DIRECTION,
} from "../../../../../../../helper/helperFunctions";

const MapComponent = compose(
  withProps({
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withGoogleMap
)((props) => (
  <GoogleMap
    defaultZoom={props.zoom}
    center={{
      lat: props.lat,
      lng: props.lng,
    }}
    onClick={(e) => {
      props.onMarkerDragEnd(e, 0);
    }}
  >
    {props.markers && props.markers.length > 0
      ? props.markers.map((marker, index) => (
          <Marker
            key={index}
            name={marker.name}
            position={marker.position}
            draggable={props.view ? false : props.canDrag}
            onDragEnd={(e) => {
              props.onMarkerDragEnd(e, index);
            }}
          />
        ))
      : null}
  </GoogleMap>
));

const LocationPreview = ({
  view,
  question,
  canDrag = true,
  canSearch = true,
  getAnswer,
  actionItem,
  currentRole,
  addUpdateAnwser,
}) => {
  const [showLocation, setShowLocation] = useState(false);
  const [_city, _setCity] = useState();
  const [_country, _setCountry] = useState();
  const [_address, _setAddress] = useState("");
  const [customAddress, setCustomAddress] = useState("");
  const [notes, setNotes] = useState("");
  const [photo, setPhoto] = useState([]);
  const [_lat, _setLat] = useState();
  const [_lng, _setLng] = useState();
  const [_mapAddress, _setMapAddress] = useState("");
  const [_locationToShow, _setLocationToShow] = useState();
  const [questionImageURL, setQuestionImageURL] = useState([]);
  const [isQuestionLoading, setIsQuestionLoading] = useState(true);
  const [questionPreview, setQuestionPreview] = useState(question);
  const [address, setAddress] = useState("");
  const [lat, setLat] = useState(
    _locationToShow && _locationToShow.lat
      ? parseFloat(_locationToShow.lat)
      : 31.5203696
  );

  const [lng, setLng] = useState(
    _locationToShow && _locationToShow.lng
      ? parseFloat(_locationToShow.lng)
      : 74.35874729999999
  );

  const [zoom, setZoom] = useState(15);
  const [markers, setMarkers] = useState([
    {
      name: "Current position",
      position: {
        lat:
          _locationToShow && _locationToShow.lat
            ? parseFloat(_locationToShow.lat)
            : 31.5203696,
        lng:
          _locationToShow && _locationToShow.lng
            ? parseFloat(_locationToShow.lng)
            : 74.35874729999999,
      },
    },
  ]);

  useEffect(() => {
    setIsQuestionLoading(true);
  }, []);

  // eslint-disable-next-line
  useEffect(async () => {
    if (question) {
      setQuestionPreview(question);

      if (question.questionUuid) {
        const answer = getAnswer(question.questionUuid);

        if (answer?.questionUuid) {
          _setMapAddress(answer.mapAddress);
          _setCity(answer.city);
          _setCountry(answer.country);
          _setLng(parseFloat(answer.lng));
          _setLat(parseFloat(answer.lat));
          setLat(parseFloat(answer.lat));
          setLng(parseFloat(answer.lng));
          _setLocationToShow({
            lng: parseFloat(answer.lng),
            lat: parseFloat(answer.lat),
          });
          setMarkers([
            {
              name: "Current position",
              position: {
                lat: parseFloat(answer.lat),
                lng: parseFloat(answer.lng),
              },
            },
          ]);
          _setAddress(answer.address);
          setNotes(answer.note);
          setQuestionImageURL([...answer.image]);

          if (answer.image && answer.image.length) {
            setPhoto([...answer.image]);
          }

          setIsQuestionLoading(false);
        } else {
          setIsQuestionLoading(false);

          _setLocationToShow();
        }
      }
    }
    // eslint-disable-next-line
  }, [question]);

  useEffect(() => {
    if (_mapAddress && _address && _city && _country && _lat && _lng) {
      let answerToSave = {
        questionUuid: question.questionUuid,
        mapAddress: _mapAddress,
        address: _address,
        city: _city,
        country: _country,
        lat: _lat,
        lng: _lng,
        note: notes,
        locationImage:
          _lat && _lng
            ? `https://maps.googleapis.com/maps/api/staticmap?center=${_lat},${_lng}&zoom=18&size=512x512&key=AIzaSyBoV-vH2LdoFdgz5JR5fR-1HQ45wAeACPg&markers=color:red%7C${_lat},${_lng}`
            : "",
        image: questionImageURL?.length ? [...questionImageURL] : [],
      };
      if (!isQuestionLoading) {
        addUpdateAnwser(answerToSave);
      }
    }
    // eslint-disable-next-line
  }, [_lng, _address, _mapAddress]);

  const saveLocation = (latitude, longitude, locationData) => {
    let answerToSave = {
      questionUuid: question?.questionUuid,
      mapAddress: locationData?.address ? locationData?.address : "",
      address: locationData?.address ? locationData?.address : "",
      city: locationData?.city,
      country: locationData?.country,
      lat: latitude,
      lng: longitude,
      note: notes,
      image: questionImageURL?.length ? [...questionImageURL] : [],
      locationImage:
        latitude && longitude
          ? `https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&zoom=18&size=512x512&key=AIzaSyBoV-vH2LdoFdgz5JR5fR-1HQ45wAeACPg&markers=color:red%7C${latitude},${longitude}`
          : "",
    };
    addUpdateAnwser(answerToSave);

    setIsQuestionLoading(false);
  };

  useEffect(() => {
    let answerToSave = {
      questionUuid: question.questionUuid,
      mapAddress: _mapAddress,
      address: _address ? _address : customAddress,
      city: _city ? _city : "",
      country: _country ? _country : "",
      lat: _lat ? _lat : "",
      lng: _lng ? _lng : "",
      note: notes,
      image: questionImageURL?.length ? [...questionImageURL] : [],
      locationImage:
        _lat && _lng
          ? `https://maps.googleapis.com/maps/api/staticmap?center=${_lat},${_lng}&zoom=18&size=512x512&key=AIzaSyBoV-vH2LdoFdgz5JR5fR-1HQ45wAeACPg&markers=color:red%7C${_lat},${_lng}`
          : "",
    };
    if (!isQuestionLoading) {
      addUpdateAnwser(answerToSave);
    }

    // eslint-disable-next-line
  }, [notes]);

  const handleChange = (address) => {
    setAddress(address);
  };
  const handleSelect = (address) => {
    setAddress(address);
    _setAddress(address);
    _setMapAddress(address);
    geocodeByAddress(address)
      .then((results) => {
        results[0].address_components.forEach((item) => {
          if (item.types) {
            item.types.forEach((subItem) => {
              if (subItem === "country") {
                _setCountry(item.long_name);
              } else if (subItem === "administrative_area_level_2") {
                _setCity(item.long_name);
              }
            });
          }
        });
        return getLatLng(results[0]);
      })
      .then((latLng) => {
        _setLat(latLng.lat);
        _setLng(latLng.lng);
        setLat(latLng.lat);
        setLng(latLng.lng);
        const lat = latLng.lat;
        const lng = latLng.lng;
        setMarkers([
          {
            name: "Current position",
            position: {
              lat: lat,
              lng: lng,
            },
          },
        ]);
        setZoom(15);
      });
  };

  const imageAddHandler = () => {
    if (photo && photo.length) {
      let answerToSave = {
        questionUuid: question.questionUuid,
        mapAddress: _mapAddress,
        address: _address,
        city: _city,
        country: _country,
        lat: _lat,
        lng: _lng,
        note: notes,
        locationImage:
          _lat && _lng
            ? `https://maps.googleapis.com/maps/api/staticmap?center=${_lat},${_lng}&zoom=18&size=512x512&key=AIzaSyBoV-vH2LdoFdgz5JR5fR-1HQ45wAeACPg&markers=color:red%7C${_lat},${_lng}`
            : "",
        image: [...photo],
      };

      setQuestionImageURL([...photo]);

      if (!isQuestionLoading) {
        addUpdateAnwser(answerToSave);
      }
    }
  };

  const imageDeleteHandler = (index) => {
    let removeImageArray = [...questionImageURL];
    removeImageArray.splice(index, 1);

    if (questionImageURL?.length) {
      let answerToSave = {
        questionUuid: question.questionUuid,
        mapAddress: _mapAddress,
        address: _address,
        city: _city,
        country: _country,
        lat: _lat,
        lng: _lng,
        note: notes,
        locationImage:
          _lat && _lng
            ? `https://maps.googleapis.com/maps/api/staticmap?center=${_lat},${_lng}&zoom=18&size=512x512&key=AIzaSyBoV-vH2LdoFdgz5JR5fR-1HQ45wAeACPg&markers=color:red%7C${_lat},${_lng}`
            : "",
        image: [...removeImageArray],
      };

      if (!isQuestionLoading) {
        addUpdateAnwser(answerToSave);
      }
      photo.splice(index, 1);
      setPhoto([...photo]);
    } else {
      setPhoto([]);
    }
  };

  const onMarkerDragEnd = async (coord, index) => {
    if (coord) {
      const { latLng } = coord;
      const lat = latLng.lat();
      const lng = latLng.lng();

      setLat(lat);
      setLng(lng);
      setMarkers(() => {
        const updatedMarkers = [...markers];
        updatedMarkers[index] = {
          ...updatedMarkers[index],
          position: { lat, lng },
        };
        return [...updatedMarkers];
      });
      let location = await GET_LOCATION_BY_GEOCODE(lat, lng);
      setIsQuestionLoading(true);
      if (location) {
        _setLat(lat + "");
        _setLng(lng + "");
        _setCity(location.city);
        _setCountry(location.country);
        setAddress(location.address);
        _setAddress(location.address);
        saveLocation(lat + "", lng + "", location);
      }
      setZoom(15);
    }
  };

  return (
    <>
      <div>
        <>
          <div id={question?.questionUuid} className="multiple-choice">
            <div className="attachment-preview">
              {question?.settings?.photo ? (
                <img src={question.settings.photo} alt="Attachment Preview" />
              ) : (
                ""
              )}
            </div>
            <div className="d-flex justify-content-between question-title  h-auto">
              <strong>
                {questionPreview?.settings.questionText ===
                "Type Instructions" ? (
                  <I18n lng="Form.TypeInstructions" />
                ) : (
                  `${
                    question?.questionNumber
                      ? question?.questionNumber
                      : actionItem?.questionNo === undefined
                      ? actionItem
                      : actionItem?.questionNo
                      ? actionItem?.questionNo + 1
                      : 1
                  }.  ${questionPreview?.settings.questionText}`
                )}
                {question.settings.isMandatory && (
                  <span className="required"></span>
                )}
              </strong>
              <div className="d-flex">
                {question.settings.description ? (
                  <>
                    <p
                      data-for={`${question.settings.questionText}`}
                      data-tip={`${question.settings.description}`}
                      data-iscapture="true"
                    >
                      <BsInfoCircleFill color={"C4C4C4"} />
                    </p>
                    <ReactTooltip
                      id={`${question.settings.questionText}`}
                      place="top"
                      type="dark"
                      effect="float"
                      multiline={true}
                    />
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>

            {showLocation === false ? (
              <div className="d-flex ">
                <input
                  className="form-control"
                  type="text"
                  placeholder={i18n("global.locationDetails")}
                  value={_mapAddress}
                  onChange={(e) => _setMapAddress(e.target.value)}
                  onBlur={(e) => {
                    let data = {
                      questionUuid: "",
                      mapAddress: "",
                      address: e.target.value,
                      city: "",
                      country: "",
                      lat: "",
                      lng: "",
                      note: "",
                      image: [],
                    };
                    setCustomAddress(e.target.value);
                    saveLocation(data.lat, data.lng, data);
                  }}
                />
                <GoLocation
                  className={`mt-1 ${
                    TEXT_DIRECTION() === "rtl" ? "mr-2" : "ml-2"
                  }`}
                  size={28}
                  onClick={() => {
                    setShowLocation(!showLocation);
                  }}
                />
              </div>
            ) : (
              <>
                <div className="row">
                  <div className="col-12">
                    {canSearch ? (
                      <PlacesAutocomplete
                        value={address}
                        onChange={handleChange}
                        onSelect={handleSelect}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div>
                            <div className="form-label-group">
                              <div className="d-flex">
                                <input
                                  value={_mapAddress}
                                  onChange={(e) =>
                                    _setMapAddress(e.target.value)
                                  }
                                  id={"searchPlaces"}
                                  placeholder={i18n("Form.SearchPlaces")}
                                  {...getInputProps({
                                    className:
                                      "location-search-input form-control",
                                  })}
                                  // disabled={view}
                                  onBlur={(e) => {
                                    handleSelect(e.target.value);
                                  }}
                                />
                                <MdEditLocationAlt
                                  className={`mt-1 ${
                                    TEXT_DIRECTION() === "rtl" ? "mr-1" : "ml-1"
                                  }`}
                                  size={32}
                                  onClick={() => setShowLocation(false)}
                                />
                              </div>
                              <label htmlFor={"searchPlaces"}>
                                <I18n lng="Form.SearchPlaces" />
                              </label>
                            </div>
                            <div className="autocomplete-dropdown-container">
                              {loading && <div>Loading...</div>}
                              {suggestions.map((suggestion, index) => {
                                const className = suggestion.active
                                  ? "suggestion-item--active"
                                  : "suggestion-item";
                                const style = suggestion.active
                                  ? {
                                      backgroundColor: "#fafafa",
                                      cursor: "pointer",
                                    }
                                  : {
                                      backgroundColor: "#ffffff",
                                      cursor: "pointer",
                                    };
                                return (
                                  <div key={index}>
                                    <div
                                      {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                      })}
                                    >
                                      <span key={index}>
                                        {suggestion.description}
                                      </span>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    ) : null}
                  </div>
                </div>
                <MapComponent
                  isMarkerShown
                  lat={lat}
                  lng={lng}
                  markers={markers}
                  onMarkerDragEnd={onMarkerDragEnd}
                  zoom={zoom}
                  canDrag={canDrag}
                  view={view}
                />
              </>
            )}
          </div>
        </>
      </div>
      {
        <Attachment
          canComment={
            address !== "" || _mapAddress !== ""
              ? question?.settings?.canComment
              : false
          }
          canAttachPhoto={
            address !== "" || _mapAddress !== ""
              ? question?.settings?.canAttachPhoto
              : false
          }
          notes={notes}
          photo={photo}
          setNotes={setNotes}
          setPhoto={setPhoto}
          imageDeleteHandler={imageDeleteHandler}
          imageAddHandler={imageAddHandler}
        />
      }
    </>
  );
};

const mapStateToProps = ({ authentication }) => ({
  currentRole: authentication.currentRole,
});

export default connect(mapStateToProps, null)(LocationPreview);
