/** @format */

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import alertIcon from "../../../../../assets/images/alertIconUpdatePlan.svg";
import DecrementImage from "../../../../../assets/images/decrement.svg";
import alertIconDecrement from "../../../../../assets/images/decrementAlertIcon.svg";
import IncrementImage from "../../../../../assets/images/increment.svg";
import DynamicModal from "../../../../../components/dialogs";
import PlanDialog from "../../../../../components/dialogs/PlanDialog";
import { getSession } from "../../../../../redux/reducers/authentication/authentication";
import { getSubscriptionByCompanyId, updateSubscriptionData } from "../../../../../redux/reducers/subscription/subscription.reducer";
import I18n from "../../../languageSelector";
import SubscriptionsCardDialog from "./card-details/subscriptions-card-details";
import ChargeBillingDetails from "./charge-billing-details";
import { getCard, getCompanyById, verifyCard } from "../../../../../redux/reducers/company/company.reducer";

const SubscriptionsDetailDialog = ({
  show,
  onHide,
  subscriptionData,
  updateSubscriptionData,
  user,
  getSubscriptionByCompanyId,
  fromUsers,
  getCompanyById,
  verifyCard,
  getCard,
}) => {
  const [totalSeats, setTotalSeats] = useState(
    subscriptionData?.currentPlan?.status === "Free" ? subscriptionData?.seats?.used : subscriptionData?.seats?.total
  );
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertAfterSubscription, setShowAlertAfterSubscription] = useState(false);
  const [decrementSeatsAlert, setDecrementSeatsAlert] = useState(false);
  const [updateSeatsModal, setUpdateSeatsModal] = useState(false);
  const [decrementSeatsModal, setDecrementSeatsModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(subscriptionData?.currentPlan?.plan);
  const [pricePerSeat, setPricePerSeat] = useState(subscriptionData?.currentPlan?.price);
  const [showChargeBillingDetails, setShowChargeBillingDetails] = useState(false);
  const [showBillingSeats, setShowBillingSeats] = useState();
  const [showBillingTotalSeats, setShowBillingTotalSeats] = useState();
  const [currentSelectedPlan, setcurrentSelectedPlan] = useState(subscriptionData?.currentPlan?.plan);
  const [cardIndex, setCardIndex] = useState(null);
  const [cardToEdit, setCardToEdit] = useState({});
  const [edit, setEdit] = useState(false);
  const [showCardDialog, setShowCardDialog] = useState(false);
  const [showYearlyDecrementSeatsAlert, setShowYearlyDecrementSeatsAlert] = useState(false);
  const [isPlanSelected, setIsPlanSelected] = useState(false);
  const [companyPaymentCard, setCompanyPaymentCard] = useState();

  useEffect(() => {
    const fetchCard = async () => {
      if (user?.company?.id) {
        const response = await getCompanyById(user?.company?.id);
        if (response?.value?.data?.paymentCards?.moyasar?.tempToken) {
          await verifyCard(user?.company?.id);
        }
        if (response?.value?.data?.paymentCards?.moyasar?.token) {
          const cardResponse = await getCard(user?.company?.id);
          setCompanyPaymentCard(cardResponse?.value?.data);
        }
      }
    };

    fetchCard();
  }, [getCompanyById, verifyCard, getCard, user?.company?.id, user?.company?.paymentCards]);

  useEffect(() => {
    if (companyPaymentCard) {
      setCardToEdit(companyPaymentCard);
      setCardIndex(1);
    }
  }, [companyPaymentCard]);

  const { handleSubmit } = useForm();

  let totalIsLessDataTotal = subscriptionData?.currentPlan?.plan === "Free" ? null : totalSeats < subscriptionData?.seats?.total;

  useEffect(() => {
    getSubscriptionByCompanyId(user?.company?.id);

    // eslint-disable-next-line
  }, [show]);

  useEffect(() => {
    setcurrentSelectedPlan(subscriptionData?.currentPlan?.plan);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      subscriptionData?.currentPlan?.plan === "Monthly Subscription" ||
      (selectedPlan === `Monthly Subscription` && subscriptionData?.currentPlan?.plan === "Monthly Subscription")
    ) {
      setSelectedPlan("Monthly Subscription");
    } else if (
      subscriptionData?.currentPlan?.plan === "Yearly Subscription" ||
      (selectedPlan === `Yearly Subscription` && subscriptionData?.currentPlan?.plan === "Yearly Subscription")
    ) {
      setSelectedPlan("Yearly Subscription");
    }
    // eslint-disable-next-line
  }, [subscriptionData]);

  useEffect(() => {
    setTotalSeats(subscriptionData?.currentPlan?.status === "Free" ? subscriptionData?.seats?.used : subscriptionData?.seats?.total);
  }, [subscriptionData, show]);

  useEffect(() => {
    if (selectedPlan === "Monthly Subscription") {
      setPricePerSeat(79);
    } else if (selectedPlan === "Yearly Subscription") {
      setPricePerSeat(790);
    }
    // eslint-disable-next-line
  }, [selectedPlan]);

  const totalPrice =
    (totalSeats - subscriptionData?.seats?.total) *
    (pricePerSeat ? pricePerSeat : subscriptionData?.currentPlan?.plan === "Monthly Subscription" ? 79 : 790);

  useEffect(() => {
    !showChargeBillingDetails &&
      setTotalSeats(subscriptionData?.currentPlan?.status === "Free" ? subscriptionData?.seats?.used : subscriptionData?.seats?.total);
    setShowAlert(false);
    setShowAlertAfterSubscription(false);
    setDecrementSeatsAlert(false);
    setDecrementSeatsModal(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionData?.seats?.total, show, updateSubscriptionData?.currentPlan?.plan, showChargeBillingDetails]);

  useEffect(() => {
    if (totalSeats === subscriptionData?.seats?.total) {
      setShowAlert(false);
      setShowAlertAfterSubscription(false);
      setDecrementSeatsAlert(false);
      setDecrementSeatsModal(false);
    }
  }, [totalSeats, subscriptionData?.seats?.total]);

  const handleIncrement = () => {
    const newTotalSeats = totalSeats + 1;
    if (newTotalSeats > subscriptionData?.seats?.total) {
      setTotalSeats(newTotalSeats);
      setShowAlert(true);
      setShowAlertAfterSubscription(subscriptionData?.subscriptionInfo?.isSubscribed);
      setDecrementSeatsAlert(false);
      setDecrementSeatsModal(false);
    } else {
      setTotalSeats(newTotalSeats);
      setShowAlert(false);
      setShowAlertAfterSubscription(false);
      setDecrementSeatsAlert(false);
      setDecrementSeatsModal(false);
    }
    setShowYearlyDecrementSeatsAlert(false);
  };
  useEffect(() => {
    setSelectedPlan(subscriptionData?.currentPlan?.plan);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (totalIsLessDataTotal) {
      setDecrementSeatsAlert(true);
    }
  }, [totalIsLessDataTotal]);

  const updatePlanAndTrialIsRunning = {
    currentPlan: {
      plan: subscriptionData?.currentPlan?.plan,
      status: subscriptionData?.currentPlan?.status,
      seats: totalSeats,
      price: 0,
    },
    seats: {
      used: subscriptionData?.seats?.used,
      available: totalSeats - subscriptionData?.seats?.used,
      total: totalSeats,
    },
  };

  const updatePlanAndSubscriptionIsRunningData = {
    currentPlan: {
      plan: selectedPlan === "Monthly Subscription" ? "Monthly Subscription" : "Yearly Subscription",
      status: subscriptionData?.currentPlan?.status,
      seats: totalSeats,
      price: selectedPlan === "Monthly Subscription" ? 79 : 790,
    },
    seats: {
      used: subscriptionData?.seats?.used,
      available: totalSeats - subscriptionData?.seats?.used,
      total: totalSeats,
    },
  };

  const updatePlanAndTrialSubscriptionIsRunning = {
    currentPlan: {
      plan: selectedPlan === "Monthly Subscription" ? "Monthly Subscription" : "Yearly Subscription",
      status: subscriptionData?.currentPlan?.status,
      seats: totalSeats,
      price: selectedPlan === "Monthly Subscription" ? 79 : 790,
    },
    seats: {
      used: subscriptionData?.seats?.used,
      available: totalSeats - subscriptionData?.seats?.used,
      total: totalSeats,
    },
  };

  const handleSubmitForm = () => {
    setIsPlanSelected(false);
    if (
      subscriptionData?.currentPlan?.plan === "Trial" ||
      (subscriptionData?.currentPlan?.plan === "Trial is running" &&
        subscriptionData.currentPlan.status !== "Paused" &&
        !subscriptionData?.subscriptionInfo?.isSubscribedExpired)
    ) {
      updateSubscriptionData(subscriptionData?.id, updatePlanAndTrialIsRunning, "Update");
      setUpdateSeatsModal(true);
      getSession();
    } else if (
      subscriptionData?.currentPlan?.plan === "Free" &&
      subscriptionData?.currentPlan?.status === "Free" &&
      subscriptionData?.userTrial?.isTrialCancelled &&
      !subscriptionData?.subscriptionInfo?.isSubscribed
    ) {
      setShowChargeBillingDetails(true);
    } else if (
      subscriptionData.currentPlan.status === "Running" &&
      subscriptionData.subscriptionInfo.isSubscribed &&
      subscriptionData.currentPlan.status !== "Paused"
    ) {
      setShowChargeBillingDetails(true);
    } else if (
      totalSeats < subscriptionData?.seats?.total &&
      subscriptionData?.currentPlan?.plan !== "Trial" &&
      !subscriptionData?.subscriptionInfo?.isUnsubscribed &&
      subscriptionData.currentPlan.status !== "Paused"
    ) {
      updateSubscriptionData(subscriptionData?.id, updatePlanAndSubscriptionIsRunningData, "Update");
      setUpdateSeatsModal(true);
    } else if (
      totalSeats >= subscriptionData?.seats?.total &&
      subscriptionData?.currentPlan?.plan !== "Trial" &&
      !subscriptionData?.subscriptionInfo?.isUnsubscribed &&
      subscriptionData.currentPlan.status !== "Paused" &&
      !subscriptionData?.subscriptionInfo?.isSubscribedExpired
    ) {
      updateSubscriptionData(subscriptionData?.id, updatePlanAndTrialSubscriptionIsRunning, "Update");
      setUpdateSeatsModal(true);
      getSession();
    } else if (
      subscriptionData?.currentPlan?.status === "Unsubscribed, subscription is running" &&
      subscriptionData?.subscriptionInfo?.isSubscribed &&
      subscriptionData?.subscriptionInfo?.isUnsubscribed &&
      subscriptionData.currentPlan.status !== "Paused"
    ) {
      setShowChargeBillingDetails(true);
    } else if (
      subscriptionData.currentPlan.status === "Paused" &&
      subscriptionData?.currentPlan?.status !== "Expired" &&
      !subscriptionData?.subscriptionInfo?.isSubscribedExpired &&
      subscriptionData?.subscriptionInfo?.isSubscribed
    ) {
      companyPaymentCard ? setEdit(true) : setEdit(false);
      setShowChargeBillingDetails(true);
    } else if (
      subscriptionData?.currentPlan?.status === "Expired" &&
      subscriptionData?.subscriptionInfo?.isSubscribed &&
      subscriptionData?.subscriptionInfo?.isSubscribedExpired &&
      subscriptionData.currentPlan.status !== "Paused"
    ) {
      setShowChargeBillingDetails(true);
    } else if (
      subscriptionData?.currentPlan?.plan === "Free" &&
      subscriptionData?.currentPlan?.status === "Free" &&
      subscriptionData?.subscriptionInfo?.isSubscribedExpired
    ) {
      setShowChargeBillingDetails(true);
    }
    getSubscriptionByCompanyId(user?.company?.id);
    onHide();
  };

  const updateSeatsModalData = {
    title: <I18n lng="Subscriptions.PlanUpdated" />,
    text_one: <I18n lng="Subscriptions.YourPlanHasBeenUpdatedSuccessfully" />,
    secondaryButtonLabel: <I18n lng="global.Close" />,
  };

  const buttonColor =
    selectedPlan !== subscriptionData?.currentPlan?.plan ||
    totalSeats !== subscriptionData?.seats?.total ||
    totalSeats < subscriptionData?.seats?.total ||
    (subscriptionData?.currentPlan?.plan === "Free" &&
      subscriptionData?.currentPlan?.status === "Free" &&
      subscriptionData?.userTrial?.isTrialExpired &&
      subscriptionData?.userTrial?.isTrialCancelled &&
      !isPlanSelected)
      ? "#20639B"
      : "#F5F5F5";

  const cursorStyle =
    selectedPlan !== subscriptionData?.currentPlan?.plan ||
    totalSeats !== subscriptionData?.seats?.total ||
    totalSeats < subscriptionData?.seats?.total
      ? "pointer"
      : "not-allowed";

  useEffect(() => {
    setShowBillingSeats(subscriptionData?.currentPlan?.status === "Free" ? totalSeats : totalSeats - subscriptionData?.seats?.total);
    setShowBillingTotalSeats(totalSeats);
    if (currentSelectedPlan === undefined) {
      setcurrentSelectedPlan(subscriptionData?.currentPlan?.plan);
    }
    // eslint-disable-next-line
  }, [showChargeBillingDetails]);

  return (
    <>
      <DynamicModal
        title={<I18n lng="global.UpdatePlan" />}
        show={show}
        secondaryAction={() => {
          setIsPlanSelected(false);
          onHide();
          getSession();
        }}
        modalContentClass="bg-white"
        customClasses={`modal-drawer custom-roles-drawer`}
      >
        <form
          onSubmit={handleSubmit(handleSubmitForm)}
          className="d-flex flex-column subscription-detail-dialog d-flex flex-column justify-content-between"
        >
          <div>
            {(subscriptionData?.subscriptionInfo?.isSubscribed && subscriptionData?.currentPlan?.plan === "Monthly Subscription") ||
            (subscriptionData?.currentPlan?.plan === "Yearly Subscription" &&
              (subscriptionData?.isGracePeriod || subscriptionData?.currentPlan?.status === "Paused")) ||
            subscriptionData?.currentPlan?.status === "Trial is running" ||
            subscriptionData?.subscriptionInfo?.isSubscribedExpired ||
            (subscriptionData?.currentPlan?.plan === "Free" &&
              subscriptionData?.currentPlan?.status === "Free" &&
              subscriptionData?.userTrial?.isTrialCancelled) ? (
              <div className="border-b subscription-detail-dialog-current-plan">
                <p className="px-4 pb-3 pt-2">
                  <I18n lng="Subscriptions.PlanTerm" />
                </p>
                <div className="d-flex justify-content-between align-items-center mx-4">
                  <div className="form-check p-2 mr-1">
                    <input
                      checked={
                        selectedPlan ? selectedPlan === "Monthly Subscription" : subscriptionData?.currentPlan?.plan === "Monthly Subscription"
                      }
                      className="form-check-input custom-radio"
                      type="radio"
                      value={"Monthly Subscription"}
                      onChange={(e) => {
                        setSelectedPlan(e.target.value);
                        setcurrentSelectedPlan(e.target.value);
                        setIsPlanSelected(true);
                      }}
                      style={{
                        width: "17px",
                        height: "17px",
                        cursor: "pointer",
                        marginLeft: "-8.9px",
                      }}
                    />
                    <label className="form-check-label mx-3 mr-4" htmlFor="Monthly Subscription">
                      <I18n lng="Subscriptions.Month" /> (
                      <I18n lng="Subscriptions.SAR" /> <I18n lng="Subscriptions.SARPerseatPerMonth" />)
                    </label>
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center mx-4">
                  <div className="form-check p-2 mr-1">
                    <input
                      checked={selectedPlan ? selectedPlan === "Yearly Subscription" : subscriptionData?.currentPlan?.plan === "Yearly Subscription"}
                      className="form-check-input custom-radio"
                      type="radio"
                      value={"Yearly Subscription"}
                      onChange={(e) => {
                        setSelectedPlan(e.target.value);
                        setcurrentSelectedPlan(e.target.value);
                        setIsPlanSelected(true);
                      }}
                      style={{
                        width: "17px",
                        height: "17px",
                        cursor: "pointer",
                        marginLeft: "-8.9px",
                      }}
                    />
                    <label className="form-check-label mx-3 mr-4" htmlFor="Yearly Subscription">
                      <I18n lng="Subscriptions.Annual" /> (
                      <I18n lng="Subscriptions.SARPerseatPerYear" />)
                    </label>
                  </div>
                </div>
                <p
                  style={{
                    marginLeft: "44px",
                    paddingBottom: "6px",
                    marginRight: "50px",
                  }}
                >
                  <I18n lng="Subscriptions.PriceIsExclusiveOfTaxes" />
                </p>
              </div>
            ) : null}
            <div className="border-b subscription-detail-dialog-current-plan">
              <p className="px-4 pb-3 pt-2">
                <I18n lng="global.CurrentPlan" />
              </p>
              <div className="d-flex justify-content-between align-items-center mx-4 mb-3">
                <span>
                  <I18n lng="Subscriptions.TheNumberOfSeatsUsed" />
                </span>
                <div>
                  <span className="ml-1">{subscriptionData?.seats?.used}</span>
                  <span className="ml-3">
                    <I18n lng="global.seats" />
                  </span>
                </div>
              </div>
              <div className="d-flex justify-content-between mx-4 mb-3">
                <span>
                  <I18n lng="global.Available" />:
                </span>
                <div>
                  <span className="ml-1">{totalSeats - subscriptionData?.seats?.used}</span>
                  <span className="ml-3">
                    <I18n lng="global.seats" />
                  </span>
                </div>
              </div>
              <div className="d-flex justify-content-between mx-4 mb-3">
                <span className="mb-2" style={{ fontWeight: "700" }}>
                  <I18n lng="Subscriptions.TotalForUpatePlanPricing" />
                </span>
                <div className="mb-2">
                  <span style={{ fontWeight: "700" }} className="ml-1">
                    {totalSeats}
                  </span>
                  <span style={{ fontWeight: "700" }} className="ml-3">
                    <I18n lng="global.seats" />
                  </span>
                </div>
              </div>
            </div>
            <form className="my-2 pb-4 d-flex justify-content-center flex-column">
              <p className="px-4 pb-3 pt-2">
                <I18n lng="Subscriptions.TotalSeatsForSubscriptionDetails" />
              </p>
              <div className="mx-4 d-flex justify-content-around my-4">
                <button
                  // disabled={
                  //   subscriptionData?.currentPlan?.plan === "Yearly Subscription" &&
                  //   subscriptionData?.currentPlan?.status === "Running" &&
                  //   totalSeats === subscriptionData.currentPlan.seats
                  // }
                  type="button"
                  className="d-flex justify-content-center align-items-center"
                  onClick={() => {
                    // cant decrease seats when yearly and grace period false
                    if (totalSeats === subscriptionData?.seats?.used) {
                      setDecrementSeatsAlert(false);
                      setDecrementSeatsModal(true);
                    } else if (
                      subscriptionData?.currentPlan?.plan === "Free" &&
                      subscriptionData?.currentPlan?.status === "Free" &&
                      subscriptionData?.userTrial?.isTrialExpired &&
                      subscriptionData?.userTrial?.isTrialCancelled
                    ) {
                      setTotalSeats(totalSeats - 1);
                      setDecrementSeatsModal(false);
                    } else if (
                      subscriptionData?.currentPlan?.status === "Paused" ||
                      subscriptionData?.currentPlan?.status === "Unsubscribed, subscription is running" ||
                      subscriptionData?.currentPlan?.status === "Expired"
                    ) {
                      setTotalSeats(totalSeats - 1);
                      setDecrementSeatsModal(false);
                    } else if (
                      subscriptionData.currentPlan.plan === "Yearly Subscription" &&
                      subscriptionData?.isGracePeriod === false &&
                      totalSeats > subscriptionData?.seats?.total
                    ) {
                      setTotalSeats(totalSeats - 1);
                      setDecrementSeatsModal(false);
                    } else if (subscriptionData.currentPlan.plan === "Yearly Subscription" && subscriptionData?.isGracePeriod === false) {
                      setShowYearlyDecrementSeatsAlert(true);
                    } else if (subscriptionData.currentPlan.plan === "Yearly Subscription" && subscriptionData?.isGracePeriod === true) {
                      setTotalSeats(totalSeats - 1);
                      setDecrementSeatsModal(false);
                    } else if (subscriptionData.currentPlan.plan === "Monthly Subscription") {
                      setTotalSeats(totalSeats - 1);
                      setDecrementSeatsModal(false);
                    } else if (subscriptionData.currentPlan.plan === "Trial") {
                      setTotalSeats(totalSeats - 1);
                      setDecrementSeatsModal(false);
                    }
                  }}
                  style={{
                    backgroundColor: "#F2F2F2",
                    borderColor: totalSeats <= subscriptionData?.currentPlan?.seats ? "#BDBDBD" : "#4F4F4F",
                  }}
                >
                  <img src={DecrementImage} alt="Decrement Icon" />
                </button>

                <span>{totalSeats}</span>
                <button
                  style={{ backgroundColor: "#F2F2F2", borderColor: "#4F4F4F" }}
                  type="button"
                  className="d-flex justify-content-center align-items-center"
                  onClick={handleIncrement}
                >
                  <img src={IncrementImage} alt="Increment Icon" />
                </button>
              </div>
            </form>
            {showAlert && (subscriptionData?.subscriptionInfo?.isSubscribed === false || subscriptionData?.currentPlan?.status === "Paused") && (
              <div className="subscription-detail-alert p-4 d-flex align-items-center">
                <img className="mr-1 ml-1" src={alertIcon} alt="alert Icon" />
                <span className="subscription-detail-alert-text-one" style={{ color: "#4F4F4F" }}>
                  <I18n lng="Company.YouAreAdding" />
                </span>
                <span className="ml-1 mr-1 subscription-detail-alert-text-two">
                  {totalSeats > subscriptionData?.seats?.total ? (
                    <>
                      {totalSeats - subscriptionData?.seats?.total}
                      <span className="ml-1" style={{ color: "#4F4F4F" }}>
                        <I18n lng="Subscriptions.AdditionalSeat" />
                      </span>
                    </>
                  ) : (
                    <>
                      {totalSeats - subscriptionData?.seats?.total}
                      <span className="ml-1" style={{ color: "#4F4F4F" }}>
                        <I18n lng="Subscriptions.AdditionalSeat" />
                      </span>
                    </>
                  )}
                </span>
              </div>
            )}
            {showAlertAfterSubscription && subscriptionData?.subscriptionInfo?.isSubscribed && subscriptionData?.currentPlan?.status !== "Paused" && (
              <div className="alert-when-adding-on-zero-seats-parent mb-2">
                <div className="alert-when-adding-on-zero-seats-child" style={{ padding: "10px", backgroundColor: "#FCFAEC" }}>
                  <div className="d-flex">
                    <div className="pr-1" style={{ marginLeft: "9px", padding: "0 8px " }}>
                      <img src={alertIcon} alt="alert Icon" />
                    </div>
                    <div className="pr-1" style={{ width: "330px" }}>
                      <p style={{ marginRight: "-8px" }}>
                        <I18n lng="Company.YouAreAdding" />
                        <span
                          style={{ fontWeight: "600", marginLeft: "4px", color: "#4f4f4f" }}
                          className="ml-1 mr-1 subscription-detail-alert-text-two"
                        >
                          {totalSeats > subscriptionData?.seats?.total ? (
                            <>
                              {totalSeats - subscriptionData?.seats?.total}
                              <span className="ml-1">
                                <I18n lng="Subscriptions.AdditionalSeat" />
                              </span>
                            </>
                          ) : (
                            <>
                              {totalSeats - subscriptionData?.seats?.total}
                              <span className="ml-1">
                                <I18n lng="Subscriptions.AdditionalSeat" />
                              </span>
                            </>
                          )}
                        </span>
                        (<I18n lng="Subscriptions.SAR" />{" "}
                        {totalPrice
                          ?.toFixed(1)
                          ?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        {"  "}
                        {selectedPlan === "Monthly Subscription" ? <I18n lng="global.PerMonth" /> : <I18n lng="global.PerYear" />}
                        {")"}
                      </p>
                      {(() => {
                        if (
                          subscriptionData?.currentPlan?.status === "Running" &&
                          subscriptionData?.subscriptionInfo?.isSubscribed &&
                          selectedPlan === subscriptionData?.currentPlan?.plan
                        ) {
                          return (
                            <p
                              style={{
                                fontSize: "12px",
                                fontWeight: "500",
                                width: "302px",
                                textAlign: "justify",
                                backgroundColor: "#FCFAEC",
                              }}
                            >
                              <I18n lng="Subscriptions.SubscriptionFeesForAdditionalSeatsWillBeProratedForTheRemainingDaysOfThisCycleAndRegularBillingWillCommenceFromTheNextCyclePleaseNoteThatThePriceDoesNotIncludeAnyTax" />
                            </p>
                          );
                        } else if (
                          subscriptionData?.currentPlan?.status === "Unsubscribed, subscription is running" &&
                          subscriptionData?.subscriptionInfo?.isSubscribed &&
                          selectedPlan === subscriptionData?.currentPlan?.plan
                        ) {
                          return (
                            <p style={{ fontSize: "12px", fontWeight: "500", marginLeft: "10px" }}>
                              <I18n lng="Subscriptions.SubscriptionFeesForTheAdditionalSeatsWillBeProratedForTheRemainingDaysThisCyclePleaseNoteThatThePriceDoesNotIncludeAnyTax" />
                            </p>
                          );
                        } else if (
                          subscriptionData?.currentPlan?.status === "Expired" &&
                          subscriptionData?.subscriptionInfo?.isSubscribed &&
                          selectedPlan === subscriptionData?.currentPlan?.plan
                        ) {
                          return (
                            <p style={{ fontSize: "12px", fontWeight: "500", width: "266px" }}>
                              <I18n lng="Subscriptions.SubscriptionsFeesForAdditionalSeatsWillBeChargedUponSubscriptionStartPleaseNoteThatThePriceDoesNotIncludeAnyTax" />
                            </p>
                          );
                        } else if (
                          subscriptionData?.currentPlan?.status === "Trial is running" &&
                          subscriptionData?.subscriptionInfo?.isSubscribed &&
                          selectedPlan === subscriptionData?.currentPlan?.plan
                        ) {
                          return (
                            <p style={{ fontSize: "12px", fontWeight: "500" }}>
                              <I18n lng="Subscriptions.NoSubscriptionFeesForAdditionalSeatsWillChargedDuringRemainingDaysOfTrialPeriodRegularBillingForTotalSeatsWillCommenceFromNextCyclePleaseNoteThatPricesDoNotIncludeAnyTax" />
                            </p>
                          );
                        } else {
                          return null;
                        }
                      })()}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {decrementSeatsModal && (
              <div className="p-3 d-flex" style={{ backgroundColor: "#FBEFEF", color: "#EB5757" }}>
                <div className="mr-1 ml-1" style={{ color: "#EB5757" }}>
                  <img src={alertIconDecrement} alt="alertIconImage" style={{ fill: "#EB5757" }} />
                </div>
                <div>
                  <span className="mr-1">
                    <I18n lng="Subscriptions.YouHave" /> {subscriptionData?.seats?.used} <I18n lng="Subscriptions.SeatedUsersRequire" />{" "}
                    {subscriptionData?.seats?.used} <I18n lng="Subscriptions.PleaseUseSeats" />
                  </span>
                  <Link to={"/management/users"}>
                    <span style={{ color: "#20639B" }} className="mr-1">
                      <I18n lng="Subscriptions.UserManagment" />
                    </span>
                  </Link>
                  <span className="mr-1">
                    <I18n lng="Subscriptions.PageToConvertSomeUnseatedUsersBeforeReducingSeats" />
                  </span>
                </div>
              </div>
            )}
            {decrementSeatsAlert && (
              <div className="subscription-detail-alert p-4 d-flex align-items-center">
                <img className="mr-1 ml-1" src={alertIcon} alt="alert Icon" />
                <span className="subscription-detail-alert-text-one">
                  <I18n lng="Subscriptions.YouAreAboutTodecreasSeatsYourPlan" />
                </span>
              </div>
            )}
            {showYearlyDecrementSeatsAlert && (
              <div
                className="p-3 d-flex"
                style={{
                  backgroundColor: "#FBEFEF",
                  color: "#EB5757",
                  boxShadow: "rgba(0, 0, 0, 0.04) 0px 3px 5px",
                }}
              >
                <div className="mr-1 ml-1" style={{ color: "#EB5757" }}>
                  <img src={alertIconDecrement} alt="alertIconImage" style={{ fill: "#EB5757" }} />
                </div>
                <div>
                  <span className="mr-1">
                    <I18n lng="Subscriptions.DecrementSeatsInYearlySubscriptionWhileSubscriptionRunning" />
                  </span>
                  <Link to={"/management/users"}>
                    <span style={{ color: "#20639B" }} className="mr-1">
                      <I18n lng="Subscriptions.UserManagment" />
                    </span>
                  </Link>
                </div>
              </div>
            )}
          </div>
          <div className="d-flex justify-content-center align-items-center pb-4 mt-2">
            <button
              disabled={
                (totalSeats === subscriptionData?.seats?.total && subscriptionData?.currentPlan?.plan === selectedPlan) ||
                (subscriptionData?.currentPlan?.plan === "Free" &&
                  subscriptionData?.currentPlan?.status === "Free" &&
                  subscriptionData?.userTrial?.isTrialExpired &&
                  subscriptionData?.userTrial?.isTrialCancelled &&
                  !isPlanSelected)
              }
              type="submit"
              className="d-flex justify-content-center align-items-center"
              style={{
                width: "276px",
                height: "48px",
                backgroundColor: (() => {
                  if (
                    subscriptionData?.currentPlan?.plan === "Free" &&
                    subscriptionData?.currentPlan?.status === "Free" &&
                    subscriptionData?.userTrial?.isTrialExpired &&
                    subscriptionData?.userTrial?.isTrialCancelled &&
                    !isPlanSelected
                  ) {
                    return "#F5F5F5";
                  } else {
                    return buttonColor;
                  }
                })(),
                cursor: (() => {
                  if (
                    subscriptionData?.currentPlan?.plan === "Free" &&
                    subscriptionData?.currentPlan?.status === "Free" &&
                    subscriptionData?.userTrial?.isTrialExpired &&
                    subscriptionData?.userTrial?.isTrialCancelled &&
                    !isPlanSelected
                  ) {
                    return "not-allowed";
                  } else {
                    return cursorStyle;
                  }
                })(),
              }}
            >
              <span>
                <I18n lng="global.Update" />
              </span>
            </button>
          </div>
        </form>
      </DynamicModal>

      {updateSeatsModal && (
        <PlanDialog
          showModal={updateSeatsModal}
          onClose={() => {
            setUpdateSeatsModal(false);
            setShowChargeBillingDetails(false);
          }}
          modalTitle={updateSeatsModalData.title}
          text_one={updateSeatsModalData.text_one}
          primaryButtonLabel={updateSeatsModalData.primaryButtonLabel}
          secondaryButtonLabel={updateSeatsModalData.secondaryButtonLabel}
          handleModalAction={() => setUpdateSeatsModal(true)}
          secondaryButtonColor="#20639B"
          titleWidth={"135px"}
          titleHeight={"20px"}
          titleFontWeight={"600"}
          titleFontSize={"20px"}
          titleLineHeight={"20px"}
          titleColor={"#333333"}
          secondaryButtonWidth={"43px"}
          secondaryButtonHeight={"16px"}
          secondaryButtonFontWeight={"600"}
          secondaryButtonFontSize={"16px"}
          secondaryButtonLineHeight={"15.92px"}
        />
      )}

      {showChargeBillingDetails && (
        <ChargeBillingDetails
          subscriptionData={subscriptionData}
          selectedPlan={currentSelectedPlan}
          setSelectedPlan={setSelectedPlan}
          show={showChargeBillingDetails}
          onHide={() => setShowChargeBillingDetails(false)}
          isAdditionalSeatsAdd={totalSeats > subscriptionData?.currentPlan?.seats ? true : false}
          billingSeats={showBillingSeats}
          billingTotalSeats={showBillingTotalSeats}
          setUpdateSeatsModal={setUpdateSeatsModal}
          fromUsers={fromUsers}
        />
      )}

      <SubscriptionsCardDialog
        edit={edit}
        cardIndex={cardIndex}
        cardToEdit={cardToEdit}
        userCardsList={companyPaymentCard}
        show={showCardDialog}
        onHide={() => setShowCardDialog(false)}
      />
    </>
  );
};

const mapStateToProps = ({ authentication, subscription }) => ({
  plans: subscription.plans,
  user: authentication.user,
  subscriptionData: subscription.subscriptionData,
});

const mapDispatchToProps = {
  updateSubscriptionData,
  getSubscriptionByCompanyId,
  getCompanyById,
  verifyCard,
  getCard,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionsDetailDialog);
