import React, { useState } from "react";
import { connect } from "react-redux";
import NavTabs from "../../../components/navTabs";
import { ReportsUIProvider } from "./reports-ui-context";
import MyReportsPage from "./my-reports/my-reports-page";
import { Route, Switch, useParams } from "react-router-dom";
import OtherReportsPage from "./other-reports/other-reports-page";
import ArchiveReportsPage from "./archive-reports/archieve-reports-page";
import {
  deleteReport,
  getOtherReports,
  getAdminManagerReports,
  getAllReportsWithOutStateChange,
  updateReportBookmarkedUnBookMarked,
} from "../../../redux/reducers/admin-manager-reports/admin-manager-report.reducer";
import { archiveFormManagement } from "../../../redux/reducers/form/form.reducer";
import SubscriptionExpiredRedirect from "../../../components/private-route/SubscriptionExpiredRedirect";

const filterInitState = {
  fromDate: "",
  toDate: "",
  site: "",
  inspector: "",
  formName: "",
  status: "",
};

const ReportsPage = ({
  currentRole,
  history,
  getAdminManagerReports,
  archiveFormManagement,
  updateReportBookmarkedUnBookMarked,
  deleteReport,
  getAllReportsWithOutStateChange,
  getOtherReports,
  user,
}) => {
  const { moduleName } = useParams();

  const [reportUUid, setReportUUid] = useState("");
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [filterState, setFilterState] = useState(filterInitState);

  const reportsUIEvents = {
    openOtherReportShareDialog: (id, reportUUid) => {
      setReportUUid(reportUUid);
      history.push(`/reports/other-reports/export/${id}`);
    },
    openReportViewDialog: (id) => {
      history.push(`/reports/my-reports/view/${id}`);
    },
    openReportEditDialog: (id) => {
      history.push(`/reports/my-reports/edit/${id}`);
    },
    openEditReportDialog: (id) => {
      history.push(`/reports/my-reports/editReport/${id}`);
    },
    openReportExportViewDialog: (id, reportUUid) => {
      setReportUUid(reportUUid);
      history.push(`/reports/my-reports/export/${id}`);
    },
    archiveReports: (id, key) => {
      archiveFormManagement(id, true).then((res) => {
        if (key === "other") {
          getOtherReports();
        } else {
          getAdminManagerReports(false, filterState);
        }
      });
    },
    unArchiveReports: (id) => {
      archiveFormManagement(id, false).then((res) => {
        getAdminManagerReports(true, filterState);
      });
    },
    deleteAdminManagerReports: (id) => {
      deleteReport(id).then((res) => {
        getAdminManagerReports(true, filterState);
      });
    },
    viewHistoryReport: (id) => {
      history.push(`/reports/my-reports/view-history/${id}`);
    },
    viewOtherHistoryReport: (id) => {
      history.push(`/reports/other-reports/view-history/${id}`);
    },
    openOtherReportsView: (id) => {
      history.push(`/reports/other-reports/others-view/${id}`);
    },
    openOtherReportsManagerView: (id) => {
      history.push(`/reports/other-reports/view/${id}`);
    },

    reportBookmarkUnBookMarked: (id, isBookmarked, type) => {
      updateReportBookmarkedUnBookMarked(id, {
        isBookmarked: !isBookmarked,
      }).then(() => {
        if (type !== undefined && type === "other") {
          getOtherReports();
        } else {
          getAllReportsWithOutStateChange(false);
        }
      });
    },
  };

  const filterApplyHandler = () => {
    getAdminManagerReports(false, filterState);
    setTimeout(() => {
      setShowFilterModal(false);
    }, 300);
  };

  return (
    <>
      <div className="px-3">
        <NavTabs
          list={
            currentRole !== "user"
              ? [
                  "Reports.My-Reports",
                  "Reports.Other-Reports",
                  "global.Archive",
                ]
              : ["Reports.My-Reports"]
          }
          moduleName={moduleName}
        />

        <ReportsUIProvider reportsUIEvents={reportsUIEvents}>
          <Switch>
            <Route
              path="/reports/my-reports"
              render={() => {
                if (currentRole !== "user") {
                  return (
                    <SubscriptionExpiredRedirect
                      component={MyReportsPage}
                      history={history}
                    />
                  );
                } else {
                  return (
                    <MyReportsPage
                      reportUUid={reportUUid}
                      filterState={filterState}
                      setFilterState={setFilterState}
                      filterInitState={filterInitState}
                      filterApplyHandler={filterApplyHandler}
                      showFilterModal={showFilterModal}
                      setShowFilterModal={setShowFilterModal}
                    />
                  );
                }
              }}
            />
            <Route
              path="/reports/other-reports"
              render={() => {
                if (currentRole !== "user") {
                  return (
                    <SubscriptionExpiredRedirect
                      component={OtherReportsPage}
                      history={history}
                    />
                  );
                } else {
                  return <OtherReportsPage reportUUid={reportUUid} />;
                }
              }}
            />
            <Route
              path="/reports/archive"
              render={() => {
                if (currentRole !== "user") {
                  return (
                    <SubscriptionExpiredRedirect
                      component={ArchiveReportsPage}
                      history={history}
                    />
                  );
                } else {
                  return <ArchiveReportsPage />;
                }
              }}
            />
          </Switch>
        </ReportsUIProvider>
      </div>
    </>
  );
};

const mapStateToProps = ({ authentication }) => ({
  user: authentication.user,
  currentRole: authentication.currentRole,
});

const mapDispatchToProps = {
  deleteReport,
  getOtherReports,
  archiveFormManagement,
  getAdminManagerReports,
  getAllReportsWithOutStateChange,
  updateReportBookmarkedUnBookMarked,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportsPage);
