/** @format */

import I18n from "../../../../languageSelector";
import DynamicModal from "../../../../../../components/dialogs";
import IconPrint from "../../../../../../assets/images/Icon-Print.svg";
import { TEXT_DIRECTION } from "../../../../../../helper/helperFunctions";
import { useHistory } from "react-router-dom";
import { getInvoice } from "../../../../../../redux/reducers/subscription/subscription.reducer";
import { connect } from "react-redux";
import moment from "moment";
import { invoiceTranslation, subscriptionsTranslation } from "../../../../../../config/constants";
import { useRef, useState } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import SubscriptionInvoice from "../subscription-invoice";

const InvoiceDetails = ({ show, onHide, invoiceData, setShowInvoicesDetails, user, lastSubscriptionData }) => {
  const history = useHistory();
  const language = localStorage.getItem("language");
  const [showInvoice, setShowInvoice] = useState(false);
  const rxPrintRef = useRef(null);
  const [generatingPDF, setGeneratingPDF] = useState(false);

  let unitPrice =
    invoiceData?.subscriptionData?.currentPlan?.plan === "Additional seats"
      ? invoiceData?.subscriptionData?.lastAdditionalseats?.lastPerSeatBill
      : invoiceData?.subscriptionData?.currentPlan?.price;

  let seats =
    invoiceData?.subscriptionData?.currentPlan?.plan === "Additional seats"
      ? invoiceData?.subscriptionData?.lastAdditionalseats?.seats
      : invoiceData?.subscriptionData?.currentPlan?.seats;
  let totalAmountBeforeTax = unitPrice * seats;
  let seatsTax = totalAmountBeforeTax * 0.15;
  let amountToCharge = seatsTax + totalAmountBeforeTax;

  const handleGeneratePDF = () => {
    setGeneratingPDF(true);
    setShowInvoice(true);
    // setPrintRxLoading(true);
    setTimeout(() => {
      const input = document.getElementById("divToPrint");

      if (input) {
        const pdfWidth = input.clientWidth;
        const pdfHeight = input.clientHeight;

        const dpi = 300;

        html2canvas(input, {
          scale: dpi / 90,
          width: pdfWidth,
          height: pdfHeight,
          logging: true,
        }).then((canvas) => {
          setShowInvoice(false);
          setGeneratingPDF(false);
          const imgData = canvas.toDataURL("image/png", 1.0);

          const pdf = new jsPDF({
            unit: "px",
            format: [pdfWidth, pdfHeight],
          });

          pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);

          const blobURL = pdf.output("bloburl");

          window.open(blobURL, "_blank");
        });
      }
    }, 1000);
  };

  return (
    <>
      <DynamicModal
        show={show}
        title={
          language === "ARABIC" ? (
            <>
              <span>A1 - {invoiceData?.invoiceDummyId}</span>{" "}
              <span>
                <I18n lng="Subscriptions.InvoiceForInvoice" />
              </span>
            </>
          ) : (
            <>
              <span>
                <I18n lng="Subscriptions.InvoiceForInvoice" />{" "}
              </span>
              <span>A1 - {invoiceData?.invoiceDummyId}</span>
            </>
          )
        }
        secondaryAction={() => {
          onHide();
          setShowInvoicesDetails(false);
          history.push("/management/subscriptions");
        }}
        modalContentclassName="bg-white"
        customClasses="modal-drawer custom-roles-drawer"
      >
        <div className="subscriptions-modal pr-1">
          <div className="form-view-wrapper dispatch-card mt-0 mb-0">
            <form className="dispatch-wrapper text-center">
              <div className="form-header">
                <div className="form-title">
                  <strong className="float-right cursor-pointer" onClick={handleGeneratePDF}>
                    {generatingPDF ? (
                      <div className="spinner-border spinner-border-sm" role="status" style={{ color: "#20639b" }}>
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <img src={IconPrint} alt="Print" />
                    )}
                  </strong>
                </div>
              </div>
            </form>
          </div>
          <div className="form-view-wrapper">
            <form className="dispatch-wrapper text-center comp-subscriptions-form mt-2">
              <div className="card dispatch-card mb-2">
                <div
                  style={{
                    color: "#333333",
                    fontWeight: "700",
                    fontSize: "14px",
                  }}
                  className={`${TEXT_DIRECTION() === "rtl" ? "ml-auto" : "mr-auto"}`}
                >
                  <I18n lng="Subscriptions.Details" />
                </div>
                <ul className="subscription-cost font-size-14 mb-2 mt-3">
                  <li>
                    <label className="text-muted" style={{ color: "#333333" }}>
                      <I18n lng="Subscriptions.DescriptionForInvoice" />
                    </label>
                    <strong className={`${TEXT_DIRECTION() === "rtl" ? "mr-auto" : "ml-auto"}`} style={{ color: "#333333" }}>
                      {invoiceTranslation(invoiceData?.subscriptionData?.currentPlan?.plan)}
                    </strong>
                  </li>
                  <li>
                    <label className="text-muted" style={{ color: "#333333" }}>
                      <I18n lng="Subscriptions.InvoiceNumber" />
                    </label>
                    <strong className={`${TEXT_DIRECTION() === "rtl" ? "mr-auto" : "ml-auto"}`} style={{ color: "#333333" }}>
                      A1- {invoiceData?.invoiceDummyId}
                    </strong>
                  </li>
                  <li>
                    <label className="text-muted" style={{ color: "#333333" }}>
                      <I18n lng="Analytics.Date" />:
                    </label>
                    <strong className={`${TEXT_DIRECTION() === "rtl" ? "mr-auto" : "ml-auto"}`} style={{ color: "#333333" }}>
                      {moment(invoiceData?.createdAt).format("DD MMMM YYYY")}
                    </strong>
                  </li>
                </ul>
              </div>
              <div className="card dispatch-card mb-2">
                <div
                  style={{
                    color: "#333333",
                    fontWeight: "700",
                    fontSize: "14px",
                    paddingTop: "6px",
                    paddingBottom: "6px",
                  }}
                  className={`${TEXT_DIRECTION() === "rtl" ? "ml-auto" : "mr-auto"}`}
                >
                  <I18n lng="Subscriptions.From" />
                </div>
                <ul className="subscription-cost mb-2">
                  <li>
                    <label style={{ color: "#333333" }}>
                      <I18n lng="Subscriptions.NumuItEstTAMAMAPP" />
                    </label>
                  </li>
                  <li>
                    <label style={{ color: "#333333" }}>
                      <I18n lng="Subscriptions.DammamSaudiArabia" />
                    </label>
                  </li>
                  <li>
                    <label style={{ color: "#333333" }}>
                      <I18n lng="Subscriptions.Cr2050139742" />
                    </label>
                  </li>
                  <li className="mb-3">
                    <span style={{ color: "#333333" }}>
                      <I18n lng="Subscriptions.TaxNo" />
                    </span>
                    <span>-</span>
                  </li>
                </ul>
              </div>
              <div className="card dispatch-card mb-2">
                <div
                  style={{
                    color: "#333333",
                    fontWeight: "700",
                    fontSize: "14px",
                    paddingTop: "14px",
                  }}
                  className={`${TEXT_DIRECTION() === "rtl" ? "ml-auto" : "mr-auto"}`}
                >
                  <I18n lng="Subscriptions.For" />
                </div>
                <ul className="subscription-cost font-size-14 mb-2 mt-3">
                  <li>
                    <label style={{ color: "#333333" }}>{user?.company?.companyName}</label>
                  </li>
                  <li>
                    <label style={{ color: "#333333" }}>Tax no. {user?.company?.taxNumber}</label>
                  </li>
                  <li>
                    <label style={{ color: "#333333" }}>{user?.company?.country}</label>
                  </li>
                </ul>
              </div>
              <div className="card dispatch-card mb-2">
                <div
                  style={{
                    color: "#333333",
                    fontWeight: "700",
                    fontSize: "14px",
                    paddingTop: "14px",
                  }}
                  className={`${TEXT_DIRECTION() === "rtl" ? "ml-auto" : "mr-auto"}`}
                >
                  <I18n lng="Subscriptions.Summary" />
                </div>
                <ul className="subscription-cost font-size-14 mb-2 mt-3">
                  <>
                    <li>
                      <label className="text-muted" style={{ color: "#333333" }}>
                        <I18n lng="Subscriptions.ProductForInvoice" />
                      </label>
                      <strong className={`${TEXT_DIRECTION() === "rtl" ? "mr-auto" : "ml-auto"}`} style={{ color: "#333333" }}>
                        {subscriptionsTranslation(invoiceData?.subscriptionData?.currentPlan?.plan)}
                      </strong>
                    </li>
                    <li>
                      <label className="text-muted" style={{ color: "#333333" }}>
                        <I18n lng="global.Price" />:
                      </label>
                      <strong style={{ color: "#333333" }} className={`${TEXT_DIRECTION() === "rtl" ? "mr-auto" : "ml-auto"}`}>
                        {language === "ARABIC" ? (
                          <>
                            {unitPrice?.toFixed(2)} <I18n lng="Subscriptions.SAR" />
                          </>
                        ) : (
                          <>
                            <I18n lng="Subscriptions.SAR" /> {unitPrice?.toFixed(2)}
                          </>
                        )}
                      </strong>
                    </li>
                    <li>
                      <label className="text-muted" style={{ color: "#333333" }}>
                        <I18n lng="Subscriptions.Seats" />:
                      </label>
                      <strong className={`${TEXT_DIRECTION() === "rtl" ? "mr-auto" : "ml-auto"}`} style={{ color: "#333333" }}>
                        {invoiceData?.subscriptionData?.lastAdditionalseats?.seats &&
                        invoiceData?.subscriptionData?.currentPlan?.plan === "Additional seats"
                          ? invoiceData?.subscriptionData?.lastAdditionalseats?.seats
                          : invoiceData?.subscriptionData?.currentPlan?.seats}
                      </strong>
                    </li>
                    <li>
                      <label className="text-muted" style={{ color: "#333333" }}>
                        <I18n lng="Subscriptions.BillingPeriod" />
                      </label>
                      <strong className={`${TEXT_DIRECTION() === "rtl" ? "mr-auto" : "ml-auto"}`} style={{ color: "#333333" }}>
                        {moment(invoiceData?.subscriptionData?.subscriptionStartedAt).format("DD MMMM YYYY")} -{" "}
                        {moment(invoiceData?.subscriptionData?.subscriptionEndedAt).format("DD MMMM YYYY")}
                      </strong>
                    </li>
                  </>
                  <hr />
                  <li>
                    <label className="text-muted" style={{ color: "#4F4F4F" }}>
                      <I18n lng="Subscriptions.SubTotal" />:
                    </label>
                    <strong style={{ color: "#4F4F4F" }} className={`${TEXT_DIRECTION() === "rtl" ? "mr-auto" : "ml-auto"}`}>
                      {language === "ARABIC" ? (
                        <>
                          {totalAmountBeforeTax?.toFixed(2)} <I18n lng="Subscriptions.SAR" />
                        </>
                      ) : (
                        <>
                          <I18n lng="Subscriptions.SAR" /> {totalAmountBeforeTax?.toFixed(2)}
                        </>
                      )}
                    </strong>
                  </li>
                  <li>
                    <label className="text-muted" style={{ color: "#4F4F4F" }}>
                      <I18n lng="Subscriptions.Discount" />:
                    </label>
                    <strong style={{ color: "#4F4F4F" }} className={`${TEXT_DIRECTION() === "rtl" ? "mr-auto" : "ml-auto"}`}>
                      {language === "ARABIC" ? (
                        <>
                          0.00 <I18n lng="Subscriptions.SAR" />
                        </>
                      ) : (
                        <>
                          <I18n lng="Subscriptions.SAR" /> 0.00
                        </>
                      )}
                    </strong>
                  </li>
                  <li>
                    <label className="text-muted" style={{ color: "#4F4F4F" }}>
                      <I18n lng="Subscriptions.TotalBeforTax" />:
                    </label>
                    <strong style={{ color: "#4F4F4F" }} className={`${TEXT_DIRECTION() === "rtl" ? "mr-auto" : "ml-auto"}`}>
                      {language === "ARABIC" ? (
                        <>
                          {totalAmountBeforeTax?.toFixed(2)} <I18n lng="Subscriptions.SAR" />
                        </>
                      ) : (
                        <>
                          <I18n lng="Subscriptions.SAR" /> {totalAmountBeforeTax?.toFixed(2)}
                        </>
                      )}
                    </strong>
                  </li>
                  <li>
                    <label className="text-muted" style={{ color: "#4F4F4F" }}>
                      <I18n lng="Invoices.Vat" /> 15%:
                    </label>
                    <strong style={{ color: "#4F4F4F" }} className={`${TEXT_DIRECTION() === "rtl" ? "mr-auto" : "ml-auto"}`}>
                      {language === "ARABIC" ? (
                        <>
                          {seatsTax.toFixed(2)} <I18n lng="Subscriptions.SAR" />
                        </>
                      ) : (
                        <>
                          <I18n lng="Subscriptions.SAR" /> {seatsTax.toFixed(2)}
                        </>
                      )}
                    </strong>
                  </li>

                  <li>
                    <label style={{ color: "#333333" }}>
                      <b>
                        <I18n lng="Subscriptions.Total" />.
                      </b>
                    </label>
                    <strong style={{ color: "#333333" }} className={`${TEXT_DIRECTION() === "rtl" ? "mr-auto" : "ml-auto"}`}>
                      <b>
                        {language === "ARABIC" ? (
                          <>
                            {amountToCharge?.toFixed(2)} <I18n lng="Subscriptions.SAR" />
                          </>
                        ) : (
                          <>
                            <I18n lng="Subscriptions.SAR" /> {amountToCharge?.toFixed(2)}
                          </>
                        )}
                      </b>
                    </strong>
                  </li>
                </ul>
              </div>
            </form>
          </div>
        </div>
      </DynamicModal>

      {showInvoice && (
        <SubscriptionInvoice
          rxPrintRef={rxPrintRef}
          invoiceData={invoiceData}
          user={user}
          Amount={lastSubscriptionData?.total}
          withTax={lastSubscriptionData?.taxes}
          totalBeforeTax={lastSubscriptionData?.seatsPrice}
          unitPrice={
            invoiceData?.subscriptionData?.currentPlan?.plan === "Additional seats"
              ? invoiceData?.subscriptionData?.lastAdditionalseats?.lastPerSeatBill
              : invoiceData?.subscriptionData?.currentPlan?.price
          }
          language={user?.language}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ authentication }) => ({
  user: authentication.user,
});

const mapDispatchToProps = {
  getInvoice,
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceDetails);
