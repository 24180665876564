/** @format */

import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { AiOutlineLogout } from "react-icons/ai";
import { BsBell, BsGear } from "react-icons/bs";
import { FaRegUserCircle } from "react-icons/fa";
import { IoIosHelpCircle } from "react-icons/io";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Collapse, Dropdown, DropdownToggle, Nav, NavItem, Navbar, NavbarBrand, NavbarToggler } from "reactstrap";
import BellIcon from "../../assets/images/bell.svg";
import BrandLogo from "../../assets/images/brand-logo.svg";
import IconCloseDrawer from "../../assets/images/icon-close-drawer.svg";
import IconCompanySetting from "../../assets/images/icon-nav-company.svg";
import IconForms from "../../assets/images/icon-nav-forms.png";
import IconReports from "../../assets/images/icon-nav-reports.svg";
import NavToggler from "../../assets/images/icon-nav-toggler.png";
import FormExpiredIcon from "../../assets/images/HeaderForm.png";
import ReportExpiredIcon from "../../assets/images/HeaderReports.png";
import ReportExpiredAnalyticsIcon from "../../assets/images/HeaderAnalyticsExpiredIcon.png";
import HeaderAnaylyticsIcon from "../../assets/images/HeaderAnalyticsIcon.png";
import { TEXT_DIRECTION, canAccessTheModule } from "../../helper/helperFunctions";
import I18n, { i18n } from "../../pages/private/languageSelector";
import SubscriptionsDetailDialog from "../../pages/private/management/company/subscriptions/subscriptions-details";
import CompanyUsersProfile from "../../pages/private/management/company/users/company-users-profile";
import NotificationsDialog from "../../pages/private/notifications/notificationsDialog";
import { changeCurrentRole, logout } from "../../redux/reducers/authentication/authentication";
import { getForm, getForms, patchForm, patchFormForNotification } from "../../redux/reducers/form/form.reducer";
import { getAllNotifications, notificationMarkedAsRead, notificationReadSingle } from "../../redux/reducers/notifications/notifications.reducer";
import { getSubscriptionByCompanyId, updateSubscriptionData } from "../../redux/reducers/subscription/subscription.reducer";
import PlanDialog from "../dialogs/PlanDialog";
import { useSidebar } from "../../pages/private/analytics/useSidebarProvider";
import PricingPlanDialog from "../dialogs/PricingPlanDialog";
import { getSession } from "../../redux/reducers/authentication/authentication";
import ChargeBillingDetails from "../../pages/private/management/company/subscriptions/charge-billing-details";

function useOutsideAlerter(ref, collapsed, setCollapsed) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref && ref?.current && !ref?.current?.contains(event.target)) {
        if (!collapsed) {
          setCollapsed(true);
        }
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, collapsed, setCollapsed]);
}

const AppHeader = ({
  user,
  logout,
  rolesAccess,
  currentRole,
  currentRoute,
  patchForm,
  patchFormForNotification,
  isAuthenticated,
  allNotifications,
  getAllNotifications,
  notificationReadSingle,
  notificationMarkedAsRead,
  storedOldForm,
  id,
  newForm,
  subscriptionData,
  updateSubscriptionData,
  getSubscriptionByCompanyId,
  getSession,
}) => {
  let wrapperRef = useRef();
  const history = useHistory();
  // const location = useLocation();

  // let formId = location?.pathname?.split("/")[3];
  // const [collapsed, setCollapsed] = useState(true);

  const { collapsed, setCollapsed } = useSidebar();
  useOutsideAlerter(wrapperRef, collapsed, setCollapsed);

  const toggleNavbar = () => {
    if (currentRoute?.enableBack) {
      if (currentRoute.step2 === "Form Management") {
        history.push("/forms/form-management");
      } else if (currentRoute.step2 === "Global List") {
        history.push("/forms/global-list");
      }
    } else {
      setCollapsed(!collapsed);
    }
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [openUserProfile, setOpenUserProfile] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const [unReadNotification, setUnReadNotification] = useState([]);
  const [modalData, setModalData] = useState(null);
  const [showUpdatePlanModal, setShowUpdatePlanModal] = useState(false);
  const [showUpdateSuccessModal, setShowUpdateSuccessModal] = useState(false);
  const [trialDate, setTrialDate] = useState(null);
  const [showDetailsDialog, setShowDetailsDialog] = useState(false);
  const [showPricingPlanModal, setShowPricingPlanModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState("");
  const [chargeBillingDetailsDailog, setChargeBillingDetailsDailog] = useState(false);
  const [showResumeSubscriptionModal, setShowResumeSubscriptionModal] = useState(false);
  const [showAfterResumeSubscriptionModal, setShowAfterResumeSubscriptionModal] = useState(false);

  const openUpdatePlanModal = (data) => {
    setModalData(data);
    setShowUpdatePlanModal(true);
  };

  const closeUpdatePlanModal = () => {
    setShowUpdatePlanModal(false);
  };

  useEffect(() => {
    if (user?.company) {
      getSubscriptionByCompanyId(user?.company?.id);
    }
  }, [user?.company, showUpdatePlanModal, getSubscriptionByCompanyId]);

  const startTrialData = {
    currentPlan: {
      plan: "Trial",
      status: "Running",
      seats: subscriptionData?.seats?.used,
      price: 0,
    },
    seats: {
      used: subscriptionData?.seats?.used,
      available: 0,
      total: subscriptionData?.seats?.used,
    },
    userTrial: {
      isTrialStarted: true,
      isTrialCancelled: false,
      isTrialExpired: false,
    },
  };

  const StartFreeTrialHandler = async () => {
    const responseData = await updateSubscriptionData(subscriptionData?.id, startTrialData, "Update");
    setTrialDate(responseData?.value?.data?.trialEndedAt);
    setShowUpdatePlanModal(false);
    setShowUpdateSuccessModal(true);
  };

  useEffect(() => {
    if (!isAuthenticated || !user) {
      setCollapsed(true);
    }
    // eslint-disable-next-line
  }, [isAuthenticated, user]);

  const markedReadSingleHandler = (id) => {
    if (id) {
      notificationReadSingle(id, { isRead: true }).then(() => {
        getAllNotifications().then(() => setOpenNotification(true));
      });
    }
  };

  const markedAsReadHandle = () => {
    notificationMarkedAsRead().then(() => getAllNotifications().then(() => setOpenNotification(true)));
  };

  useEffect(() => {
    let unreadArray = [];
    if (allNotifications && allNotifications.length) {
      unreadArray = allNotifications.filter((item) => !item.isRead).map((item) => item);
    }
    setUnReadNotification([...unreadArray]);
  }, [allNotifications]);

  const TrySubscriptionForFreeData = {
    title: <I18n lng="Subscriptions.TrySubscriptionForFree" />,
    text_two: <I18n lng="Subscriptions.StartYour30dayFreeTrialWithNoCreditCardRequired" />,
    closeButtonText: <I18n lng="global.Close" />,
    actionButtonText: <I18n lng="global.StartFreeTrial" />,
  };

  const formattedTrialEndDate = moment(trialDate).format("DD MMM YYYY");

  const updatePlanData = {
    title: <I18n lng="Subscriptions.UpdatePlanTrialStartedModalTitle" />,
    text_one: (
      <>
        <I18n lng="Subscriptions.YourTrialHasStartedSuccessfully" /> {formattedTrialEndDate}
      </>
    ),
    closeButtonText: <I18n lng="global.Close" />,
    actionButtonText: i18n("global.UpdatePlan"),
  };

  const pricingPlanModalData = {
    title: <I18n lng="Subscriptions.SelectYourPricingPlan" />,
    text_one: <I18n lng="Subscriptions.PriceIsExclusiveOfTaxes" />,
    text_two: <I18n lng="Subscriptions.YouWillBeChargedAutomaticallyAtThe" />,
    text_three: <I18n lng="Subscriptions.startOfEachBillingPeriodUntilYouCancel" />,
    actionButtonText: <I18n lng="Subscriptions.Continue" />,
    closeButtonText: <I18n lng="global.Cancel" />,
  };

  const resumeSubscriptionModalData = {
    title: <I18n lng="Subscriptions.ResumesSubscription" />,
    text_one: i18n("Subscriptions.ClickResumeSubscriptionToConfirmYourDesireToContinueAccessingAllFeaturesBenefits"),
    actionButtonText: i18n("Subscriptions.ResumesSubscription"),
    closeButtonText: <I18n lng="global.Close" />,
  };

  const afterResumeSubscriptionModalData = {
    title: <I18n lng="Subscriptions.ResumesSubscription" />,
    text_one: <I18n lng="Subscriptions.YourSubscriptionHasBeenSuccessfullyResumedYourRegularBillingCycleWillBeginOn" />,
    text_two: moment(subscriptionData?.subscriptionEndedAt).format("MMMM D, YYYY"),
    closeButtonText: <I18n lng="global.Close" />,
  };

  const trialEndedAt = new Date(subscriptionData?.trialEndedAt);
  const currentDate = new Date();
  const timeDifference = trialEndedAt.getTime() - currentDate.getTime();
  const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));

  const isTrialExpired = subscriptionData?.userTrial?.isTrialExpired && subscriptionData?.currentPlan?.status === "Expired";
  const isSubscriptionPaused = subscriptionData?.currentPlan?.status === "Paused";

  const endDate = moment(subscriptionData?.subscriptionEndedAt);
  const currentDateExpireSubscription = moment();
  const durationInDays = endDate.diff(currentDateExpireSubscription, "days");

  const resumeSubscriptionData = {
    currentPlan: {
      plan: subscriptionData?.currentPlan?.plan,
      status: "Running",
      seats: subscriptionData?.currentPlan?.seats,
      price: subscriptionData?.currentPlan?.price,
    },
    seats: {
      used: subscriptionData?.seats?.used > 10 ? 1 : subscriptionData?.seats?.used,
      available: subscriptionData?.seats?.used > 10 ? 9 : 10 - subscriptionData?.seats?.used,
      total: 10,
    },
    userTrial: {
      isTrialStarted: false,
      isTrialCancelled: true,
      isTrialExpired: true,
    },
    subscriptionInfo: {
      isSubscribed: true,
      isUnsubscribed: false,
      isSubscribeResumed: false,
      isSubscribedExpired: false,
    },
  };

  const hanldeResumeSubscription = async () => {
    await updateSubscriptionData(subscriptionData?.id, resumeSubscriptionData, "Update");
    setShowResumeSubscriptionModal(false);
    setShowAfterResumeSubscriptionModal(true);
    await getSubscriptionByCompanyId(user?.company?.id);
  };

  return (
    <>
      <header
        style={{
          zIndex: "100",
        }}
        ref={wrapperRef}
      >
        <div>
          <Navbar color="solid" dark>
            {(user && isAuthenticated && user.isEmailVerified && user.company) ||
            (user && isAuthenticated && !user.isEmailVerified && user.company) ? (
              currentRole === "admin" ||
              (currentRole === "user" && !user.paymentPlan) ||
              currentRole === "user" ||
              (currentRole === "manager" && !user.paymentPlan) ? (
                <NavbarToggler onClick={toggleNavbar} className="d-flex align-items-center mr-2">
                  <img
                    src={currentRoute?.enableBack ? IconCloseDrawer : NavToggler}
                    alt="nav-toggler"
                    className={`${TEXT_DIRECTION() === "rtl" ? "rotate-image ml-1" : ""}`}
                  />

                  {currentRoute?.enableBack ? (
                    <small className="ml-2">
                      <div
                        onClick={() => {
                          patchForm(id, { ...newForm, isEditable: localStorage.getItem("formState") === "forEdit" ? true : false });
                          patchFormForNotification(id, {
                            oldForm: storedOldForm,
                            newForm: newForm,
                          }).then(() => {
                            localStorage.setItem("isEditOpen", null);
                            getForms({
                              limit: 10,
                              page: 1,
                              sortBy: "updatedAt:desc",
                              isArchived: false,
                            });
                          });
                        }}
                      >
                        <I18n lng="global.Save" /> <br></br>
                        <I18n lng="global.Back" />
                      </div>
                    </small>
                  ) : (
                    ""
                  )}
                </NavbarToggler>
              ) : null
            ) : null}
            <NavbarBrand href="/" className="mx-auto">
              <img src={BrandLogo} alt="Tamam Logo"></img>
            </NavbarBrand>
            <Collapse isOpen={!collapsed} navbar>
              <h4>
                {currentRole === "user" ? (
                  <I18n lng="global.TamamApp" />
                ) : currentRole === "manager" ? (
                  <I18n lng="global.BackOffice" />
                ) : (
                  <I18n lng="global.BackOffice" />
                )}
              </h4>
              <Nav navbar>
                <NavItem className={window.location.href.indexOf("/forms/form-management") > -1 ? "active" : ""}>
                  {currentRole !== "user" ? (
                    canAccessTheModule(
                      rolesAccess?.form?.selected,
                      <Link
                        disabled={isTrialExpired || isSubscriptionPaused}
                        style={{
                          pointerEvents: isTrialExpired || isSubscriptionPaused ? "none" : "auto",
                          color: isTrialExpired || isSubscriptionPaused ? "#4D82AF" : "inherit",
                          textDecoration: isTrialExpired || isSubscriptionPaused ? "none" : "inherit",
                        }}
                        to="/forms/form-management"
                        onClick={() => setCollapsed(true)}
                      >
                        <span
                          style={{
                            pointerEvents: isTrialExpired || isSubscriptionPaused ? "none" : "auto",
                            color: isTrialExpired || isSubscriptionPaused ? "#4D82AF" : "inherit",
                            textDecoration: isTrialExpired || isSubscriptionPaused ? "none" : "inherit",
                          }}
                          className="nav-item-icon"
                        >
                          <img src={isTrialExpired || isSubscriptionPaused ? FormExpiredIcon : IconForms} alt="IconForms" />
                        </span>
                        {i18n("global.Forms")}
                      </Link>
                    )
                  ) : (
                    <Link
                      style={{
                        pointerEvents: isTrialExpired || isSubscriptionPaused ? "none" : "auto",
                        color: isTrialExpired || isSubscriptionPaused ? "#4D82AF" : "inherit",
                        textDecoration: isTrialExpired || isSubscriptionPaused ? "none" : "inherit",
                      }}
                      to="/forms/form-management"
                      onClick={() => setCollapsed(true)}
                    >
                      <span
                        style={{
                          pointerEvents: isTrialExpired || isSubscriptionPaused ? "none" : "auto",
                          color: isTrialExpired || isSubscriptionPaused ? "#4D82AF" : "inherit",
                          textDecoration: isTrialExpired || isSubscriptionPaused ? "none" : "inherit",
                        }}
                        className="nav-item-icon"
                      >
                        <img src={isTrialExpired || isSubscriptionPaused ? FormExpiredIcon : IconForms} alt="IconForms" />
                      </span>
                      {i18n("global.Forms")}
                    </Link>
                  )}
                </NavItem>
                <NavItem className={window.location.href.indexOf("/reports") > -1 ? "active" : ""}>
                  {currentRole !== "user"
                    ? canAccessTheModule(
                        rolesAccess?.report?.selected,
                        <Link
                          style={{
                            pointerEvents: isTrialExpired || isSubscriptionPaused ? "none" : "auto",
                            color: isTrialExpired || isSubscriptionPaused ? "#4D82AF" : "inherit",
                            textDecoration: isTrialExpired || isSubscriptionPaused ? "none" : "inherit",
                          }}
                          className="nav-link"
                          to="/reports/my-reports"
                          onClick={() => {
                            setCollapsed(true);
                          }}
                        >
                          <span
                            style={{
                              pointerEvents: isTrialExpired || isSubscriptionPaused ? "none" : "auto",
                              color: isTrialExpired || isSubscriptionPaused ? "#4D82AF" : "inherit",
                              textDecoration: isTrialExpired || isSubscriptionPaused ? "none" : "inherit",
                            }}
                            className="nav-item-icon"
                          >
                            <img src={isTrialExpired || isSubscriptionPaused ? ReportExpiredIcon : IconReports} alt="IconReports" />
                          </span>
                          {i18n("global.Reports")}
                        </Link>
                      )
                    : canAccessTheModule(
                        rolesAccess?.inspector?.accessReports,
                        <Link
                          className="nav-link"
                          to="/reports/my-reports"
                          onClick={() => {
                            setCollapsed(true);
                          }}
                        >
                          <span className="nav-item-icon">
                            <img src={isTrialExpired || isSubscriptionPaused ? ReportExpiredIcon : IconReports} alt="IconReports" />
                          </span>
                          {i18n("global.Reports")}
                        </Link>
                      )}
                </NavItem>
                {currentRole !== "user" ? (
                  <NavItem className={window.location.href.indexOf("/management/info") > -1 ? "active" : ""}>
                    {canAccessTheModule(
                      rolesAccess?.company?.selected,
                      <Link
                        className="nav-link"
                        to="/management/info"
                        onClick={() => {
                          setCollapsed(true);
                        }}
                      >
                        <span className="nav-item-icon">
                          <img src={IconCompanySetting} alt="IconCompanySetting" />
                        </span>
                        {i18n("Company.CompanySetting")}
                      </Link>
                    )}
                  </NavItem>
                ) : null}
                {currentRole !== "user" && rolesAccess?.analytics?.selected && subscriptionData?.currentPlan?.plan !== "Free" ? (
                  <NavItem
                    className={window.location.href.indexOf("/analytics") > -1 ? "active" : ""}
                    onClick={() => {
                      getSession();
                    }}
                  >
                    {canAccessTheModule(
                      rolesAccess?.analytics?.selected,
                      <Link
                        style={{
                          pointerEvents: isTrialExpired || isSubscriptionPaused ? "none" : "auto",
                          color: isTrialExpired || isSubscriptionPaused ? "#C8C8C8" : "inherit",
                          textDecoration: isTrialExpired || isSubscriptionPaused ? "none" : "inherit",
                        }}
                        className="nav-link"
                        to={"/analytics"}
                        onClick={() => {
                          getSession();
                          setCollapsed(true);
                        }}
                      >
                        <span className="nav-item-icon">
                          <img
                            src={isTrialExpired || isSubscriptionPaused ? ReportExpiredAnalyticsIcon : HeaderAnaylyticsIcon}
                            alt="IconCompanySetting"
                          />
                        </span>
                        <span
                          style={{
                            pointerEvents: isTrialExpired || isSubscriptionPaused ? "none" : "auto",
                            color: isTrialExpired || isSubscriptionPaused ? "#4D82AF" : "inherit",
                            textDecoration: isTrialExpired || isSubscriptionPaused ? "none" : "inherit",
                          }}
                        >
                          {i18n("Analytics.Analytics")}
                        </span>
                      </Link>
                    )}
                  </NavItem>
                ) : null}
              </Nav>
              {user && isAuthenticated ? (
                <React.Fragment>
                  <Dropdown isOpen={false} toggle={toggle} className="user-avatar ">
                    <DropdownToggle>
                      <div className="d-flex  align-items-center">
                        <span className="avatar-wrap">
                          {user.profileImage ? (
                            <React.Fragment>
                              <img src={user.profileImage} alt="User Avatar" />
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <FaRegUserCircle size={40} />
                            </React.Fragment>
                          )}
                        </span>
                        <small className="ml-2 mr-2">
                          <strong>
                            {i18n("global.Hello")} {user.fullName}
                          </strong>
                        </small>
                      </div>
                    </DropdownToggle>
                  </Dropdown>
                  {dropdownOpen ? (
                    <>
                      {currentRole === "admin" ||
                      (currentRole === "user" && !user.paymentPlan) ||
                      currentRole === "user" ||
                      (currentRole === "manager" && !user.paymentPlan) ? (
                        <div
                          className={`d-flex cursor-pointer ${TEXT_DIRECTION() === "rtl" ? "mr-5" : "ml-5 "}`}
                          onClick={(e) => {
                            e.preventDefault();
                            setOpenUserProfile(true);
                            setCollapsed(true);
                          }}
                        >
                          <span className="icon-wrap">
                            <BsGear />
                          </span>
                          <span className="ml-2 mr-2">{i18n("global.Settings")}</span>
                        </div>
                      ) : null}

                      <div
                        className={`d-flex cursor-pointer ml-5 mt-2 ${TEXT_DIRECTION() === "rtl" ? "mr-5" : "ml-5 "}`}
                        onClick={(e) => {
                          e.preventDefault();
                          setCollapsed(true);
                          logout();
                          history.push("/login");
                        }}
                      >
                        <span className="icon-wrap">
                          <AiOutlineLogout />
                        </span>
                        <span className="ml-2 mr-2">{i18n("global.Logout")}</span>
                      </div>
                    </>
                  ) : null}

                  <div className={`d-flex align-items-center custom-help mt-3 ${TEXT_DIRECTION() === "rtl" ? "mr-3" : "ml-3"}`}>
                    <span className="icon-wrap">
                      <IoIosHelpCircle fill="white" size={22} />
                    </span>
                    <span className="ml-2 mr-2">{i18n("global.Help")}</span>
                  </div>

                  <hr style={{ background: "white" }} />
                  {/* Show when only in Free */}
                  {subscriptionData?.currentPlan?.plan === "Free" &&
                    subscriptionData?.userTrial?.isTrialStarted === false &&
                    subscriptionData?.subscriptionInfo?.isSubscribed === false &&
                    subscriptionData?.subscriptionInfo?.isUnsubscribed === false && (
                      <>
                        <div
                          style={{
                            width: "234px",
                            height: "48px",
                            backgroundColor: "#ffffff",
                            borderRadius: "25px",
                          }}
                        >
                          <p
                            onClick={() => {
                              subscriptionData?.userTrial?.isTrialCancelled === false
                                ? openUpdatePlanModal(TrySubscriptionForFreeData)
                                : setShowPricingPlanModal(true);
                            }}
                            style={{
                              cursor: "pointer",
                              color: "#9B51E0",
                              textAlign: "center",
                              paddingTop: "11px",
                              paddingleft: "32px",
                              fontWeight: "600",
                              fontSize: "16px",
                            }}
                          >
                            {subscriptionData?.userTrial?.isTrialCancelled === false ? (
                              <I18n lng="Subscriptions.TrySubscriptionForFree" />
                            ) : (
                              <I18n lng="Subscriptions.Subscribe" />
                            )}
                          </p>
                        </div>
                      </>
                    )}
                  {subscriptionData?.currentPlan?.plan === "Free" &&
                    subscriptionData?.userTrial?.isTrialStarted === false &&
                    subscriptionData?.subscriptionInfo?.isSubscribed === false &&
                    subscriptionData?.subscriptionInfo?.isUnsubscribed === true && (
                      <>
                        <div
                          style={{
                            width: "234px",
                            height: "48px",
                            backgroundColor: "#ffffff",
                            borderRadius: "25px",
                          }}
                        >
                          <p
                            onClick={() => {
                              setShowPricingPlanModal(true);
                            }}
                            style={{
                              cursor: "pointer",
                              color: "#9B51E0",
                              textAlign: "center",
                              paddingTop: "11px",
                              paddingleft: "32px",
                              fontWeight: "600",
                              fontSize: "16px",
                            }}
                          >
                            <I18n lng="Subscriptions.Subscribe" />
                          </p>
                        </div>
                      </>
                    )}

                  {/* Show when user is in Trial */}
                  {subscriptionData?.currentPlan?.plan === "Trial" &&
                    subscriptionData.currentPlan.status !== "Expired" &&
                    subscriptionData?.userTrial?.isTrialStarted === true &&
                    subscriptionData?.subscriptionInfo?.isSubscribed === false &&
                    subscriptionData?.subscriptionInfo?.isUnsubscribed === false && (
                      <>
                        <p
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            textAlign: "center",
                          }}
                        >
                          {subscriptionData?.userTrial?.isTrialExpired ? (
                            <I18n lng="Subscriptions.YourFreeTrialIsExpired" />
                          ) : (
                            <>
                              <I18n lng="Subscriptions.TheFreeTrialWillExpireIn" />
                              {daysDifference} <I18n lng="Subscriptions.days" />
                            </>
                          )}
                        </p>
                        <div
                          style={{
                            width: "234px",
                            height: "48px",
                            backgroundColor: "#ffffff",
                            borderRadius: "25px",
                          }}
                        >
                          <p
                            onClick={() => {
                              setShowPricingPlanModal(true);
                            }}
                            style={{
                              cursor: "pointer",
                              color: "#2d9cdb",
                              textAlign: "center",
                              paddingTop: "11px",
                              paddingleft: "32px",
                              fontWeight: "600",
                              fontSize: "16px",
                            }}
                          >
                            <I18n lng="Subscriptions.Subscribe" />
                          </p>
                        </div>
                      </>
                    )}
                  {subscriptionData?.currentPlan?.status === "Expired" && (
                    <>
                      <p
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          textAlign: "center",
                        }}
                      ></p>
                      <div
                        style={{
                          width: "234px",
                          height: "48px",
                          backgroundColor: "#ffffff",
                          borderRadius: "25px",
                        }}
                      >
                        <p
                          onClick={() => {
                            setShowPricingPlanModal(true);
                          }}
                          style={{
                            cursor: "pointer",
                            color: "#2d9cdb",
                            textAlign: "center",
                            paddingTop: "11px",
                            paddingleft: "32px",
                            fontWeight: "600",
                            fontSize: "16px",
                          }}
                        >
                          <I18n lng="Subscriptions.Subscribe" />
                        </p>
                      </div>
                    </>
                  )}
                  {/* When user Unsubscribes */}
                  {subscriptionData?.subscriptionInfo?.isSubscribed === true &&
                    subscriptionData?.subscriptionInfo?.isUnsubscribed === true &&
                    subscriptionData?.currentPlan?.status !== "Expired" && (
                      <>
                        <p
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            textAlign: "center",
                          }}
                        >
                          <I18n lng="Subscriptions.SubscriptionWillExpireIn" /> {durationInDays} <I18n lng="Subscriptions.days" />
                        </p>
                        <div
                          style={{
                            width: "234px",
                            height: "48px",
                            backgroundColor: "#ffffff",
                            borderRadius: "25px",
                          }}
                        >
                          <p
                            onClick={() => {
                              setShowResumeSubscriptionModal(true);
                            }}
                            style={{
                              cursor: "pointer",
                              color: "#2d9cdb",
                              textAlign: "center",
                              paddingTop: "11px",
                              paddingleft: "32px",
                              fontWeight: "600",
                              fontSize: "16px",
                            }}
                          >
                            <I18n lng="Subscriptions.ResumesSubscription" />
                          </p>
                        </div>
                      </>
                    )}
                </React.Fragment>
              ) : null}
            </Collapse>
            {user && isAuthenticated ? (
              <Dropdown
                isOpen={openNotification}
                toggle={() => {
                  setOpenNotification(true);
                }}
                className="notification"
              >
                <DropdownToggle>
                  <small>{unReadNotification && unReadNotification.length && unReadNotification.length > 0 ? unReadNotification.length : ""}</small>
                  {unReadNotification && unReadNotification.length ? <img src={BellIcon} alt="bell" /> : <BsBell strokeWidth={1} size={22} />}
                </DropdownToggle>
              </Dropdown>
            ) : null}
          </Navbar>
        </div>
      </header>
      {user && isAuthenticated && user.isEmailVerified && user.company ? (
        <div className="container d-none">
          <Breadcrumb tag="nav" listTag="div">
            <BreadcrumbItem tag="a" href="#">
              <I18n lng="global.InspectorPortal" />
            </BreadcrumbItem>
            {currentRoute.step1 ? (
              <BreadcrumbItem tag="a" href="#">
                {currentRoute.step1}
              </BreadcrumbItem>
            ) : null}
            {currentRoute.step2 ? (
              <BreadcrumbItem tag="a" href="#">
                {currentRoute.step2}
              </BreadcrumbItem>
            ) : null}
          </Breadcrumb>
        </div>
      ) : null}
      <CompanyUsersProfile showDlg={openUserProfile} secondaryAction={setOpenUserProfile} />
      <NotificationsDialog
        showDlg={openNotification}
        allNotifications={allNotifications}
        markedAsReadHandle={markedAsReadHandle}
        markedReadSingleHandler={markedReadSingleHandler}
        secondaryAction={() => setOpenNotification(false)}
      />

      {showUpdateSuccessModal && (
        <PlanDialog
          setShowDetailsDialog={setShowDetailsDialog}
          showModal={showUpdateSuccessModal}
          onClose={() => setShowUpdateSuccessModal(false)}
          modalTitle={updatePlanData.title}
          text_one={updatePlanData.text_one}
          text_two={updatePlanData.text_two}
          secondaryButtonLabel={updatePlanData.closeButtonText}
          secondaryButtonColor="#20639B"
          titleWidth={"124px"}
          titleHeight={"60px"}
          titleFontWeight={"600"}
          titleFontSize={"20px"}
          titleLineHeight={"20px"}
          titleColor={"#333333"}
          secondaryButtonWidth={"43px"}
          secondaryButtonHeight={"16px"}
          secondaryButtonFontWeight={"600"}
          secondaryButtonFontSize={"16px"}
          secondaryButtonLineHeight={"15.92px"}
        />
      )}

      {showUpdatePlanModal && (
        <PlanDialog
          StartFreeTrialHandler={StartFreeTrialHandler}
          subscriptionId={subscriptionData?.id}
          isTrialStarted={subscriptionData?.currentPlan?.isTrialStarted}
          showModal={showUpdatePlanModal}
          onClose={closeUpdatePlanModal}
          modalTitle={modalData?.title}
          text_one={modalData?.text_one}
          text_two={modalData?.text_two}
          primaryButtonLabel={modalData?.actionButtonText}
          secondaryButtonLabel={modalData?.closeButtonText}
          secondaryButtonColor="#828282"
          primaryButtonColor="#20639B"
          titleHeight={"40px"}
          titleFontSize={"20px"}
          titleFontWeight={"600"}
          secondaryButtonHeight={"16px"}
          secondaryButtonFontWeight={"600"}
          secondaryButtonFontSize={"16px"}
          secondaryButtonLineHeight={"15.92px"}
          primaryButtonHeight={"16px"}
          primaryButtonFontSize={"16px"}
          primaryButtonFontWeight={"600"}
        />
      )}

      {showPricingPlanModal && (
        <PricingPlanDialog
          subscriptionData={subscriptionData}
          showModal={showPricingPlanModal}
          onClose={() => {
            setShowPricingPlanModal(false);
          }}
          text_one={pricingPlanModalData.text_one}
          text_two={pricingPlanModalData.text_two}
          text_three={pricingPlanModalData.text_three}
          modalTitle={pricingPlanModalData.title}
          primaryButtonLabel={pricingPlanModalData.actionButtonText}
          secondaryButtonLabel={pricingPlanModalData.closeButtonText}
          secondaryButtonColor="#828282"
          setChargeBillingDetailsDailog={setChargeBillingDetailsDailog}
          setSelectedPlan={setSelectedPlan}
          selectedPlan={selectedPlan}
        />
      )}

      {showResumeSubscriptionModal && (
        <PlanDialog
          cancelSubscription={hanldeResumeSubscription}
          showModal={showResumeSubscriptionModal}
          onClose={() => setShowResumeSubscriptionModal(false)}
          modalTitle={resumeSubscriptionModalData.title}
          text_one={resumeSubscriptionModalData.text_one}
          secondaryButtonLabel={resumeSubscriptionModalData.closeButtonText}
          secondaryButtonColor="#828282"
          primaryButtonColor="#20639B"
          primaryButtonLabel={resumeSubscriptionModalData.actionButtonText}
          titleWidth={"220px"}
          titleHeight={"20px"}
          titleFontWeight={"600"}
          titleFontSize={"20px"}
          titleLineHeight={"20px"}
          titleColor={"#333333"}
          secondaryButtonWidth={"43px"}
          secondaryButtonHeight={"16px"}
          secondaryButtonFontWeight={"600"}
          secondaryButtonFontSize={"16px"}
          secondaryButtonLineHeight={"15.92px"}
          primaryButtonWidth={"168px"}
          primaryButtonHeight={"16px"}
          primaryButtonFontWeight={"600"}
          primaryButtonFontSize={"16px"}
          primaryButtonLineHeight={"15.92px"}
        />
      )}

      {showAfterResumeSubscriptionModal && (
        <PlanDialog
          showModal={showAfterResumeSubscriptionModal}
          onClose={() => setShowAfterResumeSubscriptionModal(false)}
          modalTitle={afterResumeSubscriptionModalData.title}
          text_one={afterResumeSubscriptionModalData.text_one}
          text_two={afterResumeSubscriptionModalData.text_two}
          secondaryButtonLabel={afterResumeSubscriptionModalData.closeButtonText}
          secondaryButtonColor="#20639B"
          titleWidth={"223px"}
          titleHeight={"40px"}
          titleFontWeight={"600"}
          titleFontSize={"20px"}
          titleLineHeight={"20px"}
          titleColor={"#333333"}
          secondaryButtonWidth={"43px"}
          secondaryButtonHeight={"16px"}
          secondaryButtonFontWeight={"600"}
          secondaryButtonFontSize={"16px"}
          secondaryButtonLineHeight={"15.92px"}
        />
      )}

      <ChargeBillingDetails
        subscriptionData={subscriptionData}
        show={chargeBillingDetailsDailog}
        onHide={() => setChargeBillingDetailsDailog(false)}
        selectedPlan={selectedPlan}
        setSelectedPlan={setSelectedPlan}
      />

      <SubscriptionsDetailDialog subscriptionData={subscriptionData} show={showDetailsDialog} onHide={() => setShowDetailsDialog(false)} />
    </>
  );
};

const mapStateToProps = ({ form, authentication, notifications, subscription }) => ({
  user: authentication.user,
  currentRole: authentication.currentRole,
  storedOldForm: form.storedOldForm,
  currentRoute: authentication.currentRoute,
  rolesAccess: authentication.userRoleAccess,
  isAuthenticated: authentication.isAuthenticated,
  allNotifications: notifications.allNotifications,
  subscriptionData: subscription.subscriptionData,
});

const mapDispatchToProps = {
  logout,
  getForm,
  getForms,
  patchForm,
  patchFormForNotification,
  changeCurrentRole,
  getAllNotifications,
  notificationReadSingle,
  notificationMarkedAsRead,
  updateSubscriptionData,
  getSubscriptionByCompanyId,
  getSession,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader);
