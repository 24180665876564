import toast from "react-hot-toast";
import ToggleSwitch from "../../../../../components/form-input/input.switch.component";
import I18n from "../../../languageSelector";
import { TEXT_DIRECTION } from "../../../../../helper/helperFunctions";

const ReportsEditFilterTitle = ({ reportSetting, setReportSettings }) => {
  const reportSettingHandler = (e) => {
    if (
      (reportSetting?.title?.siteName && reportSetting?.title?.inspectorName) ||
      (reportSetting?.title?.siteName && reportSetting?.title?.dateTime) ||
      (reportSetting?.title?.dateTime && reportSetting?.title?.inspectorName)
    ) {
      setReportSettings({
        ...reportSetting,
        title: { ...reportSetting.title, [e.target.id]: !e.target.selected },
      });
    } else if (!e.target.selected === true) {
      setReportSettings({
        ...reportSetting,
        title: { ...reportSetting.title, [e.target.id]: !e.target.selected },
      });
    } else {
      toast.error("minimum one option must be selected");
    }
  };

  return (
    <>
      <ul className="list-unstyled px-4 m-0 font-weight-bold">
        <li className="row mb-0">
          <div className={`${TEXT_DIRECTION() === "rtl" ? "" : "col-1 pl-1"}`}>
            <ToggleSwitch
              id="siteName"
              selected={reportSetting?.title?.siteName}
              checked={reportSetting?.title?.siteName}
              onChange={(e) => reportSettingHandler(e)}
            />
          </div>
          <div
            style={{ color: "#20639b" }}
            className={`${TEXT_DIRECTION() === "rtl" ? "col-4" : "col-4 pl-3"}`}
          >
            <label className="mb-0" style={{ color: "#20639b" }}>
              {<I18n lng="global.SiteName" />}
            </label>
          </div>
        </li>
        <li className="row mb-0">
          <div className={`${TEXT_DIRECTION() === "rtl" ? "" : "col-1 pl-1"}`}>
            <ToggleSwitch
              id="inspectorName"
              selected={reportSetting?.title?.inspectorName}
              checked={reportSetting?.title?.inspectorName}
              onChange={(e) => reportSettingHandler(e)}
            />
          </div>
          <div
            style={{ color: "#20639b" }}
            className={`${
              TEXT_DIRECTION() === "rtl" ? "col-6 " : "col-6 pl-3"
            }`}
          >
            <label style={{ color: "#20639b" }}>
              {<I18n lng="global.Inspector" />} {"  "} {"  "}{" "}
              <I18n lng="global.Name" />
            </label>
          </div>
        </li>
        <li className="row mb-0">
          <div className={`${TEXT_DIRECTION() === "rtl" ? "" : "col-1 pl-1"}`}>
            <ToggleSwitch
              id="dateTime"
              selected={reportSetting?.title?.dateTime}
              checked={reportSetting?.title?.dateTime}
              onChange={(e) => reportSettingHandler(e)}
            />
          </div>
          <div
            style={{ color: "#20639b" }}
            className={`${
              TEXT_DIRECTION() === "rtl" ? "col-6 " : "col-6 pl-3"
            }`}
          >
            <label style={{ color: "#20639b" }}>
              {<I18n lng="Form.Date" />}
            </label>
          </div>
        </li>
      </ul>
    </>
  );
};

export default ReportsEditFilterTitle;
