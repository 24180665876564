import toast from "react-hot-toast";
import ToggleSwitch from "../../../../../components/form-input/input.switch.component";
import { i18n } from "../../../languageSelector";

const ReportsEditFilterTitle = ({ reportSetting, setReportSettings }) => {
  const reportSettingHandler = (e) => {
    if (
      (reportSetting?.title?.siteName && reportSetting?.title?.inspectorName) ||
      (reportSetting?.title?.siteName && reportSetting?.title?.dateTime) ||
      (reportSetting?.title?.dateTime && reportSetting?.title?.inspectorName)
    ) {
      setReportSettings({
        ...reportSetting,
        title: { ...reportSetting.title, [e.target.id]: !e.target.selected },
      });
    } else if (!e.target.selected === true) {
      setReportSettings({
        ...reportSetting,
        title: { ...reportSetting.title, [e.target.id]: !e.target.selected },
      });
    } else {
      toast.error("minimum one option must be selected");
    }
  };

  return (
    <>
      <ul className="list-unstyled px-4 m-0 font-weight-bold">
        <li>
          <ToggleSwitch
            label={i18n("global.SiteName")}
            id="siteName"
            selected={reportSetting?.title?.siteName}
            checked={reportSetting?.title?.siteName}
            onChange={(e) => reportSettingHandler(e)}
          />
        </li>
        <li>
          <ToggleSwitch
            label={i18n("global.Inspector") + " " + i18n("global.Name")}
            id="inspectorName"
            selected={reportSetting?.title?.inspectorName}
            checked={reportSetting?.title?.inspectorName}
            onChange={(e) => reportSettingHandler(e)}
          />
        </li>
        <li>
          <ToggleSwitch
            label={i18n("Form.Date")}
            id="dateTime"
            selected={reportSetting?.title?.dateTime}
            checked={reportSetting?.title?.dateTime}
            onChange={(e) => reportSettingHandler(e)}
          />
        </li>
      </ul>
    </>
  );
};

export default ReportsEditFilterTitle;
