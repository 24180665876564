import toast from "react-hot-toast";
import { i18n } from "../pages/private/languageSelector";

export const notificationAlert = (payload, currentRole, user) => {
  if (
    payload &&
    payload?.event === "assigned" &&
    payload?.senderId !== user?.id
  ) {
    toast(i18n("global.YouHaveNewAssignForm"), {
      icon: "🔔",
      style: {
        borderRadius: "10px",
        background: "#333",
        color: "#fff",
      },
    });
  } else if (payload && payload?.event === "approved") {
    toast(i18n("global.YourReportIsApproved"), {
      icon: "✔️",
      style: {
        borderRadius: "10px",
        background: "#333",
        color: "#fff",
      },
    });
  } else if (payload && payload?.event === "rejected") {
    toast(i18n("global.YourReportIsRejected"), {
      icon: "❌",
      style: {
        borderRadius: "10px",
        background: "#333",
        color: "#fff",
      },
    });
  } else if (payload && payload?.event === "submit") {
    toast(i18n("global.NewReportReceived"), {
      icon: "⚠️",
      style: {
        borderRadius: "10px",
        background: "#333",
        color: "#fff",
      },
    });
  } else if (payload && payload?.event === "reSubmit") {
    toast(i18n("global.ReSubmitReportReceived"), {
      icon: "⚠️",
      style: {
        borderRadius: "10px",
        background: "#333",
        color: "#fff",
      },
    });
  } else if (payload && payload?.event === "incomplete") {
    toast(i18n("global.YourReportIsIncompleted"), {
      icon: "⚠️",
      style: {
        borderRadius: "10px",
        background: "#333",
        color: "#fff",
      },
    });
  } else if (
    payload &&
    payload?.event === "roleUpdate" &&
    currentRole === "user"
  ) {
    toast(i18n("global.YourRoleUpdated"), {
      icon: "⚠️",
      style: {
        borderRadius: "10px",
        background: "#333",
        color: "#fff",
      },
    });
  }
};
