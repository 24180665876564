import { SERVER_ENV } from "./constants";

/* eslint no-console: off */
// eslint-disable-next-line
export default () => (next) => (action) => {
  if (SERVER_ENV === "development") {
    const { type, payload, meta } = action;

    console.groupCollapsed(type);
    console.log("Payload:", payload);
    console.log("Meta:", meta);
    console.groupEnd();
  }

  return next(action);
};
