import React, { useState, useEffect } from "react";
import I18n from "../../../languageSelector";
import {
  numberFailHandler,
  TEMPERATURE_CONDITION,
  TEXT_DIRECTION,
} from "../../../../../helper/helperFunctions";
import ReportImages from "../report-photo-component/report-images";
import IconArrowDown from "../../../../../assets/images/arrow-down.svg";

const NumberReportPreview = ({
  getAnswer = (answer) => console.log(answer),
  getUpdatedAnswer = (answer) => console.log(answer),
  getOldAnswer = (answer) => console.log(answer),
  setShowQuestionPreview,
  question,
  reportHistory,
  showAndHide,
  setImageIndex,
  setShowImageSrc,
  setShowImageDialog,
  setShowSection,
  setTotalAnswered,
  imageCountData,
  failStatus,
}) => {
  const [note, setNote] = useState("");
  const [photo, setPhoto] = useState("");
  const [answerValue, setAnswerValue] = useState(undefined);
  const [oldAnswerValue, setOldAnswerValue] = useState(undefined);
  const [updatedAnswerValue, setUpdatedAnswerValue] = useState(undefined);
  const [oldNote, setOldNote] = useState("");
  const [updatedNote, setUpdatedNote] = useState("");
  const [oldPhoto, setOldPhoto] = useState("");
  const [updatedPhoto, setUpdatedPhoto] = useState("");
  const [imageCount, setImageCount] = useState(0);

  useEffect(() => {
    if (question && question.questionUuid) {
      let answer = getAnswer(question.questionUuid);
      if (answer) {
        setAnswerValue(answer.value);
        setPhoto(answer.image);
        setNote(answer.note);
        if (answer?.value || answer?.image || answer?.note) {
          setShowSection(true);
          setTotalAnswered((prev) => prev + 1);
        }
      }
      let oldAnswer = getOldAnswer(question.questionUuid);
      if (oldAnswer) {
        setOldAnswerValue(+oldAnswer.value);
        setOldPhoto(oldAnswer.image);
        setOldNote(oldAnswer.note);
      }
      let updatedAnswer = getUpdatedAnswer(question.questionUuid);
      if (updatedAnswer) {
        setUpdatedAnswerValue(+updatedAnswer.value);
        setUpdatedPhoto(updatedAnswer.image);
        setUpdatedNote(updatedAnswer.note);
      }
      let isTextChanged = false;
      let isImagesChanged = false;
      let isNotesChanged = false;

      if (oldAnswer?.value === updatedAnswer?.value) {
        isTextChanged = false;
      } else {
        isTextChanged = true;
      }
      if (oldAnswer?.note === updatedAnswer?.note) {
        isNotesChanged = false;
      } else {
        isNotesChanged = true;
      }
      if (oldAnswer?.image.length === updatedAnswer?.image.length) {
        let show = false;
        oldAnswer?.image.forEach((oldImageItem, index) => {
          if (oldImageItem.image !== updatedAnswer.image[index].image) {
            show = true;
          }
        });
        if (show) {
          isImagesChanged = true;
        }
      } else {
        isImagesChanged = true;
      }
      if (!isTextChanged && !isImagesChanged && !isNotesChanged) {
        setShowQuestionPreview(false);
      }
    }
    // eslint-disable-next-line
  }, [question]);

  useEffect(() => {
    if (question && question.questionUuid && imageCountData?.length) {
      let data = imageCountData.filter((item) => {
        return item.questionId === question.questionUuid;
      });
      if (data.length) {
        setImageCount(data[0].imageCountStart);
      }
    }
  }, [question, imageCountData]);
  return (
    <>
      {failStatus && numberFailHandler(question, answerValue) ? (
        <li
          className={`card ${
            answerValue && numberFailHandler(question, answerValue)
              ? "status-failed"
              : ""
          }`}
        >
          <div className={`list-item-detail`}>
            <p className="mt-0 mb-1">
              <I18n lng="Form.Question" /> # {question?.questionNumber}
            </p>
            <p className="question">
              {question?.settings?.questionText === "Add a new question" ? (
                <I18n lng="Form.AddANewQuestion" />
              ) : (
                question?.settings?.questionText
              )}
            </p>
            {question?.settings.numberType === "TEMPERATURE" ? (
              <div className="d-flex">
                <span
                  className={`${
                    numberFailHandler(question, answerValue)
                      ? "text-danger"
                      : "text-primary"
                  }`}
                  style={
                    TEXT_DIRECTION() === "rtl" ? { direction: "ltr" } : null
                  }
                >
                  <b>
                    {answerValue}{" "}
                    {question?.settings?.measurement === "FAHRENHEIT"
                      ? "˚F  "
                      : "˚C "}{" "}
                  </b>
                </span>
                <p
                  style={{
                    fontSize: "14px",
                    color: "#828282",
                    margin: "0px 7px",
                  }}
                >
                  {TEMPERATURE_CONDITION(question)}
                </p>
              </div>
            ) : (
              <p className="answer">
                <span
                  className={`${
                    numberFailHandler(question, answerValue)
                      ? "text-danger"
                      : "text-primary"
                  }`}
                >
                  {answerValue}
                </span>
              </p>
            )}

            {note && (
              <div className="notes">
                <label>
                  <I18n lng="global.Notes" />:
                </label>
                <p>{note}</p>
              </div>
            )}
            <div className="thumbnail-wrapper">
              <ReportImages
                photo={photo}
                imageCount={imageCount}
                setImageIndex={setImageIndex}
                setShowImageSrc={setShowImageSrc}
                setShowImageDialog={setShowImageDialog}
              />
            </div>
          </div>
        </li>
      ) : (
        ""
      )}
      <li
        className={`card ${
          answerValue && numberFailHandler(question, answerValue)
            ? "status-failed"
            : ""
        }`}
      >
        {reportHistory ? (
          updatedAnswerValue || oldAnswerValue ? (
            <div className="list-item-detail">
              <p className="mt-0 mb-0">
                <I18n lng="Form.Question" /> # {question?.questionNumber}
              </p>
              <p className="question">
                {question?.settings?.questionText === "Add a new question" ? (
                  <I18n lng="Form.AddANewQuestion" />
                ) : (
                  question?.settings?.questionText
                )}
              </p>

              <p className="pAnswer">
                {" "}
                <I18n lng="global.PreviousAnswer" />
              </p>
              <div className="previous-Answer">
                {question?.settings.numberType === "TEMPERATURE" ? (
                  <p className="answer">
                    <span
                      className={`${
                        answerValue > question?.settings?.max
                          ? "text-danger"
                          : "text-primary"
                      }`}
                    >
                      {oldAnswerValue}
                      {question?.settings?.measurement === "FAHRENHEIT"
                        ? "˚F"
                        : "˚C"}{" "}
                    </span>
                    <I18n lng="global.ShouldBe" />{" "}
                    {question?.settings?.comparision} {question?.settings?.min}
                    {question?.settings?.measurement === "FAHRENHEIT"
                      ? "˚F"
                      : "˚C"}{" "}
                    to {question?.settings?.max}{" "}
                    {question?.settings?.measurement === "FAHRENHEIT"
                      ? "˚F"
                      : "˚C"}
                  </p>
                ) : (
                  <p className="answer">
                    <span
                      className={`${
                        answerValue > question?.settings?.max
                          ? "text-danger"
                          : "text-primary"
                      }`}
                    >
                      {oldAnswerValue}
                    </span>
                  </p>
                )}
                {oldNote && (
                  <div className="notes">
                    <label>
                      <I18n lng="global.Notes" />:
                    </label>
                    <p>{oldNote}</p>
                  </div>
                )}
                {oldPhoto.length ? (
                  <div className="thumbnail-wrapper">
                    <ReportImages
                      photo={oldPhoto}
                      setImageIndex={setImageIndex}
                      setShowImageSrc={setShowImageSrc}
                      setShowImageDialog={setShowImageDialog}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="text-center mt-2">
                <img src={IconArrowDown} alt="loading" />
              </div>
              <p className="uAnswer mt-3">
                <I18n lng="global.UpdatedAnswer" />
              </p>
              <div className="updated-Answer mb-4">
                {question?.settings.numberType === "TEMPERATURE" ? (
                  <p className="answer">
                    <span
                      className={`${
                        answerValue > question?.settings?.max
                          ? "text-danger"
                          : "text-primary"
                      }`}
                    >
                      {updatedAnswerValue}
                      {question?.settings?.measurement === "FAHRENHEIT"
                        ? "˚F"
                        : "˚C"}{" "}
                    </span>
                    <I18n lng="global.PreviousAnswer" />{" "}
                    {question?.settings?.comparision} {question?.settings?.min}
                    {question?.settings?.measurement === "FAHRENHEIT"
                      ? "˚F"
                      : "˚C"}{" "}
                    to {question?.settings?.max}{" "}
                    {question?.settings?.measurement === "FAHRENHEIT"
                      ? "˚F"
                      : "˚C"}
                  </p>
                ) : (
                  <p className="answer">
                    <span
                      className={`${
                        answerValue > question?.settings?.max
                          ? "text-danger"
                          : "text-primary"
                      }`}
                    >
                      {updatedAnswerValue}
                    </span>
                  </p>
                )}
                {updatedNote && (
                  <div className="notes">
                    <label>
                      <I18n lng="global.Notes" />:
                    </label>
                    <p>{updatedNote}</p>
                  </div>
                )}
                {updatedPhoto.length ? (
                  <div className="thumbnail-wrapper">
                    <ReportImages
                      photo={updatedPhoto}
                      setImageIndex={setImageIndex}
                      setShowImageSrc={setShowImageSrc}
                      setShowImageDialog={setShowImageDialog}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : null
        ) : showAndHide?.content?.all ? (
          <div className={`list-item-detail`}>
            <p className="mt-0 mb-1">
              <I18n lng="Form.Question" /> # {question?.questionNumber}
            </p>
            <p className="question">
              {question?.settings?.questionText === "Add a new question" ? (
                <I18n lng="Form.AddANewQuestion" />
              ) : (
                question?.settings?.questionText
              )}
            </p>
            {question?.settings.numberType === "TEMPERATURE" ? (
              <div className="d-flex">
                <span
                  className={`${
                    numberFailHandler(question, answerValue)
                      ? "text-danger"
                      : "text-primary"
                  }`}
                  style={
                    TEXT_DIRECTION() === "rtl" ? { direction: "ltr" } : null
                  }
                >
                  <b>
                    {answerValue}{" "}
                    {question?.settings?.measurement === "FAHRENHEIT"
                      ? "˚F  "
                      : "˚C "}{" "}
                  </b>
                </span>
                <p
                  style={{
                    fontSize: "14px",
                    color: "#828282",
                    margin: "0px 7px",
                  }}
                >
                  {TEMPERATURE_CONDITION(question)}
                </p>
              </div>
            ) : (
              <p className="answer">
                <span
                  className={`${
                    numberFailHandler(question, answerValue)
                      ? "text-danger"
                      : "text-primary"
                  }`}
                >
                  {answerValue}
                </span>
              </p>
            )}

            {note && (
              <div className="notes">
                <label>
                  <I18n lng="global.Notes" />:
                </label>
                <p>{note}</p>
              </div>
            )}
            <div className="thumbnail-wrapper">
              <ReportImages
                photo={photo}
                imageCount={imageCount}
                setImageIndex={setImageIndex}
                setShowImageSrc={setShowImageSrc}
                setShowImageDialog={setShowImageDialog}
              />
            </div>
          </div>
        ) : showAndHide?.content?.answered &&
          (answerValue || note || photo?.length) ? (
          <div className="list-item-detail">
            <p className="question">
              {question?.settings?.questionText === "Add a new question" ? (
                <I18n lng="Form.AddANewQuestion" />
              ) : (
                question?.settings?.questionText
              )}
            </p>
            {question?.settings.numberType === "TEMPERATURE" ? (
              <div className="d-flex">
                <span
                  className={`${
                    numberFailHandler(question, answerValue)
                      ? "text-danger"
                      : "text-primary"
                  }`}
                  style={
                    TEXT_DIRECTION() === "rtl" ? { direction: "ltr" } : null
                  }
                >
                  <b>
                    {answerValue}{" "}
                    {question?.settings?.measurement === "FAHRENHEIT"
                      ? "˚F  "
                      : "˚C "}{" "}
                  </b>
                </span>
                <p
                  style={{
                    fontSize: "14px",
                    color: "#828282",
                    margin: "0px 7px",
                  }}
                >
                  {TEMPERATURE_CONDITION(question)}
                </p>
              </div>
            ) : (
              <p className="answer">
                <span
                  className={`${
                    numberFailHandler(question, answerValue)
                      ? "text-danger"
                      : "text-primary"
                  }`}
                >
                  {answerValue}
                </span>
              </p>
            )}

            {note && (
              <div className="notes">
                <label>
                  <I18n lng="global.Notes" />:
                </label>
                <p>{note}</p>
              </div>
            )}
            <div className="thumbnail-wrapper">
              <ReportImages
                photo={photo}
                imageCount={imageCount}
                setImageIndex={setImageIndex}
                setShowImageSrc={setShowImageSrc}
                setShowImageDialog={setShowImageDialog}
              />
            </div>
          </div>
        ) : showAndHide?.content?.unAnswered &&
          !answerValue &&
          !note &&
          !photo?.length ? (
          <div className="list-item-detail">
            <p className="question">
              {question?.settings?.questionText === "Add a new question" ? (
                <I18n lng="Form.AddANewQuestion" />
              ) : (
                question?.settings?.questionText
              )}
            </p>
            {question?.settings.numberType === "TEMPERATURE" ? (
              <div className="d-flex">
                <span
                  className={`${
                    numberFailHandler(question, answerValue)
                      ? "text-danger"
                      : "text-primary"
                  }`}
                  style={
                    TEXT_DIRECTION() === "rtl" ? { direction: "ltr" } : null
                  }
                >
                  <b>
                    {answerValue}{" "}
                    {question?.settings?.measurement === "FAHRENHEIT"
                      ? "˚F  "
                      : "˚C "}{" "}
                  </b>
                </span>
                <p
                  style={{
                    fontSize: "14px",
                    color: "#828282",
                    margin: "0px 7px",
                  }}
                >
                  {TEMPERATURE_CONDITION(question)}
                </p>
              </div>
            ) : (
              <p className="answer">
                <span
                  className={`${
                    numberFailHandler(question, answerValue)
                      ? "text-danger"
                      : "text-primary"
                  }`}
                >
                  {answerValue}
                </span>
              </p>
            )}

            {note && (
              <div className="notes">
                <label>
                  <I18n lng="global.Notes" />:
                </label>
                <p>{note}</p>
              </div>
            )}
            <div className="thumbnail-wrapper">
              <ReportImages
                photo={photo}
                imageCount={imageCount}
                setImageIndex={setImageIndex}
                setShowImageSrc={setShowImageSrc}
                setShowImageDialog={setShowImageDialog}
              />
            </div>
          </div>
        ) : null}
      </li>
    </>
  );
};
export default NumberReportPreview;
