import moment from "moment";
import { i18n } from "../pages/private/languageSelector";
import { TEXT_DIRECTION } from "./helperFunctions";

export const getFormLastModifiedDate = (udpatedAt, type) => {
  const duration = getDurationBetweenTwoDates(udpatedAt, new Date());
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();
  const formatedDuration = days
    ? `${days}d`
    : hours
    ? `${hours}h`
    : minutes
    ? `${minutes}m`
    : seconds
    ? `${seconds}s`
    : "";
  return formatedDuration
    ? `${
        type === "archived"
          ? i18n("global.Archived")
          : i18n("global.LastModifed")
      }  ${formatedDuration} ${i18n("global.Ago")}`
    : "";
};

export const getNotificationTime = (time) => {
  const duration = getDurationBetweenTwoDates(time, new Date());
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();
  const formatedDuration = days
    ? `${days}d`
    : hours
    ? `${hours}h`
    : minutes
    ? `${minutes}m`
    : seconds
    ? `${seconds}s`
    : "";
  return formatedDuration && TEXT_DIRECTION() === "rtl"
    ? `${i18n("global.Ago")}  ${formatedDuration}`
    : `${formatedDuration} ${i18n("global.Ago")}`;
};

const getDurationBetweenTwoDates = (startDateTime, endDateTime) => {
  return moment.duration(moment(endDateTime).diff(startDateTime));
};
