/** @format */

import { useEffect, useState } from "react";
import { FaSortAlphaDown, FaSortAlphaUp } from "react-icons/fa";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import IconFilter from "../../../../../assets/images/icon-filter.svg";
import IconSearch from "../../../../../assets/images/icon-search.svg";
import IconSortAlpha from "../../../../../assets/images/icon-sort-alpha.svg";
import DialogModal from "../../../../../components/dialogs/compact-dialog";
import { TEXT_DIRECTION } from "../../../../../helper/helperFunctions";
import { getSubscriptionByCompanyId, updateSubscriptionData } from "../../../../../redux/reducers/subscription/subscription.reducer";
import { getAllUsers } from "../../../../../redux/reducers/user/user.reducer";
import I18n, { i18n } from "../../../languageSelector";
import FilterDialog from "./users-filter-dialog";
import UsersList from "./users-list-components/list-item";
import PlanDialog from "../../../../../components/dialogs/PlanDialog";
import SubscriptionsDetailDialog from "../subscriptions/subscriptions-details";

const formFilterInitState = {
  role: "",
  active: "",
  isEmailVerified: "",
};

const UsersView = ({ subscriptionData, allUsers, getAllUsers, user, currentRole, getSubscriptionByCompanyId, updateSubscriptionData }) => {
  const history = useHistory();

  const [searchValue, setSearchValue] = useState("");
  const [showFilterDlg, setShowFilterDlg] = useState(false);
  const [showSortModal, setShowSortModal] = useState(false);
  const [sortBy, setSortBy] = useState({
    attribute: "USER_NAME",
    order: "ASC",
  });
  const [formFilterState, setFormFilterState] = useState(formFilterInitState);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [showStartFreeTrialModal, setShowTrialFreeTrialModal] = useState(false);
  const [showCreateNewUserModal, setShowCreateNewUserModal] = useState(false);
  const [showSubscribeModalAfterTenUser, setShowSubsribeModalAfterTenUser] = useState(false);
  const [showDetailsDialog, setShowDetailsDialog] = useState(false);

  useEffect(() => {
    if (currentRole !== "user") {
      getAllUsers();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (user?.company) {
      getSubscriptionByCompanyId(user?.company?.id);
    }
  }, [user?.company.id, getSubscriptionByCompanyId, user]);

  const searchSortFilter = (users) => {
    let toReturn = [...users];
    if (searchValue) {
      toReturn = [
        ...toReturn.filter(
          (item) =>
            item.fullName?.toLowerCase().includes(searchValue?.toLowerCase()) ||
            item.email?.toLowerCase().includes(searchValue?.toLowerCase()) ||
            item.mobile?.toLowerCase().includes(searchValue?.toLowerCase())
        ),
      ];
    }

    toReturn = [
      ...toReturn.sort(function (a, b) {
        if (sortBy.attribute === "USER_NAME") {
          return (a.firstName === b.firstName ? 0 : a.firstName < b.firstName ? -1 : 1) * (sortBy.order === "ASC" ? 1 : -1);
        } else if (sortBy.attribute === "DATE_CREATED") {
          return (
            (a.createdAt.toString() === b.createdAt.toString() ? 0 : a.createdAt.toString() < b.createdAt.toString() ? -1 : 1) *
            (sortBy.order === "ASC" ? 1 : -1)
          );
        } else {
          return (
            (a.updatedAt.toString() === b.updatedAt.toString() ? 0 : a.updatedAt.toString() < b.updatedAt.toString() ? -1 : 1) *
            (sortBy.order === "ASC" ? 1 : -1)
          );
        }
      }),
    ];
    return toReturn;
  };

  const formFilterHandler = () => {
    getAllUsers(formFilterState).then(() => {
      setShowFilterDlg(false);
    });
  };

  const freeTrialData = {
    title: <I18n lng="global.SubscriptionRequired" />,
    text_one: <I18n lng="Subscriptions.YourCurrentPlanIsFreeYouAreCannotAddingElevenUser" />,
    text_two: <I18n lng="Subscriptions.StartYour30dayFreeTrialWithNoCreditCardRequired" />,
    closeButtonText: <I18n lng="global.Close" />,
    actionButtonText: <I18n lng="global.StartFreeTrial" />,
  };

  const createNewUserTrialStartedData = {
    title: <I18n lng="Subscriptions.UpdatePlanTrialStartedModalTitle" />,
    text_one: <I18n lng="Subscriptions.YourTrialHasStartedSuccessfullyYouCanAddMoreUser" />,
    actionButtonText: i18n("Subscriptions.CreateNewUser"),
    closeButtonText: <I18n lng="global.Close" />,
  };

  const showSubscribeModalAfterTenUserData = {
    title: <I18n lng="global.SubscriptionRequired" />,
    text_one: <I18n lng="Subscriptions.YourCurrentPlanIsFreeYouAreCannotAddingElevenUser" />,
    primaryButtonLabel: i18n("Subscriptions.Subscribe"),
    secondaryButtonLabel: <I18n lng="global.Close" />,
  };

  const startTrialData = {
    currentPlan: {
      plan: "Trial",
      status: "Running",
      seats: subscriptionData?.seats?.used,
      price: 0,
    },
    seats: {
      used: subscriptionData?.seats?.used,
      available: 0,
      total: subscriptionData?.seats?.used,
    },
    userTrial: {
      isTrialStarted: true,
      isTrialCancelled: false,
      isTrialExpired: false,
    },
  };

  const StartFreeTrialHandler = async () => {
    const responseData = await updateSubscriptionData(subscriptionData?.id, startTrialData, "Update");
    await getSubscriptionByCompanyId(user?.company?.id);
    if (responseData?.value?.data?.userTrial?.isTrialStarted) {
      setShowCreateNewUserModal(true);
      setShowTrialFreeTrialModal(false);
    }
  };

  const UserFreePlan = subscriptionData?.currentPlan?.plan === "Free" && subscriptionData?.userTrial?.isTrialCancelled === false;

  const cancelTrialCondition = subscriptionData?.currentPlan?.plan === "Free" && subscriptionData?.userTrial?.isTrialCancelled === true;

  const handleAddUser = async (e) => {
    const response = await getSubscriptionByCompanyId(user?.company?.id);
    if (response) {
      if (UserFreePlan && response?.value?.data?.seats?.available === 0) {
        setShowTrialFreeTrialModal(true);
      } else if (cancelTrialCondition && response?.value?.data?.seats?.available === 0) {
        setShowSubsribeModalAfterTenUser(true);
      } else {
        e.preventDefault();
        history.push(`/management/users/add`);
      }
    }
  };

  return (
    <>
      <div className="px-3">
        <div className="search-bar px-4 ">
          <div className="d-flex flex-column search-bar-right-side">
            <span className="search-bar-right-side-heading-one">
              {subscriptionData?.seats?.available} <I18n lng="Subscriptions.AvailableSeatsForUser" />
            </span>
            <span className="search-bar-right-side-heading-two">
              {subscriptionData?.seats?.used} <I18n lng="Subscriptions.Used" /> / {subscriptionData?.seats.total}{" "}
              <I18n lng="Subscriptions.TotalSeatsForUser" />
            </span>
          </div>
          <div className="d-flex justify-content-center align-items-center search-bar-left-side">
            <Link to="#" onClick={() => setShowSortModal(true)}>
              <img src={IconSortAlpha} alt="IconSortAlpha" />
            </Link>
            <Link to="#" onClick={() => setShowFilterDlg(true)}>
              <img src={IconFilter} alt="IconFilter" />
            </Link>
            <div className="ml-1 search-bar-search-icon" onClick={() => setIsSearchOpen(!isSearchOpen)}>
              <img src={IconSearch} alt="Search Icon" />
            </div>
          </div>
        </div>

        {isSearchOpen && (
          <form className="form-search d-flex justify-content-center">
            <div className="input-group" style={{ width: "440px" }}>
              <div className="input-group-prepend">
                <img src={IconSearch} alt="Search Icon" />
              </div>
              <input
                className="form-control"
                placeholder={i18n("global.Search")}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
              />
            </div>
          </form>
        )}

        <ul className="form-list su-list">
          {searchSortFilter(allUsers).map((user, index) => {
            return <UsersList user={user} key={index} />;
          })}
        </ul>
        <Link
          to="#"
          onClick={handleAddUser}
          className={`btn btn-rounded btn-add-new ${TEXT_DIRECTION() === "rtl" ? "left-direction" : "right-direction"}`}
        >
          <I18n lng="Company.AddUser" />
        </Link>
      </div>
      <DialogModal
        showDlg={showSortModal}
        classes="modal-sort"
        content={
          <ul className="list-unstyled">
            <li
              onClick={() => {
                if (sortBy.attribute === "USER_NAME") {
                  if (sortBy.order === "ASC") {
                    setSortBy({ attribute: "USER_NAME", order: "DESC" });
                  } else {
                    setSortBy({ attribute: "USER_NAME", order: "ASC" });
                  }
                } else {
                  setSortBy({ attribute: "USER_NAME", order: "ASC" });
                }
              }}
            >
              <span className="icon-wrap">
                {sortBy.attribute === "USER_NAME" ? sortBy.order === "ASC" ? <FaSortAlphaDown /> : <FaSortAlphaUp /> : null}
              </span>
              <strong>
                <I18n lng="global.UserName" />
              </strong>
            </li>
          </ul>
        }
        primaryActionTitle={"Apply"}
        secondaryAction={() => setShowSortModal(false)}
        primaryAction={() => setShowSortModal(false)}
      />
      <FilterDialog
        show={showFilterDlg}
        primaryAction={formFilterHandler}
        secondaryAction={() => setShowFilterDlg(false)}
        secondryActionTitle={"Clear"}
        filterState={formFilterState}
        setFilterState={setFormFilterState}
        filterInitState={formFilterInitState}
        clearActions={() => {
          getAllUsers();
          setFormFilterState({ ...formFilterInitState });
          setShowFilterDlg(false);
        }}
      />

      {showSubscribeModalAfterTenUser && (
        <PlanDialog
          setShowDetailsDialog={setShowDetailsDialog}
          showModal={showSubscribeModalAfterTenUser}
          onClose={() => setShowSubsribeModalAfterTenUser(false)}
          modalTitle={showSubscribeModalAfterTenUserData.title}
          text_one={showSubscribeModalAfterTenUserData.text_one}
          primaryButtonLabel={showSubscribeModalAfterTenUserData.primaryButtonLabel}
          secondaryButtonLabel={showSubscribeModalAfterTenUserData.secondaryButtonLabel}
          secondaryButtonColor="#828282"
          primaryButtonColor="#20639B"
          titleWidth={"221px"}
          titleHeight={"20px"}
          titleFontWeight={"600"}
          titleFontSize={"20px"}
          titleLineHeight={"20px"}
          titleColor={"#333333"}
          secondaryButtonWidth={"43px"}
          secondaryButtonHeight={"16px"}
          secondaryButtonFontWeight={"600"}
          secondaryButtonFontSize={"16px"}
          secondaryButtonLineHeight={"15.92px"}
          primaryButtonWidth={"78px"}
          primaryButtonHeight={"16px"}
          primaryButtonFontWeight={"600"}
          primaryButtonFontSize={"16px"}
          primaryButtonLineHeight={"15.92px"}
        />
      )}

      {showStartFreeTrialModal && (
        <PlanDialog
          StartFreeTrialHandler={StartFreeTrialHandler}
          showModal={showStartFreeTrialModal}
          onClose={() => setShowTrialFreeTrialModal(false)}
          modalTitle={freeTrialData.title}
          text_one={freeTrialData.text_one}
          text_two={freeTrialData.text_two}
          primaryButtonLabel={freeTrialData.actionButtonText}
          secondaryButtonLabel={freeTrialData.closeButtonText}
          secondaryButtonColor="#828282"
          primaryButtonColor="#20639B"
          titleWidth={"221px"}
          titleHeight={"20px"}
          titleFontWeight={"600"}
          titleFontSize={"20px"}
          titleLineHeight={"20px"}
          titleColor={"#333333"}
          secondaryButtonWidth={"43px"}
          secondaryButtonHeight={"16px"}
          secondaryButtonFontWeight={"600"}
          secondaryButtonFontSize={"16px"}
          secondaryButtonLineHeight={"15.92px"}
          primaryButtonWidth={"114px"}
          primaryButtonHeight={"16px"}
          primaryButtonFontWeight={"600"}
          primaryButtonFontSize={"16px"}
          primaryButtonLineHeight={"15.92px"}
        />
      )}

      {showCreateNewUserModal && (
        <PlanDialog
          showModal={showCreateNewUserModal}
          onClose={() => setShowCreateNewUserModal(false)}
          modalTitle={createNewUserTrialStartedData.title}
          text_one={createNewUserTrialStartedData.text_one}
          text_two={createNewUserTrialStartedData.text_two}
          secondaryButtonLabel={createNewUserTrialStartedData.closeButtonText}
          primaryButtonLabel={createNewUserTrialStartedData.actionButtonText}
          secondaryButtonColor="#20639B"
          primaryButtonColor="#20639B"
          titleWidth={"124px"}
          titleHeight={"60px"}
          titleFontWeight={"600"}
          titleFontSize={"20px"}
          titleLineHeight={"20px"}
          secondaryButtonWidth={"43px"}
          secondaryButtonHeight={"16px"}
          secondaryButtonFontWeight={"600"}
          secondaryButtonFontSize={"16px"}
          secondaryButtonLineHeight={"15.92px"}
          primaryButtonWidth={"134px"}
          primaryButtonHeight={"16px"}
          primaryButtonFontWeight={"600"}
          primaryButtonFontSize={"16px"}
          primaryButtonLineHeight={"15.92px"}
        />
      )}

      <SubscriptionsDetailDialog
        subscriptionData={subscriptionData}
        show={showDetailsDialog}
        onHide={() => setShowDetailsDialog(false)}
        fromUsers={true}
      />
    </>
  );
};

const mapStateToProps = ({ user, authentication, subscription }) => ({
  allUsers: user.allUsers,
  user: authentication.user,
  currentRole: authentication.currentRole,
  subscriptionData: subscription.subscriptionData,
});
const mapDispatchToProps = {
  getAllUsers,
  getSubscriptionByCompanyId,
  updateSubscriptionData,
};
export default connect(mapStateToProps, mapDispatchToProps)(UsersView);
