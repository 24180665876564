import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import english from "number-to-english";
import n2words from "rosaenlg-n2words";
import { numberToArabic } from "number-to-arabic";
import PagePreview from "./page-preview";
import { useForm } from "react-hook-form";
import { FaCaretDown } from "react-icons/fa";
import Select, { components } from "react-select";
import TitleSignaturePad from "./titleSignaturePad";
import I18n, { i18n } from "../../../languageSelector";
import { useHistory, useParams } from "react-router-dom";
import DynamicModal from "../../../../../components/dialogs/index";
import Input from "../../../../../components/form-input/input.component";
import IconDocBoard from "../../../../../assets/images/icon-doc-board.svg";
import { getAllSites } from "../../../../../redux/reducers/sites/sites.reducer";
import AlertSigupDialog from "../../../../../components/dialogs/alertSignupDialog";
import ConfirmationDialog from "../../../../../components/dialogs/confirmation-dialog";
import {
  formManagementById,
  addUpdateFormAnswer,
  getAssignedFormsForUser,
  getFormManagementAnswers,
  updateFormManagementById,
  updateFormManagementStatus,
  getForms,
} from "../../../../../redux/reducers/form/form.reducer";
import {
  updateReportSite,
  getAdminManagerReports,
  updateReportAnswersById,
} from "../../../../../redux/reducers/admin-manager-reports/admin-manager-report.reducer";
import {
  navigatingToQuestion,
  mandatoryAnswerHandler,
  mandatoryQuestionHandler,
  GET_USER_LOCATION,
  TEXT_DIRECTION,
} from "../../../../../helper/helperFunctions";

const FormPreview = ({
  showDlg,
  secondaryAction,
  description,
  title,
  user,
  conductedOn,
  preparedBy,
  documentNo,
  schema,
  currentRole,
  addUpdateFormAnswer,
  getFormManagementAnswers,
  formAnswers,
  getAssignedFormsForUser,
  view,
  reportFormAnswers,
  reportEdit,
  updateReportAnswersById,
  getForms,
  getAdminManagerReports,
  getAllSites,
  updateFormManagementById,
  sites,
  sigValue,
  reportForEdit,
  site,
  signatureName,
  filterState,
  updateFormManagementStatus,
  formManagementById,
  inspectionNumber,
  formSchemaQuestions,
  preview,
  updateReportSite,
}) => {
  const history = useHistory();
  const { register } = useForm();
  const { dispatchFormId, id } = useParams();

  const [show, setShow] = useState(false);
  const [answers, setAnswers] = useState([]);
  const [previewAnswers, setPreviewAnswers] = useState([]);
  const [totalAnswerScore, setTotalAnswerScore] = useState(0);
  const [selectFilterState, setSelectFilterState] = useState("");
  const [totalQuestionScore, setTotalQuestionScore] = useState(0);
  const [answerScorePreview, setAnswerScorePreview] = useState(0);
  const [questionScorePreview, setQuestionScorePreview] = useState(0);
  const [filterSectionPage, setFilterSectionPage] = useState("Title Page");
  const [selectFilterName, setSelectFilterName] = useState("Title Page");
  const [isFormLoading, setIsFormLoading] = useState(true);
  const [siteSelection, setSiteSelection] = useState();
  const [totalQuestion, setTotalQuestion] = useState(0);
  const [inspecterNumber, setInspecterNumber] = useState("");
  const [alertDialog, setAlertDialog] = useState(false);
  const [mandatoryIds, setMandatoryIds] = useState([]);
  const [mandatoryDialog, setMandatoryDialog] = useState(false);
  const [mandatoryAnswered, setMandatoryAnswered] = useState(0);
  const [mandatoryQuestions, setMandatoryQuestions] = useState(0);
  const [formDeletedDialog, setFormDeletedDialog] = useState(false);
  const [showFormDetail, setShowFormDetail] = useState(false);
  const [location, setLocation] = useState({});

  let arabicConvertedNumber = n2words(mandatoryQuestions - mandatoryAnswered, {
    lang: "ar",
  });
  let arabicNumber = numberToArabic(mandatoryQuestions - mandatoryAnswered);

  let englishConvertedNumber = english(mandatoryQuestions - mandatoryAnswered);

  const scrollToPageTop = () => {
    if (
      document.getElementsByClassName("modal-body") &&
      document.getElementsByClassName("modal-body").length > 0
    ) {
      setTimeout(() => {
        document.getElementsByClassName("modal-body")[0].scrollTo(0, 0);
      }, 500);
    }
  };

  useEffect(() => {
    setIsFormLoading(true);
    getAllSites();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setShow(showDlg);
    setPreviewAnswers([]);
    if (showDlg) {
      scrollToPageTop();
    }
    // eslint-disable-next-line
  }, [showDlg]);

  useEffect(() => {
    if (!view && !preview) {
      // fetching user's current location
      GET_USER_LOCATION(setLocation);
    }
    // eslint-disable-next-line
  }, [view, preview]);

  const previous = () => {
    if (filterSectionPage > 0) {
      scrollToPageTop();
      setFilterSectionPage(+filterSectionPage - 1);
    }
  };
  const nextOrFinish = () => {
    if (filterSectionPage < schema.pages.length - 1) {
      scrollToPageTop();
      setFilterSectionPage(+filterSectionPage + 1);
    } else {
      if (!view && !preview && !reportEdit) {
        let result = mandatoryAnswerHandler(
          getAnswer,
          mandatoryIds,
          mandatoryQuestions,
          setMandatoryAnswered
        );
        if (result) {
          addUpdateFormAnswer(id, dispatchFormId, user.id, "submit", [
            ...answers,
          ])
            .then(() => {
              if (currentRole === "user") {
                getAssignedFormsForUser({
                  limit: 10,
                  page: 1,
                  sortBy: "formUpdatedAt:desc",
                });
              }
              toast.success("Form Compeleted");
              secondaryAction(true);
            })
            .catch((error) => {
              if (error.response.data.message === "AssignForm not found") {
                setFormDeletedDialog(true);
              }
            });
        } else {
          setMandatoryDialog(true);
        }
      } else if (
        (!view &&
          !preview &&
          reportEdit &&
          reportForEdit?.status === "reject") ||
        reportForEdit?.status === "incomplete"
      ) {
        updateReportAnswersById(id, {
          formAnswerDetail: [...answers],
          status: "reSubmit",
        })
          .then((res) => {
            secondaryAction();
            getAdminManagerReports(false, filterState);
          })
          .catch((error) => {
            if (error.response.data.message === "AssignForm not found") {
              setFormDeletedDialog(true);
            }
          });
      } else if (
        !view &&
        !preview &&
        reportEdit &&
        reportForEdit.status === "draft"
      ) {
        updateReportAnswersById(id, {
          formAnswerDetail: [...answers],
          status: "submit",
        })
          .then(() => {
            secondaryAction();
            getAdminManagerReports(false, filterState);
          })
          .catch((error) => {
            if (error.response.data.message === "AssignForm not found") {
              setFormDeletedDialog(true);
            }
          });
      } else {
        secondaryAction();
      }
    }
  };

  const addUpdateAnwser = (answer) => {
    if (answer?.selected) {
      if (currentRole) {
        if (answers?.length) {
          const answerIndex = answers.findIndex(
            (item) => item?.questionUuid === answer.questionUuid
          );
          if (answerIndex >= 0) {
            answers[answerIndex] = { ...answer };
            setAnswers([...answers]);
          } else {
            answers.push(answer);
            setAnswers([...answers]);
          }
        } else {
          answers.push(answer);
          setAnswers([...answers]);
        }
      } else {
        if (previewAnswers?.length) {
          const previewAnswerIndex = previewAnswers.findIndex(
            (item) => item?.questionUuid === answer.questionUuid
          );
          if (previewAnswerIndex >= 0) {
            previewAnswers[previewAnswerIndex] = { ...answer };
            setPreviewAnswers([...previewAnswers]);
          } else {
            previewAnswers.push(answer);
            setPreviewAnswers([...previewAnswers]);
          }
        } else {
          previewAnswers.push(answer);
          setPreviewAnswers([...previewAnswers]);
        }
      }
    } else {
      const answersArray = currentRole ? answers : previewAnswers;

      const answerIndex = answersArray.findIndex(
        (item) => item?.questionUuid === answer.questionUuid
      );

      if (answer?.value !== "") {
        if (answerIndex >= 0) {
          answersArray[answerIndex] = { ...answer };
        } else {
          answersArray.push(answer);
        }
      } else if (answerIndex >= 0) {
        answersArray.splice(answerIndex, 1);
      }

      if (currentRole) {
        setAnswers([...answersArray]);
      } else {
        setPreviewAnswers([...answersArray]);
      }
    }
  };

  const removeFromAnswers = (uuid) => {
    let findAnswerIndex;
    let duplicateArray;
    if (currentRole) {
      if (answers && answers.length)
        findAnswerIndex = answers.findIndex(
          (item) => item?.questionUuid === uuid
        );
      if (findAnswerIndex !== null) {
        duplicateArray = [...answers];
        duplicateArray.splice(findAnswerIndex, 1);
        if (
          (reportEdit &&
            reportFormAnswers.length &&
            id &&
            answers &&
            answers.length &&
            currentRole) ||
          (reportEdit &&
            !reportFormAnswers.length &&
            id &&
            answers &&
            answers.length &&
            currentRole)
        ) {
        } else {
          addUpdateFormAnswer(id, dispatchFormId, user.id, "draft", [
            ...duplicateArray,
          ])
            .then()
            .catch((error) => {
              if (error.response.data.message === "AssignForm not found") {
                setFormDeletedDialog(true);
              }
            });
        }

        setAnswers([...duplicateArray]);
      }
    } else {
      if (previewAnswers && previewAnswers.length)
        findAnswerIndex = previewAnswers.findIndex(
          (item) => item?.questionUuid === uuid
        );
      if (findAnswerIndex !== null) {
        duplicateArray = [...previewAnswers];
        duplicateArray.splice(findAnswerIndex, 1);

        setPreviewAnswers([...duplicateArray]);
      }
    }
  };

  const getAnswer = (uuid) => {
    if (currentRole) {
      const answer = answers.find((item) => item?.questionUuid === uuid);
      return answer ? answer : undefined;
    }
  };

  useEffect(() => {
    if (answers && answers.length && dispatchFormId && id && !view) {
      if (!isFormLoading) {
        addUpdateFormAnswer(id, dispatchFormId, user.id, "draft", [...answers])
          .then()
          .catch((error) => {
            if (error?.response?.data?.message === "AssignForm not found") {
              setFormDeletedDialog(true);
            }
          });
      }
    } else if (
      (reportEdit &&
        reportFormAnswers.length &&
        id &&
        answers &&
        currentRole) ||
      (reportEdit && !reportFormAnswers.length && id && answers && currentRole)
    ) {
      if (!isFormLoading) {
        updateReportAnswersById(id, { formAnswerDetail: [...answers] })
          .then()
          .catch((error) => {
            if (error.response.data.message === "AssignForm not found") {
              setFormDeletedDialog(true);
            }
          });
      }
    }
    // eslint-disable-next-line
  }, [answers]);

  useEffect(() => {
    if (dispatchFormId) {
      setIsFormLoading(true);
      getFormManagementAnswers(dispatchFormId).then((res) => {});
    }
    // eslint-disable-next-line
  }, [dispatchFormId]);

  useEffect(() => {
    if (reportEdit && reportFormAnswers) {
      setAnswers([...reportFormAnswers]);
      setTimeout(() => {
        setIsFormLoading(false);
      }, 1000);
    } else if (formAnswers && formAnswers.id) {
      setAnswers([...formAnswers.formAnswerDetail]);
      setTimeout(() => {
        setIsFormLoading(false);
      }, 1000);
    }
    // eslint-disable-next-line
  }, [formAnswers, reportFormAnswers, reportEdit]);

  useEffect(() => {
    if (site && window.location.pathname.includes("/reports")) {
      setSiteSelection(site);
    }
    // eslint-disable-next-line
  }, [site]);

  const selectSiteHandler = (data) => {
    GET_USER_LOCATION(setLocation);
    setSiteSelection(data);
    if (dispatchFormId && data) {
      updateFormManagementById(dispatchFormId, data.value).then(() => {
        if (currentRole !== "user") {
          getForms({
            limit: 10,
            page: 1,
            sortBy: "updatedAt:desc",
            isArchived: false,
          });
        }
      });
    } else if (data && !dispatchFormId && reportForEdit.status === "draft") {
      updateReportSite(reportForEdit.id, { site: data.value }).then(() =>
        getAdminManagerReports(false, filterState)
      );
    }
  };

  useEffect(() => {
    let totalQuestions = 0;
    let failItem = 0;

    if (schema && schema.pages?.length) {
      schema?.pages?.map((item) =>
        // eslint-disable-next-line
        item.sections.map((secItem) => {
          // eslint-disable-next-line
          secItem?.questions?.map((queItem) => {
            if (queItem.settings.isEnabled) {
              totalQuestions =
                queItem?.type !== "instructions"
                  ? totalQuestions + 1
                  : totalQuestions;
              // eslint-disable-next-line
              queItem?.settings?.answers && queItem?.settings?.answers?.length
                ? // eslint-disable-next-line
                  queItem?.settings?.answers?.map((answers) => {
                    answers?.isFail
                      ? (failItem = failItem + 1)
                      : (failItem = failItem + 0);
                  })
                : null;
            }
          });
        })
      );
    }
    setTotalQuestion(totalQuestions);

    mandatoryQuestionHandler(
      schema,
      getAnswer,
      setMandatoryIds,
      setMandatoryQuestions
    );

    // eslint-disable-next-line
  }, [showDlg, schema, answers]);

  useEffect(() => {
    if (inspectionNumber) {
      let inpectSrc = "" + inspectionNumber;
      let inspecPad = "0000";
      let inspecAns =
        inspecPad.substring(0, inspecPad.length - inpectSrc.length) + inpectSrc;
      setInspecterNumber(inspecAns);
    }
    // eslint-disable-next-line
  }, [inspectionNumber]);

  const AlertDialogPrimaryHandler = () => {
    scrollToPageTop();
    if (!reportEdit && !view) {
      GET_USER_LOCATION(setLocation);
      if (siteSelection?.value === "" || siteSelection?.value === undefined) {
        toast.error(<I18n lng="Company.PleaseSelectSiteName" />);
      } else if (
        siteSelection?.value &&
        formSchemaQuestions?.status !== "draft"
      ) {
        setAlertDialog(true);
      } else if (
        siteSelection?.value &&
        formSchemaQuestions?.status === "draft"
      ) {
        formManagementById(dispatchFormId);
        setFilterSectionPage(0);
        setSelectFilterName("Page");
      }
    } else if (!reportEdit && view) {
      setFilterSectionPage(0);
      setSelectFilterName("Page");
    } else if (reportEdit && !view) {
      setFilterSectionPage(0);
      setSelectFilterName("Page");
    }
    if (preview) {
      setFilterSectionPage(0);
      setSelectFilterName("Page");
    }
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <FaCaretDown />
      </components.DropdownIndicator>
    );
  };

  useEffect(() => {
    let questionScore = 0;
    let answerScore = 0;
    if (formSchemaQuestions) {
      // eslint-disable-next-line
      formSchemaQuestions?.formDetail?.schema?.pages?.map((page) => {
        // eslint-disable-next-line
        page?.sections.map((section) => {
          // eslint-disable-next-line
          section?.questions
            .filter(
              (item) =>
                item.type === "COMPLEX_MULTIPLE_CHOICE" ||
                item.type === "checkbox" ||
                item.type === "slider"
            )
            // eslint-disable-next-line
            .map((question) => {
              if (question) {
                if (question?.settings?.score !== "-") {
                  questionScore =
                    questionScore + Number(question?.settings?.score);
                }
                let answer = answers.find(
                  (item) => item?.questionUuid === question?.questionUuid
                );
                if (answer && question.type === "COMPLEX_MULTIPLE_CHOICE") {
                  if (
                    question?.settings?.answers[answer?.selected[0]?.index]
                      ?.score === "-" &&
                    question?.settings?.selectionType !== "MULTIPLE"
                  ) {
                    questionScore = questionScore - question.settings?.score;
                  }
                  answer?.selected
                    .filter(
                      (item) =>
                        question?.settings?.answers[item?.index]?.score !== "-"
                    )
                    // eslint-disable-next-line
                    .map((select) => {
                      answerScore =
                        answerScore +
                        Number(question.settings.answers[select.index]?.score);
                    });
                } else if (answer && question.type === "checkbox") {
                  answer?.value === true
                    ? (answerScore =
                        answerScore + Number(question.settings.score))
                    : (answerScore = answerScore + 0);
                } else if (answer && question.type === "slider") {
                  let sliderMaxValue = question.settings.max;

                  answer?.sliderValue !== null || answer?.sliderValue !== ""
                    ? (answerScore =
                        answerScore +
                        (Number(answer?.sliderValue) / Number(sliderMaxValue)) *
                          Number(question.settings.score))
                    : (answerScore = answerScore + 0);
                }
              }
            });
        });
      });
    }

    setTotalAnswerScore(answerScore);
    setTotalQuestionScore(questionScore);
  }, [formSchemaQuestions, answers]);

  // calculating answered questions and score for manager preview
  useEffect(() => {
    let questionScorePreview = 0;
    let answerScorePreview = 0;

    if (schema?.pages) {
      schema?.pages?.map((page) => {
        return page?.sections.map((section) => {
          return (
            section?.questions
              .filter(
                (item) =>
                  item.type === "COMPLEX_MULTIPLE_CHOICE" ||
                  item.type === "checkbox" ||
                  item.type === "slider"
              )
              // eslint-disable-next-line
              .map((question) => {
                if (question) {
                  if (question?.settings?.score !== "-") {
                    questionScorePreview =
                      questionScorePreview + Number(question?.settings?.score);
                  }
                  let answer = previewAnswers.find(
                    (item) => item?.questionUuid === question?.questionUuid
                  );
                  if (answer && question.type === "COMPLEX_MULTIPLE_CHOICE") {
                    if (
                      question?.settings?.answers[answer?.selected[0]?.index]
                        ?.score === "-" &&
                      question?.settings?.selectionType !== "MULTIPLE"
                    ) {
                      questionScorePreview =
                        questionScorePreview - question.settings.score;
                    }
                    answer?.selected
                      .filter(
                        (item) =>
                          question.settings.answers[item.index].score !== "-"
                      )
                      // eslint-disable-next-line
                      .map((select) => {
                        answerScorePreview =
                          answerScorePreview +
                          Number(question.settings.answers[select.index].score);
                      });
                  } else if (answer && question.type === "checkbox") {
                    answer?.value === true
                      ? (answerScorePreview =
                          answerScorePreview + Number(question.settings.score))
                      : (answerScorePreview = answerScorePreview + 0);
                  } else if (answer && question.type === "slider") {
                    let sliderMaxValue = question.settings.max;

                    answer?.sliderValue !== null || answer?.sliderValue !== ""
                      ? (answerScorePreview =
                          answerScorePreview +
                          (Number(answer?.sliderValue) /
                            Number(sliderMaxValue)) *
                            Number(question.settings.score))
                      : (answerScorePreview = answerScorePreview + 0);
                  }
                }
              })
          );
        });
      });
    }
    setAnswerScorePreview(answerScorePreview);
    setQuestionScorePreview(questionScorePreview);
  }, [schema, previewAnswers]);

  let allQuestions = [];

  schema.pages.forEach((page) => {
    page.sections.forEach((section) => {
      // Loop through the questions in the section
      section.questions.forEach((question) => {
        // Push the question to the 'allQuestions' array
        allQuestions.push(question);
      });
    });
  });

  return (
    <>
      <DynamicModal
        customClasses="modal-drawer"
        modalContentClass="bg-gray "
        show={show}
        primaryAction={() => console.log("primary called")}
        secondaryAction={secondaryAction}
        showFormData={true}
        formDetailPrimaryAction={() => setShowFormDetail(true)}
        formDetailSecondaryAction={() => setShowFormDetail(false)}
      >
        <div className="form-view-wrapper">
          <div className="text-center">
            {!view &&
            siteSelection?.value !== "" &&
            (selectFilterName !== "Title Page" || selectFilterState !== "") ? (
              <Select
                className="form-preview-select mb-5"
                components={{ DropdownIndicator }}
                onChange={(e) => {
                  setSelectFilterState(e.value === "Title Page" ? e.value : "");
                  setFilterSectionPage(e.value);
                  setSelectFilterName(e.value);
                }}
                value={{
                  value:
                    selectFilterName === "Title Page" ? (
                      <I18n lng="Reports.TitlePage" />
                    ) : schema?.pages[filterSectionPage]?.name === "Page" ? (
                      `${schema?.pages[filterSectionPage]?.name} ${
                        filterSectionPage + 1
                      }`
                    ) : (
                      schema?.pages[filterSectionPage]?.name
                    ),
                  label:
                    selectFilterName === "Title Page" ? (
                      <I18n lng="Reports.TitlePage" />
                    ) : schema?.pages[filterSectionPage]?.name === "Page" ? (
                      `${i18n("Form.Page")} ${filterSectionPage + 1}`
                    ) : (
                      schema?.pages[filterSectionPage]?.name
                    ),
                }}
                options={
                  schema?.pages?.length
                    ? [
                        {
                          value: "Title Page",
                          label: <I18n lng="Reports.TitlePage" />,
                        },
                        ...schema.pages.map((item, index) => {
                          return {
                            value: index,
                            label:
                              item.name === "Page"
                                ? `${i18n("Form.Page")} ${index + 1}`
                                : item.name,
                          };
                        }),
                      ]
                    : []
                }
              />
            ) : null}
          </div>

          {filterSectionPage === "Title Page" ? (
            <>
              <form className="dispatch-wrapper text-center">
                <div className="form-header">
                  <div className="form-title">
                    <strong>
                      <img src={IconDocBoard} alt="IconDocBoard" />{" "}
                      {title ? (
                        title.includes("Untitled Form") ? (
                          <>
                            {title.replace(
                              "Untitled Form",
                              i18n("global.UntitledForm")
                            )}{" "}
                          </>
                        ) : (
                          title
                        )
                      ) : null}
                    </strong>
                  </div>
                  <div
                    style={
                      TEXT_DIRECTION() === "rtl"
                        ? { textAlign: "right" }
                        : { textAlign: "left" }
                    }
                    className="form-description"
                  >
                    <strong>
                      <span>{description}</span>
                    </strong>
                  </div>
                </div>
                <div
                  className="form-header-title"
                  style={{
                    marginLeft: "-1.5rem",
                    marginRight: "-1.5rem",
                    width: "calc(100% + 3rem)",
                    padding: ".25rem 2rem",
                  }}
                >
                  <div
                    className={`${TEXT_DIRECTION() === "rtl" ? "" : "title"}`}
                    style={{ color: "#fff" }}
                  >
                    <I18n lng="Form.TitlePage" />
                  </div>
                </div>
                <div className="title-page-details">
                  <Input
                    label={<I18n lng="global.DocumentNo" />}
                    name="documentNo"
                    type="text"
                    placeholder={i18n("global.DocumentNo")}
                    register={register}
                    readOnly={true}
                    disabled={true}
                    value={`${documentNo}  ${
                      inspecterNumber ? -inspecterNumber : ""
                    }`}
                  />
                </div>
                <div className="title-page-details mt-3">
                  <Input
                    label={<I18n lng="global.ConductedOn" />}
                    name="conductedOn"
                    type="text"
                    register={register}
                    readOnly={true}
                    disabled={true}
                    value={conductedOn}
                  />
                </div>
                <div className="title-page-details mt-3">
                  <Input
                    label={<I18n lng="global.PreparedBy" />}
                    name="preparedBy"
                    type="text"
                    register={register}
                    readOnly={true}
                    disabled={true}
                    value={preparedBy}
                  />
                </div>
                <div className="title-page-details mt-3">
                  <div className="row text-left">
                    <div className="col-12">
                      <I18n lng="global.SiteName" />:
                    </div>
                  </div>
                  <Select
                    placeholder={<I18n lng="global.Select" />}
                    label={<I18n lng="global.SiteName" />}
                    id="site"
                    name="site"
                    value={siteSelection}
                    options={
                      sites && sites.length
                        ? sites
                            .filter((subItem) => subItem.active)
                            .map((item) => {
                              return {
                                value: item.id,
                                label:
                                  item.name === "Default Site"
                                    ? `${i18n("Company.DefaultSite")}`
                                    : item.name,
                              };
                            })
                        : []
                    }
                    isDisabled={
                      (currentRole === "manager" &&
                        user?.role !== "manager/inspector") ||
                      view === true ||
                      window.location.pathname.includes("/reports")
                    }
                    onChange={selectSiteHandler}
                  />
                </div>
              </form>
            </>
          ) : null}
          {schema?.pages?.length ? (
            <>
              {filterSectionPage !== "Title Page" ? (
                <div className="form-page-wrapper">
                  <PagePreview
                    page={
                      schema?.pages[
                        filterSectionPage !== "Title Page"
                          ? filterSectionPage
                          : 0
                      ]
                    }
                    key={
                      filterSectionPage !== "Title Page" ? filterSectionPage : 0
                    }
                    currentPage={
                      filterSectionPage !== "Title Page"
                        ? +filterSectionPage + 1
                        : 0
                    }
                    totalPages={schema?.pages.length}
                    totalFilledAnswers={
                      answers && answers.length ? answers.length : 0
                    }
                    totalQuestion={totalQuestion}
                    isScored={schema.isScored}
                    filterSectionPage={filterSectionPage}
                    addUpdateAnwser={addUpdateAnwser}
                    getAnswer={getAnswer}
                    removeFromAnswers={removeFromAnswers}
                    view={view}
                    currentRole={currentRole}
                    totalAnswerScore={totalAnswerScore}
                    totalQuestionScore={totalQuestionScore}
                    preview={preview}
                    questionScorePreview={questionScorePreview}
                    answerScorePreview={answerScorePreview}
                    previewFilledAnswers={
                      previewAnswers && previewAnswers.length
                        ? previewAnswers.length
                        : 0
                    }
                    showFormDetail={showFormDetail}
                    allQuestions={allQuestions}
                  />
                </div>
              ) : null}

              {filterSectionPage !== "Title Page" &&
              filterSectionPage === schema?.pages.length - 1 ? (
                <div className="mt-3">
                  <TitleSignaturePad
                    preparedBy={signatureName}
                    fromId={
                      window.location.pathname.includes("my-reports")
                        ? reportForEdit?.form.id
                        : id
                    }
                    sigValue={sigValue}
                  />
                </div>
              ) : null}
              <div
                className={`d-flex mt-5 ${
                  filterSectionPage !== "Title Page" && filterSectionPage > 0
                    ? "justify-content-center btn-bar"
                    : "justify-content-center btn-bar"
                }`}
              >
                {filterSectionPage !== "Title Page" && filterSectionPage > 0 ? (
                  <button
                    className="btn btn-white  mr-2 ml-5"
                    onClick={(e) => {
                      e.preventDefault();
                      previous();
                    }}
                    autoFocus={false}
                  >
                    <I18n lng="global.Previous" />
                  </button>
                ) : null}
                {filterSectionPage !== "Title Page" ? (
                  <>
                    <button
                      id="form-steps"
                      to="#"
                      href="#drawerScrollTop"
                      className={`btn btn-primary 
                         ${
                           filterSectionPage !== "Title Page" &&
                           +filterSectionPage > 0
                             ? "mr-5"
                             : ""
                         }`}
                      onClick={(e) => {
                        e.preventDefault();
                        if (filterSectionPage < schema.pages.length - 1) {
                          nextOrFinish();
                        } else {
                          setTimeout(() => {
                            nextOrFinish();
                          }, 500);
                        }
                      }}
                      autoFocus={false}
                    >
                      {filterSectionPage !== "Title Page" &&
                      filterSectionPage < schema?.pages.length - 1 ? (
                        <I18n lng="global.Next" />
                      ) : view || preview ? (
                        <I18n lng="global.Close" />
                      ) : reportEdit && reportForEdit.status === "reject" ? (
                        <I18n lng="global.Resubmit" />
                      ) : (
                        <I18n lng="global.Finish" />
                      )}
                    </button>
                  </>
                ) : null}

                {filterSectionPage === "Title Page" ? (
                  <>
                    <div>
                      <button
                        to="#"
                        href="#drawerScrollTop"
                        className="btn btn-primary report-bar"
                        onClick={() => {
                          AlertDialogPrimaryHandler();
                        }}
                        autoFocus={false}
                      >
                        {reportEdit ? (
                          <I18n lng="global.Continue" />
                        ) : view ? (
                          <I18n lng="global.Next" />
                        ) : preview ? (
                          <I18n lng="global.Next" />
                        ) : (
                          <I18n lng="global.ContinuetoInspection" />
                        )}
                      </button>
                    </div>
                  </>
                ) : null}
              </div>
            </>
          ) : null}
        </div>
      </DynamicModal>

      <ConfirmationDialog
        showDlg={alertDialog}
        title={<I18n lng="global.Note" />}
        classes="modal-alert"
        iconCloseDlg
        content={
          <p>
            <I18n lng="Form.StartInspection" />
          </p>
        }
        primaryActionTitle={<I18n lng="global.Yes" />}
        secondryActionTitle={<I18n lng="global.No" />}
        secondaryAction={() => setAlertDialog(false)}
        primaryAction={() => {
          updateFormManagementStatus(
            dispatchFormId,
            { status: "draft" },
            location
          ).then(() => {
            formManagementById(dispatchFormId);
            setFilterSectionPage(0);
            setSelectFilterName("Page");
            setAlertDialog(false);
          });
        }}
        primaryActionClasses={"btn-success"}
        secondryActionClasses={"btn-danger"}
      />

      <AlertSigupDialog
        showDlg={mandatoryDialog}
        classes="modal-alert"
        iconCloseDlg
        content={
          <p>
            <br />
            <I18n lng="Form.ThereAre" />{" "}
            <b style={{ color: "red" }}>
              {TEXT_DIRECTION() === "rtl" ? (
                <>
                  {arabicConvertedNumber} ({arabicNumber})
                </>
              ) : (
                <>
                  {englishConvertedNumber} (
                  {mandatoryQuestions - mandatoryAnswered})
                </>
              )}
            </b>{" "}
            <I18n lng="Form.MandatoryQuestions" />
            <br />
            <br />
            <I18n lng="Form.GoToMandatoryQuestions" />
          </p>
        }
        secondaryAction={() => {
          setMandatoryDialog(false);
          if (mandatoryIds.length) {
            setFilterSectionPage(mandatoryIds[0].pageIndex);
          }
          setTimeout(() => {
            let unAnsweredQuestionData = navigatingToQuestion(
              mandatoryIds,
              getAnswer
            );
            if (unAnsweredQuestionData) {
              unAnsweredQuestionData.scrollIntoView({
                behavior: "smooth",
              });
            }
          }, 500);
        }}
        secondryActionTitle={<I18n lng="global.Yes" />}
        secondryActionClasses={"btn-primary"}
      />
      <AlertSigupDialog
        showDlg={formDeletedDialog}
        classes="modal-alert"
        iconCloseDlg
        content={
          <p>
            <br />
            <I18n lng="Form.ThisFormHasBeen" />
            <b style={{ color: "red" }}>
              <I18n lng="Form.Deleted" />
            </b>{" "}
            <I18n lng="Form.ByTheManager" />
          </p>
        }
        secondaryAction={() => {
          setFormDeletedDialog(false);
          history.push("/forms/form-management");
          getAssignedFormsForUser({
            limit: 10,
            page: 1,
            sortBy: "formUpdatedAt:desc",
          });
        }}
        secondryActionTitle={<I18n lng="global.Close" />}
        secondryActionClasses={"btn-primary"}
      />
    </>
  );
};

const mapStateToProps = ({ authentication, form, sites }) => ({
  sites: sites.sites,
  user: authentication.user,
  formToEdit: form.formToEdit,
  formAnswers: form.formAnswers,
  currentRole: authentication.currentRole,
});

const mapDispatchToProps = {
  getAllSites,
  updateReportSite,
  formManagementById,
  addUpdateFormAnswer,
  getForms,
  getAdminManagerReports,
  updateReportAnswersById,
  getAssignedFormsForUser,
  getFormManagementAnswers,
  updateFormManagementById,
  updateFormManagementStatus,
};
export default connect(mapStateToProps, mapDispatchToProps)(FormPreview);
