import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import I18n from "../../../languageSelector";
import DynamicModal from "../../../../../components/dialogs";
import ReportsEditFilterTitle from "./my-report-filter-title";
import ReportsEditFilterContent from "./my-report-filter-content";
import { Switch, Route, useParams, Link, useHistory } from "react-router-dom";
import {
  getReportById,
  getAdminManagerReports,
  updateReportShareSetting,
  getReportSettings,
} from "../../../../../redux/reducers/admin-manager-reports/admin-manager-report.reducer";

const settingInitState = {
  title: {
    siteName: true,
    inspectorName: true,
    dateTime: true,
  },
  content: {
    scores: true,
    failsCount: true,
    docNumber: true,
    dateTime: true,
    created: true,
    lastUpdate: true,
    conducted: true,
    failsSummary: true,
    instructions: true,
    unAnsweredQuestions: true,
    inspectionQuestions: true,
    answered: false,
    unAnswered: false,
    all: true,

    photos: true,
    standardQuality: true,
    highQuality: false,

    photosLinksQrCode: true,
    PdfLinksQrCode: true,
    tableOfContents: true,
    companyInformation: true,
    showDisclaimer: false,
    disclaimer: "",

    footer: true,
    page: true,
    title: true,
    footerDate: true,
    inspectorName: true,
  },
};

const MyReportsEditFilterView = ({
  showDlg,
  user,
  secondaryAction,
  updateReportShareSetting,
  getAdminManagerReports,
  getReportById,
  reportSettings,
  getReportSettings,
  filterState,
}) => {
  const { id } = useParams();
  const history = useHistory();

  let location = window.location.pathname;
  let path = location.split("/");

  const [reportSetting, setReportSettings] = useState(settingInitState);
  useEffect(() => {
    if (
      path &&
      path.length &&
      path[path.length - 1] !== "Report-Title" &&
      path[path.length - 1] !== "Report-Content"
    ) {
      history.push(`/reports/my-reports/settings/edit/${id}/report-title`);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getReportSettings(id, user.id).then((res) => {
      setReportSettings(res?.value.data.userReportSetting);
    });

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (id && reportSetting) {
      updateReportShareSetting(id, user.id, {
        userReportSetting: reportSetting,
      }).then(() => {
        getReportById(id);
        getReportSettings(id, user.id);
        getAdminManagerReports(false, filterState);
      });
    }
    // eslint-disable-next-line
  }, [reportSetting]);

  return (
    <>
      <DynamicModal
        customClasses="modal-drawer modal-report-edit-filter"
        show={showDlg}
        secondaryAction={secondaryAction}
      >
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <Link
              to={`/reports/my-reports/settings/edit/${id}/report-title`}
              className={`nav-link ${
                path[path.length - 1] === "report-title" ? "active" : ""
              }`}
            >
              <I18n lng="Reports.Report-Title" />
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to={`/reports/my-reports/settings/edit/${id}/report-content`}
              className={`nav-link ${
                path[path.length - 1] === "report-content" ? "active" : ""
              }`}
            >
              <I18n lng="Reports.Report-Content" />
            </Link>
          </li>
        </ul>

        <Switch>
          <Route path="/reports/my-reports/settings/edit/:id/report-title">
            <ReportsEditFilterTitle
              reportSetting={reportSetting}
              setReportSettings={setReportSettings}
            />
          </Route>
          <Route path="/reports/my-reports/settings/edit/:id/report-content">
            <ReportsEditFilterContent
              reportSetting={reportSetting}
              setReportSettings={setReportSettings}
            />
          </Route>
        </Switch>
      </DynamicModal>
    </>
  );
};
const mapStateToProps = ({ adminManagerReports, authentication }) => ({
  user: authentication.user,
  reportSettings: adminManagerReports.reportSettings,
});

const mapDispatchToProps = {
  getReportById,
  updateReportShareSetting,
  getAdminManagerReports,
  getReportSettings,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyReportsEditFilterView);
