import React, { useEffect } from "react";
import Select from "react-select";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import I18n from "../../languageSelector";
import "react-datepicker/dist/react-datepicker.css";
import DialogModal from "../../../../components/dialogs/compact-dialog";
import { getAllUsers } from "../../../../redux/reducers/user/user.reducer";
import { getAllSites } from "../../../../redux/reducers/sites/sites.reducer";
import { getAdminManagerReports } from "../../../../redux/reducers/admin-manager-reports/admin-manager-report.reducer";

const FilterDialog = ({
  show,
  allUsers,
  filterState,
  currentRole,
  getAllUsers,
  getAllSites,
  clearActions,
  hideInspector,
  primaryAction,
  setFilterState,
  secondaryAction,
  secondryActionTitle,
}) => {
  useEffect(() => {
    if (show) {
      if (currentRole !== "user") {
        getAllUsers();
        getAllSites();
      } else if (currentRole === "user") {
      } else {
        getAllSites();
      }
    } // eslint-disable-next-line
  }, [show]);

  return (
    <DialogModal
      showDlg={show}
      size="md"
      classes={"modal-filter"}
      content={
        <>
          <h3 className="text-center">
            <I18n lng="global.Filters" />
          </h3>
          <ul className="list-unstyled">
            <li>
              <strong>
                <I18n lng="global.FromDate" />
              </strong>
              <DatePicker
                selected={filterState.fromDate}
                maxDate={filterState.toDate}
                onChange={(date) => {
                  setFilterState({
                    ...filterState,
                    fromDate: date,
                  });
                }}
                className="date-picker"
              />
            </li>
            <li>
              <strong>
                <I18n lng="global.ToDate" />
              </strong>
              <DatePicker
                selected={filterState.toDate}
                minDate={filterState.fromDate}
                maxDate={new Date()}
                onChange={(date) =>
                  setFilterState({
                    ...filterState,
                    toDate: date,
                  })
                }
                className="date-picker"
              />
            </li>
            {currentRole !== "user" && !hideInspector ? (
              <li>
                <strong>
                  <I18n lng="global.Inspector" />
                </strong>
                <Select
                  className="filter-select"
                  placeholder={<I18n lng="global.Select" />}
                  onChange={(e) => {
                    setFilterState({
                      ...filterState,
                      inspector: e,
                    });
                  }}
                  value={filterState.inspector}
                  options={
                    allUsers && allUsers.length
                      ? allUsers
                          .filter((item) => item.role === "user")
                          .map((user) => {
                            return { value: user.id, label: user.fullName };
                          })
                      : []
                  }
                />
              </li>
            ) : null}
          </ul>
        </>
      }
      iconCloseDlg={true}
      clearActions={() => clearActions()}
      primaryAction={() => primaryAction()}
      secondaryAction={() => secondaryAction()}
      secondryActionTitle={secondryActionTitle}
      primaryActionTitle={<I18n lng="global.Apply" />}
    />
  );
};

const mapStateToProps = ({ authentication, user, form, sites }) => ({
  forms: form.forms,
  sites: sites.sites,
  allUsers: user.allUsers,
  currentRole: authentication.currentRole,
});

const mapDispatchToProps = {
  getAllUsers,
  getAllSites,
  getAdminManagerReports,
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterDialog);
