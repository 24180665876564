import React from "react";
import moment from "moment";
import I18n, { i18n } from "../../../languageSelector";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const AlertModal = ({
  isAssignee,
  show,
  secondaryAction,
  primaryAction,
  setReasonForRejection,
  reasonForRejection,
  role,
  reportForEdit,
}) => {
  return (
    <Modal isOpen={show} toggle={() => secondaryAction()}>
      <ModalHeader cssModule={{ "modal-title": "w-100 text-center" }}>
        <I18n lng="global.ReasonForRejection" />
      </ModalHeader>
      <ModalBody>
        {role !== "user" && !isAssignee ? (
          <textarea
            className="form-control textarea"
            rows="4"
            placeholder={i18n("global.AddText")}
            value={reasonForRejection}
            onChange={(e) => {
              setReasonForRejection(e.target.value);
            }}
          />
        ) : (
          <>
            <div className="alert-for">
              <p>
                <I18n lng="Notifications.On" />{" "}
                <b>{moment(reportForEdit?.rejectedDate).format("llll")}</b>{" "}
                <strong>{reportForEdit?.assigner?.fullName}</strong>{" "}
                <b>
                  {reportForEdit?.status === "reject" ? (
                    <I18n lng="Reports.Rejected" />
                  ) : (
                    ""
                  )}
                </b>{" "}
                <I18n lng="global.TheReportForTheFollowingReason" />
              </p>
              <p className="alert-desc">{reportForEdit?.reason} </p>
            </div>
          </>
        )}
      </ModalBody>
      <ModalFooter className="justify-content-center">
        <Button
          color={`${role === "user" || isAssignee ? "primary" : "danger"}`}
          onClick={() => primaryAction()}
        >
          {role === "user" || isAssignee ? (
            <I18n lng="Notifications.Restart" />
          ) : (
            <I18n lng="Reports.Reject" />
          )}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
export default AlertModal;
