import React, { useState, useEffect } from "react";
// import moment from "moment";
// import "moment/locale/ar"; // Import the Arabic locale
import I18n from "../../../languageSelector";
import ReportPdfImages from "../report-pdf-images/reportPdfImages.js";
import {
  TEXT_DIRECTION,
  formatDateToArabic,
  formatDateToTime,
  formatTimeToArabic,
} from "../../../../../helper/helperFunctions";

const DatePdfPreview = ({
  getAnswer = (uuid) => {
    console.log(uuid);
    return undefined;
  },
  question,
  failStatus,
  hideAndShow,
  setImageIndex,
  setShowImageSrc,
  setShowImageDialog,
  setShowSection,
  setTotalAnswered,
  showDocumentMedia,
  imageCountData,
}) => {
  const [answerValue, setAnswerValue] = useState("");
  const [note, setNote] = useState("");
  const [photo, setPhoto] = useState("");

  let language = localStorage.getItem("language");

  const weekDay = {
    weekday: "long",
    numberingSystem: language && language !== "ENGLISH" ? "arab" : "english",
  };
  const answerTime = {
    hour: "numeric",
    minute: "numeric",
    numberingSystem: language && language !== "ENGLISH" ? "arab" : "english",
  };

  const dateDayTime = {
    year: "numeric",
    month: "short",
    day: "numeric",
    numberingSystem: language && language !== "ENGLISH" ? "arab" : "english",
  };
  const date = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
    numberingSystem: language && language !== "ENGLISH" ? "arab" : "english",
  };

  const time = {
    hour12: true,
    hour: "numeric",
    minute: "numeric",
    numberingSystem: language && language !== "ENGLISH" ? "arab" : "english",
  };

  useEffect(() => {
    if (question && question.questionUuid) {
      let answer = getAnswer(question.questionUuid);
      if (answer) {
        setAnswerValue(answer.value);
        setNote(answer.note);
        setPhoto(answer.image);

        if (answer?.value || answer?.note || answer?.image) {
          setShowSection(true);
          setTotalAnswered((prev) => prev + 1);
        }
      }
    }
    // eslint-disable-next-line
  }, [question]);

  const [imageCount, setImageCount] = useState(0);

  useEffect(() => {
    if (question && question.questionUuid && imageCountData?.length) {
      let data = imageCountData.filter((item) => {
        return item.questionId === question.questionUuid;
      });
      if (data.length) {
        setImageCount(data[0].imageCountStart);
      }
    }
  }, [question, imageCountData]);

  // useEffect(() => {
  //   if (
  //     language !== "ENGLISH" &&
  //     window.location.pathname.includes("/report-pdf-view/")
  //   ) {
  //     moment.locale("ar");
  //   } else {
  //     moment.locale("en");
  //   }
  // }, []);
  console.log(answerValue);
  return (
    <>
      {!failStatus ? (
        hideAndShow?.content?.all ? (
          <div className="table-body">
            <div
              className="row table-row ml-0 mr-0   question status-na"
              style={{
                boxShadow: "0px 5px 5px rgba(0,0,0,.10)",
                backgroundColor: "#ffffff",
              }}
            >
              <div
                className={`col-lg-8 col-md-6 mb-3 table-column d-flex ${
                  TEXT_DIRECTION() === "rtl" ? "pr-0" : "pl-0"
                }`}
              >
                <span
                  className={`text-muted ${
                    TEXT_DIRECTION() === "rtl" ? "ml-2" : "mr-2"
                  }`}
                >
                  {question?.questionNumber}.
                </span>
                {"   "}
                {question?.settings?.questionText === "Add a new question" ? (
                  <I18n lng="Form.AddANewQuestion" />
                ) : (
                  question?.settings?.questionText
                )}
              </div>
              <div
                className="col-lg-4 col-md-6 table-column question-type text-primary"
                style={{ padding: "0 23px" }}
              >
                <p>
                  {question.settings.isDate && question.settings.isTime ? (
                    answerValue ? (
                      <div className="d-flex">
                        <p>
                          {formatDateToArabic(answerValue, language, weekDay)}
                        </p>
                        <p className="mx-4">
                          {formatDateToArabic(
                            answerValue,
                            language,
                            dateDayTime
                          )}
                        </p>
                        <p>
                          {formatDateToTime(answerValue, language, answerTime)}
                        </p>
                      </div>
                    ) : // "     " +
                    // moment(answerValue).format("MMM-DD-YYYY") +
                    // "    " +
                    // moment(answerValue).format("hh:mm A")
                    null
                  ) : (
                    ""
                  )}
                </p>
                {!question.settings.isDate && question.settings.isTime
                  ? answerValue
                    ? formatTimeToArabic(answerValue, language, time)
                    : null
                  : ""}
                {question.settings.isDate && !question.settings.isTime
                  ? answerValue
                    ? formatDateToArabic(answerValue, language, date)
                    : null
                  : ""}
                {note || photo.length ? (
                  <div
                    className="table-column attachments-wrapper pb-3 mt-3"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <>
                      <fieldset>
                        <legend
                          className={`${
                            TEXT_DIRECTION() === "rtl"
                              ? "legend-arabic"
                              : "legend"
                          }`}
                        >
                          <I18n lng="global.InspectorNotes" />
                        </legend>
                        {note ? (
                          <div className="notes">
                            <p>{note}</p>
                          </div>
                        ) : null}
                        {photo.length ? (
                          <div className="notes thumbnail-wrapper">
                            <ReportPdfImages
                              photo={photo}
                              imageCount={imageCount}
                              setImageIndex={setImageIndex}
                              setShowImageSrc={setShowImageSrc}
                              showDocumentMedia={showDocumentMedia}
                              setShowImageDialog={setShowImageDialog}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </fieldset>
                    </>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        ) : hideAndShow?.content?.answered &&
          (answerValue || note || photo?.length) ? (
          <div className="table-body">
            <div
              className="row table-row ml-0 mr-0   question status-na"
              style={{
                boxShadow: "0px 5px 5px rgba(0,0,0,.10)",
                backgroundColor: "#ffffff",
              }}
            >
              <div
                className={`col-lg-8 col-md-6 mb-3 table-column d-flex ${
                  TEXT_DIRECTION() === "rtl" ? "pr-0" : "pl-0"
                }`}
              >
                <span
                  className={`text-muted ${
                    TEXT_DIRECTION() === "rtl" ? "ml-2" : "mr-2"
                  }`}
                >
                  {question?.questionNumber}.
                </span>
                {"   "}
                {question?.settings?.questionText === "Add a new question" ? (
                  <I18n lng="Form.AddANewQuestion" />
                ) : (
                  question?.settings?.questionText
                )}
              </div>
              <div
                className="col-lg-4 col-md-6 table-column question-type text-primary"
                style={{ padding: "0 23px" }}
              >
                {question.settings.isDate && question.settings.isTime
                  ? answerValue
                    ? formatDateToArabic(answerValue, language, weekDay)
                    : null
                  : ""}
                {!question.settings.isDate && question.settings.isTime
                  ? answerValue
                    ? formatTimeToArabic(answerValue, language, time)
                    : null
                  : ""}
                {question.settings.isDate && !question.settings.isTime
                  ? answerValue
                    ? formatDateToArabic(answerValue, language, date)
                    : null
                  : ""}
                {note || photo.length ? (
                  <div
                    className="table-column attachments-wrapper pb-3 mt-3"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <>
                      <fieldset>
                        <legend
                          className={`${
                            TEXT_DIRECTION() === "rtl"
                              ? "legend-arabic"
                              : "legend"
                          }`}
                        >
                          <I18n lng="global.InspectorNotes" />
                        </legend>
                        {note ? (
                          <div className="notes">
                            <p>{note}</p>
                          </div>
                        ) : null}
                        {photo.length ? (
                          <div className="notes thumbnail-wrapper">
                            <ReportPdfImages
                              photo={photo}
                              imageCount={imageCount}
                              setImageIndex={setImageIndex}
                              setShowImageSrc={setShowImageSrc}
                              showDocumentMedia={showDocumentMedia}
                              setShowImageDialog={setShowImageDialog}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </fieldset>
                    </>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        ) : hideAndShow?.content?.unAnswered &&
          !answerValue &&
          !note &&
          !photo?.length ? (
          <div className="table-body">
            <div
              className="row table-row ml-0 mr-0   question status-na"
              style={{
                boxShadow: "0px 5px 5px rgba(0,0,0,.10)",
                backgroundColor: "#ffffff",
              }}
            >
              <div
                className={`col-lg-8 col-md-6 mb-3 table-column d-flex ${
                  TEXT_DIRECTION() === "rtl" ? "pr-0" : "pl-0"
                }`}
              >
                <span
                  className={`text-muted ${
                    TEXT_DIRECTION() === "rtl" ? "ml-2" : "mr-2"
                  }`}
                >
                  {question?.questionNumber}.
                </span>
                {"   "}
                {question?.settings?.questionText === "Add a new question" ? (
                  <I18n lng="Form.AddANewQuestion" />
                ) : (
                  question?.settings?.questionText
                )}
              </div>
              <div
                className="col-lg-4 col-md-6 table-column question-type text-primary"
                style={{ padding: "0 23px" }}
              >
                {question.settings.isDate && question.settings.isTime
                  ? answerValue
                    ? formatDateToArabic(answerValue, language, weekDay)
                    : null
                  : ""}
                {!question.settings.isDate && question.settings.isTime
                  ? answerValue
                    ? formatTimeToArabic(answerValue, language, time)
                    : null
                  : ""}
                {question.settings.isDate && !question.settings.isTime
                  ? answerValue
                    ? formatDateToArabic(answerValue, language, date)
                    : null
                  : ""}
              </div>
              {note || photo.length ? (
                <div
                  className="table-column attachments-wrapper pb-3"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <>
                    <fieldset>
                      <legend
                        className={`${
                          TEXT_DIRECTION() === "rtl"
                            ? "legend-arabic"
                            : "legend"
                        }`}
                      >
                        <I18n lng="global.InspectorNotes" />
                      </legend>
                      {note ? (
                        <div className="notes">
                          <p>{note}</p>
                        </div>
                      ) : null}
                      {photo.length ? (
                        <div className="notes thumbnail-wrapper">
                          <ReportPdfImages
                            photo={photo}
                            imageCount={imageCount}
                            setImageIndex={setImageIndex}
                            setShowImageSrc={setShowImageSrc}
                            showDocumentMedia={showDocumentMedia}
                            setShowImageDialog={setShowImageDialog}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </fieldset>
                  </>
                </div>
              ) : null}
            </div>
          </div>
        ) : null
      ) : null}
    </>
  );
};
export default DatePdfPreview;
