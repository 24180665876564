import jsreport from "@jsreport/browser-client";
import toast from "react-hot-toast";

export const generatePDFReport = async (
  pdfName,
  reportData,
  reportLanguage,
  downloadOrWebView
) => {
  if (
    validateJsReportInputs({
      pdfName,
      reportData,
      reportLanguage,
      downloadOrWebView,
    })
  ) {
    jsreport.serverUrl = process.env.REACT_APP_JS_REPORT_SERVER_URL;
    toast.loading("Generating PDF");
    const report = await jsreport.render({
      template: {
        name: `${
          reportLanguage === "EN"
            ? "/tamam_en_report/main"
            : "/tamam_ar_report/main"
        }`,
        recipe: "chrome-pdf",
      },
      data: { ...reportData },
    });
    if (downloadOrWebView === "DOWNLOAD") {
      report.download(pdfName);
      toast.dismiss();
      toast.success("PDF Generated Successfully");
    } else if (downloadOrWebView === "WEB_VIEW") {
      report.response
        .blob()
        .then((e) => {
          const objectUrl = URL.createObjectURL(e);

          let iframe = document.createElement("iframe");
          document.body.appendChild(iframe);
          iframe.style.display = "none";
          iframe.src = objectUrl;
          iframe.onload = function () {
            setTimeout(function () {
              iframe.focus();
              iframe.contentWindow.print();
              toast.dismiss();
              toast.success("PDF Generated Successfully");
            }, 1);
          };
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
};

const validateJsReportInputs = (inputs) => {
  if (!inputs.pdfName) {
    console.log("Invalid or missing PDF name");
    return false;
  }

  if (!inputs.reportData) {
    console.log("Invalid or missing input data");
    return false;
  }

  if (!inputs.reportLanguage || !["EN", "AR"].includes(inputs.reportLanguage)) {
    console.log("Invalid or missing report language");
    return false;
  }

  if (
    !inputs.downloadOrWebView ||
    !["DOWNLOAD", "WEB_VIEW"].includes(inputs.downloadOrWebView)
  ) {
    console.log("Invalid or missing dowload or web view");
    return false;
  }
  return true;
};
