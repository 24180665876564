import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import { useCSVReader } from "react-papaparse";
import I18n, { i18n } from "../../../private/languageSelector";
import IconPlus from "../../../../assets/images/icon-plus.svg";
import { Link, useParams, useHistory } from "react-router-dom";
import { TEXT_DIRECTION } from "../../../../helper/helperFunctions";
import IconDelete from "../../../../assets/images/icon-delete-red.svg";
import { updateRoute } from "../../../../redux/reducers/authentication/authentication";
import {
  getGlobalList,
  postGlobalList,
  patchGlobalList,
} from "../../../../redux/reducers/global-list/global-list.reducer";

const GlobaListAddEdit = ({
  updateRoute,
  getGlobalList,
  postGlobalList,
  patchGlobalList,
  globalListToEdit,
}) => {
  const { id } = useParams();
  const history = useHistory();
  const { CSVReader } = useCSVReader();

  const [list, setList] = useState([]);
  const [title, setTitle] = useState("");
  const [showOptions, setShowOptions] = useState(false);

  useEffect(() => {
    if (id) {
      getGlobalList(id);
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    updateRoute({
      step1: "Forms",
      step2: "Global List",
      enableBack: true,
      formScrol: false,
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (globalListToEdit) {
      setTitle(globalListToEdit.title);
      setList([...globalListToEdit.list]);
    }
    // eslint-disable-next-line
  }, [globalListToEdit]);

  const addItemHandler = () => {
    if (list) {
      list.push("");
    }
    setList([...list]);
  };

  const deleteHandler = (index) => {
    let tempList = [...list];
    tempList.splice(index, 1);
    setList([...tempList]);
  };

  const saveForm = () => {
    if (!title) {
      toast.error(<I18n lng="Form.ListNameIsRequired" />);
      return;
    }

    if (!list || !list.length) {
      toast.error(<I18n lng="Form.EntriesCannotBeEmpty" />);
      return;
    }

    for (let item of list) {
      if (!item) {
        toast.error(<I18n lng="Form.ListItemCannotBeEmpty" />);
        return;
      }
    }

    if (id) {
      patchGlobalList(id, { title, list }).then(() => {
        history.push("/forms/global-list");
      });
    } else {
      postGlobalList({ title, list }).then(() => {
        history.push("/forms/global-list");
      });
    }
  };

  return (
    <>
      <div className="px-3 global-list-detail">
        <div className="search-bar">
          <form className="form-search">
            <div className="page-title text-center">
              <label className="title-text">
                <I18n lng="Form.CreateACustomList" />
              </label>
            </div>

            <label className="form-label" htmlFor={"listName"}>
              <strong>
                <I18n lng="Form.ListName" />
              </strong>{" "}
              <span className="required"></span>
            </label>

            <div className="input-group list-name">
              <input
                className="form-control"
                placeholder={i18n("Form.ListName")}
                id={"listName"}
                autoFocus
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>

            <label className="form-label w-100 mt-5">
              <I18n lng="Form.Entrise" />
            </label>

            {list && list.length
              ? list.map((item, index) => (
                  <div className="d-flex align-items-center mb-3" key={index}>
                    <div
                      className={`input-group mb-0 ${
                        TEXT_DIRECTION() === "rtl" ? "list-name" : "mr-5"
                      }`}
                    >
                      <input
                        className="form-control "
                        placeholder={i18n("Form.ItemName")}
                        value={item}
                        autoFocus
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            addItemHandler();
                          }
                        }}
                        onChange={(e) => {
                          list[index] = e.target.value;
                          setList([...list]);
                        }}
                      />
                    </div>

                    <Link to="#" className="btn-link mt-0">
                      <img
                        className={`${
                          TEXT_DIRECTION() === "rtl" ? "ml-2" : ""
                        }`}
                        src={IconDelete}
                        alt="IconDelete"
                        onClick={() => deleteHandler(index)}
                      />
                    </Link>
                  </div>
                ))
              : null}

            <Link to="#" className="btn-link mb-4" onClick={addItemHandler}>
              <img className="mr-1 ml-1" src={IconPlus} alt="Icon Add" />{" "}
              <I18n lng="Form.AddItem" />
            </Link>
          </form>
        </div>

        <div className="d-flex justify-content-center">
          <button
            style={{ backgroundColor: "#20639b", color: "white" }}
            className="btn btn-secondry mr-3 ml-3"
            onClick={() => saveForm()}
          >
            <I18n lng="global.Save&Apply" />
          </button>
          <Link to="/forms/global-list/">
            <button className="btn btn-white">
              <I18n lng="global.Cancel" />
            </button>
          </Link>
        </div>

        <div className="btn-report-status-wrap global-list">
          {showOptions ? (
            <div
              className={`btn-status-wrapper ${
                TEXT_DIRECTION() === "rtl" ? "direction-left" : ""
              }`}
            >
              <CSVReader
                onUploadAccepted={(results) => {
                  let csvData = [];

                  results.data.map((item) => {
                    return item.forEach((el) => el && csvData.push(el));
                  });
                  setList(csvData);
                }}
              >
                {({ getRootProps }) => (
                  <div
                    className={`btn-status ${
                      TEXT_DIRECTION() === "rtl"
                        ? "btn-accept-left"
                        : "btn-accept"
                    }`}
                    {...getRootProps()}
                  >
                    <I18n lng="global.UploadCSV" />
                  </div>
                )}
              </CSVReader>

              <div
                className={`btn-status ${
                  TEXT_DIRECTION() === "rtl"
                    ? "btn-incomplete-left"
                    : "btn-incomplete"
                }`}
              >
                <a
                  href="https://tamam-bucket.s3.me-south-1.amazonaws.com/9efc43af920e431b417238e758708b02.csv"
                  download
                  style={{ textDecoration: "none", color: " white" }}
                >
                  <I18n lng="global.DownloadExample" />
                </a>
              </div>
            </div>
          ) : null}
          <Link
            to="#"
            className={`btn btn-rounded btn-add-new ${
              TEXT_DIRECTION() === "rtl" ? "left-direction" : "right-direction"
            }`}
            style={{ width: "55px", height: "55px" }}
            onClick={() => setShowOptions(!showOptions)}
          >
            <I18n lng="Form.Import" />
          </Link>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ globalList }) => ({
  globalListToEdit: globalList.globalListToEdit,
});

const mapDispatchToProps = {
  postGlobalList,
  patchGlobalList,
  getGlobalList,
  updateRoute,
};

export default connect(mapStateToProps, mapDispatchToProps)(GlobaListAddEdit);
