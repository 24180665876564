import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import I18n from "../../../languageSelector";
import MyReportsShare from "./my-report-share";
import { Switch, Route } from "react-router-dom";
import MyReportsHistory from "./my-report-history";
import { useParams, useHistory } from "react-router-dom";
import DynamicModal from "../../../../../components/dialogs";
import { getReportById } from "../../../../../redux/reducers/admin-manager-reports/admin-manager-report.reducer";

const MyReportsExportView = ({
  showDlg,
  secondaryAction,
  getReportById,
  reportForEdit,
}) => {
  const { id } = useParams();
  const history = useHistory();
  let location = window.location.pathname;
  let path = location.split("/");

  const URL = `${process.env.REACT_APP_URL}report-pdf-view/${reportForEdit?.reportId}`;

  useEffect(() => {
    if (
      path &&
      path.length &&
      path[path.length - 1] !== "share" &&
      path[path.length - 1] !== "history" &&
      !path.includes("other-reports")
    ) {
      history.push(`/reports/my-reports/export/${id}/share`);
    }
    if (
      path &&
      path.length &&
      path[path.length - 1] !== "share" &&
      path[path.length - 1] !== "history" &&
      path.includes("other-reports")
    ) {
      history.push(`/reports/other-reports/export/${id}/share`);
    }
    // eslint-disable-next-line
  }, [path]);

  useEffect(() => {
    if (id) {
      getReportById(id);
    }
    // eslint-disable-next-line
  }, [id]);
  return (
    <>
      <DynamicModal
        customClasses="modal-drawer modal-report-sh"
        show={showDlg}
        secondaryAction={secondaryAction}
      >
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <Link
              id="share-Link"
              className={`nav-link ${
                path[path.length - 1] === "share" ? "active" : ""
              }`}
              to={
                !path.includes("other-reports")
                  ? `/reports/my-reports/export/${id}/share`
                  : `/reports/other-reports/export/${id}/share`
              }
            >
              <I18n lng="Reports.Share" />
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${
                path[path.length - 1] === "history" ? "active" : ""
              }`}
              to={
                !path.includes("other-reports")
                  ? `/reports/my-reports/export/${id}/history`
                  : `/reports/other-reports/export/${id}/history`
              }
            >
              <I18n lng="Reports.History" />
            </Link>
          </li>
        </ul>
        <Switch>
          <Route path={`/reports/${path[2]}/export/:id/share`}>
            <MyReportsShare URL={URL} reportId={reportForEdit?.reportId} />
          </Route>
          <Route path={`/reports/${path[2]}/export/:id/history`}>
            <MyReportsHistory reportHistory={reportForEdit?.shareHistory} />
          </Route>
        </Switch>
      </DynamicModal>
    </>
  );
};
const mapStateToProps = ({ adminManagerReports }) => ({
  reportForEdit: adminManagerReports.reportForEdit,
});

const mapDispatchToProps = {
  getReportById,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyReportsExportView);
