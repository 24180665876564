import axios from "axios";
import { REQUEST, SUCCESS, FAILURE } from "../action-type.util";

export const ACTION_TYPES = {
  GET_INVOICE: "invoice/GET_INVOICE",
  GET_INVOICES: "invoice/GET_INVOICES",
  POST_INVOICE: "invoice/POST_INVOICE",
  PATCH_INVOICE: "invoice/PATCH_INVOICE",
  CANCEL_INVOICE: "invoice/CANCEL_INVOICE",
};

const initialState = {
  error: null,
  loading: false,
};

export const InvoicesState = initialState;
// Reducer
// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.GET_INVOICE):
    case REQUEST(ACTION_TYPES.GET_INVOICES):
    case REQUEST(ACTION_TYPES.POST_INVOICE):
    case REQUEST(ACTION_TYPES.PATCH_INVOICE):
    case REQUEST(ACTION_TYPES.CANCEL_INVOICE):
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FAILURE(ACTION_TYPES.GET_INVOICE):
    case FAILURE(ACTION_TYPES.GET_INVOICES):
    case FAILURE(ACTION_TYPES.POST_INVOICE):
    case FAILURE(ACTION_TYPES.PATCH_INVOICE):
    case FAILURE(ACTION_TYPES.CANCEL_INVOICE):
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SUCCESS(ACTION_TYPES.GET_INVOICES):
      return {
        invoices: action.payload.data.results,
        loading: false,
        error: null,
      };

    case SUCCESS(ACTION_TYPES.GET_INVOICE):
      return {
        invoiceForEdit: action.payload.data,
        loading: false,
        error: null,
      };

    case SUCCESS(ACTION_TYPES.POST_INVOICE):
    case SUCCESS(ACTION_TYPES.PATCH_INVOICE):
    case SUCCESS(ACTION_TYPES.CANCEL_INVOICE):
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

// Actions

export const getInvoices = () => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.GET_INVOICES,
    payload: axios.get(`/invoices`),
  });
};

export const getInvoiceById = (id) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.GET_INVOICE,
    payload: axios.get(`/invoices/${id}`),
  });
};

export const createInvoice = (invoice) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.POST_INVOICE,
    payload: axios.post(`/invoices`, invoice),
  });
};

export const updateInvoiceById = (invoice, id) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.PATCH_INVOICE,
    payload: axios.post(`/invoices/${id}`, invoice),
  });
};

export const cancelInvoiceById = (invoice, id) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.CANCEL_INVOICE,
    payload: axios.post(`/invoices/${id}/cancel`, invoice),
  });
};
