import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

const SubscriptionExpiredRedirect = ({
  component: Component,
  isSubscriptionExpired,
  isSubscriptionPaused,
  ...rest
}) => {

  const renderRedirect = (props) => {
    if (isSubscriptionExpired || isSubscriptionPaused) {
      return (
        <Redirect
          to={{
            pathname: "/management/subscriptions",
            search: props.location.search,
            state: { from: props.location },
          }}
        />
      );
    } else {
      return <Component {...props} />;
    }
  };

  return <Route {...rest} render={renderRedirect} />;
};

const mapStateToProps = ({ subscription }) => ({
  isSubscriptionExpired:
    subscription?.subscriptionData?.userTrial?.isTrialExpired &&
    subscription.subscriptionData?.currentPlan?.status === "Expired",
  isSubscriptionPaused:
    subscription?.subscriptionData?.currentPlan?.status === "Paused",
});
export default connect(mapStateToProps, null)(SubscriptionExpiredRedirect);
