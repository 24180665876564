import React from "react";
import MultipleChoicePreview from "../form-builder-components/form-builder-response-settings/multiple-choice/multiple-choice-preview";
import TextPreview from "../form-builder-components/form-builder-response-settings/text/text-preview";
import NumberPreview from "../form-builder-components/form-builder-response-settings/number/number-preview";
import CheckboxPreview from "../form-builder-components/form-builder-response-settings/checkbox/checkbox-preview";
import DateTimePreview from "../form-builder-components/form-builder-response-settings/date-time/date-time-preview";
import ImagePreview from "../form-builder-components/form-builder-response-settings/image/image-preview";
import PDFPreview from "../form-builder-components/form-builder-response-settings/pdf/pdf-preview";
import SliderPreview from "../form-builder-components/form-builder-response-settings/slider/slider-preview";
import InstructionsPreview from "../form-builder-components/form-builder-response-settings/instructions/instructions-preview";
import LocationPreview from "../form-builder-components/form-builder-response-settings/location/location-preview";
import SignaturePreview from "../form-builder-components/form-builder-response-settings/signature/signature-preview";
import GlobalListPreview from "../form-builder-components/form-builder-response-settings/globalList/globalList-preview";

const getPreview = (
  view,
  index,
  question,
  getAnswer,
  actionItem,
  addUpdateAnwser,
  removeFromAnswers
) => {
  return (
    <>
      {question?.type === "COMPLEX_MULTIPLE_CHOICE" ? (
        <MultipleChoicePreview
          actionItem={actionItem}
          question={question}
          addUpdateAnwser={addUpdateAnwser}
          getAnswer={getAnswer}
          removeFromAnswers={removeFromAnswers}
          view={view}
          index={index}
        />
      ) : question?.type === "text" ? (
        <TextPreview
          actionItem={actionItem}
          question={question}
          addUpdateAnwser={addUpdateAnwser}
          getAnswer={getAnswer}
          view={view}
          index={index}
        />
      ) : question?.type === "number" ? (
        <NumberPreview
          actionItem={actionItem}
          question={question}
          addUpdateAnwser={addUpdateAnwser}
          getAnswer={getAnswer}
          view={view}
          index={index}
        />
      ) : question?.type === "checkbox" ? (
        <CheckboxPreview
          actionItem={actionItem}
          question={question}
          addUpdateAnwser={addUpdateAnwser}
          removeFromAnswers={removeFromAnswers}
          getAnswer={getAnswer}
          view={view}
          index={index}
        />
      ) : question?.type === "date time" ? (
        <DateTimePreview
          actionItem={actionItem}
          question={question}
          addUpdateAnwser={addUpdateAnwser}
          getAnswer={getAnswer}
          view={view}
          index={index}
        />
      ) : question?.type === "image" ? (
        <ImagePreview
          actionItem={actionItem}
          question={question}
          addUpdateAnwser={addUpdateAnwser}
          getAnswer={getAnswer}
          view={view}
          index={index}
        />
      ) : question?.type === "pdf" ? (
        <PDFPreview
          actionItem={actionItem}
          question={question}
          addUpdateAnwser={addUpdateAnwser}
          getAnswer={getAnswer}
          view={view}
          index={index}
        />
      ) : question?.type === "slider" ? (
        <SliderPreview
          actionItem={actionItem}
          question={question}
          addUpdateAnwser={addUpdateAnwser}
          getAnswer={getAnswer}
          view={view}
          index={index}
        />
      ) : question?.type === "instructions" ? (
        <InstructionsPreview
          actionItem={actionItem}
          question={question}
          addUpdateAnwser={addUpdateAnwser}
          getAnswer={getAnswer}
          view={view}
          index={index}
        />
      ) : question?.type === "location" ? (
        <LocationPreview
          actionItem={actionItem}
          question={question}
          addUpdateAnwser={addUpdateAnwser}
          getAnswer={getAnswer}
          view={view}
          index={index}
        />
      ) : question?.type === "signature" ? (
        <SignaturePreview
          actionItem={actionItem}
          question={question}
          addUpdateAnwser={addUpdateAnwser}
          getAnswer={getAnswer}
          view={view}
          index={index}
        />
      ) : question?.type === "DROPDOWN" ? (
        <GlobalListPreview
          actionItem={actionItem}
          question={question}
          addUpdateAnwser={addUpdateAnwser}
          getAnswer={getAnswer}
          view={view}
          index={index}
        />
      ) : (
        ""
      )}
    </>
  );
};

const newQuestion = (questionUuid, number) => {
  const foundObject = number?.find((item) => item?.question === questionUuid);
  if (foundObject) {
    return foundObject?.questionNo;
  } else {
    return 0;
  }
};

const QuestionPreview = ({
  question,
  addUpdateAnwser,
  number,
  getAnswer,
  view,
  index,
  actionItem = newQuestion(question?.questionUuid, number),
  removeFromAnswers,
}) => {
  return (
    <>
      <div
        className="form-question-wrapper"
        style={!question.settings.isEnabled ? { display: "none" } : {}}
      >
        {getPreview(
          view,
          index,
          question,
          getAnswer,
          actionItem,
          addUpdateAnwser,
          removeFromAnswers
        )}
      </div>
    </>
  );
};

export default QuestionPreview;
