import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import LoadingBar from "react-redux-loading-bar";
import AppRoutes from "../../AppRoutes";
import IconScrollTop from "../../assets/images/icon-scroll-top.svg";
import AppHeader from "../../components/Layout/header";
import { useEffect, useState } from "react";

const AppLayout = ({ user, currentRoute, statePath }) => {
  const location = useLocation();

  const [isAppWrapperContainer, setAppWrapperContainer] = useState(true);

  useEffect(() => {
    const isAnalyticsPage = location.pathname === "/analytics";
    setAppWrapperContainer(!isAnalyticsPage);
    const body = document.getElementsByTagName("body")[0];
    if (window.location.pathname === "/analytics") {
      body.style.marginBottom = "0";
      body.style.backgroundColor = "#f2f3f8";
    } else {
      body.style.marginBottom = "";
      body.style.backgroundColor = "";
    }
  }, [location]);

  const scrollToTopButton = document.getElementById("scrollTop");
  const scrollFunc = () => {
    let y = window.scrollY;

    if (y > 0) {
      scrollToTopButton.className = `btn-scroll-top ${window.showScroll ? "show" : "hide"}`;
    } else {
      scrollToTopButton.className = "btn-scroll-top hide";
    }
  };

  window.addEventListener("scroll", scrollFunc);

  const scrollToTop = () => {
    const c = document.documentElement.scrollTop || document.body.scrollTop;

    if (c > 0) {
      window.requestAnimationFrame(scrollToTop);
      window.scrollTo(0, c - c / 10);
    }
  };

  scrollToTopButton.onclick = function (e) {
    scrollToTop();
  };

  return (
    <>
      <>
        <LoadingBar className="loading-bar" />
        <AppHeader />
        {isAppWrapperContainer ? (
          <div className="app-wrapper container">
            <AppRoutes />
          </div>
        ) : (
          <AppRoutes />
        )}
      </>
      <a href="#scrollTop" className="btn-scroll-top hide" id="scrollTop">
        <img src={IconScrollTop} alt="IconScrollTop icon"></img>
      </a>
    </>
  );
};
const mapStateToProps = ({ authentication }) => ({
  user: authentication.user,
  currentRoute: authentication.currentRoute,
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(AppLayout);
