import axios from "axios";
import { FAILURE, REQUEST, SUCCESS } from "../action-type.util";

export const ACTION_TYPES = {
  USER_CREATE: "user/Created",
  GET_USERS: "get/users",
  GET_USER_BY_ID: "getUserById/users",
  GET_USER_DATA_BY_ID: "getUserDataById/users",
  UPDATE_USER_PREMIUM_BY_ID: "updateUserPremiumById/users",
  UPDATED_USER_BY_ID: "update/user",
  UPDATE_USER_RESPONSIBILITY: "update/userResponsibility",
  USER_DISPATCH_RULE: "user/dispatch-rule",
};

const initialState = {
  loading: false,
  error: null,
  allUsers: [],
  userForEdit: {},
};

export const UserState = initialState;
// Reducer
// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.USER_CREATE):
    case REQUEST(ACTION_TYPES.GET_USERS):
    case REQUEST(ACTION_TYPES.GET_USER_BY_ID):
    case REQUEST(ACTION_TYPES.GET_USER_DATA_BY_ID):
    case REQUEST(ACTION_TYPES.UPDATE_USER_PREMIUM_BY_ID):
    case REQUEST(ACTION_TYPES.UPDATED_USER_BY_ID):
    case REQUEST(ACTION_TYPES.USER_DISPATCH_RULE):
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FAILURE(ACTION_TYPES.USER_CREATE):
    case FAILURE(ACTION_TYPES.GET_USERS):
    case FAILURE(ACTION_TYPES.GET_USER_BY_ID):
    case FAILURE(ACTION_TYPES.GET_USER_DATA_BY_ID):
    case FAILURE(ACTION_TYPES.UPDATE_USER_PREMIUM_BY_ID):
    case FAILURE(ACTION_TYPES.UPDATED_USER_BY_ID):
    case FAILURE(ACTION_TYPES.USER_DISPATCH_RULE):
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SUCCESS(ACTION_TYPES.GET_USER_BY_ID):
      return {
        ...state,
        loading: false,
        error: null,
        userForEdit: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.GET_USERS):
      return {
        ...state,
        loading: false,
        error: null,
        allUsers: action.payload.data?.map((item) => ({
          ...item,
          fullName: item.firstName + " " + item.lastName,
        })),
      };
    case SUCCESS(ACTION_TYPES.UPDATED_USER_BY_ID):
    case SUCCESS(ACTION_TYPES.UPDATE_USER_PREMIUM_BY_ID):
    case SUCCESS(ACTION_TYPES.USER_CREATE):
      return {
        ...state,
      };

    default:
      return state;
  }
};

// Actions

export const createUser = (user) => async (dispatch) => {
  let createUser = {
    role:
      user.roleSetting?.role?.inspectorRole && user.roleSetting?.role?.managerRole
        ? "manager/inspector"
        : user.roleSetting?.role?.inspectorRole
        ? "user"
        : user.roleSetting?.role?.managerRole
        ? "manager"
        : !user.roleSetting?.role?.inspectorRole && !user.roleSetting?.role?.managerRole
        ? "notRegistered"
        : user.role,

    fullName: user.firstName + " " + user.lastName,
    email: user.email,
    password: user.password,
    firstName: user.firstName,
    lastName: user.lastName,
    mobile: user.mobile,
    active: user.active,
    isInvited: user.isInvited,
    inspectorPortal: user.inspectorPortal,
    managerPortal: user.managerPortal,
    approvalReport: { ...user.approvalReport },
    reportSubmission: { ...user.reportSubmission },
    rejectReport: { ...user.rejectReport },
    assignForm: { ...user.assignForm },
    language: "ENGLISH",
    userInfoChange: true,
    timeZone: user.timeZone ? user.timeZone.label : "",
    roleSetting: user.roleSetting ? user.roleSetting.value : "",
    isUserSeated: user?.isUserSeated || false,
    roleName: user?.roleName,
  };
  return await dispatch({
    type: ACTION_TYPES.USER_CREATE,
    payload: axios.post(`/users?inviteLink=${user.inviteLink}&changePasswordLink=${user.passwordLink}`, createUser),
  });
};

export const getAllUsers = (filterState) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.GET_USERS,
    payload: axios.get(
      `/users?${
        filterState
          ? `${filterState.role ? `role=${filterState.role.value}` : ""}${filterState.active !== "" ? `&active=${filterState.active}` : ""}${
              filterState.isEmailVerified !== "" ? `&isEmailVerified=${filterState.isEmailVerified}` : ""
            }`
          : ""
      }`
    ),
  });
};

export const getUserById = (id) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.GET_USER_BY_ID,
    payload: axios.get(`/users/${id}`),
  });
};

export const getUserDataById = (id) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.GET_USER_DATA_BY_ID,
    payload: axios.get(`/users/user-data/${id}`),
  });
};

export const updateUserById = (user) => async (dispatch) => {
  let userRoleSetting = user?.roleSetting?.role ? { ...user?.roleSetting?.role } : { ...user?.roleSetting };

  let updateUser = {
    role:
      userRoleSetting?.originalName === "owner"
        ? "admin"
        : userRoleSetting?.inspectorRole && userRoleSetting?.managerRole
        ? "manager/inspector"
        : userRoleSetting?.inspectorRole
        ? "user"
        : userRoleSetting?.managerRole
        ? "manager"
        : !userRoleSetting?.inspectorRole && !userRoleSetting?.managerRole
        ? "notRegistered"
        : user?.role,

    email: user.email,
    password: user.password,
    firstName: user.firstName,
    fullName: user.firstName + " " + user.lastName,
    lastName: user.lastName,
    mobile: user.mobile,
    active: user.active,
    isInvited: user.isInvited,
    inspectorPortal: user.inspectorPortal,
    managerPortal: user.managerPortal,
    approvalReport: { ...user.approvalReport },
    reportSubmission: { ...user.reportSubmission },
    rejectReport: { ...user.rejectReport },
    assignForm: { ...user.assignForm },
    userDevices: user.userDevices?.length ? [...user.userDevices] : [],
    userCards: user.userCards?.length ? [...user.userCards] : [],
    timeZone: user?.timeZone?.label ? user.timeZone.label : user.timeZone,
    profileImage: user.profileImage,
    paymentPlan: user.paymentPlan && user.paymentPlan,
    subscription: user.subscription && user.subscription,
    userInfoChange: user.userInfoChange,
    language: user.language ? user.language : "ENGLISH",
    roleSetting: user?.roleSetting?.value ? user?.roleSetting?.value : user?.roleSetting?.id,
    isPremium: user.isPremium,
    renewPayment: user.renewPayment,
    isUserSeated: user?.isUserSeated,
  };
  return await dispatch({
    type: ACTION_TYPES.GET_USER_BY_ID,
    payload: axios.patch(
      `/users/${user.id}?inviteLink=${user.inviteLink ? user.inviteLink : false}&changePasswordLink=${user.passwordLink ? user.passwordLink : false}`,
      updateUser
    ),
  });
};

export const userPremiumById = (user) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.UPDATE_USER_PREMIUM_BY_ID,
    payload: axios.patch(`/users/${user.id}/premium`, {
      isPremium: user.isPremium,
    }),
  });
};

export const userDeleteById = (id) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.GET_USER_BY_ID,
    payload: axios.delete(`/users/${id}`),
  });
};

export const assignUserResponsibility = (existingId, newId, action) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.UPDATE_USER_RESPONSIBILITY,
    payload: axios.patch(`/users/assign-responsability/${existingId}/${newId}?action=${action}`),
  });
};

export const userDispatchRule = (id) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.USER_DISPATCH_RULE,
    payload: axios.get(`/users/dispatchRule/${id}`),
  });
};
