import React, { useState, useEffect } from "react";
import I18n from "../../../languageSelector";
import SignatureCanvas from "react-signature-canvas";
import ReportPdfImages from "../report-pdf-images/reportPdfImages.js";
import { TEXT_DIRECTION } from "../../../../../helper/helperFunctions";

const SignaturePdfPreview = ({
  getAnswer = (uuid) => {
    console.log(uuid);
    return undefined;
  },
  question,
  failStatus,
  index,
  hideAndShow,
  setImageIndex,
  setShowImageSrc,
  setShowImageDialog,
  setShowSection,
  setTotalAnswered,
  showDocumentMedia,
  imageCountData,
}) => {
  let sigRef = React.useRef(null);

  const [answerName, setAnswerName] = useState("");
  const [answerValue, setAnswerValue] = useState([]);
  const [note, setNote] = useState("");
  const [photo, setPhoto] = useState("");

  useEffect(() => {
    if (question && question.questionUuid) {
      let answer = getAnswer(question.questionUuid);
      if (answer) {
        setAnswerValue([...answer.signature]);
        setAnswerName(answer.signatureName);
        setNote(answer.note);
        setPhoto(answer.image);

        if (
          answer?.signature ||
          answer?.signatureName ||
          answer?.note ||
          answer?.image
        ) {
          setShowSection(true);
          setTotalAnswered((prev) => prev + 1);
        }
      }
    }
    // eslint-disable-next-line
  }, [question]);
  useEffect(() => {
    if (answerValue && answerValue.length && !failStatus) {
      // setTimeout(() => {
      sigRef?.fromData(answerValue);
      sigRef?.off();
      // }, 2000);
    }
    // eslint-disable-next-line
  }, [answerValue]);

  const [imageCount, setImageCount] = useState(0);

  useEffect(() => {
    if (question && question.questionUuid && imageCountData?.length) {
      let data = imageCountData.filter((item) => {
        return item.questionId === question.questionUuid;
      });
      if (data.length) {
        setImageCount(data[0].imageCountStart);
      }
    }
  }, [question, imageCountData]);

  return (
    <>
      {!failStatus ? (
        hideAndShow?.content?.all ? (
          <div className="table-body">
            <div
              className="row table-row ml-0 mr-0   question status-na"
              style={{
                boxShadow: "0px 5px 5px rgba(0,0,0,.10)",
                backgroundColor: "#ffffff",
              }}
            >
              <div
                className={`col-lg-8 col-md-6 mb-3 table-column d-flex ${
                  TEXT_DIRECTION() === "rtl" ? "pr-0" : "pl-0"
                }`}
              >
                {question?.questionNumber}.{"   "}
                {question?.settings?.questionText === "Add a new question" ? (
                  <I18n lng="Form.AddANewQuestion" />
                ) : (
                  question?.settings?.questionText
                )}
              </div>
              <div className="col-lg-4 col-md-6 table-column question-type">
                {answerValue || answerName ? (
                  <div className="sig-wrap ">
                    <div
                      className="signature w-100"
                      style={
                        TEXT_DIRECTION() === "rtl"
                          ? { maxWidth: "none", textAlign: "start" }
                          : { maxWidth: "none", textAlign: "end" }
                      }
                    >
                      <SignatureCanvas
                        ref={(ref) => {
                          sigRef = ref;
                        }}
                        canvasProps={{
                          width: 500,
                          height: 350,
                          className: "sigCanvas",
                        }}
                      />
                    </div>
                    <p className="custom-text text-center">
                      {answerName ? answerName : ""}
                    </p>
                  </div>
                ) : (
                  ""
                )}

                {note || photo.length ? (
                  <div
                    className="table-column attachments-wrapper mt-3"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <>
                      <fieldset>
                        <legend
                          className={`${
                            TEXT_DIRECTION() === "rtl"
                              ? "legend-arabic"
                              : "legend"
                          }`}
                        >
                          <I18n lng="global.InspectorNotes" />
                        </legend>
                        {note ? (
                          <div className="notes">
                            {/* <label>
                          <I18n lng="global.Notes" />:
                        </label> */}
                            <p>{note}</p>
                          </div>
                        ) : null}
                        {photo.length ? (
                          <div className="thumbnail-wrapper">
                            <ReportPdfImages
                              photo={photo}
                              imageCount={imageCount}
                              setImageIndex={setImageIndex}
                              setShowImageSrc={setShowImageSrc}
                              showDocumentMedia={showDocumentMedia}
                              setShowImageDialog={setShowImageDialog}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </fieldset>
                    </>
                  </div>
                ) : (
                  ""
                )}
              </div>{" "}
            </div>
          </div>
        ) : hideAndShow?.content?.answered &&
          (answerName || note || photo?.length) ? (
          <div className="table-body">
            <div
              className="table-row question status-na"
              style={{
                boxShadow: "0px 5px 5px rgba(0,0,0,.10)",
                backgroundColor: "#ffffff",
              }}
            >
              <div
                className={`col-lg-8 col-md-6 mb-3 table-column d-flex ${
                  TEXT_DIRECTION() === "rtl" ? "pr-0" : "pl-0"
                }`}
              >
                {question?.questionNumber}.{"   "}
                {question?.settings?.questionText === "Add a new question" ? (
                  <I18n lng="Form.AddANewQuestion" />
                ) : (
                  question?.settings?.questionText
                )}
              </div>
              <div className="col-lg-4 col-md-6 table-column question-type">
                <div className="sig-wrap ">
                  <div className="signature">
                    {index + 1}.{" "}
                    <SignatureCanvas
                      ref={(ref) => {
                        sigRef = ref;
                      }}
                      canvasProps={{
                        width: 500,
                        height: 350,
                        className: "sigCanvas",
                      }}
                    />
                    <p className="custom-text">
                      {answerName ? answerName : ""}
                    </p>
                  </div>
                </div>
                {note || photo.length ? (
                  <div
                    className="table-column attachments-wrapper mt-3"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <>
                      <fieldset>
                        <legend
                          className={`${
                            TEXT_DIRECTION() === "rtl"
                              ? "legend-arabic"
                              : "legend"
                          }`}
                        >
                          <I18n lng="global.InspectorNotes" />
                        </legend>
                        {note ? (
                          <div className="notes">
                            {/* <label>
                          <I18n lng="global.Notes" />:
                        </label> */}
                            <p>{note}</p>
                          </div>
                        ) : null}
                        {photo.length ? (
                          <div className="thumbnail-wrapper">
                            <ReportPdfImages
                              photo={photo}
                              imageCount={imageCount}
                              setImageIndex={setImageIndex}
                              setShowImageSrc={setShowImageSrc}
                              showDocumentMedia={showDocumentMedia}
                              setShowImageDialog={setShowImageDialog}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </fieldset>
                    </>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        ) : hideAndShow?.content?.unAnswered &&
          !answerName &&
          !note &&
          !photo?.length ? (
          <div className="table-body">
            <div
              className="table-row question status-na"
              style={{
                boxShadow: "0px 5px 5px rgba(0,0,0,.10)",
                backgroundColor: "#ffffff",
              }}
            >
              {" "}
              <div className="table-column">
                {question?.questionNumber}.{"   "}
                {question?.settings?.questionText === "Add a new question" ? (
                  <I18n lng="Form.AddANewQuestion" />
                ) : (
                  question?.settings?.questionText
                )}
              </div>
              <div className="table-column question-type">
                <div className="sig-wrap ">
                  <div className="signature">
                    {index + 1}.{" "}
                    <SignatureCanvas
                      ref={(ref) => {
                        sigRef = ref;
                      }}
                      canvasProps={{
                        width: 500,
                        height: 350,
                        className: "sigCanvas",
                      }}
                    />
                  </div>
                  <p className="custom-text">{answerName ? answerName : ""}</p>
                </div>
              </div>{" "}
              <div className="table-column"></div>
              <div className="table-column">
                {note ? (
                  <div className="notes">
                    <label>
                      <I18n lng="global.Notes" />:
                    </label>
                    <p>{note}</p>
                  </div>
                ) : null}
                <div className="thumbnail-wrapper">
                  <ReportPdfImages
                    photo={photo}
                    imageCount={imageCount}
                    setImageIndex={setImageIndex}
                    setShowImageSrc={setShowImageSrc}
                    showDocumentMedia={showDocumentMedia}
                    setShowImageDialog={setShowImageDialog}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : null
      ) : null}
    </>
  );
};
export default SignaturePdfPreview;
