import React, { useState } from "react";
import { connect } from "react-redux";
import IconEye from "../../../../../assets/images/icon-eye.svg";
import IconMenu from "../../../../../assets/images/icon-menu.svg";
import IconPencil from "../../../../../assets/images/icon-pencil.svg";
import IconExport from "../../../../../assets/images/icon-export.svg";
import IconArchive from "../../../../../assets/images/icon-archive.svg";
import IconBookmark from "../../../../../assets/images/icon-bookmark.png";
import IconUnBookmark from "../../../../../assets/images/icon-bookmark.svg";
import IconViewHistory from "../../../../../assets/images/icon-view-history.svg";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import {
  getReportById,
  updateReportStatus,
  getAdminManagerReports,
  getAllReportsWithOutStateChange,
} from "../../../../../redux/reducers/admin-manager-reports/admin-manager-report.reducer";
import I18n, { i18n } from "../../../../private/languageSelector";
import AlertModal from "../../reports-previews/alertModal/alerModal";
import { canAccessTheModule } from "../../../../../helper/helperFunctions";
import ConfirmationDialog from "../../../../../components/dialogs/confirmation-dialog";
import {
  deleteBookmark,
  createBookmark,
} from "../../../../../redux/reducers/form/form.reducer";

const ListItemMenu = ({
  // isSelfAssign,
  viewHistoryReport,
  id,
  form,
  bookmarkId,
  openReportViewDialog,
  deleteBookmark,
  createBookmark,
  setLoading,
  isBookmarked,
  openReportEditDialog,
  openReportExportViewDialog,
  reportItem,
  archiveReports,
  currentRole,
  reportForEdit,
  getReportById,
  status,
  user,
  reportUUid,
  updateReportStatus,
  getAllReportsWithOutStateChange,
  bookmarked,
  rolesAccess,
}) => {
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [openAlertFormReason, setOpenAlertFormReason] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  const rejectReasonAlertDialog = () => {
    if (id) {
      getReportById(id).then((res) => {
        if (currentRole && res.value.data.status === "reject") {
          setOpenAlertFormReason(true);
        } else if (currentRole && res.value.data.status !== "reject") {
          openReportEditDialog(id);
        }
      });
    } else {
      // openReportViewDialog(id);
    }
  };
  const isAssignee = reportItem?.dispatchRules.assignees?.includes(user?.id);

  const isApprover = reportItem?.dispatchRules.approvedManagers?.includes(
    user?.id
  );
  const isViewVisible = isAssignee || isApprover;
  const isReviewVisible =
    (isAssignee && isApprover) || (!isAssignee && isApprover);
  return (
    <>
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle>
          <img src={IconMenu} alt="Menu" />
        </DropdownToggle>
        <DropdownMenu right>
          <>
            <DropdownItem
              className="action action-view"
              onClick={() => openReportViewDialog(id)}
              style={{
                display: isViewVisible
                  ? "block"
                  : currentRole === "user" || currentRole === "manager"
                  ? "block"
                  : "none",
              }}
            >
              <span className="icon-wrap">
                <img src={IconEye} alt="IconEye" />
              </span>
              {isReviewVisible && currentRole !== "user"
                ? i18n("Reports.Review")
                : i18n("global.View")}
            </DropdownItem>
            {currentRole === "user" &&
            status !== "accept" &&
            status !== "submit" &&
            status !== "reSubmit" &&
            form !== null ? (
              <DropdownItem
                className="action action-edit"
                onClick={(e) => {
                  e.preventDefault();
                  rejectReasonAlertDialog();
                }}
              >
                <span className="icon-wrap">
                  <img src={IconPencil} alt="IconPencil" />
                </span>
                <I18n lng="global.Edit" />
              </DropdownItem>
            ) : null}

            <DropdownItem className="action action-view">
              {isBookmarked ? (
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    deleteBookmark(bookmarkId).then(() => {
                      setLoading(true);
                    });
                  }}
                >
                  <span className="icon-wrap">
                    <img src={IconUnBookmark} alt="IconBookmark" />
                  </span>
                  <I18n lng="global.UnBookmark" />
                </div>
              ) : (
                <>
                  <div
                    onClick={(e) => {
                      let data = {
                        module: "REPORT",
                        moduleId: id,
                      };
                      e.preventDefault();
                      createBookmark(data).then(() => {
                        setLoading(true);
                      });
                    }}
                  >
                    <span className="icon-wrap">
                      <img src={IconBookmark} alt="IconBookmark" />
                    </span>
                    <I18n lng="global.Bookmark" />
                  </div>
                </>
              )}
            </DropdownItem>
            {currentRole === "user"
              ? canAccessTheModule(
                  rolesAccess?.inspector?.access?.exportReport,
                  <DropdownItem
                    className="action action-export"
                    onClick={(e) => {
                      e.preventDefault();
                      openReportExportViewDialog(id, reportUUid);
                    }}
                  >
                    <span className="icon-wrap">
                      <img src={IconExport} alt="IconExport" />
                    </span>
                    <I18n lng="global.Export" />
                  </DropdownItem>
                )
              : ""}

            {currentRole !== "user" ? (
              <>
                {status === "reSubmit" ? (
                  <DropdownItem
                    className="action action-view-history"
                    onClick={(e) => {
                      e.preventDefault();
                      viewHistoryReport(id);
                    }}
                  >
                    <span className="icon-wrap">
                      <img src={IconViewHistory} alt="IconViewHistory" />
                    </span>
                    <I18n lng="global.ViewHistory" />
                  </DropdownItem>
                ) : null}
                {status !== "accept" &&
                status !== "submit" &&
                status !== "reSubmit" &&
                reportItem?.assignee?._id?._id === user?.id &&
                form !== null ? (
                  <DropdownItem
                    className="action action-edit"
                    onClick={(e) => {
                      e.preventDefault();
                      rejectReasonAlertDialog();
                    }}
                  >
                    <span className="icon-wrap">
                      <img src={IconPencil} alt="IconPencil" />
                    </span>
                    <I18n lng="global.Edit" />
                  </DropdownItem>
                ) : null}
                {isReviewVisible ? (
                  <DropdownItem
                    className="action action-export"
                    onClick={(e) => {
                      e.preventDefault();
                      openReportExportViewDialog(id, reportUUid);
                    }}
                  >
                    <span className="icon-wrap">
                      <img src={IconExport} alt="IconExport" />
                    </span>
                    <I18n lng="global.Export" />
                  </DropdownItem>
                ) : null}

                <DropdownItem
                  className="action action-archive"
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenConfirmationDialog(true);
                  }}
                >
                  <span className="icon-wrap">
                    <img src={IconArchive} alt="IconArchive" />
                  </span>
                  <I18n lng="global.Archive" />
                </DropdownItem>
              </>
            ) : null}
          </>
        </DropdownMenu>
      </Dropdown>
      <AlertModal
        isAssignee={isAssignee}
        show={openAlertFormReason}
        secondaryAction={() => {
          setOpenAlertFormReason(false);
        }}
        role={currentRole}
        primaryAction={() => {
          openReportEditDialog(id);
          setOpenAlertFormReason(false);
          if (currentRole && reportForEdit.status !== "reject") {
            updateReportStatus(id, { status: "incomplete" }).then((res) =>
              getAllReportsWithOutStateChange(false)
            );
          }
        }}
        reportForEdit={reportForEdit}
        setReasonForRejection={setOpenAlertFormReason}
        reason={reportForEdit?.reason ? reportForEdit?.reason : ""}
      />
      <ConfirmationDialog
        showDlg={openConfirmationDialog}
        title={i18n("global.Note")}
        classes="modal-alert"
        iconCloseDlg
        content={
          <p>
            <I18n lng="Reports.ArchiveThisReport" />
          </p>
        }
        primaryActionTitle={i18n("global.Yes")}
        secondryActionTitle={i18n("global.No")}
        secondaryAction={() => setOpenConfirmationDialog(false)}
        primaryAction={() => {
          archiveReports(id, "myReport");
          setOpenConfirmationDialog(false);
        }}
        primaryActionClasses={"btn-success"}
        secondryActionClasses={"btn-danger"}
      />
    </>
  );
};

const mapStateToProps = ({ authentication, adminManagerReports }) => ({
  currentRole: authentication.currentRole,
  user: authentication.user,
  rolesAccess: authentication.userRoleAccess,
  reportForEdit: adminManagerReports.reportForEdit,
});
const mapDispatchToProps = {
  getReportById,
  updateReportStatus,
  getAdminManagerReports,
  deleteBookmark,
  createBookmark,
  getAllReportsWithOutStateChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListItemMenu);
