import React, { useMemo, useState } from "react";
import I18n from "../../../../private/languageSelector";
import IconMenu from "../../../../../assets/images/icon-menu.svg";
import { useGlobalListUIContext } from "../global-list-ui-context";
import IconPencil from "../../../../../assets/images/icon-pencil.svg";
import IconDelete from "../../../../../assets/images/icon-delete.svg";
import IconDuplicate from "../../../../../assets/images/icon-duplicate.svg";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

const ListItemMenu = ({ globalList }) => {
  const globalListUIContext = useGlobalListUIContext();

  const GlobalListUIProps = useMemo(() => {
    return {
      openGlobalListEdit: globalListUIContext.openGlobalListEdit,
      openGlobalListDelete: globalListUIContext.openGlobalListDelete,
      openGlobalListDuplicate: globalListUIContext.openGlobalListDuplicate,
    };
  }, [globalListUIContext]);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  return (
    <>
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle>
          <img src={IconMenu} alt="Menu" />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem
            className="action action-edit"
            onClick={(e) => {
              e.preventDefault();
              GlobalListUIProps.openGlobalListEdit(globalList.id);
            }}
          >
            <span className="icon-wrap">
              <img src={IconPencil} alt="IconPencil" />
            </span>
            <I18n lng="global.Edit" />
          </DropdownItem>

          <DropdownItem
            className="action action-duplicate"
            onClick={(e) => {
              e.preventDefault();
              GlobalListUIProps.openGlobalListDuplicate(globalList.id);
            }}
          >
            <span className="icon-wrap">
              <img src={IconDuplicate} alt="IconDuplicate" />
            </span>
            <I18n lng="global.Duplicate" />
          </DropdownItem>

          <DropdownItem
            className="action action-archive"
            onClick={(e) => {
              e.preventDefault();
              GlobalListUIProps.openGlobalListDelete(globalList.id);
            }}
          >
            <span className="icon-wrap icon-red">
              <img src={IconDelete} className="icon-red" alt="Icon Delete" />
            </span>
            <I18n lng="global.Delete" />
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default ListItemMenu;
