import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import Attachment from "../snipets/attachment";
import { BsInfoCircleFill } from "react-icons/bs";
import I18n, { i18n } from "../../../../../languageSelector";

const NumberPreview = ({
  view,
  question,
  actionItem,
  currentRole,
  addUpdateAnwser = (answer) => {
    console.log(answer);
  },
  getAnswer = (uuid) => {
    console.log(uuid);
    return undefined;
  },
}) => {
  const [value, setValue] = useState("");
  const [notes, setNotes] = useState("");
  const [photo, setPhoto] = useState([]);
  const [answerSchema, setAnserSchema] = useState({});
  const [answerNumber, setAnswerNumber] = useState("");
  const [questionImageURL, setQuestionImageURL] = useState([]);
  const [isQuestionLoading, setIsQuestionLoading] = useState(true);
  const [questionPreview, setQuestionPreview] = useState(question);

  useEffect(() => {
    setIsQuestionLoading(true);
  }, []);

  useEffect(() => {
    if (question) {
      setQuestionPreview(question);

      if (question?.questionUuid) {
        const answer = getAnswer(question?.questionUuid);
        if (answer?.questionUuid) {
          setAnserSchema({ ...answer });
          setValue(answer.value);
          setNotes(answer.note);
          setQuestionImageURL([...answer.image]);

          if (answer.image && answer.image.length) {
            setPhoto([...answer.image]);
          }
          setIsQuestionLoading(false);
        } else {
          setIsQuestionLoading(false);
          setValue("");
        }
      }
    }
    // eslint-disable-next-line
  }, [question]);

  const imageDeleteHandler = (index) => {
    let removeImageArray =
      answerSchema && answerSchema?.image?.length
        ? [...answerSchema.image]
        : [...questionImageURL];

    removeImageArray.splice(index, 1);

    if (answerSchema.image?.length || questionImageURL?.length) {
      if (!isQuestionLoading) {
        let answerToSave = {
          questionUuid: question.questionUuid,
          value: value,
          note: notes,
          image: [...removeImageArray],
        };
        addUpdateAnwser(answerToSave);
      }
      photo.splice(index, 1);
      setPhoto([...photo]);
    } else {
      setPhoto([]);
    }
  };

  useEffect(() => {
    let answerToSave = {
      questionUuid: question.questionUuid,
      value: value,
      note: notes,
      image: questionImageURL?.length ? [...questionImageURL] : [],
    };
    if (!isQuestionLoading) {
      addUpdateAnwser(answerToSave);
    }
    // eslint-disable-next-line
  }, [value, notes]);

  const imageAddHandler = () => {
    if (photo && photo.length) {
      let answerToSave = {
        questionUuid: question.questionUuid,
        value: value,
        note: notes,
        image: [...photo],
      };
      setQuestionImageURL([...photo]);

      if (!isQuestionLoading) {
        addUpdateAnwser(answerToSave);
      }
    }
  };

  useEffect(() => {
    if (value !== "") {
      setAnswerNumber(value);
    }
  }, [value]);

  return (
    <div id={question?.questionUuid} className="multiple-choice">
      <div className="d-flex justify-content-between question-title h-auto">
        <strong>
          {questionPreview?.settings.questionText === "Type Instructions" ? (
            <I18n lng="Form.TypeInstructions" />
          ) : questionPreview?.settings.questionText ===
            "Add a new question" ? (
            <>
              {question?.questionNumber
                ? question?.questionNumber
                : actionItem?.questionNo === undefined
                ? actionItem
                : actionItem?.questionNo
                ? actionItem?.questionNo + 1
                : 1}
              .{"   "}
              <I18n lng="Form.AddANewQuestion" />{" "}
            </>
          ) : (
            `${
              question?.questionNumber
                ? question?.questionNumber
                : actionItem?.questionNo === undefined
                ? actionItem
                : actionItem?.questionNo
                ? actionItem?.questionNo + 1
                : 1
            }. ${questionPreview?.settings.questionText}`
          )}
          {question.settings.isMandatory && <span className="required"></span>}
        </strong>
        <div className="d-flex">
          {actionItem.isScored && (
            <span className="mr-2 score">
              {<I18n lng="Form.Score" />} : 1/1 (100%)
            </span>
          )}

          {question.settings.description ? (
            <>
              <p
                data-for={`${question.settings.questionText}`}
                data-tip={`${question.settings.description}`}
                data-iscapture="true"
              >
                <BsInfoCircleFill color={"C4C4C4"} />
              </p>
              <ReactTooltip
                id={`${question.settings.questionText}`}
                place="top"
                type="dark"
                effect="float"
                multiline={true}
              />
            </>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="attachment-preview">
        {question?.settings?.photo ? (
          <img src={question?.settings?.photo} alt="Attachment Preview" />
        ) : (
          ""
        )}
      </div>
      <div className="input-group">
        <input
          className="form-control"
          placeholder={
            question.settings.numberType === "TEMPERATURE"
              ? i18n("Form.Addtemperature")
              : i18n("Form.Addnumber")
          }
          type="number"
          value={answerNumber}
          onChange={(e) => setAnswerNumber(e.target.value)}
          onBlur={() => setValue(answerNumber)}
          onWheel={(e) => e.target.blur()}
          // disabled={view}
        />
        {question.settings.numberType === "TEMPERATURE" ? (
          <div className="input-group-append">
            {question.settings.measurement === "FAHRENHEIT" ? "℉" : "℃"}{" "}
          </div>
        ) : null}
      </div>
      {
        <Attachment
          canComment={
            answerNumber === "" ? false : question?.settings?.canComment
          }
          canAttachPhoto={
            answerNumber === "" ? false : question?.settings?.canAttachPhoto
          }
          notes={notes}
          setNotes={setNotes}
          photo={photo}
          setPhoto={setPhoto}
          imageDeleteHandler={imageDeleteHandler}
          imageAddHandler={imageAddHandler}
        />
      }
    </div>
  );
};

const mapStateToProps = ({ authentication }) => ({
  currentRole: authentication.currentRole,
});

export default connect(mapStateToProps, null)(NumberPreview);
