import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import Attachment from "../snipets/attachment";
import { BsInfoCircleFill } from "react-icons/bs";
import I18n from "../../../../../languageSelector";
import IconImgThumb from "../../../../../../../assets/images/icon-img-thumb.svg";
import { uploadImageWithThumbnail } from "../../../../../../../redux/reducers/form/form.reducer";
import ImageEditorDialog from "../../../../../../../components/dialogs/image-editor-dialog";

const ImagePreview = ({
  question,
  view,
  actionItem,
  addUpdateAnwser = (answer) => {
    console.log(answer);
  },
  getAnswer = (uuid) => {
    console.log(uuid);
    return undefined;
  },
  uploadImageWithThumbnail,
}) => {
  const hiddenFileInput = React.useRef(null);
  const reader = new FileReader();

  const [notes, setNotes] = useState("");
  const [photo, setPhoto] = useState([]);
  const [inspectorImages, setInspectorImages] = useState([]);
  const [questionPreview, setQuestionPreview] = useState(question);
  const [isQuestionLoading, setIsQuestionLoading] = useState(true);
  // const [loadingImageForEditor, setLoadingImageForEditor] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [imageIndex, setImageIndex] = useState(0);
  // eslint-disable-next-line
  const [imagesToShow, setImagesToShow] = useState([]);
  const [image64Data, setimage64Data] = useState("");
  const [imageEditing, setImageEditing] = useState(false);
  // const [isImageUploading, setIsImageUploading] = useState(false);
  const [openEditImageEditor, setOpenEditImageEditor] = useState(false);
  const [openImageEditorDialog, setOpenImageEditorDialog] = useState(false);
  const [waitForImageUpload, setWaitForImageUpload] = useState(false);
  const [loadingForImage, setLoadingForImage] = useState(false);
  const [isImageAdd, setIsImageAdd] = useState(false);

  useEffect(() => {
    if (inspectorImages?.length > 0) {
      setIsImageAdd(true);
    } else {
      setIsImageAdd(false);
    }
  }, [inspectorImages]);

  useEffect(() => {
    setIsQuestionLoading(true);
  }, []);
  useEffect(() => {
    if (imageUrl || image64Data) {
      setWaitForImageUpload(false);
      setLoadingForImage(true);
      setTimeout(() => {
        setWaitForImageUpload(true);
        setLoadingForImage(false);
        setOpenEditImageEditor(true);
        setLoadingForImage(false);
      }, 1000);
    }
  }, [imageUrl, image64Data]);
  // eslint-disable-next-line
  useEffect(async () => {
    if (question) {
      setQuestionPreview(question);

      if (question?.questionUuid) {
        const answer = getAnswer(question?.questionUuid);

        if (answer?.questionUuid) {
          setInspectorImages([...answer.inspectorImages]);
          setNotes(answer.note);
          setIsQuestionLoading(false);
          setPhoto([...answer.inspectorImages]);
        } else {
          setIsQuestionLoading(false);
        }
      }
    }
    // eslint-disable-next-line
  }, [question]);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const imageDeleteHandler = (index) => {
    if (question) {
      setQuestionPreview(question);

      if (question?.questionUuid) {
        const answer = getAnswer(question?.questionUuid);

        if (answer?.questionUuid) {
          answer.inspectorImages.splice(index, 1);
          setInspectorImages([...answer.inspectorImages]);
          setimage64Data("");
          setPhoto([...answer.inspectorImages]);
        }
      }
    }
  };
  const handleChange = async (event) => {
    if (event?.target?.files[0]?.size / 1024 / 1024 <= 5) {
      setLoadingForImage(true);
      reader.readAsDataURL(event?.target?.files[0]);
      reader.onload = function (e) {
        // setIsImageUploading(true);
        var dataURL = reader.result;
        if (dataURL) {
          // let allocatingImageToCanvas = document.getElementsByTagName("canvas");
          // setTimeout(() => {
          //   setTimeout(() => {
          //     for (let i = 0; i < allocatingImageToCanvas?.length; i++) {
          //       imageForCanvas.src = dataURL;
          //       allocatingImageToCanvas[i]
          //         ?.getContext("2d")
          //         ?.drawImage(imageForCanvas, 0, 0);
          //     }
          // setOpenImageEditorDialog(true);
          setLoadingForImage(true);
          //   }, 2000);
          //   setimage64Data(dataURL);
          //   console.log("SET URL");
          // }, 2000);
          // console.log("going to empty URL");
          // setLoadingForImage(true);
          // setimage64Data("");
          // // uploadImageWithThumbnail({
          // //   base64: dataURL,
          // //   existingFileUrl,
          // //   fileType: "png",
          // // }).then((res) => {
          // //   if (res?.value?.data?.image) {
          // //   }
          // // });
          let existingFileUrl = undefined;
          uploadImageWithThumbnail({
            base64: dataURL,
            existingFileUrl,
            fileType: "png",
          })
            .then((response) => {
              // setIsImageUploading(false);
              setImageEditing(false);
              if (
                imageUrl &&
                imageEditing &&
                openEditImageEditor &&
                imageIndex !== undefined
              ) {
                photo[imageIndex] = response.value.data;
                setPhoto([...photo]);
              } else {
                photo.push(response.value.data);
                setPhoto([...photo]);
              }
              imageAddHandler();
              setLoadingForImage(false);
            })
            .catch(() => {
              // setIsImageUploading(false);
              setImageEditing(false);
              toast.error("Image uploading failed");
              setImagesToShow([...imagesToShow]);
            });
        }

        // setEditImage(false);
        // const fileData =  getFileData(event);
        // setLoadingImageForEditor(true);
        //   if (fileData && fileData.fileUrl !== "") {
        //     setimage64Data(fileData.fileUrl);
        //     let existingFileUrl = undefined;
        //     if (fileData.fileUrl && currentRole !== "admin") {
        //       if (image64Data && selectedImageIndex !== undefined && editImage) {
        //         photo[selectedImageIndex] = { image: fileData.fileUrl };
        //         setPhoto([...photo]);
        //       } else {
        //         photo.push({ image: fileData.fileUrl });
        //         setPhoto([...photo]);
        //       }

        //       uploadImageWithThumbnail({
        //         base64: fileData.fileUrl,
        //         existingFileUrl,
        //         fileType: "png",
        //       })
        //         .then((response) => {
        //           if (
        //             image64Data &&
        //             selectedImageIndex !== undefined &&
        //             editImage
        //           ) {
        //             inspectorImages[selectedImageIndex] = response.value.data;
        //             setInspectorImages([...inspectorImages]);
        //             setPhoto([...inspectorImages]);
        //           } else {
        //             inspectorImages.push(response.value.data);
        //             setInspectorImages([...inspectorImages]);
        //             setPhoto([...inspectorImages]);
        //           }

        //           setLoadingImageForEditor(false);
        //         })
        //         .catch((err) => {
        //           toast.error("Image uploading failed");
        //           photo.pop();
        //           setPhoto([...photo]);
        //         });
        //     } else {
        //       let alreadyExsist = inspectorImages.indexOf(fileData.fileUrl);
        //       if (alreadyExsist === -1) {
        //         inspectorImages.push({ image: fileData.fileUrl });
        //         setInspectorImages([...inspectorImages]);
        //         setPhoto([...inspectorImages]);
        //       }
        //     }
        //     setTimeout(() => {
        //       // setOpenImageEditor(true);
        //       setLoadingImageForEditor(false);
        //     }, 3000);
      };
      // } else {
      //   toast.error("File size must be 5mb or less");
    }
  };

  // const ImageToolHandler = (Url) => {
  //   let existingFileUrl = undefined;
  //   if (Url) {
  //     setLoadingImageForEditor(true);
  //     uploadImageWithThumbnail({
  //       base64: Url,
  //       existingFileUrl,
  //       fileType: "png",
  //     })
  //       .then((response) => {
  //         setLoadingImageForEditor(false);
  //         if (image64Data && selectedImageIndex !== undefined && editImage) {
  //           inspectorImages[selectedImageIndex] = response.value.data;
  //           setInspectorImages([...inspectorImages]);
  //           setPhoto([...inspectorImages]);
  //         } else {
  //           inspectorImages.push(response.value.data);
  //           setInspectorImages([...inspectorImages]);
  //           setPhoto([...inspectorImages]);
  //         }

  //         setLoadingImageForEditor(false);
  //       })
  //       .catch((err) => {
  //         toast.error("Image uploading failed");
  //         photo.pop();
  //         setPhoto([...photo]);
  //       });
  //   }
  // };

  // useEffect(() => {
  //   let answerToSave = {
  //     questionUuid: question.questionUuid,
  //     inspectorImages: inspectorImages.length ? [...inspectorImages] : [],
  //     note: notes,
  //     image: "",
  //   };
  //   if (!isQuestionLoading) {
  //     addUpdateAnwser(answerToSave);
  //   }
  //   // eslint-disable-next-line
  // }, [inspectorImages, notes]);

  useEffect(() => {
    let answerToSave = {
      questionUuid: question.questionUuid,
      inspectorImages: photo.length ? [...photo] : [],
      note: notes,
      image: [],
    };
    if (!isQuestionLoading) {
      addUpdateAnwser(answerToSave);
    }
    // eslint-disable-next-line
  }, [inspectorImages, notes]);

  const imageAddHandler = () => {
    if (photo && photo.length) {
      let answerToSave = {
        questionUuid: question.questionUuid,
        inspectorImages: photo.length ? [...photo] : [],
        note: notes,
        image: [],
      };

      if (!isQuestionLoading) {
        addUpdateAnwser(answerToSave);
        setIsImageAdd(true);
      }
    }
  };

  // const selectImageForToolEdit = async (index) => {
  //   setSelectedImageIndex(index);

  //   if (index !== null) {
  //     imagetoDataURL64(inspectorImages[index]?.thumbnail, (res) => {
  //       setimage64Data(res);
  //       setLoadingImageForEditor(true);
  //       setTimeout(() => {
  //         setOpenImageEditor(true);
  //         setLoadingImageForEditor(false);
  //       }, 2000);
  //     });
  //     // let fileType = inspectorImages[index]?.image?.split(".");
  //     // let base64 = await imageBase64Url(
  //     //   inspectorImages[index]?.image,
  //     //   fileType ? fileType[fileType?.length - 1] : ""
  //     // );
  //     // if (base64) {
  //     //   setimage64Data(base64.fileUrl);
  //     //   setOpenImageEditor(true);
  //     // }
  //   }
  // };
  const imagetoDataURL64 = (url, callback) => {
    var image = new Image();
    image.crossOrigin = "Anonymous";
    image.onload = function () {
      var canvas = document.createElement("canvas");
      var context = canvas.getContext("2d");
      canvas.height = this.naturalHeight;
      canvas.width = this.naturalWidth;
      context.drawImage(this, 0, 0);
      var dataURL = canvas.toDataURL("image/jpeg");
      callback(dataURL);
    };
    image.src = url;
  };
  return (
    <div id={question?.questionUuid} className="multiple-choice">
      <div className="d-flex justify-content-between question-title h-auto">
        <strong>
          {questionPreview?.settings.questionText === "Type Instructions" ? (
            <I18n lng="Form.TypeInstructions" />
          ) : questionPreview?.settings.questionText ===
            "Add a new question" ? (
            <>
              {question?.questionNumber
                ? question?.questionNumber
                : actionItem?.questionNo === undefined
                ? actionItem
                : actionItem?.questionNo
                ? actionItem?.questionNo + 1
                : 1}
              .{"   "}
              <I18n lng="Form.AddANewQuestion" />{" "}
            </>
          ) : (
            ` ${
              question?.questionNumber
                ? question?.questionNumber
                : actionItem?.questionNo === undefined
                ? actionItem
                : actionItem?.questionNo
                ? actionItem?.questionNo + 1
                : 1
            }. ${questionPreview?.settings.questionText}`
          )}
          {question.settings.isMandatory && <span className="required" />}
        </strong>
        <div className="d-flex">
          {question.settings.description ? (
            <>
              <p
                data-for={`${question.settings.questionText}`}
                data-tip={`${question.settings.description}`}
                data-iscapture="true"
              >
                <BsInfoCircleFill color={"20639b"} />
              </p>
              <ReactTooltip
                id={`${question.settings.questionText}`}
                place="top"
                type="dark"
                effect="float"
                multiline={true}
              />
            </>
          ) : (
            ""
          )}
        </div>
      </div>

      <div className="attachment-preview">
        {question?.settings?.photo ? (
          <img src={question.settings.photo} alt="Attachment Preview" />
        ) : (
          ""
        )}
      </div>
      {loadingForImage ? (
        <div className="text-center">
          <div class="spinner-border text-info" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="preview-thumbs text-center">
        {photo.length
          ? photo.map((item, index) => {
              return (
                <div key={index} className="thumb-wrap">
                  <div className="thumb-img">
                    <img
                      id={`image-${index}`}
                      src={item.image}
                      alt="Icon thumb Upload"
                      onClick={(e) => {
                        setLoadingForImage(true);
                        if (e.cancelable) {
                          e.preventDefault();
                        }
                        imagetoDataURL64(item.thumbnail, (res) => {
                          setOpenEditImageEditor(true);
                          setLoadingForImage(true);
                          setImageIndex(index);
                          setImageEditing(true);
                          setTimeout(() => {
                            setImageUrl(res);
                            setLoadingForImage(false);
                          }, 2000);
                        });
                        // getBase64FromUrl(item.image).then((res) => {
                        //   console.log(res, "res");
                        // });
                      }}
                      onLoad={({ target: img }) => {
                        let style =
                          img.naturalWidth > img.naturalHeight
                            ? img.naturalHeight
                            : img.naturalWidth;
                        let documents;
                        documents = document.getElementById(`image-${index}`);
                        documents.setAttribute("height", style + "px");
                        documents.setAttribute("width", style + "px");
                      }}
                    />
                  </div>
                </div>
              );
            })
          : null}
        {photo.length !== null && photo.length < 3 ? (
          <div className="thumb-wrap">
            <div
              className={`thumb-icon ${photo.length === 0 ? "inactive" : ""}`}
            >
              <img src={IconImgThumb} alt=" Thumbnail" onClick={handleClick} />
              <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                accept="image/png, image/jpg, image/jpeg,"
              />
            </div>
          </div>
        ) : null}
      </div>

      {
        <Attachment
          canComment={isImageAdd ? question?.settings?.canComment : false}
          canAttachPhoto={
            isImageAdd ? question?.settings?.canAttachPhoto : false
          }
          notes={notes}
          setNotes={setNotes}
          photo={[]}
          setPhoto={setPhoto}
          imageUrl={""}
          openEditImageEditor={openEditImageEditor}
          setOpenEditImageEditor={setOpenEditImageEditor}
          questionType={"Image"}
          hideImage={true}
          imageDeleteHandler={imageDeleteHandler}
        />
      }
      {waitForImageUpload ? (
        <ImageEditorDialog
          show={
            openEditImageEditor ? openEditImageEditor : openImageEditorDialog
          }
          onHide={() =>
            openEditImageEditor
              ? setOpenEditImageEditor(false)
              : setOpenImageEditorDialog(false)
          }
          toEdit={
            openEditImageEditor && imageUrl
              ? imageUrl
              : image64Data
              ? image64Data
              : ""
          }
          onEdit={async (data) => {
            // setLoadingForImage(true);
            if (data && data.length > 20) {
              // setIsImageUploading(true);
              let existingFileUrl = undefined;

              // showing images locally before uploading
              if (
                imageUrl &&
                imageEditing &&
                openEditImageEditor &&
                imageIndex !== undefined
              ) {
                // if already uploaded image is selected so replacing it
                imagesToShow[imageIndex] = { image: data };
                setImagesToShow([...imagesToShow]);
              } else {
                // new image is uploaded so adding it to array
                setImagesToShow([...imagesToShow, { image: data }]);
              }
              if (data) {
                // uploading image to aws bucket
                await uploadImageWithThumbnail({
                  base64: data,
                  existingFileUrl,
                  fileType: "png",
                })
                  .then((response) => {
                    // setIsImageUploading(false);
                    setImageEditing(false);
                    setLoadingForImage(false);

                    if (
                      imageUrl &&
                      imageEditing &&
                      openEditImageEditor &&
                      imageIndex !== undefined
                    ) {
                      photo[imageIndex] = response.value.data;
                      setPhoto([...photo]);
                    } else {
                      photo.push(response.value.data);
                      setPhoto([...photo]);
                    }
                    imageAddHandler();
                  })
                  .catch(() => {
                    // setIsImageUploading(false);
                    setImageEditing(false);
                    toast.error("Image uploading failed");
                    setImagesToShow([...imagesToShow]);
                  });
              } else {
                // setIsImageUploading(false);
                setImageEditing(false);
              }
            } else {
              toast.error("Failed");
            }
          }}
          imageIndex={imageIndex}
          imagesToShow={imagesToShow}
          setImagesToShow={setImagesToShow}
          imageDeleteHandler={imageDeleteHandler}
          openEditImageEditor={openEditImageEditor}
          setLoadingForImage={setLoadingForImage}
          loadingForImage={loadingForImage}
        />
      ) : (
        ""
      )}
    </div>
  );
};

const mapStateToProps = ({ authentication }) => ({
  currentRole: authentication.currentRole,
});
const mapDispatchToProps = {
  uploadImageWithThumbnail,
};
export default connect(mapStateToProps, mapDispatchToProps)(ImagePreview);
