import axios from "axios";
import { REQUEST, SUCCESS, FAILURE } from "../action-type.util";

const MULTIPLE_CHOICES_URL = "/multipleChoices";

export const ACTION_TYPES = {
  MULTIPLE_CHOICE_CREATE: "mcq/Created",
  GET_ALL_MULTIPLE_CHOICE: "get/mcqs",
  GET_MULTIPLE_CHOICE_BY_ID: "getMcqById/mcq",
  UPDATE_MULTIPLE_CHOICE_BY_ID: "update/mcq",
};

const initialState = {
  error: null,
  loading: false,
  multipleChoiceForEdit: {},
  allMultipleChoiceQuestions: [],
};

export const MultipleChoiceState = initialState;

// Reducer
// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.MULTIPLE_CHOICE_CREATE):
    case REQUEST(ACTION_TYPES.GET_ALL_MULTIPLE_CHOICE):
    case REQUEST(ACTION_TYPES.GET_MULTIPLE_CHOICE_BY_ID):
    case REQUEST(ACTION_TYPES.UPDATE_MULTIPLE_CHOICE_BY_ID):
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FAILURE(ACTION_TYPES.MULTIPLE_CHOICE_CREATE):
    case FAILURE(ACTION_TYPES.GET_ALL_MULTIPLE_CHOICE):
    case FAILURE(ACTION_TYPES.GET_MULTIPLE_CHOICE_BY_ID):
    case FAILURE(ACTION_TYPES.UPDATE_MULTIPLE_CHOICE_BY_ID):
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SUCCESS(ACTION_TYPES.GET_MULTIPLE_CHOICE_BY_ID):
      return {
        ...state,
        loading: false,
        error: null,
        multipleChoiceForEdit: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.GET_ALL_MULTIPLE_CHOICE):
      return {
        ...state,
        loading: false,
        error: null,
        allMultipleChoiceQuestions: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.UPDATE_MULTIPLE_CHOICE_BY_ID):
    case SUCCESS(ACTION_TYPES.MULTIPLE_CHOICE_CREATE):
      return {
        ...state,
      };

    default:
      return state;
  }
};

// Actions
export const createMultipleChoiceQuestion = (mcq) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.MULTIPLE_CHOICE_CREATE,
    payload: axios.post(MULTIPLE_CHOICES_URL, mcq),
  });
};

export const getAllMultipleChoiceQuestions = (formId) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.GET_ALL_MULTIPLE_CHOICE,
    payload: axios.get(
      `${MULTIPLE_CHOICES_URL}${formId ? `?formId=${formId}` : ""}`
    ),
  });
};

export const getMultipleChoiceById = (id) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.GET_MULTIPLE_CHOICE_BY_ID,
    payload: axios.get(`${MULTIPLE_CHOICES_URL}/${id}`),
  });
};

export const updateMultipleChoiceById = (id, mcq) => async (dispatch) => {
  let updatedMcq = {
    name: mcq.name,
    type: mcq.type,
    questionUuid: mcq.questionUuid,
    settings: { ...mcq.settings },
    updatedAt: mcq.updatedAt,
  };
  return await dispatch({
    type: ACTION_TYPES.GET_MULTIPLE_CHOICE_BY_ID,
    payload: axios.patch(`${MULTIPLE_CHOICES_URL}/${id}`, updatedMcq),
  });
};

export const multipleChoiceDeleteById = (id) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.GET_MULTIPLE_CHOICE_BY_ID,
    payload: axios.delete(`${MULTIPLE_CHOICES_URL}/${id}`),
  });
};
