import { useEffect, useState } from "react";
import { connect } from "react-redux";
import CustomMultiSelect from "../../../../components/form-input/multiSelect";
import { TEXT_DIRECTION } from "../../../../helper/helperFunctions";
import { i18n } from "../../languageSelector";
import { GenaricListDTO } from "../DTO/analyticsDTO";
import CustomSelect from "./CustomSelect";
import moment from "moment";
export interface InitialStatProps {
  day: string;
  form: string;
  inspector: string;
  status: string;
  site: string[];
}
interface FilterFormIprops {
  isDeletedItemChecked: boolean;
  selectedDate: any;
  setSelectedDate: any;
  formValues: any;
  setFormValues: any;
  formsList: GenaricListDTO[];
  usersList: GenaricListDTO[];
  sitesList: GenaricListDTO[];
  setIsDeletedItemChecked: any;
  statusList: GenaricListDTO[];
  formHandleSelectAll: any;
  userHandleSelectAll: any;
  sitesHandleSelectAll: any;
  statusHandleSelectAll: any;
  formHandleSelectItem: any;
  sitesHandleSelectItem: any;
  userHandleSelectItem: any;
  statusHandleSelectItem: any;
  formHandleSearch: any;
  userHandleSearch: any;
  siteHandleSearch: any;
  setFormsList: any;
  setSitesList: any;
  setUsersList: any;
  setstatusList: any;
  setselectedValue: any;
  selectedValue: string;
}
function FilterForm({
  selectedDate,
  setSelectedDate,
  formValues,
  setFormValues,
  formsList,
  usersList,
  sitesList,
  isDeletedItemChecked,
  setIsDeletedItemChecked,
  statusList,
  formHandleSelectAll,
  userHandleSelectAll,
  sitesHandleSelectAll,
  statusHandleSelectAll,
  formHandleSelectItem,
  sitesHandleSelectItem,
  userHandleSelectItem,
  statusHandleSelectItem,
  formHandleSearch,
  userHandleSearch,
  siteHandleSearch,
  setFormsList,
  setSitesList,
  setUsersList,
  setstatusList,
  setselectedValue,
  selectedValue,
}: FilterFormIprops) {
  // const [site, setSite] = useState<any[]>([]);
  const [slectedLable, setslectedLable] = useState(i18n("Analytics.Last30Days"));

  let options = [
    { lable: i18n("Analytics.Today"), value: "Today" },
    { lable: i18n("Analytics.Yesterday"), value: "Yesterday" },
    { lable: i18n("Analytics.ThisWeek"), value: "ThisWeek" },
    { lable: i18n("Analytics.LastWeek"), value: "LastWeek" },
    { lable: i18n("Analytics.Last7Days"), value: "Last7Days" },
    { lable: i18n("Analytics.ThisMonth"), value: "ThisMonth" },
    { lable: i18n("Analytics.LastMonth"), value: "LastMonth" },
    { lable: i18n("Analytics.Last30Days"), value: "Last30Days" },
    { lable: i18n("Analytics.CustomRange"), value: "CustomRange" },
  ];

  useEffect(() => {
    if (selectedValue !== "CustomRange") {
      const selectedOption = options.find((item) => item.value === selectedValue);
      setslectedLable(selectedOption?.lable || "");
    } else {
      setslectedLable(`${selectedDate.startDate} ~ ${selectedDate.endDate}`);
    }
    // eslint-disable-next-line
  }, [selectedValue]);

  //reset formsList
  const handleResetForms = () => {
    let resetFormsList = formsList.map((item) => {
      return { ...item, isSelected: true };
    });
    setFormsList(resetFormsList);
  };
  //reset sitesList
  const handleResetSites = () => {
    let resetSitesList = sitesList.map((item) => {
      return { ...item, isSelected: true };
    });
    setSitesList(resetSitesList);
  };
  //reset inspectorList
  const handleResetInspectors = () => {
    let resetInspectorsList = usersList.map((item) => {
      return { ...item, isSelected: true };
    });
    setUsersList(resetInspectorsList);
  };
  //reset statusList
  const handleResetStatus = () => {
    let resetStatusList = statusList.map((item) => {
      return { ...item, isSelected: true };
    });
    setstatusList(resetStatusList);
  };

  const handleReset = () => {
    setSelectedDate({
      startDate: moment().subtract(30, "days").format("YYYY/MM/DD"),
      endDate: moment().format("YYYY/MM/DD"),
    });
    setFormValues({
      ...formValues,
      day: i18n("Analytics.Last30Days"),
    });
    setslectedLable(i18n("Analytics.Last30Days"));
    handleResetForms();
    handleResetSites();
    handleResetInspectors();
    handleResetStatus();
  };
  return (
    <div
      className=""
      style={{
        backgroundColor: "#ffffff",
        borderRadius: "4px",
        height: "98.3%",
        // position: "fixed",
        // width: "300px",
        // zIndex: "80",
      }}
    >
      <div style={{ padding: "15px", paddingTop: "2.4rem" }}>
        <div
          className={`reset ${TEXT_DIRECTION() === "rtl" ? "float-left" : "float-right"}`}
          onClick={() => {
            handleReset();
          }}
          style={{
            color: "#20639B",
            fontWeight: 500,
            fontSize: "14px",
            // marginTop: "-17px",
            cursor: "pointer",
            lineHeight: "15.92px",
            marginTop: "-24px",
            paddingLeft: "3px",
            paddingRight: "3px",
          }}
        >
          {i18n("Analytics.ResetAll")}
        </div>
        <CustomSelect
          label={i18n("Analytics.Date")}
          setselectedValue={setselectedValue}
          selectedValue={selectedValue}
          options={options}
          formValues={formValues}
          setFormValues={setFormValues}
          setslectedLable={setslectedLable}
          slectedLable={slectedLable}
          setSelectedDate={setSelectedDate}
          // selectedDate={selectedDate}
        />

        <div className="custom-multi-select mt-4">
          <span className="form-lable">{i18n("Analytics.Form")}</span>
          <CustomMultiSelect
            options={formsList}
            onSelect={() => {}}
            handleSelectAll={formHandleSelectAll}
            handleSelectedItem={formHandleSelectItem}
            handleSearch={formHandleSearch}
            formField={"FORM"}
          />
        </div>
        <div className="custom-multi-select mt-4">
          <span className="form-lable">{i18n("Analytics.Inspector")}</span>
          <CustomMultiSelect
            options={usersList}
            onSelect={() => {}}
            handleSelectAll={userHandleSelectAll}
            handleSelectedItem={userHandleSelectItem}
            handleSearch={userHandleSearch}
            formField={"USER"}
          />
        </div>
        <div className="custom-multi-select mt-4">
          <span className="form-lable">{i18n("Analytics.Site")}</span>
          <CustomMultiSelect
            options={sitesList}
            onSelect={() => {}}
            handleSelectAll={sitesHandleSelectAll}
            handleSelectedItem={sitesHandleSelectItem}
            handleSearch={siteHandleSearch}
            formField={"SITE"}
          />
        </div>
        <div className="custom-multi-select mt-4">
          <span className="form-lable">{i18n("Analytics.Status")}</span>
          <CustomMultiSelect
            options={statusList}
            onSelect={() => {}}
            handleSelectAll={statusHandleSelectAll}
            handleSelectedItem={statusHandleSelectItem}
            handleSearch={() => {}}
            formField={"STATUS"}
          />
        </div>
        <div className="d-flex align-items-start justify-content-start mt-3  check-wrap">
          <input
            type="checkbox"
            style={{
              border: "none",
              background: "#F2F2F2",
              height: "16px",
              borderRadius: "3.3px",
            }}
            checked={isDeletedItemChecked}
            onChange={() => setIsDeletedItemChecked(!isDeletedItemChecked)}
          />
          <p className="mx-2" style={{ color: "#828282", fontSize: "13.5px" }}>
            {i18n("Analytics.ShowDeletedAndArchived")}
          </p>
        </div>
      </div>
    </div>
  );
}
// const mapStateToProps = ({ analytics }: any) => ({
//   sitesList: analyticState.analyticSites,
// });

const mapDispatchToProps = {
  // getAnalytics
};
export default connect(null, mapDispatchToProps)(FilterForm);
