import { v4 as uuid } from "uuid";
import cloneDeep from "lodash/cloneDeep";
import { RESPONSES_TYPES } from "../config/constants";
import { i18n } from "../pages/private/languageSelector";

export const RETURN_SELETED_QUESTIONS = (type, lastQuestion) => {
  if (type === "COMPLEX_MULTIPLE_CHOICE") {
    return { ...RESPONSES_TYPES.MULTIPLE_CHOICE_RESPONSE_V1 };
  } else if (type === "DROPDOWN") {
    return {
      ...RESPONSES_TYPES.DROP_DOWN_LIST,
      questionUuid: uuid(),
      settings: {
        ...RESPONSES_TYPES.DROP_DOWN_LIST.settings,
        options: lastQuestion?.settings?.options ? lastQuestion?.settings?.options : [],
        listName: lastQuestion?.settings?.listName ? lastQuestion?.settings?.listName : "DropDown List",
        dropDownOpen: false,
      },
    };
  } else if (type === "DATE TIME") {
    return { ...RESPONSES_TYPES.DATE_AND_TIME };
  } else if (type === "CHECKBOX") {
    return {
      ...RESPONSES_TYPES.CHECKBOX,
      questionUuid: uuid(),
      settings: {
        ...RESPONSES_TYPES.CHECKBOX.settings,
        score: lastQuestion && lastQuestion.settings ? lastQuestion.settings.score : 1,
      },
    };
  } else if (type === "SLIDER") {
    return {
      ...RESPONSES_TYPES.SLIDER,
      questionUuid: uuid(),
      settings: {
        ...RESPONSES_TYPES.SLIDER.settings,
        score: lastQuestion.settings.score,
      },
    };
  } else {
    return { ...RESPONSES_TYPES[`${type}`] };
  }
};

export const RETURN_LAST_QUESTION = (lastQuestion) => {
  if (lastQuestion && lastQuestion.type === "COMPLEX_MULTIPLE_CHOICE") {
    let answers = lastQuestion.settings.answers.map((item) => ({
      ...item,
      canComment: false,
      canAttachPhoto: false,
    }));
    return {
      ...lastQuestion,
      questionUuid: uuid(),
      settings: {
        ...RESPONSES_TYPES.MULTIPLE_CHOICE_RESPONSE_V1.settings,
        answers: [...answers],
        score: lastQuestion.settings.score,
        selectionType: lastQuestion.settings.selectionType,
      },
    };
  } else {
    let question = RETURN_SELETED_QUESTIONS(lastQuestion?.type?.toUpperCase(), lastQuestion);
    return {
      ...question,
      questionUuid: uuid(),
    };
  }
};

export const RETURN_LAST_USED_QUESTION = (form, pageNo, sectionNo, newQuestionType, defaultMcq) => {
  let selectedQuestion = {};
  // checking if current section already have a question
  if (form.schema.pages[pageNo]?.sections[sectionNo]?.questions?.length > 0) {
    if (form?.selectedElement?.questionNo !== undefined) {
      // if a specific question is selected
      selectedQuestion = RETURN_LAST_QUESTION(form.schema.pages[pageNo].sections[sectionNo].questions[form?.selectedElement?.questionNo]);
    } else {
      // if no question is selected then pick the last one
      selectedQuestion = RETURN_LAST_QUESTION(
        form.schema.pages[pageNo].sections[sectionNo].questions[form.schema.pages[pageNo].sections[sectionNo].questions.length - 1]
      );
    }
    // current section have no questions and checking if there is a previous section in page
  } else if (form.schema.pages[pageNo]?.sections?.length > 1) {
    // if previous section have questions
    if (form.schema.pages[pageNo]?.sections[sectionNo - 1]?.questions.length > 0) {

      selectedQuestion = RETURN_LAST_QUESTION(
        form.schema.pages[pageNo].sections[sectionNo - 1].questions[form.schema.pages[pageNo].sections[sectionNo - 1].questions.length - 1]
      );
    }

    // add multiple choice if no question found
    else {
      // console.log("no question exists in the previous section");
      if (newQuestionType === "COMPLEX_MULTIPLE_CHOICE") {
        selectedQuestion = defaultMcq;
      } else {
        selectedQuestion = RETURN_SELETED_QUESTIONS(newQuestionType);
      }
    }

    // adding question from a section in previous page
  } else if (form.schema.pages.length > 1) {
    if (form.schema.pages[form.selectedElement.pageNo - 1]?.sections?.length) {
      if (
        form.schema.pages[form.selectedElement.pageNo - 1]?.sections[form.schema.pages[form.selectedElement.pageNo - 1]?.sections?.length - 1]
          ?.questions?.length
      ) {
        selectedQuestion = RETURN_LAST_QUESTION(
          form.schema.pages[form.selectedElement.pageNo - 1].sections[form.schema.pages[form.selectedElement.pageNo - 1].sections.length - 1]
            .questions[
            form.schema.pages[form.selectedElement.pageNo - 1].sections[form.schema.pages[form.selectedElement.pageNo - 1].sections.length - 1]
              .questions.length - 1
          ]
        );
      }
      // add multiple choice if no question found
      else {
        // console.log("no question exists in the previous section");
        if (newQuestionType === "COMPLEX_MULTIPLE_CHOICE") {
          selectedQuestion = defaultMcq;
        } else {
          selectedQuestion = RETURN_SELETED_QUESTIONS(newQuestionType);
        }
      }

      // add multiple choice if no question found
    } else {
      // console.log("no question exists in the previous section");
      if (newQuestionType === "COMPLEX_MULTIPLE_CHOICE") {
        selectedQuestion = defaultMcq;
      } else {
        selectedQuestion = RETURN_SELETED_QUESTIONS(newQuestionType);
      }
    }
    // add multiple choice if no question found
  } else {
    // console.log("no question exists in the previous section");
    if (newQuestionType === "COMPLEX_MULTIPLE_CHOICE") {
      selectedQuestion = defaultMcq;
    } else {
      selectedQuestion = RETURN_SELETED_QUESTIONS(newQuestionType);
    }
  }

  return selectedQuestion;
};

export const RETURN_LAST_USED_QUESTION_V1 = (form, sectionNo, newQuestionType, defaultMcq) => {
  let selectedPageQuestion = {};

  // checking if current section already have a question
  if (form.schema.pages[form.selectedElement.pageNo]?.sections[sectionNo]?.questions?.length) {
    if (form?.selectedElement?.questionNo !== undefined) {
      // if a specific question is selected
      selectedPageQuestion = RETURN_LAST_QUESTION(
        form.schema.pages[form.selectedElement.pageNo]?.sections[sectionNo]?.questions[form?.selectedElement?.questionNo]
      );
    } else {
      // console.log("getting last question from same section");
      selectedPageQuestion = RETURN_LAST_QUESTION(
        form.schema.pages[form.selectedElement.pageNo]?.sections[sectionNo]?.questions[
          form.schema.pages[form.selectedElement.pageNo]?.sections[sectionNo]?.questions?.length - 1
        ]
      );
    }

    // current section have no questions and checking if there is a previous section in page
  } else if (form.schema.pages[form.selectedElement.pageNo]?.sections?.length > 1) {
    // if previous section have questions
    if (form.schema.pages[form.selectedElement.pageNo]?.sections[sectionNo - 1]?.questions?.length > 1) {
      // console.log("getting last question from previous section");

      selectedPageQuestion = RETURN_LAST_QUESTION(
        form.schema.pages[form.selectedElement.pageNo]?.sections[sectionNo - 1]?.questions[
          form.schema.pages[form.selectedElement.pageNo]?.sections[sectionNo - 1]?.questions?.length - 1
        ]
      );
    }
  } else if (form.schema.pages.length > 1) {
    // console.log("page length > 1");
    if (form.schema.pages[form.selectedElement.pageNo - 1]?.sections?.length) {
      if (
        form.schema.pages[form.selectedElement.pageNo - 1]?.sections[form.schema.pages[form.selectedElement.pageNo - 1]?.sections?.length - 1]
          ?.questions?.length
      ) {
        selectedPageQuestion = RETURN_LAST_QUESTION(
          form.schema.pages[form.selectedElement.pageNo - 1].sections[form.schema.pages[form.selectedElement.pageNo - 1].sections.length - 1]
            .questions[
            form.schema.pages[form.selectedElement.pageNo - 1].sections[form.schema.pages[form.selectedElement.pageNo - 1].sections.length - 1]
              .questions.length - 1
          ]
        );
        // add multiple choice if no question found
      } else {
        // console.log("no question exists in the previous section");
        if (newQuestionType === "COMPLEX_MULTIPLE_CHOICE") {
          selectedPageQuestion = defaultMcq;
        } else {
          selectedPageQuestion = RETURN_SELETED_QUESTIONS(newQuestionType);
        }
      }

      // add multiple choice if no question found
    } else {
      if (newQuestionType === "COMPLEX_MULTIPLE_CHOICE") {
        selectedPageQuestion = defaultMcq;
      } else {
        selectedPageQuestion = RETURN_SELETED_QUESTIONS(newQuestionType);
      }
    }
  }
  // add multiple choice if no question found
  else {
    if (newQuestionType === "COMPLEX_MULTIPLE_CHOICE") {
      selectedPageQuestion = defaultMcq;
    } else {
      selectedPageQuestion = RETURN_SELETED_QUESTIONS(newQuestionType);
    }
  }

  return selectedPageQuestion;
};

export const COPY_HANDLER = (formData, selectedElementRef) => {
  let selectedElement = formData.selectedElement;

  if (selectedElement?.pageNo === undefined && selectedElement?.sectionNo === undefined && selectedElement?.questionNo === undefined) {
    // NOTHING TO COPY
  } else if (selectedElement?.pageNo !== undefined && selectedElement?.sectionNo !== undefined && selectedElement?.questionNo !== undefined) {
    // COPY SINGLE QUESTION
    selectedElementRef.current = selectedElement;
  } else if (selectedElement?.pageNo !== undefined && selectedElement?.sectionNo !== undefined && selectedElement?.questionNo === undefined) {
    // COPY SINGLE SECTION
    selectedElementRef.current = selectedElement;
  } else if (selectedElement?.pageNo !== undefined && selectedElement?.sectionNo === undefined && selectedElement?.questionNo === undefined) {
    // COPY SINGLE PAGE
    selectedElementRef.current = selectedElement;
  }
};

export const PASTE_HANDLER = (formData, selectedElementRef, setForm) => {
  let formSchema = formData.schema;
  let selectedElement = formData.selectedElement;

  if (
    selectedElement?.pageNo === undefined &&
    selectedElement?.sectionNo === undefined &&
    selectedElement?.questionNo === undefined &&
    selectedElementRef.current?.pageNo === undefined &&
    selectedElementRef.current?.sectionNo === undefined &&
    selectedElementRef.current?.questionNo === undefined
  ) {
    // NOTHING TO PASTE
  } else if (
    selectedElement?.pageNo !== undefined &&
    selectedElement?.sectionNo !== undefined &&
    selectedElement?.questionNo !== undefined &&
    selectedElementRef.current?.pageNo !== undefined &&
    selectedElementRef.current?.sectionNo !== undefined &&
    selectedElementRef.current?.questionNo !== undefined
  ) {
    // PASTE SINGLE QUESTION
    PASTE_QUESTION(
      selectedElement?.pageNo,
      selectedElement?.sectionNo,
      selectedElement?.questionNo,
      formData,
      formSchema?.pages[selectedElementRef.current.pageNo]?.sections[selectedElementRef.current.sectionNo]?.questions?.[
        selectedElementRef.current.questionNo
      ],
      setForm
    );
  } else if (
    selectedElement?.pageNo !== undefined &&
    selectedElement?.sectionNo !== undefined &&
    selectedElement?.questionNo === undefined &&
    selectedElementRef.current?.pageNo !== undefined &&
    selectedElementRef.current?.sectionNo !== undefined &&
    selectedElementRef.current?.questionNo === undefined
  ) {
    // PASTE SINGLE SECTION
    PASTE_SECTION(
      selectedElement?.pageNo,
      selectedElement?.sectionNo,
      formData,
      setForm,
      formSchema?.pages[selectedElementRef.current.pageNo]?.sections[selectedElementRef.current.sectionNo]
    );
  } else if (
    selectedElement?.pageNo !== undefined &&
    selectedElement?.sectionNo === undefined &&
    selectedElement?.questionNo === undefined &&
    selectedElementRef.current?.pageNo !== undefined &&
    selectedElementRef.current?.sectionNo === undefined &&
    selectedElementRef.current?.questionNo === undefined
  ) {
    // PASTE SINGLE PAGE
    PASTE_PAGE(selectedElement?.pageNo, formData, formSchema?.pages[selectedElementRef.current.pageNo], setForm);
  }
};

export const cloneSections = (sections = []) => {
  if (sections?.length > 0) {
    return sections.map((section) => {
      return {
        ...cloneDeep({
          ...section,
          questions: cloneQuestions(section.questions),
        }),
        sectionUuid: uuid(),
      };
    });
  } else {
    return [];
  }
};

export const cloneQuestions = (questions = []) => {
  if (questions?.length > 0) {
    return questions.map((question) => {
      return { ...cloneDeep(question), questionUuid: uuid() };
    });
  } else {
    return [];
  }
};

export const PASTE_PAGE = (pageNo, form, pageToCopy, setForm) => {
  if (pageNo !== undefined) {
    form.schema.pages.splice(pageNo + 1, 0, {
      ...cloneDeep({
        ...pageToCopy,
        sections: cloneSections(pageToCopy.sections),
      }),
      pageUuid: uuid(),
      pagePlaceHolder: `Page ${form.schema.pages.length + 1}`,
    });
    setForm({
      ...cloneDeep(form),
      selectedElement: {
        pageNo: pageNo + 1,
        sectionNo: undefined,
        questionNo: undefined,
      },
    });
  }
};

export const PASTE_SECTION = (pageNo, sectionNo, form, setForm, sectionToCopy) => {
  if (pageNo !== undefined && sectionNo !== undefined) {
    form.schema.pages[pageNo].sections.splice(sectionNo + 1, 0, {
      ...cloneDeep({
        ...sectionToCopy,
        questions: cloneQuestions(sectionToCopy.questions),
      }),
      sectionUuid: uuid(),
      sectionsPlaceHolder: `Section ${form.schema.pages[pageNo].sections.length + 1}`,
    });

    setForm({
      ...cloneDeep(form),
      selectedElement: {
        pageNo: pageNo,
        sectionNo: sectionNo + 1,
        questionNo: undefined,
      },
    });
  }
};

export const PASTE_QUESTION = (pageNo, sectionNo, questionNo, form, questionToCopy, setForm) => {
  if (pageNo !== undefined && sectionNo !== undefined && questionNo !== undefined && questionToCopy && questionToCopy.settings) {
    form?.schema?.pages[pageNo]?.sections[sectionNo]?.questions?.splice(questionNo + 1, 0, {
      ...cloneDeep({
        ...questionToCopy,
        settings: { ...questionToCopy.settings, isOpen: true },
      }),
      questionUuid: uuid(),
    });

    CLOSE_ALL_SECOND(cloneDeep(form), setForm, pageNo, sectionNo, questionNo + 1);
  }
};

export const addQuestionByPasting = (
  form,
  pageNo,
  sectionNo,
  questionText,
  newQuestionType,
  setNewQuestionType,
  defaultMcq,
  setForm,
  index,
  totalQuestions
) => {
  if (
    !form.selectedElement ||
    (form.selectedElement.pageNo === undefined && form.selectedElement.sectionNo === undefined && form.selectedElement.questionNo === undefined)
  ) {
    if (form.schema.pages.length === 0) {
      form.schema.pages.push({
        name: "Page",
        isOpen: true,
        pagePlaceHolder: `Page ${form.schema.pages.length + 1}`,
        pageUuid: uuid(),
        sections: [
          {
            name: "Section",
            isOpen: true,
            sectionsPlaceHolder: "Section 1",
            sectionUuid: uuid(),
            questions: [],
          },
        ],
      });
    }
    //add question section
    if (!pageNo && !sectionNo) {
      pageNo = form.schema.pages.length - 1;
      if (form.schema.pages[pageNo].sections.length) {
        sectionNo = form.schema.pages[pageNo].sections.length - 1;
      } else {
        sectionNo = 0;
      }
      if (form.schema.pages[pageNo].sections[sectionNo] === undefined) {
        form.schema.pages[pageNo].sections.push({
          name: "Section",
          isOpen: true,
          sectionsPlaceHolder: `Section ${form.schema.pages[pageNo].sections.length + 1}`,
          sectionUuid: uuid(),
          questions: [],
        });
      }
      if (newQuestionType) {
        setNewQuestionType(newQuestionType);
      } else {
        setNewQuestionType("COMPLEX_MULTIPLE_CHOICE");
      }

      // Adding last question
      let lastSelectedQuestion = RETURN_LAST_USED_QUESTION(form, pageNo, sectionNo, newQuestionType, defaultMcq);

      if (questionText !== undefined && lastSelectedQuestion) {
        form.schema.pages[pageNo].sections[sectionNo].questions.push({
          ...lastSelectedQuestion,
          settings: {
            ...lastSelectedQuestion.settings,
            questionText: questionText,
          },
        });
      }
    }
  } else {
    if (form.selectedElement.sectionNo !== undefined) {
      if (newQuestionType) {
        setNewQuestionType(newQuestionType);
      } else {
        setNewQuestionType("COMPLEX_MULTIPLE_CHOICE");
      }

      let selectedQuestion = RETURN_LAST_USED_QUESTION(form, pageNo, sectionNo, newQuestionType, defaultMcq);
      if (questionText !== undefined && selectedQuestion) {
        form?.schema?.pages[form.selectedElement.pageNo]?.sections[form.selectedElement.sectionNo]?.questions?.push({
          ...selectedQuestion,
          settings: {
            ...selectedQuestion.settings,
            questionText: questionText,
          },
        });
      }
    } else {
      // new page with no sections and questions
      if (form.schema.pages[form.selectedElement.pageNo].sections[form.schema.pages[form.selectedElement.pageNo].sections.length - 1] === undefined) {
        form.schema.pages[form.selectedElement.pageNo].sections.push({
          name: "Section",
          isOpen: true,
          sectionsPlaceHolder: `Sections ${form.schema.pages[form.selectedElement.pageNo].sections.length + 1}`,
          sectionUuid: uuid(),
          questions: [],
        });
      }
      if (form.schema.pages[pageNo].sections.length) {
        sectionNo = form.schema.pages[pageNo].sections.length - 1;
      } else {
        sectionNo = 0;
      }
      if (newQuestionType) {
        setNewQuestionType(newQuestionType);
      } else {
        setNewQuestionType("COMPLEX_MULTIPLE_CHOICE");
      }

      let seletedPageQuestion = RETURN_LAST_USED_QUESTION_V1(form, sectionNo, newQuestionType, defaultMcq);

      if (questionText !== undefined && seletedPageQuestion) {
        form.schema.pages[form.selectedElement.pageNo].sections[form.schema.pages[form.selectedElement.pageNo].sections.length - 1].questions.push({
          ...seletedPageQuestion,
          settings: {
            ...seletedPageQuestion.settings,
            questionText: questionText,
          },
        });
      }
    }
  }
  let pageNumber = form?.schema?.pages?.length ? form?.schema?.pages?.length - 1 : 0;
  let sectionNumber = form?.schema?.pages[pageNumber]?.sections?.length ? form?.schema?.pages[pageNumber]?.sections?.length - 1 : 0;
  let questionNumber = form?.schema?.pages[pageNumber]?.sections[sectionNumber]?.questions?.length
    ? form?.schema?.pages[pageNumber]?.sections[sectionNumber]?.questions?.length - 1
    : 0;

  if (index === totalQuestions) {
    if (form.selectedElement.pageNo !== undefined && form.selectedElement.sectionNo !== undefined && form.selectedElement.questionNo !== undefined) {
      pageNumber = form.selectedElement.pageNo;
      sectionNumber = form.selectedElement.sectionNo;
      questionNumber = form.selectedElement.questionNo + totalQuestions;
    } else if (
      form.selectedElement.pageNo !== undefined &&
      form.selectedElement.sectionNo !== undefined &&
      form.selectedElement.questionNo === undefined
    ) {
      pageNumber = form.selectedElement.pageNo;
      sectionNumber = form.selectedElement.sectionNo;
      questionNumber =
        form?.schema?.pages[form.selectedElement.pageNo]?.sections[form?.schema?.pages[form.selectedElement.pageNo]?.sections.length - 1]?.questions
          ?.length - 1;
    } else if (
      form.selectedElement.pageNo !== undefined &&
      form.selectedElement.sectionNo === undefined &&
      form.selectedElement.questionNo === undefined
    ) {
      pageNumber = form.selectedElement.pageNo;
      sectionNumber = form?.schema?.pages[form.selectedElement.pageNo]?.sections?.length - 1;
      questionNumber =
        form.schema.pages[form.selectedElement.pageNo].sections[form.schema.pages[form.selectedElement.pageNo].sections.length - 1].questions.length -
        1;
    }
    CLOSE_ALL_SECOND(form, setForm, pageNumber, sectionNumber, questionNumber);
  }
};

const CLOSE_ALL_SECOND = (form, setForm, targetPageNo, targetSectionNo, targetQuestionNo) => {
  form.schema.pages.forEach((page, pageIndex) => {
    form.schema.pages[pageIndex].sections.forEach((section, sectionIndex) => {
      form.schema.pages[pageIndex].sections[sectionIndex].questions.forEach((question, questionIndex) => {
        form.schema.pages[pageIndex].sections[sectionIndex].questions[questionIndex] = {
          ...question,
          settings: { ...question?.settings, isOpen: false },
        };
      });
    });
  });
  form.schema.pages[targetPageNo] = {
    ...form.schema.pages[targetPageNo],
    isOpen: true,
  };

  form.schema.pages[targetPageNo].sections[targetSectionNo] = {
    ...form.schema.pages[targetPageNo].sections[targetSectionNo],
    isOpen: true,
  };
  form.schema.pages[targetPageNo].sections[targetSectionNo].questions[targetQuestionNo] = {
    ...form.schema.pages[targetPageNo].sections[targetSectionNo].questions[targetQuestionNo],
    settings: {
      ...form.schema.pages[targetPageNo].sections[targetSectionNo].questions[targetQuestionNo]?.settings,
      isOpen: true,
    },
  };

  setForm({
    ...form,
    selectedElement: {
      ...form.selectedElement,
      questionNo: form.selectedElement.questionNo !== undefined ? targetQuestionNo : undefined,
    },
  });
};

export const ACTIVE_ELEMENT_HANDLER = (formRef) => {
  // Checking if selected element is question and is focused
  if (
    formRef.current.selectedElement.pageNo !== undefined &&
    formRef.current.selectedElement.sectionNo !== undefined &&
    formRef.current.selectedElement.questionNo !== undefined &&
    document.getElementById("form-name") === null &&
    document.activeElement !== document.getElementById("form-name") &&
    document.activeElement !== document.getElementById("form-description")
  ) {
    if (
      document.activeElement ===
      document.getElementById(
        formRef.current.schema.pages[formRef.current.selectedElement.pageNo].sections[formRef.current.selectedElement.sectionNo].questions[
          formRef.current.selectedElement.questionNo
        ].questionUuid
      )
    ) {
      return true;
    } else {
      return false;
    }
    // Checking if selected element is section and is focused
  } else if (
    formRef.current.selectedElement.pageNo !== undefined &&
    formRef.current.selectedElement.sectionNo !== undefined &&
    formRef.current.selectedElement.questionNo === undefined &&
    document.getElementById("form-name") === null &&
    document.activeElement !== document.getElementById("form-name") &&
    document.activeElement !== document.getElementById("form-description")
  ) {
    if (
      document.activeElement ===
      document.getElementById(
        formRef.current.schema.pages[formRef.current.selectedElement.pageNo].sections[formRef.current.selectedElement.sectionNo].sectionUuid
      )
    ) {
      return true;
    } else {
      return false;
    }
    // Checking if selected element is page and is focused
  } else if (
    formRef.current.selectedElement.pageNo !== undefined &&
    formRef.current.selectedElement.sectionNo === undefined &&
    formRef.current.selectedElement.questionNo === undefined &&
    document.getElementById("form-name") === null &&
    document.activeElement !== document.getElementById("form-name") &&
    document.activeElement !== document.getElementById("form-description")
  ) {
    if (document.activeElement === document.getElementById(formRef.current.schema.pages[formRef.current.selectedElement.pageNo].pageUuid)) {
      return true;
    } else {
      return false;
    }
    // Checking if selected element is form name and is focused
  } else if (document.getElementById("form-name") !== null && document.activeElement === document.getElementById("form-name")) {
    if (document.activeElement === document.getElementById("form-name")) {
      return true;
    } else {
      return false;
    }
    // Checking if selected element is form description and is focused
  } else if (document.getElementById("form-description") !== null && document.getElementById("form-name") === null) {
    if (document.activeElement === document.getElementById("form-description")) {
      return true;
    } else {
      return false;
    }
  }
};

export const ADD_PAGE = (form, setForm, defaultMcq, newQuestionType) => {
  let pageNo;
  let sectionNo;

  pageNo = form.schema.pages.length ? form.schema.pages.length - 1 : 0;
  if (form.schema.pages[pageNo]?.sections?.length) {
    sectionNo = form.schema.pages[pageNo].sections.length - 1;
  } else {
    sectionNo = 0;
  }

  let newQuestion = RETURN_LAST_USED_QUESTION(form, pageNo, sectionNo, newQuestionType, defaultMcq);

  if (form?.selectedElement?.pageNo !== undefined) {
    form.schema.pages.splice(form?.selectedElement?.pageNo + 1, 0, {
      name: "Page",
      isOpen: true,
      pagePlaceHolder: `Page ${form.schema.pages.length + 1}`,
      pageUuid: uuid(),
      sections: [
        {
          name: "Section",
          isOpen: true,
          sectionsPlaceHolder: "Section 1",
          sectionUuid: uuid(),
          questions: [{ ...newQuestion }],
        },
      ],
    });
    setForm({
      ...form,
      selectedElement: {
        pageNo: form?.selectedElement?.pageNo + 1,
        sectionNo: 0,
        questionNo: 0,
      },
    });
  } else {
    form.schema.pages.push({
      name: "Page",
      isOpen: true,
      pagePlaceHolder: `Page ${form.schema.pages.length + 1}`,
      pageUuid: uuid(),
      sections: [
        {
          name: "Section",
          isOpen: true,
          sectionsPlaceHolder: "Section 1",
          sectionUuid: uuid(),
          questions: [{ ...newQuestion }],
        },
      ],
    });
    setForm({
      ...form,
      selectedElement: {
        pageNo: form.schema.pages.length - 1,
        sectionNo: 0,
        questionNo: 0,
      },
    });
  }
};

export const ADD_SECTION = (form, setForm, defaultMcq, newQuestionType) => {
  let pageNo;
  let sectionNo;
  pageNo = form.schema.pages.length ? form.schema.pages.length - 1 : 0;
  if (form.schema.pages[pageNo]?.sections?.length) {
    sectionNo = form.schema.pages[pageNo].sections.length - 1;
  } else {
    sectionNo = 0;
  }

  let newQuestion = RETURN_LAST_USED_QUESTION(form, pageNo, sectionNo, newQuestionType, defaultMcq);

  if (
    !form.selectedElement ||
    (form.selectedElement.pageNo === undefined && form.selectedElement.sectionNo === undefined && form.selectedElement.questionNo === undefined)
  ) {
    if (form.schema.pages.length === 0) {
      form.schema.pages.push({
        name: "Page",
        isOpen: true,
        pagePlaceHolder: `Page ${form.schema.pages.length + 1}`,
        pageUuid: uuid(),
        sections: [],
      });
    }
    if (form.schema.pages.length) {
      pageNo = form.schema.pages.length - 1;
    } else {
      pageNo = 0;
    }

    form.schema.pages[pageNo].sections.push({
      name: "Section",
      isOpen: true,
      sectionsPlaceHolder: `Section ${form.schema.pages[pageNo].sections.length + 1}`,
      sectionUuid: uuid(),
      questions: [{ ...newQuestion }],
    });
    setForm({ ...form });
  } else {
    form.schema.pages[form.selectedElement.pageNo].sections.splice(form?.selectedElement?.sectionNo + 1, 0, {
      name: "Section",
      isOpen: true,
      sectionsPlaceHolder: `Section ${form.schema.pages[form.selectedElement.pageNo].sections.length + 1}`,
      sectionUuid: uuid(),
      questions: [{ ...newQuestion }],
    });
    setForm({
      ...form,
      selectedElement: {
        pageNo: form.selectedElement.pageNo,
        sectionNo:
          form?.selectedElement?.sectionNo !== undefined ? form?.selectedElement?.sectionNo + 1 : sectionNo !== undefined ? sectionNo + 1 : undefined,
        questionNo: 0,
      },
    });
  }
};

export const GET_MCQ_NAME = (text) => {
  if (text === "Access Forms") {
    return i18n("Roles.AccessForms");
  } else if (text === "Create") {
    return i18n("Roles.Create");
  } else if (text === "Edit") {
    return i18n("Roles.Edit");
  } else if (text === "Archive") {
    return i18n("Roles.Archive");
  } else if (text === "Delete") {
    return i18n("Roles.Delete");
  } else if (text === "View History") {
    return i18n("Roles.ViewHistory");
  } else if (text === "Create & Edit dispatch roles") {
    return i18n("Roles.CreateDispatchRoles");
  } else if (text === "Delete dispatch roles") {
    return i18n("Roles.DeleteDispatchRoles");
  } else if (text === "Manage approval rights") {
    return i18n("Roles.ManageApprovalRights");
  } else if (text === "Access Reports") {
    return i18n("Roles.AccessReports");
  } else if (text === "Access reports") {
    return i18n("Roles.AccessReports");
  } else if (text === "Delete archived reports") {
    return i18n("Roles.DeleteArchivedReports");
  } else if (text === "Restore deleted reports") {
    return i18n("Roles.RestoreDeletedReports");
  } else if (text === "Export other reports") {
    return i18n("Roles.ExportOtherReports");
  } else if (text === "View history of other reports") {
    return i18n("Roles.ViewHistoryOfOtherReports");
  } else if (text === "Access Company Setting") {
    return i18n("Roles.AccessCompanySetting");
  } else if (text === "Manage sites") {
    return i18n("Roles.ManageSites");
  } else if (text === "Manage users") {
    return i18n("Roles.ManageUsers");
  } else if (text === "Manage roles") {
    return i18n("Roles.ManageRoles");
  } else if (text === "Manage company information") {
    return i18n("Roles.ManageCompanyInformation");
  } else if (text === "Manage subscription") {
    return i18n("Roles.ManageSubscription");
  } else if (text === "Export report") {
    return i18n("Roles.ExportReport");
  } else if (text === "Customize report") {
    return i18n("Roles.CustomizeReport");
  }
  if (text === "Yes") {
    return i18n("global.Yes");
  } else if (text === "Enter Response") {
    return i18n("Form.EnterResponse");
  } else if (text === "N/A") {
    return i18n("global.N/a");
  } else if (text === "No") {
    return i18n("global.No");
  } else if (text === "Good") {
    return i18n("global.Good");
  } else if (text === "Fair") {
    return i18n("global.Fair");
  } else if (text === "Poor") {
    return i18n("global.Poor");
  } else if (text === "Safe") {
    return i18n("global.Safe");
  } else if (text === "Risk") {
    return i18n("global.Risk");
  } else if (text === "Pass") {
    return i18n("global.Pass");
  } else if (text === "Fail") {
    return i18n("global.Fail");
  } else if (text === "Compliant") {
    return i18n("global.Compliant");
  } else if (text === "Non-Compliant") {
    return i18n("global.NonCompliant");
  } else {
    return text;
  }
};

export const GET_ROLES_NAME = (text) => {
  if (text === "Access Forms" || text === "نماذج الوصول") {
    return i18n("Roles.AccessForms");
  } else if (text === "Create" || text === "إنشاء") {
    return i18n("Roles.Create");
  } else if (text === "Edit" || text === "تحرير") {
    return i18n("Roles.Edit");
  } else if (text === "Archive" || text === "أرشيف") {
    return i18n("Roles.Archive");
  } else if (text === "Delete" || text === "حذف") {
    return i18n("Roles.Delete");
  } else if (text === "View History" || text === "مشاهدة السجل") {
    return i18n("Roles.ViewHistory");
  } else if (text === "Create & Edit dispatch roles" || text === "إنشاء و تحرير قواعد التوزيع") {
    return i18n("Roles.CreateDispatchRoles");
  } else if (text === "Delete dispatch roles" || text === "حذف أدوار الإرسال") {
    return i18n("Roles.DeleteDispatchRoles");
  } else if (text === "Manage approval rights" || text === "إدارة حقوق الموافقة") {
    return i18n("Roles.ManageApprovalRights");
  } else if (text === "Access Reports" || text === "الوصول للتقارير") {
    return i18n("Roles.AccessReports");
  } else if (text === "Access reports" || text === "الوصول للتقارير") {
    return i18n("Roles.AccessReports");
  } else if (text === "Delete archived reports" || text === "حذف التقارير المؤرشفة") {
    return i18n("Roles.DeleteArchivedReports");
  } else if (text === "Restore deleted reports" || text === "استعادة التقارير المحذوفة") {
    return i18n("Roles.RestoreDeletedReports");
  } else if (text === "Export other reports" || text === "تصدير التقارير الأخرى") {
    return i18n("Roles.ExportOtherReports");
  } else if (text === "View history of other reports" || text === "عرض محفوظات التقارير الأخرى") {
    return i18n("Roles.ViewHistoryOfOtherReports");
  } else if (text === "Access Company Setting" || text === "مشاهدة السجل للتقارير الأخرى") {
    return i18n("Roles.AccessCompanySetting");
  } else if (text === "Manage sites" || text === "إدارة المواقع") {
    return i18n("Roles.ManageSites");
  } else if (text === "Manage users" || text === "ادارة المستخدمين") {
    return i18n("Roles.ManageUsers");
  } else if (text === "Manage roles" || text === "إدارة الأدوار") {
    return i18n("Roles.ManageRoles");
  } else if (text === "Manage company information" || text === "إدارة معلومات الشركة") {
    return i18n("Roles.ManageCompanyInformation");
  } else if (text === "Manage subscription" || text === "إدارة الاشتراك") {
    return i18n("Roles.ManageSubscription");
  } else if (text === "Export report" || text === "تصدير التقارير ") {
    return i18n("Roles.ExportReport");
  } else if (text === "Customize report" || text === "تخصيص التقارير") {
    return i18n("Roles.CustomizeReport");
  } else if (text === "Access Analytics" || text === "الوصول إلى التحليلات") {
    return i18n("Roles.AccessAnalytics");
  } else if (text === "Export" || text === "يصدّر") {
    return i18n("Analytics.Export");
  }
};
