/** @format */

import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import Select from "react-select";
import DialogModal from "../../../../../components/dialogs/compact-dialog";
import ConfirmationDialog from "../../../../../components/dialogs/confirmation-dialog";
import { getSession } from "../../../../../redux/reducers/authentication/authentication";
import { getSubscriptionByCompanyId, updateSubscriptionData } from "../../../../../redux/reducers/subscription/subscription.reducer";
import {
  assignUserResponsibility,
  getAllUsers,
  getUserById,
  getUserDataById,
  userDeleteById,
  userDispatchRule,
  userPremiumById,
} from "../../../../../redux/reducers/user/user.reducer";
import I18n, { i18n } from "../../../languageSelector";
import UsersAdd from "./company-users-add";
import UsersProfile from "./company-users-profile";
import UsersView from "./company-users-view";
import { UserUiProvider } from "./users-ui-context";

const UsersPage = ({
  history,
  getSession,
  getAllUsers,
  getUserById,
  userDeleteById,
  userPremiumById,
  getUserDataById,
  assignUserResponsibility,
  userDispatchRule,
  subscriptionData,
  updateSubscriptionData,
  getSubscriptionByCompanyId,
}) => {
  const [newUserId, setNewUserId] = useState("");
  const [usersToShow, setUsersToShow] = useState([]);
  const [existingUserId, setExistingUserId] = useState("");
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [isUserHasDispatchRule, setIsUserHasDispatchRule] = useState(false);

  //  changed the user seats
  const userSubscriptionSeatToUpdate = {
    seats: {
      used: subscriptionData?.seats?.used - 1,
      available: subscriptionData?.seats?.available + 1,
      total: subscriptionData?.seats?.total,
    },
  };

  useEffect(() => {
    if (existingUserId) {
      userDispatchRule(existingUserId).then((response) => {
        setIsUserHasDispatchRule(response.value.data);
      });
    }
  }, [existingUserId, userDispatchRule]);
  const UserUIEvents = {
    openUserViewDialog: (id) => {
      history.push(`/management/users/${id}/view`);
    },
    openUserEditDialog: (id) => {
      history.push(`/management/users/${id}/edit`);
    },
    openUserDeleteDialog: (id) => {
      setExistingUserId(id);
      history.push(`/management/users/${id}/delete`);
    },
    createPremiumUser: (user) => {
      userPremiumById({ id: user.id, isPremium: !user.isPremium })
        .then(() => {
          getAllUsers();
          getSession();
        })
        .catch((err) => toast.error(err.response.data.message));
    },
  };

  const userDeleteHandler = (id) => {
    if (id) {
      getUserDataById(id).then((res) => {
        if (res.value.data) {
          userDeleteById(id)
            .then(() => {
              updateSubscriptionData(subscriptionData?.id, userSubscriptionSeatToUpdate, "Update").then((res) => {
                getSubscriptionByCompanyId(subscriptionData?.company);
              });
              toast.success(<I18n lng="validation.UserDeleted" />);
              getAllUsers();

              getSession();
              history.push("/management/users");
            })
            .catch((error) => {
              toast.error(error.response.data.message);
            });
        } else {
          getUserById(id).then((ress) => {
            if (ress.value.data.role !== "notRegistered") {
              getAllUsers().then((res) => {
                let users = [];
                if (res?.value?.data?.length) {
                  users = res?.value?.data.filter((item) => {
                    if (!item.isUserSeated) return false;
                    if (!item.isEmailVerified) return false;
                    // if (item.role === "notRegistered") return false;
                    if (ress.value.data.role === "manager") {
                      return ["manager", "manager/inspector", "admin"].includes(item.role) && item.id !== ress.value.data.id;
                    } else if (ress.value.data.role === "user") {
                      return ["user", "manager/inspector", "admin"].includes(item.role) && item.id !== ress.value.data.id;
                    } else if (ress.value.data.role === "manager/inspector") {
                      return ["manager/inspector", "admin"].includes(item.role) && item.id !== ress.value.data.id;
                    }
                    return false;
                  });
                  if (users.length || isUserHasDispatchRule) {
                    setExistingUserId(id);
                    setUsersToShow([...users]);
                    setShowConfirmationDialog(true);
                  }
                }
              });
            } else {
              userDeleteById(id)
                .then(() => {
                  updateSubscriptionData(subscriptionData?.id, userSubscriptionSeatToUpdate, "Update").then((res) => {
                    getSubscriptionByCompanyId(subscriptionData?.company);
                  });
                  toast.success(<I18n lng="validation.UserDeleted" />);
                  getAllUsers();
                  getSession();
                  history.push("/management/users");
                })
                .catch((error) => {
                  toast.error(error.response.data.message);
                });
            }
          });
        }
      });
    }
  };

  return (
    <>
      <UserUiProvider UserUIEvents={UserUIEvents}>
        <Switch>
          <Route path="/management/users/add">
            {({ history, match }) => (
              <UsersAdd
                showDlg={match != null}
                secondaryAction={() => {
                  history.push("/management/users");
                }}
                hideInviteLinks={true}
              />
            )}
          </Route>
          <Route path="/management/users/:id/view">
            {({ history, match }) => (
              <UsersAdd
                showDlg={match != null}
                secondaryAction={() => {
                  history.push("/management/users");
                }}
                view={true}
              />
            )}
          </Route>
          <Route path="/management/users/:id/edit">
            {({ history, match }) => (
              <UsersAdd
                showDlg={match != null}
                secondaryAction={() => {
                  history.push("/management/users");
                }}
                editUser={true}
              />
            )}
          </Route>
          <Route path="/management/users/profile">
            {({ history, match }) => (
              <UsersProfile
                showDlg={match != null}
                secondaryAction={() => {
                  history.push("/management/users");
                }}
              />
            )}
          </Route>
          <Route path="/management/users/:id/delete">
            {({ history, match }) => (
              <>
                <DialogModal
                  iconCloseDlg
                  title={<I18n lng="global.Note" />}
                  classes="modal-alert"
                  showDlg={match != null}
                  content={
                    <p>
                      <I18n lng="Company.DeleteUser" />
                    </p>
                  }
                  primaryAction={userDeleteHandler}
                  primaryActionTitle={<I18n lng="Company.UserDelete" />}
                  primaryActionClasses={"btn-danger"}
                  secondaryAction={() => history.push("/management/users")}
                />
              </>
            )}
          </Route>
        </Switch>
        <UsersView />
        <ConfirmationDialog
          centered
          showDlg={showConfirmationDialog}
          title={<I18n lng="global.Note" />}
          classes="modal-alert"
          iconCloseDlg
          content={
            <>
              <p>
                <I18n lng="Company.AssignUser" />
              </p>
              <br />
              <Select
                label={<I18n lng="Company.SelectUser" />}
                options={
                  usersToShow?.length
                    ? usersToShow?.map((item) => {
                        return {
                          value: item?.id,
                          label: item?.fullName,
                        };
                      })
                    : []
                }
                onChange={(option) => setNewUserId(option.value)}
              />
              <br />
            </>
          }
          primaryActionTitle={<I18n lng="Company.AssignAndDelete" />}
          secondryActionTitle={<I18n lng="global.Cancel" />}
          secondaryAction={() => setShowConfirmationDialog(false)}
          primaryAction={() => {
            if (existingUserId && newUserId) {
              assignUserResponsibility(existingUserId, newUserId, "delete").then(() => {
                userDeleteById(existingUserId).then(() => {
                  toast.success(<I18n lng="validation.UserDeleted" />);
                  getAllUsers();
                  getSession();
                  history.push("/management/users");
                });
                setShowConfirmationDialog(false);

                history.push("/management/users");
              });
            } else {
              toast.error(i18n("Company.PleaseSelectUser"));
            }
          }}
          primaryActionClasses={"btn-success"}
          secondryActionClasses={"btn-danger"}
        />
      </UserUiProvider>
    </>
  );
};

const mapStateToProps = ({ subscription }) => ({
  subscriptionData: subscription.subscriptionData,
});

const mapDispatchToProps = {
  getSession,
  getUserById,
  getAllUsers,
  userDeleteById,
  getUserDataById,
  userPremiumById,
  userDispatchRule,
  assignUserResponsibility,
  getSubscriptionByCompanyId,
  updateSubscriptionData,
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersPage);
