/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import IconScrollTop from "../../assets/images/icon-scroll-top.svg";
import IconCloseDrawer from "../../assets/images/icon-close-drawer.svg";
import IconCloseDialog from "../../assets/images/icon-close-dialog.svg";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const DynamicModal = ({
  customClasses = {},
  modalContentClass,
  show = false,
  children,
  title,
  id,
  primaryAction = () => {},
  secondaryAction = () => {},
  count,
  showFormData = false,
  formDetailPrimaryAction = () => {},
  formDetailSecondaryAction = () => {},
}) => {
  const scrollRef = useRef(null);
  const executeScroll = () => scrollRef.current.scrollIntoView();

  const [showScrollTopButton, setShowScrollButton] = useState(false);

  return (
    <Modal
      isOpen={show}
      toggle={() => secondaryAction()}
      className={customClasses}
      contentClassName={modalContentClass}
      id={id}
    >
      <button
        className={`btn-scroll-top ${showScrollTopButton ? "show" : "hide"}`}
        onClick={executeScroll}
      >
        <img src={IconScrollTop} alt="IconScrollTop icon" />
      </button>

      <ModalHeader toggle={secondaryAction}>
        <Link to="#" className="close-drawer" onClick={() => secondaryAction()}>
          <img src={IconCloseDrawer} alt="IconCloseDialog" />
        </Link>{" "}
        <span>{title}</span>
        <small>{count}</small>
      </ModalHeader>
      <ModalBody
        onScroll={(e) => {
          e.target.scrollTop > 100
            ? setShowScrollButton(true)
            : setShowScrollButton(false);
          e.target.scrollTop > 150 && showFormData
            ? formDetailPrimaryAction()
            : formDetailSecondaryAction();
        }}
      >
        <div ref={scrollRef}></div>

        <Link to="#" className="close-dialog" onClick={() => secondaryAction()}>
          <img src={IconCloseDialog} alt="IconCloseDialog" />
        </Link>
        {children}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => primaryAction()}>
          Do Something
        </Button>
        <Button color="secondary" onClick={() => secondaryAction()}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DynamicModal;
