import axios from "axios";
import { REQUEST, SUCCESS, FAILURE } from "../action-type.util";
export const ACTION_TYPES = {
  CREATE_SITE: "site/Create",
  GET_ALL_SITES: "get/sites",
  GET_SITE_BY_ID: "get/site/By/id",
  UPDATE_SITE_BY_ID: "update/site/By/id",
};

const initialState = {
  site: {},
  sites: [],
  siteByID: {},
  loading: false,
  error: undefined,
};

export const SiteState = initialState;

// Reducer
// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.CREATE_SITE):
    case REQUEST(ACTION_TYPES.GET_ALL_SITES):
    case REQUEST(ACTION_TYPES.GET_SITE_BY_ID):
    case REQUEST(ACTION_TYPES.UPDATE_SITE_BY_ID):
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.CREATE_SITE):
    case FAILURE(ACTION_TYPES.GET_ALL_SITES):
    case FAILURE(ACTION_TYPES.GET_SITE_BY_ID):
    case FAILURE(ACTION_TYPES.UPDATE_SITE_BY_ID):
      return {
        ...state,
        error: undefined,
        loading: false,
      };
    case SUCCESS(ACTION_TYPES.GET_ALL_SITES):
      return {
        ...state,
        sites: action.payload.data,
        error: undefined,
        loading: false,
      };
    case SUCCESS(ACTION_TYPES.GET_SITE_BY_ID):
      return {
        ...state,
        siteByID: action.payload.data,
        error: undefined,
        loading: false,
      };
    case SUCCESS(ACTION_TYPES.UPDATE_SITE_BY_ID):
      return {
        ...state,
        error: undefined,
        loading: false,
      };
    case SUCCESS(ACTION_TYPES.CREATE_SITE):
      return {
        ...state,
        site: action.payload.data,
        error: undefined,
        loading: false,
      };
    default:
      return state;
  }
};

// Actions

export const createSite = (data) => async (dispatch) => {
  let createSite = {
    active: data.active,
    name: data.name,
    email: data.email,
    mobile: data.mobile ? data.mobile : "",
    address: data.address,
    city: data.city,
    state: data.state,
    postalCode: data.postalCode,
    country: data.country ? data.country.label : "",
    timeZone: data.timeZone ? data.timeZone.label : "",
    image: data.image ? data.image : "",
  };
  return await dispatch({
    type: ACTION_TYPES.CREATE_SITE,
    payload: axios.post(`/sites`, { ...createSite }),
  });
};
export const getAllSites = () => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.GET_ALL_SITES,
    payload: axios.get(`/sites`),
  });
};
export const getSiteById = (id) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.GET_SITE_BY_ID,
    payload: axios.get(`/sites/${id}`),
  });
};
export const updateSiteById = (data) => async (dispatch) => {
  let updateSite = {
    active: data.active,
    name: data.name,
    email: data.email,
    mobile: data.mobile ? data.mobile : "",
    address: data.address,
    city: data.city,
    state: data.state,
    postalCode: data.postalCode,
    country: data.country.label ? data.country.label : "",
    timeZone: data.timeZone.label
      ? data.timeZone.label
      : data.timeZone
      ? data.timeZone
      : "",
    image: data.image ? data.image : "",
  };
  return await dispatch({
    type: ACTION_TYPES.GET_SITE_BY_ID,
    payload: axios.patch(`/sites/${data.id}`, { ...updateSite }),
  });
};

export const deleteSiteById = (id) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.GET_SITE_BY_ID,
    payload: axios.delete(`/sites/${id}`),
  });
};
