import React, { useEffect, useState } from "react";
import Select from "react-select";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import DynamicModal from "../../../../components/dialogs";
import PagePreview from "../reports-previews/pageReportPreview";
import { getReportsLogs } from "../../../../redux/reducers/admin-manager-reports/admin-manager-report.reducer";
import I18n from "./../../languageSelector";

const ReportHistory = ({
  showDlg,
  reportLogs,
  getReportsLogs,
  secondaryAction,
}) => {
  const { id } = useParams();

  const [oldAnswers, setOldAnswers] = useState([]);
  const [pageName, setPageName] = useState("title");
  const [updatedAnswers, setUpdatedAnswers] = useState([]);
  const [isViewFullReport, setIsViewFullReport] = useState(0);

  useEffect(() => {
    if (id) {
      getReportsLogs(id);
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    setOldAnswers([]);
    setUpdatedAnswers([]);
  }, []);

  useEffect(() => {
    if (reportLogs) {
      setOldAnswers([...reportLogs.lastFormAnswerDetail]);
      setUpdatedAnswers([...reportLogs.formAnswerDetail]);
    }
  }, [reportLogs]);

  const getOldAnswer = (uuid) => {
    if (oldAnswers && oldAnswers.length) {
      const answer = oldAnswers.find((item) => item?.questionUuid === uuid);
      return answer ? answer : undefined;
    }
  };

  const getUpdatedAnswer = (uuid) => {
    if (updatedAnswers && updatedAnswers) {
      const answer = updatedAnswers.find((item) => item?.questionUuid === uuid);
      return answer ? answer : undefined;
    }
  };

  return (
    <DynamicModal
      customClasses="modal-drawer modal-report-preview"
      show={showDlg}
      secondaryAction={secondaryAction}
    >
      <div className="report-preview">
        <section>
          <Select
            placeholder={<I18n lng="global.Select" />}
            className="form-preview-select"
            onChange={(e) => {
              setIsViewFullReport(e.value === "title" ? 0 : e.value);
              setPageName(e.label);
            }}
            value={{
              value:
                reportLogs?.report?.formDetail?.schema?.pages[isViewFullReport]
                  .name === "Page"
                  ? `${
                      reportLogs?.report?.formDetail?.schema?.pages[
                        isViewFullReport
                      ].name
                    } ${isViewFullReport + 1}`
                  : reportLogs?.report?.formDetail?.schema?.pages[
                      isViewFullReport
                    ].name,
              label:
                reportLogs?.report?.formDetail?.schema?.pages[isViewFullReport]
                  .name === "Page"
                  ? `${
                      reportLogs?.report?.formDetail?.schema?.pages[
                        isViewFullReport
                      ].name
                    } ${isViewFullReport + 1}`
                  : reportLogs?.report?.formDetail?.schema?.pages[
                      isViewFullReport
                    ].name,
            }}
            options={
              reportLogs?.report?.formDetail?.schema?.pages?.length
                ? [
                    ...reportLogs?.report?.formDetail?.schema.pages.map(
                      (item, index) => {
                        return {
                          value: index,
                          label:
                            item.name === "Page"
                              ? `${item.name} ${index + 1}`
                              : item.name,
                        };
                      }
                    ),
                  ]
                : []
            }
          />
          {reportLogs &&
          reportLogs?.report?.formDetail?.schema?.pages.length &&
          oldAnswers &&
          updatedAnswers &&
          updatedAnswers.length ? (
            <PagePreview
              page={
                reportLogs?.report?.formDetail?.schema?.pages[
                  isViewFullReport !== null && pageName !== "title"
                    ? isViewFullReport
                    : 0
                ]
              }
              index={isViewFullReport}
              getOldAnswer={getOldAnswer}
              getUpdatedAnswer={getUpdatedAnswer}
              reportHistory={true}
              reportLogs={reportLogs?.report}
            />
          ) : (
            <p>
              <I18n lng="Reports.NoAnswerUpdated" />
            </p>
          )}
        </section>
      </div>
    </DynamicModal>
  );
};

const mapStateToProps = ({ adminManagerReports }) => ({
  reportLogs: adminManagerReports.reportLogs,
});

const mapDispatchToProps = {
  getReportsLogs,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportHistory);
