import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import { PDFViewer } from "react-view-pdf";
import IconCloseDialog from "../../assets/images/icon-close-dialog.svg";
import { FaDownload } from "react-icons/fa";
import I18n from "../../pages/private/languageSelector";
import { TEXT_DIRECTION } from "../../helper/helperFunctions";
import useDownloader from "react-use-downloader";

const PdfViewDialog = ({ showDlg, pdfUrl, secondaryAction, answerValue }) => {
  const [show, setShow] = useState(false);

  const { download } = useDownloader();
  const fileUrl = pdfUrl;
  const filename = answerValue;

  useEffect(() => {
    setShow(showDlg);
  }, [showDlg]);
  return (
    <Modal
      size={"lg"}
      isOpen={show}
      toggle={secondaryAction}
      className={"modal-alert-dialog"}
    >
      <div className="d-flex">
        <div>
          <Link
            to="#"
            onClick={secondaryAction}
            className="modal-close px-3 py-2"
          >
            <img src={IconCloseDialog} alt="IconCloseDialog" />
          </Link>
        </div>
        <div
          className={`mt-1 ${
            TEXT_DIRECTION() === "rtl" ? "mr-auto ml-3" : "ml-auto mr-3"
          }`}
        >
          <button
            style={{
              color: "black",
              cursor: "pointer",
              backgroundColor: "white",
              border: "none",
            }}
            onClick={() => download(fileUrl, filename)}
          >
            <span className="mt-2">
              <I18n lng="global.DownloadPDF" />
            </span>{" "}
            <FaDownload />
          </button>
        </div>
      </div>
      <ModalBody>
        <div style={{ direction: "ltr" }} className="modal-title text-center">
          <strong>
            <PDFViewer url={pdfUrl} />
          </strong>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default PdfViewDialog;
