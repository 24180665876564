import axios from "axios";
import { REQUEST, SUCCESS, FAILURE } from "../../../../redux/reducers/action-type.util";
export const ACTION_TYPES = {
  GET_ANALYTICS: "analytics/GET_ANALYTICS",
  GET_ALL_ANALYTICS: "analytics/GET_ALL_ANALYTICS",
  GET_ANALYTICS_SITES: "analytics/GET_SITES",
  GET_ANALYTICS_FORMS: "analytics/GET_FORMS",
  GET_ANALYTICS_USERS: "analytics/GET_USERS",
  UPDATE_ANALYTICS_USER_REPORT: "analytics/UPDATE_USER",
};

const initialState = {
  loading: false,
  analytics: {},
  allAnalyticsData: [],
  analyticSites: [],
  analyticForms: [],
  analyticUsers: [],
  error: undefined,
  analyticToEdit: undefined,
};
export const analyticState = initialState;

//Reducer
// eslint-disable-next-line
export default (state = initialState, action: any) => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.GET_ANALYTICS):
      return {
        ...state,
        error: undefined,
      };
    case REQUEST(ACTION_TYPES.GET_ALL_ANALYTICS):
    case REQUEST(ACTION_TYPES.GET_ANALYTICS_FORMS):
    case REQUEST(ACTION_TYPES.GET_ANALYTICS_SITES):
    case REQUEST(ACTION_TYPES.GET_ANALYTICS_USERS):
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.GET_ANALYTICS):
      return {
        ...state,
        error: undefined,
      };
    case FAILURE(ACTION_TYPES.GET_ALL_ANALYTICS):
    case FAILURE(ACTION_TYPES.GET_ANALYTICS_FORMS):
    case FAILURE(ACTION_TYPES.GET_ANALYTICS_SITES):
    case FAILURE(ACTION_TYPES.GET_ANALYTICS_USERS):
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case SUCCESS(ACTION_TYPES.GET_ANALYTICS):
      return {
        ...state,
        analytics: action.payload.data,
        error: undefined,
        isChartLoading: false,
      };
    case SUCCESS(ACTION_TYPES.GET_ALL_ANALYTICS):
      return {
        ...state,
        allAnalyticsData: action.payload.data,
        error: undefined,
      };
    case SUCCESS(ACTION_TYPES.GET_ANALYTICS_FORMS):
      return {
        ...state,
        analyticForms: action.payload.data,
        error: undefined,
        loading: false,
      };
    case SUCCESS(ACTION_TYPES.GET_ANALYTICS_SITES):
      return {
        ...state,
        analyticSites: action.payload.data,
        error: undefined,
        loading: false,
      };
    case SUCCESS(ACTION_TYPES.GET_ANALYTICS_USERS):
      return {
        ...state,
        analyticUsers: action.payload.data,
        error: undefined,
        loading: false,
      };
    default:
      return state;
  }
};

//Actions
interface Iprops {
  page?: number;
  size?: number;
  startDate: string;
  endDate: string;
  selectedDate?: string;
  selectedFormIds: string[];
  selectedUserIds: string[];
  selectedSiteIds: string[];
  selectedStatus: string[];
}
export const getAnalytics = (data: Iprops) => async (dispatch: any) => {
  console.log(data);
  return await dispatch({
    type: ACTION_TYPES.GET_ANALYTICS,
    payload: axios.get(
      `/analytics?limit=${data.size}&page=${data.page}&sortBy=updatedAt:desc${
        data?.selectedFormIds?.length > 0 ? `&formIds=${data?.selectedFormIds?.join(",")}` : `&formIds=undefined`
      }${data?.selectedUserIds?.length > 0 ? `&userIds=${data?.selectedUserIds?.join(",")}` : `&userIds=undefined`}${
        data?.selectedSiteIds?.length > 0 ? `&siteIds=${data?.selectedSiteIds?.join(",")}` : `&siteIds=undefined`
      }${data?.selectedStatus.length > 0 ? `&reportStatus=${data?.selectedStatus?.join(",")}` : `&reportStatus=undefined`}${
        data.startDate ? `&startDate=${data.startDate}` : ""
      }${data.endDate ? `&endDate=${data.endDate}` : ""}${data?.selectedDate ? `&selectedDate=${data.selectedDate}` : "Last30Days"}`
    ),
  });
};

export const getAllAnalytics = (data: Iprops) => async (dispatch: any) => {
  console.log(data);
  return await dispatch({
    type: ACTION_TYPES.GET_ALL_ANALYTICS,
    payload: axios.get(
      `/analytics/all?${data?.selectedFormIds?.length > 0 ? `&formIds=${data?.selectedFormIds?.join(",")}` : `&formIds=undefined`}${
        data?.selectedUserIds?.length > 0 ? `&userIds=${data?.selectedUserIds?.join(",")}` : `&userIds=undefined`
      }${data?.selectedSiteIds?.length > 0 ? `&siteIds=${data?.selectedSiteIds?.join(",")}` : `&siteIds=undefined`}${
        data?.selectedStatus.length > 0 ? `&reportStatus=${data?.selectedStatus?.join(",")}` : `&reportStatus=undefined`
      }${data.startDate ? `&startDate=${data.startDate}` : ""}${data.endDate ? `&endDate=${data.endDate}` : ""}`
    ),
  });
};
export const getAnalyticsSites = (isDeleted: boolean, searchName?: string) => async (dispatch: any) => {
  return await dispatch({
    type: ACTION_TYPES.GET_ANALYTICS_SITES,
    payload: axios.get(`/analytics/sites?isDeleted=${isDeleted}${searchName ? `&searchName=${searchName}` : ""}`),
  });
};

export const getAnalyticsForms = (isDeleted: boolean, searchName?: string) => async (dispatch: any) => {
  return await dispatch({
    type: ACTION_TYPES.GET_ANALYTICS_FORMS,
    payload: axios.get(`/analytics/forms?isDeleted=${isDeleted}${searchName ? `&searchName=${searchName}` : ""}`),
  });
};

export const getAnalyticsUsers = (isDeleted: boolean, searchName?: string) => async (dispatch: any) => {
  return await dispatch({
    type: ACTION_TYPES.GET_ANALYTICS_USERS,
    payload: axios.get(`/analytics/users?isDeleted=${isDeleted}${searchName ? `&searchName=${searchName}` : ""}`),
  });
};

export const updateAnalticsUser = (dataToUpdate: number[]) => async (dispatch: any) => {
  return await dispatch({
    type: ACTION_TYPES.UPDATE_ANALYTICS_USER_REPORT,
    payload: axios.patch(`/analytics/user-to-update`, {
      reportDataToUpdate: dataToUpdate,
    }),
  });
};
