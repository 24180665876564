import React from "react";
import I18n from "../../../languageSelector";

const ReportPdfImages = ({
  photo,
  imageCount = undefined,
  setImageIndex,
  setShowImageSrc,
  showDocumentMedia,
  setShowImageDialog,
}) => {
  const random = Math.round(1 + Math.random() * (1000000 - 1));

  return (
    <>
      {photo && photo.length
        ? photo.map((item, index) => {
            return (
              <span className="thumbnail" key={index}>
                <span
                  className="img-wrap"
                  onClick={() => {
                    setImageIndex(index);
                    setShowImageSrc(photo);
                    setShowImageDialog(true);
                  }}
                >
                  {!showDocumentMedia ? (
                    <img
                      src={item.thumbnail ? item.thumbnail : item.image}
                      alt="Thumbnail"
                      id={`image-${index}-${random}`}
                      onLoad={({ target: img }) => {
                        let style =
                          img.naturalWidth > img.naturalHeight
                            ? img.naturalHeight
                            : img.naturalWidth;
                        let documents;
                        documents = document.getElementById(
                          `image-${index}-${random}`
                        );
                        documents.setAttribute("height", style + "px");
                        documents.setAttribute("width", style + "px");
                      }}
                    />
                  ) : (
                    <a href={item.image} target="_blank" rel="noreferrer">
                      <img
                        src={item.thumbnail}
                        alt="Thumbnail"
                        id={`image-${index}-${random}`}
                        onLoad={({ target: img }) => {
                          let style =
                            img.naturalWidth > img.naturalHeight
                              ? img.naturalHeight
                              : img.naturalWidth;
                          let documents;
                          documents = document.getElementById(
                            `image-${index}-${random}`
                          );
                          documents.setAttribute("height", style + "px");
                          documents.setAttribute("width", style + "px");
                        }}
                      />
                    </a>
                  )}
                </span>
                {imageCount !== undefined ? (
                  <p className="text-center text-muted mb-0">
                    <I18n lng="global.Pic" /> {imageCount + index + 1}
                  </p>
                ) : null}
              </span>
            );
          })
        : null}
    </>
  );
};

export default ReportPdfImages;
