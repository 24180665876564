import React, { useEffect, useState } from "react";
import moment from "moment";
import { connect } from "react-redux";
import ListItemMenu from "./list-item-menu";
import { MdPermMedia } from "react-icons/md";
import I18n, { i18n } from "../../../../private/languageSelector";
import AlertDialog from "../../../../../components/dialogs/alertDialog";
import IconBookmark from "../../../../../assets/images/icon-bookmark.png";
import { getFormLastModifiedDate } from "../../../../../helper/date-time-helper";
import {
  formManagmentIsRead,
  getBookmark,
} from "../../../../../redux/reducers/form/form.reducer";
import {
  getAllNotifications,
  notificationReadSingle,
} from "../../../../../redux/reducers/notifications/notifications.reducer";
import { isRTL, TEXT_DIRECTION } from "../../../../../helper/helperFunctions";

const ListItem = ({
  form,
  archiveForm,
  currentRole,
  openEditForm,
  openFormView,
  openFormStart,
  openFormDispatch,
  loading,
  setLoading,
  allNotifications,
  getBookmark,
  formManagmentIsRead,
  user,
  createDuplicateForm,
  getAllNotifications,
  openArchvieFormDialog,
  notificationReadSingle,
  bookmarkUnbookmarkForm,
}) => {
  const [showAlertDialog, setShowAlertDialog] = useState(false);
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [managerFormNotification, setManagerFormNotification] =
    useState(undefined);
  const [inspectorFormNotification, setInspectorFormNotification] =
    useState(undefined);

  useEffect(() => {
    if (user && form) {
      getBookmark(user.id, "FORM").then((res) => {
        const bookmarkedItem = res?.value?.data.find(
          (item) => item?.moduleId === form?.id && user.id === item?.userId
        );

        if (bookmarkedItem) {
          setIsBookmarked(true);
        } else {
          setIsBookmarked(false);
        }

        setLoading(false);
      });
    }
    // eslint-disable-next-line
  }, [loading]);

  useEffect(() => {
    if (allNotifications && allNotifications.length) {
      let updatedForm = allNotifications.filter((item) => {
        return (
          item.event === "formUpdate" && item.form && item.form.id === form.id
        );
      });

      let assignedForm = allNotifications.filter((item) => {
        return (
          (item?.event === "formUpdate" &&
            item?.form &&
            item?.form?.id === form?.id) ||
          (item?.event === "assigned" &&
            item?.formManagement?.form &&
            item?.formManagement?.form?.id === form?.id)
        );
      });

      if (updatedForm && updatedForm.length) {
        setManagerFormNotification({ ...updatedForm[0] });
      }

      if (assignedForm && assignedForm.length) {
        setInspectorFormNotification({
          ...assignedForm[0],
        });
      }
    } else {
      setManagerFormNotification(undefined);
    }
  }, [form, allNotifications]);
  return (
    <li>
      <div
        className={`list-item-avatar ${
          currentRole !== "user" &&
          managerFormNotification !== undefined &&
          !managerFormNotification?.isRead
            ? "status-submit"
            : (currentRole === "user" &&
                inspectorFormNotification?.event === "assigned" &&
                !inspectorFormNotification?.isRead) ||
              (currentRole === "user" &&
                inspectorFormNotification?.event === "formUpdate" &&
                !inspectorFormNotification?.isRead)
            ? "status-pending"
            : ""
        }`}
      >
        <span
          className="avatar-wrap"
          onClick={() => {
            if (
              currentRole === "user" &&
              inspectorFormNotification !== undefined &&
              !inspectorFormNotification?.isRead
            ) {
              setShowAlertDialog(true);
            } else if (
              currentRole !== "user" &&
              managerFormNotification !== undefined &&
              !managerFormNotification?.isRead
            ) {
              setShowAlertDialog(true);
            }
          }}
        >
          {form?.formDetail?.image ? (
            <img src={form?.formDetail?.image} alt="Avatar" />
          ) : (
            <div className="svgItem">
              <MdPermMedia />
            </div>
          )}
        </span>

        <div className="avatar-badge">
          {currentRole !== "user"
            ? isBookmarked && (
                <img
                  className="icon icon-bookmark active"
                  src={IconBookmark}
                  alt="Bookmark"
                />
              )
            : isBookmarked && (
                <img
                  className="icon icon-bookmark active"
                  src={IconBookmark}
                  alt="Bookmark"
                />
              )}
        </div>
      </div>
      <div
        className="list-item-detail cursor-pointer"
        onClick={() => openFormView(form.id, form.dispatchedFormId)}
      >
        <h6
          className={`truncate ${
            TEXT_DIRECTION() !== "rtl" && isRTL(form?.formDetail?.title)
              ? "direction-rtl"
              : TEXT_DIRECTION() === "rtl" && !isRTL(form?.formDetail?.title)
              ? "direction-ltr"
              : ""
          }`}
        >
          {form?.formDetail?.title ? (
            form?.formDetail?.title.includes("Untitled Form") ? (
              <>
                {form?.formDetail?.title.replace(
                  "Untitled Form",
                  i18n("global.UntitledForm")
                )}{" "}
              </>
            ) : (
              form?.formDetail?.title
            )
          ) : null}
        </h6>

        <p
          className={`truncate text-muted ${
            TEXT_DIRECTION() !== "rtl" && isRTL(form?.formDetail?.description)
              ? "direction-rtl"
              : TEXT_DIRECTION() === "rtl" &&
                !isRTL(form?.formDetail?.description)
              ? "direction-ltr"
              : ""
          }`}
        >
          {form?.formDetail?.description === "Untitled" ? (
            <I18n lng="global.Untitled" />
          ) : (
            form?.formDetail?.description
          )}
        </p>
        {/* <p className="truncate text-muted">{form?.formDetail?.description}</p> */}
        <small>{getFormLastModifiedDate(form.updatedAt)}</small>
      </div>
      <span className="list-item-action">
        <ListItemMenu
          loading={loading}
          setLoading={setLoading}
          openEditForm={openEditForm}
          setIsBookmarked={setIsBookmarked}
          isBookmarked={isBookmarked}
          openFormView={openFormView}
          openFormStart={openFormStart}
          createDuplicateForm={createDuplicateForm}
          openFormDispatch={openFormDispatch}
          bookmarkUnbookmarkForm={bookmarkUnbookmarkForm}
          archiveForm={archiveForm}
          openArchvieFormDialog={openArchvieFormDialog}
          form={form}
          id={currentRole === "user" ? form.id : form.id}
          dispatchFormId={
            currentRole === "user" ? form.dispatchedFormId : undefined
          }
        />
      </span>
      <AlertDialog
        showDlg={showAlertDialog}
        classes="modal-alert-dialog mt-5"
        iconCloseDlg={true}
        size="md"
        content={
          currentRole === "user" ? (
            inspectorFormNotification?.event === "assigned" ? (
              <div className="text-center">
                <h3 className="mt-2">
                  <I18n lng="Notifications.FormAssignment" />!
                </h3>
                <p className="mt2">
                  <I18n lng="Notifications.NewFormTitle" />{" "}
                  <b>{form?.formDetail?.title}</b>{" "}
                  <I18n lng="Notifications.HasBeenAssigned" />
                  {"  "}
                  <b> {inspectorFormNotification?.sender?.fullName}</b>{" "}
                  <I18n lng="Notifications.On" />{" "}
                  {moment(inspectorFormNotification?.form?.updatedAt).format(
                    "DD/MM/YYYY hh:mm A"
                  )}{" "}
                  <I18n lng="global.Ago" />{" "}
                </p>
              </div>
            ) : inspectorFormNotification?.event === "formUpdate" ? (
              <div className="text-center">
                <h3 className="mt-2">
                  <I18n lng="Notifications.FormUpdated" />
                </h3>
                <span>
                  <I18n lng="Notifications.YourFormTitle" />{" "}
                  <b>"{managerFormNotification?.form?.formDetail?.title}"</b>{" "}
                  <I18n lng="Notifications.HasBeen" />{" "}
                  <b>
                    "<I18n lng="Notifications.Updated" />"
                  </b>{" "}
                  <I18n lng="Notifications.By" />{" "}
                  <b>"{managerFormNotification?.sender?.fullName}"</b>{" "}
                  <I18n lng="Notifications.On" />{" "}
                  {moment(managerFormNotification?.form?.updatedAt).format(
                    "DD/MM/YYYY hh:mm A"
                  )}{" "}
                  <I18n lng="global.Ago" />{" "}
                </span>
              </div>
            ) : (
              ""
            )
          ) : (
            <div className="text-center">
              <h3 className="mt-2">
                <I18n lng="Notifications.FormUpdated" />
              </h3>
              <span>
                <I18n lng="Notifications.YourFormTitle" />{" "}
                <b>"{managerFormNotification?.form?.formDetail?.title}"</b>{" "}
                <I18n lng="Notifications.HasBeen" />{" "}
                <b>
                  "<I18n lng="Notifications.Updated" />"
                </b>{" "}
                <I18n lng="Notifications.By" />{" "}
                <b>"{managerFormNotification?.sender?.fullName}"</b>{" "}
                <I18n lng="Notifications.On" />{" "}
                {moment(managerFormNotification?.form?.updatedAt).format(
                  "DD/MM/YYYY hh:mm A"
                )}{" "}
                <I18n lng="global.Ago" />{" "}
              </span>
            </div>
          )
        }
        primaryActionTitle={<I18n lng="global.Close" />}
        secondaryAction={() => {
          if (currentRole === "user") {
            if (inspectorFormNotification?.isRead) {
              setShowAlertDialog(false);
            } else {
              notificationReadSingle(inspectorFormNotification.id, {
                isRead: true,
              }).then(() => {
                getAllNotifications().then(() => {
                  setShowAlertDialog(false);
                });
              });
            }
          } else if (currentRole !== "user") {
            if (managerFormNotification?.isRead) {
              setShowAlertDialog(false);
            } else {
              notificationReadSingle(managerFormNotification.id, {
                isRead: true,
              }).then(() => {
                getAllNotifications().then(() => {
                  setShowAlertDialog(false);
                });
              });
            }
          }
        }}
      />
    </li>
  );
};

const mapStateToProps = ({ authentication, notifications }) => ({
  user: authentication.user,
  currentRole: authentication.currentRole,
  allNotifications: notifications.allNotifications,
});

const mapDispatchToProps = {
  getAllNotifications,
  getBookmark,
  formManagmentIsRead,
  notificationReadSingle,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListItem);
