import React, { useState, useMemo } from "react";
import I18n from "../../../../languageSelector";
import { useSiteUIContext } from "../site-ui-context";
import IconEye from "../../../../../../assets/images/icon-eye.svg";
import IconMenu from "../../../../../../assets/images/icon-menu.svg";
import IconDelete from "../../../../../../assets/images/icon-delete.svg";
import IconPencil from "../../../../../../assets/images/icon-pencil.svg";

import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

const ListItemMenu = ({ sites }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const siteUIContext = useSiteUIContext();
  const sitesUIProps = useMemo(() => {
    return {
      openSiteViewDialog: siteUIContext.openSiteViewDialog,
      openSiteEditDialog: siteUIContext.openSiteEditDialog,
      openSiteDeleteDialog: siteUIContext.openSiteDeleteDialog,
    };
  }, [siteUIContext]);
  return (
    <>
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle>
          <img src={IconMenu} alt="Menu" />
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem
            className="action action-view"
            onClick={(e) => {
              e.preventDefault();
              sitesUIProps.openSiteViewDialog(sites.id);
            }}
          >
            <span className="icon-wrap">
              <img src={IconEye} alt="IconEye" />
            </span>
            <I18n lng="global.View" />
          </DropdownItem>
          <DropdownItem
            className="action action-edit"
            onClick={(e) => {
              e.preventDefault();
              sitesUIProps.openSiteEditDialog(sites.id);
            }}
          >
            <span className="icon-wrap">
              <img src={IconPencil} alt="IconPencil" />
            </span>
            <I18n lng="global.Edit" />
          </DropdownItem>
          <DropdownItem
            className="action action-delete"
            onClick={(e) => {
              e.preventDefault();
              sitesUIProps.openSiteDeleteDialog(sites.id);
            }}
          >
            <span className="icon-wrap">
              <img src={IconDelete} alt="IconArchive" />
            </span>
            <I18n lng="global.Delete" />
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default ListItemMenu;
