import React, { useState } from "react";
import SectionPdfPreview from "./sectionPdfPreview";
import IconArrowDown from "../../../../assets/images/arrowDown.svg";
import { Collapse } from "reactstrap";
import { i18n } from "../../languageSelector";

const PagePdfPreview = ({
  page,
  index,
  getAnswer,
  setFailItems,
  failItems,
  failStatus,
  viewPageSections,
  instructionHide,
  hideAndShow,
  answers,
  showDocumentMedia,
  imageCountData,
}) => {
  const [showPage, setShowPage] = useState(false);
  const [reportPageCollapsed, setReportPageCollapsed] = useState(true);
  return (
    <>
      {failStatus && !viewPageSections ? null : hideAndShow?.content?.all ? (
        <div className="d-flex">
          <div
            className="report-page-title mb-0"
            style={{ marginTop: "15px", padding: ".75rem 1.35rem" }}
            id={page?.pageUuid}
          >
            <b className="m-0 p-0">
              {page.name === "Page"
                ? `${i18n("Form.Page")} ${index + 1}`
                : page.name}
            </b>
            <div
              onClick={() => {
                reportPageCollapsed && reportPageCollapsed === true
                  ? setReportPageCollapsed(false)
                  : setReportPageCollapsed(true);
              }}
              className="text-right align-end "
            >
              <img
                style={
                  reportPageCollapsed === true
                    ? {
                        margin: "0 15px 5px 15px",
                        width: "25px",
                        cursor: "pointer",
                      }
                    : {
                        margin: "0 15px 5px 15px",
                        width: "25px",
                        cursor: "pointer",
                        rotate: "180deg",
                      }
                }
                src={IconArrowDown}
                alt="arrow Down"
              />
            </div>
          </div>
        </div>
      ) : hideAndShow?.content?.unAnswered && showPage ? (
        <div
          className="report-page-title mb-0"
          style={{ marginTop: "15px", padding: ".75rem 1.35rem" }}
          id={page?.pageUuid}
        >
          <b className="m-0 p-0">
            {page.name === "Page"
              ? `${i18n("Form.Page")} ${index + 1}`
              : page.name}
          </b>
          <div
            onClick={() => {
              reportPageCollapsed && reportPageCollapsed === true
                ? setReportPageCollapsed(false)
                : setReportPageCollapsed(true);
            }}
            className="text-right align-end "
          >
            <img
              style={
                reportPageCollapsed === true
                  ? {
                      margin: "0 15px 5px 15px",
                      width: "25px",
                      cursor: "pointer",
                    }
                  : {
                      margin: "0 15px 5px 15px",
                      width: "25px",
                      cursor: "pointer",
                      rotate: "180deg",
                    }
              }
              src={IconArrowDown}
              alt="arrow Down"
            />
          </div>
        </div>
      ) : hideAndShow?.content?.answered && showPage ? (
        <div
          className="report-page-title mb-0"
          style={{ marginTop: "15px", padding: ".75rem 1.35rem" }}
          id={page?.pageUuid}
        >
          <b className="m-0 p-0">
            {page.name === "Page"
              ? `${i18n("Form.Page")} ${index + 1}`
              : page.name}
          </b>
          <div
            onClick={() => {
              reportPageCollapsed && reportPageCollapsed === true
                ? setReportPageCollapsed(false)
                : setReportPageCollapsed(true);
            }}
            className="text-right align-end "
          >
            <img
              style={
                reportPageCollapsed === true
                  ? {
                      margin: "0 15px 5px 15px",
                      width: "25px",
                      cursor: "pointer",
                    }
                  : {
                      margin: "0 15px 5px 15px",
                      width: "25px",
                      cursor: "pointer",
                      rotate: "180deg",
                    }
              }
              src={IconArrowDown}
              alt="arrow Down"
            />
          </div>
        </div>
      ) : null}

      {page.sections && page.sections.length
        ? page.sections.map((section, secIndex) => {
            return (
              <Collapse isOpen={reportPageCollapsed}>
                <SectionPdfPreview
                  key={index}
                  index={secIndex}
                  section={section}
                  getAnswer={getAnswer}
                  setFailItems={setFailItems}
                  failItems={failItems}
                  failStatus={failStatus}
                  viewPageSections={viewPageSections}
                  instructionHide={instructionHide}
                  answers={answers}
                  hideAndShow={hideAndShow}
                  setShowPage={setShowPage}
                  showDocumentMedia={showDocumentMedia}
                  imageCountData={imageCountData}
                />
              </Collapse>
            );
          })
        : null}
    </>
  );
};
export default PagePdfPreview;
