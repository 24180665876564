import React, { useMemo } from "react";
import { BiImage } from "react-icons/bi";
import ListItemMenu from "./list-item-menu";
import I18n, { i18n } from "../../../../languageSelector";
import { useSiteUIContext } from "../site-ui-context";
import {
  isRTL,
  TEXT_DIRECTION,
} from "../../../../../../helper/helperFunctions";

const ListItem = ({ site }) => {
  const siteUIContext = useSiteUIContext();
  const sitesUIProps = useMemo(() => {
    return {
      openSiteViewDialog: siteUIContext.openSiteViewDialog,
    };
  }, [siteUIContext]);

  return (
    <li>
      <div className="list-item-avatar">
        <span className="avatar-wrap">
          {site.image ? (
            <img src={site.image} alt="Avatar"></img>
          ) : (
            <BiImage size={"4rem"} />
          )}
        </span>
      </div>
      <div
        className="list-item-detail cursor-pointer"
        onClick={(e) => {
          e.preventDefault();
          sitesUIProps.openSiteViewDialog(site?.id);
        }}
      >
        <h6
          className={`truncate ${
            TEXT_DIRECTION() !== "rtl" && isRTL(site.name)
              ? "direction-rtl"
              : TEXT_DIRECTION() === "rtl" && !isRTL(site.name)
              ? "direction-ltr"
              : ""
          }`}
        >
          {site.name === "Default Site"
            ? `${i18n("Company.DefaultSite")}`
            : site.name}
        </h6>

        <p
          className={`truncate text-muted ${
            TEXT_DIRECTION() !== "rtl" && isRTL(`${site.city}${site.state}`)
              ? "direction-rtl"
              : TEXT_DIRECTION() === "rtl" &&
                !isRTL(`${site.city}${site.state}`)
              ? "direction-ltr"
              : ""
          }`}
        >
          <span className={` ${TEXT_DIRECTION() === "rtl" ? "ml-1" : "mr-1"}`}>
            {site.city}{" "}
          </span>
          <span className={`${TEXT_DIRECTION() === "rtl" ? "ml-1" : "mr-1"}`}>
            {site.state}{" "}
          </span>
        </p>

        <small>{site.email}</small>
      </div>
      <span className="list-item-action">
        {site.active ? (
          <small className="status status-success">
            <I18n lng="global.Enable" />
          </small>
        ) : (
          <small className="status status-danger">
            <I18n lng="global.Disabled" />
          </small>
        )}

        <ListItemMenu sites={site} />
      </span>
    </li>
  );
};
export default ListItem;
