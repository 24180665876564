import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import FilterDialog from "../reportFilterDialog";
import I18n, { i18n } from "../../languageSelector";
import "react-datepicker/dist/react-datepicker.css";
import IconSearch from "../../../../assets/images/icon-search.svg";
import IconFilter from "../../../../assets/images/icon-filter.svg";
import DialogModal from "../../../../components/dialogs/compact-dialog";
import IconSortAlpha from "../../../../assets/images/icon-sort-alpha.svg";
import ReportsList from "../my-reports/my-reports-list-components/list-item";
import {
  FaSortAlphaUp,
  FaSortAlphaDown,
  FaSortNumericUpAlt,
  FaSortNumericDownAlt,
} from "react-icons/fa";
import { getOtherReports } from "../../../../redux/reducers/admin-manager-reports/admin-manager-report.reducer";
import { getBookmark } from "../../../../redux/reducers/form/form.reducer";

const OtherReportsView = ({
  getOtherReports,
  allReports,
  otherReportFilter,
  getBookmark,
  user,
  setOtherReportFilter,
  otherReportfilterInitState,
}) => {
  const [searchValue, setSearchValue] = useState("");

  const [sortBy, setSortBy] = useState({
    attribute: "DATE_MODIFIED",
    order: "DESC",
  });
  const [showSortModal, setShowSortModal] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bookmarkForm, setBookmarkForm] = useState();

  useEffect(() => {
    if (user) {
      getBookmark(user.id, "REPORT").then((res) => {
        setBookmarkForm(res?.value?.data);
      });
    }
    // eslint-disable-next-line
  }, [loading]);

  useEffect(() => {
    getOtherReports();
    // eslint-disable-next-line
  }, []);

  const searchSortFilter = (reportsList) => {
    const activeForms = reportsList?.filter(
      (item) => item.isArchived === false
    );
    if (reportsList?.length) {
      let submitForms = activeForms?.filter((item) => item.status === "submit");
      let acceptedOrRejected = activeForms?.filter(
        (item) => item.status !== "submit"
      );

      if (submitForms?.length) {
        if (searchValue) {
          submitForms = [
            ...submitForms.filter((item) =>
              item.formDetail.title
                .toLowerCase()
                .includes(searchValue.toLowerCase())
            ),
          ];
        }

        if (sortBy) {
          submitForms = [
            ...submitForms.sort(function (a, b) {
              if (sortBy.attribute === "FORM_NAME") {
                return (
                  (a.formDetail.title === b.formDetail.title
                    ? 0
                    : a.formDetail.title < b.formDetail.title
                    ? -1
                    : 1) * (sortBy.order === "ASC" ? 1 : -1)
                );
              } else if (sortBy.attribute === "DATE_CREATED") {
                return (
                  (a.createdAt.toString() === b.createdAt.toString()
                    ? 0
                    : a.createdAt.toString() < b.createdAt.toString()
                    ? -1
                    : 1) * (sortBy.order === "ASC" ? 1 : -1)
                );
              } else {
                return (
                  (a.updatedAt.toString() === b.updatedAt.toString()
                    ? 0
                    : a.updatedAt.toString() < b.updatedAt.toString()
                    ? -1
                    : 1) * (sortBy.order === "ASC" ? 1 : -1)
                );
              }
            }),
          ];
        }
      }

      if (acceptedOrRejected?.length) {
        if (searchValue) {
          acceptedOrRejected = [
            ...acceptedOrRejected.filter((item) =>
              item.formDetail.title
                .toLowerCase()
                .includes(searchValue.toLowerCase())
            ),
          ];
        }
        if (sortBy) {
          acceptedOrRejected = [
            ...acceptedOrRejected.sort(function (a, b) {
              if (sortBy.attribute === "FORM_NAME") {
                return (
                  (a.formDetail.title === b.formDetail.title
                    ? 0
                    : a.formDetail.title < b.formDetail.title
                    ? -1
                    : 1) * (sortBy.order === "ASC" ? 1 : -1)
                );
              } else if (sortBy.attribute === "DATE_CREATED") {
                return (
                  (a.createdAt.toString() === b.createdAt.toString()
                    ? 0
                    : a.createdAt.toString() < b.createdAt.toString()
                    ? -1
                    : 1) * (sortBy.order === "ASC" ? 1 : -1)
                );
              } else {
                return (
                  (a.updatedAt.toString() === b.updatedAt.toString()
                    ? 0
                    : a.updatedAt.toString() < b.updatedAt.toString()
                    ? -1
                    : 1) * (sortBy.order === "ASC" ? 1 : -1)
                );
              }
            }),
          ];
        }
      }

      return [...submitForms, ...acceptedOrRejected];
    } else {
      return [];
    }
  };

  const otherReportFilterHandler = () => {
    getOtherReports(otherReportFilter);
    setTimeout(() => {
      setShowFilterModal(false);
    }, 300);
  };

  const moduleId = bookmarkForm?.map((bookmark) => bookmark?.moduleId);
  const userId = bookmarkForm?.map((bookmark) => bookmark?.userId);

  return (
    <>
      <div className="px-3">
        <div className="search-bar">
          <form className="form-search">
            <div className="input-group">
              <div className="input-group-prepend">
                <img src={IconSearch} alt="Search Icon" />
              </div>
              <input
                className="form-control"
                placeholder={i18n("global.Search")}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
          </form>
          <Link to="#" onClick={() => setShowSortModal(true)}>
            <img src={IconSortAlpha} alt="IconSortAlpha" />
          </Link>
          <Link to="#" onClick={() => setShowFilterModal(true)}>
            <img src={IconFilter} alt="IconFilter" />
          </Link>
        </div>
        <ul className="form-list su-list">
          {allReports && allReports.length
            ? searchSortFilter(allReports).map((item, index) => {
                if (moduleId?.includes(item._id) && userId?.includes(user.id)) {
                  return (
                    <ReportsList
                      key={index}
                      loading={loading}
                      setLoading={setLoading}
                      site={item?.site}
                      form={item?.form}
                      isRead={item?.isRead}
                      status={item?.status}
                      otherReportsView={true}
                      inspector={item.assignee}
                      updatedAt={item?.updatedAt}
                      reportUUid={item?.reportId}
                      bookmarked={item.isBookmarked}
                      dispatchDate={item?.dispatchDate}
                      id={item.id ? item.id : item._id}
                      formTitle={item?.formDetail?.title}
                      reportShareSetting={item?.reportShareSetting}
                      reportItem={item}
                      formManagementId={item.id ? item.id : item._id}
                      reportPhoto={
                        item?.formDetail?.image ? item?.formDetail?.image : ""
                      }
                    />
                  );
                }
                return null;
              })
            : null}
          {allReports && allReports.length
            ? searchSortFilter(allReports).map((item, index) => {
                if (!moduleId?.includes(item._id)) {
                  return (
                    <ReportsList
                      key={index}
                      loading={loading}
                      setLoading={setLoading}
                      site={item?.site}
                      form={item?.form}
                      isRead={item?.isRead}
                      status={item?.status}
                      otherReportsView={true}
                      inspector={item.assignee}
                      updatedAt={item?.updatedAt}
                      reportUUid={item?.reportId}
                      bookmarked={item.isBookmarked}
                      dispatchDate={item?.dispatchDate}
                      id={item.id ? item.id : item._id}
                      formTitle={item?.formDetail?.title}
                      reportShareSetting={item?.reportShareSetting}
                      reportItem={item}
                      formManagementId={item.id ? item.id : item._id}
                      reportPhoto={
                        item?.formDetail?.image ? item?.formDetail?.image : ""
                      }
                    />
                  );
                }
                return null;
              })
            : null}
        </ul>
      </div>
      <DialogModal
        showDlg={showSortModal}
        classes="modal-sort"
        content={
          <ul className="list-unstyled">
            <li
              onClick={() => {
                if (sortBy.attribute === "FORM_NAME") {
                  if (sortBy.order === "ASC") {
                    setSortBy({ attribute: "FORM_NAME", order: "DESC" });
                  } else {
                    setSortBy({ attribute: "FORM_NAME", order: "ASC" });
                  }
                } else {
                  setSortBy({ attribute: "FORM_NAME", order: "ASC" });
                }
              }}
            >
              <span className="icon-wrap">
                {sortBy.attribute === "FORM_NAME" ? (
                  sortBy.order === "ASC" ? (
                    <FaSortAlphaDown />
                  ) : (
                    <FaSortAlphaUp />
                  )
                ) : null}
              </span>
              <strong>
                <I18n lng="global.ReportName" />
              </strong>
            </li>
            <li
              onClick={() => {
                if (sortBy.attribute === "DATE_CREATED") {
                  if (sortBy.order === "ASC") {
                    setSortBy({ attribute: "DATE_CREATED", order: "DESC" });
                  } else {
                    setSortBy({ attribute: "DATE_CREATED", order: "ASC" });
                  }
                } else {
                  setSortBy({ attribute: "DATE_CREATED", order: "ASC" });
                }
              }}
            >
              <span className="icon-wrap">
                {sortBy.attribute === "DATE_CREATED" ? (
                  sortBy.order === "ASC" ? (
                    <FaSortNumericDownAlt />
                  ) : (
                    <FaSortNumericUpAlt />
                  )
                ) : null}
              </span>
              <strong>
                <I18n lng="global.DateCreated" />
              </strong>
            </li>
            <li
              onClick={() => {
                if (sortBy.attribute === "DATE_MODIFIED") {
                  if (sortBy.order === "ASC") {
                    setSortBy({ attribute: "DATE_MODIFIED", order: "DESC" });
                  } else {
                    setSortBy({ attribute: "DATE_MODIFIED", order: "ASC" });
                  }
                } else {
                  setSortBy({ attribute: "DATE_MODIFIED", order: "ASC" });
                }
              }}
            >
              <span className="icon-wrap">
                {sortBy.attribute === "DATE_MODIFIED" ? (
                  sortBy.order === "ASC" ? (
                    <FaSortNumericDownAlt />
                  ) : (
                    <FaSortNumericUpAlt />
                  )
                ) : null}
              </span>
              <strong>
                <I18n lng="global.DateModified" />
              </strong>
            </li>
          </ul>
        }
        primaryActionTitle={i18n("global.Apply")}
        secondaryAction={() => setShowSortModal(false)}
        primaryAction={() => setShowSortModal(false)}
      />
      {showFilterModal && (
        <FilterDialog
          show={showFilterModal}
          clearActions={() => {
            setOtherReportFilter({ ...otherReportfilterInitState });
            getOtherReports();
          }}
          secondryActionTitle={i18n("global.Clear")}
          primaryAction={otherReportFilterHandler}
          secondaryAction={() => setShowFilterModal(false)}
          filterState={otherReportFilter}
          setFilterState={setOtherReportFilter}
          filterInitState={otherReportfilterInitState}
        />
      )}
    </>
  );
};
const mapStateToProps = ({ adminManagerReports, authentication }) => ({
  allReports: adminManagerReports.allReports,
  currentRole: authentication.currentRole,
  user: authentication.user,
});
const mapDispatchToProps = {
  getOtherReports,
  getBookmark,
};
export default connect(mapStateToProps, mapDispatchToProps)(OtherReportsView);
