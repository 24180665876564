import React from "react";
import I18n from "../languageSelector";
import { TEXT_DIRECTION } from "../../../helper/helperFunctions";
import IconArrowDown from "../../../assets/images/arrowDown.svg";
import { Collapse } from "reactstrap";

const ReportDisclaimer = ({
  reportForEdit,
  hideAndShow,
  reportDesclaimCollapsed,
  setReportDesclaimCollapsed,
}) => {
  return (
    <div>
      {" "}
      {reportForEdit && hideAndShow?.content?.showDisclaimer && (
        <div
          className="heading-top"
          style={{ borderRadius: "12px", marginTop: "13px" }}
        >
          <div className="report-table fail-summary">
            <div className="report-header">
              <div
                className="table mb-1"
                style={{
                  border: "#FFFFFF",
                  boxShadow: "1px 5px 2px rgba(0,0,0,.10)",
                }}
              >
                <div className="failed-summary">
                  <div className="table-header">
                    <div className="table-row">
                      <div
                        className="table-head"
                        style={{
                          padding: "0.25rem 1.5rem",
                          borderRadius: "0",
                        }}
                      >
                        <div className="my-1 d-flex justify-content-between w-100 ">
                          <b
                            className={
                              TEXT_DIRECTION() === "rtl" ? "ml-3" : "mr-3"
                            }
                          >
                            <I18n lng="Reports.Disclaimer" />
                          </b>
                          <div
                            onClick={() => {
                              reportDesclaimCollapsed &&
                              reportDesclaimCollapsed === true
                                ? setReportDesclaimCollapsed(false)
                                : setReportDesclaimCollapsed(true);
                            }}
                            className="text-right align-end "
                          >
                            <img
                              style={
                                reportDesclaimCollapsed === true
                                  ? {
                                      margin: "0 15px 5px 15px",
                                      width: "25px",
                                      cursor: "pointer",
                                    }
                                  : {
                                      margin: "0 15px 5px 15px",
                                      width: "25px",
                                      cursor: "pointer",
                                      rotate: "180deg",
                                    }
                              }
                              src={IconArrowDown}
                              alt="arrow Down"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="fail-summaryBody">
                  <Collapse isOpen={reportDesclaimCollapsed}>
                    <div className="report-data card">
                      <p
                        style={{ fontSize: "14px" }}
                        className="px-4 pt-2 pb-1"
                      >
                        {hideAndShow?.content?.disclaimer}
                      </p>
                    </div>
                  </Collapse>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReportDisclaimer;
