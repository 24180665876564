import React, { useEffect, useState } from "react";
import QuestionPreview from "./questionReportPreview";
import { i18n } from "../../languageSelector";

const SectionPreview = ({
  section,
  addUpdateAnwser,
  getAnswer,
  getUpdatedAnswer,
  getOldAnswer,
  reportLogs,
  reportHistory,
  index,
  showAndHide,
  setShowPage,
  imageCountData,
  setFailItems,
  failItems,
  failStatus,
}) => {
  const [showSection, setShowSection] = useState(false);
  const [totalAnswered, setTotalAnswered] = useState(0);

  useEffect(() => {
    if (showAndHide?.content?.all) {
      setShowPage(true);
    } else if (showAndHide?.content?.answered && showSection) {
      setShowPage(true);
    } else if (
      showAndHide?.content?.unAnswered &&
      section?.questions?.length !== totalAnswered
    ) {
      setShowPage(true);
    } else {
      setShowPage(false);
    }
    // eslint-disable-next-line
  }, [showSection]);

  return (
    <>
      {showAndHide?.content?.all ? (
        <h6 className="section-title secondary" key={index}>
          <label>
            {section.name === "Section"
              ? `${i18n("Form.Section")} ${index + 1}`
              : section.name}
          </label>
          <strong></strong>
        </h6>
      ) : showAndHide?.content?.unAnswered &&
        section?.questions?.length !== totalAnswered ? (
        <h6 className="section-title secondary" key={index}>
          <label>
            {section.name === "Section"
              ? `${i18n("Form.Section")} ${index + 1}`
              : section.name}
          </label>
          <strong></strong>
        </h6>
      ) : showAndHide?.content?.answered && showSection ? (
        <h6 className="section-title secondary" key={index}>
          <label>
            {section.name === "Section"
              ? `${i18n("Form.Section")} ${index + 1}`
              : section.name}
          </label>
          <strong></strong>
        </h6>
      ) : null}

      {section && section.questions.length
        ? section.questions.map((item, queIndex) => (
            <QuestionPreview
              question={item}
              addUpdateAnwser={addUpdateAnwser}
              getAnswer={getAnswer}
              getOldAnswer={getOldAnswer}
              getUpdatedAnswer={getUpdatedAnswer}
              reportLogs={reportLogs}
              reportHistory={reportHistory}
              index={queIndex}
              showAndHide={showAndHide}
              setShowSection={setShowSection}
              setTotalAnswered={setTotalAnswered}
              imageCountData={imageCountData}
              setFailItems={setFailItems}
              failItems={failItems}
              failStatus={failStatus}
            />
          ))
        : null}
    </>
  );
};
export default SectionPreview;
