/** @format */

import moment from "moment";
import Logo from "../../../../../assets/images/TamamBlueLogo.svg";

const SubscriptionInvoice = ({ rxPrintRef, invoiceData, user, unitPrice, language }) => {
  let seats =
    invoiceData?.subscriptionData?.currentPlan?.plan === "Additional seats"
      ? invoiceData?.subscriptionData?.lastAdditionalseats?.seats
      : invoiceData?.subscriptionData?.currentPlan?.seats;
  let totalAmountBeforeTax = unitPrice * seats;
  let seatsTax = totalAmountBeforeTax * 0.15;
  let amountToCharge = seatsTax + totalAmountBeforeTax;

  return (
    <>
      {language === "ENGLISH" ? (
        <div
          ref={rxPrintRef}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div id="divToPrint" style={{ width: "895px", padding: "24px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  alignItems: "flex-start",
                  width: "18rem",
                }}
              >
                <img src={Logo} alt="" />
                <p className="m-0 p-0" style={{ fontSize: "14px", fontWeight: "400" }}>
                  Numu It Est. - TAMAM APP
                </p>
                <p className="m-0 p-0" style={{ fontSize: "14px", fontWeight: "400" }}>
                  Dammam - Saudi Arabia
                </p>
                <p className="m-0 p-0" style={{ fontSize: "14px", fontWeight: "400" }}>
                  CR .2050139742
                </p>
                <p className="m-0 p-0" style={{ fontSize: "14px", fontWeight: "400" }}>
                  <span>Tax No. </span>
                  {/* {user?.company?.taxNumber && <span>{user?.company?.taxNumber}</span>} */}
                </p>

                <div style={{ width: "90%", marginTop: "1rem" }}>
                  <hr className="m-0 p-0" style={{ width: "100%", border: "1px solid #E0E0E0" }} />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  alignItems: "flex-start",
                  width: "18rem",
                }}
              >
                <p
                  className="m-0 p-0"
                  style={{
                    fontWeight: "600",
                    fontSize: "30px",
                  }}
                >
                  Invoice
                </p>
                <p
                  className="m-0 p-0"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <span style={{ fontSize: "14px", fontWeight: "400" }}>Invoice number:</span>
                  <span style={{ fontSize: "14px", fontWeight: "400" }}>A1- {invoiceData?.invoiceDummyId}</span>
                </p>
                <p
                  className="m-0 p-0"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <span style={{ fontSize: "14px", fontWeight: "400" }}>Date:</span>
                  <span style={{ fontSize: "14px", fontWeight: "400" }}>{moment(invoiceData?.createdAt).format("DD MMMM YYYY")}</span>
                </p>
                <p
                  className="m-0 p-0"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <span style={{ fontSize: "14px", fontWeight: "400" }}>PAID</span>
                </p>
                <div style={{ width: "90%", marginTop: "2.2rem" }}>
                  <hr className="m-0 p-0" style={{ width: "100%", border: "1px solid #E0E0E0" }} />
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                marginTop: "1.5rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  alignItems: "flex-start",
                  width: "18rem",
                }}
              >
                <p
                  className="m-0 p-0"
                  style={{
                    fontSize: "1.25rem",
                    fontWeight: "bold",
                  }}
                >
                  Billed To
                </p>
                <p className="m-0 p-0" style={{ fontSize: "14px", fontWeight: "400" }}>
                  {user?.company?.companyName}
                </p>
                <p className="m-0 p-0" style={{ fontSize: "14px", fontWeight: "400" }}>
                  Tax no. {user?.company?.taxNumber}
                </p>
                <p className="m-0 p-0" style={{ fontSize: "14px", fontWeight: "400" }}>
                  {user?.company?.country}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  alignItems: "flex-start",
                  width: "18rem",
                }}
              >
                <p
                  className="m-0 p-0"
                  style={{
                    fontSize: "1.25rem",
                    fontWeight: "bold",
                  }}
                >
                  Subscription
                </p>
                <p
                  className="m-0 p-0"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <span style={{ fontSize: "14px", fontWeight: "400" }}>Subscription ID:</span>
                  <span style={{ fontSize: "14px", fontWeight: "400" }}>{invoiceData?.subscriptionData?.subscriptionId}</span>
                </p>
                <p
                  className="m-0 p-0"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <span style={{ fontSize: "14px", fontWeight: "400" }}>Billing Period</span>
                  <span style={{ fontSize: "14px", fontWeight: "400" }}>
                    {moment(invoiceData?.subscriptionData?.subscriptionStartedAt).format("DD MMMM YYYY")} -{" "}
                    {moment(invoiceData?.subscriptionData?.subscriptionEndedAt).format("DD MMMM YYYY")}
                  </span>
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                marginTop: "2.5rem",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  alignItems: "flex-start",
                  width: "100%",
                }}
              >
                <span
                  className="m-0 p-0"
                  style={{
                    fontSize: "1.25rem",
                    fontWeight: "bold",
                  }}
                >
                  Summary
                </span>
                <hr className="m-0 p-0" style={{ width: "100%", border: "1px solid #E0E0E0" }} />
                <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
                  <span className="m-0 p-0" style={{ flex: "1", fontSize: "14px" }}>
                    Description
                  </span>
                  <span className="m-0 p-0" style={{ flex: "1", textAlign: "center", fontSize: "14px" }}>
                    Unit Price
                  </span>
                  <span className="m-0 p-0" style={{ flex: "1", textAlign: "center" }}>
                    Units
                  </span>
                  <span className="m-0 p-0" style={{ flex: "1", textAlign: "center", fontSize: "14px" }}>
                    Total before tax
                  </span>
                  <span className="m-0 p-0" style={{ flex: "1", textAlign: "center", fontSize: "14px" }}>
                    Vat. 15%
                  </span>
                  <span className="m-0 p-0" style={{ flex: "1", textAlign: "center", fontWeight: "bold" }}>
                    Amount
                  </span>
                </div>
                <hr className="m-0 p-0" style={{ width: "100%", border: "1px solid #E0E0E0" }} />
                <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
                  <span className="m-0 p-0" style={{ flex: "1", fontSize: "14px" }}>
                    {invoiceData?.subscriptionData?.currentPlan?.plan}
                  </span>
                  <span className="m-0 p-0" style={{ flex: "1", textAlign: "center", fontSize: "14px" }}>
                    {unitPrice?.toFixed(2)}
                  </span>
                  <span className="m-0 p-0" style={{ flex: "1", textAlign: "center", fontSize: "14px" }}>
                    {invoiceData?.subscriptionData?.currentPlan?.plan === "Additional seats"
                      ? invoiceData?.subscriptionData?.lastAdditionalseats?.seats
                      : invoiceData?.subscriptionData?.currentPlan?.seats}
                  </span>
                  <span className="m-0 p-0" style={{ flex: "1", textAlign: "center", fontSize: "14px" }}>
                    {totalAmountBeforeTax?.toFixed(2)}
                  </span>
                  <span className="m-0 p-0" style={{ flex: "1", textAlign: "center", fontSize: "14px" }}>
                    {seatsTax?.toFixed(2)}
                  </span>
                  <span className="m-0 p-0" style={{ flex: "1", textAlign: "center", fontWeight: "bold" }}>
                    {amountToCharge?.toFixed(2)}
                  </span>
                </div>
                <hr className="m-0 p-0" style={{ width: "100%", border: "1px solid #E0E0E0" }} />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                marginTop: "2rem",
                gap: "1rem",
              }}
            >
              <p
                className="m-0 p-0"
                style={{
                  width: "18rem",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <span>Total before tax</span>
                <span>SAR {totalAmountBeforeTax?.toFixed(2)}</span>
              </p>
              <p
                className="m-0 p-0"
                style={{
                  width: "18rem",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <span>Vat. 15%</span>
                <span>SAR {seatsTax?.toFixed(2)}</span>
              </p>
              <hr className="m-0 p-0" style={{ width: "18rem", border: "1px solid #E0E0E0", marginLeft: "400px" }} />
              <p
                className="m-0 p-0"
                style={{
                  width: "18rem",
                  display: "flex",
                  justifyContent: "space-between",
                  fontWeight: "bold",
                }}
              >
                <span>Total</span>
                <span>SAR {amountToCharge?.toFixed(2)}</span>
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "2.75rem",
              }}
            >
              <img src="" alt="QR Code will be here" />
            </div>
          </div>
        </div>
      ) : (
        <div
          ref={rxPrintRef}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            direction: "rtl",
          }}
        >
          <div id="divToPrint" style={{ width: "895px", padding: "24px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  alignItems: "flex-start",
                  width: "18rem",
                }}
              >
                <img src={Logo} alt="" />
                <p className="m-0 p-0" style={{ fontSize: "14px", fontWeight: "400" }}>
                  مؤسسة نمو لتقنية المعلومات - تطبيق تمام
                </p>
                <p className="m-0 p-0" style={{ fontSize: "14px", fontWeight: "400" }}>
                  الدمام - المملكة العربية السعودية
                </p>
                <p className="m-0 p-0" style={{ fontSize: "14px", fontWeight: "400" }}>
                  سجل تجاري 2050139742
                </p>
                <p className="m-0 p-0" style={{ fontSize: "14px", fontWeight: "400" }}>
                  <span>Tax no.</span>
                  {user?.company?.taxNumber && <span>{user?.company?.taxNumber}</span>}
                </p>
                <div style={{ width: "90%", marginTop: "1rem" }}>
                  <hr className="m-0 p-0" style={{ width: "100%", border: "1px solid #E0E0E0" }} />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  alignItems: "flex-start",
                  width: "18rem",
                }}
              >
                <p
                  className="m-0 p-0"
                  style={{
                    fontWeight: "600",
                    fontSize: "30px",
                    textAlign: "right",
                  }}
                >
                  الفاتورة
                </p>
                <p
                  className="m-0 p-0"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    textAlign: "right",
                  }}
                >
                  <span style={{ fontSize: "14px", fontWeight: "400" }}>رقم الفاتورة:</span>
                  <span style={{ fontSize: "14px", fontWeight: "400" }}>{invoiceData?.invoiceDummyId} - A1</span>
                </p>
                <p
                  className="m-0 p-0"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    textAlign: "right",
                  }}
                >
                  <span style={{ fontSize: "14px", fontWeight: "400" }}>التاريخ:</span>
                  <span style={{ fontSize: "14px", fontWeight: "400" }}>{moment(invoiceData?.createdAt).format("DD MMMM YYYY")}</span>
                </p>
                <p
                  className="m-0 p-0"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    textAlign: "right",
                  }}
                >
                  <span style={{ fontSize: "14px", fontWeight: "400" }}>مدفوع</span>
                </p>
                <div style={{ width: "90%", marginTop: "2.2rem" }}>
                  <hr className="m-0 p-0" style={{ width: "100%", border: "1px solid #E0E0E0" }} />
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                marginTop: "1.5rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  alignItems: "flex-start",
                  width: "18rem",
                }}
              >
                <p
                  className="m-0 p-0"
                  style={{
                    fontSize: "1.25rem",
                    fontWeight: "bold",
                    textAlign: "right",
                  }}
                >
                  الفاتورة إلى
                </p>
                <p className="m-0 p-0" style={{ fontSize: "14px", fontWeight: "400", textAlign: "right" }}>
                  {user?.company?.companyName}
                </p>
                <p className="m-0 p-0" style={{ fontSize: "14px", fontWeight: "400", textAlign: "right" }}>
                  Tax no - {invoiceData?.subscriptionData?.company?.taxNo}
                </p>
                <p className="m-0 p-0" style={{ fontSize: "14px", fontWeight: "400", textAlign: "right" }}>
                  {user?.company?.country}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  alignItems: "flex-start",
                  width: "18rem",
                }}
              >
                <p
                  className="m-0 p-0"
                  style={{
                    fontSize: "1.25rem",
                    fontWeight: "bold",
                    textAlign: "right",
                  }}
                >
                  الاشتراك
                </p>
                <p
                  className="m-0 p-0"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    textAlign: "right",
                  }}
                >
                  <span style={{ fontSize: "14px", fontWeight: "400" }}>معرف الإشتراك:</span>
                  <span style={{ fontSize: "14px", fontWeight: "400" }}>{invoiceData?.subscriptionData?.subscriptionId}</span>
                </p>
                <p
                  className="m-0 p-0"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    textAlign: "right",
                  }}
                >
                  <span style={{ fontSize: "14px", fontWeight: "400" }}>فترة الفاتورة</span>
                  <span style={{ fontSize: "14px", fontWeight: "400" }}>
                    {moment(invoiceData?.subscriptionData?.subscriptionStartedAt).format("DD MMMM YYYY")} -{" "}
                    {moment(invoiceData?.subscriptionData?.subscriptionEndedAt).format("DD MMMM YYYY")}
                  </span>
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                marginTop: "2.5rem",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  alignItems: "flex-start",
                  width: "100%",
                }}
              >
                <p
                  className="m-0 p-0"
                  style={{
                    fontSize: "1.25rem",
                    fontWeight: "bold",
                    textAlign: "right",
                  }}
                >
                  الملخص
                </p>
                <hr className="m-0 p-0" style={{ width: "100%", border: "1px solid #E0E0E0" }} />
                <div style={{ display: "flex", width: "100%", alignItems: "center", flexDirection: "row-reverse" }}>
                  <p className="m-0 p-0" style={{ flex: "1", textAlign: "center", fontWeight: "bold" }}>
                    المبلغ
                  </p>

                  <p className="m-0 p-0" style={{ flex: "2", textAlign: "center", fontSize: "14px" }}>
                    ضريبة القيمة المضافة 15٪
                  </p>

                  <p className="m-0 p-0" style={{ flex: "2", textAlign: "center", fontSize: "14px" }}>
                    المجموع قبل الضريبة
                  </p>

                  <p className="m-0 p-0" style={{ flex: "1", textAlign: "center", fontSize: "14px" }}>
                    الوحدات
                  </p>

                  <p className="m-0 p-0" style={{ flex: "1", textAlign: "center", fontSize: "14px" }}>
                    السعر الوحدة
                  </p>

                  <p className="m-0 p-0" style={{ flex: "2", fontSize: "14px", textAlign: "right" }}>
                    الوصف
                  </p>
                </div>
                <hr className="m-0 p-0" style={{ width: "100%", border: "1px solid #E0E0E0" }} />
                <div style={{ display: "flex", width: "100%", alignItems: "center", flexDirection: "row-reverse" }}>
                  <p className="m-0 p-0" style={{ flex: "1", textAlign: "center", fontWeight: "bold" }}>
                    {amountToCharge?.toFixed(2)}
                  </p>
                  <p className="m-0 p-0" style={{ flex: "1", textAlign: "center", fontSize: "14px" }}>
                    {seatsTax?.toFixed(2)}
                  </p>
                  <p className="m-0 p-0" style={{ flex: "1", textAlign: "center", fontSize: "14px" }}>
                    {totalAmountBeforeTax?.toFixed(2)}
                  </p>
                  <p className="m-0 p-0" style={{ flex: "1", textAlign: "center", fontSize: "14px" }}>
                    {invoiceData?.subscriptionData?.lastAdditionalseats?.seats
                      ? invoiceData?.subscriptionData?.lastAdditionalseats?.seats
                      : invoiceData?.subscriptionData?.currentPlan?.seats}
                  </p>
                  <p className="m-0 p-0" style={{ flex: "1", textAlign: "center", fontSize: "14px" }}>
                    {unitPrice?.toFixed(2)}
                  </p>
                  <p className="m-0 p-0" style={{ flex: "1", fontSize: "14px", textAlign: "right" }}>
                    {invoiceData?.subscriptionData?.currentPlan?.plan}
                  </p>
                </div>
                <hr className="m-0 p-0" style={{ width: "100%", border: "1px solid #E0E0E0" }} />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                marginTop: "2rem",
                gap: "1rem",
              }}
            >
              <p
                className="m-0 p-0"
                style={{
                  width: "18rem",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <span>المجموع قبل الضريبة</span>
                <span>{totalAmountBeforeTax?.toFixed(2)} ر.س</span>
              </p>
              <p
                className="m-0 p-0"
                style={{
                  width: "18rem",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <span>ضريبة القيمة المضافة 15٪</span>
                <span>{seatsTax?.toFixed(2)} ر.س</span>
              </p>
              <hr style={{ width: "18rem", border: "1px solid #E0E0E0", marginRight: "400px" }} />
              <p
                className="m-0 p-0"
                style={{
                  width: "18rem",
                  display: "flex",
                  justifyContent: "space-between",
                  fontWeight: "bold",
                }}
              >
                <span>المجموع</span>
                <span>{amountToCharge?.toFixed(2)} ر.س</span>
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "2.75rem",
              }}
            >
              <img src="" alt="سيتم وضع رمز الاستجابة السريعة هنا" />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SubscriptionInvoice;
