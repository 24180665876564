import axios from "axios";
import { REQUEST, SUCCESS, FAILURE } from "../action-type.util";
export const ACTION_TYPES = {
  ALL_REPORTS: "All/Reports",
  GET_REPORT_SETTINGS: "GET_REPORT_SETTINGS",
  DELETE_REPORTS: "DELETE/Reports",
  GET_REPORT_FOR_EDIT: "GET/reportForEdit",
  UPDATE_REPORTS_ANSWER: "UPDATE/reportsAnswers",
  GET_REPORT_LOGS: "GET/reportLogs",
  GET_OTHER_REPORT: "GET/Other_report",
  SEND_PDF_REPORT: "SEND/pdf_reports",
  DELETE_REPORT_HISTORY: "DELETE/report-history",
  UPDATE_REPORT_SHARE_SETTING: "UPDATE/report-share-setting",
  GET_ALL_REPORTS: "GET_ALL_REPORTS",
  PATCH_REPORT_IS_READ: "PATCH_REPORT_IS_READ",
  PATCH_REPORT_SITE: "PATCH_REPORT_SITE",
  PATCH_REPORT_IS_BOOKMARKED_UNBOOKMARKED: "PATCH_REPORT_IS_BOOKMARKED_UNBOOKMARKED",
  GET_ALL_REPORT_SETTINGS: "GET_ALL_REPORT_SETTINGS",
};

const initialState = {
  loading: false,
  error: null,
  allReports: [],
  allReportSettings: [],
  reportForEdit: undefined,
  reportSettings: undefined,
  reportLogs: undefined,
};

export const ReportState = initialState;
// Reducer
// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.ALL_REPORTS):
    case REQUEST(ACTION_TYPES.GET_REPORT_SETTINGS):
    case REQUEST(ACTION_TYPES.DELETE_REPORTS):
    case REQUEST(ACTION_TYPES.DELETE_REPORT_HISTORY):
    case REQUEST(ACTION_TYPES.UPDATE_REPORT_SHARE_SETTING):
    case REQUEST(ACTION_TYPES.SEND_PDF_REPORT):
    case REQUEST(ACTION_TYPES.GET_ALL_REPORTS):
    case REQUEST(ACTION_TYPES.PATCH_REPORT_IS_READ):
    case REQUEST(ACTION_TYPES.PATCH_REPORT_SITE):
    case REQUEST(ACTION_TYPES.PATCH_REPORT_IS_BOOKMARKED_UNBOOKMARKED):
    case REQUEST(ACTION_TYPES.GET_ALL_REPORT_SETTINGS):
      return {
        ...state,
        loading: false,
      };
    case REQUEST(ACTION_TYPES.GET_REPORT_LOGS):
      return {
        ...state,
        loading: false,
        reportForEdit: undefined,
        reportLogs: undefined,
        error: action.payload,
      };
    case REQUEST(ACTION_TYPES.GET_OTHER_REPORT):
      return {
        ...state,
        loading: false,
        reportForEdit: undefined,
        reportLogs: undefined,
        allReports: [],
        error: action.payload,
      };
    case REQUEST(ACTION_TYPES.GET_REPORT_FOR_EDIT):
      return {
        ...state,
        loading: true,
        error: null,
        reportForEdit: undefined,
      };

    case REQUEST(ACTION_TYPES.UPDATE_REPORTS_ANSWER):
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FAILURE(ACTION_TYPES.ALL_REPORTS):
    case FAILURE(ACTION_TYPES.GET_REPORT_SETTINGS):
    case FAILURE(ACTION_TYPES.DELETE_REPORTS):
    case FAILURE(ACTION_TYPES.DELETE_REPORT_HISTORY):
    case FAILURE(ACTION_TYPES.GET_REPORT_LOGS):
      return {
        ...state,
        loading: false,
        reportForEdit: undefined,
        reportLogs: undefined,
        error: action.payload,
      };
    case FAILURE(ACTION_TYPES.SEND_PDF_REPORT):
    case FAILURE(ACTION_TYPES.UPDATE_REPORT_SHARE_SETTING):
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FAILURE(ACTION_TYPES.GET_REPORT_FOR_EDIT):
      return {
        ...state,
        loading: false,
        reportForEdit: undefined,
        error: action.payload,
      };
    case FAILURE(ACTION_TYPES.GET_OTHER_REPORT):
      return {
        ...state,
        loading: false,
        reportForEdit: undefined,
        allReports: [],
        error: action.payload,
      };
    case FAILURE(ACTION_TYPES.UPDATE_REPORTS_ANSWER):
    case FAILURE(ACTION_TYPES.GET_ALL_REPORTS):
    case FAILURE(ACTION_TYPES.PATCH_REPORT_IS_READ):
    case FAILURE(ACTION_TYPES.PATCH_REPORT_SITE):
    case FAILURE(ACTION_TYPES.PATCH_REPORT_IS_BOOKMARKED_UNBOOKMARKED):
    case FAILURE(ACTION_TYPES.GET_ALL_REPORT_SETTINGS):
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS(ACTION_TYPES.DELETE_REPORTS):
      return {
        ...state,
        loading: false,
        error: null,
        reportForEdit: undefined,
      };
    case SUCCESS(ACTION_TYPES.ALL_REPORTS):
      return {
        ...state,
        loading: false,
        error: null,
        // reportForEdit: undefined,
        allReports: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.GET_REPORT_FOR_EDIT):
      return {
        ...state,
        loading: false,
        error: null,
        reportForEdit: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.UPDATE_REPORTS_ANSWER):
      return {
        ...state,
        loading: false,
        error: null,
      };
    case SUCCESS(ACTION_TYPES.GET_REPORT_LOGS):
      return {
        ...state,
        loading: false,
        error: null,
        reportLogs: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.GET_OTHER_REPORT):
      return {
        ...state,
        loading: false,
        error: null,
        allReports: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.GET_REPORT_SETTINGS):
      return {
        ...state,
        loading: false,
        error: null,
        reportSettings: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_REPORT_HISTORY):
    case SUCCESS(ACTION_TYPES.SEND_PDF_REPORT):
    case SUCCESS(ACTION_TYPES.UPDATE_REPORT_SHARE_SETTING):
    case SUCCESS(ACTION_TYPES.PATCH_REPORT_IS_READ):
    case SUCCESS(ACTION_TYPES.PATCH_REPORT_SITE):
    case SUCCESS(ACTION_TYPES.PATCH_REPORT_IS_BOOKMARKED_UNBOOKMARKED):
    case SUCCESS(ACTION_TYPES.GET_ALL_REPORT_SETTINGS):
      return {
        ...state,
        loading: false,
        allReportSettings: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.GET_ALL_REPORTS):
      return {
        ...state,
        loading: false,
        allReports: action.payload.data,
      };

    default:
      return state;
  }
};

// Actions

export const getAdminManagerReports = (archived, filterState) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.ALL_REPORTS,
    payload: axios.get(
      `/reports?archived=${archived}${
        filterState
          ? `${filterState.fromDate ? `&fromDate=${filterState.fromDate}` : ""}${filterState.toDate ? `&toDate=${filterState.toDate}` : ""}${
              filterState.formName ? `&form=${filterState.formName.value}` : ""
            }${filterState.site ? `&site=${filterState.site.value}` : ""}${filterState.inspector ? `&inspector=${filterState.inspector.value}` : ""}${
              filterState.status ? `&status=${filterState.status.value}` : ""
            }`
          : ""
      }`
    ),
  });
};

export const deleteReport = (id) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.DELETE_REPORTS,
    payload: axios.delete(`/reports/${id}`),
  });
};

export const getReportById = (id) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.GET_REPORT_FOR_EDIT,
    payload: axios.get(`/reports/${id}`),
  });
};
export const getReportSettings = (reportId, userId) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.GET_REPORT_SETTINGS,
    payload: axios.get(`/reportSetting/${reportId}/${userId}`),
  });
};

export const updateReportStatus = (id, status) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.GET_REPORT_FOR_EDIT,
    payload: axios.patch(`/reports/${id}`, status),
  });
};

export const updateReportAnswersById = (id, formAnswersDetail) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.UPDATE_REPORTS_ANSWER,
    payload: axios.patch(`/reports/${id}`, formAnswersDetail),
  });
};

export const getReportsLogs = (id) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.GET_REPORT_LOGS,
    payload: axios.get(`/reports/reportLog/${id}`),
  });
};

export const getOtherReports = (filterState) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.GET_OTHER_REPORT,
    payload: axios.get(
      `/reports/others${
        filterState
          ? `?${filterState.fromDate ? `&fromDate=${filterState.fromDate}` : ""}${filterState.toDate ? `&toDate=${filterState.toDate}` : ""}${
              filterState.formName ? `&form=${filterState.formName.value}` : ""
            }${filterState.site ? `&site=${filterState.site.value}` : ""}${filterState.inspector ? `&inspector=${filterState.inspector.value}` : ""}${
              filterState.status ? `&status=${filterState.status.value}` : ""
            }`
          : ""
      }`
    ),
  });
};

export const sendPdfReport = (data) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.SEND_PDF_REPORT,
    payload: axios.post(`/reports/send`, data),
  });
};

export const getReportBySettingsId = (id) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.GET_REPORT_FOR_EDIT,
    payload: axios.get(`/reportSetting/${id}`),
  });
};

export const getReportByIdForShareData = (id, history) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.GET_REPORT_FOR_EDIT,
    payload: axios.get(`/reports/${id}/share${history !== "view" && history !== "admin" ? `/${history}` : "/admin"}`),
  });
};

export const deleteReportHistory = (reportId, historyId) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.GET_REPORT_FOR_EDIT,
    payload: axios.delete(`/reports/${reportId}/history/${historyId}`),
  });
};

export const updateReportShareSetting = (reportId, userId, reportShareSetting) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.UPDATE_REPORT_SHARE_SETTING,
    payload: axios.patch(`/reportSetting/${reportId}/${userId}`, reportShareSetting),
  });
};

export const getAllReportsWithOutStateChange = (archived) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.GET_ALL_REPORTS,
    payload: axios.get(`/reports?archived=${archived}`),
  });
};

export const updateReportIsRead = (id, isRead) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.PATCH_REPORT_IS_READ,
    payload: axios.patch(`/reports/${id}`, isRead),
  });
};

export const updateReportSite = (id, site) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.PATCH_REPORT_SITE,
    payload: axios.patch(`/reports/${id}`, site),
  });
};

export const updateReportBookmarkedUnBookMarked = (id, isBookmarked) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.PATCH_REPORT_IS_BOOKMARKED_UNBOOKMARKED,
    payload: axios.patch(`/reports/${id}`, isBookmarked),
  });
};
