import axios from "axios";
import { REQUEST, SUCCESS, FAILURE } from "../action-type.util";
export const ACTION_TYPES = {
  POST_FORM: "globalList/POST_GLOBAL_LIST",
  PATCH_GLOBAL_LIST: "globalList/PATCH_GLOBAL_LIST",
  DELETE_GLOBAL_LIST: "globalList/DELETE_GLOBAL_LIST",
  GET_GLOBAL_LIST: "globalList/GET_GLOBAL_LIST",
  GET_GLOBAL_LISTS: "globalList/GET_GLOBAL_LISTS",
  RESET: "globalList/RESET",
};

const initialState = {
  loading: false,
  globalLists: [],
  globalListToEdit: undefined,
  error: undefined,
};

export const GlobalListState = initialState;

// Reducer
// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.POST_GLOBAL_LIST):
    case REQUEST(ACTION_TYPES.PATCH_GLOBAL_LIST):
    case REQUEST(ACTION_TYPES.GET_GLOBAL_LIST):
    case REQUEST(ACTION_TYPES.GET_GLOBAL_LISTS):
    case REQUEST(ACTION_TYPES.DELETE_GLOBAL_LIST):
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.POST_GLOBAL_LIST):
    case FAILURE(ACTION_TYPES.PATCH_GLOBAL_LIST):
    case FAILURE(ACTION_TYPES.GET_GLOBAL_LIST):
    case FAILURE(ACTION_TYPES.GET_GLOBAL_LISTS):
    case FAILURE(ACTION_TYPES.DELETE_GLOBAL_LIST):
      return {
        ...state,
        error: undefined,
        loading: false,
      };
    case SUCCESS(ACTION_TYPES.POST_GLOBAL_LIST):
    case SUCCESS(ACTION_TYPES.PATCH_GLOBAL_LIST):
    case SUCCESS(ACTION_TYPES.DELETE_GLOBAL_LIST):
      return {
        ...state,
        loading: false,
      };
    case SUCCESS(ACTION_TYPES.GET_GLOBAL_LIST):
      return {
        ...state,
        loading: false,
        globalListToEdit: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.GET_GLOBAL_LISTS):
      return {
        ...initialState,
        globalLists: action.payload.data,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

// Actions

export const postGlobalList = (request) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.POST_GLOBAL_LIST,
    payload: axios.post(`/globalLists/`, { ...request }),
  });
};

export const getGlobalList = (id) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.GET_GLOBAL_LIST,
    payload: axios.get(`/globalLists/${id}`),
  });
};

export const patchGlobalList = (id, request) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.PATCH_GLOBAL_LIST,
    payload: axios.patch(`/globalLists/${id}`, { ...request }),
  });
};

export const deleteGlobalList = (id) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.DELETE_GLOBAL_LIST,
    payload: axios.delete(`/globalLists/${id}`),
  });
};

export const getGlobalLists = (filterState) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.GET_GLOBAL_LISTS,
    payload: axios.get(
      `/globalLists${
        filterState
          ? `?${
              filterState.fromDate ? `&fromDate=${filterState.fromDate}` : ""
            }${filterState.toDate ? `&toDate=${filterState.toDate}` : ""}`
          : ""
      }`,
    ),
  });
};
