import React from "react";
import QuestionPreivew from "./question-preview";
import { i18n } from "../../../languageSelector";

const SectionPreview = ({
  view,
  section,
  getAnswer,
  allQuestions,
  currentSection,
  addUpdateAnwser,
  removeFromAnswers,
}) => {
  let number = [];
  let questionNumber = 1;

  for (const subQuestion of allQuestions) {
    if (subQuestion.type !== "instructions") {
      number.push({
        question: subQuestion.questionUuid,
        questionNo: questionNumber,
      });
      questionNumber++;
    }
  }

  return (
    <>
      <div className="form-section-wrapper">
        <div className="form-section px-2">
          {section.name === "Section"
            ? `${i18n("Form.Section")} ${currentSection}`
            : section.name}
        </div>
        {section?.questions?.length ? (
          <>
            {section.questions.map((question, index) => (
              <div key={index}>
                <QuestionPreivew
                  number={number}
                  question={question}
                  key={index}
                  addUpdateAnwser={addUpdateAnwser}
                  removeFromAnswers={removeFromAnswers}
                  getAnswer={getAnswer}
                  view={view}
                />
              </div>
            ))}
          </>
        ) : null}
      </div>
    </>
  );
};
export default SectionPreview;
