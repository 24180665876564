import React, { useState, useEffect } from "react";
import { BsInfoCircleFill } from "react-icons/bs";
import ReactTooltip from "react-tooltip";
import SignatureCanvas from "react-signature-canvas";
import I18n, { i18n } from "../../../../../languageSelector";
import Attachment from "../snipets/attachment";
import { connect } from "react-redux";

const SignaturePreview = ({
  question,
  actionItem,
  view,
  answer = { name: "", signature: null, isEditable: true, page: "" },
  addUpdateAnwser = (answer) => {
    console.log(answer);
  },
  getAnswer = (uuid) => {
    console.log(uuid);
    return undefined;
  },
  currentRole,
}) => {
  const [questionPreview, setQuestionPreview] = useState(question);
  const [value, setValue] = useState(answer.name);
  const [questionValue, setQuestionValue] = useState("");
  const [notes, setNotes] = useState("");
  const [photo, setPhoto] = useState([]);
  const [signature, setSignature] = useState("");
  const [isQuestionLoading, setIsQuestionLoading] = useState(true);
  const [signatureValue, setSignatureValue] = useState([]);
  const [questionImageURL, setQuestionImageURL] = useState([]);

  let sigRef = React.useRef(null);

  useEffect(() => {
    setIsQuestionLoading(true);
  }, []);

  useEffect(() => {
    if (question) {
      setQuestionPreview(question);
      setValue("");
    }
  }, [question]);

  useEffect(() => {
    setValue(answer.name);
  }, [answer]);

  // eslint-disable-next-line
  useEffect(async () => {
    if (question?.questionUuid) {
      const answer = getAnswer(question?.questionUuid);

      if (answer?.questionUuid) {
        setQuestionValue(answer.signatureName);
        setSignatureValue(answer?.signature);
        setIsQuestionLoading(false);
        if (answer.signature && answer.signature.length > 0) {
          sigRef?.fromData(answer?.signature);
        } else {
          sigRef?.fromData(answer.signature);
        }

        setNotes(answer.note);
        setQuestionImageURL([...answer.image]);

        if (answer.image && answer.image.length) {
          setPhoto([...answer.image]);
        }
      } else {
        setIsQuestionLoading(false);
      }
    }
    // eslint-disable-next-line
  }, [question]);

  const imageDeleteHandler = (index) => {
    let removeImageArray = [...questionImageURL];
    removeImageArray.splice(index, 1);

    if (questionImageURL?.length) {
      let answerToSave = {
        questionUuid: question.questionUuid,
        signature: signatureValue.length ? [...signatureValue] : [],
        signatureName: questionValue,
        note: notes,
        image: [...removeImageArray],
      };
      if (!isQuestionLoading) {
        addUpdateAnwser(answerToSave);
      }
      photo.splice(index, 1);
      setPhoto([...photo]);
    } else {
      setPhoto([]);
    }
  };

  useEffect(() => {
    let answerToSave = {
      questionUuid: question.questionUuid,
      signature: signatureValue?.length ? signatureValue : sigRef?.toData(),
      signatureName: questionValue,
      note: notes,
      image: questionImageURL?.length ? [...questionImageURL] : [],
    };
    if (!isQuestionLoading) {
      addUpdateAnwser(answerToSave);
    }
    // eslint-disable-next-line
  }, [signature, notes, questionValue]);

  const imageAddHandler = () => {
    if (photo && photo.length && currentRole) {
      let answerToSave = {
        questionUuid: question.questionUuid,
        signature: signatureValue.length ? signatureValue : [],
        signatureName: questionValue,
        note: notes,
        image: [...photo],
      };

      setQuestionImageURL([...photo]);

      if (!isQuestionLoading) {
        addUpdateAnwser(answerToSave);
      }
    }
  };

  return (
    <div id={question?.questionUuid} className="multiple-choice">
      <div className="d-flex justify-content-between question-title h-auto">
        <strong>
          {questionPreview?.settings.questionText === "Type Instructions" ? (
            <I18n lng="Form.TypeInstructions" />
          ) : questionPreview?.settings.questionText ===
            "Add a new question" ? (
            <>
              {question?.questionNumber
                ? question?.questionNumber
                : actionItem?.questionNo === undefined
                ? actionItem
                : actionItem?.questionNo
                ? actionItem?.questionNo + 1
                : 1}
              . {"   "}
              <I18n lng="Form.AddANewQuestion" />{" "}
            </>
          ) : (
            `${
              question?.questionNumber
                ? question?.questionNumber
                : actionItem?.questionNo === undefined
                ? actionItem
                : actionItem?.questionNo
                ? actionItem?.questionNo + 1
                : 1
            }. ${questionPreview?.settings.questionText}`
          )}
          {question.settings.isMandatory && <span className="required"></span>}
        </strong>
        <div className="d-flex">
          {question.settings.description ? (
            <>
              <p
                data-for={`${question.settings.questionText}`}
                data-tip={`${question.settings.description}`}
                data-iscapture="true"
              >
                <BsInfoCircleFill color={"C4C4C4"} />
              </p>
              <ReactTooltip
                id={`${question.settings.questionText}`}
                place="top"
                type="dark"
                effect="float"
                multiline={true}
              />
            </>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="attachment-preview">
        {question?.settings?.photo ? (
          <img src={question.settings.photo} alt="Attachment Preview" />
        ) : (
          ""
        )}
      </div>
      <div className="row">
        <div className="col-12">
          <div className="form-group">
            {answer.page === "title" ? (
              <input
                className="form-control"
                placeholder={i18n("global.EnterName")}
                value={value}
                readOnly={answer.isEditable ? false : true}
                disabled={answer.isEditable ? false : true}
                onChange={(e) => {
                  setQuestionValue(e.target.value);
                }}
              />
            ) : (
              <div className="form-group">
                <input
                  className="form-control"
                  placeholder={i18n("global.EnterName")}
                  value={questionValue}
                  onChange={(e) => {
                    setQuestionValue(e.target.value);
                  }}
                  readOnly={view}
                  // disabled={view}
                />
              </div>
            )}
          </div>
        </div>
        <div className="col-12">
          <strong>
            <I18n lng="Form.SignHere" />{" "}
          </strong>
          <div className="signature-preview">
            {questionPreview?.questionUuid && (
              <SignatureCanvas
                ref={(ref) => {
                  sigRef = ref;
                }}
                onEnd={(value) => {
                  setSignature(value);
                }}
              />
            )}
          </div>
        </div>
        <div className="col-12">
          {
            <button
              className={`btn btn-primary align-items-center pt-2 pb-2 mt-2 w-100`}
              onClick={() => {
                sigRef.clear();
              }}
            >
              <I18n lng="global.ClearSignature" />
            </button>
          }
        </div>
      </div>
      {answer.page !== "title" ? (
        <Attachment
          canComment={signature !== "" ? question?.settings?.canComment : false}
          canAttachPhoto={
            signature !== "" ? question?.settings?.canAttachPhoto : false
          }
          notes={notes}
          setNotes={setNotes}
          photo={photo}
          setPhoto={setPhoto}
          imageDeleteHandler={imageDeleteHandler}
          imageAddHandler={imageAddHandler}
        />
      ) : null}
    </div>
  );
};
const mapStateToProps = ({ authentication }) => ({
  currentRole: authentication.currentRole,
});

export default connect(mapStateToProps, null)(SignaturePreview);
