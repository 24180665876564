import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import Attachment from "../snipets/attachment";
import { BsInfoCircleFill } from "react-icons/bs";
import I18n from "../../../../../languageSelector";
import { FileIcon, defaultStyles } from "react-file-icon";
import IconPDF from "../../../../../../../assets/images/icon-pdf.svg";
import { getFileData } from "../../../../../../../helper/image-helper";
import IconDelete from "../../../../../../../assets/images/icon-delete-red.svg";
import { uploadImage } from "../../../../../../../redux/reducers/form/form.reducer";

const PDFPreview = ({
  question,
  view,
  actionItem,
  addUpdateAnwser = (answer) => {
    console.log(answer);
  },
  getAnswer = (uuid) => {
    console.log(uuid);
    return undefined;
  },
  currentRole,
  uploadImage,
}) => {
  const [questionPreview, setQuestionPreview] = useState(question);
  const [notes, setNotes] = useState("");
  const [questionSetting, setQuestionSetting] = useState();
  const [photo, setPhoto] = useState([]);
  const [pdfPreview, setPdfPreview] = useState("");
  const [pdfFileUrl, setPdfFileUrl] = useState("");
  const [isQuestionLoading, setIsQuestionLoading] = useState(true);
  const [questionImageURL, setQuestionImageURL] = useState([]);
  const [answerSchema, setAnserSchema] = useState({});

  useEffect(() => {
    setIsQuestionLoading(true);
  }, []);

  const hiddenFileInput = React.useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  useEffect(() => {
    if (JSON.stringify(question) !== JSON.stringify(questionSetting)) {
      setQuestionSetting(question);
    }
    // eslint-disable-next-line
  }, [question]);

  // eslint-disable-next-line
  useEffect(async () => {
    if (question?.questionUuid) {
      const answer = getAnswer(question?.questionUuid);

      if (answer?.questionUuid) {
        setAnserSchema({ ...answer });
        setPdfPreview(answer.pdfPreview);
        setPdfFileUrl(answer?.pdfFileUrl);
        setNotes(answer.note);
        setQuestionImageURL([...answer.image]);

        if (answer.image && answer.image.length) {
          setPhoto([...answer.image]);
        }
        setIsQuestionLoading(false);
      } else {
        setIsQuestionLoading(false);
      }
    }
    // eslint-disable-next-line
  }, [question]);

  useEffect(() => {
    if (question) {
      setQuestionPreview(question);
    }
  }, [question]);

  const imageDeleteHandler = (index) => {
    let removeImageArray =
      answerSchema && answerSchema?.image?.length
        ? [...answerSchema.image]
        : [...questionImageURL];
    removeImageArray.splice(index, 1);

    if (answerSchema.image?.length || questionImageURL?.length) {
      if (!isQuestionLoading) {
        let answerToSave = {
          questionUuid: question.questionUuid,
          pdfPreview: pdfPreview,
          pdfFileUrl: pdfFileUrl,
          note: notes,
          image: [...removeImageArray],
        };
        addUpdateAnwser(answerToSave);
      }
      photo.splice(index, 1);
      setPhoto([...photo]);
    } else {
      setPhoto([]);
    }
  };

  const handleChange = async (event) => {
    if (event?.target?.files[0]?.size / 1024 / 1024 <= 5) {
      let fileName = event?.target?.files[0].name;
      const fileData = await getFileData(event);
      let existingFileUrl = questionSetting.settings.selectedFile
        ? questionSetting.settings.selectedFile
        : undefined;
      if (fileData.fileUrl) {
        uploadImage({
          questionType: "pdf",
          fileName: fileName,
          base64: fileData.fileUrl,
          existingFileUrl,
          fileType: fileData.fileType,
        }).then((response) => {
          setPdfFileUrl(response?.value?.data);
          setPdfPreview(fileData.fileName);
        });
      }
    } else {
      toast.error("File size must be 5mb or less");
    }
  };

  useEffect(() => {
    let answerToSave = {
      questionUuid: question.questionUuid,
      pdfPreview: pdfPreview,
      pdfFileUrl: pdfFileUrl,
      note: notes,
      image: questionImageURL ? questionImageURL : "",
    };
    if (!isQuestionLoading) {
      addUpdateAnwser(answerToSave);
    }

    // eslint-disable-next-line
  }, [pdfPreview, notes]);

  const imageAddHandler = () => {
    if (photo && photo.length) {
      let answerToSave = {
        questionUuid: question.questionUuid,
        pdfPreview: pdfPreview,
        pdfFileUrl: pdfFileUrl,
        note: notes,
        image: [...photo],
      };

      setQuestionImageURL([...photo]);

      if (!isQuestionLoading) {
        addUpdateAnwser(answerToSave);
      }
    }
  };

  return (
    <div id={question?.questionUuid} className="multiple-choice">
      <div className="attachment-preview">
        {question?.settings?.photo ? (
          <img src={question.settings.photo} alt="Attachment Preview" />
        ) : (
          ""
        )}
      </div>
      <div className="d-flex justify-content-between question-title  h-auto">
        <strong>
          {questionPreview?.settings.questionText === "Type Instructions" ? (
            <I18n lng="Form.TypeInstructions" />
          ) : questionPreview?.settings.questionText ===
            "Add a new question" ? (
            <>
              {question?.questionNumber
                ? question?.questionNumber
                : actionItem?.questionNo === undefined
                ? actionItem
                : actionItem?.questionNo
                ? actionItem?.questionNo + 1
                : 1}
              .{"   "}
              <I18n lng="Form.AddANewQuestion" />{" "}
            </>
          ) : (
            `${
              question?.questionNumber
                ? question?.questionNumber
                : actionItem?.questionNo === undefined
                ? actionItem
                : actionItem?.questionNo
                ? actionItem?.questionNo + 1
                : 1
            }. ${questionPreview?.settings.questionText}`
          )}
          {question.settings.isMandatory && <span className="required"></span>}
        </strong>

        <div className="d-flex">
          {question.settings.description ? (
            <>
              <p
                data-for={`${question.settings.questionText}`}
                data-tip={`${question.settings.description}`}
                data-iscapture="true"
              >
                <BsInfoCircleFill color={"C4C4C4"} />
              </p>
              <ReactTooltip
                id={`${question.settings.questionText}`}
                place="top"
                type="dark"
                effect="float"
                multiline={true}
              />
            </>
          ) : (
            ""
          )}
        </div>
      </div>
      {!pdfPreview ? (
        <div
          className="d-flex justify-content-center"
          onClick={() => {
            handleClick();
          }}
        >
          <div className="icon-upload">
            <img src={IconPDF} alt="PDF" title="Add PDF" width="50px" />
            <p>
              <I18n lng="global.AddPdf" />
            </p>
            <p>
              <I18n lng="global.MaxSize5Mb" />
            </p>
          </div>
          <input
            type="file"
            style={{ display: "none" }}
            ref={hiddenFileInput}
            onChange={handleChange}
            accept="application/pdf"
            size={1000000}
          />
        </div>
      ) : null}

      {pdfPreview ? (
        <div className="preview-pdf">
          <ul className="preview-pdf-list">
            <li>
              <span className="icon-wrap">
                <FileIcon extension="pdf" {...defaultStyles.pdf} />
              </span>
              <p className="file-name truncate">{pdfPreview}</p>
              {
                <div className="actions">
                  <span
                    className="action action-delete"
                    onClick={() => {
                      setPdfPreview("");
                      setPdfFileUrl("");
                    }}
                  >
                    <img src={IconDelete} alt="Delete" title="Delete"></img>
                  </span>
                </div>
              }
            </li>
          </ul>
        </div>
      ) : null}

      {
        <Attachment
          canComment={
            pdfFileUrl !== "" ? question?.settings?.canComment : false
          }
          canAttachPhoto={
            pdfFileUrl !== "" ? question?.settings?.canAttachPhoto : false
          }
          notes={notes}
          setNotes={setNotes}
          photo={photo}
          setPhoto={setPhoto}
          imageDeleteHandler={imageDeleteHandler}
          imageAddHandler={imageAddHandler}
        />
      }
    </div>
  );
};

const mapStateToProps = ({ authentication }) => ({
  currentRole: authentication.currentRole,
});
const mapDispatchToProps = {
  uploadImage,
};
export default connect(mapStateToProps, mapDispatchToProps)(PDFPreview);
