import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import RestoreIcon from "../../../../../assets/images/restore.svg";
import DeleteIcon from "../../../../../assets/images/delete.svg";
import { useArchiveUIContext } from "../archive-ui-context";
import { canAccessTheModule } from "../../../../../helper/helperFunctions";

const ListItemMenu = ({ form, roleAccess }) => {
  const archiveUIContext = useArchiveUIContext();

  const ArchiveUIProps = useMemo(() => {
    return {
      openDeleteFormDlg: archiveUIContext.openDeleteFormDlg,
      openRestoreFormDlg: archiveUIContext.openRestoreFormDlg,
    };
  }, [archiveUIContext]);

  return (
    <>
      <div>
        <Link
          to="#"
          onClick={(e) => {
            e.preventDefault();
            ArchiveUIProps.openRestoreFormDlg(form.id, form);
          }}
        >
          <img src={RestoreIcon} alt="RestoreIcon" />
        </Link>
        {canAccessTheModule(
          roleAccess?.form?.access?.delete,
          <Link
            to="#"
            className="mr-1 ml-2"
            onClick={(e) => {
              e.preventDefault();
              ArchiveUIProps.openDeleteFormDlg(form.id);
            }}
          >
            <img src={DeleteIcon} alt="DeleteIcon" className="icon-red" />
          </Link>
        )}
      </div>
    </>
  );
};

export default ListItemMenu;
