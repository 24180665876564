import React, { useState } from "react";
import TextSettings from "./text-settings";
import TextPreview from "./text-preview";
import I18n from "../../../../../languageSelector";
import { TEXT_DIRECTION } from "../../../../../../../helper/helperFunctions";

const TextBuilder = ({ updateQuestion, actionItem, question }) => {
  const [selected, setSelected] = useState("SETTINGS");

  return (
    <>
      <ul
        className={`nav nav-tabs ${TEXT_DIRECTION() === "rtl" ? "pr-0" : ""}`}
      >
        <li className="nav-item">
          <button
            onClick={() => setSelected("SETTINGS")}
            className={`nav-link ${selected === "SETTINGS" ? "active" : ""}`}
          >
            <I18n lng="global.Settings" />
          </button>
        </li>
        <li className="nav-item">
          <button
            onClick={() => setSelected("PREVIEW")}
            className={`nav-link ${selected === "PREVIEW" ? "active" : ""}`}
          >
            <I18n lng="Form.Preview" />
          </button>
        </li>
      </ul>
      {selected === "SETTINGS" ? (
        <TextSettings
          updateQuestion={updateQuestion}
          actionItem={actionItem}
          question={question}
        />
      ) : (
        <TextPreview actionItem={actionItem} question={question} />
      )}
    </>
  );
};

export default TextBuilder;
