import React, { useEffect } from "react";
import Select from "react-select";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import I18n, { i18n } from "../../private/languageSelector";
import "react-datepicker/dist/react-datepicker.css";
import { REPORT_STATUS_FILTER } from "../../../config/constants";
import DialogModal from "../../../components/dialogs/compact-dialog";
import { getAllUsers } from "../../../redux/reducers/user/user.reducer";
import { getAllSites } from "../../../redux/reducers/sites/sites.reducer";

const FilterDialog = ({
  show,
  primaryAction,
  secondaryAction,
  filterState,
  setFilterState,
  currentRole,
  clearActions,
  getAllUsers,
  getAllSites,
  secondryActionTitle,
  allUsers,
  forms,
  sites,
  formList,
  onlyManager,
}) => {
  useEffect(() => {
    if (show) {
      if (currentRole !== "user") {
        getAllUsers();
        getAllSites();
      } else {
        getAllSites();
      }
    } // eslint-disable-next-line
  }, [show]);
  return (
    <DialogModal
      showDlg={show}
      size="md"
      classes={"modal-filter"}
      content={
        <>
          <h3 className="text-center">
            <I18n lng="global.Filters" />
          </h3>
          <ul className="list-unstyled">
            <li>
              <strong>
                <I18n lng="global.FromDate" />
              </strong>
              <DatePicker
                selected={filterState.fromDate}
                maxDate={filterState.toDate}
                onChange={(date) => {
                  setFilterState({
                    ...filterState,
                    fromDate: date,
                  });
                }}
                className="date-picker"
              />
            </li>
            <li>
              <strong>
                <I18n lng="global.ToDate" />
              </strong>
              <DatePicker
                selected={filterState.toDate}
                minDate={filterState.fromDate}
                maxDate={new Date()}
                onChange={(date) =>
                  setFilterState({
                    ...filterState,
                    toDate: date,
                  })
                }
                className="date-picker"
              />
            </li>
            {currentRole !== "user" && (
              <li>
                <strong>
                  <I18n lng="global.Inspector" />
                </strong>
                <Select
                  placeholder={<I18n lng="global.Select" />}
                  className="filter-select"
                  onChange={(e) => {
                    setFilterState({
                      ...filterState,
                      inspector: e,
                    });
                  }}
                  value={filterState.inspector}
                  options={
                    allUsers && allUsers.length
                      ? allUsers
                          .filter((item) =>
                            onlyManager
                              ? item.role === "manager"
                              : item.role === "user"
                          )
                          .map((user) => {
                            return { value: user.id, label: user.fullName };
                          })
                      : []
                  }
                />
              </li>
            )}
            {!formList && (
              <>
                <li>
                  <strong>
                    <I18n lng="global.Site" />
                  </strong>
                  <Select
                    placeholder={<I18n lng="global.Select" />}
                    className="filter-select"
                    onChange={(e) => {
                      setFilterState({ ...filterState, site: e });
                    }}
                    value={filterState.site}
                    options={
                      sites && sites.length
                        ? sites.map((site) => {
                            return {
                              value: site.id,
                              label:
                                site.name === "Default Site"
                                  ? i18n("Company.DefaultSite")
                                  : site.name,
                            };
                          })
                        : []
                    }
                  />
                </li>
                <li>
                  <strong>
                    <I18n lng="global.Form" />
                  </strong>
                  <Select
                    placeholder={<I18n lng="global.Select" />}
                    className="filter-select"
                    onChange={(e) => {
                      setFilterState({ ...filterState, formName: e });
                    }}
                    value={filterState.formName}
                    options={
                      forms && forms.length
                        ? forms.map((form) => {
                            return {
                              value: form.id,
                              label: form.formDetail?.title?.includes(
                                "Untitled Form"
                              ) ? (
                                <>
                                  <I18n lng="global.UntitledForm" />{" "}
                                  {form?.formDetail?.documentNumber}
                                </>
                              ) : (
                                form.formDetail.title
                              ),
                            };
                          })
                        : []
                    }
                  />
                </li>
                <li>
                  <strong>
                    <I18n lng="global.Status" />
                  </strong>
                  <Select
                    placeholder={<I18n lng="global.Select" />}
                    className="filter-select"
                    onChange={(e) => {
                      setFilterState({ ...filterState, status: e });
                    }}
                    value={filterState.status}
                    options={
                      REPORT_STATUS_FILTER && REPORT_STATUS_FILTER.length
                        ? REPORT_STATUS_FILTER.map((status) => {
                            return {
                              value: status.value,
                              label:
                                status.lable === "Reject" ? (
                                  <I18n lng="Reports.RejectStatus" />
                                ) : status.lable === "Accept" ? (
                                  <I18n lng="Reports.Compelete" />
                                ) : status.lable === "Submit" ? (
                                  <I18n lng="Reports.Submitted" />
                                ) : status.lable === "In-Complete" ? (
                                  <I18n lng="Reports.Incomplete" />
                                ) : status.lable === "Draft" ? (
                                  <I18n lng="Reports.Draft" />
                                ) : status.lable === "ReSubmit" ? (
                                  <I18n lng="Reports.ResubmitStatus" />
                                ) : (
                                  "status-accept"
                                ),
                            };
                          })
                        : []
                    }
                  />
                </li>
              </>
            )}
          </ul>
        </>
      }
      iconCloseDlg={true}
      clearActions={() => clearActions()}
      primaryAction={() => primaryAction()}
      secondaryAction={() => secondaryAction()}
      secondryActionTitle={secondryActionTitle}
      primaryActionTitle={<I18n lng="global.Apply" />}
    />
  );
};

const mapStateToProps = ({ authentication, user, form, sites }) => ({
  forms: form.forms,
  sites: sites.sites,
  allUsers: user.allUsers,
  currentRole: authentication.currentRole,
});

const mapDispatchToProps = {
  getAllUsers,
  getAllSites,
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterDialog);
