import { useEffect, useRef, useState } from "react";
import dropdownIcon from "../../../../assets/images/dropdownMenuIcon.svg";
import { i18n } from "../../languageSelector";
// import { Calendar } from "react-multi-date-picker";
import moment from "moment";
import DatePicker from "react-multi-date-picker";
import { TEXT_DIRECTION } from "../../../../helper/helperFunctions";

interface SelectIProps {
  label: string;
  // selected?: string | any;
  options: any[];
  // multiSelect?: boolean;
  // value?: any;
  // setValue?: any;
  // handleSelect?: any;
  // handleRemove?: any;
  // selectedOptions?: string[];
  setFormValues: any;
  formValues: any;
  slectedLable: string;
  setslectedLable: any;
  setSelectedDate: any;
  setselectedValue: any;
  selectedValue: string;
  // selectedDate: any;
}
function CustomSelect({
  label,
  options,
  setFormValues,
  formValues,
  setslectedLable,
  setSelectedDate,
  slectedLable,
  setselectedValue,
  selectedValue,
}: SelectIProps) {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef<any>(null);
  // const [selectedValue, setselectedValue] = useState("Last30Days");
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [customDateSelect, setcustomDateSelect] = useState({
    startDate: "",
    endDate: "",
  });
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const [value, setValue] = useState();
  useEffect(() => {
    if (formValues.day === i18n("Analytics.Last30Days")) {
      setSelectedDate({
        startDate: moment().subtract(30, "days").format("YYYY/MM/DD"),
        endDate: moment().format("YYYY/MM/DD"),
      });
      //
      setIsCalendarOpen(false);
      setValue(undefined);
    }
    // eslint-disable-next-line
  }, [formValues.day]);
  useEffect(() => {
    if (datePickerRef.current) {
      datePickerRef.current.openCalendar();
      if (customDateSelect.startDate === "" && customDateSelect.endDate === "") {
        setslectedLable("");
      } else {
        setslectedLable(`${customDateSelect.startDate} ~ ${customDateSelect.endDate}`);
      }
    }
    // eslint-disable-next-line
  }, [slectedLable === i18n("Analytics.CustomRange")]);

  const handleSelectValue = (val: string, lable: string) => {
    if (val === "CustomRange") {
      setIsCalendarOpen(true);
      setslectedLable("");
      setSelectedDate({ startDate: "", endDate: "" });
      if (datePickerRef.current) {
        datePickerRef.current.openCalendar();
      }
      setIsOpen(true);

      // setFormValues({ ...formValues, day: val });
    }

    setselectedValue(val);
    setslectedLable(lable);
    setFormValues({ ...formValues, day: lable });

    if (val === "Today") {
      setSelectedDate({
        startDate: moment().format("YYYY/MM/DD"),
        endDate: moment().format("YYYY/MM/DD"),
      });
      setIsOpen(false);
      setIsCalendarOpen(false);
      setValue(undefined);
      setcustomDateSelect({ ...customDateSelect, startDate: "", endDate: "" });
    } else if (val === "Yesterday") {
      setSelectedDate({
        startDate: moment().subtract(1, "days").format("YYYY/MM/DD"),
        endDate: moment().subtract(1, "days").format("YYYY/MM/DD"),
      });
      setIsOpen(false);
      setIsCalendarOpen(false);
      setValue(undefined);
      setcustomDateSelect({ ...customDateSelect, startDate: "", endDate: "" });
    } else if (val === "LastWeek") {
      setSelectedDate({
        startDate: moment().subtract(1, "week").format("YYYY/MM/DD"),
        endDate: moment().format("YYYY/MM/DD"),
      });
      setIsOpen(false);
      setIsCalendarOpen(false);
      setValue(undefined);
      setcustomDateSelect({ ...customDateSelect, startDate: "", endDate: "" });
    } else if (val === "Last7Days") {
      setSelectedDate({
        startDate: moment().subtract(7, "days").format("YYYY/MM/DD"),
        endDate: moment().format("YYYY/MM/DD"),
      });
      setIsOpen(false);
      setIsCalendarOpen(false);
      setValue(undefined);
      setcustomDateSelect({ ...customDateSelect, startDate: "", endDate: "" });
    } else if (val === "ThisMonth") {
      setSelectedDate({
        startDate: moment().startOf("month").format("YYYY/MM/DD"),
        endDate: moment().endOf("month").format("YYYY/MM/DD"),
      });
      setIsCalendarOpen(false);
      setIsOpen(false);
      setcustomDateSelect({ ...customDateSelect, startDate: "", endDate: "" });
      setValue(undefined);
    } else if (val === "LastMonth") {
      setSelectedDate({
        startDate: moment().subtract(1, "month").startOf("month").format("YYYY/MM/DD"),
        endDate: moment().subtract(1, "month").endOf("month").format("YYYY/MM/DD"),
      });
      setIsCalendarOpen(false);
      setIsOpen(false);
      setcustomDateSelect({ ...customDateSelect, startDate: "", endDate: "" });
      setValue(undefined);
    } else if (val === "ThisWeek") {
      setSelectedDate({
        startDate: moment().startOf("week").format("YYYY/MM/DD"),
        endDate: moment().endOf("week").format("YYYY/MM/DD"),
      });
      setIsCalendarOpen(false);
      setIsOpen(false);
      setValue(undefined);
      setcustomDateSelect({ ...customDateSelect, startDate: "", endDate: "" });
    } else if (val === "Last30Days" || lable === "Last 30 days") {
      setSelectedDate({
        startDate: moment().subtract(30, "days").format("YYYY/MM/DD"),
        endDate: moment().format("YYYY/MM/DD"),
      });
      setIsCalendarOpen(false);
      setIsOpen(false);
      setcustomDateSelect({ ...customDateSelect, startDate: "", endDate: "" });

      setValue(undefined);
    } else if (val === "last7Days" || lable === "Last 7 days") {
      setSelectedDate({
        startDate: moment().subtract(7, "days").format("YYYY/MM/DD"),
        endDate: moment().format("YYYY/MM/DD"),
      });
      setValue(undefined);
      setIsOpen(false);
      setValue(undefined);
      setcustomDateSelect({ ...customDateSelect, startDate: "", endDate: "" });
    }
  };

  const datePickerRef = useRef<any>();

  const weekDays = [
    ["sun", "Su"], //[["name","shortName"], ... ]
    ["mon", "Mo"],
    ["tue", "Tu"],
    ["wed", "We"],
    ["thu", "Th"],
    ["fri", "Fr"],
    ["sat", "Sa"],
  ];
  const handleCustomSelectValue = (event: any) => {
    let years = event.map((e: any) => e.year);
    let months = event?.map((e: any) => e.month.number);
    let days = event?.map((e: any) => e.day);
    setValue(event);
    setcustomDateSelect({
      startDate: `${years[0]}/${months[0]}/${days[0]}`,
      endDate: `${years[1]}/${months[1]}/${days[1]}`,
    });
  };
  const handleConfirm = () => {
    datePickerRef?.current?.closeCalendar();
    setSelectedDate({
      startDate: customDateSelect.startDate,
      endDate: customDateSelect.endDate,
    });
    setIsOpen(false);
    setslectedLable(`${customDateSelect.startDate} ~ ${customDateSelect.endDate}`);
  };

  const handleOutsideClick = (event: any) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  console.group("start");
  console.log(slectedLable, "label");
  console.log(customDateSelect, "custom");
  console.log(selectedValue, "valu");
  console.log(value, "datepicker val");
  console.groupEnd();
  return (
    <div className={`date-input ${TEXT_DIRECTION() === "rtl" ? "isRTL" : "isLTR"}`}>
      <label className="form-lable my-1" htmlFor="inlineFormCustomSelectPref">
        {label}
      </label>
      <br />
      <div className="custom-multi-select" ref={selectRef}>
        <div
          className="select-field-date"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            // padding: "5px",
            // paddingLeft: "15px",
            // paddingRight: "15px ",
            padding: "10px 14px 10px 14px",
            color: "#333333",
          }}
          onClick={toggleDropdown}
        >
          {selectedValue === "" ? (
            <span>Select</span>
          ) : selectedValue ? (
            <span style={{ fontWeight: 400, fontSize: "13px" }}>{slectedLable}</span>
          ) : (
            ""
          )}
          <div className={`dropdown-icon ${isOpen ? "open" : ""}`}>
            <img src={dropdownIcon} alt="dropdown" />
          </div>
        </div>
        {isOpen && (
          <div className=" custom-select-dropdwon">
            <div className="selectedSubItemsDate" style={{ paddingBottom: "15px" }}>
              {isCalendarOpen ? (
                <DatePicker
                  value={value}
                  onChange={handleCustomSelectValue}
                  range
                  // numberOfMonths={2}
                  // multiple
                  showOtherDays
                  ref={datePickerRef}
                  weekDays={weekDays}
                  calendarPosition="left-start"
                  arrow={false}
                >
                  <div className="date-btn-wrapper">
                    {customDateSelect.startDate && value !== undefined && customDateSelect.endDate !== undefined ? (
                      <div className="date-to-show" style={{ marginBottom: "13px", fontSize: "14px" }}>{`${customDateSelect.startDate}-${
                        customDateSelect.endDate !== "undefined/undefined/undefined" ? customDateSelect.endDate : ""
                      }`}</div>
                    ) : null}

                    <button
                      className="cancel-date-button"
                      onClick={() => {
                        datePickerRef?.current?.closeCalendar();
                        setValue(undefined);
                        setIsOpen(false);
                        setslectedLable(i18n("Analytics.Last30Days"));
                        setSelectedDate({
                          startDate: moment().subtract(30, "days").format("YYYY/MM/DD"),
                          endDate: moment().format("YYYY/MM/DD"),
                        });
                        setcustomDateSelect({ ...customDateSelect, startDate: "", endDate: "" });
                        setFormValues({ ...formValues, day: i18n("Analytics.Last30Days") });
                      }}
                    >
                      {i18n("global.Cancel")}
                    </button>
                    <button className="apply-date-button" onClick={handleConfirm}>
                      {i18n("global.Apply")}
                    </button>
                  </div>
                </DatePicker>
              ) : null}
              {options.map((item, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => {
                      handleSelectValue(item.value, item.lable);
                    }}
                    style={{
                      marginTop: index === options.length - 1 ? "20px" : "",
                      marginBottom: index === options.length - 1 ? "10px" : "",
                      background: selectedValue === item.value ? "#F2F2F2" : "transparent",

                      cursor: "pointer",
                      fontSize: "13px",
                      fontWeight: 400,
                      lineHeight: "15px",
                      paddingLeft: "17px",
                      paddingRight: "17px",
                    }}
                    className=" py-2 date-dropdown-options"
                  >
                    {item.lable}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default CustomSelect;
