import React, { useRef, useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import "../../assets/css/auth.min.css";
import { useForm } from "react-hook-form";
import IconEye from "../../assets/images/icon-eye.svg";
import IconLock from "../../assets/images/icon-lock.svg";
import { PASSWORD_REGEX } from "../../config/validations";
import Input from "../../components/form-input/input.component";
import I18n, { i18n } from "../../pages/private/languageSelector";
import { useHistory, Redirect, useParams } from "react-router-dom";

const resetPasswordInitialValues = {
  password: "",
  password_repeat: "",
};

const PasswordResetChange = ({ isAuthenticated, location }) => {
  const history = useHistory();
  const { token } = useParams();

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm({ defaultValues: resetPasswordInitialValues });

  const password = useRef({});
  password.current = watch("password", "");

  const { from } = location.state || {
    from: { pathname: "/", search: location.search },
  };

  if (isAuthenticated) {
    return <Redirect to={from} />;
  }

  const onSubmit = (data) => {
    axios
      .post(`auth/reset-password?token=${token}`, { password: data.password })
      .then(() => {
        toast.success(<I18n lng="validation.PASSOWRD_RESET" />);
        reset(resetPasswordInitialValues);
        history.push("/password-reset-message-success");
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          toast.error(<I18n lng="validation.INVALID_LINK" />);
        } else {
          toast.error(<I18n lng="validation.AN_ERROR_OCCURED" />);
        }
      });
  };

  return (
    <div className="auth-wrapper">
      <div className="auth-title">
        <h1 className="font-weight-light font-primary text-center">
          {i18n("ForgotPassword.PasswordReset")}
        </h1>
      </div>
      <form autoComplete="false" onSubmit={handleSubmit(onSubmit)}>
        <div
          className={`input-password ${showNewPassword ? "hide-password" : ""}`}
        >
          <Input
            prefix={IconLock}
            label={i18n("global.NewPassword")}
            suffix={IconEye}
            suffixAction={() => {
              setShowNewPassword(!showNewPassword);
            }}
            type={showNewPassword ? "text" : "password"}
            id="passwordInput"
            name="password"
            placeholder={i18n("global.NewPassword")}
            register={register}
            validations={{
              required: {
                value: true,
                message: i18n("validation.REQUIRED_FIELD"),
              },
              pattern: {
                value: PASSWORD_REGEX,
                message: i18n("validation.INVALID_PASSWORD"),
              },
            }}
            error={errors["password"]}
          />
        </div>
        <div
          className={`input-password ${
            !showConfirmPassword ? "hide-password" : ""
          }`}
        >
          <Input
            label={i18n("global.ConfirmNewPassword")}
            prefix={IconLock}
            suffix={IconEye}
            suffixAction={() => {
              setShowConfirmPassword(!showConfirmPassword);
            }}
            type={showConfirmPassword ? "text" : "password"}
            id="password_repeatInput"
            name="password_repeat"
            placeholder={i18n("global.ConfirmPassword")}
            register={register}
            validations={{
              validate: (value) =>
                value === password.current || "The passwords do not match",
            }}
            error={errors["password_repeat"]}
          />
        </div>
        <div className="btn-wrap text-center">
          <button type="submit" className="btn btn-primary">
            <I18n lng="global.Submit" />
          </button>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = ({ authentication }) => ({
  isAuthenticated: authentication.isAuthenticated,
});

export default connect(mapStateToProps, null)(PasswordResetChange);
