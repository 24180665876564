import React, { useEffect } from "react";
import { useState } from "react";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import IconPlus from "../../../../assets/images/icon-plus.svg";
import I18n, { i18n } from "../../../private/languageSelector";
import { TEXT_DIRECTION } from "../../../../helper/helperFunctions";
import IconDelete from "../../../../assets/images/icon-delete-red.svg";
import { getDropDownList } from "../../../../redux/reducers/form/form.reducer";
import { updateRoute } from "../../../../redux/reducers/authentication/authentication";
import { postGlobalList } from "../../../../redux/reducers/global-list/global-list.reducer";
import { RESPONSES_TYPES } from "../../../../config/constants";
import { v4 as uuid } from "uuid";

const GlobaListAddModal = ({
  question,
  show,
  updateQuestion,
  actionItem,
  postGlobalList,
  secondaryAction,
  getDropDownList,
}) => {
  const [list, setList] = useState([]);
  const [title, setTitle] = useState("");

  useEffect(() => {
    if (show) {
      setTitle("");
      setList([]);
    }
    // eslint-disable-next-line
  }, [show]);

  useEffect(() => {
    updateRoute({
      step1: "Forms",
      step2: "Global List",
      enableBack: true,
      formScrol: false,
    });
    // eslint-disable-next-line
  }, []);

  const addItemHandler = () => {
    if (list) {
      list.push("");
    }
    setList([...list]);
  };

  const deleteHandler = (index) => {
    let tempList = [...list];
    tempList.splice(index, 1);
    setList([...tempList]);
  };

  const saveForm = () => {
    if (!title) {
      toast.error(<I18n lng="Form.ListNameIsRequired" />);
      return;
    }
    if (!list || !list.length) {
      toast.error(<I18n lng="Form.EntriesCannotBeEmpty" />);
      return;
    }

    for (let item of list) {
      if (!item) {
        toast.error(<I18n lng="Form.ListItemCannotBeEmpty" />);
        return;
      }
    }

    postGlobalList({ title, list })
      .then((res) => {
        if (res) {
          let listResponse = {
            ...RESPONSES_TYPES.DROP_DOWN_LIST,
            questionUuid: uuid(),
            id: res.value.data.id,
            settings: {
              ...RESPONSES_TYPES.DROP_DOWN_LIST.settings,
              options: [...res.value.data.list],
              listName: res.value.data.title,
              dropDownOpen: false,
            },
          };
          updateQuestion(
            actionItem.pageNo,
            actionItem.sectionNo,
            actionItem.questionNo,
            {
              ...listResponse,
              list: res.value.data.list,
              settings: {
                ...listResponse.settings,
                questionText: question.settings.questionText,
              },
            }
          );
        }
        secondaryAction();
        getDropDownList();
        toast.success(i18n("global.Added"));
      })
      .catch((error) => console.log(error));
  };

  return (
    <Modal isOpen={show} toggle={secondaryAction} size={"md"}>
      <ModalBody>
        <div className="px-3 global-list-detail">
          <div className="search-bar">
            <form className="form-search">
              <div className="page-title text-center">
                <label className="title-text">
                  <I18n lng="Form.CreateACustomList" />
                </label>
              </div>

              <label className="form-label" htmlFor={"listName"}>
                <strong>
                  <I18n lng="Form.ListName" />
                </strong>
                <span className="required"></span>
              </label>
              <div className="input-group list-name">
                <input
                  value={title}
                  id={"listName"}
                  autoFocus={true}
                  className="form-control"
                  placeholder={i18n("Form.ListName")}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <label className="form-label w-100 mt-5">
                <I18n lng="Form.Entrise" />
              </label>
              {list?.map((item, index) => (
                <div className="d-flex align-items-center mb-3" key={index}>
                  <div
                    className={`input-group mb-0 ${
                      TEXT_DIRECTION() === "rtl" ? "list-name" : "mr-5"
                    }`}
                  >
                    <input
                      className="form-control "
                      placeholder={i18n("Form.ItemName")}
                      autoFocus={true}
                      value={item}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          addItemHandler();
                        }
                      }}
                      onChange={(e) => {
                        list[index] = e.target.value;
                        setList([...list]);
                      }}
                    />
                  </div>
                  <Link to="#" className="btn-link mt-0">
                    <img
                      src={IconDelete}
                      alt="IconDelete"
                      onClick={() => deleteHandler(index)}
                    />
                  </Link>
                </div>
              ))}

              <Link
                to="#"
                className="btn-link mb-4 w-25"
                onClick={addItemHandler}
              >
                <img
                  className={`${TEXT_DIRECTION() === "rtl" ? "ml-2" : ""}`}
                  src={IconPlus}
                  alt="Icon Add"
                />
                <I18n lng="Form.AddItem" />
              </Link>
            </form>
          </div>
          <div className="d-flex justify-content-center">
            <button
              style={{ backgroundColor: "#20639b", color: "white" }}
              className={`btn btn-secondry ${
                TEXT_DIRECTION() === "rtl" ? "ml-2 mr-2" : ""
              }`}
              onClick={() => saveForm()}
            >
              <I18n lng="global.Save&Apply" />
            </button>
            <Link to="#" onClick={secondaryAction}>
              <button className="btn btn-white ml-3">
                <I18n lng="global.Cancel" />
              </button>
            </Link>
          </div>

          <Link
            to="#"
            className={`btn btn-rounded btn-add-new ${
              TEXT_DIRECTION() === "rtl" ? "left-direction" : "right-direction"
            }`}
            onClick={() => {
              alert("comming soon");
            }}
          >
            <I18n lng="Form.Import" />
          </Link>
        </div>
      </ModalBody>
    </Modal>
  );
};

const mapDispatchToProps = {
  postGlobalList,
  getDropDownList,
};
export default connect(null, mapDispatchToProps)(GlobaListAddModal);
