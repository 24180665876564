import React from "react";
import { Link } from "react-router-dom";
import { TEXT_DIRECTION } from "../../helper/helperFunctions";

const PdfCheckBox = ({
  label,
  name,
  register,
  validations,
  error,
  children,
  disabled,
  link,
  linkTitle,

  className = "custom-control custom-checkbox pl-0",
  ...otherProps
}) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          alignItems: "center",
        }}
        className={className}
      >
        <input
          type="checkbox"
          style={
            otherProps.checked === true
              ? { border: "none", order: "1", opacity: "1", position: "none" }
              : { order: "1", opacity: "1", position: "none" }
          }
          // className="custom-control-input"
          className={`
           ${TEXT_DIRECTION() === "rtl" ? "ml-2" : "mr-2"}`}
          id={name}
          name={name}
          {...(register ? register(name, { ...validations }) : {})}
          {...otherProps}
          disabled={disabled}
        />
        <label
          style={
            otherProps && otherProps.checked === true
              ? { color: "#20639B" }
              : null
          }
          // className="custom-control-label"
          className="mb-0 "
          htmlFor={name}
        >
          <p
            style={
              otherProps && otherProps.checked === true
                ? null
                : { color: "#828282", fontWeight: "500" }
            }
            className="mb-0"
          >
            {label}
          </p>

          {linkTitle && (
            <Link to={"#"} onClick={() => window.open(link)}>
              {linkTitle}
            </Link>
          )}
          {validations?.required && <span className="required"></span>}
        </label>
      </div>
      {children}
      {error && (
        <p className="error" style={{ marginTop: "0px", marginLeft: "15px" }}>
          {error.message}
        </p>
      )}
    </>
  );
};

export default PdfCheckBox;
