import { i18n } from "../../../languageSelector";

export const MANAGER_PORTAL_OPTIONS_OWNER = [
  {
    name: i18n("Roles.AccessForms"),
    selected: true,
    disabled: true,
    subItem: [
      { name: i18n("Roles.Create"), selected: true, disabled: true },
      { name: i18n("Roles.Edit"), selected: true, disabled: true },
      { name: i18n("Roles.Archive"), selected: true, disabled: true },
      { name: i18n("Roles.Delete"), selected: true, disabled: true },
      { name: i18n("Roles.ViewHistory"), selected: true, disabled: true },
      {
        name: i18n("Roles.CreateDispatchRoles"),
        selected: true,
        disabled: true,
      },
      {
        name: i18n("Roles.DeleteDispatchRoles"),
        selected: true,
        disabled: true,
      },
      {
        name: i18n("Roles.ManageApprovalRights"),
        selected: true,
        disabled: true,
      },
    ],
  },
  {
    name: i18n("Roles.AccessReports"),
    selected: true,
    disabled: true,
    subItem: [
      {
        name: i18n("Roles.DeleteArchivedReports"),
        selected: true,
        disabled: true,
      },
      {
        name: i18n("Roles.RestoreDeletedReports"),
        selected: true,
        disabled: true,
      },
      {
        name: i18n("Roles.ExportOtherReports"),
        selected: true,
        disabled: true,
      },
      {
        name: i18n("Roles.ViewHistoryOfOtherReports"),
        selected: true,
        disabled: true,
      },
    ],
  },
  {
    name: i18n("Roles.AccessCompanySetting"),
    selected: true,
    disabled: true,
    subItem: [
      { name: "Manage sites", selected: true, disabled: true },
      { name: i18n("Roles.ManageUsers"), selected: true, disabled: true },
      { name: i18n("Roles.ManageRoles"), selected: true, disabled: true },
      {
        name: i18n("Roles.ManageCompanyInformation"),
        selected: true,
        disabled: true,
      },
      {
        name: i18n("Roles.ManageSubscription"),
        selected: true,
        disabled: true,
      },
    ],
  },
  {
    name: i18n("Roles.AccessAnalytics"),
    selected: true,
    disabled: true,
    subItem: [
      {
        name: i18n("Analytics.Export"),
        selected: true,
        disabled: true,
      },
    ],
  },
];

export const INSPECTION_PORTAL_OPTIONS_OWNER = [
  {
    name: i18n("Roles.AccessReports"),
    selected: true,
    disabled: true,
    subItem: [
      { name: i18n("Roles.ExportReport"), selected: true, disabled: true },
      { name: i18n("Roles.CustomizeReport"), selected: true, disabled: true },
    ],
  },
];

export const MANAGER_PORTAL_OPTIONS_MANAGER = [
  {
    name: i18n("Roles.AccessForms"),
    selected: true,
    disabled: false,
    subItem: [
      { name: i18n("Roles.Create"), selected: true, disabled: false },
      { name: i18n("Roles.Edit"), selected: true, disabled: false },
      { name: i18n("Roles.Archive"), selected: true, disabled: false },
      { name: i18n("Roles.Delete"), selected: false, disabled: false },
      { name: i18n("Roles.ViewHistory"), selected: true, disabled: false },
      {
        name: i18n("Roles.CreateDispatchRoles"),
        selected: true,
        disabled: false,
      },
      {
        name: i18n("Roles.DeleteDispatchRoles"),
        selected: false,
        disabled: false,
      },
      {
        name: i18n("Roles.ManageApprovalRights"),
        selected: false,
        disabled: false,
      },
    ],
  },
  {
    name: i18n("Roles.AccessReports"),
    selected: true,
    disabled: false,
    subItem: [
      {
        name: i18n("Roles.DeleteArchivedReports"),
        selected: false,
        disabled: false,
      },
      {
        name: i18n("Roles.RestoreDeletedReports"),
        selected: false,
        disabled: false,
      },
      {
        name: i18n("Roles.ExportOtherReports"),
        selected: false,
        disabled: false,
      },
      {
        name: i18n("Roles.ViewHistoryOfOtherReports"),
        selected: false,
        disabled: false,
      },
    ],
  },
  {
    name: i18n("Roles.AccessCompanySetting"),
    selected: true,
    disabled: false,
    subItem: [
      { name: "Manage sites", selected: true, disabled: false },
      { name: i18n("Roles.ManageUsers"), selected: false, disabled: false },
      { name: i18n("Roles.ManageRoles"), selected: false, disabled: false },
      {
        name: i18n("Roles.ManageCompanyInformation"),
        selected: false,
        disabled: false,
      },
      {
        name: i18n("Roles.ManageSubscription"),
        selected: false,
        disabled: false,
      },
    ],
  },
  {
    name: i18n("Roles.AccessAnalytics"),
    selected: true,
    disabled: false,
    subItem: [
      {
        name: i18n("Analytics.Export"),
        selected: false,
        disabled: false,
      },
    ],
  },
];

export const INSPECTION_PORTAL_OPTIONS_MANAGER = [
  {
    name: i18n("Roles.AccessReports"),
    selected: false,
    disabled: false,
    subItem: [
      { name: i18n("Roles.ExportReport"), selected: false, disabled: false },
      { name: i18n("Roles.CustomizeReport"), selected: false, disabled: false },
    ],
  },
];

export const MANAGER_PORTAL_OPTIONS_INSPECTOR = [
  {
    name: i18n("Roles.AccessForms"),
    selected: false,
    disabled: false,
    subItem: [
      { name: i18n("Roles.Create"), selected: false, disabled: false },
      { name: i18n("Roles.Edit"), selected: false, disabled: false },
      { name: i18n("Roles.Archive"), selected: false, disabled: false },
      { name: i18n("Roles.Delete"), selected: false, disabled: false },
      { name: i18n("Roles.ViewHistory"), selected: false, disabled: false },
      {
        name: i18n("Roles.CreateDispatchRoles"),
        selected: false,
        disabled: false,
      },
      {
        name: i18n("Roles.DeleteDispatchRoles"),
        selected: false,
        disabled: false,
      },
      {
        name: i18n("Roles.ManageApprovalRights"),
        selected: false,
        disabled: false,
      },
    ],
  },
  {
    name: i18n("Roles.AccessReports"),
    selected: false,
    disabled: false,
    subItem: [
      {
        name: i18n("Roles.DeleteArchivedReports"),
        selected: false,
        disabled: false,
      },
      {
        name: i18n("Roles.RestoreDeletedReports"),
        selected: false,
        disabled: false,
      },
      {
        name: i18n("Roles.ExportOtherReports"),
        selected: false,
        disabled: false,
      },
      {
        name: i18n("Roles.ViewHistoryOfOtherReports"),
        selected: false,
        disabled: false,
      },
    ],
  },
  {
    name: i18n("Roles.AccessCompanySetting"),
    selected: false,
    disabled: false,
    subItem: [
      { name: i18n("Roles.ManageSites"), selected: false, disabled: false },
      { name: i18n("Roles.ManageUsers"), selected: false, disabled: false },
      { name: i18n("Roles.ManageRoles"), selected: false, disabled: false },
      {
        name: i18n("Roles.ManageCompanyInformation"),
        selected: false,
        disabled: false,
      },
      {
        name: i18n("Roles.ManageSubscription"),
        selected: false,
        disabled: false,
      },
    ],
  },
  // {
  //   name: i18n("Roles.AccessAnalytics"),
  //   selected: false,
  //   disabled: false,
  //   subItem: [
  //     {
  //       name: i18n("Analytics.Export"),
  //       selected: false,
  //       disabled: false,
  //     },
  //   ],
  // },
];

export const INSPECTION_PORTAL_OPTIONS_INSPECTOR = [
  {
    name: i18n("Roles.AccessReports"),
    selected: true,
    disabled: false,
    subItem: [
      { name: i18n("Roles.ExportReport"), selected: false, disabled: false },
      { name: i18n("Roles.CustomizeReport"), selected: false, disabled: false },
    ],
  },
];

export const MANAGER_PORTAL_OPTIONS_DEFAULT = [
  {
    name: i18n("Roles.AccessForms"),
    selected: false,
    disabled: false,
    subItem: [
      { name: i18n("Roles.Create"), selected: false, disabled: false },
      { name: i18n("Roles.Edit"), selected: false, disabled: false },
      { name: i18n("Roles.Archive"), selected: false, disabled: false },
      { name: i18n("Roles.Delete"), selected: false, disabled: false },
      { name: i18n("Roles.ViewHistory"), selected: false, disabled: false },
      {
        name: i18n("Roles.CreateDispatchRoles"),
        selected: false,
        disabled: false,
      },
      {
        name: i18n("Roles.DeleteDispatchRoles"),
        selected: false,
        disabled: false,
      },
      {
        name: i18n("Roles.ManageApprovalRights"),
        selected: false,
        disabled: false,
      },
    ],
  },
  {
    name: i18n("Roles.AccessReports"),
    selected: false,
    disabled: false,
    subItem: [
      {
        name: i18n("Roles.DeleteArchivedReports"),
        selected: false,
        disabled: false,
      },
      {
        name: i18n("Roles.RestoreDeletedReports"),
        selected: false,
        disabled: false,
      },
      {
        name: i18n("Roles.ExportOtherReports"),
        selected: false,
        disabled: false,
      },
      {
        name: i18n("Roles.ViewHistoryOfOtherReports"),
        selected: false,
        disabled: false,
      },
    ],
  },
  {
    name: i18n("Roles.AccessCompanySetting"),
    selected: false,
    disabled: false,
    subItem: [
      { name: i18n("Roles.ManageSites"), selected: false, disabled: false },
      { name: i18n("Roles.ManageUsers"), selected: false, disabled: false },
      { name: i18n("Roles.ManageRoles"), selected: false, disabled: false },
      {
        name: i18n("Roles.ManageCompanyInformation"),
        selected: false,
        disabled: false,
      },
      {
        name: i18n("Roles.ManageSubscription"),
        selected: false,
        disabled: false,
      },
    ],
  },
  {
    name: i18n("Roles.AccessAnalytics"),
    selected: true,
    disabled: false,
    subItem: [
      {
        name: i18n("Analytics.Export"),
        selected: false,
        disabled: false,
      },
    ],
  },
];

export const INSPECTION_PORTAL_OPTIONS_DEFAULT = [
  {
    name: i18n("Roles.AccessReports"),
    selected: false,
    disabled: false,
    subItem: [
      { name: i18n("Roles.ExportReport"), selected: false, disabled: false },
      { name: i18n("Roles.CustomizeReport"), selected: false, disabled: false },
    ],
  },
];

export const MANAGER_PORTAL_OPTIONS_ADMIN = [
  {
    name: i18n("Roles.AccessForms"),
    selected: true,
    disabled: false,
    subItem: [
      { name: i18n("Roles.Create"), selected: true, disabled: false },
      { name: i18n("Roles.Edit"), selected: true, disabled: false },
      { name: i18n("Roles.Archive"), selected: true, disabled: false },
      { name: i18n("Roles.Delete"), selected: true, disabled: false },
      { name: i18n("Roles.ViewHistory"), selected: true, disabled: false },
      {
        name: i18n("Roles.CreateDispatchRoles"),
        selected: true,
        disabled: false,
      },
      {
        name: i18n("Roles.DeleteDispatchRoles"),
        selected: true,
        disabled: false,
      },
      {
        name: i18n("Roles.ManageApprovalRights"),
        selected: true,
        disabled: false,
      },
    ],
  },
  {
    name: i18n("Roles.AccessReports"),
    selected: true,
    disabled: false,
    subItem: [
      {
        name: i18n("Roles.DeleteArchivedReports"),
        selected: true,
        disabled: false,
      },
      {
        name: i18n("Roles.RestoreDeletedReports"),
        selected: true,
        disabled: false,
      },
      {
        name: i18n("Roles.ExportOtherReports"),
        selected: true,
        disabled: false,
      },
      {
        name: i18n("Roles.ViewHistoryOfOtherReports"),
        selected: true,
        disabled: false,
      },
    ],
  },
  {
    name: i18n("Roles.AccessCompanySetting"),
    selected: true,
    disabled: false,
    subItem: [
      { name: "Manage sites", selected: true, disabled: false },
      { name: i18n("Roles.ManageUsers"), selected: true, disabled: false },
      { name: i18n("Roles.ManageRoles"), selected: true, disabled: false },
      {
        name: i18n("Roles.ManageCompanyInformation"),
        selected: true,
        disabled: false,
      },
      {
        name: i18n("Roles.ManageSubscription"),
        selected: true,
        disabled: false,
      },
    ],
  },
  {
    name: i18n("Roles.AccessAnalytics"),
    selected: true,
    disabled: false,
    subItem: [
      {
        name: i18n("Analytics.Export"),
        selected: true,
        disabled: false,
      },
    ],
  },
];

export const INSPECTION_PORTAL_OPTIONS_ADMIN = [
  {
    name: i18n("Roles.AccessReports"),
    selected: true,
    disabled: false,
    subItem: [
      { name: i18n("Roles.ExportReport"), selected: true, disabled: false },
      { name: i18n("Roles.CustomizeReport"), selected: true, disabled: false },
    ],
  },
];
