import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { QRCodeSVG } from "qrcode.react";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import I18n, { i18n } from "../../../../languageSelector";
import IconTamam from "../../../../../../assets/images/tamam.svg";
import IconPrint from "../../../../../../assets/images/Icon-Print.svg";
import {
  CAPITALIZE_FIRST_LETTER,
  INVOICE_AMOUNT_CALCULATOR,
} from "../../../../../../helper/helperFunctions";
import { getSubscriptionDetailById } from "../../../../../../redux/reducers/subscription/subscription.reducer";

const InvoicePdfView = ({
  invoiceDetailsToShow,
  getSubscriptionDetailById,
}) => {
  const { id } = useParams();
  const componentRef = useRef();

  const [invoiceTax, setInvoiceTax] = useState(0);
  const [paymentDetails, setPaymentDetails] = useState({});
  const [companyDetails, setCompanyDetails] = useState({});

  useEffect(() => {
    if (id) {
      getSubscriptionDetailById(id);
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (invoiceDetailsToShow?.id) {
      setCompanyDetails({ ...invoiceDetailsToShow?.company });
      setPaymentDetails({ ...invoiceDetailsToShow?.paymentDetails?.payment });
      let tax =
        (+invoiceDetailsToShow?.cost * +invoiceDetailsToShow?.totalUsers * 15) /
        100;
      setInvoiceTax(tax);
    }
  }, [invoiceDetailsToShow]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    bodyClass: "invoice-wrapper",
  });

  return (
    <div ref={componentRef} className="invoice-wrapper">
      <div>
        <img src={IconTamam} alt="loaidng" className="cursor-pointer" />
        <strong
          className="float-right cursor-pointer"
          onClick={() => {
            setTimeout(function () {
              handlePrint();
            }, 100);
          }}
        >
          <img src={IconPrint} alt="loading" />
        </strong>
      </div>
      <div className="title-text">
        <I18n lng="Subscriptions.Invoice" />
      </div>
      <hr />
      <div className="row">
        <div className="col-6">
          <div className="title-heading">
            <I18n lng="Subscriptions.From" />
          </div>
          <p>Numu It Est. - TAMAM APP</p>
          <p>Dammam - Saudi Arabia</p>
          <p>Cr .2050139742</p>
          <p>Tax No. 30000123456789</p>
        </div>
        <div className="col-6 ">
          <div className="title-heading">
            <I18n lng="Subscriptions.Details" />
          </div>
          <p className="invoice-detail">
            <label>
              <I18n lng="Subscriptions.InvoiceNumber" />
            </label>{" "}
            <strong>{paymentDetails?.metadata?.invoiceNumber}</strong>
          </p>
          <p className="invoice-detail">
            <label>
              <I18n lng="Subscriptions.DateOfIssue" />
            </label>{" "}
            <strong>
              {moment(invoiceDetailsToShow?.createdAt).format("DD MMMM YYYY")}
            </strong>
          </p>
          <p className="invoice-detail">
            <label>
              <I18n lng="Subscriptions.PaymentDueOn" />
            </label>
            <strong>
              {moment(invoiceDetailsToShow?.createdAt)
                .add(
                  1,
                  invoiceDetailsToShow?.type === "Monthly Subscription" ? "month" : "year"
                )
                .format("DD MMMM YYYY")}
            </strong>
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <div className="title-heading">
            <I18n lng="Subscriptions.For" />
          </div>
          <p>{companyDetails?.companyName}</p>
          <p>{companyDetails?.email}</p>
          <p>{companyDetails?.phone}</p>
          <p>
            <I18n lng="Subscriptions.TaxNo" />
            {companyDetails.taxNumber}
          </p>
          <p>{companyDetails?.address ? companyDetails?.address : ""}</p>
          <p>{companyDetails?.state ? companyDetails?.state : ""}</p>
          <p>
            {companyDetails?.city
              ? companyDetails?.city + " " + companyDetails?.postalCode
              : ""}
          </p>
          <p>{companyDetails?.country ? companyDetails?.country : ""}</p>
        </div>
      </div>
      <div className="title-heading mt-5">
        <I18n lng="Subscriptions.Summary" />
      </div>
      <hr className="mt-0 mb-0" />
      <div className="row mt-3">
        {invoiceDetailsToShow?.totalUsers ? (
          <div className="col-6">
            <p>
              <I18n lng="Subscriptions.Product" />
            </p>
            <p>
              <I18n lng="Subscriptions.SusbcriptionType" />
            </p>
            <p>
              <I18n lng="Subscriptions.UserSubscriptionFee" />
            </p>
            <p>
              <I18n lng="Subscriptions.Quantity" />
            </p>
            <p>
              <I18n lng="Subscriptions.TimePeriod" />
            </p>
            <p>
              <I18n lng="Subscriptions.StartFromDate" />
            </p>
            <p>
              <I18n lng="Subscriptions.EndToDate" />
            </p>
          </div>
        ) : (
          <div className="col-6">
            <p>
              <I18n lng="Subscriptions.Product" />
            </p>
            <p>
              <I18n lng="Subscriptions.Quantity" />
            </p>
          </div>
        )}
        {invoiceDetailsToShow?.totalUsers ? (
          <div className="col-6 text-right">
            <p>
              {CAPITALIZE_FIRST_LETTER(invoiceDetailsToShow?.name)}{" "}
              <I18n lng="Subscriptions.Package" />
            </p>
            <p>{CAPITALIZE_FIRST_LETTER(invoiceDetailsToShow?.type)}</p>
            <p>
              {invoiceDetailsToShow?.cost
                ? invoiceDetailsToShow?.cost.toFixed(2)
                : invoiceDetailsToShow?.cost}{" "}
              USD
            </p>
            <p>{invoiceDetailsToShow?.totalUsers} Seats</p>
            <p>
              1{" "}
              {invoiceDetailsToShow?.type === "Monthly Subscription"
                ? i18n("Subscriptions.Month")
                : i18n("Subscriptions.Year")}
            </p>
            <p>
              {moment(invoiceDetailsToShow?.createdAt).format("DD MMMM YYYY")}
            </p>
            <p>
              {moment(invoiceDetailsToShow?.createdAt)
                .add(
                  1,
                  invoiceDetailsToShow?.type === "Monthly Subscription" ? "month" : "year"
                )
                .format("DD MMMM YYYY")}
            </p>
          </div>
        ) : (
          <div className="col-6 text-right">
            <p>
              <I18n lng="Subscriptions.SmsBalance" />
            </p>
            <p>{invoiceDetailsToShow?.totalSms} SMS</p>
          </div>
        )}
      </div>
      <hr />
      <div className="row mt-3">
        <div className="col-6">
          <p>
            <strong>
              <I18n lng="Subscriptions.SubTotal" />
            </strong>
          </p>
          <p>
            <I18n lng="Subscriptions.Discount" />
          </p>
          <p>
            <strong>
              <I18n lng="Subscriptions.TotalBeforeTax" />
            </strong>
          </p>
          <p>Vat. 15%</p>
          <p>
            <strong>
              <I18n lng="Subscriptions.Total" />
            </strong>
          </p>
        </div>
        <div className="col-6 text-right">
          <p>
            <strong>
              {INVOICE_AMOUNT_CALCULATOR(invoiceDetailsToShow)} USD
            </strong>
          </p>
          <p>00.00 {paymentDetails?.currency}</p>
          <p>
            <strong>
              {INVOICE_AMOUNT_CALCULATOR(invoiceDetailsToShow)} USD
            </strong>
          </p>
          <p> {invoiceTax ? invoiceTax.toFixed(2) : invoiceTax} USD</p>
          <p>
            <strong>
              {INVOICE_AMOUNT_CALCULATOR(invoiceDetailsToShow, 15)}{" "}
            </strong>
          </p>
        </div>
      </div>

      <div className="qr-code">
        <QRCodeSVG size={150} value={paymentDetails?.invoice_id} />
      </div>
    </div>
  );
};

const mapStateToProps = ({ subscription }) => ({
  invoiceDetailsToShow: subscription.subscriptionDetailById,
});

const mapDispatchToProps = { getSubscriptionDetailById };

export default connect(mapStateToProps, mapDispatchToProps)(InvoicePdfView);
