import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.min.css";
import IconCloseDialog from "../../assets/images/icon-close-dialog.svg";
import { MdOutlineArrowBackIos } from "react-icons/md";

const ImageViewDialog = ({
  showDlg,
  imageSrc,
  imageIndex,
  secondaryAction,
}) => {
  const [show, setShow] = useState(false);
  const [activeImageIndex, setActiveImageIndex] = useState(0);

  useEffect(() => {
    setShow(showDlg);
  }, [showDlg]);

  useEffect(() => {
    if (imageIndex !== undefined) {
      setActiveImageIndex(imageIndex);
    }
  }, [imageIndex]);

  return (
    <Modal
      size={"lg"}
      isOpen={show}
      toggle={() => secondaryAction()}
      className={"modal-alert-dialog gallery-modal"}
    >
      <Link
        to="#"
        onClick={() => secondaryAction()}
        className="modal-close px-3 py-2"
      >
        <img src={IconCloseDialog} alt="IconCloseDialog" />
      </Link>
      <ModalBody>
        <div className="modal-title text-center">
          <div className="image-arrows-wrapper">
            <div className="arrow-image next">
              <MdOutlineArrowBackIos
                fill={
                  imageSrc?.length && activeImageIndex !== 0
                    ? "#20639B"
                    : "gray"
                }
                size={60}
                onClick={() => {
                  if (imageSrc?.length && activeImageIndex !== 0) {
                    setActiveImageIndex(activeImageIndex - 1);
                  }
                }}
              />
            </div>
            <div className="arrow-image back">
              <MdOutlineArrowBackIos
                size={60}
                fill={
                  imageSrc?.length && activeImageIndex !== imageSrc?.length - 1
                    ? "#20639B"
                    : "gray"
                }
                onClick={() => {
                  if (
                    imageSrc?.length &&
                    activeImageIndex !== imageSrc?.length - 1
                  ) {
                    setActiveImageIndex(activeImageIndex + 1);
                  }
                }}
              />
            </div>
          </div>

          {imageSrc[activeImageIndex] && imageSrc[activeImageIndex].image ? (
            <div>
              <InnerImageZoom
                src={imageSrc[activeImageIndex].image}
                zoomScale={1}
                startsActive={true}
                hideCloseButton={true}
                hideHint={true}
                moveType={"drag"}
                // width={300}
                // height={300}
              />
            </div>
          ) : (
            <div>
              <InnerImageZoom
                src={imageSrc}
                zoomScale={1}
                startsActive={true}
                hideCloseButton={true}
                hideHint={true}
                moveType={"drag"}
                // width={300}
                // height={300}
              />
            </div>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ImageViewDialog;
