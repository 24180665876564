import React, { useState } from "react";
import { Route, Switch } from "react-router-dom";
import OtherReportsView from "./other-reports-view";
import MyReportsDetail from "../my-reports/my-report-detail";
import MyReportsExportView from "../my-reports/my-reports-export/my-reports-export-view";
import OtherReportsEditFilterView from "./other-reports-edit-filter/other-reports-edit-filter-view";
import MyReportsHistory from "../my-reports/my-reports-history";

const otherReportfilterInitState = {
  fromDate: "",
  toDate: "",
  site: "",
  inspector: "",
  formName: "",
  status: "",
};

const OtherReportsPage = ({ reportUUid }) => {
  const [otherReportFilter, setOtherReportFilter] = useState(
    otherReportfilterInitState
  );
  return (
    <>
      <Switch>
        <Route path="/reports/other-reports/view/:id">
          {({ history, match }) => (
            <MyReportsDetail
              showDlg={match != null}
              secondaryAction={() => {
                history.push("/reports/other-reports");
              }}
              otherReportView={false}
              filterState={otherReportFilter}
            />
          )}
        </Route>
        <Route path="/reports/other-reports/others-view/:id">
          {({ history, match }) => (
            <MyReportsDetail
              showDlg={match != null}
              secondaryAction={() => {
                history.push("/reports/other-reports");
              }}
              otherReportView={true}
              filterState={otherReportFilter}
            />
          )}
        </Route>
        <Route path="/reports/other-reports/settings/edit/:id">
          {({ history, match }) => (
            <OtherReportsEditFilterView
              showDlg={match != null}
              secondaryAction={() => {
                history.push("/reports/other-reports");
              }}
              filterState={otherReportFilter}
            />
          )}
        </Route>
        <Route path={`/reports/other-reports/export/:id`}>
          {({ history, match }) => (
            <MyReportsExportView
              showDlg={match != null}
              secondaryAction={() => {
                history.push("/reports/other-reports");
              }}
              reportUUid={reportUUid}
              filterState={otherReportFilter}
            />
          )}
        </Route>
        <Route path="/reports/other-reports/view-history/:id">
          {({ history, match }) => (
            <MyReportsHistory
              showDlg={match != null}
              secondaryAction={() => {
                history.push("/reports/other-reports");
              }}
              filterState={otherReportFilter}
            />
          )}
        </Route>
      </Switch>
      <OtherReportsView
        otherReportFilter={otherReportFilter}
        setOtherReportFilter={setOtherReportFilter}
        otherReportfilterInitState={otherReportfilterInitState}
      />
    </>
  );
};

export default OtherReportsPage;
