import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { Modal, ModalBody } from "reactstrap";
import I18n from "../../../languageSelector";
import { Link, useParams } from "react-router-dom";
import IconDispatch from "../../../../../assets/images/icon-dispatch.svg";
import IconDelete from "../../../../../assets/images/icon-delete-red.svg";
import ReactSelect from "../../../../../components/form-input/React-Select";
import {
  getForm,
  deleteAssignUser,
  assignFormDispatch,
  getAssignUsersByFormId,
} from "../../../../../redux/reducers/form/form.reducer";
import { getAllUsers } from "../../../../../redux/reducers/user/user.reducer";

const reactSelectInitState = {
  dispatchTo: "",
};

const FormDispatch = ({
  show,
  getAllUsers,
  allUsers,
  getForm,
  assignFormDispatch,
  formForDispatch,
  currentRole,
  getAssignUsersByFormId,
  onHide,
  assignUsers,
  deleteAssignUser,
}) => {
  const { id } = useParams();

  const [filteredUsers, setFilteredUsers] = useState([]);

  useEffect(() => {
    if (currentRole !== "user" && show) {
      getAllUsers();
    }
    // eslint-disable-next-line
  }, [show]);

  useEffect(() => {
    if (id && currentRole !== "user") {
      getForm(id);
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (id && show && currentRole !== "user") {
      getAssignUsersByFormId(id);
    }
    // eslint-disable-next-line
  }, [id, show]);

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: reactSelectInitState,
  });

  useEffect(() => {
    if (allUsers?.length) {
      let filteredUsersToSet = allUsers.filter((item) => item.role);
      let gandaSet = [];
      if (assignUsers?.length) {
        for (let item of filteredUsersToSet) {
          let result = assignUsers.findIndex(
            (subItem) => item.id === subItem.assignee._id
          );
          if (result === -1) {
            gandaSet.push(item);
          }
        }
      } else {
        gandaSet = filteredUsersToSet;
      }
      if (gandaSet?.length) setFilteredUsers([...gandaSet]);
      else setFilteredUsers([]);
    }
  }, [allUsers, assignUsers]);

  const onSubmit = (data) => {
    let asignee = data?.dispatchTo?.length
      ? data.dispatchTo.map((item) => {
          return item.value;
        })
      : "";
    if (data && formForDispatch && asignee.length) {
      let formManagements = {
        formDetail: { ...formForDispatch.formDetail },
        formId: formForDispatch.id,
        assignee: [...asignee],
      };

      assignFormDispatch(formManagements)
        .then((res) => {
          getAssignUsersByFormId(id);
          reset({
            ...reactSelectInitState,
            dispatchTo: "",
          });
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message);
        });
    }
  };

  const dispatchDeleteFormHandler = (userId) => {
    if (id && userId) {
      deleteAssignUser(id, userId).then((res) => {
        getAssignUsersByFormId(id);
        getForm(id);
      });
    }
  };

  useEffect(() => {
    if (show) {
      reset({ ...reactSelectInitState, dispatchTo: "" });
    }
    // eslint-disable-next-line
  }, [show]);

  return (
    <>
      <Modal
        customClasses="modal-drawer "
        isOpen={show}
        toggle={onHide}
        size="sm"
      >
        <ModalBody>
          <form
            className="dispatch-wrapper text-center"
            onSubmit={handleSubmit(onSubmit)}
          >
            <ReactSelect
              label={<I18n lng="global.DispatchTo" />}
              placeholder={<I18n lng="global.Select" />}
              name="dispatchTo"
              register={register}
              validations={{}}
              control={control}
              options={
                filteredUsers?.length
                  ? filteredUsers.map((item) => {
                      return { value: item.id, label: item.fullName };
                    })
                  : []
              }
              isMulti={true}
              error={errors["dispatchTo"]}
            />

            <button className="btn btn-primary btn-icon" type="submit">
              <span className="icon-wrap">
                <img
                  src={IconDispatch}
                  alt="IconDispatch"
                  style={{ marginBottom: "-3px" }}
                ></img>
              </span>
              {<I18n lng="global.Dispatch" />}
            </button>
          </form>
          {assignUsers && assignUsers.length
            ? assignUsers.map((item, index) => {
                return (
                  <div
                    className="d-flex justify-content-center mt-3"
                    key={index}
                  >
                    <div className="mb-0 mt-3 mr-5">
                      {item?.assignee?.fullName}
                    </div>
                    <Link
                      to="#"
                      className="btn-link "
                      onClick={(e) => {
                        e.preventDefault();
                        dispatchDeleteFormHandler(item?.assignee?._id);
                      }}
                    >
                      <img src={IconDelete} alt="IconDelete" />
                    </Link>
                  </div>
                );
              })
            : null}
        </ModalBody>
      </Modal>
    </>
  );
};

const mapStateToProps = ({ authentication, user, form }) => ({
  user: authentication.user,
  currentRole: authentication.currentRole,
  allUsers: user.allUsers,
  formForDispatch: form.formToEdit,
  assignUsers: form.assignUsers,
});

const mapDispatchToProps = {
  getAllUsers,
  getForm,
  assignFormDispatch,
  getAssignUsersByFormId,
  deleteAssignUser,
};
export default connect(mapStateToProps, mapDispatchToProps)(FormDispatch);
