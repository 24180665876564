import React, { useEffect, useState } from "react";
import moment from "moment";
import { connect } from "react-redux";
import I18n from "../../../languageSelector";
import FormDispatchRule from "./form-dispatch-rule";
import DynamicModal from "../../../../../components/dialogs";
import IconPencil from "../../../../../assets/images/icon-pencil.svg";
import IconCheckMark from "../../../../../assets/images/checkmark.svg";
import IconArrowDown from "../../../../../assets/images/arrow-down.svg";
import IconDocBoard from "../../../../../assets/images/icon-doc-board.svg";
import { getAllUsers } from "../../../../../redux/reducers/user/user.reducer";
import {
  getForm,
  getAssignUsersByFormId,
} from "../../../../../redux/reducers/form/form.reducer";
import { canAccessTheModule } from "../../../../../helper/helperFunctions";

const FormDispatchDialog = ({
  show,
  onHide,
  formId,
  getForm,
  roleAccess,
  getAllUsers,
  currentRole,
  formForDispatch,
  getAssignUsersByFormId,
}) => {
  const [edit, setEdit] = useState(false);
  const [ruleForEdit, setRuleForEdit] = useState();
  const [dispatchedUsers, setDispatchedUsers] = useState([]);
  const [dispatchRules, setDispatchRules] = useState([]);
  const [dispatchRuleDialog, setDispatchRuleDialog] = useState(false);

  useEffect(() => {
    if (formId && show && currentRole !== "user") {
      getForm(formId);
      getAssignUsersByFormId(formId);
    }
    if (currentRole !== "user" && show) {
      getAllUsers();
    }

    // eslint-disable-next-line
  }, [currentRole, formId, show]);

  useEffect(() => {
    let users = [];
    let filteredRules = [];
    if (formForDispatch && formForDispatch.dispatchRules?.length) {
      formForDispatch.dispatchRules.map((item) => {
        if (item.isRemoved === true) {
          return users;
        } else {
          return item?.isHide ? null : users.push(...item?.assignees);
        }
      });
      filteredRules = formForDispatch.dispatchRules.filter((item) => {
        return !item?.isHide;
      });
    }
    setDispatchRules([...filteredRules]);
    setDispatchedUsers(users);
  }, [formForDispatch]);

  return (
    <>
      <DynamicModal
        show={show}
        secondaryAction={onHide}
        modalContentClass="bg-white"
        customClasses="modal-drawer user-profile-drawer"
      >
        <div className="notification">
          <div className="form-view-wrapper dispatch-card">
            <form className="dispatch-wrapper text-center">
              <div className="form-header">
                <div className="form-title">
                  <strong>
                    <img src={IconDocBoard} alt="Doc Board" />{" "}
                    {formForDispatch?.formDetail?.title}
                  </strong>
                </div>
              </div>
            </form>
          </div>
          {dispatchRules.length ? (
            <div>
              {dispatchRules.map((item, index) => {
                return item?.managers?.length > 0 &&
                  item?.assignees?.length > 0 &&
                  item?.isRemoved === false ? (
                  <div className="card dispatch-card mt-2" key={index}>
                    <h6 className="d-flex justify-content-between">
                      <div>
                        <I18n lng="Form.DispatchRuleNo" /> {index + 1}
                      </div>
                      {canAccessTheModule(
                        roleAccess?.form?.access?.formDispatch,
                        <div
                          className="cursor-pointer"
                          onClick={() => {
                            setEdit(true);
                            setRuleForEdit(item);
                            setDispatchRuleDialog(true);
                          }}
                        >
                          <img src={IconPencil} alt="loading" />
                        </div>
                      )}
                    </h6>
                    <span>
                      {" "}
                      <I18n lng="Notifications.By" />{" "}
                      {item?.createdBy?.fullName}
                    </span>
                    <div className="text-muted">
                      <I18n lng="Form.LastUpdate" />{" "}
                      {moment(item?.updatedAt).format("LLL")}{" "}
                      <I18n lng="Notifications.By" />{" "}
                      {item?.updatedBy?.fullName}
                    </div>
                    <div className="card user-card mt-2">
                      <div className="manager text-center">
                        <b>
                          <I18n lng="Form.InspectorsWithAccessToThisForm" />
                        </b>
                      </div>
                      {item?.assignees?.length
                        ? item?.assignees?.map((user, index) => {
                            return (
                              <div className="mt-1 text-center" key={index}>
                                {user?.fullName}
                              </div>
                            );
                          })
                        : null}
                    </div>
                    <div className="text-center">
                      <img src={IconArrowDown} alt="loading" />
                    </div>
                    <div className="card user-card mt-2">
                      <div className="inspector text-center">
                        <b>
                          <I18n lng="Form.ManagersWithAccessToResultingReport" />
                        </b>
                      </div>
                      {item?.managers?.length
                        ? item?.managers?.map((manager, index) => {
                            return (
                              <span
                                className="mt-2 mb-2 text-center"
                                key={index}
                              >
                                {item?.approvedManagers?.find((subItem) =>
                                  subItem?.id === manager?.id ? true : false
                                ) ? (
                                  <img
                                    width={"22px"}
                                    src={IconCheckMark}
                                    alt="loading"
                                  />
                                ) : null}
                                <span className="mt-1 ml-1 text-center">
                                  {manager?.fullName}
                                </span>
                              </span>
                            );
                          })
                        : null}
                    </div>
                  </div>
                ) : null;
              })}
            </div>
          ) : (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "80%" }}
            >
              <div className="text-center">
                <p className="text-muted">
                  <I18n lng="Form.ThisFormIsNotDispatchedYet" /> <br /> <br />{" "}
                  <I18n lng="Form.Click" />{" "}
                  <b style={{ color: "#6d98be" }}>
                    <I18n lng="Form.AddNewDispatch" />
                  </b>{" "}
                  <I18n lng="Form.BelowTo" />
                  <br />
                  <I18n lng="Form.CreateANewDispatch" />
                </p>
              </div>
            </div>
          )}
        </div>
        <div className="text-center">
          {canAccessTheModule(
            roleAccess?.form?.access?.formDispatch,
            <button
              className="btn btn-primary btn-icon mt-3 btn-bar"
              onClick={() => {
                setEdit(false);
                setRuleForEdit(undefined);
                setDispatchRuleDialog(true);
              }}
            >
              <I18n lng="Form.AddNewDispatch" />
            </button>
          )}
        </div>
      </DynamicModal>
      <FormDispatchRule
        allDispatchRules={dispatchRules}
        edit={edit}
        formId={formId}
        ruleForEdit={ruleForEdit}
        show={dispatchRuleDialog}
        dispatchedUsers={dispatchedUsers}
        onHide={() => setDispatchRuleDialog(false)}
      />
    </>
  );
};

const mapStateToProps = ({ authentication, form }) => ({
  user: authentication.user,
  formForDispatch: form.formToEdit,
  currentRole: authentication.currentRole,
  roleAccess: authentication.userRoleAccess,
});

const mapDispatchToProps = {
  getForm,
  getAllUsers,
  getAssignUsersByFormId,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormDispatchDialog);
