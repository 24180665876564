import React, { Fragment } from "react";
import { TEXT_DIRECTION } from "../../../helper/helperFunctions";
import IconArrowDown from "../../../assets/images/arrowDown.svg";
import { Collapse } from "reactstrap";
import I18n from "../languageSelector";

const ReportTableOfContent = ({
  hideAndShow,
  handleScroll,
  reportForEdit,
  reportTableContent,
  setReportTableContent,
}) => {
  return (
    <div>
      {reportForEdit && hideAndShow?.content?.tableOfContents ? (
        <div
          className="heading-top"
          style={{ borderRadius: "12px", marginTop: "13px" }}
        >
          <div className="report-table fail-summary">
            <div className="report-header">
              <div className="report-table">
                <div
                  className="table mb-1"
                  style={{
                    border: "#FFFFFF",
                    boxShadow: "1px 5px 2px rgba(0,0,0,.10)",
                  }}
                >
                  <div className="failed-summary">
                    <div className="table-header">
                      <div className="table-row">
                        <div
                          className="table-head"
                          style={{
                            padding: "0.25rem 1.5rem",
                            borderRadius: "0",
                          }}
                        >
                          <div className="my-1 d-flex justify-content-between w-100 ">
                            <b
                              className={
                                TEXT_DIRECTION() === "rtl" ? "ml-3" : "mr-3"
                              }
                            >
                              <I18n lng="Reports.TableOfContents" />
                            </b>
                            <div
                              onClick={() => {
                                reportTableContent &&
                                reportTableContent === true
                                  ? setReportTableContent(false)
                                  : setReportTableContent(true);
                              }}
                              className="text-right align-end "
                            >
                              <img
                                style={
                                  reportTableContent === true
                                    ? {
                                        margin: "0 15px 5px 15px",
                                        width: "25px",
                                        cursor: "pointer",
                                      }
                                    : {
                                        margin: "0 15px 5px 15px",
                                        width: "25px",
                                        cursor: "pointer",
                                        rotate: "180deg",
                                      }
                                }
                                src={IconArrowDown}
                                alt="arrow Down"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="fail-summaryBody">
                    <Collapse isOpen={reportTableContent}>
                      <div className="report-data card pb-2">
                        {reportForEdit &&
                        reportForEdit?.formDetail?.schema?.pages?.length
                          ? reportForEdit?.formDetail?.schema?.pages?.map(
                              (page, index) => {
                                return (
                                  <Fragment key={index}>
                                    <div
                                      className="px-4 pt-2 d-flex"
                                      onClick={() =>
                                        handleScroll(page?.pageUuid)
                                      }
                                    >
                                      <h4
                                        style={{
                                          fontSize: "18px",
                                          paddingBottom: "5px",
                                          whiteSpace: "nowrap",
                                        }}
                                        className="cursor-pointer"
                                      >
                                        {page?.name === "Page"
                                          ? page?.pagePlaceHolder
                                          : page?.name}
                                      </h4>
                                      <hr
                                        className="m-2"
                                        style={{
                                          display: "inline-block",
                                          width: "100%",
                                          border: "2px solid #000",
                                          borderStyle: "dotted",
                                        }}
                                      />
                                    </div>
                                    {page.sections && page.sections.length
                                      ? page.sections.map((section) => {
                                          return (
                                            <Fragment key={index}>
                                              <div
                                                className="px-5 pt-2 pb-1 d-flex"
                                                onClick={() =>
                                                  handleScroll(
                                                    section?.sectionUuid
                                                  )
                                                }
                                              >
                                                <h5
                                                  style={{
                                                    fontSize: "18px",
                                                    paddingBottom: "5px",
                                                    whiteSpace: "nowrap",
                                                  }}
                                                  className="cursor-pointer"
                                                >
                                                  {section?.name === "Section"
                                                    ? section?.sectionsPlaceHolder
                                                      ? section?.sectionsPlaceHolder
                                                      : section?.sectionPlaceHolder
                                                    : section?.name}
                                                </h5>
                                                <hr
                                                  className="m-2"
                                                  style={{
                                                    display: "inline-block",
                                                    width: "100%",
                                                    border: "2px solid #000",
                                                    borderStyle: "dotted",
                                                  }}
                                                />
                                              </div>
                                            </Fragment>
                                          );
                                        })
                                      : null}
                                  </Fragment>
                                );
                              }
                            )
                          : null}
                      </div>
                    </Collapse>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ReportTableOfContent;
