import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./global-list-ui-helper";

const GlobalListUIContext = createContext();

export function useGlobalListUIContext() {
  return useContext(GlobalListUIContext);
}

export const GlobalListUIConsumer = GlobalListUIContext.Consumer;

export function GlobalListUIProvider({ globalListUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initInvoice = {
    id: undefined,
    englishName: "",
    arabicName: "",
    price: 0.0,
    catagory: "",
    serviceIsActive: "",
    serviceIsTaxable: "",
    englishDetails: "",
    arabicDetails: "",
    detailedInvoice: "",
    paymentDate: "",
    barcode: "",
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initInvoice,
    openGlobalListNew: globalListUIEvents.openGlobalListNew,
    openGlobalListEdit: globalListUIEvents.openGlobalListEdit,
    openGlobalListDelete: globalListUIEvents.openGlobalListDelete,
    openGlobalListDuplicate: globalListUIEvents.openGlobalListDuplicate,
  };

  return (
    <GlobalListUIContext.Provider value={value}>
      {children}
    </GlobalListUIContext.Provider>
  );
}
