import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import cloneDeep from "lodash/cloneDeep";
import { CustomInput } from "reactstrap";
import I18n, { i18n } from "../../../languageSelector";
import IconRestore from "../../../../../assets/images/restore-roles.svg";
import {
  MANAGER_PORTAL_OPTIONS_DEFAULT,
  INSPECTION_PORTAL_OPTIONS_DEFAULT,
  MANAGER_PORTAL_OPTIONS_OWNER,
  MANAGER_PORTAL_OPTIONS_MANAGER,
  MANAGER_PORTAL_OPTIONS_INSPECTOR,
  INSPECTION_PORTAL_OPTIONS_INSPECTOR,
  INSPECTION_PORTAL_OPTIONS_MANAGER,
  INSPECTION_PORTAL_OPTIONS_OWNER,
  MANAGER_PORTAL_OPTIONS_ADMIN,
  INSPECTION_PORTAL_OPTIONS_ADMIN,
} from "./RolesConstants";
import DynamicModal from "../../../../../components/dialogs";
import Input from "../../../../../components/form-input/input.component";
import CheckBox from "../../../../../components/form-input/checkbox.component";
import { createRole, getAllRoles, updateRoleById } from "../../../../../redux/reducers/roles/roles.reducer";
import AlertSigupDialog from "../../../../../components/dialogs/alertSignupDialog";
import { CAPITALIZE_FIRST_LETTER, TEXT_DIRECTION } from "../../../../../helper/helperFunctions";
import { GET_ROLES_NAME } from "../../../../../helper/formHelperFunctions";
import { getSubscriptionByCompanyId } from "../../../../../redux/reducers/subscription/subscription.reducer";

const RolesEditDialog = ({
  show,
  onHide,
  edit,
  createRole,
  roleForEdit,
  getAllRoles,
  updateRoleById,
  user,
  getSubscriptionByCompanyId,
  subscriptionData,
}) => {
  const [roleName, setRoleName] = useState("");
  const [showUpdateDialog, setShowUpdateDialog] = useState(false);
  const [showResetDialog, setShowResetDialog] = useState(false);
  const [showManagerPortal, setShowManagerPortal] = useState(false);
  const [showInspectorPortal, setShowInspectorPortal] = useState(false);
  const [managerPortalOptions, setManagerPortalOptions] = useState([...cloneDeep(MANAGER_PORTAL_OPTIONS_DEFAULT)]);
  const [inspectionPortalOptions, setInspectionPortalOptions] = useState([...cloneDeep(INSPECTION_PORTAL_OPTIONS_DEFAULT)]);

  useEffect(() => {
    if (user?.company) {
      getSubscriptionByCompanyId(user?.company?.id);
    }
  }, [user?.company, getSubscriptionByCompanyId]);

  useEffect(() => {
    if (edit && roleForEdit) {
      setRoleName(roleForEdit?.name);
      setManagerPortalOptions([...cloneDeep(roleForEdit?.managerPortal)]);
      setInspectionPortalOptions([...cloneDeep(roleForEdit?.inspectorPortal)]);
      setShowManagerPortal(roleForEdit?.managerRole);
      setShowInspectorPortal(roleForEdit?.inspectorRole);
    } else {
      setRoleName("");
      setManagerPortalOptions([...cloneDeep(MANAGER_PORTAL_OPTIONS_DEFAULT)]);
      setInspectionPortalOptions([...cloneDeep(INSPECTION_PORTAL_OPTIONS_DEFAULT)]);
      setShowInspectorPortal(false);
      setShowManagerPortal(false);
    }
  }, [show, edit, roleForEdit]);

  const roleCreateHandler = () => {
    if (roleName !== "") {
      if (edit) {
        // Editing an existing role
        setShowUpdateDialog(true);
      } else if (!edit) {
        // Creating a new role

        let newRole = {
          name: roleName,
          originalName: roleName,
          managerPortal: [...managerPortalOptions],
          inspectorPortal: [...inspectionPortalOptions],
          managerRole: showManagerPortal,
          inspectorRole: showInspectorPortal,
        };
        createRole(newRole).then((res) => {
          getAllRoles(10);
          onHide();
          toast.success(i18n("Notifications.RoleCreated"));
        });
      }
    } else {
      toast.error(i18n("Company.EnterRoleName"));
    }
  };

  const roleUpdateHandler = () => {
    let roleData = {
      name: roleName,
      managerPortal: [...managerPortalOptions],
      inspectorPortal: [...inspectionPortalOptions],
      managerRole: showManagerPortal,
      inspectorRole: showInspectorPortal,
    };
    updateRoleById(roleForEdit?.id, roleData).then((res) => {
      getAllRoles(10);
      onHide();
      setShowUpdateDialog(false);
      toast.success(i18n("Notifications.RoleUpdated"));
    });
  };
  const resetDefaultHandler = () => {
    if (roleForEdit.originalName === "owner") {
      setShowManagerPortal(true);
      setShowInspectorPortal(true);
      setRoleName(roleForEdit?.originalName);
      setManagerPortalOptions([...cloneDeep(MANAGER_PORTAL_OPTIONS_OWNER)]);
      setInspectionPortalOptions([...cloneDeep(INSPECTION_PORTAL_OPTIONS_OWNER)]);
    } else if (roleForEdit.originalName === "manager") {
      setShowManagerPortal(true);
      setShowInspectorPortal(false);
      setRoleName(roleForEdit?.originalName);
      setManagerPortalOptions([...cloneDeep(MANAGER_PORTAL_OPTIONS_MANAGER)]);
      setInspectionPortalOptions([...cloneDeep(INSPECTION_PORTAL_OPTIONS_MANAGER)]);
    } else if (roleForEdit.originalName === "inspector") {
      setShowManagerPortal(false);
      setShowInspectorPortal(true);
      setRoleName(roleForEdit?.originalName);
      setManagerPortalOptions([...cloneDeep(MANAGER_PORTAL_OPTIONS_INSPECTOR)]);
      setInspectionPortalOptions([...cloneDeep(INSPECTION_PORTAL_OPTIONS_INSPECTOR)]);
    } else if (roleForEdit.originalName === "admin") {
      setShowManagerPortal(true);
      setShowInspectorPortal(true);
      setRoleName(roleForEdit?.originalName);
      setManagerPortalOptions([...cloneDeep(MANAGER_PORTAL_OPTIONS_ADMIN)]);
      setInspectionPortalOptions([...cloneDeep(INSPECTION_PORTAL_OPTIONS_ADMIN)]);
    }
    setShowResetDialog(false);
  };

  const handleManagerOuterOptions = (e, item, index) => {
    let newOption;
    newOption = {
      ...item,
      selected: e.target.checked,
    };
    managerPortalOptions[index] = newOption;
    let updated = managerPortalOptions[index]?.subItem?.map((item) => {
      return { ...item, selected: false };
    });
    managerPortalOptions[index].subItem = updated;
    setManagerPortalOptions([...managerPortalOptions]);
  };

  const handleManagerInnerOptions = (e, item, ind) => {
    item.subItem[ind] = {
      ...item.subItem[ind],
      selected: e.target.checked,
    };
    setManagerPortalOptions([...managerPortalOptions]);
  };

  const handleInspectionOuterOptions = (e, item, index) => {
    let newOption;
    newOption = {
      ...item,
      selected: e.target.checked,
    };
    inspectionPortalOptions[index] = newOption;
    let updated = inspectionPortalOptions[index]?.subItem?.map((item) => {
      return { ...item, selected: false };
    });
    inspectionPortalOptions[index].subItem = updated;
    setInspectionPortalOptions([...inspectionPortalOptions]);
  };

  const handleInspectionInnerOptions = (e, item, ind) => {
    item.subItem[ind] = {
      ...item.subItem[ind],
      selected: e.target.checked,
    };
    setInspectionPortalOptions([...inspectionPortalOptions]);
  };

  return (
    <>
      <DynamicModal show={show} secondaryAction={onHide} modalContentClass="bg-white" customClasses="modal-drawer custom-roles-drawer">
        <div className="roles-wrapper">
          <div
            className="card dispatch-card mb-2"
            style={subscriptionData?.currentPlan?.plan === "Free" || roleForEdit?.originalName === "guest" ? { pointerEvents: "none" } : {}}
          >
            <Input
              label={i18n("Company.RoleName")}
              id="emailInput"
              name="email"
              type="email"
              placeholder={i18n("Company.RoleName")}
              value={
                roleName === "owner"
                  ? i18n("Roles.owner")
                  : roleName === "manager"
                  ? i18n("Roles.manager")
                  : roleName === "inspector"
                  ? i18n("Roles.inspector")
                  : roleName === "admin"
                  ? i18n("Roles.admin")
                  : roleName === "guest"
                  ? i18n("Roles.guest")
                  : roleName
              }
              onChange={(e) => setRoleName(e.target.value)}
            />
          </div>
          <div className="card dispatch-card mb-2">
            <div className="row mr-0 mt-2 testing">
              <div className="col-10">
                <b>
                  <I18n lng="Company.ManagerPortal" />
                </b>
              </div>
              <div className={`${roleForEdit.originalName === "owner" || subscriptionData?.currentPlan?.plan === "Free" ? "custom-Toggler" : ""}`}>
                <CustomInput
                  className="font-weight-bold"
                  type="switch"
                  id="manager"
                  name="roles"
                  checked={roleForEdit.originalName === "guest" ? false : showManagerPortal}
                  onChange={() => setShowManagerPortal(!showManagerPortal)}
                  disabled={
                    subscriptionData?.currentPlan?.plan === "Free" || roleForEdit.originalName === "owner" || roleForEdit.originalName === "guest"
                  }
                />
              </div>
            </div>
            <p className="text-muted mb-0">
              <I18n lng="Company.UsersCanLogInWeb" />
            </p>

            {roleForEdit.originalName !== "guest" &&
              showManagerPortal &&
              managerPortalOptions.map((item, index) => {
                return (
                  <div key={index} className="mt-4">
                    <div className="row">
                      <div className={`${TEXT_DIRECTION() === "rtl" ? "col-1 mr-3 p-0 " : "col-1 ml-3 p-0"}`}>
                        <CheckBox
                          label
                          name={item.name + index}
                          checked={item.selected}
                          onChange={(e) => {
                            handleManagerOuterOptions(e, item, index);
                          }}
                          disabled={subscriptionData?.currentPlan?.plan === "Free" ? true : item?.disabled}
                          className={
                            item?.disabled
                              ? "custom-control custom-control-disabled custom-checkbox"
                              : subscriptionData?.currentPlan?.plan === "Free"
                              ? "custom-control custom-control-disabled custom-checkbox"
                              : "custom-control custom-checkbox"
                          }
                        />
                      </div>
                      <div className="col-8 p-0">
                        <label>{GET_ROLES_NAME(item?.name)}</label>
                      </div>
                    </div>

                    {item.selected &&
                      item?.subItem?.length &&
                      item.subItem.map((subItem, ind) => {
                        return (
                          <div className="ml-3" key={ind}>
                            <div className="row">
                              <div className={`${TEXT_DIRECTION() === "rtl" ? "col-1 mr-4 p-0 " : "col-1 ml-4 p-0"}`}>
                                <CheckBox
                                  label
                                  name={subItem.name + ind}
                                  checked={
                                    subscriptionData?.currentPlan?.plan === "Free" && subItem?.name === "Manage roles" ? false : subItem.selected
                                  }
                                  onChange={(e) => {
                                    handleManagerInnerOptions(e, item, ind);
                                  }}
                                  disabled={subscriptionData?.currentPlan?.plan === "Free" ? true : item?.disabled}
                                  className={
                                    item?.disabled
                                      ? "custom-control custom-control-disabled custom-checkbox"
                                      : subscriptionData?.currentPlan?.plan === "Free"
                                      ? "custom-control custom-control-disabled custom-checkbox"
                                      : "custom-control custom-checkbox"
                                  }
                                />
                              </div>
                              <div className="col-8 p-0">
                                <label>{GET_ROLES_NAME(CAPITALIZE_FIRST_LETTER(subItem?.name))}</label>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                );
              })}
          </div>
          <div className="card dispatch-card mb-3">
            <div className="row mr-0 mt-2">
              <div className="col-10">
                <b>
                  <I18n lng="Company.InspectionPortal" />
                </b>
              </div>
              <div className={`${roleForEdit.originalName === "owner" || subscriptionData?.currentPlan?.plan === "Free" ? "custom-Toggler" : ""}`}>
                <CustomInput
                  className="font-weight-bold"
                  type="switch"
                  id="inspection"
                  name="roles"
                  checked={roleForEdit.originalName === "guest" ? false : showInspectorPortal}
                  onChange={() => setShowInspectorPortal(!showInspectorPortal)}
                  disabled={
                    roleForEdit.originalName === "owner" || roleForEdit.originalName === "guest" || subscriptionData?.currentPlan?.plan === "Free"
                  }
                />
              </div>
            </div>
            <p className="text-muted mb-0">
              <I18n lng="Company.UsersCanLogInWebInspector" />
            </p>

            {roleForEdit.originalName !== "guest" &&
              showInspectorPortal &&
              inspectionPortalOptions?.map((item, index) => {
                return (
                  <>
                    <div key={index} className="mt-4">
                      <div className="row">
                        <div className={`${TEXT_DIRECTION() === "rtl" ? "col-1 mr-3 p-0 " : "col-1 ml-3 p-0"}`}>
                          <CheckBox
                            label
                            name={item?.name + index}
                            checked={item?.selected}
                            onChange={(e) => {
                              handleInspectionOuterOptions(e, item, index);
                            }}
                            disabled={subscriptionData?.currentPlan?.plan === "Free" ? true : item?.disabled}
                            className={
                              item?.disabled
                                ? "custom-control custom-control-disabled custom-checkbox"
                                : subscriptionData?.currentPlan?.plan === "Free"
                                ? "custom-control custom-control-disabled custom-checkbox"
                                : "custom-control custom-checkbox"
                            }
                          />
                        </div>
                        <div className="col-8 p-0">
                          <label>{GET_ROLES_NAME(item?.name)}</label>
                        </div>
                      </div>
                    </div>
                    <div>
                      {item?.selected &&
                        item?.subItem?.length &&
                        item?.subItem?.map((subItem, ind) => {
                          return (
                            <div className="ml-3" key={ind}>
                              <div className="row">
                                <div className={`${TEXT_DIRECTION() === "rtl" ? "col-1 mr-4 p-0 " : "col-1 ml-4 p-0"}`}>
                                  <CheckBox
                                    label
                                    name={subItem?.name + ind}
                                    checked={subItem?.selected}
                                    onChange={(e) => {
                                      handleInspectionInnerOptions(e, item, ind);
                                    }}
                                    disabled={subscriptionData?.currentPlan?.plan === "Free" ? true : item?.disabled}
                                    className={
                                      item?.disabled
                                        ? "custom-control custom-control-disabled custom-checkbox"
                                        : subscriptionData?.currentPlan?.plan === "Free"
                                        ? "custom-control custom-control-disabled custom-checkbox"
                                        : "custom-control custom-checkbox"
                                    }
                                  />
                                </div>
                                <div className="col-8 p-0">
                                  <label>{GET_ROLES_NAME(CAPITALIZE_FIRST_LETTER(subItem?.name))}</label>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </>
                );
              })}
          </div>
          {roleForEdit?.originalName === "owner" ||
          roleForEdit?.originalName === "manager" ||
          roleForEdit?.originalName === "guest" ||
          roleForEdit?.originalName === "admin" ||
          roleForEdit?.originalName === "inspector" ? (
            <div
              className="card dispatch-card mb-2"
              style={subscriptionData?.currentPlan?.plan === "Free" || roleForEdit?.originalName === "guest" ? { pointerEvents: "none" } : {}}
            >
              <div className="row mr-0 ml-2 mt-2 mb-2 cursor-pointer" onClick={() => setShowResetDialog(true)}>
                <img width="20px" src={IconRestore} alt="loading" />
                <span className="ml-2">
                  {/* <I18n lng="Company.ResetAllToDefault" />{" "} */}
                  {roleForEdit.originalName ? <I18n lng="Company.ResetAllToDefault" /> : ""}
                </span>
              </div>
            </div>
          ) : null}
        </div>
        <div className="text-center btn-bar">
          <button className="btn btn-white btn-icon p-2 mt-3" onClick={() => onHide()}>
            <I18n lng="global.Cancel" />
          </button>
          <button className="btn btn-primary btn-icon p-2 mt-3 ml-3 mr-3" onClick={() => roleCreateHandler()}>
            <I18n lng="global.Save" />
          </button>
        </div>
      </DynamicModal>
      <AlertSigupDialog
        showDlg={showUpdateDialog}
        secondaryAction={() => setShowUpdateDialog(false)}
        primaryAction={() => roleUpdateHandler()}
        classes="modal-alert"
        secondryActionTitle={i18n("global.Cancel")}
        secondryActionClasses={"btn-white"}
        primaryActionTitle={i18n("global.Confirm")}
        primaryActionClasses={"btn-primary"}
        content={
          <p>
            <br />
            <br />
            <h5 style={{ color: "#20639b" }}>
              <I18n lng="Company.ConfirmChanges" />
            </h5>
            <span>
              <I18n lng="Company.YouHaveMadeSomeChanges" /> <b>“{roleForEdit?.name}”</b> <I18n lng="Company.SaveChanges" />
            </span>
          </p>
        }
      />
      <AlertSigupDialog
        showDlg={showResetDialog}
        secondaryAction={() => setShowResetDialog(false)}
        primaryAction={() => resetDefaultHandler()}
        classes="modal-alert"
        secondryActionTitle={i18n("global.Cancel")}
        secondryActionClasses={"btn-white"}
        primaryActionTitle={i18n("global.Confirm")}
        primaryActionClasses={"btn-primary"}
        content={
          <p>
            <br />
            <br />
            <h5 style={{ color: "#20639b" }}>
              <I18n lng="Company.ConfirmResetToDefault" />
            </h5>
            <span>
              <I18n lng="Company.ResetToDefault" />
            </span>
          </p>
        }
      />
    </>
  );
};

const mapStateToProps = ({ authentication, subscription }) => ({
  user: authentication.user,
  subscriptionData: subscription.subscriptionData,
});
const mapDispatchToProps = {
  createRole,
  getAllRoles,
  updateRoleById,
  getSubscriptionByCompanyId,
};
export default connect(mapStateToProps, mapDispatchToProps)(RolesEditDialog);
