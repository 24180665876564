import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import IconSearch from "../../../../assets/images/icon-search.svg";
import IconSortAlpha from "../../../../assets/images/icon-sort-alpha.svg";
import IconFilter from "../../../../assets/images/icon-filter.svg";
import ReportsList from "./archieve-reports-list-components/list-item";
import { connect } from "react-redux";
import { getAdminManagerReports } from "../../../../redux/reducers/admin-manager-reports/admin-manager-report.reducer";
import {
  FaSortAlphaDown,
  FaSortAlphaUp,
  FaSortNumericUpAlt,
  FaSortNumericDownAlt,
} from "react-icons/fa";
import I18n, { i18n } from "../../../private/languageSelector";
import DialogModal from "../../../../components/dialogs/compact-dialog";
import FilterDialog from "../reportFilterDialog";

const archiveReportfilterInitState = {
  fromDate: "",
  toDate: "",
  site: "",
  inspector: "",
  formName: "",
  status: "",
};
const ArchiveReportsView = ({ getAdminManagerReports, allReports }) => {
  const [archiveReportFilter, setArchiveReportFilter] = useState(
    archiveReportfilterInitState
  );
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [sortBy, setSortBy] = useState({
    attribute: "FORM_NAME",
    order: "ASC",
  });
  const [showSortModal, setShowSortModal] = useState(false);

  useEffect(() => {
    getAdminManagerReports(true);
    // eslint-disable-next-line
  }, []);

  const searchSortFilter = (reportsList) => {
    const activeForms = reportsList?.filter(
      (item) => item?.isArchived === true
    );
    if (reportsList?.length) {
      let submitForms = activeForms?.filter((item) => item.status === "submit");
      let acceptedOrRejected = activeForms?.filter(
        (item) => item.status !== "submit"
      );

      if (submitForms?.length) {
        if (searchValue) {
          submitForms = [
            ...submitForms.filter((item) =>
              item.formDetail.title
                .toLowerCase()
                .includes(searchValue.toLowerCase())
            ),
          ];
        }

        if (sortBy) {
          submitForms = [
            ...submitForms.sort(function (a, b) {
              if (sortBy.attribute === "FORM_NAME") {
                return (
                  (a.formDetail.title === b.formDetail.title
                    ? 0
                    : a.formDetail.title < b.formDetail.title
                    ? -1
                    : 1) * (sortBy.order === "ASC" ? 1 : -1)
                );
              } else if (sortBy.attribute === "DATE_CREATED") {
                return (
                  (a.createdAt.toString() === b.createdAt.toString()
                    ? 0
                    : a.createdAt.toString() < b.createdAt.toString()
                    ? -1
                    : 1) * (sortBy.order === "ASC" ? 1 : -1)
                );
              } else {
                return (
                  (a.updatedAt.toString() === b.updatedAt.toString()
                    ? 0
                    : a.updatedAt.toString() < b.updatedAt.toString()
                    ? -1
                    : 1) * (sortBy.order === "ASC" ? 1 : -1)
                );
              }
            }),
          ];
        }
      }

      if (acceptedOrRejected?.length) {
        if (searchValue) {
          acceptedOrRejected = [
            ...acceptedOrRejected.filter((item) =>
              item.formDetail.title
                .toLowerCase()
                .includes(searchValue.toLowerCase())
            ),
          ];
        }
        if (sortBy) {
          acceptedOrRejected = [
            ...acceptedOrRejected.sort(function (a, b) {
              if (sortBy.attribute === "FORM_NAME") {
                return (
                  (a.formDetail.title === b.formDetail.title
                    ? 0
                    : a.formDetail.title < b.formDetail.title
                    ? -1
                    : 1) * (sortBy.order === "ASC" ? 1 : -1)
                );
              } else if (sortBy.attribute === "DATE_CREATED") {
                return (
                  (a.createdAt.toString() === b.createdAt.toString()
                    ? 0
                    : a.createdAt.toString() < b.createdAt.toString()
                    ? -1
                    : 1) * (sortBy.order === "ASC" ? 1 : -1)
                );
              } else {
                return (
                  (a.updatedAt.toString() === b.updatedAt.toString()
                    ? 0
                    : a.updatedAt.toString() < b.updatedAt.toString()
                    ? -1
                    : 1) * (sortBy.order === "ASC" ? 1 : -1)
                );
              }
            }),
          ];
        }
      }

      return [...submitForms, ...acceptedOrRejected];
    } else {
      return [];
    }
  };
  const archiveReportFilterHandler = () => {
    getAdminManagerReports(true, archiveReportFilter);
  };
  return (
    <>
      <div className="px-3">
        <div className="search-bar">
          <form className="form-search">
            <div className="input-group">
              <div className="input-group-prepend">
                <img src={IconSearch} alt="Search Icon" />
              </div>
              <input
                className="form-control"
                placeholder={i18n("global.Search")}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
              />
            </div>
          </form>
          <Link
            to="#"
            onClick={() => {
              setShowSortModal(true);
            }}
          >
            <img src={IconSortAlpha} alt="IconSortAlpha" />
          </Link>
          <Link
            to="#"
            onClick={() => {
              setShowFilterModal(true);
            }}
          >
            <img src={IconFilter} alt="IconFilter" />
          </Link>
        </div>
        <ul className="form-list su-list">
          {allReports && allReports.length
            ? searchSortFilter(allReports).map((item, index) => {
                return (
                  <ReportsList
                    key={index}
                    report={item}
                    status={item.status}
                    assignee={item.assignee}
                    formName={item.formDetail.title}
                    id={item.id ? item.id : item._id}
                    archiveReportPhoto={item?.formDetail?.image}
                  />
                );
              })
            : null}
        </ul>
      </div>
      <DialogModal
        showDlg={showSortModal}
        classes="modal-sort"
        // title={"Sort"}
        content={
          <ul className="list-unstyled">
            <li
              onClick={() => {
                if (sortBy.attribute === "FORM_NAME") {
                  if (sortBy.order === "ASC") {
                    setSortBy({ attribute: "FORM_NAME", order: "DESC" });
                  } else {
                    setSortBy({ attribute: "FORM_NAME", order: "ASC" });
                  }
                } else {
                  setSortBy({ attribute: "FORM_NAME", order: "ASC" });
                }
              }}
            >
              <span className="icon-wrap">
                {sortBy.attribute === "FORM_NAME" ? (
                  sortBy.order === "ASC" ? (
                    <FaSortAlphaDown />
                  ) : (
                    <FaSortAlphaUp />
                  )
                ) : null}
              </span>
              <strong>
                {" "}
                <I18n lng="global.ReportName" />
              </strong>
            </li>
            <li
              onClick={() => {
                if (sortBy.attribute === "DATE_CREATED") {
                  if (sortBy.order === "ASC") {
                    setSortBy({ attribute: "DATE_CREATED", order: "DESC" });
                  } else {
                    setSortBy({ attribute: "DATE_CREATED", order: "ASC" });
                  }
                } else {
                  setSortBy({ attribute: "DATE_CREATED", order: "ASC" });
                }
              }}
            >
              <span className="icon-wrap">
                {sortBy.attribute === "DATE_CREATED" ? (
                  sortBy.order === "ASC" ? (
                    <FaSortNumericDownAlt />
                  ) : (
                    <FaSortNumericUpAlt />
                  )
                ) : null}
              </span>
              <strong>
                <I18n lng="global.DateCreated" />
              </strong>
            </li>
            <li
              onClick={() => {
                if (sortBy.attribute === "DATE_MODIFIED") {
                  if (sortBy.order === "ASC") {
                    setSortBy({ attribute: "DATE_MODIFIED", order: "DESC" });
                  } else {
                    setSortBy({ attribute: "DATE_MODIFIED", order: "ASC" });
                  }
                } else {
                  setSortBy({ attribute: "DATE_MODIFIED", order: "ASC" });
                }
              }}
            >
              <span className="icon-wrap">
                {sortBy.attribute === "DATE_MODIFIED" ? (
                  sortBy.order === "ASC" ? (
                    <FaSortNumericDownAlt />
                  ) : (
                    <FaSortNumericUpAlt />
                  )
                ) : null}
              </span>
              <strong>
                <I18n lng="global.DateModified" />
              </strong>
            </li>
          </ul>
        }
        primaryActionTitle={"Apply"}
        secondaryAction={() => setShowSortModal(false)}
        primaryAction={() => setShowSortModal(false)}
      />
      {showFilterModal && (
        <FilterDialog
          show={showFilterModal}
          clearActions={() => {
            setArchiveReportFilter({ ...archiveReportfilterInitState });
            getAdminManagerReports(true);
          }}
          secondryActionTitle={"Clear"}
          primaryAction={archiveReportFilterHandler}
          secondaryAction={() => setShowFilterModal(false)}
          filterState={archiveReportFilter}
          setFilterState={setArchiveReportFilter}
          filterInitState={archiveReportfilterInitState}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ adminManagerReports }) => ({
  allReports: adminManagerReports.allReports,
});
const mapDispatchToProps = { getAdminManagerReports };
export default connect(mapStateToProps, mapDispatchToProps)(ArchiveReportsView);
