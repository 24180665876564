import axios from "axios";
import { Storage } from "react-jhipster";
import { REQUEST, SUCCESS, FAILURE } from "../action-type.util";

export const ACTION_TYPES = {
  REGISTER: "authentication/REGISTER",
  LOGIN: "authentication/LOGIN",
  GET_SESSION: "authentication/GET_SESSION",
  RESEND_EMAIL: "authentication/RESEND_EMAIL",
  LOGOUT: "authentication/LOGOUT",
  CLEAR_AUTH: "authentication/CLEAR_AUTH",
  ERROR_MESSAGE: "authentication/ERROR_MESSAGE",
  UPDATE_PROFILE: "authentication/UPDATE_PROFILE",
  CHANGE_PASSWORD: "authentication/CHANGE_PASSWORD",
  UPDATE_ROUTE: "authentication/UPDATE_ROUTE",
  REGISTER_DEVICE: "authentication/REGISTER_DEVICE",
  SETUP_ACCOUNT: "authentication/SETUP_ACCOUNT",
  CURRENT_ROLE: "authentication/CurrentRole",
  TOKEN_CHECK: "authentication/TokenCheck",
};

const AUTH_TOKEN_KEY = "tamam-authenticationToken";

const initialState = {
  loading: false,
  registering: false,
  profileUpdating: false,
  changingPassword: false,
  isAuthenticated: false,
  user: undefined,
  currentRoute: {
    step1: undefined,
    step2: undefined,
    enableBack: false,
    formScrol: false,
  },
  currentRole: undefined,
  userRoleAccess: undefined,
};

export const AuthenticationState = initialState;

// Reducer
// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.LOGIN):
    case REQUEST(ACTION_TYPES.REGISTER_DEVICE):
    case REQUEST(ACTION_TYPES.CURRENT_ROLE):
    case REQUEST(ACTION_TYPES.SETUP_ACCOUNT):
    case REQUEST(ACTION_TYPES.RESEND_EMAIL):
    case REQUEST(ACTION_TYPES.TOKEN_CHECK):
      return {
        ...state,
      };
    case REQUEST(ACTION_TYPES.UPDATE_PROFILE):
      return {
        ...state,
        profileUpdating: true,
      };
    case REQUEST(ACTION_TYPES.CHANGE_PASSWORD):
      return {
        ...state,
        changingPassword: true,
      };
    case REQUEST(ACTION_TYPES.GET_SESSION):
      return {
        ...state,
        loading: true,
        user: undefined,
      };
    case REQUEST(ACTION_TYPES.REGISTER):
      return {
        ...state,
        registering: true,
      };
    case FAILURE(ACTION_TYPES.REGISTER):
      return {
        ...state,
        registering: false,
      };
    case ACTION_TYPES.UPDATE_ROUTE:
      return {
        ...state,
        currentRoute: action.payload,
      };
    case FAILURE(ACTION_TYPES.LOGIN):
    case FAILURE(ACTION_TYPES.RESEND_EMAIL):
    case FAILURE(ACTION_TYPES.TOKEN_CHECK):
      return {
        ...state,
        loading: false,
      };
    case FAILURE(ACTION_TYPES.REGISTER_DEVICE):
    case FAILURE(ACTION_TYPES.SETUP_ACCOUNT):
    case FAILURE(ACTION_TYPES.CURRENT_ROLE):
    case FAILURE(ACTION_TYPES.UPDATE_PROFILE):
      return {
        ...state,
        profileUpdating: false,
      };
    case FAILURE(ACTION_TYPES.CHANGE_PASSWORD):
      return {
        ...state,
        changingPassword: false,
      };

    case FAILURE(ACTION_TYPES.GET_SESSION):
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        user: undefined,
      };
    case SUCCESS(ACTION_TYPES.LOGIN):
    case SUCCESS(ACTION_TYPES.REGISTER_DEVICE):
    case SUCCESS(ACTION_TYPES.SETUP_ACCOUNT):
    case SUCCESS(ACTION_TYPES.RESEND_EMAIL):
    case SUCCESS(ACTION_TYPES.TOKEN_CHECK):
      return {
        ...state,
        loading: false,
      };
    case SUCCESS(ACTION_TYPES.UPDATE_PROFILE):
      return {
        ...state,
        profileUpdating: false,
      };
    case SUCCESS(ACTION_TYPES.CHANGE_PASSWORD):
      return {
        ...state,
        changingPassword: false,
      };
    case ACTION_TYPES.CURRENT_ROLE:
      return {
        ...state,
        currentRole:
          state?.currentRole === "manager"
            ? "user"
            : state?.currentRole === "user" && state?.user?.role === "manager/inspector"
            ? "manager"
            : state?.currentRole === "admin" && state?.user?.role === "admin"
            ? "user"
            : "admin",
      };
    case SUCCESS(ACTION_TYPES.GET_SESSION):
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        currentRole:
          state?.currentRole === "user"
            ? "user"
            : state?.currentRole === "manager"
            ? "manager"
            : state?.currentRole === "admin"
            ? "admin"
            : action.payload.data.role.split("/")[0],
        user: action.payload.data,
        userRoleAccess: action.payload.data.roleSetting
          ? {
              managerPortal: action.payload.data.roleSetting?.managerRole,
              inspectorPortal: action.payload.data.roleSetting?.inspectorRole,
              form: {
                selected: action.payload.data.roleSetting.managerPortal[0].selected,
                access: {
                  create: action.payload.data.roleSetting.managerPortal[0].subItem[0].selected,
                  edit: action.payload.data.roleSetting.managerPortal[0].subItem[1].selected,
                  archive: action.payload.data.roleSetting.managerPortal[0].subItem[2].selected,
                  delete: action.payload.data.roleSetting.managerPortal[0].subItem[3].selected,
                  viewHistory: action.payload.data.roleSetting.managerPortal[0].subItem[4].selected,
                  formDispatch: action.payload.data.roleSetting.managerPortal[0].subItem[5].selected,
                  deleteDispatch: action.payload.data.roleSetting.managerPortal[0].subItem[6].selected,
                  approvalRights: action.payload.data.roleSetting.managerPortal[0].subItem[7].selected,
                },
              },
              report: {
                selected: action.payload.data.roleSetting.managerPortal[1].selected,
                access: {
                  deleteArchive: action.payload.data.roleSetting.managerPortal[1].subItem[0].selected,
                  restoreDeleteReport: action.payload.data.roleSetting.managerPortal[1].subItem[1].selected,
                  exportOTherReport: action.payload.data.roleSetting.managerPortal[1].subItem[2].selected,
                  viewHistoryOtherReport: action.payload.data.roleSetting.managerPortal[1].subItem[3].selected,
                },
              },
              company: {
                selected: action.payload.data.roleSetting.managerPortal[2].selected,
                access: {
                  manageSites: action.payload.data.roleSetting.managerPortal[2].subItem[0].selected,
                  manageUsers: action.payload.data.roleSetting.managerPortal[2].subItem[1].selected,
                  manageRoles: action.payload.data.roleSetting.managerPortal[2].subItem[2].selected,
                  manageCompanyInformation: action.payload.data.roleSetting.managerPortal[2].subItem[3].selected,
                  manageSubscription: action.payload.data.roleSetting.managerPortal[2].subItem[4].selected,
                },
              },
              inspector: {
                accessReports: action.payload.data.roleSetting.inspectorPortal[0].selected,
                access: {
                  exportReport: action.payload.data.roleSetting.inspectorPortal[0].subItem[0].selected,
                  customizeReport: action.payload.data.roleSetting.inspectorPortal[0].subItem[1].selected,
                },
              },
              analytics: {
                selected: action.payload.data.roleSetting.managerPortal[3]?.selected,
                access: {
                  export: action.payload.data.roleSetting.managerPortal[3]?.subItem[0].selected,
                },
              },
            }
          : {},
      };
    case ACTION_TYPES.LOGOUT:
      return {
        ...initialState,
      };
    case ACTION_TYPES.CLEAR_AUTH:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        user: undefined,
      };
    default:
      return state;
  }
};

export const displayAuthError = (message) => ({
  type: ACTION_TYPES.ERROR_MESSAGE,
  message,
});

export const getSession = () => async (dispatch) => {
  await dispatch({
    type: ACTION_TYPES.GET_SESSION,
    payload: axios.get("auth/whoAmI"),
  });
};

export const updateProfile =
  ({ firstName, lastName, username, mobile, company, id }) =>
  async (dispatch) => {
    await dispatch({
      type: ACTION_TYPES.UPDATE_PROFILE,
      payload: axios.patch(`users/${id}`, {
        firstName,
        lastName,
        username,
        mobile,
        company,
      }),
    });
  };

export const changeUserPassword =
  ({ id, oldPassword, newPassword }) =>
  async (dispatch) => {
    await dispatch({
      type: ACTION_TYPES.CHANGE_PASSWORD,
      payload: axios.post(`/users/auth/change-password/${id}`, {
        oldPassword,
        newPassword,
      }),
    });
  };

export const registerUser =
  ({ firstName, lastName, email, mobile, password, ip, confirmation, assignForm, reportSubmission, approvalReport, rejectReport }) =>
  async (dispatch) => {
    return await dispatch({
      type: ACTION_TYPES.REGISTER,
      payload: axios.post("auth/register", {
        fullName: firstName + " " + lastName,
        firstName,
        lastName,
        email,
        mobile,
        password,
        ip,
        confirmation,
        assignForm,
        reportSubmission,
        approvalReport,
        rejectReport,
      }),
    });
  };

export const login =
  ({ email, password, rememberMe, ipAddress, country, platform }) =>
  async (dispatch) => {
    let deviceId = localStorage.getItem("deviceId");
    const result = await dispatch({
      type: ACTION_TYPES.LOGIN,
      payload: axios.post("auth/login", { email, password, deviceId, ipAddress, country, platform }),
    });
    const bearerToken = result.value.data.tokens.access.token;
    if (bearerToken) {
      const jwt = bearerToken;
      if (rememberMe) {
        Storage.local.set(AUTH_TOKEN_KEY, jwt);
      } else {
        Storage.session.set(AUTH_TOKEN_KEY, jwt);
      }
    }
    await dispatch(getSession());
    return result;
  };

export const udpateProfile =
  ({ id, firstName, lastName, fullName, company }) =>
  async (dispatch) => {
    return await dispatch({
      type: ACTION_TYPES.UPDATE_PROFILE,
      payload: axios.patch(`users/${id}`, {
        firstName,
        lastName,
        fullName,
        company,
      }),
    });
  };

export const clearAuthToken = () => {
  if (Storage.local.get(AUTH_TOKEN_KEY)) {
    Storage.local.remove(AUTH_TOKEN_KEY);
  }
  if (Storage.local.get("activate")) {
    Storage.local.remove("activate");
  }

  if (Storage.session.get(AUTH_TOKEN_KEY)) {
    Storage.session.remove(AUTH_TOKEN_KEY);
  }

  if (Storage.session.get("activate")) {
    Storage.session.remove("activate");
  }
};

export const logout = () => (dispatch) => {
  clearAuthToken();
  dispatch({
    type: ACTION_TYPES.LOGOUT,
  });
};

export const clearAuthentication = (messageKey) => (dispatch, getState) => {
  clearAuthToken();
  dispatch(displayAuthError(messageKey));
  dispatch({
    type: ACTION_TYPES.CLEAR_AUTH,
  });
};

export const updateRoute = (route) => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_ROUTE,
    payload: route,
  });
};
export const setDeviceName = (deviceName, deviceId, token) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.REGISTER_DEVICE,
    payload: axios.post(`/auth/register-device?token=${token}`, {
      deviceName,
      deviceId,
    }),
  });
};
export const setupAccount = (setup, token) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.SETUP_ACCOUNT,
    payload: axios.post(`/auth/setup-account?token=${token}`, setup),
  });
};
export const changeUsersPassword = (password) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.CHANGE_PASSWORD,
    payload: axios.patch(`/auth/change-password`, password),
  });
};
export const changeCurrentRole = () => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.CURRENT_ROLE,
  });
};
export const tokenCheckApi = (body) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.TOKEN_CHECK,
    payload: axios.post(`/auth/token-check`, body),
  });
};
