import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import FilterDialog from "../reportFilterDialog";
import I18n, { i18n } from "../../../private/languageSelector";
import ReportsList from "./my-reports-list-components/list-item";
import IconSearch from "../../../../assets/images/icon-search.svg";
import IconFilter from "../../../../assets/images/icon-filter.svg";
import DialogModal from "../../../../components/dialogs/compact-dialog";
import IconSortAlpha from "../../../../assets/images/icon-sort-alpha.svg";
import {
  FaSortAlphaDown,
  FaSortAlphaUp,
  FaSortNumericUpAlt,
  FaSortNumericDownAlt,
} from "react-icons/fa";
import {
  updateReportIsRead,
  getAdminManagerReports,
} from "../../../../redux/reducers/admin-manager-reports/admin-manager-report.reducer";
import { getBookmark } from "../../../../redux/reducers/form/form.reducer";

const MyReportsView = ({
  allReports,
  filterState,
  reportSettings,
  setFilterState,
  user,
  showFilterModal,
  currentRole,
  getBookmark,
  filterInitState,
  filterApplyHandler,

  setShowFilterModal,
  getAdminManagerReports,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [sortBy, setSortBy] = useState({
    attribute: "DATE_MODIFIED",
    order: "DESC",
  });
  const [showSortModal, setShowSortModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bookmarkForm, setBookmarkForm] = useState();

  useEffect(() => {
    if (user) {
      getBookmark(user.id, "REPORT").then((res) => {
        setBookmarkForm(res?.value?.data);
      });
    }
    // eslint-disable-next-line
  }, [loading]);

  useEffect(() => {
    getAdminManagerReports(false);
    // eslint-disable-next-line
  }, []);

  const searchSortFilter = (reportsList) => {
    if (reportsList?.length) {
      let bookmarkedReports = reportsList?.filter(
        (item) => item?.isBookmarked === true
      );
      let unBookmarkedReports = reportsList?.filter(
        (item) => item?.isBookmarked === false
      );

      if (bookmarkedReports?.length) {
        if (searchValue) {
          bookmarkedReports = [
            ...bookmarkedReports.filter((item) =>
              item.formDetail.title
                .toLowerCase()
                .includes(searchValue.toLowerCase())
            ),
          ];
        }

        if (sortBy) {
          bookmarkedReports = [
            ...bookmarkedReports.sort(function (a, b) {
              if (sortBy.attribute === "FORM_NAME") {
                return (
                  (a.formDetail.title === b.formDetail.title
                    ? 0
                    : a.formDetail.title < b.formDetail.title
                    ? -1
                    : 1) * (sortBy.order === "ASC" ? 1 : -1)
                );
              } else if (sortBy.attribute === "DATE_CREATED") {
                return (
                  (a.createdAt.toString() === b.createdAt.toString()
                    ? 0
                    : a.createdAt.toString() < b.createdAt.toString()
                    ? -1
                    : 1) * (sortBy.order === "ASC" ? 1 : -1)
                );
              } else {
                return (
                  (a.updatedAt.toString() === b.updatedAt.toString()
                    ? 0
                    : a.updatedAt.toString() < b.updatedAt.toString()
                    ? -1
                    : 1) * (sortBy.order === "ASC" ? 1 : -1)
                );
              }
            }),
          ];
        }
      }

      if (unBookmarkedReports?.length) {
        if (searchValue) {
          unBookmarkedReports = [
            ...unBookmarkedReports.filter((item) =>
              item.formDetail.title
                .toLowerCase()
                .includes(searchValue.toLowerCase())
            ),
          ];
        }
        if (sortBy) {
          unBookmarkedReports = [
            ...unBookmarkedReports.sort(function (a, b) {
              if (sortBy.attribute === "FORM_NAME") {
                return (
                  (a.formDetail.title === b.formDetail.title
                    ? 0
                    : a.formDetail.title < b.formDetail.title
                    ? -1
                    : 1) * (sortBy.order === "ASC" ? 1 : -1)
                );
              } else if (sortBy.attribute === "DATE_CREATED") {
                return (
                  (a.createdAt.toString() === b.createdAt.toString()
                    ? 0
                    : a.createdAt.toString() < b.createdAt.toString()
                    ? -1
                    : 1) * (sortBy.order === "ASC" ? 1 : -1)
                );
              } else {
                return (
                  (a.updatedAt.toString() === b.updatedAt.toString()
                    ? 0
                    : a.updatedAt.toString() < b.updatedAt.toString()
                    ? -1
                    : 1) * (sortBy.order === "ASC" ? 1 : -1)
                );
              }
            }),
          ];
        }
      }

      return [...bookmarkedReports, ...unBookmarkedReports];
    } else {
      return [];
    }
  };

  const moduleId = bookmarkForm?.map((bookmark) => bookmark?.moduleId);
  const userId = bookmarkForm?.map((bookmark) => bookmark?.userId);

  return (
    <>
      <div className="px-3">
        <div className="search-bar">
          <form className="form-search">
            <div className="input-group">
              <div className="input-group-prepend">
                <img src={IconSearch} alt="Search Icon" />
              </div>
              <input
                className="form-control"
                placeholder={i18n("global.Search")}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
          </form>
          <Link to="#" onClick={() => setShowSortModal(true)}>
            <img src={IconSortAlpha} alt="IconSortAlpha" />
          </Link>
          <Link to="#" onClick={() => setShowFilterModal(true)}>
            <img src={IconFilter} alt="IconFilter" />
          </Link>
        </div>
        <ul className="form-list su-list">
          {allReports && allReports.length
            ? searchSortFilter(allReports).map((item, index) => {
                if (
                  (moduleId?.includes(item?._id) &&
                    currentRole !== "user" &&
                    userId?.includes(user?.id)) ||
                  (moduleId?.includes(item?.id) &&
                    userId?.includes(user?.id) &&
                    currentRole === "user")
                ) {
                  return (
                    <ReportsList
                      loading={loading}
                      setLoading={setLoading}
                      inspectorName={
                        item?.formDetail?.inspector
                          ? item?.formDetail?.inspector
                          : item?.assignee?.fullName
                      }
                      assigner={item.assigner}
                      formManagementId={item?.id ? item?.id : item?._id}
                      status={item?.status}
                      key={index}
                      reportUUid={item?.reportId}
                      reportShareSetting={
                        reportSettings ? reportSettings : null
                      }
                      dispatchDate={item?.dispatchDate}
                      createdAt={item?.createdAt}
                      form={item?.form}
                      formDetail={item?.formDetail}
                      site={item?.site}
                      updatedAt={item?.updatedAt}
                      isRead={item?.isRead}
                      id={item.id ? item.id : item._id}
                      reportItem={item}
                      bookmarked={item?.isBookmarked}
                      isSelfAssign={item?.isSelfAssignForm}
                    />
                  );
                }
                return null;
              })
            : null}
          {allReports && allReports.length
            ? searchSortFilter(allReports).map((item, index) => {
                if (
                  (!moduleId?.includes(item?._id) && currentRole !== "user") ||
                  (!moduleId?.includes(item?.id) && currentRole === "user")
                ) {
                  return (
                    <ReportsList
                      loading={loading}
                      setLoading={setLoading}
                      inspectorName={
                        item?.formDetail?.inspector
                          ? item?.formDetail?.inspector
                          : item?.assignee?.fullName
                      }
                      assigner={item.assigner}
                      formManagementId={item?.id ? item?.id : item?._id}
                      status={item?.status}
                      key={index}
                      reportUUid={item?.reportId}
                      reportShareSetting={
                        reportSettings ? reportSettings : null
                      }
                      dispatchDate={item?.dispatchDate}
                      createdAt={item?.createdAt}
                      form={item?.form}
                      formDetail={item?.formDetail}
                      site={item?.site}
                      updatedAt={item?.updatedAt}
                      isRead={item?.isRead}
                      id={item.id ? item.id : item._id}
                      reportItem={item}
                      bookmarked={item?.isBookmarked}
                      isSelfAssign={item?.isSelfAssignForm}
                    />
                  );
                }
                return null;
              })
            : null}
        </ul>
      </div>
      <DialogModal
        showDlg={showSortModal}
        classes="modal-sort"
        content={
          <ul className="list-unstyled">
            <li
              onClick={() => {
                if (sortBy.attribute === "FORM_NAME") {
                  if (sortBy.order === "ASC") {
                    setSortBy({ attribute: "FORM_NAME", order: "DESC" });
                  } else {
                    setSortBy({ attribute: "FORM_NAME", order: "ASC" });
                  }
                } else {
                  setSortBy({ attribute: "FORM_NAME", order: "ASC" });
                }
              }}
            >
              <span className="icon-wrap">
                {sortBy.attribute === "FORM_NAME" ? (
                  sortBy.order === "ASC" ? (
                    <FaSortAlphaDown />
                  ) : (
                    <FaSortAlphaUp />
                  )
                ) : null}
              </span>
              <strong>
                <I18n lng="global.ReportName" />
              </strong>
            </li>
            <li
              onClick={() => {
                if (sortBy.attribute === "DATE_CREATED") {
                  if (sortBy.order === "ASC") {
                    setSortBy({ attribute: "DATE_CREATED", order: "DESC" });
                  } else {
                    setSortBy({ attribute: "DATE_CREATED", order: "ASC" });
                  }
                } else {
                  setSortBy({ attribute: "DATE_CREATED", order: "ASC" });
                }
              }}
            >
              <span className="icon-wrap">
                {sortBy.attribute === "DATE_CREATED" ? (
                  sortBy.order === "ASC" ? (
                    <FaSortNumericDownAlt />
                  ) : (
                    <FaSortNumericUpAlt />
                  )
                ) : null}
              </span>
              <strong>
                <I18n lng="global.DateCreated" />
              </strong>
            </li>
            <li
              onClick={() => {
                if (sortBy.attribute === "DATE_MODIFIED") {
                  if (sortBy.order === "ASC") {
                    setSortBy({ attribute: "DATE_MODIFIED", order: "DESC" });
                  } else {
                    setSortBy({ attribute: "DATE_MODIFIED", order: "ASC" });
                  }
                } else {
                  setSortBy({ attribute: "DATE_MODIFIED", order: "ASC" });
                }
              }}
            >
              <span className="icon-wrap">
                {sortBy.attribute === "DATE_MODIFIED" ? (
                  sortBy.order === "ASC" ? (
                    <FaSortNumericDownAlt />
                  ) : (
                    <FaSortNumericUpAlt />
                  )
                ) : null}
              </span>
              <strong>
                <I18n lng="global.DateModified" />
              </strong>
            </li>
          </ul>
        }
        primaryActionTitle={i18n("global.Apply")}
        primaryAction={() => setShowSortModal(false)}
        secondaryAction={() => setShowSortModal(false)}
      />
      {showFilterModal && (
        <FilterDialog
          show={showFilterModal}
          primaryAction={filterApplyHandler}
          clearActions={() => {
            setFilterState({ ...filterInitState });
            getAdminManagerReports(false);
          }}
          filterState={filterState}
          secondryActionTitle={i18n("global.Clear")}
          setFilterState={setFilterState}
          filterInitState={filterInitState}
          filterApplyHandler={filterApplyHandler}
          secondaryAction={() => setShowFilterModal(false)}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ adminManagerReports, authentication }) => ({
  user: authentication.user,
  currentRole: authentication.currentRole,
  allReports: adminManagerReports.allReports,
  reportSettings: adminManagerReports.reportSettings,
});

const mapDispatchToProps = {
  updateReportIsRead,
  getBookmark,
  getAdminManagerReports,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyReportsView);
