import React from "react";
import { connect } from "react-redux";
import { i18n } from "../languageSelector";
import { useHistory } from "react-router-dom";
import { TEXT_DIRECTION } from "../../../helper/helperFunctions";

const FormNavbar = ({ moduleName, currentRole }) => {
  const history = useHistory();

  return (
    <ul
      className="nav nav-tabs"
      style={
        TEXT_DIRECTION() === "rtl"
          ? { paddingRight: "28px", paddingLeft: "6px" }
          : { paddingRight: "20px", paddingLeft: "14px" }
      }
    >
      <li className="nav-item">
        <button
          style={{ fontSize: "16px" }}
          className={`nav-link ${
            moduleName === "form-management" ? "active" : ""
          }`}
          onClick={(e) => {
            e.preventDefault();
            history.push("/forms/form-management");
          }}
        >
          {i18n("global.Forms")}
        </button>
      </li>
      {currentRole !== "user" ? (
        <>
          <li className="nav-item">
            <button
              style={{ fontSize: "16px" }}
              className={`nav-link ${
                moduleName === "global-list" ? "active" : ""
              }`}
              onClick={(e) => {
                e.preventDefault();
                history.push("/forms/global-list");
              }}
            >
              {i18n("Form.GlobalLists")}
            </button>
          </li>
          <li className="nav-item">
            <button
              style={{ fontSize: "16px" }}
              className={`nav-link ${moduleName === "archive" ? "active" : ""}`}
              onClick={(e) => {
                e.preventDefault();
                history.push("/forms/archive");
              }}
            >
              {i18n("global.Archive")}
            </button>
          </li>
        </>
      ) : null}
    </ul>
  );
};

const mapStateToProps = ({ authentication }) => ({
  currentRole: authentication.currentRole,
});

export default connect(mapStateToProps, null)(FormNavbar);
