/** @format */

import axios from "axios";
import { REQUEST, SUCCESS, FAILURE } from "../action-type.util";
export const ACTION_TYPES = {
  GET_SUBSCRIPTION_PLANS: "subscription/plans",
  GET_SUBSCRIPTION_PAYMENTS: "subscription/payment",
  SUBSCRIPTION_LOADING: "subscription/request",
  SUBSCRIPTION_FAILURE: "subscription/failed",
  SUBSCRIPTION_INVOICE_CREATED: "subscription-invoice/Success",
  SMS_INVOICE_CREATED: "sms-invoice/Success",
  SUBSCRIPTION_PAYMENT_CREATED: "subscription-payment/Success",
  USER_SUBSCRIPTION_DETAIL: "subscription-detail/Success",
  SINGLE_SUBSCRIPTION_DETAIL: "single-subscription-detail/Success",
  GET_COMPANY_SUBSCRIPTIONS: "get-company/subscription",
  UPDATE_SUBSCRIPTION_DATA: "update/subscription-data",
  GET_ALL_INVOICES: "get-all-invoices",
  GET_INVOICE: "get-invoice",
};

const initialState = {
  loading: false,
  error: null,
};

export const SubscriptionState = initialState;
// Reducer
// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.SUBSCRIPTION_LOADING):
    case REQUEST(ACTION_TYPES.GET_SUBSCRIPTION_PAYMENTS):
    case REQUEST(ACTION_TYPES.SUBSCRIPTION_INVOICE_CREATED):
    case REQUEST(ACTION_TYPES.SMS_INVOICE_CREATED):
    case REQUEST(ACTION_TYPES.USER_SUBSCRIPTION_DETAIL):
    case REQUEST(ACTION_TYPES.SINGLE_SUBSCRIPTION_DETAIL):
    case REQUEST(ACTION_TYPES.GET_COMPANY_SUBSCRIPTIONS):
    case REQUEST(ACTION_TYPES.START_FREE_TRIAL):
    case REQUEST(ACTION_TYPES.GET_ALL_INVOICES):
    case REQUEST(ACTION_TYPES.GET_INVOICE):
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FAILURE(ACTION_TYPES.SUBSCRIPTION_FAILURE):
    case FAILURE(ACTION_TYPES.GET_SUBSCRIPTION_PAYMENTS):
    case FAILURE(ACTION_TYPES.SUBSCRIPTION_INVOICE_CREATED):
    case FAILURE(ACTION_TYPES.SMS_INVOICE_CREATED):
    case FAILURE(ACTION_TYPES.USER_SUBSCRIPTION_DETAIL):
    case FAILURE(ACTION_TYPES.SINGLE_SUBSCRIPTION_DETAIL):
    case FAILURE(ACTION_TYPES.GET_COMPANY_SUBSCRIPTIONS):
    case FAILURE(ACTION_TYPES.START_FREE_TRIAL):
    case FAILURE(ACTION_TYPES.GET_ALL_INVOICES):
    case FAILURE(ACTION_TYPES.GET_INVOICE):
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SUCCESS(ACTION_TYPES.GET_SUBSCRIPTION_PLANS):
      return {
        plans: action.payload.data,
        loading: false,
        error: null,
      };
    case SUCCESS(ACTION_TYPES.SUBSCRIPTION_INVOICE_CREATED):
      return {
        ...state,
        invoice: action.payload.data,
        loading: false,
        error: null,
      };
    case SUCCESS(ACTION_TYPES.SMS_INVOICE_CREATED):
      return {
        ...state,
        smsInvoice: action.payload.data,
        loading: false,
        error: null,
      };
    case SUCCESS(ACTION_TYPES.SUBSCRIPTION_PAYMENT_CREATED):
      return {
        ...state,
        payment: action.payload.data,
        loading: false,
        error: null,
      };
    case SUCCESS(ACTION_TYPES.GET_SUBSCRIPTION_PAYMENTS):
      return {
        ...state,
        payment: action.payload.data,
        loading: false,
        error: null,
      };
    case SUCCESS(ACTION_TYPES.USER_SUBSCRIPTION_DETAIL):
      return {
        ...state,
        subscriptionsDetail: action.payload.data,
        loading: false,
        error: null,
      };
    case SUCCESS(ACTION_TYPES.SINGLE_SUBSCRIPTION_DETAIL):
      return {
        ...state,
        subscriptionDetailById: action.payload.data,
        loading: false,
        error: null,
      };
    case SUCCESS(ACTION_TYPES.GET_COMPANY_SUBSCRIPTIONS):
      return {
        ...state,
        subscriptionData: action.payload.data,
        loading: false,
        error: null,
      };
    case SUCCESS(ACTION_TYPES.UPDATE_SUBSCRIPTION_DATA):
      return {
        ...state,
        subscriptionFetchData: action.payload.data,
        loading: false,
        error: null,
      };
    case SUCCESS(ACTION_TYPES.GET_ALL_INVOICES):
      return {
        ...state,
        invoiceData: action.payload.data,
        loading: false,
        error: null,
      };
    case SUCCESS(ACTION_TYPES.GET_INVOICE):
      return {
        ...state,
        invoiceData: action.payload.data,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};

// Actions

export const getSubscriptionPlans = () => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.GET_SUBSCRIPTION_PLANS,
    payload: axios.get(`/subscriptions`),
  });
};

export const getSubscriptionPayment = (id) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.GET_SUBSCRIPTION_PAYMENTS,
    payload: axios.get(`/payments/${id}/verify`),
  });
};

export const createSubscriptionInvoice = (subscription) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.SUBSCRIPTION_INVOICE_CREATED,
    payload: axios.post(`/invoices`, subscription),
  });
};

export const createSubscriptionPayment = (subscription) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.SUBSCRIPTION_PAYMENT_CREATED,
    payload: axios.post(`/payments`, subscription),
  });
};

export const getUserSubscriptionDetail = () => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.USER_SUBSCRIPTION_DETAIL,
    payload: axios.get(`/subscriptions/subscription-details`),
  });
};

export const getSubscriptionDetailById = (id) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.SINGLE_SUBSCRIPTION_DETAIL,
    payload: axios.get(`/subscriptions/subscription-details/${id}`),
  });
};

export const createSmsInvoice = (invoice) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.SMS_INVOICE_CREATED,
    payload: axios.post(`/invoices/smsInvoices`, invoice),
  });
};

export const getSubscriptionByCompanyId = (id) => async (dispatch) => {
  if (id) {
    return await dispatch({
      type: ACTION_TYPES.GET_COMPANY_SUBSCRIPTIONS,
      payload: axios.get(`/admin/subscriptions/company/${id}`),
    });
  }
};

export const updateSubscriptionData = (id, data, value) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.UPDATE_SUBSCRIPTION_DATA,
    payload: axios.patch(`/admin/subscriptions/${id}`, { ...data, actionType: value }),
  });
};

export const getAllInvoices = () => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.GET_ALL_INVOICES,
    payload: axios.get("/invoices"),
  });
};

export const getInvoice = (id) => async (dispatch) => {
  return await dispatch({
    type: ACTION_TYPES.GET_INVOICE,
    payload: axios.get(`/invoices/${id}`),
  });
};
