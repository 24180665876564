export const getFileData = async (e) => {
  let fileData = { fileUrl: "", fileType: "", fileName: "" };

  if (e.target.files && e.target.files.length > 0) {
    const file = e.target.files[0];
    fileData.fileUrl = await readFile(file);
    fileData.fileType = file.name.split(".").pop();
    fileData.fileName = file.name;
  }
  return fileData;
};

export const getBase64FromFile = async (file) => {
  let fileData = { fileUrl: "", fileType: "" };
  if (file) {
    fileData.fileUrl = await readFile(file);
    fileData.fileType = file.name.split(".").pop();
  }
  return fileData;
};

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}
