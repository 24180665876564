import React from "react";
import Select from "react-select";
import ListItemMenu from "./list-item-menu";

const ListItem = ({ globalList }) => {
  return (
    <li className="d-flex align-items-center border-0">
      <div className="input-group input-select mr-3 mb-0">
        <Select
          className="react-select"
          placeholder={globalList.title}
          isClearable={true}
          options={globalList.list.map((item) => {
            return { label: item, value: item };
          })}
        />
      </div>
      <span className="list-item-action">
        <ListItemMenu globalList={globalList} />
      </span>
    </li>
  );
};

export default ListItem;
